import { useState } from "react";
import { ApiTeam } from "src/api/types";
import DropdownButton from "src/components/DropdownButton";
import EmptyState from "src/components/EmptyState";
import Tag from "src/components/Tag";
import Draggable from "src/components/draggable";
import withTranslation, {
	Translation,
} from "src/components/hoc/withTranslation";
import UserTeamCardItem from "src/components/profile/team/UserTeamCardItem";
import TeamCard from "src/components/profile/team/team-card/TeamCard";
import useTeams from "src/hooks/api/services/users/useTeams";
import useUsers from "src/hooks/api/services/users/useUsers";
import useNavigateModal from "src/hooks/useNavigateModal";
import { ucFirst } from "src/lib/formatters";
import { cn } from "src/lib/utils";

interface UsersTeamCardProps extends Translation {
	team: ApiTeam;
	droppable?: boolean;
	content?: {
		className?: string;
	};
}

const UsersTeamCard = ({ t, team, content, droppable }: UsersTeamCardProps) => {
	const { users: _users } = useUsers();
	const { actions } = useTeams();
	const navigateModal = useNavigateModal();
	const [status, setStatus] = useState<"idle" | "deleting">("idle");
	const users = _users.filter((user) =>
		(user?.teams || []).includes(team.slug)
	);
	const Wrapper = droppable ? Draggable.Drop : "div";
	const dropId = `TEAM_${team.hid}`;

	const onDelete = () => {
		setStatus("deleting");
		actions
			.delete(team.id)
			.then(() => {
				setStatus("idle");
			})
			.finally(() => {
				setStatus("idle");
			});
	};

	return (
		<TeamCard {...{ team }}>
			<Wrapper id={dropId} className="flex flex-col gap-4">
				<div className="flex justify-between items-center gap-3">
					<TeamCard.Title />
					<DropdownButton
						menu={{
							position: "bottom-right",
						}}
						button={{
							tabIndex: "-1",
							children: <i className="far fa-ellipsis-h" />,
							type: "transparent",
							iconOnly: true,
							className: "hover:bg-accent p-0 w-8 h-8 ",
						}}
					>
						<DropdownButton.Item
							icon="far fa-user-plus"
							onClick={(close) => {
								navigateModal(
									`/profile/users/invite?team=${team.hid}`
								);
								if (close) close();
							}}
						>
							{t("actions.invite-user")}
						</DropdownButton.Item>
						<DropdownButton.Item
							icon="far fa-pencil"
							onClick={(close) => {
								navigateModal(
									`/profile/users/teams/${team.hid}/edit`
								);
								if (close) close();
							}}
						>
							{t("actions.edit")}
						</DropdownButton.Item>
						<DropdownButton.Item
							icon="far fa-trash"
							loading={status === "deleting"}
							onClick={onDelete}
						>
							{t("actions.delete")}
						</DropdownButton.Item>
					</DropdownButton>
				</div>
				<div
					className={cn(
						"bg-accent-background rounded-md p-4 flex gap-3 flex-wrap",
						content?.className
					)}
				>
					{users.map((user) => (
						<UserTeamCardItem
							key={`user-${user.hid}-${team.hid}`}
							{...{ user, team }}
						/>
					))}
					{users.length === 0 && (
						<EmptyState
							className="border-0"
							title={t("empty.title")}
							description={t("empty.description")}
						/>
					)}
				</div>
				{!!team.skills.length && (
					<div className="flex gap-2 flex-wrap items-center">
						<p>{t("skills.title")}</p>
						{team.skills.map((skill) => (
							<Tag
								className="font-regular"
								theme="gray"
								key={`skill-${skill.slug}`}
							>
								{ucFirst(skill.name)}
							</Tag>
						))}
					</div>
				)}
			</Wrapper>
		</TeamCard>
	);
};

UsersTeamCard.locale = {
	nl: {
		actions: {
			"invite-user": "Gebruiker",
			delete: "Verwijderen",
			edit: "Aanpassen",
		},
		skills: {
			title: "Skills",
		},
		empty: {
			title: "Geen teamleden",
			description:
				"Dit team heeft momenteel geen leden; om iemand toe te voegen, sleep een teamlid naar dit vak.",
		},
	},
	en: {
		actions: {
			"invite-user": "Invite User",
			delete: "Delete",
			edit: "Edit",
		},
		skills: {
			title: "Skills",
		},
		empty: {
			title: "No Team Members",
			description:
				"This team currently has no members; to add someone, drag a team member to this box.",
		},
	},
};

export default withTranslation(UsersTeamCard);
