import { useParams } from "react-router-dom";
import BackButton from "src/components/BackButton";
import Card from "src/components/Card";
import PlatformFeedbackModal from "src/components/feedback/PlatformFeedbackModal";
import withTranslation, {
	Translation,
} from "src/components/hoc/withTranslation";
import SkillsData from "src/components/skills-data/SkillsData";
import RegistrationsSidebar from "src/components/tasks/panes/registration/RegistrationsSidebar";
import SelectedRegistrationPane from "src/components/tasks/panes/registration/SelectedRegistrationPane";
import useTask from "src/hooks/api/services/tasks/task/useTask";
import useTenant from "src/hooks/api/services/tenants/useTenant";
import useWindowSize from "src/hooks/useWindowSize";

const SelectRegistrationPane = ({ t }: Translation) => {
	const { id, registration } = useParams() as any;
	const { task } = useTask({ id });
	const { tenant } = useTenant();
	const { isTablet } = useWindowSize();

	return (
		<div className="flex gap-8 md:flex-row flex-col flex-1 min-h-[80vh] h-full">
			<RegistrationsSidebar />
			{registration && (
				<div className="flex flex-1 flex-col h-full">
					{isTablet && (
						<BackButton
							to={`/tasks/${id}/show`}
							label={t("back")}
							className="mb-4"
						/>
					)}
					<SelectedRegistrationPane id={registration} />
				</div>
			)}
			{!registration && !isTablet && (
				<div className="flex-1 flex flex-col">
					{task.skills &&
					tenant.modules.includes("skills-profile") ? (
						<Card>
							<SkillsData
								className="flex flex-col flex-1 gap-12"
								data={task.skills}
							>
								<div className="flex justify-between items-end gap-4 flex-wrap">
									<h2>{t("skills.title")}</h2>
									<PlatformFeedbackModal
										type="TASK_SKILLS"
										data={{
											url: window.location.href,
										}}
										trigger={
											<PlatformFeedbackModal.Trigger />
										}
									>
										<PlatformFeedbackModal.Title />
										<PlatformFeedbackModal.Description>
											{t("skills-feedback.description")}
										</PlatformFeedbackModal.Description>
										<PlatformFeedbackModal.Form className="mt-4" />
									</PlatformFeedbackModal>
								</div>

								<div className="flex justify-center">
									<div className="flex gap-12">
										<div className="flex gap-4 md:flex-row flex-col justify-center items-center flex-1">
											<SkillsData.AreaChart type="area">
												<SkillsData.AreaChartDisplay className="col-span-2 max-h-[400px] w-full max-w-[400px]" />
												<SkillsData.AreaChartLabels className="max-w-[360px]" />
											</SkillsData.AreaChart>
										</div>
									</div>
								</div>

								<div className="flex gap-12 md:flex-row flex-col">
									<SkillsData.CheckEmptySkills type="COMPETENCE">
										<div className="flex flex-col flex-1 gap-3">
											<div className="flex flex-col">
												<SkillsData.Title type="COMPETENCE" />
												<SkillsData.Description type="COMPETENCE" />
											</div>
											<SkillsData.Skills type="COMPETENCE" />
										</div>
									</SkillsData.CheckEmptySkills>
									<SkillsData.CheckEmptySkills type="KNOWLEDGE">
										<div className="flex flex-col flex-1 gap-3">
											<div className="flex flex-col">
												<SkillsData.Title type="KNOWLEDGE" />
												<SkillsData.Description type="KNOWLEDGE" />
											</div>

											<SkillsData.Skills type="KNOWLEDGE" />
										</div>
									</SkillsData.CheckEmptySkills>
								</div>
							</SkillsData>
						</Card>
					) : (
						<div className="flex flex-col flex-1 justify-center items-center">
							{t("empty.title")}
						</div>
					)}
				</div>
			)}
		</div>
	);
};

SelectRegistrationPane.locale = {
	nl: {
		back: "Terug naar aanmeldingen",
		empty: {
			title: "Selecteer een aanmelding",
		},
		skills: {
			title: "Skills overzicht",
		},
		"skills-feedback": {
			description:
				"Vertel ons wat je van de skill resultaten vind, zo kunnen wij de AI in de toekomst verbeteren",
		},
	},
	en: {
		back: "Back to registrations",
		empty: {
			title: "Select a registration",
		},
		skills: {
			title: "According to our software you need the following skills for this task",
		},
		"skills-feedback": {
			description:
				"Tell us what you think about the skill results, so we can improve the AI in the future",
		},
	},
};

export default withTranslation(SelectRegistrationPane);
