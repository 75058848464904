import useApi from "src/hooks/api/utils/useApi";

type Options = {};

function useTasksStats(options?: Options) {
	const { state, actions, api, pagination } = useApi(
		{ id: "TASK_STATISTICS" },
		{
			baseUrl: `/client/statistics/tasks`,
			query: {},
		}
	);

	return {
		statistics: state?.data || {},
		status: state.status,
		pagination,
		filter: state?.filter || {},
		actions: {
			...actions,
		},
		api,
	};
}

export default useTasksStats;
