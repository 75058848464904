import { motion } from "framer-motion";

interface ShadowBackgroundProps {
	onClick: () => void;
	theme?: { opacity?: number; blur?: boolean };
}

const ShadowBackground = ({ onClick, theme }: ShadowBackgroundProps) => {
	const initial = {
		backgroundColor: "rgba(0,0,0,0)",
	};
	return (
		<>
			<motion.div
				{...{ onClick }}
				className={`backdrop-blur-[2px] fixed inset-0 z-[99] transition-all ${
					theme?.blur && "backdrop-blur"
				}`}
				{...{ initial }}
				exit={initial}
				animate={{
					backgroundColor: `rgba(0,0,0,${theme?.opacity || 0.4})`,
				}}
			/>
			{theme?.blur && (
				<div className="fixed inset-0 backdrop-blur-sm  z-[99]"></div>
			)}
		</>
	);
};

export default ShadowBackground;
