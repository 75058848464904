import { ComponentProps, ReactNode } from "react";
import { ApiCompanyMinimal } from "src/api/types";
import Avatar, { AvatarSize } from "src/components/Avatar";
import Card from "src/components/Card";
import useCompanyCardContext, {
	CompanyCardContext,
} from "src/components/company-card/Provider";
import CustomFormPreviewField from "src/components/custom-form/CustomFormPreviewField";
import { cn } from "src/lib/utils";

interface CompanyCardProps {
	to?: string;
	company: ApiCompanyMinimal;
	children?: ReactNode;
	card?: {
		className?: string;
		contentClassName?: string;
	};
}

const CompanyCard = ({ company, children, card, to }: CompanyCardProps) => {
	// const [status, setStatus] = useState<"idle" | "deleting">("idle");
	return (
		<CompanyCardContext.Provider value={{ company }}>
			<Card
				{...{ to }}
				className={card?.className}
				contentClassName={cn(
					"p-6 flex flex-col gap-4",
					card?.contentClassName
				)}
			>
				{children}
			</Card>
		</CompanyCardContext.Provider>
	);
};

interface CompanyCardAvatarProps {
	className?: string;
	variant?: "default" | "details";
	size?: AvatarSize;
}

function CompanyCardAvatar({
	className,
	variant = "default",
	size = "regular",
}: CompanyCardAvatarProps) {
	const { company } = useCompanyCardContext();

	if (variant === "default") {
		return (
			<Avatar
				src={company.avatar}
				alt={company.name}
				{...{ className, size }}
			/>
		);
	}

	return (
		<div className="flex gap-2">
			<Avatar
				src={company.avatar}
				alt={company.name}
				{...{ className, size }}
			/>
			<span>{company.name}</span>
		</div>
	);
}

const CompanyCardName = ({ className, ...rest }: ComponentProps<"p">) => {
	const { company } = useCompanyCardContext();
	return <p className={cn("", className)}>{company.name}</p>;
};

const CompanyCardExcerpt = ({ className, ...rest }: ComponentProps<"p">) => {
	const { company } = useCompanyCardContext();
	if (!company.bio) return null;
	return <p className={cn("line-clamp-2", className)}>{company.bio}</p>;
};

const CompanyCardCustomFormDetails = ({ className }: ComponentProps<"div">) => {
	const { company } = useCompanyCardContext();

	return (
		<div className={cn("flex flex-col gap-1 my-2", className)}>
			{Object.values(company?.values || {}).map(
				(field: any, index: number) => (
					<div
						key={`user-card-custom-form-${index}`}
						className="flex justify-between"
					>
						<span className="opacity-70">{field.label}:</span>
						<CustomFormPreviewField {...{ field }} />
					</div>
				)
			)}
		</div>
	);
};

CompanyCard.Avatar = CompanyCardAvatar;
CompanyCard.Name = CompanyCardName;
CompanyCard.Excerpt = CompanyCardExcerpt;
CompanyCard.CustomFormDetails = CompanyCardCustomFormDetails;

export default CompanyCard;
