import { ComponentProps } from "react";
import { ApiBoard } from "src/api/types";
import Button from "src/components/Button";
import withTranslation, {
	Translation,
} from "src/components/hoc/withTranslation";
import NavItem from "src/components/layout/layout-default/sidebar/NavItem";
import NavItemGroup from "src/components/layout/layout-default/sidebar/NavItemGroup";
import useBoards from "src/hooks/api/services/tasks/useBoards";
import useTenant from "src/hooks/api/services/tenants/useTenant";
import useTeams from "src/hooks/api/services/users/useTeams";
import useAuth from "src/hooks/selectors/useAuth";
import useLayout from "src/hooks/useLayout";
import { cn } from "src/lib/utils";

const BoardNavItems = ({
	t,
	className,
	children,
	...rest
}: Translation & ComponentProps<"div">) => {
	const { layout } = useLayout();
	const { teams, status: teamStatus } = useTeams();
	const { boards, status: boardStatus } = useBoards();
	const {
		tenant: { modules },
	} = useTenant();
	const auth = useAuth();
	const loading = teamStatus !== "idle" && boardStatus !== "idle";
	const general = boards.filter((board) => !board.team);
	const items = teams
		.map((team) => {
			return {
				name: team.name,
				slug: team.slug,
				color: team.color,
				items: boards.filter((board) => board.team === team.slug),
			};
		})
		.filter((item) => item.items.length);

	const renderItem = (board: ApiBoard) => (
		<NavItem
			key={`board-${board.slug}`}
			to={`/tasks/boards/${board.slug}`}
			icon={
				board.slug === "my-tasks"
					? {
							font_awesome: {
								name: "fas fa-user-crown",
							},
					  }
					: board.icon
			}
			iconOverlay={
				board.visibility === "PRIVATE" ? (
					<div className="absolute bottom-1 right-1 flex justify-center items-center">
						<i className="fas fa-lock text-white text-[10px]"></i>
					</div>
				) : undefined
			}
		>
			{board.excerpt || board.name}
		</NavItem>
	);

	if (loading) {
		return <></>;
	}

	return (
		<div {...rest} className={cn("flex flex-col gap-2", className)}>
			<NavItemGroup
				name="general"
				alwaysOpen={auth.type === "student"}
				defaultOpen={items.length === 0}
				label={<NavItemGroup.Label>{t("general")}</NavItemGroup.Label>}
			>
				<NavItem
					to={`/tasks`}
					icon={{
						font_awesome: {
							name: "fas fa-check-circle",
						},
					}}
				>
					{t("all-tasks")}
				</NavItem>
				{general.map(renderItem)}
				{modules.includes("extern-task") && (
					<NavItem
						to={`/tasks/registrations`}
						icon={{
							font_awesome: {
								name: "fas fa-comment-alt-check",
							},
						}}
					>
						{t("registrations")}
					</NavItem>
				)}
				{modules.includes("intern-task") && (
					<NavItem
						to={`/tasks/repeat`}
						icon={{
							font_awesome: {
								name: "fas fa-repeat-alt",
							},
						}}
					>
						{t("repeat")}
					</NavItem>
				)}
				{layout.navbar.variant === "default" && (
					<Button
						className="flex lg:hidden xl:flex"
						xsmall
						type="ghost"
						to="/tasks/boards/create"
					>
						<i className="far fa-plus"></i>
						{t("create-board")}
					</Button>
				)}
			</NavItemGroup>
			{items.map((team) => (
				<NavItemGroup
					key={`nav-team-${team.slug}`}
					name={team.slug}
					color={team.color}
					label={
						<NavItemGroup.Label color={team.color}>
							{team.name}
						</NavItemGroup.Label>
					}
				>
					{team.items.map(renderItem)}
					{layout.navbar.variant === "default" && (
						<Button
							className="flex lg:hidden xl:flex"
							xsmall
							type="ghost"
							to={`/tasks/boards/create?team=${team.slug}`}
						>
							<i className="far fa-plus"></i>
							{t("create-board")}
						</Button>
					)}
				</NavItemGroup>
			))}
		</div>
	);
};

BoardNavItems.locale = {
	nl: {
		all: "Alles",
		"all-tasks": "Alle taken",
		general: "Algemeen",
		registrations: "Aanmeldingen",
		repeat: "Herhaaltaken",
		search: "Taken zoeken",
		"create-board": "Bord toevoegen",
	},
	en: {
		all: "All",
		"all-tasks": "All tasks",
		general: "General",
		registrations: "Registrations",
		repeat: "Repeat tasks",
		search: "Taken zoeken",
		"create-board": "Add board",
	},
};

export default withTranslation(BoardNavItems);
