import { ComponentProps } from "react";
import { useTaskKanban } from "src/components/tasks/kanban/TaskKanbanCard";
import useBoards from "src/hooks/api/services/tasks/useBoards";
import { cn } from "src/lib/utils";

const TaskKanbanCardType = ({ className, ...rest }: ComponentProps<"div">) => {
	const { task } = useTaskKanban();
	const { boards } = useBoards();
	const board = task?.links?.board
		? boards.find((item) => item.hid === task?.links?.board)
		: null;

	if (task?.type === "GHOST") return <></>;
	return (
		<div {...rest} className={cn("flex gap-2 items-center", className)}>
			{board && board.visibility === "PRIVATE" && (
				<i className="fas text-[10px] fa-lock text-background-foreground opacity-20"></i>
			)}
			{/* {task?.type && <TaskTypeIndicator type={task.type} />} */}
			{task.links?.repeat && (
				<i className="fas text-sm fa-retweet text-background-foreground opacity-20"></i>
			)}
		</div>
	);
};

export default TaskKanbanCardType;
