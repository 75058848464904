import { Route } from "react-router-dom";
import ProtectedRoute from "src/routes/middlewares/ProtectedRoute";
import DashboardView from "src/views/authenticated/analytics/DashboardView";
import EmployeeView from "src/views/authenticated/analytics/employees/EmployeeView";
import EmployeesView from "src/views/authenticated/analytics/employees/EmployeesView";
import PlanningView from "src/views/authenticated/analytics/planning/PlanningView";
import SkillsView from "src/views/authenticated/analytics/skills/SkillsView";

const AnalyticsRoutes = [
	<Route
		element={
			<ProtectedRoute permission={"analytics.view"} type="company">
				<DashboardView />
			</ProtectedRoute>
		}
		path="/analytics/dashboard"
		key="/analytics/dashboard"
	/>,
	<Route key="/analytics/employees" path="/analytics/employees">
		<Route
			index
			element={
				<ProtectedRoute permission={"analytics.view"} type="company">
					<EmployeesView />
				</ProtectedRoute>
			}
		/>
		<Route
			element={
				<ProtectedRoute permission={"analytics.view"} type="company">
					<EmployeeView />
				</ProtectedRoute>
			}
			path=":id"
		/>
	</Route>,
	<Route key="/analytics/skills" path="/analytics/skills">
		<Route
			index
			element={
				<ProtectedRoute permission={"analytics.view"} type="company">
					<SkillsView />
				</ProtectedRoute>
			}
		/>
	</Route>,
	<Route
		element={
			<ProtectedRoute permission={"analytics.view"} type="company">
				<PlanningView />
			</ProtectedRoute>
		}
		path="/analytics/planning"
		key="/analytics/planning"
	/>,
];

export default AnalyticsRoutes;
