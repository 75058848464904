import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import usePatch from "src/hooks/rest/usePatch";

interface ConnectUserButtonProps {
	isConnected?: boolean;
	user: any;
	color?: string;
	children?: (props: {
		isConnected: boolean;
		loading: boolean;
		onClick: () => void;
	}) => JSX.Element;
}

const ConnectUserButton = ({
	isConnected,
	user,
	color,
	children,
}: ConnectUserButtonProps) => {
	const { t } = useTranslation("common");
	const [toggleConnection, loading] = usePatch(
		`/connections/${user.id}/toggle`
	);
	const [_isConnected, setIsConnected] = useState(isConnected || false);

	useEffect(() => {
		setIsConnected(isConnected || false);
	}, [isConnected, user]);

	const handleChange = () => {
		if (loading) return;
		toggleConnection({}).then(({ data }) => {
			setIsConnected(!_isConnected);
		});
	};

	if (children && typeof children === "function") {
		return children({
			isConnected: _isConnected,
			loading,
			onClick: handleChange,
		});
	}

	return (
		<span
			onClick={handleChange}
			className={`font-bold flex items-center gap-2 cursor-pointer transition-all ${
				color ? `text-color` : `text-${_isConnected ? "green" : "dark"}`
			}`}
		>
			<i className={`fas fa-user-${_isConnected ? "check" : "plus"}`}></i>
			<span className="hidden sm:block">{t("studentpool")}</span>
		</span>
	);
};

export default ConnectUserButton;
