import moment from "moment";
import { ReactNode } from "react";
import { useCalendar } from "src/components/Calendar";
import Loader from "src/components/Loader";
import { ucFirst } from "src/lib/formatters";
import { cn } from "src/lib/utils";

interface HeadProps {
	children?: any;
	title?: string;
	actions?: ReactNode;
}

const Head = ({ title, actions }: HeadProps) => {
	const { loading, selected, controls } = useCalendar();
	const onNavigate = (type: "prev" | "next") => {
		const value = selected.value;
		if (type === "next") {
			value.add(1, controls.interval);
		} else if (type === "prev") {
			value.subtract(1, controls.interval);
		}
		selected.onChange(value.toDate());
	};
	return (
		<div className="flex items-center justify-between py-3">
			<div className="flex items-center gap-2">
				<small className="flex md:hidden">
					{title || ucFirst(selected.value.format("MMMM"))}
				</small>
				<span className="hidden md:flex">
					{title || ucFirst(selected.value.format("MMMM YYYY"))}
				</span>
				{loading && <Loader />}
			</div>

			<div className="flex gap-6 items-center">
				{actions}
				<div className="flex gap-2 items-center">
					<NavigateArrow
						type="prev"
						onClick={() => onNavigate("prev")}
					/>
					{controls.interval === "week" && (
						<strong className="px-1">
							{moment(selected.value).week()}
						</strong>
					)}
					{controls.interval === "month" && (
						<strong className="px-1">
							{moment(selected.value).format("MMM")}
						</strong>
					)}
					<NavigateArrow
						type="next"
						onClick={() => onNavigate("next")}
					/>
				</div>
			</div>
		</div>
	);
};

interface NavigateArrowProps {
	type: "prev" | "next";
	onClick: () => void;
}

const NavigateArrow = ({ type, onClick }: NavigateArrowProps) => {
	return (
		<button
			{...{ onClick }}
			className="w-8 h-8 rounded-full border border-dark text-background-foreground hover:bg-dark hover:text-white transition-all flex justify-center items-center"
		>
			<i
				className={cn(
					"far text-[16px] leading-[11px] flex items-center justify-center mt-[2px]",
					type === "prev" && "fa-angle-left",
					type === "next" && "fa-angle-right"
				)}
			></i>
		</button>
	);
};

export default Head;
