import axios from "axios";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Button from "src/components/Button";
import FileUpload from "src/components/form/file-upload/FileUpload";
import FormGroup from "src/components/form/FormGroup";
import useTaskForm from "src/hooks/api/tasks/useTaskForm";

const GalleryForm = () => {
	const { t } = useTranslation("forms", {
		keyPrefix: "gallery-form",
	});
	const { task, actions } = useTaskForm();
	const [deleting, setDeleting] = useState(undefined) as any;
	const media = task?.media || [];
	const handleFileUpload = (value: any) => {
		if (process.env.NODE_ENV !== "development") {
			window.dataLayer.push({
				event: "UploadImageEvent",
			});
		}
		actions.set({
			media: [value, ...media],
		});
	};

	const handleRemoveMedia = async (index: number) => {
		const item = media[index];

		if (item?.id) {
			setDeleting(item.id);
			await axios.delete(`/media/${item.id}`);
			setDeleting(undefined);
		}

		media.splice(index, 1);
		actions.set({
			media,
		});
		if (process.env.NODE_ENV !== "development") {
			window.dataLayer.push({
				event: "RemoveImageEvent",
			});
		}
	};

	return (
		<FormGroup label={t("label")}>
			<div className="flex flex-col gap-4 flex-1">
				<FileUpload
					value={null}
					className="gtm-gallery--button-plaats-foto"
					noPreview
					onChange={handleFileUpload}
				/>
				<div className="flex flex-col md:grid grid-cols-3 gap-4">
					{media.map((item: any, index: number) => (
						<div
							key={index + "image"}
							className="flex relative rounded-md overflow-hidden"
						>
							<div className="absolute top-2 right-2">
								<Button
									iconOnly
									type="gray"
									// loading={item?.id && deleting === item?.id}
									onClick={() => handleRemoveMedia(index)}
								>
									{deleting !== item?.id ? (
										<i className="fal fa-times"></i>
									) : (
										<span className="base-loader"></span>
									)}
								</Button>
							</div>
							<PreviewImage file={item} />
						</div>
					))}
				</div>
			</div>
		</FormGroup>
	);
};

const PreviewImage = ({ file }: { file: any }) => {
	const [preview, setPreview] = useState("");

	useEffect(() => {
		if (file?.url) {
			setPreview(file.url);
			return;
		}
		const reader = new FileReader() as any;
		const url = reader.readAsDataURL(file);
		reader.onloadend = () => {
			setPreview(reader.result);
		};
		return url;
	}, [file]);

	if (!preview) return <span className="base-loader" />;

	return <img className="block w-full" src={preview} alt="" />;
};

export default GalleryForm;
