import { DEFAULT_COLORS } from "src/lib/constants";

export function generateTextColor(hexcolor?: string) {
	if (!hexcolor) return "#161730";

	hexcolor = hexcolor.replace("#", "");
	var r = parseInt(hexcolor.substr(0, 2), 16);
	var g = parseInt(hexcolor.substr(2, 2), 16);
	var b = parseInt(hexcolor.substr(4, 2), 16);
	var yiq = (r * 299 + g * 587 + b * 114) / 1000;
	return yiq >= 128 ? "#161730" : "#fff";
}

export function lightenDarkenColor(col: string, amt: number) {
	var usePound = false;

	if (col[0] === "#") {
		col = col.slice(1);
		usePound = true;
	}

	var num = parseInt(col, 16);

	var r = (num >> 16) + amt;

	if (r > 255) r = 255;
	else if (r < 0) r = 0;

	var b = ((num >> 8) & 0x00ff) + amt;

	if (b > 255) b = 255;
	else if (b < 0) b = 0;

	var g = (num & 0x0000ff) + amt;

	if (g > 255) g = 255;
	else if (g < 0) g = 0;

	return (usePound ? "#" : "") + (g | (b << 8) | (r << 16)).toString(16);
}

export function hexToRGB(hex: string, alpha: number | undefined = 1) {
	hex = hex.toUpperCase();
	const r = parseInt(hex.slice(1, 3), 16);
	const g = parseInt(hex.slice(3, 5), 16);
	const b = parseInt(hex.slice(5, 7), 16);
	return `rgba(${r}, ${g}, ${b}, ${alpha})`;
}

export function generateRandomColor() {
	return DEFAULT_COLORS[Math.floor(Math.random() * DEFAULT_COLORS.length)];
}

export const getAreaChartColor = (index: number) => {
	let color = 1000 - index * 100;
	if (color < 100) color = 100;
	return `var(--primary-color${index !== 0 ? `-${color}` : ""})`;
};
