import { motion } from "framer-motion";
import { DEFAULT_COLORS } from "src/lib/constants";
import { cn } from "src/lib/utils";

interface SwatchesProps {
	value?: string;
	onChange: (value: string) => void;
}

const Swatches = ({ value, onChange }: SwatchesProps) => {
	return (
		<div className="flex flex-wrap gap-1">
			{DEFAULT_COLORS.map((color) => (
				<Swatch
					key={`color-${color}`}
					{...{ color }}
					active={color === value}
					onClick={() => onChange(color)}
				/>
			))}
		</div>
	);
};

interface SwatchProps {
	active: boolean;
	color: string;
	onClick: () => void;
}

const Swatch = ({ active, color, onClick }: SwatchProps) => {
	return (
		<motion.div
			{...{ onClick }}
			animate={active ? "active" : "inactive"}
			whileHover="active"
			style={{ borderColor: active ? color : undefined }}
			className={cn(
				"flex aspect-video h-7 rounded-[2px] cursor-pointer relative border-2 border-transparent transition-all"
			)}
		>
			<div
				style={{ backgroundColor: color }}
				className="flex-1 m-[1px] rounded-sm"
			></div>
		</motion.div>
	);
};

export default Swatches;
