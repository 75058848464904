import { useParams } from "react-router-dom";
import { ApiTask } from "src/api/types";
import withTranslation, {
	Translation,
} from "src/components/hoc/withTranslation";
import TabNav from "src/components/layout/tab-nav/TabNav";
import TaskDropDownButton from "src/components/tasks/TaskDropDownButton";
import useTaskTabs from "src/hooks/api/tasks/useTaskTabs";
import useAuth from "src/hooks/selectors/useAuth";
import useWindowSize from "src/hooks/useWindowSize";

interface TaskCompanyHeadProps extends Translation {
	task: ApiTask;
	button?: any;
}

const TaskCompanyHead = ({ t, task, button }: TaskCompanyHeadProps) => {
	const auth = useAuth();
	const { registration } = useParams() as any;
	const { tabs } = useTaskTabs(task);
	const { isTablet } = useWindowSize();
	if (!auth?.has_subscription) {
		return <></>;
	}

	if (registration && isTablet) {
		return <></>;
	}
	return (
		<div className="flex items-center flex-wrap gap-4">
			<div className="flex-1">
				<TabNav {...{ tabs }} type="company" />
			</div>
			<div className="flex gap-2 items-center">
				{button}
				<TaskDropDownButton {...{ task }} />
				{/* {task?.url && (
					<>
						<Button type="dark" href={task.url} iconOnly>
							<i className="fas fa-eye"></i>
						</Button>
						<Button
							type="dark"
							to={{
								modal: `/tasks/${task.hid}/${pane}/share`,
								back: {
									to: `/tasks/${task.hid}/${pane}`,
								},
							}}
							iconOnly
						>
							<i className="fas fa-share-alt"></i>
						</Button>
					</>
				)} */}
			</div>
		</div>
	);
};

TaskCompanyHead.locale = {
	nl: {
		actions: {
			view: "Bekijken",
			repeat: "Herhalen",
			edit: "Aanpassen",
			hours: "Uren controle",
			invites: "Uitnodigingen",
			share: "Delen",
			"mass-message": "Stuur massa bericht",
		},
	},
	en: {
		actions: {
			view: "View",
			repeat: "Repeat",
			edit: "Edit",
			hours: "Hour registration",
			invites: "Invites",
			share: "Share",
			"mass-message": "Send mass message",
		},
	},
};

export default withTranslation(TaskCompanyHead);
