const referral = {
	"invite-link-modal": {
		title: "Jouw eigen link",
		description:
			"Laat je vrienden zich aanmelden door middel van jouw unieke link, kopieer de link hieronder.",
	},
	"invite-modal": {
		fields: {
			required: "Verplicht",
			"incorrect-email": "Ongeldig e-mailadres",
			"inuse-email": "Email bestaat al in de lijst",
		},
		title: "Vrienden uitnodigen",
		description: "Vul de emailadressen in van je vrienden.",
		form: {
			email: "E-mail",
		},
		send_one: "1 uitnodiging versturen",
		send_other: "{{count}} uitnodigingen versturen",
	},
	"index-view": {
		title: "Tell a friend",
		heading: "Hey money maker! Nodig je vrienden uit",
		description:
			"Met onze tell a friend actie kan jij een keer geld verdienen aan je vrienden. Hoe dan? Nodig hieronder zo veel mogelijk van je vrienden uit. Heeft jouw vriend via jou een account aangemaakt? Dan krijg jij van ons &euro; 5,- extra van ons op je eerst volgende uitgevoerde opdracht. Dus zorg jij ervoor dat 5 van je vrienden zich aanmelden, dan krijg je dus &euro; 25,-",
		terms: {
			title: "Algemene actievoorwaarden",
			"term-one":
				"Je krijgt je opgebouwde krediet uitbetaald na het volbrengen van je eerste opdracht.",
			"term-two":
				"De looptijd van de actie is onbepaald. {{tenant.name}} is ten allen tijde bevoegd zonder opgave van reden de actie te stoppen.",
			"term-three":
				"{{tenant.name}} is ten allen tijde bevoegd actievoorwaarden tussentijds te wijzigen.",
			"term-four":
				"Persoonsgegevens van deelnemers worden niet verstrekt aan derden.",
		},
		buttons: {
			link: "Uitnodigen via jouw link",
			email: "Uitnodigen via email",
		},
		list: {
			title: "Jouw uitgenodigde vrienden:",
			empty: "Je hebt nog geen vrienden uitgenodigd",
			paid: "Paid",
			"payment-pending": "Nog niet uitbetaald",
			signed: "Aangemeld",
			"signed-pending": "Nog niet aangemeld",
		},
	},
};

export default referral;
