import { Formik } from "formik";
import moment from "moment";
import { useEffect } from "react";
import Button from "src/components/Button";
import Input from "src/components/form/Input";
import withTranslation, {
	Translation,
} from "src/components/hoc/withTranslation";
import Loader from "src/components/Loader";
import { ResumeFormProps } from "src/components/resume/ResumeForm";
import useResume from "src/hooks/api/services/resume/useResume";
import useAuth from "src/hooks/selectors/useAuth";
import * as Yup from "yup";
import ResumeAvatar from "./ResumeAvatar";

function GeneralInfoForm({
	t,
	children,
	onCompleted,
}: ResumeFormProps & Translation) {
	const auth = useAuth();
	const { resume, actions, status } = useResume({
		rememberKey: auth.id,
	});
	// const navigate = useNavigate();

	useEffect(() => {
		actions.get(auth.id);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const scheme = {
		bio: Yup.string().required(t("required")),
	} as const;

	const initialValues = {
		date_of_birth: resume?.date_of_birth || "",
		bio: resume?.bio?.bio || "",
	};

	return (
		<>
			{status === "loading" ? (
				<div className="flex mx-auto my-auto">
					<Loader />
				</div>
			) : (
				<Formik
					{...{ initialValues }}
					validateOnChange
					validateOnBlur
					validateOnMount
					enableReinitialize
					validationSchema={Yup.object().shape(scheme)}
					onSubmit={(values: any, { setSubmitting }) => {
						if (values.date_of_birth) {
							values.date_of_birth = moment(
								values.date_of_birth
							).format("YYYY-MM-DD");
						}

						actions
							.update(
								{
									id: auth.hid,
									...values,
								},
								{
									updateList: true,
								}
							)
							.then((data: any) => {
								setSubmitting(false);
								actions.get(auth.hid).then(() => {
									if (onCompleted) onCompleted();
								});
							});
					}}
				>
					{({
						values,
						errors,
						touched,
						handleChange,
						handleSubmit,
						handleBlur,
						isSubmitting,
						isValid,
					}) => (
						<form className="flex flex-1 gap-4 flex-col">
							<ResumeAvatar />
							<Input
								label={t("description")}
								className={""}
								multiline={true}
								name="bio"
								noMarginBottom
								placeholder={t("description-placeholder")}
								value={values.bio}
								valid={values.bio && !errors.bio}
								error={errors.bio && touched.bio && errors.bio}
								{...{ handleChange, handleBlur }}
							/>
							<div className="flex-1"></div>
							{children ? (
								children({
									isValid,
									isLoading: isSubmitting,
									onSubmit: handleSubmit,
								})
							) : (
								<div className="flex justify-end">
									<Button
										type="dark"
										small
										disabled={!isValid}
										loading={isSubmitting}
										onClick={handleSubmit}
									>
										{t("continue")}
									</Button>
								</div>
							)}
						</form>
					)}
				</Formik>
			)}
		</>
	);
}

GeneralInfoForm.locale = {
	nl: {
		title: "Mijn profiel",
		"not-found": {
			title: "CV niet gevonden",
			description: "Het lijkt er op dat je nog geen CV hebt.",
			create_resume: "Creëer een CV",
		},
		"date-of-birth": "Geboortedatum",
		"min-age": "Minimale leeftijd is 16 jaar",
		description: "Omschrijving",
		"description-placeholder": "Vertel wat over jezelf",
		required: "Verplicht",
		continue: "Doorgaan",
	},
	en: {
		title: "My profile",
		"not-found": {
			title: "Resume not found",
			description: "It seems like you don't have a resume yet.",
			create_resume: "Create a resume",
		},
		"date-of-birth": "Date of birth",
		"min-age": "Minimal age is 16 year",
		description: "Description",
		"description-placeholder": "Tell something about yourself",
		required: "Required",
		continue: "Continue",
	},
};

export default withTranslation(GeneralInfoForm);
