import { useCallback, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import useTenant from "src/hooks/api/services/tenants/useTenant";
import useAuth from "src/hooks/selectors/useAuth";
import useNavigateModal from "src/hooks/useNavigateModal";

export default function useKeyCommands() {
	const { tenant } = useTenant();
	const auth = useAuth();
	const navigate = useNavigate();
	const navigateModal = useNavigateModal();
	const location = useLocation();

	const handleKeyPress = useCallback(
		(e: any) => {
			if (auth.type === "student" && tenant.slug !== "odylyt") {
				return;
			}

			//Handle the search command
			if ((e.metaKey || e.ctrlKey) && e.key === "p") {
				e.preventDefault();
				navigateModal("./search");
			}
			//Handle the new task command
			if ((e.metaKey || e.ctrlKey) && e.key === "k") {
				e.preventDefault();
				const pathname = location.pathname.replace(/\/$/, "");

				if (location.state) {
					const match = location.pathname.match(
						/\/modals\/tasks\/(\w+)/
					);

					const currentId = match ? match[1] : null;
					if (currentId) {
						navigate(`${pathname.replace(currentId, "create")}`, {
							state: location.state,
						});
						return;
					}
					navigate(`${pathname}/modals/tasks/create`, {
						state: location.state,
					});
				} else {
					navigateModal(`./modals/tasks/create`);
				}
			}
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[location, navigate, navigateModal]
	);
	useEffect(() => {
		document.addEventListener("keydown", handleKeyPress);

		return () => {
			document.removeEventListener("keydown", handleKeyPress);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [location]);
}
