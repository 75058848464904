import { ComponentProps, useState } from "react";
import { ApiVideoPitch } from "src/api/types";
import Loader from "src/components/Loader";
import FileUpload, {
	FileUploadChildren,
} from "src/components/form/file-upload/FileUpload";
import withTranslation, {
	Translation,
} from "src/components/hoc/withTranslation";
import { uploadFile } from "src/lib/uploadFile";
import { cn } from "src/lib/utils";

interface CreateVideoPitchProps
	extends Omit<ComponentProps<"div">, "children">,
		Translation {
	type: "RESUME";
	onCompleted?: (videoPitch: ApiVideoPitch) => void;
	variant?: "card" | "button";
	children?: FileUploadChildren;
}

const CreateVideoPitch = ({
	t,
	type,
	className,
	onCompleted,
	variant = "card",
	children,
	...rest
}: CreateVideoPitchProps) => {
	const [loading, setLoading] = useState(false);

	const onDrop = async (files?: any[]) => {
		if (!files || !files.length) return;

		setLoading(true);
		try {
			const file = files[0];

			let fd = new FormData();
			fd.append("file", file);
			fd.append("type", type);

			const { data } = await uploadFile("/video-pitches", fd);
			if (onCompleted) {
				onCompleted(data?.data);
			}

			setLoading(false);
		} catch (error) {
			setLoading(false);
		}
	};

	if (variant === "button" && children) {
		return (
			<FileUpload
				allow="video/*"
				skipApi
				value={undefined}
				{...{ onDrop, className }}
			>
				{(props) => children({ ...props, loading })}
			</FileUpload>
		);
	}

	return (
		<div {...rest} className={cn("", className)}>
			<FileUpload
				allow="video/*"
				skipApi
				value={undefined}
				{...{ onDrop }}
			>
				{({ isDragActive }) => (
					<div className="flex flex-col justify-center relative items-center border border-border bg-accent rounded-md text-center py-32 px-4">
						{loading && (
							<Loader.Overlay>
								<Loader />
							</Loader.Overlay>
						)}
						<i className="fas fa-video text-4xl mb-3"></i>
						<strong>{t("title")}</strong>
						<p>{t("description")}</p>
						<small>{t("max-size")}</small>
					</div>
				)}
			</FileUpload>
		</div>
	);
};

CreateVideoPitch.locale = {
	nl: {
		title: "Stel jezelf beter voor",
		description:
			"Klik hier om een video pitch te uploaden waar je jezelf voorstelt",
		"max-size": "(Max 15mb.)",
	},
	en: {
		title: "Introduce yourself better",
		description:
			"Click here to upload a video pitch where you introduce yourself",
		"max-size": "(Max 15mb.)",
	},
};

export default withTranslation(CreateVideoPitch);
