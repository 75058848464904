import { useAtom } from "jotai";
import moment from "moment";
import { useEffect } from "react";
import { TimerFormat } from "src/hooks/api/services/useTimeTracking";

export const EMPTY_STATE = {
	hours: "00",
	minutes: "00",
	seconds: "00",
};

export default function useTimeFrom(atom: any, start?: string) {
	const [data, setData] = useAtom(atom) as any;

	const getUpdatedCount = () => {
		const startDate = moment(start);
		const endDate = moment();
		const duration = moment.duration(endDate.diff(startDate));
		// console.log(duration);
		let total = duration.asSeconds();
		const hours = Math.floor(total / 3600);
		total %= 3600;
		const minutes = Math.floor(total / 60);
		const seconds = Math.round(total % 60);

		return {
			hours: (hours < 0 ? 0 : hours).toString().padStart(2, "0"),
			minutes: (minutes < 0 ? 0 : minutes).toString().padStart(2, "0"),
			seconds: (seconds < 0 ? 0 : seconds).toString().padStart(2, "0"),
		};
	};

	useEffect(() => {
		setData(getUpdatedCount());
		const interval = start
			? setInterval(() => setData(getUpdatedCount()), 1000)
			: null;

		return () => {
			// setData(EMPTY_STATE);
			if (interval) clearInterval(interval);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [start]);

	return (format: TimerFormat) => {
		return format
			.replace("hh", data.hours)
			.replace("mm", data.minutes)
			.replace("ss", data.seconds);
	};
}
