import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import { Crumb } from "src/components/Breadcrumb";
import { ContainerVariant } from "src/components/layout/Container";
import LayoutHeader from "src/components/layout/LayoutHeader";
import { ModalViewContext } from "src/components/layout/ModalView";
import SEO from "src/components/layout/SEO";
import LayoutDefault from "src/components/layout/layout-default/LayoutDefault";
import SettingsNav from "src/components/layout/settings-nav/SettingsNav";
import useAuth from "src/hooks/selectors/useAuth";
import { ParsedBackUrl, useParseBackUrl } from "src/hooks/useBackUrl";
import "./layout.scss";

interface LayoutProps {
	children?: any;
	loading?: boolean;
	title?: any;
	subtitle?: any;
	className?: string;
	hideCtaButton?: boolean;
	hideNav?: boolean;
	actions?: any;
	sidebar?: any;
	breadcrumb?: Crumb[] | any;
	variant?: "no-container" | "default" | "small";
	backUrl?: ParsedBackUrl;
	container?: ContainerVariant;
	head?: {
		children?: any;
		className?: string;
		animation?: any;
	};
}

const Layout = (props: LayoutProps) => {
	const auth = useAuth();

	const type = auth.type;
	const { children, title, hideNav, variant } = props;
	const { isModal } = useContext(ModalViewContext);
	const classes = ["layout", `layout_${type}`, "pb-12 md:pb-0"];
	if (props?.className) classes.push(props.className);
	if (auth?.type) classes.push(`is-${type}`);

	if (isModal) {
		return <>{children}</>;
	}

	return (
		<LayoutDefault {...props} variant={hideNav ? "medium" : variant}>
			{typeof title === "string" && <SEO {...{ title }} />}

			{children}
		</LayoutDefault>
	);
};

interface LayoutBackButtonProps {
	to: string;
	label?: string;
}

const LayoutBackButton = ({ label, to }: LayoutBackButtonProps) => {
	const backUrl = useParseBackUrl();
	const { t } = useTranslation("buttons");

	return (
		<div className="mb-8">
			<NavLink to={backUrl && typeof backUrl === "string" ? backUrl : to}>
				<i className="far fa-arrow-left mr-1.5"></i>
				<span>{backUrl ? t("back") : label}</span>
			</NavLink>
		</div>
	);
};

Layout.BackButton = LayoutBackButton;
Layout.Header = LayoutHeader;
Layout.SettingsNav = SettingsNav;

export default Layout;
