import { useCalendar } from "src/components/Calendar";
import withTranslation, {
	Translation,
} from "src/components/hoc/withTranslation";
import Tab from "src/components/layout/Tab";
import Tabs from "src/components/layout/Tabs";
import useWindowSize from "src/hooks/useWindowSize";

interface WeekLayoutSwitcherProps extends Translation {
	className?: string;
}

const WeekLayoutSwitcher = ({ t, className }: WeekLayoutSwitcherProps) => {
	const { isPhone } = useWindowSize();
	const { layout, actions } = useCalendar();

	if (isPhone) {
		return (
			<Tabs.Container {...{ className }}>
				<Tab
					variant="small"
					active
					// active={layout === "work-week"}
					onClick={() => {
						if (actions?.setLayout) {
							actions?.setLayout(
								layout === "work-week"
									? "full-week"
									: "work-week"
							);
						}
					}}
				>
					{t(`layout.${layout}`)}
				</Tab>
				{/* <Tab
					variant="small"
					active={layout === "full-week"}
					onClick={() => {
						if (actions?.setLayout) {
							actions?.setLayout("full-week");
						}
					}}
				>
					{t("layout.full-week")}
				</Tab> */}
			</Tabs.Container>
		);
	}

	return (
		<Tabs.Container {...{ className }}>
			<Tab
				variant="small"
				active={layout === "work-week"}
				onClick={() => {
					if (actions?.setLayout) {
						actions?.setLayout("work-week");
					}
				}}
			>
				{t("layout.work-week")}
			</Tab>
			<Tab
				variant="small"
				active={layout === "full-week"}
				onClick={() => {
					if (actions?.setLayout) {
						actions?.setLayout("full-week");
					}
				}}
			>
				{t("layout.full-week")}
			</Tab>
		</Tabs.Container>
	);
};

WeekLayoutSwitcher.locale = {
	nl: {
		layout: {
			"full-week": "Volledig",
			"work-week": "Werkweek",
		},
	},
	en: {
		layout: {
			"full-week": "Full",
			"work-week": "Workweek",
		},
	},
};

export default withTranslation(WeekLayoutSwitcher);
