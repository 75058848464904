import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { useTranslation } from "react-i18next";
import { ApiUserMinimal } from "src/api/types";
import Avatar from "src/components/Avatar";
import uid from "src/lib/uid";
import { cn } from "src/lib/utils";

const UserSuggestionList = forwardRef((props: any, ref) => {
	const { t } = useTranslation("common");
	const [selectedIndex, setSelectedIndex] = useState(0);

	const selectItem = (index: number) => {
		const item = props.items[index];
		if (item) {
			props.command({
				id: `USER_${item.hid}_${uid()}`,
				label: item.first_name,
			});
		}
	};

	const upHandler = () => {
		setSelectedIndex(
			(selectedIndex + props.items.length - 1) % props.items.length
		);
	};

	const downHandler = () => {
		setSelectedIndex((selectedIndex + 1) % props.items.length);
	};

	const enterHandler = () => {
		selectItem(selectedIndex);
	};

	useEffect(() => setSelectedIndex(0), [props.items]);

	useImperativeHandle(ref, () => ({
		onKeyDown: ({ event }: any) => {
			if (event.key === "ArrowUp") {
				upHandler();
				return true;
			}

			if (event.key === "ArrowDown") {
				downHandler();
				return true;
			}

			if (event.key === "Enter") {
				enterHandler();
				return true;
			}

			return false;
		},
	}));

	return (
		<div
			className={cn(
				"z-[90] rounded-md border border-border bg-background p-1 text-slate-950 shadow-md outline-none dark:border-slate-800 dark:bg-slate-950 dark:text-slate-50 w-auto"
			)}
		>
			{props.items.length ? (
				props.items.map((user: ApiUserMinimal, index: number) => (
					<button
						// eslint-disable-next-line jsx-a11y/aria-role
						role=""
						aria-selected={
							index === selectedIndex ? "true" : "false"
						}
						className={cn(
							"gap-2 relative flex cursor-pointer select-none items-center rounded-md px-1.5 py-1.5 text-sm outline-none border border-transparent w-full",
							"aria-selected:border-border aria-selected:text-background-foreground dark:aria-selected:bg-dark-accent dark:aria-selected:text-white"
						)}
						key={index}
						onClick={() => selectItem(index)}
					>
						<Avatar
							src={user.avatar}
							alt={user.first_name}
							size="xsmall"
						/>
						<span className="font-regular text-left text-sm flex-1 max-w-6">
							{user.full_name}
						</span>
					</button>
				))
			) : (
				<div className="p-1 text-placeholder">{t("empty")}</div>
			)}
		</div>
	);
});

export default UserSuggestionList;
