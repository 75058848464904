const common = {
	studentpool: "Connecties",
	theme: "Thema",
	task: "Opdracht",
	profile: "Profiel",
	chat: "Chat",
	or: "Of",
	live: "Live",
	concept: "Concept",
	accepted: "Goedgekeurd",
	pending: "In afwachting",
	skills: "Vaardigheden",
	empty: "Geen resultaten",
	clear: "Legen",
	today: "Vandaag",
	tomorrow: "Morgen",
	translation: {
		choose: "Kies een taal",
		NL: "Nederlands",
		EN: "Engels",
	},
	"unread-messages_one": "{{count}} ongelezen bericht",
	"unread-messages_other": "{{count}} ongelezen berichten",
	greeting: {
		goodafternoon: "Goedemiddag",
		goodevening: "Goedenavond",
		goodmorning: "Goedemorgen",
	},
	titles: {
		performer: "Uitvoerder",
		client: "Opdrachtgever",
	},
	labels: {
		"created-by": "Aangemaakt door",
		"created-at": "Aangemaakt op",
		"not-found": "Niet gevonden",
		average: "Gemiddeld",
		you: "jij",
	},
	loading: {
		text: "Een ogenblik geduld aub",
	},
	notification: {
		title: "Nieuwe melding",
	},
	format: {
		"trailing-hour": "{{value}}u",
	},
};

export default common;
