import Button from "src/components/Button";
import withTranslation, {
	Translation,
} from "src/components/hoc/withTranslation";
import DEFAULT_JOB_OFFERS from "src/components/tools/job-offer-converter-small/data";

interface JobOfferDefaultTabsProps extends Translation {
	value: string;
	onChange: (value: string) => void;
	children?: any;
}

const JobOfferDefaultTabs = ({
	t,
	value,
	onChange,
	children,
}: JobOfferDefaultTabsProps) => {
	return (
		<div className="flex flex-col gap-3">
			<p className="opacity-70"> {t("title")}</p>
			<div className="flex items-center gap-2 flex-wrap">
				{Object.values(DEFAULT_JOB_OFFERS).map((item) => {
					return (
						<Button
							xsmall
							key={item.name}
							onClick={() => {
								onChange(item.value);
							}}
							type={item.value === value ? "dark" : "border"}
						>
							{item.name}
						</Button>
					);
				})}
				{children}
			</div>
		</div>
	);
};

JobOfferDefaultTabs.locale = {
	nl: {
		title: "Standaard vacatures",
	},
	en: {
		title: "Default job offers",
	},
};

export default withTranslation(JobOfferDefaultTabs);
