import useNavigateModal from "src/hooks/useNavigateModal";
import Button from "../Button";
import useTenant from "src/hooks/api/services/tenants/useTenant";
import useAuth from "src/hooks/selectors/useAuth";

const SearchButton = () => {
	const { tenant } = useTenant();
	const auth = useAuth();
	const navigateModal = useNavigateModal();

	// useEffect(() => {
	// 	const handleKeyPress = (e: any) => {
	// 		if ((e.metaKey || e.ctrlKey) && e.key === "p") {
	// 			// Prevent the default behavior of Control + J in this case the webbrowser
	// 			e.preventDefault();

	// 			navigateModal("search");
	// 		}
	// 	};

	// 	document.addEventListener("keydown", handleKeyPress);

	// 	return () => {
	// 		document.removeEventListener("keydown", handleKeyPress);
	// 	};
	// 	// eslint-disable-next-line react-hooks/exhaustive-deps
	// }, []);

	if (auth.type === "student" && tenant.slug !== "odylyt") {
		return null;
	}

	return (
		<Button
			type="transparent"
			iconOnly
			icon="fa fa-search"
			onClick={() => navigateModal("search")}
		/>
	);
};

export default SearchButton;
