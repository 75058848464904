import { combineReducers, createStore, applyMiddleware } from "redux";
import thunk from "redux-thunk";

import authReducer from "./auth";
import alertReducer from "./alert";
import notificationsReducer from "./notifications";
import filterReducer from "./employee/filters";
import supportReducer from "src/redux/support";
import modulesReducer from "src/redux/modules";
import discountReducer from "src/redux/discount";
import signatureReducer from "src/redux/signature";
import issuerReducer from "src/redux/issuer";
import offerReducer from "src/redux/offer";
import offerRegistrationReducer from "src/redux/offerRegistration";
import taskFormReducer from "src/redux/taskForm";
import globalReducer from "src/redux/global";
import languageReducer from "src/redux/language";
import studentPoolReducer from "src/redux/studentPool";

const rootReducer = combineReducers({
	auth: authReducer,
	alert: alertReducer,
	notifications: notificationsReducer,
	filter: filterReducer,
	support: supportReducer,
	modules: modulesReducer,
	discount: discountReducer,
	signature: signatureReducer,
	issuer: issuerReducer,
	offer: offerReducer,
	offerRegistration: offerRegistrationReducer,
	taskForm: taskFormReducer,
	global: globalReducer,
	language: languageReducer,
	studentPool: studentPoolReducer,
});

const store = createStore(rootReducer, applyMiddleware(thunk));

export default store;
