const forms = {
	"edit-profile-picture": {
		title: "Edit Profile Picture",
		"choose-different": "Choose a Different Picture",
		edit: "Edit Profile Picture",
	},
	comment: {
		placeholder: "Type a Comment",
	},
	"address-form": {
		title: "Location",
		home: "This task can be performed at <1>Home</1>",
		location: "This task will be performed at <1>{{location}}</1>",
		street: "Street",
		"house-number": "House Number",
		zipcode: "Zipcode",
		city: "City",
		required: "Required",
		"next-step": "Next",
	},
	"calculate-service-fee": {
		title: "Calculate Service Fee",
		"not-enough-credit":
			"You don't have enough credit on your account, you still need <1>{{fee}}</1> service fee. <2>Click here to top up your service fee</2>",
		free: "Since you haven't placed an order yet, you can place an order up to {{price}} for free. A fee of {{rate}}% will be charged on the remaining amount",
		description_one:
			"If you want to place an order for {{budget}} for 1 professional, you will pay a service fee of {{fee}}",
		description_other:
			"If you want to place an order for {{budget}} per professional for {{count}} professionals, you will pay a service fee of {{fee}}",
		gift: "You will receive this task as a gift 🎁",
	},
	"answer-form": {
		answer: "Answer",
		is_right: "Correct Answer",
	},
	"question-form": {
		question: "Question",
		answer: {
			title: "Do you want to test the professional on their knowledge?",
			description:
				"Enter a few answers and mark the answer(s) that is correct",
			add: "Add Answer",
			label: "Answer {{count}}",
		},
	},
	"review-form": {
		title: "How did the task go?",
		"title-cancel": "Sorry the task was not completed",
		confirm: {
			title: "Are you sure?",
			description: "You are about to give a 0 rating",
		},
		cancel: {
			description:
				"Did the professional not show up? Or was the task not performed correctly? Indicate below what went wrong. A {{tenant.name}} employee will then contact you and arrange for the task refund.",
		},
		score: "5 = very good, 0 = very bad",
		description: "Description",
		rating: "Rating",
		skills: {
			label: "Does the professional excel in some skills?",
			description:
				"It helps the professional when you give the skills a like. You can only like a professional once.",
		},
		"show-switch": {
			label: "Show Review",
			"sub-label":
				"Do you want to show this review on the professional's profile",
		},
		buttons: {
			cancel: "Cancel",
			save: "Save",
		},
	},
	"advertise-task": {
		title: "Promotion",
		description:
			"More chance of a match? For <1>{{price}}</1> we advertise the task",
	},
	"budget-form": {
		title: "Task value",
		tabs: {
			"pay-per-hour": "Price per hour",
			"fixed-fee": "Fixed fee",
		},
		"alert-all":
			"There are already registrations or payment has already been made for the task, so you cannot change the task value and number of professionals",
		"alert-budget":
			"There are already registrations or payment has already been made for the task, so you cannot change the task value",
		"amount-of-students": {
			help: "Do you need 20 people for your task? Then fill in 20 here",
			label: "How many professionals do you need",
		},
		budget: {
			help: "This is the amount the professional gets on the account",
			label: "Task value",
		},
		shifts: "The task value is automatic generated based on the shifts",
	},
	"delete-task-form": {
		alert: "It is not possible to delete a task when it already has registrations",
		title: "Delete task",
		description: "When you delete your task it is no longer found.",
	},
	"description-task-form": {
		title: "Task description",
		"task-title": "Title",
		description: {
			label: "Description",
			help: "For example: Required education level, work experience, desired skills and clear description of the task",
			placeholder: "Describe the task as extensively as possible.",
		},
		category: {
			label: "Category",
			manual: "Doesn't your category appear? Fill it in manually",
		},
	},
	"gallery-form": {
		label: "Images (optional)",
	},
	"interview-form": {
		title: "Application form",
		"title-badge": "New",
		description:
			"Ask the contractor some questions, this can be free input or a multiple choice question.",
		instruction:
			"If you leave the questions blank, applications will be made based on motivation.",
		"add-question": "Add question",
		"question-label": "Question {{count}}",
	},
	"student-pool-form": {
		title: "Invite professionals",
		"only-switch": {
			label: "Task only for professional pool",
			"sub-label": "Invite everyone in your professional pool",
		},
		"connection-dropdown": {
			label: "Invite professional directly",
			placeholder: "Search in your own professional pool",
		},
	},
	"summary-form": {
		title: "Summary",
		info: {
			title: "We have received all information",
			description:
				"Check the information and share your task with our professionals",
		},
		finance: {
			title: "Finance",
			// "amount-of-students": "Number of professionals",
			// budget: "Task value",
			// "estimated-hourly-rate": "Estimated hourly rate",
			// "per-hour": " per hour",
			// "total-service-fee": "Total placement fee",
			// "ad-cost": "Advertising cost",
			// "total-cost": "Total cost",
		},
		details: {
			title: "Details",
			"start-date": "Start date",
			"end-date": "End date",
			date: "Date",
			"estimated-duration": "Estimated duration",
			"estimated-duration-end": "hours",
			invited: "Invited",
			"invited-all": "Invite professional pool",
		},
		promo: {
			christmas: {
				title: "Donate a christmas gift 🎄🎅",
				label: "Donate a gift",
				more: "More information about the charity",
			},
		},
	},
	"target-form": {
		title: "Start and end date",
		anonymous: "Hide my company name",
		private: {
			label: "Do not promote task",
			"sub-label": "So not all our professionals can see your job",
		},
		"date-range": {
			label: "Start and end date",
			"error-date-before": "The start date cannot be before the end date",
		},
		"start-date": {
			label: "Start date",
			"error-date-before": "The start date cannot be before the end date",
		},
		"end-date": {
			label: "End date",
		},
		"estimated-duration": {
			label: "Estimated duration (in hours)",
			help: "How long do you think the professional will be working on the task",
		},
		category: {
			label: "Category",
			manual: "Doesn't your category appear? Fill it in manually",
		},
	},
	"company-contact-form": {
		alert: {
			title: "Contact Information Updated",
			description:
				"Your contact information has been successfully updated",
		},
		telephone: "Telephone Number",
		email: "Email",
		website: "Website",
	},
	"company-form": {
		"invoice-mail":
			"Invoice email (all invoices will be sent to this email)",
		"personal-invoice-mail": "Receive personal invoice mail?",
		"company-name": "Company Name",
		"chamber-of-commerce": "Chamber of Commerce",
		street: "Street",
		"house-number": "House Number",
		zipcode: "Zipcode",
		city: "City",
		industry: "Industry",
		website: "Website",
		bio: "Description",
	},
	"company-signer": {
		"alert-error": {
			title: "Something went wrong while saving",
			"description-delete": "Please try again",
			"description-put":
				"Check if all fields are filled out and try again",
		},
		"alert-success": {
			title: "Successfully updated",
			description: "Your changes have been successfully updated",
		},
		switch: {
			label: "Maker of the assignment must sign",
			tip: "The person responsible for signing contracts",
		},
		email: "Email",
		"first-name": "First Name",
		"last-name": "Last Name",
		"company-name": "Company Name",
	},
	"edit-address": {
		button: "Edit Address",
		unknown: "No address is known yet to edit",
		"complete-account": {
			description:
				"First complete your account before you can edit the information",
			button: "Complete Account",
		},
	},
	"edit-address-small": {
		title: "Residential Address",
		zipcode: "Zipcode",
		"house-number": "House Number",
	},
	"edit-bank-account": {
		button: "Edit Bank Information",
		title: "Edit Bank Information",
		description: "Orders will be paid out to this bank information",
	},
	"edit-interests": {
		placeholder: "Category...",
	},
	"edit-invoice-address": {
		switch: "Use a different invoice address than company information",
		"company-name": "Company Name",
		contact: "Contact Person (Attn)",
		street: "Street Name",
		zipcode: "Zip Code",
		"house-number": "House Number",
		city: "City",
		"alert-success": {
			title: "Your invoice address has been updated",
		},
		"alert-error": {
			title: "Error saving",
			"description-unknown": "Try again",
			"description-fields": "Check all fields",
		},
	},
	"edit-user": {
		"created-on": "Your account was created on {{email}}",
		"first-name": "First Name",
		"last-name": "Last Name",
		"date-of-birth": "Date of Birth",
		"job-title": "Job Title within the company",
		telephone: "Telephone Number",
		mobile: "Mobile Number",
		"mobile-help":
			"You will receive WhatsApp messages on this mobile number when there is a new registration",
	},
	"reset-password": {
		description:
			"To change the password you need access to <1>{{email}}</1>. You will receive an email to change the password.",
		button: "Request change",
		"alert-success": {
			title: "Success!",
			description: "You will receive an email with a change link",
		},
		"alert-error": {
			title: "Something went wrong",
		},
	},
	"subscription-feedback-form": {
		"rating-help": "5 = really good, 0 = very bad",
		periods: {
			"one-month": "1 month",
			"three-month": "3 months",
			"six-month": "6 months",
			never: "Never😢",
		},
		platform: {
			title: "How would you rate the platform?",
			improve: "What could we change to make it a 5",
		},
		contact: {
			title: "How would you rate the contact with {{tenant.code}}?",
			improve: "What could we change to make it a 5",
		},
		usage: "When will you be coming back?",
		reason: "What is the reason of pausing?",
	},
	"subtasks-form": {
		title: "Shifts",
		description: "Shift descriptions are based on the task description",
		buttons: {
			next: "Next",
			skip: "Skip",
		},
		form: {
			"min-age": "Min. age",
			shifts: {
				title: "Setup shifts",
				add: "Add shift",
			},
		},
	},
	"subtask-form": {
		form: {
			title: "Shift title",
			budget: "Shift value",
			"budget-per-hour": "Hour rate",
			amount: "Amount of professionals",
			"start-date": "Start date & time",
			"end-date": "End date & time",
		},
		detail: "The shift is from {{start}} till {{end}}, total amount of hours: {{hour}}",
		"average-hour-rate": "Average hour rate: {{price}}",
		errors: {
			"end-before-start": "The end date is before the start date",
		},
	},
};

export default forms;
