const shareNetwork = {
	"index-view": {
		title: "Nodig je netwerk uit",
		tutorial: {
			title: "Hoe werkt het referral programma",
		},
		heading: {
			title: "Meld bedrijven aan en verdien tot € 275 per bedrijf",
			description:
				"Wanneer een bedrijf zich aanmeldt krijg je € 25. Sluit het bedrijf daarna een jaar abonnement af, dan krijg je nog eens €250.",
		},
	},
};
export default shareNetwork;
