import withTranslation, {
	Translation,
} from "src/components/hoc/withTranslation";
import Field from "src/components/field/Field";
import { ApiTaskRepeatInterval } from "src/api/types";

interface IntervalDropdownProps extends Translation {
	label?: string;
	value?: ApiTaskRepeatInterval;
	onChange: (value?: ApiTaskRepeatInterval) => void;
	placeholder?: string;
}

const IntervalDropdown = ({
	t,
	label,
	placeholder,
	value,
	onChange,
}: IntervalDropdownProps) => {
	return (
		<Field.Select
			wrapper={{
				label,
				layout: "horizontal",
			}}
			{...{ onChange, placeholder, value }}
			options={["DAILY", "WEEKLY", "MONTHLY", "YEARLY"].map((key) => ({
				label: t(`options.${key}`),
				value: key,
			}))}
		/>
	);
};

IntervalDropdown.locale = {
	nl: {
		placeholder: "Wanneer",
		options: {
			DAILY: "Dagelijks",
			WEEKLY: "Wekelijks",
			MONTHLY: "Maandelijks",
			YEARLY: "Jaarlijks",
		},
	},
	en: {
		placeholder: "When",
		options: {
			DAILY: "Daily",
			WEEKLY: "Weekly",
			MONTHLY: "Monthly",
			YEARLY: "Yearly",
		},
	},
};

export default withTranslation(IntervalDropdown);
