import { Route } from "react-router-dom";
import ProtectedRoute from "src/routes/middlewares/ProtectedRoute";
import DashboardTalentDetailView from "src/views/authenticated/talents/TalentDetailView";
import DashboardTalentsView from "src/views/authenticated/talents/TalentsView";

const TalentsRoutes = [
	<Route
		element={
			<ProtectedRoute type="company" guard={["talentboard"]}>
				<DashboardTalentsView />
			</ProtectedRoute>
		}
		path="/talents"
		key="/talents"
	/>,
	<Route
		element={
			<ProtectedRoute type="company" guard={["talentboard"]}>
				<DashboardTalentDetailView />
			</ProtectedRoute>
		}
		path="/talents/:registration/show"
		key="/talents/:registration/show"
	/>,
];

export default TalentsRoutes;
