import { useTranslation } from "react-i18next";
import addNotification from "react-push-notification";
import useAuth from "src/hooks/selectors/useAuth";
import useSocketListener from "src/hooks/sockets/useSocketListener";
import { decrypt } from "src/lib/socket";
import { getAdminUrl } from "src/lib/urls";

export default function useAlertListener() {
	const { t } = useTranslation("common");
	const auth = useAuth();
	useSocketListener(auth?.id ? `auth.${auth?.hid}.alert` : null, (res) => {
		if (typeof res?.data === "string") {
			const data = decrypt(res.data, auth.key);
			if (data?.excerpt) {
				addNotification({
					title: t("notification.title"),
					message: data.excerpt,
					theme: "darkblue",
					duration: 8000,
					onClick: () => {
						const path = data?.object?.url?.path;
						if (path) {
							window.open(getAdminUrl(path), "_blank");
						}
					},
					native: true, // when using native, your OS will handle theming.
				});
			}
		}
	});
}
