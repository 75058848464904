import { useEffect } from "react";
import JobOfferConverterSmall from "src/components/tools/job-offer-converter-small/JobOfferConverterSmall";
import { usePollIframeHeight } from "src/hooks/useIframeEvents";

const JobOfferConverterView = () => {
	usePollIframeHeight();

	useEffect(() => {
		localStorage.setItem("referral_type", "JOB_OFFER_CONVERTER");
	}, []);

	return (
		<div id="tools" className="flex flex-col flex-1">
			<JobOfferConverterSmall />
		</div>
	);
};

export default JobOfferConverterView;
