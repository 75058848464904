import React, { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import ExernalSignatureCanvas from "react-signature-canvas";
import { ApiSignature, LayoutVariants } from "src/api/types";
import Button from "src/components/Button";
import usePost from "src/hooks/rest/usePost";
import useSignature from "src/hooks/selectors/useSignature";
import useAlert from "src/hooks/useAlert";
import useElementSize from "src/hooks/useElementSize";

interface SignatureCanvasProps {
	variant: LayoutVariants;
	onSubmit: (data: ApiSignature) => void;
}

const SignatureCanvas = ({ onSubmit }: SignatureCanvasProps) => {
	const { t } = useTranslation(["envelope", "buttons"]);
	const { ref, size } = useElementSize();
	const canvas = useRef() as any;
	const [sendAlert] = useAlert();
	const [hasSigned, setHasSigned] = useState(false);
	const [createSignature, loading] = usePost(`/signatures`);
	const { setSelectedSignature } = useSignature();

	const handleSubmit = () => {
		if (!hasSigned) return false;
		let data = "";
		if (canvas?.current) {
			data = canvas.current.toDataURL("image/png");
		}
		createSignature({
			image: data,
		})
			.then((res) => {
				handleClear();
				setSelectedSignature(res.data);
				onSubmit(res.data);
			})
			.catch(() => {
				sendAlert({
					type: "error",
					title: t("error.title", {
						keyPrefix: "signature-canvas",
						ns: "envelope",
					}),
					text: t("error.text", {
						keyPrefix: "signature-canvas",
						ns: "envelope",
					}),
				});
			});
	};

	const handleClear = () => {
		if (canvas?.current) {
			canvas.current.clear();
		}
		setHasSigned(false);
	};

	return (
		<div className="flex flex-col gap-3">
			<strong>
				{t("title", {
					keyPrefix: "signature-canvas",
					ns: "envelope",
				})}
			</strong>
			<div
				className={`w-full flex-1 rounded-md relative block h-[200px]`}
				{...{ ref }}
			>
				{loading && (
					<div className="absolute bg-[rgba(0,0,0,0.2)] flex justify-center items-center left-0 top-0 right-0 bottom-0">
						<span className="base-loader"></span>
					</div>
				)}
				{!loading && (
					<ExernalSignatureCanvas
						ref={canvas}
						// penColor={theme === "light" ? "black" : "white"}
						penColor="dark"
						onEnd={() => setHasSigned(true)}
						canvasProps={{
							width: size.width,
							height: 200,
							className: "sigCanvas bg-white rounded-md",
							style: {
								border: "2px solid #F5F5F5",
							},
						}}
					/>
				)}
			</div>
			<div
				onClick={handleClear}
				className={`flex items-center gap-2 transition-all ${
					!hasSigned
						? "cursor-not-allowed opacity-20"
						: "cursor-pointer"
				}`}
			>
				<i className="far fa-sync"></i>
				<span>
					{t("retry", {
						ns: "buttons",
					})}
				</span>
			</div>
			<div className="flex flex-col">
				<Button
					type="primary"
					disabled={!hasSigned}
					onClick={handleSubmit}
					className="flex-1"
				>
					<i className="fas fa-check mr-2"></i>
					{t("save", {
						ns: "buttons",
					})}
				</Button>
			</div>
		</div>
	);
};

export default SignatureCanvas;
