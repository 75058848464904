import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Layout from "src/components/layout/Layout";
import OfferVisibilityForm from "src/components/offer/forms/edit/OfferVisibilityForm";
import OfferTitleDescriptionForm from "src/components/offer/forms/edit/OfferTitleDescriptionForm";
import useEditOffer from "src/hooks/api/useEditOffer";
import OfferEditSaveButton from "src/components/offer/forms/edit/OfferEditSaveButton";
import OfferBudgetForm from "src/components/offer/forms/edit/OfferBudgetForm";
import OfferEditLoading from "src/components/offer/forms/edit/OfferEditLoading";
import OfferTagsForm from "src/components/offer/forms/edit/OfferTagsForm";
import { useTranslation } from "react-i18next";

export default function OffersEditView() {
	const { t } = useTranslation("offer-view", {
		keyPrefix: "offer-edit-view",
	});
	const params = useParams() as any;
	const { offer, fetching, fetchOffer } = useEditOffer(params.id);
	const [open, setIsOpen] = useState("description") as any;
	useEffect(() => {
		fetchOffer();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<Layout
			breadcrumb={[
				{
					to: "/offers",
					label: t("breadcrumb.home"),
					isHome: true,
				},
				{
					to: `/offers/${offer?.slug}/show`,
					label: offer?.title,
				},
			]}
			title={t("title")}
		>
			{fetching && <OfferEditLoading />}
			{offer?.id && !fetching && (
				<>
					<Layout.Header backUrl="/offers" title={offer?.title}>
						{offer?.id && <OfferEditSaveButton id={offer?.id} />}
					</Layout.Header>

					<div className="container-small flex flex-col gap-4">
						<OfferTitleDescriptionForm
							isOpen={open === "description"}
							toggleOpen={() =>
								setIsOpen(
									open === "description"
										? undefined
										: "description"
								)
							}
							id={offer.id}
						/>
						<OfferVisibilityForm
							isOpen={open === "visibility"}
							toggleOpen={() =>
								setIsOpen(
									open === "visibility"
										? undefined
										: "visibility"
								)
							}
							id={offer.id}
						/>
						<OfferTagsForm
							isOpen={open === "tags"}
							toggleOpen={() =>
								setIsOpen(open === "tags" ? undefined : "tags")
							}
							id={offer.id}
						/>
						<OfferBudgetForm
							isOpen={open === "budget"}
							toggleOpen={() =>
								setIsOpen(
									open === "budget" ? undefined : "budget"
								)
							}
							id={offer.id}
						/>
					</div>
				</>
			)}
		</Layout>
	);
}
