import { useEffect, useState } from "react";
import { Address } from "src/api/types";
import Button from "src/components/Button";
import AddressForm from "src/components/forms/AddressForm/v2/AddressForm";
import withTranslation, {
	Translation,
} from "src/components/hoc/withTranslation";
import useEditProfile from "src/hooks/api/useEditProfile";
import useAuth from "src/hooks/selectors/useAuth";
import useModal from "src/hooks/useModal";

interface OnboardingAddressModalProps extends Translation {
	children?: any;
}

const OnboardingAddressModal = ({ t }: OnboardingAddressModalProps) => {
	const auth = useAuth();
	const [update, loading] = useEditProfile();
	const [address, setAddress] = useState<Address>(auth.company.address);
	const modal = useModal();
	const disabled =
		!(
			address.city &&
			address.street &&
			address.house_number &&
			address.zipcode
		) || loading;

	const onSubmit = (e?: any) => {
		if (e) e.preventDefault();
		if (disabled) return;
		update({
			company: address,
		}).then(() => {
			modal.onClose();
		});
	};

	useEffect(() => {
		modal.setTitle(t("title"));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<form {...{ onSubmit }}>
			<AddressForm
				{...{ address }}
				defaultMode={!disabled ? "manual" : "search"}
				className="w-full max-w-[360px]"
				onChange={setAddress}
			>
				{(mode) =>
					mode === "manual" && (
						<Button
							submit
							{...{ disabled, loading }}
							type="dark"
							className="flex-1"
						>
							{t("save")}
						</Button>
					)
				}
			</AddressForm>
		</form>
	);
};

OnboardingAddressModal.locale = {
	nl: {
		title: "Adres",
		save: "Opslaan",
	},
	en: {
		title: "Address",
		save: "Save",
	},
};

export default withTranslation(OnboardingAddressModal);
