import axios from "axios";
import { ComponentProps, useState } from "react";
import Button from "src/components/Button";
import Card from "src/components/Card";
import Input from "src/components/form/Input";
import withTranslation, {
	Translation,
} from "src/components/hoc/withTranslation";
import Icon from "src/components/icon/Icon";
import useOnboarding from "src/hooks/api/services/auth/useOnboarding";
import useAuth from "src/hooks/selectors/useAuth";
import { useUpdateEffectDebounce } from "src/hooks/useDebounce";
import { cn } from "src/lib/utils";

interface WebsiteProps extends Translation {
	children?: any;
}

const Website = ({
	t,
	children,
	className,
	onSubmit,
	...rest
}: WebsiteProps & ComponentProps<"div">) => {
	const auth = useAuth();
	const { actions, status } = useOnboarding();
	const name = auth?.company?.name?.toLowerCase();
	const [isGettingInformation, setIsGettingInformation] = useState(false);
	const [information, setInformation] = useState<{
		website: string;
		description?: string;
	}>();
	const [isError, setIsError] = useState(false);
	const [website, setWebsite] = useState("");
	const disabled =
		status !== "idle" ||
		!information?.website ||
		isGettingInformation ||
		isError;

	const getWebsiteInformation = async (website: string) => {
		setIsGettingInformation(true);

		if (!website) {
			setInformation(undefined);
			setIsError(false);
			setIsGettingInformation(false);
			return;
		}

		try {
			const { data } = await axios
				.post(`/client/data/website/information`, {
					website,
				})
				.then(({ data }) => data);
			setInformation(data);
		} catch (error) {
			setIsError(true);
			setInformation(undefined);
		}
		setIsGettingInformation(false);
	};

	useUpdateEffectDebounce(website, 500, getWebsiteInformation);

	const handleSubmit = () => {
		if (disabled) return;
		actions.update({ information }).then(() => {
			actions.setStep("completed");
		});
	};

	return (
		<div
			{...rest}
			className={cn("flex flex-col flex-1 gap-4 w-full", className)}
		>
			<div className="w-full flex flex-col gap-4 py-4 max-w-md mx-auto justify-center items-center flex-1 rounded-md">
				<p className="text-center">{t("description")}</p>
				<div className="flex gap-2 items-center">
					<Input
						autoFocus
						className="max-w-[320px]"
						name="website"
						placeholder={name || t("placeholder")}
						onChange={(_, value) => setWebsite(value)}
						loading={isGettingInformation}
					/>
				</div>
				{isError && (
					<div className="flex bg-red-200 border border-red rounded-md p-3 text-red">
						<small>{t("error")}</small>
					</div>
				)}
				{information?.website && (
					<Card contentClassName="gap-2">
						<Icon
							className="bg-primary-200 hover:bg-primary border border-primary"
							icon={Icon.getFontAwesome("fa-check")}
						/>
						<div className="flex flex-col gap-1">
							<strong>{information.website}</strong>
							{information?.description && (
								<small>{information?.description}</small>
							)}
						</div>
					</Card>
				)}
			</div>
			<div className="flex flex-col mt-8 sticky bottom-4">
				<Button
					onClick={handleSubmit}
					{...{ disabled }}
					loading={status === "updating"}
					type="primary"
				>
					{t("continue")}
				</Button>
			</div>
		</div>
	);
};

Website.locale = {
	nl: {
		continue: "Doorgaan",
		placeholder: "www.google.nl",
		description:
			"We maken gebruik van je website om extra gegevens over je bedrijf te verzamelen, wat resulteert in een verbeterde gebruikerservaring.",
		error: "Weet je zeker dat je website correct is? We kunnen namelijk geen informatie vinden.",
	},
	en: {
		continue: "Continue",
		placeholder: "www.google.com",
		description:
			"We use your website to gather additional information about your company, resulting in an enhanced user experience.",
		error: "Are you sure your website is correct? We couldn't find any information.",
	},
};

export default withTranslation(Website);
