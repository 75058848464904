import { ComponentProps } from "react";
import { useSkillSelector } from "src/components/form/skill-selector/Provider";
import { ToggleGroup, ToggleGroupItem } from "src/components/ui/toggle-group";
import { cn } from "src/lib/utils";

interface ResultsProps extends ComponentProps<"div"> {
	filter?: (item: any) => boolean;
	variant?: "loading" | "display";
	itemClassName?: string;
}

const Results = ({
	className,
	filter,
	variant,
	itemClassName,
}: ResultsProps) => {
	const { value, skills, onChange, status, searchMode, search } =
		useSkillSelector();
	if (status === "loading" || variant === "loading") {
		return (
			<ToggleGroup type="multiple" {...{ className }}>
				{new Array(10).fill("").map((item, index) => (
					<ToggleGroupItem
						key={`placeholder-${index}`}
						value={index.toString()}
						className={cn(
							"min-w-[64px] animate-pulse",
							itemClassName
						)}
					>
						{item}
					</ToggleGroupItem>
				))}
			</ToggleGroup>
		);
	}

	if (variant === "display") {
		return (
			<div className={cn("flex items-center gap-2 flex-wrap", className)}>
				{skills
					.filter(filter || (() => true))
					.filter(
						searchMode === "manual"
							? (item) =>
									item.name
										.toLowerCase()
										.includes((search || "").toLowerCase())
							: (item) => true
					)
					.map((item) => (
						<div
							key={`skill-selector-item-${item.name}`}
							className={cn(
								"rounded-md text-sm font-medium transition-colors text-left py-2 px-3",
								"focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring border border-transparent",
								"hover:border-border",
								"bg-dark text-dark-foreground border-dark",
								itemClassName
							)}
						>
							{item.name}
						</div>
					))}
			</div>
		);
	}

	return (
		<ToggleGroup
			type="multiple"
			value={value.map((item) => item.slug)}
			{...{ className }}
		>
			{skills
				.filter(filter || (() => true))
				.filter(
					searchMode === "manual"
						? (item) =>
								item.name
									.toLowerCase()
									.includes((search || "").toLowerCase())
						: () => true
				)
				.map((item) => (
					<ToggleGroupItem
						value={item.slug}
						key={item.slug}
						className={itemClassName}
						onClick={() => {
							if (!onChange) return;

							if (value.find((row) => row.slug === item.slug)) {
								onChange(
									value.filter(
										(row) => row.slug !== item.slug
									)
								);
							} else {
								onChange([...value, item]);
							}
						}}
					>
						{item.name}
					</ToggleGroupItem>
				))}
		</ToggleGroup>
	);
};

export default Results;
