import { ApiRegistrationTimesheet, ApiTimesheet } from "src/api/types";
import useTimesheets from "src/hooks/api/services/timesheets/useTimesheets";
import { useEffect, useState } from "react";
import moment from "moment";
import { useUpdateEffectDebounce } from "src/hooks/useDebounce";
import useUnreads from "src/hooks/api/useUnreads";
const DATETIME_FORMAT = "YYYY-MM-DD HH:mm";

type Status = "idle" | "saving";

type State = {
	timesheet: ApiRegistrationTimesheet;
	sheet?: ApiTimesheet;
	status: Status;
	actions: {
		change: (key: string, value: any) => ApiRegistrationTimesheet;
		save: (sheet: ApiTimesheet) => Promise<any>;
	};
};

type Options = {
	timesheet: ApiRegistrationTimesheet;
	autosave?: boolean;
};

function useTimesheet(options: Options): State {
	const { fetchUnreads } = useUnreads();
	const [status, setStatus] = useState<Status>("idle");
	const { timesheets, actions, api } = useTimesheets({
		task_id: options.timesheet.task?.hid || options.timesheet.links.task,
	});
	const [timesheet, setTimesheet] = useState(
		timesheets.find((item) => item.id === options.timesheet.id) ||
			options.timesheet
	);
	const registrationId = options.timesheet.id;
	const sheet: ApiTimesheet = timesheet?.current || {
		id: null,
		// start: timesheet.end_date
		// 	? moment(timesheet.start_date).format("HH:mm")
		// 	: null,
		break: 0,
	};

	useEffect(() => {
		const value = timesheets.find(
			(item) => item.id === options.timesheet.id
		);
		if (value?.id) {
			setTimesheet(value);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [timesheets]);

	useUpdateEffectDebounce(sheet, 500, (value) => {
		if (value.start && value.end && options.autosave) {
			save(value);
		}
	});

	const getTotal = (sheet: ApiTimesheet) => {
		let total = moment
			.duration(
				moment(sheet.end, "HH:mm").diff(moment(sheet.start, "HH:mm"))
			)
			.asHours();

		if (total < 0) {
			total = moment
				.duration(
					moment(sheet.end, "HH:mm")
						.add(1, "day")
						.diff(moment(sheet.start, "HH:mm"))
				)
				.asHours();
		}

		return total - (sheet.break || 0);
	};

	const formatSheet = (sheet: ApiTimesheet) => {
		if (sheet.start && sheet.end) {
			sheet.total = getTotal(sheet);
		}
		return sheet;
	};

	const change = (key: string, value: any) => {
		actions.set((state: any) => ({
			...state,
			list: timesheets.map((item) => {
				if (item.id === timesheet.id) {
					return {
						...item,
						current: formatSheet({
							...sheet,
							[key]: value,
						}),
					};
				}
				return item;
			}),
		}));

		return timesheet;
	};

	const setCurrent = (value: any) => {
		actions.set((state: any) => ({
			...state,
			list: timesheets.map((item) => {
				if (item.id === timesheet.id) {
					return {
						...item,
						current: {
							...item.current,
							...value,
						},
					};
				}
				return item;
			}),
		}));

		return timesheet;
	};

	const formatTimeToDate = (str: string) => {
		let [hours, minutes] = str.split(":");

		return moment(timesheet.start_date)
			.set("hours", Number(hours))
			.set("minutes", Number(minutes))
			.format(DATETIME_FORMAT);
	};

	const save = async (sheet: ApiTimesheet) => {
		const value = { ...sheet };
		if (!value.start || !value.end) return;
		setStatus("saving");

		// let [hours, minutes] = value.start.split(":");
		value.start = formatTimeToDate(value.start);
		value.end = formatTimeToDate(value.end);

		if (
			moment
				.duration(moment(value.end).diff(moment(value.start)))
				.asHours() < 0
		) {
			value.end = moment(value.end).add(1, "day").format(DATETIME_FORMAT);
		}

		try {
			const { data } = sheet.id
				? await api.patch(`/${sheet.id}`, {
						registration_id: registrationId,
						...value,
				  })
				: await api.post("", {
						registration_id: registrationId,
						...value,
				  });

			setStatus("idle");
			setCurrent(data?.data);
			setTimesheet({
				...timesheet,
				current: data?.data,
			});
			if (!options.autosave) {
				fetchUnreads();
			}
			return data;
		} catch (error) {
			setStatus("idle");
			throw error;
		}
	};

	return {
		timesheet: timesheet || options.timesheet,
		sheet,
		status,
		actions: {
			change,
			save,
		},
	};
}

export default useTimesheet;
