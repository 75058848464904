import { useNavigate } from "react-router-dom";
import { ApiTaskKanban } from "src/api/types";
import Skeleton from "src/components/Skeleton";
import Field from "src/components/field/Field";
import TaskDeadlineText from "src/components/tasks/TaskDeadlineText";
import TaskStatus from "src/components/tasks/TaskStatus";
import TaskKanbanCard from "src/components/tasks/kanban/TaskKanbanCard";
import useBackUrl from "src/hooks/useBackUrl";
import useNavigateModal from "src/hooks/useNavigateModal";
import { cn } from "src/lib/utils";

export type TaskListItemElement = "status" | "description" | "date";

interface TaskListItemProps {
	task: ApiTaskKanban;
	onUpdate?: (id: string | number, data: any) => Promise<any>;
	elements?: TaskListItemElement[];
	className?: string;
}

const TaskListItem = ({
	task,
	onUpdate,
	elements = ["status", "description", "date"],
	className,
}: TaskListItemProps) => {
	const navigateModal = useNavigateModal();
	const navigate = useNavigate();
	const back = useBackUrl();

	return (
		<TaskKanbanCard
			className="p-0 bg-transparent border-0 shadow-none"
			contentClassName="p-0"
			onClick={() => {}}
			{...{ task }}
		>
			<div
				onClick={(e: any) => {
					e.stopPropagation();
					if (task.type !== "INTERNAL") {
						navigate(`/tasks/${task.hid}/show?${back}`);
						return;
					}
					navigateModal(`./modals/tasks/${task.hid}`);
				}}
				className={cn("flex items-center gap-3", className)}
			>
				{elements.includes("status") && (
					<div className="w-28 flex">
						{onUpdate ? (
							<Field.Status
								theme={{
									trigger: {
										className: "p-0 border-0",
									},
								}}
								settings={{ hideArrow: true }}
								disabled={task.type !== "INTERNAL"}
								value={task.status}
								onChange={(value) => {
									onUpdate(task.id, {
										status: value,
									});
								}}
							/>
						) : (
							<TaskStatus status={task.status} />
						)}
					</div>
				)}
				{elements.includes("description") && (
					<p className="font-regular leading-[130%] flex-1">
						{task.title}
					</p>
				)}
				{elements.includes("date") && task.end_date && (
					<TaskDeadlineText date={task.end_date} />
				)}
			</div>
		</TaskKanbanCard>
	);
};

const Loader = () => {
	return (
		<div className="flex items-center gap-3">
			<Skeleton className="w-28 h-8" />
			<div className="flex-1">
				<Skeleton className="w-1/2 h-4" />
			</div>
			<Skeleton className="w-12 h-4" />
		</div>
	);
};

TaskListItem.Loader = Loader;

export default TaskListItem;
