export const ERROR_GIFS: string[] = [
	"https://media.giphy.com/media/BsQAVgY6ksvIY/giphy.gif",
	"https://media.giphy.com/media/3ohzdYJK1wAdPWVk88/giphy.gif",
	"https://media.giphy.com/media/26tPtM8Arb1nyc1i0/giphy.gif",
	"https://media.giphy.com/media/3ohfFBfnd33ciPcozm/giphy.gif",
];
export const SUCCESS_GIFS: string[] = [
	"https://media.giphy.com/media/KEVNWkmWm6dm8/giphy.gif",
	"https://media.giphy.com/media/KEVNWkmWm6dm8/giphy.gif",
	"https://media.giphy.com/media/T0WzQ475t9Cw/giphy.gif",
	"https://media.giphy.com/media/3ohzdIuqJoo8QdKlnW/giphy.gif",
	"https://media.giphy.com/media/sBLcw5Ic4QUTK/giphy.gif",
	"https://media.giphy.com/media/l0Ex6kAKAoFRsFh6M/giphy.gif",
	"https://media.giphy.com/media/uFtywzELtkFzi/giphy.gif",
	"https://media.giphy.com/media/12DBG3iuBrqfJu/giphy.gif",
	"https://media.giphy.com/media/5fMlYckytHM4g/giphy.gif",
	"https://media.giphy.com/media/8wfrUCrnhez09JEjBt/giphy.gif",
	"https://media.giphy.com/media/YP258EkezKv5RSPGRI/giphy.gif",
	"https://media3.giphy.com/media/l0Iyl55kTeh71nTXy/giphy.gif",
	"https://media2.giphy.com/media/8UF0EXzsc0Ckg/giphy.gif",
	"https://media2.giphy.com/media/LdOyjZ7io5Msw/giphy.gif",
];

export const randomSuccessGif = () => {
	const src = SUCCESS_GIFS[Math.floor(Math.random() * SUCCESS_GIFS.length)];
	return src;
};

export const randomErrorGif = () => {
	const src = ERROR_GIFS[Math.floor(Math.random() * ERROR_GIFS.length)];
	return src;
};

export const getRandomGif = (type: string) => {
	if (type === "success") {
		return SUCCESS_GIFS[Math.floor(Math.random() * SUCCESS_GIFS.length)];
	}
	return ERROR_GIFS[Math.floor(Math.random() * ERROR_GIFS.length)];
};
