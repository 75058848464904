import { useTranslation } from "react-i18next";
import Card from "src/components/Card";
import Layout from "src/components/layout/Layout";
import useAuth from "src/hooks/selectors/useAuth";
import CreateReferralCode from "src/components/referral/share-network/CreateReferralCode";
import ReferralLinkPayouts from "src/components/referral/share-network/ReferralLinkPayouts";
import ReferralTutorial from "src/components/referral/share-network/ReferralTutorial";
import ShareReferralCode from "src/components/referral/share-network/ShareReferralCode";
import useTenant from "src/hooks/api/services/tenants/useTenant";

const DashboardShareNetworkView = () => {
	const { t } = useTranslation("share-network-view", {
		keyPrefix: "index-view",
	});
	const auth = useAuth();
	const { tenant } = useTenant();
	return (
		<Layout title={t("title")} container="xxl">
			{tenant.slug === "getjobsdone" && (
				<div className="flex flex-col gap-6">
					{!auth.has_referral_code && (
						<>
							<Card>
								<h2 className="mb-12">{t("tutorial.title")}</h2>
								<ReferralTutorial />
								<CreateReferralCode onComplete={console.log} />
							</Card>
						</>
					)}

					{auth.has_referral_code && (
						<>
							<div className="flex-1">
								<h2 className="text-2xl md:text-4xl text-background-foreground dark:text-white mb-4">
									{t("heading.title")}
								</h2>
								<p>{t("heading.description")}</p>
							</div>
							<Card>
								<ShareReferralCode />
							</Card>
							<Card>
								<ReferralTutorial />
							</Card>
							<Card>
								<ReferralLinkPayouts />
							</Card>
						</>
					)}
				</div>
			)}
		</Layout>
	);
};

export default DashboardShareNetworkView;
