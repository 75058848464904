import React from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import moment from "moment";
import { useTranslation } from "react-i18next";
import Button from "src/components/Button";
import DateOfBirthPicker from "src/components/form/DateOfBirthPicker";
import Input from "src/components/form/Input";
import usePatch from "src/hooks/rest/usePatch";
import { updateProfile } from "src/redux/auth";
import useAuth from "src/hooks/selectors/useAuth";
import { isValidPhoneNumber } from "src/components/form/Input/phone/utils";
import useTenant from "src/hooks/api/services/tenants/useTenant";

interface EditUserProps {
	onDone?: () => void;
	prevButton?: any;
	buttonTitle?: string;
}

const EditUser = ({ prevButton, onDone, buttonTitle }: EditUserProps) => {
	const { t } = useTranslation(["forms", "schemes", "buttons"]);
	const user = useAuth();
	const { tenant } = useTenant();
	const dispatch = useDispatch();
	const [patchProfile] = usePatch(
		user.type === "student" ? "/auth/profile" : "/auth/profile/company"
	);

	const scheme = {
		first_name: Yup.string().required(t("required")),
		last_name: Yup.string().required(t("required")),
		telephone: Yup.string()
			.test(
				"len",
				t("telephone", { ns: "schemes", length: 9 }),
				(val: any) => {
					return isValidPhoneNumber(val);
				}
			)
			.required(t("required")),
	} as {
		[key: string]: any;
	};

	if (user.type === "student" && tenant.modules.includes("extern-task")) {
		scheme["date_of_birth"] = Yup.string()
			.test(
				"minimum_dob",
				t("min-age", { ns: "schemes", age: 16 }),
				(value) => moment().diff(moment(value), "years") >= 16
			)
			.required(t("required"));
	}

	if (user.type !== "student") {
		scheme["job_title"] = Yup.string().required(t("required")).nullable();
		scheme["mobile_number"] = Yup.string()
			.nullable()
			.notRequired()
			.when("mobile_number", {
				is: (value) => value?.length,
				then: (rule: any) =>
					rule.test(
						"len",
						t("telephone", { ns: "schemes", length: 9 }),
						(val: any) => {
							return isValidPhoneNumber(val);
						}
					),
			});
	}

	return (
		<Formik
			initialValues={{
				first_name: user.first_name,
				last_name: user.last_name,
				date_of_birth: user.date_of_birth,
				telephone: user.telephone,
				job_title: user?.job_title,
				mobile_number: user?.mobile_number || undefined,
			}}
			validationSchema={Yup.object().shape(
				scheme,
				user.type === "company"
					? [["mobile_number", "mobile_number"]]
					: undefined
			)}
			onSubmit={(values: any, { setSubmitting, resetForm }) => {
				let profile = {
					...values,
					telephone: values.telephone,
				};

				if (profile.date_of_birth) {
					profile.date_of_birth = moment(
						profile.date_of_birth
					).format("YYYY-MM-DD");
				}

				patchProfile(profile)
					.then((res: any) => {
						dispatch(updateProfile(res.data));
						if (onDone) onDone();
					})
					.finally(() => setSubmitting(false));
			}}
		>
			{({
				values,
				errors,
				touched,
				handleChange,
				handleBlur,
				handleSubmit,
				isSubmitting,
			}) => (
				<form className="flex flex-col gap-4" onSubmit={handleSubmit}>
					<p>
						{t("created-on", {
							email: user.email,
							ns: "forms",
							keyPrefix: "edit-user",
						})}
					</p>
					<Input
						label={t("first-name", {
							ns: "forms",
							keyPrefix: "edit-user",
						})}
						name="first_name"
						value={values.first_name}
						valid={values.first_name && !errors.first_name}
						error={
							errors.first_name &&
							touched.first_name &&
							errors.first_name
						}
						{...{ handleChange, handleBlur }}
					/>
					<Input
						label={t("last-name", {
							ns: "forms",
							keyPrefix: "edit-user",
						})}
						name="last_name"
						value={values.last_name}
						valid={values.last_name && !errors.last_name}
						error={
							errors.last_name &&
							touched.last_name &&
							errors.last_name
						}
						{...{ handleChange, handleBlur }}
					/>

					{user.type === "student" ? (
						<>
							{tenant.modules.includes("extern-task") && (
								<DateOfBirthPicker
									label={t("date-of-birth", {
										ns: "forms",
										keyPrefix: "edit-user",
									})}
									name="date_of_birth"
									value={values.date_of_birth}
									valid={
										values.date_of_birth &&
										!errors.date_of_birth
									}
									error={errors.date_of_birth}
									{...{ handleChange, handleBlur }}
									onChange={(key, value) => {
										handleChange({
											target: {
												name: "date_of_birth",
												value,
											},
										});
									}}
								/>
							)}
						</>
					) : (
						<Input
							name="job_title"
							label={t("job-title", {
								ns: "forms",
								keyPrefix: "edit-user",
							})}
							value={values.job_title}
							valid={values.job_title && !errors.job_title}
							error={
								errors.job_title &&
								touched.job_title &&
								errors.job_title
							}
							{...{ handleChange, handleBlur }}
							onChange={(key, value) => {
								handleChange({
									target: {
										name: "job_title",
										value,
									},
								});
							}}
						/>
					)}
					<Input
						label={t("telephone", {
							ns: "forms",
							keyPrefix: "edit-user",
						})}
						name="telephone"
						type="telephone"
						value={values.telephone}
						valid={values.telephone && !errors.telephone}
						error={
							errors.telephone &&
							touched.telephone &&
							errors.telephone
						}
						{...{ handleChange, handleBlur }}
					/>

					{user.type === "company" && (
						<Input
							label={t("mobile", {
								ns: "forms",
								keyPrefix: "edit-user",
							})}
							name="mobile_number"
							help={t("mobile-help", {
								ns: "forms",
								keyPrefix: "edit-user",
							})}
							type="telephone"
							value={values.mobile_number}
							valid={
								values.mobile_number && !errors.mobile_number
							}
							error={
								errors.mobile_number &&
								touched.mobile_number &&
								errors.mobile_number
							}
							{...{ handleChange, handleBlur }}
						/>
					)}

					<div className="buttons">
						{prevButton}
						<Button
							submit
							loading={isSubmitting}
							className={`gtm-${user.type}-dashboard-mijn-profiel--button-aanpassen`}
						>
							{buttonTitle || t("edit", { ns: "buttons" })}
						</Button>
					</div>
				</form>
			)}
		</Formik>
	);
};

export default EditUser;
