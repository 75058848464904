import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { ApiRegistration } from "src/api/types";
import FlatList from "src/components/FlatList";
import RegistrationListItem from "src/components/lists/list-items/RegistrationListItem";
import useRememberGet from "src/hooks/rest/useRememberGet";
import useSocketListener from "src/hooks/sockets/useSocketListener";
import useUpdateEffect from "src/hooks/useUpdateEffect";

interface RegistrationSelectListProps {
	task?: string | number;
	params?: {
		limit: number;
		query?: string;
		state?: any;
	};
}

const RegistrationSelectList = ({
	task,
	params,
}: RegistrationSelectListProps) => {
	const location = useLocation();
	const { t } = useTranslation("lists", {
		keyPrefix: "registration-select-list",
	});
	const [result, loading, { pagination, handleSearch, setResult, fetchGet }] =
		useRememberGet(
			`registrations-${task}-${params?.state || "all"}`,
			`/tasks/${task}/registrations`,
			{
				defaultData: {
					data: [],
				},
				params: {
					...params,
					include: ["task", "user", "favorite"],
					response_type: "list",
				},
				usePagination: true,
			}
		);
	const { data } = result;

	const setItemStatus = (status: string, registration: string) => {
		setResult((res: any) => ({
			...res,
			data: res.data.map((item: ApiRegistration) => {
				if (item.hid === registration) {
					return {
						...item,
						status,
					};
				}
				return item;
			}),
		}));
	};

	useSocketListener(`tasks.${task}.registrations.status`, (res) => {
		if (res?.status && res?.registration) {
			setItemStatus(res.status, res.registration);
		}
	});

	useUpdateEffect(() => {
		if (!loading) {
			fetchGet(false);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [location]);

	return (
		<FlatList
			noInfiniteScroll
			gap={4}
			searchPlaceholder={t("search")}
			{...{ loading, pagination, handleSearch }}
			data={data || []}
			empty={{
				title: t("empty.title"),
			}}
			renderItem={(row: any) => (
				<RegistrationListItem
					registration={row}
					key={`registration-${row?.id}`}
				/>
			)}
		/>
	);
};

export default RegistrationSelectList;
