import moment from "moment";
import { useTranslation } from "react-i18next";
import { ApiPayment } from "src/api/types";
import Card from "src/components/Card";
import KeyValue from "src/components/KeyValue";
import PaymentBadge from "src/components/payment/PaymentBadge";
import { formatPrice } from "src/lib/formatters";

interface PaymentDetailCardProps {
	payment: ApiPayment;
}

const PaymentDetailCard = ({ payment }: PaymentDetailCardProps) => {
	const { t: translatedMethod } = useTranslation("payments", {
		keyPrefix: "methods",
	});
	const { t } = useTranslation("payments", {
		keyPrefix: "payment-detail-card",
	});
	return (
		<Card title={t("title")} className=" ">
			<div className="flex flex-col gap-1">
				{payment?.invoice_number && (
					<KeyValue.Small
						bold
						label={t("invoice-number")}
						text={payment.invoice_number}
					/>
				)}
				{payment?.type && (
					<KeyValue.Small
						bold
						label={t("method")}
						text={translatedMethod(payment.type)}
					/>
				)}
				<KeyValue.Small
					bold
					label={t("description")}
					text={payment.description}
				/>
				<KeyValue.Small
					bold
					label={t("price.including-vat")}
					text={formatPrice(payment.total)}
				/>
				<KeyValue.Small
					bold
					label={t("price.percentage-vat")}
					text={`${payment.btw * 100}%`}
				/>
				{payment.status && (
					<KeyValue.Small
						bold
						label={t("status")}
						text={<PaymentBadge status={payment.status} />}
					/>
				)}
				{payment.created_at && (
					<KeyValue.Small
						bold
						label={t("created-at")}
						text={moment(payment.created_at).format("DD-MM-YYYY")}
					/>
				)}

				{payment.escrow_date && payment.status !== "completed" && (
					<KeyValue.Small
						bold
						label={t("payout-at")}
						text={moment(payment.escrow_date).format("DD-MM-YYYY")}
					/>
				)}
			</div>
		</Card>
	);
};

export default PaymentDetailCard;
