import React from "react";
import UserAvatars from "src/components/UserAvatars";
import TaskMatchProfile from "src/components/tasks/TaskMatchProfile";
import TaskMatchProfiles from "src/components/tasks/TaskMatchProfiles";
import { useTaskKanban } from "src/components/tasks/kanban/TaskKanbanCard";

const TaskKanbanCardRegistrations = () => {
	const { task } = useTaskKanban();
	const match = task?.matches?.users ? task?.matches?.users[0] : null;

	if (!task?.matches?.users && !task.registrations?.users) {
		return null;
	}

	return (
		<>
			{match && task?.matches?.count === 1 && (
				<TaskMatchProfile
					avatar={{
						size: "xsmall",
					}}
					user={match}
				/>
			)}

			{task?.matches?.count && task?.matches?.count > 1 && (
				<TaskMatchProfiles users={task?.matches?.users || []} />
			)}

			{!!task.registrations?.users?.length && (
				<UserAvatars
					users={task.registrations.users}
					avatar={{
						size: "xsmall",
					}}
					overlay={
						<div className="absolute inset-0 bg-primary backdrop-blur-sm rounded-md flex justify-center items-center text-white">
							<strong>{task.registrations.count}</strong>
						</div>
					}
				/>
			)}
		</>
	);
};

export default TaskKanbanCardRegistrations;
