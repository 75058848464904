import moment from "moment";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import Button from "src/components/Button";
import Input from "src/components/form/Input";
import useTenant from "src/hooks/api/services/tenants/useTenant";
import useSubtaskForm from "src/hooks/api/tasks/useSubtaskForm";
import { formatPrice } from "src/lib/formatters";
import { SubtaskForm as SubtaskFormType } from "src/redux/taskForm";

interface SubtaskFormProps {
	task: SubtaskFormType;
}

const SubtaskForm = ({ task }: SubtaskFormProps) => {
	const { settings } = useTenant();
	const { actions } = useSubtaskForm(task?._id || task.id);
	const [visible, setVisible] = useState(true);
	const { t } = useTranslation("forms", {
		keyPrefix: "subtask-form",
	});

	return (
		<form
			onSubmit={(e) => e?.preventDefault()}
			className="flex flex-col gap-2"
		>
			<div className="flex flex-1 gap-2">
				<Input
					name="title"
					value={task.title}
					placeholder={t("form.title")}
					noMarginBottom
					className="flex-1"
					onChange={(_, value) => {
						actions.set({
							title: value,
						});
					}}
				/>
				<Button
					type="gray"
					onClick={() => setVisible(!visible)}
					iconOnly
					className="w-[46px]"
					tabIndex="-1"
				>
					{visible ? (
						<i className="fas fa-angle-up"></i>
					) : (
						<i className="fas fa-angle-down"></i>
					)}
				</Button>
				<Button
					type="gray"
					onClick={() => actions.duplicate(task)}
					iconOnly
					className="w-[46px]"
					tabIndex="-1"
				>
					<i className="fas fa-copy"></i>
				</Button>
				<Button
					type="gray"
					onClick={() => actions.remove(task._id)}
					iconOnly
					className="w-[46px]"
					tabIndex="-1"
				>
					<i className="fas fa-trash"></i>
				</Button>
			</div>

			{visible && (
				<div className="flex flex-col gap-2">
					<hr className="border-border" />
					{settings.tasks.budget_per_hour ? (
						<Input
							type="price"
							noMarginBottom
							name="budget"
							placeholder={t("form.budget-per-hour")}
							value={task.budget_per_hour}
							onChange={(_, value) => {
								actions.set({
									budget:
										(task?.estimated_duration || 0) * value,
									budget_per_hour: value,
								});
							}}
						/>
					) : (
						<Input
							type="price"
							noMarginBottom
							name="budget"
							placeholder={t("form.budget")}
							value={task.budget}
							onChange={(_, value) => {
								actions.set({
									budget: value,
								});
							}}
						/>
					)}
					<Input
						type="number"
						noMarginBottom
						name="budget"
						placeholder={t("form.amount")}
						value={task.amount_of_students}
						onChange={(_, value) => {
							actions.set({
								amount_of_students: value,
							});
						}}
					/>
					<Input
						type="date"
						withTime
						noMarginBottom
						name="start_date"
						placeholder={t("form.start-date")}
						value={task.start_date}
						onChange={(_, value) => {
							actions.set({
								start_date: value,
							});
						}}
					/>
					<Input
						type="date"
						withTime
						noMarginBottom
						name="end_date"
						placeholder={t("form.end-date")}
						value={task.end_date}
						onChange={(_, value) => {
							actions.set({
								end_date: value,
							});
						}}
					/>

					{task.end_date && task.start_date && (
						<>
							<p className="mb-0">
								{t("detail", {
									start: moment(task.start_date).format(
										"HH:mm DD MMM"
									),
									end: moment(task.end_date).format(
										"HH:mm DD MMM"
									),
									hour:
										Math.round(
											(task?.estimated_duration || 0) *
												100
										) / 100,
								})}
							</p>
							{task.budget &&
								task.estimated_duration &&
								!task.budget_per_hour && (
									<strong>
										{t("average-hour-rate", {
											price: formatPrice(
												task.budget /
													task.estimated_duration
											),
										})}
									</strong>
								)}

							{moment(task.end_date).isBefore(
								moment(task.start_date)
							) && (
								<strong className="bg-red-100 text-red rounded-md p-4">
									{t("errors.end-before-start")}
								</strong>
							)}
						</>
					)}
					<hr className="my-2 border-border" />
				</div>
			)}
		</form>
	);
};

export default SubtaskForm;
