import { motion } from "framer-motion";
import { ComponentProps, useState } from "react";
import Button from "src/components/Button";
import DEFAULTS from "src/components/forms/onboarding/elements/teams.defaults";
import withTranslation, {
	Translation,
} from "src/components/hoc/withTranslation";
import Icon from "src/components/icon/Icon";
import IconPicker from "src/components/icon/IconPicker";
import useOnboarding, {
	OnboardingTeam,
} from "src/hooks/api/services/auth/useOnboarding";
import useLanguage from "src/hooks/api/useLanguage";
import useEmoji from "src/hooks/data/useEmoji";
import { slugify } from "src/lib/slugify";
import { cn } from "src/lib/utils";

interface CreateTeamsProps extends Translation {
	children?: any;
}

const CreateTeams = ({
	t,
	children,
	className,
	onSubmit,
	...rest
}: CreateTeamsProps & ComponentProps<"form">) => {
	const { actions, onboarding, status } = useOnboarding();
	const { actions: emojiActions } = useEmoji();
	const { current } = useLanguage();
	const [team, setTeam] = useState("");
	const [icon, setIcon] = useState({
		emoji: emojiActions.getRandomEmoji(),
	});
	const [teams, setTeams] = useState<OnboardingTeam[]>(
		onboarding?.teams?.length
			? onboarding.teams.map((team: any) => ({
					name: team.name,
					icon: team.icon,
					type: "MANUAL",
			  }))
			: DEFAULTS[current].map((team) => ({
					name: team.name,
					icon: team.icon,
					type: "MANUAL",
			  }))
	);

	const defaultGroup = teams.filter(({ type }) => type === "DEFAULT");
	const manualGroup = teams.filter(({ type }) => type === "MANUAL");

	const handleSubmit = () => {
		if (!teams.length) return;
		actions
			.update({
				teams,
			})
			.then(() => {
				actions.setStep("team-skills");
			});
	};

	const handleAddTeam = (e: any) => {
		e.preventDefault();

		if (teams.find((item) => slugify(item.name) === slugify(team))) {
			setTeam("");
			return;
		}

		setTeams((state) => [...state, { name: team, type: "MANUAL", icon }]);
		setTeam("");
		setIcon({
			emoji: emojiActions.getRandomEmoji(),
		});
	};

	const handleRemoveTeam = (team: string) => {
		setTeams((state) =>
			state.filter((item) => slugify(item.name) !== slugify(team))
		);
	};

	const renderTeam = (team: OnboardingTeam) => (
		<motion.div
			key={`team-${team.name}`}
			layout
			initial={{
				scale: 0.8,
				opacity: 0,
			}}
			animate={{
				scale: 1,
				opacity: 1,
			}}
			exit={{
				opacity: 0,
				scale: 0.8,
			}}
			transition={{
				type: "keyframes",
			}}
			onClick={() => handleRemoveTeam(team.name)}
			className="flex items-center px-4 py-3 cursor-pointer hover:bg-border transition-all rounded-md bg-accent gap-3 w-max border border-border relative z-[2]"
		>
			{team.icon && (
				<Icon
					className="bg-accent border border-border w-7 h-7"
					icon={team.icon}
				/>
			)}
			{team.name}
			<i className="far fa-times text-[15px]"></i>
		</motion.div>
	);

	return (
		<form
			{...rest}
			onSubmit={handleAddTeam}
			className={cn("flex flex-col flex-1 gap-4 w-full", className)}
		>
			<div className="flex flex-col justify-center flex-1 max-w-[340px] mx-auto gap-6 py-8">
				{!!defaultGroup?.length && (
					<div className="flex flex-col gap-4 w-full justify-center items-center relative">
						{defaultGroup.map(renderTeam)}
						<div className="hidden md:flex gap-4 absolute -top-3 -bottom-3 opacity-[0.15] hover:opacity-100 transition-all -right-[320px]">
							<div className="w-[140px] border border-l-0 border-primary transition-all"></div>
							<div className="w-[240px] text-background-foreground transition-all top-6">
								{t("defaults.description")}
							</div>
						</div>
					</div>
				)}

				{!!manualGroup?.length && (
					<div className="flex flex-col gap-4 justify-center items-center relative">
						{manualGroup.map(renderTeam)}
					</div>
				)}
			</div>

			<div className="w-full flex items-center p-2 bg-accent placeholder:text-placeholder border border-border rounded-md">
				<IconPicker
					trigger={
						<Icon
							className="bg-accent border border-border w-[42px] h-[42px]"
							icon={icon}
						/>
					}
					onChange={(emoji) =>
						setIcon({
							emoji,
						})
					}
					value={icon?.emoji}
				/>
				<input
					placeholder={t("placeholder")}
					className="h-full bg-transparent flex-1 px-4 py-2 placeholder:text-placeholder"
					name="team"
					value={team}
					onChange={(event) => setTeam(event?.target?.value)}
				/>
				<Button
					className="h-[42px]"
					type="primary"
					submit
					disabled={!team || status !== "idle"}
				>
					{t("add")}
				</Button>
			</div>
			<div className="flex flex-col mt-8">
				<Button
					onClick={handleSubmit}
					disabled={!teams.length || status !== "idle"}
					loading={status === "updating"}
					type="primary"
				>
					{t("continue")}
				</Button>
			</div>
		</form>
	);
};

CreateTeams.locale = {
	nl: {
		placeholder: "Team naam...",
		add: "Toevoegen",
		continue: "Doorgaan",
		defaults: {
			description:
				"Deze teams hebben wij al voor te toegevoegd, je kan ze verwijderen wanner ze niet binnen je bedrijf aanwezig zijn",
		},
	},
	en: {
		placeholder: "Team name...",
		add: "Add",
		continue: "Continue",
		defaults: {
			description:
				"We have already added these teams for you; you can remove them when they are not present in your company.",
		},
	},
};

export default withTranslation(CreateTeams);
