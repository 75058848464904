import { useEffect } from "react";
import Kanban from "src/components/kanban/Kanban";
import TasksKanbanColumn from "src/components/tasks/kanban/TasksKanbanColumn";
import useTaskFormAutoSave from "src/hooks/api/tasks/useTaskFormAutoSave";
import useTasksKanban from "src/hooks/api/tasks/useTasksKanban";

interface TasksKanbanProps {
	onStatusChange?: () => void;
}

const TasksKanban = ({ onStatusChange }: TasksKanbanProps) => {
	useTaskFormAutoSave();
	const { state, actions } = useTasksKanban();
	const columns = state?.columns || {};

	useEffect(() => {
		const statuses = Object.keys(columns);
		if (!statuses.length) {
			actions.getAllStatuses();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const onDrop = (params: any) => {
		if (params.from && params.to && params.id) {
			actions.updateStatus(params).then(() => {
				if (onStatusChange) {
					onStatusChange();
				}
			});
		}
	};

	return (
		<Kanban.Container>
			<Kanban {...{ onDrop }}>
				{Object.keys(columns).map((status: any) => (
					<TasksKanbanColumn {...{ status }} key={status} />
				))}
			</Kanban>
		</Kanban.Container>
	);
};

export default TasksKanban;
