import { ApiBoard } from "src/api/types";
import useTenant from "src/hooks/api/services/tenants/useTenant";
import useApi, { ApiStatus } from "src/hooks/api/utils/useApi";

function useBoards() {
	const {
		tenant: { modules },
	} = useTenant();
	const { state, actions, api, pagination } = useApi(
		{ id: "BOARDS" },
		{
			baseUrl: `/client/boards`,
		}
	);
	const hasCustomBoards = (state?.list || []).some(
		(board) => !board.is_static
	);

	const leave = async (slug: string) => {
		actions.set((prev: any) => ({
			...prev,
			status: "leaving",
		}));
		api.delete(`/${slug}/leave`)
			.then(() => {
				actions.set((prev: any) => ({
					...(prev || {}),
					status: "idle",
					list: (prev?.list || []).filter(
						(item: ApiBoard) => item.slug !== slug
					),
				}));
			})
			.catch(() =>
				actions.set((prev: any) => ({
					...prev,
					status: "idle",
				}))
			);
	};

	return {
		boards: state?.list || [],
		hasCustomBoards: !!hasCustomBoards,
		isForcedBoards:
			!!hasCustomBoards &&
			modules.includes("intern-task") &&
			!modules.includes("extern-task"),
		status: state.status as ApiStatus | "leaving",
		filter: state?.filter || null,
		pagination,
		actions: {
			...actions,
			leave,
		},
		api,
	};
}

export default useBoards;
