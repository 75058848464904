import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { ApiTask } from "src/api/types";
import Button from "src/components/Button";
import Modal from "src/components/Modal";
import Input from "src/components/form/Input";
import withTranslation, {
	Translation,
} from "src/components/hoc/withTranslation";
import useDelete from "src/hooks/rest/useDelete";

interface DeleteTaskButtonProps extends Translation {
	task: ApiTask;
}

const DeleteTaskButton = ({ t, task }: DeleteTaskButtonProps) => {
	const [deleteTask, loading] = useDelete(`/company/tasks/${task.id}`);
	const [feedback, setFeedback] = useState("");
	const [visible, setVisible] = useState(false);
	const navigate = useNavigate();

	const onSubmit = () => {
		deleteTask({ feedback }).then(() => {
			navigate("/tasks");
		});
	};

	return (
		<>
			<Modal
				{...{ visible }}
				onClose={() => setVisible(false)}
				title={t("modal.title")}
			>
				<p className="mb-4 max-w-[300px]">{t("modal.description")}</p>

				<Input
					noMarginBottom
					label={t("modal.label")}
					placeholder={t("modal.placeholder")}
					name={"feedback"}
					onChange={(_, value) => setFeedback(value)}
					className="mb-6"
					multiline
				/>

				<Button type="red" {...{ loading }} onClick={onSubmit}>
					{t("button.label")}
				</Button>
				<Button
					className="mt-4"
					type="gray"
					disabled={loading}
					onClick={() => setVisible(false)}
				>
					{t("button.cancel")}
				</Button>
			</Modal>
			<Button
				{...{ loading }}
				onClick={() => setVisible(!visible)}
				type="red"
			>
				{t("button.label")}
			</Button>
		</>
	);
};

DeleteTaskButton.locale = {
	nl: {
		button: {
			label: "Opdracht verwijderen",
			cancel: "Annuleren",
		},
		modal: {
			description:
				"We leren graag van je. Waarom wil je deze opdracht verwijderen?",
			label: "Feedback",
			placeholder: "Helaas is de opdracht niet meer nodig...",
			title: "Opdracht verwijderen",
		},
	},
	en: {
		button: {
			label: "Delete task",
			cancel: "Cancel",
		},
		modal: {
			description:
				"We would like to learn from you! Why do you want to delete the task",
			label: "Feedback",
			placeholder: "The task is not needed any more because...",
			title: "Delete task",
		},
	},
};

export default withTranslation(DeleteTaskButton);
