import { createAction, handleActions } from "redux-actions";
import { ApiSalesTeamMember } from "src/api/types";

export const updateDiscount = createAction("UPDATE_DISCOUNT");
export const removeDiscount = createAction("REMOVE_DISCOUNT");

export const emptyDiscountState = {
	dicsount: undefined,
	loading: true,
};

type DiscountState = {
	discount?: ApiSalesTeamMember;
	loading: boolean;
};

const discountReducer = handleActions(
	{
		UPDATE_DISCOUNT: (state: DiscountState, action: any) => {
			return { ...state, ...action.payload };
		},
		REMOVE_DISCOUNT: () => {
			return {
				...emptyDiscountState,
			};
		},
	},
	{
		...emptyDiscountState,
	}
);
export default discountReducer;
