import Badge from "src/components/Badge";
import withTranslation, {
	Translation,
} from "src/components/hoc/withTranslation";

interface CompanyUserStatusProps extends Translation {
	status: "INVITED" | "ACTIVE" | "INACTIVE" | "DELETED";
}

const CompanyUserStatus = ({ t, status }: CompanyUserStatusProps) => {
	return (
		<Badge variant={status === "ACTIVE" ? "green" : "gray"}>
			{t(`status.${status}`)}
		</Badge>
	);
};

CompanyUserStatus.locale = {
	nl: {
		status: {
			INVITED: "Uitgenodigd",
			ACTIVE: "Actief",
			DELETED: "Verwijdert",
		},
	},
	en: {
		status: {
			INVITED: "Invited",
			ACTIVE: "Active",
			DELETED: "Removed",
		},
	},
};

export default withTranslation(CompanyUserStatus);
