import { useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";
import Button from "src/components/Button";
import Calendar from "src/components/Calendar";
import withTranslation, {
	Translation,
} from "src/components/hoc/withTranslation";
import Layout from "src/components/layout/Layout";
import SubscriptionFeatureMiddleware from "src/components/middlewares/SubscriptionFeatureMiddleware";
import usePaymentRequests from "src/hooks/api/services/payment-requests/usePaymentRequests";
import useConnection from "src/hooks/api/services/users/useConnection";
import useAuth from "src/hooks/selectors/useAuth";
import useModules from "src/hooks/selectors/useModules";
import useUpdateEffect from "src/hooks/useUpdateEffect";

const PaymentRequestView = ({ t }: Translation) => {
	const auth = useAuth();
	const { modules, loading: loadingModules } = useModules();
	const params = useParams() as any;
	const connection = useConnection(params?.connectionId, {
		filter: {
			with: ["bio"],
		},
	});
	const location = useLocation();
	const paymentRequests = usePaymentRequests({
		connectionId: params.connectionId,
	});

	const title =
		connection?.connection?.name ||
		connection?.connection?.first_name ||
		"";

	useUpdateEffect(() => {
		//Fetch the payment requests
		paymentRequests.actions.list();
	}, [location]);

	useEffect(() => {
		//Fetch the connection
		connection.actions.get(params.connectionId);

		//Fetch the payment requests
		paymentRequests.actions.list();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	if (
		auth.type === "company" &&
		!modules.includes("payment-requests") &&
		!loadingModules
	) {
		return (
			<Layout
				title={t("title")}
				breadcrumb={[
					{
						label: t("title"),
						to: "/payment-requests",
					},
					{
						label: title,
						to: `/payment-requests/${params.connectionId}`,
					},
				]}
			>
				<SubscriptionFeatureMiddleware plan="premium" />
			</Layout>
		);
	}

	return (
		<Layout
			{...{ title }}
			// container="xxl"
			loading={
				connection.status !== "idle" &&
				paymentRequests.status !== "idle" &&
				!paymentRequests.requests.length
			}
			breadcrumb={[
				{
					label: t("title"),
					to: "/payment-requests",
				},
				{
					label: title,
					to: `/payment-requests/${params.connectionId}`,
				},
			]}
			actions={
				<>
					{auth.type === "student" && (
						<Button
							to={{
								modal: `/payment-requests/${params.connectionId}/create`,
							}}
						>
							{t("create")}
						</Button>
					)}
				</>
			}
		>
			<Calendar items={[]} className="flex-1">
				<Calendar.WeekTime />
			</Calendar>
			{/* <div className="flex flex-col md:flex-row gap-12 flex-1">
				<div className="flex-1 flex flex-col">
					{paymentRequests.requests.length === 0 &&
						paymentRequests.status === "idle" && (
							<EmptyState
								variant={
									auth.type === "student" ? "company" : "user"
								}
								className="p-4"
								company={
									auth.type === "student"
										? (connection.connection as any)
										: undefined
								}
								user={
									auth.type === "company"
										? (connection.connection as any)
										: undefined
								}
							/>
						)}

					<Calendar items={[]} className="flex-1">
						<Calendar.WeekTime />
					</Calendar>
				</div>
				<div className="flex flex-col gap-8 md:max-w-[320px]">
					{auth?.type === "student" && (
						<CompanyCard company={connection.connection}>
							<div className="flex flex-col gap-1">
								<div className="flex gap-2 items-center flex-row justify-between">
									<div className="flex items-center gap-2">
										<CompanyCard.Avatar />
										<div className="">
											<CompanyCard.Name className="line-clamp-1" />
										</div>
									</div>
								</div>
								<CompanyCard.Excerpt className="opacity-70" />
							</div>
						</CompanyCard>
					)}
					<Card></Card>
				</div>
			</div> */}
		</Layout>
	);
};

PaymentRequestView.locale = {
	nl: {
		title: "Betaalverzoeken",
		create: "Aanmaken",
	},
	en: {
		title: "Payment requests",
		create: "Create",
	},
};

export default withTranslation(PaymentRequestView);
