import MessageSmall from "src/components/chat/MessageSmall";
import withTranslation, {
	Translation,
} from "src/components/hoc/withTranslation";
import Tutorial from "src/components/tutorial/Tutorial";
import useModal from "src/hooks/useModal";

function ReferralsTutorialModal({ t }: Translation) {
	const modal = useModal();
	return (
		<div className="flex flex-col  pt-4">
			<Tutorial
				finishLabel={t("finish")}
				onEndReach={() => modal.onClose()}
			>
				<Tutorial.Item
					image={
						<Tutorial.Image
							src="/assets/images/referral-share.webp"
							elements={[
								<div
									key="share-1"
									className="absolute z-30 flex left-0 justify-end -right-2  top-2"
								>
									<MessageSmall
										className="fixed"
										icon={{
											name: "fab fa-linkedin",
											className: "text-socials-linkedin",
										}}
									>
										{t("share.linkedin")}
									</MessageSmall>
								</div>,
								<div
									key="share-2"
									className="absolute z-20 -left-3 top-20"
								>
									<MessageSmall
										className="fixed"
										align="right"
										icon={{
											name: "fab fa-whatsapp",
											className: "text-socials-whatsapp",
										}}
									>
										{t("share.whatsapp")}
									</MessageSmall>
								</div>,
								<div
									key="share-3"
									className="absolute z-20 left-4 bottom-2"
								>
									<MessageSmall
										className="fixed"
										icon={{
											name: "far fa-envelope",
											className:
												"text-background-foreground",
										}}
									>
										{t("share.mail")}
									</MessageSmall>
									,
								</div>,
							]}
						/>
					}
				>
					<Tutorial.Title>{t("share.title")}</Tutorial.Title>
					<Tutorial.Description>
						{t("share.description")}
					</Tutorial.Description>
				</Tutorial.Item>
				<Tutorial.Item
					image={
						<Tutorial.Image src="/assets/images/referral-completed.webp" />
					}
				>
					<Tutorial.Title>{t("earn.title")}</Tutorial.Title>
					<Tutorial.Description>
						{t("earn.description")}
					</Tutorial.Description>
				</Tutorial.Item>
			</Tutorial>
		</div>
	);
}

ReferralsTutorialModal.locale = {
	nl: {
		title: "Referrals",
		finish: "Let's go!",
		share: {
			title: "Deel jouw unieke link",
			description:
				"Verspreid je link onder je connecties. Wanneer zij via jouw link zich aanmelden op {{tenant.name}} zie je dit terug bij de lijst “Uitnodigingen”.",
			linkedin: "Hi! Ik maak gebruik van gebruik...",
			whatsapp: "Meld je aan via mijn link!",
			mail: "Ook opdrachten uitbesteden?",
		},
		earn: {
			title: "Wanneer krijg ik korting?",
			description:
				"Als de gebruiker die je hebt uitgenodigd zich aanmeldt, ontvang je een korting van €20. Als die persoon ook een abonnement neemt, krijg je nog eens 50% van het abonnementsbedrag als korting.",
		},
	},
	en: {
		title: "Referrals",
		finish: "Let's go!",
		share: {
			title: "Share your unique link",
			description:
				"Spread your link among your connections. When they sign up on {{tenant.name}} through your link, you'll see it listed under 'Invitations'.",
			linkedin: "Hi! I'm using...",
			whatsapp: "Sign up through my link!",
			mail: "Interested in outsourcing tasks too?",
		},
		earn: {
			title: "When do I receive a discount?",
			description:
				"When the user you invited signs up, you will receive a €20 discount. Additionally, if the person takes out a subscription, you will receive an extra 50% of the subscription amount as a discount.",
		},
	},
};
export default withTranslation(ReferralsTutorialModal);
