import { useTranslation } from "react-i18next";
import Input from "src/components/form/Input";
import OfferForm from "src/components/offer/forms/OfferForm";
import useCreateOffer from "src/hooks/api/useCreateOffer";
import useOfferScheme from "src/hooks/schemes/useOfferScheme";

const OfferBudgetForm = () => {
	const { t } = useTranslation("offer", {
		keyPrefix: "form",
	});
	const { setCurrent, offer } = useCreateOffer();
	const scheme = useOfferScheme();

	return (
		<OfferForm
			onSubmit={async () => {
				setCurrent(5);
				return;
			}}
			value={{
				budget: offer?.budget,
				estimated_duration: offer?.estimated_duration,
			}}
			schema={() => ({
				budget: scheme.budget,
				estimated_duration: scheme.estimated_duration,
			})}
		>
			{(getInputProps) => (
				<>
					<Input label={t("budget")} {...getInputProps("budget")} />
					<Input
						optional
						label={t("estimated-duration")}
						{...getInputProps("estimated_duration")}
					/>
				</>
			)}
		</OfferForm>
	);
};

export default OfferBudgetForm;
