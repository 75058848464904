import { ApiTimesheet } from "src/api/types";
import TimesheetChangeLogItem from "src/components/timesheet/TimesheetChangeLogItem";

interface TimesheetChangeLogProps {
	timesheets: ApiTimesheet[];
}

const TimesheetChangeLog = ({ timesheets }: TimesheetChangeLogProps) => {
	return (
		<div className=" ">
			{timesheets.map((item, index) => {
				return (
					<TimesheetChangeLogItem
						key={`log-${item.id}`}
						type={{
							first: index === 0,
							last: index >= timesheets.length - 1,
						}}
						timesheet={item}
					/>
				);
			})}
		</div>
	);
};

export default TimesheetChangeLog;
