import { ApiReferralReward } from "src/api/types";
import { formatPrice } from "src/lib/formatters";
import { cn } from "src/lib/utils";

interface ReferralPriceProps {
	rewards: ApiReferralReward[];
}

const ReferralPrice = ({ rewards }: ReferralPriceProps) => {
	const price = rewards.reduce((prev, reward) => prev + reward.amount, 0);

	return (
		<span className={cn(price === 0 && "text-border")}>
			{formatPrice(price)}
		</span>
	);
};

export default ReferralPrice;
