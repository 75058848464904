import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import Line from "src/components/Line";
import ShareSocialReferralCode from "src/components/referral/share-network/ShareSocialReferralCode";
// import useTenant from "src/hooks/api/services/tenants/useTenant";
import useGet from "src/hooks/rest/useGet";
import copyToClipboard from "src/lib/copyToClipboard";
import { getAdminUrl } from "src/lib/urls";

const ShareReferralCode = () => {
	const { t } = useTranslation("share-network", {
		keyPrefix: "share-referral-code",
	});
	const [{ data }, loading] = useGet("/student/referral-link");
	// const { tenant } = useTenant();
	// const link = tenant?.urls?.public + `/company/join/${data?.code}`;
	const link = getAdminUrl(`/join/${data?.code}`);
	const [isCopied, setIsCopied] = useState(false);

	const text = t("share.text", {
		link,
	});
	const title = t("share.title");
	return (
		<div className="">
			<h2 className="text-background-foreground dark:text-white text-center mb-8">
				{t("title")}
			</h2>

			<div className="text-center">
				<p className="text-center mb-0">{t("description")}</p>
				{!loading && (
					<div
						onClick={() => {
							copyToClipboard(link);
							setIsCopied(true);
							setTimeout(() => {
								setIsCopied(false);
							}, 2500);
						}}
						className={`mt-2 flex-wrap flex transition-all items-center justify-center cursor-pointer dark:text-white text-${
							isCopied ? "primary" : "dark"
						}`}
					>
						<strong className="break-all">{link}</strong>
						<i
							className={`fas fa-${
								isCopied ? "check" : "clipboard"
							} ml-4`}
						></i>
					</div>
				)}
			</div>
			<Line label="Of" />
			<ShareSocialReferralCode url={link} {...{ text, title }} />
		</div>
	);
};

export default ShareReferralCode;
