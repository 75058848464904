import React from "react";
import { Trans, useTranslation } from "react-i18next";
import Button from "src/components/Button";
import usePost from "src/hooks/rest/usePost";
import useAuth from "src/hooks/selectors/useAuth";
import useAlert from "src/hooks/useAlert";

const ResetPassword = () => {
	const { t } = useTranslation("forms", {
		keyPrefix: "reset-password",
	});
	const user = useAuth();
	const [requestPassword, loading] = usePost(`/auth/password/create`);
	const [sendAlert] = useAlert();
	const handleClick = () => {
		requestPassword({ email: user.email })
			.then(() => {
				sendAlert({
					type: "success",
					title: t("alert-success.title"),
					text: t("alert-success.description"),
				});
			})
			.catch(() => {
				sendAlert({
					type: "error",
					title: t("alert-error.title"),
				});
			});
	};
	return (
		<div className="reset-password flex flex-col gap-4">
			<p>
				<Trans {...{ t }} i18nKey="description">
					Om het wachtwoord te wijzigen heb je toegang nodig tot
					<strong>{{ email: user.email }}</strong>. Je ontvangt een
					email waarmee het wachtwoord kan worden aangepast.
				</Trans>
			</p>
			<div className="flex">
				<Button
					small
					type="dark"
					onClick={handleClick}
					{...{ loading }}
					className={`gtm-${user.type}-profiel--button-wachtwoord-wijzigen`}
				>
					{t("button")}
				</Button>
			</div>
		</div>
	);
};

export default ResetPassword;
