import { ALLOWED_EXTENSIONS } from "src/lib/constants";

const alerts = {
	"login-failed": "Login failed",
	"something-wrong": "Oops! Something seems to be wrong.",
	file: {
		"to-large": {
			title: "This file is too large",
			description: "Upload a file smaller than 2mb",
		},
		unknown: {
			title: "This file cannot be uploaded",
			description: `Only these file types are allowed: ${ALLOWED_EXTENSIONS.join(
				", "
			)}`,
		},
	},
};

export default alerts;
