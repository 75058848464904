import React from "react";
import { useTranslation } from "react-i18next";
import Layout from "src/components/layout/Layout";
import MandateList from "src/components/subscription/billing/MandateList";

const DashboardConnectMandateView = () => {
	const { t } = useTranslation("dashboard-view", {
		keyPrefix: "connect-mandate-view",
	});
	return (
		<Layout title={t("title")}>
			<Layout.BackButton to="/dashboard" label="Terug naar dashboard" />
			<div className="rounded-md py-8 px-4 w-full justify-center items-center flex flex-col">
				<div className="flex justify-center items-center gap-4 flex-col">
					<h2>{t("title")}</h2>
					<p>{t("description")}</p>
				</div>
			</div>
			<div className="mt-4 flex justify-center items-center">
				<MandateList />
			</div>
		</Layout>
	);
};

export default DashboardConnectMandateView;
