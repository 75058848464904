const shareNetwork = {
	"create-referral-code": {
		button: "Create My Code",
		alert: {
			title: "Your unique code has been created",
			text: "Success! Use your code now to sign up companies",
		},
	},
	"share-referral-code": {
		title: "Share Your Unique Link",
		description: "Through the following link you can invite companies",
		share: {
			title: "Shall we collaborate?",
			text: "Shall we collaborate? On {{tenant.name}} I do tasks for companies for a fixed fee price and without any contractual obligations. Sign up using my personal link: {{link}}",
		},
	},
	"share-referral-tutorial": {
		create: {
			title: "Create Your Unique Link",
			description:
				"Then you can share your link with companies in your own network",
		},
		share: {
			title: "Companies Sign Up",
			description:
				"When a company signs up, you receive €25. If the company subscribes to a yearly plan afterward, you earn an additional €250.",
		},
		paid: {
			title: "Get Paid",
			// description:
			// 	"We check the sign ups, when they are approved the amount will be added in the next task.",
			description:
				"At the end of every month we check all signups, when they get approved we will pay the commision via a task payout",
		},
	},
	"share-social-referral": {
		facebook: "Share on Facebook",
		linkedin: "Share on LinkedIn",
		twitter: "Share on Twitter",
	},
	"referral-link-payouts": {
		empty: {
			title: "No payments are ready for you yet",
		},
		item: {
			text: {
				"signed-up": "{{name}} signed up via your link",
				"placed-task": "{{name}} placed a task via your link",
				"subscribed-year":
					"{{name}} subscribed for a year via your link`",
			},
			approved: "Approved",
			pending: "Pending",
			"created-at": "created on {{date}}",
		},
	},
};

export default shareNetwork;
