import moment from "moment";
import { useTranslation } from "react-i18next";
import { ApiEnvelopeSigner } from "src/api/types";
import Button from "src/components/Button";
import SignersUser from "src/components/signer/SignersUser";
import Skeleton from "src/components/Skeleton";
import List from "src/components/ui/list";
import useGet from "src/hooks/rest/useGet";
import useAuth from "src/hooks/selectors/useAuth";
import useBackUrl from "src/hooks/useBackUrl";

interface EnvelopeListProps {
	query?: string;
}

const EnvelopeList = ({ query }: EnvelopeListProps) => {
	const { t } = useTranslation("lists");
	const auth = useAuth();
	const backUrl = useBackUrl();
	const [{ data }, loading, { pagination }] = useGet(
		`/envelopes?with[]=signers&${query || ""}`,
		{ data: [] },
		true
	);

	const getEmptyTitle = () => {
		if (query?.includes("state=open")) {
			return {
				title: t("envelope-list.empty-open.title"),
				text: t("envelope-list.empty-open.text"),
			};
		}
		if (query?.includes("state=SIGNED")) {
			return {
				title: t("envelope-list.empty-signed.title"),
				text: t("envelope-list.empty-signed.text"),
			};
		}
		return {
			title: t("envelope-list.empty.title"),
			text: t("envelope-list.empty.text"),
		};
	};

	const emptyText = getEmptyTitle();

	return (
		<List {...{ data }} status={loading ? "loading" : "idle"}>
			<List.Head>
				<List.Item>{t("headers.status")}</List.Item>
				{auth.type === "student" && (
					<List.Item>{t("headers.company")}</List.Item>
				)}
				<List.Item>{t("headers.task")}</List.Item>
				<List.Item>{t("headers.created-at")}</List.Item>
				<List.Item>{t("headers.actions")}</List.Item>
			</List.Head>
			<List.Items>
				{(item) => (
					<List.Row key={`envelope-${item.hid}`}>
						<List.Item>
							<div className="flex flex-col gap-2">
								{(item?.signers || []).map(
									(item: ApiEnvelopeSigner) => (
										<SignersUser
											layout="small"
											signer={item}
											key={`signer-${item.id}`}
											isUpdateViewed={false}
										/>
									)
								)}
							</div>
						</List.Item>
						{auth.type === "student" && (
							<List.Item>
								{item?.task?.company?.name ||
									t("envelope-list.not-found")}
							</List.Item>
						)}
						<List.Item>
							<p
								className="line-clamp-1"
								// to={`/tasks/${item.task.hid}/show?match=${item.links?.registration}`}
							>
								{item?.task?.title}
							</p>
						</List.Item>
						<List.Item>
							{moment(item.created_at).format("DD-MM-YYYY")}
						</List.Item>
						<List.Item>
							<Button
								iconOnly
								to={`/envelopes/${item.hid}?${backUrl}`}
							>
								<i className="fas fa-eye"></i>
							</Button>
						</List.Item>
					</List.Row>
				)}
			</List.Items>
			<List.Loading>
				<List.Item>
					<div className="flex flex-col gap-2">
						<SignersUser.Loader variant="light" layout="small" />
						<SignersUser.Loader variant="light" layout="small" />
					</div>
				</List.Item>
				<List.Item>
					<Skeleton className="w-full h-6" />
				</List.Item>
				<List.Item>
					<Skeleton className="w-full h-6" />
				</List.Item>
				<List.Item>
					<Skeleton className="w-10 h-10" />
				</List.Item>
			</List.Loading>
			<List.Empty>
				<h2>{emptyText.title}</h2>
				<p>{emptyText.text}</p>
			</List.Empty>
			<List.Pagination
				{...{ pagination }}
				onNavigate={pagination.setPage}
			/>
		</List>
	);
};

export default EnvelopeList;
