import moment from "moment";
import { Link, useNavigate, useParams } from "react-router-dom";
import withTranslation, {
	Translation,
} from "src/components/hoc/withTranslation";
import Layout from "src/components/layout/Layout";
import RegistrationAcceptList from "src/components/lists/RegistrationAcceptList";
import RegistrationDetailModal from "src/components/modals/RegistrationDetailModal";
import SubtasksTable from "src/components/tasks/sub-tasks/SubtasksTable";
import TaskCompanyHead from "src/components/tasks/TaskCompanyHead";
import useTask from "src/hooks/api/services/tasks/task/useTask";
import useAuth from "src/hooks/selectors/useAuth";
import useBackUrl from "src/hooks/useBackUrl";

function TasksDetailEventView({ t }: Translation) {
	const navigate = useNavigate();
	const { id, registration } = useParams() as any;
	const auth = useAuth();
	const { task, status } = useTask({ id });
	const back = useBackUrl();
	const isTaskInPast = task.end_date
		? moment(task.end_date).isBefore(moment())
		: false;

	return (
		<Layout
			backUrl="/tasks"
			loading={status === "loading"}
			title={task?.title || ""}
			subtitle={
				auth.type === "company"
					? `Aangemaakt door: ${task?.creator?.first_name}`
					: undefined
			}
		>
			<RegistrationDetailModal
				registrationId={registration}
				key={registration}
				modal={{
					visible: registration ? registration : false,
					onClose: () => navigate(`/tasks/${id}/show`),
				}}
			/>

			{isTaskInPast &&
			task.budget_per_hour &&
			task.status !== "COMPLETED" ? (
				<div
					className={`bg-dark text-white text-center px-8 py-4 flex justify-center items-center flex-col rounded-md`}
				>
					<Link to={`/hours/${task?.hid}/show?${back}`}>
						<p
							dangerouslySetInnerHTML={{
								__html: t("timesheet-available.text"),
							}}
						></p>
					</Link>
				</div>
			) : (
				false
			)}

			<TaskCompanyHead {...{ task }} />
			{task?.id && (
				<>
					{task?.has_subtasks ? (
						<SubtasksTable {...{ task }} />
					) : (
						<RegistrationAcceptList
							key={`${id}-registrations`}
							matches={{
								total: task.amount_of_students,
								count: task.matches?.count,
							}}
							taskHid={id}
							taskId={Number(task.id)}
						/>
					)}
				</>
			)}
		</Layout>
	);
}

TasksDetailEventView.locale = {
	nl: {
		registration: {
			title: "Aanmelding",
		},
		actions: {
			edit: "Aanpassen",
			hours: "Uren controle",
		},
		"timesheet-available": {
			text: "De opdracht is verlopen, het is nu mogelijk om de uren van de kandidaten te verwerken / goed te keuren. <span class='underline'>Klik hier</span> om naar de uren te gaan",
		},
	},
	en: {
		registration: {
			title: "Registration",
		},
		actions: {
			edit: "Edit",
			hours: "Hour validation",
		},
		"timesheet-available": {
			text: "The task has expired, you are now able to process and check the hours. <span class='underline'>Click here</span> to go to the hours",
		},
	},
};

export default withTranslation(TasksDetailEventView);
