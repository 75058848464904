import { motion } from "framer-motion";

interface AlertProps {
	type: "success" | "danger" | "error" | "info";
	children?: any;
	options?: {
		noAnimation?: boolean;
		noIcon?: boolean;
	};
}

const Alert = ({ type, children, options }: AlertProps) => {
	let className =
		" p-4 rounded-md flex flex-wrap sm:flex-nowrap flex-col md:flex-row md:items-center gap-4";
	let iconClassName =
		"md:w-8 md:h-8 w-12 h-12 rounded-md flex items-center justify-center";
	let icon = null;

	if (type === "success") {
		className += " bg-green-200 text-green";
		iconClassName += " bg-green text-white";
		icon = "fas fa-check";
	}
	if (type === "danger" || type === "error") {
		className += " bg-red-200 text-red";
		iconClassName += " bg-red text-white";
		icon = "fas fa-times";
	}

	if (type === "info") {
		className += " bg-accent font-bold ";
		icon = "fas fa-info";
	}

	if (options?.noAnimation) {
		return (
			<div {...{ className }}>
				{icon && !options?.noIcon && (
					<div className={iconClassName}>
						<i className={icon}></i>
					</div>
				)}
				<span>{children}</span>
			</div>
		);
	}

	return (
		<motion.div
			initial={{ opacity: 0, scale: 0.9 }}
			animate={{ opacity: 1, scale: 1 }}
			{...{ className }}
		>
			{icon && !options?.noIcon && (
				<div className={iconClassName}>
					<i className={icon}></i>
				</div>
			)}
			<p className="flex-1">{children}</p>
		</motion.div>
	);
};

export default Alert;
