import { ApiEndorseSkill } from "src/api/types";
import useApi from "src/hooks/api/utils/useApi";
import useAuth from "src/hooks/selectors/useAuth";

type Options = {
	filter?: any;
};

function useSkills(options?: Options) {
	const auth = useAuth();
	const { state, actions, api } = useApi(
		{ id: `SKILLS:${auth.id}` },
		{
			baseUrl: `/resume/${auth.id}/skills`,
			query: {
				state: "all",
				...(options?.filter || {}),
			},
		}
	);

	const skills: ApiEndorseSkill[] = state?.list || [];

	return {
		skills,
		skill: state?.data,
		status: state.status,
		actions,
		api,
	};
}

export default useSkills;
