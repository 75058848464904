import {
	ComponentProps,
	createContext,
	useContext,
	useEffect,
	useState,
} from "react";
import { ApiUserOptionMinimal } from "src/api/types";
import Avatar from "src/components/Avatar";
import Button from "src/components/Button";
import Input from "src/components/form/Input";
import { useT } from "src/components/hoc/withTranslation";
import useAllConnections from "src/hooks/api/services/users/useAllConnections";
import { ApiStatus } from "src/hooks/api/utils/useApi.types";
import { cn } from "src/lib/utils";

type ConnectionSelectorListVariant = "open" | "selected" | "combined";

type ConnectionSelectorType = {
	value: string[];
	onChangeValue: React.Dispatch<React.SetStateAction<string[]>>;
	search: {
		value: string;
		onChange: React.Dispatch<React.SetStateAction<string>>;
	};
	connections: {
		data: ApiUserOptionMinimal[];
		status: ApiStatus;
	};
};

const ConnectionSelectorContext = createContext<ConnectionSelectorType>({
	value: [],
	onChangeValue: () => {},
	connections: {
		data: [],
		status: "idle",
	},
	search: {
		value: "",
		onChange: () => {},
	},
});

const useConnectionSelector = () => {
	const { value, onChangeValue, connections, search } = useContext(
		ConnectionSelectorContext
	);
	const getItemsByVariant = (variant: ConnectionSelectorListVariant) => {
		if (variant === "open") {
			return connections.data
				.filter((item) => !value.includes(item.id))
				.filter((item) =>
					item.name.toLowerCase().includes(search.value.toLowerCase())
				);
		}
		if (variant === "selected") {
			return connections.data
				.filter((item) => value.includes(item.id))
				.filter((item) =>
					item.name.toLowerCase().includes(search.value.toLowerCase())
				);
		}
		return connections.data.filter((item) =>
			item.name.toLowerCase().includes(search.value.toLowerCase())
		);
	};
	return {
		value,
		onChangeValue,
		connections,
		isItemSelected: (id: string) => value.includes(id),
		toggleItem: (id: string) => {
			onChangeValue((prev) =>
				prev.includes(id)
					? prev.filter((item) => item !== id)
					: [...prev, id]
			);
		},
		getItemsByVariant,
		search,
	};
};

interface ConnectionSelectorProps extends ComponentProps<"div"> {
	value: ConnectionSelectorType["value"];
	onChangeValue: ConnectionSelectorType["onChangeValue"];
}

const ConnectionSelector = ({
	value = [],
	onChangeValue,
	children,
	className,
	...rest
}: ConnectionSelectorProps) => {
	const { connections, actions, status } = useAllConnections();
	const [search, setSearch] = useState<string>("");

	useEffect(() => {
		actions.list();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<ConnectionSelectorContext.Provider
			value={{
				value,
				onChangeValue,
				connections: {
					data: connections,
					status: status,
				},
				search: {
					value: search,
					onChange: setSearch,
				},
			}}
		>
			<div className={cn("", className)} {...rest}>
				{children}
			</div>
		</ConnectionSelectorContext.Provider>
	);
};

interface ConnectionSelectorListProps
	extends Omit<ComponentProps<"div">, "children"> {
	variant: ConnectionSelectorListVariant;
	children: (props: {
		item: ApiUserOptionMinimal;
		isSelected: boolean;
		toggleItem: () => void;
	}) => React.ReactNode;
}

export const ConnectionSelectorList = ({
	children,
	className,
	variant,
	...rest
}: ConnectionSelectorListProps) => {
	const { getItemsByVariant, isItemSelected, toggleItem } =
		useConnectionSelector();

	return (
		<div className={cn("flex flex-col gap-2", className)} {...rest}>
			{getItemsByVariant(variant).map((item) =>
				children({
					item,
					isSelected: isItemSelected(item.id),
					toggleItem: () => toggleItem(item.id),
				})
			)}
		</div>
	);
};

export const ConnectionSelectorSearch = () => {
	const { search } = useConnectionSelector();
	const t = useT({
		nl: {
			search: "Zoeken",
		},
		en: {
			search: "Search",
		},
	});

	return (
		<Input
			placeholder={t("search")}
			name="search"
			value={search.value}
			onChange={(key, value) => search?.onChange(value)}
		/>
	);
};

export const ConnectionSelectorListSelectAll = ({
	className,
	variant,
}: {
	className?: string;
	variant: ConnectionSelectorListVariant;
}) => {
	const { getItemsByVariant, onChangeValue } = useConnectionSelector();

	const t = useT({
		nl: {
			select: "Alles selecteren",
			unselect: "Alles deselecteren",
		},
		en: {
			select: "Select all",
			unselect: "Unselect all",
		},
	});
	return (
		<Button
			xsmall
			type="border"
			onClick={() => {
				if (variant === "selected") {
					onChangeValue([]);
				} else {
					onChangeValue(
						getItemsByVariant(variant).map((item) => item.id)
					);
				}
			}}
			className={cn("", className)}
		>
			{t(variant === "selected" ? "unselect" : "select")}
		</Button>
	);
};

export const ConnectionSelectorListTitle = ({
	variant,
	className,
	...rest
}: ComponentProps<"p"> & {
	variant: ConnectionSelectorListVariant;
}) => {
	const t = useT({
		nl: {
			combined: "Alle connecties",
			open: "Open connecties",
			selected: "Geselecteerde connecties",
		},
		en: {
			combined: "All connections",
			open: "Open connections",
			selected: "Selected connections",
		},
	});
	return (
		<p {...rest} className={cn("font-bold", className)}>
			{t(variant)}
		</p>
	);
};

export const ConnectionSelectorListTotal = ({
	className,
	variant,
	...rest
}: ComponentProps<"p"> & { variant: ConnectionSelectorListVariant }) => {
	const { getItemsByVariant } = useConnectionSelector();
	const t = useT({
		nl: {
			total: "{{count}} connecties",
			total_one: "{{count}} connectie",
			total_zero: "Geen connecties",
		},
		en: {
			total: "{{count}} connections",
			total_one: "{{count}} connection",
			total_zero: "No connections",
		},
	});
	const count = getItemsByVariant(variant).length;
	return (
		<p {...rest} className={cn("", className)}>
			{t(count === 0 ? "total_zero" : "total", { count })}
		</p>
	);
};

const ConnectionSelectorItemContext = createContext<{
	item: ApiUserOptionMinimal;
}>({
	item: { id: "", name: "", avatar: "" },
});

interface ConnectionSelectorItemProps extends ComponentProps<"div"> {
	item: ApiUserOptionMinimal;
}

export const ConnectionSelectorItem = ({
	children,
	className,
	item,
	...rest
}: ConnectionSelectorItemProps) => {
	return (
		<ConnectionSelectorItemContext.Provider value={{ item }}>
			<div
				className={cn(
					"flex items-center gap-2 border-b border-border p-3",
					className
				)}
				{...rest}
			>
				{children}
			</div>
		</ConnectionSelectorItemContext.Provider>
	);
};

export const useConnectionSelectorItem = () => {
	const { item } = useContext(ConnectionSelectorItemContext);
	return { item };
};

export const ConnectionSelectorItemAvatar = () => {
	const { item } = useConnectionSelectorItem();
	return <Avatar src={item.avatar || undefined} />;
};

export const ConnectionSelectorItemName = ({
	className,
	...rest
}: ComponentProps<"p">) => {
	const { item } = useConnectionSelectorItem();
	return (
		<p {...rest} className={cn("", className)}>
			{item.name}
		</p>
	);
};

export const ConnectionSelectorItemToggle = ({
	className,
}: {
	className?: string;
}) => {
	const { toggleItem, isItemSelected } = useConnectionSelector();
	const { item } = useConnectionSelectorItem();
	const isSelected = isItemSelected(item.id);

	const t = useT({
		nl: {
			add: "Voeg toe",
			remove: "Verwijder",
		},
		en: {
			add: "Add",
			remove: "Remove",
		},
	});
	return (
		<Button
			xsmall
			type="border"
			onClick={() => toggleItem(item.id)}
			className={cn("", className)}
		>
			{t(isSelected ? "remove" : "add")}
		</Button>
	);
};

export default ConnectionSelector;
