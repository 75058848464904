import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import Alert from "src/components/Alert";
import Input from "src/components/form/Input";
import CalculateServiceFee from "src/components/forms/CalculateServiceFee";
import { TaskFormOptions } from "src/components/tasks/TaskForm";
import Form from "src/components/tasks/TaskForm/Form";
import useTaskForm from "src/hooks/api/tasks/useTaskForm";
import useTaskScheme from "src/hooks/schemes/useTaskScheme";
import useAuth from "src/hooks/selectors/useAuth";

interface FixedFeeFormProps extends TaskFormOptions {
	amountLocked?: boolean;
}

const FixedFeeForm = ({ amountLocked, hideButtons }: FixedFeeFormProps) => {
	const { t } = useTranslation("forms", {
		keyPrefix: "budget-form",
	});
	const scheme = useTaskScheme();
	const auth = useAuth();
	const { task, actions, current } = useTaskForm();
	const hasRegistrations =
		task?.registrations?.count && task?.registrations?.count > 0;
	const isPaid = task?.is_paid;

	useEffect(() => {
		actions.set({ budget_per_hour: null });
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<Form
			{...{ hideButtons }}
			title={t("title")}
			onSubmit={async () => {
				actions.updateCurrent(current + 1);
			}}
			value={() => {
				if (task?.subtasks?.length > 0) {
					return {};
				}
				return {
					budget: task?.budget,
					amount_of_students: task.amount_of_students,
				};
			}}
			schema={() => {
				if (task?.subtasks?.length > 0) {
					return {};
				}
				return {
					budget: scheme.budget,
					amount_of_students: scheme.amount_of_students,
				};
			}}
		>
			{(getInputProps) => {
				const budgetField = getInputProps("budget");
				const amountOfStudentsField =
					getInputProps("amount_of_students");
				return (
					<>
						{(hasRegistrations || isPaid) && (
							<Alert
								options={{
									noAnimation: true,
									noIcon: true,
								}}
								type="danger"
							>
								{t(isPaid ? "alert-all" : "alert-budget")}
							</Alert>
						)}

						{!(task.subtasks?.length > 0) && (
							<>
								{!amountLocked && (
									<Input
										help={t("amount-of-students.help")}
										label={t("amount-of-students.label")}
										type="number"
										disabled={isPaid}
										{...amountOfStudentsField}
										name="amount_of_students"
									/>
								)}

								<Input
									help={t("budget.help")}
									label={t("budget.label")}
									type="price"
									{...budgetField}
									disabled={hasRegistrations}
								/>
							</>
						)}
						{budgetField?.value && auth.service_rate > 0 && (
							<CalculateServiceFee
								amountOfStudents={amountOfStudentsField?.value}
								budget={budgetField?.value}
							/>
						)}
						{task.subtasks?.length > 0 && <p>{t("shifts")}</p>}
					</>
				);
			}}
		</Form>
	);
};

export default FixedFeeForm;
