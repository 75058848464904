import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Avatar from "src/components/Avatar";
import Button from "src/components/Button";
import Card from "src/components/Card";
import Loader from "src/components/Loader";
import withTranslation, {
	Translation,
} from "src/components/hoc/withTranslation";
import Layout from "src/components/layout/Layout";
import BoardForm from "src/components/tasks/boards/BoardForm";
import useBoards from "src/hooks/api/services/tasks/useBoards";
import useBoardForm from "src/hooks/api/tasks/useBoardForm";
import useTasksFilter from "src/hooks/api/tasks/useTasksFilter";
import useAlert from "src/hooks/useAlert";

const EditBoardView = ({ t }: Translation) => {
	const [status, setStatus] = useState("loading");
	const { slug } = useParams();
	const navigate = useNavigate();
	const [sendAlert] = useAlert();
	const { filterState: filter, actions: filterActions } = useTasksFilter();
	const {
		boards,
		actions: boardsActions,
		status: boardsStatus,
	} = useBoards();
	const { board, isDisabled, actions: boardFormActions } = useBoardForm();

	useEffect(() => {
		// boardsActions.list().then(() => {
		if (boardsStatus === "idle") {
			if (boards) {
				const currentBoard = boards.find((item) => item.slug === slug);
				if (!currentBoard?.can.edit) {
					setStatus("not-creator");
					boardFormActions.set(currentBoard);
					return;
				}
				if (!currentBoard) {
					setStatus("not-found");
					return;
				}
				if (currentBoard?.is_static) {
					setStatus("is-static");
					return;
				}

				if (currentBoard && !currentBoard.is_static) {
					filterActions.set(currentBoard.filter);
					boardFormActions.set(currentBoard);
					setStatus("idle");
				}
			}
		}
		// });
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [boardsStatus]);

	const handleDelete = () => {
		if (isDisabled || !board.hid || boardsStatus !== "idle") return;

		sendAlert({
			type: "confirm",
			title: t("delete.title"),
			text: t("delete.text"),
			onConfirm: () => {
				boardsActions.delete(board.hid).then(() => {
					navigate("/tasks");
				});
			},
		});
	};

	const onSubmit = () => {
		if (isDisabled || !board.hid) return;
		boardsActions
			.update(
				{
					id: board.hid,
					...board,
					filter,
				},
				{
					updateList: true,
				}
			)
			.then((data) => {
				navigate(`/tasks/boards/${board.slug}`);
			});
	};

	const handleLeave = () => {
		if (!slug) {
			return;
		}
		boardsActions.leave(slug).then(() => {
			navigate(`/tasks`);
		});
	};

	const defaultMapping: any = {
		match: "match_student_id",
		assignees: "assignees",
		creator: "creator_id",
		team: "teams",
	};

	const excludeFields = [
		"search",
		"match",
		"assignees",
		"creator",
		"team",
		"completed_tasks",
	];

	for (const key in defaultMapping) {
		const keyFilter = defaultMapping[key];

		if (filter[keyFilter])
			if (
				filter[keyFilter] !== undefined &&
				filter[keyFilter] !== "" &&
				(Array.isArray(filter[keyFilter])
					? filter[keyFilter].length !== 0
					: true)
			) {
				excludeFields.splice(excludeFields.indexOf(key), 1);
			}
	}

	return (
		<Layout backUrl={`/tasks/boards/${slug}`} title={t("title")}>
			<div className={`${status !== "not-creator" && "container-small"}`}>
				{["not-found", "is-static", "not-creator"].includes(status) && (
					<div className="flex flex-1 justify-center items-center p-8 text-center flex-col max-w-md mx-auto">
						<strong className="mb-1 text-[24px]">
							{t(`${status}.title`)}
						</strong>
						{status === "not-creator" ? (
							board.creator ? (
								<>
									<div className="text-left">
										<Avatar.User
											className="mt-1 mb-4"
											user={board?.creator}
										/>
									</div>
									<p className="mb-8">
										{t("not-creator.advice", {
											name: board?.creator?.first_name,
										})}
									</p>
								</>
							) : (
								<div className="mt-2 mb-8">
									<Loader />
								</div>
							)
						) : (
							<p className="mb-4">{t(`${status}.description`)}</p>
						)}

						{status === "not-creator" && (
							<Button
								loading={boardsStatus === "leaving"}
								type="dark"
								onClick={handleLeave}
							>
								{t(`${status}.button`)}
							</Button>
						)}
					</div>
				)}
				{status === "idle" && (
					<Card>
						<BoardForm exclude={excludeFields} {...{ onSubmit }} />
						<hr className="border-border my-4" />
						<div className="flex">
							<Button
								type="gray"
								className="flex-1"
								loading={boardsStatus === "deleting"}
								onClick={handleDelete}
							>
								{t("delete.button")}
							</Button>
						</div>
					</Card>
				)}
				{status === "loading" && (
					<div className="flex flex-col flex-1 justify-center items-center">
						<Loader />
					</div>
				)}
			</div>
		</Layout>
	);
};

EditBoardView.locale = {
	nl: {
		title: "Bord aanpassen",
		"not-found": {
			title: "Bord niet gevonden",
			description:
				"Het lijkt er op dat dit bord nooit heeft bestaan of is verwijdert.",
		},
		"is-static": {
			title: "Je kan dit bord niet aanpassen",
			description:
				"Dit is een standaard bord, het is daarom niet mogelijk om deze te wijzigen",
		},
		"not-creator": {
			title: "Je kan dit bord niet aanpassen",
			description: "Alleen de aanmaker van dit bord kan hem aanpassen",
			advice: "Raadpleeg {{name}} om wijzigingen door te voeren binnen dit bord",
			button: "Bord verlaten",
		},
		delete: {
			button: "Bord verwijderen",
			title: "Weet je het zeker?",
			text: "Weet je zeker dat je dit bord wilt verwijderen",
		},
	},
	en: {
		title: "Edit board",
		"not-found": {
			title: "Board not found",
			description:
				"It looks like the board has never existed or has been deleted",
		},
		"is-static": {
			title: "This board is not editable",
			description:
				"This is a default board, it is not possible to edit or delete it",
		},
		"not-creator": {
			title: "This board is not editable",
			description: "Only the creator can edit this board",
			advice: "Ask {{name}} to apply changes to this board",
			button: "Leave board",
		},
		delete: {
			button: "Delete board",
			title: "Are you sure?",
			text: "Are you sure that you want to delete this board",
		},
	},
};

export default withTranslation(EditBoardView);
