const buttons = {
	continue: "Continue",
	cancel: "Cancel",
	confirm: "Confirm",
	back: "Back",
	"back-to-overview": "Back to Overview",
	open: "Open",
	close: "Close",
	signin: "Sign In",
	edit: "Edit",
	save: "Save",
	"click-here": "Click Here",
	deny: "Deny",
	report: "Report",
	signup: {
		facebook: "Sign up with Facebook",
	},
	"read-more": "Read More",
	"read-less": "Read Less",
	task: {
		finish: "Finish Task",
		create: "Create Task",
		"create-concept": "Save as Concept",
		publish: "Publish",
	},
	"load-more": "Load More",
	skip: "Skip",
	"previous-step": "Previous",
	"next-step": "Next",
	next: "Next",
	prev: "Previous",
	chat: "Chat",
	sign: "Sign",
	view: "View",
	retry: "Retry",
	signout: "Sign Out",
	connection: "Connection",
	favorite: "Favorite",
};

export default buttons;
