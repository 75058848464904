import { Trans, useTranslation } from "react-i18next";
import Link from "src/components/Link";
import Skeleton from "src/components/Skeleton";
import useTenant from "src/hooks/api/services/tenants/useTenant";
import useCalculateServiceFee from "src/hooks/api/tasks/useCalculateServiceFee";
import { formatPrice } from "src/lib/formatters";

interface CalculateServiceFeeProps {
	budget: number;
	amountOfStudents: number;
	variant: "budget" | "per-hour";
}

const CalculateServiceFee = ({
	budget,
	amountOfStudents,
	variant,
}: CalculateServiceFeeProps) => {
	const { tenant } = useTenant();
	const { t } = useTranslation("forms", {
		keyPrefix: "calculate-service-fee",
	});
	const { data, loading } = useCalculateServiceFee(budget, amountOfStudents);
	if (tenant.slug === "getjobsdone") {
		return <></>;
	}
	if ((!data || !data.budget) && !loading) {
		return <></>;
	}

	if (!data.can_pay && !loading) {
		return (
			<>
				<p>
					<Trans
						fee={formatPrice(data.fee)}
						i18nKey="not-enough-credit"
						{...{ t }}
					>
						Je hebt niet genoeg krediet op je account, je mist nog
						<b className="inline">
							{{ fee: formatPrice(data.fee) }}
						</b>
						<Link
							className="underline gtm-werkgever-dashboard-plaats-opdracht-1--button-opwaarderen"
							to="/credits/upgrade"
						>
							Klik hier om jouw plaatsingstegoed op te waarderen
						</Link>
					</Trans>
				</p>
				{data.is_free ? (
					<small>
						{t("free", {
							price: formatPrice(data.max_free_price),
							rate: data.rate * 100,
						})}
					</small>
				) : (
					false
				)}
			</>
		);
	}

	return (
		<div className=" ">
			{!data.is_gift && <strong>{t("title")}</strong>}
			{data.fee && data.budget && !data.is_gift && !loading ? (
				<p>
					{t(`description.${variant}`, {
						count: amountOfStudents,
						budget: formatPrice(budget),
						fee: data.fee ? formatPrice(data.fee) : "",
					})}
				</p>
			) : null}

			{loading && (
				<div className="flex gap-2 flex-col">
					<Skeleton className="h-3 w-full" />
					<Skeleton className="h-3 w-1/2" />
				</div>
			)}

			{data.is_gift && !loading ? (
				<p className="font-bold">{t("gift")}</p>
			) : null}
		</div>
	);
};

CalculateServiceFee.defaultProps = {
	amountOfStudents: 1,
	variant: "budget",
};

export default CalculateServiceFee;
