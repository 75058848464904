import withTranslation, {
	Translation,
} from "src/components/hoc/withTranslation";
import AuthLayout, {
	AuthLayoutContainer,
} from "src/components/layout/auth-layout/AuthLayout";
import Link from "src/components/Link";
import OurTenantsCard from "src/components/tenant/our-tenants-card";

const ProductsView = ({ t }: Translation) => {
	return (
		<AuthLayout variant="flat">
			<AuthLayoutContainer className="flex flex-col justify-center items-center gap-16">
				<h1>{t("title")}</h1>
				<OurTenantsCard hideTitle className="w-full max-w-md" />
				<div className="flex flex-col justify-center items-center">
					<Link
						className="opacity-60 hover:opacity-100 transition-all"
						to="/dashboard"
					>
						{t("back")}
					</Link>
				</div>
			</AuthLayoutContainer>
		</AuthLayout>
	);
};

ProductsView.locale = {
	nl: {
		title: "Onze producten",
		back: "Terug naar mijn dashboard",
	},
	en: {
		back: "Back to my dashboard",
	},
};

export default withTranslation(ProductsView);
