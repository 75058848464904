import { LayoutVariants } from "src/api/types";

interface ModalTabsProps {
	tabs: string[];
	children?: (tab: string) => void;
	selected: string;
	setSelected: (tab: string) => void;
	variant: LayoutVariants;
}

const ModalTabs = ({
	tabs,
	selected,
	setSelected,
	children,
	variant,
}: ModalTabsProps) => {
	return (
		<>
			<div
				className={`flex mb-4 p-1 rounded-md ${
					variant === "dark" ? "bg-dark-1100" : "bg-accent"
				}`}
			>
				{tabs.map((tab) => (
					<ModalTab
						{...{ variant }}
						key={tab}
						onClick={() => setSelected(tab)}
						active={selected === tab}
					>
						{tab}
					</ModalTab>
				))}
			</div>

			{children && children(selected)}
		</>
	);
};

interface ModalTabProps {
	active: boolean;
	children?: any;
	variant: LayoutVariants;
	onClick: () => void;
}

const ModalTab = ({ active, children, variant, onClick }: ModalTabProps) => {
	const activeBg = variant === "dark" ? "bg-dark" : "bg-background";
	return (
		<div
			{...{ onClick }}
			className={`cursor-pointer flex-1 py-2 px-4 rounded-md text-center transition-all ${
				active ? `${activeBg} shadow opacity-100` : "opacity-40"
			}`}
		>
			<strong>{children}</strong>
		</div>
	);
};

export default ModalTabs;
