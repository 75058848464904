import { useEffect } from "react";
import Button from "src/components/Button";
import withTranslation, {
	Translation,
} from "src/components/hoc/withTranslation";
import Layout from "src/components/layout/Layout";
import Link from "src/components/Link";
import SubscriptionCheckoutHead from "src/components/subscription/SubscriptionCheckoutHead";
import SubscriptionPlans from "src/components/subscription/SubscriptionPlans";
import useTenant from "src/hooks/api/services/tenants/useTenant";
import usePlans from "src/hooks/api/usePlans";
import useSubscription from "src/hooks/selectors/useSubscription";
import { useParseBackUrl } from "src/hooks/useBackUrl";
import useQueryString from "src/hooks/useQueryString";

const SubscriptionView = ({ t }: Translation) => {
	const subscription = useSubscription();
	const qs = useQueryString();
	const parseBackUrl = useParseBackUrl();
	const { actions } = usePlans();
	const { tenant } = useTenant();

	useEffect(() => {
		if (qs.interval) {
			actions.setInterval(qs.interval);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	if (subscription && !subscription?.is_paused) {
		return (
			<Layout hideNav>
				<div className="flex flex-col justify-center items-center flex-1">
					<h2 className="mb-8">{t("already.title")}</h2>
					<Button to="/dashboard">
						{t("already.button")}
						<i className="fas fa-arrow-right ml-4"></i>
					</Button>
				</div>
			</Layout>
		);
	}

	return (
		<Layout hideNav>
			<div className="flex flex-col flex-1">
				<SubscriptionCheckoutHead
					title={
						tenant.slug === "getrecruitmentdone"
							? t("head.title-one-time")
							: t("head.title")
					}
					subtitle={t("head.subtitle")}
				/>
				<SubscriptionPlans
					getPlanCheckoutUrl={(plan, interval) =>
						`/subscription/${plan.slug}?interval=${interval}`
					}
				/>
			</div>
			<div className="block">
				<Link className="underline" to={parseBackUrl || "/dashboard"}>
					{t("back")}
				</Link>
			</div>
		</Layout>
	);
};

SubscriptionView.locale = {
	nl: {
		already: {
			title: "Je hebt al een actief abonnement",
			button: "Doorgaan naar mijn dashboard",
		},
		back: "Terug naar dashboard",
		loyal: {
			title: "Speciaal abonnement voor loyale klanten!",
			description:
				"Bedankt dat je al <1>{{days}}</1> dagen een klant van ons bent! Daarom hebben we voor jou een speciale korting.",
		},
		head: {
			title: "Abonnement kiezen",
			"title-one-time": "Pakket kiezen",
			subtitle: "Kies het abonnement dat bij jouw organisatie past.",
		},
	},
	en: {
		already: {
			title: "You already have an active subscription",
			button: "Continue to my dashboard",
		},
		back: "Back to dashboard",
		loyal: {
			title: "Special subscription for loyal customers!",
			description:
				"Thank you for already being a customer with us for <1>{{days}}</1> days! That's why we have a special discount for you.",
		},
		head: {
			title: "Choose package",
			"title-one-time": "Choose package",
			subtitle: "Choose the package that fits your organization.",
		},
	},
};

export default withTranslation(SubscriptionView);
