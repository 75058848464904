import moment from "moment";
import { useMemo } from "react";
import { roundToDecimalPlace } from "src/lib/formatters";

export default function useHoursBetweenDates(
	startDate?: string,
	endDate?: string,
	breakNum: number = 0
) {
	return useMemo(() => {
		if (!startDate || !endDate) {
			return null;
		}
		const duration = moment.duration(
			moment(endDate).diff(moment(startDate))
		);
		return roundToDecimalPlace(duration.asHours(), 2) - (breakNum || 0);
	}, [startDate, endDate, breakNum]);
}
