import { useTranslation } from "react-i18next";
import Input from "src/components/form/Input";
import OfferForm from "src/components/offer/forms/OfferForm";
import useCreateOffer from "src/hooks/api/useCreateOffer";
import useOfferScheme from "src/hooks/schemes/useOfferScheme";
import useAuth from "src/hooks/selectors/useAuth";

const OfferDescriptionForm = () => {
	const { t } = useTranslation("offer", {
		keyPrefix: "form",
	});
	const { setCurrent, offer } = useCreateOffer();
	const auth = useAuth();
	const scheme = useOfferScheme();

	return (
		<OfferForm
			onSubmit={async () => {
				setCurrent(2);
				return;
			}}
			value={{
				description_intro:
					offer?.description_intro || offer?.description?.intro,
				description_what:
					offer?.description_what || offer?.description?.what,
				description_how:
					offer?.description_how || offer?.description?.how,
			}}
			schema={() => ({
				description_intro: scheme.description.intro,
				description_what: scheme.description.what,
				description_how: scheme.description.how,
			})}
		>
			{(getInputProps) => (
				<>
					<Input
						label={t("description.intro")}
						placeholder={t("description.intro-placeholder", {
							name: auth.first_name,
						})}
						multiline
						{...getInputProps("description_intro")}
					/>
					<Input
						label={t("description.what")}
						placeholder={t("description.what-placeholder")}
						multiline
						{...getInputProps("description_what")}
					/>
					<Input
						label={t("description.how")}
						placeholder={t("description.how-placeholder")}
						multiline
						{...getInputProps("description_how")}
					/>
				</>
			)}
		</OfferForm>
	);
};

export default OfferDescriptionForm;
