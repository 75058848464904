import React from "react";
import useWindowSize from "src/hooks/useWindowSize";

interface CtaBarProps {
	children?: any;
	boxClassName?: string;
	className?: string;
}

const CtaBar: React.FC<CtaBarProps & React.HTMLProps<HTMLDivElement>> = ({
	children,
	boxClassName,
	className,
	...rest
}: CtaBarProps) => {
	const size = useWindowSize();
	return (
		<div
			{...rest}
			className={`fixed left-0 right-0 bottom-0 z-40 ${className || ""}`}
		>
			<div
				className={`bg-background border-2 border-border shadow-md-top rounded-t-md ${
					!size.isPhone ? "container-md" : ""
				} ${boxClassName || ""}`}
			>
				{children}
			</div>
		</div>
	);
};

export default CtaBar;
