import Card from "src/components/Card";
import Loader from "src/components/Loader";
import { cn } from "src/lib/utils";

interface TableProps {
	variant: "card" | "flat";
	fetching?: boolean;
	children?: any;
	headers?: any[];
	footer?: any;
	className?: string;
}

const Table = ({
	variant,
	fetching,
	children,
	headers,
	footer,
	className: _className,
}: TableProps) => {
	let className = "w-full leading-normal";

	if (variant === "flat") {
		return (
			<div
				className={cn(
					"lg:overflow-x-auto bg-background hide-default-scrollbar",
					_className
				)}
			>
				<table {...{ className }}>
					<thead className="bg-background border-b-[1px] border-border">
						<tr>
							{headers?.map((item, index) => (
								<th
									className="py-3 px-6 text-left font-bold relative"
									key={item}
								>
									<p className="mb-0 whitespace-nowrap">
										{item}
									</p>
									{index === 0 && fetching && (
										<div className="absolute top-0 right-0 bottom-0 flex items-center">
											<Loader />
										</div>
									)}
								</th>
							))}
						</tr>
					</thead>
					<tbody>{children}</tbody>
					<tfoot>{footer}</tfoot>
				</table>
			</div>
		);
	}

	return (
		<Card
			type="no-padding"
			className={cn("overflow-x-auto bg-background", _className)}
		>
			<table {...{ className }}>
				<thead className="bg-background border-b-[1px] border-border">
					<tr>
						{headers?.map((item) => (
							<th
								className="py-4 p-6 text-left font-bold"
								key={item}
							>
								<p className="mb-0 whitespace-nowrap">{item}</p>
							</th>
						))}
					</tr>
				</thead>
				<tbody>{children}</tbody>
				<tfoot>{footer}</tfoot>
			</table>
		</Card>
	);
};

Table.defaultProps = {
	variant: "card",
};

interface Props {
	children?: any;
	className?: string;
}

interface TableRowProps extends Props {}

const TableRow = ({ children, className }: TableRowProps) => {
	return (
		<tr
			className={cn(
				"border-b border-accent dark:border-background last:border-transparent",
				className
			)}
		>
			{children}
		</tr>
	);
};

interface TableCellProps extends Props {
	colSpan?: number;
}

const TableCell = ({ children, className, ...rest }: TableCellProps) => {
	return (
		<td {...rest} className={cn("px-6 py-4 text-left w-max", className)}>
			{children}
		</td>
	);
};

Table.Row = TableRow;
Table.Cell = TableCell;

export default Table;
