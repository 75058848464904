import moment from "moment";
import Link from "src/components/Link";
import FocusModeSwitch from "src/components/forms/FocusModeSwitch";
import withTranslation, {
	Translation,
} from "src/components/hoc/withTranslation";
import Icon from "src/components/icon/Icon";
import useAuth from "src/hooks/selectors/useAuth";
import useWindowSize from "src/hooks/useWindowSize";
import { ucFirst } from "src/lib/formatters";

interface DashboardHeaderProps extends Translation {}

const DashboardHeader = ({ t }: DashboardHeaderProps) => {
	const auth = useAuth();
	const { breakpoint } = useWindowSize();
	if (["xs", "sm", "md"].includes(breakpoint)) {
		return (
			<div className="flex flex-col gap-6">
				<div className="flex items-center justify-between">
					<div className="flex flex-col gap-1">
						<h1 className="text-[32px] line-clamp-1">
							{t("greeting", {
								name: auth.first_name,
							})}
						</h1>
						<p className="opacity-40 leading-full line-clamp-1">
							{ucFirst(moment().format(t("date-format")))}
						</p>
					</div>
					<FocusModeSwitch />
				</div>
				<Link
					className="flex items-center justify-between gap-2 rounded-md bg-accent border border-border p-1"
					to={{
						modal: "/dashboard/search",
					}}
				>
					<p className="text-placeholder px-4 line-clamp-1">
						{t("search")}
					</p>
					<Icon
						icon={Icon.getFontAwesome("fa-search")}
						className="w-9 h-9 bg-accent rounded-full hover:bg-accent text-border"
					/>
				</Link>
			</div>
		);
	}

	return (
		<div className="flex pb-8 justify-between items-center border-b border-border">
			<div className="flex flex-col gap-1">
				<h1 className="text-[32px] line-clamp-1">
					{t("greeting", {
						name: auth.first_name,
					})}
				</h1>
				<p className="opacity-40 leading-full line-clamp-1">
					{ucFirst(moment().format(t("date-format")))}
				</p>
			</div>
			<div className="flex items-center gap-4">
				<FocusModeSwitch />
				<Link
					className="flex items-center gap-2 rounded-md bg-accent border border-border p-1"
					to={{
						modal: "/dashboard/search",
					}}
				>
					<p className="text-placeholder px-4 line-clamp-1">
						{t("search")}
					</p>
					<Icon
						icon={Icon.getFontAwesome("fa-search")}
						className="w-9 h-9 bg-accent rounded-full hover:bg-accent text-border"
					/>
				</Link>
			</div>
		</div>
	);
};

DashboardHeader.locale = {
	nl: {
		greeting: "Hallo {{name}}",
		"date-format": "dddd, DD MMMM",
		search: "Zoek taken...",
	},
	en: {
		greeting: "Hello {{name}}",
		"date-format": "dddd, MMMM DD",
		search: "Search tasks...",
	},
};

export default withTranslation(DashboardHeader);
