import { useRef, useState } from "react";
import Loader from "src/components/Loader";
import withTranslation, {
	Translation,
} from "src/components/hoc/withTranslation";
import { CommandInput } from "src/components/ui/command";
import { useUpdateEffectDebounce } from "src/hooks/useDebounce";

interface SelectSearchProps extends Translation {
	placeholder?: string;
	onChange?: (search: string) => void;
	loading?: boolean;
	value?: string;
	autoFocus?: boolean;
	className?: string;
}

const SelectSearch = ({
	t,
	placeholder,
	onChange,
	loading,
	value,
	className,
}: SelectSearchProps) => {
	const input = useRef<HTMLInputElement>(null);
	const [search, setSearch] = useState<string>(value || "");
	useUpdateEffectDebounce(search, 500, (value) => {
		if (onChange) {
			onChange(value);
		}
	});

	const test = 1;

	if (test) {
		return (
			<CommandInput
				{...{ className }}
				placeholder={placeholder}
				{...(onChange
					? {
							value: search,
							onValueChange: setSearch,
					  }
					: {})}
			/>
		);
	}

	return (
		<div className="relative">
			<div className="pl-4 absolute top-0 bottom-0 left-0 items-center flex pointer-events-none">
				<div className="w-4">
					<i className="far fa-search text-[14px]"></i>
				</div>
			</div>
			<input
				ref={input}
				name="search"
				onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) =>
					e.stopPropagation()
				}
				placeholder={placeholder || t("placeholder")}
				className="py-3 pl-10 pr-4 rounded-md focus:bg-accent w-full placeholder-placeholder"
				required
				value={search}
				onChange={(e) => {
					e.preventDefault();
					e.stopPropagation();
					setSearch(e.target.value);
				}}
			/>
			{loading && (
				<div className="pr-4 absolute top-0 bottom-0 right-0 items-center flex pointer-events-none">
					<div className="w-4">
						<Loader />
					</div>
				</div>
			)}
		</div>
	);
};

SelectSearch.locale = {
	nl: {
		placeholder: "Zoeken...",
	},
	en: {
		placeholder: "Search...",
	},
};

export default withTranslation(SelectSearch);
