import { useParams } from "react-router-dom";
import TaskEditableTitle from "src/components/tasks/TaskEditableTitle";
import useTask from "src/hooks/api/services/tasks/task/useTask";

interface TaskModalHeadProps {
	autoFocus?: boolean;
}

const TaskModalHead = ({ autoFocus }: TaskModalHeadProps) => {
	const { id } = useParams();
	const { task } = useTask({ id });

	return (
		<div className="flex-1 flex-col md:flex-row md:min-h-10 max-h-full flex gap-3">
			{task?.links?.repeat ? (
				<div className="h-7 flex items-center">
					<i className="far fa-retweet text-border text-[15px]"></i>
				</div>
			) : null}
			<TaskEditableTitle variant="medium" {...{ autoFocus }} />
		</div>
	);
};

export default TaskModalHead;
