import type { Active, DropAnimation } from "@dnd-kit/core";
import {
	DndContext,
	DragOverlay,
	KeyboardSensor,
	PointerSensor,
	defaultDropAnimationSideEffects,
	useSensor,
	useSensors,
} from "@dnd-kit/core";
import {
	SortableContext,
	arrayMove,
	sortableKeyboardCoordinates,
} from "@dnd-kit/sortable";
import type { PropsWithChildren } from "react";
import { useMemo, useState } from "react";
import { ApiForm } from "src/api/types";
import Button from "src/components/Button";
import CustomForm from "src/components/custom-form/CustomForm";
import CustomFormEditField, {
	CustomFormEditFieldValue,
} from "src/components/custom-form/CustomFormEditField";
import withTranslation, {
	Translation,
} from "src/components/hoc/withTranslation";
import { sleep } from "src/lib/helpers";

function generateRandomId(length: number) {
	const characters =
		"ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
	const charactersLength = characters.length;
	let result = new Array(length);

	for (let i = 0; i < length; i++) {
		result[i] = characters[Math.floor(Math.random() * charactersLength)];
	}

	return result.join("");
}

export type CustomFormType = "CONNECTION" | "PAYMENT_REQUEST";

interface CustomFormEditFormProps extends Translation {
	form: ApiForm;
	onUpdate: (fields: CustomFormEditFieldValue[]) => Promise<ApiForm>;
	variant?: "form" | "form-with-preview";
	type?: CustomFormType;
}

const CustomFormEditForm = ({
	t,
	form,
	onUpdate,
	variant = "form",
	type = "PAYMENT_REQUEST",
}: CustomFormEditFormProps) => {
	const sensors = useSensors(
		useSensor(PointerSensor),
		useSensor(KeyboardSensor, {
			coordinateGetter: sortableKeyboardCoordinates,
		})
	);

	const [active, setActive] = useState<Active | null>(null);
	const [isUpdatingFields, setIsUpdatingFields] = useState(false);
	const [fields, setFields] = useState<CustomFormEditFieldValue[]>(
		form?.fields || []
	);
	const activeItem = useMemo(
		() =>
			fields.find(
				(item) => item.id === active?.id || item._id === active?.id
			),
		[active, fields]
	);
	const orderedFields = fields.sort((a, b) => a.order - b.order);

	const handleUpdateFields = async () => {
		setIsUpdatingFields(true);
		try {
			const result = await onUpdate(fields);
			setFields(result?.fields || []);
			setIsUpdatingFields(false);
		} catch (error) {
			setIsUpdatingFields(false);
		}
	};

	const handleNewField = () => {
		setFields([
			...fields,
			{
				_id: generateRandomId(12),
				order: fields.length + 1,
				fillable_by: "ALL",
				is_filterable: true,
			} as CustomFormEditFieldValue,
		]);
	};

	return (
		<div className="flex gap-6">
			<div className="flex flex-col gap-6 flex-1">
				<DndContext
					{...{ sensors }}
					onDragStart={({ active }) => {
						console.log(active);
						setActive(active);
					}}
					onDragEnd={({ active, over }) => {
						if (over && active.id !== over?.id) {
							const activeIndex = fields.findIndex(
								(item) =>
									item.id === active.id ||
									item._id === active.id
							);
							const overIndex = fields.findIndex(
								(item) =>
									item.id === over.id ||
									item._id === active.id
							);

							setFields(
								arrayMove(fields, activeIndex, overIndex).map(
									(item, index) => ({
										...item,
										order: index + 1,
									})
								)
							);
						}
						setActive(null);
					}}
					onDragCancel={() => {
						setActive(null);
					}}
				>
					<div className="flex flex-col empty:hidden gap-4">
						<SortableContext
							items={orderedFields.map(
								(item) => item.id || item?._id || ""
							)}
						>
							{orderedFields.map((field, index) => (
								<CustomFormEditField
									key={`field-${index}`}
									value={field}
									{...{ type }}
									onDelete={() =>
										setFields(
											fields.filter(
												(item, itemIndex) =>
													itemIndex !== index
											)
										)
									}
									onChange={(value) =>
										setFields(
											fields.map((item, itemIndex) =>
												itemIndex === index
													? value
													: item
											)
										)
									}
								/>
							))}
						</SortableContext>
						<SortableOverlay>
							{activeItem ? (
								<CustomFormEditField
									className="bg-background"
									value={activeItem}
									onDelete={() => {}}
									onChange={(value) => {}}
								/>
							) : null}
						</SortableOverlay>
						<Button xsmall type="border" onClick={handleNewField}>
							{t("buttons.new")}
						</Button>
					</div>
					<hr className="border-border" />
					<div className="flex">
						<Button
							loading={isUpdatingFields}
							onClick={handleUpdateFields}
						>
							{t("buttons.save")}
						</Button>
					</div>
				</DndContext>
			</div>
			{variant === "form-with-preview" && (
				<div className="hidden md:flex flex-col w-full max-w-[400px]">
					<div className="bg-accent flex flex-col p-6 rounded-md max-w-[400px] w-full">
						<CustomForm
							className="bg-background rounded-md p-6 shadow"
							form={{
								...form,
								fields: orderedFields as any[],
							}}
							onSubmit={async () => {
								await sleep(2000);
							}}
						/>
					</div>
				</div>
			)}
		</div>
	);
};

CustomFormEditForm.locale = {
	nl: {
		buttons: {
			save: "Opslaan",
			new: "Nieuw veld",
		},
	},
	en: {
		buttons: {
			save: "Save",
			new: "New field",
		},
	},
};

export default withTranslation(CustomFormEditForm);

const dropAnimationConfig: DropAnimation = {
	sideEffects: defaultDropAnimationSideEffects({
		styles: {
			active: {
				opacity: "0.4",
			},
		},
	}),
};

interface Props {}

export function SortableOverlay({ children }: PropsWithChildren<Props>) {
	return (
		<DragOverlay dropAnimation={dropAnimationConfig}>
			{children}
		</DragOverlay>
	);
}
