import { Route } from "react-router-dom";
import ExperienceFormModal from "src/components/resume/elements/experience-form/ExperienceFormModal";
import ProtectedRoute from "src/routes/middlewares/ProtectedRoute";
import OnboardingView from "src/views/authenticated/onboarding/OnboardingView";

const OnboardingRoutes = [
	<Route
		element={
			<ProtectedRoute>
				<OnboardingView />
			</ProtectedRoute>
		}
		path="/onboarding"
		key="/onboarding"
	/>,
];

export const OnboardingModalRoutes = [
	<Route
		path="/onboarding/experiences/:type/:id/edit"
		key="/onboarding/experiences/:type/:id/edit"
		element={
			<ProtectedRoute
				variant="modal"
				modal={{
					backUrl: "/onboarding",
					expanded: false,
					size: "default",
				}}
			>
				<ExperienceFormModal />
			</ProtectedRoute>
		}
	/>,
];

export default OnboardingRoutes;
