import { useEffect, useState } from "react";
import { ApiBoard } from "src/api/types";
import withTranslation, {
	Translation,
} from "src/components/hoc/withTranslation";
import useBoardForm from "src/hooks/api/tasks/useBoardForm";
import Button from "src/components/Button";
import useBoards from "src/hooks/api/services/tasks/useBoards";
import useAlert from "src/hooks/useAlert";
import Input from "src/components/form/Input";

interface BoardMissingContextProps extends Translation {
	board: ApiBoard;
}

const BoardMissingContext = ({
	t,
	board: _board,
}: BoardMissingContextProps) => {
	const [sendAlert] = useAlert();
	const { board, actions } = useBoardForm();
	const [loading, setLoading] = useState<"submit" | "skip">();
	const { actions: boardsActions, api: boardApi } = useBoards();
	const isNotEditable = !board?.can?.edit;

	const onSubmit = () => {
		if (isNotEditable || !board.hid || !board?.description) return;
		setLoading("submit");
		boardApi
			.patch(`/${board.hid}/context`, {
				description: board?.description,
				skipped_skills_at: null,
			})
			.then(({ data: { data } }) => {
				boardsActions.setItem(
					{ data },
					{
						updateList: true,
					}
				);
				console.log(data);
			})
			.finally(() => {
				setLoading(undefined);
			});

		// boardsActions.update(
		// 	{
		// 		id: board.hid,
		// 		skills: board.skills,
		// 		skipped_skills_at: null,
		// 	},
		// 	{
		// 		updateList: true,
		// 	}
		// );
	};

	const onContinueWithoutSkills = () => {
		if (isNotEditable || !board.hid) return;
		sendAlert({
			type: "confirm",
			title: t("skip.confirm.title"),
			text: t("skip.confirm.description"),
			onConfirm: () => {
				setLoading("skip");

				boardsActions
					.update(
						{
							id: board.hid,
							type: "NOINDEX",
							skipped_skills_at: true,
						},
						{
							updateList: true,
						}
					)
					.finally(() => setLoading(undefined));
			},
		});
	};

	useEffect(() => {
		actions.set({ ..._board, description: {} });
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<div className="mt-6 gap-6 flex flex-col max-w-md mx-auto">
			<div className="flex flex-col gap-2">
				<h2>
					{t("title", {
						name: board.name,
					})}
				</h2>
				{/* <p>{t("description")}</p> */}
			</div>
			<div className="flex flex-col gap-4 mt-4">
				{/* <Text.Eyebrow className="opacity-100">
					{t("label")}
				</Text.Eyebrow> */}

				<Input
					autoFocus
					label={t("fields.description.label")}
					name="description"
					placeholder={t("fields.description.placeholder")}
					multiline
					value={board.description?.description}
					onChange={(key: string, value) =>
						actions.set({
							description: {
								...(board?.description || {}),
								description: value,
							},
						})
					}
				/>

				<Input
					autoFocus
					label={t("fields.techniques.label")}
					name="techniques"
					placeholder={t("fields.techniques.placeholder")}
					multiline
					value={board.description?.techniques}
					onChange={(key: string, value) =>
						actions.set({
							description: {
								...(board?.description || {}),
								techniques: value,
							},
						})
					}
				/>

				<p className="text-sm opacity-70 italic">{t("better-input")}</p>

				{/* <SkillSelector.Root
					disabled={isNotEditable}
					defaultValue={board.name}
					onChange={(skills) =>
						!isNotEditable ? actions.set({ skills }) : null
					}
					value={board.skills}
					defaultSearch={board.team}
					theme={{
						active: {
							className: "bg-primary border border-primary",
						},
						inactive: {
							className:
								"bg-border text-background-foreground border border-border",
						},
						default: {
							size: "small",
						},
					}}
				>
					<SkillSelector.Results />
					<SkillSelector.Input />
				</SkillSelector.Root> */}
				<div className="flex items-center gap-2 mt-4">
					<Button
						className="w-max"
						onClick={onSubmit}
						loading={loading === "submit"}
						disabled={!!loading || isNotEditable}
					>
						{t("save")}
					</Button>
					<Button
						className="w-max"
						onClick={onContinueWithoutSkills}
						loading={loading === "skip"}
						disabled={isNotEditable || !!loading}
						type="transparent"
					>
						{t("skip.button")}
					</Button>
				</div>
				{isNotEditable && (
					<p className="mt-2 italic">
						{t("not-editable.description")}
					</p>
				)}
			</div>
			<hr className="border-border my-4" />
			<div className="flex flex-col gap-3">
				<h2>{t("why")}</h2>
				<p>{t("description")}</p>
			</div>
		</div>
	);
};

BoardMissingContext.locale = {
	nl: {
		title: "Het bord '{{name}}' heeft momenteel geen context",
		why: "Maar waarom?",
		description:
			"Om de vaardigheden van onze AI effectiever te laten aansluiten bij een taak, is het essentieel dat de AI de context van het bord begrijpt. Op deze manier kunnen er meer relevante vaardigheden worden gekoppeld aan een specifieke taak.",
		"better-input":
			"Probeer zo duidelijk mogelijk de context van het bord te omschrijven. Hierdoor kan onze AI later beter skills matchen bij taken",
		fields: {
			description: {
				label: "Welk doel wil je bereiken binnen dit bord",
				placeholder:
					"Binnen dit bord werken we met de volgende functionaliteiten en ons doel is...",
			},
			techniques: {
				label: "Welke technieken worden er gebruikt",
				placeholder: "Bijvoorbeeld: Microsoft Teams, HTML, CRM...",
			},
		},
		"not-editable": {
			description:
				"Raadpleeg de eigenaar van dit bord om vaardigheden te selecteren",
		},
		save: "Opslaan",
		skip: {
			button: "Doorgaan zonder skills",
			confirm: {
				title: "Weet je het zeker",
				description:
					"Je voortgang in skills wordt dan niet bijgehouden",
			},
		},
	},
	en: {
		title: "The board '{{name}}' currently lacks context",
		why: "But why?",
		description:
			"To make the skills of our AI more effectively align with a task, it is essential for the AI to understand the context of the board. This way, more relevant skills can be matched to a specific task.",
		"better-input":
			"Try to describe the context of the board as clearly as possible. This will help our AI better match skills for tasks in the future.",
		fields: {
			description: {
				label: "What goal do you want to achieve within this board",
				placeholder:
					"Within this board, we work with the following functionalities, and our goal is...",
			},
			techniques: {
				label: "Which techniques are used",
				placeholder: "For example: Microsoft Teams, HTML, CRM...",
			},
		},
		"not-editable": {
			description: "Consult the owner of this board to select skills.",
		},
		save: "Save",
		skip: {
			button: "Continue without skills",
			confirm: {
				title: "Are you sure",
				description: "You won't be able to progress your skills",
			},
		},
	},
};

export default withTranslation(BoardMissingContext);
