const middlewares = {
	"multiple-companies": {
		title: "Vestigingen",
		heading: "Je hebt een premium abonnement nodig voor deze functie",
		description:
			"Om gebruik te maken van meerdere vestigingen dien je een <1>Premium</1> abonnement te hebben.",
	},
	network: {
		title: "Connections",
		heading: "Je hebt een premium abonnement nodig voor deze functie",
		description:
			"Om gebruik te maken van meerdere vestigingen dien je een <1>Premium</1> abonnement te hebben.",
	},
};

export default middlewares;
