import { useRef, useState } from "react";
import Wrapper, { WrapperProps } from "src/components/field/utils/Wrapper";

interface TimeProps {
	wrapper?: WrapperProps;
	value?: number | string;
	onChange: (value: number | string) => void;
}

const Time = ({ wrapper, value, onChange }: TimeProps) => {
	const [defaultValue, setDefaultValue] = useState(value);
	const input: any = useRef();

	//Format the float to hours + minutes
	function formatNumericInput(hours: number) {
		//Transofrm the text to float (input can be 60.)
		hours = parseFloat(hours.toString());
		if (isNaN(hours)) return "0h 0m";

		//Get the whole hours
		const wholeHours = Math.floor(hours);
		//Get the minutes
		const minutes = Math.round((hours - wholeHours) * 60);
		//Return the formatted string
		return `${wholeHours}h ${minutes}m`;
	}

	const handleInputChange = (event: any) => {
		onChange(event.target.value);
		setDefaultValue(event.target.value);
	};

	const checkInputChange = () => {
		if (defaultValue) {
			onChange(defaultValue);
		}
	};

	return (
		<Wrapper
			{...{
				...wrapper,
				onFocus: () => input?.current?.focus(),
			}}
		>
			<div className="flex flex-row items-center gap-2 ml-1">
				<input
					className="w-[75px] pl-2 pr-1 py-1 border rounded-md border-border bg-background placeholder:text-placeholder"
					placeholder="0.00"
					name="estimated"
					type="number"
					step={0.25}
					value={defaultValue || ""}
					onChange={handleInputChange}
					onBlur={checkInputChange}
				/>
				<p className="text-placeholder">
					{formatNumericInput(
						parseFloat(defaultValue?.toString() || "")
					)}
				</p>
			</div>
		</Wrapper>
	);
};

export default Time;
