import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { ApiIssuer } from "src/api/types";
import { updateIssuer } from "src/redux/issuer";

const useIssuer = () => {
	const issuer = useSelector((state: any) => state.issuer);
	const dispatch = useDispatch();

	const fetchIssuers = async () => {
		axios
			.get("/client/issuers?type=prefetch")
			.then(({ data }) => {
				let ideal = [] as ApiIssuer[];
				let mandate = [] as ApiIssuer[];

				(data?.data || []).map((item: ApiIssuer) => {
					if (item.object.startsWith("ideal")) ideal.push(item);
					if (item.object.startsWith("mandate")) mandate.push(item);
					return item;
				});
				dispatch(
					updateIssuer({
						mandate,
						ideal,
						loading: false,
					})
				);
			})
			.catch(() => {
				dispatch(updateIssuer({ loading: false }));
			});
	};

	return {
		...issuer,
		fetchIssuers,
	};
};

export default useIssuer;
