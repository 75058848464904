import moment from "moment";
import React from "react";
import { useTranslation } from "react-i18next";
import { ApiOffer } from "src/api/types";
import Badge from "src/components/Badge";
import Card from "src/components/Card";
import OfferStatisticsCard from "src/components/offer/cards/OfferStatisticsCard";
import ReadMore from "src/components/ReadMore";
import Skeleton from "src/components/Skeleton";

interface OfferCardProps {
	offer: ApiOffer;
}

const OfferCard = ({ offer }: OfferCardProps) => {
	const { t } = useTranslation("common");
	return (
		<Card to={`/offers/${offer.slug}/show`} className=" ">
			<div className="flex flex-col gap-4">
				<div className="flex gap-4 items-center justify-between">
					<div className="flex flex-wrap items-center gap-2">
						<h2 className="text-[20px]">{offer.title}</h2>
						<Badge
							style={{
								backgroundColor: "#2870D120",
							}}
							bg=" "
							color="blue"
						>
							{offer.category.name}
						</Badge>
						{offer.visibility.status === "CONCEPT" && (
							<Badge bg="gray" color="dark">
								{t("concept")}
							</Badge>
						)}
						{offer.visibility.status === "PUBLISHED" &&
							offer.visibility.is_verified && (
								<Badge bg="green-200" color="green">
									{t("live")}
								</Badge>
							)}
					</div>

					{offer?.unread_messages ? (
						<>
							<strong className="text-red">
								{t("unread-messages", {
									count: offer?.unread_messages || 0,
								})}
							</strong>
						</>
					) : (
						false
					)}
				</div>
				<div className="">
					<ReadMore
						className="mb-0"
						text={offer?.description.intro || ""}
						length={200}
					/>
				</div>
				<div className="flex flex-wrap items-center justify-between">
					<OfferStatisticsCard variant="minimal" {...{ offer }} />
					<div className="flex items-center gap-4 text-[16px]">
						<span className="opacity-20">
							{moment(offer.created_at).fromNow()}
						</span>
						<span
							className={
								offer.visibility.is_verified
									? "text-green flex items-center gap-1"
									: "opacity-20"
							}
						>
							{offer.visibility.is_verified ? (
								<>
									<img
										src="/assets/images/emoji/check.png"
										className="h-4"
										alt=""
									/>
									{t("accepted")}
								</>
							) : (
								t("pending")
							)}
						</span>
					</div>
				</div>
			</div>
		</Card>
	);
};

OfferCard.Loading = () => (
	<Card>
		<div className="flex gap-4 items-center justify-between">
			<Skeleton className="h-8 w-[30%]" />
			<Skeleton className="h-8 w-[20%]" />
		</div>
		<div className="flex flex-col gap-4 mt-4">
			<Skeleton className="h-6 w-full" />
			<Skeleton className="h-6 w-full" />
			<Skeleton className="h-6 w-full" />
		</div>
		<div className="flex items-center gap-4 mt-4 justify-between">
			<div className="flex items-center gap-4">
				<Skeleton className="h-6 w-12" />
				<Skeleton className="h-6 w-12" />
				<Skeleton className="h-6 w-12" />
			</div>
			<Skeleton className="h-6 w-12" />
		</div>
	</Card>
);

export default OfferCard;
