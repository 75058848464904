import { useEffect, useRef } from "react";

export default function useAutosizeTextArea() {
	const ref = useRef<HTMLTextAreaElement>(null);
	const value = ref?.current?.value;

	useEffect(() => {
		if (ref?.current) {
			ref.current.style.height = "0px";
			const scrollHeight = ref.current.scrollHeight;
			ref.current.style.height = scrollHeight + "px";
		}
	}, [ref, value]);

	return {
		ref,
	};
}
