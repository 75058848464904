import ReactTooltip from "react-tooltip";
import { ApiBadge } from "src/api/types";
import ProfileBadgeElement from "./ProfileBadgeElement";

interface ProfileBadgeProps {
	badge: ApiBadge;
	variant: "small" | "resume" | "logo-only" | "resume-list" | "horizontal";
	className?: string;
	badgeClassName?: string;
}

const ProfileBadge = ({
	badge,
	variant,
	className,
	badgeClassName,
}: ProfileBadgeProps) => {
	let _badgeClassName = badgeClassName || "";

	if (!badgeClassName?.includes("w-")) {
		_badgeClassName += ` w-24`;
	}

	if (variant === "logo-only") {
		let _className = ` ${className || ""}`;
		if (!_className?.includes("w-")) {
			_className += " w-24";
		}
		return (
			<>
				<div data-tip data-for={`tooltip-${badge.type}`}>
					<ProfileBadgeElement
						className={_className}
						type={badge.type}
					/>
				</div>
				<ReactTooltip id={`tooltip-${badge.type}`} effect="solid">
					{badge.title}
				</ReactTooltip>
			</>
		);
	}

	if (variant === "resume-list") {
		return (
			<div
				className={`flex flex-col md:flex-row flex-wrap justify-center md:items-center gap-4 ${
					className || ""
				}`}
			>
				<ProfileBadgeElement
					className="w-16 md:w-20"
					type={badge.type}
				/>
				<div className="flex-1 flex md:text-left flex-col">
					<strong className="block">{badge.title}</strong>
					<span>{badge.description}</span>
				</div>
			</div>
		);
	}

	if (variant === "horizontal") {
		return (
			<div
				className={`flex flex-row flex-wrap justify-center items-center gap-4 ${
					className || ""
				}`}
			>
				<ProfileBadgeElement className="w-12" type={badge.type} />
				<div className="flex-1">
					<strong className="block">{badge.title}</strong>
					<span>{badge.description}</span>
				</div>
			</div>
		);
	}

	return (
		<div
			className={`flex flex-col flex-wrap justify-center items-center gap-4 ${
				className || ""
			}`}
		>
			<ProfileBadgeElement
				className={_badgeClassName}
				type={badge.type}
			/>
			<div className="flex-1 text-center">
				<strong className="block">{badge.title}</strong>
				<span>{badge.description}</span>
			</div>
		</div>
	);
};

interface RowProps {
	badges: ApiBadge[];
}

ProfileBadge.Row = ({ badges }: RowProps) => {
	if (badges.length === 0) {
		return <></>;
	}
	return (
		<div className="flex flex-row">
			{badges.map((badge, index) => (
				<div
					key={`header-${badge.type}`}
					className={index !== 0 ? `-ml-2` : undefined}
				>
					<ProfileBadge
						variant="logo-only"
						className="w-[32px]"
						{...{ badge }}
					/>
				</div>
			))}
		</div>
	);
};

export default ProfileBadge;
