import { useEffect } from "react";
import Card from "src/components/Card";
import EmptyState from "src/components/EmptyState";
import withTranslation, {
	Translation,
} from "src/components/hoc/withTranslation";
import TaskList from "src/components/tasks/list/TaskList";
import useMyOverdueTasks from "src/hooks/api/services/tasks/useMyOverdueTasks";

interface MyOverdueTasksCardProps extends Translation {
	className?: string;
}

const MyOverdueTasksCard = ({ t, className }: MyOverdueTasksCardProps) => {
	const { tasks, pagination, status, actions } = useMyOverdueTasks();

	useEffect(() => {
		actions.list({});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<Card {...{ className }}>
			<Card.Head
				className="flex-row justify-between"
				title={t("title")}
				icon={{
					font_awesome: {
						name: "fas fa-clock",
					},
				}}
			/>
			<div className="flex flex-col gap-4">
				<TaskList
					{...{ tasks }}
					draggable={{
						id: "DATE:OVERDUE",
					}}
					onUpdate={(id, value) =>
						actions.update(
							{
								id,
								...value,
							},
							{
								updateList: true,
							}
						)
					}
					loading={status === "loading"}
					empty={
						tasks.length === 0 &&
						status === "idle" &&
						pagination.page >= pagination.last_page && (
							<EmptyState
								to={{
									modal: "./modals/tasks/create",
								}}
								icon="fas fa-check-circle"
								title={t("empty.title")}
								description={t("empty.description")}
							/>
						)
					}
				/>
				{pagination && pagination.page && pagination.last_page ? (
					<Card.LoadMore
						variant="more"
						value={false}
						onClick={() => {
							actions.list(
								{
									page:
										tasks.length === 0 &&
										pagination.last_page > pagination.page
											? 1
											: pagination.page + 1,
								},
								{
									appendResult: true,
								}
							);
						}}
					/>
				) : (
					false
				)}
			</div>
		</Card>
	);
};

MyOverdueTasksCard.locale = {
	nl: {
		title: "Achterstallige taken",
		empty: {
			title: "Je bent up to date",
			description: "Momenteel heb je geen achterstallige taken",
		},
	},
	en: {
		title: "Overdue tasks",
		empty: {
			title: "Your up to date",
			description: "You have no overdue tasks",
		},
	},
};

export default withTranslation(MyOverdueTasksCard);
