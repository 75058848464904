import { FormEvent, useEffect, useState } from "react";
import Button from "src/components/Button";
import Input from "src/components/form/Input";
import withTranslation, {
	Translation,
} from "src/components/hoc/withTranslation";
import useResume from "src/hooks/api/services/resume/useResume";
import useAuth from "src/hooks/selectors/useAuth";
import useAlert from "src/hooks/useAlert";
import useModal from "src/hooks/useModal";
import { VALID_SOCIALS } from "src/lib/constants";
import { cn } from "src/lib/utils";

const formatSocialMediaToValue = (social_media: any) => {
	if (!social_media.length) return {};
	return social_media.reduce((acc: any, curr: any) => {
		acc[curr.platform] = curr.direct_link;
		return acc;
	}, {});
};

interface ResumeSocialMediaProps extends Translation {
	editable?: boolean;
}

const ResumeSocialMedia = ({ t, editable }: ResumeSocialMediaProps) => {
	const auth = useAuth();
	const [sendAlert] = useAlert();
	useModal({
		title: t("title"),
	});

	const { actions, resume, api } = useResume({
		rememberKey: auth.hid,
	});
	const [value, setValue] = useState<any>(() => {
		return formatSocialMediaToValue(resume?.social_media || []);
	});

	useEffect(() => {
		if (resume.hid === auth.hid) {
			setValue(formatSocialMediaToValue(resume?.social_media || []));
		} else {
			actions.get(auth.hid).then((resume) => {
				setValue(formatSocialMediaToValue(resume?.social_media || []));
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleSubmit = async (e: FormEvent) => {
		e.preventDefault();

		return api
			.post(`/${auth.hid}/social-media/multi`, {
				links: value,
			})
			.then(({ data: { data } }) => {
				setValue(formatSocialMediaToValue(data));
				actions.setItem({
					data: {
						...resume,
						social_media: data,
					},
				});
			})
			.catch(() =>
				sendAlert({
					title: t("error-save.title"),
					text: t("error-save.text"),
					type: "error",
				})
			);
	};

	if (editable) {
		return (
			<div className="flex flex-col">
				<ul className="flex flex-col gap-2">
					{VALID_SOCIALS.map((social: any) => (
						<li
							key={social.name}
							className="flex gap-2 items-center"
						>
							<div className="flex rounded-md justify-center items-center w-8">
								<i className={social.icon}></i>
							</div>
							<Input
								placeholder={social.label}
								value={value[social.name] || ""}
								onChange={(_, value) =>
									setValue((state: any) => ({
										...state,
										[social.name]: value,
									}))
								}
								name={social.name}
							/>
						</li>
					))}
				</ul>
				<div className="flex mt-4">
					<Button onClick={handleSubmit}>{t("save")}</Button>
				</div>
			</div>
		);
	}

	if ((resume.social_media || []).length === 0) {
		return (
			<div className="flex flex-col justify-center flex-1 items-center p-8">
				<strong>{t("empty.title")}</strong>
			</div>
		);
	}

	return (
		<div className="flex flex-row flex-wrap gap-4">
			{(resume.social_media || []).map((social: any) => (
				<a
					key={social.name}
					className={cn(
						`flex rounded-md px-4 py-2 items-center border border-transparent cursor-pointer justify-between gap-3
                        hover:bg-primary`
					)}
					href={social.direct_link}
					target="_blank"
					rel="noreferrer"
				>
					<div className="block min-w-[18px]">
						<i className={social.icon}></i>
					</div>
					<span className="flex-1">{social.name.toLowerCase()}</span>
				</a>
			))}
		</div>
	);
};

ResumeSocialMedia.locale = {
	nl: {
		save: "Opslaan",
		empty: {
			title: "Geen social media gevonden",
		},
		"error-save": {
			title: "Er is iets misgegaan",
			text: "Controleer of de velden juist zijn ingevuld",
		},
	},
	en: {
		save: "Save",
		empty: {
			title: "No social media found",
		},
		"error-save": {
			title: "Something went wrong",
			text: "Check if all fields are correctly filled in",
		},
	},
};

export default withTranslation(ResumeSocialMedia);
