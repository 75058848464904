import { Notifications } from "react-push-notification";
import useTasksListeners from "src/hooks/api/tasks/useTasksListeners";
import useAlertListener from "src/hooks/api/useAlertListener";
import useUnreadsListener from "src/hooks/api/useUnreadsListener";
import useProfileListener from "src/hooks/sockets/useProfileListener";
import useKeyCommands from "src/hooks/useKeyCommands";

const ListenerProvider = () => {
	useTasksListeners();
	useUnreadsListener();
	useProfileListener();
	useAlertListener();
	useKeyCommands();

	return (
		<>
			<Notifications />
		</>
	);
};

export default ListenerProvider;
