import { useState } from "react";
import { useParams } from "react-router-dom";
import { ApiSignature } from "src/api/types";
import Button from "src/components/Button";
import Card from "src/components/Card";
import Layout from "src/components/layout/Layout";
import Modal from "src/components/Modal";
import SEO from "src/components/layout/SEO";
import EnvelopeDocument from "src/components/signer/document/EnvelopeDocument";
import EnvelopeInformation from "src/components/signer/EnvelopeInformation";
import EnvelopeStatus from "src/components/signer/EnvelopeStatus";
import SignerContainer from "src/components/signer/layout/SignerContainer";
import SignerLayoutTitle from "src/components/signer/layout/SignerLayoutTItle";
import SignerMobileCta from "src/components/signer/layout/SignerMobileCta";
import Signers from "src/components/signer/Signers";
import SignerSignatureForm from "src/components/signer/SignerSignatureForm";
import useEnvelope from "src/hooks/api/useEnvelope";
import usePost from "src/hooks/rest/usePost";
import useAuth from "src/hooks/selectors/useAuth";
import useAlert from "src/hooks/useAlert";
import useWindowSize from "src/hooks/useWindowSize";
import useBackUrl from "src/hooks/useBackUrl";
import BackButton from "src/components/BackButton";
import { useTranslation } from "react-i18next";
import useUnreads from "src/hooks/api/useUnreads";

export default function SignerView() {
	const { t } = useTranslation("signer-view", {
		keyPrefix: "signer-view",
	});
	const { id } = useParams() as any;
	const backUrl = useBackUrl();
	const auth = useAuth();
	const [visible, setVisible] = useState(false);
	const size = useWindowSize();
	const { envelope, loading, fetchEnvelope } = useEnvelope(id);
	const [signDocument, signingDocument] = usePost(`/envelopes/${id}/sign`);
	const [sendAlert] = useAlert();
	const { fetchUnreads } = useUnreads();

	const handleSignDocument = (signer: ApiSignature) => {
		sendAlert({
			type: "confirm",
			title: t("confirm.title"),
			text: t("confirm.text"),
			onConfirm: () => {
				signDocument({ signature_id: signer.id })
					.then(() => {
						fetchEnvelope(false);
						sendAlert({
							title: t("success.title"),
						});
						fetchUnreads();
					})
					.catch(() => {
						sendAlert({
							type: "error",
							title: t("error.title"),
							text: t("error.text"),
						});
					});
			},
		});
	};

	if (!envelope?.id && !loading) {
		return (
			<Layout>
				<div className="mt-8 flex justify-center items-center flex-col">
					<h1 className="text-center">{t("not-found.title")}</h1>
					<div className="flex mt-4">
						<Button to="/tasks">
							<i className="fas fa-arrow-left mr-2"></i>
							{t("not-found.button")}
						</Button>
					</div>
				</div>
			</Layout>
		);
	}

	return (
		<>
			{envelope?.task?.title && <SEO title={envelope.task.title} />}
			<Layout
				title={
					envelope?.id && auth.type === "company" ? (
						<SignerLayoutTitle
							title={envelope?.task?.title || ""}
							status={envelope?.status || "CREATED"}
						/>
					) : (
						envelope?.task?.title || ""
					)
				}
				backUrl={"/envelopes"}
				breadcrumb={[
					{
						to: "/envelopes",
						label: t("breadcrumb.envelopes"),
						isHome: true,
					},
					{
						to: `/envelopes/${id}`,
						label: t("breadcrumb.envelope"),
					},
				]}
			>
				<Modal
					{...{ visible }}
					onClose={() => setVisible(false)}
					title={t("information-modal.title")}
					variant={auth.variant}
				>
					<div className="flex flex-col gap-12">
						<div>
							{envelope?.hid && (
								<Signers
									variant={auth.variant}
									hid={envelope?.hid}
								/>
							)}
						</div>
						<hr
							className={
								auth.variant === "dark"
									? "border-dark-accent"
									: "border-border"
							}
						/>
						<div>
							<EnvelopeInformation
								variant={auth.variant}
								{...{ envelope, loading }}
							/>
						</div>
					</div>
				</Modal>

				{(size.isTablet || auth?.type === "student") && (
					<div className="flex flex-col">
						{size.isTablet && (
							<div className="flex justify-between items-center mb-4">
								{size.isPhone && (
									<div className="flex flex-col gap-2 mb-4">
										<strong>{envelope?.task?.title}</strong>
										<div className="flex">
											<EnvelopeStatus
												status={
													envelope?.status ||
													"CREATED"
												}
											/>
										</div>
									</div>
								)}
								<div
									onClick={() => setVisible(!visible)}
									className="cursor-pointer flex items-center gap-2"
								>
									{!size.isPhone && (
										<strong>
											{t("information-modal.title")}
										</strong>
									)}
									<i className="fas fa-info-circle"></i>
								</div>
							</div>
						)}

						{auth?.type === "student" && (
							<BackButton to={backUrl || "/envelopes"} />
						)}
					</div>
				)}
				<SignerContainer>
					{signingDocument ? (
						<div className="flex flex-1 text-center justify-center items-center flex-col gap-4">
							<h2>{t("loading.title")}</h2>
							<p>{t("loading.description")}</p>
							<span className="base-loader"></span>
						</div>
					) : (
						<>
							{!loading && envelope?.id ? (
								<EnvelopeDocument {...{ envelope }} />
							) : (
								<EnvelopeDocument.Loader />
							)}
						</>
					)}
					{!size.isTablet && (
						<div>
							<SignerContainer.SideBar>
								<Card>
									{envelope?.hid && (
										<Signers
											title={t("signers.title")}
											variant={auth.variant}
											hid={envelope?.hid}
										/>
									)}
								</Card>
								<Card>
									<EnvelopeInformation
										variant={auth.variant}
										{...{ envelope, loading }}
									/>
								</Card>
								<Card>
									<SignerSignatureForm
										onSubmit={handleSignDocument}
										variant={auth.variant}
										{...{ envelope, loading }}
									/>
								</Card>
							</SignerContainer.SideBar>
						</div>
					)}
					{size.isTablet && (
						<>
							<SignerMobileCta variant={auth.variant}>
								<Button
									className={"w-[50px] h-[50px]"}
									type="gray"
									iconOnly
									onClick={() => setVisible(!visible)}
								>
									<i className="fas fa-users"></i>
								</Button>
								<SignerSignatureForm
									type="button"
									onSubmit={handleSignDocument}
									variant={auth.variant}
									{...{ envelope, loading }}
								/>
							</SignerMobileCta>
						</>
					)}
				</SignerContainer>
				{size.isTablet && <div className="h-[79px] block"></div>}
			</Layout>
		</>
	);
}
