import React from "react";
import withTranslation, {
	Translation,
} from "src/components/hoc/withTranslation";
import Skeleton from "src/components/Skeleton";

interface CompanyInfoSearchListItemProps extends Translation {
	info: any;
	onClick?: () => void;
}

const CompanyInfoSearchListItem = ({
	t,
	info,
	onClick,
}: CompanyInfoSearchListItemProps) => {
	return (
		<div
			{...{ onClick }}
			className="border-[1px] bg-background hover:bg-accent transition-all border-border-dark rounded-md px-6 p-4 cursor-pointer flex flex-col gap-2"
		>
			<div className="flex justify-between flex-wrap gap-2 items-center">
				<strong>{info?.handelsnaam}</strong>
				<i className="far fa-arrow-right"></i>
			</div>
			<div className="flex gap-4">
				{info?.dossiernummer && (
					<span>
						{t("kvk", {
							kvk: info.dossiernummer,
						})}
					</span>
				)}
				{info?.plaats && (
					<span>
						{t("location", {
							location: info.plaats,
						})}
					</span>
				)}
			</div>
		</div>
	);
};

export const CompanyInfoSearchListItemLoader = () => (
	<div className="border-[1px] bg-background hover:bg-accent transition-all border-border-dark rounded-md p-4 cursor-pointer">
		<Skeleton className="w-2/4 h-3" />
	</div>
);

CompanyInfoSearchListItem.locale = {
	nl: {
		kvk: "Kvk: {{kvk}}",
		location: "Locatie: {{location}}",
	},
	en: {
		kvk: "Kvk: {{kvk}}",
		location: "Location: {{location}}",
	},
};

export default withTranslation(CompanyInfoSearchListItem);
