const exampleTasks = {
	marketing: {
		title: "Marketing",
		items: [
			{
				title: "Social media beheren",
				budget: 250,
			},
			{
				title: "Webpagina's toevoegen",
				budget: 200,
			},
			{
				title: "Website vertalen",
				budget: 500,
			},
			{
				title: "SEO zoekwoordenonderzoek",
				budget: 200,
			},
			{
				title: "Facebook/Instagram ads strategie instellen en maken",
				budget: 500,
			},
			{
				title: "Google Ads instellen voor verbeteren zichtbaarheid",
				budget: 250,
			},
		],
	},
	"marketing-development": {
		title: "Marketing / Development",
		items: [
			{
				title: "Data scrapen voor leadlijst",
				budget: 300,
			},

			{
				title: "Webshop bijwerken met nieuwe producten",
				budget: 300,
			},

			{
				title: "Instellen van Google Analytics en social media pixels",
				budget: 500,
			},

			{
				title: "Mailingfunnel opmaken en designen",
				budget: 250,
			},
		],
	},
	"marketing-content": {
		title: "Marketing / Content creëren",
		items: [
			{
				title: "15 Gifjes maken",
				budget: 300,
			},
			{
				title: "5 Blogs schrijven SEO geoptimaliseerd",
				budget: 250,
			},
			{
				title: "Transcribreren van 10 interviews van drie kwartier",
				budget: 200,
			},
			{
				title: "TikTok content maken en account beheren",
				budget: 200,
			},
		],
	},
	sales: {
		title: "Sales",
		items: [
			{
				title: "10 afspraken maken met prospects",
				budget: 500,
			},
			{
				title: "10 kandidaten recruiten voor sollicitatie",
				budget: 250,
			},
			{
				title: "Customer Care hulp",
				budget: 400,
			},
		],
	},
	"administration-legal": {
		title: "Administratie / Juridisch",
		items: [
			{
				title: "Hulp bij het verwerken van administratie",
				budget: 350,
			},
			{
				title: "BTW aangiftes opstellen",
				budget: 500,
			},
			{
				title: "Contracten juridisch checken en verbeteren",
				budget: 250,
			},
			{
				title: "Privacy statement en algemene voorwaarden maken",
				budget: 300,
			},
			{
				title: "Cookiebeleid opstellen en pop up instellen op website",
				budget: 250,
			},
		],
	},
	other: {
		title: "Overig",
		items: [
			{
				title: "Mystery shoppen/visit",
				budget: 25,
			},
		],
	},
};

export default exampleTasks;
