import { ApiListTenant } from "src/api/types";
import useApi from "src/hooks/api/utils/useApi";
import axios from "src/lib/api";

function useTenants() {
	const { state, actions, api } = useApi(
		{ id: "TENANTS" },
		{
			baseUrl: `/tenant/all`,
			query: {},
		}
	);
	const tenants: ApiListTenant[] = state?.list || [];

	const join = async (tenant: ApiListTenant, ref: string) => {
		const { data } = await axios.post(`/tenant/${tenant.slug}/join`, {
			ref,
		});
		return data?.data;
	};

	return {
		tenants,
		search: state.filter.q,
		status: state.status,
		actions: {
			...actions,
			join,
		},
		api,
	};
}

export default useTenants;
