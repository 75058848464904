import { useEffect } from "react";
import Avatar from "src/components/Avatar";
import Card from "src/components/Card";
import Skeleton from "src/components/Skeleton";
import { DateRangeFilterValue } from "src/components/date-range-filter/Provider";
import withTranslation, {
	Translation,
} from "src/components/hoc/withTranslation";
import List from "src/components/ui/list";
import useBestPerformingUsers from "src/hooks/api/services/statistics/users/useBestPerformingUsers";
import { cn } from "src/lib/utils";

interface BestPerformingUsersListProps extends Translation {
	date: DateRangeFilterValue;
	className?: string;
}

const BestPerformingUsersList = ({
	t,
	date,
	className,
}: BestPerformingUsersListProps) => {
	const { users, status, actions } = useBestPerformingUsers();

	useEffect(() => {
		if (date?.start && date?.end) {
			actions.list({
				date: [
					date.start.format("YYYY-MM-DD"),
					date.end?.format("YYYY-MM-DD"),
				],
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [date]);

	return (
		<Card title={t("title")} className={cn("", className)}>
			<List data={users} {...{ status }}>
				<List.Head>
					<List.Item className="w-full py-1 pb-3">
						{t("headers.name")}
					</List.Item>
					<List.Item className="py-1 pb-3">
						{t("headers.completed")}
					</List.Item>
				</List.Head>
				<List.Items>
					{(item) => (
						<List.Row
							className="border-0"
							key={`best-user-${item.id}`}
						>
							<List.Item className="py-1">
								<Avatar.User user={item} />
							</List.Item>
							<List.Item align="right" className="py-1">
								{item?.tasks_count}
							</List.Item>
						</List.Row>
					)}
				</List.Items>
				<List.Loading>
					<List.Item className="py-1">
						<Skeleton className="h-8 w-1/2" />
					</List.Item>
					<List.Item align="right" className="py-1">
						<Skeleton className="h-4 w-6" />
					</List.Item>
				</List.Loading>
				<List.Empty className="gap-0 text-center">
					<strong>{t("empty.title")}</strong>
					<p>{t("empty.description")}</p>
				</List.Empty>
			</List>
		</Card>
	);
};

BestPerformingUsersList.locale = {
	nl: {
		title: "Best presterende medewerker",
		headers: {
			name: "Naam",
			completed: "Afgerond",
		},
		empty: {
			title: "Oeps",
			description: "We hebben geen medewerkers kunnen vinden",
		},
	},
	en: {
		title: "Best performing employees",
		headers: {
			name: "Name",
			completed: "Completed",
		},
		empty: {
			title: "Oops",
			description: "We could not find any employees",
		},
	},
};

export default withTranslation(BestPerformingUsersList);
