import moment from "moment";
import { useEffect } from "react";
import { ApiVacancy } from "src/api/types";
import Button from "src/components/Button";
import withTranslation, {
	Translation,
} from "src/components/hoc/withTranslation";
import Skeleton from "src/components/Skeleton";
import List from "src/components/ui/list";
import VacancyRegistrationAvatars from "src/components/vacancies/vacancy-avatars";
import useVacancies from "src/hooks/api/services/vacancies/useVacancies";
import { useUpdateEffectDebounce } from "src/hooks/useDebounce";

interface VacanciesTableProps extends Translation {
	filter?: Record<string, any>;
}

const VacanciesTable = ({ t, filter }: VacanciesTableProps) => {
	const { vacancies, status, actions, pagination } = useVacancies();

	useUpdateEffectDebounce(filter, 1000, () => {
		actions.list(filter);
	});

	useEffect(() => {
		actions.list(filter);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<List
			data={vacancies}
			status={vacancies.length > 0 && status !== "idle" ? "idle" : status}
		>
			<List.Head>
				<List.Item className="flex-1">{t("headers.title")}</List.Item>
				<List.Item>{t("headers.registrations")}</List.Item>
				<List.Item>{t("headers.due")}</List.Item>
				<List.Item>{t("headers.actions")}</List.Item>
			</List.Head>
			<List.Items>
				{(vacancy: ApiVacancy) => (
					<List.Row key={vacancy.id}>
						<List.Item>{vacancy.title}</List.Item>
						<List.Item>
							{vacancy?.registrations?.count &&
							vacancy?.registrations?.count > 0 ? (
								<VacancyRegistrationAvatars
									className="gap-2"
									vacancy={vacancy}
									avatarSize="small"
								/>
							) : (
								"-"
							)}
						</List.Item>
						<List.Item>
							{vacancy.unpublished_at
								? moment(vacancy.unpublished_at).format(
										"DD-MM-YYYY"
								  )
								: "-"}
						</List.Item>
						<List.Item>
							{vacancy.type === "DRAFT" && (
								<Button
									xsmall
									to={`/vacancies/create?vacancy_id=${vacancy.id}`}
								>
									{t("actions.publish")}
								</Button>
							)}
							{vacancy.type === "PUBLISHED" && (
								<Button xsmall to={`/vacancies/${vacancy.id}`}>
									{t("actions.view")}
								</Button>
							)}
						</List.Item>
					</List.Row>
				)}
			</List.Items>
			<List.Loading>
				<List.Item>
					<Skeleton className="h-6 w-full" />
				</List.Item>
				<List.Item>
					<Skeleton className="h-6 w-24" />
				</List.Item>
				<List.Item>
					<Skeleton className="h-6 w-24" />
				</List.Item>
			</List.Loading>
			<List.Empty>
				<h2>{t("empty.title")}</h2>
				<p>{t("empty.description")}</p>
			</List.Empty>
			<List.Pagination
				{...{ pagination }}
				onNavigate={async (page) => {
					await pagination.setPage(page);
					window.scrollTo(0, 0);
					return null;
				}}
			/>
		</List>
	);
};

VacanciesTable.locale = {
	nl: {
		headers: {
			title: "Vacature",
			registrations: "Aanmeldingen",
			due: "Geldig tot",
			actions: "Acties",
		},
		empty: {
			title: "Geen vacatures",
			description: "Je hebt nog geen vacatures geplaatst.",
		},
		actions: {
			view: "Bekijk",
			publish: "Publiceer",
		},
		registrations: "+ {{count}}",
	},
	en: {
		headers: {
			title: "Vacancy",
			registrations: "Registrations",
			due: "Due",
			actions: "Actions",
		},
		empty: {
			title: "No vacancies",
			description: "You have not created any vacancies yet.",
		},
		actions: {
			view: "View",
			publish: "Publish",
		},
		registrations: "+ {{count}}",
	},
};

export default withTranslation(VacanciesTable);
