import { useTranslation } from "react-i18next";
import Button from "src/components/Button";
import Input from "src/components/form/Input";
import { TaskFormOptions } from "src/components/tasks/TaskForm";
import SubtaskForm from "src/components/tasks/TaskForm/elements/SubtaskForm";
import Form from "src/components/tasks/TaskForm/Form";
import useSubtaskForm from "src/hooks/api/tasks/useSubtaskForm";
import useTaskForm from "src/hooks/api/tasks/useTaskForm";
import { SubtaskForm as SubtaskFormType } from "src/redux/taskForm";

const SubtasksForm = ({ hideButtons }: TaskFormOptions) => {
	const { actions, current, task } = useTaskForm();
	const { subtasks, actions: subtaskActions, isValid } = useSubtaskForm();
	const { t } = useTranslation("forms", {
		keyPrefix: "subtasks-form",
	});
	return (
		<Form
			{...{ hideButtons }}
			title={t("title")}
			onSubmit={async () => {
				actions.updateCurrent(current + 1);
			}}
			value={{
				min_age: task?.min_age,
			}}
			schema={() => ({})}
			nextDisabled={!isValid}
			nextButtonLabel={
				subtasks.length ? t("buttons.next") : t("buttons.skip")
			}
		>
			{(getInputProps) => {
				return (
					<>
						<Input
							optional
							label={t("form.min-age")}
							type="number"
							{...getInputProps("min_age")}
						/>

						<div className="flex flex-col gap-2">
							<strong>{t("form.shifts.title")}</strong>
							{subtasks.length > 0 && (
								<div className="flex flex-col gap-4 border-2 border-border rounded-md p-2">
									{subtasks.map(
										(
											task: SubtaskFormType,
											index: number
										) => (
											<SubtaskForm
												key={`task-${index}`}
												{...{ task }}
											/>
										)
									)}
								</div>
							)}
							<div className="flex justify-center p-2">
								<Button onClick={subtaskActions.createEmpty}>
									{t("form.shifts.add")}
								</Button>
							</div>
						</div>

						<p>{t("description")}</p>
					</>
				);
			}}
		</Form>
	);
};

export default SubtasksForm;
