const SUBDOMAINS = ["account.", "www.", "demo.", "app."];

export const getCurrentDomainName = () => {
	let host = window.location.hostname;

	//Get the search params
	const search = window.location.search;
	const urlParams = new URLSearchParams(search);
	const tenant = urlParams.get("tenant");

	if (urlParams.get("embed") === "true" && tenant) {
		return tenant;
	}

	SUBDOMAINS.map((subdomain) => {
		host = host.replace(subdomain, "");
		return subdomain;
	});
	[host] = host.split(".");
	return host;
};
