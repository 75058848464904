import * as chrono from "chrono-node";
import {
	Dispatch,
	SetStateAction,
	useCallback,
	useEffect,
	useState,
} from "react";
import useLanguage from "src/hooks/api/useLanguage";
import { getNLChrono } from "src/lib/chrono";

const chronoNl = getNLChrono();

export type Result = chrono.ParsedResult;

function useParseDatesState(
	initialValue: string
): [{ value: string; dates: any }, Dispatch<SetStateAction<string>>] {
	const { current: language } = useLanguage();
	const [state, setState] = useState(initialValue);
	const [dates, setDates] = useState([]);
	const setLoggedState = useCallback((newValue: SetStateAction<string>) => {
		// console.log("State is being updated:", newValue);
		setState(newValue);
	}, []);

	const parseDatesFromText = (text: string) => {
		let parsed = [];
		if (language === "nl") {
			parsed = chronoNl.parse(text);
		} else {
			parsed = chrono.parse(text);
		}
		setDates(parsed as any);
	};

	useEffect(() => {
		parseDatesFromText(state);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [state]);

	return [{ value: state, dates }, setLoggedState];
}

interface ParseDatesByValueOptions {
	format: (value: Result) => any;
}

export function useParseDatesByValue(
	value: string,
	options?: ParseDatesByValueOptions
) {
	const { current: language } = useLanguage();
	const [dates, setDates] = useState([]);

	const parseDatesFromText = (text: string) => {
		let parsed = [];
		if (language === "nl") {
			parsed = chronoNl.parse(text);
		} else {
			parsed = chrono.parse(text);
		}

		if (options?.format) {
			parsed = parsed.map(options.format);
		}

		setDates(parsed as any);
	};

	useEffect(() => {
		parseDatesFromText(value);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [value]);

	return { dates };
}

export default useParseDatesState;
