import { ApiMedia } from "src/api/types";
import FileActions from "src/components/form/file-upload/FileActions";
import FileUploadItem from "src/components/form/file-upload/FileUploadItem";
import withTranslation, {
	Translation,
} from "src/components/hoc/withTranslation";

interface FileListProps extends Translation {
	files: ApiMedia[];
	onChange?: (files: ApiMedia[]) => void;
}

const FileList = ({ t, files, onChange }: FileListProps) => {
	return (
		<div className="flex flex-col min-w-[300px] gap-2">
			{!files.length && (
				<div className="flex flex-col p-8">
					<strong>{t("empty.title")}</strong>
					<p>{t("empty.description")}</p>
				</div>
			)}
			{files.map((media) => (
				<FileUploadItem
					className="w-full flex-1"
					isFullname
					{...{ media }}
					key={`file-list-${media.id}`}
				>
					{onChange && (
						<div className="ml-4">
							<FileActions
								{...{ media }}
								onDelete={() =>
									onChange(
										files.filter(
											(item) => item.id !== media.id
										)
									)
								}
							/>
						</div>
					)}
				</FileUploadItem>
			))}
		</div>
	);
};

FileList.locale = {
	nl: {
		empty: {
			title: "Geen bestanden gevonden",
			description: "We hebben geen bestanden of afbeelding kunnen vinden",
		},
	},
	en: {
		empty: {
			title: "No files found",
			description: "We were not able to found any files or pictures",
		},
	},
};

export default withTranslation(FileList);
