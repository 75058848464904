import { useEffect, useState } from "react";
import usePost from "src/hooks/rest/usePost";

interface ToggleFavoriteProps {
	defaultFavorite?: boolean;
	type: "REGISTRATION";
	id: string | number;
	children: (props: {
		isFavorite: boolean;
		loading: boolean;
		onClick: () => void;
	}) => JSX.Element;
}

const ToggleFavorite = ({
	children,
	defaultFavorite,
	type,
	id,
}: ToggleFavoriteProps) => {
	const [isFavorite, setIsFavorite] = useState<boolean>(
		defaultFavorite || false
	);
	const [toggle, loading] = usePost(`/client/favorites/toggle`);

	useEffect(() => {
		setIsFavorite(defaultFavorite || false);
	}, [defaultFavorite, id]);

	const onClick = () => {
		if (loading) return;
		toggle({ id, type }).then(({ data }) => {
			console.log(data);
			setIsFavorite((state) => !state);
		});
	};

	return children({
		isFavorite,
		loading,
		onClick,
	});
};

export default ToggleFavorite;
