import { useBarChart } from "src/components/statistics/bar-chart/Provider";
import { cn } from "src/lib/utils";

const Info = () => {
	const { totals, theme } = useBarChart();
	return (
		<>
			<div className="w-8 flex"></div>
			<div className="flex flex-col flex-1 absolute inset-0 z-1 pointer-events-auto">
				{totals.steps.map((step: number, index) => (
					<div
						key={`${step}-${index}`}
						className={cn(
							"flex flex-row items-end",
							index !== 0 ? "flex-1" : "h-0"
						)}
					>
						<div className="flex flex-col w-12 justify-end items-start">
							{(step > 0 ||
								index === totals.steps.length - 1) && (
								<span className="leading-[90%] -mb-[2px]">
									{step}
								</span>
							)}
						</div>
						<div className="flex-1 border-b border-border"></div>
					</div>
				))}
				<div className={theme.footer.className}></div>
			</div>
		</>
	);
};

export default Info;
