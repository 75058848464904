import { Route } from "react-router-dom";
import ProtectedRoute from "src/routes/middlewares/ProtectedRoute";
import CreatePaymentRequestModal from "src/views/authenticated/payment-requests/CreatePaymentRequestModal";
import DenyPaymentRequestModal from "src/views/authenticated/payment-requests/DenyPaymentRequestModal";
import PaymentRequestsProcessView from "src/views/authenticated/payment-requests/PaymentRequestsProcessView";
import PaymentRequestsView from "src/views/authenticated/payment-requests/PaymentRequestsView";
import SelectPaymentRequestModal from "src/views/authenticated/payment-requests/SelectPaymentRequestModal";
import PaymentRequestCheckoutModal from "src/views/authenticated/payment-requests/checkout/PaymentRequestCheckoutModal";
import PaymentRequestCheckoutView from "src/views/authenticated/payment-requests/checkout/PaymentRequestCheckoutView";
import DeletePaymentRequestModal from "src/views/authenticated/payment-requests/detail/DeletePaymentRequestModal";
import EditPaymentRequestModal from "src/views/authenticated/payment-requests/detail/EditPaymentRequestModal";
import PaymentRequestDetailView from "src/views/authenticated/payment-requests/detail/PaymentRequestDetailView";
import PaymentRequestsFinalizeView from "src/views/authenticated/payment-requests/finalize/PaymentRequestsFinalizeView";
import PaymentRequestsReviewModal from "src/views/authenticated/payment-requests/finalize/PaymentRequestsReviewModal";

const PaymentRequestsRoutes = [
	<Route
		element={
			<ProtectedRoute>
				<PaymentRequestsView />
			</ProtectedRoute>
		}
		path="/payment-requests"
		key="/payment-requests"
	/>,
	<Route
		element={
			<ProtectedRoute type="company">
				<PaymentRequestsProcessView />
			</ProtectedRoute>
		}
		path="/payment-requests/process"
		key="/payment-requests/process"
	/>,
	<Route
		element={
			<ProtectedRoute type="company">
				<PaymentRequestsFinalizeView />
			</ProtectedRoute>
		}
		path="/payment-requests/finalize"
		key="/payment-requests/finalize"
	/>,
	<Route
		element={
			<ProtectedRoute type="company">
				<PaymentRequestCheckoutView />
			</ProtectedRoute>
		}
		path="/payment-requests/process/checkout"
		key="/payment-requests/process/checkout"
	/>,
	<Route
		element={
			<ProtectedRoute>
				<PaymentRequestDetailView />
			</ProtectedRoute>
		}
		path="/payment-requests/:connectionId/show"
		key="/payment-requests/:connectionId/show"
	/>,
];

export const PaymentRequestsModalRoutes = [
	<Route
		element={
			<ProtectedRoute
				variant="modal"
				modal={{
					backUrl: "/payment-requests",
					expanded: false,
					size: "default",
				}}
			>
				<SelectPaymentRequestModal />
			</ProtectedRoute>
		}
		path="/payment-requests/select"
		key="/payment-requests/select"
	/>,
	<Route
		element={
			<ProtectedRoute
				variant="modal"
				modal={{
					backUrl: "/payment-requests",
					expanded: false,
					size: "default",
				}}
			>
				<DenyPaymentRequestModal />
			</ProtectedRoute>
		}
		path="/payment-requests/deny"
		key="/payment-requests/deny"
	/>,
	<Route
		element={
			<ProtectedRoute
				variant="modal"
				modal={{
					backUrl: "/payment-requests",
					expanded: false,
					size: "large",
					className: "h-auto sm:h-auto max-w-[920px]",
				}}
			>
				<EditPaymentRequestModal />
			</ProtectedRoute>
		}
		path="/payment-requests/:paymentRequestId/edit"
		key="/payment-requests/:paymentRequestId/edit"
	/>,
	<Route
		element={
			<ProtectedRoute
				variant="modal"
				modal={{
					backUrl: "/payment-requests",
					expanded: false,
					size: "default",
				}}
			>
				<DeletePaymentRequestModal />
			</ProtectedRoute>
		}
		path="/payment-requests/:paymentRequestId/delete"
		key="/payment-requests/:paymentRequestId/delete"
	/>,
	<Route
		element={
			<ProtectedRoute
				variant="modal"
				modal={{
					backUrl: "/payment-requests",
					expanded: false,
					size: "large",
					className: "h-auto sm:h-auto max-w-[920px]",
				}}
			>
				<CreatePaymentRequestModal />
			</ProtectedRoute>
		}
		path="/payment-requests/create"
		key="/payment-requests/create"
	/>,
	<Route
		element={
			<ProtectedRoute
				variant="modal"
				modal={{
					backUrl: "/payment-requests/process",
					expanded: false,
					size: "large",
					className: "h-auto sm:h-auto max-w-[920px]",
				}}
			>
				<CreatePaymentRequestModal />
			</ProtectedRoute>
		}
		path="/payment-requests/process/create"
		key="/payment-requests/process/create"
	/>,
	<Route
		element={
			<ProtectedRoute
				variant="modal"
				modal={{
					backUrl: "/payment-requests/process",
					expanded: false,
					size: "default",
				}}
			>
				<DeletePaymentRequestModal />
			</ProtectedRoute>
		}
		path="/payment-requests/process/:paymentRequestId/delete"
		key="/payment-requests/process/:paymentRequestId/delete"
	/>,
	<Route
		element={
			<ProtectedRoute
				variant="modal"
				modal={{
					backUrl: "/payment-requests/:connectionId/show",
					expanded: false,
					size: "medium",
					className: "h-auto sm:h-auto",
				}}
			>
				<CreatePaymentRequestModal />
			</ProtectedRoute>
		}
		path="/payment-requests/:connectionId/create"
		key="/payment-requests/:connectionId/create"
	/>,
	<Route
		element={
			<ProtectedRoute
				variant="modal"
				modal={{
					backUrl: "/payment-requests/:connectionId/show",
					expanded: false,
					size: "medium",
					className: "h-auto sm:h-auto",
				}}
			>
				<EditPaymentRequestModal />
			</ProtectedRoute>
		}
		path="/payment-requests/:connectionId/show/:paymentRequestId/edit"
		key="/payment-requests/:connectionId/show/:paymentRequestId/edit"
	/>,
	<Route
		element={
			<ProtectedRoute
				variant="modal"
				modal={{
					backUrl: "/payment-requests/process",
					expanded: false,
					size: "small",
					className: "w-full md:max-w-[620px]",
				}}
			>
				<PaymentRequestCheckoutModal />
			</ProtectedRoute>
		}
		path="/payment-requests/process/checkout"
		key="/payment-requests/process/checkout"
	/>,
	<Route
		element={
			<ProtectedRoute
				variant="modal"
				modal={{
					backUrl: "/payment-requests/finalize",
					expanded: false,
					size: "small",
					className: "w-full md:max-w-[620px] h-auto",
				}}
			>
				<PaymentRequestsReviewModal />
			</ProtectedRoute>
		}
		path="/payment-requests/finalize/review"
		key="/payment-requests/finalize/review"
	/>,
];

export default PaymentRequestsRoutes;
