import * as React from "react";
import { DayPicker } from "react-day-picker";
import { nl } from "date-fns/locale";

import { cn } from "src/lib/utils";
import useLanguage from "src/hooks/api/useLanguage";
import moment from "moment";

export type CalendarProps = React.ComponentProps<typeof DayPicker> & {
	size?: "default" | "small";
};

function Calendar({
	className,
	classNames,
	showOutsideDays = true,
	size = "default",
	...props
}: CalendarProps) {
	const { current } = useLanguage();
	return (
		<DayPicker
			{...{ showOutsideDays }}
			locale={current === "nl" ? nl : undefined}
			className={cn("p-2", className)}
			classNames={{
				months: "flex flex-col sm:flex-row space-y-2 sm:space-x-4 sm:space-y-0",
				month: "space-y-2",
				caption: "flex justify-center pt-1 relative items-center",
				caption_label: "text-sm font-medium",
				nav: "space-x-1 flex items-center",
				nav_button: cn(
					"bg-transparent p-0 opacity-50 hover:opacity-100",
					size === "small" && "w-8 h-8",
					size === "default" && "w-9 h-9"
				),
				nav_button_previous: "absolute left-1",
				nav_button_next: "absolute right-1",
				table: "w-full border-collapse",
				head_row: "flex my-2",
				head_cell: cn(
					"text-center rounded-md font-bold text-[0.8rem] dark:text-slate-400",
					size === "small" && "w-8",
					size === "default" && "w-9"
				),
				row: "flex w-full",
				cell: "text-center text-sm p-0 relative [&:has([aria-selected])]:bg-slate-100 first:[&:has([aria-selected])]:rounded-l-md last:[&:has([aria-selected])]:rounded-r-md focus-within:relative focus-within:z-20 dark:[&:has([aria-selected])]:bg-slate-800",
				day: cn(
					"border text-background-foreground border-transparent hover:bg-accent hover:text-background-foreground transition-all rounded-sm p-0 font-normal aria-selected:opacity-100 flex justify-center items-center cursor-pointer",
					size === "small" && "w-8 h-8",
					size === "default" && "w-9 h-9"
				),
				day_selected:
					"bg-primary text-white hover:bg-accent [&:not(.is-selected)]:hover:text-accent-foreground hover:opacity-90 is-selected",
				day_today: "day-is-today",
				day_outside: "text-slate-500 opacity-50",
				day_disabled: "text-slate-500 opacity-50 cursor-not-allowed",
				day_range_start:
					"rounded-l-sm rounded-r-none hover:bg-primary hover:text-white",
				day_range_middle:
					"day-range-middle bg-primary-300 !text-primary hover:!text-background-foreground hover:bg-primary-300",
				day_range_end:
					"rounded-l-none rounded-r-sm hover:bg-primary hover:text-white",
				day_hidden: "invisible",
				...classNames,
			}}
			components={{
				IconLeft: ({ ...props }) => <i className="far fa-angle-left" />,
				IconRight: ({ ...props }) => (
					<i className="far fa-angle-right" />
				),
			}}
			formatters={{
				formatCaption: (date) => moment(date).format("YYYY MMM"),
			}}
			{...props}
		/>
	);
}
Calendar.displayName = "Calendar";

export { Calendar };
