import React from "react";
import { useTranslation } from "react-i18next";
import { ApiTask } from "src/api/types";
import Button from "src/components/Button";
import usePost from "src/hooks/rest/usePost";
import useAlert from "src/hooks/useAlert";

interface PublishInviteTaskProps {
	onSubmit: (task: ApiTask) => void;
	task: ApiTask;
}

const PublishInviteTask = ({ onSubmit, task }: PublishInviteTaskProps) => {
	const { t } = useTranslation("tasks", {
		keyPrefix: "publish-invite-task",
	});

	const [publishTask, loading] = usePost(`/company/tasks/${task.id}/publish`);
	const [sendAlert] = useAlert();
	const handlePublish = () => {
		sendAlert({
			title: t("confirm.title"),
			text: t("confirm.description"),
			type: "confirm",
			onConfirm: () => {
				publishTask({})
					.then(({ data }) => {
						sendAlert({
							title: t("success.title"),
							text: t("success.description"),
						});
						onSubmit(data);
					})
					.catch(() => {
						sendAlert({
							title: t("failed.title"),
							text: t("failed.description"),
						});
					});
			},
		});
	};
	return (
		<div className="p-8 flex flex-col gap-4 justify-center items-center">
			<Button onClick={handlePublish} {...{ loading }}>
				{t("button")}
			</Button>
			<p>{t("disclaimer")}</p>
		</div>
	);
};

export default PublishInviteTask;
