import { ComponentProps } from "react";
import { ApiTaskWeight } from "src/api/types";
import withTranslation, {
	Translation,
} from "src/components/hoc/withTranslation";

interface TaskWeightProps extends Translation {
	weight: ApiTaskWeight;
}

const TaskWeight = ({
	t,
	weight,
	...rest
}: TaskWeightProps & ComponentProps<"p">) => {
	return <p {...rest}>{t(weight)}</p>;
};

TaskWeight.locale = {
	nl: {
		LOW_EFFORT: "Laag",
		MEDIUM_EFFORT: "Gemiddeld",
		HIGH_EFFORT: "Hoog",
	},
	en: {
		LOW_EFFORT: "Low",
		MEDIUM_EFFORT: "Medium",
		HIGH_EFFORT: "High",
	},
};

export default withTranslation(TaskWeight);
