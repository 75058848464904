import { ReactRenderer } from "@tiptap/react";
import { ApiUserMinimal } from "src/api/types";
import UserSuggestionList from "src/components/field/fields/Editor/plugins/UserSuggestionList";
import tippy from "tippy.js";

const UserSuggestion = (users: ApiUserMinimal[]) => ({
	items: ({ query }: any) => {
		return users
			.filter((item) =>
				item.full_name.toLowerCase().startsWith(query.toLowerCase())
			)
			.slice(0, 5);
	},

	render: () => {
		let component: any;
		let popup: any;

		return {
			onStart: (props: any) => {
				component = new ReactRenderer(UserSuggestionList, {
					props,
					editor: props.editor,
				});

				if (!props.clientRect) {
					return;
				}

				popup = tippy("body", {
					getReferenceClientRect: props.clientRect,
					appendTo: () => document.body,
					content: component.element,
					showOnCreate: true,
					interactive: true,
					trigger: "manual",
					placement: "bottom-start",
				});
			},

			onUpdate(props: any) {
				component.updateProps(props);

				if (!props.clientRect) {
					return;
				}

				popup[0].setProps({
					getReferenceClientRect: props.clientRect,
				});
			},

			onKeyDown(props: any) {
				if (props.event.key === "Escape") {
					popup[0].hide();

					return true;
				}

				return component.ref?.onKeyDown(props);
			},

			onExit() {
				popup[0].destroy();
				component.destroy();
			},
		};
	},
});

export default UserSuggestion;
