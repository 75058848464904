import { AxiosInstance } from "axios";
import { ApiRegistration } from "src/api/types";
import useApi, {
	ApiActions,
	ApiFilter,
	ApiStatus,
	Pagination,
} from "src/hooks/api/utils/useApi";

type State = {
	registrations: ApiRegistration[];
	filter: ApiFilter;
	status: ApiStatus;
	actions: ApiActions;
	api: AxiosInstance;
	pagination: Pagination;
};

type Options = {
	task_id?: number;
	filter?: any;
};

function useRegistrations(options?: Options): State {
	const { state, actions, api, pagination } = useApi(
		{ id: "REGISTRATIONS" },
		{
			baseUrl: "/client/registrations",
			query: {
				task_id: options?.task_id,
				...(options?.filter || {}),
			},
		}
	);

	return {
		registrations: state?.list || [],
		filter: state.filter,
		status: state.status,
		pagination,
		actions,
		api,
	};
}

export default useRegistrations;
