import { app } from "@todesktop/client-core";
import { isDesktopApp } from "@todesktop/client-core/platform/todesktop";
import axios from "axios";
import { useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import useAuth from "src/hooks/selectors/useAuth";
import { updateProfile } from "src/redux/auth";

export default function useUnreads() {
	const dispatch = useDispatch();
	const auth = useAuth();
	const [loading, setLoading] = useState(false);
	const unreads = useMemo(() => auth.unreads, [auth]);
	const setUnreads = (unreads: any) => {
		dispatch(
			updateProfile({
				unreads,
			})
		);
	};
	const fetchUnreads = () => {
		setLoading(true);
		return axios
			.get("/auth/profile/unreads")
			.then(async ({ data }) => {
				setUnreads(data?.data);
			})
			.finally(() => setLoading(false))
			.catch(console.log);
	};

	useEffect(() => {
		if (isDesktopApp()) {
			app.setBadgeCount(unreads.alerts);
		}
	}, [unreads]);

	return {
		unreads,
		fetchUnreads,
		setUnreads,
		loading,
	};
}

export function useFetchUnreadsOnMount() {
	const { fetchUnreads, loading } = useUnreads();

	useEffect(() => {
		fetchUnreads();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return {
		loading,
	};
}
