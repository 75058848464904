import moment from "moment";
import { useEffect } from "react";
import Card from "src/components/Card";
import { DateRangeFilterValue } from "src/components/date-range-filter/Provider";
import withTranslation, {
	Translation,
} from "src/components/hoc/withTranslation";
import BarChart from "src/components/statistics/bar-chart";
import TotalCard from "src/components/statistics/total-card";
import useTasksStats from "src/hooks/api/services/statistics/tasks/useTasksStats";
import { cn } from "src/lib/utils";

interface TasksStatisticsProps {
	date: DateRangeFilterValue;
	userId?: string | number;
	children?: any;
}

const TasksStatistics = ({ userId, date, children }: TasksStatisticsProps) => {
	const { actions } = useTasksStats();
	// const [isRefreshing, setIsRefreshing] = useState(false);

	const getStatistics = async (date: DateRangeFilterValue) => {
		if (date.start && date.end) {
			await actions.get("all", {
				user_id: userId,
				date: [
					date.start.format("YYYY-MM-DD"),
					date?.end?.format("YYYY-MM-DD"),
				],
			});
		}
	};

	useEffect(() => {
		getStatistics(date);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [date, userId]);

	return (
		<>
			{children}
			{/* <div className="col-span-3">
				<Icon
					onClick={() => {
						setIsRefreshing(true);
						getStatistics(date).finally(() => {
							setIsRefreshing(false);
						});
					}}
					iconFontSize={14}
					className={cn("cursor-pointer text-placeholder")}
					icon={{
						font_awesome: {
							name: `far fa-sync-alt ${
								isRefreshing ? "fa-spin" : ""
							}`,
						},
					}}
				/>
			</div> */}
		</>
	);
};

const CompletedBarchart = ({
	t,
	className,
}: { className?: string } & Translation) => {
	const { statistics } = useTasksStats();
	const interval = statistics?.chart?.interval || "day";
	const completed = Object.keys(statistics?.chart?.values || {}).map(
		(key: any) => {
			if (interval === "week") {
				return {
					id: key,
					label: `W${moment(key).week()}`,
					description: `${moment(key)
						.startOf("week")
						.format("DD-MM-YYYY")} - ${moment(key)
						.endOf("week")
						.format("DD-MM-YYYY")}`,
					count: statistics?.chart?.values[key],
				};
			}

			if (interval === "month") {
				return {
					id: key,
					label: moment(key).format("MMM"),
					description: `${moment(key)
						.startOf("month")
						.format("DD-MM-YYYY")} - ${moment(key)
						.endOf("month")
						.format("DD-MM-YYYY")}`,
					count: statistics?.chart?.values[key],
				};
			}

			return {
				id: key,
				label: moment(key).format("ddd D"),
				description: moment(key).format("DD-MM-YYYY"),
				count: statistics?.chart?.values[key],
			};
		}
	);
	return (
		<Card className={cn("", className)} title={t("title")}>
			<BarChart
				data={[
					{
						label: t("label"),
						theme: {
							bar: {
								className: "bg-dark",
							},
						},
						data: completed,
					},
				]}
			/>
		</Card>
	);
};

CompletedBarchart.locale = {
	nl: {
		title: "Afgeronde taken",
		label: "Afgerond",
	},
	en: {
		title: "Completed tasks",
		label: "Completed",
	},
};

const TotalCompleted = ({
	t,
	className,
}: { className?: string } & Translation) => {
	const { status, statistics } = useTasksStats();

	return (
		<TotalCard
			{...{ className }}
			loading={status !== "idle"}
			label={t("title")}
			value={statistics?.counts?.completed || 0}
		>
			<TotalCard.Label />
			<div className="flex items-center gap-3">
				<TotalCard.Value />
				<TotalCard.Indicator />
			</div>
		</TotalCard>
	);
};

TotalCompleted.locale = {
	nl: {
		title: "Totaal afgeronde taken",
	},
	en: {
		title: "Total completed tasks",
	},
};

const TotalOpen = ({ t, className }: { className?: string } & Translation) => {
	const { status, statistics } = useTasksStats();
	return (
		<TotalCard
			{...{ className }}
			loading={status !== "idle"}
			label={t("title")}
			value={statistics?.counts?.open || 0}
		>
			<TotalCard.Label />
			<div className="flex items-center gap-3">
				<TotalCard.Value variant="k" />
				<TotalCard.Indicator />
			</div>
		</TotalCard>
	);
};

TotalOpen.locale = {
	nl: {
		title: "Openstaande taken",
	},
	en: {
		title: "Open tasks",
	},
};

const TotalHoursWorked = ({
	t,
	className,
}: { className?: string } & Translation) => {
	const { status, statistics } = useTasksStats();
	return (
		<TotalCard
			{...{ className }}
			loading={status !== "idle"}
			label={t("title")}
			value={statistics?.counts?.hours_worked || 0}
		>
			<TotalCard.Label />
			<div className="flex items-center gap-3">
				<TotalCard.Value variant="hours" />
				<TotalCard.Indicator />
			</div>
		</TotalCard>
	);
};

TotalHoursWorked.locale = {
	nl: {
		title: "Aantal gewerkte uren",
	},
	en: {
		title: "Total hours worked",
	},
};

TasksStatistics.CompletedBarChart = withTranslation(CompletedBarchart);
TasksStatistics.TotalCompleted = withTranslation(TotalCompleted);
TasksStatistics.TotalOpen = withTranslation(TotalOpen);
TasksStatistics.TotalHoursWorked = withTranslation(TotalHoursWorked);

export default TasksStatistics;
