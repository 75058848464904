import { ReactNode, useState } from "react";
import { ApiResume } from "src/api/types";
import ResumeEditable from "src/components/resume/resume-detail/ResumeEditable";
import ResumeBadges from "src/components/resume/resume-detail/elements/ResumeBadges";
import {
	ResumeTab,
	ResumeTabs,
} from "src/components/resume/resume-detail/elements/ResumeTabs";
import { ResumeDetailContext, ResumeTabName } from "./ResumeDetailContext";
import ResumeBio from "./elements/ResumeBio";
import ResumeEducation from "./elements/ResumeEducation";
import ResumeHead from "./elements/ResumeHead";
import ResumePublicHead from "./elements/ResumePublicHead";
import ResumeReviews from "./elements/ResumeReviews";
import ResumeSkills from "./elements/ResumeSkills";
import ResumeSocialMedia from "./elements/ResumeSocialMedia";
import ResumeWork from "./elements/ResumeWork";

export type ResumeOptions = {};

interface ResumeDetailProps {
	resume: ApiResume;
	children: ReactNode;
	tabs?: ResumeTabName[];
}

const ResumeDetail = ({ resume, children, tabs = [] }: ResumeDetailProps) => {
	const [activeTab, setActiveTab] = useState<ResumeTabName>(
		tabs.length > 0 ? tabs[0] : "profile"
	);
	return (
		<ResumeDetailContext.Provider
			value={{
				resume,
				tabs: { all: tabs, active: activeTab, setActive: setActiveTab },
			}}
		>
			{children}
		</ResumeDetailContext.Provider>
	);
};

ResumeDetail.Editable = ResumeEditable;

ResumeDetail.Head = ResumeHead;

ResumeDetail.PublicHead = ResumePublicHead;
ResumeDetail.Bio = ResumeBio;
ResumeDetail.Skills = ResumeSkills;
ResumeDetail.Education = ResumeEducation;
ResumeDetail.Work = ResumeWork;
ResumeDetail.Reviews = ResumeReviews;
ResumeDetail.SocialMedia = ResumeSocialMedia;
ResumeDetail.Badges = ResumeBadges;
ResumeDetail.Tabs = ResumeTabs;
ResumeDetail.Tab = ResumeTab;

export default ResumeDetail;
