import { useDispatch } from "react-redux";
import { ApiPermission } from "src/api/types";
import useApi from "src/hooks/api/utils/useApi";
import useAuth from "src/hooks/selectors/useAuth";
import { updateProfile } from "src/redux/auth";

function usePermissions() {
	const dispatch = useDispatch();
	const auth = useAuth();
	const { state, actions, api } = useApi(
		{ id: "PERMISSIONS" },
		{
			baseUrl: `/client/permissions`,
			query: {},
		}
	);
	const permissions = state?.list || [];

	const getByUser = async (userId: number | string) => {
		actions.set({
			status: "loading",
		});
		try {
			const data = await api
				.get(`/user/${userId}`)
				.then(({ data }) => data);
			actions.set({
				status: "idle",
			});
			return data;
		} catch (error) {
			actions.set({
				status: "error",
			});
			return [];
		}
	};

	const updateUser = async (
		userHid: number | string,
		permissions: string[]
	) => {
		actions.set({
			status: "updating",
		});
		const { data } = await api
			.patch(`/user/${userHid}`, {
				permissions,
			})
			.then(({ data }) => data);

		if (userHid === auth.hid) {
			dispatch(
				updateProfile({
					...auth,
					permissions: data,
				})
			);
		}

		actions.set({
			status: "idle",
		});
	};

	const has = (permission: ApiPermission | ApiPermission[]) => {
		if (Array.isArray(permission)) {
			return permission.every((per) => auth.permissions.includes(per));
		}
		return (auth?.permissions || []).includes(permission);
	};

	return {
		mine: (auth.permissions || []) as ApiPermission[],
		permissions,
		status: state.status,
		has,
		actions: {
			...actions,
			getByUser,
			updateUser,
			has,
		},
	};
}

export default usePermissions;
