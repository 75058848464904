import { useAtom } from "jotai";
import { ApiTaskKanban } from "src/api/types";
import useApi from "src/hooks/api/utils/useApi";
import useAuth from "src/hooks/selectors/useAuth";
import uid from "src/lib/uid";
import { atomWithStorage } from "jotai/utils";
import moment from "moment";

const showCompletedTasksAtom = atomWithStorage(
	"hide-completed-week-tasks",
	true
);

type Options = {
	resetFilter?: boolean;
	filter?: any;
};

function useMyWeekTasks(options?: Options) {
	const auth = useAuth();
	const [showCompletedTasks, setShowCompletedTasks] = useAtom(
		showCompletedTasksAtom
	);
	const { state, actions, api, pagination } = useApi(
		{ id: "MY_WEEK_TASKS", group: "TASKS" },
		{
			baseUrl: `/client/tasks`,
			isAllowed: (item: ApiTaskKanban) => {
				const isInDateRange = moment(item.end_date).isBetween(
					moment(options?.filter?.date[0]),
					moment(options?.filter?.date[1])
				);
				const isLastDayOfRange = moment(item.end_date).isSame(
					moment(options?.filter?.date[1])
				);
				const isToday = moment(item.end_date).isSame(
					moment(options?.filter?.date[0]),
					"day"
				);
				const inRangeOrSameAsEndDate =
					isToday || isInDateRange || isLastDayOfRange;

				return Boolean(
					item.links.assignees?.includes(auth.id) &&
						item.end_date &&
						options?.filter?.date?.length === 2 &&
						inRangeOrSameAsEndDate
				);
			},
			appendListResult: true,
			query: {
				limit: 30,
				recent: 1,
				board: "my-tasks",
				variant: "kanban",
				include: ["private", "project-tasks"],
				...(options?.filter || {}),
			},
		}
	);

	const tasks = state?.list || [];

	const createEditableTask = (date: string) => {
		actions.set((state: any) => {
			const tasks = state.list || [];
			const item = {
				_id: uid(),
				status: "BACKLOG",
				end_date: date,
				title: "",
				tags: [],
				type: "INTERNAL",
				mode: "NEW",
				registrations: null,
				matches: null,
				links: {
					assignees: [],
				},
			};
			return {
				...state,
				list: [item, ...tasks],
			};
		});
	};

	const updateNewItem = (_id: string, data: ApiTaskKanban) => {
		actions.set((state: any) => {
			const tasks = state.list || [];
			return {
				...state,
				list: tasks.map((item: any) => {
					return item._id === _id ? data : item;
				}),
			};
		});
	};

	const removeNewItem = (_id: string) => {
		actions.set((state: any) => {
			const tasks = state.list || [];
			return {
				...state,
				list: tasks.filter((item: any) => {
					return item._id !== _id;
				}),
			};
		});
	};

	return {
		tasks,
		status: state.status,
		filter: state?.filter || null,
		showCompletedTasks,
		pagination,
		actions: {
			...actions,
			createEditableTask,
			removeNewItem,
			updateNewItem,
			setShowCompletedTasks,
		},
		api,
	};
}

export default useMyWeekTasks;
