const talent = {
	"index-view": {
		filters: {
			all: "Alle professionals",
			accepted: "Geaccepteerde professionals",
			denied: "Afgewezen professionals",
		},
		title: "Professionals",
		search: "Professional zoeken",
	},
	"detail-view": {
		title: "Talent",
	},
};

export default talent;
