import { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import Loader from "src/components/Loader";
import TenantIcons from "src/components/tenant/TenantIcons";
import TenantManifest from "src/components/tenant/TenantManifest";
import useTenant from "src/hooks/api/services/tenants/useTenant";
import usePrefetch from "src/hooks/api/usePrefetch";

const SKIPPABLE_PREFETCH_PATHS = [
	"/tools/job-offer-converter",
	"/public/tools/job-offer-converter",
	"/tools/cost-calculator",
	"/public/tools/cost-calculator",
];

interface TenantProviderProps {
	children: any;
}

const TenantProvider = ({ children }: TenantProviderProps) => {
	const { t } = useTranslation("providers", {
		keyPrefix: "tenant-provider",
	});
	const { status, actions, tenant } = useTenant();
	const prefetch = usePrefetch();
	const path = useMemo(() => window.location.pathname, []);
	const isPrefetchSkippable = SKIPPABLE_PREFETCH_PATHS.includes(path);

	useEffect(() => {
		actions.current().then((tenant) => {
			prefetch.run(tenant);
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	if (
		(status === "loading" || prefetch.loading) &&
		!tenant?.slug &&
		!isPrefetchSkippable
	) {
		return (
			<div className="flex justify-center items-center h-screen w-screen">
				<Loader />
			</div>
		);
	}

	if (prefetch.loading && !isPrefetchSkippable) {
		return (
			<div className="flex justify-center items-center h-screen w-screen">
				<Loader />
			</div>
		);
	}

	if (status === "error") {
		return (
			<div className="flex flex-col gap-2 justify-center items-center h-screen w-screen">
				<h2>{t("error.title")}</h2>
				<p>{t("error.description")}</p>
			</div>
		);
	}

	return (
		<>
			<TenantIcons />
			<TenantManifest />
			{children}
		</>
	);
};

export default TenantProvider;
