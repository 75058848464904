import { createContext, useContext } from "react";
import Dropdown from "src/components/form/Dropdown";
import Input from "src/components/form/Input";
import withTranslation, {
	Translation,
} from "src/components/hoc/withTranslation";

export type SourceValue = {
	mode: "SELECT" | "MANUAL";
	source?: string;
	description?: string;
};

interface SourceInputProps {
	children?: any;
	value: SourceValue;
	onChange: (value: SourceValue) => void;
}

interface SourceContext {
	value: SourceInputProps["value"];
	onChange: SourceInputProps["onChange"];
}

const Context = createContext<SourceContext>({} as SourceContext);
const useSourceInput = () => useContext(Context);

export const SourceInput = ({
	children,
	value,
	onChange,
}: SourceInputProps) => {
	return (
		<Context.Provider value={{ value, onChange }}>
			{children}
		</Context.Provider>
	);
};

interface SourceInputDropdownProps {
	label?: string;
	placeholder?: string;
}

export const SourceInputDropdown = withTranslation(
	({ t, label, placeholder }: SourceInputDropdownProps & Translation) => {
		const { value, onChange } = useSourceInput();

		return (
			<Dropdown
				{...{ label, placeholder }}
				onChange={(source) => {
					onChange({
						...value,
						source: source?.toString(),
					});
				}}
				value={value?.source}
				options={[
					...[
						"INSTAGRAM",
						"FACEBOOK",
						"LINKEDIN",
						"GOOGLE",
						"MOUTH",
						"OTHER",
					].map((source) => ({
						id: source,
						name: t(`${source}`),
					})),
				]}
			/>
		);
	},
	{
		nl: {
			INSTAGRAM: "Instagram",
			FACEBOOK: "Facebook",
			LINKEDIN: "LinkedIn",
			GOOGLE: "Google",
			MOUTH: "Iemand vertelde mij over {{tenant.name}}",
			OTHER: "Anders...",
		},
		en: {
			INSTAGRAM: "Instagram",
			FACEBOOK: "Facebook",
			LINKEDIN: "LinkedIn",
			GOOGLE: "Google",
			MOUTH: "Someone told me about {{tenant.name}}",
			OTHER: "Other...",
		},
	}
);

interface SourceInputOtherProps {
	label?: string;
	placeholder?: string;
}

export const SourceInputOther = withTranslation(
	({ t, label, placeholder }: SourceInputOtherProps & Translation) => {
		const { value, onChange } = useSourceInput();

		if (value.source !== "OTHER") return null;
		return (
			<Input
				label={label || t("label")}
				placeholder={placeholder || t("placeholder")}
				name="other"
				onChange={(key, description) => {
					onChange({
						...value,
						description,
					});
				}}
				value={value?.description}
			/>
		);
	},
	{
		nl: {
			label: "Kan je ons vertellen waar je ons gevonden hebt?",
			placeholder: "Bijvoorbeeld: een website, een blog, een podcast...",
		},
		en: {
			label: "Could you tell us where you found us?",
			placeholder: "For example: a website, a blog, a podcast...",
		},
	}
);
