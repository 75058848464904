import { useEffect } from "react";
import Card from "src/components/Card";
import withTranslation, {
	Translation,
} from "src/components/hoc/withTranslation";
import TimeTrackerList from "src/components/time-tracker/TimeTrackerList";
import Timer from "src/components/time-tracker/Timer";
import useTimeTracking from "src/hooks/api/services/useTimeTracking";

interface TimeTrackerCardProps extends Translation {
	className?: string;
	data: {
		task_id: number;
	};
}

const TimeTrackerCard = ({ t, data, className }: TimeTrackerCardProps) => {
	const { list, actions } = useTimeTracking();

	useEffect(() => {
		actions.list({
			with: ["creator"],
			...data,
		});
		return () => {
			actions.resetList();
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<Card title={t("title")} {...{ className }}>
			<Timer size="large" {...{ data }} />
			{!!list?.length && (
				<>
					<hr className="border-border"></hr>
					<TimeTrackerList {...{ data }} />
				</>
			)}
		</Card>
	);
};

TimeTrackerCard.locale = {
	nl: {
		title: "Tijd bijhouden",
		estimated: {
			title: "Geschatte tijdsduur",
		},
	},
	en: {
		title: "Track time",
		estimated: {
			title: "Estimated duration",
		},
	},
};

export default withTranslation(TimeTrackerCard);
