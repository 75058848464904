import { ApiRegistration, ApiTask } from "src/api/types";
import Chat from "src/components/chat/Chat";

interface TaskChatProps {
	task: ApiTask;
	registration: ApiRegistration | any;
}

const TaskChat = ({ task, registration }: TaskChatProps) => {
	return (
		<Chat
			options={{
				type: "task",
				room: `room-${registration.id}`,
			}}
			data={{
				task,
				registration,
			}}
		/>
	);
};

export default TaskChat;
