import { ComponentProps } from "react";
import Lottie from "react-lottie";
import * as aiLoader from "src/assets/lotties/ai-loader.json";
import { cn } from "src/lib/utils";

interface LoaderProps extends ComponentProps<"div"> {
	variant?: "default" | "ai";
}

const Loader = ({ variant = "default", ...rest }: LoaderProps) => {
	if (variant === "ai") {
		return (
			<div {...rest} className={cn("relative", rest?.className)}>
				<div className="scale-[1.7] pointer-events-none">
					<Lottie
						options={{
							loop: true,
							autoplay: true,
							animationData: aiLoader,
							rendererSettings: {
								preserveAspectRatio: "xMidYMid slice",
							},
						}}
						height="100%"
						width="100%"
					/>
				</div>
			</div>
		);
	}
	return (
		<div {...rest}>
			<span className="base-loader"></span>
		</div>
	);
};

const LoaderOverlay = ({ variant = "default" }: LoaderProps) => (
	<div className="absolute inset-0 backdrop-blur-sm flex justify-center items-center rounded-md z-10">
		<Loader />
	</div>
);

Loader.Overlay = LoaderOverlay;

export default Loader;
