import { PopoverPortal } from "@radix-ui/react-popover";
import moment from "moment";
import {
	ComponentProps,
	createContext,
	useContext,
	useEffect,
	useState,
} from "react";
import Button from "src/components/Button";
import Field from "src/components/field/Field";
import Wrapper from "src/components/field/utils/Wrapper";
import withTranslation, {
	Translation,
} from "src/components/hoc/withTranslation";
import {
	Popover,
	PopoverContent,
	PopoverTrigger,
} from "src/components/ui/popover";
import { PaymentRequestFormValue } from "src/hooks/api/services/payment-requests/usePaymentRequests";
import useHoursBetweenDates from "src/hooks/useHoursBetweenDates";
import { roundToDecimalPlace } from "src/lib/formatters";
import { cn } from "src/lib/utils";

const FieldContext = createContext<{
	open: boolean;
	onOpenChange: React.Dispatch<React.SetStateAction<boolean>>;
	disabled?: boolean;
}>({ open: false, onOpenChange: () => {}, disabled: false });

interface PaymentRequestPriceFieldProps extends Translation {
	value: PaymentRequestFormValue;
	onChange?: React.Dispatch<React.SetStateAction<PaymentRequestFormValue>>;
	onSubmit?: (value: number) => Promise<void>;
	children?: any;
	disabled?: boolean;
}

const PaymentRequestPriceField = ({
	t,
	value,
	children,
	onChange: _onChange,
	onSubmit,
	disabled,
}: PaymentRequestPriceFieldProps) => {
	const [open, onOpenChange] = useState(false);
	const hours = useHoursBetweenDates(
		value?.start_date,
		value?.end_date,
		value?.break
	);
	const [_value, _setValue] = useState<{ price: number }>({
		price: value?.price ?? 0,
	});
	const [isLoading, setIsLoading] = useState(false);

	const onChange = _onChange ? _onChange : onSubmit ? _setValue : null;

	useEffect(() => {
		if (!isLoading) {
			_setValue({ price: value?.price ?? 0 });
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [value]);

	const handleSubmit = async () => {
		setIsLoading(true);
		try {
			await onSubmit?.(_value.price);
			setIsLoading(false);
			onOpenChange(false);
		} catch (error) {
			setIsLoading(false);
		}
	};

	return (
		<FieldContext.Provider value={{ open, onOpenChange, disabled }}>
			<Popover open={!disabled && open} {...{ onOpenChange }}>
				{children}
				<PopoverPortal>
					<PopoverContent>
						<div className="flex flex-col gap-2 z-[99]">
							{value?.start_date && (
								<Wrapper
									layout="horizontal-always"
									label={t("fields.start.label")}
								>
									<span>
										{moment(value.start_date).format(
											"HH:mm"
										)}
									</span>
								</Wrapper>
							)}
							{value?.end_date && (
								<Wrapper
									layout="horizontal-always"
									label={t("fields.end.label")}
								>
									<span>
										{moment(value.end_date).format("HH:mm")}
									</span>
								</Wrapper>
							)}
							{value?.break && value?.break > 0 && (
								<Wrapper
									layout="horizontal-always"
									label={t("fields.break.label")}
								>
									<span>
										{t("fields.break.value", {
											value: value.break,
										})}
									</span>
								</Wrapper>
							)}
							{hours !== null && (
								<Wrapper
									layout="horizontal-always"
									label={t("fields.hours.label")}
								>
									<span>
										{t("fields.hours.value", {
											value: hours,
										})}
									</span>
								</Wrapper>
							)}
							{onChange && (
								<>
									{hours !== null && (
										<Field.Input
											type="number"
											value={
												_value.price
													? roundToDecimalPlace(
															_value.price /
																hours,
															2
													  )
													: ""
											}
											onChange={(price: any) => {
												const newPrice: number | null =
													!isNaN(price)
														? Number(price * hours)
														: null;

												onChange((prev: any) => ({
													...prev,
													price: newPrice
														? roundToDecimalPlace(
																newPrice,
																2
														  )
														: prev.price,
												}));
											}}
											placeholder={t(
												"fields.hour_rate.placeholder"
											)}
											className="border-border md:min-w-0 w-full"
											wrapper={{
												className: "w-full",
												label: t(
													"fields.hour_rate.label"
												),
											}}
										/>
									)}
									<Field.Input
										type="number"
										value={_value?.price?.toString()}
										onChange={(price) =>
											onChange((prev: any) => ({
												...prev,
												price: price
													? Number(price)
													: undefined,
											}))
										}
										placeholder={t(
											"fields.price.placeholder"
										)}
										className="border-border w-full"
										wrapper={{
											className: "w-full",
											label: t("fields.price.label"),
										}}
									/>
								</>
							)}

							{onSubmit && (
								<Button
									loading={isLoading}
									disabled={!_value.price}
									onClick={handleSubmit}
								>
									{t("buttons.save")}
								</Button>
							)}
						</div>
					</PopoverContent>
				</PopoverPortal>
			</Popover>
		</FieldContext.Provider>
	);
};

PaymentRequestPriceField.locale = {
	nl: {
		fields: {
			start: { label: "Van" },
			end: { label: "Tot" },
			break: { label: "Pauze", value: "{{value}} uur" },
			hours: { label: "Uur", value: "{{value}} uur" },
			price: { label: "Totale waarde", placeholder: "€" },
			hour_rate: { label: "Per uur", placeholder: "€" },
		},
		buttons: {
			save: "Opslaan",
		},
	},
	en: {
		fields: {
			start: { label: "From" },
			end: { label: "To" },
			break: { label: "Break", value: "{{value}} hours" },
			hours: { label: "Hours", value: "{{value}} hours" },
			price: { label: "Total Value", placeholder: "€" },
			hour_rate: { label: "Per Hour", placeholder: "€" },
		},
		buttons: {
			save: "Save",
		},
	},
};

export const PaymentRequestPriceFieldTrigger = ({
	className,
	children,
	...rest
}: ComponentProps<"button">) => {
	const { disabled } = useContext(FieldContext);
	return (
		<PopoverTrigger
			{...(rest as any)}
			{...{ disabled }}
			className={cn(
				"flex gap-1.5 justify-center items-center bg-transparent h-[44px] w-max rounded-md px-3 text-accent-foreground border-border border focus:border-border cursor-pointer",
				disabled && "bg-accent cursor-not-allowed",
				className
			)}
		>
			{children}
		</PopoverTrigger>
	);
};

export default withTranslation(PaymentRequestPriceField as any) as any;
