import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import Button from "src/components/Button";
import EmptyState from "src/components/EmptyState";
import withTranslation, {
	Translation,
} from "src/components/hoc/withTranslation";
import PaymentRequestTableItem from "src/components/payment-request/visualizations/PaymentRequestTableItem";
import List from "src/components/ui/list";
import usePaymentRequests, {
	PaymentRequestFilter,
} from "src/hooks/api/services/payment-requests/usePaymentRequests";
import useAuth from "src/hooks/selectors/useAuth";
import useNavigateModal from "src/hooks/useNavigateModal";

interface PaymentRequestTableProps extends Translation {
	children?: any;
	filter?: PaymentRequestFilter;
	createUrl: string;
}

const PaymentRequestTable = ({
	t,
	filter,
	createUrl,
}: PaymentRequestTableProps) => {
	const navigateModal = useNavigateModal();
	const auth = useAuth();
	const { requests, status, actions, pagination } = usePaymentRequests({
		stateId: "PAYMENT_REQUESTS_TABLE",
		filter,
	});
	const location = useLocation();

	useEffect(() => {
		actions.list(filter);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [location, filter]);

	return (
		<List
			data={requests}
			{...{ status }}
			showEmptyList
			keepListWhileLoading
			className="relative flex-1"
		>
			{requests.length === 0 && status === "idle" && (
				<EmptyState.Overlay className="p-4">
					<EmptyState.Title>{t("empty.title")}</EmptyState.Title>
					<EmptyState.Description>
						{t("empty.description")}
					</EmptyState.Description>
					<EmptyState.Buttons>
						<Button
							type="dark"
							to={{
								modal: createUrl,
							}}
						>
							{t("buttons.create")}
						</Button>
					</EmptyState.Buttons>
				</EmptyState.Overlay>
			)}
			{auth.type === "company" && (
				<List.Selected variant="floating-card">
					{(selected, clear) => (
						<>
							<small className="hidden md:block">
								{t("selected", { count: selected.length })}
							</small>
							<Button
								onClick={() => {
									let params = new URLSearchParams();
									selected.forEach((item: any) => {
										params.append("ids", item);
									});
									clear();
									navigateModal(
										`/payment-requests/deny?${params}`
									);
								}}
								disabled={status !== "idle"}
								xsmall
							>
								<i className="far fa-times"></i>
								{t("buttons.deny")}
							</Button>
							<Button
								xsmall
								onClick={() =>
									actions.approve(selected).then(async () => {
										await actions.list(filter);
										clear();
									})
								}
							>
								<i className="far fa-check"></i>
								{t("buttons.accept")}
							</Button>
						</>
					)}
				</List.Selected>
			)}
			<List.Head>
				{auth.type === "company" && (
					<List.Item className="md:pr-0 px-0"></List.Item>
				)}
				<List.Item>{t("headers.status")}</List.Item>
				<List.Item>
					{t(
						`headers.${
							auth.type === "student" ? "company" : "student"
						}`
					)}
				</List.Item>
				<List.Item>{t("headers.description")}</List.Item>
				<List.Item>{t("headers.date")}</List.Item>
				<List.Item>{t("headers.price")}</List.Item>
				<List.Item>{t("headers.total")}</List.Item>
				<List.Item>{t("headers.paid-at")}</List.Item>
				<List.Item></List.Item>
			</List.Head>
			<List.Items>
				{(row) => (
					<PaymentRequestTableItem
						request={row}
						key={`row-${row.id}`}
						onUpdate={(value) =>
							actions.update(
								{ id: row.id, ...value },
								{ updateList: true }
							)
						}
					/>
				)}
			</List.Items>
			<List.Pagination
				{...{ pagination }}
				onNavigate={pagination.setPage}
			/>
		</List>
	);
};

PaymentRequestTable.locale = {
	nl: {
		selected: "{{count}} geselecteerd",
		headers: {
			status: "Status",
			company: "Opdrachtgever",
			student: "Opdrachtnemer",
			description: "Omschrijving",
			date: "Datum",
			price: "Waarde",
			total: "Totaal",
			"paid-at": "Uitbetaald op",
		},
		buttons: {
			edit: "Bewerk",
			delete: "Annuleren",
			create: "Betaalverzoek maken",
			accept: "Accepteren",
			deny: "Afwijzen",
		},
		empty: {
			title: "Geen verzoeken gevonden",
			description: "Er zijn geen verzoeken gevonden, maak er één aan.",
		},
	},
	en: {
		selected: "{{count}} selected",
		headers: {
			status: "Status",
			company: "Client",
			student: "Contractor",
			description: "Description",
			date: "Date",
			price: "Value",
			total: "Total",
			"paid-at": "Paid at",
		},
		buttons: {
			edit: "Edit",
			delete: "Cancel",
			create: "Create Payment Request",
			accept: "Approve",
			deny: "Reject",
		},
		empty: {
			title: "No requests found",
			description: "No requests found, create one.",
		},
	},
};

export default withTranslation(PaymentRequestTable);
