import { createAction, handleActions } from "redux-actions";
import { ApiOfferCreate } from "src/api/types";

export const updateOffer = createAction("UPDATE_OFFER");
export const removeOffer = createAction("REMOVE_OFFER");

export const emptyOfferState = {
	offer: {
		title: "",
		description: {
			intro: "",
			what: "",
			how: "",
		},

		category_id: undefined,
		visibility: {
			is_private: false,
			is_anonymous: false,
			status: undefined,
		},
		estimated_duration: undefined,
		budget: undefined,
		tags: [],
	},
	current: 0,
	loading: true,
};

type OfferState = {
	offer: ApiOfferCreate;
	current: number;
	loading: boolean;
};

const offerReducer = handleActions(
	{
		UPDATE_OFFER: (state: OfferState, action: any) => {
			return { ...state, ...action.payload };
		},
		REMOVE_OFFER: () => {
			return {
				...emptyOfferState,
			} as any;
		},
	},
	emptyOfferState as any
);
export default offerReducer;
