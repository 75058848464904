import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Button from "src/components/Button";
import Onboarding from "src/components/forms/onboarding";
import withTranslation, {
	Translation,
} from "src/components/hoc/withTranslation";
import OnboardingLayout from "src/components/layout/onboarding-layout/OnboardingLayout";
import {
	SourceInput,
	SourceInputDropdown,
	SourceInputOther,
	SourceValue,
} from "src/components/Onboarding/source-input";
import ExperienceForm from "src/components/resume/elements/experience-form/ExperienceForm";
import InterestsForm from "src/components/resume/elements/InterestsForm";
import ResumeAvatar from "src/components/resume/elements/ResumeAvatar";
import Steps from "src/components/Steps";
import useOnboarding from "src/hooks/api/services/auth/useOnboarding";
import useTenant from "src/hooks/api/services/tenants/useTenant";
import useAuth from "src/hooks/selectors/useAuth";
import useQueryString from "src/hooks/useQueryString";

const OnboardingView = ({ t }: Translation) => {
	const auth = useAuth();
	const {
		tenant: { modules },
	} = useTenant();
	const navigate = useNavigate();
	const {
		step,
		actions,
		defaultStep,
		onboarding,
		data: onboardingData,
		status,
	} = useOnboarding();
	const [source, setSource] = useState<SourceValue>({
		mode: "SELECT",
		source: undefined,
	});
	const qs = useQueryString();
	const containerClassName = "min-h-[33vh] flex flex-col gap-8";
	useEffect(() => {
		actions.current();
		if (qs.reset) {
			actions.setStep(defaultStep);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleSkip = () => {
		if (
			auth.type === "student" &&
			(modules.includes("extern-task") || modules.includes("vacancies"))
		) {
			const currentIndex = onboardingData?.all?.indexOf(step);

			actions.setStep(
				onboardingData?.all[currentIndex + 1] || "completed"
			);
			return;
		}

		switch (step) {
			case "create-company":
				actions.setStep("company");
				break;
			case "create-teams":
				actions.setStep(
					onboarding.teams.length === 0
						? "invite-users"
						: "team-skills"
				);
				break;
			case "team-skills":
				actions.setStep("invite-users");
				break;
			case "invite-users":
				actions.setStep("create-boards");
				break;
			case "create-boards":
				actions.setStep(
					auth.type === "student" ? "completed" : "completed"
				);
				break;
			case "website":
				actions.setStep("completed");
				break;
			default:
				break;
		}
	};

	const handleBack = () => {
		if (
			auth.type === "student" &&
			(modules.includes("extern-task") || modules.includes("vacancies"))
		) {
			const currentIndex = onboardingData?.all?.indexOf(step);
			const previousStep = onboardingData?.all[currentIndex - 1];

			if (
				step === "education_experience" &&
				(onboarding.resumes.getjobsdone ||
					onboarding.resumes.getcrewdone)
			) {
				actions.setStep("copy-resume");
			} else if (previousStep) {
				actions.setStep(previousStep);
			}
			return;
		}
	};

	const handleComplete = async () => {
		await actions.complete({
			source,
		});
		navigate("/dashboard");
	};

	if (
		auth.type === "student" &&
		(modules.includes("extern-task") || modules.includes("vacancies"))
	) {
		return (
			<OnboardingLayout
				actions={
					<>
						{(onboardingData?.skippable || []).includes(step) &&
							step !== "avatar" && (
								<Button
									xsmall
									type="border"
									onClick={handleSkip}
								>
									{t("skip")}
								</Button>
							)}
					</>
				}
			>
				<OnboardingLayout.Header>
					<Steps
						max={onboardingData.all.length}
						current={onboardingData.all.indexOf(step)}
						className="mb-2 md:mb-8 max-w-[360px]"
						theme={{
							item: {
								inactive: "bg-border",
								active: "bg-primary",
							},
						}}
					/>
					<OnboardingLayout.Title>
						{t(`${auth.type}.${step}.title`)}
					</OnboardingLayout.Title>
					<OnboardingLayout.Description>
						{t(`${auth.type}.${step}.description`)}
					</OnboardingLayout.Description>
				</OnboardingLayout.Header>

				{step === "copy-resume" && (
					<OnboardingLayout.Container>
						<Onboarding.CopyResume />
					</OnboardingLayout.Container>
				)}

				{["education_experience", "job_experience"].includes(
					step as any
				) && (
					<ExperienceForm
						type={
							step === "education_experience"
								? "education"
								: "job"
						}
						key={step as any}
					>
						<OnboardingLayout.Container
							className={containerClassName}
						>
							<ExperienceForm.List
								className="flex-1"
								getEditTo={({ id, type }) => ({
									modal: `/onboarding/experiences/${type}/${id}/edit`,
								})}
							/>
							<ExperienceForm.EmptyState className="flex-1" />
							<div className="flex flex-col gap-3">
								<ExperienceForm.Autocomplete className="flex-col md:flex-row" />
								<ExperienceForm.QuickCreate />
							</div>
						</OnboardingLayout.Container>
						<OnboardingLayout.Footer className="justify-end gap-3">
							<Button
								type="gray"
								disabled={
									step === "education_experience" &&
									!(
										onboarding?.resumes?.getjobsdone ||
										onboarding?.resumes?.getcrewdone
									)
								}
								onClick={handleBack}
							>
								{t("back")}
							</Button>
							<Button
								onClick={() => {
									if (step === "education_experience") {
										actions.setStep("job_experience");
										return;
									}

									if (step === "job_experience") {
										actions.setStep("interests");
										return;
									}
								}}
							>
								{t("continue")}
							</Button>
						</OnboardingLayout.Footer>
					</ExperienceForm>
				)}

				{step === "interests" && (
					<InterestsForm>
						<OnboardingLayout.Container
							className={containerClassName}
						>
							<InterestsForm.List />
						</OnboardingLayout.Container>
						<OnboardingLayout.Footer className="justify-end gap-3">
							<Button type="gray" onClick={handleBack}>
								{t("back")}
							</Button>
							<InterestsForm.SubmitButton
								onSubmitted={() => actions.setStep("avatar")}
							>
								{t("continue")}
							</InterestsForm.SubmitButton>
						</OnboardingLayout.Footer>
					</InterestsForm>
				)}

				{step === "avatar" && (
					<>
						<OnboardingLayout.Container
							className={containerClassName}
						>
							<ResumeAvatar />
							<SourceInput value={source} onChange={setSource}>
								<SourceInputDropdown
									label={t("fields.source.label")}
									placeholder={t("fields.source.placeholder")}
								/>
								<SourceInputOther />
							</SourceInput>
						</OnboardingLayout.Container>
						<OnboardingLayout.Footer className="justify-end gap-3">
							<Button type="gray" onClick={handleBack}>
								{t("back")}
							</Button>
							<Button
								disabled={!source.source}
								onClick={handleComplete}
							>
								{t("complete")}
							</Button>
						</OnboardingLayout.Footer>
					</>
				)}
			</OnboardingLayout>
		);
	}

	return (
		<OnboardingLayout className="justify-center items-center gap-12">
			<OnboardingLayout.Header>
				{step !== "completed" && (
					<OnboardingLayout.Title>
						{t(`${auth.type}.${step}.title`)}
					</OnboardingLayout.Title>
				)}
			</OnboardingLayout.Header>
			<OnboardingLayout.Container>
				<Onboarding className="w-full flex flex-1 flex-col z-2 relative">
					{status !== "loading" && (
						<>
							{step === "create-teams" &&
								modules.includes("intern-task") && (
									<Onboarding.CreateTeams />
								)}
							{step === "team-skills" &&
								modules.includes("intern-task") && (
									<Onboarding.TeamSkills />
								)}
							{step === "invite-users" && (
								<Onboarding.InviteUsers />
							)}
							{step === "create-boards" &&
								modules.includes("intern-task") && (
									<Onboarding.CreateBoards />
								)}

							{step === "website" && <Onboarding.Website />}
							{step === "company" && <Onboarding.Company />}
							{step === "completed" && <Onboarding.Completed />}
						</>
					)}
				</Onboarding>
			</OnboardingLayout.Container>
			<div className="flex flex-col w-full mt-4">
				{!["completed", "company", "create-company"].includes(
					step as any
				) && (
					<Button
						onClick={handleSkip}
						type="transparent"
						className="opacity-50 hover:opacity-100"
					>
						<span className="relative flex items-center">
							{t("skip")}
							<i className="far fa-arrow-right absolute -right-6"></i>
						</span>
					</Button>
				)}
			</div>
		</OnboardingLayout>
	);
};

OnboardingView.locale = {
	nl: {
		continue: "Doorgaan",
		skip: "Overslaan",
		back: "Terug",
		complete: "Afronden",
		fields: {
			source: {
				label: "Hoe heb je van ons gehoord",
				placeholder: "Via welk medium...",
			},
		},
		student: {
			"create-boards": {
				eyebrow: "Borden / groepen",
				title: "Onderscheid je taken in verschillende groepen",
			},
			"copy-resume": {
				title: "Je hebt al een CV",
				description:
					"Bij een ander product van ons heb je al een CV aangemaakt. Je kunt deze hier kopieren en gebruiken.",
			},
			completed: {
				eyebrow: "Ready",
				title: "Je account is compleet!",
				description:
					"Je account is nu klaar om op opdrachten te reageren",
			},
			education_experience: {
				title: "Wat voor opleidingen heb je gedaan?",
				description:
					"Deze gegevens gebruiken we om een beter beeld van je vaardigheden te krijgen.",
			},
			job_experience: {
				title: "Wat voor werkervaring heb je al?",
				description:
					"Deze gegevens gebruiken we om een beter beeld van je vaardigheden te krijgen.",
			},
			interests: {
				title: "In welke opdrachten heb je interesses?",
				description:
					"Dit gebruiken we om nieuwe opdrachten te vinden en voor te stellen.",
			},
			avatar: {
				title: "De laatste stap",
				description:
					"Vertel ons waar je ons gevonden hebt en voeg eventueel een profielfoto toe om je account persoonlijk te maken.",
			},
		},
		company: {
			"create-company": {
				eyebrow: "Account",
				title: "Hoe heet je bedrijf?",
			},
			"create-teams": {
				eyebrow: "Teams instellen",
				title: "Welke teams zijn er binnen jouw bedrijf?",
			},
			"team-skills": {
				eyebrow: "Skills toewijzen",
				title: "Welke skills zijn er nodig om te werken binnen deze teams?",
			},
			"invite-users": {
				eyebrow: "Mederwerkers uitnodigen",
				title: "Voeg medewerkers toe aan je team",
			},
			"create-boards": {
				eyebrow: "Borden / omgevingen",
				title: "Maak verschillende borden aan voor je teams",
			},
			website: {
				eyebrow: "Website",
				title: "Mogen we de url van je website?",
			},
			completed: {
				eyebrow: "Ready",
				title: "Je account is klaar om taken beter te managen",
			},
			company: {
				eyebrow: "Account",
				title: "Bedrijfsgegevens",
			},
		},
	},
	en: {
		continue: "Continue",
		skip: "Skip",
		back: "Back",
		complete: "Complete",
		fields: {
			source: {
				label: "How did you hear about us",
				placeholder: "Via which medium...",
			},
		},
		student: {
			"create-boards": {
				eyebrow: "Boards / Groups",
				title: "Organize your tasks into different groups",
			},
			completed: {
				eyebrow: "Ready",
				title: "Your account is complete!",
				description: "Your account is now ready to apply for tasks.",
			},
			"copy-resume": {
				title: "You already have a resume",
				description:
					"You have a much higher chance of success when you have set a profile picture.",
			},
			education_experience: {
				title: "What kind of education have you completed?",
				description:
					"We use this information to get a better understanding of your skills.",
			},
			job_experience: {
				title: "What work experience do you already have?",
				description:
					"We use this information to get a better understanding of your skills.",
			},
			interests: {
				title: "What tasks are you interested in?",
				description:
					"We use this to find and suggest new tasks for you.",
			},
			avatar: {
				title: "Add a profile picture",
				description:
					"You have a much higher chance of success when you have set a profile picture.",
			},
		},
		company: {
			"create-company": {
				eyebrow: "Account",
				title: "What is your company name?",
			},
			"create-teams": {
				eyebrow: "Set up teams",
				title: "What teams exist within your company?",
			},
			"team-skills": {
				eyebrow: "Assign skills",
				title: "What skills are required to work within these teams?",
			},
			"invite-users": {
				eyebrow: "Invite employees",
				title: "Add employees to your team",
			},
			"create-boards": {
				eyebrow: "Boards / Environments",
				title: "Create different boards for your teams",
			},
			website: {
				eyebrow: "Website",
				title: "May we have your website URL?",
			},
			completed: {
				eyebrow: "Ready",
				title: "Your account is ready to manage tasks better.",
			},
			company: {
				eyebrow: "Account",
				title: "Company details",
			},
		},
	},
};

export default withTranslation(OnboardingView);
