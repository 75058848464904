import { createAction, handleActions } from "redux-actions";

export const setAlert = createAction("SET_ALERT");
export const clearAlert = createAction("CLEAR_ALERT");

export const emptyAlert = {
	title: "",
	text: undefined,
	type: undefined,
};

const alertReducer = handleActions(
	{
		SET_ALERT: (state: any, action: any) => {
			return { ...state, ...action.payload };
		},
		CLEAR_ALERT: () => {
			return {
				...emptyAlert,
			};
		},
	},
	{
		...emptyAlert,
	}
);
export default alertReducer;
