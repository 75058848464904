import { motion } from "framer-motion";
import { atom, useAtom } from "jotai";
import { atomFamily } from "jotai/utils";
import { useEffect, useMemo, useState } from "react";
import Button from "src/components/Button";
import Icon from "src/components/field/utils/Icon";
import SelectSearch from "src/components/field/utils/SelectSearch";
import Wrapper, { WrapperProps } from "src/components/field/utils/Wrapper";
import withTranslation, {
	Translation,
} from "src/components/hoc/withTranslation";
import {
	Command,
	CommandEmpty,
	CommandGroup,
	CommandItem,
} from "src/components/ui/command";
import {
	Popover,
	PopoverContent,
	PopoverTrigger,
} from "src/components/ui/popover";
import Text from "src/components/ui/text";
import { filterByUnique } from "src/lib/filters";
import { cn } from "src/lib/utils";

export type SelectValue = string | string[] | any;

type Option = {
	value: string;
	label: string | JSX.Element;
	group?: string;
};

const recentUsedAtom = atomFamily(
	() => atom<Option[]>([]),
	(a: any, b: any) => a.name === b.name
);

export type SelectTheme = {
	value?: {
		arrow?: "caret";
		renderValue?: (value: SelectValue) => JSX.Element;
	};
	placeholder?: {
		className?: string;
	};
	card?: {
		className?: string;
	};
	trigger?: {
		className?: string;
		arrow?: {
			position?: "left" | "right";
			className?: string;
		};
	};
	item?: {
		indicator?: boolean;
		className?: string;
	};
};

export type SelectSettings = {
	isClearable?: boolean;
	stayOpen?: boolean;
	hideArrow?: boolean;
};

export type SelectRecent = {
	name: string;
};

interface SelectProps extends Translation {
	wrapper?: WrapperProps;
	options: Option[];
	placeholder?: string;
	value?: SelectValue;
	onChange: (value?: SelectValue) => void;
	disabled?: boolean;
	hideSearch?: boolean;
	search?: {
		onChange: (search: string) => void;
		loading?: boolean;
		placeholder?: string;
		value?: string;
		autoFocus?: boolean;
	};
	onClose?: () => void;
	create?: {
		value: string;
		render: JSX.Element;
	};
	theme?: SelectTheme;
	settings?: SelectSettings;
	empty?: {
		description: string;
	};
	recent?: SelectRecent;
	className?: string;
}

const Select = ({
	t,
	wrapper,
	options,
	placeholder,
	value,
	onChange,
	search,
	hideSearch,
	theme,
	settings,
	empty,
	onClose,
	create,
	disabled,
	recent,
	className,
}: SelectProps) => {
	const [recents, setRecents] = useAtom(
		recentUsedAtom({ name: recent?.name || "" })
	);
	const [saveRecentValue, setSaveRecentValue] = useState(false);
	const [isOpen, setIsOpen] = useState(false);
	const isMultiple = Array.isArray(value);
	const hasValue = isMultiple ? isMultiple && value?.length > 0 : !!value;
	const selected: Option[] | null = useMemo(() => {
		if (!value) return null;
		return options.filter(
			(item) =>
				item.value === value ||
				(Array.isArray(value) && value.includes(item.value))
		);
	}, [value, options]);
	const groups = options
		.map((item) => item.group)
		.filter(filterByUnique)
		.filter((item) => item);

	useEffect(() => {
		return () => {
			if (saveRecentValue && value) {
				const option = options.find((item) => item.value === value);
				if (option && !recents?.find((item) => item.value === value)) {
					setRecents((items: Option[]) =>
						[option, ...items].filter((item, index) => index < 3)
					);
				}
			}
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [saveRecentValue]);

	const isCreateAllowed = useMemo(() => {
		if (!search?.value) return false;
		//Format the search string
		const term = search.value.toString().split(" ").join("").toLowerCase();
		//Check if value can be found
		const option = options.find((option) => {
			return (
				option.value.toString().split(" ").join("").toLowerCase() ===
				term
			);
		});

		return !option?.label ? true : false;
	}, [search, options]);

	const handleChange = (update?: any) => {
		if (disabled) return;
		if (!update) {
			onChange(isMultiple ? [] : undefined);
			setIsOpen(false);
			return;
		}
		if (update !== value) {
			setSaveRecentValue(true);
		}

		if (isMultiple) {
			if (value.includes(update)) {
				onChange(value.filter((item: string) => item !== update));
			} else {
				onChange([...value, update]);
			}
		} else {
			onChange(update);
		}

		if (!settings?.stayOpen) {
			setIsOpen(false);
		}
	};

	const renderItem = (option: Option) => {
		// const isSelected =
		// 	option.value === value ||
		// 	(isMultiple &&
		// 		(value?.includes(option.value) ||
		// 			value?.includes(option.value.toString()) ||
		// 			value?.includes(Number(option.value))));
		return (
			<CommandItem
				key={option.value}
				onSelect={() => {
					handleChange(
						option.value !== value ? option.value : undefined
					);
				}}
				value={option.value}
				className={cn("grid", theme?.item?.className)}
			>
				{option.label}
			</CommandItem>
		);
	};

	return (
		<Wrapper {...wrapper}>
			<div className={`relative`}>
				<Popover
					open={isOpen}
					onOpenChange={(v) => {
						if (disabled) {
							return;
						}
						setIsOpen(v);
						if (onClose && !v) {
							onClose();
						}
					}}
				>
					<PopoverTrigger asChild>
						<button
							{...{ disabled }}
							// onFocus={() =>
							// 	!disabled && !isOpen && setIsOpen(true)
							// }
							className={cn(
								"flex items-center gap-3 border border-transparent py-1.5 px-3 rounded-md text-background-foreground hover:border-border focus:border-border transition-all",
								theme?.trigger?.className,
								disabled && "bg-accent cursor-not-allowed",
								className
							)}
							onClick={(e) => {
								e.stopPropagation();
							}}
						>
							<div className="flex items-center gap-1 flex-1">
								{!hasValue && (
									<span
										className={cn(
											"text-placeholder line-clamp-1",
											theme?.placeholder?.className || ""
										)}
									>
										{placeholder || t("placeholder")}
									</span>
								)}
								{hasValue && (
									<>
										{theme?.value?.renderValue
											? theme.value.renderValue(value)
											: selected &&
											  selected.map((item: Option) => (
													<div key={item.value}>
														{item.label}
													</div>
											  ))}
									</>
								)}
							</div>

							{theme?.value?.arrow === "caret" &&
								!settings?.hideArrow && (
									<motion.div
										animate={isOpen ? "open" : "closed"}
										variants={{
											open: {
												rotate: 180,
											},
										}}
									>
										<i className="fas fa-caret-down"></i>
									</motion.div>
								)}
							{!theme?.value?.arrow && !settings?.hideArrow && (
								<motion.div
									animate={isOpen ? "open" : "closed"}
									variants={{
										open: {
											rotate: 180,
										},
									}}
								>
									<Icon
										className={cn(
											"cursor-pointer bg-transparent text-placeholder transition-all",
											isOpen && "text-accent-foreground",
											theme?.trigger?.arrow?.className
										)}
										icon="far fa-angle-down"
									/>
								</motion.div>
							)}
						</button>
					</PopoverTrigger>
					<PopoverContent
						align="start"
						className="w-full p-0 max-w-[220px]"
						onClick={(e) => {
							e.stopPropagation();
						}}
					>
						<Command
							className="max-h-[300px] overflow-auto"
							shouldFilter={search?.onChange ? false : true}
						>
							{!hideSearch && (
								<div className="flex relative">
									{settings?.isClearable && hasValue && (
										<div className="absolute top-0 right-3 bottom-0 flex items-center opacity-50">
											<i
												onClick={() => {
													handleChange();
													setIsOpen(false);
												}}
												className="fal fa-times cursor-pointer"
											></i>
										</div>
									)}
									<SelectSearch {...search} />
								</div>
							)}
							<CommandEmpty>
								<p className="opacity-20">
									{empty?.description ||
										t("empty.description")}
								</p>
							</CommandEmpty>

							{groups.length > 0 ? (
								<>
									{groups.map((group: any) => (
										<CommandGroup
											key={`group-${group}`}
											heading={
												<Text.Eyebrow className="text-[12px] dark:text-[12px]">
													{group}
												</Text.Eyebrow>
											}
										>
											{options
												.filter(
													(item) =>
														item.group === group
												)
												.map(renderItem)}
										</CommandGroup>
									))}

									{create?.value && isCreateAllowed && (
										<CommandGroup className="max-h-[240px] overflow-auto">
											<CommandItem
												value={create.value}
												onSelect={() => {
													handleChange(create.value);
												}}
											>
												{create.render}
											</CommandItem>
										</CommandGroup>
									)}
								</>
							) : (
								<CommandGroup className="max-h-[240px] overflow-auto">
									{options.map(renderItem)}

									{create?.value && isCreateAllowed && (
										<CommandItem
											value={create.value}
											onSelect={() => {
												handleChange(create.value);
											}}
										>
											{create.render}
										</CommandItem>
									)}
								</CommandGroup>
							)}
						</Command>
					</PopoverContent>
				</Popover>
			</div>
			{recent && recents.length > 0 && !value ? (
				<div className="mt-2">
					<div className="flex items-center gap-1 flex-wrap">
						{recents &&
							recents.map((item: Option) => (
								<Button
									className="text-[14px] py-1.5 px-1.5"
									xsmall
									type="border"
									onClick={() => handleChange(item.value)}
									key={item.value}
								>
									{theme?.value?.renderValue
										? theme.value.renderValue(item.value)
										: item.label}
								</Button>
							))}
					</div>
				</div>
			) : undefined}
		</Wrapper>
	);
};

Select.locale = {
	nl: {
		placeholder: "Selecteer...",
		empty: {
			description: "Geen resultaten gevonden",
		},
	},
	en: {
		placeholder: "Select...",
		empty: {
			description: "No results found",
		},
	},
};

export default withTranslation(Select);
