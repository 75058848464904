import { useTranslation } from "react-i18next";
import Input from "src/components/form/Input";
import { OfferEditFormType } from "src/components/offer/forms/edit/OfferEditFormType";
import OfferEditForm from "src/components/offer/forms/OfferEditForm";
import useEditOffer from "src/hooks/api/useEditOffer";
import useOfferScheme from "src/hooks/schemes/useOfferScheme";

const OfferBudgetForm = ({ id, isOpen, toggleOpen }: OfferEditFormType) => {
	const { t } = useTranslation("offer", {
		keyPrefix: "form",
	});
	const { offer } = useEditOffer(id);
	const scheme = useOfferScheme();

	return (
		<OfferEditForm
			title={t("title-budget")}
			value={{
				budget: offer?.budget,
				estimated_duration: offer?.estimated_duration,
			}}
			schema={() => ({
				budget: scheme.budget,
				estimated_duration: scheme.estimated_duration,
			})}
			{...{ isOpen, toggleOpen }}
		>
			{(getInputProps) => (
				<>
					<Input label={t("budget")} {...getInputProps("budget")} />
					<Input
						optional
						label={t("estimated-duration")}
						{...getInputProps("estimated_duration")}
					/>
				</>
			)}
		</OfferEditForm>
	);
};

export default OfferBudgetForm;
