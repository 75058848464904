import React, { useEffect } from "react";
import { useCookies } from "react-cookie";
import { useNavigate, useParams } from "react-router-dom";
import Loader from "src/components/Loader";

interface ReferralJoinViewProps {
	children?: any;
}

const ReferralJoinView = ({ children }: ReferralJoinViewProps) => {
	const navigate = useNavigate();
	const params = useParams();
	const [, setCookie] = useCookies();

	useEffect(() => {
		setCookie("referral_slug", params.code);
		navigate("/signup");
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<div className="flex flex-1 justify-center items-center">
			<Loader />
		</div>
	);
};

export default ReferralJoinView;
