import { ComponentProps } from "react";
import Button from "src/components/Button";
import PaginationComp from "src/components/FlatList/Pagination";
import withTranslation, {
	Translation,
} from "src/components/hoc/withTranslation";
import { useList } from "src/components/ui/list/Provider";
import { cn } from "src/lib/utils";

interface PaginationProps extends ComponentProps<"tr"> {
	pagination: {
		page: number;
		last_page: number;
	};
	onNavigate: (page: number) => Promise<unknown>;
	variant?: "pages" | "more";
}
const Pagination = ({
	t,
	children,
	className,
	pagination,
	onNavigate,
	variant = "pages",
	...rest
}: PaginationProps & Translation) => {
	const { isEmpty, columnCount, status } = useList();
	if (isEmpty || !pagination?.last_page) return null;
	return (
		<tfoot>
			<tr {...rest} className={cn("", className)}>
				<td colSpan={columnCount}>
					<div className="flex justify-center items-center pt-4">
						{variant === "pages" && (
							<PaginationComp
								page={{
									current: pagination.page,
									max: pagination.last_page,
								}}
								fetching={status !== "idle"}
								{...{ onNavigate }}
							/>
						)}
						{variant === "more" &&
							pagination.last_page !== pagination.page && (
								<Button
									small
									type="border"
									onClick={() =>
										onNavigate(pagination.page + 1)
									}
								>
									{t("load-more")}
								</Button>
							)}
					</div>
				</td>
			</tr>
		</tfoot>
	);
};

Pagination.locale = {
	nl: {
		"load-more": "Laad meer",
	},
	en: {
		"load-more": "Load more",
	},
};

export default withTranslation(Pagination);
