import Skeleton from "src/components/Skeleton";
import Switch from "src/components/form/Switch";
import withTranslation, {
	Translation,
} from "src/components/hoc/withTranslation";

interface PermissionsProps extends Translation {
	value: string[];
	onChange: (value: string[]) => void;
}

const Permissions = ({ t, value, onChange }: PermissionsProps) => {
	const toggle = (permission: string) => {
		if (value.includes(permission)) {
			onChange(value.filter((item) => item !== permission));
		} else {
			onChange([...value, permission]);
		}
	};

	return (
		<div className="flex flex-col gap-4">
			<div className="flex flex-col gap-2">
				<p className="uppercase text-sm opacity-80 font-regular tracking-wide">
					{t("analytics.title")}
				</p>
				<div className="flex flex-col">
					<Switch
						value={(value || []).includes("analytics.view")}
						onChange={() => toggle("analytics.view")}
						label={t("analytics.view")}
					/>
				</div>
			</div>

			<div className="flex flex-col gap-2">
				<p className="uppercase text-sm opacity-80 font-regular tracking-wide">
					{t("users.title")}
				</p>
				<div className="flex flex-col">
					<Switch
						value={(value || []).includes("permissions.edit")}
						onChange={() => toggle("permissions.edit")}
						label={t("users.permissions")}
						disabled={(value || []).includes(
							"permissions.edit.blocked"
						)}
					/>
				</div>
			</div>
		</div>
	);
};

Permissions.locale = {
	nl: {
		analytics: {
			title: "Analytics",
			view: "Bekijken",
		},
		users: {
			title: "Gebruikers",
			permissions: "Rechten wijzigen",
		},
		tasks: {
			title: "Taken",
			extern: "Uitbesteden",
		},
	},
	en: {
		analytics: {
			title: "Analytics",
			view: "View",
		},
		users: {
			title: "Users",
			permissions: "Change permissions",
		},
	},
};

export const PermissionsLoading = () => (
	<div className="flex flex-col gap-4">
		<div className="flex flex-col gap-2">
			<Skeleton className="h-4 w-16" />
			<div className="flex flex-col">
				<Skeleton className="h-8 w-1/2" />
			</div>
		</div>

		<div className="flex flex-col gap-2">
			<Skeleton className="h-4 w-16" />
			<div className="flex flex-col">
				<Skeleton className="h-8 w-1/2" />
			</div>
		</div>
	</div>
);

export default withTranslation(Permissions);
