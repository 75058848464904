import { useContext, useEffect } from "react";
import { ModalViewContext } from "src/components/layout/ModalView";

type Options = {
	title?: string;
	actions?: React.ReactNode;
};

export default function useModal(options?: Options) {
	const modal = useContext(ModalViewContext);

	useEffect(() => {
		if (options?.title) {
			modal.setTitle(options.title);
		}
		if (options?.actions) {
			modal.setActions(options.actions);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return modal;
}
