import { cn } from "src/lib/utils";

interface RegistrationSectionProps {
	children?: any;
	title?: string;
	className?: string;
}

const RegistrationSection = ({
	title,
	children,
	className,
}: RegistrationSectionProps) => {
	return (
		<div
			className={cn(
				"dark:bg-accent p-3 rounded-md flex flex-col",
				className
			)}
		>
			{title && (
				<strong className="text-[22px] block mb-5">{title}</strong>
			)}
			<div className="flex flex-col flex-1 gap-4">{children}</div>
		</div>
	);
};

export default RegistrationSection;
