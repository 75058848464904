import { Navigate, useParams } from "react-router-dom";
import Card from "src/components/Card";
import withTranslation, {
	Translation,
} from "src/components/hoc/withTranslation";
import StickyWrapper from "src/components/layout/StickyWrapper";
import VacancyDetailLayout from "src/components/vacancies/vacancy-detail-layout";
import CheckVacancyForm, {
	CheckVacancyFormAccordion,
	CheckVacancyFormAddressFields,
	CheckVacancyFormDescriptionFields,
	CheckVacancyFormSalaryField,
	CheckVacancyFormSalaryTypeDropdown,
	CheckVacancyFormSubmitButton,
	CheckVacancyFormTitleField,
} from "src/components/vacancies/vacancy-form/steps/check-vacancy-form";
import VacancyForm from "src/components/vacancies/vacancy-form/vacancy-form";

const EditVacancyView = ({ t }: Translation) => {
	const params = useParams();

	//Check if the ID exists
	if (!params.id) {
		return <Navigate to="/vacancies" />;
	}

	return (
		<VacancyDetailLayout id={params.id.toString()}>
			{(vacancy, actions) => (
				<VacancyForm
					defaultVacancy={vacancy}
					onSave={actions.setVacancy}
				>
					<CheckVacancyForm className="md:flex-row gap-4">
						<Card className="flex-1">
							<CheckVacancyFormTitleField />
							<CheckVacancyFormDescriptionFields />
							<CheckVacancyFormAccordion name="salary">
								<div className="flex flex-col gap-3">
									<CheckVacancyFormSalaryTypeDropdown />
									<CheckVacancyFormSalaryField name="min" />
									<CheckVacancyFormSalaryField name="max" />
								</div>
							</CheckVacancyFormAccordion>
							<CheckVacancyFormAccordion name="address">
								<CheckVacancyFormAddressFields />
							</CheckVacancyFormAccordion>
						</Card>
						<div className="flex flex-col min-w-[340px]">
							<StickyWrapper top={100}>
								<Card>
									<strong>{t("save.title")}</strong>
									<CheckVacancyFormSubmitButton>
										{t("save.button")}
									</CheckVacancyFormSubmitButton>
								</Card>
							</StickyWrapper>
						</div>
					</CheckVacancyForm>
				</VacancyForm>
			)}
		</VacancyDetailLayout>
	);
};

EditVacancyView.locale = {
	nl: {
		save: {
			title: "Vacature bewerken",
			button: "Opslaan",
		},
	},
	en: {
		save: {
			title: "Edit vacancy",
			button: "Save",
		},
	},
};

export default withTranslation(EditVacancyView);
