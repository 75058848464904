import moment from "moment";
import { useState } from "react";
import ApiCall from "src/components/ApiCall";
import Dropdown from "src/components/form/Dropdown";
import withTranslation, {
	Translation,
} from "src/components/hoc/withTranslation";
import Layout from "src/components/layout/Layout";
import Tabs from "src/components/layout/Tabs";
import PaymentList from "src/components/lists/PaymentList";
import Skeleton from "src/components/Skeleton";
import IconTotalCard from "src/components/statistics/IconTotalCard";
import useAuth from "src/hooks/selectors/useAuth";
import { formatPrice } from "src/lib/formatters";
import DashboardCompanyPayments from "src/views/authenticated/payments/company/CompanyPaymentsView";

const CURRENT_YEAR = moment().year();
//Get the options from 2020 to current year
const OPTIONS = Array.from({ length: CURRENT_YEAR - 2020 + 1 }, (_, i) => ({
	name: (CURRENT_YEAR - i).toString(),
	id: (CURRENT_YEAR - i).toString(),
})) as any;

const PaymentsView = ({ t }: Translation) => {
	const auth = useAuth();
	const [year, setYear] = useState<string | null>(moment().year().toString());

	if (auth.type === "company") {
		return <DashboardCompanyPayments />;
	}

	return (
		<Layout title={t("title")} container="xxl">
			<div className="flex flex-col gap-6">
				<div className="flex items-center gap-3">
					<p>{t("title-refresh")}</p>
					<Dropdown
						options={OPTIONS}
						isClearable={false}
						onChange={(year) => setYear(year?.toString() || null)}
						value={year ? year.toString() : undefined}
					/>
				</div>

				{/* <strong>{t("title-refresh", { year: moment().year() })}</strong> */}
				<ApiCall query={`/student/payments/statistics?year=${year}`}>
					{(data, loading) => {
						return (
							<div className="flex flex-wrap items-center gap-4">
								<IconTotalCard
									label={t("status.reserved")}
									icon="far fa-lock"
								>
									{loading ? (
										<Skeleton className="h-8 w-32" />
									) : (
										formatPrice(data?.reserved)
									)}
								</IconTotalCard>
								<IconTotalCard
									label={t("status.paid")}
									icon="far fa-check"
								>
									{loading ? (
										<Skeleton className="h-8 w-32" />
									) : (
										formatPrice(data?.paid)
									)}
								</IconTotalCard>
							</div>
						);
					}}
				</ApiCall>
			</div>
			<Tabs tabs={[t("tabs.all"), t("tabs.reserved"), t("tabs.open")]}>
				{(tab) => {
					if (tab === t("tabs.reserved")) {
						return (
							<PaymentList
								query={`response_type=list&status=reserved&with[]=company&year=${year}`}
							/>
						);
					}

					if (tab === t("tabs.open")) {
						return (
							<PaymentList
								query={`response_type=list&state=open&with[]=company&year=${year}`}
							/>
						);
					}

					return (
						<PaymentList
							query={`response_type=list&with[]=company&year=${year}`}
						/>
					);
				}}
			</Tabs>
		</Layout>
	);
};

PaymentsView.locale = {
	nl: {
		title: "Mijn betalingen",
		"title-refresh": "Verdiensten van",
		status: {
			reserved: "Gereserveerd",
			coming: "Onderweg",
			paid: "Uitbetaald",
		},
		cards: {
			open: "Openstaande betalingen",
			"open-worth": "Openstaande waarde",
			"open-invoices": "Open facturen",
		},
		tabs: {
			all: "Alles",
			open: "Open",
			credit: "Credit",
			reserved: "Gereserveerd",
		},
	},
	en: {
		title: "My payments",
		"title-refresh": "Earnings of {{year}}",
		status: {
			reserved: "Reserved",
			coming: "On the way",
			paid: "Paid out",
		},
		cards: {
			open: "Outstanding payments",
			"open-worth": "Outstanding value",
			"open-invoices": "Open invoices",
		},
		tabs: {
			all: "All",
			open: "Open",
			credit: "Credit",
			reserved: "Reserved",
		},
	},
};

export default withTranslation(PaymentsView);
