import { ApiReport } from "src/api/types";
import useApi from "src/hooks/api/utils/useApi";

type Options = {
	filter?: any;
};

function useReport(options?: Options) {
	const { state, actions, api } = useApi(
		{ id: "REPORT" },
		{
			baseUrl: `/report`,
			query: {
				state: "all",
				...(options?.filter || {}),
			},
		}
	);

	const report: ApiReport[] = state?.list || [];

	return {
		report,
		idea: state?.data,
		status: state.status,
		actions: {
			...actions,
		},
		api,
	};
}

export default useReport;
