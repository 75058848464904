import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import Button from "src/components/Button";
import Card from "src/components/Card";
import OppPaymentDetailCard from "src/components/cards/OppPaymentDetailCard";
import PaymentDetailCard from "src/components/cards/PaymentDetailCard";
import Grid from "src/components/Grid";
import Layout from "src/components/layout/Layout";
import Tabs from "src/components/layout/Tabs";
import PaymentUsersList from "src/components/lists/PaymentUsersList";
import useGet from "src/hooks/rest/useGet";

export default function CompanyPaymentDetailView() {
	const { t } = useTranslation("payment-view", { keyPrefix: "detail-view" });
	const { id } = useParams() as any;
	const [{ data }, loading] = useGet(`/company/payments/${id}`);

	const translatedTabs = {
		information: t("information"),
		invoice: t("invoice"),
		students: t("students"),
	};

	const tabs = [translatedTabs.information];

	if (data?.invoice) {
		tabs.push(translatedTabs.invoice);
	}

	if (data?.reference === "PAYOUT_REGISTRATION") {
		tabs.push(translatedTabs.students);
	}

	return (
		<Layout
			backUrl="/payments"
			title={t("title", {
				invoice_number: data?.invoice_number,
			})}
			container="xxl"
			{...{ loading }}
		>
			<Tabs {...{ tabs }}>
				{(tab) => {
					if (!data) {
						return <></>;
					}
					if (tab === translatedTabs.invoice) {
						return (
							<embed
								title={`factuur-${data.invoice_number}.pdf`}
								src={data.invoice.url}
								type="application/pdf"
								width="100%"
								height="100%"
							></embed>
						);
					}

					if (tab === translatedTabs.students) {
						return <PaymentUsersList payment={data} />;
					}

					return (
						<Grid columns={2}>
							<PaymentDetailCard payment={data} />
							{data?.type === "invoice" && (
								<OppPaymentDetailCard payment={data} />
							)}

							{["cancelled", "chargeback"].includes(
								data?.status
							) && (
								<Card title={t("payment-failed.title")}>
									<p>{t("payment-failed.description")}</p>
									<div className="flex mt-4">
										<Button
											type="dark"
											to={`/payments/${data?.hid}/refresh`}
										>
											{t("payment-failed.button")}
										</Button>
									</div>
								</Card>
							)}
						</Grid>
					);
				}}
			</Tabs>
		</Layout>
	);
}
