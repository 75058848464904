import moment from "moment";
import { createContext, useContext, useEffect, useState } from "react";
import {
	CalendarContextType,
	CalendarProps,
} from "src/components/Calendar/types";
import Head from "src/components/Calendar/utils/Head";
import WeekLayoutSwitcher from "src/components/Calendar/utils/WeekLayoutSwitcher";
import WeekTime from "src/components/Calendar/week-time/WeekTime";
import DateRange from "src/components/Calendar/week/DateRange";
import Month from "src/components/Calendar/week/Month";
import Week from "src/components/Calendar/week/Week";
import useRememberState from "src/hooks/useRememberState";
import { cn } from "src/lib/utils";

export const CalendarContext = createContext<CalendarContextType>({
	selected: {
		value: moment() as any,
		onChange: () => {},
	},
	controls: {
		interval: "day",
	},
	options: {},
	layout: "work-week",
	items: [],
});

export function useCalendar() {
	const calendar = useContext(CalendarContext);
	return {
		...calendar,
		isDraggable: calendar?.actions?.onItemChange ? true : false,
	};
}

const Calendar = ({
	loading,
	children,
	selected: _selected,
	className,
	head,
	controls = {
		interval: "day",
	},
	items = [],
	actions,
	options,
}: CalendarProps) => {
	const [layout, setLayout] = useRememberState(
		"calendar-week-layout",
		"work-week"
	);
	const [selected, setSelected] = useState(_selected || moment());

	useEffect(() => {
		if (
			_selected &&
			_selected?.format("YYYY-MM-DD") !== selected.format("YYYY-MM-DD")
		) {
			setSelected(_selected);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [_selected]);

	useEffect(() => {
		if (actions?.onSelectChange) {
			actions.onSelectChange(selected);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selected]);

	return (
		<div className={cn("flex flex-col", className)}>
			<CalendarContext.Provider
				value={{
					layout,
					loading,
					selected: {
						value: selected,
						onChange: (value) => {
							setSelected(moment(value));
						},
					},
					controls,
					items,
					actions: {
						...(actions || {}),
						setLayout,
					},
					options,
				}}
			>
				{head !== null && <Head {...head} />}
				{children}
			</CalendarContext.Provider>
		</div>
	);
};

Calendar.Week = Week;
Calendar.WeekTime = WeekTime;
Calendar.Month = Month;
Calendar.WeekLayoutSwitcher = WeekLayoutSwitcher;
Calendar.DateRange = DateRange;

export default Calendar;
