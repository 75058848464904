const reviews = {
	"index-view": {
		title: "Reviews",
		tabs: {
			received: "Ontvangen",
			created: "Geplaatst",
		},
		empty: {
			created: "Je hebt nog geen reviews geplaatst",
			received: "Er zijn nog geen reviews geplaatst over jou",
		},
	},
};

export default reviews;
