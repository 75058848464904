import React from "react";
import { useTranslation } from "react-i18next";
import Button from "src/components/Button";
import usePost from "src/hooks/rest/usePost";
import useRefetchProfile from "src/hooks/rest/useRefetchProfile";
import useAlert from "src/hooks/useAlert";

interface CreateReferralCodeProps {
	onComplete: () => void;
}

const CreateReferralCode = ({ onComplete }: CreateReferralCodeProps) => {
	const { t } = useTranslation("share-network", {
		keyPrefix: "create-referral-code",
	});
	const [createReferralCode, loading] = usePost("/student/referral-link");
	const { fetching, refetchProfile } = useRefetchProfile();
	const [sendAlert] = useAlert();
	const handleSubmit = () => {
		createReferralCode({}).then(async () => {
			await refetchProfile();
			sendAlert({
				title: t("alert.title"),
				text: t("alert.text"),
			});
			onComplete();
		});
	};

	return (
		<div className="flex items-center flex-col justify-center mt-8">
			<Button onClick={handleSubmit} loading={loading || fetching}>
				{t("button")}
			</Button>
		</div>
	);
};

export default CreateReferralCode;
