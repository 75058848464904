import * as React from "react";
import {
	Command,
	CommandGroup,
	CommandInput,
	CommandItem,
} from "src/components/ui/command";
import {
	Popover,
	PopoverContent,
	PopoverTrigger,
} from "src/components/ui/popover";
import { cn } from "src/lib/utils";
import countries from "./countries";
import {
	Country as CountryType,
	PhoneNumber,
	applyMask,
	getCountryByIso,
	getMaskDigit,
	replaceDialCode,
	splitPhoneNumber,
} from "./utils";

const DEFAULT_PHONE_NUMBER = {
	raw: "",
	formatted: "",
	country: countries.find((country) => country[2] === "nl") || countries[0],
};

const PhoneContext = React.createContext<
	[PhoneNumber, (pN: PhoneNumber) => void]
>([
	DEFAULT_PHONE_NUMBER,
	// eslint-disable-next-line @typescript-eslint/no-empty-function
	() => {},
]);

const usePhoneContext = () => React.useContext(PhoneContext);

const DISPLAY_NAME = "Phone";

export interface PhoneProps
	extends Omit<
		React.ComponentPropsWithRef<"input">,
		"value" | "defaultValue"
	> {
	value?: string;
	defaultValue?: string;
	defaultCountry?: CountryType[2];
}

export const _Phone = React.forwardRef<HTMLInputElement, PhoneProps>(
	(
		{ className, style, children, defaultCountry = "nl", value, ...props },
		ref
	) => {
		const _ref = React.useRef<HTMLInputElement | null>(null);
		const _defaultValue = props.defaultValue || value;
		const defaultPhoneNumber =
			(_defaultValue
				? splitPhoneNumber(_defaultValue)
				: defaultCountry && {
						raw: "",
						formatted: "",
						country: getCountryByIso(defaultCountry),
				  }) || DEFAULT_PHONE_NUMBER;

		const [_value, setValue] =
			React.useState<PhoneNumber>(defaultPhoneNumber);

		const handleChange = (phoneNumber: PhoneNumber) => {
			setValue(phoneNumber);

			if (_ref.current != null) {
				// @ts-expect-error Gets the own property descriptor of the specified object.
				Object.getOwnPropertyDescriptor(
					window.HTMLInputElement.prototype,
					"value"
				).set.call(_ref.current, phoneNumber.raw);

				_ref.current.dispatchEvent(
					new Event("input", { bubbles: true })
				);
			}
		};

		return (
			<PhoneContext.Provider value={[_value, handleChange]}>
				<span className={className} style={style}>
					<input
						aria-hidden="true"
						type="tel"
						style={{ display: "none" }}
						{...props}
						ref={(r) => {
							if (typeof ref === "function") ref(r);
							_ref.current = r;
						}}
						defaultValue={defaultPhoneNumber.raw}
					/>
					{children as any}
				</span>
			</PhoneContext.Provider>
		);
	}
);

_Phone.displayName = DISPLAY_NAME;

interface CountryProps extends React.ComponentProps<"select"> {
	search: {
		placeholder: string;
	};
}

const Country = (props: CountryProps) => {
	const [open, setOpen] = React.useState(false);
	const [_value, setValue] = usePhoneContext();
	const [search, setSearch] = React.useState("");
	return (
		<Popover {...{ open }} onOpenChange={setOpen}>
			<PopoverTrigger asChild>
				<button
					className={cn(
						"py-2 px-3 w-max transition-all flex items-center gap-2 text-sm text-placeholder",
						!_value ? "text-placeholder" : "text-accent-foreground",
						props?.className
					)}
				>
					<span>
						{_value.country
							? `${_value.country[2].toUpperCase()} (+${
									_value.country[3]
							  })`
							: ""}
					</span>
					<i className={`fas fa-caret-${open ? "up" : "down"}`}></i>
				</button>
			</PopoverTrigger>
			<PopoverContent className="w-[200px] max-h-[200px] p-0">
				<Command shouldFilter={false}>
					<CommandInput
						value={search}
						onValueChange={setSearch}
						placeholder={props.search.placeholder}
					/>
					<CommandGroup>
						{countries
							.filter(
								(country) =>
									country[0]
										?.toLowerCase()
										.includes(search.toLowerCase()) ||
									country[2]
										?.toLowerCase()
										.includes(search.toLowerCase()) ||
									country[3]
										?.toLowerCase()
										.includes(search.toLowerCase())
							)
							.map((country) => (
								<CommandItem
									value={country[2]}
									key={country[2]}
									onSelect={(currentValue) => {
										props.onChange &&
											props.onChange({
												target: {
													value: currentValue,
													name: props?.name || "",
												},
											} as any);
										const country = getCountryByIso(
											currentValue as any
										);

										const raw = _value.raw
											? replaceDialCode(
													_value.raw,
													_value.country[3],
													"+" + country[3]
											  )
											: "+" + country[3];

										setValue({
											formatted: applyMask(
												_value.formatted,
												country[4]
											),
											raw,
											country,
										});
										setOpen(false);
									}}
								>
									{/* {framework.label} */}
									{country[0]}&nbsp;(+{country[3]})
								</CommandItem>
							))}
					</CommandGroup>
				</Command>
			</PopoverContent>
		</Popover>
	);

	// return (
	// 	<select
	// 		ref={ref}
	// 		{...props}
	// 		value={_value.country[2]}
	// 		className={cn("pl-3 text-sm w-max", props.className)}
	// 		onChange={(e) => {
	// 			props.onChange && props.onChange(e);
	// 			const country = getCountryByIso(
	// 				e.target.value as CountryType[2]
	// 			);

	// 			const raw = _value.raw
	// 				? replaceDialCode(
	// 						_value.raw,
	// 						_value.country[3],
	// 						"+" + country[3]
	// 				  )
	// 				: "+" + country[3];

	// 			setValue({
	// 				formatted: applyMask(_value.formatted, country[4]),
	// 				raw,
	// 				country,
	// 			});
	// 		}}
	// 	>
	// 		{countries.map((country) => (
	// 			<option value={country[2]} key={country[2]}>
	// 				{country[2]}&nbsp;(+{country[3]})
	// 			</option>
	// 		))}
	// 	</select>
	// );
};

Country.displayName = DISPLAY_NAME + ".Country";

const _Number = React.forwardRef<
	HTMLInputElement,
	React.ComponentPropsWithRef<"input">
>((props, ref) => {
	const [_value, setValue] = usePhoneContext();

	return (
		<input
			ref={ref}
			{...props}
			placeholder={
				/\d+/.test(props.placeholder || "")
					? applyMask(props.placeholder, _value.country[4])
					: props.placeholder
			}
			type="tel"
			value={_value.formatted}
			onChange={(e) => {
				props.onChange?.(e);
				setValue(
					Object.assign({}, _value, {
						raw:
							"+" +
							_value.country[3] +
							getMaskDigit(e.target.value, _value.country[4]),
						formatted: applyMask(e.target.value, _value.country[4]),
					})
				);
			}}
		/>
	);
});

_Number.displayName = DISPLAY_NAME + ".Number";

export const Phone = Object.assign(_Phone, { Country, Number: _Number });
export {
	applyMask,
	countries,
	getCountryByIso,
	replaceDialCode,
	splitPhoneNumber,
};
