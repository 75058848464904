import { motion } from "framer-motion";
import { useAtom } from "jotai";
import { atomWithStorage } from "jotai/utils";
import moment from "moment";
import { useParams } from "react-router-dom";
import Button from "src/components/Button";
import Field from "src/components/field/Field";
import withTranslation, {
	Translation,
} from "src/components/hoc/withTranslation";
import { getDeadlineColor } from "src/components/tasks/TaskDeadlineText";
import useProjects from "src/hooks/api/services/projects/useProjects";
import useTask from "src/hooks/api/services/tasks/task/useTask";
import useBoards from "src/hooks/api/services/tasks/useBoards";
import useTasksFilter from "src/hooks/api/tasks/useTasksFilter";
import useAuth from "src/hooks/selectors/useAuth";
import useWindowSize from "src/hooks/useWindowSize";
import { cn } from "src/lib/utils";

type LayoutMode = "full" | "compact";
const layoutModeAtom = atomWithStorage<LayoutMode>("task-modal-fields", "full");

interface TaskModalContentProps extends Translation {
	isForcedBoard?: boolean;
	onCreate?: () => Promise<any>;
}

const TaskModalContent = ({
	t,
	isForcedBoard,
	onCreate,
}: TaskModalContentProps) => {
	const auth = useAuth();
	const [layoutMode, setLayoutMode] = useAtom(layoutModeAtom);
	const { isPhone } = useWindowSize();
	const { project } = useParams();
	const { project: projectData } = useProjects();
	const { filter } = useTasksFilter();
	const { id } = useParams();
	const { task, update, actions, status } = useTask({ id });
	const { boards } = useBoards();
	const board = filter.board
		? (boards || []).find((item) => item.slug === filter.board)
		: null;
	const wrapperLayout = "horizontal-always";
	const selectTheme = {
		trigger: {
			className: "min-w-[220px] justify-between",
		},
	};

	if (isForcedBoard) {
		return (
			<form
				onSubmit={(e) => {
					e.preventDefault();
					if (onCreate) onCreate();
				}}
				className="flex flex-col"
			>
				{!project && (
					<div className="flex flex-col gap-1.5 max-w-full md:max-w-[340px] relative">
						<Field.Board
							wrapper={{
								label: t("fields.board.label"),
								layout: wrapperLayout,
							}}
							recent={{
								name: "TASK_BOARDS",
							}}
							value={task.board}
							onChange={(value) => {
								actions.change({
									board: value,
								});
							}}
						/>
						<p className="italic text-placeholder mt-2 text-sm">
							{t("fields.board.description")}
						</p>
					</div>
				)}
				{onCreate && (
					<Button
						disabled={
							!(
								(update?.title && update?.board) ||
								(update?.title && project)
							) || status !== "idle"
						}
						loading={status === "creating"}
						onClick={onCreate}
						className="mt-4"
					>
						{t("buttons.create")}
					</Button>
				)}
			</form>
		);
	}

	return (
		<div className="flex flex-col gap-1.5 pb-12">
			<motion.div
				layout="position"
				className="flex gap-1 5 flex-col group/section relative"
			>
				<Field.Status
					wrapper={{ label: "Status", layout: wrapperLayout }}
					value={task.status}
					onChange={(value) => {
						actions.change({
							status: value,
						});
					}}
					theme={selectTheme}
				/>

				{layoutMode === "full" && (
					<div
						className={cn(
							"flex flex-col transition-all overflow-hidden"
						)}
					>
						{task.type !== "TEMPLATE" && (
							<div className="flex items-center group/date">
								<Field.Date
									wrapper={{
										label: t("fields.date.label"),
										layout: wrapperLayout,
									}}
									value={
										task.end_date
											? moment(task.end_date).format(
													"YYYY-MM-DD"
											  )
											: undefined
									}
									onChange={(value) => {
										actions.change({
											end_date: value,
										});
									}}
									calendar={{
										clearable: true,
									}}
									theme={{
										placeholder: {
											className: task.end_date
												? getDeadlineColor(
														task.end_date
												  )
												: undefined,
										},
									}}
								/>
								{!isPhone && (
									<>
										{!task.end_date ? (
											<div className="flex items-center gap-1">
												<button
													onClick={() =>
														actions.change({
															end_date:
																moment().format(
																	"YYYY-MM-DD"
																),
														})
													}
													className="border border-border font-regular group-hover/date:opacity-100 opacity-0 px-2.5 py-1 rounded-md cursor-pointer transition-all hover:bg-border text-sm"
												>
													{t("fields.date.today")}
												</button>
												<button
													onClick={() =>
														actions.change({
															end_date: moment()
																.add(1, "day")
																.format(
																	"YYYY-MM-DD"
																),
														})
													}
													className="border border-border font-regular group-hover/date:opacity-100 opacity-0 px-2.5 py-1 rounded-md cursor-pointer transition-all hover:bg-border text-sm"
												>
													{t("fields.date.tomorrow")}
												</button>
											</div>
										) : (
											<button
												onClick={() =>
													actions.change({
														end_date: null,
													})
												}
												className="border border-border font-regular group-hover/date:opacity-100 opacity-0 px-2.5 py-1 rounded-md cursor-pointer transition-all hover:bg-border text-sm"
											>
												{t("fields.date.clear")}
											</button>
										)}
									</>
								)}
							</div>
						)}

						<Field.Tag
							canCreate
							wrapper={{
								label: "Tags",
								layout: wrapperLayout,
							}}
							value={(task?.tags || []).map(
								(tag: any) => tag?.tag || tag
							)}
							onChange={(tags) => {
								actions.change({
									tags,
								});
							}}
							theme={selectTheme}
						/>
						<Field.Users
							trigger={selectTheme.trigger}
							wrapper={{
								label: t("fields.assignees.label"),
								layout: wrapperLayout,
							}}
							filter={(user) => {
								if (
									projectData?.id &&
									projectData.members.length
								) {
									return (
										(projectData?.members || []).includes(
											Number(user.id)
										) || user.id === "me"
									);
								}
								if (board?.slug && board?.users?.length) {
									return (
										[
											...(board?.users || []),
											board?.creator?.id,
										].includes(Number(user.id)) ||
										user.id === "me"
									);
								}

								return true;
							}}
							value={
								task?.assignees
									? (task?.assignees || []).map((user: any) =>
											typeof user === "object"
												? user.id.toString()
												: user
									  )
									: []
							}
							onChange={(value) => {
								if (
									value?.includes("me") &&
									(task?.assignees || []).find(
										(u: any) => u.id === auth.id
									)
								) {
									value = value.filter(
										(item: any) =>
											item !== "me" &&
											item !== auth?.id &&
											item !== auth.id.toString()
									);
								}
								actions.change({
									assignees: value,
								});
							}}
						/>

						{!project && !projectData.id && (
							<Field.Project
								wrapper={{
									label: t("fields.project.label"),
									layout: wrapperLayout,
								}}
								value={
									task?.links?.project ||
									projectData.hid ||
									undefined
								}
								onChange={(project) => {
									actions.change({
										project: project || null,
										links: {
											...task?.links,
											project: project || null,
										},
									});
								}}
								theme={selectTheme}
							/>
						)}
						<Field.Time
							wrapper={{
								label: t("fields.estimated.label"),
								layout: wrapperLayout,
							}}
							value={task?.estimated_duration}
							onChange={(estimated_duration) => {
								actions.change({
									estimated_duration:
										estimated_duration || null,
								});
							}}
						/>
						{!task?.links?.board && !task?.links?.project && (
							<Field.Board
								wrapper={{
									label: t("fields.board.label"),
									layout: wrapperLayout,
								}}
								recent={{
									name: "TASK_BOARDS",
								}}
								value={task.board}
								onChange={(value) => {
									actions.change({
										board: value,
									});
								}}
							/>
						)}
						<Field.File
							wrapper={{
								label: t("fields.files.label"),
								layout: wrapperLayout,
							}}
							value={task?.media || []}
							onChange={(media) => {
								actions.change({
									media,
								});
							}}
						/>
					</div>
				)}

				<button
					onClick={(e) => {
						e.preventDefault();
						setLayoutMode(
							layoutMode === "full" ? "compact" : "full"
						);
					}}
					className="w-max flex items-center gap-1.5 px-2 py-2 rounded-md bg-accent opacity-0 group-hover/section:opacity-80 hover:opacity-100 transition-all absolute -bottom-10"
				>
					<div className="flex justify-center items-center">
						<i
							className={cn(
								"far text-[13px]",
								layoutMode === "compact"
									? "fa-chevron-down"
									: "fa-chevron-up"
							)}
						></i>
					</div>
					<span className="font-regular text-[15px] leading-full">
						{t(
							layoutMode === "full"
								? "layout.compact"
								: "layout.full"
						)}
					</span>
				</button>
			</motion.div>

			<hr className="border-accent my-4 block" />

			<Field.Editor
				variant="floating"
				value={task.description}
				onChange={(value) => {
					actions.change({
						description: value,
					});
				}}
			/>
		</div>
	);
};

TaskModalContent.locale = {
	nl: {
		title: "",
		layout: {
			compact: "Verberg velden",
			full: "Toon velden",
		},
		fields: {
			description: {
				placeholder: "Omschrijf de taak...",
			},
			board: {
				label: "Bord",
				description:
					"Het bord geeft ons AI een context waar de taak over gaat",
			},
			files: {
				label: "Bestanden",
			},
			project: {
				label: "Project",
			},
			assignees: {
				label: "Uitvoerders",
			},
			date: {
				label: "Datum",
				today: "Vandaag",
				tomorrow: "Morgen",
				clear: "Legen",
			},
			estimated: {
				label: "Tijdsduur",
			},
		},
		buttons: {
			create: "Taak aanmaken",
		},
	},
	en: {
		title: "",
		layout: {
			compact: "Hide fields",
			full: "Show fields",
		},
		fields: {
			description: {
				placeholder: "Describe the task...",
			},
			board: {
				label: "Board",
				description: "The board gives our AI context about the task",
			},
			files: {
				label: "Files",
			},
			project: {
				label: "Project",
			},
			assignees: {
				label: "Assignees",
			},
			date: {
				label: "Date",
				today: "Today",
				tomorrow: "Tomorrow",
				clear: "Clear",
			},
			estimated: {
				label: "Duration",
			},
		},
		buttons: {
			create: "Create task",
		},
	},
};

export default withTranslation(TaskModalContent);
