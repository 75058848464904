import Layout from "src/components/layout/Layout";
import JobOfferConverter from "src/components/tools/job-offer-converter/JobOfferConverter";

const JobOfferConverterView = () => {
	return (
		<Layout>
			<JobOfferConverter />
		</Layout>
	);
};

export default JobOfferConverterView;
