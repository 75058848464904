import { useMemo } from "react";
import { ApiNotificationSetting } from "src/api/types";
import useGet from "src/hooks/rest/useGet";

type Settings = {
	[name: string]: ApiNotificationSetting[];
};

interface Options {
	query?: string;
}

function useNotificationSettings(options: Options) {
	const [{ data }] = useGet(
		`/settings/notifications${options?.query ? `?${options.query}` : ""}`
	);
	const settings: Settings = useMemo(() => {
		let groups: Settings = {};
		(data || []).map((item: ApiNotificationSetting) => {
			if (!groups[item.event]) groups[item.event] = [];
			groups[item.event].push(item);
			return data;
		});
		return groups;
	}, [data]);
	return {
		settings,
	};
}

export default useNotificationSettings;
