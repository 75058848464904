import withTranslation, {
	Translation,
} from "src/components/hoc/withTranslation";
import Accordion from "src/components/layout/Accordion";
import Form from "src/components/tasks/TaskForm/Form";
import AddressForm from "src/components/tasks/TaskForm/steps/AddressForm";
import InterviewForm from "src/components/tasks/TaskForm/steps/InterviewForm";
import StudentPoolForm from "src/components/tasks/TaskForm/steps/StudentPoolForm";
import VisibilityForm from "src/components/tasks/TaskForm/steps/VisibilityForm";
import useTaskForm from "src/hooks/api/tasks/useTaskForm";
import useModules from "src/hooks/selectors/useModules";

interface ExtraFormProps extends Translation {
	hideButtons?: boolean;
}

const ExtraForm = ({ t, hideButtons }: ExtraFormProps) => {
	const { actions, current } = useTaskForm();
	const { modules } = useModules();
	return (
		<Form
			value={{}}
			{...{ hideButtons }}
			onSubmit={async () => {
				actions.updateCurrent(current + 1);
			}}
		>
			{() => (
				<>
					{modules.includes("student-pool") && (
						<Accordion
							head={{
								title: t("accordions.invite"),
								variant: "sticky",
							}}
						>
							<StudentPoolForm hideButtons />
						</Accordion>
					)}

					<Accordion
						head={{
							title: t("accordions.visibility"),
							variant: "sticky",
						}}
					>
						<VisibilityForm hideButtons />
					</Accordion>
					<Accordion
						head={{
							title: t("accordions.address"),
							variant: "sticky",
						}}
					>
						<AddressForm hideButtons />
					</Accordion>
					{!modules.includes("ai-description-creation") && (
						<Accordion
							head={{
								title: t("accordions.interview"),
								variant: "sticky",
							}}
						>
							<InterviewForm hideButtons />
						</Accordion>
					)}
					{/* <Accordion
						head={{
							title: t("accordions.tags"),
							variant: "sticky",
						}}
					>
						<TagForm hideButtons />
					</Accordion> */}
				</>
			)}
		</Form>
	);
};

ExtraForm.locale = {
	nl: {
		accordions: {
			invite: "Connecties uitnodigen",
			visibility: "Zichtbaarheid",
			address: "Locatie",
			interview: "Interview",
			tags: "Tags & borden",
		},
	},
	en: {
		accordions: {
			invite: "Invite connections",
			visibility: "Visibility",
			address: "Location",
			interview: "Interview",
			tags: "Tags & boards",
		},
	},
};

export default withTranslation(ExtraForm);
