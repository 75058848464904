import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { ApiFormFilter } from "src/api/types";
import Badge from "src/components/Badge";
import Field from "src/components/field/Field";
import useCustomFormFilters from "src/hooks/api/services/forms/useCustomFormFilters";

interface CustomFormFiltersProps {
	type: "CONNECTION" | "PAYMENT_REQUEST";
	value: any;
	onChange: (value: any) => void;
}

const CustomFormFilters = ({
	type,
	value,
	onChange,
}: CustomFormFiltersProps) => {
	const location = useLocation();
	const { filters, actions } = useCustomFormFilters({ type });

	useEffect(() => {
		actions.list();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [location]);

	const handleChange = (filter: ApiFormFilter, update: any) => {
		let newValue = update;
		if (!!filter.options.length) {
			let items = value?.[filter.id] || [];

			if (!Array.isArray(items)) {
				items = [items];
			}

			if (items.includes(update)) {
				items = items.filter((item: any) => item !== update);
			} else {
				items.push(update);
			}

			newValue = items;

			if (newValue.length === 0) {
				newValue = undefined;
			}
		}

		onChange({
			...value,
			[filter?.id]: newValue,
		});
	};

	return (
		<>
			{filters.map((filter: ApiFormFilter) => (
				<div key={filter.id} className="flex flex-col gap-3">
					<div className="flex flex-col">
						<p className="opacity-70">{filter.label}</p>
					</div>
					{filter.options.length > 0 && (
						<div className="flex flex-wrap gap-2">
							{filter.options.map((option: any) => (
								<Badge
									key={`${filter.id}-option-${option}`}
									size="small"
									className="cursor-pointer line-clamp-1"
									onClick={() => handleChange(filter, option)}
									variant={
										(value?.[filter.id] || []).includes(
											option
										)
											? "dark"
											: "accent"
									}
								>
									{option}
								</Badge>
							))}
						</div>
					)}

					{filter.type === "BOOLEAN" && (
						<Field.Switch
							wrapper={{}}
							onChange={(update) =>
								handleChange(filter, update ? true : undefined)
							}
							value={value?.[filter.id] || false}
						/>
					)}
				</div>
			))}
		</>
	);
};

export default CustomFormFilters;
