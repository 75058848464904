import { ComponentProps, ReactNode } from "react";
import Link, { LinkTo } from "src/components/Link";
import Icon from "src/components/icon/Icon";
import { cn } from "src/lib/utils";

interface EmptyStateProps {
	icon?: string;
	title?: string;
	description?: string;
	to?: LinkTo;
	className?: string;
	variant?: "left" | "center";
	theme?: "default" | "dashed";
	children?: ReactNode;
}

const EmptyState = ({
	icon,
	title,
	description,
	to,
	className,
	variant = "left",
	theme = "default",
	children,
}: EmptyStateProps) => {
	const Wrapper = to ? Link : "div";
	return (
		<Wrapper
			to={to as any}
			className={cn(
				"flex gap-4 p-3 md:py-5 lg:px-4 rounded-md",
				variant === "left" &&
					"items-center md:justify-center md:text-center md:flex-col lg:flex-row lg:text-left lg:justify-start",
				variant === "center" &&
					"flex-col justify-center items-center text-center",
				theme === "default" &&
					"dark:border dark:border-border dark:bg-accent",
				theme === "dashed" &&
					"transition-colors border-2 border-border border-dashed hover:bg-accent",
				className
			)}
		>
			{icon && (
				<Icon
					iconFontSize={22}
					className="bg-accent border-border text-border border-2 w-12 h-12 hover:bg-border"
					icon={{
						font_awesome: {
							name: icon,
						},
					}}
				/>
			)}
			{title && (
				<div className="flex flex-col flex-1 gap-2">
					<strong className="leading-full text-[17px]">
						{title}
					</strong>
					{description && (
						<p className="opacity-60 leading-full text-[15px]">
							{description}
						</p>
					)}
				</div>
			)}
			{children}
		</Wrapper>
	);
};

const EmptyStateOverlay = ({ className, ...rest }: ComponentProps<"div">) => {
	return (
		<div
			{...rest}
			className={cn(
				"z-[77] flex flex-col border border-border rounded-md backdrop-blur-md absolute inset-0 flex-1 justify-center items-center gap-1",
				className
			)}
		/>
	);
};

EmptyState.Overlay = EmptyStateOverlay;

const EmptyStateTitle = ({ className, ...rest }: ComponentProps<"p">) => {
	return <p {...rest} className={cn("leading-full", className)} />;
};

EmptyState.Title = EmptyStateTitle;

const EmptyStateDescription = ({ className, ...rest }: ComponentProps<"p">) => {
	return (
		<p
			{...rest}
			className={cn("opacity-70 max-w-[460px] text-center", className)}
		/>
	);
};

EmptyState.Description = EmptyStateDescription;

const Buttons = ({ className, ...rest }: ComponentProps<"div">) => {
	return (
		<p
			{...rest}
			className={cn("flex items-center gap-3 mt-4", className)}
		/>
	);
};

EmptyState.Buttons = Buttons;

export default EmptyState;
