import {
	ComponentProps,
	createContext,
	useContext,
	useMemo,
	useState,
} from "react";
import { ApiVacancy } from "src/api/types";
import Button, { ButtonProps } from "src/components/Button";
import Field from "src/components/field/Field";
import { EditorPreview } from "src/components/field/fields/Editor/Editor";
import AddressForm from "src/components/forms/AddressForm/v2/AddressForm";
import { useT } from "src/components/hoc/withTranslation";
import AccordionComp from "src/components/ui/accordion";
import {
	useVacancyForm,
	VacancyFormSectionName,
} from "src/components/vacancies/vacancy-form/provider";
import { formatPrice } from "src/lib/formatters";
import { cn } from "src/lib/utils";

interface CheckVacancyFormContextType {
	disabled: boolean;
	onSubmit: () => Promise<void>;
}

const CheckVacancyFormContext = createContext<CheckVacancyFormContextType>({
	disabled: false,
	onSubmit: async () => {},
});

interface CheckVacancyFormProps extends ComponentProps<"div"> {}

const CheckVacancyForm = ({
	children,
	className,
	...rest
}: CheckVacancyFormProps) => {
	const { vacancy, valid, actions, step } = useVacancyForm();
	const disabled = !Object.values(valid).every((value) => value);

	const onSubmit = async () => {
		if (disabled || !vacancy) return;
		actions.setStatus("updating");
		try {
			await actions.update(vacancy);
			await step.set("publish");
		} finally {
			actions.setStatus("idle");
		}
	};

	if (!vacancy) {
		return <MissingVacancy />;
	}

	return (
		<CheckVacancyFormContext.Provider value={{ disabled, onSubmit }}>
			<div className={cn("flex flex-col", className)} {...rest}>
				{children}
			</div>
		</CheckVacancyFormContext.Provider>
	);
};

export default CheckVacancyForm;

const MissingVacancy = () => {
	const t = useT({
		nl: {
			title: "Vacature niet gevonden",
			description:
				"De vacature is niet gevonden in onze database. Probeer het opnieuw met een andere vacature.",
		},
		en: {
			title: "Vacancy not found",
			description:
				"The vacancy was not found in our database. Please try again with a different vacancy.",
		},
	});
	return (
		<div className="flex flex-col">
			<div className="flex flex-col gap-2">
				<strong>{t("title")}</strong>
				<p className="opacity-70">{t("description")}</p>
			</div>
		</div>
	);
};

const TitleField = () => {
	const { vacancy, actions } = useVacancyForm();
	const title = vacancy?.title;
	const t = useT({
		nl: {
			label: "Titel",
			placeholder: "Titel",
		},
		en: {
			label: "Title",
			placeholder: "Title",
		},
	});
	return (
		<Field.Input
			wrapper={{
				label: t("label"),
				layout: "horizontal",
			}}
			className="border-border w-full"
			placeholder={t("placeholder")}
			value={title}
			onChange={(value) => {
				if (vacancy) {
					actions.setVacancy({ ...vacancy, title: value || "" });
				}
			}}
		/>
	);
};

interface DescriptionsProps extends Omit<ComponentProps<"div">, "children"> {}

const DescriptionFields = ({ className, ...rest }: DescriptionsProps) => {
	return (
		<div className={cn("flex flex-col gap-4", className)} {...rest}>
			{["company", "offer", "role", "profile"].map((name) => (
				<DescriptionField {...{ name }} />
			))}
		</div>
	);
};

const DescriptionField = ({ name }: { name: string }) => {
	const { vacancy, actions } = useVacancyForm();
	const value =
		vacancy?.descriptions[name as keyof typeof vacancy.descriptions] || "";
	const [mode, setMode] = useState<"preview" | "edit">(
		!!value ? "preview" : "edit"
	);
	const t = useT({
		nl: {
			edit: "Bewerk",
			preview: "Bekijk",
			company: {
				label: "Over het bedrijf",
				placeholder: "Meer informatie over het bedrijf",
			},
			offer: {
				label: "Het aanbod",
				placeholder: "Meer informatie over het aanbod",
			},
			role: {
				label: "De rol",
				placeholder: "Meer informatie over de rol",
			},
			profile: {
				label: "Gezocht profiel",
				placeholder:
					"Meer informatie over het profiel dat gezocht wordt",
			},
		},
		en: {
			edit: "Edit",
			preview: "Preview",
			company: {
				label: "About the company",
				placeholder: "More information about the company",
			},
			offer: {
				label: "The offer",
				placeholder: "More information about the offer",
			},
			role: {
				label: "The role",
				placeholder: "More information about the role",
			},
			profile: {
				label: "Sought profile",
				placeholder: "More information about the sought profile",
			},
		},
	});
	return (
		<AccordionComp defaultOpen={!value && name === "company"}>
			<AccordionComp.Head>
				<div className="flex items-center gap-2">
					<AccordionComp.ValidOrPendingIcon valid={!!value} />
					<AccordionComp.Title>
						{t(`${name}.label`)}
					</AccordionComp.Title>
				</div>
				<AccordionComp.Icon />
			</AccordionComp.Head>
			<AccordionComp.Content>
				<div className="flex flex-col gap-2">
					{mode === "preview" ? (
						<div className="flex flex-col">
							<EditorPreview value={value} />
						</div>
					) : (
						<Field.Editor
							placeholder={t(`${name}.placeholder`)}
							variant="bordered"
							onChange={(value) => {
								if (vacancy) {
									actions.setVacancy({
										...vacancy,
										descriptions: {
											...vacancy.descriptions,
											[name]: value || "",
										},
									});
								}
							}}
							value={value}
						/>
					)}
					<div className="flex gap-2">
						{mode === "preview" && (
							<Button
								type="border"
								onClick={() => setMode("edit")}
								xsmall
							>
								{t("edit")}
							</Button>
						)}
						{mode === "edit" && (
							<Button
								type="border"
								onClick={() => setMode("preview")}
								xsmall
							>
								{t("preview")}
							</Button>
						)}
					</div>
				</div>
			</AccordionComp.Content>
		</AccordionComp>
	);
};

interface SalaryFieldProps extends Omit<ComponentProps<"div">, "children"> {
	name: keyof ApiVacancy["salary"];
}

const SalaryField = ({ className, name, ...rest }: SalaryFieldProps) => {
	const { vacancy, actions } = useVacancyForm();
	const salary = vacancy?.salary;
	const value = salary?.[name];
	const t = useT({
		nl: {
			min: {
				label: "Minimaal",
				placeholder: "Salaris vanaf",
			},
			max: {
				label: "Maximaal",
				placeholder: "Salaris tot",
			},
		},
		en: {
			min: {
				label: "Minimum",
				placeholder: "Salary from",
			},
			max: {
				label: "Maximum",
				placeholder: "Salary to",
			},
		},
	});

	if (value === null) return null;

	return (
		<Field.Input
			wrapper={{
				label: t(`${name}.label`),
				className: "border-border",
				layout: "horizontal",
			}}
			placeholder={t(`${name}.placeholder`)}
			className="border-border"
			type="number"
			value={value !== null ? value : ""}
			{...(name === "max" ? { min: salary?.min || 0 } : { min: 0 })}
			onChange={(value) => {
				if (vacancy) {
					actions.setVacancy({
						...vacancy,
						salary: {
							...(salary || { min: 0, max: 0 }),
							[name]: Number(value) || 0,
						},
					});
				}
			}}
		/>
	);
};

const SalaryTypeDropdown = () => {
	const { vacancy, actions } = useVacancyForm();
	const salary = vacancy?.salary;
	const value = useMemo(() => {
		if (salary?.min !== null && salary?.max !== null) {
			return "min_max";
		}
		return "hide";
	}, [salary]);
	const t = useT({
		nl: {
			label: "Salaris type",
			placeholder: "Salaris type",
			options: {
				hide: "Geen salaris tonen",
				min_max: "Salaris range",
			},
		},
		en: {
			label: "Salary type",
			placeholder: "Salary type",
			options: {
				hide: "Hide salary",
				min_max: "Salary range",
			},
		},
	});

	return (
		<Field.Select
			wrapper={{
				label: t("label"),
				className: "border-border",
				layout: "horizontal",
			}}
			hideSearch
			placeholder={t("placeholder")}
			className="border-border"
			options={[
				{ label: t("options.hide"), value: "hide" },
				{ label: t("options.min_max"), value: "min_max" },
			]}
			{...{ value }}
			onChange={(value) => {
				console.log(value);
				if (vacancy) {
					if (value === "hide") {
						actions.setVacancy({
							...vacancy,
							salary: { min: null, max: null },
						});
					}

					if (value === "min_max") {
						actions.setVacancy({
							...vacancy,
							salary: {
								min: 1200,
								max: 2000,
							},
						});
					}
				}
			}}
		/>
	);
};

const AddressFields = () => {
	const { vacancy, actions } = useVacancyForm();
	const address = vacancy?.address;
	return (
		<AddressForm
			className="w-full"
			defaultMode={address?.city ? "manual" : "search"}
			address={{
				street: address?.street || "",
				house_number: address?.house_number || "",
				zipcode: address?.zipcode || "",
				city: address?.city || "",
			}}
			onChange={(address) => {
				if (vacancy) {
					actions.setVacancy({
						...vacancy,
						address: {
							street: address?.street || "",
							house_number: address?.house_number || "",
							zipcode: address?.zipcode || "",
							city: address?.city || "",
						},
					});
				}
			}}
		/>
	);
};

const SubmitButton = ({ children, ...props }: ButtonProps) => {
	const { disabled, onSubmit } = useContext(CheckVacancyFormContext);
	const t = useT({
		nl: {
			submit: "Doorgaan",
		},
		en: {
			submit: "Continue",
		},
	});

	return (
		<Button {...{ ...props, disabled }} onClick={onSubmit}>
			{children || t("submit")}
		</Button>
	);
};

interface ValidIconProps {
	name: VacancyFormSectionName;
}

const ValidIcon = ({ name }: ValidIconProps) => {
	const { valid } = useVacancyForm();
	return <AccordionComp.ValidOrPendingIcon valid={valid[name]} />;
};

const Accordion = ({
	children,
	name,
}: ComponentProps<"div"> & { name: VacancyFormSectionName }) => {
	const t = useT({
		nl: {
			titles: {
				description: "Omschrijvingen",
				salary: "Salaris",
				address: "Locatie",
			},
		},
		en: {
			titles: {
				description: "Descriptions",
				salary: "Salary",
				address: "Address",
			},
		},
	});
	const { valid, vacancy } = useVacancyForm();

	return (
		<AccordionComp defaultOpen={!valid[name]}>
			<AccordionComp.Head>
				<div className="flex items-center gap-2">
					<ValidIcon {...{ name }} />
					<AccordionComp.Title>
						{t(`titles.${name}`)}
					</AccordionComp.Title>
					{name === "salary" &&
						vacancy?.salary?.min !== null &&
						vacancy?.salary?.max !== null && (
							<p className="opacity-70 ml-2 select-none">
								({formatPrice(vacancy?.salary?.min || 0)} -{" "}
								{formatPrice(vacancy?.salary?.max || 0)})
							</p>
						)}

					{name === "address" && (
						<p className="opacity-70 ml-2">
							(
							<span>
								{vacancy?.address?.street
									? `${vacancy?.address?.street}, `
									: ""}
							</span>
							<span>{vacancy?.address?.city}</span>)
						</p>
					)}
				</div>
				<AccordionComp.Icon />
			</AccordionComp.Head>
			<AccordionComp.Content>{children}</AccordionComp.Content>
		</AccordionComp>
	);
};

export {
	Accordion as CheckVacancyFormAccordion,
	AddressFields as CheckVacancyFormAddressFields,
	DescriptionFields as CheckVacancyFormDescriptionFields,
	SalaryField as CheckVacancyFormSalaryField,
	SalaryTypeDropdown as CheckVacancyFormSalaryTypeDropdown,
	SubmitButton as CheckVacancyFormSubmitButton,
	TitleField as CheckVacancyFormTitleField,
	ValidIcon as CheckVacancyFormValidIcon,
};
