import { useState } from "react";
import useWindowSize from "src/hooks/useWindowSize";
import { cn } from "src/lib/utils";

interface PaginationProps {
	className?: string;
	page: {
		current: number;
		max: number;
	};
	fetching: boolean;
	onNavigate: (page: number) => Promise<unknown>;
}

const Pagination = ({ className, page, onNavigate }: PaginationProps) => {
	const [pageLoading, setPageLoading] = useState(undefined) as any;
	const { isPhone } = useWindowSize();
	const pages = [] as number[];
	for (let index = page.current + 1; index < page.max; index++) {
		if (pages.length < 2) {
			pages.push(index);
		}
	}

	const handleNavigate = async (newPage: number) => {
		setPageLoading(newPage);
		await onNavigate(newPage);
		setPageLoading(undefined);
	};

	if (page.current === 1 && page.max === 1) {
		return <></>;
	}

	return (
		<div
			className={`flex items-center px-2 justify-center gap-2 text-[14px] ${className}`}
		>
			<PaginationItem
				disabled={page.current === 1 || pageLoading !== undefined}
				onClick={() =>
					handleNavigate(page.current !== 1 ? page.current - 1 : 1)
				}
			>
				<i className="far fa-angle-left"></i>
			</PaginationItem>
			{page.current > 1 && (
				<PaginationItem
					onClick={() => handleNavigate(page.current - 1)}
					page={page.current - 1}
					isLoading={pageLoading === page.current - 1}
				></PaginationItem>
			)}
			<PaginationItem
				onClick={() => handleNavigate(page.current)}
				active={true}
				page={page.current}
				isLoading={pageLoading === page.current}
			></PaginationItem>
			{pages.map((item) => (
				<PaginationItem
					key={`page-${item}`}
					onClick={() => handleNavigate(item)}
					active={page.current === item}
					page={item}
					isLoading={pageLoading === item}
				></PaginationItem>
			))}
			{page.current !== page.max && page.max > 3 && !isPhone && (
				<div className="flex items-center justify-center">
					<strong>...</strong>
				</div>
			)}
			{page.max !== 1 && page.current !== page.max && (
				<PaginationItem
					onClick={() => handleNavigate(page.max)}
					active={page.current === page.max}
					page={page.max}
					isLoading={pageLoading === page.max}
				></PaginationItem>
			)}
			<PaginationItem
				disabled={page.current >= page.max || pageLoading !== undefined}
				onClick={() => handleNavigate(page.current + 1)}
			>
				<i className="far fa-angle-right"></i>
			</PaginationItem>
		</div>
	);
};

interface PaginationItemProps {
	onClick: () => any;
	active?: boolean;
	page?: number;
	children?: any;
	disabled?: boolean;
	className?: string;
	isLoading?: boolean;
}

const PaginationItem = ({
	page,
	onClick,
	active,
	children,
	disabled,
	className,
	isLoading,
}: PaginationItemProps) => {
	return (
		<div
			onClick={() => {
				if (disabled) return;

				onClick();
			}}
			className={cn(
				"w-7 h-7 flex cursor-pointer items-center justify-center transition-all rounded-md font-regular border border-transparent",
				active && "border-border",
				className
			)}
		>
			{page ? (
				isLoading ? (
					<span className="base-loader"></span>
				) : (
					<span className="text-[14px]">{page}</span>
				)
			) : (
				<div className={` ${disabled && "opacity-20"}`}>{children}</div>
			)}
		</div>
	);
};

export default Pagination;
