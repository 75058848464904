import { useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";
import ApiCall from "src/components/ApiCall";
import Button from "src/components/Button";
import Card from "src/components/Card";
import withTranslation, {
	Translation,
} from "src/components/hoc/withTranslation";
import Layout from "src/components/layout/Layout";
import PaymentStatus from "src/components/payment/PaymentStatus";
import TaskInfoCard from "src/components/tasks/TaskInfoCard";
import TimesheetChangeLog from "src/components/timesheet/TimesheetChangeLog";
import TimesheetForm from "src/components/timesheet/TimesheetForm";
import TimesheetInfo from "src/components/timesheet/TimesheetInfo";
import useTimesheets from "src/hooks/api/services/timesheets/useTimesheets";
import useUnreads from "src/hooks/api/useUnreads";
import useAuth from "src/hooks/selectors/useAuth";
import NotFound from "src/views/auth/NotFoundView";

function HoursDetailStudentView({ t }: Translation) {
	const location = useLocation();
	const auth = useAuth();
	const { id } = useParams() as any;
	const { fetchUnreads } = useUnreads();
	const {
		timesheets,
		actions: timesheetsActions,
		status,
	} = useTimesheets({
		task_id: id,
		filter: {
			with: ["timesheets", "current", "task", "creator", "approver"],
		},
	});
	const timesheet = (timesheets || []).length >= 1 ? timesheets[0] : null;
	const changes = timesheet?.timesheets || [];
	const showLog =
		changes.length > 1 ||
		["approved", "disapproved", "processed"].includes(
			timesheet?.current?.status as any
		);
	const isEditable =
		timesheet?.current?.links?.creator === auth.id &&
		!["approved", "disapproved", "processed"].includes(
			timesheet?.current?.status as any
		);

	useEffect(() => {
		fetchUnreads();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		timesheetsActions.list();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [location]);

	if (!timesheet && status !== "loading") {
		return <NotFound />;
	}

	return (
		<Layout title={t("title")} loading={status !== "idle"}>
			<div className="flex flex-col pb-16 md:pb-0 md:grid md:grid-cols-3 gap-4">
				<Card className="col-span-2">
					<div className="flex items-center gap-4 mb-6">
						<Button to={`/hours`} type="dark" iconOnly>
							<i className="fas fa-arrow-left"></i>
						</Button>
						<h2>{t("form.title")}</h2>
					</div>
					{timesheet?.id && (
						<>
							{timesheet?.status === "canceled" ? (
								<div className="flex flex-col">
									<p>{t("canceled.title")}</p>
									<p className="opacity-70">
										{t("canceled.description")}
									</p>
								</div>
							) : (
								<>
									{!timesheet?.current?.id || isEditable ? (
										<TimesheetForm
											options={{ autosave: false }}
											variant="vertical"
											{...{ timesheet }}
										/>
									) : (
										<TimesheetInfo {...{ timesheet }} />
									)}
								</>
							)}
						</>
					)}
				</Card>
				<div className="flex flex-col gap-4">
					{showLog && (
						<Card>
							<TimesheetChangeLog timesheets={changes} />
						</Card>
					)}
					{timesheet?.task && (
						<TaskInfoCard task={timesheet.task as any} />
					)}
					{timesheet?.current?.links?.payment && (
						<ApiCall
							query={`/student/payments/${timesheet?.current.links.payment}`}
						>
							{(data) =>
								data?.id ? (
									<PaymentStatus
										payment={data}
										registration={undefined}
									/>
								) : (
									<></>
								)
							}
						</ApiCall>
					)}
				</div>
			</div>
		</Layout>
	);
}

HoursDetailStudentView.locale = {
	nl: {
		title: "Uren controle",
		form: {
			title: "Uren opgeven",
		},
		canceled: {
			title: "De opdracht is geannuleerd",
			description:
				"De opdrachtgever heeft aangegeven dat je de opdracht niet hebt uitgevoerd, daardoor is het niet mogelijk om je uren te noteren.",
		},
	},
	en: {
		title: "Hour validation",
		form: {
			title: "Submit your hours",
		},
		canceled: {
			title: "The task has been canceled",
			description:
				"The task provider has indicated that you have not performed the task, therefore it is not possible to submit your hours.",
		},
	},
};

export default withTranslation(HoursDetailStudentView);
