import React from "react";
import { NavLink, useLocation } from "react-router-dom";

export type LinkTo =
	| string
	| {
			modal?: string;
	  };

interface LinkProps {
	to: LinkTo;
	activeClassName?: string;
	tabIndex?: number;
	ref?: any;
}

const Link: React.FC<LinkProps & React.HTMLProps<HTMLAnchorElement>> = ({
	to,
	children,
	activeClassName,
	ref,
	...rest
}) => {
	const location = useLocation();

	const isHref =
		typeof to === "string"
			? to.startsWith("http") ||
			  to.startsWith("www") ||
			  to.startsWith("mailto:")
			: false;
	if (isHref) {
		return (
			<a
				{...(rest as any)}
				target="_blank"
				rel="noreferrer"
				href={to || "/"}
				{...{ ref }}
			>
				{children}
			</a>
		);
	}

	return (
		<NavLink
			{...rest}
			target={rest?.target || undefined}
			className={rest?.className}
			to={(typeof to === "string" ? to : to?.modal) || "/"}
			state={
				typeof to !== "string" && to?.modal
					? {
							background: location,
					  }
					: undefined
			}
			tabIndex={rest?.tabIndex}
			{...{ ref }}
		>
			{children as any}
		</NavLink>
	);
};

export default Link;
