import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { ApiOffer, ApiOfferRegistration } from "src/api/types";
import FlatList from "src/components/FlatList";
import FilterTabs from "src/components/layout/FilterTabs";
import OfferRegistrationCard from "src/components/offer/cards/OfferRegistrationCard";
import useGet from "src/hooks/rest/useGet";
import useAuth from "src/hooks/selectors/useAuth";
import useScrollToHid from "src/hooks/useScrollToHid";

interface OfferRegistrationListProps {
	query?: string;
	id: string | string;
	offer: ApiOffer;
}

const OfferRegistrationList = ({
	query,
	id,
	offer,
}: OfferRegistrationListProps) => {
	const { t } = useTranslation("offer", {
		keyPrefix: "offer-registration-list",
	});
	const filters = {
		all: t("filters.all"),
		accepted: t("filters.accepted"),
		denied: t("filters.denied"),
	} as any;
	const auth = useAuth();
	const [filter, setFilter] = useState("all");
	const [{ data, hasMore }, loading, { fetchNext }] = useGet(
		`/student/offers/${id}/registrations?type=${filter}&${query || ""}`,
		{ data: [] },
		true
	);
	useScrollToHid({
		loading,
		prefix: "offer-registration",
	});

	return (
		<div className="flex flex-col gap-4">
			<strong>{t("title")}</strong>
			<FilterTabs
				tabs={filters}
				variant={auth.type === "company" ? "light" : "dark"}
				active={filter}
				onChange={setFilter}
			/>
			{loading && <Loading />}
			{!loading && (
				<div className="flex flex-col gap-4">
					<FlatList
						gap={16}
						empty={{
							title: t("empty.title"),
							text: t("empty.description"),
						}}
						onNext={async () => await fetchNext()}
						{...{ data, hasMore }}
						renderItem={(registration: ApiOfferRegistration) => (
							<OfferRegistrationCard
								{...{ offer, registration }}
								key={`offer-registration-${registration.id}`}
							/>
						)}
					/>
				</div>
			)}
		</div>
	);
};

const Loading = () => (
	<div className="flex flex-col gap-4">
		<OfferRegistrationCard.Loading />
		<OfferRegistrationCard.Loading />
		<OfferRegistrationCard.Loading />
		<OfferRegistrationCard.Loading />
		<OfferRegistrationCard.Loading />
	</div>
);

OfferRegistrationList.Loading = Loading;

export default OfferRegistrationList;
