import { useState, useEffect } from "react";

function useRememberState(key: string, defaultValue?: string) {
	const [value, setValue] = useState(
		localStorage.getItem(key) || defaultValue
	);

	useEffect(() => {
		const local = localStorage.getItem(key);
		if (local) {
			setValue(local);
		}
	}, [key]);

	const onChange = (str: string) => {
		setValue(str);
		localStorage.setItem(key, str);
		return str;
	};

	return [value, onChange as any];
}

export default useRememberState;
