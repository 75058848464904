import useUnreads from "src/hooks/api/useUnreads";
import useAuth from "src/hooks/selectors/useAuth";
import useSocketListener from "src/hooks/sockets/useSocketListener";

export default function useUnreadsListener() {
	const auth = useAuth();
	const { setUnreads } = useUnreads();
	useSocketListener(auth?.id ? `auth.${auth?.hid}.unreads` : null, (res) => {
		if (res?.unreads) {
			setUnreads(res?.unreads);
		}
	});
}
