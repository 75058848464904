import { useLocation } from "react-router-dom";
import useQueryString from "src/hooks/useQueryString";

interface Options {
	type?: "modal";
}

export default function useBackUrl(options?: Options) {
	const location = useLocation();
	let url = location.pathname;
	url = btoa(url);

	if (options?.type) {
		return `back=${url}&back_type=${options.type}`;
	}

	return `back=${url}`;
}

export type ParsedBackUrl = string | { modal: string } | undefined;

export function useParseBackUrl(): ParsedBackUrl {
	const query = useQueryString();
	if (!query.back) return undefined;

	const url = atob(query.back as string);

	if (query.back_type === "modal") {
		return {
			modal: url,
		};
	}

	return url;
}
