import axios from "axios";
import uid from "src/lib/uid";
import useBoards from "src/hooks/api/services/tasks/useBoards";
import useUsers from "src/hooks/api/services/users/useUsers";
import useTasksFilter from "src/hooks/api/tasks/useTasksFilter";
import useAuth from "src/hooks/selectors/useAuth";
import useQueryString from "src/hooks/useQueryString";
import { getHoursBetweenDates } from "src/lib/dates";
import { useParams } from "react-router-dom";

export default function useFillTask() {
	const { project } = useParams();
	const auth = useAuth();
	const { users } = useUsers();
	const qs = useQueryString();
	const { boards } = useBoards();
	const { filter } = useTasksFilter();
	const board = filter?.board
		? boards.find((item: any) => item.slug === filter.board)
		: null;

	const getQueryData = async () => {
		const { title, budget, status, assign_to, end_date } = qs;
		const data: any = {
			amount_of_students: 1,
		};
		if (
			title ||
			budget ||
			status ||
			board ||
			filter ||
			end_date ||
			project
		) {
			let assignees = JSON.parse(
				JSON.stringify(
					board?.filter?.assignees || filter?.assignees || []
				)
			);
			if (assign_to) {
				const user = users.find((item) => item.hid === assign_to);
				if (user?.id) assignees = [user.id];
			} else {
				assignees.push(auth.id.toString());
			}

			if (board?.slug === "my-tasks" && !assignees.includes(auth.id)) {
				assignees.push(auth.id.toString());
			}

			if (end_date) data.end_date = end_date;
			if (title) data.title = title;
			if (budget) data.budget = budget;
			if (status) data.status = status;

			if (assignees.length) data.assignees = assignees;

			if (board?.filter?.tags || filter?.tags) {
				data.tags = board?.filter?.tags || filter?.tags || [];
			}

			if (board?.slug && board?.hid) {
				data.board = board.hid;
			}

			if (filter.project) {
				data.project_slug = filter?.project;
			}

			if (project) {
				data.project = project;
			}

			return data;
		}
		return data;
	};

	const getRepeatTaskData = async (repeat: string) => {
		const task = await axios
			.get(`/client/tasks/${qs.repeat}`)
			.then(({ data }) => data?.data);
		let questions = null;
		let subtasks = undefined;

		if (!task?.id) return {};

		if (task.questions) {
			questions = task.questions.map((question: any) => {
				let item = {
					title: question.title,
					answers: (question?.choices || []).map((item: any) => ({
						answer: item.answer,
						is_right: item.is_right,
					})),
				};
				return item;
			});
		}

		if (task.subtasks) {
			task.budget = null;
			task.amount_of_students = null;
			subtasks = task.subtasks.map((subtask: any) => ({
				_id: uid(),
				title: subtask.title,
				budget: subtask.budget,
				amount_of_students: subtask.amount_of_students,
				start_date: subtask.start_date,
				end_date: subtask.end_date,
				estimated_duration: getHoursBetweenDates(
					subtask.start_date,
					subtask.end_date
				),
				budget_per_hour: subtask.budget_per_hour,
			}));
		}

		return {
			title: task.title,
			description: task.description,
			category_id: task.category_id,
			amount_of_students: task.amount_of_students
				? Number(task.amount_of_students)
				: 1,
			budget: !task?.budget_per_hour ? task.budget : null,
			budget_per_hour: task.budget_per_hour,
			address: task.address,
			is_home: task.is_home,
			is_office: task.is_office,
			media: task.media,
			estimated_duration: task.estimated_duration,
			is_private: task.is_private,
			is_anonymous: task.is_anonymous,
			questions,
			subtasks,
			min_age: task?.age?.min || null,
			students: [],
		};
	};

	const getFillableData = async () => {
		let data: any = {};

		//Connect the student from the connections
		if (qs.profile) {
			const student = await axios
				.get(`/connections/${qs.profile}`)
				.then(({ data }) => data?.data);
			if (student?.hid) {
				data.student = student;
				data.student_hid = student.hid;
			}
		}

		//Fill the task by repeat id
		if (qs.repeat) {
			const repeatData = await getRepeatTaskData(qs.repeat.toString());
			if (repeatData?.title) {
				data = {
					...data,
					...repeatData,
				};
			}
		}

		const queryData = await getQueryData();
		if (Object.keys(queryData).length) {
			data = {
				...data,
				...queryData,
			};
		}

		return data;
	};

	return getFillableData;
}
