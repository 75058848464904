import { motion } from "framer-motion";
import { useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { ApiTaskKanban } from "src/api/types";
import Button from "src/components/Button";
import Loader from "src/components/Loader";
import withTranslation, {
	Translation,
} from "src/components/hoc/withTranslation";
import TaskKanbanCard from "src/components/tasks/kanban/TaskKanbanCard";
import Text from "src/components/ui/text";
import useBoards from "src/hooks/api/services/tasks/useBoards";
import useTaskForm from "src/hooks/api/tasks/useTaskForm";
import useAuth from "src/hooks/selectors/useAuth";
import useBlur from "src/hooks/useBlur";

const variants = {
	missing: {
		scale: [1, 1.01, 0.99, 1],
		transition: {
			repeat: 1,
			duration: 0.5,
		},
	},
	idle: {
		rotate: 0,
	},
};

interface TaskNewKanbanCardProps extends Translation {
	task: ApiTaskKanban;
	onComplete: (task: ApiTaskKanban) => void;
	onCancel?: (task: ApiTaskKanban) => void;
}

const TaskNewKanbanCard = ({
	t,
	task,
	onComplete,
	onCancel,
}: TaskNewKanbanCardProps) => {
	const auth = useAuth();
	const params = useParams();
	const { boards, hasCustomBoards, isForcedBoards } = useBoards();
	const selectedBoard = params?.board
		? boards?.find((b) => b.slug === params.board)
		: null;
	const [form, setForm] = useState({
		title: "",
		board: selectedBoard?.hid || null,
	});
	const ref: any = useRef();
	const { actions, status } = useTaskForm();
	const [isMissingTitle, setIsMissingTitle] = useState(false);
	const [isMissingBoard, setIsMissingBoard] = useState(false);

	useBlur(ref, () => {
		if (!form.title) setIsMissingTitle(true);
		if (!form.board && isForcedBoards) setIsMissingBoard(true);

		if (form.title) {
			handleSubmit();
		}
	});

	const handleSubmit = () => {
		if (hasCustomBoards && !form.title && !form.board) return;
		if (!hasCustomBoards && !form.title) return;

		const data = {
			...task,
			...form,
			assignees: [auth.id],
			// disable_socket: true,
			variant: "kanban",
		};

		const _id = task._id;

		actions
			.create("INTERNAL", {
				data,
				disableRedirect: true,
			})
			.then((task) => {
				if (_id && onComplete) {
					onComplete({
						_id,
						...task,
					});
				}
			});
	};

	return (
		<TaskKanbanCard
			actions={
				status === "creating" ? (
					<Loader />
				) : (
					<Text.Eyebrow>{t("new")}</Text.Eyebrow>
				)
			}
			disableActions
			{...{ task }}
		>
			<div {...{ ref }} className="flex flex-col">
				<form onSubmit={handleSubmit} className="flex flex-col gap-2">
					<motion.div
						className="flex flex-col"
						animate={isMissingTitle ? "missing" : "idle"}
						{...{ variants }}
						onAnimationComplete={(definition) => {
							if (definition === "missing") {
								setIsMissingTitle(false);
							}
						}}
					>
						<TaskKanbanCard.Title
							value={form.title}
							className="w-full mb-0"
							isMissing={isMissingTitle}
							onChange={(value) => {
								setForm((state: any) => ({
									...state,
									title: value,
								}));
							}}
						/>
					</motion.div>
					{!!boards.length && !params?.project && hasCustomBoards && (
						<motion.div
							className="empty:hidden"
							animate={isMissingBoard ? "missing" : "idle"}
							{...{ variants }}
							onAnimationComplete={(definition) => {
								if (definition === "missing") {
									setIsMissingBoard(false);
								}
							}}
						>
							<TaskKanbanCard.Board
								value={form.board || undefined}
								isMissing={isMissingBoard}
								onChange={(board) =>
									setForm((state: any) => ({
										...state,
										board,
									}))
								}
							/>
						</motion.div>
					)}
				</form>
				{onCancel && (
					<div className="flex justify-end">
						<Button
							onClick={() => onCancel(task)}
							xsmall
							type="border-bg"
						>
							{t("cancel")}
						</Button>
					</div>
				)}
			</div>
		</TaskKanbanCard>
	);
};

TaskNewKanbanCard.locale = {
	nl: {
		new: "Nieuw",
		cancel: "Annuleren",
	},
	en: {
		new: "New",
		cancel: "Cancel",
	},
};

export default withTranslation(TaskNewKanbanCard);
