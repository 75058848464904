import { AlertProps } from "src/components/AlertContainer";
import { useDispatch } from "react-redux";
import { setAlert } from "src/redux/alert";

const useAlert = () => {
	const dispatch = useDispatch();
	const send = (alert: AlertProps) => {
		dispatch(setAlert(alert));
	};
	return [send];
};

export default useAlert;
