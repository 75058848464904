import { AxiosInstance } from "axios";
import {
	ApiRegistrationTimesheet,
	ApiTimesheetProcessResult,
} from "src/api/types";
import useApi, {
	ApiActions,
	ApiStatus,
	Pagination,
} from "src/hooks/api/utils/useApi";
import useAuth from "src/hooks/selectors/useAuth";

interface ApiTimesheetsActions extends ApiActions {
	process: (data?: any) => Promise<ApiTimesheetProcessResult>;
	getProcessResult: () => Promise<ApiTimesheetProcessResult>;
}

type ApiTimesheetsStatus = ApiStatus | "processing";

type State = {
	timesheets: ApiRegistrationTimesheet[];
	result?: ApiTimesheetProcessResult;
	status: ApiTimesheetsStatus;
	actions: ApiTimesheetsActions;
	api: AxiosInstance;
	pagination: Pagination;
};

type Options = {
	task_id: number | string;
	filter?: any;
};

function useTimesheets(options: Options): State {
	const auth = useAuth();
	const { state, actions, api, pagination } = useApi(
		{ id: `TIMESHEETS_TASK:${options.task_id}` },
		{
			baseUrl: `/${
				auth.type === "student" ? "student" : "client"
			}/tasks/${options.task_id}/timesheets`,
			query: {
				task_id: options.task_id,
				with: ["timesheets", "current"],
				...(options?.filter || {}),
			},
		}
	);

	const process = async () => {
		actions.set((state: any) => ({ ...state, status: "processing" }));
		try {
			const { data } = await api.post("/process", {
				with: ["payments"],
			});
			actions.set((state: any) => ({
				...state,
				custom: {
					...state?.custom,
					result: data?.data,
				},
				status: "idle",
			}));
			return data;
		} catch (error) {
			actions.set((state: any) => ({ ...state, status: "idle" }));

			throw error;
		}
	};

	const getProcessResult = async () => {
		actions.set((state: any) => ({ ...state, status: "loading" }));
		try {
			const { data } = await api.get("/process/result", {
				params: {
					with: ["payments"],
				},
			});
			actions.set((state: any) => ({
				...state,
				custom: {
					...state?.custom,
					result: data?.data,
				},
				status: "idle",
			}));
			return data;
		} catch (error) {
			actions.set((state: any) => ({ ...state, status: "idle" }));
			throw error;
		}
	};

	return {
		timesheets: state?.list || [],
		result: state?.custom?.result || {},
		status: state.status,
		pagination,
		actions: {
			...actions,
			process,
			getProcessResult,
		},
		api,
	};
}

export default useTimesheets;
