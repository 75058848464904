import withTranslation, {
	Translation,
} from "src/components/hoc/withTranslation";
import ProfileBadge from "src/components/ProfileBadge/ProfileBadge";
import ResumeSectionTitle from "src/components/resume/resume-detail/ResumeSectionTitle";
import useResumeDetailContext from "../ResumeDetailContext";

function ResumeBadges({ t }: Translation) {
	const { resume } = useResumeDetailContext();

	if (resume.badges.length === 0) {
		return <></>;
	}

	return (
		<div className="flex flex-col gap-4">
			<div className="flex gap-3 flex-col">
				<ResumeSectionTitle>{t("title")}</ResumeSectionTitle>
				{resume.badges.map((item: any) => (
					<ProfileBadge
						variant={
							resume.badges?.length === 1
								? "resume-list"
								: "resume-list"
						}
						key={item.type}
						badge={item}
					/>
				))}
			</div>
		</div>
	);
}

ResumeBadges.locale = {
	nl: {
		title: "Badges",
		"title-no-badges": "{{first_name}} heeft nog geen badges",
	},
	en: {
		title: "Badges",
		"title-no-badges": "{{first_name}} has no badges yet",
	},
};

export default withTranslation(ResumeBadges);
