import { Route } from "react-router-dom";
import ProtectedRoute from "src/routes/middlewares/ProtectedRoute";
import FeedbackView from "src/views/authenticated/feedback/FeedbackView";
import CreateIdeaModal from "src/views/authenticated/feedback/create/CreateFeedbackModal";
import DetailIdeaModal from "src/views/authenticated/feedback/detail/DetailFeedbackModal";

const FeedbackRoutes = [
	<Route
		element={
			<ProtectedRoute type="company">
				<FeedbackView />
			</ProtectedRoute>
		}
		path="/feedback"
		key="/feedback"
	/>,
];

export const FeedbackModalRoutes = [
	<Route
		element={
			<ProtectedRoute
				variant="modal"
				modal={{
					backUrl: "/feedback",
					expanded: false,
					size: "default",
				}}
			>
				<CreateIdeaModal />
			</ProtectedRoute>
		}
		path="/feedback/create"
		key="/feedback/create"
	/>,
	<Route
		element={
			<ProtectedRoute
				variant="modal"
				modal={{
					backUrl: "/feedback",
					expanded: false,
					size: "default",
				}}
			>
				<DetailIdeaModal />
			</ProtectedRoute>
		}
		path="/feedback/:id"
		key="/feedback/:id"
	/>,
];

export default FeedbackRoutes;
