import io from "socket.io-client";

const DEBUG = false;

const socket = io(process.env.REACT_APP_SOCKET_URL || "");

export const listen = (event: string, callback: (data: any) => void) => {
	if (DEBUG) {
		console.log(`Connected to event: ${event}`);
	}
	return socket.off(event).on(event, callback);
};

export const off = (event: string) => {
	if (DEBUG) {
		console.log(`Disconnect from event: ${event}`);
	}
	return socket?.off(event);
};

export const decrypt = (data: string, key: string) => {
	const decoded = atob(data);

	return JSON.parse(atob(decoded.replace(key, "")));
};

export default socket;
