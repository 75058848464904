import axios from "axios";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import useFetchProfile from "src/hooks/api/useFetchProfile";
import usePost from "src/hooks/rest/usePost";
import useAuth from "src/hooks/selectors/useAuth";
import { removeOffer, updateOffer } from "src/redux/offer";

function useCreateOffer() {
	const dispatch = useDispatch();
	const offer = useSelector((state: any) => state.offer.offer);
	const current = useSelector((state: any) => state.offer.current);
	const [create] = usePost(`/student/offers`);
	const [creating, setCreating] = useState(undefined) as any;
	const navigate = useNavigate();
	const auth = useAuth();
	const { fetchProfile } = useFetchProfile();

	const fetchDefaultIntro = () => {
		axios.get(`/resume/${auth.hid}/intro`).then(({ data }) => {
			if (data?.data?.description) {
				onChange("description_intro", data?.data?.description);
			}
		});
	};

	const onChange = (key: string, value?: any) => {
		dispatch(
			updateOffer({
				offer: {
					...offer,
					[key]: value,
				},
			})
		);
	};

	const setCurrent = (n: number) => {
		if (n < 0) return;
		dispatch(
			updateOffer({
				current: n,
			})
		);
	};

	const getParsedData = () => {
		const data = {
			...offer,
		};
		if (
			data?.description_intro ||
			data?.description_what ||
			data?.description_how
		) {
			data.description = {
				intro: data.description_intro || data?.description?.intro,
				what: data.description_what || data?.description?.what,
				how: data.description_how || data?.description?.how,
			};
		}
		delete data.description_intro;
		delete data.description_what;
		delete data.description_how;
		data.visibility = {
			is_anonymous: data.is_anonymous ? true : false,
			is_private: data.is_private ? true : false,
		};
		delete data.is_anonymous;
		delete data.is_private;
		return data;
	};

	const handleCreate = async (status = "PUBLISHED") => {
		const data = getParsedData();
		data.visibility.status = status;
		setCreating(status);

		try {
			const { data: offer } = await create(data);
			setCreating(undefined);
			dispatch(removeOffer());
			fetchProfile();
			navigate(`/offers/create/${offer.slug}/finished`);
		} catch (error) {
			setCreating(undefined);
		}
	};

	const clearOffer = () => {
		dispatch(removeOffer());
	};

	return {
		offer,
		onChange,
		current,
		setCurrent,
		handleCreate,
		creating,
		clearOffer,
		fetchDefaultIntro,
	};
}
export default useCreateOffer;
