import { useLocation, useNavigate } from "react-router-dom";
import { ApiOffer, ApiOfferRegistration } from "src/api/types";
import Chat from "src/components/chat/Chat";
import ReviewForm from "src/components/forms/ReviewForm";
import IconButton from "src/components/IconButton";
import Modal from "src/components/Modal";
import OfferInfoCard from "src/components/offer/cards/OfferInfoCard";
import OfferRegistrationMatchCard from "src/components/offer/cards/OfferRegistrationMatchCard/OfferRegistrationMatchCard";
import OfferAcceptForm from "src/components/offer/forms/OfferAcceptForm";
import OfferBidForm from "src/components/offer/forms/OfferBidForm";
import OfferDenyForm from "src/components/offer/forms/OfferDenyForm";
import useAuth from "src/hooks/selectors/useAuth";
import useQueryString from "src/hooks/useQueryString";
import useWindowSize from "src/hooks/useWindowSize";

interface OfferChatProps {
	offer: ApiOffer;
	registration: ApiOfferRegistration | any;
	variant: "full" | "modal";
}

const OfferChat = ({ offer, registration, variant }: OfferChatProps) => {
	const auth = useAuth();
	const { isTablet } = useWindowSize();
	const location = useLocation();
	const isCreator = offer?.user?.id === auth.id;
	const navigate = useNavigate();
	const qs = useQueryString();
	const modal: any = qs?.modal;

	const setModal = (modal?: string) => {
		if (!modal || modal === "undefined") {
			navigate(location.pathname);
			return;
		}
		navigate(`${location.pathname}?modal=${modal}`);
	};

	const titles = {
		"bid-form": "Opdrachtwaarde wijzigen",
		"deny-form": "Aanvraag afwijzen",
		"accept-form": "Aanvraag goedkeuren",
		"offer-info": offer.title,
		"match-info": "Match informatie",
		review: "Afronden",
	} as any;

	return (
		<>
			<Modal
				title={titles[modal] || "Informatie"}
				variant={modal === "match-info" ? "dark" : "light"}
				visible={modal}
				onClose={() => setModal(undefined)}
			>
				{modal === "bid-form" && (
					<OfferBidForm
						variant="form"
						onDone={() => setModal(undefined)}
					/>
				)}
				{modal === "accept-form" && (
					<OfferAcceptForm
						variant="form"
						onDone={() => setModal(undefined)}
					/>
				)}
				{modal === "deny-form" && (
					<OfferDenyForm
						variant="form"
						onDone={() => setModal(undefined)}
					/>
				)}
				{modal === "offer-info" && <OfferInfoCard {...{ offer }} />}
				{registration.status === "ACCEPTED" &&
					modal === "match-info" && (
						<OfferRegistrationMatchCard
							reviewUrl={`${location.pathname}?modal=review`}
							variant="flat"
							{...{ registration }}
						/>
					)}

				{modal === "review" && registration.status === "ACCEPTED" && (
					<ReviewForm
						theme="flat"
						taskId={registration?.task?.id}
						userId={offer?.user?.id}
						companyId={registration?.company?.id}
						onDone={() => {
							navigate(location.pathname);
						}}
						onCancel={() => {
							navigate(location.pathname);
						}}
					/>
				)}
			</Modal>
			<div className="flex flex-1 gap-3">
				<Chat
					headActions={
						<>
							{registration.status === "ACCEPTED" && (
								<div className="text-green flex items-center gap-2">
									<i className="fas fa-check"></i>
									<span className="hidden sm:block">
										Gematcht
									</span>
								</div>
							)}
							<IconButton
								icon="fas fa-question"
								onClick={() => setModal("offer-info")}
							/>
							{(isTablet || variant === "modal") && (
								<>
									{registration.status === "PENDING" && (
										<>
											<IconButton
												variant="dark"
												icon="fas fa-money-check-edit"
												onClick={() =>
													setModal("bid-form")
												}
											/>
											{isCreator && (
												<>
													<IconButton
														variant="red"
														icon="fas fa-times"
														onClick={() =>
															setModal(
																"deny-form"
															)
														}
													/>
													<IconButton
														variant="primary"
														icon="fas fa-check"
														onClick={() =>
															setModal(
																"accept-form"
															)
														}
													/>
												</>
											)}
										</>
									)}

									{registration.status === "ACCEPTED" && (
										<>
											<IconButton
												variant="dark"
												onClick={() =>
													setModal("match-info")
												}
												icon="fas fa-info"
												indicator={
													registration.needs_attention
														? "!"
														: undefined
												}
											/>
										</>
									)}
								</>
							)}
						</>
					}
					className="md:col-span-2 flex-1"
					options={{
						type: "offer",
						room: `room-${registration.hid}-offer`,
						backUrl: `/offers/${offer.slug}/show`,
					}}
					data={{
						registration,
						offer,
					}}
				/>
				{!isTablet && variant === "full" && (
					<div className="flex flex-col gap-4 max-w-[360px]">
						{registration.status === "PENDING" && (
							<>
								<OfferBidForm />
								{isCreator && (
									<>
										<OfferAcceptForm />
										<OfferDenyForm />
									</>
								)}
							</>
						)}
						{registration.status === "ACCEPTED" && (
							<OfferRegistrationMatchCard
								reviewUrl={`${location.pathname}?modal=review`}
								{...{ registration }}
							/>
						)}
					</div>
				)}
			</div>
		</>
	);
};

OfferChat.defaultProps = {
	variant: "full",
};

export default OfferChat;
