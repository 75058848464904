import { Formik } from "formik";
import { useTranslation } from "react-i18next";
import Button from "src/components/Button";
import Input from "src/components/form/Input";
import Link from "src/components/Link";
import useLogin from "src/hooks/api/services/auth/useLogin";
import useAlert from "src/hooks/useAlert";
import * as Yup from "yup";

const LoginScheme = Yup.object().shape({
	email: Yup.string().email("Ongeldig e-mailadres").required("Verplicht"),
	password: Yup.string()
		.min(6, "Wachtwoord is te kort")
		.required("Verplicht"),
});

interface LoginFormProps {
	redirectUrl?: string;
}

const LoginForm = ({ redirectUrl }: LoginFormProps) => {
	const { t } = useTranslation("auth", {
		keyPrefix: "login-form",
	});
	const { actions, status } = useLogin({
		redirectUrl: redirectUrl || "/dashboard",
	});
	const [sendAlert] = useAlert();
	return (
		<Formik
			initialValues={{
				email: "",
				password: "",
			}}
			validationSchema={LoginScheme}
			onSubmit={(values, { setSubmitting }) => {
				setSubmitting(true);
				actions.login(values).catch((error) => {
					sendAlert({
						title: t("error.title"),
						text: t("error.text"),
						type: "error",
					});
					setSubmitting(false);
				});
			}}
		>
			{({
				values,
				errors,
				touched,
				handleChange,
				handleBlur,
				handleSubmit,
				isSubmitting,
			}) => (
				<form className="flex flex-col gap-4" onSubmit={handleSubmit}>
					<Input
						label={t("form.email")}
						placeholder={t("form.email")}
						name="email"
						type="email"
						value={values.email}
						valid={values.email && !errors.email}
						error={errors.email && touched.email && errors.email}
						{...{ handleChange, handleBlur }}
					/>
					<Input
						label={t("form.password")}
						subLabel={
							<Link
								className="gtm-inloggen--link-wachtwoord-vergeten text-[14px]"
								to="/forgot-password"
								tabIndex={-1}
							>
								{t("buttons.forgot")}
							</Link>
						}
						placeholder={t("form.password")}
						name="password"
						type="password"
						value={values.password}
						valid={!errors.password && touched.password}
						error={
							errors.password &&
							touched.password &&
							errors.password
						}
						{...{ handleChange, handleBlur }}
					/>
					<Button
						submit
						loading={status === "loading" || isSubmitting}
						type="dark"
						className="gtm-inloggen--cta-inloggen mt-2"
					>
						{t("buttons.login")}
					</Button>
				</form>
			)}
		</Formik>
	);
};

export default LoginForm;
