import { AnimatePresence, motion } from "framer-motion";
import { useContext, useMemo, useState } from "react";
import { createPortal } from "react-dom";
import Loader from "src/components/Loader";
import { ModalProps } from "src/components/Modal";
import ShadowBackground from "src/components/Modal/ShadowBackground";
import { ModalViewContext } from "src/components/layout/ModalView";
import useLayout from "src/hooks/useLayout";
import useWindowSize from "src/hooks/useWindowSize";
import { cn } from "src/lib/utils";

const FULLSCREEN_SPACING = 64;

const SideModal = ({
	onClose,
	visible,
	children,
	className,
	title,
	loading,
	expanded,
	portalId,
}: ModalProps) => {
	const modal = useContext(ModalViewContext);
	const { layout } = useLayout();
	const { isPhone } = useWindowSize();
	const modalWidth = window.innerWidth - layout.navbar.size.width || 600;
	const [isFullScreen, setIsFullScreen] = useState(expanded || false);
	const handleClose = () => {
		setIsFullScreen(false);
		onClose();
	};

	const animationState = useMemo(() => {
		if (visible) {
			if (isPhone) {
				return "open";
			}
			return isFullScreen ? "fullscreen" : "open";
		}
		return "closed";
	}, [isFullScreen, isPhone, visible]);

	const wrapperAnimations = useMemo(() => {
		if (isPhone) {
			return {
				closed: { x: 1000 },
				open: { x: 0 },
				fullscreen: { x: 0 },
			};
		}

		return {
			closed: {
				x: 1000,
				maxWidth: 600,
			},
			open: { x: 0, maxWidth: (modalWidth / 3) * 2 },
			fullscreen: {
				x: 0,
				maxWidth: modalWidth - FULLSCREEN_SPACING,
			},
		};
	}, [modalWidth, isPhone]);

	return createPortal(
		<AnimatePresence>
			{visible && (
				<>
					<ShadowBackground onClick={onClose} />
					<motion.div
						transition={{
							ease: "linear",
						}}
						animate={animationState}
						variants={wrapperAnimations}
						initial="closed"
						exit="closed"
						className={cn(
							"z-[99] fixed w-full flex flex-col flex-1 right-0 bottom-0 bg-background top-0",
							className || ""
						)}
					>
						<div className="flex items-center gap-6 px-4 md:px-6 pt-4">
							<div className="flex items-center gap-4 flex-1">
								<div
									onClick={() =>
										setIsFullScreen(!isFullScreen)
									}
									className="w-8 h-8 rounded-sm cursor-pointer hover:bg-dark-200 bg-accent-background transition-all hidden md:flex items-center justify-center"
								>
									<i
										className={cn(
											"far",
											isFullScreen
												? "fa-compress"
												: "fa-expand"
										)}
									></i>
								</div>
								{(modal?.title || title) && (
									<strong>{title || modal?.title}</strong>
								)}
							</div>
							<i
								onClick={handleClose}
								className="fal fa-times text-3xl cursor-pointer transition-all text-placeholder hover:text-foreground"
							></i>
						</div>

						<div className="overflow-y-auto flex flex-col flex-1">
							{loading ? (
								<div className="flex flex-1 flex-col justify-center items-center">
									<Loader />
								</div>
							) : (
								children
							)}
						</div>
					</motion.div>
				</>
			)}
		</AnimatePresence>,
		document.getElementById(portalId || "modal-root") as any
	);
};

export default SideModal;
