import StepsLine from "src/components/StepsLine";
import useTaskForm from "src/hooks/api/tasks/useTaskForm";
import useTaskFormSteps from "src/hooks/api/tasks/useTaskFormSteps";

interface TaskFormWrapperProps {
	children?: any;
}

const TaskFormWrapper = ({ children }: TaskFormWrapperProps) => {
	const { current, actions } = useTaskForm();
	const { steps } = useTaskFormSteps();

	return (
		<>
			<div className="flex">
				<StepsLine
					onClick={(step: any) => {
						actions.updateCurrent(step);
					}}
					variant="horizontal-transparent"
					active={current}
					{...{ steps }}
				/>
			</div>
			<div className="mt-4 md:mt-8 flex flex-col flex-1 gap-4">
				{children}
			</div>
		</>
	);
};

export default TaskFormWrapper;
