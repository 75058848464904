const common = {
	studentpool: "Connections",
	theme: "Theme",
	task: "Task",
	profile: "Profile",
	chat: "Chat",
	or: "Or",
	live: "Live",
	concept: "Concept",
	accepted: "Accepted",
	pending: "Pending",
	skills: "Skills",
	empty: "No results",
	clear: "Clear",
	today: "Today",
	tomorrow: "Tomorrow",
	translation: {
		choose: "Choose a language",
		NL: "Dutch",
		EN: "English",
	},
	"unread-messages_one": "{{count}} unread message",
	"unread-messages_other": "{{count}} unread messages",
	greeting: {
		goodafternoon: "Good afternoon",
		goodevening: "Good evening",
		goodmorning: "Good morning",
	},
	titles: {
		performer: "Performer",
		client: "Client",
	},
	labels: {
		"created-by": "Created by",
		"created-at": "Created at",
		"not-found": "Not found",
		average: "Average",
		you: "you",
	},
	loading: {
		text: "Please wait",
	},
	notification: {
		title: "New alert",
	},
	format: {
		"trailing-hour": "{{value}}h",
	},
};

export default common;
