import Card from "src/components/Card";
import Loader from "src/components/Loader";
import { cn } from "src/lib/utils";

interface FormGridProps {
	children?: any;
	variant?: "grid" | "grid-sm" | "stacked";
}

const FormGrid = ({ children, variant = "grid" }: FormGridProps) => {
	return (
		<div
			className={cn(
				`gap-8 flex flex-col`,
				variant === "grid" &&
					"md:grid-cols-[300px,1fr] lg:grid-cols-[400px,1fr] md:grid",
				variant === "grid-sm" &&
					"md:grid-cols-[300px,1fr] lg:grid-cols-[300px,1fr] md:grid"
			)}
		>
			{children}
		</div>
	);
};

interface HeaderProps {
	title?: string;
	children?: any;
}

const Header = ({ title, children }: HeaderProps) => {
	return (
		<div className="flex flex-col pt-4">
			<strong className="text-[20px]">{title}</strong>
			{children && <div className="max-w-[500px]">{children}</div>}
		</div>
	);
};

interface CardProps {
	children?: any;
	className?: string;
	loading?: boolean;
	variant?: "flat" | "card";
}

const WrapperCard = ({
	children,
	className,
	loading,
	variant = "card",
}: CardProps) => {
	const Element = variant === "card" ? Card : "div";
	return (
		<div className={`max-w-[640px] mb-8 ${className || ""}`}>
			<Element className="relative" contentClassName=" ">
				{loading && <Loader.Overlay />}
				{children}
			</Element>
		</div>
	);
};

FormGrid.Header = Header;
FormGrid.Card = WrapperCard;

export default FormGrid;
