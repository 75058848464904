const tasks = {
	alert: {
		success: {
			title: "De opdracht is aangepast",
			text: "De wijzigingen zijn succesvol doorgevoerd",
		},
		error: {
			title: "Er is iets misgegaan",
			text: "Controleer of alle velden juist zijn ingevuld",
		},
	},
	"task-button": {
		deleted: "Opdracht bestaat niet meer",
		pending: "Afwachting van reactie",
		accepted: "Opdracht geaccepteerd",
		denied: "Niet geselecteerd",
		"not-signed": "⚠️ Nog niet ondertekend",
		messages: "Ongelezen berichten",
		// messages_one: "1 ongelezen bericht",
		// messages_othe: "{{count}} ongelezen berichten",
		completed: "Opdracht voltooid",
		"account-pending": "Uw account moet goedgekeurd worden",
		expired: "Opdracht is velopen",
		view: "Bekijk aanmelding",
	},
	status: {
		open: "Open",
		cancelled: "Geannuleerd",
		completed: "Voltooid",
		matched: "Gematcht",
		"has-messages": "Ongelezen berichten",
		"has-registrations": "Open",
		"open-envelope": "Nog ondertekenen",
		expired: "Verlopen",
		concept: "Concept",
		backlog: "Backlog",
		BACKLOG: "Backlog",
		TODO: "To-do",
		IN_PROGRESS: "In progress",
		COMPLETED: "Completed",
		PENDING: "In afwachting",
		DENIED: "Niet geselecteerd",
		extern: {
			open: "Open",
			cancelled: "Geannuleerd",
			completed: "Voltooid",
			matched: "Gematcht",
			"has-messages": "Ongelezen berichten",
			"has-registrations": "Open",
			"open-envelope": "Nog ondertekenen",
			expired: "Verlopen",
			concept: "Concept",
			backlog: "Backlog",
			BACKLOG: "Backlog",
			TODO: "To-do",
			IN_PROGRESS: "In uitvoering",
			COMPLETED: "Afgerond",
			PENDING: "In afwachting",
			DENIED: "Niet geselecteerd",
		},
	},
	steps: {
		description: "Opdrachtomschrijving",
		budget: "Opdrachtwaarde",
		location: "Locatie",
		date: "Start- en einddatum",
		form: "Aanmeldformulier",
		shifts: "Shifts",
		invite: "Professionals uitnodigen",
		interview: "Interview",
		planning: {
			questions: "AI vragen",
			budget: "Opdrachtwaarde",
			extra: "Extra",
			publish: "Uitbesteden",
		},
	},
	gift: {
		title: "Gefeliciteerd",
		description_one: "Je krijgt 1 gratis opdracht cadeau!",
		description_other: "Je krijgt {{count}} gratis opdrachten cadeau!",
		cta_one: "Opdracht plaatsen",
		cta_other: "Opdrachten plaatsen",
	},
	"empty-card": {
		title: "Laten we een opdracht gaan maken!",
		description_one: "De opdracht maak je gemakkelijk in 1 simpele stap",
		description_other:
			"De opdracht maak je gemakkelijk in {{count}} simpele stappen",
		instructions: {
			description: "Geef een duidelijke opdrachtomschrijving",
			budget: "Bepaal de opdrachtwaarde",
			location: "Geef de locatie op: vanuit huis, op kantoor of extern",
			interview: "Stel de potentiele kandidaat alvast wat vragen",
			date: "Bepaal de begindatum, deadline en categorie van de opdracht",
			finish: "Wij verspreiden jouw opdracht onder onze professionals",
		},
		cta: "Eerste opdracht maken",
	},
	"delete-task": {
		label: "Opdracht verwijderen",
		confirm: {
			title: "Weet je het zeker",
			description:
				"Je staat op het punt om {{title}} te verwijderen, weet je het zeker?",
		},
	},
	"denie-button": {
		title: "Professional afkeuren",
		description:
			"Licht kort toe waarom deze professional niet geschikt is, hierdoor kunnen wij in de toekomst betere kandidaten selecteren voor jouw opdrachten.",
		placeholder: "Deze professional is niet geschikt omdat:",
		label: "Motivatie",
		confirm: {
			title: "Weet je het zeker",
			description: "Weet je zeker dat je de aanmelding wilt afwijzen?",
		},
	},
	"publish-draft-modal": {
		title: "Plaats of bewerk je opdracht",
		"no-results": "Geen opdracht gevonden",
		keys: {
			"amount-of-students": "Aantal professionals",
			budget: "Opdrachtwaarde",
			"estimated-hourly-rate": "Geschatte uurloon",
			"per-hour": "Per uur",
			"start-date": "Start datum",
			"end-date": "Einddatum",
			date: "Datum",
		},
	},
	"publish-invite-task": {
		confirm: {
			title: "Weet je het zeker?",
			description:
				"Wanneer je de opdracht openbaar zet kunnen alle professionals hier op reageren",
		},
		success: {
			title: "Opdracht succesvol openbaar gezet",
			description:
				"Houd je aanmeldingen in de gaten om nieuwe aanmeldingen te bekijken",
		},
		failed: {
			title: "Er lijkt ietst niet goed te gaan",
			description: "Probeer het later opnieuw",
		},
		button: "Opdracht openbaar zetten",
		disclaimer:
			"Alle professionals kunnen zich dan aanmelden op de opdracht.",
	},
	"student-banner": {
		title: "Opdracht maken voor:",
	},
	"registration-denie-modal": {
		title: "Professional afwijzen",
		confirm: {
			title: "Weet je het zeker?",
			description:
				"Weet je zeker dat je de aanmelding wilt afwijzen met de volgende reden:",
			button: "Afwijzen bevestigen",
		},
		description:
			"Licht kort toe waarom deze professional niet geschikt is, hierdoor kunnen wij in de toekomst betere kandidaten selecteren voor jouw opdrachten.",
		label: "Motivatie",
		placeholder: "Deze professional is niet geschikt omdat:",
	},
	"share-task": {
		title: "Deel je opdracht via social media om meer aanmeldingen te krijgen",
		social: {
			title: "Ik heb zojuist een nieuwe opdracht geplaatst op {{tenant.name}}: {{title}}",
			text: "Wil jij €{{budget}} verdienen? Klik dan op de onderstaande link ({{url}}).",
		},
		labels: {
			facebook: "Delen op Facebook",
			linkedin: "Delen op LinkedIn",
			twitter: "Delen op Twitter",
		},
	},
	"task-card": {
		"unread-messages_one": "{{count}} ongelezen bericht",
		"unread-messages_other": "{{count}} ongelezen berichten",
		home: "Thuis",
		"estimated-duration": "Ongeveer {{hour}} uur",
	},
	"task-match-profile": {
		"matched-with": "Gematcht met",
	},
	"task-match-progress": {
		matched: "Gematcht",
	},
	"task-table-row": {
		"no-registrations": "Nog geen aanmeldingen",
		"per-hour": "per uur",
		registrations_one: "1 aanmelding",
		registrations_other: "{{count}} aanmeldingen",
		types: {
			INTERNAL: "Intern",
			PUBLISHED: "Extern",
			DRAFT: "Concept",
		},
		delete: {
			title: "Weet je het zeker?",
			description: "Weet je zeker dat je de opdracht wilt verwijderen?",
		},
	},
	"tasks-grid": {
		empty: {
			title: "Je hebt je nog niet aangemeld voor opdrachten",
			text: "Opdracht zoeken",
		},
		header: {
			search: "Opdrachten zoeken",
			create: "Opdracht aanmaken",
		},
		head: {
			student: {
				title: "Mijn opdrachten",
				subtitle: "Op deze opdrachten heb jij je aangemeld",
			},
		},
	},
	"task-info-card": {
		home: "Thuis",
		deadline: "Deadline",
		hour: "Uur",
		"per-hour": "/ uur",
	},
	"task-company-head": {
		repeat: "Opdracht herhalen",
	},
	"task-invites": {
		empty: {
			title: "Je hebt nog niemand uitgenodigd",
		},
		headers: {
			student: "Professional",
			status: "Status",
			registered: "Aangemeld",
			"invited-on": "Uitgenodigd op",
		},
		yes: "Ja",
		no: "Nee",
		status: {
			ACCEPTED: "Goedgekeurd",
			READ: "Bekeken",
			DENIED: "Afgewezen",
			OPEN: "Open",
		},
	},
};
export default tasks;
