import { useEffect } from "react";
import LayoutDesktopDragSpacer from "src/components/layout/layout-default/LayoutDesktopDragSpacer";
import AuthSessionProcessingLabel from "src/components/layout/layout-default/sidebar/AuthSessionProcessingLabel";
import NavItems from "src/components/layout/layout-default/sidebar/NavItems";
import useTenant from "src/hooks/api/services/tenants/useTenant";
import useElementSize from "src/hooks/useElementSize";
import useLayout from "src/hooks/useLayout";
import { cn } from "src/lib/utils";

const LayoutSidebar = () => {
	const { tenant } = useTenant();
	const { size, ref } = useElementSize();
	const { actions } = useLayout();
	const { layout } = useLayout();

	useEffect(() => {
		actions.setNavbarSize({ size });
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [size]);

	return (
		<>
			<div className="flex">
				<div style={{ width: size.width }} className="block"></div>
			</div>
			<div
				{...{ ref }}
				style={{ zIndex: 94 }}
				className={cn(
					"fixed left-0 top-0 bottom-0 flex flex-col flex-1 group/sidebar hide-default-scrollbars",
					"bg-accent dark:bg-background",
					tenant.slug === "odylyt" &&
						"dark:bg-background dark:border-transparent bg-background border-transparent"
				)}
			>
				<div
					className={cn(
						"flex flex-col flex-1 bg-white border-r-[1px] border-gray",
						"dark:bg-background dark:border-r-0"
					)}
				>
					<LayoutDesktopDragSpacer />
					{/* <SidebarHead /> */}
					<AuthSessionProcessingLabel
						className={cn(
							"my-3 hidden xl:flex",
							layout.navbar.variant !== "default" && "hidden"
						)}
					/>
					<NavItems />
				</div>
			</div>
		</>
	);
};

export default LayoutSidebar;
