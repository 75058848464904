import moment from "moment";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { ApiPaymentRequest } from "src/api/types";
import Avatar from "src/components/Avatar";
import Calendar from "src/components/Calendar";
import EmptyState from "src/components/EmptyState";
import withTranslation, {
	Translation,
} from "src/components/hoc/withTranslation";
import usePaymentRequests, {
	PaymentRequestFilter,
} from "src/hooks/api/services/payment-requests/usePaymentRequests";
import useAuth from "src/hooks/selectors/useAuth";

interface PaymentRequestCalendarProps extends Translation {
	children?: any;
	filter?: PaymentRequestFilter;
	createUrl: string;
}

const PaymentRequestCalendar = ({
	t,
	filter,
	createUrl,
}: PaymentRequestCalendarProps) => {
	const auth = useAuth();
	const [date, setDate] = useState(moment());
	const { requests, status, actions } = usePaymentRequests({
		stateId: "PAYMENT_REQUESTS_CALENDAR",
		filter,
	});
	const location = useLocation();

	useEffect(() => {
		actions.list(filter);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [location, filter]);

	return (
		<Calendar
			className="flex-1 relative"
			selected={date}
			actions={{
				onSelectChange: setDate,
			}}
			controls={{
				interval: "week",
			}}
			head={{
				actions: <Calendar.WeekLayoutSwitcher />,
			}}
		>
			{requests.length === 0 && status === "idle" && (
				<EmptyState.Overlay className="p-4">
					<EmptyState.Title>{t("empty.title")}</EmptyState.Title>
					<EmptyState.Description>
						{t("empty.description")}
					</EmptyState.Description>
				</EmptyState.Overlay>
			)}
			<Calendar.WeekTime>
				{requests.map((item: ApiPaymentRequest) => (
					<Calendar.WeekTime.Item
						data={{
							start: moment(item.start_date),
							end: moment(item?.end_date || item.end_date),
						}}
						onDateChange={(start, end) => {
							setDate(start);
						}}
						to={{
							modal: `/payment-requests/${item.id}/edit`,
						}}
					>
						<div className="p-2">
							<p className="text-[15px]">{item.description}</p>
							{auth.type === "student" ? (
								<div className="flex gap-3 items-center mt-3">
									<Avatar src={item?.company?.avatar} />
									<div className="flex flex-col flex-1">
										{item?.company?.name}
									</div>
								</div>
							) : (
								<div className="flex gap-3 items-center mt-3">
									<Avatar src={item?.receiver?.avatar} />
									<div className="flex flex-col flex-1">
										{item?.receiver?.full_name}
									</div>
								</div>
							)}
						</div>
					</Calendar.WeekTime.Item>
				))}
			</Calendar.WeekTime>
		</Calendar>
	);
};

PaymentRequestCalendar.locale = {
	nl: {
		buttons: {
			create: "Betaalverzoek maken",
		},
		empty: {
			title: "Geen verzoeken gevonden",
			description: "Er zijn geen verzoeken die verwerkt moeten worden.",
		},
	},
	en: {
		buttons: {
			create: "Create Payment Request",
		},
		empty: {
			title: "No requests found",
			description: "There are no requests to be processed.",
		},
	},
};

export default withTranslation(PaymentRequestCalendar);
