import useApi from "src/hooks/api/utils/useApi";

function useBestPerformingTeams() {
	const { state, actions, api, pagination } = useApi(
		{ id: "TEAMS_PERFORMING" },
		{
			baseUrl: `/client/statistics/teams/performing`,
			query: {
				sort: "best",
			},
		}
	);

	const teams = state?.list || [];

	return {
		teams,
		status:
			teams.length && state.status === "loading" ? "idle" : state.status,
		pagination,
		filter: state?.filter || {},
		actions: {
			...actions,
		},
		api,
	};
}

export default useBestPerformingTeams;
