import { Route } from "react-router-dom";
import ProtectedRoute from "src/routes/middlewares/ProtectedRoute";
import LanguageView from "src/views/auth/LanguageView";

const LanguageRoutes = [
	<Route
		element={
			<ProtectedRoute>
				<LanguageView />
			</ProtectedRoute>
		}
		path="/language"
		key="/language"
	/>,
];

export default LanguageRoutes;
