import { ApiForm } from "src/api/types";
import useApi from "src/hooks/api/utils/useApi";

type Options = {
	rememberId?: string;
	filter?: any;
};

function useForm(options?: Options) {
	const { state, actions, api, pagination } = useApi(
		{ id: options?.rememberId || "FORMS" },
		{
			baseUrl: `/client/forms`,
			defaultStatus: "mounted",
			query: options?.filter || {},
		}
	);
	const forms: ApiForm[] = state?.list || [];

	return {
		forms,
		form: state?.data,
		filter: state?.filter,
		status: state.status,
		pagination,
		actions,
		api,
	};
}

export default useForm;
