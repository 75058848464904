import { ComponentProps, useState } from "react";
import { cn } from "src/lib/utils";
import withTranslation, {
	Translation,
} from "src/components/hoc/withTranslation";
import Button from "src/components/Button";
import useOnboarding, {
	OnboardingBoard,
} from "src/hooks/api/services/auth/useOnboarding";
import CreateBoardCard from "src/components/forms/onboarding/elements/CreateBoardCard";
import useAuth from "src/hooks/selectors/useAuth";

const CreateBoards = ({
	t,
	children,
	className,
	...rest
}: Translation & ComponentProps<"div">) => {
	const auth = useAuth();
	const { actions, onboarding, status } = useOnboarding();
	const [boards, setBoards] = useState<OnboardingBoard[]>(() => {
		const boards = onboarding?.boards || [];
		if (!boards.length && auth.type === "student") {
			return [
				{
					name: t("defaults.sport"),
					type: "MANUAL",
					icon: {
						emoji: {
							icon: "🏋️‍♂️",
							description: "man lifting weights",
						},
					},
				},
				{
					name: t("defaults.work"),
					type: "MANUAL",
					icon: {
						emoji: {
							icon: "💼",
							description: "briefcase",
						},
					},
				},
				{
					name: t("defaults.hobby"),
					type: "MANUAL",
					icon: {
						emoji: {
							icon: "🎨",
							description: "artist palette",
						},
					},
				},
			];
		}
		return boards;
	});

	const handleSubmit = () => {
		actions.update({ boards }).then(() => {
			if (auth.type === "student") {
				actions.setStep("completed");
			} else {
				actions.setStep("completed");
			}
		});
	};

	return (
		<div
			{...rest}
			className={cn("flex flex-col flex-1 gap-4 w-full", className)}
		>
			<div className="w-full flex flex-col gap-4 py-4 max-w-md mx-auto flex-1 rounded-md">
				<CreateBoardCard
					{...{ boards }}
					onChange={(boards) => setBoards(boards)}
					defaultOpen
				/>
				{(onboarding?.teams || []).map((team: any) => (
					<CreateBoardCard
						key={team.hid}
						{...{ team, boards }}
						onChange={(boards) => setBoards(boards)}
					/>
				))}
			</div>
			<div className="flex flex-col mt-8 sticky bottom-4">
				<Button
					onClick={handleSubmit}
					disabled={status !== "idle" || !boards.length}
					loading={status === "updating"}
					type="primary"
				>
					{t("continue")}
				</Button>
			</div>
		</div>
	);
};

CreateBoards.locale = {
	nl: {
		continue: "Doorgaan",
		defaults: {
			sport: "Sport",
			work: "Werk",
			hobby: "Hobby",
		},
	},
	en: {
		continue: "Continue",
		defaults: {
			sport: "Sport",
			work: "Work",
			hobby: "Hobby",
		},
	},
};

export default withTranslation(CreateBoards);
