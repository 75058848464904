import { Check, CreditCard, Plus, X } from "lucide-react";
import moment from "moment";
import { ComponentProps, createContext, useContext } from "react";
import { ApiPaymentStatuses } from "src/api/types";
import { useT } from "src/components/hoc/withTranslation";
import { cn } from "src/lib/utils";

interface TrackPaymentItemContextProps {
	date: string | null;
	status: keyof ApiPaymentStatuses;
}

const TrackPaymentItemContext = createContext<TrackPaymentItemContextProps>({
	date: null,
	status: "created",
});

const useTrackPaymentItemContext = () => {
	return useContext(TrackPaymentItemContext);
};

interface TrackPaymentItemProps {
	date: TrackPaymentItemContextProps["date"];
	status: TrackPaymentItemContextProps["status"];
	icon?: React.ReactNode;
	layout?: "default" | "last";
}

const TrackPaymentItem = ({
	date,
	status,
	children,
	icon,
	layout = "default",
	className,
	...rest
}: TrackPaymentItemProps & ComponentProps<"li">) => {
	if (!status) return null;
	return (
		<TrackPaymentItemContext.Provider value={{ date, status }}>
			<li {...rest} className={cn("flex gap-6", className)}>
				<div className="flex flex-col gap-2 justify-center items-center w-10 pb-2">
					<div
						className={cn(
							"w-10 h-10 rounded-md flex justify-center items-center",
							date && "bg-primary text-primary-foreground",
							!date && "bg-border",
							status === "canceled" &&
								date &&
								"bg-red text-[#fff]"
						)}
					>
						{icon || <TrackPaymentItemIcon />}
					</div>
					<hr
						className={cn(
							"flex-1 border border-border rounded-full",
							layout === "last" && "opacity-0",
							status === "canceled" && "border-red border-dashed"
						)}
					/>
				</div>
				<div className="flex-1 flex flex-col pb-6">{children}</div>
			</li>
		</TrackPaymentItemContext.Provider>
	);
};

export const TrackPaymentItemLabel = ({
	className,
	children,
	...rest
}: ComponentProps<"span">) => {
	const { status } = useTrackPaymentItemContext();
	const t = useT({
		nl: {
			created: "Betaling aangemaakt",
			reserved: "Betaling ontvangen op tussenrekening",
			completed: "Betaling uitbetaald",
			canceled: "Betaling geannuleerd",
		},
		en: {
			created: "Payment created",
			reserved: "Payment received on reserve account",
			completed: "Payment paid out",
			canceled: "Payment canceled",
		},
	});
	return (
		<span {...rest} className={cn("", className)}>
			{children || t(status)}
		</span>
	);
};

export const TrackPaymentItemDescription = ({
	className,
	children,
	...rest
}: ComponentProps<"span">) => {
	const { status } = useTrackPaymentItemContext();
	const t = useT({
		nl: {
			created:
				"De betaling is aangemaakt door de opdrachtgever, de betaling is nog niet voldaan.",
			reserved:
				"De betaling is overgemaakt naar de tussenrekening van {{tenant.name}}, de opdrachtgever kan d.m.v. een review de betaling uitbetalen.",
			completed:
				"De betaling is uitbetaald, de betaling staat gemiddeld binnen 24 uur op je rekening.",
			canceled:
				"De opdrachtgever heeft de betaling geannuleerd, neem contact op met de opdrachtgever indien dit nog niet is gecommuniceerd.",
		},
		en: {
			created:
				"The payment is created by the customer, the payment is not yet paid.",
			reserved:
				"The payment is transferred to the reserve account of {{tenant.name}}, the customer can pay out the payment by means of a review.",
			completed:
				"The payment is paid out, the payment is usually within 24 hours on your account.",
			canceled:
				"The customer has canceled the payment, contact the customer if this has not yet been communicated.",
		},
	});
	return (
		<span {...rest} className={cn("opacity-70", className)}>
			{children || t(status)}
		</span>
	);
};

export const TrackPaymentItemDate = ({
	className,
	children,
	...rest
}: ComponentProps<"span">) => {
	const { date } = useTrackPaymentItemContext();
	const t = useT({
		nl: {
			"no-date": "Nog niet gedaan",
		},
		en: {
			"no-date": "Not done yet",
		},
	});
	return (
		<span
			{...rest}
			className={cn("opacity-50", !date && "opacity-30", className)}
		>
			{date ? moment(date).format("DD-MM-YYYY HH:mm") : t("no-date")}
		</span>
	);
};

export const TrackPaymentItemIcon = () => {
	const { status } = useTrackPaymentItemContext();

	if (status === "created") {
		return <Plus path="currentColor" />;
	}

	if (status === "reserved") {
		return <CreditCard path="currentColor" />;
	}

	if (status === "completed") {
		return <Check path="currentColor" />;
	}

	if (status === "canceled") {
		return <X path="currentColor" />;
	}

	return null;
};

export default TrackPaymentItem;
