import { useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import useTenant from "src/hooks/api/services/tenants/useTenant";
import useAuth from "src/hooks/selectors/useAuth";
import useSubscription from "./selectors/useSubscription";

const useRedirect = () => {
	const { auth } = useAuth();
	const navigate = useNavigate();
	const location = useLocation();
	const subscription = useSubscription();
	const isChargeback = subscription?.payment?.status === "chargeback";
	const refreshPath = `/payments/${subscription?.payment?.hid}/refresh`;
	const { tenant } = useTenant();

	useEffect(() => {
		if (location.pathname.startsWith("/admin")) {
			let p = location.pathname.replace("/admin", "");
			navigate(p + location.search);
		} else if (location.pathname === "/") {
			if (isChargeback) {
				navigate(refreshPath);
			} else {
				navigate(`/dashboard${location.search}`);
			}
		} else if (location.pathname.includes("/opdracht/")) {
			window.location.assign(
				tenant?.urls?.public + `${location.pathname}${location.search}`
			);
		}

		if (
			(location.pathname.startsWith("/signup") ||
				location.pathname.startsWith("/forgot-password")) &&
			auth?.id
		) {
			navigate("/dashboard");
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [location]);
};

export default useRedirect;
