import { useTranslation } from "react-i18next";
import ApiCall from "src/components/ApiCall";
import Button from "src/components/Button";
import Card from "src/components/Card";
import Loader from "src/components/Loader";
import OfferSummaryGroup from "src/components/offer/layout/OfferSummaryGroup";
import ReadMore from "src/components/ReadMore";
import useCreateOffer from "src/hooks/api/useCreateOffer";
import { formatPrice } from "src/lib/formatters";

const OfferSummaryCard = () => {
	const { t } = useTranslation("offer", {
		keyPrefix: "offer-summary-card",
	});
	const { offer, setCurrent, current, handleCreate, creating } =
		useCreateOffer();
	const duration = parseFloat(offer?.estimated_duration).toString();

	return (
		<Card className=" ">
			<h2 className="text-[24px] mb-4">{offer.title}</h2>
			<div className="flex flex-col gap-4">
				<OfferSummaryGroup
					title={t("description.intro")}
					onEdit={() => setCurrent(1)}
				>
					<ReadMore
						text={
							offer?.description_intro ||
							offer?.description?.intro
						}
						length={300}
						className="mb-0"
					/>
				</OfferSummaryGroup>
				<OfferSummaryGroup
					title={t("description.what")}
					onEdit={() => setCurrent(1)}
				>
					<ReadMore
						text={
							offer?.description_what || offer?.description?.what
						}
						length={300}
						className="mb-0"
					/>
				</OfferSummaryGroup>
				<OfferSummaryGroup
					title={t("description.how")}
					onEdit={() => setCurrent(1)}
				>
					<ReadMore
						text={offer?.description_how || offer?.description?.how}
						length={300}
						className="mb-0"
					/>
				</OfferSummaryGroup>

				<OfferSummaryGroup
					title={t("visibility.title")}
					onEdit={() => setCurrent(2)}
				>
					<OfferSummaryGroup.Item label={t("visibility.category")}>
						{offer?.category_id ? (
							<ApiCall query={`/categories/${offer.category_id}`}>
								{(data, loading) => (
									<>
										{loading && <Loader />}
										{data?.name ||
											t("visibility.category-not-found")}
									</>
								)}
							</ApiCall>
						) : (
							offer?.category_name ||
							t("visibility.category-not-found")
						)}
					</OfferSummaryGroup.Item>
					<OfferSummaryGroup.Item label={t("visibility.everybody")}>
						{offer.is_private ? t("no") : t("yes")}
					</OfferSummaryGroup.Item>
					<OfferSummaryGroup.Item
						label={t("visibility.show-profile")}
					>
						{offer.is_anonymous ? t("no") : t("yes")}
					</OfferSummaryGroup.Item>
				</OfferSummaryGroup>
				<OfferSummaryGroup
					title={t("budget.title")}
					onEdit={() => setCurrent(3)}
				>
					<OfferSummaryGroup.Item label={t("budget.budget")}>
						{formatPrice(offer.budget)}
					</OfferSummaryGroup.Item>
					<OfferSummaryGroup.Item
						label={t("budget.estimated-duration")}
					>
						{offer?.estimated_duration
							? `${duration} ${t("budget.hour")}`
							: t("unknown")}
					</OfferSummaryGroup.Item>
				</OfferSummaryGroup>
			</div>
			<div className="flex flex-col-reverse md:flex-row justify-between gap-4 mt-6">
				<Button
					disabled={creating}
					type="gray"
					onClick={() => setCurrent(current - 1)}
				>
					<i className="fas fa-arrow-left mr-2"></i>
					{t("previous-step")}
				</Button>
				<Button
					type="primary"
					loading={creating && creating === "PUBLISHED"}
					onClick={() => {
						if (!creating) {
							handleCreate();
						}
					}}
				>
					{!creating && creating === "PUBLISHED" && (
						<i className="fas fa-check mr-2"></i>
					)}
					{t("publish")}
				</Button>
			</div>
			<div className="flex justify-center items-center gap-2 mt-6 text-primary">
				{creating && creating === "CONCEPT" && <Loader />}

				<strong
					onClick={() => {
						if (!creating) {
							handleCreate("CONCEPT");
						}
					}}
					className={`flex cursor-pointer ${
						creating && creating === "PUBLISHED"
							? "opacity-30 cursor-not-allowed"
							: ""
					}`}
				>
					{t("concept")}
				</strong>
			</div>
		</Card>
	);
};

export default OfferSummaryCard;
