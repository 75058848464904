import React from "react";
import { useTranslation } from "react-i18next";
import Grid from "src/components/Grid";

const ReferralTutorial = () => {
	const { t } = useTranslation("share-network", {
		keyPrefix: "share-referral-tutorial",
	});
	return (
		<Grid columns={3}>
			<TutorialItem title={t("create.title")} icon="fas fa-share-square">
				{t("create.description")}
			</TutorialItem>
			<TutorialItem title={t("share.title")} icon="fas fa-users">
				{t("share.description")}
			</TutorialItem>
			<TutorialItem title={t("paid.title")} icon="fas fa-sack-dollar">
				{t("paid.description")}
			</TutorialItem>
		</Grid>
	);
};

interface TutorialItemProps {
	icon: string;
	children?: any;
	title: string;
}

const TutorialItem = ({ children, icon, title }: TutorialItemProps) => {
	return (
		<div className="text-center flex items-center gap-4 flex-col">
			<div
				style={{ backgroundColor: "#1aaa9d30" }}
				className="text-primary w-24 h-24 flex items-center justify-center rounded-full"
			>
				<i className={`${icon} text-2xl`}></i>
			</div>
			<div className="text-center">
				<strong className="mb-2">{title}</strong>
				<p className="text-center mb-0">{children}</p>
			</div>
		</div>
	);
};

export default ReferralTutorial;
