import React from "react";
import { ApiEmoji } from "src/api/types";
import Icon from "src/components/icon/Icon";

interface IconListProps {
	selected: ApiEmoji;
	data: ApiEmoji[];
	onSelect?: (item: ApiEmoji) => void;
}

const IconList = ({ selected, data, onSelect }: IconListProps) => {
	return (
		<>
			{data.map((item) => (
				<Icon
					key={item.description}
					onClick={onSelect ? () => onSelect(item) : undefined}
					iconFontSize={16}
					variant={
						item.description === selected?.description
							? "active"
							: undefined
					}
					icon={{
						emoji: item,
					}}
				/>
			))}
		</>
	);
};

export default IconList;
