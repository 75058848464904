import Layout from "src/components/layout/Layout";
import Card from "src/components/Card";
import Button from "src/components/Button";
import ShareTask from "src/components/promotion/share/ShareTask";
import useQueryString from "src/hooks/useQueryString";
import useGet from "src/hooks/rest/useGet";
import { useTranslation } from "react-i18next";
import usePermissions from "src/hooks/api/services/auth/usePermissions";

export default function TasksCompletedView() {
	const permissions = usePermissions();
	const { t } = useTranslation("task-view", {
		keyPrefix: "thanks-view",
	});
	const queryString = useQueryString();
	const [{ data: task }, loading] = useGet(
		queryString?.hid ? `/company/tasks/${queryString.hid}` : undefined
	);
	const isConcept = task?.type !== "PUBLISHED";
	return (
		<Layout {...{ loading }} title={t("title")}>
			<div className="container-small">
				<Card>
					<div className="flex flex-col gap-8">
						<h2 className="mb-0">{t("card.title")}</h2>

						{!isConcept && (
							<div className="flex flex-col gap-2">
								<p className="mb-0">{t("card.share")}</p>
								<ShareTask
									variant="buttons"
									budget={task?.budget}
									task_id={task?.slug || ""}
									description={task?.description}
									title={task?.title || ""}
									url={task?.url}
								/>
							</div>
						)}
						{isConcept && <strong>{t("card.concept")}</strong>}
						<div className="flex gap-4 flex-wrap items-center">
							{permissions.has("tasks.create") && (
								<Button to="/tasks/create">
									{t("card.buttons.create")}
								</Button>
							)}
							<Button to="/tasks" type="dark">
								{t("card.buttons.back")}
							</Button>
						</div>
					</div>
				</Card>
			</div>
		</Layout>
	);
}
