import React from "react";
import CountUp from "react-countup";
import "./money-counter.scss";
import Card from "src/components/Card";

const MoneyCounter = ({
	label,
	price,
	loading,
}: {
	label: string;
	price?: number;
	loading?: boolean;
}) => {
	const renderChild = () => {
		if (loading) return <span className="base-loader"></span>;
		if (!price) return <span>&euro; 0,00</span>;
		return (
			<CountUp
				prefix="€ "
				end={price}
				decimals={2}
				separator="."
				decimal=","
			/>
		);
	};
	return (
		<Card className="money-counter" type="small-padding">
			<strong>{label}</strong>
			<div className="price">{renderChild()}</div>
		</Card>
	);
};

export default MoneyCounter;
