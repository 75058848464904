import React from "react";
import { ApiTimesheet } from "src/api/types";
import withTranslation, {
	Translation,
} from "src/components/hoc/withTranslation";
import useAuth from "src/hooks/selectors/useAuth";

interface TimesheetDescriptionProps extends Translation {
	timesheet: ApiTimesheet;
	className?: string;
}

const TimesheetDescription = ({
	t,
	timesheet,
	className,
}: TimesheetDescriptionProps) => {
	const auth = useAuth();
	if (!timesheet.start || !timesheet.end) {
		return <></>;
	}
	return (
		<p className={`${className}`}>
			{t(
				timesheet.break
					? `${auth.type}.description-with-break`
					: `${auth.type}.description-no-break`,
				{
					start: timesheet.start,
					end: timesheet.end,
					break: timesheet.break,
					count: timesheet.total,
				}
			)}
		</p>
	);
};

TimesheetDescription.locale = {
	nl: {
		student: {
			"description-no-break":
				"Je hebt gewerkt van {{start}} tot {{end}} zonder pauze, in totaal heb je {{count}} uur gewerkt",
			"description-with-break":
				"Je hebt gewerkt van {{start}} tot {{end}} en je hebt {{break}} pauze uur gehouden, in totaal heb je {{count}} uur gewerkt",
		},
		company: {
			"description-no-break":
				"Van {{start}} tot {{end}} zonder pauze, is totaal {{count}} uur",
			"description-with-break":
				"Van {{start}} tot {{end}} met een pauze van {{break}} uur, is totaal {{count}} uur",
		},
	},
	en: {
		student: {
			"description-no-break_one":
				"You have worked from {{start}} till {{end}} without a break, in total you have worked {{count}} hour",
			"description-no-break_other":
				"You have worked from {{start}} till {{end}} without a break, in total you have worked {{count}} hour",
			"description-with-break_one":
				"You have worked from {{start}} till {{end}} with a break of {{break}} hour(s), in total you have worked {{count}} hour",
			"description-with-break_other":
				"You have worked from {{start}} till {{end}} with a break of {{break}} hour(s), in total you have worked {{count}} hour",
		},
		company: {
			"description-no-break":
				"From {{start}} till {{end}} without a break, is a total of {{count}} hour(s)",
			"description-with-break":
				"From {{start}} till {{end}} with a break of {{break}} hour(s), is a total of {{count}} hour(s)",
		},
	},
};

export default withTranslation(TimesheetDescription);
