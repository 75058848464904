import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Card from "src/components/Card";
import Loader from "src/components/Loader";
import withTranslation, {
	Translation,
} from "src/components/hoc/withTranslation";
import Layout from "src/components/layout/Layout";
import BoardForm from "src/components/tasks/boards/BoardForm";
import useBoards from "src/hooks/api/services/tasks/useBoards";
import useBoardForm from "src/hooks/api/tasks/useBoardForm";
import useTasksFilter from "src/hooks/api/tasks/useTasksFilter";
import { getRandomEmoji } from "src/hooks/data/useEmoji";
import useQueryString from "src/hooks/useQueryString";

const CreateBoardView = ({ t }: Translation) => {
	const [status, setStatus] = useState("loading");
	const navigate = useNavigate();
	const qs = useQueryString();
	const { filterState: filter, actions } = useTasksFilter();
	const { actions: boardsActions } = useBoards();
	const { board, isDisabled, actions: boardFormActions } = useBoardForm();
	const team = qs?.team;

	useEffect(() => {
		if (qs.filter) {
			const data = JSON.parse(atob(qs?.filter?.toString() || ""));
			actions.set(data, "reset");
		} else {
			actions.set({}, "reset");
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		boardFormActions.set(
			{
				name: boardFormActions.generateDefaultName(filter),
				users: [],
				icon: {
					emoji: getRandomEmoji(),
				},
				team,
			},
			"reset"
		);
		setStatus("idle");
		return () => {
			setStatus("loading");
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [team]);

	const onSubmit = () => {
		if (isDisabled) return;

		boardsActions
			.create({
				...board,
				filter: {
					assignees: [],
					board: undefined,
					creator_id: undefined,
					match_student_id: undefined,
					q: "",
					tags: filter?.tags ? filter.tags : [],
					teams: [],
					type: filter?.type,
				},
			})
			.then((data) => {
				if (data?.slug) {
					boardsActions.set((state: any) => ({
						...state,
						list: [...state.list, data],
					}));
					navigate(`/tasks/boards/${data.slug}`);
				}
			});
	};
	return (
		<Layout backUrl="/tasks" title={t("title")}>
			{status === "loading" && (
				<div className="flex flex-col flex-1 justify-center items-center">
					<Loader />
				</div>
			)}
			{status === "idle" && (
				<div className="container-small">
					<Card>
						<BoardForm {...{ onSubmit }} />
					</Card>
				</div>
			)}
		</Layout>
	);
};

CreateBoardView.locale = {
	nl: {
		title: "Bord aanmaken",
	},
	en: {
		title: "Create board",
	},
};

export default withTranslation(CreateBoardView);
