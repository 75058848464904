import React from "react";
import { useTranslation } from "react-i18next";
import { LayoutVariants } from "src/api/types";
import Button from "src/components/Button";
import FlatList from "src/components/FlatList";
import SignatureCardItem from "src/components/signer/signature/SignatureCardItem";
import useGet from "src/hooks/rest/useGet";
import useSignature from "src/hooks/selectors/useSignature";
import useWindowSize from "src/hooks/useWindowSize";

interface SignatureGridProps {
	variant: LayoutVariants;
	onSubmit: () => void;
	onDirectSubmit: () => void;
}

const SignatureGrid = ({
	variant,
	onSubmit,
	onDirectSubmit,
}: SignatureGridProps) => {
	const { t } = useTranslation("envelope", {
		keyPrefix: "signature-grid",
	});
	const { signature } = useSignature();
	const size = useWindowSize();
	const [{ data, hasMore }, loading, { fetchNext }] = useGet(
		`/signatures`,
		{
			data: [],
		},
		true
	);

	const texts = {
		title: t("empty.title"),
	};

	return (
		<>
			<FlatList
				empty={texts as any}
				{...{ hasMore, data, loading }}
				loadingClassName="py-8"
				columns={2}
				gap={8}
				onNext={async () => await fetchNext()}
				renderItem={(item) => {
					return (
						<SignatureCardItem
							{...{ variant }}
							signature={item}
							key={`signature-${item.id}`}
						/>
					);
				}}
			/>
			{!loading && data?.length >= 1 && signature?.id && (
				<div
					className={`sticky flex left-0 right-0 bottom-0 px-2 py-4 rounded-t-md ${
						variant === "dark" ? "bg-dark" : "bg-background"
					}`}
				>
					{size.isTablet ? (
						<Button
							onClick={() => {
								onSubmit();
								onDirectSubmit();
							}}
							type="primary"
							className="flex-1"
						>
							<i className="fas fa-check mr-2"></i>

							{t("button.sign")}
						</Button>
					) : (
						<Button
							onClick={() => onSubmit()}
							type="primary"
							className="flex-1"
						>
							{t("button.select")}
						</Button>
					)}
				</div>
			)}
		</>
	);
};

export default SignatureGrid;
