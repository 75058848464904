import { motion } from "framer-motion";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import Avatar from "src/components/Avatar";
import SelectSearch from "src/components/field/utils/SelectSearch";
import FormGroup, { FormGroupLayout } from "src/components/form/FormGroup";
import {
	Command,
	CommandEmpty,
	CommandGroup,
	CommandItem,
} from "src/components/ui/command";
import {
	Popover,
	PopoverContent,
	PopoverTrigger,
} from "src/components/ui/popover";
import useElementSize from "src/hooks/useElementSize";
import { cn } from "src/lib/utils";

export type Option = {
	avatar?: string;
	name: string;
	id: number | string;
};

export interface DropdownProps {
	label?: string;
	value?: number | string;
	onChange: (value?: number | string) => void;
	className?: string;
	placeholder?: string;
	options: Option[];
	loading?: boolean;
	error?: string | boolean;
	valid?: string | boolean;
	help?: string;
	isSearch?: boolean;
	onSearch?: (q: string) => void;
	hasAvatar?: boolean;
	noMarginBottom?: boolean;
	renderIcon?: (options: Option) => any;
	direction?: "up" | "down";
	isClearable?: boolean;
	layout?: FormGroupLayout;
	disabled?: boolean;
	avatarClassName?: string;
}

const Dropdown = ({
	label,
	className,
	options: _options,
	value,
	placeholder,
	onChange,
	valid,
	error,
	help,
	isSearch,
	onSearch,
	hasAvatar,
	noMarginBottom,
	renderIcon,
	isClearable = true,
	layout = "stacked",
	disabled,
	avatarClassName,
}: DropdownProps) => {
	const { t } = useTranslation("form");
	const [visible, setVisible] = useState(false);
	const [search, setSearch] = useState("");
	const trigger = useElementSize();
	const item = useMemo(() => {
		return _options.find((o: any) => o.id === value);
	}, [value, _options]);

	const options = useMemo(() => {
		if (!search && !isSearch) {
			return _options;
		}
		return _options.filter((o) =>
			o.name.toLowerCase().includes(search.trim().toLowerCase())
		);
	}, [_options, isSearch, search]);

	const handleReset = (e: any) => {
		e?.preventDefault();
		e?.stopPropagation();
		if (onSearch) {
			onSearch("");
		}
		setSearch("");
		onChange(undefined);
		setVisible(false);
	};

	return (
		<FormGroup
			{...{
				label,
				valid,
				help,
				error,
				noMarginBottom,
				layout,
				className,
			}}
			name={label}
		>
			<Popover
				open={visible}
				onOpenChange={(v) => {
					setVisible(v);
				}}
			>
				<PopoverTrigger asChild>
					<button
						ref={trigger.ref}
						className={cn(
							"py-2 px-3 border-border border rounded-[6px] h-[42px] dark:bg-accent bg-transparent w-full transition-all flex items-center gap-2",
							!value
								? "text-placeholder"
								: "text-accent-foreground",
							disabled && "opacity-40 cursor-not-allowed"
						)}
						onClick={(e) => {
							e.stopPropagation();
						}}
					>
						{value && item?.avatar && hasAvatar && (
							<Avatar
								src={item.avatar}
								className={cn("w-8 h-8", avatarClassName)}
								alt={item.name}
							/>
						)}
						<div className="flex-1 text-left text-[16px] leading-full line-clamp-1">
							{value ? (
								<span>{item?.name}</span>
							) : (
								<span>{placeholder}</span>
							)}
						</div>

						<div className="flex items-center gap-3 text-[14px] text-placeholder">
							{isClearable && value && (
								<div
									onClick={handleReset}
									className="w-5 h-5 rounded-sm transition-all"
								>
									<i className="fal fa-times cursor-pointer"></i>
								</div>
							)}
							<div className="w-5 h-5 rounded-sm transition-all">
								<motion.div
									animate={visible ? "open" : "closed"}
									variants={{
										open: {
											rotate: 180,
										},
									}}
								>
									<i className="fas fa-caret-down"></i>
								</motion.div>
							</div>
						</div>
					</button>
				</PopoverTrigger>
				<PopoverContent
					align="start"
					className="w-full py-0 px-0 z-[999] bg-accent text-accent-foreground"
					style={{
						width: trigger.size.width,
					}}
					onClick={(e) => {
						e.stopPropagation();
					}}
				>
					<Command shouldFilter={false}>
						<div className="flex relative">
							{(isSearch || onSearch) && (
								<SelectSearch
									className="w-full"
									value={search}
									onChange={(search) => {
										if (isSearch) {
											setSearch(search);
										}
										if (onSearch) {
											onSearch(search);
											setSearch(search);
										}
									}}
								/>
							)}
						</div>
						<CommandEmpty>
							<p className="opacity-20">
								{t("dropdown.empty.description")}
							</p>
						</CommandEmpty>
						<CommandGroup className="max-h-[240px] overflow-auto">
							{(options || []).map((option) => (
								<CommandItem
									key={`option-${option.id}`}
									value={option.id?.toString()}
									onSelect={() => {
										onChange(option.id);
										setVisible(false);
									}}
									className="px-3 py-2 text-[16px] justify-between gap-3"
								>
									{hasAvatar && (
										<>
											{option.avatar && renderIcon ? (
												renderIcon(option)
											) : (
												<Avatar
													src={option.avatar}
													className={cn(
														"w-8 h-8",
														avatarClassName
													)}
													alt={option.name}
												/>
											)}
										</>
									)}
									<span className="flex-1">
										{option.name}
									</span>
									<div className="ml-2 w-4 h-4 flex justify-center items-center">
										<i
											className={cn(
												"far fa-check text-sm transition-all opacity-0",
												value?.toString() ===
													option.id.toString() &&
													"opacity-100"
											)}
										></i>
									</div>
								</CommandItem>
							))}
						</CommandGroup>
					</Command>
				</PopoverContent>
			</Popover>
		</FormGroup>
	);
};

export default Dropdown;
