const resume = {
	"preview-view": {
		title: "My resume",
	},
	"edit-avatar-modal": {
		title: "Profile picture",
	},
	"edit-basic-modal": {
		steps: {
			basic: "Phone number & birthdate",
			address: "Address",
		},
		buttons: {
			cancel: "Cancel",
			continue: "Continue",
		},
		title: "Basic information",
		description:
			"We need this information to later create the contracts for the assignments. We need your address, birthdate & phone number.",
	},
	"edit-experience-modal": {
		job: {
			title: "Add work experience",
			"title-edit": "Edit work experience",
			success: "Work experience added successfully",
			form: {
				title: "Company name",
				subtitle: "Position",
			},
		},
		education: {
			title: "Add education",
			"title-edit": "Edit education",
			success: "Education added successfully",
			form: {
				title: "Education",
				subtitle: "School",
			},
		},
		form: {
			started: "Year started",
			ended: "Year ended (leave empty if active)",
		},
		buttons: {
			edit: "Edit",
			add: "Add",
			cancel: "Cancel",
			delete: "Delete",
		},
	},
	"edit-experiences-view": {
		tip: "Describe your work experience or education as thoroughly as possible. Companies love this!",
		title: "My experiences",
		breadcrumb: {
			"my-profile": "My profile",
			experiences: "Experiences",
		},
		education: "Education / courses",
		job: "Work experience",
	},
	"edit-extra-view": {
		breadcrumb: {
			"my-profile": "My profile",
			extra: "Bio, skills & socials",
		},
		title: "Bio, skills & socials",
		cards: {
			bio: "Bio",
			skills: "Skills",
			socials: "Social media",
		},
	},
	"edit-interests-modal": {
		title: "Interests",
		description:
			"Choose a few categories you are interested in. Can't find anything? Fill in the field you would like to work in.",
		buttons: {
			cancel: "Cancel",
			save: "Save",
		},
	},
	"theme-modal": {
		title: "Change CV theme",
		button: "Save",
	},
};

export default resume;
