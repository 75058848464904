const task = {
	tabs: {
		task: "Task",
		editor: "Editor",
		general: "General",
		registration: "Registration",
		registrations: "Registrations",
		invites: "Invitations",
		statistics: "Statistics",
		edit: "Edit",
		hours: "Hour validation",
		shifts: "Shifts",
		files: "Files",
	},
	"thanks-view": {
		title: "Task Posted",
		card: {
			title: "Thanks for posting the task!",
			share: "Share the task in your own network to get more registrations",
			concept: "Edit or publish the concept later from the task overview",
			buttons: {
				create: "Post another task",
				back: "Back to tasks",
			},
		},
	},
	"create-view": {
		"title-create": "Create Task",
		"title-edit": "Edit Task",
		"no-subscription": {
			title: "You can't create tasks yet",
			description:
				"In order to create tasks you first need an active subscription.",
			button: "Choose subscription",
		},
		breadcrumb: {
			tasks: "Tasks",
			create: "Create Task",
		},
	},
	"edit-view": {
		title: "Edit Task",
		error: {
			title: "Something went wrong",
		},
		disabled: {
			title: "The task can no longer be modified",
			description:
				"Since the task has already been completed, it is no longer possible to change it.",
		},
		cards: {
			description: {
				title: "Task description",
			},
			budget: {
				title: "Task Value",
				description:
					"The task value can only be changed if there are no registrations and no payment has been made for the task.",
			},
			location: {
				title: "Location",
			},
			date: {
				title: "Start and end date",
			},
			form: {
				title: "Registration form",
			},
			delete: {
				title: "Delete",
			},
			visibility: {
				title: "Visibility",
			},
		},
	},
	"detail-view": {
		empty: {
			title: "No task found",
		},
		"not-selected": {
			title: "You have not been chosen yet",
			description:
				"Unfortunately you have not yet been chosen for the task.",
		},
	},
};

export default task;
