const support = {
	"support-help-card": {
		employee: "Een medewerker van {{tenant.name}}",
		description: "{{name}} helpt je hier graag mee verder.",
		button: {
			call: "Direct bellen",
			mail: "Stuur een mail",
		},
	},
};

export default support;
