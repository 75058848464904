import Card from "src/components/Card";
import withTranslation, {
	Translation,
} from "src/components/hoc/withTranslation";
import Layout from "src/components/layout/Layout";
import StickyWrapper from "src/components/layout/StickyWrapper";
import AnalyseVacancyForm, {
	AnalyseVacancyFormField,
	AnalyseVacancyFormSubmitButton,
} from "src/components/vacancies/vacancy-form/steps/analyse-vacancy-form";
import CheckVacancyForm, {
	CheckVacancyFormAccordion,
	CheckVacancyFormAddressFields,
	CheckVacancyFormDescriptionFields,
	CheckVacancyFormSalaryField,
	CheckVacancyFormSalaryTypeDropdown,
	CheckVacancyFormSubmitButton,
	CheckVacancyFormTitleField,
} from "src/components/vacancies/vacancy-form/steps/check-vacancy-form";
import PublishVacancyForm, {
	PublishVacancyFormAddress,
	PublishVacancyFormChooseCreditList,
	PublishVacancyFormDescription,
	PublishVacancyFormSalary,
	PublishVacancyFormSection,
	PublishVacancyFormSubmitButton,
} from "src/components/vacancies/vacancy-form/steps/publish-vacancy-form";
import VacancyForm from "src/components/vacancies/vacancy-form/vacancy-form";
import VacancyFormSteps, {
	ActiveVacancyFormStep,
	VacancyFormStep,
} from "src/components/vacancies/vacancy-form/vacancy-form-steps";
import useQueryString from "src/hooks/useQueryString";

const CreateVacancyView = ({ t }: Translation) => {
	const qs = useQueryString();

	return (
		<Layout
			title={t("title")}
			breadcrumb={[
				{ label: t("vacancies"), to: "/vacancies" },
				{ label: t("title"), to: "/vacancies/create" },
			]}
			container="xxl"
		>
			<VacancyForm
				className="gap-8 md:mt-4"
				vacancyId={qs.vacancy_id?.toString() || undefined}
			>
				<VacancyFormSteps className="flex-col items-start md:items-center md:flex-row">
					<VacancyFormStep name="submit" />
					<VacancyFormStep name="check" />
					<VacancyFormStep name="publish" />
				</VacancyFormSteps>
				<ActiveVacancyFormStep name="submit">
					<AnalyseVacancyForm className="md:flex-row gap-4">
						<Card className="flex-1">
							<AnalyseVacancyFormField />
						</Card>
						<div className="flex flex-col min-w-[340px]">
							<StickyWrapper top={100}>
								<Card>
									<strong>{t("analyse.title")}</strong>
									<AnalyseVacancyFormSubmitButton />
								</Card>
							</StickyWrapper>
						</div>
					</AnalyseVacancyForm>
				</ActiveVacancyFormStep>
				<ActiveVacancyFormStep name="check">
					<CheckVacancyForm className="md:flex-row gap-4">
						<Card className="flex-1">
							<CheckVacancyFormTitleField />
							<CheckVacancyFormAccordion name="description">
								<CheckVacancyFormDescriptionFields />
							</CheckVacancyFormAccordion>
							<CheckVacancyFormAccordion name="salary">
								<div className="flex flex-col gap-3">
									<CheckVacancyFormSalaryTypeDropdown />
									<CheckVacancyFormSalaryField name="min" />
									<CheckVacancyFormSalaryField name="max" />
								</div>
							</CheckVacancyFormAccordion>
							<CheckVacancyFormAccordion name="address">
								<CheckVacancyFormAddressFields />
							</CheckVacancyFormAccordion>
						</Card>
						<div className="flex flex-col min-w-[340px]">
							<StickyWrapper top={100}>
								<Card>
									<strong>{t("publish-submit.title")}</strong>
									<CheckVacancyFormSubmitButton />
								</Card>
							</StickyWrapper>
						</div>
					</CheckVacancyForm>
				</ActiveVacancyFormStep>
				<ActiveVacancyFormStep name="publish">
					<PublishVacancyForm>
						<Card className="max-w-xl">
							<div className="flex flex-col gap-2">
								<h2>{t("publish.title")}</h2>
								<p>{t("publish.description")}</p>
							</div>
							<PublishVacancyFormSection name="description">
								<PublishVacancyFormDescription />
							</PublishVacancyFormSection>
							<PublishVacancyFormSection name="salary">
								<PublishVacancyFormSalary />
							</PublishVacancyFormSection>
							<PublishVacancyFormSection name="address">
								<PublishVacancyFormAddress />
							</PublishVacancyFormSection>

							<PublishVacancyFormChooseCreditList />
							<PublishVacancyFormSubmitButton />
						</Card>
					</PublishVacancyForm>
				</ActiveVacancyFormStep>
			</VacancyForm>
		</Layout>
	);
};

CreateVacancyView.locale = {
	nl: {
		vacancies: "Vacatures",
		title: "Vacature aanmaken",
		analyse: {
			title: "Vacature analyseren",
		},
		"publish-submit": {
			title: "Vacature publiceren",
		},
		publish: {
			title: "Klaar om je vacature te delen met de wereld?",
			description:
				"We hebben genoeg informatie van je ontvangen om de vacature te matchen met onze pool van (Young) Professionals. Controleer nog één keer de gegevens. ",
		},
	},
	en: {
		title: "Create vacancy",
		analyse: {
			title: "Analyse vacancy",
		},
		"publish-submit": {
			title: "Publish vacancy",
		},
		publish: {
			title: "Ready to share your vacancy with the world?",
			description:
				"We have received enough information from you to match the vacancy with our pool of (Young) Professionals. Check one more time the data.",
		},
	},
};

export default withTranslation(CreateVacancyView);
