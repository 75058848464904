import useTenant from "src/hooks/api/services/tenants/useTenant";
import useTaskForm from "src/hooks/api/tasks/useTaskForm";
import { useUpdateEffectDebounce } from "src/hooks/useDebounce";

export default function useTaskFormAutoSave(isDisabled?: boolean) {
	const {
		tenant: { modules },
	} = useTenant();
	const { actions, changes, status } = useTaskForm();

	useUpdateEffectDebounce(changes, 500, () => {
		if (
			Object.keys(changes).length &&
			status === "idle" &&
			!isDisabled &&
			modules.includes("intern-task")
		) {
			actions.save({
				disableAlert: true,
				disableSetState: true,
			});
		}
	});
}
