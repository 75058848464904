import { useTranslation } from "react-i18next";
import Button from "src/components/Button";
import Input from "src/components/form/Input";
import Switch from "src/components/form/Switch";
import { Answer } from "src/components/forms/QuestionForm/QuestionForm";

interface AnswerFormProps {
	input?: {
		label?: string;
	};
	value: Answer;
	onChange: (value?: Answer) => void;
	onEnter?: () => void;
}

const AnswerForm = ({ input, value, onChange, onEnter }: AnswerFormProps) => {
	const { t } = useTranslation("forms", {
		keyPrefix: "answer-form",
	});
	return (
		<div className="flex flex-col gap-4">
			<div className="flex items-end gap-2">
				<Input
					noMarginBottom
					{...input}
					autoFocus
					name="answer"
					onChange={(_, answer) => onChange({ ...value, answer })}
					onKeyPress={(e) => {
						if (e.key === "Enter" && onEnter) {
							e.preventDefault();
							onEnter();
						}
					}}
					placeholder={t("answer")}
					value={value.answer}
				/>
				<Button
					onClick={() => onChange(undefined)}
					className="w-11 h-11"
					iconOnly
					type="transparent"
				>
					<i className="fas fa-trash"></i>
				</Button>
			</div>
			<Switch
				label={t("is_right")}
				value={value.is_right}
				onChange={() =>
					onChange({ ...value, is_right: !value.is_right })
				}
			/>
		</div>
	);
};

export default AnswerForm;
