import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import EditAvatar from "src/components/forms/profile/EditAvatar";
import Modal from "src/components/Modal";

const DashboardEditAvatarModal = () => {
	const { t } = useTranslation("resume-view", {
		keyPrefix: "edit-avatar-modal",
	});
	const navigate = useNavigate();
	const onClose = () => {
		navigate("/dashboard");
	};

	return (
		<Modal
			title={t("title")}
			className="max-w-[460px]"
			visible
			{...{ onClose }}
		>
			<EditAvatar onDone={onClose} variant="profile-picture" />
		</Modal>
	);
};

export default DashboardEditAvatarModal;
