import { createAction, handleActions } from "redux-actions";
import { ApiPermission } from "src/api/types";
import getTokenName from "src/lib/getTokenName";
import socket from "src/lib/socket";
export const updateProfile = createAction("UPDATE_PROFILE");
export const logout = createAction("REMOVE_PROFILE");

export const emptyAuthState = {
	id: null,
	full_name: "",
	first_name: "",
	affix: "",
	last_name: "",
	avatar: "",
	email: "",
	company: undefined,
	type: "",
	primary_color: "",
	is_sales: false,
	isLoading: true,
	has_subscription: false,
	mode: "default",
};

type AuthState = {
	id: number | null;
	full_name: string;
	first_name: string;
	affix?: string;
	last_name: string;
	avatar?: string;
	email: string;
	company: any;
	type: string;
	unread_messages?: number;
	primary_color: string;
	is_sales?: boolean;
	isLoading: boolean;
	has_subscription?: boolean;
	mode: "default" | "beta";
	key: string;
	permissions: ApiPermission[];
};

const authReducer = handleActions(
	{
		UPDATE_PROFILE: (state: AuthState, action: any) => {
			if (action.payload && action.payload.token) {
				localStorage.setItem(getTokenName(), action.payload.token);
			}
			if (action.payload.id) {
				socket.emit("loggedin", {
					id: action.payload.id,
				});
			}

			return {
				...state,
				...action.payload,
				isLoading: action?.payload?.id ? false : true,
			};
		},
		REMOVE_PROFILE: () => {
			localStorage.setItem(getTokenName(), "");
			return {} as AuthState;
		},
	},
	{} as AuthState
);
export default authReducer;
