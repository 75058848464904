import React from "react";
import Button from "src/components/Button";
import Card from "src/components/Card";
import withTranslation, {
	Translation,
} from "src/components/hoc/withTranslation";
import Container from "src/components/layout/Container";
import Logo from "src/components/Logo";
import useTenant from "src/hooks/api/services/tenants/useTenant";
import useAuth from "src/hooks/selectors/useAuth";
import useLogout from "src/hooks/useLogout";

function EnableTenantAccessView({ t }: Translation) {
	const auth = useAuth();
	const { tenant, status, actions } = useTenant();
	const { loading: loggingOut, handleLogout } = useLogout();

	return (
		<div className="flex flex-col flex-1 justify-center overflow-hidden">
			<Container variant="xsmall">
				<Card
					className="text-background-foreground z-20 relative"
					bg="white"
				>
					{auth.id && tenant.has_access ? (
						<>
							<h2>{t("already-signed-in.title")}</h2>
							<div className="flex items-center mt-4">
								<Button to="/dashboard">
									{t("already-signed-in.button")}
									<i className="fas fa-arrow-right ml-4"></i>
								</Button>
							</div>
						</>
					) : !auth?.id ? (
						<>
							<h2>{t("login.title")}</h2>
							<div className="flex items-center mt-4">
								<Button to="/dashboard">
									{t("login.button")}
									<i className="fas fa-arrow-right ml-4"></i>
								</Button>
							</div>
						</>
					) : (
						<>
							<h3>
								{t("enable.title", {
									tenant: tenant.name,
								})}
							</h3>
							<p>
								{t("enable.description", {
									tenant: tenant.name,
								})}
							</p>
							<div className="mt-4 gap-4 flex">
								<Button
									onClick={actions.linkCurrent}
									disabled={loggingOut}
									loading={status !== "idle"}
								>
									{t("enable.button")}
								</Button>
								<Button
									onClick={handleLogout}
									loading={loggingOut}
									type="transparent"
								>
									{t("signout.button")}
								</Button>
							</div>
						</>
					)}
				</Card>
			</Container>
			<div className="absolute right-4 bottom-4 z-10">
				<Logo color="primary" className="w-[300px] block" />
			</div>
		</div>
	);
}

EnableTenantAccessView.locale = {
	nl: {
		title: "Je hebt nog geen toegang",
		enable: {
			title: "Je account is nog niet actief bij {{tenant}}",
			description:
				"Via de onderstaande knop kan je gemakkelijk je account inschakelen bij {{tenant}}",
			button: "Account inschakelen",
		},
		"already-signed-in": {
			title: "Je bent al ingelogd",
			button: "Naar dashboard",
		},
		login: {
			title: "Je bent uitgelogd",
			button: "Inloggen",
		},
		signout: {
			button: "Uitloggen",
		},
	},
	en: {
		title: "You don't have access yet",
		enable: {
			title: "Your account is not yet active at {{tenant}}",
			description:
				"Using the button below, you can easily activate your account at {{tenant}}",
			button: "Activate Account",
		},
		"already-signed-in": {
			title: "You're already logged in",
			button: "Go to dashboard",
		},
		login: {
			title: "You've been logged out",
			button: "Log in",
		},
		signout: {
			button: "Log out",
		},
	},
};

export default withTranslation(EnableTenantAccessView);
