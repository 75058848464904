import { atom, useAtom } from "jotai";
import { useEffect, useState } from "react";
import Confetti from "react-dom-confetti";
import Button from "src/components/Button";
import SkillSelector from "src/components/form/skill-selector/SkillSelector";
import withTranslation, {
	Translation,
} from "src/components/hoc/withTranslation";
import EditLayout, {
	EditLayoutDescription,
	EditLayoutHeader,
	EditLayoutTitle,
} from "src/components/resume/edit-layout/EditLayout";
import SkillValidationStatus from "src/components/resume/elements/skills/SkillValidationStatus";
import {
	Dialog,
	DialogContent,
	DialogOverlay,
	DialogPortal,
	DialogTrigger,
} from "src/components/ui/dialog";
import useResume from "src/hooks/api/services/resume/useResume";
import useValidateSkills from "src/hooks/api/services/resume/useValidateSkills";
import useFetchProfile from "src/hooks/api/useFetchProfile";
import useAuth from "src/hooks/selectors/useAuth";
import useBackUrl from "src/hooks/useBackUrl";
import useModal from "src/hooks/useModal";

const isValidatedAtom = atom(false);

const DashboardValidateSkillsModal = ({ t }: Translation) => {
	const backUrl = useBackUrl({
		type: "modal",
	});
	const modal = useModal();
	const { fetchProfile } = useFetchProfile();
	const [isValidated, setIsValidated] = useAtom(isValidatedAtom);
	const { actions, experiences, statuses } = useValidateSkills();
	const [current, setCurrent] = useState<string>(experiences[0]?.id);
	const auth = useAuth();
	const { resume } = useResume({
		rememberKey: auth.hid,
	});
	const [skills, setSkills] = useState<any[]>(resume.skills?.skills || []);
	const experience = (experiences || []).find(
		(item: any) => item.id === current
	);
	const [isConfirmOpen, setIsConfirmOpen] = useState(false);
	const [showConfetti, setShowConfetti] = useState(false);

	useEffect(() => {
		actions.experiences().then((items) => {
			setCurrent(items[0]?.id);
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const scrollToTop = () => {
		const element = document.querySelector('div[role="dialog"]');
		if (!element) return;
		element.scrollTo({ top: 0, behavior: "smooth" });
	};

	const handleValidateExperience = async () => {
		await actions.validateExperience(experience.id, skills);
		const newExperiences = experiences.filter(
			(item: any) => item.id !== experience.id
		);

		const nextId = newExperiences[0]?.id || "";
		//All experiences are validated
		if (!nextId) {
			await fetchProfile();
			// modal.setTheme("dark");
			setTimeout(() => {
				setShowConfetti(true);
			}, 300);
		} else {
			// modal.setTheme("default");
		}

		scrollToTop();
		actions.setExperiences(newExperiences);
		setCurrent(nextId);
		setIsConfirmOpen(false);
	};

	if (!isValidated) {
		return (
			<EditLayout>
				<EditLayoutHeader className="-top-2 md:-top-8">
					<EditLayoutTitle>{t(`pending.title`)}</EditLayoutTitle>
					<EditLayoutDescription>
						{t(`pending.description`)}
					</EditLayoutDescription>
				</EditLayoutHeader>
				<div className="flex flex-col mt-8 gap-12">
					<SkillValidationStatus
						stayLoading={statuses.experiences !== "idle"}
						onSubmit={() => {
							setIsValidated(true);
							modal.setTheme("default");
						}}
					/>
				</div>
			</EditLayout>
		);
	}

	if (experiences.length === 0 && statuses.experiences === "idle") {
		return (
			<EditLayout className="flex-1 justify-center items-center gap-4">
				<div className="flex flex-col gap-2 text-center pt-16">
					<h1 className="text-xl text-balance">
						{t("completed.title")}
					</h1>
					<p>{t("completed.description")}</p>
				</div>
				<Button type="primary" onClick={() => modal.onClose()}>
					{t("completed.button")}
				</Button>
				<Confetti active={showConfetti} config={{ angle: 135 }} />
				<Confetti active={showConfetti} config={{ angle: 45 }} />
			</EditLayout>
		);
	}

	if (!experience?.is_linked) {
		return (
			<EditLayout>
				<EditLayoutHeader className="-top-2 md:-top-8">
					<div className="flex mb-1 items-center h-[35px]">
						<strong className="text-primary1">
							{t(`experience.${experience?.type}.eyebrow`)}
						</strong>
					</div>

					<EditLayoutTitle>
						{t(`experience.${experience?.type}.not-linked.title`, {
							name: experience?.name,
						})}
					</EditLayoutTitle>
					<EditLayoutDescription>
						{t(
							`experience.${experience?.type}.not-linked.description`,
							{
								name: experience?.name,
							}
						)}
					</EditLayoutDescription>
					{experience?.type && (
						<div className="flex flex-col p-3 rounded-md bg-card mt-8 gap-4">
							<Button
								to={{
									modal: `/dashboard/resume/edit/skills/experiences/${experience.type}/${experience.id}/change?${backUrl}`,
								}}
							>
								{t(`experience.${experience.type}.change`)}
							</Button>
							<Button
								type="border"
								onClick={handleValidateExperience}
							>
								{t(`experience.${experience.type}.skip`)}
							</Button>
						</div>
					)}
				</EditLayoutHeader>
			</EditLayout>
		);
	}

	return (
		<EditLayout>
			<EditLayoutHeader className="-top-2 md:-top-8">
				<div className="flex mb-1 items-center h-[35px]">
					<strong className="text-primary">
						{t(`experience.${experience?.type}.eyebrow`)}
					</strong>
				</div>

				<EditLayoutTitle>
					{t(`experience.${experience?.type}.title`, {
						name: experience?.name,
					})}
				</EditLayoutTitle>
			</EditLayoutHeader>
			<div className="flex flex-col mt-4 md:mt-8 gap-12">
				<SkillSelector.Root
					defaultSkills={experience?.skills}
					className="gap-6"
					searchMode="manual"
					value={skills}
					onChange={(newValue) => {
						console.log("CHANGE", newValue);
						setSkills(newValue);
					}}
				>
					{experience?.categories?.length > 0 ? (
						<div className="flex flex-col gap-8">
							<SkillSelector.Input />

							{experience.categories.map((category: any) => (
								<div
									key={`category-${category.slug}`}
									className="flex flex-col gap-2"
								>
									<p className="opacity-80">
										{category.name}
									</p>
									<SkillSelector.Results
										filter={(item: any) =>
											item.category_id === category.id ||
											item.categories?.includes(
												category.id
											)
										}
									/>
								</div>
							))}
						</div>
					) : (
						<div className="flex flex-col">
							<SkillSelector.Input className="mb-6" />
							<hr className="hidden md:flex mb-6 border-border" />
							<SkillSelector.Results />
						</div>
					)}
					{statuses.experiences === "loading" && (
						<>
							<SkillSelector.Results variant="loading" />
						</>
					)}
				</SkillSelector.Root>
				<div className="flex flex-col gap-2 p-3 rounded-md bg-card sticky bottom-0 left-0 right-0">
					{skills.length > 0 ? (
						<Button
							onClick={handleValidateExperience}
							className="flex-1"
						>
							{t("save")}
						</Button>
					) : (
						<Dialog
							open={isConfirmOpen}
							onOpenChange={setIsConfirmOpen}
						>
							<DialogTrigger className="flex-1">
								<Button className="flex-1 w-full">
									{t("save")}
								</Button>
							</DialogTrigger>
							<DialogPortal>
								<DialogOverlay className="z-[999]" />
								<DialogContent
									variant="default"
									className="max-w-[340px] rounded-md"
								>
									<div className="flex flex-col">
										<strong>
											{t("empty-skills.title")}
										</strong>
										<p className="mb-1 text-[16px]">
											{t("empty-skills.description")}
										</p>
									</div>
									<Button
										onClick={handleValidateExperience}
										className="w-full gap-2"
									>
										<i className="far fa-check"></i>

										{t("empty-skills.button")}
									</Button>
								</DialogContent>
							</DialogPortal>
						</Dialog>
					)}

					{experience && (
						<Button
							type="border"
							to={{
								modal: `/dashboard/resume/edit/skills/experiences/${experience.type}/${experience.id}/change?${backUrl}`,
							}}
						>
							{t(`experience.${experience.type}.change`)}
						</Button>
					)}
				</div>
			</div>
		</EditLayout>
	);
};

DashboardValidateSkillsModal.locale = {
	nl: {
		pending: {
			title: "We gaan opzoek naar jouw vaardigheden",
			description:
				"Op basis van jouw huidige werkervaring en opleidingen gaan we een nul punt ontwikkelen. Op basis van dit nul punt ontwikkel je je skills profiel verder door",
		},
		experience: {
			education: {
				eyebrow: "Opleiding",
				title: "Welke skills heb je geleerd met de opleiding: {{name}}",
				description:
					"We hebben de opleiding geindexeerd en hebben de vaardigheden die je hebt geleerd gegroepeerd op basis van categorieën. Kies bij elke categorie welke vaardigheden je hebt geleerd.",
				change: "Wijzig opleiding",
				skip: "Overslaan",
				"not-linked": {
					title: "We konden je opleiding niet vinden in onze database - {{name}}",
					description:
						"Je kan je opleiding: {{name}} wijzigen naar een andere opleiding, of sla deze opleiding over. Mocht je vinden dat deze opleiding in onze database moet staan? Neem dan contact op met support@{{tenant.urls.domain}}",
				},
			},
			job: {
				eyebrow: "Werkervaring",
				title: "Welke skills gebruik je in de functie: {{name}}",
				description:
					"We hebben de functie geindexeerd en hebben de vaardigheden die je hebt geleerd gegroepeerd op basis van categorieën. Kies bij elke categorie welke vaardigheden je hebt geleerd.",
				change: "Dit is niet mijn functie",
				skip: "Overslaan",
				"not-linked": {
					title: "We konden je functie niet vinden in onze database - {{name}}",
					description:
						"Je kan je functie: {{name}} wijzigen naar een andere functie, of sla deze functie over. Mocht je vinden dat deze functie in onze database moet staan? Neem dan contact op met support@{{tenant.urls.domain}}",
				},
			},
		},
		"empty-skills": {
			title: "Geen vaardigheden ingesteld",
			description:
				"Je hebt nog geen vaardigheden toegevoegd, weet je zeker dat je wilt doorgaan?",
			button: "Doorgaan",
		},
		completed: {
			title: "Je hebt voor alle ervaringen je vaardigheden toegevoegd",
			description:
				"Op basis van deze vaardigheden maken we nu je skills profiel. Dit profiel is zichtbaar voor opdrachtgevers, opdrachtgevers bepalen op basis van deze vaardigheden of jij de ideale match bent",
			button: "Naar je profiel",
		},
		save: "Opslaan",
	},
	en: {
		pending: {
			title: "We are searching for your skills",
			description:
				"Based on your current work experience and education, we will develop a starting point. From this starting point, you will continue to develop your skills profile.",
		},
		experience: {
			education: {
				eyebrow: "Education",
				title: "What skills did you learn in the program: {{name}}",
				description:
					"We have indexed the program and grouped the skills you learned based on categories. For each category, choose which skills you have learned.",
				change: "Change program",
				skip: "Skip",
				"not-linked": {
					title: "We couldn't find your program in our database - {{name}}",
					description:
						"You can change your program: {{name}} to another, or skip this program. Do you think this program should be in our database? Contact support@{{tenant.urls.domain}}",
				},
			},
			job: {
				eyebrow: "Work experience",
				title: "What skills do you use in the role: {{name}}",
				description:
					"We have indexed the role and grouped the skills you have used based on categories. For each category, choose which skills you have used.",
				change: "This is not my role",
				skip: "Skip",
				"not-linked": {
					title: "We couldn't find your role in our database - {{name}}",
					description:
						"You can change your role: {{name}} to another, or skip this role. Do you think this role should be in our database? Contact support@{{tenant.urls.domain}}",
				},
			},
		},
		"empty-skills": {
			title: "No skills set",
			description:
				"You haven't added any skills yet, are you sure you want to continue?",
			button: "Continue",
		},
		completed: {
			title: "You have added skills for all your experiences",
			description:
				"Based on these skills, we are now creating your skills profile. This profile will be visible to clients, who will determine if you are the ideal match based on these skills.",
			button: "Go to your profile",
		},
		save: "Save",
	},
};

export default withTranslation(DashboardValidateSkillsModal);
