import { useState } from "react";
import { ApiPayment } from "src/api/types";
import useApi from "src/hooks/api/utils/useApi";

type ApiPaymentSession = {
	id: string;
	value: {
		variant: "MULTI_PAYMENT" | "PAYMENT";
		redirect_url?: string;
	};
	payment: ApiPayment;
};

function usePaymentSession() {
	const { state, actions } = useApi(
		{ id: "PAYMENT_SESSION" },
		{
			baseUrl: `/payment/session`,
			query: {
				with: ["payment"],
			},
		}
	);
	const [isError, setIsError] = useState(false);
	const session: ApiPaymentSession = state?.data;
	// Find the session by ID
	const find = async (id: string) => {
		try {
			const session = await actions.get(id);
			return session;
		} catch (error) {
			setIsError(true);
			return {};
		}
	};

	return {
		session,
		status: state.status,
		isError,
		actions: {
			find,
		},
	};
}

export default usePaymentSession;
