import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import useTaskForm from "src/hooks/api/tasks/useTaskForm";
import useTaskFormSteps from "src/hooks/api/tasks/useTaskFormSteps";
import useValidateTask from "src/hooks/api/tasks/useValidateTask";
import useWindowSize from "src/hooks/useWindowSize";
import Button from "src/components/Button";

interface ButtonsProps {
	className?: string;
	variant?: "sticky";
	disabled?: boolean;
	loading?: boolean;
	nextButtonLabel?: string;
	hideButtons?: boolean;
}

const Buttons = ({
	className,
	variant,
	loading,
	disabled,
	nextButtonLabel,
	hideButtons,
}: ButtonsProps) => {
	const { t } = useTranslation(["buttons"]);
	const { id } = useParams() as any;
	const { isFinal } = useTaskFormSteps();
	const { task, current, actions, status } = useTaskForm();
	const { isPhone } = useWindowSize();
	const { isValid } = useValidateTask();
	const navigate = useNavigate();
	let canMakeConcept = true;

	if (
		task?.student ||
		task?.student_hid ||
		task?.students?.length ||
		task?.invite_all ||
		task?.type === "INTERNAL"
	) {
		canMakeConcept = false;
	}

	const getPreviousLabel = () => {
		if (current === 0)
			return t("cancel", {
				ns: "buttons",
			});

		if (isPhone) {
			return <i className="fas fa-arrow-left"></i>;
		}
		return t("previous-step", {
			ns: "buttons",
		});
	};

	if (hideButtons) {
		return <></>;
	}

	if (isFinal) {
		return (
			<Wrapper {...{ variant, className }}>
				<div className="flex-1 flex flex-col gap-4">
					<Button
						type="primary"
						disabled={
							(status === "creating" && task.type === "DRAFT") ||
							(status === "creating" &&
								task.type === "INTERNAL") ||
							!isValid
						}
						loading={
							status !== "idle" &&
							["PUBLISHED", "INTERNAL"].includes(task.type)
						}
						onClick={() =>
							id
								? actions.publish().then(() => {
										console.log("PUBLISHED");
										navigate("/tasks");
								  })
								: actions.create("PUBLISHED")
						}
					>
						{t(id ? "task.publish" : "task.create", {
							ns: "buttons",
						})}
					</Button>
					{canMakeConcept && !task.subtasks?.length && (
						<Button
							disabled={
								(status === "creating" &&
									task.type === "PUBLISHED") ||
								!isValid
							}
							loading={
								status === "creating" && task.type === "DRAFT"
							}
							onClick={() => actions.create("DRAFT")}
							type="ghost"
						>
							{t("task.create-concept", {
								ns: "buttons",
							})}
						</Button>
					)}
				</div>
			</Wrapper>
		);
	}

	return (
		<Wrapper {...{ variant, className }}>
			<Button
				disabled={current === 0}
				onClick={() => actions.updateCurrent(current - 1)}
				type="gray"
				// iconOnly={isPhone}
			>
				{getPreviousLabel()}
			</Button>
			<Button
				// iconOnly={isPhone}
				submit
				{...{ loading, disabled }}
				type="dark"
			>
				{nextButtonLabel ||
					t("next-step", {
						ns: "buttons",
					})}
				{!isPhone && (
					<i
						className={`fas fa-arrow-right ${!isPhone && "ml-2"}`}
					></i>
				)}
			</Button>
		</Wrapper>
	);
};

interface WrapperProps {
	className?: string;
	children?: any;
	variant?: "sticky";
}

const Wrapper = ({ children, className, variant }: WrapperProps) => {
	const { isPhone } = useWindowSize();
	if (variant === "sticky" || isPhone) {
		return (
			<div
				className={`fixed z-20 left-0 shadow-top right-0 bottom-0 bg-background p-4 flex md:flex-row flex-wrap gap-2 md:items-center justify-between ${
					className || ""
				}`}
			>
				{children}
			</div>
		);
	}
	return (
		<div
			className={`flex flex-col-reverse md:flex-row flex-wrap gap-2 md:items-center justify-between ${
				className || ""
			}`}
		>
			{children}
		</div>
	);
};

export default Buttons;
