import Avatar from "src/components/Avatar";
import Button from "src/components/Button";
import Card from "src/components/Card";
import Grid from "src/components/Grid";
import Loader from "src/components/Loader";
import withTranslation, {
	Translation,
} from "src/components/hoc/withTranslation";
import IdeaLikeButton from "src/components/feedback/FeedbackLikeButton";
import TaskStatus from "src/components/tasks/TaskStatus";
import useIdeas from "src/hooks/api/services/useFeedback";
import ReleaseItem from "src/components/feedback/ReleaseItem";
import Pagination from "src/components/FlatList/Pagination";

interface FeedbackGridProps extends Translation {
	variant?: "releases";
}

const FeedbackGrid = ({ t, variant }: FeedbackGridProps) => {
	const { ideas, status, pagination } = useIdeas();

	if (status === "loading" && !ideas?.length) {
		return (
			<div className="flex flex-1 flex-col justify-center items-center">
				<Loader />
			</div>
		);
	}

	if (ideas.length === 0) {
		return (
			<div className="flex flex-1 flex-col justify-center items-center">
				<strong>{t("empty.title")}</strong>
				<p className="mb-4">{t("empty.description")}</p>
				<Button
					type="dark"
					to={{
						modal: "/feedback/create",
					}}
				>
					{t("empty.button")}
				</Button>
			</div>
		);
	}

	return (
		<>
			<Grid
				columns={variant === "releases" ? 1 : 2}
				className="grid mb-4 gap-4"
			>
				{!!ideas?.length &&
					ideas.map((idea: any) => {
						if (variant === "releases") {
							return (
								<ReleaseItem
									key={`release-${idea.title}`}
									release={idea}
								/>
							);
						}
						return (
							<Card
								to={{
									modal: `/feedback/${idea.id}`,
								}}
								key={`idea-${idea.id}`}
							>
								<div className="flex flex-col flex-1 mb-4">
									<div className="flex mb-2 items-center justify-between">
										<strong className="text-[18px]">
											{idea.title}
										</strong>

										<Button
											icon={
												idea?.settings?.is_me &&
												!idea.status
													? "far fa-pencil"
													: "far fa-eye"
											}
											iconOnly
											small
											type="gray"
										></Button>
									</div>
									<p>{idea.description}</p>
								</div>

								{idea.status && (
									<div className="mb-4">
										<TaskStatus status={idea.status} />
									</div>
								)}

								<div className="flex items-center justify-between flex-wrap">
									{idea.creator ? (
										<Avatar.User user={idea.creator} />
									) : (
										<div
											className={`flex items-center gap-2`}
										>
											<Avatar size="small" />
											<div
												className={`flex flex-col whitespace-nowrap gap-1`}
											>
												<p>{t("anonymous.label")}</p>
												<small></small>
											</div>
										</div>
									)}
									{idea?.id && (
										<IdeaLikeButton {...{ idea }} />
									)}
								</div>
							</Card>
						);
					})}
			</Grid>
			<div className="flex justify-center items-center">
				<Pagination
					className="py-4"
					page={{
						current: pagination.page,
						max: pagination.last_page,
					}}
					fetching={false}
					// fetching={loading || false}
					onNavigate={pagination.setPage}
				/>
			</div>
		</>
	);
};

FeedbackGrid.locale = {
	nl: {
		empty: {
			title: "Er is nog geen feedback gegevens",
			description: "Post je feedback via de onderstaande knop",
			button: "Feedback posten",
		},
		anonymous: {
			label: "Anoniem",
		},
		"load-more": "Meer laden",
	},
	en: {
		empty: {
			title: "No feedback found",
			description: "Post feedback via the button below",
			button: "Post feedback",
		},
		anonymous: {
			label: "Anonymous",
		},
		"load-more": "Load more",
	},
};

export default withTranslation(FeedbackGrid);
