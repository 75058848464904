import { useState } from "react";
import Button from "src/components/Button";
import withTranslation, {
	Translation,
} from "src/components/hoc/withTranslation";
import useAlert from "src/hooks/useAlert";

interface DeleteCustomFormButtonProps extends Translation {
	onDelete: () => Promise<void>;
}

const DeleteCustomFormButton = ({
	t,
	onDelete,
}: DeleteCustomFormButtonProps) => {
	const [sendAlert] = useAlert();
	const [isSubmitting, setIsSubmitting] = useState(false);
	const onClick = () => {
		sendAlert({
			type: "confirm",
			title: t("confirm.title"),
			text: t("confirm.description"),
			onConfirm: () => {
				setIsSubmitting(true);
				onDelete().finally(() => {
					setIsSubmitting(false);
				});
			},
		});
	};
	return (
		<Button
			{...{ onClick }}
			xsmall
			type="red"
			loading={isSubmitting}
			disabled={isSubmitting}
		>
			{t("label")}
		</Button>
	);
};

DeleteCustomFormButton.locale = {
	nl: {
		label: "Verwijder",
		confirm: {
			title: "Weet je het zeker?",
			description: "Weet je zeker dat je dit formulier wilt verwijderen",
		},
	},
	en: {
		label: "Delete",
		confirm: {
			title: "Are you sure?",
			description: "Are you sure you want to delete this form?",
		},
	},
};

export default withTranslation(DeleteCustomFormButton);
