import Icon from "src/components/icon/Icon";
import IconPicker from "src/components/icon/IconPicker";
import ProjectEditableTitle from "src/components/projects/ProjectEditableTitle";
import useProjects from "src/hooks/api/services/projects/useProjects";

const ProjectFormTitle = () => {
	const { formState, setFormState } = useProjects();

	return (
		<div className="flex flex-row items-center gap-5 md:min-h-[40px] max-h-full">
			<IconPicker
				trigger={
					<Icon className="bg-accent w-10" icon={formState?.icon} />
				}
				onChange={(emoji) => {
					setFormState((prev) => ({ ...prev, icon: { emoji } }));
				}}
				value={formState?.icon?.emoji}
			/>
			<ProjectEditableTitle variant="medium" autoFocus />
		</div>
	);
};

export default ProjectFormTitle;
