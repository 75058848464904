import React, { ComponentProps } from "react";
import { cn } from "src/lib/utils";

interface DescriptionProps extends ComponentProps<"p"> {}

const Description = ({ className, ...rest }: DescriptionProps) => {
	return (
		<p {...rest} className={cn("text-sm text-placeholder", className)}></p>
	);
};

export default Description;
