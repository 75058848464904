import React from "react";
import { motion } from "framer-motion";

const Path = (props: any) => (
	<motion.path
		fill="transparent"
		strokeWidth="2"
		strokeLinecap="round"
		{...props}
	/>
);

const ToggleButton = ({ onClick, stroke, open }: any) => {
	return (
		<motion.div
			onClick={onClick}
			className="cursor-pointer flex justify-center items-center"
		>
			<svg width="23" height="23" viewBox="0 0 23 23">
				<Path
					variants={{
						"closed-shadow": { d: "M 2 2.5 L 20 2.5" },
						closed: { d: "M 2 2.5 L 20 2.5" },
						open: { d: "M 3 16.5 L 17 2.5" },
					}}
					animate={open ? "open" : "closed"}
					stroke={stroke || "#fff"}
				/>
				<Path
					d="M 2 9.423 L 20 9.423"
					variants={{
						"closed-shadow": { opacity: 1 },
						closed: { opacity: 1 },
						open: { opacity: 0 },
					}}
					animate={open ? "open" : "closed"}
					transition={{ duration: 0.1 }}
					stroke={stroke || "#fff"}
				/>
				<Path
					animate={open ? "open" : "closed"}
					variants={{
						"closed-shadow": { d: "M 2 16.346 L 20 16.346" },
						closed: { d: "M 2 16.346 L 20 16.346" },
						open: { d: "M 3 2.5 L 17 16.346" },
					}}
					stroke={stroke || "#fff"}
				/>
			</svg>
		</motion.div>
	);
};

export default ToggleButton;
