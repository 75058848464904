import { ComponentProps } from "react";
import { cn } from "src/lib/utils";

interface ResumeSectionTitle extends ComponentProps<"p"> {}

const ResumeEditButton = ({
	className,
	children,
	...rest
}: ResumeSectionTitle) => {
	return (
		<p {...rest} className={cn("font-bold", className)}>
			{children}
		</p>
	);
};

export default ResumeEditButton;
