import React from "react";
import { useTranslation } from "react-i18next";
import Button from "src/components/Button";
import useTaskForm from "src/hooks/api/tasks/useTaskForm";
import { VariantTypes } from "src/hooks/useVariant";

interface SaveTaskButtonProps {
	type?: VariantTypes;
}

const SaveTaskButton = ({ type = "dark" }: SaveTaskButtonProps) => {
	const { t } = useTranslation("buttons");

	const { actions, status, changes } = useTaskForm();
	return (
		<Button
			{...{ type }}
			disabled={!Object.keys(changes).length}
			loading={status === "updating"}
			onClick={() => {
				actions.save({
					disableAlert: true,
				});
			}}
		>
			{t("save")}
		</Button>
	);
};

export default SaveTaskButton;
