import { useTranslation } from "react-i18next";
import FormGrid from "src/components/form/FormGrid";
import CompanyContactForm from "src/components/forms/profile/CompanyContactForm";
import EditAvatar from "src/components/forms/profile/EditAvatar";
import EditCompanyDetails from "src/components/forms/profile/EditCompanyDetails";
import EditInvoiceAddress from "src/components/forms/profile/EditInvoiceAddress";
import Grid from "src/components/Grid";
import ProfileSettingsLayout from "src/components/layout/profile-settings-layout/ProfileSettingsLayout";

export default function CompanyView() {
	const { t } = useTranslation("profile-view", {
		keyPrefix: "company-view",
	});
	return (
		<ProfileSettingsLayout title={t("title")}>
			<Grid columns={1}>
				<FormGrid>
					<FormGrid.Header title={t("avatar.title")}>
						{t("avatar.description")}
					</FormGrid.Header>
					<FormGrid.Card>
						<EditAvatar />
					</FormGrid.Card>
					<FormGrid.Header title={t("info.title")}></FormGrid.Header>
					<FormGrid.Card>
						<EditCompanyDetails />
					</FormGrid.Card>
					<FormGrid.Header title={t("invoice-address.title")}>
						{t("invoice-address.description")}
					</FormGrid.Header>
					<FormGrid.Card>
						<EditInvoiceAddress />
					</FormGrid.Card>
					<FormGrid.Header title={t("contact.title")}>
						{t("contact.description")}
					</FormGrid.Header>
					<FormGrid.Card>
						<CompanyContactForm />
					</FormGrid.Card>
				</FormGrid>
			</Grid>
		</ProfileSettingsLayout>
	);
}
