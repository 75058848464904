import { useEffect, useState } from "react";
import { ApiRegistrationTimesheet } from "src/api/types";
import Avatar from "src/components/Avatar";
import Button from "src/components/Button";
import Checkbox from "src/components/form/Checkbox";
import withTranslation, {
	Translation,
} from "src/components/hoc/withTranslation";
import Table from "src/components/Table";
import TimesheetStatus from "src/components/timesheet/TimesheetStatus";
import useSocketListener from "src/hooks/sockets/useSocketListener";
import { formatPrice } from "src/lib/formatters";

interface TimesheetListItemProps extends Translation {
	timesheet: ApiRegistrationTimesheet;
	onEdit: () => void;
	select?: {
		active: boolean;
		onSelect: () => void;
	};
}

const TimesheetListItem = ({
	t,
	timesheet,
	onEdit,
	select,
}: TimesheetListItemProps) => {
	const [status, setStatus] = useState<any>(timesheet.current.status);

	useSocketListener(
		`timesheet.${timesheet.current.id}.status`,
		({ status }: any) => {
			if (status) setStatus(status);
		}
	);

	useEffect(() => {
		setStatus(timesheet.current.status);
	}, [timesheet]);

	return (
		<>
			<Table.Row>
				<Table.Cell>
					{select && status === "pending" && (
						<Checkbox
							onChange={select.onSelect}
							value={select.active}
						/>
					)}
				</Table.Cell>
				<Table.Cell className="w-max">
					<TimesheetStatus status={status} />
				</Table.Cell>
				<Table.Cell className="w-full">
					<Avatar.User
						className="w-full flex-1"
						wrapper={{
							className: "flex-1 max-w-full",
						}}
						user={timesheet.user}
						subtitle={timesheet.user?.tagline}
					/>
				</Table.Cell>
				<Table.Cell>{timesheet.current.start}</Table.Cell>
				<Table.Cell>{timesheet.current.end}</Table.Cell>
				<Table.Cell>{timesheet.current.break}</Table.Cell>
				<Table.Cell>{timesheet.current.total}</Table.Cell>
				<Table.Cell>
					{timesheet.current?.total
						? formatPrice(
								timesheet.hour_rate * timesheet.current?.total
						  )
						: t("price-unknown")}
				</Table.Cell>
				<Table.Cell>
					<Button
						tabIndex={-1}
						iconOnly
						type="gray"
						disabled={status === "approved"}
						onClick={onEdit}
					>
						<i className={`fas fa-pencil`}></i>
					</Button>
				</Table.Cell>
			</Table.Row>
		</>
	);
};

TimesheetListItem.locale = {
	nl: {
		"price-unknown": "-",
	},
	en: {
		"price-unknown": "-",
	},
};

export default withTranslation(TimesheetListItem);
