import { useMemo } from "react";
import { useTranslation } from "react-i18next";

/* Create a function that return 4 random items from an array */
function getRandomItems(array: any[], numberOfItems = 4) {
	const shuffled = array.sort(() => 0.5 - Math.random());
	return shuffled.slice(0, numberOfItems);
}

function useExampleTask() {
	const { t } = useTranslation("example-tasks-hook");
	const data = useMemo(() => {
		return {
			[t("marketing.title")]: t("marketing.items", {
				returnObjects: true,
			}) as string[],
			[t("marketing-development.title")]: t(
				"marketing-development.items",
				{
					returnObjects: true,
				}
			) as string[],
			[t("marketing-content.title")]: t("marketing-content.items", {
				returnObjects: true,
			}) as string[],
			[t("sales.title")]: t("sales.items", {
				returnObjects: true,
			}) as string[],
			[t("administration-legal.title")]: t("administration-legal.items", {
				returnObjects: true,
			}) as string[],
			[t("other.title")]: t("other.items", {
				returnObjects: true,
			}) as string[],
		};
	}, [t]);

	const all = useMemo(() => {
		const flattened: any[] = [];
		Object.keys(data).forEach((key) => {
			flattened.push(...data[key]);
		});
		return flattened;
	}, [data]);
	const randomItems = useMemo(() => {
		return getRandomItems(all);
	}, [all]);
	return {
		tasks: data,
		all,
		randomItems,
		categories: Object.keys(data),
	};
}

export default useExampleTask;
