import { ComponentProps, useEffect, useState } from "react";
import Button from "src/components/Button";
import Card from "src/components/Card";
import Skeleton from "src/components/Skeleton";
import Field from "src/components/field/Field";
import withTranslation, {
	Translation,
} from "src/components/hoc/withTranslation";
import useBoards from "src/hooks/api/services/tasks/useBoards";
import useTaskForm from "src/hooks/api/tasks/useTaskForm";
import useAuth from "src/hooks/selectors/useAuth";
import useNavigateModal from "src/hooks/useNavigateModal";
import { cutString } from "src/lib/formatters";
import { cn } from "src/lib/utils";

interface CreatedTask {
	board_slug?: string;
	task_title?: string;
	task_hid?: string;
}

interface CreateTaskProps extends Translation, ComponentProps<"div"> {
	as?: "card" | "div";
}

const CreateTaskCard = ({
	t,
	className,
	as = "card",
	...rest
}: CreateTaskProps) => {
	const Wrapper = as === "card" ? Card : "div";
	const auth = useAuth();
	const { task, actions, status } = useTaskForm();
	const { boards } = useBoards();
	const [title, setTitle] = useState<string>();
	const [createdTasks, setCreatedTasks] = useState<CreatedTask[]>([]);
	const navigateModal = useNavigateModal();

	const setEmptyState = () => {
		actions.set({
			assignees: [auth.id],
			board: null,
			links: {
				board: null,
			},
			tags: [],
		});
		setTitle("");
	};

	useEffect(() => {
		return setEmptyState();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleSubmit = (e: any) => {
		if (e) e.preventDefault();
		if (!title) return;

		actions
			.create("INTERNAL", {
				disableRedirect: true,
				updateState: true,
				data: {
					title,
					assignees:
						!task?.assignees || task.assignees?.length === 0
							? ["me"]
							: task.assignees,
				},
			})
			.then((data) => {
				setCreatedTasks([data, ...createdTasks]);
				setEmptyState();
			});
	};

	return (
		<div {...rest} className={cn("flex flex-col gap-3", className)}>
			<Wrapper
				{...(as === "card"
					? {
							contentClassName: "px-6 py-3 flex-1",
					  }
					: {})}
				className="flex-1"
			>
				<form
					onSubmit={handleSubmit}
					className="flex flex-col gap-3 md:flex-row justify-between"
				>
					<div className="flex flex-row items-center gap-2">
						<input
							className="bg-transparent placeholder:text-placeholder w-full"
							placeholder={t("input-task")}
							value={title}
							onChange={(e: any) => {
								let title = e.target.value;
								title =
									title.charAt(0).toUpperCase() +
									title.slice(1);
								setTitle(title);
							}}
						/>
					</div>
					<div className="flex flex-row items-center gap-4">
						<Field.Users
							wrapper={{
								layout: "vertical",
							}}
							trigger={{
								className: "-m-1 p-1 flex-nowrap",
								arrow: {
									className: "hidden",
								},
							}}
							placeholder={t("users")}
							value={
								task.assignees
									? task.assignees.map((user: any) =>
											typeof user === "object"
												? user.id.toString()
												: user
									  )
									: []
							}
							onChange={(value) => {
								if (
									value?.includes("me") &&
									task.assignees.find(
										(u: any) => u.id === auth.id
									)
								) {
									value = value.filter(
										(item: any) =>
											item !== "me" &&
											item !== auth?.id &&
											item !== auth.id.toString()
									);
								}
								actions.set({
									assignees: value,
								});
							}}
						/>
						<Field.Board
							wrapper={{
								layout: "vertical",
							}}
							trigger={{
								className: "-m-1 p-1 flex-nowrap h-[42px]",
								arrow: {
									className: "hidden",
								},
							}}
							settings={{
								hideArrow: true,
							}}
							placeholder={t("board")}
							value={task?.links?.board || undefined}
							onChange={(board) => {
								const boardState = boards.find(
									(boardState) => boardState.hid === board
								);
								actions.set({
									board: board || null,
									links: {
										...task?.links,
										board: board || null,
									},
									tags: boardState?.filter?.tags,
								});
							}}
						/>
						<Button
							disabled={!title || !task.board}
							loading={status === "creating"}
							className="w-[37px] h-[37px] p-0"
							type="dark"
							onClick={handleSubmit}
						>
							<i className="far fa-plus"></i>
						</Button>
					</div>
				</form>
			</Wrapper>
			<div className="flex gap-3 empty:hidden overflow-x-auto hide-default-scrollbars">
				{createdTasks
					.filter((item, index) => index < 7)
					.map((task: any, index: number) => (
						<Card
							onClick={() =>
								navigateModal(`./modals/tasks/${task.hid}`)
							}
							contentClassName="p-3"
							className="cursor-pointer w-max"
							key={`CREATED_TASK_${task?.hid}`}
						>
							<p className="flex whitespace-nowrap">
								{cutString(task?.title || "", 30)}
							</p>
						</Card>
					))}
			</div>
		</div>
	);
};

export const CreateTaskCardSkeleton = () => {
	return (
		<div className="flex flex-row justify-between">
			<Skeleton className="h-10 w-48" />
			<div className="flex flex-row items-center gap-2">
				<Skeleton className="h-10 w-24" />
				<Skeleton className="h-10 w-24" />
				<Skeleton className="h-10 w-10" />
			</div>
		</div>
	);
};

CreateTaskCard.locale = {
	nl: {
		"input-task": "Taak...",
		users: "Uitvoerder",
		board: "Bord",
	},
	en: {
		"input-task": "Task...",
		users: "Assignees",
		board: "Board",
	},
};

export default withTranslation(CreateTaskCard);
