import axios from "axios";
import { useEffect, useState } from "react";
import { useUpdateEffectDebounce } from "src/hooks/useDebounce";

function useCalculateServiceFee(budget: number, amountOfStudents = 1) {
	const [data, setData] = useState({} as any);
	const [loading, setLoading] = useState(false);
	const checkPrice = () => {
		setLoading(true);
		axios
			.get(
				`/helpers/calculate-price?price=${budget}&amount_of_students=${amountOfStudents}`
			)
			.then(({ data }) => {
				setData(data);
			})
			.finally(() => setLoading(false));
	};

	useUpdateEffectDebounce([budget, amountOfStudents], 500, () =>
		checkPrice()
	);

	useEffect(() => {
		if (budget) {
			checkPrice();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return {
		data,
		loading,
	};
}

export default useCalculateServiceFee;
