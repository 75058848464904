const providers = {
	"tenant-provider": {
		error: {
			title: "Er is iets misgegaan",
			description: "De pagina is momenteel niet te bereiken",
		},
	},
};

export default providers;
