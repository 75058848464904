import { useAtom } from "jotai";
import { atomWithStorage } from "jotai/utils";

const filterAtom = atomWithStorage("CONNECTION_FILTER", {});

function useConnectionFilter() {
	const [filter, setFilter] = useAtom<any>(filterAtom);
	return {
		filter,
		setFilter,
	} as any;
}

export default useConnectionFilter;
