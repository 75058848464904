import { useState, useEffect } from "react";

export default function useOnScreen(ref: any, rootMargin: number = 0) {
	const [isIntersecting, setIntersecting] = useState(false);

	useEffect(() => {
		const observer = new IntersectionObserver(
			([entry]) => {
				setIntersecting(entry.isIntersecting);
			},
			{
				rootMargin: `${rootMargin}px`,
			}
		);

		const currentElement = ref?.current;

		if (currentElement) {
			observer.observe(currentElement);
		}

		return () => {
			if (currentElement && observer) {
				observer.unobserve(currentElement);
			}
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return isIntersecting;
}
