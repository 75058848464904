import { ComponentProps, createContext, useContext, useState } from "react";
import { ApiVacancy } from "src/api/types";
import Button from "src/components/Button";
import FieldComp from "src/components/field/Field";
import { useT } from "src/components/hoc/withTranslation";
import { useVacancyForm } from "src/components/vacancies/vacancy-form/provider";
import useAlert from "src/hooks/useAlert";
import { cn } from "src/lib/utils";

const AnalyseVacancyFormContext = createContext<{
	value: string;
	setValue: (value: string) => void;
	disabled: boolean;
	onSubmit: () => Promise<void>;
}>({
	value: "",
	setValue: () => {},
	disabled: false,
	onSubmit: async () => {},
});

interface AnalyseVacancyFormProps extends ComponentProps<"div"> {
	onSuccess?: (vacancy: ApiVacancy) => void;
}

const AnalyseVacancyForm = ({
	children,
	className,
	onSuccess,
	...rest
}: AnalyseVacancyFormProps) => {
	const t = useT({
		nl: {
			error: {
				title: "Er is iets fout gegaan",
				description:
					"Het is niet mogelijk om de vacature te analyseren",
			},
		},
		en: {
			error: {
				title: "Something went wrong",
				description: "It is not possible to analyse the vacancy",
			},
		},
	});
	const [sendAlert] = useAlert();
	const { step, actions } = useVacancyForm();
	const [value, setValue] = useState<string>("");
	const disabled = !value || value === "<br/>";

	const onSubmit = async () => {
		//Create the vacancy from the text
		try {
			const vacancy = await actions.analyze({ description: value });
			if (vacancy && onSuccess) {
				onSuccess(vacancy);
			}
			//Set the step to check the data
			await step.set("check");
		} catch (error) {
			sendAlert({
				title: t("error.title"),
				text: t("error.description"),
			});
		}
	};

	return (
		<AnalyseVacancyFormContext.Provider
			value={{ value, setValue, disabled, onSubmit }}
		>
			<div className={cn("flex flex-col", className)} {...rest}>
				{children}
			</div>
		</AnalyseVacancyFormContext.Provider>
	);
};

export default AnalyseVacancyForm;

const Field = () => {
	const { value, setValue } = useContext(AnalyseVacancyFormContext);
	const t = useT({
		nl: {
			placeholder: "Plak hier je volledige vacature...",
		},
		en: {
			placeholder: "Paste your full vacancy here...",
		},
	});
	return (
		<FieldComp.Editor
			wrapper={{}}
			variant="bordered"
			value={value}
			onChange={setValue}
			placeholder={t("placeholder")}
		/>
	);
};

const SubmitButton = () => {
	const { disabled, onSubmit } = useContext(AnalyseVacancyFormContext);
	const t = useT({
		nl: {
			submit: "Doorgaan",
		},
		en: {
			submit: "Continue",
		},
	});

	return (
		<Button {...{ disabled }} onClick={onSubmit}>
			{t("submit")}
		</Button>
	);
};

export {
	Field as AnalyseVacancyFormField,
	SubmitButton as AnalyseVacancyFormSubmitButton,
};
