import { useState } from "react";
import { useTranslation } from "react-i18next";
import { ApiTaskInvite } from "src/api/types";
import Button from "src/components/Button";
import Modal from "src/components/Modal";
import Input from "src/components/form/Input";
import usePost from "src/hooks/rest/usePost";

interface SignupTaskProps {
	invite: ApiTaskInvite;
	onSuccess: () => void;
}

const SignupTask = ({ invite, onSuccess }: SignupTaskProps) => {
	const { t } = useTranslation(["network", "buttons"]);
	const [visible, setVisible] = useState(false);
	const [motivation, setMotivation] = useState("");
	const [signupUser, loading] = usePost(
		`/tasks/${invite.task.id}/registrations`
	);
	const [success, setSuccess] = useState(false);
	const handleSubmit = () => {
		setSuccess(false);
		signupUser({ motivation }).then(() => {
			setSuccess(true);
			setVisible(false);
			onSuccess();
		});
	};
	return (
		<>
			{invite.is_registered ? (
				<Button className="flex-1" type="primary">
					{t("button.label-success", {
						ns: "network",
						keyPrefix: "signup-task",
					})}
				</Button>
			) : (
				<Button
					type="primary"
					onClick={() => setVisible(!visible)}
					className="flex-1"
				>
					{t("button.label", {
						ns: "network",
						keyPrefix: "signup-task",
					})}
				</Button>
			)}
			<Modal
				title="Aanmelden"
				{...{ visible }}
				onClose={() => setVisible(false)}
			>
				<div className="flex-1 flex flex-col gap-4">
					{success && (
						<strong className="text-primary">
							{t("alert-success", {
								ns: "network",
								keyPrefix: "signup-task",
							})}
						</strong>
					)}
					<p>
						{t("description", {
							ns: "network",
							keyPrefix: "signup-task",
						})}
					</p>
					<Input
						name="motivation"
						label={t("input-label", {
							ns: "network",
							keyPrefix: "signup-task",
						})}
						multiline
						value={motivation}
						onChange={(key, value) => setMotivation(value)}
					/>
				</div>
				<Modal.Buttons>
					<Button type="gray" onClick={() => setVisible(false)}>
						{t("cancel", {
							ns: "buttons",
						})}
					</Button>
					<Button
						disabled={!motivation}
						{...{ loading }}
						onClick={handleSubmit}
					>
						{t("confirm", {
							ns: "buttons",
						})}
					</Button>
				</Modal.Buttons>
			</Modal>
		</>
	);
};

export default SignupTask;
