import { Route } from "react-router-dom";
import ProtectedRoute from "src/routes/middlewares/ProtectedRoute";
import NotificationsView from "src/views/authenticated/settings/NotificationsView";
import NetworkView from "src/views/authenticated/settings/network/NetworkView";
import NetworkCustomFormDetailView from "src/views/authenticated/settings/network/forms/NetworkCustomFormDetailView";
import PaymentRequestsView from "src/views/authenticated/settings/payment-requests/PaymentRequestsView";
import CreatePaymentRequestAddonModal from "src/views/authenticated/settings/payment-requests/forms/CreatePaymentRequestAddonModal";
import PaymentRequestAddonDetailView from "src/views/authenticated/settings/payment-requests/forms/PaymentRequestAddonDetailView";
// import DashboardSettingsView from "src/views/authenticated/settings/SettingsView";

const SettingsRoutes = [
	<Route
		element={
			<ProtectedRoute>
				<NotificationsView />
			</ProtectedRoute>
		}
		path="/settings/notifications"
		key="/settings/notifications"
	/>,
	<Route
		element={
			<ProtectedRoute type="company">
				<PaymentRequestsView />
			</ProtectedRoute>
		}
		path="/settings/payment-requests"
		key="/settings/payment-requests"
	/>,
	<Route
		element={
			<ProtectedRoute type="company">
				<PaymentRequestAddonDetailView />
			</ProtectedRoute>
		}
		path="/settings/payment-requests/addons/:id"
		key="/settings/payment-requests/addons/:id"
	/>,
	<Route
		element={
			<ProtectedRoute type="company">
				<NetworkView />
			</ProtectedRoute>
		}
		path="/settings/network"
		key="/settings/network"
	/>,
	<Route
		element={
			<ProtectedRoute type="company">
				<NetworkCustomFormDetailView />
			</ProtectedRoute>
		}
		path="/settings/network/forms/:id"
		key="/settings/network/forms/:id"
	/>,
];

export const SettingsModalRoutes = [
	<Route
		element={
			<ProtectedRoute
				variant="modal"
				type="company"
				modal={{
					size: "default",
					backUrl: "/settings/payment-requests",
				}}
			>
				<CreatePaymentRequestAddonModal />
			</ProtectedRoute>
		}
		path="/settings/payment-requests/addons/create"
		key="/settings/payment-requests/addons/create"
	/>,
];

export default SettingsRoutes;
