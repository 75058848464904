import { useEffect, useState } from "react";
import Field from "src/components/field/Field";
import withTranslation, {
	Translation,
} from "src/components/hoc/withTranslation";
import Layout from "src/components/layout/Layout";
import SubscriptionFeatureMiddleware from "src/components/middlewares/SubscriptionFeatureMiddleware";
import PaymentRequestFinalizeTable from "src/components/payment-request/visualizations/PaymentRequestFinalizeTable";
import useUnreads from "src/hooks/api/useUnreads";
import useAuth from "src/hooks/selectors/useAuth";
import useModules from "src/hooks/selectors/useModules";

const PaymentRequestsFinalizeView = ({ t }: Translation) => {
	const auth = useAuth();
	const { fetchUnreads } = useUnreads();
	const { modules, loading: loadingModules } = useModules();
	const [filter, setFilter] = useState<{
		connection_id?: number | string;
	}>({});

	useEffect(() => {
		fetchUnreads();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	if (
		auth.type === "company" &&
		!modules.includes("payment-requests") &&
		!loadingModules
	) {
		return (
			<Layout
				title={t("title")}
				breadcrumb={[
					{
						label: t("title"),
						to: "/payment-requests/finalize",
					},
				]}
			>
				<SubscriptionFeatureMiddleware plan="premium" />
			</Layout>
		);
	}

	return (
		<Layout
			title={t("title")}
			breadcrumb={[
				{
					label: t("title"),
					to: "/payment-requests/finalize",
				},
			]}
		>
			<div className="flex justify-between">
				<div className="flex items-center gap-4">
					<Field.Connection
						onChange={(value) =>
							setFilter((filter) => ({
								...filter,
								connection_id: value,
							}))
						}
						value={filter?.connection_id}
						placeholder={t(
							`filters.${
								auth.type === "student" ? "company" : "student"
							}`
						)}
					/>
				</div>
			</div>
			<div className="flex flex-col flex-1">
				<PaymentRequestFinalizeTable {...{ filter }} />
			</div>
		</Layout>
	);
};

PaymentRequestsFinalizeView.locale = {
	nl: {
		title: "Verzoeken afronden",
		filters: {
			company: "Opdrachtgever",
			student: "Opdrachtnemer",
			status: "Status",
		},
	},
	en: {
		title: "Complete Requests",
		filters: {
			company: "Client",
			student: "Contractor",
			status: "Status",
		},
	},
};

export default withTranslation(PaymentRequestsFinalizeView);
