import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { ApiOffer } from "src/api/types";
import Alert from "src/components/Alert";
import Button from "src/components/Button";
import Layout from "src/components/layout/Layout";
import OfferDescriptionCard from "src/components/offer/cards/OfferDescriptionCard";
import OfferShareCard from "src/components/offer/cards/OfferShareCard";
import OfferStatisticsCard from "src/components/offer/cards/OfferStatisticsCard";
import ArchiveOfferButton from "src/components/offer/forms/ArchiveOfferButton";
import OfferAvailableWorkSwitch from "src/components/offer/forms/OfferAvailableWorkSwitch";
import PublishOfferButton from "src/components/offer/forms/PublishOfferButton";
import OfferDetailGrid from "src/components/offer/layout/OfferDetailGrid";
import OfferRegistrationList from "src/components/offer/lists/OfferRegistrationList";
import useGet from "src/hooks/rest/useGet";
import useQueryString from "src/hooks/useQueryString";

export default function OffersDetailView() {
	const { t } = useTranslation("offer-view", {
		keyPrefix: "offer-detail-view",
	});
	const params = useParams() as any;
	const query = useQueryString();
	const navigate = useNavigate();
	const [result, loading, { setResult }] = useGet(
		`/student/offers/${params?.id}?with[]=statistics`
	);
	const [isPublished, setIsPublished] = useState(false);
	const { data: offer } = result;

	const updateOffer = (offer: ApiOffer) => {
		setResult({
			...result,
			data: offer,
		});
	};

	if (loading) {
		return <OfferDetailGrid.Loading />;
	}

	return (
		<Layout
			breadcrumb={[
				{
					to: "/offers",
					label: t("breadcrumb.home"),
					isHome: true,
				},
				{
					to: `/offers/${offer?.slug}/show`,
					label: offer?.title,
				},
			]}
			container="xxl"
			title={t("title")}
		>
			{isPublished && (
				<Alert type="success">{t("alert.published")}</Alert>
			)}
			{query?.update && (
				<Alert type="success">{t("alert.success")}</Alert>
			)}

			<Layout.Header backUrl="/offers" title={offer?.title}>
				<div className="flex items-center gap-4">
					{offer?.visibility?.status !== "CONCEPT" && (
						<OfferAvailableWorkSwitch
							id={offer?.id}
							type="detail"
							defaultValue={offer?.visibility.is_available}
							onChange={updateOffer}
						/>
					)}

					{offer?.visibility?.status === "CONCEPT" && (
						<PublishOfferButton
							{...{ offer }}
							onChange={(offer) => {
								updateOffer(offer);
								if (offer.visibility.status === "PUBLISHED") {
									setIsPublished(true);
									setTimeout(() => {
										setIsPublished(false);
									}, 10000);
								}
							}}
						/>
					)}
					{offer?.id && (
						<ArchiveOfferButton
							{...{ offer }}
							onChange={() => {
								navigate("/offers");
							}}
						/>
					)}
					<Button
						to={`/offers/${offer?.slug}/edit`}
						iconOnly
						type="gray"
					>
						<i className="fas fa-pencil"></i>
					</Button>
				</div>
			</Layout.Header>
			{offer?.id && (
				<OfferDetailGrid>
					<OfferDescriptionCard
						{...{ offer }}
						className="md:col-span-2 md:row-span-3"
					/>
					<OfferStatisticsCard {...{ offer }} />
					<OfferShareCard {...{ offer }} className="md:row-span-2" />
					<div className="md:col-span-2 mt-4">
						<OfferRegistrationList
							{...{ offer }}
							query="with[]=company&with[]=user"
							id={params.id}
						/>
					</div>
				</OfferDetailGrid>
			)}
		</Layout>
	);
}
