const setting = {
	"notifications-view": {
		title: "Instellingen",
		emails: {
			title: "E-mails",
			"new-chat": {
				label: "Nieuw chatbericht",
				"sub-label": "Je hebt een nieuw chatbericht",
			},
			"new-review": {
				label: "Nieuwe review",
				"sub-label": "Iemand heeft een review geplaatst",
			},
			"new-envelope": {
				label: "Nieuwe overeenkomst",
				"sub-label": "Een overeenkomst moet nog ondertekend worden.",
			},
			"summary-registrations": {
				label: "Samenvatting van aanmeldingen",
				"sub-label":
					"Zoveel professionals hebben de afgelopen week op je opdracht gereageerd",
			},
			"new-registration": {
				label: "Nieuwe aanmelding",
				"sub-label": "Je hebt een nieuwe aanmelding op je opdracht",
			},
			"new-payment": {
				label: "Nieuwe betaling",
				"sub-label": "Mails omtrent betalingen en facturen",
			},
			"task-expires": {
				label: "Opdracht verloopt",
				"sub-label": "Opdracht x verloopt over 3 dagen",
			},
			"no-registrations": {
				label: "Je opdracht heeft nog geen aanmeldingen",
			},
			"offer-recommendations": {
				label: "Nieuwe professionals deze week",
			},
			"new-tasks": {
				label: "Nieuwe opdrachten",
				"sub-label":
					"Deze opdrachten zijn de afgelopen 4 uur geplaatst",
			},
			"registration-received": {
				label: "Aanmelding ontvangen",
				"sub-label": "Je aanmelding voor opdracht x is ontvangen",
			},
			"registration-accepted": {
				label: "Aanmelding goedgekeurd",
				"sub-label": "Je aanmelding voor opdracht x is goedgekeurd",
			},
			"registration-denied": {
				label: "Aanmelding niet geselecteerd",
				"sub-label":
					"Je aanmelding voor opdracht x is niet geselecteerd",
			},
			payout: {
				label: "Uitbetalingen",
				sublabel: "Je bent uitbetaald!",
			},
			"task-invite": {
				label: "Uitnodigingen voor opdrachten",
				"sub-label": "Bedrijf x heeft je uitgenodigd voor een opdracht",
			},
			"offer-created": {
				label: "We hebben jouw aanbod ontvangen!",
			},
			"offer-verified": {
				label: "Goed nieuws! Je aanbod is goedgekeurd.",
			},
			"offer-new-registration": {
				label: "Nieuwe aanmeldingen op jouw aanbod",
				"sub-label": "Je hebt een nieuwe aanmelding!",
			},
		},
		notifications: {
			title: "Notificaties",
			"new-chat": {
				label: "Nieuw chatbericht",
				"sub-label": "Je hebt een nieuw chatbericht",
			},
			"recommend-task": {
				label: "Nieuwe opdrachten",
				"sub-label":
					"Is 4 blogs maken bij Digital marketing B.V. misschien wat voor jou?",
			},
			"task-invite": {
				label: "Uitnodigingen voor opdrachten",
				"sub-label": "Bedrijf x heeft je uitgenodigd voor een opdracht",
			},
		},
	},
};

export default setting;
