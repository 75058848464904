import { motion } from "framer-motion";
import { Children, cloneElement, useEffect } from "react";
import { useSlider } from "src/components/slider/Slider";
import useElementSize from "src/hooks/useElementSize";
import { cn } from "src/lib/utils";

interface ItemsProps {
	children?: any;
	className?: string;
}

const Items = ({ children, className }: ItemsProps) => {
	const { options, current, items, setItems } = useSlider();
	const container = useElementSize();
	const slides = options.slides;
	const itemWidth = container.size.width / slides;

	const getAnimationState = (index: number) => {
		const end = current + slides;

		if (index >= current && index < end) {
			return "visible";
		}

		return "hidden";
	};

	useEffect(() => {
		setItems(Children.toArray(children));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [children]);

	return (
		<div
			ref={container.ref}
			className={cn("flex flex-col relative", className)}
		>
			<div
				style={{
					gap: options?.gap,
					margin: -8,
					padding: 8,
				}}
				className="flex overflow-hidden"
			>
				{Children.map(items, (child: any, index: number) => (
					<motion.div
						style={{
							flexBasis: itemWidth - options.gap || 0,
							flexGrow: 0,
							flexShrink: 0,
						}}
						key={`tutorial-item-${index}`}
						className="flex flex-col h-full"
						transition={{
							ease: "linear",
						}}
						animate={getAnimationState(index)}
						variants={{
							hidden: {
								opacity: 0,
								x: -(current * itemWidth),
							},
							visible: {
								opacity: 1,
								x: -(current * itemWidth),
							},
						}}
					>
						{cloneElement(child, {
							index,
						})}
					</motion.div>
				))}
			</div>
		</div>
	);
};

export default Items;
