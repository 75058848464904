import moment from "moment";
import { ApiAlert } from "src/api/types";
import Avatar from "src/components/Avatar";
import AlertListDescription from "src/components/alerts/AlertListDescription";
import useAlert from "src/hooks/api/services/useAlert";

interface AlertListItemProps {
	alert: ApiAlert;
}

const AlertListItem = ({ alert }: AlertListItemProps) => {
	const { actions } = useAlert();

	return (
		<div
			onClick={() => {
				if (!alert.viewed_at) {
					actions.read(alert.id);
				}
			}}
			className={`px-6 py-4 flex gap-4 ${
				alert.viewed_at ? "opacity-40" : ""
			}`}
		>
			{alert?.creator ? (
				<Avatar size="button" src={alert.creator.avatar} />
			) : (
				<div />
			)}
			<div className="flex flex-1 flex-col justify-between gap-1">
				<AlertListDescription {...{ alert }} />
				<small className="text-placeholder">
					{moment(alert.created_at).fromNow()}
				</small>
			</div>
		</div>
	);
};

export default AlertListItem;
