import { hexToRGB } from "src/lib/colors";
import { cn } from "src/lib/utils";

const THEMES = {
	primary: "bg-primary text-white",
	dark: "bg-dark text-white",
	gray: "bg-accent",
	white: "bg-background text-background-foreground",
	red: "bg-red text-white",
};

const SIZES = {
	xsmall: "leading-[8px] text-[12px] py-2 px-2 rounded-[4px]",
	small: "leading-[10px] text-[14px] py-2 px-[10px] rounded-[4px]",
	default: "leading-[10px] text-[16px] py-[11px] px-[10px]",
	regular: "leading-[10px] text-[16px] py-2.5 px-3",
	large: "leading-[10px] text-[18px] py-3 px-4",
};

export type TagSize = keyof typeof SIZES;

export interface TagProps {
	theme?: keyof typeof THEMES;
	custom?: string;
	size: TagSize;
	children?: any;
	onClick?: () => void;
	className?: string;
	options?: {
		closeable?: boolean;
	};
}

const Tag = ({
	theme,
	custom,
	size,
	children,
	onClick,
	className,
	options,
}: TagProps) => {
	let style = {};

	if (custom) {
		style = {
			...(style || {}),
			backgroundColor: hexToRGB(custom, 0.1),
			color: custom,
		};
	}

	return (
		<div
			{...{ onClick, style }}
			className={cn(
				"inline-flex items-center flex-row transition-all relative gap-2 w-max font-bold rounded-md",
				SIZES[size],
				theme && THEMES[theme],
				onClick && "cursor-pointer",
				className
			)}
		>
			{children}

			{options?.closeable && (
				<div className="w-5 h-5 flex justify-center items-center bg-dark/10 rounded-sm">
					<i className="fas fa-times text-[12px]"></i>
				</div>
			)}
		</div>
	);
};

Tag.defaultProps = {
	theme: "gray",
	size: "default",
};

export default Tag;
