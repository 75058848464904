import React, { useEffect, useRef } from "react";
import useVariant, { VariantTypes } from "src/hooks/useVariant";
import useWindowSize from "src/hooks/useWindowSize";
import Card from "./Card";

interface StepsLineProps {
	className?: string;
	backgroundColor?: string;
	active: number;
	steps: string[];
	onClick?: (step: number) => void;
	variant:
		| "horizontal"
		| "vertical"
		| "vertical-transparent"
		| "horizontal-transparent";
	theme?: "dark";
}

const StepsLine: React.FC<StepsLineProps & React.HTMLProps<HTMLDivElement>> = ({
	className,
	backgroundColor,
	steps,
	active,
	onClick,
	variant,
	theme,
	...rest
}: StepsLineProps) => {
	const { isPhone } = useWindowSize();
	const ref = useRef(null);
	const getVariant = (n: number) => {
		return n <= active ? "primary" : "gray";
	};

	const wrapChildren = (children: any) => {
		if (variant.endsWith("-transparent")) {
			return (
				<div className="overflow-x-scroll hide-default-scrollbar">
					{children}
				</div>
			);
		}

		return (
			<Card
				className="overflow-x-scroll w-full md:w-auto"
				contentClassName="p-0"
			>
				{children}
			</Card>
		);
	};

	if (variant === "vertical") {
		return (
			<Card
				contentClassName={`${
					theme === "dark" &&
					"bg-background text-background-foreground"
				}`}
			>
				<div
					className={`flex flex-col w-full gap-4 ${className || ""}`}
					{...{ ref }}
					{...rest}
				>
					{steps.map((step, index) => (
						<Step
							key={`${step}-${index}`}
							active={index === active}
							valid={index < active}
							variant={getVariant(index) as any}
							label={step}
							{...{ index, onClick }}
							direction="vertical"
							onClick={() => {
								if (index <= active && onClick) {
									onClick(index);
								}
							}}
						/>
					))}
				</div>
			</Card>
		);
	}

	if (variant === "vertical-transparent") {
		return (
			<div
				className={`flex flex-col w-full gap-4 ${className || ""}`}
				{...{ ref }}
				{...rest}
			>
				{steps.map((step, index) => (
					<Step
						key={`${step}-${index}`}
						active={index === active}
						valid={index < active}
						variant={getVariant(index) as any}
						label={step}
						{...{ index, onClick }}
						direction="vertical"
						onClick={() => {
							if (index <= active && onClick) {
								onClick(index);
							}
						}}
					/>
				))}
			</div>
		);
	}

	const activeStep = steps.find((_, index) => index === active);

	if (isPhone) {
		return (
			<div
				className={`flex flex-row items-center justify-between w-full ${
					className || ""
				}`}
				{...{ ref }}
				{...rest}
			>
				<Step
					valid={false}
					active={true}
					variant="primary"
					label={activeStep || ""}
					direction="horizontal"
					index={active}
				/>
				<strong>
					{active + 1} / {steps.length}
				</strong>
			</div>
		);
	}

	return wrapChildren(
		<div
			className={`flex flex-row flex-wrap gap-3 items-center w-full ${
				className || ""
			}`}
			{...{ ref }}
			{...rest}
		>
			{steps.map((step, index) => (
				<Step
					key={`${step}-${index}`}
					active={index === active}
					valid={index < active}
					variant={getVariant(index) as any}
					label={step}
					direction="horizontal"
					{...{ index, onClick }}
					onClick={() => {
						if (index <= active && onClick) {
							onClick(index);
						}
					}}
				/>
			))}
		</div>
	);
};

interface StepProps {
	label: string;
	index: number;
	active: boolean;
	valid: boolean;
	variant?: VariantTypes;
	onClick?: () => void;
	direction: "horizontal" | "vertical";
}

const Step = ({
	label,
	index,
	active,
	valid,
	variant,
	onClick,
	direction,
}: StepProps) => {
	const variantClassName = useVariant(variant);
	const { isTablet } = useWindowSize();
	const ref = useRef(null);
	let showLabel = true;
	let cursor = "cursor-default";

	if (isTablet && direction === "horizontal") {
		showLabel = !active ? false : true;
	}

	if (onClick && !valid && !active) {
		cursor = "cursor-not-allowed";
	}

	if (onClick && !active && valid) {
		cursor = "cursor-pointer";
	}

	useEffect(() => {
		if (ref && ref?.current && active && direction !== "vertical") {
			const current = ref?.current as any;
			current.scrollIntoView({
				behavior: "smooth",
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [ref, active]);

	return (
		<div
			onClick={() => {
				if ((active || valid) && onClick) {
					onClick();
				}
			}}
			id={`step-${index}`}
			className={`flex items-center pr-1 gap-2 ${cursor}`}
			{...{ ref }}
		>
			<div
				className={`${variantClassName} overflow-hidden relative flex justify-center items-center rounded-full w-10 h-10`}
			>
				<div
					className={`${variantClassName} absolute opacity-${
						valid ? 100 : 0
					} transition-all top-0 bottom-0 flex items-center`}
				>
					<i className="fas fa-check"></i>
				</div>
				<strong>{index + 1}</strong>
			</div>
			{showLabel && (
				<strong
					className={`${
						direction === "horizontal" && "whitespace-nowrap"
					} transition-all ${active || valid ? "text-primary" : ""}`}
				>
					{label}
				</strong>
			)}
		</div>
	);
};

StepsLine.defaultProps = {
	className: "",
	backgroundColor: "white",
	steps: [],
	active: 0,
	variant: "horizontal",
};

export default StepsLine;
