import { Switch as SwitchComp } from "src/components/ui/switch";
import Loader from "src/components/Loader";
import { cn } from "src/lib/utils";

type SwitchProps = {
	value: boolean;
	onChange: (v: boolean) => void;
	label?: any;
	subLabel?: any;
	type?: string;
	fetching?: boolean;
	className?: string;
	changeColor?: boolean;
	disabled?: boolean;
	switchClassName?: string
};

const Switch = ({
	value,
	onChange,
	label,
	fetching,
	subLabel,
	className,
	disabled,
	switchClassName
}: SwitchProps) => {
	return (
		<div className={cn("flex gap-2 items-center", className)}>
			{fetching && <Loader />}
			<div className="flex items-center">
				<SwitchComp
					className={switchClassName}
					checked={value}
					onCheckedChange={() => onChange(!value)}
					{...{ disabled }}
				/>
			</div>
			{(label || subLabel) && (
				<div className="flex-1">
					{label && (
						<div className="flex min-h-5 items-center">
							<p className="mb-0">{label}</p>
						</div>
					)}
					{subLabel && (
						<span className="mb-0 block opacity-60">
							{subLabel}
						</span>
					)}
				</div>
			)}
		</div>
	);
};

export default Switch;
