import { useTranslation } from "react-i18next";
import { ApiTask, ApiTaskKanbanStatus, ApiTaskStatus } from "src/api/types";
import Tag, { TagSize } from "src/components/Tag";
import useTenant from "src/hooks/api/services/tenants/useTenant";
import { cn } from "src/lib/utils";

export const colors = {
	BACKLOG: "bg-[#E3E6E6] text-dark",
	completed: "bg-green text-white",
	matched: "bg-primary text-white",
	expired: "bg-red text-white",
	cancelled: "bg-red text-white",
	TODO: "bg-[#E3E6E6] text-dark",
	IN_PROGRESS: "bg-[#E7EDF8] dark:bg-[#9CB7E5] text-blue",
	COMPLETED: "bg-[#ECF4E8] dark:bg-[#2C4A1C] text-green",
	DENIED: "bg-transparent text-dark dark:text-white",
} as any;

interface TaskStatusProps {
	task?: ApiTask;
	status: ApiTaskStatus | ApiTaskKanbanStatus;
	size: TagSize;
	className?: string;
}

const TaskStatus = ({ status, size, className }: TaskStatusProps) => {
	const { tenant } = useTenant();
	const isExtern =
		tenant.modules.includes("extern-task") &&
		!tenant.modules.includes("intern-task");
	const { t } = useTranslation("tasks", {
		keyPrefix: "status",
	});

	if (tenant.slug === "getjobsdone" && ["BACKLOG", "TODO"].includes(status)) {
		status = "concept";
	}

	return (
		<Tag
			className={cn(
				"font-regular leading-full",
				status ? colors[status] : "bg-accent text-accent-foreground",
				className
			)}
			{...{ size }}
		>
			{t(`${isExtern ? "extern." : ""}${status || "open"}`)}
		</Tag>
	);
};

TaskStatus.defaultProps = {
	size: "default",
};

export default TaskStatus;
