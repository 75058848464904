import moment from "moment";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Button from "src/components/Button";
import Card from "src/components/Card";
import Loader from "src/components/Loader";
import withTranslation, {
	Translation,
} from "src/components/hoc/withTranslation";
import Layout from "src/components/layout/Layout";
import ProjectForm from "src/components/projects/create/ProjectForm";
import ProjectFormTitle from "src/components/projects/create/ProjectFormTitle";
import useProjects from "src/hooks/api/services/projects/useProjects";
import useAlert from "src/hooks/useAlert";

function EditProjectView({ t }: Translation) {
	const [status, setStatus] = useState("loading");
	const navigate = useNavigate();
	const { project } = useParams() as any;
	const [sendAlert] = useAlert();
	const {
		actions: projectActions,
		project: projectState,
		status: projectStatus,
		formState,
		setFormState,
		clearFormState,
	} = useProjects();

	useEffect(() => {
		projectActions.get(project).then((data: any) => {
			setFormState({
				icon: data.icon,
				name: data.name,
				start_date: data.start_date,
				end_date: data.end_date,
				members: data.members,
				skills: data?.skills || [],
			});
		});

		return () => {
			clearFormState();
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (projectStatus === "idle") {
			setStatus("idle");
		}
		if (projectStatus === "loading") {
			setStatus("loading");
		}
		if (projectStatus === "idle" && !projectState.id) {
			setStatus("not-found");
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [projectStatus]);

	const handleDelete = () => {
		if (!projectState.id || projectStatus !== "idle") return;
		sendAlert({
			type: "confirm",
			title: t("delete.title"),
			text: t("delete.text"),
			onConfirm: () => {
				projectActions.delete(projectState.id).then(() => {
					navigate("/projects");
				});
			},
		});
	};

	const onSubmit = () => {
		if (!projectState.id || projectStatus !== "idle") return;
		projectActions
			.update(
				{
					id: projectState.id,
					...formState,
				},
				{
					updateList: true,
				}
			)
			.then((data: any) => {
				clearFormState();
				navigate(`/projects/${data.slug}`);
			});
	};

	const errorDate =
		formState.start_date !== "" &&
		formState.end_date !== "" &&
		(moment(formState.start_date).isAfter(moment(formState.end_date)) ||
			moment(formState.end_date).isBefore(moment(formState.start_date)));

	const errorCheck =
		formState?.name?.trim() === "" ||
		formState?.start_date === "" ||
		formState?.members?.length === 0 ||
		errorDate
			? true
			: false;

	return (
		<Layout
			backUrl={
				status === "not-found" ? `/projects` : `/projects/${project}`
			}
			title={t("title")}
		>
			<div className="flex flex-col mx-auto w-full max-w-[540px]">
				{status === "idle" && (
					<Card>
						<ProjectFormTitle />
						<ProjectForm />
						{errorDate && (
							<div className="input_has-error">
								{t("error_date")}
							</div>
						)}
						<Button
							disabled={errorCheck}
							loading={projectStatus === "updating"}
							type="dark"
							className="w-full"
							onClick={onSubmit}
						>
							{t("save")}
						</Button>
						<hr className="border-border my-2" />
						<div className="flex">
							<Button
								disabled={projectStatus === "loading"}
								type="gray"
								className="flex-1"
								loading={projectStatus === "deleting"}
								onClick={handleDelete}
							>
								{t("delete.button")}
							</Button>
						</div>
					</Card>
				)}
				{status === "loading" && (
					<div className="flex flex-col flex-1 justify-center items-center">
						<Loader />
					</div>
				)}
				{status === "not-found" && (
					<div className="flex flex-1 justify-center items-center p-8 text-center flex-col max-w-md mx-auto">
						<strong className="mb-1 text-[18px]">
							{t("not-found.title")}
						</strong>
						<p className="mb-4">{t("not-found.description")}</p>
					</div>
				)}
			</div>
		</Layout>
	);
}

EditProjectView.locale = {
	nl: {
		title: "Project aanpassen",
		"not-found": {
			title: "Project niet gevonden",
			description:
				"Het lijkt er op dat dit project nooit heeft bestaan of is verwijdert.",
		},
		delete: {
			button: "Project verwijderen",
			title: "Weet je het zeker?",
			text: "Weet je zeker dat je dit project wilt verwijderen",
		},
		error_date: "Start datum kan niet voor eind datum of andersom",
		save: "Opslaan",
	},
	en: {
		title: "Adjust project",
		"not-found": {
			title: "Project not found",
			description:
				"It seems that this project never existed or was removed.",
		},
		delete: {
			button: "Delete project",
			title: "Are you sure?",
			text: "Are you sure that you want to delete this project",
		},
		error_date: "Start date cannot be before end date or vice versa",
		save: "Save",
	},
};

export default withTranslation(EditProjectView);
