import { useEffect } from "react";
import { useParams } from "react-router-dom";
import Loader from "src/components/Loader";
import withTranslation, {
	Translation,
} from "src/components/hoc/withTranslation";
import usePaymentSession from "src/hooks/api/services/session/usePaymentSession";
import useModal from "src/hooks/useModal";

const SessionPaymentInvoiceModal = ({ t }: Translation) => {
	useModal({
		title: t("title"),
	});
	const { sessionId } = useParams();
	const { session, actions, status } = usePaymentSession();

	useEffect(() => {
		if (session?.id !== sessionId && sessionId) {
			actions.find(sessionId);
		}
	});

	return (
		<div className="h-[60vh] flex flex-col">
			{status !== "idle" && !session?.payment?.invoice && (
				<div className="flex justify-center items-center flex-1">
					<Loader />
				</div>
			)}
			{session?.payment?.invoice && (
				<div className="flex flex-col flex-1">
					<iframe
						title={`${session.payment.invoice_number}`}
						src={session.payment.invoice.url}
						className="flex flex-col flex-1 h-full"
					></iframe>
				</div>
			)}
		</div>
	);
};

SessionPaymentInvoiceModal.locale = {
	nl: {
		title: "Factuur",
	},
	en: {
		title: "Invoice",
	},
};

export default withTranslation(SessionPaymentInvoiceModal);
