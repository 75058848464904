import { useState } from "react";
import Avatar from "src/components/Avatar";
import Button from "src/components/Button";
import FormGroup from "src/components/form/FormGroup";
import FileUpload from "src/components/form/file-upload/FileUpload";
import withTranslation, {
	Translation,
} from "src/components/hoc/withTranslation";
import useEditProfile from "src/hooks/api/useEditProfile";
import useAuth from "src/hooks/selectors/useAuth";

const ResumeAvatar = ({ t }: Translation) => {
	const auth = useAuth();
	const [media, setMedia] = useState({
		url: auth.avatar,
	});
	const [editProfile] = useEditProfile();
	return (
		<FormGroup noMarginBottom label={t("title")}>
			<div className="flex flex-1 flex-row gap-4 items-center">
				<FileUpload
					type="avatar"
					onChange={(media) => {
						setMedia(media);
						editProfile({ media_id: media.id });
					}}
					value={media}
				>
					{({ loading }) => (
						<>
							<div className="flex items-center gap-3">
								<Avatar
									size="large"
									variant="gray"
									src={media?.url}
								/>
								<Button {...{ loading }} xsmall type="border">
									{t("edit")}
								</Button>
							</div>
						</>
					)}
				</FileUpload>
			</div>
		</FormGroup>
	);
};

ResumeAvatar.locale = {
	nl: {
		title: "Profielfoto (optioneel)",
		save: "Upload foto",
		edit: "Aanpassen",
	},
	en: {
		title: "Profile picture (optional)",
		save: "Upload photo",
		edit: "Edit",
	},
};

export default withTranslation(ResumeAvatar);
