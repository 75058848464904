import React, { useEffect } from "react";
import { ApiTaskMinimal } from "src/api/types";
import Table from "src/components/Table";
import useTimesheetsTotals from "src/hooks/api/services/timesheets/useTimesheetsTotals";
import { formatPrice } from "src/lib/formatters";

interface TimesheetListFooterProps {
	task: ApiTaskMinimal;
}

const TimesheetListFooter = ({ task }: TimesheetListFooterProps) => {
	const { totals, actions } = useTimesheetsTotals({
		task_id: task.id,
	});
	useEffect(() => {
		actions.get();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	return (
		<Table.Row className="">
			<Table.Cell></Table.Cell>
			<Table.Cell></Table.Cell>
			<Table.Cell></Table.Cell>
			<Table.Cell></Table.Cell>
			<Table.Cell></Table.Cell>
			<Table.Cell>{totals.break}</Table.Cell>
			<Table.Cell>{totals.total}</Table.Cell>
			<Table.Cell>{formatPrice(totals.price)}</Table.Cell>
			<Table.Cell></Table.Cell>
		</Table.Row>
	);
};

export default TimesheetListFooter;
