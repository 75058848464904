import { useState } from "react";
import Select, { SelectTheme } from "src/components/field/fields/Select";
import { WrapperProps } from "src/components/field/utils/Wrapper";
import Icon from "src/components/icon/Icon";
import useProjectOptions from "src/hooks/api/services/projects/useProjectOptions";
import useSubscription from "src/hooks/selectors/useSubscription";
import { useUpdateEffectDebounce } from "src/hooks/useDebounce";

interface ProjectProps {
	wrapper: WrapperProps;
	value: any;
	onChange: (value?: string) => void;
	theme?: SelectTheme;
}

const Project = ({ wrapper, value, onChange, theme }: ProjectProps) => {
	const subscription = useSubscription();
	const [search, setSearch] = useState<string>("");
	const { projects, actions } = useProjectOptions();
	const project = projects.find((project) => project.hid === value);
	useUpdateEffectDebounce(search, 500, () => {
		actions.list({ search: search.toLowerCase() });
	});

	if (!subscription?.id) return <></>;

	return (
		<Select
			{...{ wrapper }}
			onChange={(value) => {
				onChange(value);
			}}
			search={{
				value: search,
				onChange: (value) => setSearch(value),
			}}
			value={value as any}
			options={projects
				.filter((project) =>
					project.name
						.toLocaleLowerCase()
						.includes(search.toLocaleLowerCase())
				)
				.filter((project, index) => index < 16)
				.map((project) => ({
					value: project.hid.toString(),
					label: (
						<div className="flex items-center">
							{project.icon && (
								<Icon
									className="hover:bg-transparent w-6 h-6"
									iconFontSize={12}
									icon={project.icon}
								/>
							)}
							{project.name}
						</div>
					),
				}))}
			theme={{
				...(theme || {}),
				item: {
					indicator: true,
					className: "radix-highlighted:bg-accent",
				},
				value: {
					renderValue: () => (
						<div className="flex items-center text-left gap-1">
							{project?.icon && (
								<Icon
									className="hover:bg-transparent w-6 h-6"
									iconFontSize={12}
									icon={project.icon}
								/>
							)}
							<span className="leading-[120%]">
								{project?.name}
							</span>
						</div>
					),
				},
			}}
		/>
	);
};

export default Project;
