const reviews = {
	title: "Reviews",
	share: {
		created_one:
			"Ik heb zojuist een review geplaatst van 1 ster op {{tenant.name}}. Meer weten over {{tenant.name}}?",
		created_other:
			"Ik heb zojuist een review geplaatst van {{count}} sterren op {{tenant.name}}. Meer weten over {{tenant.name}}?",
		received_one:
			"Ik heb zojuist een review ontvangen van 1 ster op {{tenant.name}}. Meer weten over {{tenant.name}}?",
		received_other:
			"Ik heb zojuist een review ontvangen van {{count}} sterren op {{tenant.name}}. Meer weten over {{tenant.name}}?",
	},
};

export default reviews;
