import React from "react";
import { useTranslation } from "react-i18next";
import Card from "src/components/Card";
import PaymentBalanceItem from "src/components/dashboard/student/PaymentBalance/PaymentBalanceItem";
import useAuth from "src/hooks/selectors/useAuth";
import useGet from "src/hooks/rest/useGet";
import Grid from "src/components/Grid";
import MoneyCounter from "src/components/MoneyCounter";
import "./payment-balance.scss";

interface PaymentBalanceProps {
	variant: "card";
}

const PaymentBalance = ({ variant }: PaymentBalanceProps) => {
	const auth = useAuth();
	const [{ data }] = useGet("/student/payments/statistics");
	const { t } = useTranslation("elements", {
		keyPrefix: "payment-balance",
	});

	if (variant === "card") {
		return (
			<Card className="relative" title="Mijn verdiensten">
				{!auth.status.is_merchant && (
					<div className="absolute inset-0 p-4 flex justify-center items-center flex-col backdrop-blur-sm dark:bg-dark-accent/30 bg-background/30 ">
						<strong>{t("empty.title")}</strong>
						<small className="text-center">
							{t("empty.description")}
						</small>
					</div>
				)}
				<PaymentBalanceItem
					type="reserved"
					value={data?.reserved}
					isFirst
				/>
				{/* <PaymentBalanceItem type="coming" value={data?.coming} /> */}
				<PaymentBalanceItem isLast type="paid" value={data?.paid} />
			</Card>
		);
	}

	return (
		<Grid columns={3} className="payment-balance">
			<MoneyCounter label={t("status.reserved")} price={data?.reserved} />
			{/* <MoneyCounter label={t("status.coming")} price={data?.coming} /> */}
			<MoneyCounter label={t("status.paid")} price={data?.paid} />
		</Grid>
	);
};

PaymentBalance.defaultProps = {
	variant: "card",
};

export default PaymentBalance;
