import React from "react";
import { LayoutVariants } from "src/api/types";

interface SignerMobileCtaProps {
	children?: any;
	variant: LayoutVariants;
}

const SignerMobileCta = ({ children, variant }: SignerMobileCtaProps) => {
	return (
		<div
			className={`bg-${
				variant === "dark" ? "dark-accent" : "light"
			} fixed left-0 bottom-0 right-0 z-[99] py-4`}
		>
			<div className="container">
				<div className="flex gap-4 items-center">{children}</div>
			</div>
		</div>
	);
};

export default SignerMobileCta;
