const resume = {
	resume: {
		empty: {
			title: "No profile found",
		},
		titles: {
			bio: "About me",
			education: "Education & courses",
			social: "Social media",
			job: "Work experience",
			reviews: "Reviews",
			skills: "Skills",
			badges: "Badges",
		},
	},
	"edit-bio": {
		label: "Introduce yourself to your future employer",
	},
	"resume-badges": {
		empty: {
			title: "No badges found",
		},
	},
	"resume-experiences-list": {
		create: "Create",
		job: {
			add: "Add work experience",
			empty: {
				title: "No work experiences found",
			},
		},
		education: {
			add: "Add",
			empty: {
				title: "No education or courses found",
			},
		},
	},
	"resume-reviews": {
		empty: {
			title: "No reviews found",
		},
	},
	"resume-skills": {
		empty: {
			title: "No skills found",
		},
		form: {
			label: "Type a skill like 'Word' and then press enter",
			button: "Add",
		},
	},
	"resume-social-media": {
		title: "Sociale media",
		"error-delete": {
			title: "Social media deletion failed",
		},
		"error-save": {
			title: "Social media save failed",
			text: "Is the profile link correct?",
		},
		empty: {
			title: "No socials found",
		},
		form: {
			placeholder: "Link to your {{name}} page",
			button: "Save",
		},
	},
};

export default resume;
