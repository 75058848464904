import { atom } from "jotai";
import useForm from "src/hooks/schemes/useForm";

interface SignupStudentActions {
	getField: (name: string) => any;
	setForm: any;
	resetForm: any;
}

type State = {
	form: any;
	validate: any;
	actions: SignupStudentActions;
};

const formAtom: any = atom({});
const validateAtom: any = atom({});

type Options = {
	filter?: any;
};

function useCompanyForm(options?: Options): State {
	const {
		form,
		actions: { setForm, getField, resetForm },
		validate,
	} = useForm({
		atoms: { form: formAtom, validate: validateAtom },
		scheme: {
			name: ["string", "required"],
			kvk: ["string", "required"],
			street: ["string", "required"],
			house_number: ["string", "required"],
			city: ["string", "required"],
			zipcode: ["string", "required"],
			branche: ["string", "required"],
		},
	});

	return {
		form,
		validate,
		actions: {
			getField,
			setForm,
			resetForm,
		},
	};
}

export default useCompanyForm;
