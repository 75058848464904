import { createContext, useContext } from "react";
import { ApiResume } from "src/api/types";
import { ResumeOptions } from "src/components/resume/resume-detail/ResumeDetail";

export type ResumeTabName = "profile" | "experiences";

export const ResumeDetailContext = createContext<{
	resume: ApiResume;
	options?: ResumeOptions;
	tabs: {
		all: ResumeTabName[];
		active: ResumeTabName;
		setActive: (tab: ResumeTabName) => void;
	};
} | null>(null);

export function useResumeDetailContext() {
	const context = useContext(ResumeDetailContext);
	if (!context) {
		throw new Error(
			"ResumeDetail.* component must be rendered as child of ResumeDetail component"
		);
	}
	return context;
}

export default useResumeDetailContext;
