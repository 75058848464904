import { createContext, useContext } from "react";
import { DateRangeFilterValue } from "src/components/date-range-filter/Provider";

export type AnalyticsLayoutContextProps = {
	date: DateRangeFilterValue;
	setDate: React.Dispatch<React.SetStateAction<DateRangeFilterValue>>;
};

export const AnalyticsLayoutContext =
	createContext<AnalyticsLayoutContextProps>(
		{} as AnalyticsLayoutContextProps
	);

export function useAnalyticsLayout() {
	const props = useContext(AnalyticsLayoutContext);
	return props;
}
