import { useLocation, useNavigate, useParams } from "react-router-dom";
import Modal from "src/components/Modal";
import withTranslation, {
	Translation,
} from "src/components/hoc/withTranslation";
import ShareTask from "src/components/promotion/share/ShareTask";
import useTask from "src/hooks/api/tasks/useTask";

interface ShareTaskModalProps extends Translation {}

const ShareTaskModal = ({ t }: ShareTaskModalProps) => {
	const params = useParams();
	const navigate = useNavigate();
	const { task } = useTask();
	const location = useLocation();
	return (
		<Modal
			title={t("title")}
			visible={location.pathname.includes("/share")}
			onClose={() => {
				navigate(`/tasks/${params.id}/${params.pane}`);
			}}
			className=" "
		>
			<ShareTask
				variant="buttons-stacked"
				budget={task?.budget}
				task_id={task?.slug || ""}
				description={task?.description}
				title={task?.title || ""}
				url={task?.url}
			/>
		</Modal>
	);
};

ShareTaskModal.locale = {
	nl: {
		title: "Opdracht delen",
	},
	en: {
		title: "Share task",
	},
};

export default withTranslation(ShareTaskModal);
