const forms = {
	"edit-profile-picture": {
		title: "Jouw profielfoto",
		"choose-different": "Kies een andere foto",
		edit: "Profielfoto aanpassen",
	},
	comment: {
		placeholder: "Type een opmerking",
	},
	"address-form": {
		title: "Locatie",
		home: `Deze opdracht kan <1>Thuis</1> worden uitgevoerd`,
		location: `Deze opdracht wordt op <1>{{location}}</1> uitgevoerd`,
		street: "Straat",
		"house-number": "Huisnummer",
		zipcode: "Postcode",
		city: "Plaats",
		required: "Verplicht",
		"next-step": "Volgende",
	},
	"calculate-service-fee": {
		title: "Succesfee berekenen",
		"not-enough-credit":
			"Je hebt niet genoeg krediet op je account, je mist nog <1>{{fee}}</1> plaatsingstegoed. <2>Klik hier om jouw plaatsingstegoed op te waarderen</2>",
		free: "Aangezien je nog geen opdracht hebt geplaatst kan je een opdracht tot {{price}} kosteloos plaatsen. Over het resterende bedrag wordt een fee van {{rate}}% gerekend",
		description: {
			budget_one:
				"Als je een opdracht wilt plaatsen van {{budget}} voor 1 professional, betaal je een succesfee van {{fee}}",
			budget_other:
				"Als je een opdracht wilt plaatsen van {{budget}} p.p. voor {{count}} professionals, betaal je een succesfee van {{fee}}",
			"per-hour":
				"Als je een opdracht wilt plaatsen van {{budget}} per uur, betaal je een succesfee van {{fee}} per gewerkt uur",
		},
		gift: "Deze opdracht krijg je cadeau 🎁",
	},
	"answer-form": {
		answer: "Antwoord",
		is_right: "Juiste antwoord",
	},
	"question-form": {
		question: "Vraag",
		answer: {
			title: "Wil je de professional testen op hun kennis?",
			description:
				"Vul een paar antwoorden in en markeer het antwoord(en) die juist is",
			add: "Antwoord toevoegen",
			label: "Antwoord {{count}}",
		},
	},
	"review-form": {
		title: "Hoe is de opdracht verlopen?",
		"title-cancel": "Vervelend dat de klus niet is geklaard",
		confirm: {
			title: "Weet je het zeker?",
			description: "Je staat op het punt om een 0 review te geven",
		},
		cancel: {
			description:
				"Is de professional niet komen opdagen? Of is de opdracht niet juist uitgevoerd? Geef hieronder aan wat er is misgegaan. Een medewerker van {{tenant.name}} neemt dan contact op en regelt de refund van de opdracht.",
		},
		score: "5 = heel goed, 0 = heel slecht",
		description: "Omschrijving",
		rating: "Rating",
		skills: {
			label: "Blinkt de professional uit in sommige vaardigheden?",
			description:
				"Het helpt de professional wanneer je de vaardigheden een like geeft. Je kan een professional maar 1 keer liken",
		},
		"show-switch": {
			label: "Review tonen",
			"sub-label":
				"Wil je deze review tonen op het profiel van de professional",
		},
		buttons: {
			cancel: "Annuleren",
			save: "Opslaan",
		},
	},
	"advertise-task": {
		title: "Promotie",
		description:
			"Meer kans op een match? Voor <1>{{price}}</1> adverteren wij de opdracht",
	},
	"budget-form": {
		title: "Opdrachtwaarde",
		tabs: {
			"pay-per-hour": "Prijs per uur",
			"fixed-fee": "Vaste waarde",
		},
		"hour-rate": {
			label: "Uurloon",
		},
		"alert-all":
			"Er zijn al aanmeldingen of er is al betaald voor de opdracht, hierdoor kan je de opdrachtwaarde en aantal professionals niet aanpassen",
		"alert-budget":
			"Er zijn al aanmeldingen of er is al betaald voor de opdracht, hierdoor kan je de opdrachtwaarde niet aanpassen",
		"amount-of-students": {
			help: "Heb je 20 mensen nodig voor je klus? Dan vul je hier 20 in",
			label: "Hoeveel mensen heb je nodig",
		},
		budget: {
			help: "Dit bedrag krijgt de professional op de rekening",
			label: "Opdrachtwaarde",
		},
		"estimated-duration": {
			label: "Geschatte tijdsduur (in uren)",
			help: "Hoelang denk jij dat de professional ongeveer met de opdracht bezig is",
		},
		shifts: "De opdrachtwaarde wordt automatisch berekend d.m.v. de opgegeven shifts",
	},
	"delete-task-form": {
		alert: "Het is niet mogelijk om een opdracht te verwijderen wanneer deze al aanmeldingen heeft",
		title: "Opdracht verwijderen",
		description:
			"Wanneer u uw opdracht verwijdert is deze niet meer terug te vinden.",
	},
	"description-task-form": {
		title: "Opdrachtomschrijving",
		"task-title": "Titel",
		description: {
			label: "Omschrijving",
			help: "Bijvoorbeeld: Vereiste opleidingsniveau, werkervaring, gewenste vaardigheden en duidelijke omschrijving van de opdracht",
			placeholder: "Beschrijf zo uitgebreid mogelijk de opdracht.",
		},
		category: {
			label: "Categorie",
			manual: "Staat jouw categorie er niet bij? Vul hem dan handmatig in",
		},
	},
	"gallery-form": {
		label: "Afbeeldingen (optioneel)",
	},
	"interview-form": {
		title: "Aanmeldformulier",
		"title-badge": "Nieuw",
		description:
			"Stel de opdrachtnemer wat vragen, dit kan vrije invoer zijn of een meerkeuze vraag.",
		instruction:
			"Indien je de vragen leeg laat worden er aanmeldingen gedaan op basis van motivatie.",
		"add-question": "Vraag toevoegen",
		"question-label": "Vraag {{count}}",
	},
	"student-pool-form": {
		title: "Professionals uitnodigen",
		"only-switch": {
			label: "Opdracht alleen voor connecties",
			"sub-label": "Nodig iedereen uit in je connecties",
		},
		"connection-dropdown": {
			label: "Professional direct uitnodigen",
			placeholder: "Zoek in je eigen connecties",
		},
	},
	"summary-form": {
		title: "Samenvatting",
		info: {
			title: "We hebben alle informatie ontvangen",
			description:
				"Controleer de informatie en deel je opdracht met onze professionals",
		},
		// finance: {
		// 	title: "Financieel",
		// 	"amount-of-students": "Aantal professionals",
		// 	budget: "Opdrachtwaarde",
		// 	"estimated-hourly-rate": "Geschatte uurloon",
		// 	"per-hour": " per uur",
		// 	"fee-per-hour": "Fee per gewerkt uur",
		// 	"budget-per-hour": "Uurloon",
		// 	"total-service-fee": "Totale succesfee",
		// 	"ad-cost": "Adverteerkosten",
		// 	"total-cost": "Totale kosten",
		// 	"total-hour-cost": "Totale kosten per uur",
		// },
		details: {
			title: "Details",
			"start-date": "Start datum",
			"end-date": "Einddatum",
			date: "Datum",
			"estimated-duration": "Geschatte tijdsduur",
			"estimated-duration-end": "uur",
			invited: "Uitgenodigden",
			"invited-all": "Connecties uitnodigen",
		},
		promo: {
			christmas: {
				title: "Doneer een kerststol 🎄🎅",
				label: "Kerststol doneren aan de voedselbank",
				more: "Meer informatie over de doneer actie",
			},
		},
	},
	"target-form": {
		title: "Start- en einddatum",
		anonymous: "Verberg mijn bedrijfsnaam",
		private: {
			label: "Opdracht niet promoten",
			"sub-label": "Zo kunnen niet al onze professionals jouw job zien",
		},
		"date-range": {
			label: "Start en einddatum",
			"error-date-before": "De start datum kan niet voor de einddatum",
		},
		"start-date": {
			label: "Start datum",
			"error-date-before": "De start datum kan niet voor de einddatum",
		},
		"end-date": {
			label: "Eind datum",
		},
		"estimated-duration": {
			label: "Geschatte tijdsduur (in uren)",
			help: "Hoelang denk jij dat de professional ongeveer met de opdracht bezig is",
		},
		category: {
			label: "Categorie",
			manual: "Staat jouw categorie er niet bij? Vul hem dan handmatig in",
		},
	},
	"company-contact-form": {
		alert: {
			title: "Contactgegevens aangepast",
			description: "Je contactgegevens zijn succesvol aangepast",
		},
		telephone: "Telefoonnummer",
		email: "Email",
		website: "Website",
	},
	"company-form": {
		"invoice-mail":
			"Factuur email (hier worden alle facturen per email naar verzonden)",
		"personal-invoice-mail": "Persoonlijke mail van de factuur ontvangen?",
		"company-name": "Bedrijfsnaam",
		"chamber-of-commerce": "KVK",
		street: "Straat",
		"house-number": "Huisnummer",
		zipcode: "Postcode",
		city: "Plaats",
		industry: "Branche",
		website: "Website",
		bio: "Omschrijving",
	},
	"company-signer": {
		"alert-error": {
			title: "Er is iets foutgegaan bij het opslaan",
			"description-delete": "Probeer het opnieuw",
			"description-put":
				"Controleer of alle velden zijn ingevuld en probeer het opnieuw",
		},
		"alert-success": {
			title: "Succesvol aangepast",
			description: "Je wijzigingen zijn succesvol aangepast",
		},
		switch: {
			label: "Maker van de opdracht moet ondertekenen",
			tip: "De persoon die verantwoordelijk is voor het ondertekenen van contracten",
		},
		email: "Email",
		"first-name": "Voornaam",
		"last-name": "Achternaam",
		"company-name": "Bedrijfsnaam",
	},
	"edit-address": {
		button: "Adres aanpassen",
		unknown: "Er is nog geen adres bekend om te kunnen bewerken",
		"complete-account": {
			description:
				"Voltooi eerst je account voordat je de gegevens kunt bewerken",
			button: "Account afronden",
		},
	},
	"edit-address-small": {
		title: "Woonadres",
		zipcode: "Postcode",
		"house-number": "Huisnummer",
	},
	"edit-bank-account": {
		button: "Bankgegevens aanpassen",
		title: "Bankgegevens aanpassen",
		description: "Hier worden opdrachten op uitbetaald",
	},
	"edit-interests": {
		placeholder: "Categorie...",
	},
	"edit-invoice-address": {
		switch: "Gebruik een ander factuuradres dan de bedrijfsinformatie",
		"company-name": "Bedrijfsnaam",
		contact: "Contactpersoon (t.a.v.)",
		street: "Straatnaam",
		zipcode: "Postcode",
		"house-number": "Huisnummer",
		city: "Stad",
		"alert-success": {
			title: "Je factuur adres is aangepast",
		},
		"alert-error": {
			title: "Fout bij het opslaan",
			"description-unknown": "Probeer het opnieuw",
			"description-fields": "Controleer alle velden",
		},
	},
	"edit-user": {
		"created-on": "Jouw account is aangemaakt op {{email}}",
		"first-name": "Voornaam",
		"last-name": "Achternaam",
		"date-of-birth": "Geboortedatum",
		"job-title": "Functie binnen het bedrijf",
		telephone: "Telefoonnummer",
		mobile: "Mobielnummer",
		"mobile-help":
			"Op dit mobiele nummer ontvangt u WhatsApp berichten wanneer er een nieuwe aanmelding is",
	},
	"reset-password": {
		description:
			"Om het wachtwoord te wijzigen heb je toegang nodig tot <1>{{email}}</1>. Je ontvangt een email waarmee het wachtwoord kan worden aangepast.",
		button: "Wijziging aanvragen",
		"alert-success": {
			title: "Gelukt!",
			description: "Je ontvangt een e-mail met een wijzigingslink",
		},
		"alert-error": {
			title: "Er is iets misgegaan",
		},
	},
	"subscription-feedback-form": {
		"rating-help": "5 = heel goed, 0 = heel slecht",
		periods: {
			"one-month": "1 maand",
			"three-month": "3 maanden",
			"six-month": "6 maanden",
			never: "Nooit😢",
		},
		platform: {
			title: "Hoeveel sterren geef je het platform?",
			improve: "Wat zouden wij kunnen verbeteren om een 5 te scoren?",
		},
		contact: {
			title: "Hoeveel sterren geef je het klantcontact?",
			improve: "Wat zouden wij kunnen verbeteren om een 5 te scoren?",
		},
		usage: "Wanneer denk je weer gebruik te maken van {{tenant.code}}?",
		reason: "Wat is de reden van pauzering?",
	},
	"subtasks-form": {
		title: "Shifts",
		description:
			"Shift omschrijvingen worden op basis van de opdrachtomschrijving gemaakt.",
		buttons: {
			next: "Volgende",
			skip: "Overslaan",
		},
		form: {
			"min-age": "Minimale leeftijd",
			shifts: {
				title: "Shifts instellen",
				add: "Shift toevoegen",
			},
		},
	},
	"subtask-form": {
		form: {
			title: "Shift titel",
			budget: "Shift waarde",
			"budget-per-hour": "Prijs per uur",
			amount: "Aantal professionals",
			"start-date": "Start datum & tijd",
			"end-date": "Eind datum & tijd",
		},
		detail: "De shift is van {{start}} tot {{end}}, totaal aantal uur: {{hour}}",
		"average-hour-rate": "Gemiddelde uurloon: {{price}}",
		errors: {
			"end-before-start": "De einddatum is voor de start datum",
		},
	},
};

export default forms;
