import { useLocation } from "react-router-dom";
import { ApiTask } from "src/api/types";
import Breadcrumb from "src/components/Breadcrumb";
import withTranslation, {
	Translation,
} from "src/components/hoc/withTranslation";
import Icon from "src/components/icon/Icon";
import useBoards from "src/hooks/api/services/tasks/useBoards";
import { useParseBackUrl } from "src/hooks/useBackUrl";

interface TaskDetailBreadcrumbsProps extends Translation {
	task: ApiTask;
}

const TaskDetailBreadcrumbs = ({ t, task }: TaskDetailBreadcrumbsProps) => {
	const back = useParseBackUrl();
	const { pathname } = useLocation();
	const { boards } = useBoards();
	const breadcrumbs: any[] = [
		{
			label: t("all-tasks"),
			to: "/tasks",
			icon: Icon.getFontAwesome("fas fa-home"),
		},
	];

	if (back && typeof back === "string" && back.startsWith("/tasks/boards/")) {
		const slug = back.replace("/tasks/boards/", "");
		const board = boards.find((board) => board.slug === slug);
		breadcrumbs.push({
			label: board?.name || "",
			to: `/tasks/boards/${slug}`,
		});
	}
	return (
		<Breadcrumb
			variant="dark"
			routes={[
				...breadcrumbs,
				{
					label: t("current"),
					to: pathname,
				},
			]}
		/>
	);
};

TaskDetailBreadcrumbs.locale = {
	nl: {
		home: "Taken",
		current: "Huidig",
		"all-tasks": "Alle taken",
	},
	en: {
		home: "Tasks",
		current: "Current",
		"all-tasks": "All tasks",
	},
};

export default withTranslation(TaskDetailBreadcrumbs);
