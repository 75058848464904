import { useParams } from "react-router-dom";
import EditableText, {
	EditableVariant,
} from "src/components/form/EditableText";
import withTranslation, {
	Translation,
} from "src/components/hoc/withTranslation";
import useTask from "src/hooks/api/services/tasks/task/useTask";
import { cn } from "src/lib/utils";

interface TaskEditableTitleProps extends Translation {
	className?: string;
	variant: EditableVariant;
	autoFocus?: boolean;
	disabled?: boolean;
}

const TaskEditableTitle = ({
	t,
	className,
	variant,
	autoFocus,
	disabled,
}: TaskEditableTitleProps) => {
	const { id } = useParams();
	const { task, actions } = useTask({ id });
	return (
		<EditableText
			className={cn(
				"bg-transparent placeholder:text-placeholder",
				className
			)}
			{...{ variant, autoFocus, disabled }}
			spellCheck={false}
			value={task.title}
			onChange={(e: any) => {
				let title = e.target.value;
				title = title.charAt(0).toUpperCase() + title.slice(1);
				actions.change({ title });
			}}
			placeholder={t("placeholder")}
		/>
	);
};

TaskEditableTitle.locale = {
	nl: {
		placeholder: "Titel...",
	},
	en: {
		placeholder: "Title...",
	},
};

export default withTranslation(TaskEditableTitle);
