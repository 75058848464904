import { useState } from "react";
import useApi from "src/hooks/api/utils/useApi";
import useAuth from "src/hooks/selectors/useAuth";

type Status = "idle" | "loading";

function useValidateSkills() {
	const auth = useAuth();
	const { state, actions, api } = useApi(
		{ id: `VALIDATE_SKILLS:${auth.hid}` },
		{
			baseUrl: `/resume/${auth.hid}/skills/validate`,
		}
	);
	const [experiencesStatus, setExperiencesStatus] = useState<Status>("idle");

	const setExperiences = (experiences: any[]) => {
		actions.set((state: any) => ({
			...state,
			custom: {
				...(state?.custom || {}),
				experiences,
			},
		}));
	};
	const experiences = async () => {
		setExperiencesStatus("loading");
		const { data } = await api.get("/experiences", {
			params: {
				with: ["categories", "skills"],
			},
		});
		setExperiencesStatus("idle");
		const experiences = data?.data || [];
		setExperiences(experiences);
		return experiences;
	};

	const validateExperience = async (experienceId: string, skills: any[]) => {
		await api.post(`/experiences`, {
			id: experienceId,
			skills,
		});
	};

	return {
		status: state.status,
		experiences: state?.custom?.experiences || [],
		statuses: {
			experiences: experiencesStatus,
		},
		actions: {
			experiences,
			validateExperience,
			setExperiences,
		},
		api,
	};
}

export default useValidateSkills;
