import withTranslation, {
	Translation,
} from "src/components/hoc/withTranslation";
import MiddlewareLayout from "src/components/middlewares/MiddlewareLayout";

interface NoSubscriptionMiddlewareProps extends Translation {
	title?: string;
	children?: any;
	tabs?: any;
}

const NoSubscriptionMiddleware = ({
	t,
	children,
	tabs,
	title,
}: NoSubscriptionMiddlewareProps) => {
	return (
		<MiddlewareLayout
			title={title || ""}
			{...{ tabs }}
			heading={t("title")}
		>
			{children}
			<div className="flex flex-col">
				<p className="max-w-md text-center">{t("description")}</p>
			</div>
		</MiddlewareLayout>
	);
};

NoSubscriptionMiddleware.locale = {
	nl: {
		title: "Je hebt nog geen abonnement!",
		description:
			"Om gebruik te kunnen maken van {{tenant.name}} heb je een actief abonnement nodig. Deze kan je gelukkig in een paar kleine stappen al aanmaken.",
		button: "Upgrade account",
	},
	en: {
		title: "You don't have an active subscription!",
		description:
			"To use {{tenant.name}}, you need an active subscription. You can create one in a few steps.",
		button: "Upgrade account",
	},
};

export default withTranslation(NoSubscriptionMiddleware);
