import { useEffect, useRef, useState } from "react";
import withTranslation, {
	Translation,
} from "src/components/hoc/withTranslation";
import Icon from "src/components/icon/Icon";
import { useUpdateEffectDebounce } from "src/hooks/useDebounce";
import { cn } from "src/lib/utils";

export interface SearchInputProps extends Translation {
	value?: string;
	placeholder?: string;
	onChange: (value: string) => void;
	defaultOpen?: boolean;
	theme?: "default" | "bordered";
	className?: string;
	inputClassName?: string;
}

const SearchInput = ({
	t,
	value,
	placeholder,
	onChange,
	defaultOpen,
	theme = "default",
	className,
	inputClassName,
}: SearchInputProps) => {
	const input = useRef() as any;
	const [_visible, setVisible] = useState(false);
	const [search, setSearch] = useState(value);
	const visible = defaultOpen || _visible;
	useUpdateEffectDebounce(search, 1000, (value) => {
		onChange(value);
	});

	useEffect(() => {
		setSearch(value);
	}, [value]);

	const toggle = () => {
		setVisible(!visible);
	};

	return (
		<div
			className={cn(
				"flex items-center gap-2 rounded-md h-max",
				visible && "bg-accent",
				theme === "bordered" && "border border-border bg-accent",
				className
			)}
		>
			<Icon
				onClick={toggle}
				iconFontSize={14}
				className={cn("cursor-pointer text-placeholder w-9 h-9")}
				icon={{
					font_awesome: {
						name: "far fa-search",
					},
				}}
			/>
			{(theme === "bordered" || visible || value) && (
				<input
					ref={input}
					type="text"
					name="search"
					value={search || ""}
					autoFocus
					onBlur={() => {
						if (!value) {
							setVisible(false);
						}
					}}
					onChange={(e) => setSearch(e?.target?.value)}
					className={cn(
						"placeholder-placeholder bg-transparent text-sm py-2",
						inputClassName
					)}
					placeholder={placeholder || t("placeholder")}
				/>
			)}
		</div>
	);
};

SearchInput.locale = {
	nl: {
		placeholder: "Zoeken...",
	},
	en: {
		placeholder: "Search...",
	},
};

export default withTranslation(SearchInput);
