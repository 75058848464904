import axios from "axios";
import { useEffect, useState } from "react";
import { Navigate, useParams } from "react-router-dom";
import Avatar from "src/components/Avatar";
import Button from "src/components/Button";
import SignupForm from "src/components/forms/auth/signup/SignupForm";
import withTranslation, {
	Translation,
} from "src/components/hoc/withTranslation";
import AuthLayout from "src/components/layout/auth-layout/AuthLayout";
import Link from "src/components/Link";
import useAuth from "src/hooks/selectors/useAuth";
import useQueryString from "src/hooks/useQueryString";
import { cn } from "src/lib/utils";

function SignupWithConnectionView({ t }: Translation) {
	const params = useParams();
	const auth = useAuth();
	const qs = useQueryString();
	const [company, setCompany] = useState<{
		id: string;
		name: string;
		avatar: string;
		is_connected: boolean;
	}>();
	const [isCompanyFetched, setIsCompanyFetched] = useState(false);
	const [isJoined, setIsJoined] = useState(false);

	useEffect(() => {
		if (params.slug) {
			axios.get(`/companies/${params.slug}`).then(({ data }) => {
				setCompany(data?.data);
				setIsCompanyFetched(true);
				setIsJoined(data?.data?.is_connected);
			});
		}
	}, [params.slug]);

	const handleJoinNetwork = async () => {
		setIsJoined(false);
		try {
			await axios.post(`/connections/company/${company?.id}`);
			setIsJoined(true);
		} catch (error) {
			setIsJoined(false);
		}
	};

	if (!params.slug) {
		return <Navigate to="/signup" />;
	}

	return (
		<AuthLayout
			loading={!isCompanyFetched}
			head={{
				title: t("title"),
			}}
		>
			{company && (
				<>
					<div className="flex flex-col md:flex-row gap-4 md:items-end mb-4">
						{company.avatar && (
							<Avatar
								size="large"
								src={company.avatar}
								className="border border-border"
							/>
						)}
						<div className="flex flex-col flex-1">
							<p className="text-xl opacity-70">
								{t("invited-by")}
							</p>
							<p className="text-2xl font-bold">{company.name}</p>
						</div>
					</div>
					{!auth?.id && (
						<div className="flex flex-col gap-4">
							<SignupForm
								connection={
									company
										? {
												id: company.id,
												type: "COMPANY",
												invite_id:
													qs?.invite_connection_id?.toString(),
										  }
										: undefined
								}
							/>
							<Link to="/login">
								<span
									dangerouslySetInnerHTML={{
										__html: t("login"),
									}}
								></span>
							</Link>
						</div>
					)}

					{auth?.id && (
						<div className="flex flex-col">
							<Button
								onClick={handleJoinNetwork}
								type={isJoined ? "primary" : undefined}
							>
								<span className="relative flex items-center">
									<i
										className={cn(
											"far fa-check absolute -left-10 transition-all duration-300 opacity-0",
											isJoined && "opacity-100"
										)}
									></i>
									{t(
										isJoined
											? "join.button-joined"
											: "join.button"
									)}
								</span>
							</Button>

							<div
								className={cn(
									"flex flex-col max-h-0 overflow-hidden opacity-0 transition-all duration-300",
									isJoined && "max-h-40 opacity-100"
								)}
							>
								<p
									className="mt-2"
									dangerouslySetInnerHTML={{
										__html: t("join.success", {
											name: company.name,
										}),
									}}
								></p>
							</div>
						</div>
					)}
				</>
			)}
		</AuthLayout>
	);
}

SignupWithConnectionView.locale = {
	nl: {
		title: "Aanmelden",
		"invited-by": "Je bent uitgenodigd door",
		join: {
			button: "Join netwerk",
			"button-joined": "Netwerk gekoppeld",
			success:
				"Je bent toegevoegd aan het netwerk van <strong>{{name}}</strong>, zij kunnen je nu uitnodigen voor opdrachten.",
		},
		login: "Heb je al een al een account? <span class='underline'>Log dan eerst in</span>",
	},
	en: {
		title: "Sign up",
		"invited-by": "You are invited by",
		join: {
			button: "Join network",
			"button-joined": "Network connected",
			success:
				"You are added to the network of <strong>{{name}}</strong>, they can now invite you for jobs.",
		},
		login: "Have an account already? <span class='underline'>Log in first</span>",
	},
};
export default withTranslation(SignupWithConnectionView);
