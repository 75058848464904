import { motion } from "framer-motion";
import Loader from "src/components/Loader";
import Logo from "src/components/Logo";
import withTranslation, {
	Translation,
} from "src/components/hoc/withTranslation";
import useTenant from "src/hooks/api/services/tenants/useTenant";
import useTheme from "src/hooks/useTheme";
import { cn } from "src/lib/utils";

interface MessageProps extends Translation {
	value: any;
	variant: "USER" | "SYSTEM";
	loading: boolean;
	className?: string;
	theme?: {
		body?: string;
		card?: string;
	};
}

const Message = ({
	t,
	value,
	variant,
	loading,
	className,
	theme: classNameTheme,
}: MessageProps) => {
	const { tenant } = useTenant();
	const { theme } = useTheme();

	return (
		<div
			className={cn(
				"flex justify-start gap-2",
				variant === "USER" && "flex-row-reverse items-end",
				className
			)}
		>
			<div
				className={cn(
					"flex flex-col gap-1 max-w-3/4",
					classNameTheme?.body
				)}
			>
				{variant === "SYSTEM" && (
					<div className="flex items-center gap-1">
						<Logo
							color={theme === "dark" ? "white" : "dark"}
							variant="small-outline"
							className="w-4 h-4"
						/>
						<small>{tenant.code}</small>
					</div>
				)}
				{loading ? (
					<div className="p-4">
						<Loader />
					</div>
				) : (
					<motion.div
						initial={{
							scale: 0.8,
							y: 20,
						}}
						animate={{ scale: 1, y: 0 }}
						className={cn(
							"flex flex-col gap-2 p-4 rounded-xl bg-accent dark:bg-background max-w-[380px]",
							variant === "SYSTEM" && "rounded-tl-sm",
							variant === "USER" &&
								"items-end mt-0 mb-4 bg-primary text-primary-foreground rounded-br-sm",
							classNameTheme?.card
						)}
					>
						{typeof value === "string" ? (
							<p className="description">{value}</p>
						) : (
							value
						)}
					</motion.div>
				)}
			</div>
		</div>
	);
};

Message.locale = {
	nl: {
		loading: "Aan het denken...",
		me: "Ik",
	},
	en: {
		loading: "Thinking...",
		me: "Me",
	},
};

export const Loading = () => {
	const { tenant } = useTenant();
	const { theme } = useTheme();
	return (
		<div className={cn("flex justify-start gap-2")}>
			<div className={cn("flex flex-col gap-1")}>
				<div className="flex items-center gap-1">
					<Logo
						color={theme === "dark" ? "white" : "dark"}
						variant="small-outline"
						className="w-4 h-4"
					/>
					<small>{tenant.code}</small>
				</div>
				<div className="p-4">
					<Loader />
				</div>
			</div>
		</div>
	);
};

export default withTranslation(Message);
