import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import ReactTooltip from "react-tooltip";
import { Formik } from "formik";
import * as Yup from "yup";
import Button from "src/components/Button";
import Input from "src/components/form/Input";
import useAlert from "src/hooks/useAlert";
import store from "src/redux";
import { updateProfile } from "src/redux/auth";
import Switch from "src/components/form/Switch";
import { useTranslation } from "react-i18next";

const defaultFormDataState = {
	email: "",
	first_name: "",
	last_name: "",
	company_name: "",
};

const CompanySignerForm = () => {
	const { t } = useTranslation(["forms", "schemes", "buttons"]);

	const auth = useSelector((state: any) => state.auth);
	const [loadingDelete, setLoadingDelete] = useState(false);
	const [formData, setFormData] = useState(defaultFormDataState);
	const [creatorIsSigner, setCreatorIsSigner] = useState(true);
	const [sendAlert] = useAlert();
	const dispatch = useDispatch();

	const sendAlertProps = (type: "delete" | "put") => {
		const texts = {
			delete: t("alert-error.description-delete", {
				ns: "forms",
				keyPrefix: "company-signer",
			}),
			put: t("alert-error.description-put", {
				ns: "forms",
				keyPrefix: "company-signer",
			}),
		};

		return {
			type: "error",
			title: t("alert-error.title", {
				ns: "forms",
				keyPrefix: "company-signer",
			}),
			text: texts[type],
		};
	};
	const SignerScheme = Yup.object().shape({
		email: Yup.string()
			.email(t("email", { ns: "schemes" }))
			.required(t("required", { ns: "schemes" })),
		first_name: Yup.string().required(t("required", { ns: "schemes" })),
		last_name: Yup.string().required(t("required", { ns: "schemes" })),
		company_name: Yup.string().nullable(),
	});

	useEffect(() => {
		if (auth?.company?.company_signer) {
			setCreatorIsSigner(false);
			const { email, first_name, last_name, company_name } =
				auth.company.company_signer;
			setFormData({ email, first_name, last_name, company_name });
		} else {
			setCreatorIsSigner(true);
			setFormData(defaultFormDataState);
		}
	}, [auth.company]);

	const handleSwitch = (value: boolean) => {
		setLoadingDelete(true);

		axios
			.delete("/company/company-signer")
			.then(() => {
				let company_signer = auth.company.company_signer
					? null
					: defaultFormDataState;

				store.dispatch(
					updateProfile({
						...auth,
						company: { ...auth.company, company_signer },
					})
				);
			})
			.catch(() => sendAlert(sendAlertProps("delete")))
			.finally(() => setLoadingDelete(false));
	};

	return (
		<div className="flex flex-col">
			<div
				data-tip
				data-for="company-signer-tooltip"
				className="row flex justify-between flex-wrap md:flex-nowrap"
			>
				<ReactTooltip id="company-signer-tooltip" effect="solid">
					{t("switch.tip", {
						ns: "forms",
						keyPrefix: "company-signer",
					})}
				</ReactTooltip>
				<Switch
					value={creatorIsSigner}
					onChange={handleSwitch}
					label={t("switch.label", {
						ns: "forms",
						keyPrefix: "company-signer",
					})}
					fetching={loadingDelete}
				/>
			</div>

			{!creatorIsSigner && (
				<>
					<Formik
						initialValues={formData}
						validationSchema={SignerScheme}
						onSubmit={(values, { setSubmitting }) => {
							setSubmitting(true);
							axios
								.put("/company/company-signer", values)
								.then(() => {
									dispatch(
										updateProfile({
											...auth,
											company: {
												...auth.company,
												company_signer: { ...values },
											},
										})
									);
									sendAlert({
										type: "success",
										title: t("alert-success.title", {
											ns: "forms",
											keyPrefix: "company-signer",
										}),
										text: t("alert-success.description", {
											ns: "forms",
											keyPrefix: "company-signer",
										}),
									});
								})
								.catch(() => sendAlert(sendAlertProps("put")))
								.finally(() => setSubmitting(false));
						}}
					>
						{({
							values,
							errors,
							touched,
							handleChange,
							handleBlur,
							handleSubmit,
							isSubmitting,
						}) => (
							<form
								className="mt-6 flex flex-col gap-4"
								onSubmit={handleSubmit}
							>
								<Input
									value={values.email}
									name="email"
									label={t("email", {
										ns: "forms",
										keyPrefix: "company-signer",
									})}
									valid={values.email && !errors.email}
									error={
										errors.email &&
										touched.email &&
										errors.email
									}
									{...{ handleChange, handleBlur }}
								/>
								<Input
									label={t("first-name", {
										ns: "forms",
										keyPrefix: "company-signer",
									})}
									value={values.first_name}
									name="first_name"
									valid={
										values.first_name && !errors.first_name
									}
									error={
										errors.first_name &&
										touched.first_name &&
										errors.first_name
									}
									{...{ handleChange, handleBlur }}
								/>
								<Input
									label={t("last-name", {
										ns: "forms",
										keyPrefix: "company-signer",
									})}
									value={values.last_name}
									name="last_name"
									valid={
										values.last_name && !errors.last_name
									}
									error={
										errors.last_name &&
										touched.last_name &&
										errors.last_name
									}
									{...{ handleChange, handleBlur }}
								/>
								<Input
									label={t("company-name", {
										ns: "forms",
										keyPrefix: "company-signer",
									})}
									optional
									value={values.company_name}
									name="company_name"
									valid={
										values.company_name &&
										!errors.company_name
									}
									error={
										errors.company_name &&
										touched.company_name &&
										errors.company_name
									}
									{...{ handleChange, handleBlur }}
								/>
								<div className="flex">
									<Button submit loading={isSubmitting}>
										{t("edit", { ns: "buttons" })}
									</Button>
								</div>
							</form>
						)}
					</Formik>
				</>
			)}
		</div>
	);
};

export default CompanySignerForm;
