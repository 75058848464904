import { Moment } from "moment";
import { createContext, useContext } from "react";

export type DateRangeFilterValue = {
	start: Moment;
	end: Moment | null;
};

type DateRangeFilterContextType = {
	value: DateRangeFilterValue;
	onChange: (value: DateRangeFilterValue) => void;
};

export const DateRangeFilterContext = createContext<DateRangeFilterContextType>(
	{} as DateRangeFilterContextType
);

export function useDateRangeFilter() {
	const props = useContext(DateRangeFilterContext);
	return props;
}
