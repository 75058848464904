import qs from "query-string";
import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { ApiRegistration, ApiTask } from "src/api/types";
import Modal from "src/components/Modal";
import CheckoutPaymentMethods from "src/components/checkout/CheckoutPaymentMethods";
import ManualCheckoutSummary from "src/components/checkout/ManualCheckoutSummary";
import Field from "src/components/field/Field";
import Switch from "src/components/form/Switch";
import withTranslation, {
	Translation,
} from "src/components/hoc/withTranslation";
import RegistrationNoMerchant from "src/components/tasks/TaskForm/elements/RegistrationNoMerchant";
import useTenant from "src/hooks/api/services/tenants/useTenant";
import usePost from "src/hooks/rest/usePost";
import useAuth from "src/hooks/selectors/useAuth";
import useSignature from "src/hooks/selectors/useSignature";
import useWindowSize from "src/hooks/useWindowSize";
import { cn } from "src/lib/utils";

interface RegistrationAcceptModalProps extends Translation {
	visible?: boolean;
	onClose: (data?: ApiRegistration) => Promise<unknown>;
	registration: ApiRegistration;
	task: ApiTask;
}

const RegistrationAcceptModal = ({
	t,
	visible,
	onClose,
	registration,
	task,
}: RegistrationAcceptModalProps) => {
	const auth = useAuth();
	const { tenant } = useTenant();
	const { isTablet } = useWindowSize();
	const location = useLocation();
	const url =
		registration.status === "matched"
			? `/company/registrations/${registration?.id}/request-payment`
			: `/company/registrations/${registration?.id}/confirm`;
	const [postConfirm] = usePost(url);
	const { signature } = useSignature();
	const [projectCode, setProjectCode] = useState<string | null | undefined>();
	const [isSignAutomatic, setIsSignAutomatic] = useState(
		signature?.id ? true : false
	);
	const [pending, setPending] = useState(undefined) as any;

	useEffect(() => {
		if (signature?.id) {
			setIsSignAutomatic(true);
		}
	}, [signature]);

	const handleClose = async (registration?: ApiRegistration) => {
		if (onClose) await onClose(registration);
	};

	const handleConfirm = async (_type: string, issuer?: string) => {
		let adminUrl = tenant?.urls?.account;
		let params = {
			payment_return: 1,
		} as any;
		if (registration?.hid) {
			params.rid = registration.hid;
		}

		const query = qs.stringify(params);
		if (location.pathname?.endsWith("/accept") && registration?.hid) {
			adminUrl = `${adminUrl}/tasks/${task.hid}/show/${registration.hid}`;
		}
		adminUrl = `${adminUrl}?${query}`;

		setPending(_type);
		try {
			const params: any = {
				type: _type,
				redirect_url: adminUrl,
				issuer,
				project_code: projectCode ? projectCode : undefined,
			};
			if (registration.status !== "matched") {
				params.is_sign_direct = isSignAutomatic ? 1 : 0;
			}
			const { data } = await postConfirm(params);
			const { payment } = data;

			if (data?.redirect_url) {
				window.location = data.redirect_url;
			} else if (payment && payment.redirect_url) {
				window.location = payment.redirect_url;
			} else {
				handleClose(data);
			}
			setPending(undefined);
		} catch (error) {
			setPending(undefined);
		}
	};

	return (
		<Modal
			title={t("title")}
			size="custom"
			className={cn(
				"overflow-auto hide-default-scrollbar w-full max-w-xl",
				!registration.has_merchant && "max-w-md",
				isTablet && "w-[90%] max-w-full max-h-[70vh]"
			)}
			visible={visible || false}
			onClose={handleClose}
		>
			{!registration.has_merchant ? (
				<RegistrationNoMerchant {...{ registration }} />
			) : (
				<div className="flex flex-col md:flex-row gap-6">
					<div className="flex flex-col md:border-r md:border-border pr-6 flex-1">
						<ManualCheckoutSummary
							variant="light"
							products={[
								{
									description: task.title,
									price: task.budget,
									vat: 0,
								},
								{
									description: t("service-fee", {
										rate: Math.round(
											auth.service_rate * 100
										),
									}),
									price: tenant.modules.includes(
										"fee-in-payment"
									)
										? task.budget * auth.service_rate
										: 1,
									vat: 0.21,
								},
							]}
						/>
						<p className="mt-4 text-sm">
							{t("payment-description")}
						</p>
					</div>
					<div className="flex flex-col flex-1">
						<CheckoutPaymentMethods
							variant="light"
							methods={[
								"ideal",
								"invoice",
								"afterpay",
								"mandate",
							]}
							onSubmit={handleConfirm}
							type="registration"
							{...{ pending }}
							extra={
								<>
									<div className="flex flex-col gap-2 mb-8">
										<strong>{t("project.label")}</strong>
										<Field.Input
											wrapper={{
												description: t("project.help"),
											}}
											className="border border-border w-full"
											placeholder={t(
												"project.placeholder"
											)}
											onChange={(value) =>
												setProjectCode(value)
											}
											autoFill="payment_project_codes"
											value={projectCode || ""}
										/>
									</div>
									{registration?.status !== "matched" && (
										<div className="flex flex-col gap-2">
											<strong>
												{t("agreement.label")}
											</strong>
											<Switch
												label={t("sign-direct")}
												onChange={setIsSignAutomatic}
												value={isSignAutomatic}
												subLabel={
													<>
														<Link
															target="_blank"
															className="font-bold underline"
															to={`/signer/${task?.hid}/registration/${registration?.hid}/preview`}
														>
															{t("click-here")}
														</Link>{" "}
														{t(
															"sign-direct-sublabel"
														)}
													</>
												}
											/>
										</div>
									)}
								</>
							}
						/>
					</div>
				</div>
			)}
		</Modal>
	);
};

RegistrationAcceptModal.locale = {
	nl: {
		title: "Aanmelding goedkeuren",
		"payment-description":
			"Deze betaling wordt vastgehouden op een derdenrekening, wanneer jij een positieve review geeft wordt het bedrag doorgestort naar de professional.",
		"sign-direct": "Automatisch de overeenkomst tekenen",
		"sign-direct-sublabel": "om de overeenkomst te bekijken",
		"service-fee": "Successfee ({{rate}}%)",
		"click-here": "Klik hier",
		project: {
			label: "Projectcode",
			placeholder: "Bijv. A123",
			help: "Deze code wordt genoteerd op de factuur",
		},
		agreement: {
			label: "Overeenkomst",
		},
	},
	en: {
		title: "Accept registration",
		"payment-description":
			"The payment will be held on a third-party account, when you give a positive review the amount will be transferred to the professional.",
		"sign-direct": "Sign the agreement automatically",
		"sign-direct-sublabel": "to view the agreement",
		"service-fee": "Successfee ({{rate}}%)",
		"click-here": "Click here",
		project: {
			label: "Project code",
			placeholder: "For example A123",
			help: "This code will be noted on the invoice",
		},
		agreement: {
			label: "Agreement",
		},
	},
};

export default withTranslation(RegistrationAcceptModal);
