import { useState } from "react";
import { useTranslation } from "react-i18next";
import InfiniteScroll from "react-infinite-scroll-component";
import Button from "src/components/Button";
import EmptyState from "src/components/EmptyState";
import FlatListHead from "src/components/FlatList/FlatListHead";
import Pagination from "src/components/FlatList/Pagination";
import Grid from "src/components/Grid";
import Skeleton from "src/components/Skeleton";
import Table from "src/components/Table";
import Input from "src/components/form/Input";
import useAuth from "src/hooks/selectors/useAuth";
import { useUpdateEffectDebounce } from "src/hooks/useDebounce";
import "./flat-list.scss";

type FlatListProps = {
	variant: "card" | "flat";
	loading?: boolean;
	fetching?: boolean;
	data: any[];
	renderItem: (item: any, index?: number) => void;
	renderLoadingItem?: (index: number) => any;
	loadingRows: number;
	empty: {
		title: string;
		to?: string;
		text?: string;
		href?: string;
		children?: any;
	};
	pagination?: {
		setPage: (page: number) => any;
		page: number;
		last_page: number;
	};
	paginationType: "button" | "pages";
	columns?: number | string;
	className?: string;
	loadingClassName?: string;
	noInfiniteScroll?: boolean;
	onNext?: () => void;
	loadingNext?: boolean;
	hasMore?: boolean;
	header?: any;
	table?: {
		headers: any[];
		footer?: any;
	};
	style?: any;
	horizontal?: boolean;
	handleSearch?: (q: string) => any;
	searchPlaceholder?: string;
	classes?: any;
	gap?: number;
	title?: any;
	searchActions?: any;
	meta?: any;
	formatTotalTitle?: (total: number) => any;
};

const FlatList = ({
	fetching: _fetching,
	variant,
	renderItem,
	data,
	loading,
	empty,
	columns,
	className,
	header,
	style,
	onNext,
	loadingNext,
	hasMore,
	table,
	horizontal,
	handleSearch,
	gap,
	noInfiniteScroll,
	title,
	searchActions,
	loadingClassName,
	searchPlaceholder,
	meta,
	formatTotalTitle,
	pagination,
	renderLoadingItem,
	loadingRows,
}: FlatListProps) => {
	const auth = useAuth();
	const { t } = useTranslation(["form", "buttons"]);
	const [fetching, setFetching] = useState(false);
	const [query, setQuery] = useState("");
	useUpdateEffectDebounce(query, 1000, (q) => {
		if (handleSearch && !fetching) {
			setFetching(true);
			handleSearch(q).then(() => {
				setFetching(false);
			});
		}
	});

	// if (loading && !table && !renderLoadingItem) {
	// 	return (
	// 		<div
	// 			{...{ style }}
	// 			className={`flat-list-loading ${loadingClassName} ${className}`}
	// 		>
	// 			<span className="base-loader"></span>
	// 		</div>
	// 	);
	// }

	const renderSearchInput = () => (
		<div className="flex flex-col-reverse items-stretch md:flex-row gap-2 mb-2">
			{searchActions}
			<Input
				{...{ loading }}
				autoFocus
				icon={<i className="far fa-search" />}
				name="search"
				placeholder={
					searchPlaceholder ||
					t("placeholder.search.keyword", { ns: "form" })
				}
				value={query}
				className="p-0"
				noMarginBottom
				onChange={(key, value) => setQuery(value)}
			/>
		</div>
	);

	const renderLoadMore = () => {
		if (!data?.length || !hasMore) {
			return <></>;
		}
		if (auth.type === "company") {
			return (
				<Button
					small
					type="white"
					onClick={onNext}
					loading={loading || loadingNext || fetching}
				>
					{t("load-more", { ns: "buttons" })}
				</Button>
			);
		}

		return (
			<Button
				small
				type="dark"
				onClick={onNext}
				loading={loading || loadingNext}
			>
				{t("load-more", { ns: "buttons" })}
			</Button>
		);
	};

	const renderGrid = () => {
		if (columns === 1 || !columns) {
			return (
				<div style={{ gap }} className="flex flex-col">
					{header}
					{loading && renderLoadingItem && data.length === 0 && (
						<>
							{new Array(loadingRows || 12)
								.fill(" ")
								.map((_, index) => renderLoadingItem(index))}
						</>
					)}
					{data.map(renderItem)}
				</div>
			);
		}
		return (
			<Grid {...{ columns, gap }} className="flat-list-content">
				{header}
				{loading && renderLoadingItem && data.length === 0 && (
					<>
						{new Array(loadingRows || 12)
							.fill(" ")
							.map((_, index) => renderLoadingItem(index))}
					</>
				)}
				{data.map(renderItem)}
			</Grid>
		);
	};

	const renderChild = () => {
		if (onNext && data?.length) {
			if (noInfiniteScroll) {
				return (
					<>
						{renderGrid()}
						{hasMore && (
							<div className="flat-list-load-more">
								{renderLoadMore()}
							</div>
						)}
					</>
				);
			}

			return (
				<InfiniteScroll
					dataLength={data.length}
					next={onNext}
					hasMore={hasMore || false}
					scrollableTarget="scrollableDiv"
					loader={
						<div className="flat-list-has-more">
							<span className="base-loader"></span>
						</div>
					}
				>
					{renderGrid()}
					{hasMore && (
						<div className="flat-list-load-more">
							{renderLoadMore()}
						</div>
					)}
				</InfiniteScroll>
			);
		}

		return (
			<div className="flex flex-col flex-1">
				{renderGrid()}
				<div className="flex-1"></div>
				{pagination && (
					<Pagination
						className="py-4"
						page={{
							current: pagination.page,
							max: pagination.last_page,
						}}
						fetching={loading || false}
						onNavigate={pagination.setPage}
					/>
				)}
			</div>
		);
	};

	if (table) {
		const renderTable = () => (
			<div className="flex flex-col flex-1 gap-4">
				{meta && meta?.total !== 0 && formatTotalTitle && (
					<strong>{formatTotalTitle(meta.total)}</strong>
				)}
				{handleSearch && renderSearchInput()}
				<Table
					{...{ variant }}
					fetching={_fetching || fetching}
					footer={
						pagination ? (
							<>
								{table?.footer}
								{pagination.last_page !== 1 && (
									<tr className="py-4">
										<Table.Cell
											colSpan={table.headers.length}
										>
											<Pagination
												page={{
													current: pagination.page,
													max: pagination.last_page,
												}}
												fetching={loading || false}
												onNavigate={pagination.setPage}
											/>
										</Table.Cell>
									</tr>
								)}
							</>
						) : (
							table.footer
						)
					}
					headers={table.headers}
				>
					{loading && (
						<>
							{new Array(loadingRows || 12)
								.fill(" ")
								.map((_, index) => {
									if (renderLoadingItem) {
										return renderLoadingItem(index);
									}
									return (
										<Table.Row>
											{table.headers.map((head) => (
												<Table.Cell
													key={`loading-${head}-${index}`}
												>
													<Skeleton className="h-6 w-full" />
												</Table.Cell>
											))}
										</Table.Row>
									);
								})}
						</>
					)}

					{data.length === 0 && !loading && (
						<Table.Row>
							<Table.Cell colSpan={table.headers?.length}>
								<div className="flex flex-1 justify-center items-center p-8 text-center flex-col max-w-md mx-auto">
									<div className="flex flex-col gap-1">
										<EmptyState.Title>
											{empty.title}
										</EmptyState.Title>
										<EmptyState.Description>
											{empty.text}
										</EmptyState.Description>
									</div>
								</div>
							</Table.Cell>
						</Table.Row>
					)}
					{!loading && data.map(renderItem)}
				</Table>
			</div>
		);
		if (data?.length) {
			return (
				<>
					{renderTable()}
					{hasMore && onNext && !pagination && (
						<div className="flat-list-load-more">
							{renderLoadMore()}
						</div>
					)}
				</>
			);
		}
		return renderTable();
	}

	return (
		<div
			{...{ style }}
			className={`flat-list flex flex-col flex-1 ${
				horizontal ? "flat-list-horizontal" : ""
			} ${className}`}
		>
			{handleSearch && (
				<div className="mb-2 flex flex-col">{renderSearchInput()}</div>
			)}
			{title && (
				<div className="flex mb-2">
					<strong>{title}</strong>
				</div>
			)}

			{!data.length && !table && !loading ? (
				<div className="flex flex-col gap-8 justify-center items-center flex-1 p-8">
					{empty.to || empty.href ? (
						<>
							<p
								className={
									!empty.to
										? "mb-0 text-center"
										: "text-center"
								}
							>
								{empty.title}
							</p>

							{empty.to && (
								<Button to={empty.to}>{empty.text}</Button>
							)}
							{empty.href && (
								<Button href={empty.href}>{empty.text}</Button>
							)}
						</>
					) : (
						<div className="flex flex-col flex-1 justify-center items-center text-center gap-1">
							<div className="flex flex-col gap-1">
								<EmptyState.Title>
									{empty.title}
								</EmptyState.Title>
								<EmptyState.Description>
									{empty.text}
								</EmptyState.Description>
							</div>
							{empty.children && empty.children}
						</div>
					)}
				</div>
			) : (
				renderChild()
			)}
		</div>
	);
};

FlatList.defaultProps = {
	paginationType: "button",
	loadingRows: 12,
	variant: "card",
};

FlatList.Head = FlatListHead;

export default FlatList;
