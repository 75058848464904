import { useParams } from "react-router-dom";
import Field from "src/components/field/Field";
import withTranslation, {
	Translation,
} from "src/components/hoc/withTranslation";
import useTask from "src/hooks/api/services/tasks/task/useTask";

interface TaskDetailFieldsProps extends Translation {
	className?: string;
}

const TaskDetailFields = ({ t, className }: TaskDetailFieldsProps) => {
	const { id } = useParams();
	const { task, actions } = useTask({ id });

	return (
		<div className={`flex flex-col gap-2 ${className}`}>
			{task.type === "INTERNAL" && (
				<>
					<Field.Status
						wrapper={{ label: "Status", layout: "horizontal" }}
						value={task.status}
						onChange={(value) => {
							actions.change({
								status: value,
							});
						}}
					/>
					<Field.Date
						wrapper={{ label: "Deadline", layout: "horizontal" }}
						value={task.end_date}
						calendar={{
							clearable: true,
						}}
						onChange={(value) => {
							actions.change({
								end_date: value,
							});
						}}
					/>
					<Field.Tag
						canCreate
						wrapper={{ label: "Tags", layout: "horizontal" }}
						value={(task?.tags || []).map(
							(tag: any) => tag?.tag || tag
						)}
						onChange={(tags) => {
							actions.change({
								tags,
							});
						}}
					/>
					<Field.Users
						wrapper={{
							label: t("fields.assignees.label"),
							layout: "horizontal",
						}}
						value={
							task.assignees
								? task.assignees.map((user: any) =>
										typeof user === "object"
											? user.id.toString()
											: user
								  )
								: []
						}
						onChange={(value) => {
							actions.change({
								assignees: value,
							});
						}}
					/>
					<Field.Project
						wrapper={{
							label: t("fields.project.label"),
							layout: "horizontal",
						}}
						value={task?.links?.project || undefined}
						onChange={(project) => {
							actions.change({
								project: project || null,
								links: {
									...task?.links,
									project: project || null,
								},
							});
						}}
					/>
					<Field.Time
						wrapper={{
							label: t("fields.estimated.label"),
							layout: "horizontal",
						}}
						value={task?.estimated_duration}
						onChange={(estimated_duration) => {
							actions.change({
								estimated_duration: estimated_duration || null,
							});
						}}
					/>
					<Field.File
						wrapper={{
							label: t("fields.files.label"),
							layout: "horizontal",
						}}
						value={task?.media || []}
						onChange={(media) => {
							actions.change({
								media,
							});
						}}
					/>
				</>
			)}
		</div>
	);
};

TaskDetailFields.locale = {
	nl: {
		title: "",
		fields: {
			assignees: {
				label: "Uitvoerders",
			},
			project: {
				label: "Project",
			},
			estimated: {
				label: "Geschatte tijdsduur",
			},
			files: {
				label: "Bestanden",
			},
		},
	},
	en: {
		title: "",
		fields: {
			assignees: {
				label: "Assignees",
			},
			project: {
				label: "Project",
			},
			files: {
				label: "Files",
			},
			estimated: {
				label: "Estimated duration",
			},
		},
	},
};

export default withTranslation(TaskDetailFields);
