import axios from "axios";
import { useEffect, useState } from "react";
import {
	ApiTask,
	ApiRegistration,
	ApiOffer,
	ApiOfferRegistration,
} from "src/api/types";
import useOfferRegistration from "src/hooks/api/useOfferRegistration";
import useChatParams from "src/hooks/useChatParams";

type ApiData = {
	task?: ApiTask;
	offer?: ApiOffer;
	registration?: ApiOfferRegistration | ApiRegistration;
	type: "offer" | "task";
};

interface ChatProviderProps {
	id: string;
	children: (loading: boolean, data?: ApiData) => any;
}

const ChatProvider = ({ children, id }: ChatProviderProps) => {
	const params = useChatParams(id);
	const [isLoading, setIsLoading] = useState(false);
	const { fetchData: getOfferRegistration } = useOfferRegistration(
		params?.registrationId
	);
	const [data, setData] = useState<ApiData>({} as ApiData);

	const get = async (url: string) => {
		const { data } = await axios.get(url);
		return data;
	};

	const fetchData = async () => {
		if (params.type === "offer") {
			const registration = await getOfferRegistration();
			return {
				offer: registration?.offer,
				registration,
				type: "offer",
			} as ApiData;
		}

		const { data: task } = await get(`/tasks/${params.taskId}`);
		const { data: registration } = await get(
			`/tasks/${params.taskId}/registrations/${params.registrationId}`
		);
		return {
			task,
			registration,
			type: "task",
		} as ApiData;
	};

	useEffect(() => {
		if (!isLoading) {
			setIsLoading(true);
			fetchData().then((res: ApiData) => {
				setData(res);
				setIsLoading(false);
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [id]);

	return children(isLoading, data);
};
export default ChatProvider;
