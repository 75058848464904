import moment from "moment";
import { ComponentProps, useMemo } from "react";
import { ApiFormField } from "src/api/types";
import Field from "src/components/field/Field";
import withTranslation, {
	Translation,
} from "src/components/hoc/withTranslation";
import useHoursBetweenDates from "src/hooks/useHoursBetweenDates";
import { cn } from "src/lib/utils";

interface CustomFormFieldProps extends Translation {
	field: ApiFormField;
	value?: any;
	onChange: (value: any) => void;
	layout?: "horizontal" | "vertical";
}

const CustomFormField = ({
	t,
	className,
	field,
	value,
	onChange,
	layout = "vertical",
	...rest
}: CustomFormFieldProps & Omit<ComponentProps<"div">, "children">) => {
	const hours = useHoursBetweenDates(value?.start_date, value?.end_date);
	const props = useMemo(() => {
		return {
			wrapper: {
				layout:
					layout === "horizontal" ? "horizontal-always" : "vertical",
				label: field.label,
				className: "w-full",
			},
			placeholder: field?.placeholder || field.label,
			className: cn(
				"border-border min-w-0 w-full"
				// field.type !== "FILE" && "border-border"
			),
			value,
			onChange,
		} as const;
	}, [field.label, field?.placeholder, layout, onChange, value]);
	const options = useMemo(() => {
		return (field?.options || "")
			.split(",")
			.map((item: string) => item.trim())
			.filter(Boolean)
			.map((item: string) => ({
				value: item,
				label: item,
			}));
	}, [field?.options]);

	const handleTimeInput = (
		name: "start_date" | "end_date",
		timeValue: any
	) => {
		const time = moment(timeValue, "HH:mm");
		let val = value[name];
		if (val === "Invalid date") {
			val = value.start_date;
		}
		if (!val && value?.start_date) {
			val = value.start_date;
		}
		let date = moment(val);
		date.set({
			hour: time.get("hour"),
			minute: time.get("minute"),
			second: time.get("second"),
		});
		onChange({ ...value, [name]: date.format("YYYY-MM-DD HH:mm") });
	};

	if (!field.type) {
		return null;
	}
	return (
		<div {...rest} className={cn("", className)}>
			{field.type === "FILE" && (
				<Field.File
					theme="input-with-preview"
					{...props}
					onChange={(value) => {
						onChange(value || null);
					}}
				/>
			)}
			{field.type === "DATE" && (
				<Field.Date
					{...props}
					value={
						value ? moment(value).format("YYYY-MM-DD") : undefined
					}
				/>
			)}
			{field.type === "DATE_START_END_TIME" && (
				<div className="flex flex-col gap-1">
					<div className="flex gap-2 items-end">
						<Field.Date
							{...props}
							value={
								value?.start_date
									? moment(value.start_date).format(
											"YYYY-MM-DD"
									  )
									: ""
							}
							onChange={(date) =>
								onChange({ start_date: date, end_date: date })
							}
						/>
						<Field.Input
							disabled={!value?.start_date}
							type="time"
							{...props}
							wrapper={{ className: "w-full md:w-[160px]" }}
							value={
								value?.start_date
									? moment(value.start_date).format("HH:mm")
									: ""
							}
							onChange={(time) =>
								handleTimeInput("start_date", time)
							}
						/>
						<Field.Input
							disabled={!value?.end_date}
							type="time"
							{...props}
							wrapper={{ className: "w-full md:w-[160px]" }}
							value={
								value?.end_date
									? moment(value.end_date).format("HH:mm")
									: ""
							}
							onChange={(time) =>
								handleTimeInput("end_date", time)
							}
						/>
					</div>
					{hours !== null && hours > 0 && (
						<div className="flex justify-end">
							<p className="text-[16px] opacity-70">
								{t("hours", { count: hours })}
							</p>
						</div>
					)}
				</div>
			)}
			{field.type === "BOOLEAN" && <Field.Switch {...props} />}
			{field.type === "TEXT" && (
				<Field.Input
					as={field.settings?.is_multiline ? "textarea" : "input"}
					{...props}
				/>
			)}
			{field.type === "NUMBER" && (
				<Field.Input type="number" {...props} />
			)}
			{field.type === "PRICE" && <Field.Input type="number" {...props} />}
			{field.type === "OPTIONS" && (
				<Field.Select {...props} {...{ options }} />
			)}
		</div>
	);
};

CustomFormField.locale = {
	nl: {
		from: "Van",
		till: "Tot",
		hours: "{{count}} uur",
	},
	en: {
		from: "From",
		till: "To",
		hours: "{{count}} hours",
	},
};

export default withTranslation(CustomFormField);
