import { atom, useAtom } from "jotai";
import { ApiTaskCompletedOverview } from "src/api/types";
import useApi from "src/hooks/api/utils/useApi";

export const completedStatsAtom = atom({
	best: {
		date: "",
		count: 0,
	},
	completed: {
		total: 0,
		previous: 0,
	},
});

type Options = {
	filter?: any;
};

function useCompletedTasks(options?: Options) {
	const [completedStats, setCompletedStats] = useAtom(completedStatsAtom);
	const { state, actions, api, pagination } = useApi(
		{ id: "TASKS_COMPLETED" },
		{
			baseUrl: `/client/tasks/completed`,
			appendListResult: true,
			query: {
				state: "all",
				...(options?.filter || {}),
			},
		}
	);

	const statistics = async (filter: any) => {
		const { data } = await api.get(`/statistics`, {
			params: {
				filter: filter,
				start_date: filter.start_date,
				end_date: filter.end_date,
			},
		});
		setCompletedStats({
			best: {
				date: data.best.date,
				count: data.best.count,
			},
			completed: {
				total: data.completed.total,
				previous: data.completed.previous,
			},
		});
	};

	const tasks: ApiTaskCompletedOverview[] = state?.list || [];

	return {
		statistics,
		completedStats,
		tasks,
		task: state?.data,
		status: state.status,
		pagination,
		actions: {
			...actions,
		},
		api,
	};
}

export default useCompletedTasks;
