import { createContext, useContext, useState } from "react";
import Button, { ButtonProps } from "src/components/Button";
import Link from "src/components/Link";
import Loader from "src/components/Loader";
import {
	ContextMenu,
	ContextMenuContent,
	ContextMenuItem,
	ContextMenuTrigger,
} from "src/components/ui/context-menu";
import {
	DropdownMenu,
	DropdownMenuContent,
	DropdownMenuItem,
	DropdownMenuTrigger,
} from "src/components/ui/dropdown-menu";
import { cn } from "src/lib/utils";

export const ContextProvider = createContext({
	onClose: () => {},
	isContext: false,
});

const positions = {
	"bottom-left": "left-0",
	"bottom-right": "right-0",
};

export type DropdownButtonPosition = keyof typeof positions;

interface DropdownButtonProps {
	children: any;
	menu?: {
		position?: DropdownButtonPosition;
	};
	button?: ButtonProps;
	context?: JSX.Element;
}

const DropdownButton = ({
	children,
	menu,
	button,
	context,
}: DropdownButtonProps) => {
	const [isVisible, setIsVisible] = useState(false);
	let align: "start" | "center" | "end" = "center";
	if (menu?.position === "bottom-left") align = "start";
	if (menu?.position === "bottom-right") align = "end";

	if (context) {
		return (
			<ContextProvider.Provider
				value={{
					isContext: true,
					onClose: () => setIsVisible(false),
				}}
			>
				<ContextMenu>
					<ContextMenuTrigger>{context}</ContextMenuTrigger>
					<ContextMenuContent>{children}</ContextMenuContent>
				</ContextMenu>
			</ContextProvider.Provider>
		);
	}

	return (
		<ContextProvider.Provider
			value={{
				isContext: false,
				onClose: () => setIsVisible(false),
			}}
		>
			<DropdownMenu
				onOpenChange={(value) => {
					setIsVisible(value);
				}}
				open={isVisible}
			>
				<DropdownMenuTrigger>
					<Button as="div" {...button} />
				</DropdownMenuTrigger>

				<DropdownMenuContent
					align={align || "center"}
					className="z-[99]"
				>
					{children}
				</DropdownMenuContent>
			</DropdownMenu>
		</ContextProvider.Provider>
	);
};

interface ItemProps {
	children?: any;
	icon?: string;
	to?: any;
	href?: string;
	target?: string;
	onClick?: (close: () => void) => void;
	onClose?: () => void;
	loading?: boolean;
	className?: string;
	activeClassName?: string;
	locale?: string;
	disabled?: boolean;
	samePage?: boolean;
}

const Item = ({
	children,
	icon,
	to,
	href,
	target,
	onClick,
	loading,
	className,
	activeClassName,
	locale,
	disabled,
	samePage,
}: ItemProps) => {
	const { isContext, onClose } = useContext(ContextProvider);
	const Element = to ? Link : href ? "a" : "div";
	const props: any = {};

	if (to) {
		props.to = to;
		props.activeClassName = activeClassName;
		props.samePage = samePage;
	}

	const Wrapper = isContext ? ContextMenuItem : DropdownMenuItem;

	return (
		<Wrapper asChild className="p-0 transition-all">
			<Element
				{...props}
				{...{ locale, disabled, href, target }}
				onClick={(e) => {
					e.stopPropagation();
					if (to && onClose) {
						onClose();
					}
					if (disabled) {
						return;
					}
					if (onClick) {
						if (e) e?.preventDefault();
						onClick(() => onClose && onClose());
						return;
					}
					if (onClose) {
						onClose();
					}
				}}
				className={cn(
					"cursor-pointer flex gap-2.5 items-center px-2 py-1.5 w-full",
					disabled && "opacity-40",
					className
				)}
			>
				{icon && !loading && (
					<div className="w-4">
						<i aria-hidden="true" className={`${icon}`}></i>
					</div>
				)}
				{loading && (
					<div className="w-4 text-background-foreground-400">
						<Loader />
					</div>
				)}
				<span className="whitespace-nowrap font-regular">
					{children}
				</span>
			</Element>
		</Wrapper>
	);
};

Item.defaultProps = {
	variant: "gray-outline",
};

DropdownButton.Item = Item;

const Divider = () => {
	return (
		<div className="py-1">
			<hr className="border-border" />
		</div>
	);
};

DropdownButton.Divider = Divider;

export default DropdownButton;
