import { Briefcase, GraduationCap } from "lucide-react";
import {
	ComponentProps,
	createContext,
	ReactNode,
	useContext,
	useEffect,
	useState,
} from "react";
import { ApiExperience } from "src/api/types";
import Button from "src/components/Button";
import Input from "src/components/form/Input";
import withTranslation, {
	Translation,
} from "src/components/hoc/withTranslation";
import Skeleton from "src/components/Skeleton";
import {
	Popover,
	PopoverContent,
	PopoverTrigger,
} from "src/components/ui/popover";
import useExperiences from "src/hooks/api/services/resume/useExperiences";
import { useUpdateEffectDebounce } from "src/hooks/useDebounce";
import { cn } from "src/lib/utils";

const ExperienceContext = createContext({});
const useExperienceContext = () => useContext(ExperienceContext) as any;

interface ExperienceFormProps {
	type: "job" | "education";
	children: ReactNode;
	mode?: "create" | "search";
	onCreated?: (experience: ApiExperience) => void;
	defaultValue?: string;
}

const ExperienceForm = ({
	type,
	children,
	onCreated,
	mode = "create",
	defaultValue = "",
}: ExperienceFormProps) => {
	const { actions } = useExperiences({ type });
	const [value, setValue] = useState<string>(defaultValue);

	useEffect(() => {
		actions.list();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleCreateExperience = (
		title: string,
		data: { linked?: string } = {}
	) => {
		if (mode !== "create") return;

		actions.createByTitle(title, data).then((result) => {
			setValue("");
			if (result?.id && onCreated) {
				onCreated(result);
			}
		});
	};

	return (
		<ExperienceContext.Provider
			value={{ type, value, setValue, handleCreateExperience, mode }}
		>
			{children}
		</ExperienceContext.Provider>
	);
};

interface ExperienceFormQuickCreateProps extends Translation {
	placeholder?: string;
}

const ExperienceFormQuickCreate = withTranslation(
	({ t, placeholder }: ExperienceFormQuickCreateProps) => {
		const { type, value, setValue, handleCreateExperience, mode } =
			useExperienceContext();
		const { status } = useExperiences({ type });

		return (
			<form
				onSubmit={(event) => {
					event.preventDefault();
					if (!value || status !== "idle") return;
					handleCreateExperience(value);
				}}
				className="flex flex-col"
			>
				<div className="flex gap-2">
					<Input
						name="title"
						placeholder={placeholder || t(`${type}.placeholder`)}
						{...{ value }}
						onChange={(key, value) => setValue(value)}
					/>
					{mode === "create" && (
						<Button
							type="dark"
							submit
							small
							className="h-[42px]"
							loading={status === "creating"}
						>
							{t("buttons.create")}
						</Button>
					)}
				</div>
				<small className="mt-1 pl-2">{t(`${type}.example`)}</small>
			</form>
		);
	},
	{
		nl: {
			job: {
				placeholder: "Functie...",
				example: "Bijvoorbeeld: Marketing medewerker",
			},
			education: {
				placeholder: "Opleiding...",
				example: "Bijvoorbeeld: HBO Bedrijfskunde",
			},
			buttons: {
				create: "Toevoegen",
			},
		},
	}
);

interface ExperienceFormAutocompleteProps extends Translation {
	onSelect?: (experience: any) => void;
	label?: string;
	showLoading?: boolean;
}

const ExperienceFormAutocomplete = withTranslation(
	({
		t,
		className,
		onSelect,
		label,
		showLoading,
		...rest
	}: ExperienceFormAutocompleteProps & ComponentProps<"div">) => {
		const { type, value, handleCreateExperience } = useExperienceContext();
		const { autocomplete, actions } = useExperiences({ type });
		const [isLoading, setIsLoading] = useState(true);

		const fetchAutocomplete = async (query: string) => {
			setIsLoading(true);
			await actions.getAutocomplete(query);
			setIsLoading(false);
		};

		useUpdateEffectDebounce(value, 700, (query) => {
			if (!query) {
				actions.setAutocomplete([]);
				return;
			}
			fetchAutocomplete(query);
		});

		useEffect(() => {
			if (autocomplete.length === 0 && value) {
				fetchAutocomplete(value);
			}
			// eslint-disable-next-line react-hooks/exhaustive-deps
		}, []);

		if (!value || (!autocomplete?.length && !showLoading)) return null;

		return (
			<div {...rest} className={cn("flex gap-2", className)}>
				<div className="h-[35px] flex items-center">
					<small className="opacity-70 whitespace-nowrap">
						{label || t("title")}
					</small>
				</div>
				<div className="flex flex-wrap gap-2 flex-1">
					{showLoading && isLoading ? (
						<>
							<Skeleton className="h-[35px] w-[240px]" />
							<Skeleton className="h-[35px] w-[200px]" />
							<Skeleton className="h-[35px] w-[220px]" />
						</>
					) : (
						<>
							{autocomplete.map((item: any) => (
								<Button
									key={item.name}
									xsmall
									type="border"
									onClick={() => {
										if (onSelect) {
											onSelect(item);
											return;
										}

										handleCreateExperience(item.name, {
											linked: item.id,
										});
									}}
								>
									{item.name}
								</Button>
							))}
						</>
					)}
				</div>
			</div>
		);
	},
	{
		nl: {
			title: "Bedoel je misschien:",
		},
	}
);

interface ExperienceFormListProps extends Translation {
	getEditTo: (data: { id: string; type: "job" | "education" }) => any;
}

const ExperienceFormList = withTranslation(
	({
		t,
		className,
		getEditTo,
		...rest
	}: ExperienceFormListProps & ComponentProps<"div">) => {
		const { type } = useExperienceContext();
		const { experiences, actions } = useExperiences({ type });
		const primaryKey = type === "job" ? "subtitle" : "title";
		const secondaryKey = type === "education" ? "subtitle" : "title";
		return (
			<div
				{...rest}
				className={cn("flex flex-col gap-3 empty:hidden", className)}
			>
				{experiences.map((experience) => {
					return (
						<div
							key={experience.id}
							className="flex gap-2 items-center border border-border rounded-md p-3"
						>
							<div className="flex-1">
								<span>{experience[primaryKey]}</span>
								<div className="flex items-center gap-2 opacity-70">
									<small>
										{experience.started ||
											t(`defaults.started`)}
										{" - "}
										{experience.ended ||
											t(`defaults.ended`)}
									</small>
									<small>
										@
										{experience[secondaryKey] ||
											t(`defaults.${type}.subtitle`)}
									</small>
								</div>
							</div>
							<Popover>
								<PopoverTrigger>
									<Button xsmall type="border">
										<i className="far fa-trash"></i>
									</Button>
								</PopoverTrigger>
								<PopoverContent>
									<p className="mb-1 text-[16px]">
										{t("delete.title")}
									</p>
									<Button
										xsmall
										type="red"
										onClick={async () =>
											actions.delete(experience.id)
										}
									>
										{t("delete.cta")}
									</Button>
								</PopoverContent>
							</Popover>

							<Button
								to={getEditTo({
									id: experience.id.toString(),
									type,
								})}
								xsmall
								type="border"
							>
								{t("edit")}
							</Button>
						</div>
					);
				})}
			</div>
		);
	},
	{
		nl: {
			edit: "Bewerk",
			delete: {
				title: "Weet je zeker dat je dit wilt verwijderen?",
				cta: "Verwijder",
			},
			defaults: {
				started: "van",
				ended: "tot",
				education: {
					subtitle: "opleider",
				},
				job: {
					subtitle: "bedrijf",
				},
			},
		},
	}
);

const EmptyState = withTranslation(
	({
		t,
		className,
		children,
		...rest
	}: Translation & ComponentProps<"div">) => {
		const { type } = useExperienceContext();
		const { experiences, status } = useExperiences({ type });
		if (experiences.length > 0 || status !== "idle") return null;
		return (
			<div
				{...rest}
				className={cn(
					"flex flex-col gap-2 md:gap-4 justify-center items-center p-8",
					className
				)}
			>
				<div className="md:w-24 md:h-24 h-16 w-16 md:mb-4 relative">
					<div className="absolute inset-0 bg-primary opacity-30 rounded-full"></div>
					{type === "job" && (
						<Briefcase className="w-full h-full stroke-gray-600 dark:stroke-gray-300 absolute inset-0 p-5" />
					)}
					{type === "education" && (
						<GraduationCap className="w-full h-full stroke-gray-600 dark:stroke-gray-300 absolute inset-0 p-5" />
					)}
				</div>
				<div className="flex flex-col justify-center items-center gap-1 text-center">
					<p className="font-bold">{t(`${type}.title`)}</p>
					<p className="text-base opacity-80 md:mb-6 max-w-sm text-center">
						{t(`${type}.description`)}
					</p>
				</div>
			</div>
		);
	},
	{
		nl: {
			job: {
				title: "Nog geen werkervaring toegevoegd",
				description:
					"Begin je professionele reis door je eerste werkervaring toe te voegen. Elke ervaring telt!",
			},
			education: {
				title: "Nog geen opleiding toegevoegd",
				description:
					"Begin met het opbouwen van je academische profiel door je eerste opleiding toe te voegen. Elke stap in je leerproces is waardevol!",
			},
		},
		en: {
			job: {
				title: "No job experience added yet",
				description:
					"Start your professional journey by adding your first job experience. Every experience counts!",
			},
			education: {
				title: "No education added yet",
				description:
					"Start building your academic profile by adding your first education experience. Every step in your learning process is valuable!",
			},
		},
	}
);

ExperienceForm.List = ExperienceFormList;
ExperienceForm.QuickCreate = ExperienceFormQuickCreate;
ExperienceForm.Autocomplete = ExperienceFormAutocomplete;
ExperienceForm.EmptyState = EmptyState;

export default ExperienceForm;
