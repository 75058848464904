import { useNavigate, useParams } from "react-router-dom";
import Icon from "src/components/icon/Icon";
import { useTaskKanban } from "src/components/tasks/kanban/TaskKanbanCard";

const TaskKanbanCardProject = () => {
	const { task } = useTaskKanban();
	const navigate = useNavigate();
	const params = useParams();
	if (!task?.project || params?.project) return <></>;
	return (
		<div
			className="inline-flex gap-0.5 items-center text-sm group w-max cursor-pointer"
			onClick={(e) => {
				e.stopPropagation();
				e.preventDefault();
				navigate(`/projects/${task?.project?.slug}`);
			}}
		>
			<div className="">
				<Icon
					className="w-5 h-5 rounded-sm group-hover:bg-accent"
					iconFontSize={12}
					icon={
						task?.project?.icon ||
						Icon.getFontAwesome("fa-folder", "far")
					}
				/>
			</div>
			<span className="underline">{task.project.name}</span>
		</div>
	);
};

export default TaskKanbanCardProject;
