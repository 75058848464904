import axios from "axios";
import { useMemo, useState } from "react";
import Button from "src/components/Button";
import withTranslation, {
	Translation,
} from "src/components/hoc/withTranslation";
import CreateVideoPitch from "src/components/video-pitch/CreateVideoPitch";
import VideoPitchPlayer from "src/components/video-pitch/VideoPitchPlayer";
import useResume from "src/hooks/api/services/resume/useResume";
import useAuth from "src/hooks/selectors/useAuth";
import useAlert from "src/hooks/useAlert";
import { cn } from "src/lib/utils";

const INTERVAL_DURATION = 4000;

interface VideoPitchFormProps extends Translation {
	children?: any;
}

const VideoPitchForm = ({ t, children }: VideoPitchFormProps) => {
	const auth = useAuth();
	// const videoPitch = resume.video_pitch;
	const [isDeletingVideoPitch, setIsDeletingVideoPitch] = useState(false);
	const [sendAlert] = useAlert();
	const { resume, actions } = useResume({
		rememberKey: auth.id,
	});
	const videoPitch = resume.video_pitch;

	const IS_PROCESSING = ["PROCESSING", "UPLOADING"].includes(
		videoPitch?.status || ""
	);

	const aspectRatio = useMemo(() => {
		if (!videoPitch) return "PORTRAIT";
		if (IS_PROCESSING) {
			return "PORTRAIT";
		}

		return videoPitch?.id ? videoPitch?.thumbnail?.aspect_ratio : null;
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [videoPitch]);

	const getStatus = async () => {
		const newResume = await actions.get(auth.id);
		if (newResume.video_pitch?.status !== "READY") {
			setTimeout(() => {
				getStatus();
			}, INTERVAL_DURATION);
		}
	};

	const deleteVideoPitch = async () => {
		sendAlert({
			type: "confirm",
			title: t("confirm.title"),
			text: t("confirm.description"),
			onConfirm: () => {
				setIsDeletingVideoPitch(true);
				axios
					.delete(`/video-pitches/${videoPitch?.id}`)
					.then(async () => {
						// setVideoPitch(undefined);
						await actions.get(auth.id);
					})
					.finally(() => setIsDeletingVideoPitch(false));
			},
		});
	};

	return (
		<>
			{!videoPitch && (
				<CreateVideoPitch onCompleted={getStatus} type="RESUME" />
			)}
			{videoPitch && (
				<div className="flex flex-col relative">
					<VideoPitchPlayer
						{...{ videoPitch }}
						className={cn(
							videoPitch?.id &&
								aspectRatio === "PORTRAIT" &&
								"md:max-w-[320px]"
						)}
					/>
					{videoPitch?.id && videoPitch.status === "READY" && (
						<div className="flex mt-2">
							<Button
								xsmall
								type="red"
								onClick={deleteVideoPitch}
								loading={isDeletingVideoPitch}
							>
								{t("delete")}
							</Button>
						</div>
					)}
				</div>
			)}
		</>
	);
};

VideoPitchForm.locale = {
	nl: {
		delete: "Verwijder",
		confirm: {
			title: "Weet je het zeker?",
			description:
				"Weet je zeker dat je deze videopitch wilt verwijderen?",
		},
	},
	en: {
		delete: "Delete",
		confirm: {
			title: "Are you sure?",
			description: "Are you sure you want to delete this video pitch?",
		},
	},
};

export default withTranslation(VideoPitchForm);
