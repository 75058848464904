import { Formik } from "formik";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import moment from "moment";
import _ from "lodash";
import useAuth from "src/hooks/selectors/useAuth";
import useEditProfile from "src/hooks/api/useEditProfile";
import DateOfBirthPicker from "src/components/form/DateOfBirthPicker";
import Input from "src/components/form/Input";
import { isValidPhoneNumber } from "src/components/form/Input/phone/utils";

interface EditUserSmallProps {
	onDone?: () => void;
	renderButtons: (options?: {
		isSubmitting?: boolean;
		isValid?: boolean;
	}) => any;
}

const EditUserSmall = ({ onDone, renderButtons }: EditUserSmallProps) => {
	const { t } = useTranslation(["forms", "schemes", "buttons"]);
	const scheme = {
		telephone: Yup.string()
			.test(
				"len",
				t("telephone", { ns: "schemes", length: 9 }),
				(val: any) => isValidPhoneNumber(val)
			)
			.required(t("required")),
		date_of_birth: Yup.string()
			.test(
				"minimum_dob",
				t("min-age", { ns: "schemes", age: 16 }),
				(value) => moment().diff(moment(value), "years") >= 16
			)
			.required(t("required")),
	} as {
		[key: string]: any;
	};

	const user = useAuth();
	const [updateProfile, updating] = useEditProfile();
	const initialValues = {
		date_of_birth: user.date_of_birth || "",
		telephone: user.telephone || "",
	};

	const checkIfFullyValid = ({ isValid, touched, values }: any) => {
		const isFormValid =
			isValid &&
			(!_.isEmpty(touched) ||
				Object.keys(values).every((key) => values[key]));
		return isFormValid;
	};

	return (
		<Formik
			{...{ initialValues }}
			validateOnChange
			validateOnBlur
			validateOnMount
			validationSchema={Yup.object().shape(scheme)}
			onSubmit={(values: any, { setSubmitting }) => {
				if (values.date_of_birth) {
					values.date_of_birth = moment(values.date_of_birth).format(
						"YYYY-MM-DD"
					);
				}

				updateProfile(values)
					.then(() => {
						if (onDone) onDone();
					})
					.finally(() => setSubmitting(false));
			}}
		>
			{({
				values,
				errors,
				touched,
				handleChange,
				handleSubmit,
				handleBlur,
				isSubmitting,
				isValid,
			}) => (
				<form
					className="flex flex-col gap-4 flex-1"
					onSubmit={handleSubmit}
				>
					<div className="flex flex-col gap-6 flex-1">
						<DateOfBirthPicker
							label={t("date-of-birth", {
								ns: "forms",
								keyPrefix: "edit-user",
							})}
							name="date_of_birth"
							value={values.date_of_birth}
							valid={
								values.date_of_birth && !errors.date_of_birth
							}
							error={errors.date_of_birth}
							{...{ handleChange, handleBlur }}
							onChange={(key, value) => {
								handleChange({
									target: {
										name: "date_of_birth",
										value,
									},
								});
							}}
						/>

						<Input
							label={t("telephone", {
								ns: "forms",
								keyPrefix: "edit-user",
							})}
							name="telephone"
							type="telephone"
							value={values.telephone}
							valid={values.telephone && !errors.telephone}
							error={
								errors.telephone &&
								touched.telephone &&
								errors.telephone
							}
							{...{ handleChange, handleBlur }}
						/>
					</div>

					{renderButtons({
						isSubmitting: isSubmitting || updating,
						isValid: checkIfFullyValid({
							isValid,
							touched,
							values,
						}),
					})}
				</form>
			)}
		</Formik>
	);
};

export default EditUserSmall;
