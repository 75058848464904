import { createContext, useContext } from "react";
import { ApiUserMinimal, ApiUserStatistics } from "src/api/types";
import { ApiStatus } from "src/hooks/api/utils/useApi";

type UserStatisticsContextType = {
	userId: string | number;
	user: ApiUserMinimal;
	data: ApiUserStatistics;
	status: ApiStatus;
};

export const UserStatisticsContext = createContext<UserStatisticsContextType>(
	{} as UserStatisticsContextType
);

export function useUserStatistics() {
	const props = useContext(UserStatisticsContext);
	return props;
}
