const offer = {
	status: {
		pending: "In afwachting",
		denied: "Afgewezen",
		accepted: "Goedgekeurd",
	},
	"offer-error-card": {
		title: "Er gaat iets niet helemaal goed",
		description:
			"Ververs de pagina, als dit probleem nog steeds blijft voordoen mail dan naar <1>support@{{tenant.slug}}.nl</1>",
		"try-again": "Opnieuw beginnen",
		"refresh-page": "Pagina herladen",
	},
	"offer-registration-match-card": {
		"envelope-not-signed":
			"De overeenkomst moet volledig zijn ondertekend voor je kan afronden.",
		"missing-payment": "Er moet een betaling zijn voor je kan afronden",
		alert: {
			success: "Opdracht goedgekeurd",
		},
		signers: {
			title: "Overeenkomst",
		},
	},
	"offer-share-card": {
		"not-allowed": {
			title: "Je kan je aanbod niet delen",
			description: "Je aanbod moet geverifieerd en gepubliceerd zijn",
		},
		title: "Deel je project",
	},
	"offer-summary-card": {
		yes: "Ja",
		no: "Nee",
		unknown: "Niet opgegeven",
		"previous-step": "Vorige stap",
		publish: "Publiceer de opdracht",
		concept: "Opslaan als concept",
		description: {
			intro: "Even voorstellen",
			what: "Dit ga ik voor je bedrijf doen",
			how: "op deze manier ga ik het doen",
		},
		visibility: {
			title: "Zichtbaarheid",
			category: "Categorie:",
			"category-not-found": "Categorie niet gevonden",
			everybody: "Iedereen mag mijn opdracht zien:",
			"show-profile": "Toon mijn profiel bij de opdracht:",
		},
		budget: {
			title: "Budget",
			budget: "Het volgende ontvang je op je rekening:",
			"estimated-duration": "Geschatte tijdsduur",
			hour: "uur",
		},
	},
	"archive-offer-button": {
		confirm: {
			title: "Aanbod archiveren",
			description: "Weet je zeker dat je dit aanbod wilt archiveren?",
		},
	},
	"offer-accept-form": {
		title: "Klaar om de opdracht aan te gaan?",
		description:
			"Ben je tevreden met de prijs en de opdracht? Klik dan op opdracht goedkeuren. Er wordt dan een opdracht bij het bedrijf aangemaakt waar jullie verder kunnen werken.",
		agree: "Ja ik wil de opdracht aangaan",
		button: "Opdracht goedkeuren",
	},
	"offer-available-work-switch": {
		all: "Beschikbaar om te werken",
		detail: "Opdracht tonen",
	},
	"offer-bid-form": {
		title: "Toch een andere prijs voorstellen?",
		description: "Deze opdracht is momenteel {{price}} waard",
		placeholder: "Bedrag",
		button: "Prijs aanpassen",
	},
	"offer-deny-form": {
		title: "Opdracht afwijzen",
		description:
			"Toch er niet helemaal uitgekomen? Keur dan de opdracht af.",
		checkbox: "Ja ik wil de opdracht afkeuren",
		button: "Opdracht afwijzen",
	},
	"publish-offer-button": {
		confirm: {
			title: "Aanbod publiceren",
			description: "Weet je zeker dat je dit aanbod wilt publiceren?",
		},
		button: "Publiceer aanbod",
	},
	form: {
		budget: "Opdrachtwaarde",
		"estimated-duration": "Geschatte tijdsduur",
		"title-budget": "Budget & tijdsduur",
		description: {
			intro: "Vertel wat over jezelf",
			"intro-placeholder": "Mijn naam is {{name}}",
			what: "Wat ga je voor het bedrijf doen",
			"what-placeholder":
				"Ik ga de social media van jouw bedrijf updaten…",
			how: "Hoe ga je dit doen voor het bedrijf",
			"how-placeholder":
				"D.m.v. 3 verschillende posts verwacht ik een groei…",
		},
		skills: "Vaardigheden",
		"skills-placeholder": "Word, SEO, Webdesign",
		title: {
			unknown: "Geen titel gevonden",
			label: "Type een titel voor jouw project",
			placeholder: "Titel",
			example: {
				title: "Denk bijvoorbeeld aan",
				"example-one": "Social media bijhouden",
				"example-two": "SEO blogs schrijven",
				"example-three": "Dagje helpen bij evenementen",
				"example-four": "Website op maat",
				"example-five": "Ik lever 10 nieuwe klanten",
			},
		},
		category: {
			label: "Kies een categorie",
			placeholder: "Categorie",
		},
		"category-manual": {
			label: "Staat je categorie er niet bij? Maak hem dan zelf",
			placeholder: "Categorie naam",
		},
		visibility: {
			title: "Zichtbaarheid",
			"is-private": "Iedereen mag mijn opdracht zien",
			"is-anonymous": "Toon mijn profiel bij de opdracht",
		},
		"title-description": "Titel & omschrijving",
		"project-title": "Project titel",
		steps: {
			title: "Titel",
			description: "Omschrijven",
			visibility: "Zichtbaarheid",
			skills: "Vaardigheden",
			budget: "Budget",
		},
	},
	"offer-edit-save-button": {
		confirm: {
			title: "Weet je zeker dat je je aanbod wilt aanpassen?",
			description:
				"Het aanbod moet opnieuw worden goedgekeurd door een medewerker van {{tenant.code}}",
		},
	},
	"offer-list": {
		"empty-student": {
			title: "Je hebt nog geen aanbod aangemaakt",
			description: "Aanbod maken",
		},
	},
	"offer-registration-company-list": {
		empty: {
			title: "Geen professionals gevonden",
			description: "Vind jouw professionals",
		},
	},
	"offer-registration-list": {
		title: "Aanvragen",
		empty: {
			title: "Je hebt nog geen aanmeldingen",
			description:
				"Tip: Verspreid je aanbod ook in je eigen netwerk, wie weet wie jouw kwaliteiten kan gebruiken.",
		},
		filters: {
			all: "Alle",
			accepted: "Geaccepteerd",
			denied: "Afgewezen",
		},
	},
};

export default offer;
