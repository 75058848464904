import { AnimatePresence, motion } from "framer-motion";
import Loader from "src/components/Loader";
import Skeleton from "src/components/Skeleton";
import { cn } from "src/lib/utils";

type CheckboxProps = {
	loading?: boolean;
	value?: boolean;
	onChange: (value: boolean) => void;
	label?: any;
	disabled?: boolean;
	className?: string;
	defaultIcon?: any;
	button?: {
		className?: string;
	};
};

const Checkbox = ({
	loading,
	value,
	onChange,
	label,
	disabled,
	className,
	defaultIcon,
	button,
}: CheckboxProps) => {
	return (
		<div className={cn("flex gap-2", className)}>
			<div className="flex relative">
				{loading && (
					<Skeleton className="flex justify-center items-center h-6 w-6 rounded-sm absolute inset-0">
						<Loader />
					</Skeleton>
				)}
				<button
					onClick={(e) => {
						e.stopPropagation();
						e.preventDefault();
						if (disabled) return;
						onChange(!value);
					}}
					type="button"
					role="checkbox"
					aria-checked="false"
					className={cn(
						"border-2 cursor-pointer h-5 w-5 flex text-primary-foreground items-center justify-center rounded-sm transition-all border-border focus:ring focus:ring-primary",
						value && "border-primary bg-primary",
						button?.className,
						disabled &&
							"bg-border dark:bg-border cursor-not-allowed"
					)}
				>
					<AnimatePresence>
						{value && (
							<svg
								xmlns="http://www.w3.org/2000/svg"
								fill="none"
								viewBox="0 0 24 24"
								strokeWidth={2}
								stroke="currentColor"
								className="w-[90%]"
							>
								<motion.path
									initial={{ pathLength: 0 }}
									animate={{ pathLength: 1 }}
									exit={{ pathLength: 0 }}
									transition={{
										type: "tween",
										duration: 0.3,
										ease: value ? "easeOut" : "easeIn",
									}}
									strokeLinecap="round"
									strokeLinejoin="round"
									d="M4.5 12.75l6 6 9-13.5"
								/>
							</svg>
						)}
					</AnimatePresence>
					{!value && defaultIcon}
				</button>
			</div>

			{label && <span className="mb-0">{label}</span>}
		</div>
	);
};

interface LoaderProps {
	className?: string;
	label?: boolean;
}

const LoaderSkeleton = ({ className, label }: LoaderProps) => {
	return (
		<div className={`flex gap-2 ${className || ""}`}>
			<Skeleton className="h-5 w-5 rounded-sm" />
			{label && <Skeleton className="h-5 w-18" />}
		</div>
	);
};

Checkbox.Loader = LoaderSkeleton;

export default Checkbox;
