import { useState } from "react";
import Button from "src/components/Button";
import FilterTabs from "src/components/layout/FilterTabs";
import Layout from "src/components/layout/Layout";
import OfferAvailableWorkSwitch from "src/components/offer/forms/OfferAvailableWorkSwitch";
import OfferList from "src/components/offer/lists/OfferList";
import useAuth from "src/hooks/selectors/useAuth";
import useWindowSize from "src/hooks/useWindowSize";

import withTranslation, {
	Translation,
} from "src/components/hoc/withTranslation";

function OffersView({ t }: Translation) {
	const auth = useAuth();
	const [sort, setSort] = useState("newest");
	const { isTablet } = useWindowSize();

	const sorts = {
		newest: t("sort.all"),
		populair: t("sort.populair"),
		live: t("sort.live"),
	};

	if (!auth?.status?.is_onboarded) {
		return (
			<Layout
				breadcrumb={[
					{
						to: "/offers",
						label: t("title"),
						isHome: true,
					},
				]}
				title={t("title")}
			>
				<div className="flex flex-1 justify-center items-center flex-col">
					<h2 className="mb-2">{t("not-onboarded.title")}</h2>
					<p className="max-w-[500px] text-center">
						{t("not-onboarded.description")}
					</p>
					<Button className="mt-4" to="/dashboard">
						{t("not-onboarded.button")}
					</Button>
				</div>
			</Layout>
		);
	}

	return (
		<Layout
			breadcrumb={[
				{
					to: "/offers",
					label: t("title"),

					isHome: true,
				},
			]}
			container="xxl"
			title={t("title")}
			actions={
				<>
					<Button to="/offers/create">{t("create")}</Button>
				</>
			}
		>
			{isTablet && (
				<div className="">
					<OfferAvailableWorkSwitch
						defaultValue={auth.status.is_available}
						type="all"
					/>
				</div>
			)}
			<div>
				<FilterTabs
					tabs={sorts}
					variant={auth.type === "student" ? "dark" : "light"}
					active={sort}
					onChange={setSort}
				/>
			</div>
			<OfferList
				query={`with[]=statistics&with[]=unread_messages&sort=${sort}`}
			/>
		</Layout>
	);
}

OffersView.locale = {
	nl: {
		title: "Mijn aanbod",
		create: "Aanbod aanmaken",
		"not-onboarded": {
			title: "Je account is nog niet compleet",
			description:
				"Voor je je talenten kan delen met de wereld willen we eerst wat meer over je weten. Ga naar je profiel om je cv compleet te maken.",
			button: "Profiel compleet maken",
		},
		sort: {
			all: "Alles",
			populair: "Populairste",
			live: "Live",
		},
	},
	en: {
		title: "My offer",
		create: "Create offer",
		"not-onboarded": {
			title: "Your account is not complete yet",
			description:
				"Before you can share your talents with the world, we first want to know more about you. Go to your profile to complete your CV.",
			button: "Complete profile",
		},
		sort: {
			all: "All",
			populair: "Popular",
			live: "Live",
		},
	},
};

export default withTranslation(OffersView);
