import { Form, Formik } from "formik";
import { useNavigate } from "react-router-dom";
import Button from "src/components/Button";
import Input from "src/components/form/Input";
import withTranslation, {
	Translation,
} from "src/components/hoc/withTranslation";
import usePost from "src/hooks/rest/usePost";
import useAlert from "src/hooks/useAlert";
import * as Yup from "yup";

type ApiPasswordReset = {
	id: number;
	email: string;
	token: string;
	created_at: string;
	updated_at: string;
};

interface ForgotPasswordResetFormProps extends Translation {
	data?: ApiPasswordReset;
}

const ForgotPasswordResetForm = ({ t, data }: ForgotPasswordResetFormProps) => {
	const ForgotPasswordResetScheme = Yup.object().shape({
		password: Yup.string()
			.matches(
				/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/,
				t("validate.characters") || ""
			)
			.required("Verplicht"),
		password_repeat: Yup.string()
			.oneOf([Yup.ref("password")], t("validate.mismatch") || "")
			.required(t("validate.required") || ""),
	});

	const navigate = useNavigate();

	const [resetPassword] = usePost("/auth/password/reset");

	const [sendAlert] = useAlert();

	if (!data) {
		return <span>{t("failed.title")}</span>;
	}

	return (
		<Formik
			initialValues={{ password: "", password_repeat: "" }}
			validationSchema={ForgotPasswordResetScheme}
			onSubmit={(values, { setSubmitting }) => {
				resetPassword({
					password: values.password,
					token: data.token,
					email: data.email,
				})
					.then(() => {
						navigate("/login?password_reset=success");
						setSubmitting(false);
					})
					.catch(() => {
						sendAlert({
							type: "error",
							title: t("failed.title") || "",
							text: t("failed.text") || "",
						});
						setSubmitting(false);
					});
			}}
		>
			{({
				values,
				errors,
				touched,
				handleChange,
				handleBlur,
				handleSubmit,
				isSubmitting,
			}) => (
				<Form className="flex flex-col gap-6" onSubmit={handleSubmit}>
					<Input
						label={t("fields.password.label") || ""}
						placeholder={t("fields.password.label") || ""}
						name="password"
						type="password"
						value={values.password}
						valid={!errors.password && values.password}
						error={
							errors.password &&
							touched.password &&
							errors.password
						}
						{...{ handleChange, handleBlur }}
					/>
					<Input
						label={t("fields.password-repeat.label") || ""}
						placeholder={t("fields.password-repeat.label") || ""}
						name="password_repeat"
						type="password"
						value={values.password_repeat}
						valid={
							!errors.password_repeat && values.password_repeat
						}
						error={
							errors.password_repeat &&
							touched.password_repeat &&
							errors.password_repeat
						}
						{...{ handleChange, handleBlur }}
					/>
					<Button type="dark" submit loading={isSubmitting}>
						{t("buttons.submit")}
					</Button>
				</Form>
			)}
		</Formik>
	);
};

ForgotPasswordResetForm.locale = {
	nl: {
		validate: {
			characters:
				"Je wachtwoord moet minimaal 8 karakters hebben, 1 hoofdletter & 1 cijfer",
			mismatch: "Wachtwoorden komen niet overeen",
			required: "Verplicht",
		},
		success: {
			title: "Je wachtwoord is aangepast",
			text: "Login met je nieuwe wachtwoord",
		},
		failed: {
			title: "Er is iets misgegaan",
			text: "Controleren of de wachtwoorden overeen komen en probeer het opnieuw",
		},
		fields: {
			password: {
				label: "Wachtwoord",
			},
			"password-repeat": {
				label: "Wachtwoord herhalen",
			},
		},
		buttons: {
			submit: "Wachtwoord wijzigen",
		},
	},
	en: {
		validate: {
			characters:
				"Your password must have at least 8 characters, 1 uppercase & 1 number",
			mismatch: "Passwords do not match",
			required: "Required",
		},
		success: {
			title: "Your password has been changed",
			text: "Login with your new password",
		},
		failed: {
			title: "Something went wrong",
			text: "Check if the passwords match and try again",
		},
		fields: {
			password: {
				label: "Password",
			},
			"password-repeat": {
				label: "Repeat Password",
			},
		},
		buttons: {
			submit: "Change Password",
		},
	},
};

export default withTranslation(ForgotPasswordResetForm);
