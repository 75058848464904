import { useEffect } from "react";
import { useParams } from "react-router-dom";
import withTranslation, {
	Translation,
} from "src/components/hoc/withTranslation";
import RepeatableTaskForm from "src/components/tasks/repeatable-task/RepeatableTaskForm";
import useModal from "src/hooks/useModal";

interface RepeatableTaskSettingsModalProps extends Translation {}

const RepeatableTaskSettingsModal = ({
	t,
}: RepeatableTaskSettingsModalProps) => {
	const modal = useModal();
	const params = useParams();

	useEffect(() => {
		modal.setTitle(t("title"));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	if (!params?.taskId) {
		return <></>;
	}

	return (
		<RepeatableTaskForm
			taskId={params.taskId.toString()}
			actions={{
				onComplete: modal.onClose,
				onDelete: modal.onClose,
			}}
		/>
	);
};

RepeatableTaskSettingsModal.locale = {
	nl: {
		title: "Taak herhalen",
	},
	en: {
		title: "Repeat task",
	},
};

export default withTranslation(RepeatableTaskSettingsModal);
