import React from "react";
import { useNavigate } from "react-router-dom";
import Button from "src/components/Button";
import DropdownButton from "src/components/DropdownButton";
import Link from "src/components/Link";
import withTranslation, {
	Translation,
} from "src/components/hoc/withTranslation";
import useLimits from "src/hooks/api/services/auth/useLimits";
import useTenant from "src/hooks/api/services/tenants/useTenant";
import useAuth from "src/hooks/selectors/useAuth";
import useModules from "src/hooks/selectors/useModules";
import useBackUrl from "src/hooks/useBackUrl";

interface PublishTaskButtonProps extends Translation {
	id: string;
	variant: "button" | "dropdown-button" | "list-button";
	button?: {
		small?: boolean;
	};
}

const PublishTaskButton = ({
	t,
	id,
	variant,
	button,
}: PublishTaskButtonProps) => {
	const auth = useAuth();
	const { tenant } = useTenant();
	const { modules } = useModules();
	const navigate = useNavigate();
	const { limits, status } = useLimits();
	const back = useBackUrl();
	const onClick = () => navigate(`/tasks/${id}/publish?${back}`);
	let help = undefined;

	if (!limits?.published_tasks?.is_available) {
		help = t("help.exceeded");
	}

	if (!modules?.includes("extern-task")) {
		help = t("help.subscription");
	}

	if (!tenant.modules.includes("extern-task")) {
		return null;
	}

	if (variant === "dropdown-button") {
		return (
			<DropdownButton.Item
				loading={status !== "idle"}
				icon="far fa-hands-helping"
				disabled={!limits?.published_tasks?.is_available}
				{...{ onClick }}
			>
				{t("publish")}
			</DropdownButton.Item>
		);
	}

	if (variant === "list-button") {
		return (
			<Link
				to={
					!auth.has_subscription
						? "/subscription"
						: !limits?.published_tasks?.is_available
						? "/profile/billing/change"
						: `/tasks/${id}/publish`
				}
				className="flex gap-2 items-center cursor-pointer"
			>
				<div className="w-5 flex justify-center items-center">
					<i className="far fa-handshake"></i>
				</div>
				<span>{t("publish")}</span>
			</Link>
		);
	}

	return (
		<Button
			{...{ onClick }}
			onClick={() => (auth.has_subscription ? onClick() : null)}
			to={
				!auth.has_subscription
					? "/subscription"
					: !limits?.published_tasks?.is_available
					? "/profile/billing/change"
					: undefined
			}
			loading={status !== "idle"}
			help={help}
			tabIndex="-1"
			type="primary"
			id="outsource-button"
			{...button}
		>
			{t("publish")}
		</Button>
	);
};

PublishTaskButton.locale = {
	nl: {
		publish: "Uitbesteden",
		help: {
			exceeded:
				"Je hebt het uitbesteed limiet van je abonnement verbruikt",
			subscription:
				"Je hebt een basic of premium abonnement nodig om taken uit te besteden",
		},
	},
	en: {
		publish: "Outsource",
		help: {
			exceeded:
				"You have reached the outsource limit of your subscription",
			subscription:
				"You need a basic or premium subscription to outsource tasks",
		},
	},
};

export default withTranslation(PublishTaskButton);
