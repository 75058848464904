import { useEffect } from "react";
import CustomFormPreviewField from "src/components/custom-form/CustomFormPreviewField";
import withTranslation, {
	Translation,
} from "src/components/hoc/withTranslation";
import usePaymentRequestCustomForms from "src/hooks/api/services/forms/usePaymentRequestCustomForms";
import { PaymentRequestFormValue } from "src/hooks/api/services/payment-requests/usePaymentRequests";

interface PaymentRequestCustomFormDetailsProps extends Translation {
	request: PaymentRequestFormValue;
	companyId: string | number;
}

const PaymentRequestCustomFormDetails = ({
	t,
	request,
	companyId,
}: PaymentRequestCustomFormDetailsProps) => {
	const { forms, actions } = usePaymentRequestCustomForms({
		company_id: companyId,
	});
	const values = request?.forms || {};

	useEffect(() => {
		actions.list();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<div className="flex flex-col gap-2">
			{forms.map((form) => (
				<div className="flex flex-col p-3 border border-border rounded-md">
					<span className="opacity-70">{form.name}</span>
					<div className="flex flex-col">
						{form.fields.map((field) => {
							const value = values[form.id]?.[field.id]?.value;
							if (!value) return null;

							return (
								<div className="flex items-center gap-2">
									<div className="w-40 opacity-50">
										<span>{field.label}</span>
									</div>

									<CustomFormPreviewField
										field={{
											...field,
											value,
										}}
									/>
								</div>
							);
						})}
					</div>
				</div>
			))}
		</div>
	);
};

PaymentRequestCustomFormDetails.locale = {
	nl: {
		DATE_START_END_TIME: "Op {{date}} van {{start}} tot {{end}}",
		BOOLEAN: {
			true: "Ja",
			false: "Nee",
		},
	},
	en: {
		DATE_START_END_TIME: "{{date}} from {{start}} to {{end}}",
		BOOLEAN: {
			true: "Yes",
			false: "No",
		},
	},
};

export default withTranslation(PaymentRequestCustomFormDetails);
