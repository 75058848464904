import moment from "moment";
import { ApiIdeaRelease } from "src/api/types";
import Avatar from "src/components/Avatar";
import withTranslation, {
	Translation,
} from "src/components/hoc/withTranslation";

function wrapImgTags(htmlString: string) {
	const wrapper = document.createElement("div");
	wrapper.innerHTML = htmlString;

	const imgTags = wrapper.getElementsByTagName("img");
	for (let i = 0; i < imgTags.length; i++) {
		const imgTag: any = imgTags[i];
		const divWrapper = document.createElement("div");
		divWrapper.classList.add("img-cover");
		imgTag.parentNode.insertBefore(divWrapper, imgTag);
		divWrapper.appendChild(imgTag);
	}

	return wrapper.innerHTML;
}

interface ReleaseItemProps extends Translation {
	release: ApiIdeaRelease;
}

const ReleaseItem = ({ t, release }: ReleaseItemProps) => {
	return (
		<div className="max-w-md flex flex-col mx-auto w-full my-8 gap-4">
			<div className="flex flex-col gap-2">
				<h2>{release.title}</h2>
				<p className="opacity-50">
					{moment(release.created_at).format("DD-MM-YYYY HH:mm")}
				</p>
			</div>
			<div
				className="description description-preview description-preview-release"
				dangerouslySetInnerHTML={{
					__html: wrapImgTags(release.description),
				}}
			></div>
			<div className="flex items-center gap-2 flex-wrap justify-between">
				<strong>{t("author")}</strong>
				<Avatar.User user={release.author} />
			</div>
		</div>
	);
};

ReleaseItem.locale = {
	nl: {
		author: "Gemaakt door",
	},
	en: {
		author: "Create by",
	},
};

export default withTranslation(ReleaseItem);
