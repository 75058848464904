import moment from "moment";
import { useParams } from "react-router-dom";
import useProjects from "src/hooks/api/services/projects/useProjects";
import useBoards from "src/hooks/api/services/tasks/useBoards";
import useTasksFilter from "src/hooks/api/tasks/useTasksFilter";
import useAuth from "src/hooks/selectors/useAuth";
import { uploadFile } from "src/lib/uploadFile";

export default function useParseUpdateTaskData() {
	const auth = useAuth();
	const { board: _board } = useParams() as any;
	const { boards } = useBoards();
	const { projects } = useProjects();
	const { filter } = useTasksFilter();
	const board = filter.board
		? boards.find(
				(item) => item.slug === filter.board || item.slug === _board
		  )
		: null;
	const project = filter.project
		? projects.find((item) => item.slug === filter.project)
		: null;

	return async (_data: any, variant: "CREATE" | "UPDATE") => {
		const address = _data.address;
		delete _data.address;
		let data = {
			..._data,
			...(address || {}),
		} as any;
		const subtasks = data?.subtasks || [];

		if (variant === "CREATE") {
			if (board?.slug && board?.hid) {
				data.board = board.hid;
			}

			if (project?.slug && project?.hid && _data?.project === undefined) {
				data.project = project.hid;
			}
		}

		if (data.is_office) {
			data = {
				...data,
				...auth?.company.address,
			};
		}

		if (data.media) {
			let uploaded_media = [];
			for (let file of data.media) {
				if (file.id) {
					uploaded_media.push(file.id);
				} else {
					let fd = new FormData();
					fd.append("file", file);
					let res = await uploadFile("/media", fd);
					uploaded_media.push(res?.data?.data?.id || null);
				}
			}
			data.media = uploaded_media;
		}

		if (data.start_date) {
			data.start_date = moment(data.start_date).format(
				"YYYY-MM-DD HH:mm:ss"
			);
		}

		if (data.end_date) {
			data.end_date = moment(data.end_date).format("YYYY-MM-DD HH:mm:ss");
		}

		if (subtasks?.length) {
			const start = subtasks
				.map((item: any) => item.start_date)
				.reduce((c: any, n: any) =>
					Date.parse(n) < Date.parse(c) ? n : c
				);
			const end = subtasks
				.map((item: any) => item.start_date)
				.reduce((c: any, n: any) =>
					Date.parse(n) > Date.parse(c) ? n : c
				);

			if (start && end) {
				data.start_date = moment(start).format("YYYY-MM-DD HH:mm:ss");
				data.end_date = moment(end).format("YYYY-MM-DD HH:mm:ss");
			}
		}

		return data;
	};
}
