import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ApiTaskKanban } from "src/api/types";
import TaskKanbanCard from "src/components/tasks/kanban/TaskKanbanCard";
import useTasks from "src/hooks/api/services/tasks/useTasks";
import useTaskForm from "src/hooks/api/tasks/useTaskForm";
import useAuth from "src/hooks/selectors/useAuth";
import useBackUrl from "src/hooks/useBackUrl";
import useNavigateModal from "src/hooks/useNavigateModal";

interface ItemProps {
	task: ApiTaskKanban;
}

const Item = ({ task }: ItemProps) => {
	const navigate = useNavigate();
	const { board, project } = useParams();
	const auth = useAuth();
	const navigateModal = useNavigateModal();
	const back = useBackUrl();
	const form = useTaskForm();
	const { actions } = useTasks();

	const handleDescriptionBlur = (data: ApiTaskKanban) => {
		if (!data.title) return;
		form.actions
			.create("INTERNAL", {
				data: {
					...data,
					assignees: [...(data?.links?.assignees || []), auth.id],
					disable_socket: true,
					variant: "kanban",
				},
				disableRedirect: true,
			})
			.then((task) => {
				if (data._id) {
					actions.updateNewItem(data._id, task);
				}
			});
	};

	return (
		<TaskKanbanCard
			// actions={<TaskKanbanCard.Actions />}
			onClick={(e) => {
				if (task.mode === "NEW") return;
				e.stopPropagation();
				if (task.type === "INTERNAL") {
					navigateModal(
						board
							? `/tasks/boards/${board}/${task.hid}/show`
							: project
							? `/projects/${project}/tasks/${task.hid}/show`
							: `/tasks/${task.hid}/show`
					);
				} else {
					navigate(`/tasks/${task.hid}/show?${back}`);
				}
			}}
			{...{ task }}
		>
			<div className="flex flex-col gap-2">
				<TaskKanbanCard.Title
					onBlur={
						task.mode === "NEW" && task?._id
							? (value) =>
									handleDescriptionBlur({
										...task,
										title: value,
									})
							: undefined
					}
				/>
				<div className="flex flex-wrap gap-2">
					<TaskKanbanCard.Status />
					<TaskKanbanCard.Tags />
				</div>
				<TaskKanbanCard.ProjectLink />
				<TaskKanbanCard.Registrations />
			</div>
		</TaskKanbanCard>
	);
};

export default Item;
