import { useMemo } from "react";
import { ApiForm, ApiFormField } from "src/api/types";
import withTranslation, {
	Translation,
} from "src/components/hoc/withTranslation";

interface CustomSelectedFiltersProps extends Translation {
	forms: ApiForm[];
	filter: any;
}

const CustomSelectedFilters = ({
	t,
	forms,
	filter,
}: CustomSelectedFiltersProps) => {
	const fields = useMemo(() => {
		return forms
			.map((form) => {
				return form.fields;
			})
			.flat();
	}, [forms]);
	return (
		<div className="flex flex-col gap-2">
			{fields.map((field: ApiFormField) => {
				const value = filter[field.id];
				if (!value) return null;

				if (field.type === "BOOLEAN") {
					return (
						<div key={field.id} className="flex gap-2 items-center">
							<span className="opacity-70">{field.label}:</span>
							{t(`boolean.${value ? "true" : "false"}`)}
						</div>
					);
				}

				return (
					<div key={field.id} className="flex gap-2 items-center">
						<span className="opacity-70">{field.label}:</span>
						{Array.isArray(value) ? (
							<div className="flex flex-wrap gap-2">
								{value.join(", ")}
							</div>
						) : (
							<span>{value}</span>
						)}
					</div>
				);
			})}
		</div>
	);
};

CustomSelectedFilters.locale = {
	nl: {
		title: "Filters",
		boolean: {
			true: "Ja",
			false: "Nee",
		},
	},
	en: {
		title: "Filters",
		boolean: {
			true: "Yes",
			false: "No",
		},
	},
};

export default withTranslation(CustomSelectedFilters);
