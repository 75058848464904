import { Navigate, useParams } from "react-router-dom";
import AuthLayout, {
	AuthLayoutContainer,
} from "src/components/layout/auth-layout/AuthLayout";

const SignupCompanySubscriptionCheckoutThanksView = () => {
	const params = useParams();

	if (!params.session_id) return <Navigate to="/signup/company" />;

	return (
		<AuthLayout variant="flat">
			<AuthLayoutContainer></AuthLayoutContainer>
		</AuthLayout>
	);
};

export default SignupCompanySubscriptionCheckoutThanksView;
