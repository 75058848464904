import React from "react";
import FormGrid from "src/components/form/FormGrid";

interface NotificationSettingsSectionProps {
	title: string;
	children?: any;
}

const NotificationSettingsSection = ({
	title,
	children,
}: NotificationSettingsSectionProps) => {
	return (
		<>
			<FormGrid.Header {...{ title }} />
			<FormGrid.Card className="w-full flex flex-col">
				<div className="flex flex-col gap-6">{children}</div>
			</FormGrid.Card>
		</>
	);
};

export default NotificationSettingsSection;
