import { useEffect, useState } from "react";
import { Navigate, useParams } from "react-router-dom";
import SignupForm from "src/components/forms/auth/signup/SignupForm";
import withTranslation, {
	Translation,
} from "src/components/hoc/withTranslation";
import AuthLayout, {
	AuthLayoutContainer,
} from "src/components/layout/auth-layout/AuthLayout";
import Loader from "src/components/Loader";
import useAuthSession from "src/hooks/api/services/session/useAuthSession";
import useTenant from "src/hooks/api/services/tenants/useTenant";
import usePolling from "src/hooks/usePolling";
import useQueryString from "src/hooks/useQueryString";

function SignupView({ t }: Translation) {
	const { tenant } = useTenant();
	const params = useParams();
	const qs = useQueryString();
	const { session, actions } = useAuthSession();
	const [isSessionFetched, setIsSessionFetched] = useState(false);
	const mandateStatus = session?.mandate?.status;
	const isMandatePending = ["pending", "created"].includes(mandateStatus);
	usePolling({
		fn: async () => {
			if (params.session) {
				actions.find(params.session?.toString());
			}
		},
		interval: 2000,
		enabled:
			params.session &&
			(isMandatePending ||
				(session?.interval === "ONE_TIME" &&
					session?.payment?.status === "pending"))
				? true
				: false,
	});

	useEffect(() => {
		if (params.session) {
			actions
				.find(params.session.toString())
				.finally(() => setIsSessionFetched(true));
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	if (
		!params.session &&
		["getjobsdone", "getrecruitmentdone"].includes(tenant.slug)
	) {
		return <Navigate to="/choose" />;
	}

	if (params.session && !session?.id && isSessionFetched) {
		return <Navigate to="/signup" />;
	}

	if (params.session && !isSessionFetched) {
		return (
			<div className="flex flex-col justify-center items-center flex-1">
				<Loader />
			</div>
		);
	}

	if (session?.type === "SUBSCRIPTION_CHECKOUT" && isMandatePending) {
		return (
			<AuthLayout variant="flat">
				<AuthLayoutContainer className="flex flex-col flex-1 justify-center items-center">
					<div className="flex flex-col max-w-md gap-2 justify-center items-center text-center">
						<h2>{t("mandate.pending.title")}</h2>
						<p>{t("mandate.pending.description")}</p>
					</div>
				</AuthLayoutContainer>
			</AuthLayout>
		);
	}

	if (
		session?.type === "SUBSCRIPTION_CHECKOUT" &&
		!["completed"].includes(mandateStatus) &&
		params.session &&
		session?.id &&
		!session?.is_invoice &&
		session.interval !== "ONE_TIME"
	) {
		return (
			<Navigate
				to={`/signup/company/subscription/${session?.plan}?interval=${session?.interval}&error=mandate-failed&session_id=${params.session}`}
			/>
		);
	}

	if (
		session?.type === "SUBSCRIPTION_CHECKOUT" &&
		session?.interval === "ONE_TIME"
	) {
		//Redirect if the payment failed
		if (
			!session.payment ||
			["expired", "cancelled", "failed"].includes(session.payment.status)
		) {
			return (
				<Navigate
					to={`/signup/company/subscription/${session?.plan}?interval=${session?.interval}&error=mandate-failed&session_id=${params.session}`}
				/>
			);
		}
	}

	return (
		<AuthLayout
			head={{
				title:
					qs.state === "subscribing"
						? t("subscribing.title")
						: t("title"),
				back:
					qs.state !== "subscribing"
						? {
								to: "/login",
								label: t("back"),
						  }
						: undefined,
			}}
		>
			<SignupForm
				hideTerms={qs.agreed === "true"}
				sessionId={params.session}
				defaultValue={{
					agreed: qs.agreed === "true",
					...(session?.user || {}),
				}}
			/>
		</AuthLayout>
	);
}

SignupView.locale = {
	nl: {
		title: "Aanmelden",
		back: "Terug naar inloggen",
		subscribing: {
			title: "Maak je account compleet",
		},
		mandate: {
			pending: {
				title: "Een ogenblik geduld",
				description:
					"De koppeling met je bank wordt gecontroleerd. Dit duurt maximaal een minuut, mocht het te lang duren neem dan even contact op met support@{{tenant.slug}}.nl",
			},
		},
	},
	en: {
		title: "Signup",
		back: "Back to login",
		subscribing: {
			title: "Complete your account",
		},
		mandate: {
			pending: {
				title: "A moment of patience",
				description:
					"Your bank connection is being checked. This usually takes less than a minute. If it takes longer, please contact support@{{tenant.slug}}.nl",
			},
		},
	},
};
export default withTranslation(SignupView);
