const envelope = {
	envelopes: {
		title: "Overeenkomsten",
		status: {
			open: "Open",
			signed: "Ondertekend",
		},
	},
};

export default envelope;
