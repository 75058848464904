import React, { useState } from "react";
import { ApiIdea } from "src/api/types";
import LikeButton from "src/components/form/LikeButton";
import useFeedback from "src/hooks/api/services/useFeedback";

interface FeedbackLikeButtonProps {
	idea: ApiIdea;
}

const FeedbackLikeButton = ({ idea }: FeedbackLikeButtonProps) => {
	const { actions } = useFeedback();
	const [loading, setLoading] = useState(false);

	const onChange = () => {
		if (loading) return;
		setLoading(true);
		actions.like(idea.id).then(() => {
			setLoading(false);
		});
	};

	return (
		<LikeButton
			isLiked={idea.likes.is_liked}
			count={idea.likes.total}
			{...{ onChange, loading }}
		/>
	);
};

export default FeedbackLikeButton;
