import FormGrid from "src/components/form/FormGrid";
import CompanySignerForm from "src/components/forms/profile/CompanySigner";
import EditAddress from "src/components/forms/profile/EditAddress";
import EditAvatar from "src/components/forms/profile/EditAvatar";
import EditBankAccount from "src/components/forms/profile/EditBankAccount";
import EditUser from "src/components/forms/profile/EditUser";
import ResetPassword from "src/components/forms/profile/ResetPassword";
import withTranslation, {
	Translation,
} from "src/components/hoc/withTranslation";
import KeyValue from "src/components/KeyValue";
import LanguageSelectList from "src/components/language/LanguageSelectList";
import ProfileSettingsLayout from "src/components/layout/profile-settings-layout/ProfileSettingsLayout";
import InterestsForm from "src/components/resume/elements/InterestsForm";
import usePermissions from "src/hooks/api/services/auth/usePermissions";
import useTenant from "src/hooks/api/services/tenants/useTenant";
import useAuth from "src/hooks/selectors/useAuth";

function ProfileView({ t }: Translation) {
	const permissions = usePermissions();
	const auth = useAuth();
	const {
		tenant: { modules },
	} = useTenant();

	if (auth.type === "company") {
		return (
			<ProfileSettingsLayout title={t("title")}>
				<FormGrid>
					<FormGrid.Header title={t("avatar.title")}>
						{t("avatar.description")}
					</FormGrid.Header>
					<FormGrid.Card>
						<EditAvatar variant="profile-picture-in-company" />
					</FormGrid.Card>
					<FormGrid.Header
						title={t("cards.account")}
					></FormGrid.Header>
					<FormGrid.Card>
						<EditUser />
					</FormGrid.Card>
					{modules.includes("extern-task") && (
						<>
							<FormGrid.Header
								title={t("cards.signer")}
							></FormGrid.Header>
							<FormGrid.Card>
								<CompanySignerForm />
							</FormGrid.Card>
						</>
					)}
					<FormGrid.Header
						title={t("cards.password")}
					></FormGrid.Header>
					<FormGrid.Card>
						<ResetPassword />
					</FormGrid.Card>
					<FormGrid.Header title={t("cards.language")} />
					<FormGrid.Card>
						<LanguageSelectList
							button={{
								label: t("save"),
							}}
						/>
					</FormGrid.Card>
				</FormGrid>
			</ProfileSettingsLayout>
		);
	}

	return (
		<ProfileSettingsLayout title={t("title")}>
			<FormGrid>
				<FormGrid.Header title={t("cards.account")} />
				<FormGrid.Card>
					<EditUser />
				</FormGrid.Card>
				<FormGrid.Header title={t("cards.password")} />
				<FormGrid.Card>
					<ResetPassword />
				</FormGrid.Card>
				{auth.merchant_id &&
					permissions.has("registrations.create") && (
						<EditBankAccount as="form-grid" />
					)}

				{modules.includes("extern-task") &&
					permissions.has("registrations.create") && (
						<>
							<FormGrid.Header title={t("cards.address")} />
							<FormGrid.Card>
								<div className="flex flex-col gap-1 mb-4">
									{auth?.address ? (
										<div className="flex flex-col gap-2">
											<KeyValue.Small
												label={t("address.street")}
												text={auth?.address?.street}
											/>
											<KeyValue.Small
												label={t(
													"address.house-number"
												)}
												text={
													auth?.address?.house_number
												}
											/>
											<KeyValue.Small
												label={t("address.zipcode")}
												text={auth?.address?.zipcode}
											/>
											<KeyValue.Small
												label={t("address.city")}
												text={auth?.address?.city}
											/>
										</div>
									) : (
										<p>{t("address.missing")}</p>
									)}
								</div>
								<EditAddress />
							</FormGrid.Card>
						</>
					)}

				{modules.includes("extern-task") &&
					permissions.has("registrations.create") && (
						<>
							<FormGrid.Header title={t("cards.interests")} />
							<FormGrid.Card>
								<InterestsForm>
									<InterestsForm.List />
									<div className="flex mt-2">
										<InterestsForm.SubmitButton>
											{t("save")}
										</InterestsForm.SubmitButton>
									</div>
								</InterestsForm>
							</FormGrid.Card>
						</>
					)}

				<FormGrid.Header title={t("cards.language")} />
				<FormGrid.Card>
					<LanguageSelectList
						button={{
							label: t("save"),
						}}
					/>
				</FormGrid.Card>
			</FormGrid>
		</ProfileSettingsLayout>
	);
}

ProfileView.locale = {
	nl: {
		title: "Mijn profiel",
		save: "Opslaan",
		avatar: {
			title: "Avatar",
			description:
				"Voeg een profielfoto toe, hierdoor herkennen je collega's sneller jouw opdrachten.",
		},
		cards: {
			account: "Accountgegevens",
			signer: "Ondertekenaar",
			password: "Wachtwoord",
			language: "Taal",
			address: "Adres wijzigen",
			interests: "Interesses",
		},
		address: {
			street: "Straat",
			"house-number": "Huisnummer",
			zipcode: "Postcode",
			city: "Stad",
			missing: "We missen nog een persoonlijk adres",
		},
	},
	en: {
		title: "My profile",
		save: "Save",
		avatar: {
			title: "Avatar",
			description:
				"Add your profile picture so that your colleagues can easily recognize your assignments.",
		},
		cards: {
			account: "Account information",
			signer: "Signer",
			password: "Password",
			language: "Language",
			address: "Change address",
			interests: "Interests",
		},
		address: {
			street: "Street",
			"house-number": "House number",
			zipcode: "Zip code",
			city: "City",
			missing: "Your personal address is mising",
		},
	},
};

export default withTranslation(ProfileView);
