import { cn } from "src/lib/utils";

interface StarsProps {
	rating?: number;
	className?: string;
	size: string | null;
	activeClassName?: string;
	inActiveClassName?: string;
	iconClassName?: string;
	onClick?: (value: number) => void;
}

const Stars = ({
	rating,
	className,
	size,
	activeClassName,
	inActiveClassName,
	iconClassName,
	onClick,
}: StarsProps) => (
	<div className={cn("flex gap-1 items-center", className)}>
		{new Array(5).fill(" ").map((_, index) => {
			const position = index + 1;
			const isActive =
				rating !== undefined && position <= Math.round(rating);
			return (
				<i
					onClick={() => {
						if (onClick) {
							onClick(position === rating ? 0 : position);
						}
					}}
					key={`star-${position}`}
					className={`${
						isActive ? "fas" : "far"
					} fa-star transition-all ${size && `text-[${size}]`} ${
						isActive ? activeClassName : inActiveClassName
					} ${onClick && "cursor-pointer"} ${iconClassName || ""}`}
				></i>
			);
		})}
	</div>
);

Stars.defaultProps = {
	size: "16px",
	activeClassName: "text-review",
	inActiveClassName: "text-placeholder",
};

export default Stars;
