const form = {
	placeholder: {
		search: {
			keyword: "Zoek op trefwoorden",
		},
	},
	dropdown: {
		empty: {
			description: "Geen items gevonden",
		},
	},
	"date-of-birth-picker": {
		placeholder: {
			day: "Dag",
			month: "Maand",
			year: "Jaar",
		},
	},
	"date-range-picker": {
		placeholder: {
			start: "Start datum",
			end: "Einddatum",
		},
	},
	"multi-select-list": {
		placeholder: "Zoeken...",
	},
	"file-upload": {
		loading: "Een ogenblik geduld, dit kan een paar minuten duren",
		placeholder: "Klik hier om te uploaden of sleep het bestand erover",
		"placeholder-active": "Plaats het bestand hier",
		"max-file-size": "Maximaal 10MB",
		failed: {
			description: "Het geselecteerde bestand kon niet worden geupload",
		},
	},
	"form-group": {
		optional: "Optioneel",
	},
	"category-interest-text": {
		empty: "Selecteer een categorie om te zien hoeveel professionals interesse hebben in jouw job",
		text_one:
			"Er is <1>1 professional</1> met interesse in <3>{{category}}</3>",
		text_other:
			"Er zijn <1>{{count}} professionals</1> met interesse in <3>{{category}}</3>",
	},
	input: {
		clear: "Leeg maken",
		"date-placeholder": "dd-mm-jjjj",
		telephone: {
			search: {
				placeholder: "Land zoeken...",
			},
		},
	},
};

export default form;
