import moment from "moment";
import Field from "src/components/field/Field";
import * as SkillSelector from "src/components/form/skill-selector/SkillSelector";
import withTranslation, {
	Translation,
} from "src/components/hoc/withTranslation";
import { getDeadlineColor } from "src/components/tasks/TaskDeadlineText";
import useProjects from "src/hooks/api/services/projects/useProjects";
import useAuth from "src/hooks/selectors/useAuth";

interface ProjectFormProps extends Translation {}

const ProjectForm = ({ t }: ProjectFormProps) => {
	const auth = useAuth();
	const { formState, setFormState } = useProjects();

	return (
		<div className="flex flex-col gap-[6px]">
			<Field.Date
				wrapper={{
					label: t("fields.start-date.label"),
					layout: "horizontal",
				}}
				value={
					formState.start_date
						? moment(formState.start_date).format("YYYY-MM-DD")
						: undefined
				}
				onChange={(value) => {
					if (value) {
						setFormState((prev) => ({
							...prev,
							start_date: value,
						}));
					}
				}}
				theme={{
					placeholder: {
						className: formState.start_date
							? getDeadlineColor(formState.start_date)
							: undefined,
					},
				}}
			/>
			<Field.Date
				wrapper={{
					label: t("fields.end-date.label"),
					layout: "horizontal",
				}}
				value={
					formState.end_date
						? moment(formState.end_date).format("YYYY-MM-DD")
						: undefined
				}
				onChange={(value) => {
					setFormState((prev) => ({
						...prev,
						end_date: value || "",
					}));
				}}
				theme={{
					placeholder: {
						className: formState.end_date
							? getDeadlineColor(formState.end_date)
							: undefined,
					},
				}}
			/>
			<Field.Users
				wrapper={{
					label: t("fields.members.label"),
					layout: "horizontal",
				}}
				value={
					formState.members
						? formState.members.map((user: any) =>
								typeof user === "object"
									? user.id.toString()
									: user
						  )
						: []
				}
				onChange={(value) => {
					if (
						value?.includes("me") &&
						formState.members.find((u: any) => u.id === auth.id)
					) {
						value = value.filter(
							(item: any) =>
								item !== "me" &&
								item !== auth?.id &&
								item !== auth.id.toString()
						);
					}
					setFormState((prev) => ({ ...prev, members: value }));
				}}
			/>
			<hr className="border-border my-4" />
			<div className="flex flex-col gap-2">
				<p className="mb-0">{t("fields.skills.label")}</p>
				<SkillSelector.Root
					value={formState.skills || []}
					onChange={(skills) =>
						setFormState((prev) => ({
							...prev,
							skills,
						}))
					}
				>
					<SkillSelector.Results />
					<SkillSelector.Input />
					<SkillSelector.Description className="mb-0">
						{t("fields.skills.description")}
					</SkillSelector.Description>
				</SkillSelector.Root>
			</div>
			<hr className="border-border my-4" />
		</div>
	);
};

ProjectForm.locale = {
	nl: {
		fields: {
			members: {
				label: "Leden",
			},
			"start-date": {
				label: "Start datum",
			},
			"end-date": {
				label: "Eind datum",
			},
			skills: {
				label: "Voeg vaardigheden toe die horen bij dit project",
				description: "Deze vaardigheden worden gebruikt door onze AI",
			},
		},
	},
	en: {
		fields: {
			members: {
				label: "Members",
			},
			"start-date": {
				label: "Start date",
			},
			"end-date": {
				label: "End date",
			},
		},
	},
};

export default withTranslation(ProjectForm);
