import { useEffect, useState } from "react";
import _ from "lodash";
import { ApiTaskMinimal, ApiRegistrationTimesheet } from "src/api/types";
import FlatList from "src/components/FlatList";
import withTranslation, {
	Translation,
} from "src/components/hoc/withTranslation";
import useTimesheets from "src/hooks/api/services/timesheets/useTimesheets";
import TimesheetListItem from "src/components/lists/list-items/TimesheetListItem";
import TimesheetListFooter from "src/components/lists/footers/TimesheetListFooter";
import Button from "src/components/Button";
import Checkbox from "src/components/form/Checkbox";
import TimesheetApproveModal from "src/components/timesheet/modals/TimesheetApproveModal";
import TimesheetEditModal from "src/components/timesheet/modals/TimesheetEditModal";
import { formatPrice } from "src/lib/formatters";
import useUnreads from "src/hooks/api/useUnreads";
import useTimesheetsTotals from "src/hooks/api/services/timesheets/useTimesheetsTotals";
import TimesheetMissingList from "src/components/lists/TimesheetMissingList";

interface TimesheetListProps extends Translation {
	task: ApiTaskMinimal;
}

const TimesheetList = ({ t, task }: TimesheetListProps) => {
	const [selected, setSelected] = useState<number[]>([]);
	const [modal, setModal] = useState<"accept" | "edit">();
	const [timesheet, setTimesheet] = useState<ApiRegistrationTimesheet>();
	const { fetchUnreads } = useUnreads();
	const { timesheets, actions, status, pagination } = useTimesheets({
		task_id: task.id,
	});
	const { totals, actions: totalsActions } = useTimesheetsTotals({
		task_id: task.id,
	});

	useEffect(() => {
		actions.list();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const headers = [
		<Checkbox
			value={
				(timesheets || []).every((item) =>
					selected.includes(item?.current?.id)
				) &&
				status === "idle" &&
				timesheets.length !== 0
			}
			onChange={(value) => {
				setSelected(
					_.xor(
						selected,
						timesheets
							.filter(
								(item) =>
									!["approved", "processed"].includes(
										item?.current?.status
									)
							)
							.map((item) => item?.current?.id)
					)
				);
			}}
		/>,
		t("headers.status"),
		t("headers.user"),
		t("headers.start"),
		t("headers.finish"),
		t("headers.break"),
		t("headers.total"),
		t("headers.price"),
		"",
	];

	return (
		<>
			<TimesheetApproveModal
				visible={modal === "accept"}
				onClose={(status) => {
					if (status === "completed") {
						setSelected([]);
						actions.list(
							{
								page: pagination.page,
							},
							{
								disableLoading: true,
							}
						);
						totalsActions.get();
						fetchUnreads();
					}
					setModal(undefined);
				}}
				links={{
					selected,
					task: task.id,
				}}
			/>
			<TimesheetEditModal
				visible={modal === "edit" && timesheet?.id ? true : false}
				timesheet={timesheet as any}
				onClose={() => {
					setSelected([]);
					setModal(undefined);
					setTimesheet(undefined);
					totalsActions.get();
				}}
				links={{
					task: task.id,
				}}
			/>
			<div className="flex flex-col md:grid grid-cols-3 gap-4">
				<div className="flex flex-col gap-4 flex-1 md:col-span-2">
					<FlatList.Head
						title={task.title}
						subtitle={t(`subtitle`, {
							title: task?.parent?.title || task.title,
							hour_rate: formatPrice(task.budget_per_hour),
						})}
						search={{
							onSearch: (q: string) => {
								actions.list({ page: 1, q });
							},
						}}
					>
						{selected.length > 0 && (
							<Button
								onClick={() => setModal("accept")}
								className="whitespace-nowrap"
							>
								{`(${selected.length}) ${t("buttons.approve")}`}
							</Button>
						)}
						{totals.approved > 0 && (
							<Button
								className="whitespace-nowrap"
								to={`/hours/${task.hid}/show/process`}
								type="dark"
							>
								{`(${totals.approved}) ${t(
									"buttons.process-payment"
								)}`}
							</Button>
						)}
					</FlatList.Head>
					<FlatList
						{...{ pagination }}
						data={timesheets}
						table={{
							headers,
							footer: <TimesheetListFooter {...{ task }} />,
						}}
						loading={status !== "idle"}
						renderItem={(
							timesheet: ApiRegistrationTimesheet,
							index
						) => {
							if (!timesheet?.current?.id) {
								return <></>;
							}
							return (
								<TimesheetListItem
									key={`timesheet-${timesheet.id}-${index}`}
									select={{
										active: selected.includes(
											timesheet?.current?.id
										),
										onSelect: () =>
											setSelected(
												_.xor(selected, [
													timesheet?.current?.id,
												])
											),
									}}
									onEdit={() => {
										setModal("edit");
										setTimesheet(timesheet);
									}}
									{...{ timesheet }}
								/>
							);
						}}
						empty={{
							title: t("empty.title"),
							text: t("empty.text"),
						}}
					/>
				</div>
				<TimesheetMissingList {...{ task }} />
			</div>
		</>
	);
};

TimesheetList.locale = {
	nl: {
		subtitle: "{{title}} | {{hour_rate}} per uur",
		empty: {
			title: "Geen uren gevonden",
			text: "Gebruikers hebben nog geen uren opgegeven",
		},
		headers: {
			status: "Status",
			user: "Uitvoerder",
			// hours: "Start / Eind / Pauze",
			start: "Starttijd",
			finish: "Eindtijd",
			break: "Pauze",
			total: "Totaal",
			price: "Bedrag",
		},
		buttons: {
			approve: "Goedkeuren",
			"process-payment": "Betaling verwerken",
		},
	},
	en: {
		subtitle: "{{title}} | {{hour_rate}} per hour",
		empty: {
			title: "No hours found",
			text: "Users has not yet submitted there hours",
		},
		headers: {
			status: "Status",
			user: "User",
			// hours: "Start / Eind / Pauze",
			start: "Start time",
			finish: "End time",
			break: "break",
			total: "Total",
			price: "Amount",
		},
		buttons: {
			approve: "Approve",
			"process-payment": "Process payment",
		},
	},
};

export default withTranslation(TimesheetList);
