import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Button from "src/components/Button";
import ConnectionSelector, {
	ConnectionSelectorItem,
	ConnectionSelectorItemAvatar,
	ConnectionSelectorItemName,
	ConnectionSelectorItemToggle,
	ConnectionSelectorList,
	ConnectionSelectorListSelectAll,
	ConnectionSelectorListTitle,
	ConnectionSelectorListTotal,
	ConnectionSelectorSearch,
} from "src/components/form/connection-selector/ConnectionSelector";
import withTranslation, {
	Translation,
} from "src/components/hoc/withTranslation";
import useTask from "src/hooks/api/tasks/useTask";
import usePost from "src/hooks/rest/usePost";
import useModal from "src/hooks/useModal";

interface TaskAddRegistrationModalProps extends Translation {}

const TaskAddRegistrationModal = ({ t }: TaskAddRegistrationModalProps) => {
	useModal({
		title: t("title"),
	});
	const params = useParams();
	const navigate = useNavigate();
	const { task } = useTask();
	const [users, setUsers] = useState<string[]>([]);
	const [addRegistration, loading] = usePost(
		`/client/tasks/${task.id}/registrations`
	);
	const [response, setResponse] = useState<null | {
		status: "success" | "error";
		count?: number;
	}>();
	const onSubmit = async () => {
		if (users.length === 0 || loading) return;

		try {
			await addRegistration({ users });
			navigate(`/tasks/${params.id}/${params.pane}`);
		} catch (error) {
			setResponse({
				status: "error",
			});
		}
	};

	return (
		<div className="flex flex-col gap-4 relative">
			{response?.status === "error" && (
				<div className="p-4 bg-red-300 rounded-md text-red  mb-4">
					{t("error")}
				</div>
			)}

			<ConnectionSelector
				value={users}
				onChangeValue={setUsers}
				className="flex flex-col gap-4"
			>
				<ConnectionSelectorSearch />
				<div className="flex flex-col md:flex-row gap-4">
					{["open", "selected"].map((variant: any) => (
						<div
							key={variant}
							className="flex flex-col rounded-lg flex-1 border border-border p-4 gap-3"
						>
							<div className="flex gap-3 items-center justify-between">
								<div className="flex flex-col">
									<ConnectionSelectorListTitle
										variant={variant}
									/>
									<ConnectionSelectorListTotal
										variant={variant}
										className="opacity-70"
									/>
								</div>
								<ConnectionSelectorListSelectAll
									variant={variant}
								/>
							</div>
							<ConnectionSelectorList {...{ variant }}>
								{({ item }) => (
									<ConnectionSelectorItem
										item={item}
										className="flex items-center gap-2"
									>
										<ConnectionSelectorItemAvatar />
										<ConnectionSelectorItemName className="flex-1" />
										<ConnectionSelectorItemToggle />
									</ConnectionSelectorItem>
								)}
							</ConnectionSelectorList>
						</div>
					))}
				</div>
			</ConnectionSelector>
			<div className="sticky bottom-0 z-[99] left-0 right-0 p-3 flex justify-end">
				<Button onClick={onSubmit} disabled={users.length === 0}>
					{t("buttons.add")}
				</Button>
			</div>
		</div>
	);
};

TaskAddRegistrationModal.locale = {
	nl: {
		title: "Aanmelding toevoegen",
		user: "Opdrachtnemer",
		error: "Er is iets misgegaan, probeer het later opnieuw.",
		buttons: {
			add: "Toevoegen",
		},
	},
	en: {
		title: "Add registration",
		user: "Client",
		error: "Something went wrong, try again later.",
		buttons: {
			add: "Add",
		},
	},
};

export default withTranslation(TaskAddRegistrationModal);
