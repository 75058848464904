import { atom } from "jotai";
import { useEffect, useState } from "react";
import Button from "src/components/Button";
import Input from "src/components/form/Input";
import withTranslation, {
	Translation,
} from "src/components/hoc/withTranslation";
import useStateForm from "src/hooks/schemes/useForm";

const formAtom = atom({});
const validateAtom = atom({});

export type FormValues = {
	name: string;
	description?: string;
};

interface GeneralCustomFormProps extends Translation {
	variant?: "create" | "update";
	onSubmit: (values: FormValues) => Promise<void>;
	defaultValue?: FormValues;
}

const GeneralCustomForm = ({
	t,
	variant = "create",
	onSubmit,
	defaultValue,
}: GeneralCustomFormProps) => {
	const [isSubmitting, setIsSubmitting] = useState(false);
	const { form, actions, validate } = useStateForm({
		atoms: { form: formAtom, validate: validateAtom },
		scheme: {
			name: ["string", "required"],
			description: ["string"],
		},
	});

	useEffect(() => {
		actions.setForm(defaultValue || {}, false);
		return () => {
			actions.resetForm();
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleSubmit = async (e: any) => {
		e.preventDefault();
		if (!validate.isValid) {
			return;
		}
		setIsSubmitting(true);
		try {
			await onSubmit(form);
			setIsSubmitting(false);
		} catch (error) {
			setIsSubmitting(false);
		}
	};

	return (
		<form onSubmit={handleSubmit} className="flex flex-col gap-6">
			<div className="flex flex-col gap-4">
				<Input
					{...actions.getField("name")}
					label={t("fields.name.label")}
					placeholder={t("fields.name.placeholder")}
					autoFocus={variant === "create"}
				/>
				<Input
					{...actions.getField("description")}
					label={t("fields.description.label")}
					placeholder={t("fields.description.placeholder")}
					multiline
				/>
			</div>
			<div className="flex w-full">
				<Button
					submit
					loading={isSubmitting}
					disabled={isSubmitting || !validate.isValid}
					className={variant === "create" ? "flex-1" : ""}
				>
					{t(`buttons.${variant}`)}
				</Button>
			</div>
		</form>
	);
};

GeneralCustomForm.locale = {
	nl: {
		fields: {
			name: {
				label: "Naam",
				placeholder: "Voer een naam in",
			},
			description: {
				label: "Omschrijving (optioneel)",
				placeholder: "Voer een omschrijving in",
			},
		},
		buttons: {
			create: "Aanmaken",
			update: "Opslaan",
		},
	},
	en: {
		fields: {
			name: {
				label: "Name",
				placeholder: "Enter a name",
			},
			description: {
				label: "Description (optional)",
				placeholder: "Enter a description",
			},
		},
		buttons: {
			create: "Create",
			update: "Save",
		},
	},
};

export default withTranslation(GeneralCustomForm);
