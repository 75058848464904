import React from "react";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { ApiReview } from "src/api/types";
import FlatList from "src/components/FlatList";
import Layout from "src/components/layout/Layout";
import TabNav from "src/components/layout/tab-nav/TabNav";
import Review from "src/components/Review";
import useGet from "src/hooks/rest/useGet";
import { useTranslation } from "react-i18next";

export default function ReviewsView() {
	const { t } = useTranslation("review-view", {
		keyPrefix: "index-view",
	});
	const tabs = [
		{
			to: "/reviews/about-me",
			label: t("tabs.received"),
		},
		{
			to: "/reviews/created",
			label: t("tabs.created"),
		},
	];
	const type = useSelector((state: any) => state.auth.type);
	const { pane } = useParams() as any;
	let query = pane === "created" ? "created_by_me=1" : "";
	const [{ data, hasMore }, loading, { fetchNext }] = useGet(
		`/reviews?${query}`,
		{ data: [] },
		true
	);

	return (
		<Layout title={t("title")}>
			<TabNav {...{ tabs, type }} />
			<FlatList
				empty={{
					title: t(
						`empty.${pane === "created" ? "created" : "received"}`
					),
				}}
				data={data || []}
				{...{ hasMore, loading }}
				onNext={async () => {
					if (hasMore) await fetchNext();
				}}
				columns={2}
				renderItem={(review: ApiReview) => (
					<Review {...{ review }} key={review.id + "Review"} />
				)}
			/>
		</Layout>
	);
}
