import React, { ReactHTML } from "react";
import { cn } from "src/lib/utils";

export type TitleCutBackground =
	| "white"
	| "gray-background"
	| "dark"
	| "gray"
	| "accent"
	| "background";

interface TitleCutProps {
	children?: any;
	className?: string;
	color?: TitleCutBackground;
	as: keyof ReactHTML;
}

const TitleCut = ({ children, className, color, as }: TitleCutProps) => {
	const Text = as;
	return (
		<div className={`relative block overflow-hidden ${className || ""}`}>
			<Text className="whitespace-nowrap">{children}</Text>
			<div
				className={cn(
					"absolute top-0 right-0 w-24 bottom-0 bg-gradient-to-l",
					color && `from-${color}`
				)}
			></div>
		</div>
	);
};

TitleCut.defaultProps = {
	as: "p",
};

export default TitleCut;
