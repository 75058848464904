import React from "react";
import { cn } from "src/lib/utils";

interface RadioProps {
	value: boolean;
	onChange?: (value: boolean) => void;
	variant: "dark" | "light";
}

const Radio = ({ value, onChange }: RadioProps) => {
	return (
		<div
			onClick={() => {
				if (onChange) {
					onChange(!value);
				}
			}}
			className={cn(
				"w-6 h-6 p-1 rounded-full border-2 transition-all border-border",
				value && "border-primary"
			)}
		>
			<div
				className={cn(
					"w-full h-full flex rounded-full transition-all opacity-0 bg-primary",
					value && "opacity-100"
				)}
			></div>
		</div>
	);
};

Radio.defaultProps = {
	value: false,
	variant: "dark",
};

export default Radio;
