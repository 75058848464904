import { useEffect } from "react";

export function useKeyPress(keyCodes: string[], callback: () => void): void {
	const handler = ({ code }: KeyboardEvent) => {
		if (keyCodes.includes(code)) {
			callback();
		}
	};

	useEffect(() => {
		window.addEventListener("keydown", handler);
		return () => {
			window.removeEventListener("keydown", handler);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
}
