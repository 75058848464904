import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Avatar from "src/components/Avatar";
import CompanyUserForm from "src/components/forms/auth/signup/company/CompanyUserForm";
import withTranslation, {
	Translation,
} from "src/components/hoc/withTranslation";
import AuthLayout from "src/components/layout/auth-layout/AuthLayout";
import useSignup from "src/hooks/api/services/auth/useSignup";

function SignupCompanyInviteView({ t }: Translation) {
	const { hid } = useParams() as any;
	const navigate = useNavigate();
	const { actions, status, invite } = useSignup({
		type: "company-invite",
		inviteId: hid,
	});

	useEffect(() => {
		if (hid) {
			actions.getInvitation().catch(() => {
				navigate("/login?invalid_invite=1");
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const onSubmit = (user: any) => {
		if (status === "loading") {
			return;
		}

		actions.signup({}).catch((error) => {
			console.log(error);
		});
	};

	return (
		<AuthLayout
			head={{
				title: t("title"),
				back: {
					to: "/login",
					label: t("back.label"),
				},
			}}
			loading={status === "loading"}
		>
			{invite?.hid && (
				<>
					<div className="flex flex-col gap-4">
						<div className="flex gap-4 items-end">
							{invite?.company.avatar && (
								<Avatar
									size="large"
									variant="white"
									src={invite?.company?.avatar}
								/>
							)}
							<div className="flex flex-col flex-1">
								<strong>{invite?.company?.name}</strong>
								<span>
									{t("head.subtitle", {
										name: invite?.company?.name,
									})}
								</span>
							</div>
						</div>
					</div>
					<div className="my-4">
						<CompanyUserForm
							type="company-invite"
							hide={["email"]}
							{...{ onSubmit }}
						/>
					</div>
				</>
			)}
		</AuthLayout>
	);
}

SignupCompanyInviteView.locale = {
	nl: {
		title: "Account aanmaken",
		back: {
			label: "Terug naar inloggen",
		},
		head: {
			subtitle:
				"Je bent uitgenodigd om een account aan te maken voor {{name}}",
		},
	},
	en: {
		title: "Create Account",
		back: {
			label: "Back to Login",
		},
		head: {
			subtitle: "You are invited to create an account for {{name}}",
		},
	},
};
export default withTranslation(SignupCompanyInviteView);
