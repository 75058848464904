import { AnimatePresence, motion } from "framer-motion";
import { useState } from "react";
import { ApiIcon } from "src/api/types";
import Icon from "src/components/icon/Icon";
import { cn } from "src/lib/utils";

const HEAD_VARIANTS = {
	default: "",
	sticky: "sticky top-0 z-[2]",
};

interface AccordionProps {
	head: {
		icon?: ApiIcon;
		title: string;
		subtitle?: string;
		variant?: keyof typeof HEAD_VARIANTS;
	};
	children?: any;
	defaultVisible?: boolean;
}

const Accordion = ({ head, children, defaultVisible }: AccordionProps) => {
	const [visible, setVisible] = useState(defaultVisible || false);
	return (
		<div className="flex flex-col py-2">
			<div
				onClick={() => setVisible(!visible)}
				className={`cursor-pointer rounded-md flex items-center gap-8 justify-between py-4 transition-all ${
					HEAD_VARIANTS[head?.variant || "default"]
				}`}
			>
				<div className="flex flex-col md:flex-row flex-1 gap-4">
					{head.icon && (
						<Icon
							icon={head.icon}
							iconFontSize={20}
							className="bg-accent h-12 w-12"
						/>
					)}
					<div className="flex flex-col justify-center gap-1 flex-1">
						<strong className="text-[20px] leading-[20px] mb-0">
							{head.title}
						</strong>
						{head.subtitle && (
							<p className="mb-0 opacity-80">{head.subtitle}</p>
						)}
					</div>
				</div>
				<motion.div
					className="w-8 h-8 flex justify-center items-center relative overflow-hidden rounded-full"
					animate={visible ? "rotated" : "default"}
					variants={{
						default: {
							rotate: 0,
						},
						rotated: {
							rotate: 180,
						},
					}}
				>
					<motion.div
						animate={visible ? "rotated" : "default"}
						variants={{
							default: {
								opacity: 0,
							},
							rotated: {
								opacity: 0.2,
							},
						}}
						className="absolute inset-0 bg-primary shadow"
					></motion.div>
					<i
						style={{ zIndex: 1, marginTop: 2 }}
						className="far fa-angle-down"
					></i>
				</motion.div>
			</div>
			<AnimatePresence>
				{visible && (
					<motion.div
						initial={{ height: 0, opacity: 0, scale: 0.9 }}
						animate={{ height: "auto", opacity: 1, scale: 1 }}
						exit={{ height: 0, opacity: 0, scale: 0.9 }}
						className="overflow-hidden relative"
					>
						<div className="pt-2 pb-6">{children}</div>
					</motion.div>
				)}
			</AnimatePresence>
		</div>
	);
};

interface AccordionContentProps {
	variant: "bordered";
	children?: any;
	options?: {
		iconOffset?: boolean;
	};
}

const AccordionContent = ({
	variant,
	options,
	children,
}: AccordionContentProps) => {
	return (
		<div
			className={cn(
				variant === "bordered" &&
					"border-2 border-border rounded-md p-4",
				options?.iconOffset && "md:ml-16"
			)}
		>
			{children}
		</div>
	);
};

Accordion.Content = AccordionContent;

export default Accordion;
