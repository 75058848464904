import withTranslation, {
	Translation,
} from "src/components/hoc/withTranslation";
import Layout from "src/components/layout/Layout";
import NotificationSettings from "src/components/settings/notifications/NotificationSettings";

function NotificationsView({ t }: Translation) {
	return (
		<Layout container="xxl" title={t("title")}>
			<Layout.SettingsNav />
			<NotificationSettings />
		</Layout>
	);
}

NotificationsView.locale = {
	nl: {
		title: "Notificaties",
	},
	en: {
		title: "Notificaties",
	},
};

export default withTranslation(NotificationsView);
