import { useState, useEffect } from "react";
import useElementSize from "src/hooks/useElementSize";

interface StickyWrapperProps {
	children?: any;
	className?: string;
	wrapperClassName?: string;
	top?: number;
	bottom?: number;
}

const StickyWrapper = ({
	children,
	className,
	wrapperClassName,
	top,
	bottom,
}: StickyWrapperProps) => {
	const [isSticky, setSticky] = useState(false);
	const [width, setWidth] = useState(0);
	const { size, ref } = useElementSize();
	const style = {
		width: width ? `${width}px` : "auto",
	} as any;

	if (isSticky && top !== undefined) {
		style.top = `${top}px`;
	} else {
		style.top = `32px`;
	}

	if (isSticky && bottom !== undefined) {
		style.bottom = `${bottom}px`;
	} else {
		style.bottom = `32px`;
	}

	const handleScroll = () => {
		if (ref.current) {
			setSticky(ref.current.getBoundingClientRect().top <= 32);
			setWidth(ref.current.getBoundingClientRect().width);
		}
	};

	useEffect(() => {
		window.addEventListener("scroll", handleScroll);

		return () => {
			window.removeEventListener("scroll", () => handleScroll);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<>
			<div
				className={`relative flex flex-col flex-1 w-full ${className}`}
				ref={ref}
			>
				{isSticky && (
					<div
						className="block"
						style={{ height: size?.height }}
					></div>
				)}
				<div
					className={`${wrapperClassName} z-20 h-full ${
						isSticky && "relative md:fixed z-20"
					}`}
					{...{ style }}
				>
					{children}
				</div>
			</div>
		</>
	);
};

export default StickyWrapper;
