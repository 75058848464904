import { createContext, useContext } from "react";
import { ApiCompanyMinimal } from "src/api/types";

export const CompanyCardContext = createContext<{
	company: ApiCompanyMinimal;
} | null>(null);

export function useCompanyCardContext() {
	const context = useContext(CompanyCardContext);
	if (!context) {
		throw new Error(
			"UserCard.* component must be rendered as child of UserCard component"
		);
	}
	return context;
}

export default useCompanyCardContext;
