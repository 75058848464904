import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { ApiExperience } from "src/api/types";
import Button from "src/components/Button";
import withTranslation, {
	Translation,
} from "src/components/hoc/withTranslation";
import Loader from "src/components/Loader";
import EditLayout, {
	EditLayoutDescription,
	EditLayoutHeader,
	EditLayoutTitle,
} from "src/components/resume/edit-layout/EditLayout";
import ExperienceForm from "src/components/resume/elements/experience-form/ExperienceForm";
import {
	Dialog,
	DialogContent,
	DialogOverlay,
	DialogPortal,
} from "src/components/ui/dialog";
import useExperiences from "src/hooks/api/services/resume/useExperiences";
import useModal from "src/hooks/useModal";
import useNavigateModal from "src/hooks/useNavigateModal";

const DashboardChangeExperienceLinkModal = ({ t }: Translation) => {
	const navigateModal = useNavigateModal();
	const modal = useModal();
	const { experienceType: type, experienceId: id } = useParams() as any;
	const { actions } = useExperiences({
		type,
	});
	const [experience, setExperience] = useState<ApiExperience>();
	const [newLink, setNewLink] = useState<{
		slug: string;
		name: string;
		id: any;
	}>();
	const title =
		type === "education" ? experience?.title : experience?.subtitle;

	useEffect(() => {
		if (id) {
			actions.get(id).then((data) => {
				setExperience(data);
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [id]);

	const handleUpdateLink = async () => {
		if (!newLink || !newLink.slug) return;
		await actions.updateLink(id, newLink?.id);
		navigateModal(`/dashboard/resume/edit/skills`);
	};

	if (!id || !["education", "job"].includes(type)) return null;

	if (!experience) {
		return (
			<EditLayout>
				<div className="flex flex-1 flex-col justify-center items-center p-8">
					<Loader />
				</div>
			</EditLayout>
		);
	}

	return (
		<EditLayout>
			<Dialog
				open={!!newLink}
				onOpenChange={(value) => setNewLink(undefined)}
			>
				<DialogPortal>
					<DialogOverlay className="z-[999]" />
					<DialogContent variant="default" className="max-w-[460px]">
						<div className="flex flex-col gap-1">
							<strong>{t(`${type}.confirm.title`)}</strong>
							<p className="opacity-80">
								{t(`${type}.confirm.description`, {
									old: title,
									new: newLink?.name,
								})}
							</p>
						</div>
						<Button onClick={handleUpdateLink}>
							{t(`${type}.confirm.button`)}
						</Button>
					</DialogContent>
				</DialogPortal>
			</Dialog>
			<EditLayoutHeader className="-top-2 md:-top-8">
				<div className="flex mb-2">
					<Button
						type="border"
						xsmall
						onClick={() => modal.onClose()}
					>
						<i className="far fa-arrow-left text-[10px]"></i>
						{t("back")}
					</Button>
				</div>
				<EditLayoutTitle>{t(`${type}.title`)}</EditLayoutTitle>
				<EditLayoutDescription>
					{t(`${type}.description`)}
				</EditLayoutDescription>
			</EditLayoutHeader>
			<div className="flex flex-col mt-8 gap-6">
				{experience?.id && (
					<ExperienceForm
						{...{ type }}
						mode="search"
						defaultValue={title}
					>
						<div className="flex flex-col gap-2">
							<ExperienceForm.Autocomplete
								showLoading
								className="flex-col gap-1"
								label={t(`${type}.label`)}
								onSelect={(experience) =>
									setNewLink(experience)
								}
							/>
							<ExperienceForm.QuickCreate
								placeholder={t(`${type}.placeholder`)}
							/>
						</div>
					</ExperienceForm>
				)}
			</div>
		</EditLayout>
	);
};

DashboardChangeExperienceLinkModal.locale = {
	nl: {
		back: "Terug",
		education: {
			title: "Wijzig je opleiding",
			description:
				"Om de relevante skills bij een opleiding te zoeken hebben wij een database met opleidingen gemaakt, staat je opleding er niet bij? Neem dan contact op met support@{{tenant.urls.domain}}",
			label: "Kies een opleiding",
			placeholder: "Zoek je opleiding...",
			confirm: {
				title: "Wil je je opleiding wijzigen?",
				description:
					"Wil je je opleiding van {{old}} naar {{new}} wijzigen?",
				button: "Wijzig",
			},
		},
		job: {
			title: "Wijzig je functie",
			description:
				"Om de relevante skills bij een functie te zoeken hebben wij een database met functies gemaakt, staat je functie er niet bij? Neem dan contact op met support@{{tenant.urls.domain}}",
			label: "Kies een functie",
			placeholder: "Zoek je functie...",
			confirm: {
				title: "Wil je je functie wijzigen?",
				description:
					"Wil je je functie van {{old}} naar {{new}} wijzigen?",
				button: "Wijzig",
			},
		},
	},
	en: {
		back: "Back",
		education: {
			title: "Edit your education",
			description:
				"To find relevant skills for an education, we have created a database of programs. If your education is not listed, please contact support@{{tenant.urls.domain}}",
			label: "Select an education",
			placeholder: "Search for your education...",
			confirm: {
				title: "Do you want to change your education?",
				description:
					"Do you want to change your education from {{old}} to {{new}}?",
				button: "Change",
			},
		},
		job: {
			title: "Edit your position",
			description:
				"To find relevant skills for a position, we have created a database of roles. If your position is not listed, please contact support@{{tenant.urls.domain}}",
			label: "Select a position",
			placeholder: "Search for your position...",
			confirm: {
				title: "Do you want to change your position?",
				description:
					"Do you want to change your position from {{old}} to {{new}}?",
				button: "Change",
			},
		},
	},
};

export default withTranslation(DashboardChangeExperienceLinkModal);
