import { atom, useAtom } from "jotai";
import moment from "moment";
import { ApiAlert, ApiIdea } from "src/api/types";
import useUnreads from "src/hooks/api/useUnreads";
import useApi from "src/hooks/api/utils/useApi";

const alertOpenAtom = atom(false);

type Options = {
	filter?: any;
};

function useAlert(options?: Options) {
	const { setUnreads } = useUnreads();
	const [open, setOpen] = useAtom(alertOpenAtom);
	const { state, actions, api, pagination } = useApi(
		{ id: "ALERTS" },
		{
			baseUrl: `/alerts`,
			query: {
				state: "not-viewed",
				...(options?.filter || {}),
			},
		}
	);
	const alerts: ApiIdea[] = state?.list || [];

	const readAll = async () => {
		return api.post(`/read-all`).then(() => {
			actions.set((state: any) => ({
				...state,
				list: state.list.map((item: ApiAlert) => ({
					...item,
					viewed_at: !item.viewed_at
						? moment().format("YYYY-MM-DD HH:mm")
						: item.viewed_at,
				})),
			}));
			setUnreads({
				alerts: 0,
			});
		});
	};

	const read = async (id: number) => {
		return api.post(`/${id}/read`).then(() => {
			actions.set((state: any) => ({
				...state,
				list: state.list.map((item: ApiAlert) => ({
					...item,
					viewed_at:
						item.id === id
							? moment().format("YYYY-MM-DD HH:mm")
							: item.viewed_at,
				})),
			}));
			setUnreads({
				alerts: 0,
			});
		});
	};

	// const like = async (id: number) => {
	// 	const { data } = await api
	// 		.patch(`/${id}/like`)
	// 		.then(({ data }) => data);

	// 	if (data?.id) {
	// 		actions.set((state: any) => ({
	// 			...state,
	// 			list: (state?.list || []).map((item: ApiIdea) => {
	// 				if (item.id === data.id) {
	// 					return data;
	// 				}
	// 				return item;
	// 			}),
	// 		}));
	// 	}
	// };

	return {
		alerts,
		open,
		filter: state?.filter,
		search: state?.filter?.q,
		status: state.status,
		pagination,
		actions: {
			...actions,
			setOpen,
			readAll,
			read,
		},
		api,
	};
}

export default useAlert;
