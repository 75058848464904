import { Helmet } from "react-helmet";
import useTenant from "src/hooks/api/services/tenants/useTenant";
import useLanguage from "src/hooks/api/useLanguage";

interface SEOProps {
	title: string;
}

const SEO = ({ title }: SEOProps) => {
	const { current } = useLanguage();
	const { tenant } = useTenant();
	let _title = `${title && `${title} |`} ${tenant?.name} `;
	return (
		<Helmet>
			{current && (
				<meta http-equiv="content-language" content={current}></meta>
			)}
			<meta property="og:title" content={_title} />
			<title>{_title}</title>
		</Helmet>
	);
};

export default SEO;
