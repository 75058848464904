import React from "react";
import { cn } from "src/lib/utils";

interface MandatePaymentMethodProps {
	onClick: () => void;
	label: string;
	icon: string;
	loading?: boolean;
	open?: boolean;
	variant?: "dark" | "light";
}

const MandatePaymentMethod = ({
	onClick,
	label,
	icon,
	loading,
	open,
}: MandatePaymentMethodProps) => {
	return (
		<li
			{...{ onClick }}
			className={cn(
				"p-2 rounded-md flex flex-row transition-all cursor-pointer items-center border border-border"
			)}
		>
			<div className="flex flex-row flex-1">
				<div>
					<i className={`mr-4 ${icon}`}></i>
				</div>
				<strong className="mb-0">{label}</strong>
			</div>
			{loading ? (
				<span className="base-loader"></span>
			) : (
				<div>
					<i
						className={`fas fa-angle-right transition-all ${
							open && "transform rotate-90"
						}`}
					></i>
				</div>
			)}
		</li>
	);
};

MandatePaymentMethod.defaultProps = {
	variant: "dark",
};

export default MandatePaymentMethod;
