import { Route } from "react-router-dom";
import ProtectedRoute from "src/routes/middlewares/ProtectedRoute";
import SetupClientView from "src/views/authenticated/setup/SetupClientView";
import SetupTypeView from "src/views/authenticated/setup/SetupTypeView";

const SetupRoutes = [
	<Route
		element={
			<ProtectedRoute>
				<SetupClientView />
			</ProtectedRoute>
		}
		path="/setup/client"
		key="/setup/client"
	/>,
	<Route
		element={
			<ProtectedRoute>
				<SetupTypeView />
			</ProtectedRoute>
		}
		path="/setup/type"
		key="/setup/type"
	/>,
];

export default SetupRoutes;
