import { createAction, handleActions } from "redux-actions";
import { ApiSignature } from "src/api/types";

export const updateSignature = createAction("UPDATE_SIGNATURE");
export const removeSignature = createAction("REMOVE_SIGNATURE");

export const emptySignatureStature = {
	signature: undefined,
	loading: true,
};

type SignatureStature = {
	signature?: ApiSignature;
	loading: boolean;
};

const signatureReducer = handleActions(
	{
		UPDATE_SIGNATURE: (state: SignatureStature, action: any) => {
			return { ...state, ...action.payload };
		},
		REMOVE_SIGNATURE: () => {
			return {
				...emptySignatureStature,
			};
		},
	},
	{
		...emptySignatureStature,
	}
);
export default signatureReducer;
