import moment from "moment";
import { useTranslation } from "react-i18next";
import { ApiTask } from "src/api/types";
import FlatList from "src/components/FlatList";
import Table from "src/components/Table";
import useGet from "src/hooks/rest/useGet";

interface TaskInvitesProps {
	task: ApiTask;
}

const TaskInvites = ({ task }: TaskInvitesProps) => {
	const { t } = useTranslation("tasks", {
		keyPrefix: "task-invites",
	});
	const [{ data, hasMore }, loading, { fetchNext }] = useGet(
		`/company/tasks/${task.id}/invites`,
		{
			data: [],
		},
		true
	);

	return (
		<FlatList
			empty={{
				title: t("empty.title"),
			}}
			table={{
				headers: [
					t("headers.student"),
					t("headers.status"),
					t("headers.registered"),
					t("headers.invited-on"),
				],
			}}
			{...{ hasMore, data, loading }}
			onNext={async () => await fetchNext()}
			renderItem={(item) => {
				return (
					<Table.Row key={`table-task-invite-${item.hid}`}>
						<Table.Cell className="w-full">
							<p className="mb-0 whitespace-nowrap">
								{item.user.full_name}
							</p>
						</Table.Cell>
						<Table.Cell className="w-full">
							{t(`status.${item.status || "OPEN"}`)}
						</Table.Cell>
						<Table.Cell className="w-full">
							{item.is_registered ? t("yes") : t("no")}
						</Table.Cell>
						<Table.Cell>
							{moment(item.created_at).format("DD-MM-YYYY")}
						</Table.Cell>
					</Table.Row>
				);
			}}
		/>
	);
};

export default TaskInvites;
