import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import Button from "src/components/Button";
import Card from "src/components/Card";
import Layout from "src/components/layout/Layout";
import RandomGif from "src/components/RandomGif";
import useGet from "src/hooks/rest/useGet";
import useWindowSize from "src/hooks/useWindowSize";

export default function OffersCreateFinishedView() {
	const { t } = useTranslation("offer-view", {
		keyPrefix: "offer-create-finished-view",
	});
	const { slug } = useParams() as any;
	const [{ data: offer }, loading] = useGet(`/student/offers/${slug}`);
	const { isPhone } = useWindowSize();
	let title =
		offer?.visibility?.status === "CONCEPT"
			? t("concept.title")
			: t("published.title");
	let info =
		offer?.visibility?.status === "CONCEPT"
			? t("concept.description")
			: t("published.description");

	return (
		<Layout
			breadcrumb={[
				{
					to: "/offers",
					label: t("breadcrumb.home"),
					isHome: true,
				},
				{
					to: "/offers/create",
					label: t("breadcrumb.create"),
				},
				{
					to: "/offers/create/",
					label: t("breadcrumb.created"),
				},
			]}
			// title="Aanbod aangemaakt"
			variant={isPhone ? "no-container" : undefined}
			className={isPhone ? "bg-background" : undefined}
		>
			{!isPhone && <Layout.Header title={t("title")} backUrl="/offers" />}

			{!loading && offer?.id && isPhone && (
				<div>
					<div className="bg-dark block h-24"></div>
					<div className="relative -mt-12 z-20">
						<div className="container">
							<RandomGif type="success" />
							<div className="mt-2">
								<h2 className="text-[24px] flex items-center mb-2">
									{title}{" "}
									<img
										className="w-6 ml-2"
										src="/assets/images/emoji/party-popper.png"
										alt="🎉"
									/>
								</h2>
								<p className="mb-0">{info}</p>
								<div className="flex flex-col mt-6 gap-4">
									<Button to={`/offers/${offer.slug}/show`}>
										{t("buttons.view")}
									</Button>
									<Button type="gray" to={`/offers/create`}>
										<i className="fas fa-plus mr-2"></i>
										{t("buttons.create")}
									</Button>
								</div>
							</div>
						</div>
					</div>
				</div>
			)}

			{!loading && offer?.id && !isPhone && (
				<div className="container-small">
					<Card>
						<RandomGif type="success" />

						<div className="mt-4 text-center">
							<h2 className="text-[24px] flex items-center justify-center mb-4 text-center">
								{title}{" "}
								<img
									className="w-6 ml-2"
									src="/assets/images/emoji/party-popper.png"
									alt="🎉"
								/>
							</h2>
							<p className="mb-0">{info}</p>
							<div className="flex flex-wrap justify-center mt-6 gap-4">
								<Button to={`/offers/${offer.slug}/show`}>
									{t("buttons.view")}
								</Button>
								<Button type="gray" to={`/offers/create`}>
									<i className="fas fa-plus mr-2"></i>
									{t("buttons.create")}
								</Button>
							</div>
						</div>
						<div className=""></div>
					</Card>
				</div>
			)}
		</Layout>
	);
}
