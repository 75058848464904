import { useTranslation } from "react-i18next";
import { ApiRegistration, ApiTask } from "src/api/types";
import Avatar from "src/components/Avatar";
import Button from "src/components/Button";
import ConnectUserButton from "src/components/ConnectUserButton";
import Stars from "src/components/Stars";
import ToggleFavorite from "src/components/favorites/ToggleFavorite";
import useModules from "src/hooks/selectors/useModules";
import useBackUrl from "src/hooks/useBackUrl";
import { cn } from "src/lib/utils";

interface RegistrationHeadProps {
	registration: ApiRegistration;
	task: ApiTask;
}

const RegistrationHead = ({ registration, task }: RegistrationHeadProps) => {
	const { t } = useTranslation(["registrations", "buttons"]);
	const { modules } = useModules();
	const { user } = registration || {};
	const backUrl = useBackUrl();
	const review = Number(user?.reviews_average || 0);

	return (
		<div className="rounded-md overflow-hidden">
			<div className="flex z-[20] sm:flex-row flex-col relative gap-4 px-6 pt-6 sm:items-end rounded-md">
				<Avatar
					size="large"
					src={user?.avatar}
					className="bg-accent dark:bg-background"
				/>
				<div className="flex flex-col gap-2 flex-1">
					<div className="flex justify-between items-center">
						<h2>{user?.full_name}</h2>
						{review > 0 && (
							<Stars
								size={null}
								className="sm:text-[24px]"
								rating={review}
							/>
						)}
					</div>
					<div className="flex flex-wrap gap-3 justify-between items-end">
						<div className="flex flex-col justify-end items-end gap-6">
							<div className="flex flex-wrap gap-2">
								<Button
									type="border"
									xsmall
									to={`/tasks/${task.hid}/show/${registration.hid}/report`}
								>
									<i className={"fas fa-flag"}></i>
									{t("report", { ns: "buttons" })}
								</Button>
								<ToggleFavorite
									defaultFavorite={
										registration.is_favorite || false
									}
									id={registration.id}
									type="REGISTRATION"
								>
									{({ isFavorite, onClick, loading }) => (
										<Button
											type={
												isFavorite ? "green" : "border"
											}
											xsmall
											{...{ onClick, loading }}
										>
											<i
												className={cn(
													isFavorite ? "fas" : "far",
													"fa-star"
												)}
											></i>
											{t("favorite", {
												ns: "buttons",
											})}
										</Button>
									)}
								</ToggleFavorite>
								{user &&
									(modules || []).includes(
										"student-pool"
									) && (
										<ConnectUserButton
											isConnected={
												registration.is_connected
											}
											{...{ user }}
										>
											{({
												isConnected,
												loading,
												onClick,
											}) => (
												<Button
													type={
														isConnected
															? "green"
															: "border"
													}
													xsmall
													{...{
														onClick,
														loading,
													}}
												>
													<i
														className={cn(
															"fas",
															isConnected
																? "fa-user-check"
																: "fa-user-plus"
														)}
													></i>
													{t("connection", {
														ns: "buttons",
													})}
												</Button>
											)}
										</ConnectUserButton>
									)}
								<Button
									type="border"
									xsmall
									to={`/view-profile/${user?.hid}?${backUrl}`}
									target="_blank"
								>
									{t("full-cv", {
										ns: "registrations",
										keyPrefix: "registration-head",
									})}
									<i className={"fas fa-external-link"}></i>
								</Button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default RegistrationHead;
