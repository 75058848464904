import Form from "src/components/tasks/TaskForm/Form";
import useTaskForm from "src/hooks/api/tasks/useTaskForm";
import CalculateServiceFee from "src/components/forms/CalculateServiceFee";
import Input from "src/components/form/Input";
import Alert from "src/components/Alert";
import { useTranslation } from "react-i18next";
import useTaskScheme from "src/hooks/schemes/useTaskScheme";
import { useEffect } from "react";
import { TaskFormOptions } from "src/components/tasks/TaskForm";

interface PerHourFormProps extends TaskFormOptions {
	amountLocked?: boolean;
}

const PerHourForm = ({ amountLocked, hideButtons }: PerHourFormProps) => {
	const { t } = useTranslation("forms", {
		keyPrefix: "budget-form",
	});
	const scheme = useTaskScheme();
	const { task, actions, current } = useTaskForm();
	const hasRegistrations =
		task?.registrations?.count && task?.registrations?.count > 0;
	const isPaid = task?.is_paid;

	useEffect(() => {
		actions.set({ budget: null });
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<Form
			{...{ hideButtons }}
			title={t("title")}
			onSubmit={async () => {
				actions.updateCurrent(current + 1);
			}}
			value={() => {
				if (task?.subtasks?.length > 0) {
					return {};
				}
				return {
					budget_per_hour: task?.budget_per_hour,
					amount_of_students: task.amount_of_students || 1,
					estimated_duration: task.estimated_duration,
				};
			}}
			schema={() => {
				if (task?.subtasks?.length > 0) {
					return {};
				}
				return {
					budget_per_hour: scheme.budget_per_hour,
					amount_of_students: scheme.amount_of_students || 1,
					estimated_duration: scheme.estimated_duration,
				};
			}}
			nextDisabled={!(task.budget_per_hour && task.estimated_duration)}
		>
			{(getInputProps) => {
				// const budgetField = getInputProps("budget");
				const budgetPerHourField = getInputProps("budget_per_hour");
				const amountOfStudentsField =
					getInputProps("amount_of_students");

				return (
					<>
						{(hasRegistrations || isPaid) && (
							<Alert
								options={{
									noAnimation: true,
									noIcon: true,
								}}
								type="danger"
							>
								{t(isPaid ? "alert-all" : "alert-budget")}
							</Alert>
						)}
						{!(task.subtasks?.length > 0) && (
							<>
								{!amountLocked && (
									<Input
										help={t("amount-of-students.help")}
										label={t("amount-of-students.label")}
										type="number"
										disabled={isPaid}
										{...amountOfStudentsField}
									/>
								)}
								<Input
									help={t("hour-rate.help")}
									label={t("hour-rate.label")}
									type="price"
									{...budgetPerHourField}
									disabled={hasRegistrations}
								/>
								{!(task?.subtasks?.length > 0) && (
									<Input
										label={t("estimated-duration.label")}
										help={t("estimated-duration.help")}
										type="number"
										{...getInputProps("estimated_duration")}
									/>
								)}
							</>
						)}
						{budgetPerHourField?.value ? (
							<CalculateServiceFee
								variant="per-hour"
								amountOfStudents={1}
								budget={budgetPerHourField?.value}
							/>
						) : (
							false
						)}
						{task.subtasks?.length > 0 && <p>{t("shifts")}</p>}
					</>
				);
			}}
		</Form>
	);
};

export default PerHourForm;
