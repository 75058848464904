import { createContext, useContext } from "react";
import { ApiTeam } from "src/api/types";

export const TeamCardContext = createContext<{
	team?: ApiTeam;
} | null>(null);

export function useTeamCard() {
	const context = useContext(TeamCardContext);
	if (!context) {
		throw new Error(
			"TeamCard.* component must be rendered as child of TeamCard component"
		);
	}
	return context;
}
