import { atom } from "jotai";
import useTimeTracking, {
	TimerFormat,
} from "src/hooks/api/services/useTimeTracking";
import useTimeFrom, { EMPTY_STATE } from "src/hooks/useTimeFrom";

const timerLabelAtom = atom(EMPTY_STATE);

interface TimerProps {
	format?: TimerFormat;
}

const TimerLabel = ({ format = "hh:mm:ss" }: TimerProps) => {
	const { time } = useTimeTracking();
	const placeholder = format
		.replace("hh", "00")
		.replace("mm", "00")
		.replace("ss", "00");
	const label = useTimeFrom(timerLabelAtom, time?.started_at);
	return <>{time?.started_at ? label(format) : placeholder}</>;
};

export default TimerLabel;
