import { useCookies } from "react-cookie";

export default function useSignupTracking() {
	const [cookies] = useCookies();

	return {
		referral_slug: cookies?.referral_slug || undefined,
		initial_traffic_source: cookies?.initialTrafficSource || undefined,
		utm_campaign: cookies?.utm_campaign || undefined,
		utm_medium: cookies?.utm_medium || undefined,
		utm_source: cookies?.utm_source || undefined,
		referral: cookies?.referral || undefined,
		referral_type:
			cookies?.referral_type ||
			localStorage.getItem("referral_type") ||
			undefined,
	};
}
