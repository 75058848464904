import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { ApiReview } from "src/api/types";
import Button from "src/components/Button";
import Skeleton from "src/components/Skeleton";
import Stars from "src/components/Stars";
import useGet from "src/hooks/rest/useGet";
import useAuth from "src/hooks/selectors/useAuth";
import useBackUrl from "src/hooks/useBackUrl";

interface ReviewInfoProps {
	id: string | number;
	registrationId: string | number;
	query?: string;
	showButton?: boolean;
	buttonDisclaimer?: string;
	buttonUrl?: string;
}

const ReviewInfo = ({
	id,
	registrationId,
	query,
	showButton,
	buttonDisclaimer,
	buttonUrl,
}: ReviewInfoProps) => {
	const auth = useAuth();
	const { t } = useTranslation(["reviews", "buttons", "common"]);
	const [{ data }, loading, { fetchGet }] = useGet(
		`/client/tasks/${id}/reviews?${query || ""}`
	);
	const location = useLocation();
	const backUrl = useBackUrl();
	const hasPlacedReview = (data || []).find(
		(review: ApiReview) => review.creator?.id === auth.id
	);
	const reviews = {
		student: (data || []).find(
			(review: ApiReview) => review?.creator?.type === "student"
		),
		company: (data || []).find(
			(review: ApiReview) => review?.creator?.type === "company"
		),
	};

	useEffect(() => {
		fetchGet(false);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [location]);

	return (
		<div className="flex flex-col gap-3">
			<strong>{t("title", { ns: "reviews" })}</strong>
			{loading ? (
				<Loading />
			) : (
				<>
					<div className="flex items-center gap-2 justify-between">
						<strong>
							{t("titles.client", { ns: "common" })}
							{auth.type === "company"
								? ` (${t("labels.you", { ns: "common" })})`
								: ""}
						</strong>
						{reviews.company ? (
							<Stars rating={reviews?.company?.rating} />
						) : (
							<span>{t("labels.not-found")}</span>
						)}
					</div>
					<div className="flex items-center gap-2 justify-between">
						<strong>
							{t("titles.performer", { ns: "common" })}
							{auth.type === "student"
								? ` (${t("labels.you", { ns: "common" })})`
								: ""}
						</strong>
						{reviews.student ? (
							<Stars rating={reviews?.student?.rating} />
						) : (
							<span>
								{t("labels.not-found", { ns: "common" })}
							</span>
						)}
					</div>
					{!hasPlacedReview && showButton && (
						<div className="flex flex-col justify-center items-center mt-3">
							<Button
								to={
									buttonUrl
										? buttonUrl
										: auth.type === "student"
										? `/tasks/${id}/show/${registrationId}/accept?${backUrl}`
										: `/tasks/${id}/show/${registrationId}/review?${backUrl}`
								}
								type="orange"
								small
							>
								{t("task.finish", { ns: "buttons" })}
							</Button>
						</div>
					)}
					{buttonDisclaimer && (
						<div className="flex flex-col justify-center items-center mt-12">
							<span className="text-center opacity-40">
								{buttonDisclaimer}
							</span>
						</div>
					)}
				</>
			)}
		</div>
	);
};

ReviewInfo.defaultProps = {
	showButton: true,
};

const Loading = () => {
	return (
		<>
			<div className="flex items-center gap-2 justify-between">
				<Skeleton className="h-5 w-16" />
				<Skeleton className="h-5 w-28" />
			</div>
			<div className="flex items-center gap-2 justify-between">
				<Skeleton className="h-5 w-16" />
				<Skeleton className="h-5 w-28" />
			</div>
			<div className="flex flex-col mt-3">
				<Skeleton className="w-[150px] h-[50px] mx-auto" />
			</div>
		</>
	);
};

export default ReviewInfo;
