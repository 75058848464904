import { createContext, useContext, useState } from "react";
import { ApiVacancy } from "src/api/types";
import Button from "src/components/Button";
import { useT } from "src/components/hoc/withTranslation";
import Modal from "src/components/Modal";
import ShareButton from "src/components/promotion/share/ShareButton";
import ShareClipboard from "src/components/promotion/share/ShareClipboard";
import useTenant from "src/hooks/api/services/tenants/useTenant";

interface ShareVacancyContextType {
	vacancy: ApiVacancy;
	share: {
		title: string;
		description: string;
		url: string;
	};
	visible: boolean;
	setVisible: (visible: boolean) => void;
}

const ShareVacancyContext = createContext<ShareVacancyContextType>(
	{} as ShareVacancyContextType
);

const useShareVacancy = () => {
	return useContext(ShareVacancyContext);
};

interface ShareVacancyProps {
	vacancy: ApiVacancy;
	children: React.ReactNode;
}

export default function ShareVacancy({ vacancy, children }: ShareVacancyProps) {
	const { tenant } = useTenant();
	const [visible, setVisible] = useState(false);
	const t = useT({
		nl: {
			social: {
				title: "Ik heb zojuist een nieuwe vacature geplaatst op {{tenant.name}}: {{title}}",
				text: "Interesse in deze job? Klik dan op de onderstaande link {{url}}.",
			},
		},
		en: {
			social: {
				title: "I just posted a new vacancy on {{tenant.name}}: {{title}}",
				text: "Interested in this job? Click the link below {{url}}.",
			},
		},
	});

	const url = tenant?.urls?.community + `/vacatures/${vacancy.slug}`;

	const share = {
		title: t("social.title", {
			title: vacancy.title,
			tenant: { name: tenant?.name },
		}),
		description: t("social.text", { url }),
		url,
	};

	return (
		<ShareVacancyContext.Provider
			value={{ vacancy, share, visible, setVisible }}
		>
			{children}
		</ShareVacancyContext.Provider>
	);
}

export const ShareVacancyTrigger = () => {
	const { setVisible } = useShareVacancy();
	const t = useT({
		nl: {
			share: "Delen",
		},
		en: {
			share: "Share",
		},
	});

	return (
		<Button small className="gap-1.5" onClick={() => setVisible(true)}>
			<i className="fas fa-share"></i>
			{t("share")}
		</Button>
	);
};

export const ShareVacancyModal = () => {
	const { visible, setVisible, share } = useShareVacancy();
	const t = useT({
		nl: {
			title: "Vacature delen",
			labels: {
				facebook: "Delen op Facebook",
				linkedin: "Delen op LinkedIn",
				twitter: "Delen op Twitter",
			},
		},
		en: {
			title: "Share Vacancy",
			labels: {
				facebook: "Share on Facebook",
				linkedin: "Share on LinkedIn",
				twitter: "Share on Twitter",
			},
		},
	});

	return (
		<Modal
			visible={visible}
			onClose={() => setVisible(false)}
			title={t("title")}
		>
			<div className="flex flex-col gap-4">
				<ShareButton type={"linkedin"} {...{ share }}>
					{t("labels.linkedin")}
				</ShareButton>
				<ShareButton type={"facebook"} {...{ share }}>
					{t("labels.facebook")}
				</ShareButton>

				<ShareButton type={"twitter"} {...{ share }}>
					{t("labels.twitter")}
				</ShareButton>
				<ShareClipboard url={share.url} />
			</div>
		</Modal>
	);
};
