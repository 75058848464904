import { AnimatePresence, motion } from "framer-motion";
import { ComponentProps, createContext, useContext, useState } from "react";
import Badge from "src/components/Badge";
import Button from "src/components/Button";
import Input from "src/components/form/Input";
import withTranslation, {
	Translation,
} from "src/components/hoc/withTranslation";
import { filterByUnique } from "src/lib/filters";
import { cn } from "src/lib/utils";

type InviteByEmailContextType = {
	emails: string[];
	setEmails: React.Dispatch<React.SetStateAction<string[]>>;
};

const InviteByEmailContext = createContext<InviteByEmailContextType>({
	emails: [],
	setEmails: () => {},
});

interface InviteByEmailProps extends ComponentProps<"div"> {
	emails: InviteByEmailContextType["emails"];
	setEmails: InviteByEmailContextType["setEmails"];
}

export function InviteByEmail({
	emails,
	setEmails,
	children,
	className,
	...rest
}: InviteByEmailProps) {
	return (
		<InviteByEmailContext.Provider value={{ emails, setEmails }}>
			<div {...rest} className={cn("", className)}>
				{children}
			</div>
		</InviteByEmailContext.Provider>
	);
}

export const InviteByEmailInput = withTranslation(
	({ t }: Translation) => {
		const { emails, setEmails } = useContext(InviteByEmailContext);
		const [value, setValue] = useState("");

		const onAddEmails = () => {
			//Split the value by new lines
			const newEmails = value.split("\n");
			//Split the new emails by commas
			const splitEmails = newEmails.map((email) => email.split(","));
			//Flatten the array
			const flatEmails: string[] = splitEmails
				.flat()
				.map((email) => email.trim().toLowerCase());

			//Combine emails and flatEmails and make it unique
			const uniqueEmails: string[] = [...emails, ...flatEmails]
				.filter(filterByUnique)
				.map((item) => item.trim());

			//Filter invalid emails
			const validEmails = uniqueEmails.filter((email) => {
				const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
				return emailRegex.test(email);
			});

			setEmails(validEmails);
			setValue("");
		};

		return (
			<div className="flex flex-col gap-2">
				<div className="flex flex-col md:flex-row md:items-end gap-3">
					<Input
						multiline
						textareaClassName="min-h-[42px]"
						name="emails"
						placeholder={t("placeholder")}
						label={t("label")}
						onChange={(key, value) => setValue(value)}
						{...{ value }}
					/>
					<Button
						type="border"
						disabled={!value}
						onClick={onAddEmails}
					>
						{t("add")}
					</Button>
				</div>
				<small className="opacity-70">{t("description")}</small>
			</div>
		);
	},
	{
		nl: {
			label: "Emails",
			placeholder: "bijv. jane@acme.com",
			description:
				"Emailadressen gescheiden door komma's of nieuwe regels",
			add: "Toevoegen",
		},
		en: {
			label: "Emails",
			placeholder: "e.g. jane@acme.com",
			description: "Email addresses separated by commas or new lines",
			add: "Add",
		},
	}
);

export const InviteByEmailList = withTranslation(
	({ t, className, ...rest }: Translation & ComponentProps<"div">) => {
		const { emails, setEmails } = useContext(InviteByEmailContext);
		const onRemoveEmail = (email: string) => {
			setEmails(emails.filter((e) => e !== email));
		};
		return (
			<div
				{...rest}
				className={cn("flex flex-wrap gap-2 empty:hidden", className)}
			>
				<AnimatePresence>
					{emails.map((email) => (
						<motion.div
							key={`email-${email}`}
							initial={{ scale: 0.95 }}
							animate={{ scale: 1 }}
							exit={{ scale: 0.95 }}
							layout
						>
							<Badge
								variant="border"
								className="border gap-2 cursor-pointer items-center group bg-background select-none"
								onClick={() => onRemoveEmail(email)}
							>
								<span>{email}</span>
								<i className="far fa-times leading-[14px] text-placeholder group-hover:text-foreground"></i>
							</Badge>
						</motion.div>
					))}
				</AnimatePresence>
			</div>
		);
	},
	{}
);
