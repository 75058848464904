import { isDesktopApp } from "@todesktop/client-core/platform/todesktop";
import { ComponentProps } from "react";
import { cn } from "src/lib/utils";

const LayoutDesktopDragSpacer = ({
	className,
	...rest
}: ComponentProps<"div">) => {
	if (!isDesktopApp()) return null;
	return <div {...rest} className={cn("desktop-draggable h-4", className)} />;
};

export default LayoutDesktopDragSpacer;
