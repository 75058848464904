import useApi from "src/hooks/api/utils/useApi";

function useStatistics() {
	const { state, actions, pagination } = useApi(
		{ id: "STATISTICS" },
		{
			baseUrl: `/client/statistics`,
		}
	);

	return {
		statistics: state.data,
		status: state.status,
		pagination,
		actions,
	};
}

export default useStatistics;
