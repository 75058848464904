import { useTranslation } from "react-i18next";
import { ApiEnvelope, ApiSignature, LayoutVariants } from "src/api/types";
import Button from "src/components/Button";
import SignatureCardItem from "src/components/signer/signature/SignatureCardItem";
import SignatureModal from "src/components/signer/signature/SignatureModal";
import Skeleton from "src/components/Skeleton";
import useAuth from "src/hooks/selectors/useAuth";
import useSignature from "src/hooks/selectors/useSignature";

interface SignerSignatureFormProps {
	onSubmit: (signature: ApiSignature) => void;
	envelope: ApiEnvelope;
	loading: boolean;
	variant: LayoutVariants;
	type: "form" | "button";
}

const SignerSignatureForm = ({
	onSubmit,
	variant,
	type,
	envelope,
}: SignerSignatureFormProps) => {
	const { t } = useTranslation("envelope", {
		keyPrefix: "signer-signature-form",
	});
	const auth = useAuth();
	const { signature, loading: loadingSignature } = useSignature();

	if (!envelope) {
		return <></>;
	}

	if (auth.id !== envelope.signer?.user?.id) {
		return (
			<div>
				<strong>{t("not-allowed.title")}</strong>
				<p className="mb-0">
					{t("not-allowed.description", {
						name: envelope?.signer?.user?.full_name,
					})}
				</p>
			</div>
		);
	}
	if (type === "button") {
		return (
			<SignatureModal {...{ variant, onSubmit }}>
				{(onClick) => (
					<Button
						{...{ onClick }}
						disabled={envelope?.has_signed}
						className="flex-1"
						type="primary"
					>
						<i className="fas fa-check mr-2"></i>
						{envelope?.has_signed
							? t("button.already-signed")
							: t("button.sign")}
					</Button>
				)}
			</SignatureModal>
		);
	}

	return (
		<div className=" ">
			<div className="flex flex-col gap-3">
				<strong>{t("title")}</strong>

				<div className="flex flex-col">
					{loadingSignature ? (
						<Skeleton
							className="h-[91px]"
							bg={variant === "dark" ? "dark" : "gray"}
						/>
					) : !envelope?.has_signed ? (
						<>
							{signature?.id && (
								<SignatureCardItem
									className="h-[91px]"
									{...{ signature, variant }}
								/>
							)}

							<SignatureModal {...{ variant, onSubmit }}>
								{(onClick) => {
									if (signature?.id) {
										return (
											<div
												{...{ onClick }}
												className="cursor-pointer"
											>
												<small>
													{t("edit-signature")}
												</small>
											</div>
										);
									}
									return (
										<div
											className={`flex group hover:shadow transition-all py-8 cursor-pointer flex-col justify-center items-center rounded-md ${
												variant === "dark"
													? "bg-dark"
													: "bg-accent text-background-foreground"
											}`}
											{...{ onClick }}
										>
											<span className="opacity-20 transition-all group-hover:opacity-80">
												{t("click-to-sign")}
											</span>
										</div>
									);
								}}
							</SignatureModal>
						</>
					) : (
						<div
							className={`flex group hover:shadow transition-all py-8 px-4 flex-col justify-center items-center rounded-md bg-accent dark:bg-background`}
						>
							<span className="text-green text-center">
								<i className="fas fa-check mr-2"></i>
								{t("signed-succesfull")}
							</span>
						</div>
					)}
				</div>
				{!envelope?.has_signed && !loadingSignature && (
					<div className="flex mt-3 justify-center items-center">
						<Button
							className="flex-1"
							type="primary"
							disabled={!signature}
							onClick={() => {
								onSubmit(signature);
							}}
						>
							<i className="fas fa-check mr-2"></i>
							{t("button.sign")}
						</Button>
					</div>
				)}
			</div>
		</div>
	);
};

SignerSignatureForm.defaultProps = {
	type: "form",
};

export default SignerSignatureForm;
