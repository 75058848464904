import { AxiosInstance } from "axios";
import useApi, {
	ApiActions,
	ApiStatus,
	Pagination,
} from "src/hooks/api/utils/useApi";

type State = {
	totals: {
		break: number;
		total: number;
		price: number;
		approved: number;
	};
	status: ApiStatus;
	actions: ApiActions;
	api: AxiosInstance;
	pagination: Pagination;
};

type Options = {
	task_id: number | string;
	filter?: any;
};

function useTimesheetsTotals(options: Options): State {
	const { state, actions, api, pagination } = useApi(
		{ id: `TIMESHEET_TOTALS:${options.task_id}` },
		{
			baseUrl: `/client/tasks/${options.task_id}/timesheets/totals`,
			query: {
				task_id: options.task_id,
				...(options?.filter || {}),
			},
		}
	);

	return {
		totals: state?.data || {},
		status: state.status,
		pagination,
		actions,
		api,
	};
}

export default useTimesheetsTotals;
