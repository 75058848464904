import Button from "src/components/Button";
import withTranslation, {
	Translation,
} from "src/components/hoc/withTranslation";
import useSubscription from "src/hooks/selectors/useSubscription";

interface InviteUserButtonProps extends Translation {
	className?: string;
}

const InviteUserButton = ({ t, className }: InviteUserButtonProps) => {
	const subscription = useSubscription();

	if (!subscription?.id) {
		return <></>;
	}

	return (
		<Button
			type="ghost"
			to={{
				modal: "/profile/users/invite",
			}}
			{...{ className }}
		>
			<i className="far fa-user-plus mr-2"></i>
			<span>{t("invite-user")}</span>
		</Button>
	);
};

InviteUserButton.locale = {
	nl: {
		"invite-user": "Teamleden uitnodigen",
	},
	en: {
		"invite-user": "Teamleden uitnodigen",
	},
};

export default withTranslation(InviteUserButton);
