import React, { useState } from "react";
import { ApiNotificationChannel, ApiNotificationSetting } from "src/api/types";
import Switch from "src/components/form/Switch";
import usePatch from "src/hooks/rest/usePatch";

interface NotificationSettingsItemProps {
	notification: ApiNotificationSetting;
}

const NotificationSettingsItem = ({
	notification,
}: NotificationSettingsItemProps) => {
	const switches: any = notification.channels;

	return (
		<div className="flex flex-wrap items-center justify-between gap-2">
			<div className="flex flex-col max-w-[400px]">
				<strong>{notification.name}</strong>
				<span>{notification.description}</span>
			</div>
			<div className="flex flex-col gap-2">
				{Object.keys(switches).map((channel: any) => (
					<NotificationSwitch
						key={`${notification.name}-${channel}`}
						{...{ notification, channel }}
					/>
				))}
			</div>
		</div>
	);
};

interface NotificationSwitchProps {
	notification: ApiNotificationSetting;
	channel: ApiNotificationChannel;
}

const NotificationSwitch = ({
	notification,
	channel,
}: NotificationSwitchProps) => {
	const [update, loading] = usePatch(
		`/settings/notifications/${notification.id}`
	);
	const [value, setValue] = useState(notification?.channels[channel]);
	const channels: any = {
		mobile: "Notificatie",
		mail: "Email",
	};

	const onChange = (value: boolean) => {
		if (loading) return;

		update({
			channel,
			value,
		}).then(() => {
			setValue(value);
		});
	};

	return (
		<div className="flex items-center justify-between gap-2">
			<p className="flex-1 text-right">{channels[channel]}</p>
			<Switch
				key={`${notification.name}-${channel}`}
				{...{ value, onChange, loading }}
			/>
		</div>
	);
};

export default NotificationSettingsItem;
