import AnalalyticsLayout from "src/components/analytics/analytics-layout/AnalalyticsLayout";
import BestPerformingTeamsList from "src/components/analytics/lists/BestPerformingTeamsList";
import BestPerformingUsersList from "src/components/analytics/lists/BestPerformingUsersList";
import WorstPerformingUsersList from "src/components/analytics/lists/WorstPerformingUsersList";
import TasksStatistics from "src/components/analytics/tasks/TasksStatistics";
import withTranslation, {
	Translation,
} from "src/components/hoc/withTranslation";
import Layout from "src/components/layout/Layout";

const DashboardView = ({ t }: Translation) => {
	return (
		<Layout
			breadcrumb={[
				{
					label: t("title"),
					to: "/analytics/dashboard",
				},
			]}
			title={t("title")}
		>
			<AnalalyticsLayout>
				{({ date }) => (
					<div className="flex flex-col md:grid md:grid-cols-3 gap-4 auto-rows-max">
						<TasksStatistics {...{ date }}>
							<TasksStatistics.CompletedBarChart className="md:col-span-2" />
							<div className="grid md:grid-cols-1 gap-4">
								<TasksStatistics.TotalCompleted />
								<TasksStatistics.TotalOpen />
								<TasksStatistics.TotalHoursWorked />
							</div>
						</TasksStatistics>
						<BestPerformingTeamsList {...{ date }} />
						<BestPerformingUsersList {...{ date }} />
						<WorstPerformingUsersList {...{ date }} />
					</div>
				)}
			</AnalalyticsLayout>
		</Layout>
	);
};

DashboardView.locale = {
	nl: {
		title: "Analytics",
		tabs: {
			dashboard: "Dashboard",
			employees: "Medewerkers",
			planning: "Planning",
		},
	},
	en: {
		title: "Analytics",
		tabs: {
			dashboard: "Dashboard",
			employees: "Employees",
			planning: "Planning",
		},
	},
};

export default withTranslation(DashboardView);
