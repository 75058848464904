const dashboard = {
	company: {
		"create-task": "Create Task",
		title: "Dashboard",
		"demo-banner":
			"{{tenant.name}} uses a subscription, <2>click here</2> to view all subscriptions",
		cards: {
			registrations: "New Registrations",
			inbox: "My Messages",
			"example-tasks": "Example Tasks",
		},
		"wallet-block": {
			description:
				"To create a task you need credit, we have a task placement fee of {{fee}}%",
			button: "Top-up",
		},
		"dashboard-company-head": {
			description:
				"Post a new task today to over <2>{{count}}+ professionals</2>",
			buttons: {
				create: "Create Task",
				studentpool: "Connections",
			},
		},
		"dashboard-company-welcome": {
			title: "Welcome to {{tenant.name}}",
			description:
				"Over <1>{{count}}+ professionals</1> are ready to complete your tasks! You still need a subscription to do so.",
			"credit-description":
				"Your task placement credit hasn't disappeared, until your task placement credit runs out, the task placement fees and subscriptions will be paid with it.",
			buttons: {
				demo: "Schedule a demo",
				subscription: "Choose a subscription",
			},
		},
	},
	"connect-mandate-view": {
		title: "Link Bank Account",
		description: "Click the plus sign below to link your bank",
	},
	"mandate-view": {
		title: "You are being redirected",
		description:
			"Please wait, you are being redirected to our payment provider",
	},
	"bank-card": {
		status: {
			new: "Created",
			pending: "Pending",
			approved: "Approved",
			disapproved: "Disapproved",
		},
		empty: {
			title: "You have not linked a bank yet",
			description:
				"When you have completed a task and money is ready for you, you can link your bank.",
		},
		new: {
			title: "Link your bank account now",
			description:
				"We need your bank account to pay you after the task is completed.",
			button: "Link Bank Account",
		},
	},
};

export default dashboard;
