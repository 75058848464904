import UserStatistics from "src/components/analytics/user/UserStatistics";
import withTranslation, {
	Translation,
} from "src/components/hoc/withTranslation";
import ProfileSettingsLayout from "src/components/layout/profile-settings-layout/ProfileSettingsLayout";
import Section from "src/components/ui/section";
import useAuth from "src/hooks/selectors/useAuth";

const ProgressView = ({ t }: Translation) => {
	const auth = useAuth();
	return (
		<ProfileSettingsLayout title={t("title")}>
			{auth?.id && (
				<UserStatistics userId={auth.id} className="flex flex-1">
					<div className="flex flex-col p-6 pt-12 pr-12 border-r border-border gap-16 w-full max-w-[380px]">
						<div className="flex flex-col gap-4">
							<UserStatistics.Avatar size="xlarge" />
							{/* <UserStatistics.Name /> */}
						</div>
						<Section label={t("sections.stats")}>
							<UserStatistics.Totals />
						</Section>
						<Section label={t("sections.skills")}>
							<UserStatistics.Skills />
						</Section>
					</div>
					<div className="flex flex-col gap-8 p-12 flex-1">
						<Section variant="large" label={t("sections.progress")}>
							<UserStatistics.SkillProgress />
						</Section>
						<Section
							variant="large"
							label={t("sections.distribution")}
						>
							<UserStatistics.SkillDistribution />
						</Section>
						{/* <Section
							variant="large"
							label={t("sections.suggested-skills")}
						></Section> */}
					</div>
				</UserStatistics>
			)}
		</ProfileSettingsLayout>
	);
};

ProgressView.locale = {
	nl: {
		title: "Mijn voortgang",
		sections: {
			stats: "Statistieken",
			skills: "Recente skills",
			progress: "Voortgang",
			distribution: "Verdeling",
			"suggested-skills": "Voorgestelde skills",
		},
	},
	en: {
		title: "My progress",
		sections: {
			stats: "Statistics",
			skills: "Main skills",
			progress: "Progress",
			distribution: "Distribution",
			"suggested-skills": "Suggested skills",
		},
	},
};

export default withTranslation(ProgressView);
