import { ComponentProps } from "react";
import Company from "src/components/forms/onboarding/steps/Company";
import Completed from "src/components/forms/onboarding/steps/Completed";
import CopyResume from "src/components/forms/onboarding/steps/CopyResume";
import CreateBoards from "src/components/forms/onboarding/steps/CreateBoards";
import CreateTeams from "src/components/forms/onboarding/steps/CreateTeams";
import InviteUsers from "src/components/forms/onboarding/steps/InviteUsers";
import TeamSkills from "src/components/forms/onboarding/steps/TeamSkills";
import Website from "src/components/forms/onboarding/steps/Website";
import { cn } from "src/lib/utils";

interface OnboardingProps extends ComponentProps<"div"> {}

const Onboarding = ({ children, className, ...rest }: OnboardingProps) => {
	return (
		<div {...rest} className={cn("", className)}>
			{children}
		</div>
	);
};

Onboarding.CreateTeams = CreateTeams;
Onboarding.TeamSkills = TeamSkills;
Onboarding.InviteUsers = InviteUsers;
Onboarding.CreateBoards = CreateBoards;
Onboarding.Completed = Completed;
Onboarding.Website = Website;
Onboarding.Company = Company;
Onboarding.CopyResume = CopyResume;

export default Onboarding;
