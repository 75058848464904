const envelope = {
	envelopes: {
		title: "Agreements",
		status: {
			open: "Open",
			signed: "Signed",
		},
	},
};

export default envelope;
