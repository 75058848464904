import { ReactNode } from "react";
import { ApiTaskKanban } from "src/api/types";
import Draggable from "src/components/draggable";
import TaskListItem, {
	TaskListItemElement,
} from "src/components/tasks/list/TaskListItem";

interface TaskListProps {
	loading?: boolean;
	tasks: ApiTaskKanban[];
	onUpdate?: (id: string | number, data: any) => Promise<any>;
	item?: {
		elements?: TaskListItemElement[];
	};
	draggable?: {
		id: string;
	};
	empty?: ReactNode;
	ghostItemVariant?: "line" | "list-item";
	renderItem?: (task: ApiTaskKanban, index: number) => any;
}

const TaskList = ({
	loading,
	tasks,
	onUpdate,
	item,
	draggable,
	empty,
	ghostItemVariant = "list-item",
	renderItem,
}: TaskListProps) => {
	if (draggable) {
		return (
			<Draggable.Drop
				{...{ ghostItemVariant }}
				className="flex flex-col gap-3"
				id={draggable.id}
			>
				{tasks.map((task, index) => (
					<Draggable.Item
						key={`task-${task.hid}-${index}`}
						id={`LIST_${task.id}`}
						data={task}
						currentDropId={draggable.id}
					>
						{renderItem ? (
							renderItem(task, index)
						) : (
							<TaskListItem
								className="bg-card"
								key={`task-${task.hid}-${index}`}
								{...{ task, onUpdate, ...item }}
							/>
						)}
					</Draggable.Item>
				))}

				{loading && tasks.length === 0 && (
					<>
						{new Array(6).fill("").map((_, index) => (
							<TaskListItem.Loader
								key={`task-list-item-loading-${index}`}
							/>
						))}
					</>
				)}
				{empty}
			</Draggable.Drop>
		);
	}

	return (
		<div className="flex flex-col gap-3">
			{tasks.map((task, index) =>
				renderItem ? (
					renderItem(task, index)
				) : (
					<TaskListItem
						key={`task-${task.hid}-${index}`}
						{...{ task, onUpdate, ...item }}
					/>
				)
			)}

			{loading && tasks.length === 0 && (
				<>
					{new Array(6).fill("").map((_, index) => (
						<TaskListItem.Loader
							key={`task-list-item-loading-${index}`}
						/>
					))}
				</>
			)}
			{empty}
		</div>
	);
};

export default TaskList;
