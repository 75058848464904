import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Avatar from "src/components/Avatar";
import Button from "src/components/Button";
import Loader from "src/components/Loader";
import Input from "src/components/form/Input";
import Switch from "src/components/form/Switch";
import withTranslation, {
	Translation,
} from "src/components/hoc/withTranslation";
import useFeedback from "src/hooks/api/services/useFeedback";
import useModal from "src/hooks/useModal";

interface DetailFeedbackModalProps extends Translation {
	children?: any;
}

const DetailFeedbackModal = ({ t, children }: DetailFeedbackModalProps) => {
	const navigate = useNavigate();
	const { id } = useParams() as any;

	const { actions, idea, status } = useFeedback();
	const [form, setForm] = useState<any>({});
	const modal = useModal();

	useEffect(() => {
		actions.get(id).then((state: any) => {
			if (state.release) {
				modal.setTitle(state.title);
			}
			setForm({
				title: state?.title,
				description: state?.description,
				visibility: state?.visibility,
			});
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [id]);

	const onSubmit = (e?: any) => {
		if (e) e.preventDefault();
		actions.update(form).then(() => {
			actions.list({
				page: 1,
			});
			navigate("/feedback");
		});
	};

	useEffect(() => {
		modal.setTitle(t("title"));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	if (status === "loading") {
		return (
			<div className="flex flex-col p-8 justify-center items-center">
				<Loader />
			</div>
		);
	}

	if (idea?.settings?.is_me && !idea.release) {
		return (
			<form {...{ onSubmit }} className=" ">
				<Input
					name="title"
					label={t("fields.title.label")}
					placeholder={t("fields.title.placeholder")}
					value={form.title}
					onChange={(key, value) =>
						setForm((state: any) => ({
							...state,
							[key]: value,
						}))
					}
				/>
				<Input
					name="description"
					label={t("fields.description.label")}
					placeholder={t("fields.description.placeholder")}
					multiline
					value={form.description}
					onChange={(key, value) =>
						setForm((state: any) => ({
							...state,
							[key]: value,
						}))
					}
				/>
				<Switch
					value={form.visibility === "ANONYMOUS"}
					onChange={() =>
						setForm((state: any) => ({
							...state,
							visibility:
								state.visibility === "ANONYMOUS"
									? "PUBLIC"
									: "ANONYMOUS",
						}))
					}
					label={t("fields.visibility.label")}
					subLabel={t("fields.visibility.sublabel")}
				/>
				<div className="mt-4">
					<Button
						loading={status !== "idle"}
						disabled={
							!form.title ||
							!form.description ||
							status !== "idle"
						}
						className="w-full"
						submit
						type="dark"
					>
						{t("button.create")}
					</Button>
				</div>
			</form>
		);
	}

	if (idea.release?.description) {
		return (
			<div className="max-w-[400px] flex flex-col gap-4">
				<div
					className="description"
					dangerouslySetInnerHTML={{
						__html: idea.release.description,
					}}
				></div>
				<hr className="border-border" />
				<div className="flex items-center gap-2 flex-wrap justify-between">
					<strong className="text-placeholder">{t("author")}</strong>
					<Avatar.User user={idea.release.author} />
				</div>
			</div>
		);
	}

	return (
		<div className="max-w-[400px]">
			<strong>{idea.title}</strong>
			<p>{idea.description}</p>
		</div>
	);
};

DetailFeedbackModal.locale = {
	nl: {
		title: "Feedback",
		fields: {
			title: {
				label: "Functie titel",
				placeholder: "Bestanden toevoegen aan taken...",
			},
			description: {
				label: "Omschrijf je feedback",
				placeholder: "Het zou fijn zijn om bestanden...",
			},
			visibility: {
				label: "Anoniem posten",
				sublabel:
					"Andere gebruikers zien niet dat jij feedback hebt gepost",
			},
		},
		button: {
			create: "Aanpassen",
		},
		author: "Gemaakt door",
	},
	en: {
		title: "Feedback",
		fields: {
			title: {
				label: "Feature title",
				placeholder: "Upload files to a task...",
			},
			description: {
				label: "Describe your feedback",
				placeholder: "It would help to be able to upload...",
			},
			visibility: {
				label: "Post anonymous",
				sublabel: "Other users won't see that you posted this feedback",
			},
		},
		button: {
			create: "Edit",
		},
		author: "Created by",
	},
};

export default withTranslation(DetailFeedbackModal);
