import Progress from "src/components/Progress";

interface ProjectStatusProps {
	completedTasks: number;
	totalTasks: number;
}

const ProjectStatus = ({ completedTasks, totalTasks }: ProjectStatusProps) => {
	const percentage =
		completedTasks === 0 && totalTasks === 0
			? 0
			: Math.round((completedTasks / totalTasks) * 100);

	return (
		<div className="flex flex-row gap-2 items-center">
			<small className="font-bold">{percentage} %</small>
			<div className="flex-1">
				<Progress current={completedTasks} total={totalTasks} />
			</div>
		</div>
	);
};

export default ProjectStatus;
