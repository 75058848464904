import { atom, useAtom } from "jotai";

export interface ActiveDraggable {
	id?: string | number;
	from?: string | number;
	to?: string | number;
}

type Ghost = {
	width?: number;
	height?: number;
	column?: number | string;
};

const kanbanAtom = atom<{
	current: ActiveDraggable;
	ghost: Ghost;
}>({
	current: {
		id: undefined,
		from: undefined,
		to: undefined,
	},
	ghost: {
		width: undefined,
		height: undefined,
		column: undefined,
	},
});

export default function useKanban() {
	const [kanban, setKanban] = useAtom(kanbanAtom);

	const setActiveId = (id?: number | string) => {
		setKanban((state: any) => ({
			...state,
			current: { ...state.current, id },
		}));
	};

	const setToColumn = (column?: number | string) => {
		setKanban((state: any) => ({
			...state,
			current: { ...state.current, to: column },
		}));
	};

	const setFromColumn = (column?: number | string) => {
		setKanban((state: any) => ({
			...state,
			current: { ...state.current, from: column },
		}));
	};

	const setGhost = (ghost: Ghost) => {
		setKanban((state: any) => ({
			...state,
			ghost,
		}));
	};

	return {
		...kanban,
		actions: {
			set: setKanban,
			setActiveId,
			setFromColumn,
			setToColumn,
			setGhost,
		},
	};
}
