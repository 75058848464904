import { HTMLProps } from "react";
import Loader from "src/components/Loader";

interface IconProps extends HTMLProps<HTMLDivElement> {
	icon: string;
	loading?: boolean;
}

const Icon = ({ icon, loading, ...rest }: IconProps) => {
	let className = `flex justify-center items-center rounded-md w-8 h-8 ${
		rest?.className || ""
	}`;

	if (!className?.includes("cursor-") && rest?.onClick) {
		className += " cusor-pointer";
	}

	if (!className?.includes("bg-")) {
		className += " bg-accent";
	}

	return (
		<div {...rest} {...{ className }}>
			{loading ? <Loader /> : <i className={`${icon} text-[16px]`}></i>}
		</div>
	);
};

const IconText = ({ ...rest }: HTMLProps<HTMLDivElement>) => {
	let className = `flex justify-center items-center rounded-md h-8 min-w-8 px-2 block ${
		rest?.className || ""
	}`;

	if (!className?.includes("cursor-") && rest?.onClick) {
		className += " cusor-pointer";
	}

	if (!className?.includes("bg-")) {
		className += " bg-accent";
	}

	return (
		<div {...rest} {...{ className }}>
			{rest?.children}
		</div>
	);
};

Icon.Text = IconText;

export default Icon;
