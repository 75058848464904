import { useState } from "react";
import { useTranslation } from "react-i18next";
import { ApiPayment } from "src/api/types";
import Button from "src/components/Button";
import Modal from "src/components/Modal";
import TrackPayment from "src/components/TrackPayment/TrackPayment";

interface TrackPaymentModalProps {
	payment: ApiPayment;
	button?: {
		label: string;
	};
}

const TrackPaymentModal = ({ payment, button }: TrackPaymentModalProps) => {
	const [visible, setVisible] = useState(false);
	const { t } = useTranslation("payments");
	return (
		<>
			<Button type="dark" small onClick={() => setVisible(!visible)}>
				{button?.label || t("track")}
			</Button>
			<Modal
				title={t("track")}
				className="md:max-w-[520px]"
				{...{ visible }}
				onClose={() => setVisible(false)}
			>
				{visible && <TrackPayment {...{ payment }} />}
			</Modal>
		</>
	);
};

export default TrackPaymentModal;
