import moment from "moment";
import { useEffect } from "react";
import Button from "src/components/Button";
import DayIcon from "src/components/DayIcon";
import Skeleton from "src/components/Skeleton";
import withTranslation, {
	Translation,
} from "src/components/hoc/withTranslation";
import List from "src/components/ui/list";
import useRepeatTasks from "src/hooks/api/services/tasks/useRepeatTasks";
import useNavigateModal from "src/hooks/useNavigateModal";
import { DAYS_IN_WEEK } from "src/lib/constants";

interface TaskRepeatTableProps extends Translation {
	children?: any;
}

const TaskRepeatTable = ({ t, children }: TaskRepeatTableProps) => {
	const navigateModal = useNavigateModal();
	const { tasks, status, actions, pagination } = useRepeatTasks();

	useEffect(() => {
		actions.list();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<div className="flex flex-col flex-1">
			<List {...{ status }} data={tasks}>
				<List.Head>
					<List.Item>{t("headers.task")}</List.Item>
					<List.Item>{t("headers.interval")}</List.Item>
					<List.Item>{t("headers.start_at")}</List.Item>
					<List.Item></List.Item>
				</List.Head>
				<List.Items>
					{(task) => (
						<List.Row key={`table-${task.hid}`}>
							<List.Item className="w-full">
								{task.title}
							</List.Item>
							<List.Item>
								<div className="flex flex-col gap-2">
									{task?.repeat.interval === "WEEKLY" ? (
										<div className="flex gap-1 items-center">
											{DAYS_IN_WEEK.map((day) => (
												<DayIcon
													key={`day-${task.hid}-${day}`}
													size="small"
													variant={
														task.repeat.days?.includes(
															day
														)
															? "active"
															: undefined
													}
													{...{ day }}
												/>
											))}
										</div>
									) : (
										<p>
											{t(
												`interval.${task.repeat.interval}`
											)}
										</p>
									)}
								</div>
							</List.Item>
							<List.Item>
								<p className="whitespace-nowrap">
									{moment(task.repeat.start_at).format(
										"DD-MM-YYYY HH:mm"
									)}
								</p>
							</List.Item>
							<List.Item>
								<div className="flex gap-2">
									<Button
										iconOnly
										icon="fas fa-pencil"
										type="gray"
										onClick={() =>
											navigateModal(
												`./repeat-task/${task.hid}`
											)
										}
									/>
									<Button
										iconOnly
										icon="fas fa-eye"
										type="dark"
										onClick={() =>
											navigateModal(`./${task.hid}/show`)
										}
									/>
								</div>
							</List.Item>
						</List.Row>
					)}
				</List.Items>
				<List.Empty>
					<h2>{t("empty.title")}</h2>
					<p>{t("empty.text")}</p>
				</List.Empty>
				<List.Loading>
					<List.Item>
						<Skeleton className="h-6 w-full" />
					</List.Item>
					<List.Item>
						<Skeleton className="h-6 w-full" />
					</List.Item>
					<List.Item>
						<Skeleton className="h-6 w-full" />
					</List.Item>
					<List.Item align="right">
						<Skeleton className="h-10 w-10" />
					</List.Item>
				</List.Loading>
				<List.Pagination
					{...{ pagination }}
					onNavigate={pagination.setPage}
				/>
			</List>
		</div>
	);
};

TaskRepeatTable.locale = {
	nl: {
		empty: {
			title: "Geen herhaaltaken gevonden",
			text: "Je hebt nog geen herhaaltaken ingesteld",
		},
		headers: {
			task: "Taak",
			interval: "Wanneer",
			start_at: "Begonnen op",
		},
		interval: {
			DAILY: "Dagelijks",
			WEEKLY: "Wekelijks",
			MONTHLY: "Maandelijks",
			YEARLY: "Jaarlijks",
		},
	},
	en: {
		empty: {
			title: "Geen repeatable tasks found",
			text: "You have not configured any repeatable tasks",
		},
		headers: {
			task: "Task",
			interval: "When",
			start_at: "Started at",
		},
		interval: {
			DAILY: "Daily",
			WEEKLY: "Weekly",
			MONTHLY: "Monthly",
			YEARLY: "Yearly",
		},
	},
};

export default withTranslation(TaskRepeatTable);
