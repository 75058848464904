import { ApiCategory } from "src/api/types";
import useApi from "src/hooks/api/utils/useApi";

type Options = {
	filter?: any;
};

function useCategories(options?: Options) {
	const { state, actions, api } = useApi(
		{ id: "CATEGORIES" },
		{
			baseUrl: `/categories`,
			query: {
				...(options?.filter || {}),
			},
		}
	);
	const categories: ApiCategory[] = state?.list || [];

	return {
		categories,
		status: state.status,
		actions,
		api,
	};
}

export default useCategories;
