import { useCallback, useEffect, useRef, useState } from "react";
import Alert from "src/components/Alert";
import Button from "src/components/Button";
import Field from "src/components/field/Field";
import AILoading from "src/components/tasks/TaskForm/elements/AILoading";
import SummaryWrapper from "src/components/tasks/TaskForm/elements/SummaryWrapper";
import withTranslation, {
	Translation,
} from "src/components/hoc/withTranslation";
import Tab from "src/components/layout/Tab";
import Tabs from "src/components/layout/Tabs";
import useTaskForm from "src/hooks/api/tasks/useTaskForm";

const LOADING_STATUSES = ["PENDING", "GENERATING"];
const MAX_TRIES = 14;

interface GeneratedDescriptionFormProps extends Translation {
	hideButtons?: boolean;
}

const GeneratedDescriptionForm = ({ t }: GeneratedDescriptionFormProps) => {
	const { task, status: apiStatus, actions } = useTaskForm();
	const [view, setView] = useState<"edit" | "preview">("preview");
	const status = task?.description_obj?.status || "PENDING";
	const isLoading = LOADING_STATUSES.includes(status);
	const [description, setDescription] = useState(task?.description);
	// const [tries, setTries] = useState(0);
	const tries = useRef(0);
	const [isFailed, setIsFailed] = useState(false);

	const handleSubmit = () => {
		actions
			.save({
				data: {
					description,
				},
				disableAlert: true,
			})
			.then((task) => {
				if (task?.description) {
					setDescription(task?.description);
					setView("preview");
				}
			});
	};

	const checkDescriptionStatus = useCallback(() => {
		if (tries.current >= MAX_TRIES) {
			setIsFailed(true);
			setView("edit");
			return;
		}

		actions
			.get({
				skipLoading: true,
			})
			.then((task) => {
				const status = task?.description_obj?.status;
				setDescription(task?.description);

				tries.current += 1;
				if (LOADING_STATUSES.includes(status)) {
					setTimeout(() => checkDescriptionStatus(), 3000);
				}
			});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [tries]);

	useEffect(() => {
		if (isLoading) {
			checkDescriptionStatus();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<SummaryWrapper
			title={t("title")}
			icon={
				<Tabs.Container>
					<Tab
						active={view === "preview"}
						onClick={() => setView("preview")}
					>
						<i className="fas fa-eye"></i>
					</Tab>
					<Tab
						active={view === "edit"}
						onClick={() => setView("edit")}
					>
						<i className="fas fa-pencil"></i>
					</Tab>
				</Tabs.Container>
			}
		>
			{isLoading && !isFailed ? (
				<AILoading description={t("generating.description")} />
			) : (
				<div className="mt-2">
					{isFailed && (
						<div className="mb-4">
							<Alert type={"info"}>{t("failed")}</Alert>
						</div>
					)}

					{view === "preview" ? (
						<div
							className="flex flex-col gap-2"
							dangerouslySetInnerHTML={{ __html: description }}
						></div>
					) : (
						<div>
							<Field.Editor
								value={description}
								onChange={(value) => setDescription(value)}
							/>

							<Button
								onClick={handleSubmit}
								loading={apiStatus === "updating"}
								disabled={
									apiStatus !== "idle" ||
									description === task?.description
								}
								small
								type="dark"
							>
								Opslaan
							</Button>
						</div>
					)}
				</div>
			)}
		</SummaryWrapper>
	);
};

GeneratedDescriptionForm.locale = {
	nl: {
		title: "Onze AI heeft de volgende briefing geschreven",
		input: {
			label: "Onze AI heeft de volgende briefing geschreven",
		},
		generating: {
			description: "De briefing wordt gegenereerd",
		},
		failed: "De AI kon helaas geen omschrijving maken, zou je er zelf 1 willen schrijven?",
	},
	en: {
		title: "Our AI generated the following briefing",
		input: {
			label: "Our AI generated the following briefing",
		},
		generating: {
			description: "The briefing is being generated",
		},
		failed: "The AI was not able to generate a briefing, could you make one your self?",
	},
};

export default withTranslation(GeneratedDescriptionForm);
