import axios from "axios";
import moment from "moment";
import { useEffect, useRef } from "react";
import { ApiRegistration, ApiReview } from "src/api/types";
import Avatar from "src/components/Avatar";
import useGet from "src/hooks/rest/useGet";
import useAuth from "src/hooks/selectors/useAuth";
import useOnScreen from "src/hooks/useOnScreen";
import RegistrationSection from "src/components/tasks/panes/registration/RegistrationSection";
import { useTranslation } from "react-i18next";
import Skeleton from "src/components/Skeleton";

interface RegistrationDetailsProps {
	registration: ApiRegistration;
}

const RegistrationReviews = ({ registration }: RegistrationDetailsProps) => {
	const { t } = useTranslation("registrations", {
		keyPrefix: "registration-reviews",
	});
	const auth = useAuth();
	const [{ data }, loading] = useGet(
		`/${auth.type}/registrations/${registration.id}/reviews`
	);

	const employee = (data || []).find(
		(item: any) => item?.creator?.type === "company"
	);
	const student = (data || []).find(
		(item: any) => item?.creator?.type === "student"
	);

	return (
		<div className="flex md:flex-row flex-col gap-6">
			<ReviewSection
				title={t("client")}
				review={employee}
				{...{ loading }}
			/>
			<ReviewSection
				title={t("contractor")}
				review={student}
				{...{ loading }}
			/>
		</div>
	);
};

interface ReviewSectionProps {
	title: string;
	review?: ApiReview;
	loading?: boolean;
}

const ReviewSection = ({ title, review, loading }: ReviewSectionProps) => {
	const { t } = useTranslation("registrations", {
		keyPrefix: "registration-reviews",
	});
	const ref = useRef() as any;
	const onScreen = useOnScreen(ref);
	const avatar = review?.creator?.avatar;

	useEffect(() => {
		if (review?.can_read && !review?.is_read && onScreen) {
			axios.post(`/reviews/${review.id}/read`);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [review, onScreen]);

	return (
		<RegistrationSection {...{ title }} className="flex-1">
			<div {...{ ref }} className="flex flex-col gap-1">
				<strong>{t("review.title")}</strong>
				{loading ? (
					<Skeleton className="h-6 w-1/2" bg="border" />
				) : (
					<p>{review?.description || t("review.not-created")}</p>
				)}
			</div>

			{review ? (
				<>
					<div className="flex flex-col gap-1">
						<strong>{t("review.created-by")}</strong>
						<div className="flex items-center gap-2">
							<Avatar
								src={avatar}
								alt={review?.creator?.full_name}
							/>
							<div className="flex flex-col">
								<p>{review?.creator?.full_name}</p>
								{review?.creator?.company && (
									<small className="opacity-60">
										{review?.creator?.company?.name}
									</small>
								)}
							</div>
						</div>
					</div>
					<small className="opacity-40">
						{moment(review?.created_at).fromNow()}
					</small>
				</>
			) : loading ? (
				<>
					<div className="flex flex-col gap-1">
						<Skeleton className="h-6 w-1/3" bg="border" />
						<div className="flex items-center gap-2">
							<Avatar.Loader bg="border" />
							<div className="flex flex-col">
								<Skeleton className="h-6 w-1/3" bg="border" />
								<Skeleton className="h-6 w-1/4" bg="border" />
							</div>
						</div>
					</div>
					<Skeleton className="h-4 w-1/4" bg="border" />
				</>
			) : (
				false
			)}
		</RegistrationSection>
	);
};

export default RegistrationReviews;
