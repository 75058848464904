import axios from "axios";
import moment from "moment";
import { useCookies } from "react-cookie";
import { useDispatch, useSelector } from "react-redux";
import { ApiLanguageCode } from "src/api/types";
import useEditProfile from "src/hooks/api/useEditProfile";
import useAuth from "src/hooks/selectors/useAuth";
import { createCookie } from "src/hooks/useTokenListener";
import i18n from "src/lib/i18n";
import { updateLanguage } from "src/redux/language";

function useLanguage() {
	const [, setCookie] = useCookies();
	const auth = useAuth();
	const dispatch = useDispatch();
	const [editProfile, updating] = useEditProfile();
	const language = useSelector((state: any) => state.language);

	// useEffect(() => {
	// dispatch(updateLanguage({ current: i18n.language }));
	// eslint-disable-next-line react-hooks/exhaustive-deps
	// }, []);

	const update = async (code: ApiLanguageCode) => {
		changeLanguage(code);
		if (auth?.id) {
			await editProfile({
				language: {
					code,
				},
			});
		}
	};

	const changeLanguage = (code?: ApiLanguageCode) => {
		if (!code) {
			return;
		}
		//Set the moment locale
		if (code === "NL") {
			require("moment/locale/nl");
			moment.locale("nl");
		} else {
			moment.locale("en");
		}

		setCookie("Locale", code, createCookie());

		i18n.changeLanguage(code.toLowerCase());
		dispatch(updateLanguage({ current: code.toLowerCase() }));
	};

	const get = async () => {
		const { data } = await axios.get(`/languages`);
		dispatch(updateLanguage({ languages: data?.data }));
	};

	return {
		language,
		updating,
		current: language?.current as "nl" | "en",
		actions: {
			get,
			update,
			changeLanguage,
		},
	};
}

export default useLanguage;
