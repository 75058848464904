import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import StudentPoolDropdown from "src/components/form/dropdowns/StudentPoolDropdown";
import TasksLayout from "src/components/layout/tasks-layout/TasksLayout";
import PublishDraftModal from "src/components/tasks/TaskForm/elements/PublishDraftModal";
import TasksTable from "src/components/tasks/TasksTable";
import TasksCalendar from "src/components/tasks/calendar/TasksCalendar";
import TasksKanban from "src/components/tasks/kanban/TasksKanban";
import useTasks from "src/hooks/api/services/tasks/useTasks";
import useTasksFilter from "src/hooks/api/tasks/useTasksFilter";
import useTasksKanban from "src/hooks/api/tasks/useTasksKanban";
import useStudentPool from "src/hooks/api/useStudentpool";
import useTasksView from "src/hooks/useTasksView";

function TasksView() {
	const { team, board, project } = useParams();
	const { view } = useTasksView();
	const [modalTask, setModalTask] = useState<string | null>(null);
	const { students } = useStudentPool();
	const [matchStudentId, setMatchStudentId] = useState(undefined);
	const { filter, actions } = useTasksFilter();
	const { actions: tasksActions } = useTasks();
	const { actions: kanbanActions } = useTasksKanban();

	useEffect(() => {
		return () => {
			tasksActions.resetState();
			tasksActions.resetFilter();
			kanbanActions.reset();
			//Clear filters
			actions.clear();
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (team) {
			actions.set(
				{
					team,
				},
				"reset"
			);
		} else if (filter?.team) {
			actions.set({
				team: null,
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [team]);

	useEffect(() => {
		if (board) {
			actions.set(
				{
					board,
					include: board === "my-tasks" ? ["project-tasks"] : [],
				},
				"reset"
			);
			kanbanActions.getAllStatuses();
		} else if (filter?.board) {
			actions.set(
				{
					board: undefined,
				},
				"reset"
			);
			kanbanActions.getAllStatuses();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [board]);

	useEffect(() => {
		if (project) {
			actions.set(
				{
					project,
				},
				"reset"
			);
		} else if (filter?.project) {
			actions.set(
				{
					project: undefined,
				},
				"reset"
			);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [project]);

	return (
		<TasksLayout>
			<div className="flex flex-col flex-1">
				{view === "kanban" && <TasksKanban />}
				{view === "list" && (
					<TasksTable
						{...{ setModalTask }}
						searchActions={
							students?.length > 0 && (
								<>
									<StudentPoolDropdown
										placeholder="Filter op student"
										value={matchStudentId}
										onChange={(value: any) => {
											setMatchStudentId(value);
										}}
									/>
								</>
							)
						}
					/>
				)}
				{view === "calendar" && <TasksCalendar />}
			</div>

			<PublishDraftModal taskId={modalTask} setModalTask={setModalTask} />
		</TasksLayout>
	);
}

export default TasksView;
