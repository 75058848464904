import React from "react";
import { ComponentProps, useEffect } from "react";
import { Avatar, Name } from "src/components/analytics/user/Profile";
import { UserStatisticsContext } from "src/components/analytics/user/Provider";
import {
	SkillDistribution,
	SkillProgress,
	Skills,
	Totals,
} from "src/components/analytics/user/Statistics";
import useUserStats from "src/hooks/api/services/statistics/user/useUserStats";
import useUsers from "src/hooks/api/services/users/useUsers";
import useAuth from "src/hooks/selectors/useAuth";
import useSocketListener from "src/hooks/sockets/useSocketListener";
import { cn } from "src/lib/utils";

interface UserStatisticsProps extends ComponentProps<"div"> {
	userId: number;
	as?: "div" | "fragment";
}

const UserStatistics = ({
	userId,
	className,
	as = "div",
	...rest
}: UserStatisticsProps) => {
	const auth = useAuth();
	const { usersRaw: users } = useUsers();
	const {
		data,
		actions,
		status,
		userId: _userId,
	} = useUserStats({
		userId,
	});
	let hid = users.find((user) => user.id === userId)?.hid;
	if (!hid) {
		hid = auth?.hid;
	}

	useEffect(() => {
		actions.get("");
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<UserStatisticsContext.Provider
			value={{
				userId: _userId,
				user: users.find((user) => user.id === userId) as any,
				status,
				data,
			}}
		>
			{hid && userId && <Socket id={hid} userId={userId} />}
			{userId && (
				<>
					{as === "fragment" && rest?.children}

					{as === "div" && (
						<div {...(rest as any)} className={cn("", className)} />
					)}
				</>
			)}
		</UserStatisticsContext.Provider>
	);
};

const Socket = ({ id, userId }: { id: string; userId: any }) => {
	const { actions } = useUserStats({
		userId,
	});
	useSocketListener(`SKILL_PROGRESS_${id}`, async () => {
		actions.get("");
		actions.getSkillDistribution();
	});
	return null;
};

UserStatistics.Name = Name;

//Statistics
UserStatistics.Skills = Skills;
UserStatistics.Totals = Totals;
UserStatistics.SkillDistribution = SkillDistribution;
UserStatistics.SkillProgress = SkillProgress;
UserStatistics.Avatar = Avatar;

export default UserStatistics;
