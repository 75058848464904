import { useEffect } from "react";
import Input from "src/components/form/Input";
import withTranslation, {
	Translation,
} from "src/components/hoc/withTranslation";
import CompanyInfoSearchListItem, {
	CompanyInfoSearchListItemLoader,
} from "src/components/lists/list-items/CompanyInfoSearchListItem";
import useCompanyInfoSearch from "src/hooks/api/services/auth/useCompanyInfo";
import useDebounce from "src/hooks/useDebounce";
import useUpdateEffect from "src/hooks/useUpdateEffect";

interface CompanyInfoSearchListProps extends Translation {
	search?: { value?: string };
	item?: {
		onClick?: (id: string) => void;
	};
}

const CompanyInfoSearchList = ({
	t,
	search: _search,
	item,
}: CompanyInfoSearchListProps) => {
	const { searches, actions, status } = useCompanyInfoSearch();
	const [search, setSearch, debouncedSearch] = useDebounce(
		_search?.value || "",
		1000,
		actions.search
	);

	useEffect(() => {
		if (_search?.value && status === "idle") {
			setSearch(_search.value);
			actions.search(_search.value);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useUpdateEffect(() => {
		if (search) actions.search(debouncedSearch);
	}, [debouncedSearch]);

	return (
		<div className="flex flex-col gap-4 my-4">
			<Input
				icon={<i className="fas pr-2 fa-search" />}
				value={search}
				name="search"
				placeholder={t("placeholder")}
				onChange={(key, value) => setSearch(value)}
				loading={status === "loading"}
			/>

			{!search && searches?.length === 0 && (
				<div className="flex flex-col p-8">
					<p>{t("missing-search")}</p>
				</div>
			)}

			{searches?.length > 0 && status === "idle" && (
				<div className="flex flex-col max-h-[360px] overflow-auto gap-4">
					{searches.map((info, index) => (
						<CompanyInfoSearchListItem
							key={`search-item-${index}`}
							{...{ info }}
							onClick={() => {
								if (item?.onClick) {
									item.onClick(info.id);
								}
							}}
						/>
					))}
				</div>
			)}

			{status === "idle" && search && searches.length === 0 && (
				<div className="flex flex-col p-8">
					<p>
						{t("empty", {
							search,
						})}
					</p>
				</div>
			)}

			{status === "loading" && (
				<div className="flex flex-col max-h-[360px] overflow-auto gap-4">
					{new Array(6).fill(" ").map((item, index) => (
						<CompanyInfoSearchListItemLoader
							key={`search-item-${index}-loader`}
						/>
					))}
				</div>
			)}
		</div>
	);
};

CompanyInfoSearchList.locale = {
	nl: {
		placeholder: "Zoek op bedrijfsnaam of kvk",
		"missing-search":
			"Vul een bedrijfsnaam of kvk in, zo vinden we gemakkelijk al je bedrijfsinformatie.",
		empty: "We hebben geen zoekresultaten gevonden voor de zoekterm: {{search}}.",
	},
	en: {
		placeholder: "Search by company name or chamber of commerce",
		"missing-search":
			"Use your company name to search for your company details",
		empty: "We did not found any results for the search term: {{search}}.",
	},
};

export default withTranslation(CompanyInfoSearchList);
