import _ from "lodash";
import DayIcon from "src/components/DayIcon";
import { ApiDay } from "src/api/types";
import { DAYS_IN_WEEK } from "src/lib/constants";

interface DayPickerProps {
	value?: ApiDay[];
	onChange: (value?: ApiDay[]) => void;
}

const DayPicker = ({ value, onChange }: DayPickerProps) => {
	return (
		<div className="flex items-center justify-between gap-2">
			{DAYS_IN_WEEK.map((day) => (
				<DayIcon
					{...{ day }}
					key={`day-${day}`}
					variant={
						(value || [])?.includes(day) ? "active" : undefined
					}
					onClick={(e) => {
						e.preventDefault();
						onChange(_.xor(value || [], [day]));
					}}
				/>
			))}
		</div>
	);
};

export default DayPicker;
