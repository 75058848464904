import { ComponentProps } from "react";
import Card from "src/components/Card";
import {
	Indicator,
	Label,
	Value,
} from "src/components/statistics/total-card/Elements";
import {
	TotalCardContext,
	TotalCardContextProps,
} from "src/components/statistics/total-card/Provider";
import { cn } from "src/lib/utils";

interface TotalCardProps extends ComponentProps<"div"> {}

const TotalCard = ({
	value,
	label,
	loading,
	...rest
}: TotalCardContextProps & TotalCardProps) => {
	return (
		<TotalCardContext.Provider
			value={{
				value,
				label,
				loading,
			}}
		>
			<Card
				{...rest}
				contentClassName="gap-3 justify-center"
				className={cn(rest?.className)}
			></Card>
		</TotalCardContext.Provider>
	);
};

TotalCard.Label = Label;
TotalCard.Value = Value;
TotalCard.Indicator = Indicator;

export default TotalCard;
