import Card from "src/components/Card";
import withTranslation, {
	Translation,
} from "src/components/hoc/withTranslation";
import ResumeEditButton from "src/components/resume/resume-detail/ResumeEditButton";
import ResumeSectionTitle from "src/components/resume/resume-detail/ResumeSectionTitle";
import Slider from "src/components/slider/Slider";
import TimelineItem from "src/components/Timeline/TimelineItem";
import useAuth from "src/hooks/selectors/useAuth";
import useWindowSize from "src/hooks/useWindowSize";
import { cn } from "src/lib/utils";
import useResumeDetailContext from "../ResumeDetailContext";

interface ResumeEducationProps extends Translation {
	editable?: boolean;
	variant?: "slider" | "timeline";
}

function ResumeEducation({
	t,
	editable,
	variant = "slider",
}: ResumeEducationProps) {
	const auth = useAuth();
	const { resume } = useResumeDetailContext();
	const experiences = resume?.study_experiences || [];
	const { isPhone } = useWindowSize();
	const color = resume?.primary_color;

	if (!experiences.length) {
		if (editable && resume?.id === auth.id) {
			return (
				<div
					className={cn(
						"flex flex-col text-center px-4 py-8 md:p-8 rounded-md justify-center items-center"
					)}
				>
					<h3 className="mb-2">{t("empty.title")}</h3>
					<p className="">{t("empty.description")}</p>
					{editable && (
						<ResumeEditButton
							type="button"
							variant="education"
							className="mt-6"
						>
							<i className="fas fa-plus mr-1"></i>
							{t("empty.button")}
						</ResumeEditButton>
					)}
				</div>
			);
		}
		return <></>;
	}

	if (variant === "timeline") {
		return (
			<div className="flex flex-col gap-3">
				<div className="flex justify-between items-center">
					<ResumeSectionTitle className="">
						{t("title")}
					</ResumeSectionTitle>
					{editable && <ResumeEditButton variant="education" />}
				</div>
				<div className="flex flex-col">
					{experiences.map((item: any, index) => (
						<TimelineItem
							key={`experience-${item.id}`}
							date={{
								start: item.started,
								end: item?.ended,
							}}
							title={item.title}
							subtitle={item.subtitle}
							type={{
								first: index === 0,
								last: experiences.length === (index || 0) + 1,
							}}
							{...{ color }}
						/>
					))}
				</div>
			</div>
		);
	}

	return (
		<div className={`flex flex-col`}>
			<Slider
				options={{
					slides: 3,
					gap: 12,
				}}
			>
				<div className="flex items-center justify-between mb-4">
					<ResumeSectionTitle className="">
						{t("title")}
					</ResumeSectionTitle>
					<div className="flex items-center gap-4">
						{editable && <ResumeEditButton variant="education" />}
						{(experiences.length > 3 ||
							(isPhone && experiences.length > 1)) && (
							<div className="flex items-center gap-3">
								<Slider.Button
									direction="previous"
									iconOnly
									type="dark"
								>
									<i className="far fa-arrow-left"></i>
								</Slider.Button>
								<Slider.Button
									direction="next"
									iconOnly
									type="dark"
								>
									<i className="far fa-arrow-right"></i>
								</Slider.Button>
							</div>
						)}
					</div>
				</div>
				<Slider.Items>
					{experiences.map((item: any) => (
						<Card key={item.id}>
							<div>
								{item.started && (
									<small>{`${item.started}-${
										item.ended ? item.ended : "heden"
									}`}</small>
								)}
								<h4>{item.title}</h4>
								<p className="mt-1">{item.subtitle}</p>
							</div>
						</Card>
					))}
				</Slider.Items>
			</Slider>
		</div>
	);
}

ResumeEducation.locale = {
	nl: {
		title: "Opleidingen",
		empty: {
			title: "Voeg oploedingen toe",
			description: "Voeg verschillende opleidingen en cursussen toe",
			button: "Opleidingen toevoegen",
		},
	},
	en: {
		title: "Educations",
		empty: {
			title: "Add educations",
			description: "Add different educations and courses",
			button: "Add educations",
		},
	},
};

export default withTranslation(ResumeEducation);
