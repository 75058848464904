import { useEffect, useState } from "react";
import { ApiForm } from "src/api/types";
import Button from "src/components/Button";
import CustomForm from "src/components/custom-form/CustomForm";
import Loader from "src/components/Loader";
import {
	Popover,
	PopoverContent,
	PopoverTrigger,
} from "src/components/ui/popover";
import usePaymentRequestCustomForms from "src/hooks/api/services/forms/usePaymentRequestCustomForms";

interface PaymentRequestCustomFormsProps {
	companyId: string | number;
	value: any;
	onChange: (values: any) => any;
	variant?: "buttons" | "list";
}

const PaymentRequestCustomForms = ({
	companyId,
	value,
	onChange,
	variant = "buttons",
}: PaymentRequestCustomFormsProps) => {
	const {
		forms,
		actions: formsActions,
		status: formsStatus,
	} = usePaymentRequestCustomForms({
		company_id: companyId,
	});

	useEffect(() => {
		formsActions.list();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleSubmit = (formId: string, values: any) => {
		return onChange({
			...value,
			[formId]: values,
		});
	};

	if (variant === "list") {
		return (
			<>
				{forms.map((form: ApiForm) => (
					<div
						key={form.id}
						className="flex flex-col gap-1 p-3 border border-border rounded-md"
					>
						<p>{form.name}</p>
						<CustomForm
							{...{ form }}
							layout="horizontal"
							value={value[form.id] || {}}
							onChange={(values) => handleSubmit(form.id, values)}
						/>
					</div>
				))}
			</>
		);
	}

	return (
		<>
			{formsStatus === "loading" && <Loader />}
			{forms.map((form: ApiForm) => (
				<Item
					key={form.id}
					{...{ form }}
					value={value[form.id] || {}}
					onChange={(values) => handleSubmit(form.id, values)}
				/>
			))}
		</>
	);
};

interface ItemProps {
	form: ApiForm;
	value?: any;
	onChange: (values: any) => Promise<any>;
}

const Item = ({ form, value, onChange }: ItemProps) => {
	const [isOpen, setIsOpen] = useState(false);
	return (
		<Popover open={isOpen} onOpenChange={setIsOpen}>
			<PopoverTrigger>
				<Button xsmall type="border">
					{form.name}
				</Button>
			</PopoverTrigger>
			<PopoverContent>
				<CustomForm
					{...{ form, value }}
					onChange={(values) => {
						onChange(values);
					}}
				/>
			</PopoverContent>
		</Popover>
	);
};

export default PaymentRequestCustomForms;
