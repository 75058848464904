import { ComponentProps, createContext, useContext } from "react";
import Button, { ButtonProps } from "src/components/Button";
import { useT } from "src/components/hoc/withTranslation";
import { cn } from "src/lib/utils";

const MEMBERS = {
	joost: {
		name: "Joost",
		avatar: "https://framerusercontent.com/images/R9HDiUOrrUGEtgArTbpStHYGdxI.webp?scale-down-to=512",
		calendly: "https://calendly.com/getjobsdone/adviesgesprek",
	},
	patrick: {
		name: "Patrick",
		avatar: "https://framerusercontent.com/images/yBnR6DEyTrpl74UPz27zwwFAVY.webp?scale-down-to=512",
		calendly: "https://calendly.com/kennismaking-getjobsdone/adviesgesprek",
	},
} as const;

const BookDemoCardContext = createContext<any>({});

const useBookDemoCard = () => useContext(BookDemoCardContext);

interface BookDemoCardProps extends ComponentProps<"div"> {
	member: keyof typeof MEMBERS;
}

const BookDemoCard = ({
	member: memberKey,
	className,
	children,
	...rest
}: BookDemoCardProps) => {
	const member = MEMBERS[memberKey];
	return (
		<BookDemoCardContext.Provider value={{ member }}>
			<div
				{...rest}
				className={cn(
					"flex flex-col p-6 rounded-md bg-card shadow text-card-foreground",
					className
				)}
			>
				{children}
			</div>
		</BookDemoCardContext.Provider>
	);
};

export const BookDemoCardAvatar = ({
	className,
	...rest
}: ComponentProps<"div">) => {
	const { member } = useBookDemoCard();
	return (
		<div
			{...rest}
			className={cn(
				"flex justify-center items-center w-32 h-32 rounded-full",
				className
			)}
		>
			<img src={member.avatar} alt="" />
		</div>
	);
};

export const BookDemoCardTitle = ({
	className,
	...rest
}: ComponentProps<"p">) => {
	const t = useT({
		nl: {
			title: "Vragen over de abonnementen?",
		},
		en: {
			title: "Questions about the subscriptions?",
		},
	});
	return (
		<p {...rest} className={cn("", className)}>
			{t("title")}
		</p>
	);
};

export const BookDemoCardDescription = ({
	className,
	...rest
}: ComponentProps<"p">) => {
	const { member } = useBookDemoCard();
	const t = useT({
		nl: {
			description: "Plan een adviesgesprek in met {{name}}.",
		},
		en: {
			description: "Schedule a call with {{name}}.",
		},
	});
	return (
		<p {...rest} className={cn("", className)}>
			{t("description", {
				name: member.name,
			})}
		</p>
	);
};

export const BookDemoCardButton = ({ className, ...rest }: ButtonProps) => {
	const { member } = useBookDemoCard();
	const t = useT({
		nl: {
			label: "Plan direct een adviesgesprek",
		},
		en: {
			label: "Schedule a call",
		},
	});
	return (
		<Button
			{...rest}
			small
			className={cn("w-max", className)}
			to={member.calendly}
			type="border"
		>
			{t("label")}
		</Button>
	);
};

export default BookDemoCard;
