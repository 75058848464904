import { useState } from "react";
import { useTranslation } from "react-i18next";
import { cleanHTMLText, formatExcerpt } from "src/lib/formatters";
import { cn } from "src/lib/utils";

interface ReadMoreProps {
	text: string;
	length?: number;
	button_labels?: {
		open?: string;
		closed?: string;
	};
	defaultOpen?: boolean;
	className?: string;
	variant?: "default" | "line-clamp";
}

const ReadMore = ({
	text,
	length,
	button_labels,
	defaultOpen,
	className,
	variant = "default",
}: ReadMoreProps) => {
	const { t } = useTranslation(["buttons"]);
	const excerpt = formatExcerpt(text, length || 20);
	const [visible, setVisible] = useState(defaultOpen || false);

	const getButtonLabel = () => {
		if (visible) {
			return button_labels?.open || t("read-less");
		}
		return button_labels?.closed || t("read-more");
	};

	if (variant === "line-clamp") {
		return (
			<div className={cn("flex flex-col gap-1", className)}>
				<p className={cn("", !visible && "line-clamp-2")}>{text}</p>
				<strong
					onClick={() => setVisible(!visible)}
					className="block cursor-pointer transition-all opacity-40 hover:opacity-100 select-none"
				>
					{getButtonLabel()}
				</strong>
			</div>
		);
	}

	return (
		<p className={`${className || ""} description`}>
			{visible ? cleanHTMLText(text) : cleanHTMLText(excerpt)}
			{text?.length !== excerpt?.length && (
				<strong
					onClick={() => setVisible(!visible)}
					className="block cursor-pointer transition-all opacity-40 hover:opacity-100 select-none"
				>
					{getButtonLabel()}
				</strong>
			)}
		</p>
	);
};

export default ReadMore;
