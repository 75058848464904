import { ComponentProps } from "react";
import { useLocation } from "react-router";
import Link from "src/components/Link";
import { cn } from "src/lib/utils";

interface TabNavItemProps extends ComponentProps<"div"> {
	to: string;
	ref?: any;
	active?: boolean;
}

const TabNavItem = ({
	to,
	children,
	className,
	ref,
	active,
	...rest
}: TabNavItemProps) => {
	const location = useLocation();
	const isActive = active || to === location.pathname;

	return (
		<Link
			{...(rest as any)}
			{...{ to }}
			className={cn(
				"font-regular text-background-foreground py-3 relative whitespace-nowrap opacity-40",
				isActive && "text-background-foreground opacity-100",
				className
			)}
		>
			{children}
		</Link>
	);
};

export default TabNavItem;
