import { useEffect, useState } from "react";
import { CheckoutType } from "src/api/types";
import Button from "src/components/Button";
import KeyValue from "src/components/KeyValue";
import Loader from "src/components/Loader";
import CheckoutPaymentMethods from "src/components/checkout/CheckoutPaymentMethods";
import Field from "src/components/field/Field";
import withTranslation, {
	Translation,
} from "src/components/hoc/withTranslation";
import { Switch } from "src/components/ui/switch";
import usePaymentRequestsCheckout from "src/hooks/api/services/payment-requests/usePaymentRequestsCheckout";
import useAuth from "src/hooks/selectors/useAuth";
import useSignature from "src/hooks/selectors/useSignature";
import useModal from "src/hooks/useModal";
import useQueryString from "src/hooks/useQueryString";
import { formatPrice } from "src/lib/formatters";

const PaymentRequestCheckoutView = ({ t }: Translation) => {
	useModal({ title: t("title") });
	const qs = useQueryString();
	const ids = typeof qs.ids === "string" ? [qs.ids] : (qs.ids as string[]);
	const auth = useAuth();
	const { signature } = useSignature();
	const [projectCode, setProjectCode] = useState<string | null | undefined>();
	const [isSignAutomatic, setIsSignAutomatic] = useState(
		signature?.id ? true : false
	);
	const [isSubmitting, setIsSubmitting] = useState(false);
	const { status, totals, actions } = usePaymentRequestsCheckout({ ids });

	useEffect(() => {
		actions.list({ ids });
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (signature?.id) {
			setIsSignAutomatic(true);
		}
	}, [signature]);

	const onSubmit = async (method: CheckoutType, issuer?: any) => {
		setIsSubmitting(true);
		const redirect_url = `/payment-requests/finalize`;
		try {
			const data = await actions.checkout({
				ids,
				method,
				issuer,
				redirect_url,
				is_sign_direct: isSignAutomatic,
				project_code: projectCode ? projectCode : undefined,
			});
			setIsSubmitting(false);
			if (data.redirect_url) {
				window.location = data.redirect_url;
			}
		} catch (error) {
			setIsSubmitting(false);
		}
	};

	if (!ids || !Array.isArray(ids) || ids?.length === 0) {
		return (
			<div className="flex flex-col flex-1 justify-center items-center">
				<div className="flex flex-col text-center">
					<strong>{t("empty.title")}</strong>
					<p className="opacity-70 max-w-[460px] text-center">
						{t("empty.description")}
					</p>
					<div className="flex items-center justify-center gap-3 mt-4">
						<Button to="/payment-requests/process">
							{t("buttons.select-payments")}
						</Button>
					</div>
				</div>
			</div>
		);
	}

	return (
		<div className="">
			{status === "loading" ? (
				<div className="flex flex-col items-center justify-center p-4 h-[50vh]">
					<Loader />
				</div>
			) : (
				<div className="flex flex-col flex-1 gap-2">
					<strong className="block mb-2">{t("summary.title")}</strong>

					<KeyValue.Small
						label={t("keys.payments.label")}
						text={totals.payments}
					/>
					<KeyValue.Small
						label={t("keys.hours.label")}
						text={t("keys.hours.value", {
							count: totals.hours,
						})}
					/>
					<KeyValue.Small
						label={t("keys.price.label")}
						text={formatPrice(totals.price)}
					/>
					<KeyValue.Small
						label={t("keys.fee.label", {
							rate: auth.service_rate * 100,
						})}
						text={formatPrice(totals.fee)}
					/>
					<div className="flex flex-col">
						<KeyValue.Small
							label={t("keys.vat.label")}
							text={formatPrice(totals.vat)}
						/>
						<small className="opacity-70">
							{t("keys.vat.help")}
						</small>
					</div>
					<hr className="border-border" />
					<KeyValue.Small
						label={t("keys.total.label")}
						text={formatPrice(totals.total)}
					/>

					<Field.Input
						wrapper={{
							layout: "horizontal",
							label: t("project.label"),
							description: t("project.help"),
						}}
						className="border border-border"
						placeholder={t("project.placeholder")}
						onChange={(value) => setProjectCode(value)}
						value={projectCode || ""}
						autoFill="payment_project_codes"
					/>

					<CheckoutPaymentMethods
						className="mt-4"
						methods={["ideal", "invoice"]}
						{...{ onSubmit }}
						pending={isSubmitting ? true : undefined}
						extra={
							<>
								<div
									onClick={() =>
										setIsSignAutomatic(!isSignAutomatic)
									}
									className="flex gap-2 items-center"
								>
									<Switch checked={isSignAutomatic} />
									<div className="flex flex-1">
										<p>{t("sign-direct.title")}</p>
									</div>
								</div>
							</>
						}
					/>

					<p className="opacity-70">{t("help")}</p>
				</div>
			)}
		</div>
	);
};

PaymentRequestCheckoutView.locale = {
	nl: {
		processTitle: "Verzoeken verwerken",
		title: "Betaling verwerken",
		empty: {
			title: "Geen verzoeken geselecteerd",
			description:
				"Selecteer eerst een aantal verzoeken om een betaling aan te maken.",
		},
		buttons: {
			"select-payments": "Selecteer verzoeken",
		},
		summary: {
			title: "Overzicht",
		},
		"sign-direct": {
			title: "Overeenkomsten automatisch ondertekenen",
		},
		project: {
			label: "Projectcode",
			placeholder: "Bijv. A123",
			help: "Deze code wordt genoteerd op de factuur",
		},
		help: 'Wanneer je op "Afrekenen" klikt, worden alle opdrachtnemers op een gezamenlijke betaling gebundeld.',
		keys: {
			payments: {
				label: "Uitbetalingen",
			},
			hours: {
				label: "Totale uren",
				value: "{{count}} uren",
			},
			price: {
				label: "Uitbetaalbedrag",
			},
			fee: {
				label: "Succesfee ({{rate}}%)",
			},
			vat: {
				label: "BTW (21%)",
				help: "BTW wordt berekend over de succesfee",
			},
			total: {
				label: "Totaal",
			},
		},
	},
	en: {
		processTitle: "Process Requests",
		title: "Process Payment",
		empty: {
			title: "No Requests Selected",
			description: "First select some requests to create a payment.",
		},
		buttons: {
			"select-payments": "Select Requests",
		},
		summary: {
			title: "Summary",
		},
		"sign-direct": {
			title: "Automatically Sign Agreements",
		},
		project: {
			label: "Project Code",
			placeholder: "E.g., A123",
			help: "This code will be noted on the invoice",
		},
		help: 'When you click "Checkout," all contractors will be bundled into a single payment.',
		keys: {
			payments: {
				label: "Payments",
			},
			hours: {
				label: "Total Hours",
				value: "{{count}} hours",
			},
			price: {
				label: "Payout Amount",
			},
			fee: {
				label: "Success Fee ({{rate}}%)",
			},
			vat: {
				label: "VAT (21%)",
				help: "VAT is calculated on the success fee",
			},
			total: {
				label: "Total",
			},
		},
	},
};

export default withTranslation(PaymentRequestCheckoutView);
