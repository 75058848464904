import { ApiTeam } from "src/api/types";
import SkillSelector from "src/components/form/skill-selector/SkillSelector";
import withTranslation, {
	Translation,
} from "src/components/hoc/withTranslation";
import Icon from "src/components/icon/Icon";
import Accordion from "src/components/ui/accordion";
import Text from "src/components/ui/text";

interface TeamSkillsCardProps extends Translation {
	team: ApiTeam;
	onChange: (value: ApiTeam) => void;
	defaultOpen?: boolean;
}

const TeamSkillsCard = ({
	t,
	team,
	onChange,
	defaultOpen,
}: TeamSkillsCardProps) => {
	return (
		<Accordion {...{ defaultOpen }}>
			<Accordion.Head>
				<div className="flex items-center gap-1">
					{team.icon && (
						<Icon
							icon={team.icon}
							className="w-auto p-0 h-5"
							iconFontSize={14}
						/>
					)}
					<Text.Eyebrow className="opacity-100">
						{team.name}
					</Text.Eyebrow>
					{/* <strong>{team.name}</strong> */}
				</div>
				<div className="flex items-center gap-3">
					{!!team.skills.length && (
						<span>
							{t("skills", { count: team.skills.length })}
						</span>
					)}
					<Accordion.Icon />
				</div>
			</Accordion.Head>
			<Accordion.Content>
				<SkillSelector.Root
					onChange={(skills) => onChange({ ...team, skills })}
					value={team.skills}
					defaultSearch={team.name}
					theme={{
						active: {
							className: "bg-primary border border-primary",
						},
						inactive: {
							className:
								"bg-border text-background-foreground border border-border",
						},
						default: {
							size: "small",
						},
					}}
				>
					<SkillSelector.Results />
					<SkillSelector.Input />
				</SkillSelector.Root>
			</Accordion.Content>
		</Accordion>
	);
};

TeamSkillsCard.locale = {
	nl: {
		skills_one: "{{count}} skill",
		skills_other: "{{count}} skills",
	},
	en: {
		skills_one: "{{count}} skill",
		skills_other: "{{count}} skills",
	},
};

export default withTranslation(TeamSkillsCard);
