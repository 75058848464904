import moment from "moment";
import { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import Breadcrumb from "src/components/Breadcrumb";
import Button from "src/components/Button";
import Card from "src/components/Card";
import Skeleton from "src/components/Skeleton";
import withTranslation, {
	Translation,
} from "src/components/hoc/withTranslation";
import Icon from "src/components/icon/Icon";
import LoadMore from "src/components/kanban/LoadMore";
import Layout from "src/components/layout/Layout";
import TaskKanbanCard from "src/components/tasks/kanban/TaskKanbanCard";
import useBoards from "src/hooks/api/services/tasks/useBoards";
import useCompletedTasks from "src/hooks/api/tasks/useCompletedTasks";
import useTasksFilter from "src/hooks/api/tasks/useTasksFilter";
import { getDatesBetweenDates } from "src/lib/dates";
import { cn } from "src/lib/utils";

function OverviewCompletedTasks({ t }: Translation) {
	const { board } = useParams();
	const {
		tasks,
		actions,
		status,
		pagination,
		statistics,
		completedStats: { completed, best },
	} = useCompletedTasks({
		filter: {
			board,
		},
	});
	const { filter: _filter, actions: filterActions } = useTasksFilter();
	const filter = {
		...(_filter || {}),
		board,
	};
	const [week, setWeek] = useState(moment().week());
	const { boards } = useBoards();
	const activeBoard = (boards || []).find((item) => item.slug === board);
	const dates = useMemo(() => {
		return getDatesBetweenDates(
			moment().week(week).startOf("week"),
			moment().week(week).endOf("week")
		);
	}, [week]);

	useEffect(() => {
		actions.list(
			{
				page: 1,
				filter,
				start_date: moment().week(week).startOf("week").toString(),
				end_date: moment().week(week).endOf("week").toString(),
			},
			{
				disableMergeFilter: true,
				appendResult: false,
			}
		);
		statistics({
			...filter,
			start_date: moment().week(week).startOf("week").toString(),
			end_date: moment().week(week).endOf("week").toString(),
		});
		filterActions.set({
			board: board,
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [week]);

	function capitalize(str: string) {
		return str.charAt(0).toUpperCase() + str.slice(1);
	}

	return (
		<Layout
			title={
				<div className="flex items-center gap-4">
					<h1 className="text-[24px] hidden sm:flex lg:text-[32px]">
						{t("title")}
					</h1>
				</div>
			}
		>
			<div className="flex flex-col mx-auto md:min-w-[1000px] min-w-[400px] gap-4">
				<div>
					{activeBoard?.name && (
						<Breadcrumb
							variant="dark"
							routes={[
								{
									label: t("breadcrumbs.tasks"),
									to: "/tasks",
									icon: Icon.getFontAwesome("fa-home"),
								},
								{
									label: activeBoard.name,
									icon: activeBoard.icon,
									to: `/tasks/boards/${activeBoard.slug}`,
								},
								{
									label: t("breadcrumbs.completed-tasks"),
									to: `/tasks/boards/${activeBoard.slug}/overview`,
									icon: Icon.getFontAwesome(
										"fa-check-circle"
									),
								},
							]}
						/>
					)}
				</div>
				<div className="flex justify-between">
					<h1>{`Week ${week}`}</h1>
					<div className="flex row gap-4">
						<Button
							type="dark"
							iconOnly
							icon="far fa-arrow-left"
							onClick={() => setWeek(week - 1)}
						/>
						<Button
							disabled={week === moment().week()}
							type="dark"
							iconOnly
							icon="far fa-arrow-right"
							onClick={() => setWeek(week + 1)}
						/>
					</div>
				</div>
				<div className="py-1">
					<hr className="border-border" />
				</div>

				{status === "loading" ? (
					<CompletedViewSkeleton />
				) : (
					<>
						{tasks.length === 0 ? (
							<p>{t("no-results")}</p>
						) : (
							<>
								<div className="flex flex-row gap-4">
									{completed?.total !== null && (
										<Card>
											<div>
												<div className="flex flex-row gap-2 items-center">
													<h2>{completed.total}</h2>
													<div
														className={cn(
															"flex flex-row items-center gap-1 justify-center bg-accent text-background-foreground py-0.5 px-1 rounded-sm",
															completed.previous >
																completed.total &&
																"bg-red-200 text-red",
															completed.previous <
																completed.total &&
																"bg-green-200 text-green"
														)}
													>
														<i
															className={cn(
																"fas text-[12px]",
																completed.previous >
																	completed.total &&
																	"fa-caret-down",
																completed.previous <
																	completed.total &&
																	"fa-caret-up"
															)}
														/>
														<small>
															{completed.previous}
														</small>
													</div>
												</div>
												<small className="text-placeholder">
													{t("completed-assignments")}
												</small>
											</div>
										</Card>
									)}
									{best?.date !== null && (
										<Card>
											<div>
												<div className="flex flex-row items-center gap-2">
													<h2>{`${capitalize(
														moment(
															best.date
														).format("dd")
													)}.`}</h2>

													<div
														className={cn(
															"flex flex-row items-center gap-1 justify-center bg-green-200 text-green py-0.5 px-1 rounded-sm"
														)}
													>
														<i
															className={cn(
																"fas text-[12px] fa-check"
															)}
														/>
														<small>
															{best.count}
														</small>
													</div>
												</div>
												<small className="text-placeholder">
													{t("best-day")}
												</small>
											</div>
										</Card>
									)}
								</div>
								<div className="py-1">
									<hr className="border-border" />
								</div>

								{dates.map((day) => {
									// Filter tasks for the current day
									const tasksForDay = (tasks || []).filter(
										(task) =>
											task.completed_at ===
											day.format("YYYY-MM-DD").toString()
									);

									// Render the date header only if there are tasks for the day
									if (tasksForDay.length > 0) {
										return (
											<div key={`calendar-${day}`}>
												<p className="text-placeholder mb-4">
													{capitalize(
														day.format("dddd")
													)}
												</p>
												{tasksForDay.map(
													(task, index) => (
														<TaskKanbanCard
															disableActions={
																true
															}
															key={index}
															task={{
																...task,
																can: [],
															}}
															className="mb-4"
														>
															<div className="flex flex-row justify-between items-center">
																<div className="flex flex-col gap-1">
																	<TaskKanbanCard.Title />
																	<div className="flex flex-wrap gap-2">
																		<TaskKanbanCard.Tags />
																	</div>
																	<TaskKanbanCard.ProjectLink />
																</div>
																<div className="flex flex-row gap-2 items-center">
																	<p className="text-placeholder">
																		{
																			task.completed_at
																		}
																	</p>
																	<TaskKanbanCard.Assignee />
																</div>
															</div>
														</TaskKanbanCard>
													)
												)}
											</div>
										);
									} else {
										// If no tasks for the day, return null (or you can omit this block to skip rendering)
										return null;
									}
								})}
								{tasks.length > 0 &&
									pagination.page < pagination.last_page && (
										<LoadMore
											onClick={() =>
												actions.list({
													page: pagination.page + 1,
												})
											}
											loading={status !== "idle"}
										/>
									)}
							</>
						)}
					</>
				)}
			</div>
		</Layout>
	);
}

OverviewCompletedTasks.locale = {
	nl: {
		title: "Afgeronde taken",
		"completed-assignments": "Afgeronde opdrachten",
		"best-day": "Beste dag",
		"no-results": "Geen afgeronde taken deze week",
		breadcrumbs: {
			tasks: "Taken",
			"my-tasks": "Mijn taken",
			"completed-tasks": "Afgeronde taken",
		},
	},
	en: {
		title: "Completed tasks",
		"completed-assignments": "Completed assignments",
		"best-day": "Best day",
		"no-results": "No completed tasks this week",
		breadcrumbs: {
			tasks: "Tasks",
			"my-tasks": "My tasks",
			"completed-tasks": "Completed tasks",
		},
	},
};

const CompletedViewSkeleton = () => {
	return (
		<>
			<div className="flex flex-row gap-4 mt-4">
				<Skeleton className="w-[200px] h-[100px]"></Skeleton>
				<Skeleton className="w-[100px] h-[100px]"></Skeleton>
			</div>
			<div className="flex flex-col gap-2 mt-4">
				<Skeleton className="w-[100%] h-[80px]"></Skeleton>
				<Skeleton className="w-[100%] h-[80px]"></Skeleton>
				<Skeleton className="w-[100%] h-[80px]"></Skeleton>
				<Skeleton className="w-[100%] h-[80px]"></Skeleton>
				<Skeleton className="w-[100%] h-[80px]"></Skeleton>
				<Skeleton className="w-[100%] h-[80px]"></Skeleton>
			</div>
		</>
	);
};

export default withTranslation(OverviewCompletedTasks);
