import { Formik } from "formik";
import moment from "moment";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import Button from "src/components/Button";
import Input from "src/components/form/Input";
import Switch from "src/components/form/Switch";
import withTranslation, {
	Translation,
} from "src/components/hoc/withTranslation";
import useEducations from "src/hooks/api/services/resume/useEducations";
import useAlert from "src/hooks/useAlert";
import useModal from "src/hooks/useModal";
import * as Yup from "yup";
import EducationLevelDropdown from "./EducationLevelDropdown";

export enum EnumLevelType {
	"BASIS",
	"VMBO",
	"MBO2",
	"HAVO",
	"HBO_START",
	"HBO_SENIOR",
	"UNIVERSITAIR_EXPERT",
	"UNIVERSITAIR_SENIOR",
}

function EducationFormModal({ t }: Translation) {
	const { education, actions, status } = useEducations();
	const { id } = useParams() as any;
	const [sendAlert] = useAlert();
	const modal = useModal({
		title: t("title"),
	});

	useEffect(() => {
		if (id && !education.id) {
			actions.get(id);
		}
		return () => {
			actions.setItem(
				{
					data: {},
				},
				{
					updateList: false,
				}
			);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [id]);

	const options = [
		{ name: t("options.basis"), id: "0" },
		{ name: t("options.vmbo"), id: "1" },
		{ name: t("options.mbo2"), id: "2" },
		{ name: t("options.havo"), id: "3" },
		{ name: t("options.hbo_start"), id: "4" },
		{ name: t("options.hbo_senior"), id: "5" },
		{ name: t("options.universitair_expert"), id: "6" },
		{ name: t("options.universitair_senior"), id: "7" },
	];

	const scheme = {
		level: Yup.string().required(t("required")),
		study: Yup.string().required(t("required")),
		school: Yup.string().required(t("required")),
		current: Yup.boolean(),
		startYear: Yup.number()
			.required(t("required"))
			.max(moment().year(), t("input.date.year-future"))
			.test(
				"len",
				t("input.date.type-year"),
				(val?: any) =>
					val === undefined || (val && val.toString().length === 4)
			),
		endYear: Yup.number().when("current", {
			is: false,
			then: Yup.number()
				.required(t("required"))
				.test(
					"len",
					t("input.date.type-year"),
					(val?: any) =>
						val === undefined ||
						(val && val.toString().length === 4)
				)
				.max(moment().year(), t("input.date.year-future"))
				.when(
					"startYear",
					(started: any, schema: any) =>
						started &&
						schema.min(started, t("end-date-before-start-date"))
				),
		}),
	} as {
		[key: string]: any;
	};

	const initialValues = {
		level: education.level ? EnumLevelType[education.level] : "",
		study: education.title ? education.title : "",
		school: education.subtitle ? education.subtitle : "",
		current: !education.ended && education.started ? true : false,
		startYear: education.started ? education.started : "",
		endYear: education.ended ? education.ended : "",
	};

	const handleDelete = () => {
		if (!education.id || status !== "idle") return;
		sendAlert({
			type: "confirm",
			title: t("delete.title"),
			text: t("delete.text"),
			onConfirm: () => {
				actions.delete(education.id).then(() => {
					modal.onClose();
				});
			},
		});
	};

	return (
		<Formik
			{...{ initialValues }}
			validateOnChange
			validateOnBlur
			enableReinitialize
			validateOnMount
			validationSchema={Yup.object().shape(scheme)}
			onSubmit={(values: any, { setSubmitting }) => {
				const form = {
					level: EnumLevelType[values.level],
					title: values.study,
					subtitle: values.school,
					started: values.startYear,
					ended: values.endYear,
				};

				if (education.id) {
					actions
						.update(
							{
								id: education.id,
								...form,
							},
							{
								updateList: true,
							}
						)
						.then((data: any) => {
							setSubmitting(false);
							modal.onClose();
						});
				} else {
					actions.create(form).then(() => {
						setSubmitting(false);
						actions.list({
							page: 1,
						});
						modal.onClose();
					});
				}
			}}
		>
			{({
				values,
				errors,
				touched,
				handleChange,
				handleSubmit,
				handleBlur,
				isSubmitting,
				isValid,
			}) => (
				<form className="flex w-full flex-col gap-4">
					<Input
						noMarginBottom
						autoFocus
						label={t("input.study.label")}
						placeholder={t("input.study.label")}
						name="study"
						value={values.study}
						valid={values.study && !errors.study}
						error={errors.study && touched.study && errors.study}
						{...{ handleChange, handleBlur }}
					/>
					<EducationLevelDropdown
						label={t("dropdown.level")}
						placeholder={t("dropdown.level")}
						options={options}
						value={values.level?.toString()}
						// valid={values.level}
						error={
							errors.level &&
							touched.level &&
							errors.level.toString()
						}
						onChange={(value) => {
							handleChange({
								target: {
									name: "level",
									value,
								},
							});
						}}
					/>
					<Input
						noMarginBottom
						label={t("input.school.label")}
						placeholder={t("input.school.label")}
						name="school"
						value={values.school}
						valid={values.school && !errors.school}
						error={errors.school && touched.school && errors.school}
						{...{ handleChange, handleBlur }}
					/>
					<Switch
						changeColor
						value={values.current}
						label={t("switch.label")}
						onChange={(value) => {
							handleChange({
								target: {
									name: "current",
									value,
								},
							});
						}}
					/>
					<Input
						noMarginBottom
						type="number"
						label={t("input.date.start")}
						placeholder={t("input.date.placeholder")}
						name="startYear"
						value={values.startYear}
						valid={values.startYear && !errors.startYear}
						error={
							errors.startYear &&
							touched.startYear &&
							errors.startYear
						}
						{...{ handleChange, handleBlur }}
					/>
					<div className={`${values.current ? "hidden" : ""}`}>
						<Input
							noMarginBottom
							type="number"
							label={t("input.date.end")}
							placeholder={t("input.date.placeholder")}
							name="endYear"
							value={values.endYear}
							valid={values.endYear && !errors.endYear}
							error={
								errors.endYear &&
								touched.endYear &&
								errors.endYear
							}
							{...{ handleChange, handleBlur }}
						/>
					</div>
					<div className="flex flex-col gap-4 mt-4">
						<Button
							disabled={!isValid}
							loading={
								isSubmitting ||
								status === "deleting" ||
								status === "loading"
							}
							onClick={handleSubmit}
							className="w-full"
							type="dark"
						>
							{id ? t("edit") : t("add")}
						</Button>
						{id && (
							<Button
								disabled={status === "loading"}
								loading={
									status === "loading" ||
									status === "deleting"
								}
								onClick={handleDelete}
								className="w-full"
								type="red"
							>
								{t("delete.button")}
							</Button>
						)}
					</div>
				</form>
			)}
		</Formik>
	);
}

EducationFormModal.locale = {
	nl: {
		title: "Opleidingen toevoegen",
		add: "Toevoegen",
		delete: {
			button: "Verwijderen",
			title: "Weet je het zeker?",
			text: "Weet je zeker dat je deze opleiding wilt verwijderen",
		},
		edit: "Opslaan",
		dropdown: {
			level: "Niveau",
		},
		input: {
			study: {
				label: "Studierichting",
			},
			school: {
				label: "School / instituut",
			},
			date: {
				"year-future": "Het jaartal ligt in de toekomst",
				"type-year": "Moet een jaar zijn",
				start: "Start jaar",
				end: "Eind jaar",
				placeholder: "yyyy",
			},
		},
		switch: {
			label: "Deze opleiding volg ik momenteel",
		},
		required: "Verplicht",
		"end-date-before-start-date": "Eind datum is voor start datum",
		options: {
			basis: "Wereldklasse Werker (BASIS/ VMBO)",
			vmbo: "Praktische Uitvoerder (VMBO)",
			mbo2: "Helpende Held (MBO2-4)",
			havo: "Slimme Doener (HAVO/ MBO4/ Start HBO)",
			hbo_start: "Trained professional (HBO/ Start Universitair)",
			hbo_senior: "Leading professional (HBO senior/ Universitair)",
			universitair_expert: "Expert management (Universitair)",
			universitair_senior: "Top management (Senior Universitair)",
		},
	},
	en: {
		title: "Add educations",
		add: "Add",
		delete: {
			button: "Delete",
			title: "Are you sure?",
			text: "Are you sure that you want to delete this education",
		},
		edit: "Save",
		dropdown: {
			level: "Level",
		},
		input: {
			study: {
				label: "Field of study",
			},
			school: {
				label: "School/institute",
			},
			date: {
				"year-future": "Year is in the future",
				"type-year": "Must be a year",
				start: "Start year",
				end: "Eind year",
				placeholder: "yyyy",
			},
		},
		switch: {
			label: "I am currently taking this education",
		},
		required: "Required",
		"end-date-before-start-date": "End date is before start date",
		options: {
			basis: "Worldclass Worker (Primary)",
			vmbo: "Practical practitioner (Primary/Practical)",
			mbo2: "Helping Hero (Practical)",
			havo: "Clever Craftsman (Practical/Applied Science)",
			hbo_start: "Trained professional (Applied science/ Start Academic)",
			hbo_senior:
				"Leading professional (Applied science senior/ Academic)",
			universitair_expert: "Expert management (Academic)",
			universitair_senior: "Top management (Senior Academic)",
		},
	},
};

export default withTranslation(EducationFormModal);
