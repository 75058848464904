import { useEffect } from "react";
import { ApiTaskKanbanStatus } from "src/api/types";
import useTenant from "src/hooks/api/services/tenants/useTenant";
import useTasksKanbanUpdater from "src/hooks/api/tasks/useTasksKanbanUpdater";
import { useTasksUpdater } from "src/hooks/api/tasks/useTasksUpdater";
import useAuth from "src/hooks/selectors/useAuth";
import socket, { listen, decrypt } from "src/lib/socket";

export type TaskSocketEvent = {
	type: "DELETE" | "UPDATE" | "CREATE";
	id: number;
	status?: ApiTaskKanbanStatus;
	data?: any;
};

export default function useTasksListeners() {
	const auth = useAuth();
	const tasks = useTasksUpdater();
	const kanban = useTasksKanbanUpdater();
	const { tenant } = useTenant();
	const urls = {
		status: `tasks.${auth?.links?.client}.status`,
		update: `tasks.${auth?.links?.client}.update`,
	};

	const decryptString = (encryptedData: any) => {
		return decrypt(encryptedData, auth.key);
	};

	const decryptEvent = async (event: TaskSocketEvent) => {
		const data = event?.data;
		if (typeof data === "string") {
			const decrypted = await decryptString(data);
			if (typeof decrypted === "object") {
				event.data = decrypted;
			}
		}
		return event;
	};

	const onStatus = async (event: any) => {
		// console.log("STATUS", event);
		event = await decryptEvent(event);
		tasks.handleStatus(event);
		kanban.handleStatus(event);
	};

	const onUpdate = async (event: TaskSocketEvent) => {
		// console.log("UPDATE", event);
		event = await decryptEvent(event);
		tasks.handleEvent(event);
		kanban.handleEvent(event);
	};

	useEffect(() => {
		if (tenant.modules.includes("intern-task")) {
			listen(urls.status, onStatus);
			listen(urls.update, onUpdate);
		}
		return () => {
			socket.off(urls.status, onStatus);
			socket.off(urls.update, onUpdate);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
}
