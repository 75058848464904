import { useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import Button from "src/components/Button";
import useEditOffer from "src/hooks/api/useEditOffer";
import useAlert from "src/hooks/useAlert";
import useWindowSize from "src/hooks/useWindowSize";
import { useTranslation } from "react-i18next";
import useOfferScheme from "src/hooks/schemes/useOfferScheme";

interface OfferEditFormProps {
	id: number;
}

const OfferEditSaveButton = ({ id }: OfferEditFormProps) => {
	const { t } = useTranslation(["offer", "buttons"]);
	const { offer, handleUpdate, updating } = useEditOffer(id);
	const [sendAlert] = useAlert();
	const { isPhone } = useWindowSize();
	const scheme = useOfferScheme();
	const values = {
		title: offer?.title,
		description_intro:
			offer?.description_intro || offer?.description?.intro,
		description_what: offer?.description_what || offer?.description?.what,
		description_how: offer?.description_how || offer?.description?.how,
		category_id: offer?.category_id || offer?.category?.id,
	};
	const formik = useFormik({
		initialValues: values,
		validationSchema: Yup.object().shape({
			title: scheme.title,
			description_intro: scheme.description.intro,
			description_what: scheme.description.what,
			description_how: scheme.description.how,
			category_id: scheme.category_id,
		}),
		onSubmit: (values: any, { setSubmitting }) => {
			sendAlert({
				type: "confirm",
				title: t("confirm.title", {
					ns: "offer",
					keyPrefix: "offer-edit-save-button",
				}),
				text: t("confirm.description", {
					ns: "offer",
					keyPrefix: "offer-edit-save-button",
				}),
				onConfirm: () => {
					setSubmitting(false);
					handleUpdate();
				},
			});
		},
		validateOnChange: true,
	});
	const { isSubmitting, isValid, setValues, handleSubmit } = formik;

	useEffect(() => {
		setValues(values);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [offer]);

	if (isPhone) {
		return (
			<div className="fixed z-20 left-0 right-0 bottom-0 bg-background p-4 flex">
				<Button
					className="flex-1"
					loading={updating}
					onClick={handleSubmit}
					type="primary"
					disabled={!isValid || isSubmitting}
				>
					{t("save", { ns: "buttons" })}
				</Button>
			</div>
		);
	}

	return (
		<Button
			loading={updating}
			onClick={handleSubmit}
			type="primary"
			disabled={!isValid || isSubmitting}
		>
			{t("save", { ns: "buttons" })}
		</Button>
	);
};

export default OfferEditSaveButton;
