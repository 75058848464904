import { ComponentProps, useEffect } from "react";
import Loader from "src/components/Loader";
import withTranslation, {
	Translation,
} from "src/components/hoc/withTranslation";
import useAuthSession from "src/hooks/api/services/session/useAuthSession";
import { cn } from "src/lib/utils";

interface AuthSessionProcessingLabelProps
	extends ComponentProps<"div">,
		Translation {}

const AuthSessionProcessingLabel = ({
	t,
	children,
	className,
	...rest
}: AuthSessionProcessingLabelProps) => {
	const { session, actions } = useAuthSession();

	useEffect(() => {
		const interval = setInterval(() => {
			if (!session?.id || session.status === "COMPLETED") {
				actions.deleteCookie();

				clearInterval(interval);
				return;
			}
			actions.find(session.id);
		}, 1000);
		return () => {
			clearInterval(interval);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	if (!session?.id) return null;

	return (
		<div
			{...rest}
			className={cn(
				"flex gap-1 items-center px-4 text-sm",
				session?.status === "COMPLETED" && "text-green",
				className
			)}
		>
			{session?.status !== "COMPLETED" ? (
				<Loader />
			) : (
				<i className="far fa-check"></i>
			)}
			<p>
				{t(session?.status !== "COMPLETED" ? "pending" : "completed")}
			</p>
		</div>
	);
};

AuthSessionProcessingLabel.locale = {
	nl: {
		pending: "Account aan het verwerken",
		completed: "Je account is verwerkt",
	},
	en: {
		pending: "Processing account",
		completed: "Your account is processed",
	},
};

export default withTranslation(AuthSessionProcessingLabel);
