import { useEffect } from "react";
import Button from "src/components/Button";
import withTranslation, {
	Translation,
} from "src/components/hoc/withTranslation";
import TeamList from "src/components/team/TeamList";
import useTeams from "src/hooks/api/services/users/useTeams";
import useWindowSize from "src/hooks/useWindowSize";
import ProfileSettingsLayout from "src/components/layout/profile-settings-layout/ProfileSettingsLayout";

const TeamsView = ({ t }: Translation) => {
	const { isPhone } = useWindowSize();
	const { actions } = useTeams();

	useEffect(() => {
		actions.list();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<ProfileSettingsLayout title={t("title")}>
			<div className="flex justify-between">
				<div className=""></div>
				<div className="flex items-center gap-4">
					<Button
						type="dark"
						to={{
							modal: "/profile/teams/create",
						}}
						iconOnly={isPhone}
					>
						<i className="fas fa-users-medical md:mr-2"></i>
						{!isPhone && <span>{t("buttons.create-team")}</span>}
					</Button>
				</div>
			</div>
			<div
				className={`flex flex-col gap-4 flex-1 overflow-auto hide-default-scrollbar md:mx-0 md:px-0 -mx-4 px-4`}
			>
				<TeamList />
			</div>
		</ProfileSettingsLayout>
	);
};

TeamsView.locale = {
	nl: {
		title: "Teams",
		buttons: {
			"create-team": "Team aanmaken",
		},
	},
	en: {
		title: "Teams",
		buttons: {
			"create-team": "Create team",
		},
	},
};

export default withTranslation(TeamsView);
