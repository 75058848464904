import React from "react";
import { ApiBadgeTypes } from "src/api/types";

interface ProfileBadgeElementProps {
	type: ApiBadgeTypes;
	alt?: string;
	className?: string;
}

const ProfileBadgeElement = ({
	type,
	alt,
	className,
}: ProfileBadgeElementProps) => {
	return (
		<img
			className={`${className || ""}`}
			src={`/assets/images/badges/${type}.png`}
			{...{ alt }}
		/>
	);
};

export default ProfileBadgeElement;
