import { BubbleMenu, FloatingMenu } from "@tiptap/react";
import { useEditorProps } from "src/components/field/fields/Editor/Editor";
import HeaderItem from "src/components/field/fields/Editor/HeaderItem";
import ImageHeadItem from "src/components/field/fields/Editor/items/ImageHeadItem";
import useTenant from "src/hooks/api/services/tenants/useTenant";
import { cn } from "src/lib/utils";

interface HeaderProps {
	variant?: "bordered" | "floating" | "bubble";
	className?: string;
}

const Header = ({ className, variant }: HeaderProps) => {
	const { tenant } = useTenant();
	const { editor } = useEditorProps();
	const elements: any = {
		floating: FloatingMenu,
		bubble: BubbleMenu,
		default: "div",
		bordered: "div",
	};
	const Element = elements[variant || "default"];

	return (
		<Element
			tippyOptions={
				variant === "floating"
					? {
							offset: [0, 32],
					  }
					: {}
			}
			editor={editor}
			className={cn(
				"flex items-center border-y-[1px] border-border p-1 gap-1",
				variant === "floating" &&
					"bg-background dark:bg-background rounded-md border-0 border-transparent shadow dark:shadow-none",
				variant === "bubble" &&
					"bg-background dark:bg-accent dark:border dark:border-border rounded-md border-0 border-transparent shadow",
				className
			)}
		>
			<HeaderItem
				{...{ variant }}
				active={editor.isActive("heading", {
					level: 2,
				})}
				icon="fas fa-h1"
				onChange={() => {
					editor.chain().toggleHeading({ level: 2 }).focus().run();
				}}
			/>
			<HeaderItem
				{...{ variant }}
				active={editor.isActive("heading", {
					level: 3,
				})}
				icon="fas fa-h2"
				onChange={() => {
					editor.chain().toggleHeading({ level: 3 }).focus().run();
				}}
			/>
			<HeaderItem
				{...{ variant }}
				icon="fas fa-text"
				onChange={() => {
					let chain = editor.chain().focus().setParagraph();
					if (editor.isActive("bold")) {
						chain = chain.toggleBold();
					}

					if (editor.isActive("bulletList")) {
						chain = chain.toggleBulletList();
					}

					if (editor.isActive("orderedList")) {
						chain = chain.toggleOrderedList();
					}

					chain.run();
				}}
			/>
			<HeaderItem
				{...{ variant }}
				disabled={!editor.can().chain().focus().toggleBold().run()}
				active={editor.isActive("bold")}
				icon="fas fa-bold"
				onChange={() => editor.chain().focus().toggleBold().run()}
			/>
			<HeaderItem
				{...{ variant }}
				disabled={!editor.can().chain().focus().toggleUnderline().run()}
				active={editor.isActive("underline")}
				icon="fas fa-underline"
				onChange={() => editor.chain().focus().toggleUnderline().run()}
			/>
			<HeaderItem
				{...{ variant }}
				// disabled={!editor.can().chain().focus().toggleUnderline().run()}
				// active={editor.isActive("underline")}
				icon="fas fa-horizontal-rule"
				onChange={() =>
					editor
						.chain()
						.focus()
						.setHorizontalRule()
						.selectNodeForward()
						.focus()
						.run()
				}
			/>
			<Divider />
			<HeaderItem
				{...{ variant }}
				disabled={
					!editor.can().chain().focus().toggleBulletList().run()
				}
				active={editor.isActive("bulletList")}
				icon="fas fa-list"
				onChange={() => editor.chain().focus().toggleBulletList().run()}
			/>
			<HeaderItem
				{...{ variant }}
				disabled={
					!editor.can().chain().focus().toggleOrderedList().run()
				}
				active={editor.isActive("orderedList")}
				icon="fas fa-list-ol"
				onChange={() =>
					editor.chain().focus().toggleOrderedList().run()
				}
			/>
			<HeaderItem
				{...{ variant }}
				disabled={!editor.can().chain().focus().toggleTaskList().run()}
				// disabled={!editor.can().chain().focus().commands({}).run()}
				active={editor.isActive("taskList")}
				icon="fas fa-tasks"
				onChange={() => editor.chain().focus().toggleTaskList().run()}
			/>
			{tenant?.slug === "getjobsdone" && variant !== "bubble" && (
				<>
					<Divider />
					<ImageHeadItem />
				</>
			)}
			{["bubble", "floating"].includes(variant as any) && (
				<>
					<div className="flex-1"></div>
					<HeaderItem
						{...{ variant }}
						disabled={!editor.can().chain().focus().undo().run()}
						icon="fas fa-undo"
						onChange={() => editor.chain().focus().undo().run()}
					/>
					<HeaderItem
						{...{ variant }}
						disabled={!editor.can().chain().focus().redo().run()}
						icon="fas fa-redo"
						onChange={() => editor.chain().focus().redo().run()}
					/>
				</>
			)}
		</Element>
	);
};

const Divider = () => {
	return <div className="w-[1px] h-8 bg-accent block"></div>;
};

export default Header;
