import React from "react";
import Card from "src/components/Card";
import Skeleton from "src/components/Skeleton";

interface SettingCardProps {
	active?: boolean;
	isLoading?: boolean;
	padding?: number;
	maxWidth?: any;
	to?: string;
}

const SettingCard: React.FC<
	SettingCardProps & React.HTMLProps<HTMLDivElement>
> = ({ children, active, isLoading, padding, maxWidth, ...rest }) => {
	return (
		<Card
			{...rest}
			className={`min-h-[160px] max-w-${
				maxWidth || "[340px]"
			} md:min-w-[300px] hover:border-primary transition-all ${
				active ? "border-4 border-primary" : ""
			} bg-dark-1100 flex-1 flex flex-col rounded-md relative ${
				rest.className || ""
			}`}
		>
			{active && (
				<div className="absolute -right-4 -top-4 w-8 h-8 flex justify-center items-center rounded-full bg-primary text-white">
					{isLoading ? (
						<span className="base-loader"></span>
					) : (
						<i className="fas fa-check leading-0 h-5 border-2 border-primary text-background-foreground-1100"></i>
					)}
				</div>
			)}
			<div
				className={`flex-1 relative flex flex-col overflow-hidden text-background-foreground`}
			>
				{children}
			</div>
		</Card>
	);
};

export const SettingCardLoading: React.FC<
	SettingCardProps & React.HTMLProps<HTMLDivElement>
> = ({ children, ...rest }) => {
	return (
		<Skeleton
			{...rest}
			className={`min-h-[160px] max-w-[340px] md:min-w-[300px] hover:border-primary transition-all border-4 border-dark-1100 bg-background flex-1 flex flex-col rounded-md relative ${
				rest.className || ""
			}`}
		></Skeleton>
	);
};

export default SettingCard;
