import { useState } from "react";
import Confetti from "react-dom-confetti";
import Loader from "src/components/Loader";

interface LikeButtonProps {
	isLiked: boolean;
	count?: number;
	onChange: () => void;
	loading?: boolean;
}

const LikeButton = ({ isLiked, count, loading, onChange }: LikeButtonProps) => {
	const [showConfetti, setShowConfetti] = useState(false);
	const hasCount = !!count;
	return (
		<div
			onClick={(e) => {
				if (!loading) {
					e.stopPropagation();
					e.preventDefault();
					onChange();
					setShowConfetti(!isLiked);
				}
			}}
			className={`relative cursor-pointer border  rounded-md px-3 py-1 transition-all ${
				isLiked
					? "text-primary border-primary"
					: "text-placeholder border-placeholder"
			}`}
		>
			<Confetti
				config={{
					colors: [
						"#1aaa9d",
						"#ffffff",
						"#4BB543",
						"#f88c5e",
						"#f9b739",
					],
				}}
				active={showConfetti}
			/>

			<div className="flex items-center gap-2">
				{loading ? (
					<Loader />
				) : (
					<i
						className={`${isLiked ? "fas" : "far"} fa-thumbs-up`}
					></i>
				)}
				{hasCount ? <strong>{count}</strong> : <span>Like</span>}
			</div>
		</div>
	);
};

export default LikeButton;
