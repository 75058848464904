import { useState } from "react";
import Button from "src/components/Button";
import withTranslation, {
	Translation,
} from "src/components/hoc/withTranslation";
import useAlert from "src/hooks/api/services/useAlert";

interface AlertActionsProps extends Translation {
	children?: any;
}

const AlertActions = ({ t }: AlertActionsProps) => {
	const [isReadingAll, setIsReadingAll] = useState(false);
	const { actions } = useAlert();

	const handleReadAll = () => {
		if (isReadingAll) return;
		setIsReadingAll(true);
		actions
			.readAll()
			.then(() => {})
			.finally(() => setIsReadingAll(false));
	};

	return (
		<div className="flex items-center gap-4 justify-between p-6">
			<div></div>
			<Button
				loading={isReadingAll}
				disabled={isReadingAll}
				onClick={handleReadAll}
				small
				type="dark"
			>
				{t("mark-as-read")}
			</Button>
		</div>
	);
};

AlertActions.locale = {
	nl: {
		"mark-as-read": "Markeren als gelezen",
	},
	en: {
		"mark-as-read": "Mark all as read",
	},
};

export default withTranslation(AlertActions);
