export default function usePercentageOfTimeInDay(date: Date) {
	const startOfDay = new Date(
		date.getFullYear(),
		date.getMonth(),
		date.getDate()
	);
	const endOfDay = new Date(
		date.getFullYear(),
		date.getMonth(),
		date.getDate() + 1
	);
	const startOfDayMs = startOfDay.getTime();
	const endOfDayMs = endOfDay.getTime();
	const dateMs = new Date(date).getTime();
	const percentage = (dateMs - startOfDayMs) / (endOfDayMs - startOfDayMs);
	return percentage;
}
