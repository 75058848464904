import withTranslation, {
	Translation,
} from "src/components/hoc/withTranslation";
import AuthLayout, {
	AuthLayoutContainer,
} from "src/components/layout/auth-layout/AuthLayout";
import SubscriptionPlans from "src/components/subscription/SubscriptionPlans";
import useTenant from "src/hooks/api/services/tenants/useTenant";

function SignupCompanyView({ t }: Translation) {
	const { tenant } = useTenant();
	return (
		<AuthLayout variant="flat" actions={["contact-sales"]}>
			<AuthLayoutContainer
				className={
					tenant.slug === "getrecruitmentdone" ? "md:pt-6 gap-4" : ""
				}
			>
				{tenant.slug === "getrecruitmentdone" && (
					<div className="flex flex-col gap-6 max-w-md mx-auto text-center">
						<p
							className="text-xl"
							dangerouslySetInnerHTML={{ __html: t("tagline") }}
						></p>
					</div>
				)}

				<SubscriptionPlans
					getPlanCheckoutUrl={(plan, interval) =>
						`/signup/company/subscription/${plan.slug}?interval=${interval}`
					}
				/>

				{tenant.slug === "getrecruitmentdone" && (
					<div className="flex flex-col gap-6 max-w-md mx-auto mt-8">
						<h2>{t("usps.title")}</h2>
						<ul className="flex flex-col gap-4">
							{[
								"access",
								"efficience",
								"quality",
								"transparent",
								"support",
							].map((name) => (
								<li
									key={name}
									className="flex items-center gap-3"
								>
									<i className="far fa-check text-primary text-2xl"></i>
									<span
										className="flex-1"
										dangerouslySetInnerHTML={{
											__html: t(`usps.${name}`),
										}}
									></span>
								</li>
							))}
						</ul>
					</div>
				)}
			</AuthLayoutContainer>
		</AuthLayout>
	);
}

SignupCompanyView.locale = {
	nl: {
		tagline:
			"{{tenant.name}} biedt een platform dat het wervingsproces vereenvoudigt door vacatures te matchen met een netwerk van <strong>{{global.students}}</strong> young professionals op basis van hun vaardigheden en eerdere ervaringen.",
		usps: {
			title: "Wat maakt {{tenant.name}} uniek?",
			access: "<strong>Toegang tot een groot netwerk</strong>: Bereik direct een pool van {{global.students}} young professionals met diverse vaardigheden.",
			efficience:
				"<strong>Efficiëntie</strong>: Publiceer moeiteloos vacatures en ontvang snel geschikte kandidaten, waardoor het wervingsproces wordt versneld.",
			quality:
				"<strong>Kwaliteit van matches</strong>: Dankzij uitgebreide profielen en een unieke matchscore vind je de perfecte kandidaat voor jouw organisatie.",
			transparent:
				"<strong>Transparante kosten</strong>: Eenmalig vast bedrag per vacature zonder verborgen kosten, zodat je precies weet waar je aan toe bent.",
			support:
				"<strong>Ondersteuning</strong>: Professionele begeleiding gedurende het hele wervingsproces om de beste resultaten te garanderen.",
		},
	},
	en: {
		tagline:
			"{{tenant.name}} provides a platform that simplifies the recruitment process by matching vacancies with a network of <strong>{{global.students}}</strong> young professionals based on their skills and previous experience.",
		usps: {
			title: "What makes {{tenant.name}} unique?",
			access: "<strong>Access to a large network</strong>: Directly reach a pool of {{global.students}} young professionals with diverse skills.",
			efficience:
				"<strong>Efficiency</strong>: Easily publish vacancies and receive qualified candidates, speeding up the recruitment process.",
			quality:
				"<strong>High-quality matches</strong>: Benefit from detailed profiles and a unique match score to find the perfect candidate for your organization.",
			transparent:
				"<strong>Transparent pricing</strong>: A fixed fee per vacancy with no hidden costs, ensuring you know exactly what you're paying for.",
			support:
				"<strong>Support</strong>: Professional guidance throughout the recruitment process to ensure the best results.",
		},
	},
};

export default withTranslation(SignupCompanyView);
