import React from "react";
import { ApiOffer } from "src/api/types";
import Card from "src/components/Card";
import Skeleton from "src/components/Skeleton";
import { kFormatter } from "src/lib/formatters";

interface OfferStatisticsCardProps {
	offer: ApiOffer;
	variant?: "minimal";
}

const OfferStatisticsCard = ({ offer, variant }: OfferStatisticsCardProps) => {
	const renderItem = (icon: string, value: any) => (
		<h2
			className={`${
				variant !== "minimal" ? "text-[20px]" : "text-[16px]"
			} items-center flex gap-3`}
		>
			<i className={`fas fa-${icon}`}></i>
			<span className="">{kFormatter(value || 0, "default")}</span>
		</h2>
	);

	if (variant === "minimal") {
		return (
			<div className="flex gap-4 items-center">
				{renderItem("eye", offer?.statistics?.views)}
				{renderItem("reply", offer?.statistics?.replies)}
				{renderItem("check", offer?.statistics?.completed)}
			</div>
		);
	}

	return (
		<Card className="flex-1 flex flex-col justify-center">
			<div className="flex items-center justify-between">
				{renderItem("eye", offer?.statistics?.views)}
				{renderItem("reply", offer?.statistics?.replies)}
				{renderItem("check", offer?.statistics?.completed)}
			</div>
		</Card>
	);
};

const Loading = () => (
	<Card className="flex flex-col justify-center flex-1">
		<div className="flex items-center flex-1 gap-3 justify-between">
			{new Array(3).fill("").map((_, index) => (
				<div key={`state-${index}`} className="flex gap-3 items-center">
					<Skeleton className="w-8 h-8" />
					<Skeleton className="w-16 h-8" />
				</div>
			))}
		</div>
	</Card>
);

OfferStatisticsCard.Loading = Loading;

export default OfferStatisticsCard;
