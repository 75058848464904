import { createContext, useContext } from "react";

export type ValueVariant = "default" | "price" | "k" | "hours";

export type TotalCardContextProps = {
	label?: string;
	value: number | { current: number; previous: number };
	loading?: boolean;
};

export const TotalCardContext = createContext<TotalCardContextProps>(
	{} as TotalCardContextProps
);

export function useTotalCard() {
	const props = useContext(TotalCardContext);
	return props;
}
