import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import Card from "src/components/Card";
import ExternTaskMiddleware from "src/components/middlewares/ExternTaskMiddleware";
import TaskForm from "src/components/tasks/TaskForm";
import TaskFormWrapper from "src/components/tasks/TaskForm/TaskFormWrapper";
import TaskDetailWrapper from "src/components/tasks/detail/TaskDetailWrapper";
import useTaskForm from "src/hooks/api/tasks/useTaskForm";
import useTaskFormSteps from "src/hooks/api/tasks/useTaskFormSteps";
import useModules from "src/hooks/selectors/useModules";

export default function TaskPublishView() {
	const { modules } = useModules();
	const { t: translatedStep } = useTranslation("tasks", {
		keyPrefix: "steps",
	});
	const { task, status, actions } = useTaskForm();
	const { isFinal, currentStep } = useTaskFormSteps();
	const showMiddleware = false;

	useEffect(() => {
		actions.get();

		return () => {
			actions.clear();
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<TaskDetailWrapper
			{...{ task }}
			loading={status === "loading"}
			backUrl={`/tasks/${task?.hid}/editor`}
			options={{
				hideTabs: true,
				hideFields: true,
				hideSidebar: true,
			}}
			container="xxl"
		>
			{showMiddleware ? (
				<div className="container flex flex-col flex-1">
					<ExternTaskMiddleware />
				</div>
			) : (
				<>
					{task?.id && (
						<TaskFormWrapper>
							<Card contentClassName="gap-4">
								{currentStep ===
									translatedStep("planning.questions") && (
									<TaskForm.AIQuestions />
								)}

								{currentStep ===
									translatedStep("planning.extra") && (
									<TaskForm.Extra />
								)}

								{currentStep ===
									translatedStep("description") && (
									<TaskForm.Description />
								)}
								{currentStep === translatedStep("budget") && (
									<TaskForm.Budget
										amountLocked={
											task?.student_hid ? true : false
										}
									/>
								)}
								{currentStep === translatedStep("shifts") && (
									<TaskForm.Subtasks />
								)}
								{currentStep === translatedStep("location") && (
									<TaskForm.Address />
								)}
								{currentStep === translatedStep("date") && (
									<TaskForm.Target />
								)}
								{currentStep === translatedStep("invite") && (
									<TaskForm.StudentPool />
								)}
								{currentStep === translatedStep("form") && (
									<TaskForm.Interview />
								)}

								{isFinal &&
									!modules?.includes(
										"ai-description-creation"
									) && <TaskForm.Summary />}
								{isFinal &&
									modules?.includes(
										"ai-description-creation"
									) && <TaskForm.SummaryAI />}
							</Card>
						</TaskFormWrapper>
					)}
				</>
			)}
		</TaskDetailWrapper>
	);
}
