import axios from "axios";
import { ComponentProps, useState } from "react";
import Button from "src/components/Button";
import withTranslation, {
	Translation,
} from "src/components/hoc/withTranslation";
import useOnboarding from "src/hooks/api/services/auth/useOnboarding";
import useExperiences from "src/hooks/api/services/resume/useExperiences";
import useAuth from "src/hooks/selectors/useAuth";
import { cn } from "src/lib/utils";

interface CopyResumeProps extends ComponentProps<"div">, Translation {}

const CopyResume = ({ t, children, className, ...rest }: CopyResumeProps) => {
	const auth = useAuth();
	const { onboarding, actions } = useOnboarding();
	const { actions: experiencesActions } = useExperiences({
		type: "education",
	});
	const [isCopying, setIsCopying] = useState(false);

	const onSubmit = async (tenant: "getjobsdone" | "getcrewdone") => {
		setIsCopying(true);
		await axios.post(`/resume/${auth.hid}/copy`, {
			tenant,
		});
		await experiencesActions.list();
		setIsCopying(false);
		actions.setStep("education_experience");
	};
	return (
		<>
			<div
				className={cn(
					"flex flex-col gap-4 p-4 rounded-md border border-border",
					className
				)}
				{...rest}
			>
				{onboarding?.resumes?.getjobsdone && (
					<div className="flex items-center justify-between gap-2">
						<div className="flex flex-col">
							<p>GetJobsDone</p>
							<p className="opacity-70">
								{t("tenant.description")}
							</p>
						</div>
						<Button small onClick={() => onSubmit("getjobsdone")}>
							{t("copy")}
						</Button>
					</div>
				)}
			</div>
			<div className="flex justify-between items-center pb-4 pt-4 md:pt-8">
				<Button
					className="w-full"
					type="accent"
					disabled={isCopying}
					onClick={() => {
						actions.setStep("education_experience");
					}}
				>
					{t("skip")}
				</Button>
			</div>
		</>
	);
};

CopyResume.locale = {
	nl: {
		tenant: {
			description: "Kopieer je GetJobsDone profiel",
		},
		copy: "Kopieren",
		skip: "Overslaan",
	},
	en: {
		tenant: {
			description: "Copy your GetJobsDone profile",
		},
		copy: "Copy",
		skip: "Skip",
	},
};

export default withTranslation(CopyResume);
