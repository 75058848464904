import { ApiProjectMinimal } from "src/api/types";
import useApi from "src/hooks/api/utils/useApi";

function useProjectOptions() {
	const { state, actions, api, pagination } = useApi(
		{ id: "PROJECT_OPTIONS" },
		{
			baseUrl: `/client/projects`,
			appendListResult: true,
			query: {
				state: "my",
				variant: "minimal",
				limit: 32,
			},
		}
	);
	const projects: ApiProjectMinimal[] = state?.list || [];

	return {
		projects,
		status: state.status,
		pagination,
		actions: {
			...actions,
		},
		api,
	};
}

export default useProjectOptions;
