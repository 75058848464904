import { HTMLProps } from "react";
import { cn } from "src/lib/utils";

interface CardActionsProps extends HTMLProps<HTMLDivElement> {}

const CardActions = ({ children, className, ...rest }: CardActionsProps) => {
	return (
		<div {...rest} className={cn("flex items-center gap-2", className)}>
			{children}
		</div>
	);
};

export default CardActions;
