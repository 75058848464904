import Validator from "fastest-validator";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import {
	isE164Compliant,
	splitPhoneNumber,
} from "src/components/form/Input/phone/utils";
import { replaceAll } from "src/lib/replace";

export type Rule =
	| "string"
	| "required"
	| "email"
	| "password"
	| "telephone"
	| "number";

export interface SchemeProps {
	[key: string]: Rule[];
}

function useScheme(fields: SchemeProps) {
	const { t } = useTranslation("schemes");
	const v = new Validator({
		useNewCustomCheckerFunction: true, // using new version
		messages: {
			required: t("required") || "",
			email: t("email") || "",
			password: t("password") || "",
			telephone:
				t("telephone", {
					length: 9,
				}) || "",
			stringLength: t("length") || "",
			number: t("number") || "",
		},
	});
	const scheme = useMemo(() => {
		let scheme: any = {};
		for (let field of Object.keys(fields)) {
			scheme[field] = {};
			const rules = fields[field];

			const lengthRule = rules.find((rule) => rule.startsWith("length:"));

			if (lengthRule) {
				const [, length] = lengthRule.split(":");
				scheme[field].length = Number(length);
			}

			if (!rules.includes("required")) {
				scheme[field].optional = true;
			}
			if (rules.includes("email")) {
				scheme[field].type = "email";
			} else if (rules?.includes("password")) {
				scheme[field].type = "custom";
				scheme[field].check = function check(
					value: any,
					errors: any[]
				) {
					const regex = new RegExp(
						/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/
					);
					if (!value.match(regex)) {
						errors.push({
							type: "password",
						});
					}
					return value;
				};
			} else if (rules?.includes("telephone")) {
				scheme[field].type = "custom";
				scheme[field].check = (_value: any, errors: any[]) => {
					const value = _value;
					if (!isE164Compliant(value)) {
						errors.push({
							type: "telephone",
						});
						return value;
					}
					const splitted = splitPhoneNumber(value);
					const formatted: string = splitted?.formatted || "";

					if (
						!formatted ||
						replaceAll(formatted, " ", "").length !== 9
					) {
						errors.push({
							type: "telephone",
						});
					}
					return value;
				};
			} else if (rules.includes("string")) {
				scheme[field].type = "string";
			} else if (rules.includes("number")) {
				scheme[field].type = "number";
			}
		}
		return scheme;
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [fields]);
	const check = v.compile(scheme);

	const validate = (value: any) => check(value);

	return { scheme, validate };
}
export default useScheme;
