import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { ApiProfileMinimal, ApiUserMinimal } from "src/api/types";
import DropdownButton from "src/components/DropdownButton";
import useDelete from "src/hooks/rest/useDelete";
import useAlert from "src/hooks/useAlert";
import useBackUrl from "src/hooks/useBackUrl";
import useUserCardContext from "../UserCardContext";

interface UserCardActionsProps {
	onSubmit: (profile: ApiProfileMinimal | ApiUserMinimal) => Promise<void>;
}

const UserCardActions = ({ onSubmit }: UserCardActionsProps) => {
	const { user, setStatus } = useUserCardContext();
	const backUrl = useBackUrl();
	const [sendAlert] = useAlert();
	const { t } = useTranslation("network", {
		keyPrefix: "network-student-card",
	});
	const navigate = useNavigate();
	const iconButtonClassName = "w-[40px] h-[40px] text-[20px]";

	const [removeConnection, loading] = useDelete(`/connections/${user.id}`);

	const handleRemove = (onClose: () => void) => {
		onClose();
		const onConfirm = async () => {
			setStatus("deleting");
			await removeConnection();
			await onSubmit(user);
			setStatus("idle");
		};

		sendAlert({
			type: "confirm",
			title: t("confirm.title"),
			text: t("confirm.description"),
			onConfirm,
		});
	};

	return (
		<div className="flex items-center gap-3">
			<DropdownButton
				menu={{
					position: "bottom-right",
				}}
				button={{
					tabIndex: "-1",
					children: <i className="far fa-ellipsis-v " />,
					iconOnly: true,
					className: iconButtonClassName,
					type: "gray",
					small: true,
				}}
			>
				<DropdownButton.Item
					disabled={loading}
					icon="fas fa-user mr-2"
					onClick={() => {
						navigate(`/view-profile/${user.id}?${backUrl}`);
					}}
				>
					{t("view-profile")}
				</DropdownButton.Item>
				<DropdownButton.Item
					loading={loading}
					icon="fas fa-trash mr-2"
					onClick={handleRemove}
				>
					{t("delete-connection")}
				</DropdownButton.Item>
			</DropdownButton>
		</div>
	);
};

export default UserCardActions;
