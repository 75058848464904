import { useState } from "react";
import "./card.scss";
import { LayoutVariants } from "src/api/types";
import Link from "src/components/Link";
import Loader from "src/components/Loader";
import Progress from "src/components/Progress";
import { cn } from "src/lib/utils";
import CardHead from "src/components/Card/CardHead";
import CardSearch from "src/components/Card/CardSearch";
import CardLoadMore from "src/components/Card/CardLoadMore";
import CardActions from "src/components/Card/CardActions";

type CardProps = {
	type?: string;
	children?: any;
	className?: string;
	contentClassName?: string;
	activeClassName?: string;
	steps?: {
		total: number;
		current: number;
	};
	to?:
		| string
		| {
				modal: string;
		  };
	created_at?: string;
	title?: string;
	style?: any;
	closeable?: boolean;
	id?: string;
	onClick?: (e: any) => any;
	variant?: LayoutVariants;
	ref?: any;
	bg?: string | null;
	target?: string;
	actions?: any;
	loading?: boolean;
};

const Card = ({
	type,
	children,
	steps,
	className,
	to,
	title,
	style,
	closeable,
	contentClassName,
	activeClassName,
	id,
	onClick,
	ref,
	target,
	actions,
	loading,
}: CardProps) => {
	const [visible, setVisible] = useState(true);

	const _className = cn([
		"card rounded-md flex flex-col",
		"border border-transparent dark:border-border bg-card text-card-foreground shadow",
		type && `card_${type}`,
		steps && "card_has-steps",
		title && "card_has-title",
		closeable && "card_closeable",
		className,
	]);

	const renderChildren = () => {
		return (
			<>
				{steps?.total && <Progress {...steps} />}
				<div
					className={cn(
						"p-6 card-content flex flex-col gap-4 flex-1 rounded-md",
						contentClassName
					)}
				>
					{title && (
						<CardHead
							{...{ title }}
							className={cn(
								"flex-row items-center justify-between gap-4",
								visible && closeable && "mb-4"
							)}
						>
							{actions}
							{closeable && (
								<span
									className="cursor-pointer"
									onClick={() => setVisible(!visible)}
								>
									{visible ? (
										<i className="fas fa-angle-up"></i>
									) : (
										<i className="fas fa-angle-down"></i>
									)}
								</span>
							)}
						</CardHead>
					)}
					{loading ? (
						<div className="flex flex-1 flex-col items-center justify-center">
							<Loader />
						</div>
					) : (
						visible && children
					)}
				</div>
			</>
		);
	};

	if (to) {
		return (
			<Link
				{...{ to, ref, id, onClick, target, activeClassName }}
				className={_className}
			>
				{renderChildren()}
			</Link>
		);
	}

	return (
		<div {...{ style, ref, id, onClick }} className={_className}>
			{renderChildren()}
		</div>
	);
};

Card.Head = CardHead;
Card.Search = CardSearch;
Card.LoadMore = CardLoadMore;
Card.Actions = CardActions;

export default Card;
