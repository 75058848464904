import React, { useEffect } from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import withTranslation, {
	Translation,
} from "src/components/hoc/withTranslation";
import Layout from "src/components/layout/Layout";
import TimesheetProcessList from "src/components/lists/TimesheetProcessList";
import useTask from "src/hooks/api/tasks/useTask";

function HoursProcessView({ t }: Translation) {
	const location = useLocation();
	const navigate = useNavigate();
	const { id } = useParams() as any;
	const { task, actions, status } = useTask();

	useEffect(() => {
		if (!task?.id || task?.hid !== id) {
			actions.get(true, "variant=table&is_hourlist=1&include[]=parent");
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [location]);

	const onSubmit = (status: "success" | "error") => {
		navigate(`/hours/${id}/show/process/result`);
	};

	return (
		<Layout
			loading={status !== "idle"}
			title={t("title")}
			backUrl={`/hours/${id}/show`}
		>
			{task?.id && status === "idle" && (
				<TimesheetProcessList {...{ task, onSubmit }} />
			)}
		</Layout>
	);
}

HoursProcessView.locale = {
	nl: {
		title: "Uren verwerken",
	},
	en: {
		title: "Process hours",
	},
};

export default withTranslation(HoursProcessView);
