import React from "react";
import { ApiIcon } from "src/api/types";
import useElementSize from "src/hooks/useElementSize";
import { cn } from "src/lib/utils";

const DIVIDE = 0.45;

interface IconProps extends React.ComponentProps<"div"> {
	icon?: ApiIcon | JSX.Element;
	variant?: "active" | "nav-item";
	iconFontSize?: any;
}

const Icon = ({
	icon: _icon,
	variant,
	iconFontSize,
	className,
	...rest
}: IconProps) => {
	const { ref, size } = useElementSize();
	const icon: any = _icon;
	return (
		<div
			{...rest}
			{...{ ref }}
			className={cn(
				"w-8 aspect-square flex justify-center items-center rounded-md hover:bg-accent transition-colors",
				variant === "active" && "bg-accent border border-border shadow",
				rest?.onClick && "cursor-pointer",
				variant === "nav-item" && "w-7",
				className
			)}
			style={{
				fontSize: iconFontSize || size.width * DIVIDE,
			}}
		>
			{React.isValidElement(icon) ? (
				icon
			) : (
				<>
					{icon?.emoji && (
						<span role="img" aria-label={icon.emoji.description}>
							{icon.emoji.icon}
						</span>
					)}
					{icon?.font_awesome && (
						<i className={`${icon.font_awesome.name}`}></i>
					)}
				</>
			)}
		</div>
	);
};

Icon.getFontAwesome = (name: string, style: "fas" | "far" | "fal" = "fas") => ({
	font_awesome: {
		name: `${style} ${name}`,
	},
});

export default Icon;
