import axios from "axios";
import { ComponentProps, useEffect, useState } from "react";
import {
	Options,
	Skill,
	SkillSelectorContext,
	Theme,
} from "src/components/form/skill-selector/Provider";
import Description from "src/components/form/skill-selector/elements/Description";
import Input from "src/components/form/skill-selector/elements/Input";
import Loading from "src/components/form/skill-selector/elements/Loading";
import Results from "src/components/form/skill-selector/elements/Results";
import { useUpdateEffectDebounce } from "src/hooks/useDebounce";
import useUpdateEffect from "src/hooks/useUpdateEffect";
import { cn } from "src/lib/utils";

interface SkillSelectorProps {
	defaultSearch?: string;
	defaultSkills?: Skill[];
	value?: Skill[];
	onChange?: (value: Skill[]) => void;
	options?: Options;
	theme?: Theme;
	disabled?: boolean;
	filter?: {
		categories?: number[];
	};
	searchMode?: "manual" | "api";
}

const SkillSelector = ({
	defaultSearch,
	defaultSkills,
	value,
	onChange,
	className,
	children,
	options,
	theme,
	disabled,
	filter,
	searchMode = "api",
	...rest
}: SkillSelectorProps & Omit<ComponentProps<"div">, "onChange">) => {
	const [search, setSearch] = useState<string>("");
	const [skills, setSkills] = useState<Skill[]>(defaultSkills || []);
	const [status, setStatus] = useState<"idle" | "loading">("loading");

	const getSkills = async (s: string) => {
		const { data } = await axios
			.get(`/client/skills`, {
				params: {
					...(filter || {}),
					q: s,
				},
			})
			.then(({ data }) => data);

		setSkills(
			[...(value || []), ...data].filter(
				(item, index, self) =>
					index === self.findIndex((t) => t.slug === item.slug)
			)
		);
	};

	useUpdateEffectDebounce(search, 500, (search) => {
		if (search && searchMode === "api") {
			setStatus("loading");
			getSkills(search).finally(() => setStatus("idle"));
		}
	});

	useUpdateEffect(() => {
		setSkills(defaultSkills || []);
	}, [defaultSkills]);

	useEffect(() => {
		if (defaultSearch) {
			getSkills(defaultSearch).finally(() => setStatus("idle"));
		} else {
			setStatus("idle");
		}

		return () => {
			setSkills([]);
			setStatus("loading");
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [defaultSearch]);

	return (
		<SkillSelectorContext.Provider
			value={{
				value: value || [],
				onChange,
				search,
				onSearch: setSearch,
				skills,
				setSkills,
				status,
				setStatus,
				options,
				theme,
				disabled,
				searchMode,
			}}
		>
			<div {...rest} className={cn("flex flex-col gap-1", className)}>
				{children}
			</div>
		</SkillSelectorContext.Provider>
	);
};

export { Description, Input, Results, SkillSelector as Root };

SkillSelector.Root = SkillSelector;
SkillSelector.Results = Results;
SkillSelector.Input = Input;
SkillSelector.Description = Description;
SkillSelector.Loading = Loading;
SkillSelector.Results = Results;

export default SkillSelector;
