import Card from "src/components/Card";
import withTranslation, {
	Translation,
} from "src/components/hoc/withTranslation";

interface ReferralTermsProps extends Translation {
	children?: any;
}

const ReferralTerms = ({ t, children }: ReferralTermsProps) => {
	return (
		<Card className=" ">
			<Card.Head title={t("title")} />
			<ul className="pl-8 list-disc flex flex-col gap-2">
				<li>{t("term.1")}</li>
				<li>{t("term.2")}</li>
				<li>{t("term.3")}</li>
				<li>{t("term.4")}</li>
				<li>{t("term.5")}</li>
			</ul>
		</Card>
	);
};

ReferralTerms.locale = {
	nl: {
		title: "Algemene actievoorwaarden",
		term: {
			1: "De opgebouwde korting wordt verekend met de maandelijkse abonnements factuur.",
			2: "De looptijd van de actie is onbepaald. {{tenant.name}} is ten allen tijde bevoegd zonder opgave van reden de actie te stoppen.",
			3: "{{tenant.name}} is ten allen tijde bevoegd actievoorwaarden tussentijds te wijzigen.",
			4: "Persoonsgegevens van deelnemers worden niet verstrekt aan derden.",
			5: "{{tenant.name}} is bevoegd om referrals te annuleren als er vermoedens zijn van frauduleuze verwijzingen.",
		},
	},
	en: {
		title: "General Terms and Conditions of the Promotion",
		term: {
			"1": "The accrued discount will be deducted from the monthly subscription invoice.",
			"2": "The duration of the promotion is indefinite. {{tenant.name}} is at all times authorized to terminate the promotion without providing a reason.",
			"3": "{{tenant.name}} is at all times authorized to modify the terms of the promotion.",
			"4": "Personal data of participants will not be disclosed to third parties.",
			"5": "{{tenant.name}} is authorized to cancel referrals if there are suspicions of fraudulent referrals.",
		},
	},
};

export default withTranslation(ReferralTerms);
