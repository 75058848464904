import React, { ComponentProps } from "react";
import AvatarComp, { AvatarProps } from "src/components/Avatar";
import { useUserStatistics } from "src/components/analytics/user/Provider";
import { cn } from "src/lib/utils";

export const Avatar = ({
	className,
	...rest
}: Omit<AvatarProps, "variant">) => {
	const { user } = useUserStatistics();
	// return (
	// 	<AvatarComp
	// 		{...rest}
	// 		className={cn("border border-border", className)}
	// 		src={user?.avatar}
	// 	/>
	// );
	if (!user) return null;
	return (
		<AvatarComp.User
			avatar={{
				...rest,
				className: "border border-border",
				size: rest?.size || "medium",
			}}
			{...{ user }}
		/>
	);
};

export const Name = ({ className, ...rest }: ComponentProps<"h1">) => {
	const { user } = useUserStatistics();
	return <h1 className={cn("text-xl", className)}>{user?.full_name}</h1>;
};
