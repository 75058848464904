import withTranslation, {
	Translation,
} from "src/components/hoc/withTranslation";
import { cn } from "src/lib/utils";

interface TemplateTaskBannerProps extends Translation {
	className?: string;
}

const TemplateTaskBanner = ({ t, className }: TemplateTaskBannerProps) => {
	return (
		<div
			className={cn(
				"flex justify-center items-center bg-accent py-3",
				className
			)}
		>
			<p className="font-bold text-placeholder">{t("text")}</p>
		</div>
	);
};

TemplateTaskBanner.locale = {
	nl: {
		text: "Dit is een template taak",
	},
	en: {
		text: "This is a template task",
	},
};

export default withTranslation(TemplateTaskBanner);
