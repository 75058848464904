import { useParams } from "react-router-dom";
import { ApiTask } from "src/api/types";
import DropdownButton from "src/components/DropdownButton";
import withTranslation, {
	Translation,
} from "src/components/hoc/withTranslation";

interface TaskDropDownButtonProps extends Translation {
	task: ApiTask;
}

const TaskDropDownButton = ({ t, task }: TaskDropDownButtonProps) => {
	const { pane } = useParams();
	return (
		<DropdownButton
			menu={{
				position: "bottom-right",
			}}
			button={{
				tabIndex: "-1",
				children: <i className="far fa-ellipsis-v" />,
				type: "dark",
				iconOnly: true,
				className: "p-0 w-8 h-8 ",
			}}
		>
			{task?.url && (
				<>
					<DropdownButton.Item
						href={task.url}
						target="_blank"
						icon="fas fa-eye"
					>
						{t("actions.view")}
					</DropdownButton.Item>
					<DropdownButton.Item
						to={{
							modal: `/tasks/${task.hid}/${pane}/share`,
							back: {
								to: `/tasks/${task.hid}/${pane}`,
							},
						}}
						icon="fas fa-share-alt"
					>
						{t("actions.share")}
					</DropdownButton.Item>
				</>
			)}

			<DropdownButton.Item
				to={`/tasks/create?repeat=${task?.hid}`}
				icon="fas fa-retweet"
			>
				{t("actions.repeat")}
			</DropdownButton.Item>
			{!task.has_subtasks && (
				<>
					<DropdownButton.Item
						to={{
							modal: `/tasks/${task.hid}/${pane}/add-registration`,
						}}
						icon="fas fa-user-plus"
					>
						{t("actions.add-registration")}
					</DropdownButton.Item>
					<DropdownButton.Item
						to={{
							modal: `/tasks/${task.hid}/${pane}/mass-message`,
						}}
						icon="fas fa-comments"
					>
						{t("actions.mass-message")}
					</DropdownButton.Item>
				</>
			)}
		</DropdownButton>
	);
};

TaskDropDownButton.locale = {
	nl: {
		actions: {
			view: "Bekijken",
			repeat: "Herhalen",
			edit: "Aanpassen",
			hours: "Uren controle",
			invites: "Uitnodigingen",
			share: "Delen",
			"mass-message": "Stuur massa bericht",
			"add-registration": "Aanmelding",
		},
	},
	en: {
		actions: {
			view: "View",
			repeat: "Repeat",
			edit: "Edit",
			hours: "Hour registration",
			invites: "Invites",
			share: "Share",
			"mass-message": "Send mass message",
			"add-registration": "Registration",
		},
	},
};

export default withTranslation(TaskDropDownButton);
