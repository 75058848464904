import { useState } from "react";
import { ApiTeam } from "src/api/types";
import Button from "src/components/Button";
import Input from "src/components/form/Input";
import withTranslation, {
	Translation,
} from "src/components/hoc/withTranslation";
import Accordion from "src/components/ui/accordion";
import { OnboardingBoard } from "src/hooks/api/services/auth/useOnboarding";
import Text from "src/components/ui/text";
import { slugify } from "src/lib/slugify";
import IconPicker from "src/components/icon/IconPicker";
import useEmoji from "src/hooks/data/useEmoji";
import Icon from "src/components/icon/Icon";

interface CreateBoardCardProps extends Translation {
	team?: ApiTeam;
	boards: OnboardingBoard[];
	onChange: (boards: OnboardingBoard[]) => void;
	defaultOpen?: boolean;
}

const CreateBoardCard = ({
	t,
	team,
	boards,
	onChange,
	defaultOpen,
}: CreateBoardCardProps) => {
	const { actions: emojiActions } = useEmoji();
	const [name, setName] = useState("");
	const [icon, setIcon] = useState({
		emoji: emojiActions.getRandomEmoji(),
	});
	const items = team
		? boards.filter((board) => board.team === team.id)
		: boards.filter((board) => !board.team);

	const onCreateBoard = (event: any) => {
		event.preventDefault();
		if (
			!name ||
			boards.find((board) => slugify(board.name) === slugify(name))
		) {
			return;
		}

		onChange([
			...(boards as any[]),
			{
				name,
				team: team?.id || null,
				type: "MANUAL",
				icon,
				skills: team?.skills || [],
			},
		]);

		setName("");
		setIcon({
			emoji: emojiActions.getRandomEmoji(),
		});
	};

	return (
		<Accordion {...{ defaultOpen }} className="bg-transparent border-0">
			<Accordion.Head className="px-2">
				<Text.Eyebrow>{team?.name || t("title")}</Text.Eyebrow>
				<div className="flex items-center gap-3">
					{!!items.length && (
						<span>{t("boards", { count: items.length })}</span>
					)}
					<Accordion.Icon />
				</div>
			</Accordion.Head>
			<Accordion.Content className="gap-4 px-2">
				<ul className="py-4 empty:hidden flex flex-col gap-2">
					{items.map((item) => (
						<li
							key={`${name}-${item.name}`}
							className="flex items-center justify-between"
						>
							<div className="flex items-center gap-3">
								{item.icon && (
									<Icon
										icon={item.icon}
										className="w-10 h-10 bg-accent"
									/>
								)}
								<span>{item.name}</span>
							</div>
							{item.type !== "STATIC" ? (
								<i
									onClick={() => {
										onChange(
											boards.filter(
												(board) =>
													board.name !== item.name
											)
										);
									}}
									className="far fa-times cursor-pointer opacity-40 hover:opacity-100"
								></i>
							) : (
								<Text.Eyebrow className="opacity-40">
									{t("default")}
								</Text.Eyebrow>
							)}
						</li>
					))}
				</ul>

				<form onSubmit={onCreateBoard} className="flex gap-2">
					<IconPicker
						trigger={
							<Icon
								className="bg-accent border border-border w-[42px] h-[42px]"
								icon={icon}
							/>
						}
						onChange={(emoji) =>
							setIcon({
								emoji,
							})
						}
						value={icon?.emoji}
					/>
					<Input
						placeholder={t("name.placeholder")}
						name="name"
						onChange={(key, value) => setName(value)}
						value={name}
					/>
					<Button className="h-[42px]" small submit type="primary">
						{t("add")}
					</Button>
				</form>
			</Accordion.Content>
		</Accordion>
	);
};

CreateBoardCard.locale = {
	nl: {
		title: "Algemeen",
		add: "Toevoegen",
		name: {
			placeholder: "Omgeving naam",
		},
		default: "Standaard",
		boards_one: "{{count}} bord",
		boards_other: "{{count}} borden",
	},
	en: {
		title: "General",
		add: "Add",
		name: {
			placeholder: "Board name",
		},
		default: "Default",
		boards_one: "{{count}} board",
		boards_other: "{{count}} boards",
	},
};

export default withTranslation(CreateBoardCard);
