import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Button from "src/components/Button";
import Layout from "src/components/layout/Layout";
import OfferErrorCard from "src/components/offer/cards/OfferErrorCard";
import OfferSummaryCard from "src/components/offer/cards/OfferSummaryCard";
import OfferBudgetForm from "src/components/offer/forms/create/OfferBudgetForm";
import OfferDescriptionForm from "src/components/offer/forms/create/OfferDescriptionForm";
import OfferTagsForm from "src/components/offer/forms/create/OfferTagsForm";
import OfferTitleForm from "src/components/offer/forms/create/OfferTitleForm";
import OfferVisibilityForm from "src/components/offer/forms/create/OfferVisibilityForm";
import OfferCreateLayout from "src/components/offer/layout/OfferCreateLayout";
import useCreateOffer from "src/hooks/api/useCreateOffer";
import useAuth from "src/hooks/selectors/useAuth";
import useWindowSize from "src/hooks/useWindowSize";

export default function OffersCreateView() {
	const { t } = useTranslation("offer-view", {
		keyPrefix: "offer-create-view",
	});
	const { current, clearOffer, offer, fetchDefaultIntro } = useCreateOffer();
	const [isEmpty, setIsEmpty] = useState(false);
	const { isPhone } = useWindowSize();
	const auth = useAuth();
	const headers = [
		{
			title: t("headers.title.title"),
			description: t("headers.title.description"),
		},
		{
			title: t("headers.description.title"),
			description: t("headers.description.description"),
		},
		{
			title: t("headers.visibility.title"),
			description: t("headers.visibility.description"),
		},
		{
			title: t("headers.skills.title"),
			description: t("headers.skills.description"),
		},
		{
			title: t("headers.budget.title"),
			description: t("headers.budget.description"),
		},
	];
	const header = headers[current] || {};

	useEffect(() => {
		fetchDefaultIntro();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (offer?.id) {
			clearOffer();
			setIsEmpty(true);
		} else {
			setIsEmpty(true);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [offer]);

	const getForm = () => {
		if (current === 0) {
			return <OfferTitleForm />;
		}

		if (current === 1) {
			return <OfferDescriptionForm />;
		}
		if (current === 2) {
			return <OfferVisibilityForm />;
		}
		if (current === 3) {
			return <OfferTagsForm />;
		}
		if (current === 4) {
			return <OfferBudgetForm />;
		}
		return <OfferErrorCard />;
	};

	return (
		<Layout
			loading={!isEmpty}
			breadcrumb={[
				{
					to: "/offers",
					label: t("breadcrumb.home"),
					isHome: true,
				},
				{
					to: "/offers/create",
					label: t("breadcrumb.create"),
				},
			]}
			container="xxl"
			className={isPhone ? "bg-background" : undefined}
			title={t("title")}
			backUrl="/offers"
		>
			{!auth?.is_verified ? (
				<div className="flex flex-col justify-center items-center flex-1">
					<div className="flex flex-col gap-2 max-w-md justify-center items-center text-center">
						<strong>{t("verify.title")}</strong>
						<p>{t("verify.description")}</p>
						<Button to={"/dashboard"}>{t("verify.button")}</Button>
					</div>
				</div>
			) : (
				<>
					{current === 5 ? (
						<div className={!isPhone ? "container-small" : ""}>
							<OfferSummaryCard />
						</div>
					) : (
						<OfferCreateLayout {...{ header }}>
							{getForm()}
						</OfferCreateLayout>
					)}
				</>
			)}
		</Layout>
	);
}
