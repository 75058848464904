import { motion } from "framer-motion";
import { Children, ReactNode } from "react";

interface TutorialImageProps {
	src?: any;
	elements?: ReactNode[];
}

const TutorialImage = ({ src, elements: _elements }: TutorialImageProps) => {
	const elements = Children.toArray(_elements || []);

	return (
		<div className="flex flex-col w-full h-[240px] bg-accent rounded-md relative">
			{elements.map((element, index) => (
				<motion.div
					key={`image-${index}-${src}`}
					initial={{
						opacity: 0,
						scale: 0.8,
					}}
					transition={{
						delay: 0.5 + index * 0.5,
					}}
					variants={{
						active: {
							opacity: 1,
							scale: 1,
						},
						left: {
							opacity: 0,
						},
						right: {
							opacity: 0,
						},
					}}
				>
					{element}
				</motion.div>
			))}
			<img {...{ src }} className="flex-1 rounded-md" alt="" />
		</div>
	);
};

export default TutorialImage;
