import { useState } from "react";
import Button from "src/components/Button";
import Modal from "./Modal";
import Radio from "./form/Radio";
import Input from "./form/Input";
import useWindowSize from "src/hooks/useWindowSize";
import { useTranslation } from "react-i18next";
import { ApiRegistration, ApiTask } from "src/api/types";
import useReport from "src/hooks/api/services/useReport";

export enum EnumReportType {
	"NOT_SHOWING",
	"BAD_LANGUAGE",
	"OTHER",
}

interface formReport {
	user_id: string | number;
	task_id: number | undefined;
	type: string;
	description: string | undefined;
}

interface ModalReportProps {
	visible?: boolean;
	onClose: (data?: ApiRegistration) => Promise<unknown>;
	registration: ApiRegistration;
	task: ApiTask;
}

const ModalReport = ({
	visible,
	onClose,
	registration,
	task,
}: ModalReportProps) => {
	const { t } = useTranslation(["report", "buttons"]);
	const { actions, status } = useReport();
	const { isPhone } = useWindowSize();
	const [selected, setSelected] = useState(0);
	const [additionalReportField, setAdditionalReportField] = useState("");
	const [errorMessage, setErrorMessage] = useState("");
	const options = [
		t("options.not_there"),
		t("options.incorrect_language"),
		t("options.other"),
	];

	const handleClose = async (registration?: ApiRegistration) => {
		if (onClose) await onClose(registration);
		setSelected(0);
		setAdditionalReportField("");
	};

	const handleSubmit = async () => {
		if (selected === 2 && !additionalReportField) {
			setErrorMessage(t("required"));
		} else {
			if (status !== "idle") return;

			const form: formReport = {
				user_id: registration.user.id,
				task_id: task.id,
				type: EnumReportType[selected],
				description: undefined,
			};
			if (selected === 2) {
				form.description = additionalReportField;
			}
			actions.create(form).then(() => {
				handleClose();
			});
		}
	};

	return (
		<Modal
			visible={visible || false}
			onClose={handleClose}
			title={t("title")}
		>
			<ul className={`flex flex-col gap-4`}>
				<>
					{options.map((option, index) => (
						<ReportSelectItem
							key={index}
							{...{ option }}
							onClick={() => {
								setSelected(index);
							}}
							selected={index === selected}
						/>
					))}
				</>
			</ul>
			<Input
				className={`mt-4 ${selected !== 2 ? "hidden" : ""}`}
				multiline={!isPhone}
				placeholder={t("placeholder")}
				value={additionalReportField}
				error={errorMessage}
				onChange={(_, value) => {
					setErrorMessage("");
					setAdditionalReportField(value);
				}}
				name="additional-report-field"
			/>
			<Button
				loading={status === "creating"}
				className={selected !== 2 ? "mt-4" : ""}
				onClick={handleSubmit}
			>
				{t("confirm", {
					ns: "buttons",
				})}
			</Button>
		</Modal>
	);
};

interface ReportSelectItemProps {
	option: string;
	onClick?: () => void;
	selected?: boolean;
}

const ReportSelectItem = ({
	option,
	onClick,
	selected,
}: ReportSelectItemProps) => {
	return (
		<li
			{...{ onClick }}
			className={`rounded-md pointer p-2 group hover:border-primary flex transition-all cursor-pointer items-center gap-4 border-2 bg-background border-${
				selected ? "primary" : "gray"
			} ${selected && "text-primary"}`}
		>
			<Radio value={selected} variant="light" />
			<div className="mb-0 flex gap-4 items-center flex-1">
				<div className="flex flex-col">
					<strong>{option}</strong>
				</div>
			</div>
		</li>
	);
};

export default ModalReport;
