import { useEffect } from "react";
import Button from "src/components/Button";
import withTranslation, {
	Translation,
} from "src/components/hoc/withTranslation";
import Layout from "src/components/layout/Layout";
import Tab from "src/components/layout/Tab";
import Tabs from "src/components/layout/Tabs";
import useIdeas from "src/hooks/api/services/useFeedback";
import useNavigateModal from "src/hooks/useNavigateModal";
import FeedbackGrid from "src/components/feedback/FeedbackGrid";
import Loader from "src/components/Loader";

interface FeedbackViewProps extends Translation {
	children?: any;
}

const FeedbackView = ({ t }: FeedbackViewProps) => {
	const navigateModal = useNavigateModal();
	const { actions, filter, status } = useIdeas();

	useEffect(() => {
		actions.list({
			page: 1,
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<Layout
			title={t("title")}
			container="xxl"
			className=" "
			breadcrumb={[
				{
					label: t("title"),
					to: "/feedback",
				},
			]}
		>
			<div className="flex items-center flex-wrap gap-4 justify-between">
				<div className="">
					<Tabs.Container>
						<Tab
							onClick={() =>
								actions.list({
									page: 1,
									state: "all",
								})
							}
							active={filter.state === "all"}
						>
							{t("tabs.all")}
						</Tab>
						<Tab
							onClick={() =>
								actions.list({
									page: 1,
									state: "my",
								})
							}
							active={filter.state === "my"}
						>
							{t("tabs.my")}
						</Tab>
						<Tab
							onClick={() =>
								actions.list({
									page: 1,
									state: "releases",
								})
							}
							active={filter.state === "releases"}
						>
							{t("tabs.releases")}
						</Tab>
					</Tabs.Container>
				</div>
				<Button
					type="dark"
					onClick={() => navigateModal("/feedback/create")}
				>
					<i className="fas fa-plus mr-2"></i>
					{t("button.create")}
				</Button>
			</div>
			{status === "loading" && (
				<div className="flex justify-center items-center flex-1">
					<Loader />
				</div>
			)}
			{status !== "loading" && (
				<FeedbackGrid
					variant={
						filter.state === "releases" ? "releases" : undefined
					}
				/>
			)}
		</Layout>
	);
};

FeedbackView.locale = {
	nl: {
		title: "Feedback",
		button: {
			create: "Post je feedback",
		},
		tabs: {
			releases: "Releases",
			all: "Open",
			my: "Mijn feedback",
		},
	},
	en: {
		title: "Feedback",
		button: {
			create: "Post your feedback",
		},
		tabs: {
			releases: "Releases",
			all: "Open",
			my: "My feedback",
		},
	},
};

export default withTranslation(FeedbackView);
