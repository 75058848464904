import { AnimatePresence, motion } from "framer-motion";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Button from "src/components/Button";
import Input from "src/components/form/Input";
import AnswerForm from "src/components/forms/QuestionForm/AnswerForm";

const menuAnimation = {
	initial: {
		opacity: 0,
		y: -20,
	},
	animate: {
		opacity: 1,
		y: 0,
	},
};

export type Question = {
	title: string;
	create_type?: string;
	answers: Answer[];
};

export type Answer = {
	answer: string;
	is_right: boolean;
};

interface QuestionFormProps {
	input?: {
		label?: string;
	};
	value: Question;
	onChange: (value?: Question) => void;
	settings?: {
		defaultOpen?: boolean;
		addOnFocus?: boolean;
	};
	autoFillQuery?: string;
}

const QuestionForm = ({
	input,
	onChange,
	value,
	settings,
	autoFillQuery,
}: QuestionFormProps) => {
	const { t } = useTranslation("forms", {
		keyPrefix: "question-form",
	});
	const [isOpen, setIsOpen] = useState(undefined) as any;
	const answers = value?.answers || [];

	const handleAnswerChange = (index: number, answer?: Answer) => {
		const { answers } = value;

		if (!answer) {
			onChange({
				...value,
				create_type: "MANUAL",
				answers: answers.filter((item, i) => i !== index),
			});
			return;
		}

		onChange({
			...value,
			create_type: "MANUAL",
			answers: answers.map((item, i) => {
				if (i === index) {
					return answer;
				}
				// if (answer.is_right) item.is_right = false;

				return item;
			}),
		});
	};

	const handleAddNewAnswer = () => {
		onChange({
			...value,
			answers: [...(value.answers || []), {} as Answer],
		});
	};

	useEffect(() => {
		if (
			(!value?.answers || !value?.answers?.length) &&
			settings?.addOnFocus
		) {
			handleAddNewAnswer();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (isOpen === undefined) {
			setIsOpen(settings?.defaultOpen || false);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [settings]);

	return (
		<div className="flex flex-col gap-2">
			<div className="flex items-end gap-2">
				<Input
					{...{ autoFillQuery }}
					// autoFill="question"
					noMarginBottom
					name="question"
					placeholder={t("question")}
					value={value.title}
					onChange={(_, title) => onChange({ ...value, title })}
					onKeyPress={(e) => {
						e.key === "Enter" && e.preventDefault();
					}}
					{...input}
				/>
				<Button
					onClick={() => onChange(undefined)}
					className="w-11 h-11"
					iconOnly
					type="transparent"
				>
					<i className="fas fa-trash"></i>
				</Button>
				<Button
					onClick={() => setIsOpen(!isOpen)}
					className="w-11 h-11"
					iconOnly
					type="transparent"
				>
					<i className={`fas fa-caret-${isOpen ? "up" : "down"}`}></i>
				</Button>
			</div>
			<div className="overflow-hidden">
				<AnimatePresence>
					{isOpen && (
						<motion.div
							{...menuAnimation}
							className="mt-4 flex flex-col gap-4"
						>
							<div className="flex flex-col">
								<strong>{t("answer.title")}</strong>
								<p>{t("answer.description")}</p>
							</div>

							<div className="flex flex-col gap-6">
								{value.answers.map((answer, index) => (
									<AnswerForm
										input={{
											label: t("answer.label", {
												count: index + 1,
											}),
										}}
										value={answer}
										onChange={(value) =>
											handleAnswerChange(index, value)
										}
										onEnter={() => {
											if (answer.answer) {
												handleAddNewAnswer();
											}
										}}
									/>
								))}
							</div>
							<div className="flex justify-center items-center">
								<Button
									onClick={() => handleAddNewAnswer()}
									type="transparent"
									small
									disabled={
										(answers &&
											answers.length &&
											!answers[answers.length - 1]
												.answer) ||
										false
									}
								>
									<i className="fas fa-plus mr-2"></i>
									{t("answer.add")}
								</Button>
							</div>
							<hr className="border-border mt-4" />
						</motion.div>
					)}
				</AnimatePresence>
			</div>
		</div>
	);
};

export default QuestionForm;
