import { ReactNode } from "react";

interface TutorialItemProps {
	index?: number;
	image: ReactNode;
	children?: any;
}

const TutorialItem = ({ image, children }: TutorialItemProps) => {
	return (
		<div className="flex flex-col gap-6">
			<div className="flex relative rounded-md">{image}</div>
			<div className="flex flex-col gap-2">{children}</div>
		</div>
	);
};

export default TutorialItem;
