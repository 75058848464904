import moment from "moment";
import { useRef } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { ApiTaskInvite } from "src/api/types";
import Avatar from "src/components/Avatar";
import Button from "src/components/Button";
import Card from "src/components/Card";
import { formatPrice } from "src/lib/formatters";

interface TaskInviteCardProps {
	invite: ApiTaskInvite;
}

const TaskInviteCard = ({ invite }: TaskInviteCardProps) => {
	const { t } = useTranslation("network", {
		keyPrefix: "task-invite-card",
	});
	const ref = useRef() as any;
	const navigate = useNavigate();
	const task = invite.task;
	const company = task?.company || null;
	return (
		<Card
			{...{ ref }}
			className="cursor-pointer"
			contentClassName="p-8 flex flex-1 relative flex-col gap-3"
			onClick={() => navigate(`/network/invites/${invite.hid}/invite`)}
		>
			{!invite.is_read && (
				<div className="absolute right-8 top-">
					<div className="w-2 h-2 bg-primary rounded-full"></div>
				</div>
			)}
			<div className="flex flex-wrap items-center justify-between gap-4">
				{company && (
					<div className="flex gap-2 items-end">
						<Avatar
							src={company.avatar}
							alt={company.name}
							size="regular"
							className="bg-accent dark:bg-border"
						/>
						<div className="flex flex-col">
							<strong>{company.name}</strong>
							{task?.creator && (
								<span className="opacity-70">
									{task?.creator?.first_name}
								</span>
							)}
						</div>
					</div>
				)}
				<div className="flex gap-1">
					<h2 className="text-primary">
						{formatPrice(task.budget_per_hour || task.budget)}
					</h2>
					{task.budget_per_hour ? (
						<sup className="text-primary font-bold">
							{t("per-hour")}
						</sup>
					) : (
						""
					)}
				</div>
			</div>

			<div className="flex flex-col flex-1">
				<strong>{task.title}</strong>
				<p className="opacity-70">{task.excerpt}</p>
			</div>
			<Button
				type={invite.is_registered ? "primary" : undefined}
				to={`/network/${invite.hid}/invite`}
			>
				{invite.is_registered
					? t("button.registered")
					: t("button.view-invite")}
			</Button>
			<span className="opacity-70">
				{moment(invite.created_at).fromNow()}
			</span>
		</Card>
	);
};

export default TaskInviteCard;
