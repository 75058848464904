import { HTMLProps, useEffect, useState } from "react";
import useAutosizeTextArea from "src/hooks/useAutosizeTextArea";
import { cn } from "src/lib/utils";

const VARIANTS = {
	large: "input-editable-title input-editable-title_large",
	medium: "input-editable-title input-editable-title_medium",
};

export type EditableVariant = keyof typeof VARIANTS;

interface EditableTextProps extends HTMLProps<HTMLTextAreaElement> {
	variant: EditableVariant;
}

const EditableText = ({
	variant,
	size,
	autoFocus,
	value,
	...rest
}: EditableTextProps) => {
	const [isFocused, setIsFocused] = useState(false);
	const [, setIsHovered] = useState(false);
	const { ref } = useAutosizeTextArea();
	const className = cn(
		VARIANTS[variant],
		"bg-transparent w-full",
		rest?.className || ""
	);

	useEffect(() => {
		if (ref?.current && autoFocus && !isFocused) {
			ref.current.focus();
			ref.current.selectionStart = ref.current.value.length;
		}
		setTimeout(() => {
			if (ref?.current) {
				ref.current.style.height = "0px";
				const scrollHeight = ref.current.scrollHeight;
				ref.current.style.height = scrollHeight + "px";
			}
		}, 300);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [value]);

	return (
		<textarea
			{...{ ref, ...rest, className }}
			value={value || ""}
			autoFocus={false}
			onFocus={() => setIsFocused(true)}
			onBlur={() => setIsFocused(false)}
			onMouseLeave={() => setIsHovered(false)}
		/>
	);
};

export default EditableText;
