import { useEffect, useState } from "react";
import Button from "src/components/Button";
import FormGroup from "src/components/form/FormGroup";
import Input from "src/components/form/Input";
import withTranslation, {
	Translation,
} from "src/components/hoc/withTranslation";
import Loader from "src/components/Loader";
import useCompanyInfoSearch from "src/hooks/api/services/auth/useCompanyInfo";
import useProspect from "src/hooks/api/services/auth/useProspect";
import useSignupCompany from "src/hooks/api/services/auth/useCompanyForm";

interface CompanyInformationFormProps extends Translation {
	infoId?: string;
	onSubmit?: () => void;
	onBack?: () => void;
}

const CompanyInformationForm = ({
	t,
	infoId,
	onSubmit: _onSubmit,
	onBack,
}: CompanyInformationFormProps) => {
	const { form, validate, actions } = useSignupCompany();
	const { prospect } = useProspect();
	const { actions: infoActions, status: infoStatus } = useCompanyInfoSearch();
	const [editAddressMode, setEditAddressMode] = useState(true);
	const valid = validate?.valid || {};
	const isValidAddress =
		valid?.street && valid?.house_number && valid?.city && valid?.zipcode;

	useEffect(() => {
		if (infoId && infoStatus === "idle") {
			infoActions.getDetails(infoId).then((res) => {
				if (
					res?.straat &&
					res?.huisnummer &&
					res?.plaats &&
					res?.postcode
				) {
					setEditAddressMode(false);
				}

				actions.setForm({
					name: prospect?.company_name || res?.handelsnaam,
					kvk: prospect?.kvk || res?.dossiernummer,
					street: res?.straat,
					house_number: res?.huisnummer,
					city: res?.plaats,
					zipcode: res?.postcode,
					bio: res?.omschrijving,
				});
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const getFormField = (name: string) => {
		return {
			...actions.getField(name),
			noMarginBottom: true,
			label: t(`fields.${name}`),
			placeholder: t(`fields.${name}`),
		};
	};

	const onSubmit = (e?: any) => {
		if (e) e.preventDefault();
		if (!validate.isValid) return;

		if (_onSubmit) _onSubmit();
	};

	if (infoStatus === "loading") {
		return (
			<div className="flex flex-col justify-center items-center p-4 flex-1">
				<Loader />
			</div>
		);
	}

	return (
		<form className="flex flex-col gap-4" {...{ onSubmit }}>
			<Input {...getFormField("name")} />
			<Input {...getFormField("kvk")} />

			{editAddressMode ? (
				<>
					<div className="flex gap-4">
						<Input {...getFormField("street")} />
						<Input {...getFormField("house_number")} />
					</div>
					<Input {...getFormField("city")} />
					<Input {...getFormField("zipcode")} />
				</>
			) : (
				<FormGroup
					valid={isValidAddress}
					noMarginBottom
					label={t("fields.address")}
				>
					<div className="flex flex-col w-full">
						<Input
							disabled
							noMarginBottom
							name="address"
							value={`${form?.street} ${form?.house_number}, ${form?.zipcode} ${form.city}`}
						/>
						<small
							onClick={() => setEditAddressMode(true)}
							className="cursor-pointer mt-2"
						>
							{t("edit-address")}
						</small>
					</div>
				</FormGroup>
			)}

			<Input optional multiline {...getFormField("bio")} />
			<Input {...getFormField("branche")} />

			<div className="flex justify-between gap-4 mt-2">
				{onBack && (
					<Button type="gray" onClick={onBack}>
						{t("buttons.back")}
					</Button>
				)}
				<Button type="dark" submit disabled={!validate.isValid}>
					{t("buttons.continue")}
				</Button>
			</div>
		</form>
	);
};

CompanyInformationForm.locale = {
	nl: {
		fields: {
			name: "Bedrijfsnaam",
			kvk: "KVK",
			street: "Straat",
			house_number: "Huisnummer",
			city: "Stad",
			zipcode: "Postcode",
			bio: "Omschrijving",
			branche: "Branche",
			address: "Adresgegevens",
		},
		"edit-address": "Adres aanpassen",
		buttons: {
			back: "Terug",
			continue: "Doorgaan",
		},
	},
	en: {
		fields: {
			name: "Company name",
			kvk: "Chamber of commerce",
			street: "Street",
			house_number: "Housenumber",
			city: "City",
			zipcode: "Zipcode",
			bio: "Description",
			branche: "Industry",
			address: "Address details",
		},
		"edit-address": "Edit address",
		buttons: {
			back: "Back",
			continue: "Continue",
		},
	},
};

export default withTranslation(CompanyInformationForm);
