import { ComponentProps } from "react";
import Button from "src/components/Button";
import withTranslation, {
	Translation,
} from "src/components/hoc/withTranslation";
import useBackUrl from "src/hooks/useBackUrl";
import { cn } from "src/lib/utils";

const StudentNeedBasicInformation = ({
	t,
	className,
	...rest
}: Translation & ComponentProps<"div">) => {
	const backUrl = useBackUrl();
	return (
		<div {...rest} className={cn("flex flex-col", className)}>
			<strong>{t("title")}</strong>
			<p className="opacity-70 mb-2">{t("description")}</p>
			<div className="flex">
				<Button
					xsmall
					to={{
						modal: `/dashboard/resume/basic?${backUrl}`,
					}}
				>
					<i className="fas fa-exclamation"></i>
					{t("button")}
				</Button>
			</div>
		</div>
	);
};

StudentNeedBasicInformation.locale = {
	nl: {
		title: "We missen nog wat basis informatie",
		description:
			"Wanneer je straks geaccepteerd wilt worden voor een opdracht moeten we gegevens zoals je woonplaats, telefoonnummer en geboortedatum nog ontvangen. Doe dit voor dat je op opdrachten gaat reageren.",
		button: "Informatie invullen",
	},
	en: {
		title: "We're missing some basic information",
		description:
			"When you want to be accepted for a task, we still need to receive details like your place of residence, phone number, and date of birth. Please provide this information before you start applying for tasks.",
		button: "Fill in information",
	},
};

export default withTranslation(StudentNeedBasicInformation);
