import { useMemo, useState } from "react";
import * as unicodeEmoji from "unicode-emoji";

const EMOTICONS = unicodeEmoji.getEmojis();
let CATEGORIES: any[] = EMOTICONS.map((item) => ({
	name: item.category,
	icon: item,
}));

CATEGORIES = CATEGORIES.filter(
	(obj, index) =>
		CATEGORIES.findIndex((item: any) => item.name === obj.name) === index
);

export const getRandomEmoji = () => {
	const icon = EMOTICONS[Math.floor(Math.random() * EMOTICONS.length)];
	return {
		icon: icon.emoji,
		description: icon.description,
	};
};

export default function useEmoji() {
	const [selectedCategory, setSelectedCategory] =
		useState<string>("face-emotion");
	const [search, setSearch] = useState<string>();
	const data: any[] = useMemo(() => {
		let data = EMOTICONS;

		if (selectedCategory && !search) {
			data = data.filter((item) => item.category === selectedCategory);
		}

		if (search) {
			const q = search.toLowerCase();
			data = data.filter((item) => {
				const inKeywords = !!item.keywords.filter((word) =>
					word.toLowerCase().includes(q)
				)?.length;
				return inKeywords || item.description.includes(q);
			});
		}

		return data;
	}, [search, selectedCategory]);

	return {
		data,
		categories: CATEGORIES,
		filter: {
			category: selectedCategory,
			search,
		},
		actions: {
			selectCategory: setSelectedCategory,
			search: setSearch,
			getRandomEmoji,
		},
	};
}
