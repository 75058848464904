const task = {
	tabs: {
		task: "Taak",
		editor: "Editor",
		general: "Algemeen",
		registration: "Aanmelding",
		registrations: "Aanmeldingen",
		invites: "Uitnodigingen",
		statistics: "Statistieken",
		edit: "Aanpassen",
		hours: "Uren controle",
		shifts: "Shifts",
		files: "Bestanden",
	},
	"thanks-view": {
		title: "Opdracht geplaatst",
		card: {
			title: "Bedankt voor het plaatsen van de opdracht!",
			share: "Deel de opdracht in je eigen netwerk om nog meer aanmeldingen te krijgen",
			concept:
				"Bewerk of publiceer het concept later vanuit het opdrachtenoverzicht",
			buttons: {
				create: "Nog een opdracht plaatsen",
				back: "Terug naar opdrachten",
			},
		},
	},
	"create-view": {
		"title-create": "Taak aanmaken",
		"title-edit": "Taak aanpassen",
		"no-subscription": {
			title: "Je kan nog geen taken plaatsen",
			description:
				"Om taken te kunnen plaatsen heb je eerst een actief abonnement nodig.",
			button: "Abonnement kiezen",
		},
		breadcrumb: {
			tasks: "Taken",
			create: "Taak aanmaken",
		},
	},
	"edit-view": {
		title: "Taak aanpassen",
		error: {
			title: "Er is iets misgegaan",
		},
		disabled: {
			title: "De taak kan niet meer aangepast worden",
			description:
				"Aangezien de taak al is afgerond is het niet meer mogelijk om de opdracht aan te passen.",
		},
		cards: {
			description: {
				title: "Opdrachtomschrijving",
			},
			budget: {
				title: "Opdrachtwaarde",
				description:
					"De opdracht waarde kan je alleen nog aanpassen wanneer er geen aanmeldingen zijn en er nog niet betaald is voor de opdracht.",
			},
			location: {
				title: "Locatie",
			},
			date: {
				title: "Start- en einddatum",
			},
			form: {
				title: "Aanmeldformulier",
			},
			delete: {
				title: "Verwijderen",
			},
			visibility: {
				title: "Zichtbaarheid",
			},
		},
	},
	"detail-view": {
		empty: {
			title: "Geen opdracht gevonden",
		},
		"not-selected": {
			title: "Je bent nog niet gekozen",
			description: "Helaas ben je nog niet gekozen voor de opdracht.",
		},
	},
};

export default task;
