import React from "react";
import Button from "src/components/Button";
import Progress from "src/components/Progress";
import withTranslation, {
	Translation,
} from "src/components/hoc/withTranslation";
import useLimits from "src/hooks/api/services/auth/useLimits";
import useLayout from "src/hooks/useLayout";

interface SubscribeInternCardProps extends Translation {}

const SubscribeInternCard = ({ t }: SubscribeInternCardProps) => {
	const { limits } = useLimits();
	const { layout } = useLayout();
	if (layout.navbar.variant !== "default") {
		return <></>;
	}
	return (
		<div className="max-w-[240px] p-4 rounded-md border border-border gap-4 flex flex-col">
			<div className="flex flex-col gap-1">
				<strong className="leading-full text-[16px]">
					{t("label")}
				</strong>
				<p className="text-[16px]">
					{t("description", limits.intern_tasks)}
				</p>
			</div>
			<Progress
				total={limits.intern_tasks?.maximum || 0}
				current={limits.intern_tasks?.count || 0}
				className="bg-border"
				barClassName="bg-primary"
			/>
			<div className="flex flex-col gap-2">
				<Button small to="/subscription" type="primary">
					<div className="flex gap-2 w-full max-w-[120px] relative text-center justify-center">
						<div className="absolute left-0">
							<i className="far fa-chevron-double-up"></i>
						</div>
						<span>{t("button")}</span>
					</div>
				</Button>
				<Button
					help={t("invite-disclaimer")}
					small
					id="referrals"
					to="/referrals"
					type="gray"
				>
					<div className="flex gap-2 w-full max-w-[120px] relative text-center justify-center">
						{/* <div className="absolute left-0">
						<i className="far fa-user-plus"></i>
					</div> */}
						<span>{t("continue")}</span>
					</div>
				</Button>
			</div>
		</div>
	);
};

SubscribeInternCard.locale = {
	nl: {
		title: "Upgrade je abonnement",
		label: "Jouw abonnement",
		description:
			"Je hebt {{count}} van de {{maximum}} gratis taken gebruikt",
		button: "Upgrade",
		invite: "Invite",
		continue: "Gratis doorgaan",
		"invite-disclaimer": "Krijg 100 taken extra per invite",
	},
	en: {
		title: "Upgrade your payment",
		label: "Your subscription",
		description: "You have used {{count}} of the {{maximum}} free tasks",
		button: "Upgrade",
		invite: "Invite",
		continue: "Continue for free",
		"invite-disclaimer": "Get 100 extra tasks per invite",
	},
};

export default withTranslation(SubscribeInternCard);
