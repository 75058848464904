import useApi from "src/hooks/api/utils/useApi";

function useBestPerformingUsers() {
	const { state, actions, api, pagination } = useApi(
		{ id: "USER_BEST_PERFORMING" },
		{
			baseUrl: `/client/statistics/users/performing`,
			query: {
				sort: "best",
			},
		}
	);
	const users = state?.list || [];

	return {
		users: state?.list || [],
		status:
			users.length && state.status === "loading" ? "idle" : state.status,
		pagination,
		filter: state?.filter || {},
		actions: {
			...actions,
		},
		api,
	};
}

export default useBestPerformingUsers;
