import moment from "moment";
import Field from "src/components/field/Field";
import Input from "src/components/form/Input";
import withTranslation, {
	Translation,
} from "src/components/hoc/withTranslation";
import useProjects from "src/hooks/api/services/projects/useProjects";

interface ProjectFilterFormProps extends Translation {}

const ProjectFilterForm = ({ t }: ProjectFilterFormProps) => {
	const { setFilterState, filterState } = useProjects();

	return (
		<div className="flex flex-col gap-4">
			<Input
				autoFocus
				icon={<i className="far fa-search" />}
				name="search"
				placeholder={t("fields.search.placeholder")}
				value={filterState?.search}
				className="p-0"
				noMarginBottom
				onChange={(key, value) =>
					setFilterState((prev) => ({ ...prev, search: value }))
				}
			/>
			<Field.Date
				wrapper={{
					label: t("fields.type.start_date"),
					layout: "horizontal",
				}}
				value={
					filterState?.start_date
						? moment(filterState.start_date).format("YYYY-MM-DD")
						: undefined
				}
				onChange={(value: any) => {
					setFilterState((prev) => ({ ...prev, start_date: value }));
				}}
			/>
			<Field.Date
				wrapper={{
					label: t("fields.type.end_date"),
					layout: "horizontal",
				}}
				value={
					filterState?.end_date
						? moment(filterState.end_date).format("YYYY-MM-DD")
						: undefined
				}
				onChange={(value: any) => {
					setFilterState((prev) => ({ ...prev, end_date: value }));
				}}
			/>
			<Field.Users
				wrapper={{
					label: t("fields.assignees.label"),
					layout: "horizontal",
				}}
				value={
					filterState.members
						? filterState.members.map((user: any) =>
								typeof user === "object"
									? user.id.toString()
									: user
						  )
						: []
				}
				onChange={(value) => {
					setFilterState((prev) => ({ ...prev, members: value }));
				}}
			/>
		</div>
	);
};

ProjectFilterForm.locale = {
	nl: {
		fields: {
			type: {
				start_date: "Start datum",
				end_date: "Eind datum",
			},
			search: {
				placeholder: "Zoek een project...",
			},
			assignees: {
				label: "Leden",
			},
		},
	},
	en: {
		fields: {
			type: {
				start_date: "Start date",
				end_date: "End date",
			},
			search: {
				placeholder: "Search a project...",
			},
			assignees: {
				label: "Members",
			},
		},
	},
};

export default withTranslation(ProjectFilterForm);
