import { ComponentProps, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { ApiTaskKanban } from "src/api/types";
import Card from "src/components/Card";
import EmptyState from "src/components/EmptyState";
import Draggable from "src/components/draggable";
import withTranslation, {
	Translation,
} from "src/components/hoc/withTranslation";
import TaskKanbanCard from "src/components/tasks/kanban/TaskKanbanCard";
import TaskList from "src/components/tasks/list/TaskList";
import useMyTasksInbox from "src/hooks/api/services/tasks/useMyTasksInbox";
import useBackUrl from "src/hooks/useBackUrl";
import useNavigateModal from "src/hooks/useNavigateModal";

interface MyTaskInboxCheckListProps extends Translation, ComponentProps<"div"> {
	defaultOpen?: boolean;
}

const MyTaskInboxCheckList = ({
	t,
	className,
	defaultOpen,
	...rest
}: MyTaskInboxCheckListProps) => {
	const dropId = "STATUS:BACKLOG";
	const navigate = useNavigate();
	const back = useBackUrl();
	const navigateModal = useNavigateModal();
	const { tasks, pagination, status, actions } = useMyTasksInbox({});

	useEffect(() => {
		actions.list();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const onToggleStatusCompleted = (task: ApiTaskKanban) => {
		return actions.update(
			{
				id: task.id,
				status: task.status === "COMPLETED" ? "BACKLOG" : "COMPLETED",
			},
			{
				updateList: true,
			}
		);
	};

	return (
		<Draggable.Drop id={dropId} ghostItemVariant="none" className="">
			{({ isOver }: any) => (
				<div className="flex flex-col gap-4">
					<TaskList
						{...{ tasks }}
						onUpdate={(id, value) =>
							actions.update(
								{
									id,
									...value,
								},
								{
									updateList: true,
								}
							)
						}
						item={{
							elements: ["status", "description"],
						}}
						loading={status === "loading"}
						empty={
							tasks.length === 0 &&
							status === "idle" && (
								<EmptyState
									to={{
										modal: "./modals/tasks/create",
									}}
									icon="fas fa-check-circle"
									title={t("empty.title")}
									description={t("empty.description")}
									theme="dashed"
									// variant="center"
								/>
							)
						}
						renderItem={(task) => (
							<Draggable.Item
								key={`task-${task.hid}-check`}
								id={`LIST_${task.id}`}
								data={task}
								currentDropId={dropId}
							>
								<TaskKanbanCard
									disableActions
									{...{ task }}
									variant="fragment"
								>
									<div
										onClick={(e: any) => {
											e.stopPropagation();
											if (task.type !== "INTERNAL") {
												navigate(
													`/tasks/${task.hid}/show?${back}`
												);
												return;
											}
											navigateModal(
												`./modals/tasks/${task.hid}`
											);
										}}
										className="flex gap-4 rounded-md items-center"
									>
										<TaskKanbanCard.Check
											value={task.status === "COMPLETED"}
											onChange={async () =>
												await onToggleStatusCompleted(
													task
												)
											}
										/>
										<div className="flex gap-1 flex-1">
											<TaskKanbanCard.Title
												className="flex-1 leading-full"
												variant="animated"
												isLineThrough={
													task.status === "COMPLETED"
												}
											/>
											<div className="flex flex-col gap-1">
												<TaskKanbanCard.Board
													cutText={6}
												/>
												<TaskKanbanCard.Date />
											</div>
										</div>
									</div>
								</TaskKanbanCard>
							</Draggable.Item>
						)}
					/>
					{pagination.page < pagination.last_page &&
						tasks.length !== 0 && (
							<Card.LoadMore
								variant="more"
								value={false}
								onClick={() => {
									actions.list(
										{
											page:
												tasks.length === 0 &&
												pagination.last_page >
													pagination.page
													? 1
													: pagination.page + 1,
										},
										{
											appendResult: true,
										}
									);
								}}
							/>
						)}
				</div>
			)}
		</Draggable.Drop>
	);
};

MyTaskInboxCheckList.locale = {
	nl: {
		today: "Jouw taken voor vandaag",
		empty: {
			title: "Geen taken voor vandaag gevonden",
			description: "Klik hier om taken toe te voegen",
		},
		add: "Taak toevoegen",
	},
	en: {
		today: "Your tasks for today",
		empty: {
			title: "No tasks found",
			description: "Click here to create a new task",
		},
		add: "Add task",
	},
};

export default withTranslation(MyTaskInboxCheckList);
