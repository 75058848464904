import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Trans, useTranslation } from "react-i18next";
import Button from "src/components/Button";
import SEO from "src/components/layout/SEO";
import { logout } from "src/redux/auth";
import useTenant from "src/hooks/api/services/tenants/useTenant";

const BlockedAccount = () => {
	const { t } = useTranslation("auth-view", {
		keyPrefix: "blocked-account",
	});
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const auth = useSelector((state: any) => state.auth);
	const { tenant } = useTenant();
	const handleLogout = () => {
		dispatch(logout());
		navigate("/");
	};
	return (
		<>
			<SEO title="Geblokkeerd" />
			<div className="flex flex-col flex-1 justify-center items-center">
				<div className="max-w-md flex flex-col gap-4">
					<h1>{t("title")}</h1>
					<p>
						<Trans i18nKey="description" {...{ t }}>
							Door verdachte activiteiten is je account (
							{{ email: auth?.email || "" }}) geblokkeerd, neem
							contact op met
							<a
								className="highlight-green"
								href={`mailto: support@${tenant?.urls?.domain}`}
							>
								support@{tenant?.urls?.domain}
							</a>
						</Trans>
					</p>
					<div className="flex">
						<Button type="dark" onClick={handleLogout}>
							{t("button")}
						</Button>
					</div>
				</div>
			</div>
		</>
	);
};

export default BlockedAccount;
