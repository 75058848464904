import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Button from "src/components/Button";
import Input from "src/components/form/Input";
import Switch from "src/components/form/Switch";
import withTranslation, {
	Translation,
} from "src/components/hoc/withTranslation";
import useFeedback from "src/hooks/api/services/useFeedback";
import useModal from "src/hooks/useModal";

interface CreateFeedbackModalProps extends Translation {
	children?: any;
}

const CreateFeedbackModal = ({ t, children }: CreateFeedbackModalProps) => {
	const navigate = useNavigate();
	const { actions, status } = useFeedback();
	const [form, setForm] = useState({
		title: "",
		description: "",
		visibility: "PUBLIC",
	});
	const modal = useModal();

	const onSubmit = (e?: any) => {
		if (e) e.preventDefault();
		if (status !== "idle") return;

		actions.create(form).then(() => {
			actions.list({
				page: 1,
			});
			navigate("/feedback");
		});
	};

	useEffect(() => {
		modal.setTitle(t("title"));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<form {...{ onSubmit }} className="flex flex-col gap-4">
			<Input
				name="title"
				label={t("fields.title.label")}
				placeholder={t("fields.title.placeholder")}
				value={form.title}
				onChange={(key, value) =>
					setForm((state: any) => ({
						...state,
						[key]: value,
					}))
				}
			/>
			<Input
				name="description"
				label={t("fields.description.label")}
				placeholder={t("fields.description.placeholder")}
				multiline
				value={form.description}
				onChange={(key, value) =>
					setForm((state: any) => ({
						...state,
						[key]: value,
					}))
				}
			/>
			<Switch
				value={form.visibility === "ANONYMOUS"}
				onChange={() =>
					setForm((state: any) => ({
						...state,
						visibility:
							state.visibility === "ANONYMOUS"
								? "PUBLIC"
								: "ANONYMOUS",
					}))
				}
				label={t("fields.visibility.label")}
				subLabel={t("fields.visibility.sublabel")}
			/>
			<div className="mt-4">
				<Button
					loading={status !== "idle"}
					disabled={
						!form.title || !form.description || status !== "idle"
					}
					className="w-full"
					submit
					type="dark"
				>
					{t("button.create")}
				</Button>
			</div>
		</form>
	);
};

CreateFeedbackModal.locale = {
	nl: {
		title: "Feedback posten",
		fields: {
			title: {
				label: "Functie titel",
				placeholder: "Bestanden toevoegen aan taken...",
			},
			description: {
				label: "Omschrijf je feedback",
				placeholder: "Het zou fijn zijn om bestanden...",
			},
			visibility: {
				label: "Anoniem posten",
				sublabel:
					"Andere gebruikers zien niet dat jij feedback hebt gepost",
			},
		},
		button: {
			create: "Posten",
		},
	},
	en: {
		title: "Post Feedback",
		fields: {
			title: {
				label: "Feature title",
				placeholder: "Upload files to a task...",
			},
			description: {
				label: "Describe your feedback",
				placeholder: "It would help to be able to upload...",
			},
			visibility: {
				label: "Post anonymous",
				sublabel: "Other users won't see that you posted this feedback",
			},
		},
		button: {
			create: "Post",
		},
	},
};

export default withTranslation(CreateFeedbackModal);
