import { useState } from "react";
import { ApiSkill, ApiTask, ApiTaskKanban } from "src/api/types";
import Button from "src/components/Button";
import Loader from "src/components/Loader";
import * as SkillSelector from "src/components/form/skill-selector/SkillSelector";
import withTranslation, {
	Translation,
} from "src/components/hoc/withTranslation";
import useTask from "src/hooks/api/services/tasks/task/useTask";
import useSocketListener from "src/hooks/sockets/useSocketListener";

interface TaskSkillsProps extends Translation {
	task: ApiTask | ApiTaskKanban;
	onChange?: (skills: ApiSkill[], method?: "update") => void;
}

const TaskSkills = ({ t, task: _task, onChange }: TaskSkillsProps) => {
	const { actions, task, update } = useTask({ id: _task.hid });
	const skills = update?.skills || task?.skills || [];
	const [status, setStatus] = useState("idle");
	useSocketListener(`TASK.${task.hid}.INDEXED`, (res) => {
		if (res?.skills && onChange) {
			onChange(res?.skills);
		}
		setStatus("idle");
	});

	useSocketListener(`TASK.${task.hid}.INDEXING`, (res) => {
		setStatus("loading");
		if (res?.skills && onChange) {
			setStatus("loading");
		}
	});

	const handleIndexSkills = async () => {
		setStatus("loading");
		try {
			const data = await actions.indexSkills();
			if (onChange) {
				onChange(data);
			}
		} catch (error) {
			setStatus("idle");
		}
	};

	return (
		<div className="flex flex-col">
			{!!skills.length ? (
				<SkillSelector.Root
					onChange={(skills) => actions.change({ skills })}
					value={skills}
					theme={{
						default: {
							className: "text-sm bg-accent border border-border",
							size: "small",
						},
						active: {
							className:
								"text-sm bg-accent border border-border text-background-foreground",
						},
					}}
					options={{
						limit: 4,
						methods: {
							delete: true,
						},
					}}
				>
					<SkillSelector.Results />
				</SkillSelector.Root>
			) : status === "loading" ? (
				<div className="flex items-center gap-2 cursor-wait h-[45.5px]">
					<Loader variant="ai" className="w-8 h-8" />
					<span className="">{t("loading")}</span>
				</div>
			) : (
				<Button onClick={handleIndexSkills} type="ghost">
					<i className="fas fa-search mr-2 text-[12px]"></i>
					{t("button")}
				</Button>
			)}
		</div>
	);
};

TaskSkills.locale = {
	nl: {
		button: "Skills zoeken",
		loading: "Skills worden gezocht",
	},
	en: {
		button: "Search skills",
		loading: "Searching skills",
	},
};

export default withTranslation(TaskSkills);
