import { useState } from "react";
import Button from "src/components/Button";
import ConnectionDropdown from "src/components/form/Dropdown/ConnectionDropdown";
import withTranslation, {
	Translation,
} from "src/components/hoc/withTranslation";
import useAuth from "src/hooks/selectors/useAuth";

interface PaymentRequestSelectFormProps extends Translation {
	onSubmit: (values: string) => void;
}

const PaymentRequestSelectForm = ({
	t,
	onSubmit: _onSubmit,
}: PaymentRequestSelectFormProps) => {
	const auth = useAuth();
	const [value, setValue] = useState<any>(null);

	const onSubmit = async (event: any) => {
		event.preventDefault();
		_onSubmit(value);
	};

	return (
		<form {...{ onSubmit }} className="flex flex-col gap-4">
			<ConnectionDropdown
				label={t(
					auth.type === "student" ? "company.label" : "receiver.label"
				)}
				placeholder={t(
					auth.type === "student"
						? "company.placeholder"
						: "receiver.placeholder"
				)}
				value={value}
				onChange={(value) => {
					setValue(value);
				}}
			/>
			<Button submit>{t("buttons.select")}</Button>
		</form>
	);
};

PaymentRequestSelectForm.locale = {
	nl: {
		buttons: {
			select: "Selecteer",
		},
		company: {
			label: "Opdrachtgever",
			placeholder: "Selecteer een opdrachtgever",
		},
		receiver: {
			label: "Opdrachtnemer",
			Placeholder: "Selecteer een opdrachtnemer",
		},
	},
	en: {
		buttons: {
			select: "Select",
		},
		company: {
			label: "Company",
			placeholder: "Select a company",
		},
		receiver: {
			label: "Receiver",
			placeholder: "Select a receiver",
		},
	},
};

export default withTranslation(PaymentRequestSelectForm);
