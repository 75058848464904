import { ComponentProps } from "react";
import { ApiLinkedSkill } from "src/api/types";
import withTranslation, {
	Translation,
} from "src/components/hoc/withTranslation";
import { cn } from "src/lib/utils";

interface TitleProps {
	type: ApiLinkedSkill["type"];
}

export const Title = withTranslation(
	({
		t,
		className,
		type,
		...rest
	}: TitleProps & Translation & ComponentProps<"strong">) => {
		return (
			<strong {...rest} className={cn("", className)}>
				{t(`${type}.title`)}
			</strong>
		);
	},
	{
		nl: {
			KNOWLEDGE: {
				title: "Kennis vaardigheden",
			},
			COMPETENCE: {
				title: "Competentie vaardigheden",
			},
		},
		en: {
			KNOWLEDGE: {
				title: "Knowledge skills",
			},
			COMPETENCE: {
				title: "Competence skills",
			},
		},
	}
);

interface DescriptionProps {
	type: ApiLinkedSkill["type"];
}

export const Description = withTranslation(
	({
		t,
		className,
		type,
		...rest
	}: DescriptionProps & Translation & ComponentProps<"p">) => {
		return (
			<p {...rest} className={cn("opacity-80", className)}>
				{t(`${type}.description`)}
			</p>
		);
	},
	{
		nl: {
			KNOWLEDGE: {
				description:
					"Kennis vaardigheden zijn feiten, theorieën en inzichten die nodig zijn om een taak of beroep goed uit te voeren.",
			},
			COMPETENCE: {
				description:
					"Competentie vaardigheden zijn vaardigheden die je moet kunnen toepassen in de praktijk.",
			},
		},
		en: {
			KNOWLEDGE: {
				description:
					"Knowledge skills are facts, theories, and insights that are needed to complete a task or assignment successfully.",
			},
			COMPETENCE: {
				description:
					"Competence skills are skills that you need to be able to apply in the work.",
			},
		},
	}
);
