const elements = {
	student: {
		"referral-card": {
			title: "Jouw netwerk is geld waard",
			description:
				"Verdien €5,- per aangemelde vriend of tot wel €50,- per bedrijf",
			buttons: {
				"invite-friends": "Vrienden uitnodigen",
				"invite-companies": "Bedrijven uitnodigen",
			},
		},
	},
	"tip-card": {
		title: "💡Tip van het huis",
	},
	"payment-balance": {
		empty: {
			title: "Je account is nog niet compleet",
			description:
				"Wanneer je je profiel volledig hebt ingevuld zullen hier je verdiensten staan",
		},
		status: {
			reserved: "Gereserveerd",
			coming: "Onderweg",
			paid: "Uitbetaald",
		},
	},
};

export default elements;
