import Card from "src/components/Card";
import withTranslation, {
	Translation,
} from "src/components/hoc/withTranslation";

interface ComplianceItemProps extends Translation {
	object_redirect_url: string | null;
	object_type: string;
	object_uid: null;
	object_url: string;
	status: string;
	type: string;
}

const ComplianceItem = ({
	t,
	object_type,
	status,
	object_redirect_url,
}: ComplianceItemProps) => {
	const titles = {
		contact: t("contact"),
		bank_account: t("bank_account"),
	} as any;

	if (!object_redirect_url) {
		return <></>;
	}

	return (
		<Card
			target="_blank"
			to={
				status !== "pending"
					? object_redirect_url || undefined
					: undefined
			}
			className="relative overflow-hidden"
		>
			{status !== "verified" && (
				<div className="w-1 bg-red absolute left-0 top-0 bottom-0"></div>
			)}
			<div className="flex gap-4 px-4 items-center">
				<ComplianceIcon {...{ status }} />
				<span className="flex-1">{titles[object_type] || ""}</span>
				{status !== "pending" && object_redirect_url && (
					<i className="fal fa-angle-right"></i>
				)}
				{(status === "pending" || !object_redirect_url) && (
					<small className="opacity-40">{t("pending")}</small>
				)}
			</div>
		</Card>
	);
};

const ComplianceIcon = ({ status }: { status: string }) => {
	const classes = ["fas"];

	if (status === "pending") {
		classes.push("fa-clock");
	} else if (status === "unverified") {
		classes.push("fa-info");
	}

	return <i className={classes.join(" ")}></i>;
};

ComplianceItem.locale = {
	nl: {
		contact: "Contactgegevens",
		bank_account: "Bankrekening",
		pending: "In afwachting",
		cta: "Bekijk account status",
	},
	en: {
		contact: "Contact information",
		bank_account: "Bank account",
		pending: "Pending",
		cta: "View account status",
	},
};

export default withTranslation(ComplianceItem);
