import { useParams } from "react-router-dom";
import { ApiBoard } from "src/api/types";
import Button from "src/components/Button";
import Field from "src/components/field/Field";
import EditableText from "src/components/form/EditableText";
import * as SkillSelector from "src/components/form/skill-selector/SkillSelector";
import withTranslation, {
	Translation,
} from "src/components/hoc/withTranslation";
import Icon from "src/components/icon/Icon";
import IconPicker from "src/components/icon/IconPicker";
import Accordion from "src/components/layout/Accordion";
import TaskFilterForm, {
	TaskFilterName,
} from "src/components/tasks/TaskFilterForm";
import useBoards from "src/hooks/api/services/tasks/useBoards";
import useBoardForm from "src/hooks/api/tasks/useBoardForm";
import useAuth from "src/hooks/selectors/useAuth";

interface BoardFormProps extends Translation {
	exclude?: string[];
	onSubmit: (board: ApiBoard) => void;
}

const BoardForm = ({ t, onSubmit, exclude }: BoardFormProps) => {
	const auth = useAuth();
	const { slug } = useParams();
	const { status } = useBoards();
	const { board, isDisabled, actions } = useBoardForm();

	return (
		<>
			<div className="flex items-end gap-4 mb-8">
				<IconPicker
					trigger={
						<Icon className="bg-accent w-20" icon={board?.icon} />
					}
					onChange={(emoji) =>
						actions.set({
							icon: {
								emoji,
							},
						})
					}
					value={board?.icon?.emoji}
				/>
				<EditableText
					placeholder={t("fields.name.placeholder")}
					value={board.name}
					variant={"large"}
					className="flex-1 bg-transparent"
					onChange={(e: any) => {
						actions.set({
							name: e?.target?.value,
						});
					}}
				/>
			</div>

			<div className="flex flex-col">
				{auth.type === "company" && (
					<>
						<Accordion
							defaultVisible
							head={{
								icon: Icon.getFontAwesome("fa-filter"),
								title: t("filter.title"),
								subtitle: t("filter.description"),
							}}
						>
							<Accordion.Content
								options={{
									iconOffset: true,
								}}
								variant="bordered"
							>
								<TaskFilterForm
									options={{
										canCreateTags: true,
									}}
									exclude={
										exclude
											? (exclude as TaskFilterName[])
											: [
													"search",
													"match",
													"assignees",
													"creator",
													"team",
													"completed_tasks",
											  ]
									}
								/>
							</Accordion.Content>
						</Accordion>

						<Accordion
							head={{
								icon: Icon.getFontAwesome("fa-eye"),
								title: t("visibility.title"),
								subtitle: t("visibility.description"),
							}}
						>
							<Accordion.Content
								options={{
									iconOffset: true,
								}}
								variant="bordered"
							>
								<Field.Team
									value={board.team}
									onChange={(value) => {
										actions.set({
											team: value,
										});
									}}
									wrapper={{
										layout: "horizontal",
										label: t("fields.team.label"),
									}}
								/>
								<Field.Select
									value={board.visibility}
									options={[
										{
											value: "PUBLIC",
											label: t(
												`fields.visibility.options.PUBLIC.label`
											),
										},
										{
											value: "PRIVATE",
											label: t(
												`fields.visibility.options.PRIVATE.label`
											),
										},
									]}
									onChange={(value) =>
										actions.set({
											visibility: value,
										})
									}
									wrapper={{
										layout: "horizontal",
										label: t("fields.visibility.label"),
										// description: t(
										// 	`fields.visibility.options.${board.visibility}.description`
										// ),
									}}
								/>
								{board.visibility === "PRIVATE" && (
									<Field.Users
										value={board.users}
										onChange={(value) => {
											actions.set({
												users: value,
											});
										}}
										wrapper={{
											layout: "horizontal",
											label: t("fields.members.label"),
										}}
										options={{
											excludeMe: true,
											stayOpen: true,
										}}
									/>
								)}
								<small className="italic text-placeholder">
									{t(
										`fields.visibility.options.${board.visibility}.description`
									)}
								</small>
							</Accordion.Content>
						</Accordion>
					</>
				)}
				<Accordion
					head={{
						icon: Icon.getFontAwesome("fa-user-circle"),
						title: t("skills.title"),
						subtitle: t("skills.description"),
					}}
				>
					<Accordion.Content
						options={{
							iconOffset: true,
						}}
						variant="bordered"
					>
						<SkillSelector.Root
							onChange={(skills) => actions.set({ skills })}
							value={board.skills}
							defaultSearch={board.team}
							theme={{
								active: {
									className:
										"bg-primary border border-primary",
								},
								inactive: {
									className:
										"bg-border text-background-foreground border border-border",
								},
								default: {
									size: "small",
								},
							}}
						>
							<SkillSelector.Results />
							<SkillSelector.Input />
						</SkillSelector.Root>
					</Accordion.Content>
				</Accordion>
				<div className="flex items-center justify-between mt-12 gap-4 md:flex-row flex-direction">
					<Button
						disabled={status !== "idle"}
						to={slug ? `/tasks/boards/${slug}` : "/tasks"}
						type="gray"
					>
						{t("buttons.cancel")}
					</Button>
					<Button
						disabled={isDisabled}
						onClick={onSubmit}
						type="dark"
						loading={status !== "idle"}
					>
						{t(board.slug ? "buttons.save" : "buttons.create")}
					</Button>
				</div>
			</div>
		</>
	);
};

BoardForm.locale = {
	nl: {
		filter: {
			title: "Filters",
			description:
				"Op basis van deze filters worden de taken in het bord weergegeven",
		},
		visibility: {
			title: "Zichtbaarheid & deelnemers",
			description: "Wie mogen de taken binnen het bord zien",
		},
		skills: {
			title: "Vaardigheden",
			description:
				"De vaardigheden die je nodig hebt om te werken binnen dit bord",
		},
		fields: {
			name: {
				placeholder: "Bord naam...",
			},
			members: {
				label: "Deelnemers",
			},
			team: {
				label: "Team",
			},
			visibility: {
				label: "Zichtbaarheid",
				options: {
					PUBLIC: {
						label: "Openbaar",
						description:
							"Iedereen kan de taken van het bord inzien",
					},
					PRIVATE: {
						label: "Afgesloten",
						description:
							"Alleen deelnemers kunnen deze taken zien, daar buiten zijn ze niet zichtbaar.",
					},
				},
			},
		},
		buttons: {
			cancel: "Annuleren",
			create: "Aanmaken",
			save: "Opslaan",
		},
	},
	en: {
		filter: {
			title: "Filters",
			description:
				"These filters determine how the tasks are displayed on the board.",
		},
		visibility: {
			title: "Visibility & Members",
			description: "Who can see the tasks within the board.",
		},
		skills: {
			title: "Skills",
			description: "The skills you need to work within this board",
		},
		fields: {
			name: {
				placeholder: "Board name...",
			},
			members: {
				label: "Members",
			},
			team: {
				label: "Team",
			},
			visibility: {
				label: "Visibility",
				options: {
					PUBLIC: {
						label: "Public",
						description: "Anyone can view the tasks on the board.",
					},
					PRIVATE: {
						label: "Private",
						description:
							"Only members can see these tasks; they are not visible to others.",
					},
				},
			},
		},
		buttons: {
			cancel: "Cancel",
			create: "Create",
			save: "Save",
		},
	},
};

export default withTranslation(BoardForm);
