import { motion } from "framer-motion";
import { useEffect, useState } from "react";
import Button from "src/components/Button";
import Loader from "src/components/Loader";
import Logo from "src/components/Logo";
import withTranslation, {
	Translation,
} from "src/components/hoc/withTranslation";
import { MessageType } from "src/components/tasks/create-task-chat/CreateTaskChat";
import useTenant from "src/hooks/api/services/tenants/useTenant";
import useTheme from "src/hooks/useTheme";
import { cn } from "src/lib/utils";

export type FormattedTaskChat = {
	title: string;
	answers: {
		question: string;
		answer: string;
	}[];
};

interface FinalProps extends Translation {
	messages: MessageType[];
	onScrollToBottom: () => void;
	onSubmit: () => void;
	isLoading?: boolean;
	formatted?: FormattedTaskChat;
	autoSubmit?: boolean;
}

const Final = ({
	t,
	onScrollToBottom,
	onSubmit: _onSubmit,
	isLoading,
	onSubmit,
	autoSubmit,
}: FinalProps) => {
	const { theme } = useTheme();
	const { tenant } = useTenant();
	// const { actions, status } = useTaskForm();
	const [isFakeLoading, setIsFakeLoading] = useState(true);

	useEffect(() => {
		if (autoSubmit) {
			onSubmit();
		}
		setTimeout(() => {
			setIsFakeLoading(false);
			setTimeout(() => {
				onScrollToBottom();
			}, 200);
		}, 500);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<motion.div
			layout
			className={cn(
				"flex justify-start gap-2",
				!isFakeLoading && "justify-center"
			)}
		>
			<motion.div layout className={cn("flex flex-col gap-1")}>
				<div className="flex items-center gap-1">
					<Logo
						color={theme === "dark" ? "white" : "dark"}
						variant="small-outline"
						className="w-4 h-4"
					/>
					<small>{tenant.code}</small>
				</div>
				<div className="">
					{isFakeLoading ? (
						<div className="p-4">
							<Loader />
						</div>
					) : (
						<motion.div
							className={cn(
								"flex flex-col gap-3 p-4 rounded-xl bg-accent dark:bg-background max-w-[380px]"
							)}
						>
							<div className="flex flex-col">
								<strong>{t("title")}</strong>
								<p>{t("description")}</p>
							</div>
							<Button
								loading={isLoading}
								type="primary"
								onClick={onSubmit}
							>
								<span className="relative">
									<span className="absolute top-0 bottom-0 -left-8 flex items-center">
										<i className="fas fa-sparkles"></i>
									</span>
									{t("generate")}
								</span>
							</Button>
						</motion.div>
					)}
				</div>
			</motion.div>
		</motion.div>
	);
};

Final.locale = {
	nl: {
		generate: "Genereer opdracht",
		title: "We weten genoeg!",
		description:
			"Genereer een opdracht en geef er zelf een opdrachtwaarde aan",
		questions: {
			GOAL: "Wat is het doel van de opdracht?",
			TASKS: "Wat voor taken vallen er onder de opdracht?",
			DELIVER: "Hoe moet de opdracht opgeleverd worden?",
		},
	},
	en: {
		generate: "Generate assignment",
		title: "We know enough!",
		description: "Generate an assignment and assign a value to it yourself",
		questions: {
			GOAL: "What is the goal of the assignment?",
			TASKS: "What tasks are involved in the assignment?",
			DELIVER: "How should the assignment be delivered?",
		},
	},
};

export default withTranslation(Final);
