import React from "react";
import { useTranslation } from "react-i18next";
import { ApiOffer } from "src/api/types";
import Card from "src/components/Card";
import Link from "src/components/Link";
import ReadMore from "src/components/ReadMore";
import Skeleton from "src/components/Skeleton";

interface OfferDescriptionCardProps {
	offer: ApiOffer;
	className?: string;
}

const OfferDescriptionCard = ({
	offer,
	className,
}: OfferDescriptionCardProps) => {
	const { t } = useTranslation(["buttons", "common"]);
	return (
		<Card {...{ className }}>
			<div className="flex items-center justify-between mb-2">
				<strong>{offer.title}</strong>
				<Link to={`/offers/${offer.slug}/edit`}>
					<small className="opacity-30">
						{t("edit", { ns: "buttons" })}
					</small>
				</Link>
			</div>
			<ReadMore
				className="mb-0 description"
				text={offer?.description_formatted || ""}
				length={300}
			/>

			{offer.tags.length ? (
				<div className="mt-4">
					<strong className="mb-2 block">
						{t("skills", { ns: "common" })}
					</strong>
					<div className="flex flex-wrap gap-2">
						{(offer.tags || []).map((tag: string) => (
							<div
								key={tag}
								className="p-2 px-3 bg-primary text-white rounded-md flex items-center gap-2"
							>
								<span>{tag}</span>
							</div>
						))}
					</div>
				</div>
			) : (
				false
			)}
		</Card>
	);
};

const Loading = ({ className }: { className?: string }) => (
	<Card {...{ className }}>
		<div className="flex gap-4 items-center justify-between">
			<Skeleton className="h-8 w-[30%]" />
			<Skeleton className="h-8 w-[20%]" />
		</div>
		<div className="flex flex-col gap-4 mt-4">
			<Skeleton className="h-6 w-full" />
			<Skeleton className="h-6 w-full" />
			<Skeleton className="h-6 w-full" />
		</div>
	</Card>
);

OfferDescriptionCard.Loading = Loading;

export default OfferDescriptionCard;
