import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Avatar from "src/components/Avatar";
import Button from "src/components/Button";
import withTranslation, {
	Translation,
} from "src/components/hoc/withTranslation";
import AuthLayout from "src/components/layout/auth-layout/AuthLayout";
import CompanyInfoSearchList from "src/components/lists/CompanyInfoSearchList";
import useProspect from "src/hooks/api/services/auth/useProspect";

function SignupCompanySearchView({ t }: Translation) {
	const { slug } = useParams() as any;
	const navigate = useNavigate();
	const { prospect, actions, status } = useProspect();
	useEffect(() => {
		if (slug) {
			actions.get(slug).catch(() => {
				navigate("/signup/company");
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<AuthLayout
			head={{
				title: t("title"),
				back: {
					to: "/signup/company",
					label: t("back"),
				},
			}}
			loading={status === "loading"}
		>
			<div className="flex flex-col gap-4">
				<div className="flex gap-4 items-end">
					{prospect.icon && (
						<Avatar
							size="medium"
							variant="white"
							src={prospect?.icon}
						/>
					)}
					<div className="flex flex-col">
						<strong>{prospect.company_name}</strong>
						{(prospect?.website || prospect?.kvk) && (
							<span>
								{prospect?.kvk
									? t("kvk", {
											kvk: prospect.kvk,
									  })
									: prospect.website}
							</span>
						)}
					</div>
				</div>

				<CompanyInfoSearchList
					search={{
						value: prospect?.extra?.kvk || prospect.company_name,
					}}
					item={{
						onClick: (id) =>
							navigate(
								`/signup/company/${slug}/information?info=${id}`
							),
					}}
				/>
				<div className="flex flex-col gap-4 mt-4">
					<p>{t("manual-disclaimer")}</p>
					<div className="">
						<Button
							to={`/signup/company/${slug}/information`}
							type="gray"
						>
							{t("buttons.manual")}
						</Button>
					</div>
				</div>
			</div>
		</AuthLayout>
	);
}

SignupCompanySearchView.locale = {
	nl: {
		title: "Aanmelden als bedrijf",
		back: "Terug naar aanmelden",
		kvk: "kvk: {{kvk}}",
		"manual-disclaimer":
			"Staat je bedrijf niet tussen de zoekresultaten? Gebruik de ondertaande knop om je bedrijfsgegevens handmatig in te vullen",
		buttons: {
			manual: "Handmatig invoeren",
			continue: "Doorgaan",
		},
	},
	en: {
		title: "I want to outsource tasks",
		back: "Back to signup",
		kvk: "chamber of commerce: {{kvk}}",
		"manual-disclaimer":
			"Are you not able to find your company, no worries. Use the button below to add your details manual",
		buttons: {
			manual: "Continue manually",
			continue: "Continue",
		},
	},
};
export default withTranslation(SignupCompanySearchView);
