import { HTMLAttributes } from "react";
import InterestsForm from "src/components/resume/elements/InterestsForm";
import VideoPitchForm from "src/components/resume/elements/VideoPitchForm";
import EducationForm from "./elements/EducationForm";
import GeneralInfoForm from "./elements/GeneralInfoForm";
import SkillForm from "./elements/SkillForm";
import WorkExperienceForm from "./elements/WorkExperienceForm";

type ChildrenProps = {
	isValid: boolean;
	isLoading: boolean;
	onSubmit: () => void;
};

export interface ResumeFormProps {
	children?: (props: ChildrenProps) => JSX.Element;
	onCompleted?: () => void;
}

const ResumeForm = ({ children, ...rest }: HTMLAttributes<HTMLFormElement>) => {
	return <form {...rest}>{children}</form>;
};

ResumeForm.GeneralInfo = GeneralInfoForm;
ResumeForm.Education = EducationForm;
ResumeForm.WorkExperience = WorkExperienceForm;
ResumeForm.Skill = SkillForm;
ResumeForm.Interests = InterestsForm;
ResumeForm.VideoPitch = VideoPitchForm;

export default ResumeForm;
