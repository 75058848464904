import { ComponentProps, useEffect, useState } from "react";
import { ApiForm } from "src/api/types";
import Button from "src/components/Button";
import CustomFormField from "src/components/custom-form/CustomFormField";
import withTranslation, {
	Translation,
} from "src/components/hoc/withTranslation";
import { cn } from "src/lib/utils";

interface CustomFormProps extends Translation {
	form: ApiForm;
	value?: any;
	onChange?: (values: any) => any;
	onSubmit?: (values: any) => Promise<any>;
	layout?: "horizontal" | "vertical";
}

const CustomForm = ({
	t,
	className,
	form,
	value: defaultValue,
	onChange,
	onSubmit,
	layout = "vertical",
	...rest
}: CustomFormProps & Omit<ComponentProps<"div">, "children">) => {
	const [value, setValue] = useState<any>(defaultValue || {});

	useEffect(() => {
		if (onChange) {
			onChange(value);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [value]);

	return (
		<div {...rest} className={cn("flex flex-col gap-2", className)}>
			{form.fields.map((field) => (
				<CustomFormField
					onChange={(update: any) =>
						setValue({
							...value,
							[field.id]: {
								...value[field.id],
								value: update,
							},
						})
					}
					{...{ layout }}
					value={value[field.id]?.value}
					key={field.id}
					field={field}
				/>
			))}
			{onSubmit && (
				<div className="flex mt-4">
					<Button small onClick={async () => await onSubmit(value)}>
						{t("buttons.save")}
					</Button>
				</div>
			)}
		</div>
	);
};

CustomForm.locale = {
	nl: {
		buttons: {
			save: "Opslaan",
		},
	},
	en: {
		buttons: {
			save: "Save",
		},
	},
};

export default withTranslation(CustomForm);
