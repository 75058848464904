import { motion } from "framer-motion";
import moment from "moment";
import { useState } from "react";
import { ApiSubscription, ApiSubscriptionItem } from "src/api/types";
import { formatDecimal, formatPrice } from "src/lib/formatters";
import Button from "src/components/Button";
import PaymentBadge from "src/components/payment/PaymentBadge";
import { useTranslation } from "react-i18next";
import Table from "src/components/Table";
import useTenant from "src/hooks/api/services/tenants/useTenant";

interface SubscriptionPaymentItemProps {
	subscription: ApiSubscription;
}

const SubscriptionPaymentItem = ({
	subscription,
}: SubscriptionPaymentItemProps) => {
	const { tenant } = useTenant();
	const { t } = useTranslation("subscription", {
		keyPrefix: "subscription-payment-item",
	});
	const [isToggled, setIsToggled] = useState(false);
	const items = subscription?.items || [];
	const payment = subscription?.payment;

	const renderBadge = (item: ApiSubscription) => {
		let status = item?.payment?.status || "pending";

		if (!item.payment && item.credit_log) {
			status = "completed";
		}

		return <PaymentBadge {...{ status }} />;
	};

	const renderPrice = (price: number) => {
		return (
			<span className="grid grid-cols-[30px,1fr]">
				<span>&euro;</span>
				<span className="block min-w-[60px]">
					{formatDecimal(price)}
				</span>
			</span>
		);
	};

	return (
		<>
			<Table.Row>
				<Table.Cell>
					<strong className="p-4">
						{formatPrice(subscription.total_price)}
					</strong>
				</Table.Cell>
				<Table.Cell>{renderBadge(subscription)}</Table.Cell>
				<Table.Cell>
					<p className="whitespace-nowrap">
						{tenant.name}, {subscription.plan.name}
					</p>
				</Table.Cell>
				<Table.Cell>
					<span className="whitespace-nowrap">
						{moment(subscription.started_at).format("DD-MM-YYYY")}
						{" - "}
						{moment(subscription.ends_at).format("DD-MM-YYYY")}
					</span>
				</Table.Cell>
				<Table.Cell>
					<div className="flex justify-end">
						<ToggleButton
							{...{ isToggled }}
							onClick={() => setIsToggled(!isToggled)}
						/>
					</div>
				</Table.Cell>
			</Table.Row>

			{isToggled && (
				<Table.Row>
					<Table.Cell colSpan={5}>
						<div className="flex flex-col gap-8 md:grid grid-cols-3">
							<div className="col-span-2">
								<strong>{t("summary.title")}</strong>
								<ul className="mt-4">
									{items.map(
										(item: ApiSubscriptionItem, index) => (
											<li
												key={`parent-${item.description}-${index}`}
												className="mb-2"
											>
												<div className="flex gap-2 justify-between">
													<p>{item.description}</p>
													{renderPrice(item.price)}
												</div>
												{item?.items && (
													<ul className="ml-8 relative opacity-50">
														<div className="absolute top-0 -left-6">
															<i className="far opacity-20 transform rotate-90 fa-level-up-alt"></i>
														</div>
														{item?.items.map(
															(item, index) => (
																<li
																	key={`item-${item.price}-${index}`}
																	className="flex gap-2 justify-between"
																>
																	<p>
																		{
																			item.description
																		}
																	</p>
																	{renderPrice(
																		item.price
																	)}
																</li>
															)
														)}
													</ul>
												)}
											</li>
										)
									)}
								</ul>
							</div>
							<div className="">
								<strong>{t("information.title")}</strong>
								<div className="mt-4 flex flex-col gap-2">
									{subscription?.started_at && (
										<p className="flex items-center">
											<span className="block flex-1">
												{t("information.start-date")}
											</span>{" "}
											{moment(
												subscription.started_at
											).format("DD-MM-YYYY")}
										</p>
									)}

									<p className="flex items-center">
										<span className="block flex-1">
											{t("information.end-date")}
										</span>{" "}
										{moment(subscription.ends_at).format(
											"DD-MM-YYYY"
										)}
									</p>
								</div>
								{payment && (
									<>
										<div className="mt-4">
											<strong>
												{t("information.invoice")}
											</strong>
											{payment?.invoice_number && (
												<p className="flex items-center mt-4">
													<span className="block flex-1">
														{t(
															"information.invoice-number"
														)}
													</span>{" "}
													{payment?.invoice_number}
												</p>
											)}
											<p className="flex items-center mt-4">
												<span className="block flex-1">
													{t(
														"information.worth-excl-vat"
													)}
												</span>{" "}
												{formatPrice(payment?.price)}
											</p>
										</div>
										<div className="flex justify-center items-center mt-8">
											<Button
												target="_blank"
												small
												to={`/payments/${subscription?.payment?.hid}`}
											>
												{t("button.download-invoice")}
												<i className="fas fa-download ml-2"></i>
											</Button>
										</div>
									</>
								)}
								{subscription?.credit_log && (
									<div className="mt-4">
										<strong>
											{t("credit-log.service-fee")}
										</strong>
										<p className="flex items-center mt-4">
											<span className="block flex-1">
												{t("credit-log.before")}
											</span>{" "}
											{formatPrice(
												subscription.credit_log
													.before_amount
											)}
										</p>
										<p className="flex items-center">
											<span className="block flex-1">
												{t("credit-log.total")}
											</span>{" "}
											{formatPrice(
												subscription.credit_log.amount
											)}
										</p>
										<p className="flex items-center">
											<span className="block flex-1">
												{t("credit-log.after")}
											</span>{" "}
											{formatPrice(
												subscription.credit_log
													.after_amount
											)}
										</p>
									</div>
								)}
							</div>
						</div>
					</Table.Cell>
				</Table.Row>
			)}
		</>
	);
};

interface ToggleButtonProps {
	onClick: () => void;
	isToggled: boolean;
}

const ToggleButton = ({ onClick, isToggled }: ToggleButtonProps) => {
	return (
		<div
			{...{ onClick }}
			className="w-10 h-10 rounded-md bg-dark-1100 hover:bg-dark-900 hover:text-white transition-all flex justify-center items-center cursor-pointer"
		>
			<motion.div
				animate={isToggled ? "open" : "closed"}
				variants={{
					open: {
						rotate: 180,
					},
					closed: {
						rotate: 0,
					},
				}}
			>
				<i className="fas leading-0 fa-angle-down"></i>
			</motion.div>
		</div>
	);
};

export default SubscriptionPaymentItem;
