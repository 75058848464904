import React from "react";
import {
	FacebookShareButton,
	LinkedinShareButton,
	TwitterShareButton,
	WhatsappShareButton,
} from "react-share";
import { ShareTypes } from "src/api/types";
import useTenant from "src/hooks/api/services/tenants/useTenant";
import useAuth from "src/hooks/selectors/useAuth";
import { SHARE_TYPES } from "src/lib/constants";

interface ShareButtonProps {
	type: ShareTypes;
	share: {
		title: string;
		description: string;
		url: string;
	};
	children?: any;
}

const ShareButton = ({ type, share, children }: ShareButtonProps) => {
	const { color, icon } = SHARE_TYPES[type];
	const description = `${share.title}\n\n${share.description}\n\n`;
	const auth = useAuth();
	const { tenant } = useTenant();

	const renderChildren = () => {
		if (children) {
			return (
				<div
					style={{ backgroundColor: color }}
					className="flex items-center p-2 text-white  rounded-md"
				>
					<div className="w-8 h-8 bg-dark-400 rounded-md cursor-pointer flex justify-center items-center">
						<i className={`${icon} w-4 h-4`}></i>
					</div>
					{children && (
						<div className="px-3">
							<strong>{children}</strong>
						</div>
					)}
				</div>
			);
		}

		return (
			<div
				style={{ backgroundColor: color }}
				className="w-8 h-8 rounded-md text-white cursor-pointer flex justify-center items-center"
			>
				<i className={`${icon}`}></i>
			</div>
		);
	};

	if (type === "facebook") {
		return (
			<FacebookShareButton
				hashtag={tenant.name}
				quote={description}
				url={share.url}
			>
				{renderChildren()}
			</FacebookShareButton>
		);
	}

	if (type === "linkedin") {
		return (
			<LinkedinShareButton
				title={share.title}
				summary={description}
				source={share.url}
				url={share.url}
			>
				{renderChildren()}
			</LinkedinShareButton>
		);
	}

	if (type === "whatsapp") {
		return (
			<WhatsappShareButton title={description} url={share.url}>
				{renderChildren()}
			</WhatsappShareButton>
		);
	}

	if (type === "mail") {
		return (
			<a
				href={`mailto: ?subject=${share.title}&body=${description}&reply-to=${auth.email}`}
			>
				{renderChildren()}
			</a>
		);
	}

	if (type === "twitter") {
		return (
			<TwitterShareButton via={description} url={share.url}>
				{renderChildren()}
			</TwitterShareButton>
		);
	}

	return renderChildren();
};

export default ShareButton;
