import { useTranslation } from "react-i18next";
import Progress from "src/components/Progress";

interface TaskMatchProgressProps {
	students: number;
	matched: number;
}

const TaskMatchProgress = ({ students, matched }: TaskMatchProgressProps) => {
	const { t } = useTranslation("tasks", {
		keyPrefix: "task-match-progress",
	});
	return (
		<div className="flex flex-col gap-2">
			<small className="font-bold">
				{matched} / {students} {t("matched")}
			</small>
			<Progress current={matched} total={students} />
		</div>
	);
};

export default TaskMatchProgress;
