import { useState } from "react";
import Alert from "src/components/Alert";
import Button from "src/components/Button";
import Field from "src/components/field/Field";
import Input from "src/components/form/Input";
import withTranslation, {
	Translation,
} from "src/components/hoc/withTranslation";
import useTeams from "src/hooks/api/services/users/useTeams";
import useUsers from "src/hooks/api/services/users/useUsers";
import { ApiStatus } from "src/hooks/api/utils/useApi";
import useAuth from "src/hooks/selectors/useAuth";
import useSubscription from "src/hooks/selectors/useSubscription";
import useModal from "src/hooks/useModal";
import useQueryString from "src/hooks/useQueryString";

const InviteUserModal = ({ t }: Translation) => {
	const qs = useQueryString();
	const { users, actions } = useUsers();
	const auth = useAuth();
	const subscription = useSubscription();
	const { teams } = useTeams();
	const [status, setStatus] = useState<ApiStatus>("idle");
	const [error, setError] = useState<"email-in-use" | "unknown">();
	const [form, setForm] = useState<any>({
		first_name: null,
		last_name: null,
		email: null,
		teams: teams
			.filter((item) => item.hid === qs.team)
			.map((item) => item.slug),
	});
	const modal = useModal({
		title: t("title"),
	});
	const isValid = form.first_name && form.last_name && form.email;
	const canInviteUsers =
		typeof subscription?.plan?.users !== "number" ||
		users.length < (subscription?.plan?.users || 1) ||
		auth.mode === "demo";

	const getFormField = (name: string) => ({
		noMarginBottom: true,
		name,
		value: form[name],
		label: t(`fields.${name}.label`),
		placeholder: t(`fields.${name}.label`),
		onChange: (key: string, value: string) =>
			setForm((state: any) => ({
				...state,
				[key]: value,
			})),
	});

	const onSubmit = (e?: any) => {
		if (e) e.preventDefault();
		if (status !== "idle") return;
		setStatus("loading");
		actions
			.invite(form)
			.then(() => {
				modal.onClose();
			})
			.catch(({ response }) => {
				const errors = response?.data?.data?.errors;
				const keys = Object.keys(errors || {});
				if (keys.includes("email-in-use")) {
					setError("email-in-use");
				} else {
					setError("unknown");
				}
			})
			.finally(() => setStatus("idle"));
	};

	if (!canInviteUsers) {
		return (
			<div className="flex flex-col mx-auto">
				<strong>{t("max.title")}</strong>
				<p className="mb-4">{t("max.text")}</p>
				<Button to="/profile/billing/change">{t("max.button")}</Button>
			</div>
		);
	}

	return (
		<form {...{ onSubmit }} className="max-w-[440px] flex flex-col gap-4">
			{error && <Alert type={"error"}>{t(`errors.${error}`)}</Alert>}

			<Input autoFocus {...getFormField("first_name")} />
			<Input {...getFormField("last_name")} />
			<Input {...getFormField("email")} />
			{teams.length > 0 && (
				<Field.Team
					wrapper={{
						label: t("fields.team.label"),
						layout: "horizontal",
						variant: "form-group",
					}}
					value={form.teams}
					onChange={(teams) =>
						setForm((state: any) => ({
							...state,
							teams,
						}))
					}
				/>
			)}

			<div className="mt-4">
				<Button
					disabled={!isValid}
					loading={status !== "idle"}
					submit
					className="w-full"
					type="dark"
				>
					{t("buttons.invite")}
				</Button>
			</div>
		</form>
	);
};

InviteUserModal.locale = {
	nl: {
		title: "Teamleden uitnodigen",
		max: {
			title: "Maximaal aantal gebruikers",
			text: "Je hebt het maximaal aantal gebruikers gebruikt voor jouw abonnement. Upgrade naar een groter abonnement om meer gebruikers toe te voegen.",
			button: "Abonnement upgraden",
		},
		errors: {
			unknown: "Er is iets misgegaan",
			"email-in-use": "Email is al in gebruik",
		},
		fields: {
			first_name: {
				label: "Voornaam",
			},
			last_name: {
				label: "Achternaam",
			},
			email: {
				label: "Email",
			},
			team: {
				label: "Team",
			},
		},
		buttons: {
			invite: "Uitnodigen",
		},
	},
	en: {
		title: "Invite Team Members",
		max: {
			title: "Maximum Number of Users",
			text: "You have reached the maximum number of users for your subscription. Upgrade to a larger subscription to add more users.",
			button: "Upgrade Subscription",
		},
		errors: {
			unknown: "Something went wrong",
			"email-in-use": "Email is already in use",
		},
		fields: {
			first_name: {
				label: "First Name",
			},
			last_name: {
				label: "Last Name",
			},
			email: {
				label: "Email",
			},
			team: {
				label: "Team",
			},
		},
		buttons: {
			invite: "Invite",
		},
	},
};

export default withTranslation(InviteUserModal);
