import { useMemo } from "react";
import { useLocation } from "react-router-dom";
import Button, { ButtonProps } from "src/components/Button";
import useAuth from "src/hooks/selectors/useAuth";

interface SubscriptionProtectButtonProps extends ButtonProps {}

const SubscriptionProtectButton = ({
	...rest
}: SubscriptionProtectButtonProps) => {
	const location = useLocation();
	const auth = useAuth();
	const to = useMemo(() => {
		const sp = new URLSearchParams(location.search);
		let to = rest.to || "/";
		if (!auth?.has_subscription) {
			to = location.pathname;
			sp.set("modal", "need-subscription");
		}
		return `${to}${sp ? `?${sp.toString()}` : ""}`;
	}, [auth?.has_subscription, location.pathname, location.search, rest.to]);

	return <Button {...rest} {...{ to }} />;
};

export default SubscriptionProtectButton;
