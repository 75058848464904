import { useEffect } from "react";
import BenefitCard, {
	BenefitCardBanner,
	BenefitCardButton,
	BenefitCardDescription,
	BenefitCardFooter,
	BenefitCardHeader,
	BenefitCardImage,
	BenefitCardName,
	BenefitCardRestriction,
} from "src/components/benefits/benefit-card";
import Button from "src/components/Button";
import withTranslation, {
	Translation,
} from "src/components/hoc/withTranslation";
import Layout from "src/components/layout/Layout";
import useShopBenefits from "src/hooks/api/services/benefits/useShopBenefits";

const BenefitsView = ({ t }: Translation) => {
	const { benefits, actions, pagination } = useShopBenefits();

	useEffect(() => {
		actions.list();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<Layout
			title={t("title")}
			breadcrumb={[{ label: t("title"), to: "/benefits" }]}
			container="xxl"
		>
			<div className="grid grid-cols-1 md:grid-cols-3 gap-4">
				{benefits.map((benefit) => (
					<BenefitCard
						key={benefit.id}
						benefit={benefit}
						className="flex-1"
					>
						{benefit.media && (
							<BenefitCardBanner>
								<BenefitCardImage />
							</BenefitCardBanner>
						)}
						<div className="flex flex-col flex-1">
							<BenefitCardHeader className="flex-1">
								<BenefitCardName />
								<BenefitCardDescription />
							</BenefitCardHeader>
							<BenefitCardFooter>
								<BenefitCardButton />
								<BenefitCardRestriction />
							</BenefitCardFooter>
						</div>
					</BenefitCard>
				))}
			</div>
			<div className="flex flex-col justify-center items-center p-3 empty:hidden">
				{pagination.page < pagination.last_page && (
					<Button
						onClick={() => pagination.loadMore(pagination.page + 1)}
					>
						{t("more")}
					</Button>
				)}
			</div>
		</Layout>
	);
};

BenefitsView.locale = {
	nl: {
		title: "Voordelen",
		more: "Laad meer voordelen",
	},
	en: {
		title: "Benefits",
		more: "Load more benefits",
	},
};

export default withTranslation(BenefitsView);
