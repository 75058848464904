import { cn } from "src/lib/utils";

interface IndicatorProps {
	children?: any;
	style?: any;
	className?: string;
}

const Indicator = ({ children, style, className }: IndicatorProps) => {
	return (
		<span
			{...{ style }}
			className={cn(
				"text-xs p-2 w-5 h-5 flex items-center justify-center font-bold bg-red text-white rounded-sm",
				className
			)}
		>
			{children}
		</span>
	);
};

export default Indicator;
