import React from "react";
import { useTranslation } from "react-i18next";
import { ApiUserAvatar } from "src/api/types";
import { AvatarSize } from "src/components/Avatar";

interface TaskMatchProfilesProps {
	users: ApiUserAvatar[];
	avatar?: {
		size: AvatarSize;
	};
}

const TaskMatchProfiles = ({ users, avatar }: TaskMatchProfilesProps) => {
	const { t } = useTranslation("tasks", {
		keyPrefix: "task-match-profile",
	});

	let names = users.map((user) => user.full_name.split(" ")[0]);
	const max = 2;
	const total = names.length;
	const remaining = total - max;
	if (total > max) {
		names = names.splice(0, max);
	}

	return (
		<div className="flex gap-3 items-center">
			{/* <div className="flex relative">
				<Avatar {...avatar} src={user.avatar} />
				<div className="absolute -right-2 -bottom-2">
					<i className="fas fa-check text-green"></i>
				</div>
			</div> */}
			<div className="flex flex-col gap-[2px]">
				<small>{t("matched-with")}</small>
				<small className="leading-1 whitespace-nowrap font-bold">
					{/* {user.full_name} */}
					{names.join(", ")}{" "}
					{remaining ? <sup>{`+ ${remaining}`}</sup> : ""}
				</small>
			</div>
		</div>
	);
};

export default TaskMatchProfiles;
