import withTranslation, {
	Translation,
} from "src/components/hoc/withTranslation";
import EditLayout, {
	EditLayoutDescription,
	EditLayoutHeader,
	EditLayoutTitle,
} from "src/components/resume/edit-layout/EditLayout";
import ResumeSocialMedia from "src/components/resume/modules/ResumeSocialMedia";

const DashboardEditSocialMediaModal = ({ t }: Translation) => {
	return (
		<EditLayout>
			<EditLayoutHeader className="-top-2 md:-top-8">
				<EditLayoutTitle>{t("title")}</EditLayoutTitle>
				<EditLayoutDescription>
					{t("description")}
				</EditLayoutDescription>
			</EditLayoutHeader>
			<div className="flex flex-col mt-8 gap-6">
				<ResumeSocialMedia editable />
			</div>
		</EditLayout>
	);
};

DashboardEditSocialMediaModal.locale = {
	nl: {
		title: "Link je social media accounts",
		description:
			"Voeg bijvoorbeeld je portfolio toe om je werk te tonen aan opdrachtgevers.",
		save: "Opslaan",
	},
	en: {
		title: "Link your social media accounts",
		description:
			"For example, add your portfolio to show your work to job providers.",
		save: "Save",
	},
};

export default withTranslation(DashboardEditSocialMediaModal);
