import { ReactHTML } from "react";

const KeyValue = ({
	label,
	text,
	className,
}: {
	label: any;
	text?: any;
	className?: string;
}) => (
	<div
		className={`key-value ${!className?.includes("mb-") ? "mb-1" : ""} ${
			className || ""
		}`}
	>
		<strong className="key-value-label">{label}:</strong>
		<p>{text || ""}</p>
	</div>
);

interface KeyValueSmallProps {
	label: any;
	text?: any;
	className?: string;
	bold?: boolean;
	labelAs: keyof ReactHTML;
}

const KeyValueSmall = ({
	label,
	text,
	className,
	bold,
	labelAs,
}: KeyValueSmallProps) => {
	const Label = bold ? "strong" : labelAs;
	return (
		<div
			className={`flex items-center justify-between flex-wrap ${
				className || ""
			}`}
		>
			<Label className={`flex`}>{label}:</Label>
			<div className="flex">{text}</div>
		</div>
	);
};

KeyValueSmall.defaultProps = {
	labelAs: "span",
};

KeyValue.Small = KeyValueSmall;

export default KeyValue;
