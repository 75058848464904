import React, { useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import { ApiStep } from "src/api/types";
import {
	Tooltip,
	TooltipContent,
	TooltipProvider,
	TooltipTrigger,
} from "src/components/ui/tooltip";
import useResume from "src/hooks/api/services/resume/useResume";
import useAuth from "src/hooks/selectors/useAuth";
import useWindowSize from "src/hooks/useWindowSize";
import { cutString } from "src/lib/formatters";
import { cn } from "src/lib/utils";

interface StepsLineResumeProps {
	className?: string;
	backgroundColor?: string;
	steps: ApiStep[];
	onClick?: (step: string) => void;
	theme?: "dark";
	stepsLabel: string;
}

const StepsLineResume: React.FC<
	StepsLineResumeProps & React.HTMLProps<HTMLDivElement>
> = ({
	className,
	backgroundColor,
	steps,
	onClick,
	stepsLabel,
	theme,
	...rest
}: StepsLineResumeProps) => {
	const ref = useRef(null);
	const { step: paramStep } = useParams();
	const auth = useAuth();
	const { resume, actions } = useResume({
		rememberKey: auth.id,
	});

	useEffect(() => {
		actions.get(auth.id);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [paramStep]);

	function areKeysPresentInModules(key: string | string[]): boolean {
		const modules: string[] = resume.resume_modules;
		if (modules) {
			if (Array.isArray(key)) {
				return key.every((k) => modules.includes(k));
			} else {
				return modules.includes(key);
			}
		}
		return false;
	}

	return (
		<div
			className={`flex flex-row items-center w-full ${className || ""}`}
			{...{ ref }}
			{...rest}
		>
			{steps.map((step, index) => (
				<Step
					key={`${step}-${index}`}
					active={
						step.name === paramStep ||
						(!paramStep && step.name === "general")
					}
					valid={areKeysPresentInModules(step.key)}
					label={step.label}
					total={4}
					stepsLabel={stepsLabel}
					direction="horizontal"
					{...{ step, index, onClick }}
					onClick={() => {
						if (onClick && areKeysPresentInModules(step.key)) {
							onClick(step.name);
						}
					}}
				/>
			))}
		</div>
	);
};

interface StepProps {
	label: string;
	stepsLabel: string;
	step: ApiStep;
	index: number;
	total: number;
	active: boolean;
	valid: boolean;
	onClick?: () => void;
	direction: "horizontal" | "vertical";
}

const Step = ({
	label,
	stepsLabel,
	step,
	index,
	total,
	active,
	valid,
	onClick,
	direction,
}: StepProps) => {
	const { isTablet, isPhone } = useWindowSize();
	const ref = useRef(null);
	let showLabel = true;
	let cursor = cn(
		"cursor-default",

		onClick && !valid && !active && "cursor-not-allowed",
		onClick && !active && valid && "cursor-pointer"
	);

	if (isTablet && direction === "horizontal") {
		showLabel = !active ? false : true;
	}

	useEffect(() => {
		if (ref && ref?.current && active && direction !== "vertical") {
			const current = ref?.current as any;
			current.scrollIntoView({
				behavior: "smooth",
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [ref, active]);

	return (
		<div className={`${active ? "flex-1" : ""}`}>
			<div
				onClick={() => {
					if ((active || valid) && onClick) {
						onClick();
					}
				}}
				id={`step-${index}`}
				className={`flex items-end pr-3 md:pr-6 gap-2 ${cursor}`}
				{...{ ref }}
			>
				<TooltipProvider>
					<Tooltip>
						<TooltipTrigger>
							<div
								className={`${
									active || valid
										? `text-primary bg-primary-100 ${
												active &&
												"border border-primary"
										  }`
										: "text-border border-2"
								} overflow-hidden relative flex justify-center rounded-md
				 items-center w-10 h-10`}
							>
								{step.name === "general" && (
									<i className="far fa-user"></i>
								)}
								{step.name === "education" && (
									<i className="far fa-graduation-cap"></i>
								)}
								{step.name === "work" && (
									<i className="far fa-briefcase"></i>
								)}
								{step.name === "skill" && (
									<i className="far fa-star"></i>
								)}

								{step.name === "interests" && (
									<i className="far fa-heart"></i>
								)}

								{valid && (
									<div className={`absolute top-5 left-5`}>
										<i className="far fa-check"></i>
									</div>
								)}
							</div>
						</TooltipTrigger>

						<TooltipContent>{label}</TooltipContent>
					</Tooltip>
				</TooltipProvider>
				{showLabel && active && (
					<div className="flex flex-1 gap-1 flex-col">
						<small className="text-placeholder line-clamp-1">
							{`${stepsLabel} ${index + 1} / ${total}`}
						</small>
						<strong className="text-sm md:text-[16px] line-clamp-1">
							{cutString(label, isPhone ? 15 : 100)}
						</strong>
					</div>
				)}
			</div>
		</div>
	);
};

StepsLineResume.defaultProps = {
	className: "",
	backgroundColor: "white",
	steps: [],
};

export default StepsLineResume;
