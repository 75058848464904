import { useState } from "react";
import Button from "src/components/Button";
import Input from "src/components/form/Input";
import Switch from "src/components/form/Switch";
import withTranslation, {
	Translation,
} from "src/components/hoc/withTranslation";
import Link from "src/components/Link";
import Modal from "src/components/Modal";
import useSelectRegistrations from "src/hooks/api/tasks/useSelectRegistrations";
import usePost from "src/hooks/rest/usePost";
import useSignature from "src/hooks/selectors/useSignature";

interface ProcessRegistrationsModalProps extends Translation {
	visible: boolean;
	variant: "accept" | "deny";
	onStatusChange: (
		status: "success" | "error" | "close",
		ids: number[]
	) => void;
	links?: {
		task?: string;
		registration?: string;
	};
}

const ProcessRegistrationsModal = ({
	t,
	visible,
	variant,
	onStatusChange,
	links,
}: ProcessRegistrationsModalProps) => {
	const { selected } = useSelectRegistrations();
	const { signature } = useSignature();
	const [isSignAutomatic, setIsSignAutomatic] = useState(
		signature?.id ? true : false
	);
	const [motivation, setMotivation] = useState("");
	const [accept, accepting] = usePost(`/company/registrations/multi-accept`);
	const [deny, denying] = usePost(`/company/registrations/multi-deny`);
	const loading = accepting || denying;

	const onSubmit = () => {
		if (variant === "accept") {
			accept({
				ids: selected,
				is_sign_direct: isSignAutomatic,
			})
				.then(({ data }) => {
					setMotivation("");
					onStatusChange("success", data?.ids || []);
				})
				.catch(() => {
					onStatusChange("error", []);
				});
		}
		if (variant === "deny") {
			deny({
				ids: selected,
				motivation,
			})
				.then(() => {
					onStatusChange("success", selected);
					setMotivation("");
				})
				.catch(() => {
					onStatusChange("error", []);
				});
		}
	};

	return (
		<Modal
			title={t(`${variant}.title`)}
			{...{ visible }}
			onClose={() => onStatusChange("close", [])}
		>
			<div className="flex flex-col gap-4">
				<p className="">{t(`${variant}.description`)}</p>

				<strong>
					{t(`${variant}.registrations`, { count: selected.length })}
				</strong>

				{variant === "deny" && (
					<Input
						name="motivation"
						label={t(`${variant}.motivation`, {
							count: selected.length,
						})}
						multiline
						optional
						value={motivation}
						onChange={(key, value) => setMotivation(value)}
					/>
				)}

				{variant === "accept" && signature?.id && (
					<Switch
						label={t(`${variant}.sign-direct`)}
						onChange={setIsSignAutomatic}
						value={isSignAutomatic}
						subLabel={
							<>
								{links?.registration && links?.task && (
									<Link
										target="_blank"
										className="font-bold underline"
										to={`/signer/${links?.task}/registration/${links?.registration}/preview`}
									>
										{t(`${variant}.buttons.click-here`)}
									</Link>
								)}{" "}
								{t(`${variant}.envelope-preview`)}
							</>
						}
					/>
				)}

				<div className="flex flex-col gap-2">
					<Button onClick={onSubmit} loading={loading} type="dark">
						{t(`${variant}.buttons.submit`)}
					</Button>
					<Button
						onClick={(e) => {
							e.preventDefault();
							onStatusChange("close", []);
						}}
						disabled={loading}
						type="gray"
					>
						{t(`${variant}.buttons.cancel`)}
					</Button>
				</div>
			</div>
		</Modal>
	);
};

ProcessRegistrationsModal.locale = {
	nl: {
		accept: {
			title: "Aanmeldingen goedkeuren",
			description:
				"Zodra je op 'goedkeuren' klikt worden alle professionals op de hoogte gebracht dat ze zijn uitgekozen voor de opdracht. Er wordt direct een overeenkomst aangemaakt, achteraf wordt de betaling aangemaakt op basis van de gewerkte uren.",
			registrations_one: "1 aanmelding",
			registrations_other: "{{count}} aanmeldingen",
			buttons: {
				submit: "Goedkeuren",
				cancel: "Annuleren",
				"click-here": "Klik hier",
			},
			"sign-direct": "Overeenkomst direct ondertekenen",
			"envelope-preview": "om de overeenkomst te bekijken",
		},
		deny: {
			title: "Aanmeldingen afwijzen",
			motivation_one: "Waarom wil je deze student afwijzen",
			motivation_other: "Waarom wil je deze professionals afwijzen",
			registrations_one: "1 aanmelding",
			registrations_other: "{{count}} aanmeldingen",
			description:
				"Zodra je op 'afwijzen' klikt worden alle student op de hoogte gebracht dat zij niet geselecteerd zijn voor de opdracht.",
			buttons: {
				submit: "Afwijzen",
				cancel: "Annuleren",
			},
		},
	},
	en: {
		accept: {
			title: "Approve Registrations",
			description:
				"Once you click 'Approve,' all professionals will be notified that they have been chosen for the assignment. An agreement will be created immediately, and payment will be made afterward based on the hours worked.",
			registrations_one: "1 registration",
			registrations_other: "{{count}} registrations",
			buttons: {
				submit: "Approve",
				cancel: "Cancel",
				"click-here": "Click here",
			},
			"sign-direct": "Sign Agreement Immediately",
			"envelope-preview": "to view the agreement",
		},
		deny: {
			title: "Reject Registrations",
			motivation_one: "Why do you want to reject this student?",
			motivation_other: "Why do you want to reject these professionals?",
			registrations_one: "1 registration",
			registrations_other: "{{count}} registrations",
			description:
				"Once you click 'Reject,' all students will be notified that they were not selected for the assignment.",
			buttons: {
				submit: "Reject",
				cancel: "Cancel",
			},
		},
	},
};

export default withTranslation(ProcessRegistrationsModal);
