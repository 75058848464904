import { ComponentProps } from "react";
import withTranslation, {
	Translation,
} from "src/components/hoc/withTranslation";
import TabNav from "src/components/layout/tab-nav/TabNav";
import ShareVacancy, {
	ShareVacancyModal,
	ShareVacancyTrigger,
} from "src/components/vacancies/share-vacancy";
import useVacancy from "src/hooks/api/services/vacancies/useVacancy";
import { cn } from "src/lib/utils";

interface VacancyTabsProps extends Translation {
	id: string;
}

const VacancyTabs = ({
	t,
	className,
	id,
	...rest
}: VacancyTabsProps & ComponentProps<"div">) => {
	const { vacancy } = useVacancy(id);

	return (
		<div {...rest} className={cn("flex gap-4 items-end", className)}>
			<div className="flex flex-col flex-1">
				<TabNav
					tabs={[
						{
							to: `/vacancies/${id}`,
							label: t("tabs.candidates"),
						},
						{
							to: `/vacancies/${id}/edit`,
							label: t("tabs.edit"),
						},
					]}
				/>
			</div>
			<ShareVacancy vacancy={vacancy}>
				<ShareVacancyTrigger />
				<ShareVacancyModal />
			</ShareVacancy>
		</div>
	);
};

VacancyTabs.locale = {
	nl: {
		tabs: {
			candidates: "Kandidaten",
			edit: "Aanpassen",
		},
		share: "Delen",
	},
	en: {
		tabs: {
			candidates: "Candidates",
			edit: "Edit",
		},
		share: "Share",
	},
};

export default withTranslation(VacancyTabs);
