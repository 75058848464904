import React from "react";
import { ApiSignature, LayoutVariants } from "src/api/types";
import useSignature from "src/hooks/selectors/useSignature";
import { cn } from "src/lib/utils";

interface SignatureCardItemProps {
	variant: LayoutVariants;
	signature: ApiSignature;
	className?: string;
}

const SignatureCardItem = ({
	signature,
	variant,
	className,
}: SignatureCardItemProps) => {
	const { updateSelectedSignature, signature: selected } = useSignature();
	const active = signature.id === selected?.id;
	return (
		<div
			onClick={() => {
				if (!active) {
					updateSelectedSignature(signature);
				}
			}}
			className={cn(
				"flex items-center rounded-md bg-white overflow-hidden border-4 transition-all relative hover:border-primary",
				!active && "cursor-pointer",
				active && "border-primary",
				className
			)}
		>
			<img
				className="border-2 h-full bg-white object-contain w-full"
				src={signature.media.url}
				alt=""
			/>
			{active && (
				<div className="absolute z-[20] w-8 h-8 flex justify-center items-center rounded-tl-md bg-primary right-0 bottom-0">
					<i className="fas fa-check text-white"></i>
				</div>
			)}
		</div>
	);
};

export default SignatureCardItem;
