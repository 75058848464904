import moment from "moment";
import { useState } from "react";
import withTranslation, {
	Translation,
} from "src/components/hoc/withTranslation";
import { useTaskKanban } from "src/components/tasks/kanban/TaskKanbanCard";
import useDescriptionAnimate from "src/components/tasks/kanban/card-elements/hooks/useDescriptionAnimate";
import useAutosizeTextArea from "src/hooks/useAutosizeTextArea";
import { cn } from "src/lib/utils";

interface TaskKanbanCardTitleProps extends Translation {
	onBlur?: (value: string) => void;
	value?: string;
	onChange?: (value: string) => void;
	isMissing?: boolean;
	className?: string;
	isLineThrough?: boolean;
	variant?: "animated" | "default";
	showIcon?: boolean;
}

const TaskKanbanCardTitle = ({
	t,
	onBlur,
	value,
	onChange,
	isMissing,
	className,
	isLineThrough,
	variant = "default",
	showIcon,
}: TaskKanbanCardTitleProps) => {
	const { task } = useTaskKanban();
	const [title, setTitle] = useState(value || task.title);
	const textarea = useAutosizeTextArea();
	const scope = useDescriptionAnimate(
		isLineThrough || false,
		variant !== "animated"
	);

	if (onBlur || onChange) {
		return (
			<textarea
				ref={textarea.ref}
				spellCheck={false}
				value={title}
				className={cn(
					"font-regular placeholder:text-placeholder bg-transparent resize-none border border-border p-2 rounded-md transition-all",
					isMissing && "border-red",
					className
				)}
				onChange={(event) => {
					setTitle(event?.target?.value);
					if (onChange) {
						onChange(event.target.value);
					}
				}}
				placeholder={t("placeholder")}
				autoFocus
				onBlur={() => {
					if (onBlur) {
						onBlur(title);
					}
				}}
			/>
		);
	}

	return (
		<div
			ref={scope}
			className={cn(
				"font-regular leading-[22px] relative flex flex-wrap gap-1.5",
				task.type === "GHOST" && "opacity-50",
				className
			)}
		>
			{showIcon && task?.has?.description && (
				<div
					className={cn(
						"h-[22px] flex items-center",
						className?.includes("leading-full") && "h-[31px]"
					)}
				>
					<i className="fas fa-file text-border"></i>
				</div>
			)}
			{variant === "default" && task.title}
			{variant === "animated" &&
				task.title.split(" ").map((word, index) => (
					<span
						className="relative flex items-center anim-line"
						key={`${task.id}-${word}-${index}`}
					>
						{word}
						<span
							className={cn(
								"absolute flex left-0 -right-1.5 overflow-hidden pointer-events-none",
								index === 0 && "rounded-l-full",
								index === task.title.split(" ").length - 1 &&
									"rounded-r-full"
							)}
						>
							<span className="h-[2px] bg-accent-foreground anim-line_line"></span>
						</span>
					</span>
				))}
			{task.type === "GHOST" && (
				<small className="leading-full opacity-70">
					{moment(task.end_date).format("HH:mm")}
				</small>
			)}
		</div>
	);
};

TaskKanbanCardTitle.locale = {
	nl: {
		placeholder: "Taak...",
	},
	en: {
		placeholder: "Task...",
	},
};

export default withTranslation(TaskKanbanCardTitle);
