const signer = {
	"preview-view": {
		title: "Overeenkomst",
		back: "Terug naar opdracht",
	},
	"signer-view": {
		confirm: {
			title: "Weet je het zeker",
			text: "Weet je zeker dat je deze overeenkomst wilt ondertekenen",
		},
		success: {
			title: "Document is ondertekend",
		},
		error: {
			title: "Er is iets misgegaan",
			text: "Probeer het op een later moment opnieuw",
		},
		"not-found": {
			title: "Geen overeenkomst gevonden",
			button: "Terug naar opdrachten",
		},
		loading: {
			title: "De overeenkomst wordt ondertekend",
			description: "Een ogenblik geduld",
		},
		breadcrumb: {
			envelopes: "Overeenkomsten",
			envelope: "Overeenkomst",
		},
		"information-modal": {
			title: "Informatie",
		},
		signers: {
			title: "Partijen",
		},
	},
};

export default signer;
