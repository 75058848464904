import { useEffect } from "react";
import { ApiSubscriptionPlan } from "src/api/types";
import BookDemoCard, {
	BookDemoCardAvatar,
	BookDemoCardButton,
	BookDemoCardDescription,
	BookDemoCardTitle,
} from "src/components/cards/BookDemoCard";
import { useT } from "src/components/hoc/withTranslation";
import Plan, {
	PlanCard,
	PlanDisclaimer,
	PlanInterval,
	PlanNormalMonthlyPrice,
	PlanPerMonthLabel,
	PlanPricePerMonth,
	PlanProps,
	PlanSelectButton,
	PlanServiceFee,
	PlanServices,
	PlanSkeleton,
	PlanSpecialBackground,
	PlanTitle,
} from "src/components/subscription/plan/Plan";
import SubscriptionIntervalSwitch from "src/components/subscription/SubscriptionIntervalSwitch";
import useTenant from "src/hooks/api/services/tenants/useTenant";
import usePlans from "src/hooks/api/usePlans";
import SubscriptionGrid from "./SubscriptionGrid";

interface PlansProps {
	getPlanCheckoutUrl: PlanProps["getPlanCheckoutUrl"];
}

const SubscriptionPlans = ({ getPlanCheckoutUrl }: PlansProps) => {
	const { plans, actions, intervalEnabled, status } = usePlans();
	const { tenant } = useTenant();

	const t = useT({
		nl: {
			"black-friday-sale": "Speciale korting",
			countdown: "Actie verloopt bijna",
		},
		en: {
			"black-friday-sale": "Special discount",
			countdown: "Discount is almost over",
		},
	});

	useEffect(() => {
		actions.list();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const items = plans.filter(
		(item: ApiSubscriptionPlan) =>
			item.interval !== "MONTHLY" || tenant.slug === "getcrewdone"
	);
	const plan = items[0];

	const monthly = plans.find(
		(item: ApiSubscriptionPlan) => item.interval === "MONTHLY"
	);

	return (
		<div className="flex flex-col mt-12 w-full">
			{intervalEnabled && (
				<div className="flex justify-center items-center mb-12">
					<SubscriptionIntervalSwitch />
				</div>
			)}

			<SubscriptionGrid columns={3}>
				{status !== "idle" && items.length < 1 && (
					<>
						<PlanSkeleton />
						<PlanSkeleton isMostCommon={true} />
						<PlanSkeleton />
					</>
				)}
				{items.map((plan: ApiSubscriptionPlan) => {
					const isMostCommon =
						plan.interval === "HALF_YEARLY" ||
						(plan.is_most_common && plan.interval === "ONE_TIME");
					return (
						<div
							className="relative flex flex-col flex-1"
							key={`plan-${plan.id}-${plan.interval}`}
						>
							<Plan
								{...{ plan, getPlanCheckoutUrl, isMostCommon }}
								className="flex flex-col flex-1"
							>
								{isMostCommon && <PlanSpecialBackground />}
								<PlanCard className="flex flex-col flex-1">
									<div className="flex flex-col gap-3">
										{tenant.slug === "getjobsdone" && (
											<p className="font-bold text-primary">
												{t("black-friday-sale")}
											</p>
										)}
										<div className="flex flex-col gap-3">
											{tenant.slug !==
											"getrecruitmentdone" ? (
												<PlanInterval className="opacity-50" />
											) : (
												<PlanTitle />
											)}
											<div className="flex gap-2 items-end">
												<PlanNormalMonthlyPrice />
												<PlanPricePerMonth />
												<PlanPerMonthLabel />
											</div>
										</div>
										{tenant.slug !==
											"getrecruitmentdone" && (
											<PlanServiceFee />
										)}
									</div>

									<hr className="border-border" />
									<PlanServices className="flex-1" />
									<div className="flex flex-col gap-4">
										<PlanSelectButton label="claim-deal" />
										{tenant.slug === "getjobsdone" && (
											<p className="text-center text-primary font-bold">
												{t("countdown")}
											</p>
										)}
									</div>
								</PlanCard>
							</Plan>
						</div>
					);
				})}
			</SubscriptionGrid>
			<div className="flex flex-col gap-6 justify-center mt-12 w-full mx-auto max-w-[540px]">
				{plan && tenant.slug === "getjobsdone" && (
					<Plan
						key={`plan-${plan.id}-${plan.interval}`}
						{...{ plan, getPlanCheckoutUrl }}
						plan={monthly}
						className="flex-1"
					>
						<PlanCard className="md:flex-row md:items-center p-8">
							<div className="flex flex-col gap-3 flex-1">
								<div className="flex flex-col gap-3">
									<PlanInterval className="opacity-50" />
									<div className="flex gap-2 items-end">
										<PlanPricePerMonth />
										<PlanPerMonthLabel />
									</div>
								</div>
								<PlanServiceFee />
							</div>
							<PlanSelectButton label="select" />
						</PlanCard>
					</Plan>
				)}

				<div className="flex flex-col">
					<PlanDisclaimer />
				</div>

				{tenant.slug === "getjobsdone" && (
					<BookDemoCard
						member="patrick"
						className="flex-1 justify-center md:justify-start md:flex-row items-center gap-4"
					>
						<BookDemoCardAvatar className="w-20 h-20 md:w-28 md:h-28" />
						<div className="flex flex-col flex-1 justify-center items-center md:justify-start md:items-start text-center">
							<BookDemoCardTitle className="font-bold" />
							<BookDemoCardDescription className="opacity-70 text-balance" />
							<BookDemoCardButton className="mt-4" />
						</div>
					</BookDemoCard>
				)}
			</div>
		</div>
	);
};

export default SubscriptionPlans;
