import Dropdown, { DropdownProps } from "src/components/form/Dropdown";
import useStudentPool from "src/hooks/api/useStudentpool";

interface StudentPoolDropdownProps extends Omit<DropdownProps, "options"> {}

const StudentPoolDropdown = ({ ...rest }: StudentPoolDropdownProps) => {
	const { students, loading } = useStudentPool();

	return (
		<Dropdown
			noMarginBottom
			isSearch
			className={`${rest?.className || ""} mb-0`}
			options={students}
			{...{ loading, ...rest }}
		/>
	);
};

StudentPoolDropdown.defaultProps = {
	layout: "stacked",
};

export default StudentPoolDropdown;
