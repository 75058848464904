import { useTranslation } from "react-i18next";
import Button from "src/components/Button";
import useUserCardContext from "../UserCardContext";

function UserCardMakeTask() {
	const { user, status } = useUserCardContext();
	const { t } = useTranslation("network", {
		keyPrefix: "network-student-card",
	});

	return (
		<Button
			disabled={status !== "idle"}
			xsmall
			loading={status === "deleting"}
			to={`/tasks/create?profile=${user.id}`}
		>
			<i className="far fa-plus mr-1"></i> {t("create-task")}
		</Button>
	);
}

export default UserCardMakeTask;
