import { atom } from "jotai";
import { AxiosInstance } from "axios";
import { ApiTaskKanban } from "src/api/types";
import useApi, {
	ApiActions,
	ApiFilter,
	ApiStatus,
	emptyApiState,
	Pagination,
} from "src/hooks/api/utils/useApi";

interface ApiTaskActions extends ApiActions {}

type State = {
	tasks: ApiTaskKanban[];
	status: ApiStatus;
	actions: ApiTaskActions;
	api: AxiosInstance;
	pagination: Pagination;
	filter: ApiFilter;
};

export const repeatTasksAtom: any = atom({
	name: "useRepeatTasks",
	...emptyApiState,
});

type Options = {
	filter?: any;
};

function useRepeatTasks(options?: Options): State {
	const { state, actions, api, pagination } = useApi(
		{ id: "TASK_REPEATS" },
		{
			baseUrl: `/client/tasks/repeat`,
			query: {
				type: "template",
				with: ["repeat"],
				...(options?.filter || {}),
			},
		}
	);

	return {
		tasks: state?.list || [],
		status: state.status,
		filter: state?.filter || null,
		pagination,
		actions: {
			...actions,
		},
		api,
	};
}

export default useRepeatTasks;
