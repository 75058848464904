import {
	DndContext,
	useSensor,
	useSensors,
	TouchSensor,
	MouseSensor,
	DragEndEvent,
	DragOverlay,
} from "@dnd-kit/core";
import { createContext, useContext, useState } from "react";
import { createPortal } from "react-dom";
import DraggableDrop from "src/components/draggable/DraggableDrop";
import DraggableItem from "src/components/draggable/DraggableItem";
import useWindowSize from "src/hooks/useWindowSize";

export const DraggableContext = createContext<any>(null);

export function useDraggable() {
	const draggable = useContext(DraggableContext);
	if (!draggable) {
		return {
			isDraggable: false,
		};
	}
	return {
		isDraggable: true,
		...draggable,
	};
}

export type DragChangeEvent = {
	from: string | number;
	to: string | number;
	id: string | number;
	data: any;
};

interface DraggableProps {
	children?: any;
	disabled?: boolean;
	onChange: (event: DragChangeEvent) => void;
	overlay?: {
		variant?: "flat";
	};
}

const Draggable = ({
	children,
	disabled,
	onChange,
	overlay,
}: DraggableProps) => {
	const [draggingChildren, setDraggingChildren] = useState<any>();
	const { isPhone } = useWindowSize();
	const sensors = useSensors(
		useSensor(MouseSensor, {
			// Require the mouse to move by 10 pixels before activating
			activationConstraint: {
				distance: 10,
			},
		}),
		useSensor(TouchSensor, {
			// Press delay of 250ms, with tolerance of 5px of movement
			activationConstraint: {
				delay: isPhone ? 300 : 0,
				tolerance: 5,
			},
		})
		// useSensor(KeyboardSensor)
	);
	const [from, setFrom] = useState<string>();
	if (disabled) return children;

	const onDragEnd = (event: DragEndEvent) => {
		let id: any = event.active.id.toString().split("_");
		id = id[id.length - 1];
		const to = event.over?.id;
		if (id && to && from && from !== to) {
			onChange({
				from,
				to,
				id,
				data: event?.active?.data?.current,
			});
		}

		setFrom(undefined);
		setDraggingChildren(undefined);
	};

	return (
		<DraggableContext.Provider
			value={{
				setDraggingChildren,
				onStartDragging: (currentDropId: string) =>
					setFrom(currentDropId),
			}}
		>
			<DndContext sensors={sensors} {...{ onDragEnd }}>
				{children}
				{createPortal(
					<DragOverlay className="cursor-grabbing relative">
						{overlay?.variant !== "flat" && (
							<div className="absolute bg-accent border border-border opacity-80 rounded-md -inset-4"></div>
						)}
						<div className="relative z-1">{draggingChildren}</div>
					</DragOverlay>,
					document.getElementById("root") as any
				)}
			</DndContext>
		</DraggableContext.Provider>
	);
};

Draggable.Item = DraggableItem;
Draggable.Drop = DraggableDrop;

export default Draggable;
