import Avatar, { AvatarSize } from "src/components/Avatar";
import useUserCardContext from "../UserCardContext";

interface UserCardAvatarProps {
	className?: string;
	variant?: "default" | "details";
	size?: AvatarSize;
}

function UserCardAvatar({
	className,
	variant = "default",
	size = "regular",
}: UserCardAvatarProps) {
	const { user } = useUserCardContext();

	if (variant === "details") {
		return (
			<Avatar.User
				avatar={{
					size,
				}}
				user={user as any}
				{...{ className }}
			/>
		);
	}

	return (
		<Avatar
			src={user.avatar}
			alt={user.full_name}
			{...{ className, size }}
		/>
	);
}

export default UserCardAvatar;
