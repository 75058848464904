const network = {
	"network-company-view": {
		title_one: "1 connectie gevonden",
		title_other: "{{count}} connecties gevonden",
		empty: "Je hebt nog geen connecties",
	},
	"network-student-view": {
		title_one: "1 uitnodiging gevonden",
		title_other: "{{count}} uitnodigingen gevonden",
		empty: "Je hebt nog geen uitnodigingen",
	},

	"network-invite-view": {
		title: "Uitnodiging",
		back: "Terug naar uitnodigingen",
	},
};

export default network;
