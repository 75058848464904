import { useState } from "react";
import Button from "src/components/Button";
import Choose from "src/components/forms/auth/signup/Choose";
import withTranslation, {
	Translation,
} from "src/components/hoc/withTranslation";
import AuthLayout, {
	AuthLayoutContainer,
} from "src/components/layout/auth-layout/AuthLayout";
import Link from "src/components/Link";
import useTenant from "src/hooks/api/services/tenants/useTenant";
import useBackUrl from "src/hooks/useBackUrl";

function SignupChooseView({ t }: Translation) {
	const { tenant } = useTenant();
	const backUrl = useBackUrl();
	const [type, setType] = useState<"professional" | "company">();
	const prefix =
		tenant.slug === "getrecruitmentdone" ? `${tenant.slug}.` : "";

	return (
		<AuthLayout variant="flat">
			<AuthLayoutContainer className="items-center">
				<h1 className="max-w-md text-2xl md:text-3xl text-center text-balance">
					{t(`${prefix}title`)}
				</h1>
				<div className="flex flex-col gap-6 mt-8 md:mt-16 w-full max-w-[740px]">
					<Choose className="flex flex-col md:flex-row gap-4 md:gap-6">
						<Choose.Item
							size="large"
							title={t(`${prefix}choose.professional.title`)}
							icon="fas fa-user"
							onClick={() => setType("professional")}
							active={type === "professional"}
							className="flex-1"
						/>
						<Choose.Item
							size="large"
							title={t(`${prefix}choose.company.title`)}
							icon="fas fa-building"
							onClick={() => setType("company")}
							active={type === "company"}
							className="flex-1"
						/>
					</Choose>
					<div className="flex flex-col gap-6 justify-center items-center mt-4 md:mt-8">
						<Button
							disabled={!type}
							to={`/signup/${type}?${backUrl}`}
						>
							{t(
								type
									? `${prefix}choose.${type}.cta`
									: `${prefix}choose.cta`
							)}
						</Button>
						<p>
							<span>{t("login.description")}</span>
							<Link
								to={`/login?${backUrl}`}
								className="text-primary hover:underline"
							>
								{t("login.cta")}
							</Link>
						</p>
					</div>
				</div>
			</AuthLayoutContainer>
		</AuthLayout>
	);
}

SignupChooseView.locale = {
	nl: {
		getrecruitmentdone: {
			title: "Maak je account om op vacatures te reageren of te plaatsen",
			choose: {
				cta: "Account aanmaken",
				professional: {
					title: "Ik ben opzoek naar een baan",
					cta: "Account aanmaken",
				},
				company: {
					title: "Ik wil mijn vacatures plaatsen",
					cta: "Account aanmaken",
				},
			},
		},
		title: "Meld je aan als opdrachtnemer of opdrachtgever",
		choose: {
			cta: "Account aanmaken",
			professional: {
				title: "Ik ben een opdrachtnemer die opdrachten wilt uitvoeren",
				cta: "Aanmelden als opdrachtnemer",
			},
			company: {
				title: "Ik ben een opdrachtgever die opdrachten wilt plaatsen",
				cta: "Aanmelden als opdrachtgever",
			},
		},
		login: {
			description: "Heb je al een account? ",
			cta: "Log in",
		},
	},
	en: {
		getrecruitmentdone: {
			title: "Create your account to apply for or post jobs",
			choose: {
				cta: "Create account",
				professional: {
					title: "I'm looking for a job",
					cta: "Create account",
				},
				company: {
					title: "I want to post jobs",
					cta: "Create account",
				},
			},
		},
		title: "Sign up as a contractor or client",
		choose: {
			cta: "Create account",
			professional: {
				title: "I'm a contractor looking to take on assignments",
				cta: "Sign up as contractor",
			},
			company: {
				title: "I'm a client looking to post assignments",
				cta: "Sign up as client",
			},
		},
		login: {
			description: "Already have an account? ",
			cta: "Log in",
		},
	},
};
export default withTranslation(SignupChooseView);
