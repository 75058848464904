import React, { ComponentProps } from "react";
import Skeleton from "src/components/Skeleton";
import { cn } from "src/lib/utils";

interface LoadingProps {
	count?: number;
	skeletonClassName?: string;
}

const Loading = ({
	count = 8,
	skeletonClassName,
	className,
	...rest
}: LoadingProps & ComponentProps<"div">) => {
	return (
		<div
			{...rest}
			className={cn("flex gap-2 flex-wrap mb-2 items-center", className)}
		>
			{new Array(count).fill("").map((item, index) => (
				<Skeleton
					key={`loading-${count}-${index}`}
					className={cn("h-6 w-12", skeletonClassName)}
				/>
			))}
		</div>
	);
};

export default Loading;
