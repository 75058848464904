import React from "react";
import { ApiOfferDescription } from "src/api/types";

interface OfferDescriptionProps {
	className?: string;
	description: ApiOfferDescription;
}

const OfferDescription = ({
	className,
	description,
}: OfferDescriptionProps) => {
	return (
		<div className={`${className || ""} flex flex-col gap-4`}>
			<div className="flex flex-col">
				<strong>Even voorstellen</strong>
				<p>{description.intro}</p>
			</div>
			<div className="flex flex-col">
				<strong>Wat kan ik voor jouw bedrijf betekenen?</strong>
				<p>{description.what}</p>
			</div>
			<div className="flex flex-col">
				<strong>Hoe ga ik dit doen?</strong>
				<p>{description.how}</p>
			</div>
		</div>
	);
};

export default OfferDescription;
