import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { updateModules } from "src/redux/modules";

function useModules() {
	const modules = useSelector((state: any) => state.modules);
	const dispatch = useDispatch();

	const fetchModules = async () => {
		axios
			.get("/client/modules")
			.then(({ data }) => {
				const { modules, expires } = data?.data;
				dispatch(
					updateModules({
						modules: modules || [],
						expires: expires || {},
						loading: false,
					})
				);
			})
			.catch(() => {
				dispatch(updateModules({ loading: false }));
			});
	};

	return {
		...modules,
		fetchModules,
	};
}

export default useModules;
