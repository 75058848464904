import { ComponentProps } from "react";
import Avatar from "src/components/Avatar";
import withTranslation, {
	Translation,
} from "src/components/hoc/withTranslation";
import { cn } from "src/lib/utils";
import useResumeDetailContext from "../ResumeDetailContext";

interface ResumeHeadProps extends Translation {}

function ResumeHead({
	t,
	className,
	...rest
}: ResumeHeadProps & ComponentProps<"div">) {
	const { resume } = useResumeDetailContext();

	return (
		<div
			{...rest}
			className={cn("flex flex-row gap-4 items-end", className)}
		>
			<div className="flex w-max relative">
				<Avatar size="large" src={resume.avatar} />
			</div>
			<div className="flex flex-col gap-2 flex-1">
				<h2>{resume.full_name}</h2>
			</div>
		</div>
	);
}

export default withTranslation(ResumeHead);
