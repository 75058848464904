import { ApiReferral } from "src/api/types";
import useApi from "src/hooks/api/utils/useApi";

type Options = {
	filter?: any;
};

function useReferrals(options?: Options) {
	const { state, actions, api, pagination } = useApi(
		{ id: "REFERRALS" },
		{
			baseUrl: `/client/referrals`,
			query: {
				...(options?.filter || {}),
			},
		}
	);
	const referrals: ApiReferral[] = state?.list || [];

	const invite = async (emails: string) => {
		return api.post(`/invite`, {
			emails: emails.split(",").map((email) => email.trim()),
		});
	};

	const getStatistics = async () => {
		const {
			data: { data: statistics },
		} = await api.get("/statistics");
		actions.set((state: any) => ({
			...state,
			custom: {
				...state,
				statistics,
			},
		}));
		return statistics;
	};

	return {
		referrals,
		statistics: state?.custom?.statistics || {},
		filter: state?.filter,
		search: state?.filter?.q,
		status: state.status,
		pagination,
		actions: {
			...actions,
			statistics: {
				get: getStatistics,
			},
			invite,
		},
		api,
	};
}

export default useReferrals;
