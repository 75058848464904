import moment from "moment";
import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import Button from "src/components/Button";
import Icon from "src/components/field/utils/Icon";
import Wrapper, { WrapperProps } from "src/components/field/utils/Wrapper";
import { Calendar } from "src/components/ui/calendar";
import {
	Popover,
	PopoverContent,
	PopoverTrigger,
} from "src/components/ui/popover";
import useBlur from "src/hooks/useBlur";
import { useKeyPress } from "src/hooks/useKeyPress";
import useWindowSize from "src/hooks/useWindowSize";
import { cn } from "src/lib/utils";

interface DateProps {
	wrapper: WrapperProps;
	value?: string;
	onChange: (value?: string | null) => void;
	calendar?: {
		visible?: boolean;
		disabled?: boolean;
		clearable?: boolean;
	};
	disabled?: boolean;
	className?: string;
	theme?: {
		placeholder?: {
			className?: string;
		};
	};
}

const Date = ({
	wrapper,
	value,
	onChange,
	calendar,
	className,
	theme,
	disabled,
}: DateProps) => {
	const { isPhone } = useWindowSize();
	const { t } = useTranslation("common");
	const calendarRef: any = useRef();
	const [showCalendar, setShowCalendar] = useState(calendar?.visible);
	const input: any = useRef();
	useKeyPress(["Escape"], () => {
		setShowCalendar(false);
	});
	useBlur(calendarRef, () => {
		setShowCalendar(false);
	});

	const getInputValue = () => {
		if (!value) return undefined;
		const iso = moment.utc(value, "YYYY-MM-DD").toISOString();

		return iso ? iso.substring(0, 10) : undefined;
	};

	return (
		<Wrapper
			{...{
				...wrapper,
				onFocus: () => input?.current?.focus(),
			}}
		>
			<div className="flex">
				<Popover
					open={showCalendar && !disabled}
					onOpenChange={setShowCalendar}
				>
					<PopoverTrigger asChild>
						<div
							onClick={() => {
								if (isPhone && !disabled) {
									setShowCalendar(true);
								}
							}}
							className={cn(
								"flex items-center relative gap-2 border rounded-md border-transparent focus-within:border-border p-1.5",
								disabled && "bg-accent cursor-not-allowed",
								className
							)}
						>
							{!calendar?.disabled && (
								<Icon
									onClick={() =>
										setShowCalendar(!showCalendar)
									}
									icon="far fa-calendar cursor-pointer"
								/>
							)}
							{!isPhone ? (
								<input
									{...{ disabled }}
									className={cn(
										"hide-date-icon bg-transparent flex-1",
										!value &&
											"text-placeholder placeholder:text-placeholder",
										theme?.placeholder?.className
									)}
									ref={input}
									type="date"
									value={getInputValue()}
									onChange={(e) => {
										onChange(
											moment(e.target.value).format(
												"YYYY-MM-DD"
											)
										);
									}}
								/>
							) : (
								<span
									className={cn(
										!value && "text-placeholder",
										theme?.placeholder?.className
									)}
								>
									{value
										? moment(value).format("DD-MM-YYYY")
										: "dd-mm-jjjj"}
								</span>
							)}
						</div>
					</PopoverTrigger>

					<PopoverContent className="p-2 w-max">
						<Calendar
							mode="single"
							selected={
								value ? moment(value).toDate() : undefined
							}
							className="p-0 w-max"
							onSelect={(value) => {
								setShowCalendar(false);
								onChange(moment(value).format("YYYY-MM-DD"));
							}}
						/>

						{calendar?.clearable && value && (
							<div className="p-2 w-max">
								<span
									onClick={() => {
										setShowCalendar(false);
										onChange(null);
									}}
									className="cursor-pointer px-2 py-1 text-sm rounded-md hover:bg-accent transition-all font-regular"
								>
									{t("clear")}
								</span>
							</div>
						)}
						<div className="mt-2 flex gap-2">
							<Button
								xsmall
								type="border"
								onClick={() =>
									onChange(moment().format("YYYY-MM-DD"))
								}
							>
								{t("today")}
							</Button>
							<Button
								xsmall
								type="border"
								onClick={() =>
									onChange(
										moment()
											.add("day", 1)
											.format("YYYY-MM-DD")
									)
								}
							>
								{t("tomorrow")}
							</Button>
						</div>
					</PopoverContent>
				</Popover>
			</div>
		</Wrapper>
	);
};

export default Date;
