import { Helmet } from "react-helmet";
import { getCurrentDomainName } from "src/lib/domain";

const TenantIcons = () => {
	const domain = getCurrentDomainName();
	return (
		<>
			<Helmet>
				<link
					rel="icon"
					href={
						process.env.PUBLIC_URL + `/icons/${domain}/favicon.png`
					}
					sizes="32x32"
					type="image/png"
				/>

				<link
					rel="icon"
					href={
						process.env.PUBLIC_URL + `/icons/${domain}/logo192.png`
					}
					sizes="192x192"
					type="image/png"
				/>

				<link
					rel="icon"
					href={
						process.env.PUBLIC_URL + `/icons/${domain}/logo512.png`
					}
					sizes="512x512"
					type="image/png"
				/>
			</Helmet>
		</>
	);
};

export default TenantIcons;
