import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Button from "src/components/Button";
import Loader from "src/components/Loader";
import EditableText from "src/components/form/EditableText";
import ColorPicker from "src/components/form/color-picker/ColorPicker";
import * as SkillSelector from "src/components/form/skill-selector/SkillSelector";
import withTranslation, {
	Translation,
} from "src/components/hoc/withTranslation";
import Icon from "src/components/icon/Icon";
import IconPicker from "src/components/icon/IconPicker";
import useTeams from "src/hooks/api/services/users/useTeams";
import useEmoji from "src/hooks/data/useEmoji";
import { useValueDebounce } from "src/hooks/useDebounce";
import useModal from "src/hooks/useModal";
import { generateRandomColor } from "src/lib/colors";

const TeamFormModal = ({ t }: Translation) => {
	const { id } = useParams();
	const modal = useModal();
	const navigate = useNavigate();
	const { status, actions, api } = useTeams();
	const [isLoading, setIsLoading] = useState(false);
	const { actions: emojiActions } = useEmoji();
	const [form, setForm] = useState({
		id: undefined,
		icon: {
			emoji: emojiActions.getRandomEmoji(),
		} as any,
		name: "",
		color: generateRandomColor(),
		skills: [],
	});

	const defaultSkillSearch = useValueDebounce(form.name, 500);

	useEffect(() => {
		modal.setTitle(id ? t("title-edit") : t("title-create"));
		if (id && id !== form.id) {
			setIsLoading(true);
			api.get(`/${id}`).then(({ data }: any) => {
				setIsLoading(false);
				setForm({
					id: data?.data?.hid,
					icon: data?.data?.icon,
					name: data?.data?.name,
					color: data?.data?.color,
					skills: data?.data?.skills,
				});
			});
		}

		return () => {
			setForm({
				id: undefined,
				icon: undefined,
				name: "",
				color: generateRandomColor(),
				skills: [],
			});
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const onSubmit = (e?: any) => {
		if (e) e.preventDefault();

		if (!form.name) return;

		if (id) {
			actions.update(form, { updateList: true }).then((res) => {
				modal.onClose();
			});
		} else {
			actions.create(form).then((res) => {
				actions.set((state: any) => ({
					...state,
					list: [...(state?.list || []), res],
				}));

				if (modal?.onClose) {
					modal.onClose();
				} else {
					navigate(`/profile/users`);
				}
			});
		}
	};

	if (isLoading) {
		return (
			<div className="flex flex-col justify-center items-center p-8">
				<Loader />
			</div>
		);
	}

	return (
		<form {...{ onSubmit }} className="flex flex-col gap-4">
			<div className="flex items-end gap-4 relative">
				<IconPicker
					value={form.icon as any}
					onChange={(icon) =>
						setForm((state: any) => ({
							...state,
							icon: {
								emoji: icon,
							},
						}))
					}
					trigger={
						<Icon className="bg-accent w-20" icon={form?.icon} />
					}
				/>
				<EditableText
					placeholder={t("fields.name.label")}
					value={form.name}
					variant={"large"}
					autoFocus
					className="flex-1"
					onChange={(e: any) => {
						setForm((state: any) => ({
							...state,
							name: e.target.value,
						}));
					}}
				/>
			</div>

			<ColorPicker
				label={t("fields.color.label")}
				value={form?.color}
				onChange={(color) =>
					setForm((state: any) => ({
						...state,
						color,
					}))
				}
			/>

			<hr className="my-4 border-border" />

			<SkillSelector.Root
				defaultSearch={defaultSkillSearch}
				value={form.skills || []}
				onChange={(value: any) =>
					setForm((state: any) => ({
						...state,
						skills: value,
					}))
				}
			>
				<p className="mb-3">{t("fields.skills.description")}</p>
				<SkillSelector.Results />
				<SkillSelector.Input />
			</SkillSelector.Root>

			<div className="mt-4">
				<Button
					loading={status !== "idle"}
					disabled={!form.name || status !== "idle"}
					className="w-full"
					submit
					type="dark"
				>
					{id ? t("button.edit") : t("button.create")}
				</Button>
			</div>
		</form>
	);
};

TeamFormModal.locale = {
	nl: {
		"title-create": "Team aanmaken",
		"title-edit": "Team aanpassen",
		fields: {
			name: {
				label: "Naam",
				placeholder: "Marketing",
			},
			color: {
				label: "Kleur",
			},
			skills: {
				description:
					"Voeg vaardigheden toe, deze vaardigheden heb je nodig om binnen dit team te werken. Op basis van deze skills worden taken toegewezen aan teamleden.",
			},
		},
		button: {
			create: "Aanmaken",
			edit: "Opslaan",
		},
	},
	en: {
		"title-create": "Create team",
		"title-edit": "Edit team",
		fields: {
			name: {
				label: "Name",
				placeholder: "Marketing",
			},
			color: {
				label: "Color",
			},
			skills: {
				description:
					"Add skills, these skills are necessary to work within this team. Tasks are assigned to team members based on these skills.",
			},
		},
		button: {
			create: "Create",
			edit: "Save",
		},
	},
};

export default withTranslation(TeamFormModal);
