import { useState } from "react";
import { Link } from "react-router-dom";
import { ApiTeam } from "src/api/types";
import Avatar from "src/components/Avatar";
import Badge from "src/components/Badge";
import Button from "src/components/Button";
import CompanyUserStatus from "src/components/CompanyUserStatus";
import Table from "src/components/Table";
import UserAvatars from "src/components/UserAvatars";
import withTranslation, {
	Translation,
} from "src/components/hoc/withTranslation";
import useTeams from "src/hooks/api/services/users/useTeams";
import useUsers from "src/hooks/api/services/users/useUsers";
import useAlert from "src/hooks/useAlert";

interface TeamListItemProps extends Translation {
	team: ApiTeam;
}

const TeamListItem = ({ t, team }: TeamListItemProps) => {
	const { actions } = useTeams();
	const [status, setStatus] = useState<"idle" | "deleting">("idle");
	const [sendAlert] = useAlert();
	const { users } = useUsers();
	const [showUsers, setShowUsers] = useState(false);
	const members = users.filter((user) => user.teams.includes(team.slug));
	const memberLimit = 5;
	const onDelete = () => {
		sendAlert({
			type: "confirm",
			title: t("delete.title"),
			text: t("delete.text"),
			onConfirm: () => {
				setStatus("deleting");
				actions
					.delete(team.id)
					.then(() => {
						actions.set((state: any) => ({
							...state,
							list: (state?.list || []).filter(
								(item: ApiTeam) => item.id !== team.id
							),
						}));
					})
					.finally(() => setStatus("idle"));
			},
		});
	};

	return (
		<>
			<Table.Row key={`team-${team.id}`}>
				<Table.Cell>
					<Badge custom={team.color}>{team.name}</Badge>
				</Table.Cell>
				<Table.Cell className="w-full">
					<Link to={`/profile/users?team=${team.slug}`}>
						{!!members.length ? (
							<div className="flex items-center gap-2">
								<UserAvatars
									users={members.slice(0, memberLimit)}
								/>
								{members.length > memberLimit && (
									<p>+ {members.length - memberLimit}</p>
								)}
							</div>
						) : (
							<p>{t("members.empty")}</p>
						)}
					</Link>
				</Table.Cell>
				<Table.Cell>
					<div className="flex items-center gap-4 justify-end">
						{!!members.length && (
							<Button
								onClick={() => setShowUsers(!showUsers)}
								type="gray"
								iconOnly
								icon={`fas fa-caret-${
									showUsers ? "up" : "down"
								}`}
							></Button>
						)}
						<Button
							loading={status === "deleting"}
							onClick={onDelete}
							type="gray"
							iconOnly
							icon="fas fa-trash"
						></Button>
						<Button
							to={{ modal: `/profile/teams/${team.hid}/edit` }}
							type="dark"
							iconOnly
							icon="fas fa-pencil"
						></Button>
					</div>
				</Table.Cell>
			</Table.Row>

			{showUsers && (
				<>
					{members.map((member) => (
						<Table.Row
							className={`${showUsers ? "" : ""}`}
							key={`member-${member.id}-${team.hid}`}
						>
							<Table.Cell>
								{member.status && (
									<CompanyUserStatus status={member.status} />
								)}
							</Table.Cell>
							<Table.Cell>
								<Avatar.User
									user={member}
									options={{ titleCutBackground: "gray" }}
								/>
							</Table.Cell>
							<Table.Cell>
								<div className="flex justify-end">
									<Button
										to={{
											modal: `/profile/users/${member.hid}/edit`,
											back: {
												to: "/profile/teams",
											},
										}}
										type="gray"
										iconOnly
										icon="fas fa-pencil"
									></Button>
								</div>
							</Table.Cell>
						</Table.Row>
					))}
				</>
			)}
		</>
	);
};

TeamListItem.locale = {
	nl: {
		delete: {
			title: "Weet je het zeker",
			text: "Wanneer je het team verwijdert worden alle gebruikers losgekoppeld van dit team",
		},
		members: {
			empty: "Geen teamleden",
		},
	},
	en: {
		delete: {
			title: "Are you sure?",
			text: "When you delete this team, all the users will de disconnected from the team",
		},
		members: {
			empty: "No members",
		},
	},
};

export default withTranslation(TeamListItem);
