import { Route } from "react-router-dom";
import ProtectedRoute from "src/routes/middlewares/ProtectedRoute";
import InboxView from "src/views/authenticated/inbox/InboxView";

const ChatRoutes = [
	<Route
		element={
			<ProtectedRoute>
				<InboxView />
			</ProtectedRoute>
		}
		path="/inbox/:id?"
		key="/inbox/:id?"
	/>,
];

export default ChatRoutes;
