import { isInteger } from "lodash";
import React from "react";
import { cn } from "src/lib/utils";

interface ProgressProps {
	total: number;
	current: number;
	colors: {
		bar: "green";
		background: string;
	};
	height: number;
	className?: string;
	barClassName?: string;
}

const Progress = ({
	total,
	current,
	colors,
	height,
	className,
	barClassName,
}: ProgressProps) => {
	let percent = Math.round((current / total) * 100);

	if (!isInteger(percent)) {
		percent = 0;
	}
	return (
		<div
			className={cn(
				`bg-${colors?.background} overflow-hidden rounded-full h-${height}`,
				className
			)}
		>
			<div
				style={{ width: `${percent}%` }}
				className={cn(
					`transition-all block rounded-full h-${height} bg-${colors.bar}`,
					barClassName
				)}
			></div>
		</div>
	);
};

Progress.defaultProps = {
	colors: {
		bar: "green",
		background: "gray",
	},
	height: 2,
};

export default Progress;
