import Layout from "src/components/layout/Layout";
import ProfileTabs from "src/components/layout/profile-settings-layout/ProfileTabs";

interface ProfileSettingsLayoutProps {
	title: string;
	children?: any;
}

const ProfileSettingsLayout = ({
	title,
	children,
}: ProfileSettingsLayoutProps) => {
	return (
		<Layout container="xxl" {...{ title }}>
			<ProfileTabs />
			{children}
		</Layout>
	);
};

ProfileSettingsLayout.Tabs = ProfileTabs;

export default ProfileSettingsLayout;
