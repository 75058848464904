import { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import Button from "src/components/Button";
import Card from "src/components/Card";
import Loader from "src/components/Loader";
import withTranslation, {
	Translation,
} from "src/components/hoc/withTranslation";
import Layout from "src/components/layout/Layout";
import usePaymentSession from "src/hooks/api/services/session/usePaymentSession";
import useTenant from "src/hooks/api/services/tenants/useTenant";
import useUnreads from "src/hooks/api/useUnreads";
import useAuth from "src/hooks/selectors/useAuth";
import usePolling from "src/hooks/usePolling";
import useWindowSize from "src/hooks/useWindowSize";
import { formatPrice } from "src/lib/formatters";
import { cn } from "src/lib/utils";
import NotFoundView from "src/views/auth/NotFoundView";

const SessionPaymentView = ({ t }: Translation) => {
	const { sessionId } = useParams();
	const { fetchUnreads } = useUnreads();
	const [isMounted, setIsMounted] = useState(false);
	const { isPhone } = useWindowSize();
	const { session, actions, status, isError } = usePaymentSession();
	const payment = session?.payment;
	const hasInvoice = !!session?.payment?.invoice;
	const isCanceled = ["cancelled", "refunded"].includes(payment?.status);
	const isCompleted = ["completed", "reserved"].includes(payment?.status);
	const isPending = useMemo(() => {
		if (payment?.type === "ideal") {
			return payment?.status === "pending";
		}
		return false;
	}, [payment?.type, payment?.status]);

	const isPollingEnabled = useMemo(() => {
		if (isError) return false;
		if (!session?.id) {
			return true;
		}
		if (payment?.type === "invoice") {
			return !hasInvoice;
		}
		return !hasInvoice || !isCompleted;
	}, [hasInvoice, isCompleted, isError, payment?.type, session?.id]);

	usePolling({
		fn: () => {
			fetchUnreads();
			return actions.find(sessionId?.toString() || "");
		},
		interval: 3000,
		enabled: isPollingEnabled,
	});

	const { tenant } = useTenant();
	const auth = useAuth();

	const vat = payment?.lines?.reduce(
		(acc, cur) => acc + (cur?.merchant?.vat?.price || 0),
		0
	);

	const subtotal = payment?.lines?.reduce(
		(acc, cur) => acc + (cur?.price || 0),
		0
	);

	useEffect(() => {
		setIsMounted(true);
		if (sessionId) {
			actions.find(sessionId);
		}
		fetchUnreads();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	if (status === "idle" && !session?.id && isMounted) {
		return <NotFoundView />;
	}

	return (
		<Layout loading={(status !== "idle" && !session?.id) || !isMounted}>
			<div
				className={cn(
					"flex flex-col flex-1 md:pt-8",
					!isPhone && "container"
				)}
			>
				<div className="flex flex-col-reverse md:grid md:grid-cols-2 md:gap-16 flex-1 md:pb-12">
					<div className="flex flex-col md:pt-12 md:pb-8">
						<div className="flex flex-col gap-3 md:py-12">
							<span className="text-md opacity-70">
								{t("payment")}
							</span>
							<div className="flex gap-2 flex-wrap items-end">
								<h1>{formatPrice(payment?.total)}</h1>
								<span className="opacity-50">
									{t("incl-vat")}
								</span>
							</div>
						</div>
						<div className="flex flex-col flex-1 py-6 md:py-12 gap-4">
							<p className="opacity-70 text-md">
								{t("payments.title")}
							</p>

							<div className="flex flex-col gap-2">
								{payment?.lines?.map((item: any) => (
									<div
										key={item.id}
										className="flex flex-col gap-2"
									>
										<div className="flex items-center gap-3 justify-between">
											<p>
												{item?.merchant?.is_tenant
													? tenant.name
													: item.merchant?.user
															?.full_name}
											</p>
											<p className="">
												{formatPrice(item.price)}
											</p>
										</div>
										<div className="flex items-center justify-end text-[15px]">
											<p className="opacity-50">
												{t("payments.vat", {
													percent:
														item.merchant?.vat
															?.percent,
												})}
											</p>
										</div>
									</div>
								))}

								<div className="flex flex-col gap-4 mt-4 pt-4 border-t border-border">
									<div className="flex justify-between">
										<p>{t("payments.subtotal")}</p>
										<p>{formatPrice(subtotal)}</p>
									</div>
									<div className="flex justify-between">
										<p>
											{t("payments.vat", {
												percent: 21,
											})}
										</p>
										<p>{formatPrice(vat)}</p>
									</div>
									<div className="flex justify-between">
										<p>{t("payments.total")}</p>
										<p>{formatPrice(payment?.total)}</p>
									</div>
								</div>
							</div>
						</div>

						<div className="flex flex-col mb-6 md:mb-0">
							<a
								className="underline"
								href={`mailto: support@${tenant?.urls?.domain}?subject=Vraag over betaling met ref: ${payment?.hid}&reply-to=${auth.email}`}
							>
								{t("contact-support")}
							</a>
						</div>
					</div>
					<Card
						className="flex flex-col mb-8 md:mb-0 bg-transparent md:bg-card shadow-none md:shadow"
						contentClassName="gap-6 md:gap-16 p-0 md:p-6"
					>
						<div className="flex flex-col">
							<p className="opacity-70 text-md">
								{t("info.title")}
							</p>
							<div className="flex flex-col gap-4 mt-6 md:mt-8">
								<div className="flex">
									<span className="opacity-70 w-44">
										{t("info.status")}
									</span>
									<div
										className={cn(
											"flex gap-1.5 items-center",
											isCompleted && "text-green",
											isCanceled && "text-red"
										)}
									>
										{isCompleted && (
											<i className="far text-sm fa-check"></i>
										)}
										{isCanceled && (
											<i className="far text-sm fa-times"></i>
										)}
										{payment?.status === "pending" && (
											<Loader />
										)}
										<p>
											{t(
												`info.payment-status.${payment?.status}`
											)}
										</p>
									</div>
								</div>
								<div className="flex">
									<span className="opacity-70 w-44">
										{t("info.method")}
									</span>
									<p>{t(`info.methods.${payment?.type}`)}</p>
								</div>
								<div className="flex">
									<span className="opacity-70 w-44">
										{t("info.invoice-number")}
									</span>
									<div
										className={cn(
											"flex gap-1.5 items-center",
											isCanceled && "text-red"
										)}
									>
										{isCanceled && (
											<i className="far text-sm fa-times"></i>
										)}
										<p
											className={cn(
												"opacity-100 transition-all",
												isCanceled && "text-red",
												!payment?.invoice_number &&
													!isCanceled &&
													"opacity-50"
											)}
										>
											{isCanceled
												? t(
														"info.payment-status.cancelled"
												  )
												: payment?.invoice_number ||
												  t(
														`info.invoice-number-missing`
												  )}
										</p>
									</div>
								</div>
							</div>
						</div>

						<div className="flex flex-col">
							<div className="flex justify-between">
								<p className="opacity-70 text-md">
									{t("invoices.title")}
								</p>
								{/* <Button
									xsmall
									disabled={!payment?.invoice}
									to={{
										modal: `/session/payment/${sessionId}/send-invoice`,
									}}
								>
									<i className="fas fa-envelope"></i>
									{t("buttons.send-to-administration")}
								</Button> */}
							</div>
							<div className="flex flex-col mt-8 border border-border p-4 rounded-md">
								{!payment?.invoice && (
									<div className="flex flex-col justify-center items-center py-8">
										{!isCanceled && <Loader />}
										<p className="text-border text-md text-center mt-3">
											{isCanceled
												? t(
														"info.payment-status.cancelled"
												  )
												: t("invoices.loading")}
										</p>
									</div>
								)}

								{payment?.invoice && (
									<div className="flex flex-col gap-4">
										<div className="flex justify-between items-center">
											<p>{payment?.invoice_number}</p>
											<div className="flex gap-3 items-center">
												<Button
													xsmall
													type="border"
													href={payment.invoice.url}
												>
													<i className="fas fa-download"></i>
												</Button>
												<Button
													xsmall
													type="border"
													to={{
														modal: `/session/payment/${sessionId}/view-invoice/${payment.hid}`,
													}}
													href={payment.invoice.url}
												>
													<i className="fas fa-eye"></i>
												</Button>
											</div>
										</div>
									</div>
								)}
							</div>
						</div>

						<div className="flex-1"></div>

						{session?.value?.redirect_url && (
							<Button
								disabled={isCanceled || isPending}
								to={session.value.redirect_url}
							>
								{t("buttons.continue")}
							</Button>
						)}
					</Card>
				</div>
			</div>
		</Layout>
	);
};

SessionPaymentView.locale = {
	nl: {
		payment: "Betaling",
		"contact-support": "Neem contact op met onze support",
		"incl-vat": "Incl. BTW",
		payments: {
			title: "Betalingen",
			vat: "BTW ({{percent}}%)",
			subtotal: "Subtotaal",
			total: "Totaal",
		},
		info: {
			title: "Betaalinformatie",
			status: "Status",
			method: "Methode",
			"invoice-number": "Factuurnummer",
			"invoice-number-missing": "Wordt gegenereerd",
			methods: {
				invoice: "Factuur",
				ideal: "Ideal",
				mandate: "Automatisch incasso",
				afterpay: "Factuur achteraf",
				checkout: "Betaling achteraf",
				undefined: "Onbekend",
			},
			"payment-status": {
				pending: "In afwachting",
				completed: "Geslaagd",
				expired: "Mislukt",
				reserved: "Gereserveerd",
				Betaald: "Betaald",
				cancelled: "Geannuleerd",
				chargeback: "Geannuleerd",
				refunded: "Geannuleerd",
				created: "In afwachting",
				credit: "Credit",
				undefined: "Onbekend",
			},
		},
		invoices: {
			title: "Factuur",
			loading: "Facturen worden gegenereerd...",
		},
		buttons: {
			continue: "Doorgaan",
			"send-to-administration": "Mail naar administratie",
		},
	},
	en: {
		payment: "Payment",
		"contact-support": "Contact our support",
		"incl-vat": "Incl. VAT",
		payments: {
			title: "Payments",
			vat: "VAT ({{percent}}%)",
			subtotal: "Subtotal",
			total: "Total",
		},
		info: {
			title: "Payment Information",
			status: "Status",
			method: "Method",
			"invoice-number": "Invoice Number",
			"invoice-number-missing": "To be generated",
			methods: {
				invoice: "Invoice",
				ideal: "Ideal",
				mandate: "Direct Debit",
				afterpay: "Pay After Delivery",
				checkout: "Pay After Delivery",
				undefined: "Unknown",
			},
			"payment-status": {
				pending: "Pending",
				completed: "Completed",
				expired: "Failed",
				reserved: "Reserved",
				Betaald: "Paid",
				cancelled: "Cancelled",
				chargeback: "Cancelled",
				refunded: "Cancelled",
				created: "Pending",
				credit: "Credit",
				undefined: "Unknown",
			},
		},
		invoices: {
			title: "Invoice",
			loading: "Invoices are being generated...",
		},
		buttons: {
			continue: "Continue",
			"send-to-administration": "Mail to Administration",
		},
	},
};

export default withTranslation(SessionPaymentView);
