import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import Button from "src/components/Button";
import Card from "src/components/Card";
import Input from "src/components/form/Input";
import useOfferRegistration from "src/hooks/api/useOfferRegistration";
import usePost from "src/hooks/rest/usePost";
import useAuth from "src/hooks/selectors/useAuth";
import { formatPrice } from "src/lib/formatters";

interface OfferBidFormProps {
	onDone?: () => void;
	variant?: "form" | "card";
}

const OfferBidForm = ({ onDone, variant }: OfferBidFormProps) => {
	const { t } = useTranslation("offer", {
		keyPrefix: "offer-bid-form",
	});
	const { registration, fetchData } = useOfferRegistration();
	const auth = useAuth();
	const { offer } = registration;
	const [value, setValue] = useState(offer.budget);
	const [handleBid, posting] = usePost(
		`/offer-registrations/${registration.id}/bid`
	);

	const onSubmit = (e?: any) => {
		if (e) e.preventDefault();
		if (value === offer.budget) return;
		handleBid({
			budget: value,
		}).then((message) => {
			fetchData();
			if (onDone) {
				onDone();
			}
			// socket.emit("message", message);
		});
	};

	const renderChildren = () => (
		<>
			<strong>{t("title")}</strong>
			<p className="mb-0">
				{t("description", {
					price: formatPrice(offer.budget),
				})}
			</p>
			<form {...{ onSubmit }} className="flex flex-col mt-4">
				<Input
					type="number"
					{...{ value }}
					placeholder={t("placeholder")}
					onChange={(_, value) => setValue(value)}
					name="budget"
				/>
				<Button
					loading={posting}
					disabled={value === offer.budget}
					submit
					className="flex-1"
				>
					{t("button")}
				</Button>
			</form>
		</>
	);

	if (variant === "form") {
		return renderChildren();
	}

	return (
		<Card
			className={
				auth.type === "company"
					? "bg-dark-accent text-white"
					: "bg-background"
			}
			contentClassName={
				auth.type === "company" ? "text-white" : undefined
			}
		>
			{renderChildren()}
		</Card>
	);
};

OfferBidForm.defaultProps = {
	variant: "card",
};

export default OfferBidForm;
