import { ApiTaskCredit } from "src/api/types";
import useApi from "src/hooks/api/utils/useApi";

function useTaskCredits() {
	const { state, actions, pagination } = useApi(
		{ id: "TASK_CREDITS" },
		{
			baseUrl: `/client/task-credits`,
			query: {
				with: ["registrations"],
			},
		}
	);

	const credits: ApiTaskCredit[] = state?.list || [];

	return {
		credits,
		status: state.status,
		pagination,
		actions,
	};
}

export default useTaskCredits;
