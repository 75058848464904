import React from "react";
import Loader from "src/components/Loader";
import withTranslation, {
	Translation,
} from "src/components/hoc/withTranslation";
import { cn } from "src/lib/utils";

interface LoadMoreProps extends Translation {
	onClick: () => void;
	loading: boolean;
	className?: string;
}

const LoadMore = ({ t, onClick, loading, className }: LoadMoreProps) => {
	return (
		<button
			onClick={(e) => {
				e.preventDefault();
				if (!loading) {
					onClick();
				}
			}}
			className={cn(
				"w-max flex items-center gap-1.5 px-2 py-2 rounded-md hover:bg-accent transition-all",
				className
			)}
		>
			<div className="flex justify-center items-center">
				{loading ? (
					<Loader />
				) : (
					<i className="far text-[13px] fa-angle-down"></i>
				)}
			</div>
			<span className="font-regular text-[15px] leading-full">
				{t("label")}
			</span>
		</button>
	);
};

LoadMore.locale = {
	nl: {
		label: "Meer",
	},
	en: {
		label: "More",
	},
};

export default withTranslation(LoadMore);
