import { decode, encode } from "base-64";
import useQueryString from "src/hooks/useQueryString";
export function getRedirectUrl(to: string, redirect: string) {
	const encodedRedirect = encode(redirect);
	const url = `${to}?red=${encodedRedirect}`;
	return url;
}

export function getRedirectSearch(redirect: string) {
	const encodedRedirect = encode(redirect);
	const search = `red=${encodedRedirect}`;
	return search;
}

export function useRedirectUrl(to: string, redirect: string) {
	return getRedirectUrl(to, redirect);
}

export function useParseRedirectUrl() {
	const qs = useQueryString() as any;
	if (!qs.red) {
		return null;
	}
	return decode(qs?.red);
}
