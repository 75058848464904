import Dropdown from "src/components/form/Dropdown";
import withTranslation, {
	Translation,
} from "src/components/hoc/withTranslation";
import { BANK_ICONS } from "src/lib/constants";

type Method = "ideal" | "invoice";

interface OneTimePaymentMethodsProps extends Translation {
	children?: any;
	value?: Method;
	onChange: (method?: Method) => void;
	label?: string;
	placeholder?: string;
}

const OneTimePaymentMethods = ({
	t,
	value,
	onChange,
	label,
	placeholder,
}: OneTimePaymentMethodsProps) => {
	return (
		<Dropdown
			hasAvatar
			label={label || t("label")}
			placeholder={placeholder || t("placeholder")}
			value={value}
			onChange={(value: any) => {
				onChange(value);
			}}
			options={[
				{
					id: "ideal",
					name: t("methods.ideal"),
					avatar: BANK_ICONS["ideal"],
				},
				{
					id: "invoice",
					name: t("methods.invoice"),
					avatar: BANK_ICONS["unknown"],
				},
			]}
			avatarClassName="w-12 h-8 rounded-md"
		/>
	);
};

OneTimePaymentMethods.locale = {
	nl: {
		label: "Betalingsmethode",
		placeholder: "Kies een betalingsmethode",
		methods: {
			ideal: "Ideal",
			invoice: "Factuur",
		},
	},
	en: {
		label: "Payment method",
		placeholder: "Choose a payment method",
		methods: {
			ideal: "Ideal",
			invoice: "Invoice",
		},
	},
};

export default withTranslation(OneTimePaymentMethods);
