import React from "react";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { ApiSubscription } from "src/api/types";
import Card from "src/components/Card";

interface SubscriptionNextPaymentProps {
	subscription: ApiSubscription;
}

const SubscriptionNextPayment = ({
	subscription,
}: SubscriptionNextPaymentProps) => {
	const { t } = useTranslation("subscription", {
		keyPrefix: "subscription-next-payment",
	});
	const nextPaymentDate = moment(subscription.ends_at).format(
		"DD MMMM, YYYY"
	);

	return (
		<Card contentClassName="p-6 gap-2 flex flex-col justify-center flex-1 h-full">
			<p className="mb-0">{t("title")}</p>
			<p className="mb-0 font-bold">
				{t("description", { date: nextPaymentDate })}
			</p>
		</Card>
	);
};

export default SubscriptionNextPayment;
