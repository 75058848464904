import moment from "moment";
import { ComponentProps, useMemo } from "react";
import { useDateRangeFilter } from "src/components/date-range-filter/Provider";
import withTranslation, {
	Translation,
} from "src/components/hoc/withTranslation";
import { cn } from "src/lib/utils";

type Extenders = Translation & ComponentProps<"div">;

interface PresetsProps extends Extenders {}

const Presets = ({ t, className, ...rest }: PresetsProps) => {
	const { onChange, value } = useDateRangeFilter();
	const dates = useMemo(() => {
		return [
			{
				label: t("last.days", { days: 30 }),
				date: {
					start: moment().subtract(30, "days"),
					end: moment(),
				},
			},
			{
				label: t("last.months", { months: 3 }),
				date: {
					start: moment().subtract(3, "months"),
					end: moment(),
				},
			},
			{
				label: t("last.months", { months: 6 }),
				date: {
					start: moment().subtract(6, "months"),
					end: moment(),
				},
			},
			{
				label: t("this.week"),
				date: {
					start: moment().startOf("week"),
					end: moment().endOf("week"),
				},
			},
			{
				label: t("this.month"),
				date: {
					start: moment().startOf("month"),
					end: moment().endOf("month"),
				},
			},
			{
				label: t("this.quarter"),
				date: {
					start: moment().startOf("quarter"),
					end: moment().endOf("quarter"),
				},
			},
			{
				label: t("this.year"),
				date: {
					start: moment().startOf("year"),
					end: moment().endOf("year"),
				},
			},
		];
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<div
			{...rest}
			className={cn("flex flex-col gap-1 pr-2 pt-8", className)}
		>
			{dates.map((item) => (
				<Item
					key={item.label}
					label={item.label}
					active={
						(value.start.isSame(item.date.start) &&
							value.end &&
							value.end.isSame(item.date.end)) ||
						false
					}
					onClick={() => onChange(item.date)}
				/>
			))}
		</div>
	);
};

interface ItemProps {
	label: string;
	active?: boolean;
	onClick: () => void;
}

const Item = ({ label, active, onClick }: ItemProps) => {
	return (
		<button
			className={cn(
				"text-[#767777] w-max hover:text-background-foreground transition-colors relative text-[14px] md:text-[17px]",
				active && "text-background-foreground"
			)}
			onClick={(e) => {
				e.preventDefault();
				onClick();
			}}
		>
			{label}

			<div
				className={cn(
					"absolute left-0 bottom-0 h-[1px] rounded-md bg-dark flex transition-all w-0",
					active && "right-0 w-full"
				)}
			></div>
		</button>
	);
};

Presets.locale = {
	nl: {
		last: {
			days: "Laatste {{days}} dagen",
			months: "Laatste {{months}} maanden",
		},
		this: {
			week: "Huidige week",
			month: "Huidige maand",
			quarter: "Dit kwartaal",
			year: "Dit jaar",
		},
	},
	en: {
		last: {
			days: "Last {{days}} days",
			months: "Last {{months}} months",
		},
		this: {
			week: "This week",
			month: "This month",
			quarter: "This quarter",
			year: "This year",
		},
	},
};

export default withTranslation(Presets);
