const profile = {
	"company-create-modal": {
		"email-incorrect": "Ongeldig emailadres",
		required: "Verplicht",
		"already-in-use": "{{email}} is al in gebruikt",
		status: {
			added: "De vestiging is toegevoegd! Het opgegeven emailadres ontvangt een uitnodiging zsm.",
			failed: "Er is iets misgegaan, probeer het later opnieuw. Blijft dit probleem neem dan contact op met <1>support@{{tenant.slug}}.nl</1>",
			button: "Sluiten",
		},
		title: "Vestiging toevoegen",
		description:
			"Vul een emailadres in en wij nodigen degene uit voor de vestiging op <1>{{address}}</1>. D.m.v. een mail kan degene een eigen account aanmaken",
		"fill-description": "Vul het adres in van de nieuwe vestiging",
		email: "E-mail",
		buttons: {
			back: "Stap terug",
			create: "Vestiging aanmaken",
			cancel: "Annuleren",
		},
	},
	"companies-view": {
		title: "Vestigingen",
		add: "Vestiging toevoegen",
		empty: "Op dit moment zijn er geen vestigingen",
		card: {
			primary: "Hoofdvestiging",
			current: "Jouw vestiging",
			branche: "Vestiging",
			kvk: "KVK",
		},
	},
	"company-view": {
		title: "Bedrijfsprofiel",
		avatar: {
			title: "Avatar",
			description:
				"Voeg je logo toe, hierdoor herkennen de professionals sneller jouw opdrachten.",
		},
		info: {
			title: "Bedrijfsgegevens",
		},
		"invoice-address": {
			title: "Factuuradres",
			description: "Deze gegevens worden getoond op de facturen.",
		},
		contact: {
			title: "Contactgegevens",
			description:
				"Gematchte professionals kunnen hier op vragen achterlaten over bijvoorbeeld openstaande betalingen of dergelijke vragen.",
		},
	},
	"invite-user-modal": {
		fields: {
			required: "Verplicht",
			"incorrect-email": "Ongeldig e-mailadres",
			"inuse-email": "{{email}} is al in gebruik",
		},
		status: {
			added: "De gebruiker is toegevoegd! Het opgegeven emailadres ontvangt een uitnodiging zsm.",
			failed: "Er is iets misgegaan, probeer het later opnieuw. Blijft dit probleem neem dan contact op met <1>support@{{tenant.slug}}.nl</1>",
			button: "Sluiten",
		},
		title: "Gebruiker uitnodigen",
		description: "Nodig een gebruiker uit door middel van een email",
		form: {
			email: "E-mail",
			button: "Uitnodiging versturen",
		},
	},
	"profile-tabs": {
		"my-profile": "Mijn profiel",
		"my-resume": "Mijn CV",
		"my-subscription": "Mijn abonnement",
		"company-profile": "Bedrijfsprofiel",
		companies: "Vestigingen",
		users: "Gebruikers",
		teams: "Teams",
		"teams-and-users": "Teams & Gebruikers",
	},
};

export default profile;
