import moment from "moment";
import { useEffect, useState } from "react";
import Badge from "src/components/Badge";
import Button from "src/components/Button";
import withTranslation, {
	Translation,
} from "src/components/hoc/withTranslation";
import {
	InviteByEmail,
	InviteByEmailInput,
	InviteByEmailList,
} from "src/components/network/invite-modal/invite-by-email";
import ShareClipboard from "src/components/promotion/share/ShareClipboard";
import List from "src/components/ui/list";
import useTenant from "src/hooks/api/services/tenants/useTenant";
import useNetworkInvites from "src/hooks/api/services/useInviteNetwork";
import useAuth from "src/hooks/selectors/useAuth";
import useModal from "src/hooks/useModal";
import { cn } from "src/lib/utils";

const InviteModal = ({ t }: Translation) => {
	useModal({
		title: t("title"),
	});
	const { company } = useAuth();
	const [submitStatus, setSubmitStatus] = useState<"success" | "error">();
	const [emails, setEmails] = useState<string[]>([]);
	const { tenant } = useTenant();
	const { invites, status, actions, pagination } = useNetworkInvites();
	const slug = company.slug;
	const url = `${tenant.urls.account}/signup/invite/${slug}`;

	useEffect(() => {
		actions.list();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleEmailSubmit = async () => {
		setSubmitStatus(undefined);

		try {
			await actions.create({
				emails,
			});
			await actions.list();
			setEmails([]);
			setSubmitStatus("success");
		} catch (error) {
			setSubmitStatus("error");
		}
	};

	return (
		<div className="flex flex-1 flex-col gap-4 p-4 md:p-6">
			<div className="md:border border-border rounded-xl md:p-6 flex flex-col gap-4">
				<p className="">{t("url.title")}</p>
				<ShareClipboard {...{ url }} />
				<small className="opacity-70">{t("url.description")}</small>
			</div>
			<div className="md:border border-border rounded-xl md:p-6 flex flex-col gap-6">
				<div className="flex flex-col gap-1">
					<p className="">{t("email.title")}</p>
					<p className="opacity-70">{t("email.description")}</p>
				</div>
				{submitStatus && (
					<div
						className={cn(
							"flex flex-col gap-2 w-max p-3 rounded-md",
							submitStatus === "success" &&
								"bg-green-300 text-green",
							submitStatus === "error" && "bg-red-300 text-red"
						)}
					>
						<p className="text-sm text-accent-foreground">
							{t(`email.${submitStatus}.description`)}
						</p>
					</div>
				)}
				<InviteByEmail {...{ emails, setEmails }}>
					<InviteByEmailInput />
					<InviteByEmailList className="mt-4" />
				</InviteByEmail>
				<div className="flex flex-col gap-2">
					<Button
						onClick={handleEmailSubmit}
						className="md:w-fit"
						disabled={emails.length === 0}
					>
						{t(
							emails?.length === 0
								? "email.submit_none"
								: "email.submit",
							{
								count: emails.length || 0,
							}
						)}
					</Button>
				</div>
			</div>

			<div className="flex flex-col">
				<List
					status={
						status !== "idle" && invites?.length ? "idle" : status
					}
					data={invites}
				>
					<List.Head>
						<List.Item>{t("headers.status")}</List.Item>
						<List.Item>{t("headers.receiver")}</List.Item>
						<List.Item>{t("headers.created_at")}</List.Item>
					</List.Head>
					<List.Items>
						{(invite) => (
							<List.Row>
								<List.Item>
									<Badge
										className={cn(
											"whitespace-nowrap gap-1 flex items-center",
											invite.status === "PENDING" &&
												"bg-accent text-accent-foreground",
											[
												"ACCEPTED",
												"ACCEPTED_AUTOMATIC",
											].includes(invite.status) &&
												"bg-green-200 text-green"
										)}
									>
										{invite.status ===
											"ACCEPTED_AUTOMATIC" && (
											<i className="far fa-check text-[14px]"></i>
										)}
										{t(`email.status.${invite.status}`)}
									</Badge>
								</List.Item>
								<List.Item className="w-full">
									{invite.email}
								</List.Item>
								<List.Item>
									{moment(invite.created_at).format(
										"DD-MM-YYYY"
									)}
								</List.Item>
							</List.Row>
						)}
					</List.Items>
					<List.Empty>
						<p>{t("empty.description")}</p>
					</List.Empty>
					<List.Pagination
						{...{ pagination }}
						onNavigate={async (page) => {
							await pagination.setPage(page);
							window.scrollTo(0, 0);
							return null;
						}}
					/>
				</List>
			</div>
		</div>
	);
};

InviteModal.locale = {
	nl: {
		title: "Uitnodigen",

		url: {
			title: "Deel je unieke link",
			description:
				"Nieuwe en opdrachtnemers die al een account hebben kunnen zich aanmelden via deze link.",
		},
		email: {
			title: "Nodig direct uit via email",
			description:
				"Voer de emailadressen in van opdrachtnemers die je wilt uitnodigen. Wanneer het een bekend emailadres is worden ze direct toegevoegd aan je netwerk, mocht dit niet het geval zijn sturen we een uitnodiging.",
			submit_one: "1 uitnodiging versturen",
			submit_other: "{{count}} uitnodigingen versturen",
			submit_none: "Uitnodigingen versturen",
			success: {
				description: "De uitnodigingen zijn verstuurd!",
			},
			status: {
				PENDING: "In afwachting",
				ACCEPTED: "Geaccepteerd",
				ACCEPTED_AUTOMATIC: "Automatisch",
			},
			error: {
				description:
					"Er is iets fout gegaan bij het versturen van de uitnodigingen.",
			},
		},
		headers: {
			receiver: "Ontvanger",
			created_at: "Aangemaakt",
			status: "Status",
		},
		empty: {
			description: "Er zijn nog geen uitnodigingen verstuurd.",
		},
	},
	en: {
		title: "Invite",
		url: {
			title: "Share your unique link",
			description: "New and existing users can sign up using this link.",
		},
		email: {
			title: "Invite directly via email",
			description:
				"Enter the email addresses of the users you want to invite. If it's a known email address, they will be added to your network automatically, otherwise we will send an invitation.",
			submit: "Send invitations",
		},
		headers: {
			receiver: "Receiver",
			created_at: "Created",
			status: "Status",
		},
		empty: {
			description: "There are no invitations sent yet.",
		},
	},
};

export default withTranslation(InviteModal);
