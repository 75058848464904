import { useTranslation } from "react-i18next";
import { ApiEnvelopeSigner, LayoutVariants } from "src/api/types";
import Button from "src/components/Button";
import SignersUser from "src/components/signer/SignersUser";
import useGet from "src/hooks/rest/useGet";
import useSocketListener from "src/hooks/sockets/useSocketListener";
import useBackUrl from "src/hooks/useBackUrl";

interface SignersProps {
	id?: string | number;
	hid?: string;
	variant: LayoutVariants;
	title?: string;
	showButton?: boolean;
	buttonUrl?: string;
	isUpdateViewed: boolean;
}

const Signers = ({
	id,
	hid,
	variant,
	title,
	showButton,
	buttonUrl,
	isUpdateViewed,
}: SignersProps) => {
	const { t } = useTranslation("buttons");
	const backUrl = useBackUrl();
	const [{ data: signers }, loading, { fetchGet }] = useGet(
		`/envelopes/${id || hid}/signers?with[]=user`
	);
	useSocketListener(`envelope.${hid}.signers`, () => {
		fetchGet(false);
	});

	const signerMe = (signers || []).find(
		(signer: ApiEnvelopeSigner) => signer.is_me
	);
	const isSigned = signerMe?.status === "SIGNED";

	const getButton = () => {
		if (signerMe.url && !isSigned) {
			return (
				<Button
					type={isSigned ? "dark" : "dark"}
					href={signerMe.url}
					className="flex-1 md:flex-[0]"
				>
					{!isSigned ? t("sign") : t("view")}
				</Button>
			);
		}

		if (
			!signerMe.url ||
			(signerMe.url &&
				(signers || []).every(
					(s: ApiEnvelopeSigner) => s.status === "SIGNED"
				))
		) {
			return (
				<Button
					type={isSigned ? "dark" : "primary"}
					to={buttonUrl || `/signer/${hid}?${backUrl}`}
					// className="flex-1 md:flex-[0]"
					small
				>
					{!isSigned ? t("sign") : t("view")}
				</Button>
			);
		}

		return null;
	};

	return (
		<>
			{title && <strong>{title}</strong>}
			<div className={`flex flex-col gap-3 ${title && "mt-3"}`}>
				{loading &&
					new Array(2)
						.fill("")
						.map((_, index) => (
							<SignersUser.Loader
								key={`signer-loader-${index}`}
								{...{ variant }}
							/>
						))}

				{!loading &&
					(signers || []).map((signer: ApiEnvelopeSigner) => (
						<SignersUser
							key={`signer-${signer.id}`}
							{...{ signer, variant, isUpdateViewed }}
						/>
					))}
			</div>
			{showButton && signerMe?.id && (
				<div className="flex justify-center mt-3">{getButton()}</div>
			)}
		</>
	);
};

Signers.defaultProps = {
	variant: "dark",
	isUpdateViewed: true,
};

export default Signers;
