import moment from "moment";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { ApiTimeTracking } from "src/api/types";
import Button from "src/components/Button";
import Loader from "src/components/Loader";
import Input from "src/components/form/Input";
import withTranslation, {
	Translation,
} from "src/components/hoc/withTranslation";
import Layout from "src/components/layout/Layout";
import useTimeTracking from "src/hooks/api/services/useTimeTracking";
import { ApiStatus } from "src/hooks/api/utils/useApi";
import useModal from "src/hooks/useModal";

function EditTimeTrackingModal({ t }: Translation) {
	const { timeTrackingId: id } = useParams();
	const [form, setForm] = useState({
		started_at: "",
		ended_at: "",
	});
	const [status, setStatus] = useState<ApiStatus>("loading");
	const { actions, api } = useTimeTracking();
	const modal = useModal();
	const isEndBefore =
		form?.started_at &&
		form?.ended_at &&
		moment(form.ended_at).isBefore(moment(form.started_at));
	const disabled = !(form.started_at || form.ended_at) && isEndBefore;

	const getField = (name: keyof typeof form) => ({
		name,
		value: form[name] || "",
		label: t(`fields.${name}.label`),
		noMarginBottom: true,
		handleChange: (e: any) =>
			setForm((state) => ({
				...state,
				[name]: e.target.value,
			})),
	});

	const onSubmit = (event: any) => {
		event.preventDefault();
		if (!id || disabled) return;
		setStatus("updating");
		const data = {
			id,
			started_at: moment(form.started_at).format("YYYY-MM-DD HH:mm:ss"),
			ended_at: moment(form.ended_at).format("YYYY-MM-DD HH:mm:ss"),
		};
		actions
			.update(data, {
				updateList: true,
				disableUpdateItem: true,
			})
			.then(modal.onClose)
			.finally(() => {
				setStatus("idle");
			});
	};

	const onDelete = (event: any) => {
		event?.preventDefault();
		if (!id) return;
		setStatus("deleting");
		actions
			.delete(id)
			.then(modal.onClose)
			.finally(() => {
				setStatus("idle");
			});
	};

	useEffect(() => {
		modal.setTitle(t("title"));
		api.get(`/${id}`)
			.then(({ data }) => {
				const time: ApiTimeTracking = data?.data;
				if (time.id) {
					setForm({
						started_at: time.started_at,
						ended_at: time.ended_at || "",
					});
				}
			})
			.catch(modal.onClose)
			.finally(() => setStatus("idle"));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<Layout title={t("title")}>
			{status === "loading" ? (
				<div className="p-8 flex justify-center items-center">
					<Loader />
				</div>
			) : (
				<form className="flex flex-col gap-4" {...{ onSubmit }}>
					<Input type="date" withTime {...getField("started_at")} />
					<Input type="date" withTime {...getField("ended_at")} />
					<div className="mt-4 flex flex-col gap-4">
						<Button
							submit
							loading={status === "updating"}
							disabled={status !== "idle" || !!isEndBefore}
						>
							{t("save")}
						</Button>
						<Button
							loading={status === "deleting"}
							disabled={status !== "idle"}
							onClick={onDelete}
							type="gray"
						>
							{t("delete")}
						</Button>
					</div>
				</form>
			)}
		</Layout>
	);
}

EditTimeTrackingModal.locale = {
	nl: {
		title: "Wijzig tijd",
		fields: {
			started_at: {
				label: "Start",
			},
			ended_at: {
				label: "Eind",
			},
		},
		save: "Opslaan",
		delete: "Verwijder",
	},
	en: {
		title: "Edit time",
		fields: {
			started_at: {
				label: "Start",
			},
			ended_at: {
				label: "End",
			},
		},
		save: "Save",
		delete: "Delete",
	},
};

export default withTranslation(EditTimeTrackingModal);
