import { useTranslation } from "react-i18next";
import { useEffect } from "react";
import useTaskForm from "src/hooks/api/tasks/useTaskForm";
import useAuth from "src/hooks/selectors/useAuth";
import useTaskFormSteps from "src/hooks/api/tasks/useTaskFormSteps";
import withTranslation, {
	Translation,
} from "src/components/hoc/withTranslation";
import NoSubscriptionMiddleware from "src/components/middlewares/NoSubscriptionMiddleware";
import TaskForm from "src/components/tasks/TaskForm";
import TaskFormWrapper from "src/components/tasks/TaskForm/TaskFormWrapper";
import Loader from "src/components/Loader";
import useModules from "src/hooks/selectors/useModules";
import useModal from "src/hooks/useModal";

interface CreateTaskModalProps extends Translation {}

function CreateTaskModal({ t }: CreateTaskModalProps) {
	const { modules } = useModules();
	const modal = useModal();
	const auth = useAuth();
	const { t: translatedStep } = useTranslation("tasks", {
		keyPrefix: "steps",
	});
	const { task, actions, status } = useTaskForm();
	const { isFinal, currentStep } = useTaskFormSteps();

	useEffect(() => {
		actions.get().then((task) => {
			modal.setTitle(task.title);
		});
		return () => {
			actions.clear();
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	if (!auth.has_subscription) {
		return <NoSubscriptionMiddleware />;
	}

	if (status === "loading") {
		return (
			<div className="flex flex-col p-8 justify-center items-center flex-1">
				<Loader />
			</div>
		);
	}

	return (
		<TaskFormWrapper>
			{currentStep === translatedStep("planning.questions") && (
				<TaskForm.AIQuestions />
			)}
			{currentStep === translatedStep("planning.extra") && (
				<TaskForm.Extra />
			)}

			{currentStep === translatedStep("description") && (
				<TaskForm.Description />
			)}
			{currentStep === translatedStep("budget") && (
				<TaskForm.Budget
					amountLocked={task?.student_hid ? true : false}
				/>
			)}
			{currentStep === translatedStep("shifts") && <TaskForm.Subtasks />}
			{currentStep === translatedStep("location") && <TaskForm.Address />}
			{currentStep === translatedStep("date") && <TaskForm.Target />}
			{currentStep === translatedStep("invite") && (
				<TaskForm.StudentPool />
			)}
			{currentStep === translatedStep("form") && <TaskForm.Interview />}

			{isFinal && !modules?.includes("ai-description-creation") && (
				<TaskForm.Summary />
			)}
			{isFinal && modules?.includes("ai-description-creation") && (
				<TaskForm.SummaryAI />
			)}
		</TaskFormWrapper>
	);
}

CreateTaskModal.locale = {};

export default withTranslation(CreateTaskModal);
