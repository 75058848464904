import { useRef, useState } from "react";
import Card from "src/components/Card";
import useBlur from "src/hooks/useBlur";
import useElementSize from "src/hooks/useElementSize";

interface ButtonMenuProps {
	head?: any;
	children?: any;
}

const ButtonMenu = ({ head, children }: ButtonMenuProps) => {
	const [visible, setVisible] = useState(false);
	const { ref: buttonRef, size: buttonSize } = useElementSize();
	const ref = useRef(null);
	useBlur(ref, () => setVisible(false));
	const { ref: childRef, size: childSize } = useElementSize();
	const left = -((childSize.width - buttonSize.width) / 2);

	return (
		<div
			{...{ ref }}
			className="relative z-[88] flex flex-col justify-center"
		>
			<div
				ref={buttonRef}
				onClick={() => setVisible(!visible)}
				className="cursor-pointer"
			>
				{head}
			</div>
			<div
				style={{
					left,
					top: buttonSize.height,
					opacity: visible ? 100 : 0,
				}}
				className="absolute top-0 transition-all"
			>
				<Card type="no-padding">
					<div ref={childRef} className="p-3">
						{children}
					</div>
				</Card>
			</div>
		</div>
	);
};

export default ButtonMenu;
