import React from "react";
import Button from "src/components/Button";

interface LayoutHeaderProps {
	children?: any;
	title?: any;
	backUrl?: string;
}

const LayoutHeader = ({ children, title, backUrl }: LayoutHeaderProps) => {
	return (
		<div className="flex items-center flex-wrap justify-between gap-4">
			{(title || backUrl) && (
				<div className="flex gap-4 items-center">
					{backUrl && (
						<Button to={backUrl} iconOnly type="gray">
							<i className="fas fa-arrow-left"></i>
						</Button>
					)}
					<h2 className="md:text-[30px] text-[20px] mb-0">{title}</h2>
				</div>
			)}
			{children}
		</div>
	);
};

export default LayoutHeader;
