import withTranslation, {
	Translation,
} from "src/components/hoc/withTranslation";
import { cn } from "src/lib/utils";

interface CardLoadMoreProps extends Translation {
	variant?: "all" | "more";
	onClick: () => void;
	value: boolean;
	className?: string;
}

export const CardLoadMore = ({
	t,
	variant = "all",
	onClick,
	value,
	className,
}: CardLoadMoreProps) => {
	return (
		<span
			{...{ onClick }}
			className={cn("cursor-pointer text-border font-regular", className)}
		>
			{variant === "all" && t(value ? "all.less" : "all.all")}
			{variant === "more" && t(value ? "more.less" : "more.all")}
		</span>
	);
};

CardLoadMore.locale = {
	nl: {
		more: {
			all: "Laad meer",
			less: "Toon minder",
		},
		all: {
			all: "Toon alles",
			less: "Toon minder",
		},
	},
	en: {
		more: {
			all: "Load more",
			less: "Show less",
		},
		all: {
			all: "Show all",
			less: "Show less",
		},
	},
};

export default withTranslation(CardLoadMore);
