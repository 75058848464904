import { ComponentProps, createContext, useContext } from "react";
import { ApiRegistration } from "src/api/types";
import AvatarComp, { AvatarSize } from "src/components/Avatar";
import { cn } from "src/lib/utils";

interface RegistrationContextType {
	registration: ApiRegistration;
}

const RegistrationContext = createContext<RegistrationContextType>({
	registration: {} as ApiRegistration,
});

export function useRegistration() {
	return useContext(RegistrationContext);
}

interface RegistrationProps extends ComponentProps<"div"> {
	registration: RegistrationContextType["registration"];
}

export default function Registration({
	children,
	className,
	registration,
	...rest
}: RegistrationProps) {
	return (
		<RegistrationContext.Provider value={{ registration }}>
			<div className={cn("flex flex-col", className)} {...rest}>
				{children}
			</div>
		</RegistrationContext.Provider>
	);
}

const Avatar = ({
	className,
	size = "large",
	...rest
}: Omit<ComponentProps<typeof AvatarComp>, "size"> & { size?: AvatarSize }) => {
	const { registration } = useRegistration();
	return (
		<AvatarComp {...rest} {...{ size }} src={registration.user?.avatar} />
	);
};

interface NameProps extends ComponentProps<"h2"> {
	className?: string;
}

const Name = ({ className, ...rest }: NameProps) => {
	const { registration } = useRegistration();
	return (
		<h2 className={cn("text-2xl", className)} {...rest}>
			{registration.user?.full_name}
		</h2>
	);
};

export { Avatar as RegistrationAvatar, Name as RegistrationName };
