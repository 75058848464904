import { Route } from "react-router-dom";
import DashboardOfferRegistrationView from "src/views/authenticated/offers/registrations/OfferRegistrationView";
import DashboardOffersCreateFinishedView from "src/views/authenticated/offers/OffersCreateFinishedView";
import DashboardOffersCreateView from "src/views/authenticated/offers/OffersCreateView";
import DashboardOffersDetailView from "src/views/authenticated/offers/OffersDetailView";
import DashboardOffersEditView from "src/views/authenticated/offers/OffersEditView";
import DashboardOffersView from "src/views/authenticated/offers/OffersView";
import ProtectedRoute from "src/routes/middlewares/ProtectedRoute";

const OffersRoutes = [
	<Route
		element={
			<ProtectedRoute type="student">
				<DashboardOffersView />
			</ProtectedRoute>
		}
		path="/offers"
		key="/offers"
	/>,
	<Route
		element={
			<ProtectedRoute type="student">
				<DashboardOffersCreateView />
			</ProtectedRoute>
		}
		path="/offers/create"
		key="/offers/create"
	/>,
	<Route
		element={
			<ProtectedRoute type="student">
				<DashboardOffersCreateFinishedView />
			</ProtectedRoute>
		}
		path="/offers/create/:slug/finished"
		key="/offers/create/:slug/finished"
	/>,
	<Route
		element={
			<ProtectedRoute type="student">
				<DashboardOffersDetailView />
			</ProtectedRoute>
		}
		path="/offers/:id/show"
		key="/offers/:id/show"
	/>,
	<Route
		element={
			<ProtectedRoute type="student">
				<DashboardOffersEditView />
			</ProtectedRoute>
		}
		path="/offers/:id/edit"
		key="/offers/:id/edit"
	/>,
	<Route
		element={
			<ProtectedRoute type="student">
				<DashboardOfferRegistrationView />
			</ProtectedRoute>
		}
		path="/offers/:id/registration/:registration"
		key="/offers/:id/registration/:registration"
	/>,
];

export default OffersRoutes;
