import EditableText, {
	EditableVariant,
} from "src/components/form/EditableText";
import withTranslation, {
	Translation,
} from "src/components/hoc/withTranslation";
import useProjects from "src/hooks/api/services/projects/useProjects";
import { cn } from "src/lib/utils";

interface ProjectEditableTitleProps extends Translation {
	className?: string;
	variant: EditableVariant;
	autoFocus?: boolean;
}

const ProjectEditableTitle = ({
	t,
	className,
	variant,
	autoFocus,
}: ProjectEditableTitleProps) => {
    const {formState, setFormState}  = useProjects();

	return (
		<EditableText
			className={cn("bg-transparent", className)}
			{...{ variant, autoFocus }}
			value={formState.name}
			onChange={(e: any) => {
				let title = e.target.value;
				title = title.charAt(0).toUpperCase() + title.slice(1);
                setFormState((prev) => ({ ...prev, 'name': title }));
			}}
			placeholder={t("placeholder")}
		/>
	);
};

ProjectEditableTitle.locale = {
	nl: {
		placeholder: "Project naam...",
	},
	en: {
		placeholder: "Project name...",
	},
};

export default withTranslation(ProjectEditableTitle);
