import { ComponentProps, createContext, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Button from "src/components/Button";
import EmptyState from "src/components/EmptyState";
import { useT } from "src/components/hoc/withTranslation";
import Loader from "src/components/Loader";
import { Label } from "src/components/ui/label";
import {
	RadioGroup,
	RadioGroupItem,
	RadioGroupWrapper,
} from "src/components/ui/radio-group";
import {
	useVacancyForm,
	VacancyFormSectionName,
} from "src/components/vacancies/vacancy-form/provider";
import useTaskCredits from "src/hooks/api/services/useTaskCredits";
import { formatPrice } from "src/lib/formatters";
import { cn } from "src/lib/utils";

interface PublishVacancyFormContextType {
	disabled: boolean;
	onSubmit: (creditId: string) => Promise<void>;
}

const PublishVacancyFormContext = createContext<PublishVacancyFormContextType>({
	disabled: false,
	onSubmit: async () => {},
});

interface PublishVacancyFormProps extends ComponentProps<"div"> {}

const PublishVacancyForm = ({
	children,
	className,
	...rest
}: PublishVacancyFormProps) => {
	const { vacancy, valid, actions, creditId } = useVacancyForm();
	const disabled = !Object.values(valid).every((value) => value) || !creditId;
	const navigate = useNavigate();

	const onSubmit = async () => {
		if (disabled || !vacancy || !creditId) return;
		actions.setStatus("updating");
		try {
			await actions.publish();
			navigate(`/vacancies/${vacancy.id}?published=1`);
		} finally {
			actions.setStatus("idle");
		}
	};

	return (
		<PublishVacancyFormContext.Provider value={{ disabled, onSubmit }}>
			<div className={cn("flex flex-col", className)} {...rest}>
				{children}
			</div>
		</PublishVacancyFormContext.Provider>
	);
};

export default PublishVacancyForm;

const EditButton = () => {
	const { step } = useVacancyForm();
	const t = useT({
		nl: {
			edit: "Aanpassen",
		},
		en: {
			edit: "Edit",
		},
	});
	return (
		<Button xsmall type="border" onClick={() => step.set("check")}>
			{t("edit")}
		</Button>
	);
};

const ChooseCreditList = ({
	className,
	children,
	...rest
}: ComponentProps<"div">) => {
	const { credits, actions: taskCreditsActions, status } = useTaskCredits();
	const { actions, creditId } = useVacancyForm();
	const t = useT({
		nl: {
			title: "Hoe lang wil je je vacature delen?",
			empty: {
				title: "Kies eerst een pakket",
				description:
					"Voor je je vacature met {{global.students}} kunt delen, moet je eerst een pakket kopen.",
				cta: "Pakket afnemen",
			},
			month_one: "{{count}} Maand",
			month_other: "{{count}} Maanden",
		},
		en: {
			title: "How long do you want to share your vacancy?",
			empty: {
				title: "Choose a package first",
				description:
					"To share your vacancy with {{global.students}}, you must first purchase a package.",
				cta: "Purchase package",
			},
			month_one: "{{count}} Month",
			month_other: "{{count}} Months",
		},
	});

	//Filter the credits based on unique available_days and sort by lowest available_days
	const uniqueCredits = credits
		.filter(
			(credit, index, self) =>
				index ===
				self.findIndex(
					(t) => t.available_days === credit.available_days
				)
		)
		.sort((a, b) => a.available_days - b.available_days);

	useEffect(() => {
		taskCreditsActions.list();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	if (status !== "idle") {
		return (
			<div
				className={cn(
					"flex flex-col p-4 justify-center items-center rounded-md border border-border",
					className
				)}
			>
				<Loader />
			</div>
		);
	}

	if (credits.length === 0) {
		return (
			<div
				className={cn(
					"flex flex-col p-6 gap-1.5 justify-center items-center rounded-md border border-border",
					className
				)}
			>
				<EmptyState.Title>{t("empty.title")}</EmptyState.Title>
				<EmptyState.Description>
					{t("empty.description")}
				</EmptyState.Description>
				<Button to="/subscription" className="mt-2">
					{t("empty.cta")}
				</Button>
			</div>
		);
	}

	return (
		<div
			className={cn(
				"flex flex-col p-4 rounded-md border border-border gap-3",
				className
			)}
			{...rest}
		>
			<p>{t("title")}</p>
			<RadioGroup
				value={creditId || undefined}
				onValueChange={(value) => actions.setCreditId(value)}
			>
				{uniqueCredits.map((credit) => (
					<RadioGroupWrapper key={credit.id}>
						<RadioGroupItem value={credit.id} id={credit.id} />
						<Label className="cursor-pointer" htmlFor={credit.id}>
							{t("month", {
								count: credit.available_days / 31,
							})}
						</Label>
					</RadioGroupWrapper>
				))}
			</RadioGroup>
		</div>
	);
};

const SubmitButton = () => {
	const { disabled, onSubmit } = useContext(PublishVacancyFormContext);
	const t = useT({
		nl: {
			submit: "Vacature plaatsen",
		},
		en: {
			submit: "Publish vacancy",
		},
	});

	return (
		<Button {...{ disabled }} onClick={() => onSubmit("123")}>
			{t("submit")}
		</Button>
	);
};

const Section = ({
	children,
	name,
	className,
	...rest
}: ComponentProps<"div"> & { name: VacancyFormSectionName }) => {
	const t = useT({
		nl: {
			titles: {
				description: "Vacature teksten",
				salary: "Salaris",
				address: "Locatie",
			},
		},
		en: {
			titles: {
				description: "Vacancy texts",
				salary: "Salary",
				address: "Address",
			},
		},
	});
	return (
		<div className={cn("", className)} {...rest}>
			<div className="flex items-center justify-between gap-3">
				<strong>{t(`titles.${name}`)}</strong>
				<EditButton />
			</div>
			<div className="flex flex-col gap-3">{children}</div>
		</div>
	);
};

const Description = () => {
	const { vacancy } = useVacancyForm();
	const t = useT({
		nl: {
			company: "Over het bedrijf",
			offer: "Het aanbod",
			role: "De rol",
			profile: "Gezocht profiel",
		},
		en: {
			company: "About the company",
			offer: "The offer",
			role: "The role",
			profile: "Sought profile",
		},
	});
	return (
		<div className="flex flex-col gap-3">
			{["company", "offer", "role", "profile"].map((name) => (
				<div
					className="flex items-center gap-3"
					key={`description-${name}`}
				>
					<div
						className={cn(
							"w-8 h-8 rounded-md bg-accent flex items-center justify-center text-sm",
							vacancy?.descriptions[
								name as keyof typeof vacancy.descriptions
							] && "bg-green-300 text-green"
						)}
					>
						{vacancy?.descriptions[
							name as keyof typeof vacancy.descriptions
						] ? (
							<i className="fas fa-check"></i>
						) : (
							<i className="fas fa-clock"></i>
						)}
					</div>
					<p>{t(name)}</p>
				</div>
			))}
		</div>
	);
};

const Salary = () => {
	const { vacancy } = useVacancyForm();

	const t = useT({
		nl: {
			hide: "Geen salaris tonen",
		},
		en: {
			hide: "Hide salary",
		},
	});

	if (vacancy?.salary?.min === null && vacancy?.salary?.max === null) {
		return <p>{t("hide")}</p>;
	}

	return (
		<div className="flex items-center gap-1">
			<p>
				{vacancy?.salary.min ? formatPrice(vacancy?.salary.min) : "-"}
			</p>
			<p>-</p>
			<p>
				{vacancy?.salary.max ? formatPrice(vacancy?.salary.max) : "-"}
			</p>
		</div>
	);
};

const Address = () => {
	const { vacancy } = useVacancyForm();

	if (!vacancy?.address) return null;

	if (vacancy.address.street && vacancy.address.city) {
		return (
			<p>
				{vacancy.address.street}, {vacancy.address.city}
			</p>
		);
	}

	return <p>{vacancy.address.city}</p>;
};

export {
	Address as PublishVacancyFormAddress,
	ChooseCreditList as PublishVacancyFormChooseCreditList,
	Description as PublishVacancyFormDescription,
	EditButton as PublishVacancyFormEditButton,
	Salary as PublishVacancyFormSalary,
	Section as PublishVacancyFormSection,
	SubmitButton as PublishVacancyFormSubmitButton,
};
