import { useState } from "react";
import Avatar from "src/components/Avatar";
import useAuth from "src/hooks/selectors/useAuth";
import useLogout from "src/hooks/useLogout";
import Link from "src/components/Link";
import {
	DropdownMenu,
	DropdownMenuContent,
	DropdownMenuItem,
	DropdownMenuTrigger,
} from "src/components/ui/dropdown-menu";
import withTranslation, {
	Translation,
} from "src/components/hoc/withTranslation";
import { cn } from "src/lib/utils";
import Icon from "src/components/icon/Icon";
import { useLocation } from "react-router-dom";
import useTheme from "src/hooks/useTheme";

const UserProfileButton = ({ t }: Translation) => {
	const { theme, toggle } = useTheme();
	const [open, onOpenChange] = useState(false);
	const auth = useAuth();
	const { loading: loggingOut, handleLogout } = useLogout();
	const type = auth.type;

	return (
		<DropdownMenu {...{ open, onOpenChange }}>
			<DropdownMenuTrigger asChild>
				<div className="flex cursor-pointer">
					<Avatar
						src={auth.avatar}
						className="border border-border"
					/>
				</div>
			</DropdownMenuTrigger>

			<DropdownMenuContent sideOffset={8} align="end" className="z-[99]">
				<UserProfileButtonItem
					to="/profile/show"
					icon="fa-user"
					label={t("my-profile")}
				/>
				<UserProfileButtonItem
					to="/settings/notifications"
					icon="fa-cogs"
					label={t("settings")}
				/>
				<UserProfileButtonItem
					to="/reviews/about-me"
					icon="fa-star"
					label={t("reviews")}
				/>

				{type === "company" && (
					<>
						{/* <UserProfileButtonItem
							icon="fa-question"
							to="https://getjobsdone.nl/bedrijven/gebruik-van-het-platform"
							label={t("tutorial")}
						/> */}
						<UserProfileButtonItem
							icon="fa-comment-alt"
							to="/feedback"
							label={t("feedback")}
						/>
						<UserProfileButtonItem
							icon="fa-user-plus"
							to="/referrals"
							label={t("invite")}
						/>
					</>
				)}

				<UserProfileButtonItem
					icon={theme !== "dark" ? "fa-sun" : "fa-moon"}
					label={t(`theme.${theme === "dark" ? "light" : "dark"}`)}
					onClick={() => toggle()}
				/>

				<UserProfileButtonItem
					icon="fa-sign-out-alt"
					label={t("signout")}
					onClick={handleLogout}
					loading={loggingOut}
				/>
			</DropdownMenuContent>
		</DropdownMenu>
	);
};

interface UserProfileButtonItemProps {
	to?: string;
	onClick?: () => void;
	icon: string;
	label: string;
	loading?: boolean;
}

const UserProfileButtonItem = ({
	to,
	onClick,
	icon,
	label,
	loading,
}: UserProfileButtonItemProps) => {
	const As = to ? Link : "div";
	const location = useLocation();
	const iconStyles = ["fas", "far", "fal", "fab"];
	const isActive = to && to === location.pathname;
	const iconStyle = isActive ? "fas" : "far";
	const hasStyle =
		typeof icon === "string"
			? iconStyles.some((style) => (icon || "").includes(style))
			: false;

	return (
		<DropdownMenuItem
			asChild
			className={cn(
				"transition-all",
				isActive && "bg-background hover:bg-background"
			)}
		>
			<As
				className={cn(
					"flex items-center cursor-pointer gap-1",
					isActive && "bg-background"
				)}
				activeClassName="underline"
				to={to || "/"}
				{...{ onClick }}
			>
				{loading ? (
					<span className="base-loader"></span>
				) : (
					<Icon
						variant="nav-item"
						icon={{
							font_awesome: {
								name: `${icon} ${!hasStyle ? iconStyle : ""}`,
							},
						}}
					/>
				)}
				<span>{label}</span>
			</As>
		</DropdownMenuItem>
	);
};

UserProfileButton.locale = {
	nl: {
		dashboard: "Dashboard",
		messages: "Berichten",
		hours: "Uren controle",
		"my-hours": "Urenregistratie",
		studentpool: "Connecties",
		talents: "Professionals",
		payments: "Betalingen",
		agreements: "Overeenkomsten",
		"my-profile": "Mijn profiel",
		signout: "Uitloggen",
		"search-tasks": "Opdrachten zoeken",
		"my-tasks": "Mijn opdrachten",
		"my-offers": "Mijn aanbod",
		"my-network": "Mijn netwerk",
		"my-payments": "Mijn betalingen",
		links: "Links",
		faq: "Veelgestelde vragen",
		contact: "Contact",
		"edit-profile": "Profiel aanpassen",
		settings: "Instellingen",
		reviews: "Reviews",
		feedback: "Feedback",
		tutorial: "Tutorial",
		invite: "Referrals",
		theme: {
			dark: "Donker",
			light: "Licht",
		},
	},
	en: {
		dashboard: "Dashboard",
		messages: "Messages",
		hours: "Hour validation",
		"my-hours": "Hour registration",
		studentpool: "Connections",
		talents: "Professionals",
		payments: "Payments",
		agreements: "Agreements",
		"my-profile": "My Profile",
		signout: "Sign Out",
		"search-tasks": "Search Tasks",
		"my-tasks": "My Tasks",
		"my-offers": "My Offers",
		"my-network": "My Network",
		"my-payments": "My Payments",
		links: "Links",
		faq: "FAQ",
		contact: "Contact",
		"edit-profile": "Edit Profile",
		settings: "Settings",
		reviews: "Reviews",
		feedback: "Feedback",
		tutorial: "Tutorial",
		invite: "Referrals",
		theme: {
			dark: "Dark",
			light: "Light",
		},
	},
};

export default withTranslation(UserProfileButton);
