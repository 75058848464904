import { ApiPaymentRequestTask } from "src/api/types";
import useUnreads from "src/hooks/api/useUnreads";
import useApi from "src/hooks/api/utils/useApi";

export type FinalizeValues = {
	rating: number;
	review: string;
};
type Options = {
	stateId?: string;
	connectionId?: string;
	filter?: any;
};

function usePaymentRequestFinalize(options?: Options) {
	const { fetchUnreads } = useUnreads();
	const { state, actions, api, pagination } = useApi(
		{
			id: "PAYMENT_REQUESTS_FINALIZE",
		},
		{
			baseUrl: `/payment-requests/finalize`,
			query: {
				connection_id: options?.connectionId,
				with: ["connection_fields"],
				...(options?.filter || {}),
			},
		}
	);
	const requests: ApiPaymentRequestTask[] = state?.list || [];

	const finalize = async (ids: string[], data: FinalizeValues) => {
		actions.set((state: any) => ({
			...state,
			status: "loading",
		}));
		await api.post(``, {
			ids,
			...data,
		});
		await fetchUnreads();
		actions.set((state: any) => ({
			...state,
			status: "idle",
		}));
	};

	return {
		request: state?.data || {},
		requests,
		status: state.status,
		pagination,
		actions: {
			...actions,
			finalize,
		},
		api,
	};
}

export default usePaymentRequestFinalize;
