const support = {
	"support-help-card": {
		employee: "A {{tenant.name}} employee",
		description: "{{name}} is here to help.",
		button: {
			call: "Call directly",
			mail: "Send an email",
		},
	},
};

export default support;
