const dashboard = {
	company: {
		"create-task": "Opdracht plaatsen",
		title: "Dashboard",
		"demo-banner":
			"{{tenant.name}} maakt gebruik van een abonnement, <2>klik hier</2> om alle abonnementen te bekijken",
		cards: {
			registrations: "Nieuwe aanmeldingen",
			inbox: "Mijn berichten",
			"example-tasks": "Voorbeeldopdrachten",
		},
		"wallet-block": {
			description:
				"Voor het plaatsen van opdrachten heb je krediet nodig, wij hanteren een plaatsings fee van {{fee}}%",
			button: "Opwaarderen",
		},
		"dashboard-company-head": {
			description:
				"Zet vandaag nog een nieuwe opdracht uit bij meer dan <2>{{count}}+ professionals</2>",
			buttons: {
				create: "Opdracht plaatsen",
				studentpool: "Connecties",
			},
		},
		"dashboard-company-welcome": {
			title: "Welkom bij {{tenant.name}}",
			description:
				"Er staan <1>{{count}}+ professionals</1> klaar om jouw opdrachten uit te voeren! Voor je dat kan doen heb je nog wel een abonnement nodig.",
			"credit-description":
				"Jouw plaatsingstegoed is niet verdwenen, totdat je plaatsingstegoed op is worden daar de plaatsings fees en abonnementen mee betaald.",
			buttons: {
				demo: "Plan een demo",
				subscription: "Abonnement kiezen",
			},
		},
	},
	"connect-mandate-view": {
		title: "Bankrekening koppelen",
		description: "Klik hieronder op het plusje om je bank te koppelen",
	},
	"mandate-view": {
		title: "Je wordt doorgestuurd",
		description:
			"Een ogenblik geduld, je wordt doorverwezen naar onze betalingsprovider",
	},
	"bank-card": {
		status: {
			new: "Aangemaakt",
			pending: "In afwachting",
			approved: "Goedgekeurd",
			disapproved: "Afgekeurd",
		},
		empty: {
			title: "Je hebt nog geen bank gekoppeld",
			description:
				"Wanneer je een opdracht hebt uitgevoerd en er geld voor je klaar staat kan je je bank koppelen.",
		},
		new: {
			title: "Koppel nu je bankrekening",
			description:
				"We hebben je bankrekening nodig om je na de opdracht te kunnen uitbetalen.",
			button: "Bankrekening koppelen",
		},
	},
};

export default dashboard;
