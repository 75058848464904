const credit = {
	title: "Opwaarderen",
	"price-form": {
		required: "Verplicht",
		title: "Uw account opwaarderen",
		description:
			"Je staat op het moment om jouw account op te waarderen, maar waarom? Voor het aanbieden van je opdracht vragen wij een plaatsings fee van {{fee}}% van het budget voor de opdracht. Je kunt eenmalig een bedrag opwaarderen en daarna gemakkelijk opdrachten plaatsen.",
		subtotal: "Subtotaal",
		"service-fee": "Partner fee",
		vat: "BTW",
		total: "Totaal",
		label: "Hoeveel wil je opwaarderen",
		cancel: "Annuleren",
		checkout: "Afrekenen",
	},
	checkout: {
		failed: {
			title: "Uw betaling kon niet aangemaakt worden",
			button: "Opnieuw proberen",
		},
		title: "hoe wil je betalen",
		ideal: "Betalen met iDeal",
		invoice: "Betalen d.m.v. factuur",
	},
};

export default credit;
