import { nl } from "chrono-node";

export const getNLChrono = () => {
	const chronoNl = nl.casual.clone();

	//Months
	chronoNl.parsers.push({
		pattern: () => {
			// Definieer een regex-patroon voor Nederlandse datum- en tijduitdrukkingen
			return /(\d{1,2})\s*(januari|februari|maart|april|mei|juni|juli|augustus|september|oktober|november|december)\s*(\d{4})?/i;
		},
		extract: (context, match) => {
			// Extraheer de gevonden informatie uit de regex match
			const day = match[1];
			const month = match[2];
			const year = match[3] || new Date().getFullYear(); // Als geen jaar is opgegeven, gebruik het huidige jaar

			// Zet de maand om naar een cijfer
			const monthMapping: any = {
				januari: 0,
				februari: 1,
				maart: 2,
				april: 3,
				mei: 4,
				juni: 5,
				juli: 6,
				augustus: 7,
				september: 8,
				oktober: 9,
				november: 10,
				december: 11,
			};

			const monthIndex = monthMapping[month.toLowerCase()];

			return {
				ref: context.refDate,
				text: match[0],
				index: match.index,
				start: {
					day: parseInt(day),
					month: monthIndex + 1,
					year: parseInt(year.toString()),
				},
			} as any;
		},
	});

	// van x smiddags tot x
	chronoNl.parsers.push({
		pattern: () => /van (\w+ uur 's \w+) tot (\w+ uur 's \w+)/i,
		extract: (context, match) => {
			const ref = context.refDate;
			const result = context.createParsingResult(
				match?.index || 0,
				match[0]
			);

			const startTime = match[1];
			const endTime = match[2];

			const today = new Date(ref);
			let startHour = 0;
			let endHour = 0;

			const convertTime = (time: string) => {
				const [hour, period] = time.split(" uur 's ");
				let parsedHour = 0;

				switch (hour) {
					case "één":
					case "een":
					case "1":
						parsedHour = 1;
						break;
					case "twee":
					case "2":
						parsedHour = 2;
						break;
					case "drie":
					case "3":
						parsedHour = 3;
						break;
					case "vier":
					case "4":
						parsedHour = 4;
						break;
					case "vijf":
					case "5":
						parsedHour = 5;
						break;
					case "zes":
					case "6":
						parsedHour = 6;
						break;
					case "zeven":
					case "7":
						parsedHour = 7;
						break;
					case "acht":
					case "8":
						parsedHour = 8;
						break;
					case "negen":
					case "9":
						parsedHour = 9;
						break;
					case "tien":
					case "10":
						parsedHour = 10;
						break;
					case "elf":
					case "11":
						parsedHour = 11;
						break;
					case "twaalf":
					case "12":
						parsedHour = 12;
						break;
					default:
						parsedHour = parseInt(hour, 10);
						break;
				}

				if (period === "middags" && parsedHour < 12) {
					parsedHour += 12;
				} else if (period === "ochtends" && parsedHour === 12) {
					parsedHour = 0;
				}

				return parsedHour;
			};

			startHour = convertTime(startTime);
			endHour = convertTime(endTime);

			result.start.assign("hour", startHour);
			result.start.assign("minute", 0);
			result.start.assign("day", today.getDate());
			result.start.assign("month", today.getMonth() + 1); // Month is zero-indexed
			result.start.assign("year", today.getFullYear());

			result.end = result.start.clone();
			result.end.assign("hour", endHour);
			result.end.assign("minute", 0);

			return result;
		},
	});

	//Text hour written
	chronoNl.parsers.push({
		pattern: () =>
			/van (half \w+|\w+)( uur)? tot (\w+)( uur)?( in de middag)?/i,
		extract: (context, match) => {
			console.log(context);
			const ref = context.refDate;
			const result = context.createParsingResult(
				match?.index || 0,
				match[0]
			);

			const startTime = match[1];
			const endTime = match[3];

			const today = new Date(ref);

			const convertTime = (time: string) => {
				if (!time) {
					return { hour: undefined, minute: undefined };
				}
				const [hour, period] = time.split(" ");

				let parsedHour = 0;
				let parsedMinute = 0;

				switch (hour) {
					case "half":
						parsedHour = period === "drie" ? 2 : 3; // "half drie" or "half vier"
						parsedMinute = 30;
						break;
					case "één":
					case "een":
					case "1":
						parsedHour = 1;
						break;
					case "twee":
					case "2":
						parsedHour = 2;
						break;
					case "drie":
					case "3":
						parsedHour = 3;
						break;
					case "vier":
					case "4":
						parsedHour = 4;
						break;
					case "vijf":
					case "5":
						parsedHour = 5;
						break;
					case "zes":
					case "6":
						parsedHour = 6;
						break;
					case "zeven":
					case "7":
						parsedHour = 7;
						break;
					case "acht":
					case "8":
						parsedHour = 8;
						break;
					case "negen":
					case "9":
						parsedHour = 9;
						break;
					case "tien":
					case "10":
						parsedHour = 10;
						break;
					case "elf":
					case "11":
						parsedHour = 11;
						break;
					case "twaalf":
					case "12":
						parsedHour = 12;
						break;
					default:
						parsedHour = parseInt(hour, 10);
						break;
				}

				return { hour: parsedHour, minute: parsedMinute };
			};

			const { hour: startHourVal, minute: startMinuteVal } =
				convertTime(startTime);
			const { hour: endHourVal, minute: endMinuteVal } =
				convertTime(endTime);

			let startHour = startHourVal || 0;
			let startMinute = startMinuteVal || 0;
			let endHour = endHourVal || 0;
			let endMinute = endMinuteVal || 0;

			if (match[5]) {
				// Adjust hours for "in de middag"
				if (startHour < 12) startHour += 12;
				if (endHour < 12) endHour += 12;
			}

			result.start.assign("hour", startHour);
			result.start.assign("minute", startMinute);
			result.start.assign("day", today.getDate());
			result.start.assign("month", today.getMonth() + 1); // Month is zero-indexed
			result.start.assign("year", today.getFullYear());

			result.end = result.start.clone();
			result.end.assign("hour", endHour);
			result.end.assign("minute", endMinute);

			return result;
		},
	});

	//Today from till
	chronoNl.parsers.push({
		pattern: () =>
			/(vandaag|gisteren|morgen|overmorgen) van (\d{1,2}:\d{2}) tot (\d{1,2}:\d{2})/i,
		extract: (context, match) => {
			const ref = context.refDate;
			const result = context.createParsingResult(
				match.index || 0,
				match[0]
			);

			const dayKeyword = match[1].toLowerCase();
			const startTime = match[2];
			const endTime = match[3];

			const today = new Date(ref);
			let targetDate = new Date(today);

			if (dayKeyword === "vandaag") {
				targetDate.setDate(today.getDate());
			} else if (dayKeyword === "gisteren") {
				targetDate.setDate(today.getDate() - 1);
			} else if (dayKeyword === "morgen") {
				targetDate.setDate(today.getDate() + 1);
			} else if (dayKeyword === "overmorgen") {
				targetDate.setDate(today.getDate() + 2);
			}

			const startParts = startTime.split(":");
			const endParts = endTime.split(":");

			result.start.assign("hour", parseInt(startParts[0], 10));
			result.start.assign("minute", parseInt(startParts[1], 10));
			result.start.assign("day", today.getDate());
			result.start.assign("month", today.getMonth() + 1); // Month is zero-indexed
			result.start.assign("year", today.getFullYear());

			result.end = result.start.clone();
			result.end.assign("hour", parseInt(endParts[0], 10));
			result.end.assign("minute", parseInt(endParts[1], 10));

			return result;
		},
	});

	return chronoNl;
};
