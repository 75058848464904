export const getAdminUrl = (path?: string) => {
	let host = window.location.host;

	console.log(host);

	if (process.env.NODE_ENV === "development") {
		return `https://account.getjobsdone.test${path || ""}`;
	}
	host = host.replace("www.", "");
	if (host.includes("demo.")) {
		return `https://account.demo.getjobsdone.nl${path}`;
	}

	if (host.includes("dev.")) {
		return `https://account.dev.getjobsdone.nl${path}`;
	}

	return `https://account.getjobsdone.nl${path}`;
};

export const getPublicUrl = (path?: string) => {
	let host = window.location.host;
	if (process.env.NODE_ENV === "development") {
		return `https://getjobsdone.test${path || ""}`;
	}
	if (host.includes("demo.")) {
		return `https://demo.getjobsdone.nl${path || ""}`;
	}
	if (host.includes("account.")) {
		host = host.replace("account.", "");
	}
	host = host.replace("www.", "");
	return `https://${host}${path || ""}`;
};

export const getRouteUrl = (lat: string, lng: string) => {
	return `https://www.google.com/maps/dir/@${lat},${lng}z/data=!4m8!4m7!1m0!1m5!1m1!1s0x47c63f501c4876d3:0x7471209098bce69a!2m2!1d5.366667!2d52.25`;
};

export const getEmployeeUrl = (path: string = "") => {
	let host = window.location.host;
	if (process.env.NODE_ENV === "development") {
		return `https://employee.getjobsdone.test${path || ""}`;
	}
	host = host.replace("www.", "");
	if (host.includes("demo.")) {
		return `https://employee.demo.getjobsdone.nl${path || ""}`;
	}
	return `https://employee.getjobsdone.nl${path || ""}`;
};
