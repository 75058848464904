import { useTaskKanban } from "src/components/tasks/kanban/TaskKanbanCard";
import Timer from "src/components/time-tracker/Timer";
import useTimeTracking from "src/hooks/api/services/useTimeTracking";
import { cn } from "src/lib/utils";

interface TaskKanbanCardTimerProps {
	variant?: "absolute";
	className?: string;
}

const TaskKanbanCardTimer = ({
	variant = "absolute",
	className,
}: TaskKanbanCardTimerProps) => {
	const { task } = useTaskKanban();
	const { time } = useTimeTracking();

	if (!task?.id || task.mode === "NEW" || task.type === "GHOST") return <></>;

	return (
		<div
			className={cn(
				"opacity-0 pointer-events-none group-hover/card:opacity-100 group-hover/card:pointer-events-auto bg-background flex items-center p-1 rounded-[4px]",
				time?.links?.task_id === task.id &&
					"opacity-100 group-hover/card:text-dark",
				variant === "absolute" && "absolute -left-1 bottom-0 z-[20]",
				className
			)}
		>
			<Timer
				format="mm:ss"
				size="small"
				data={{
					task_id: task.id,
				}}
			/>
		</div>
	);
};

export default TaskKanbanCardTimer;
