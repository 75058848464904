import React from "react";
import { Trans, useTranslation } from "react-i18next";
import MiddlewareLayout from "src/components/middlewares/MiddlewareLayout";

const NetworkMiddleware = () => {
	const { t } = useTranslation("middlewares", {
		keyPrefix: "network",
	});
	return (
		<MiddlewareLayout title={t("title")} heading={t("heading")}>
			<Trans {...{ t }} i18nKey="description">
				Om gebruik te maken van meerdere vestigingen dien je een
				<strong>Premium</strong> abonnement te hebben.
			</Trans>
		</MiddlewareLayout>
	);
};

export default NetworkMiddleware;
