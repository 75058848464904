import Button from "src/components/Button";
import Card from "src/components/Card";
import withTranslation, {
	Translation,
} from "src/components/hoc/withTranslation";
import useAuth from "src/hooks/selectors/useAuth";
import useBackUrl from "src/hooks/useBackUrl";

interface CompleteAccountCardProps extends Translation {
	children?: any;
}

const CompleteAccountCard = ({ t, children }: CompleteAccountCardProps) => {
	const auth = useAuth();
	const back = useBackUrl();
	const onboarding = auth?.onboarding;
	const missing = (onboarding?.missing || []).filter(
		(item: string) => item !== "information"
	);
	if (!missing.length) {
		return null;
	}

	return (
		<Card
			to={`/onboarding?${back}&reset=1`}
			contentClassName="py-5 px-6 relative overflow-hidden group"
		>
			<div className="flex items-center gap-3 relative z-[2]">
				<div className="flex flex-col flex-1 gap-1">
					<p className="font-bold leading-full">{t("title")}</p>
					<p className="text-sm">{t("description")}</p>
				</div>
				<Button iconOnly type="primary">
					<i className="fas fa-arrow-right"></i>
				</Button>
			</div>
			<div className="absolute inset-0 bg-primary opacity-0 scale-75 group-hover:scale-100 group-hover:opacity-10 transition-all"></div>
		</Card>
	);
};

CompleteAccountCard.locale = {
	nl: {
		title: "Maak je account compleet",
		description:
			"Om 100% gebruik te maken van de voordelen van {{tenant.name}} moet je je account compleet maken.",
		button: "Configureer account",
	},
	en: {
		title: "Complete your account",
		description:
			"To fully utilize the benefits of {{tenant.name}}, you need to complete your account",
		button: "Configure account",
	},
};

export default withTranslation(CompleteAccountCard);
