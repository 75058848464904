import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import CompanyForm from "src/components/forms/profile/CompanyForm";
import usePatch from "src/hooks/rest/usePatch";
import { updateProfile } from "src/redux/auth";

const EditCompanyDetails = () => {
	const { t } = useTranslation("buttons");

	const dispatch = useDispatch();
	const [patchProfile] = usePatch("/auth/profile/company");
	let company = useSelector((state: any) => state.auth.company);
	company = {
		...company,
		...company.address,
	};
	const onSubmit = async (values: any) => {
		return patchProfile({
			company: values,
		}).then((res: any) => {
			dispatch(updateProfile(res.data));
		});
	};

	return (
		<CompanyForm
			type="edit"
			buttonLabel={t("save")}
			{...{ company, onSubmit }}
		/>
	);
};

export default EditCompanyDetails;
