import axios from "axios";
import { useEffect, useState } from "react";
import { ApiMessage } from "src/api/types";
import useFetchProfile from "src/hooks/api/useFetchProfile";
import useGet from "src/hooks/rest/useGet";
import useSocketListener from "src/hooks/sockets/useSocketListener";

type MessageSection = {
	[key: string]: ApiMessage[];
};

function useChat(id: string | number, ref: any, type = "task") {
	const [response, loading, { error, setResult, fetchGet }] = useGet(
		`/inbox/${id}?type=${type}`,
		{
			data: [],
		}
	);
	const [pending, setPending] = useState(false);
	const { fetchProfile } = useFetchProfile();
	const [isRead, setIsRead] = useState(false);
	const [messages, setMessages] = useState<MessageSection>({});

	useSocketListener(`room.${id}`, () => {
		fetchGet(false);
		setTimeout(() => {
			ref.current.scrollToBottom();
		}, 500);
	});

	useEffect(() => {
		const sections: MessageSection = {};
		for (let message of response.data) {
			const page = message.page || 1;
			if (!sections[page]) sections[page] = [];
			sections[page].push(message);
			sections[page] = sections[page].sort((a: any, b: any) =>
				a.id > b.id ? 1 : -1
			);
		}
		setMessages(sections);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [response]);

	useEffect(() => {
		if (!isRead) {
			setIsRead(true);
			axios.post(`/inbox/${id}/latest-read?type=${type}`).then(() => {
				fetchProfile();
			});
		}
	}, [fetchProfile, id, isRead, type]);

	const handleLoadMore = async () => {
		setPending(true);
		const currentPage = response?.meta?.current_page;
		let page = currentPage + 1;
		const { data }: any = await axios.get(
			`/inbox/${id}?page=${page}&type=${type}`
		);
		if (data?.data) {
			data.data = data.data.map((item: ApiMessage) => ({
				...item,
				page,
			}));
			setResult({
				...data,
				data: [...data.data, ...response.data],
			});
		}

		setPending(false);
	};

	return {
		messages,
		response,
		loading,
		error,
		setResult,
		handleLoadMore,
		pending,
	};
}
export default useChat;
