const promotion = {
	"connect-discount-banner": {
		"last-day": "⚠️ Today is the last day to use your discount!",
		"days-remaining_other":
			"⚠️ Your special discount expires in {{days}} days",
		"days-remaining_one": "⚠️ Your special discount expires in 1 day",
		title: "All packages now with 15% placement fee",
		description:
			"Choose your subscription by {{date}} and take advantage of a 15% placement fee on all packages",
	},
	"no-mandate-banner": {
		description:
			"You have no active bank connection, <1>click here</1> to create a new connection",
	},
	"no-subscription-banner": {
		"payment-failed":
			"The payment for the subscription has failed. <1>Click here</1> to pay after all",
		"no-active":
			"You have no active subscription. <1>Click here</1> to choose a subscription.",
	},
	"paused-banner": {
		description:
			"Your account is paused, your account will be fully closed on <1>{{date}}</1>. Until then you can still view applications and complete assignments. Click here to resume your subscription. ",
	},
	"student-can-register": {
		description:
			"Your account has been approved! Click here to respond to assignments",
	},
	"subscription-changed-banner": {
		description: "Your account changes to {{plan}} on {{date}}",
	},
	"euro-banner": {
		description:
			"Choose a subscription for only <2>€ 1 the upcoming month.</2> <4>Click here</4> to claim your discount.",
	},
	"download-app-box": {
		appstore: "Download in the app store",
		playstore: "Download in the play store",
	},
};

export default promotion;
