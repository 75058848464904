import { useParams } from "react-router-dom";
import Field from "src/components/field/Field";
import useTask from "src/hooks/api/services/tasks/task/useTask";

interface TaskDetailEditorProps {
	className?: string;
}

const TaskDetailEditor = ({ className }: TaskDetailEditorProps) => {
	const { id } = useParams();
	const { task, actions } = useTask({ id });

	return (
		<Field.Editor
			wrapper={{
				className,
			}}
			variant="floating"
			value={task.description}
			onChange={(value) => {
				actions.change({
					description: value,
				});
			}}
		/>
	);
};

export default TaskDetailEditor;
