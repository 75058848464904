import { useState } from "react";
import _ from "lodash";
import Avatar from "src/components/Avatar";
import Checkbox from "src/components/form/Checkbox";
import Input from "src/components/form/Input";
import Stars from "src/components/Stars";
import { useUpdateEffectDebounce } from "src/hooks/useDebounce";
import Button from "src/components/Button";
import Skeleton from "src/components/Skeleton";
import { useTranslation } from "react-i18next";

type MultiSelectSettings = {
	showAvatar?: boolean;
	showRating?: boolean;
};

type Option = {
	id: string;
	name: string;
	avatar?: string;
	rating: number;
};

interface MultiSelectListProps {
	options: Option[];
	settings?: MultiSelectSettings;
	search?: {
		placeholder?: string;
		value: string;
		onChange: (q: string) => void;
	};
	hasMore?: boolean;
	value: any[];
	onChange: (value: any[]) => void;
	onNext?: () => void;
	loading?: boolean;
	button?: {
		label: string;
	};
}

const MultiSelectList = ({
	options,
	search,
	settings,
	hasMore,
	value,
	onChange,
	onNext,
	loading,
	button,
}: MultiSelectListProps) => {
	const { t } = useTranslation(["buttons", "form"]);

	const [query, setQuery] = useState(search?.value || "");
	useUpdateEffectDebounce(query, 500, (q) => {
		if (search?.onChange) {
			search.onChange(q);
		}
	});

	return (
		<div className="flex flex-col gap-3">
			{search && (
				<Input
					icon={<i className="fas fa-search" />}
					name="search"
					placeholder={
						search?.placeholder ||
						t("multi-select-list.placeholder", {
							ns: "form",
						})
					}
					value={query}
					onChange={(_, value) => setQuery(value)}
					noMarginBottom
					onKeyPress={(e) => {
						if (e.key === "Enter") {
							e.preventDefault();
						}
					}}
				/>
			)}
			<div className="flex flex-col gap-2">
				{(options || []).map((option) => (
					<MultiSelectItem
						key={`option-${option.id}`}
						active={value?.includes(option.id)}
						onClick={() => {
							onChange(_.xor(value, [option.id]));
						}}
						{...{ option, settings }}
					/>
				))}

				{loading && (options || []).length === 0 && (
					<>
						<MultiSelectItemLoader {...{ settings }} />
						<MultiSelectItemLoader {...{ settings }} />
						<MultiSelectItemLoader {...{ settings }} />
						<MultiSelectItemLoader {...{ settings }} />
					</>
				)}

				{!loading && (options || []).length === 0 && query && (
					<div className="flex flex-col items-center justify-center p-4">
						<p>
							Geen resultaten gevonden met de zoekterm:{" "}
							<strong>{query}</strong>
						</p>
					</div>
				)}
			</div>
			{hasMore && onNext && (
				<div className="flex justify-center items-center">
					<Button small type="white" onClick={() => onNext()}>
						{button?.label ||
							t("load-more", {
								ns: "buttons",
							})}
					</Button>
				</div>
			)}
		</div>
	);
};

interface MultiSelectItemProps {
	option: Option;
	settings?: MultiSelectSettings;
	active: boolean;
	onClick: () => void;
}

const MultiSelectItem = ({
	option,
	settings,
	active,
	onClick,
}: MultiSelectItemProps) => {
	return (
		<div
			{...{ onClick }}
			className="flex gap-2 cursor-pointer p-1 px-2 hover:bg-accent-background rounded-md items-center"
		>
			<div className="mr-2">
				<Checkbox
					onChange={() => {
						onClick();
					}}
					value={active}
				/>
			</div>
			{settings?.showAvatar && <Avatar src={option.avatar} />}
			<div className="flex flex-col">
				<strong>{option.name}</strong>
				{settings?.showRating && <Stars rating={option.rating} />}
			</div>
		</div>
	);
};

interface MultiSelectItemLoaderProps {
	settings?: MultiSelectSettings;
}

const MultiSelectItemLoader = ({ settings }: MultiSelectItemLoaderProps) => {
	return (
		<div className="flex gap-2 cursor-pointer p-1 px-2 hover:bg-accent-background rounded-md items-center">
			<div className="mr-2">
				<Checkbox.Loader />
			</div>
			{settings?.showAvatar && <Avatar.Loader />}
			<div className="flex flex-col flex-1 gap-1">
				<Skeleton className="w-1/4 h-4" />
				<Skeleton className="w-2/4 h-4" />
			</div>
		</div>
	);
};

export default MultiSelectList;
