import { useEffect } from "react";
import { useParams } from "react-router-dom";
import Field from "src/components/field/Field";
import Input from "src/components/form/Input";
import withTranslation, {
	Translation,
} from "src/components/hoc/withTranslation";
import TaskStatus from "src/components/tasks/TaskStatus";
import useTenant from "src/hooks/api/services/tenants/useTenant";
import useTasksFilter from "src/hooks/api/tasks/useTasksFilter";
import useAuth from "src/hooks/selectors/useAuth";

export type TaskFilterName =
	| "search"
	| "type"
	| "assignees"
	| "creator"
	| "match"
	| "team"
	| "tag"
	| "completed_tasks";

interface TaskFilterFormProps extends Translation {
	exclude?: TaskFilterName[];
	options?: {
		canCreateTags?: boolean;
	};
}

function compareObjects(obj1: any, obj2: any) {
	for (const key in obj1) {
		if (obj1[key] !== obj2[key]) {
			return false; // Objects are not equal
		}
	}
	return true; // Objects are equal
}

const TaskFilterForm = ({
	t,
	exclude: _exclude,
	options,
}: TaskFilterFormProps) => {
	const auth = useAuth();
	const exclude = _exclude || [];
	const { filterState, setFilterState, filter } = useTasksFilter();
	const { tenant } = useTenant();
	const { board } = useParams();

	if (board) {
		exclude.push("tag");
	}

	useEffect(() => {
		const result = compareObjects(filter, filterState);
		if (!result) {
			setFilterState(filter);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	if (auth.type === "student") {
		exclude.push(
			...(["type", "assignees", "match", "team"] as TaskFilterName[])
		);
	}

	return (
		<div className="flex flex-col gap-4">
			{!exclude.includes("search") && (
				<Input
					autoFocus
					icon={<i className="far fa-search" />}
					name="search"
					placeholder={t("fields.search.placeholder")}
					value={filterState.q}
					className="p-0"
					noMarginBottom
					onChange={(key, value) =>
						setFilterState((prev: any) => ({ ...prev, q: value }))
					}
				/>
			)}
			{tenant.modules.includes("extern-task") && (
				<>
					<Field.Select
						className="border border-border"
						value={filterState.status}
						wrapper={{
							label: t("fields.status.label"),
							layout: "horizontal",
						}}
						options={[
							{
								value: "open",
								label: <TaskStatus status="open" />,
							},
							{
								value: "IN_PROGRESS",
								label: <TaskStatus status="IN_PROGRESS" />,
							},
							{
								value: "COMPLETED",
								label: <TaskStatus status="COMPLETED" />,
							},
						]}
						onChange={(value) =>
							setFilterState((prev: any) => ({
								...prev,
								status: value,
							}))
						}
					/>
				</>
			)}
			{tenant.modules.includes("intern-task") && (
				<>
					{/* {!exclude.includes("type")  && (
						<Field.Options
							wrapper={{
								label: t("fields.type.label"),
								layout: "horizontal",
							}}
							onChange={(value) =>
								setFilterState((prev: any) => ({
									...prev,
									type: value,
								}))
							}
							value={filterState.type}
							options={[
								{
									label: t("fields.type.options.all"),
									value: "all",
								},
								{
									label: t("fields.type.options.internal"),
									value: "internal",
								},
								{
									label: t("fields.type.options.external"),
									value: "published",
								},
							]}
						/>
					)} */}
					{!exclude.includes("assignees") && (
						<Field.Users
							wrapper={{
								label: t("fields.assignees.label"),
								layout: "horizontal",
							}}
							value={
								filterState.assignees
									? filterState.assignees.map((user: any) =>
											typeof user === "object"
												? user.id.toString()
												: user
									  )
									: []
							}
							onChange={(value) => {
								setFilterState((prev: any) => ({
									...prev,
									assignees: value,
								}));
							}}
						/>
					)}
				</>
			)}

			{!exclude.includes("creator") && (
				<Field.User
					isClearable
					wrapper={{
						label: t("fields.creator.label"),
						layout: "horizontal",
					}}
					value={
						filterState.creator_id
							? filterState.creator_id
							: undefined
					}
					onChange={(value) => {
						setFilterState((prev: any) => {
							if (prev) {
								return { ...prev, creator_id: value };
							} else {
								// Handle the case where prev is undefined (e.g., initialize with a default state)
								return { creator_id: value };
							}
						});
					}}
				/>
			)}
			{!exclude.includes("match") && (
				<Field.Connection
					isClearable
					wrapper={{
						label: t("fields.match.label"),
						layout: "horizontal",
					}}
					value={
						filterState.match_student_id
							? filterState.match_student_id
							: undefined
					}
					onChange={(value) => {
						setFilterState((prev: any) => {
							if (prev) {
								return { ...prev, match_student_id: value };
							} else {
								// Handle the case where prev is undefined (e.g., initialize with a default state)
								return { match_student_id: value };
							}
						});
					}}
				/>
			)}
			{tenant.modules.includes("intern-task") && (
				<>
					{/* {!exclude.includes("team") && (
						<Field.Team
							wrapper={{
								label: t("fields.team.label"),
								layout: "horizontal",
							}}
							value={
								filterState.teams
									? filterState.teams
									: undefined
							}
							onChange={(value) => {
								setFilterState((prev: any) => ({
									...prev,
									teams: value,
								}));
							}}
						/>
					)} */}
					{!exclude.includes("tag") && (
						<Field.Tag
							canCreate={options?.canCreateTags}
							wrapper={{
								label: t("fields.tag.label"),
								layout: "horizontal",
							}}
							value={
								filterState.tags ? filterState.tags : undefined
							}
							onChange={(value) => {
								setFilterState((prev: any) => ({
									...prev,
									tags: value,
								}));
							}}
						/>
					)}
				</>
			)}
			{!exclude.includes("completed_tasks") && (
				<Field.Switch
					wrapper={{
						label: t("fields.completed.label"),
						layout: "horizontal",
					}}
					value={filterState.hide_completed}
					onChange={(value) => {
						setFilterState((prev: any) => ({
							...prev,
							hide_completed: value,
						}));
					}}
				/>
			)}
		</div>
	);
};

TaskFilterForm.locale = {
	nl: {
		fields: {
			type: {
				label: "Type",
				options: {
					all: "Alles",
					internal: "Intern",
					external: "Uitbesteed",
				},
			},
			search: {
				placeholder: "Zoek een taak...",
			},
			match: {
				label: "Match",
			},
			creator: {
				label: "Aangemaakt door",
			},
			tag: {
				label: "Tags",
			},
			team: {
				label: "Team",
			},
			assignees: {
				label: "Uitvoerder",
			},
			completed: {
				label: "Verberg afgerond",
			},
			status: {
				label: "Status",
			},
		},
	},
	en: {
		fields: {
			type: {
				label: "Type",
				options: {
					all: "All",
					internal: "Intern",
					external: "Outsourced",
				},
			},
			search: {
				placeholder: "Search a task...",
			},
			match: {
				label: "Match",
			},
			creator: {
				label: "Created by",
			},
			tag: {
				label: "Tags",
			},
			team: {
				label: "Team",
			},
			assignees: {
				label: "Assigned to",
			},
			completed: {
				label: "Hide completed",
			},
			status: {
				label: "Status",
			},
		},
	},
};

export default withTranslation(TaskFilterForm);
