import { ApiIcon } from "src/api/types";
import Icon from "src/components/icon/Icon";
import { useTeamCard } from "src/components/profile/team/team-card/TeamCardContext";
import { cn } from "src/lib/utils";

interface TeamCardTitleProps {
	children?: any;
	icon?: ApiIcon;
}

const TeamCardTitle = ({ icon: _icon, children }: TeamCardTitleProps) => {
	const { team } = useTeamCard();
	const icon = _icon || team?.icon;
	return (
		<div className="flex items-center gap-3">
			{icon && (
				<Icon
					className={cn(
						"w-8 h-8 bg-accent hover:bg-accent",
						team?.color &&
							`bg-[${team.color}] hover:bg-[${team.color}]`
					)}
					style={{
						backgroundColor: `${team?.color}30`,
					}}
					iconFontSize={17}
					{...{ icon }}
				/>
			)}
			<strong className="text-[20px]">{children || team?.name}</strong>
		</div>
	);
};

export default TeamCardTitle;
