import moment from "moment";
import { useEffect } from "react";
import { ApiTask } from "src/api/types";
import Button from "src/components/Button";
import withTranslation, {
	Translation,
} from "src/components/hoc/withTranslation";
import TaskMatchProgress from "src/components/tasks/TaskMatchProgress";
import TaskStatus from "src/components/tasks/TaskStatus";
import List from "src/components/ui/list";
import useSubtasks from "src/hooks/api/services/tasks/task/useSubtasks";
import useBackUrl from "src/hooks/useBackUrl";

interface SubtasksTableProps extends Translation {
	task: ApiTask;
}

const SubtasksTable = ({ task, t }: SubtasksTableProps) => {
	const backUrl = useBackUrl();
	const { tasks, status, actions } = useSubtasks({
		taskId: task.hid?.toString() || "",
	});

	useEffect(() => {
		actions.list();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<div className="flex flex-col ">
			<List
				status={status !== "idle" && tasks?.length ? "idle" : status}
				data={tasks}
			>
				<List.Head>
					<List.Item className="w-full md:w-auto">
						{t("headers.status")}
					</List.Item>
					<List.Item>{t("headers.title")}</List.Item>
					<List.Item>{t("headers.registrations")}</List.Item>
					<List.Item>{t("headers.date")}</List.Item>
					<List.Item align="right">{t("headers.actions")}</List.Item>
				</List.Head>
				<List.Empty>
					<strong>{t("empty.title")}</strong>
					<p>{t("empty.text")}</p>
				</List.Empty>
				<List.Items>
					{(task) => (
						<List.Row key={`subtask-${task.hid}`}>
							<List.Item className="w-full md:w-auto">
								<div className="relative flex w-max">
									{task.status === "COMPLETED" ? (
										<TaskStatus status={task.status} />
									) : (
										<TaskMatchProgress
											students={task.amount_of_students}
											matched={task.matches?.count}
										/>
									)}
								</div>
							</List.Item>
							<List.Item>
								<p className="w-[200px] md:w-[260px] line-clamp-2">
									{task.title}
								</p>
							</List.Item>
							<List.Item>
								<span className="whitespace-nowrap">
									{t("item.registrations", {
										count: task.registrations?.count || 0,
									})}
								</span>
							</List.Item>
							<List.Item>
								<div className="flex flex-col gap-1">
									{task.start_date && task.end_date && (
										<>
											<span className="whitespace-nowrap">
												{t("item.date", {
													date: moment(
														task.start_date
													).format("DD-MM-YYYY"),
												})}
											</span>
											<span className="whitespace-nowrap">
												{t("item.from-to", {
													date: moment(
														task.start_date
													).format("DD-MM-YYYY"),
													from: moment(
														task.start_date
													).format("HH:mm"),
													to: moment(
														task.end_date
													).format("HH:mm"),
												})}
											</span>
										</>
									)}

									{task.start_date && !task.end_date && (
										<span className="whitespace-nowrap">
											{t("item.date", {
												date: moment(
													task.start_date
												).format("DD-MM-YYYY"),
											})}
										</span>
									)}
								</div>
							</List.Item>

							<List.Item align="right">
								<div className="flex justify-end flex-wrap gap-2">
									<Button
										xsmall
										type="border"
										to={`/tasks/${task.hid}/edit?${backUrl}`}
									>
										<i className="fas fa-pencil"></i>
										{t("actions.edit")}
									</Button>
									<Button
										xsmall
										type="dark"
										to={`/tasks/${task.hid}/show?${backUrl}`}
									>
										<i className="fas fa-users"></i>

										{t("actions.registrations")}
									</Button>
								</div>
							</List.Item>
						</List.Row>
					)}
				</List.Items>
			</List>
		</div>
	);
};

SubtasksTable.locale = {
	nl: {
		empty: {
			title: "Geen opdrachten gevonden",
			text: "Deze opdracht heeft geen onderliggende opdrachten",
		},
		headers: {
			status: "Status",
			title: "Shift",
			budget: "Budget",
			date: "Datum",
			"estimated-duration": "Geschatte tijdsduur",
			"amount-of-students": "Aantal professionals",
			registrations: "Aanmeldingen",
			actions: "Acties",
		},
		actions: {
			edit: "Bewerken",
			registrations: "Aanmeldingen",
		},
		item: {
			from: "Van {{date}}",
			to: "Tot {{date}}",
			registrations_one: "{{count}} aanmelding",
			registrations_other: "{{count}} aanmeldingen",
			"from-to": "{{from}} tot {{to}}",
			date: "{{date}}",
		},
	},
	en: {
		empty: {
			title: "No tasks found",
			text: "This task has no underlying tasks.",
		},
		headers: {
			status: "Status",
			title: "Shift",
			budget: "Budget",
			date: "Date",
			"estimated-duration": "Estimated duration",
			"amount-of-students": "Number of professionals",
			registrations: "Registrations",
			actions: "Actions",
		},
		actions: {
			edit: "Edit",
			registrations: "Registrations",
		},
		item: {
			from: "From {{date}}",
			to: "To {{date}}",
			registrations_one: "{{count}} registration",
			registrations_other: "{{count}} registrations",
			"from-to": "{{from}} to {{to}}",
			date: "{{date}}",
		},
	},
};

export default withTranslation(SubtasksTable);
