import { ComponentProps, createContext, useContext } from "react";
import { ApiUserAvatar } from "src/api/types";
import AvatarComp, { AvatarSize } from "src/components/Avatar";
import {
	Tooltip,
	TooltipContent,
	TooltipProvider,
	TooltipTrigger,
} from "src/components/ui/tooltip";
import { cn } from "src/lib/utils";

interface AvatarsType {
	avatars: ApiUserAvatar[];
}

const Avatars = createContext<AvatarsType>({ avatars: [] });

export function useAvatars() {
	return useContext(Avatars);
}

interface AvatarsProps extends ComponentProps<"div"> {
	avatars: AvatarsType["avatars"];
}

export default function Registration({
	children,
	className,
	avatars,
	...rest
}: AvatarsProps) {
	return (
		<Avatars.Provider value={{ avatars }}>
			<div className={cn("flex items-center", className)} {...rest}>
				{children}
			</div>
		</Avatars.Provider>
	);
}

const ItemContext = createContext<{ avatar: ApiUserAvatar }>({} as any);

interface ItemsProps extends Omit<ComponentProps<"div">, "children"> {
	children: (avatar: ApiUserAvatar, index: number) => React.ReactNode;
}

const Items = ({ children, className, ...rest }: ItemsProps) => {
	const { avatars } = useAvatars();
	return (
		<div className={cn("flex items-center gap-[2px]", className)} {...rest}>
			{avatars.map((avatar, index) => (
				<ItemContext.Provider value={{ avatar }}>
					{children(avatar, index)}
				</ItemContext.Provider>
			))}
		</div>
	);
};

interface RotateWrapperProps extends ComponentProps<"div"> {
	index: number;
}

const RotateWrapper = ({
	children,
	className,
	index,
	...rest
}: RotateWrapperProps) => {
	return (
		<div
			className={cn(
				index === 0 && "rotate-[6deg]",
				index === 1 && "rotate-[-5deg]",
				index === 2 && "rotate-[5deg]",
				index === 3 && "rotate-[4deg]",
				`transition-all duration-300 hover:rotate-0`,
				className
			)}
			{...rest}
		>
			{children}
		</div>
	);
};

const Avatar = ({
	className,
	size = "large",
	...rest
}: Omit<ComponentProps<typeof AvatarComp>, "size"> & { size?: AvatarSize }) => {
	const { avatar } = useContext(ItemContext);
	return (
		<AvatarComp
			{...rest}
			{...{ size }}
			className={cn("border-2 border-primary-300", className)}
			src={avatar.avatar}
		/>
	);
};

const TooltipWrapper = ({ children }: { children: React.ReactNode }) => {
	const { avatar } = useContext(ItemContext);
	return (
		<TooltipProvider delayDuration={400}>
			<Tooltip>
				<TooltipTrigger>{children}</TooltipTrigger>
				<TooltipContent>{avatar?.full_name}</TooltipContent>
			</Tooltip>
		</TooltipProvider>
	);
};

const LoadingItems = () => {
	return (
		<div className="flex items-center gap-[2px]">
			{Array.from({ length: 4 }).map((_, index) => (
				<RotateWrapper key={index} index={index}>
					<AvatarComp.Loader bg="border" />
				</RotateWrapper>
			))}
		</div>
	);
};

export {
	Avatar,
	Items as AvatarsItems,
	LoadingItems as AvatarsLoadingItems,
	RotateWrapper as AvatarsRotateWrapper,
	TooltipWrapper as AvatarsTooltipWrapper,
};
