import withTranslation, {
	Translation,
} from "src/components/hoc/withTranslation";
import Layout from "src/components/layout/Layout";
import TaskRepeatTable from "src/components/tasks/TaskRepeatTable";
import useTaskFormAutoSave from "src/hooks/api/tasks/useTaskFormAutoSave";

function TasksView({ t }: Translation) {
	useTaskFormAutoSave();

	return (
		<Layout
			breadcrumb={[
				{
					label: t("tasks"),
					to: "/tasks",
				},
				{
					label: t("title"),
					to: "/tasks/repeat",
				},
			]}
			title={t("title")}
			container="xxl"
		>
			<TaskRepeatTable />
		</Layout>
	);
}

TasksView.locale = {
	nl: {
		tasks: "Taken",
		title: "Herhaaltaken",
	},
	en: {
		tasks: "Tasks",
		title: "Repeat Tasks",
	},
};

export default withTranslation(TasksView);
