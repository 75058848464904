const facebookInit = async () => {
	return new Promise((resolve) => {
		window.fbAsyncInit = function () {
			window.FB.init({
				appId: "406393320651753",
				xfbml: true,
				version: "v12.0",
			});
			window.FB.AppEvents.logPageView();
		};

		(function (d, s, id) {
			var js: any,
				fjs: any = d.getElementsByTagName(s)[0];
			if (d.getElementById(id)) {
				return;
			}
			js = d.createElement(s);
			js.id = id;
			js.src = "https://connect.facebook.net/en_US/sdk.js";
			if (fjs && fjs.parentNode) {
				fjs.parentNode.insertBefore(js, fjs);
			}
		})(document, "script", "facebook-jssdk");
	});
};

export async function loginFacebook() {
	// login with facebook then authenticate with the API to get a JWT auth token
	const result = await new Promise((resolve) => {
		window.FB.login(
			({ authResponse }: any) => {
				window.FB.api(`/me?fields=email`, (me: any) => {
					resolve({
						email: me?.email || false,
						id: me?.id || false,
						accessToken: authResponse?.accessToken || false,
					});
				});
			},
			{
				scope: "email",
			}
		);
	});
	return result;
}

export default facebookInit;
