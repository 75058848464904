import withTranslation, {
	Translation,
} from "src/components/hoc/withTranslation";
import Card from "src/components/Card";
import CreateTaskButton from "src/components/tasks/TaskForm/elements/CreateTaskButton";

const NoTasksCard = ({ t }: Translation) => {
	const INSTRUCTIONS = [
		t("instructions.description"),
		t("instructions.budget"),
		t("instructions.extra"),
	];

	return (
		<Card>
			<div className="flex flex-col gap-4 ">
				<h2>{t("title")}</h2>
				<p className="">
					{t("description", {
						count: INSTRUCTIONS.length,
					})}
				</p>
				<ul className="pl-3">
					{INSTRUCTIONS.map((item, index) => (
						<li key={`instruction-${index}`} className="flex gap-2">
							<strong className="w-6">{index + 1}.</strong>
							{item}
						</li>
					))}
					<li className="flex gap-2 text-primary">
						<strong className="w-6">
							<i className="fas fa-check"></i>
						</strong>
						{t("instructions.finish")}
					</li>
				</ul>

				<div className="flex items-center gap-4">
					<CreateTaskButton variant="CREATE" />
				</div>
			</div>
		</Card>
	);
};

NoTasksCard.locale = {
	nl: {
		title: "Laten we een opdracht gaan maken!",
		description_one: "De opdracht maak je gemakkelijk in 1 simpele stap",
		description_other:
			"De opdracht maak je gemakkelijk in {{count}} simpele stappen",
		instructions: {
			description: "Geef een duidelijke opdrachtomschrijving",
			budget: "Bepaal de opdrachtwaarde",
			extra: "Geef eventuele extra informatie op zoals: adres, interview, startdatum etc.",
			// location: "Geef de locatie op: vanuit huis, op kantoor of extern",
			// interview: "Stel de potentiele kandidaat alvast wat vragen",
			// date: "Bepaal de begindatum, deadline en categorie van de opdracht",
			finish: "Wij verspreiden jouw opdracht onder onze professionals",
		},
		cta: "Eerste opdracht maken",
	},
	en: {
		title: "Let's make a task!",
		description_one: "The task is easily created in 1 simple step",
		description_other:
			"The task is easily created in {{count}} simple steps",
		instructions: {
			description: "Provide a clear task description",
			budget: "Determine the task value",
			extra: "Provide any additional information such as: address, interview, start date, etc.",
			// location:
			// 	"Specify the location: from home, in the office, or external",
			// interview: "Ask the potential candidate some questions",
			// date: "Determine the start date, deadline, and category of the task",
			finish: "We will distribute your task among our professionals",
		},
		cta: "Create first task",
	},
};

export default withTranslation(NoTasksCard);
