import { Navigate } from "react-router-dom";
import Card from "src/components/Card";
import withTranslation, {
	Translation,
} from "src/components/hoc/withTranslation";
import useTenant from "src/hooks/api/services/tenants/useTenant";
import useAuth from "src/hooks/selectors/useAuth";

const WaitlistView = ({ t }: Translation) => {
	const auth = useAuth();
	const { tenant } = useTenant();

	if (!tenant.settings.features.waitlist || auth.is_waitlisted) {
		return <Navigate to="/dashboard" />;
	}

	return (
		<div className="dark flex flex-1 flex-col justify-center mx-auto gap-8 w-full max-w-[600px] px-3">
			<Card>
				<div className="flex flex-col gap-4">
					<strong className="text-2xl">{t("title")}</strong>
					<p>{t("description")}</p>

					<hr className="border-border my-6" />

					<p
						dangerouslySetInnerHTML={{
							__html: t("tips.description"),
						}}
					></p>

					<p>
						{t("greets")}
						<br />
						<span className="italic">Jacky</span>
					</p>
				</div>
			</Card>
		</div>
	);
};

WaitlistView.locale = {
	nl: {
		title: "Bedankt voor de interesse in {{tenant.name}}!",
		description:
			"{{tenant.name}} werkt momenteel met een wachtlijst om de kwaliteit van het platform te garanderen. Je bent toegevoegd aan de wachtlijst, hou je mailbox in de gaten voor een uitnodiging (of tips om sneller toegang te krijgen).",
		tips: {
			description:
				'Heb je tips of feedback welke features er zeker in {{tenant.name}} moeten zitten? Laat het me weten via <a class="underline" href="mailto: jacky@{{tenant.urls.domain}}">jacky@{{tenant.urls.domain}}</a>.',
		},
		greets: "Met virtuele groeten,",
	},
	en: {
		title: "Thank you for your interest in {{tenant.name}}!",
		description:
			"{{tenant.name}} is currently operating with a waiting list to ensure the quality of the platform. You have been added to the waiting list; keep an eye on your mailbox for an invitation (or tips to gain faster access).",
		tips: {
			description:
				'Do you have tips or feedback on features that should definitely be in {{tenant.name}}? Let me know via <a class="underline" href="mailto: jacky@{{tenant.urls.domain}}">jacky@{{tenant.urls.domain}}</a>.',
		},
		greets: "With virtual regards,",
	},
};

export default withTranslation(WaitlistView);
