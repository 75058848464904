import { useEffect } from "react";
import { useDraggable } from "@dnd-kit/core";
import useElementSize from "src/hooks/useElementSize";
import useKanban from "src/hooks/useKanban";
import { motion } from "framer-motion";

interface ItemProps {
	id: number | string;
	column: number | string;
	children?: any;
	options?: {
		disabled?: boolean;
	};
}

const Item = ({ id, children, column, options }: ItemProps) => {
	const { actions } = useKanban();
	const {
		attributes,
		listeners,
		setNodeRef: ref,
		transform,
		active,
	} = useDraggable({ id });
	const wrapper = useElementSize();
	const isActive = active?.id === id;

	const style = transform
		? {
				transform: `translate(${transform.x}px, ${transform.y}px)`,
				width: wrapper.size.width,
				zIndex: 99,
		  }
		: undefined;

	const getDragProps = () => {
		if (options?.disabled) return {};
		return {
			ref,
			style,
			...listeners,
			...attributes,
		};
	};

	useEffect(() => {
		if (isActive) {
			actions.setGhost({
				height: wrapper.size.height,
				width: wrapper.size.width,
			});
			actions.setFromColumn(column);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isActive]);

	let className = "cursor-pointer touch-none";

	if (options?.disabled) {
		className = "";
	}

	return (
		<div {...{ className }} {...getDragProps()}>
			<div ref={wrapper.ref} className={`relative`}>
				<motion.div
					variants={{
						visible: {
							opacity: 0,
							top: -2,
							left: -2,
							bottom: -2,
							right: -2,
						},
						hidden: {
							opacity: 0,
						},
					}}
					animate={isActive ? "visible" : "hidden"}
					className="absolute inset-0 bg-dark rounded-md"
				/>
				<div className="relative z-1">{children}</div>
			</div>
		</div>
	);
};

export default Item;
