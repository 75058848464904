import ProfileBadge from "src/components/ProfileBadge/ProfileBadge";
import useUserCardContext from "../UserCardContext";

function UserCardBadges() {
	const { user } = useUserCardContext() as any;
	if ((user?.badges || [])?.length === 0) {
		return <></>;
	}

	return (
		<div className="flex flex-row">
			{user?.badges.map((badge: any, index: number) => (
				<div
					key={`header-${badge.type}`}
					className={index !== 0 ? `-ml-2` : undefined}
				>
					<ProfileBadge
						variant="logo-only"
						className="w-[32px]"
						{...{ badge }}
					/>
				</div>
			))}
		</div>
	);
}

export default UserCardBadges;
