import Wrapper, { WrapperProps } from "src/components/field/utils/Wrapper";
import SwitchComp from "src/components/form/Switch";

interface SwitchProps {
	wrapper: WrapperProps;
	value: boolean;
	onChange: (value?: boolean) => void;
	theme?: {
		placeholder?: {
			className?: string;
		};
	};
	className?: string;
}

const Switch = ({ wrapper, value, onChange, className }: SwitchProps) => {
	return (
		<Wrapper
			{...{
				...wrapper,
			}}
		>
			<SwitchComp
				{...{ className }}
				switchClassName="data-[state=unchecked]:bg-placeholder"
				value={value}
				onChange={(value) => onChange(value)}
			/>
		</Wrapper>
	);
};

export default Switch;
