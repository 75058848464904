import withTranslation, {
	Translation,
} from "src/components/hoc/withTranslation";
import { cn } from "src/lib/utils";
import useUserCardContext from "../UserCardContext";

interface UserCardStatusProps extends Translation {}

function UserCardStatus({ t }: UserCardStatusProps) {
	const { user } = useUserCardContext();
	return (
		<span
			className={cn(
				"font-bold text-sm",
				user?.status === "INACTIVE" && "opacity-40"
			)}
		>
			{t(user.status)}
		</span>
	);
}

UserCardStatus.locale = {
	nl: {
		ACTIVE: "Actief",
		INACTIVE: "Inactief",
		INVITED: "Uitgenodigd",
	},
	en: { ACTIVE: "Active", INACTIVE: "Inactive", INVITED: "Invited" },
};

export default withTranslation(UserCardStatus);
