import { useAtom } from "jotai";
import { atomWithStorage } from "jotai/utils";
import { useParams } from "react-router-dom";
import useTenant from "src/hooks/api/services/tenants/useTenant";

export type TaskView = "kanban" | "list" | "calendar";

const viewAtom = atomWithStorage<any>("views", {
	ALL: "kanban",
});

export default function useTasksView() {
	const { tenant } = useTenant();
	const { board } = useParams() as any;
	const [_view, setView] = useAtom(viewAtom);
	const view = _view[board || "ALL"] || "kanban";

	return {
		view: tenant.modules.includes("intern-task") ? view : "list",
		setView: (value: TaskView) => {
			setView((state: any) => ({
				...state,
				[board || "ALL"]: value,
			}));
		},
	};
}
