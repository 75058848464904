import { createContext, useContext, useMemo } from "react";
import { BarChartPopup, Dataset } from "src/components/statistics/bar-chart";

type BarChartContextProps = {
	data: Dataset[];
	popup?: BarChartPopup;
	active: any;
	setActive: (value: any) => void;
};

export const BarChartContext = createContext<BarChartContextProps>(
	{} as BarChartContextProps
);

export function useBarChart() {
	const { data, active, setActive, popup } = useContext(BarChartContext);
	const realMax = useMemo(() => {
		const maxes = data.map((item) => {
			return item.data.reduce((max, obj) => {
				return obj.count > max ? obj.count : max;
			}, 0);
		});
		if (maxes.length === 0) return 0;
		return maxes.reduce((max, current) => {
			return current > max ? current : max;
		});
	}, [data]);

	const totals = useMemo(() => {
		const totalSteps = realMax / 5;
		const stepSize = Math.ceil(totalSteps / 5) * 5;
		const steps = new Array(6)
			.fill("")
			.map((i, index) => stepSize * index)
			.sort((a, b) => b - a);

		return {
			size: stepSize,
			steps,
			max: steps[0],
		};
	}, [realMax]);

	const ids = useMemo(() => {
		const ids: any[] = [];
		data.map((item) => {
			item.data.map(({ id, label, description }) => {
				if (!ids.find((_id) => _id.id === id)) {
					ids.push({
						id,
						label,
						description,
					});
				}
				return id;
			});
			return item;
		});
		return ids;
	}, [data]);

	return {
		data,
		ids,
		totals,
		popup,
		active: {
			value: active,
			set: setActive,
		},
		theme: {
			footer: {
				className: "h-8 flex",
			},
		},
	};
}
