import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { ApiRegistration } from "src/api/types";
import Button from "src/components/Button";
import FlatList from "src/components/FlatList";
import Checkbox from "src/components/form/Checkbox";
import withTranslation, {
	Translation,
} from "src/components/hoc/withTranslation";
import RegistrationAcceptListItem from "src/components/lists/list-items/RegistrationAcceptListItem";
import Progress from "src/components/Progress";
import ProcessRegistrationsModal from "src/components/tasks/TaskForm/modals/ProcessRegistrationsModal";
import useRegistrations from "src/hooks/api/services/registrations/useRegistrations";
import useSelectRegistrations from "src/hooks/api/tasks/useSelectRegistrations";
import useSocketListener from "src/hooks/sockets/useSocketListener";

interface RegistrationAcceptListProps extends Translation {
	taskId: number;
	taskHid: string;
	filter?: any;
	matches?: {
		total: number;
		count: number;
	};
}

const RegistrationAcceptList = ({
	t,
	taskId,
	taskHid,
	filter,
	matches,
}: RegistrationAcceptListProps) => {
	const location = useLocation();
	const { registrations, actions, status, pagination } = useRegistrations({
		task_id: taskId,
		filter: {
			include: ["user", "unread_messages"],
			...(filter || {}),
		},
	});
	const [modal, setModal] = useState<"accept" | "deny">();
	const { selected, actions: selectActions } = useSelectRegistrations();
	const [matched, setMatched] = useState(matches?.count);
	useSocketListener(`task.${taskHid}.match-change`, (res) => {
		if (res.matched) {
			setMatched(res.matched);
		}
	});

	useEffect(() => {
		actions.list({
			task_id: taskId,
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [taskId, location]);

	const headers = [
		<Checkbox
			value={
				selected.length === registrations?.length &&
				status === "idle" &&
				registrations.length !== 0
			}
			onChange={(value) => {
				selectActions.set(
					value
						? registrations
								.filter(
									(registration) =>
										registration.status === "pending"
								)
								.map(({ id }) => id)
						: []
				);
			}}
		/>,
		t("headers.status"),
		t("headers.name"),
		t("headers.telephone"),
		t("headers.registration"),
		t("headers.created-at"),
		"",
	];

	return (
		<>
			<ProcessRegistrationsModal
				visible={modal ? true : false}
				variant={modal || "accept"}
				onStatusChange={(status, ids) => {
					if (status === "success") {
						actions.set((state: any) => ({
							...state,
							list: state.list.map((item: any) => {
								if (ids.includes(item.id)) {
									return {
										...item,
										status:
											modal === "accept"
												? "matched"
												: "denied",
									};
								}
								return item;
							}),
						}));
						selectActions.set([]);
					}

					if (["success", "close"].includes(status)) {
						setModal(undefined);
					}
				}}
			/>

			<FlatList.Head
				title={
					matches?.total
						? t("matched-head.title", {
								total: matches.total,
								count: matched,
						  })
						: t("head.title")
				}
				subtitle={
					matches?.total ? (
						<div className="block mt-1">
							<Progress
								current={matched || 0}
								total={matches.total}
							/>
						</div>
					) : (
						t("head.subtitle")
					)
				}
				search={{
					onSearch: (q: string) => {
						actions.list({ page: 1, q });
					},
				}}
			>
				{selected?.length ? (
					<>
						<Button onClick={() => setModal("deny")} type="gray">
							<i className="fas fa-times mr-2"></i>
							<span>
								{`(${selected.length}) ${t("buttons.deny")}`}
							</span>
						</Button>
						<Button onClick={() => setModal("accept")} type="dark">
							<i className="fas fa-check mr-2"></i>
							<span>
								{`(${selected.length}) ${t("buttons.accept")}`}
							</span>
						</Button>
					</>
				) : (
					false
				)}
			</FlatList.Head>
			<FlatList
				{...{ pagination }}
				data={registrations}
				table={{
					headers,
				}}
				loading={status !== "idle" && !registrations?.length}
				renderItem={(registration: ApiRegistration) => (
					<RegistrationAcceptListItem
						key={`registration-${registration.id}`}
						links={{
							taskHid,
						}}
						{...{ registration }}
					/>
				)}
				empty={{
					title: t("empty.title"),
					text: t("empty.text"),
				}}
			/>
		</>
	);
};

RegistrationAcceptList.locale = {
	nl: {
		empty: {
			title: "Geen aanmeldingen gevonden",
			text: "Er zijn nog geen professionals die deze opdracht willen doen",
		},
		headers: {
			status: "Status",
			name: "Naam",
			telephone: "Telefoonnummer",
			registration: "Aanmelding",
			"created-at": "Aangemaakt op",
		},
		head: {
			title: "Alle aanmeldingen",
			subtitle:
				"Deze professionals hebben zich aangemeld op jouw opdracht",
		},
		"matched-head": {
			title: "({{count}}/{{total}}) aanmeldingen gematcht",
		},
		buttons: {
			accept: "Accepteren",
			deny: "Afwijzen",
		},
	},
	en: {
		empty: {
			title: "No registrations found",
			text: "There are no registrations yet",
		},
		headers: {
			status: "Status",
			name: "Name",
			telephone: "Telephone",
			registration: "Registration",
			"created-at": "Created at",
		},
		head: {
			title: "All registrations",
			subtitle: "",
		},
		"matched-head": {
			title: "({{count}}/{{total}}) registrations matched",
		},
		buttons: {
			accept: "Accept",
			deny: "Deny",
		},
	},
};

export default withTranslation<any>(RegistrationAcceptList);
