import axios from "axios";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { ApiTaskType } from "src/api/types";
import useLimits from "src/hooks/api/services/auth/useLimits";
import useBoards from "src/hooks/api/services/tasks/useBoards";
import useTenant from "src/hooks/api/services/tenants/useTenant";
import useTasksFilter from "src/hooks/api/tasks/useTasksFilter";
import usePatch from "src/hooks/rest/usePatch";
import usePost from "src/hooks/rest/usePost";
import useAuth from "src/hooks/selectors/useAuth";
import useAlert from "src/hooks/useAlert";
import useQueryString from "src/hooks/useQueryString";
import { uploadFile } from "src/lib/uploadFile";
import store from "src/redux";
import {
	removeTask,
	TaskFormStatus,
	updateStatusTask,
	updateTask,
} from "src/redux/taskForm";
import useProjects from "../services/projects/useProjects";

type SetType = "fill" | "manual" | "merge";

interface GetParams {
	skipLoading?: boolean;
}

type SaveOptions = {
	data?: any;
	forceSave?: boolean;
	disableAlert?: boolean;
	disableLoading?: boolean;
	disableSetState?: boolean;
};

function useTaskForm() {
	const {
		tenant: { slug: tenantSlug, modules },
	} = useTenant();
	const { id: _id, board: _board } = useParams() as any;
	const { boards } = useBoards();
	const { projects } = useProjects();
	const { filter } = useTasksFilter();
	const board = filter.board
		? boards.find(
				(item) => item.slug === filter.board || item.slug === _board
		  )
		: null;
	const project = filter.project
		? projects.find((item) => item.slug === filter.project)
		: null;
	const qs = useQueryString();
	const { t } = useTranslation("tasks", {
		keyPrefix: "alert",
	});
	const limits = useLimits();
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const auth = useAuth();
	const task = useSelector((state: any) => state.taskForm.task);
	const questions = useSelector((state: any) => state.taskForm.questions);
	const changes = useSelector((state: any) => state.taskForm.changes);
	const current = useSelector((state: any) => state.taskForm.current);
	const status = useSelector((state: any) => state.taskForm.status);
	const [sendAlert] = useAlert();
	const q = `with[]=answers&with[]=description_obj&with[]=assignees&with[]=teams&with[]=skills`;
	const [apiCreate] = usePost(`/client/tasks?${q}`);
	const id = _id || task?.hid;
	const [apiUpdate] = usePatch(id ? `/client/tasks/${id}?${q}` : undefined);

	const get = async (params?: GetParams) => {
		if (status === "loading") {
			return;
		}
		if (!id) return;
		if (!params?.skipLoading) {
			setStatus("loading");
		}

		try {
			const {
				data: { data: _task },
			} = await axios.get(`/client/tasks/${id}?${q}`);

			if (!_task?.address?.street && !qs.repeat) {
				_task.is_home = true;
			}

			dispatch(
				updateTask({
					task: _task,
					status: "idle",
				})
			);
			return _task;
		} catch (error) {
			console.log(error);
			setStatus("error");
		}
	};

	const save = async (options?: SaveOptions) => {
		if (!id || status !== "idle") return;
		const changes = store.getState().taskForm.changes;
		const update = options?.data || (await parseData(changes));
		if (!Object.keys(update)?.length && !options?.forceSave) {
			return;
		}

		if (!options?.disableLoading) {
			setStatus("updating");
		}

		try {
			const { data: _task } = await apiUpdate(update);
			if (!options?.disableAlert) {
				sendAlert({
					type: "success",
					title: t("success.title"),
					text: t("success.text"),
				});
			}

			if (update.type === "PUBLISHED") {
				limits.actions.get("");
			}

			dispatch(
				updateTask({
					task: {
						...task,
						...(!options?.disableSetState ? _task : {}),
					},
					changes: {},
					status: "idle",
				})
			);
			return _task;
		} catch (error) {
			dispatch(
				updateTask({
					changes: {},
					// status: "error",
				})
			);
		}
	};

	const updateCurrent = (n: number) => {
		if (n < 0) return;
		window.scrollTo(0, 0);

		const changes = store.getState().taskForm.changes;

		if (task.id && Object.keys(changes).length) {
			save({
				data: changes,
				disableAlert: true,
			});
		}

		dispatch(
			updateTask({
				current: n,
			})
		);
	};

	const setStatus = (status: TaskFormStatus) => {
		dispatch(updateStatusTask(status));
	};

	const setTask = (data: any) => {
		dispatch(
			updateTask({
				task: data,
				status: "idle",
			})
		);
	};

	const set = (data?: any, type: SetType = "manual") => {
		if (data.amount_of_students) {
			data.amount_of_students = Number(data.amount_of_students);
		}
		let update = {
			task: {
				...task,
				...data,
			},
		} as any;
		if (type === "manual") {
			update.changes = {
				...(changes || {}),
				...data,
			};
		}
		if (type === "fill") {
			dispatch(
				updateTask({
					task: {
						...data,
					},
				})
			);
			return;
		}

		if (type === "merge") {
			dispatch(
				updateTask({
					task: {
						...task,
						...data,
					},
				})
			);
			return;
		}

		dispatch(updateTask(update));
	};

	const parseData = async (_data: any) => {
		const address = _data.address;
		delete _data.address;
		let data = {
			..._data,
			...(address || {}),
		} as any;
		const subtasks = data?.subtasks || [];

		if (board?.slug && board?.hid) {
			data.board = board.hid;
		}

		if (project?.slug && project?.hid && _data?.project === undefined) {
			data.project = project.hid;
		}

		if (data.is_office) {
			data = {
				...data,
				...auth?.company.address,
			};
		}

		if (data.media) {
			let uploaded_media = [];
			for (let file of data.media) {
				if (file.id) {
					uploaded_media.push(file.id);
				} else {
					let fd = new FormData();
					fd.append("file", file);
					let res = await uploadFile("/media", fd);
					uploaded_media.push(res?.data?.data?.id || null);
				}
			}
			data.media = uploaded_media;
		}

		if (data.start_date) {
			data.start_date = moment(data.start_date).format(
				"YYYY-MM-DD HH:mm:ss"
			);
		}

		if (data.end_date) {
			data.end_date = moment(data.end_date).format("YYYY-MM-DD HH:mm:ss");
		}

		if (subtasks?.length) {
			const start = subtasks
				.map((item: any) => item.start_date)
				.reduce((c: any, n: any) =>
					Date.parse(n) < Date.parse(c) ? n : c
				);
			const end = subtasks
				.map((item: any) => item.start_date)
				.reduce((c: any, n: any) =>
					Date.parse(n) > Date.parse(c) ? n : c
				);

			if (start && end) {
				data.start_date = moment(start).format("YYYY-MM-DD HH:mm:ss");
				data.end_date = moment(end).format("YYYY-MM-DD HH:mm:ss");
			}
		}

		return data;
	};

	const indexSkills = async (id: any) => {
		if (!modules.includes("intern-task") || tenantSlug === "getjobsdone") {
			return null;
		}

		// console.log("INDEXING SKILLS", id);
		const { data } = await axios
			.post(`/client/tasks/ai/${id}/skills`)
			.then(({ data }) => data);
		// console.log("INDEXED SKILLS", id);
		return data;
	};

	const create = async (
		type: ApiTaskType,
		options?: {
			disableRedirect?: boolean;
			updateState?: boolean;
			data?: any;
		}
	) => {
		if (id) return;
		set({
			type,
		});
		setStatus("creating");

		const data = await parseData({
			...task,
			...(options?.data || {}),
			type,
		});

		if (!data?.address?.street) {
			data.is_home = true;
		}
		if (filter.project) {
			data.project_slug = filter.project;
		}
		data.type = type;
		return apiCreate(data)
			.then(({ data }) => {
				limits.actions.get("");
				if (data?.id) {
					indexSkills(data?.id);
				}
				if (!options?.disableRedirect) {
					navigate(`/tasks/create/completed/thanks?hid=${data?.hid}`);
				}
				if (options?.updateState) {
					dispatch(
						updateTask({
							task: data,
							status: "idle",
						})
					);
				} else {
					setStatus("idle");
				}
				setStatus("idle");

				return data;
			})
			.catch(() => {
				sendAlert({
					type: "error",
					title: t("error.title"),
					text: t("error.text"),
				});
				dispatch(
					updateTask({
						status: "idle",
						changes: {},
					})
				);
			});
	};

	const createByTitle = async (title: string, extraData?: any) => {
		// if (id) return;
		setStatus("creating");
		const data = await parseData({
			title,
			description: "",
			start_date: moment().toDate(),
			type: "INTERNAL",
			...(extraData || {}),
		});
		if (board?.hid) {
			data.tags = board?.filter?.tags || filter?.tags || [];
			data.assignees =
				board?.filter?.assignees || filter?.assignees || [];
		}

		return apiCreate(data)
			.then(({ data }) => {
				dispatch(
					updateTask({
						task: data,
						status: "idle",
					})
				);
				let query = "";
				if (
					data?.answers?.length &&
					data?.answers?.every((item: any) => item.answer)
				) {
					query += "generate=1";
				}
				navigate(`/tasks/${data?.hid}/publish?${query}`);
			})
			.catch((e) => {
				console.log("ERROR", e);
				setStatus("idle");
			});
	};

	const publish = async () => {
		if (!id) return;
		setStatus("creating");
		try {
			const data: any = {
				type: "PUBLISHED",
				students: task?.students,
				invite_all: task?.invite_all,
				student_hid: task?.student_hid,
				advertise_task: task?.advertise_task,
			};

			if (!task.is_home && !task?.address?.street) {
				data.is_home = 1;
			}

			const { data: _task } = await apiUpdate(data);
			limits.actions.get("");
			dispatch(
				updateTask({
					task: _task,
					status: "idle",
				})
			);
		} catch (error) {
			setStatus("error");
		}
	};

	const archive = async (id: number) => {
		setStatus("deleting");

		try {
			await axios.delete(`/client/tasks/${id}`);
			setStatus("idle");
		} catch (error) {
			setStatus("error");
		}
	};

	const clear = () => dispatch(removeTask());

	const getQuestions = async () => {
		if (!id) return;
		const { data } = await axios.get(`/client/tasks/ai/${id}/questions`);

		dispatch(
			updateTask({
				questions: data?.data,
				task: {
					...task,
					answers: (data?.data || []).map((question: any) => ({
						question: question?.question,
						answer: "",
					})),
				},
			})
		);
		return data;
	};

	const setQuestions = (questions: any[]) => {
		dispatch(
			updateTask({
				questions: questions,
			})
		);
	};

	const clearQuestions = () => {
		dispatch(updateTask({ questions: [] }));
	};

	const duplicate = async (id: number) => {
		setStatus("duplicating");
		return axios
			.post(`/client/tasks/${id}/duplicate`)
			.then(({ data }) => {
				setStatus("idle");
				return data?.data;
			})
			.catch(() => {
				setStatus("idle");
			});
	};

	const skipAIStep = () => {
		dispatch(
			updateTask({
				task: {
					...task,
					isManualDescription: true,
				},
				current: current + 1,
			})
		);
	};

	return {
		task,
		questions,
		changes,
		status,
		current,
		actions: {
			set,
			setTask,
			clear,
			create,
			updateCurrent,
			setStatus,
			get,
			save,
			createByTitle,
			getQuestions,
			clearQuestions,
			questions,
			setQuestions,
			publish,
			duplicate,
			archive,
			skipAIStep,
			indexSkills,
		},
	};
}

export default useTaskForm;
