import { ComponentProps } from "react";
import Button, { ButtonProps } from "src/components/Button";
import withTranslation, {
	Translation,
} from "src/components/hoc/withTranslation";
import useBackUrl from "src/hooks/useBackUrl";
import { cn } from "src/lib/utils";

const ResumeMiddleware = ({ className, ...rest }: ComponentProps<"div">) => {
	return (
		<div
			{...rest}
			className={cn(
				"flex flex-col rounded-md p-4 b border border-aqua-dark",
				className
			)}
		/>
	);
};

const Title = withTranslation(
	({
		t,
		children,
		className,
		...rest
	}: Translation & ComponentProps<"strong">) => {
		return (
			<strong {...rest} className="flex flex-col gap-4">
				{children || t("title")}
			</strong>
		);
	},
	{
		nl: {
			title: "Ontgrendel dit profiel",
		},
		en: {
			title: "Unlock this profile",
		},
	}
);

const Description = withTranslation(
	({
		t,
		children,
		className,
		...rest
	}: Translation & ComponentProps<"p">) => {
		return (
			<p {...rest} className="">
				{children || t("description")}
			</p>
		);
	},
	{
		nl: {
			description:
				"Om dit profiel in te zien dien je een Premium account te activeren.",
		},
		en: {
			description:
				"To see this profile, you need to upgrade your account.",
		},
	}
);

const UpgradeButton = withTranslation(
	({ t, children, ...rest }: Translation & ButtonProps) => {
		const backUrl = useBackUrl();
		return (
			<Button to={`/subscription?${backUrl}`} {...rest}>
				{children || t("label")}
			</Button>
		);
	},
	{
		nl: {
			label: "Upgrade je account",
		},
		en: {
			label: "Upgrade your account",
		},
	}
);

ResumeMiddleware.Title = Title;
ResumeMiddleware.Description = Description;
ResumeMiddleware.UpgradeButton = UpgradeButton;

export default ResumeMiddleware;
