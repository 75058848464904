import { createAction, handleActions } from "redux-actions";
export const updateGlobal = createAction("UPDATE_GLOBAL");
export const removeGlobal = createAction("REMOVE_GLOBAL");

export const emptyGlobalState = {
	data: {
		students: "",
	},
	isFetched: false,
};

type GlobalState = {
	data?: {
		students: number | string;
	};
	isFetched: boolean;
};

const globalReducer = handleActions(
	{
		UPDATE_GLOBAL: (state: GlobalState, action: any) => {
			return { ...state, ...action.payload, isFetched: true };
		},
		REMOVE_GLOBAL: () => {
			return {
				...emptyGlobalState,
			};
		},
	},
	emptyGlobalState as GlobalState
);
export default globalReducer;
