import moment from "moment";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import FormGroup from "src/components/form/FormGroup";
import Dropdown from "src/components/form/Dropdown";
import "./date-of-birth-picker.scss";

interface DateOfBirthPickerProps {
	label?: string;
	value?: any;
	onChange: (key: string, value: any) => void;
	handleChange: (e: any) => void;
	handleBlur?: (e: any) => void;
	error?: any | boolean;
	valid?: string | boolean | number;
	loading?: boolean;
	name: string;
	noMarginBottom?: boolean;
}

const DateOfBirthPicker = ({
	label,
	value,
	error,
	valid,
	handleChange,
	onChange,
	name,
	noMarginBottom,
}: DateOfBirthPickerProps) => {
	const { t } = useTranslation("form", { keyPrefix: "date-of-birth-picker" });
	const MONTHS = Array.apply(0, Array(12)).map(function (_, i) {
		return moment().month(i).format("MMMM");
	});
	const [dates, setDates] = useState({
		day: undefined,
		month: undefined,
		year: undefined,
	} as any);

	const DAYS = new Array(31).fill("");
	const YEARS = [];

	const now = moment();
	const oldest = now.year() - 70;
	for (let i = now.year() - 16; i > oldest; i--) {
		YEARS.push(i);
	}

	useEffect(() => {
		if (value) {
			const format = moment(value).format("DD-MM-YYYY");
			if (format) {
				const [day, month, year] = format.split("-");
				setDates({
					day: Number(day),
					month: Number(month),
					year: Number(year),
				});
			}
		}
	}, [value]);

	const formatDatesToMoment = (dates: any) => {
		if (dates.day && dates.month && dates.year) {
			const day = dates.day >= 10 ? dates.day : `0${dates.day}`;
			const month = dates.month >= 10 ? dates.month : `0${dates.month}`;
			return moment(`${day}-${month}-${dates.year}`, "DD-MM-YYYY");
		}
		return undefined;
	};

	const pushChange = (value: any) => {
		if (value) {
			const val = value.toDate();
			onChange(name, val);
			handleChange({
				target: {
					name,
					value: val,
				},
			});
		} else {
			onChange(name, undefined);
			handleChange({
				target: {
					name,
					value: undefined,
				},
			});
		}
	};

	const dropdownChange = (key: string, value?: number) => {
		const newDates = {
			...dates,
			[key]: value,
		};

		const val = formatDatesToMoment(newDates) as any;
		pushChange(val);
		setDates({
			...newDates,
		});
	};

	return (
		<FormGroup {...{ label, valid, error, noMarginBottom }}>
			<div className={`flex items-center gap-3 flex-1`}>
				<div className="flex-1">
					<Dropdown
						noMarginBottom
						placeholder={t("placeholder.day")}
						options={DAYS.map((_, index) => ({
							name: (index + 1).toString(),
							id: index + 1,
						}))}
						value={dates.day}
						onChange={(value: any) => dropdownChange("day", value)}
					/>
				</div>
				<div className="flex-1">
					<Dropdown
						noMarginBottom
						placeholder={t("placeholder.month")}
						options={MONTHS.map((name, index) => ({
							id: index + 1,
							name,
						}))}
						value={dates.month}
						onChange={(value: any) =>
							dropdownChange("month", value)
						}
					/>
				</div>
				<div className="flex-1">
					<Dropdown
						noMarginBottom
						placeholder={t("placeholder.year")}
						options={YEARS.map((year) => ({
							id: year,
							name: year.toString(),
						}))}
						value={dates.year}
						onChange={(value: any) => dropdownChange("year", value)}
					/>
				</div>
			</div>
		</FormGroup>
	);
};

export default DateOfBirthPicker;
