import { ApiPaymentStatus } from "src/api/types";
import Badge from "src/components/Badge";
import usePaymentStatus from "src/hooks/data/usePaymentStatus";

interface PaymentBadgeProps {
	status: ApiPaymentStatus;
}

const color = {
	completed: {
		bg: "green-200",
		color: "green",
	},
	reserved: {
		bg: "green-200",
		color: "green",
	},
	cancelled: {
		bg: "red-200",
		color: "red",
	},
	chargeback: {
		bg: "red-200",
		color: "red",
	},
	expired: {
		bg: "red-200",
		color: "red",
	},
	failed: {
		bg: "red-200",
		color: "red",
	},
	credit: {
		bg: "red-200",
		color: "red",
	},
} as any;

const PaymentBadge = ({ status }: PaymentBadgeProps) => {
	const label = usePaymentStatus(status);
	return (
		<Badge
			bg={color[status]?.bg || "gray"}
			color={color[status]?.color || "dark"}
			className="font-regular"
		>
			{label}
		</Badge>
	);
};

export default PaymentBadge;
