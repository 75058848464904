import { ApiMedia } from "src/api/types";
import Tag, { TagProps } from "src/components/Tag";
import FileUploadItemIcon from "src/components/form/file-upload/FileUploadItemIcon";
import {
	HoverCard,
	HoverCardContent,
	HoverCardTrigger,
} from "src/components/ui/hover-card";
import { cn } from "src/lib/utils";

interface FileUploadItemProps extends TagProps {
	media: ApiMedia;
	isFullname: boolean;
	isPreview?: boolean;
}

const FileUploadItem = ({
	media,
	isFullname,
	isPreview,
	className,
	...rest
}: FileUploadItemProps) => {
	const Wrapper = media.url ? "a" : "div";
	if (isPreview) {
		const isImage = media.mime_type.startsWith("image");
		return (
			<div {...rest} className={cn(className, "flex items-center gap-1")}>
				<Wrapper
					className={cn(
						"flex items-center gap-2 flex-1 text-[16px]",
						media.url && "hover:underline"
					)}
					onClick={(event) => {
						event.stopPropagation();
					}}
					href={media.url}
					target="_blank"
				>
					<HoverCard>
						<HoverCardTrigger>
							<div className="flex items-center gap-2">
								<FileUploadItemIcon {...{ media }} />
								{isFullname
									? media.filename
									: media.short_filename}
							</div>
						</HoverCardTrigger>
						{isImage && (
							<HoverCardContent
								side="right"
								align="center"
								sideOffset={8}
							>
								<a
									href={media.url}
									target="_blank"
									rel="noreferrer"
								>
									<img
										className="max-w-[260px] w-full"
										src={media.url}
										alt={media.filename}
									/>
								</a>
							</HoverCardContent>
						)}
					</HoverCard>
				</Wrapper>
				{rest?.children}
			</div>
		);
	}

	return (
		<Tag {...{ ...rest, className }}>
			<div className="flex items-center gap-2 flex-1">
				<FileUploadItemIcon {...{ media }} />
				{isFullname ? media.filename : media.short_filename}
			</div>
			{rest?.children}
		</Tag>
	);
};

FileUploadItem.defaultProps = {
	theme: "gray",
	size: "default",
	isFullname: false,
};

export default FileUploadItem;
