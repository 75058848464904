import { ComponentProps } from "react";
import { ApiResumeModule } from "src/api/types";
import Button from "src/components/Button";
import withTranslation, {
	Translation,
} from "src/components/hoc/withTranslation";
import useResumeDetailContext from "src/components/resume/resume-detail/ResumeDetailContext";
import { cn } from "src/lib/utils";

interface ResumeEditableProps extends Translation {
	module: ApiResumeModule;
	children?: any;
}

const ResumeEditable = ({
	t,
	module: resumeModule,
	children,
	className,
	...rest
}: ResumeEditableProps & ComponentProps<"div">) => {
	const {
		resume: { resume_modules: modules },
	} = useResumeDetailContext();
	const hasValue = modules.includes(resumeModule);

	if (
		resumeModule === "skills" &&
		!modules.includes("experiences.education") &&
		!modules.includes("experiences.job")
	) {
		return (
			<div
				{...rest}
				className={cn(
					"flex flex-col gap-4 rounded-md border border-border p-4",

					className
				)}
			>
				<div className="flex flex-col gap-1.5">
					<p className="font-bold text-balance">
						{t(`missing-experiences-for-skills.title`)}
					</p>
					<p className="opacity-70">
						{t(`missing-experiences-for-skills.description`)}
					</p>
				</div>

				<Button
					xsmall
					to={{
						modal: `/dashboard/resume/edit/experiences/education`,
					}}
					type="border"
				>
					{t(`missing-experiences-for-skills.button`)}
				</Button>
			</div>
		);
	}

	if (!hasValue) {
		return (
			<div
				{...rest}
				className={cn(
					"flex flex-col gap-4 rounded-md border border-border p-4",
					resumeModule === "skills" &&
						"justify-center items-center text-center bg-dark text-dark-foreground p-6 border-dark",
					className
				)}
			>
				<div className="flex flex-col gap-1.5">
					<p className="text-primary">
						{t(`empty.${resumeModule}.eyebrow`)}
					</p>
					<p className="font-bold text-balance">
						{t(`empty.${resumeModule}.title`)}
					</p>
					<p className="opacity-70">
						{t(`empty.${resumeModule}.description`)}
					</p>
				</div>

				<Button
					xsmall
					to={{
						modal: `/dashboard/resume/edit/${resumeModule.replace(
							".",
							"/"
						)}`,
					}}
					type={resumeModule === "skills" ? "primary" : "border"}
				>
					{t(`empty.${resumeModule}.add`)}
				</Button>
			</div>
		);
	}

	return (
		<div {...rest} className={cn("flex flex-col gap-4", className)}>
			{children}
			{!["skills"].includes(resumeModule) && (
				<Button
					xsmall
					to={{
						modal: `/dashboard/resume/edit/${resumeModule.replace(
							".",
							"/"
						)}`,
					}}
					type="border"
				>
					{t("edit")}
				</Button>
			)}
		</div>
	);
};

ResumeEditable.locale = {
	nl: {
		edit: "Bewerk",
		"missing-experiences-for-skills": {
			title: "We kunnen nog geen skills profiel maken",
			description:
				"Voeg eerst je werk en / of opleidingen toe, daarna kan je op basis van deze ervaringen je skillset samenstellen.",
			button: "Ervaring toevoegen",
		},
		empty: {
			bio: {
				eyebrow: "Bio",
				title: "Voeg je persoonlijke touch toe",
				description:
					"Je hebt nog geen omschrijving, profielfoto of video toegevoegd. Voeg dit toe om jezelf te presenteren aan toekomstige bedrijven.",
				add: "Toevoegen",
			},
			"social-media": {
				eyebrow: "Social media",
				title: "Voeg je socials of portfolio toe",
				description:
					"Je hebt nog geen sociale netwerken toegevoegd. Voeg deze toe om jezelf te presenteren aan toekomstige bedrijven.",
				add: "Toevoegen",
			},
			experiences: {
				job: {
					eyebrow: "Werk",
					title: "Voeg je werk ervaring toe",
					description:
						"Je hebt nog geen werk ervaring toegevoegd. We gebruiken je werk ervaring om de basis van jouw skills profiel te maken.",
					add: "Toevoegen",
				},
				education: {
					eyebrow: "Opleiding",
					title: "Voeg je opleiding toe",
					description:
						"Je hebt nog geen opleidingen toegevoegd. We gebruiken je opleidingen om de basis van jouw skills profiel te maken.",
					add: "Toevoegen",
				},
			},
			skills: {
				eyebrow: "Skills",
				title: "Stel je huidige vaardigheden in",
				description:
					"Ons systeem kijkt op basis van je ervaringen naar welke vaardigheden je hebt. Je stelt deze vaardigheden in, zodra je opdrachten afrond level je je skills profiel door.",
				add: "Stel je skills in",
			},
		},
	},
	en: {
		edit: "Edit",
		"missing-experiences-for-skills": {
			title: "We can't create a skills profile yet",
			description:
				"First add your work and / or education experiences, then you can match these experiences to your skills.",
			button: "Add experience",
		},
		empty: {
			bio: {
				eyebrow: "Bio",
				title: "Add your personal touch",
				description:
					"You haven't added a description, profile picture, or video yet. Add these to present yourself to future companies.",
				add: "Add",
			},
			"social-media": {
				eyebrow: "Social media",
				title: "Add your socials or portfolio",
				description:
					"You haven't added any social networks yet. Add these to present yourself to future companies.",
				add: "Add",
			},
			experiences: {
				job: {
					eyebrow: "Work",
					title: "Add your work experience",
					description:
						"You haven't added any work experience yet. We use your work experience as the foundation of your skills profile.",
					add: "Add",
				},
				education: {
					eyebrow: "Education",
					title: "Add your education",
					description:
						"You haven't added any education yet. We use your education as the foundation of your skills profile.",
					add: "Add",
				},
			},
			skills: {
				eyebrow: "Skills",
				title: "Set your current skills",
				description:
					"Our system looks at your experiences to identify which skills you have. Once you've completed tasks, you can level up your skills profile.",
				add: "Set your skills",
			},
		},
	},
};

export default withTranslation(ResumeEditable);
