import { useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Breadcrumb from "src/components/Breadcrumb";
import Link from "src/components/Link";
import Field from "src/components/field/Field";
import withTranslation, {
	Translation,
} from "src/components/hoc/withTranslation";
import Icon from "src/components/icon/Icon";
import Layout from "src/components/layout/Layout";
import TasksLayoutHead from "src/components/layout/tasks-layout/TasksLayoutHead";
import BoardMissingContext from "src/components/tasks/boards/BoardMissingContext";
import Text from "src/components/ui/text";
import useBoards from "src/hooks/api/services/tasks/useBoards";
import useTenant from "src/hooks/api/services/tenants/useTenant";
import useTeams from "src/hooks/api/services/users/useTeams";
import useAuth from "src/hooks/selectors/useAuth";

interface TasksLayoutProps extends Translation {
	children?: any;
}

const TasksLayout = ({ t, children }: TasksLayoutProps) => {
	const auth = useAuth();
	const { team, board } = useParams();
	const navigate = useNavigate();
	const { tenant } = useTenant();
	const { boards } = useBoards();
	const { teams: teamsItems } = useTeams();
	const activeBoard = (boards || []).find((item) => item.slug === board);
	const activeTeam = team
		? (teamsItems || []).find((item) => item.slug === team)
		: null;

	const isBoardConfigured =
		!activeBoard?.needs_skills ||
		activeBoard?.is_static ||
		(tenant.modules.includes("intern-task") &&
			tenant.modules.includes("extern-task"));

	const type = useMemo(() => {
		if (tenant.modules.includes("intern-task")) {
			return "intern";
		}
		if (
			tenant.modules.includes("extern-task") &&
			!tenant.modules.includes("intern-task")
		) {
			return "extern";
		}
		return undefined;
	}, [tenant]);
	const container =
		auth?.type === "student" && type === "extern" ? "xxl" : undefined;

	const routes: any[] = [
		{
			label: t(
				tenant.slug === "getrecruitmentdone"
					? "recruitment.title"
					: type === "extern"
					? "breadcrumbs.home-extern"
					: "breadcrumbs.home"
			),
			to: "/tasks",
			isHome: true,
		},
	];

	if (activeBoard?.name) {
		routes.push(
			<Field.Select
				key="select-board"
				settings={{
					isClearable: true,
				}}
				onChange={(slug) => {
					navigate(slug ? `/tasks/boards/${slug}` : "/tasks");
				}}
				placeholder="Kies een bord"
				value={board || ""}
				options={boards.map((item) => ({
					value: item.slug,
					label: <div className="">{item.name}</div>,
				}))}
				theme={{
					value: {
						arrow: "caret",
					},
				}}
			/>
		);
	}

	return (
		<Layout
			{...{ container }}
			title={
				isBoardConfigured ? (
					<div className="flex items-center gap-4">
						{activeBoard?.icon && (
							<Icon
								icon={activeBoard.icon}
								className="bg-accent w-14 h-14 border border-border"
							/>
						)}
						<Text.Title className="">
							{activeTeam?.name ||
								activeBoard?.name ||
								t(
									tenant.slug === "getrecruitmentdone"
										? t("recruitment.title")
										: type === "extern"
										? `title-extern.${auth?.type}`
										: "title"
								)}
						</Text.Title>
					</div>
				) : null
			}
			breadcrumb={
				<div className="flex justify-between items-center gap-4">
					<Breadcrumb {...{ routes }} />
					{activeBoard && !activeBoard.is_static && (
						<Link
							className="text-border hover:text-background-foreground"
							to={`/tasks/boards/${activeBoard.slug}/edit`}
						>
							{t("edit-board")}
						</Link>
					)}
				</div>
			}
			head={
				isBoardConfigured
					? {
							className: "mb-0 border-b-0",
							children: <TasksLayoutHead />,
					  }
					: undefined
			}
		>
			{isBoardConfigured ? (
				children
			) : (
				<BoardMissingContext board={activeBoard} />
			)}
		</Layout>
	);
};

TasksLayout.locale = {
	nl: {
		title: "Taken",
		"title-extern": {
			company: "Opdrachten",
			student: "Aanmeldingen",
		},
		recruitment: {
			title: "Aanmeldingen",
		},

		breadcrumbs: {
			home: "Alle taken",
			"home-extern": "Alle opdrachten",
		},
		"edit-board": "Bord aanpassen",
	},
	en: {
		title: "Tasks",
		"title-extern": {
			company: "Tasks",
			student: "Registrations",
		},
		recruitment: {
			title: "Registrations",
		},
		breadcrumbs: {
			home: "All tasks",
			"home-extern": "All tasks",
		},
		"edit-board": "Edit board",
	},
};

export default withTranslation(TasksLayout);
