import Button from "src/components/Button";
import withTranslation, {
	Translation,
} from "src/components/hoc/withTranslation";
import SEO from "src/components/layout/SEO";

const NoPermissionView = ({ t }: Translation) => {
	return (
		<>
			<SEO title={t("title")} />
			<div className="flex flex-col flex-1 justify-center items-center">
				<div className="flex flex-1 flex-col justify-center max-w-md">
					<div className="flex flex-col gap-4 mb-8">
						<h1>{t("title")}</h1>
						<p>{t("description")}</p>
					</div>
					<div className="flex items-center gap-4">
						<Button to="/dashboard">
							{t("button")}
							<i className="far fa-arrow-right ml-2"></i>
						</Button>
					</div>
				</div>
			</div>
		</>
	);
};

NoPermissionView.locale = {
	nl: {
		title: "Geen toegang",
		description:
			"Je hebt niet genoeg rechten om deze pagina te bekijken, raadpleeg de administrator van jouw account om rechten toe te wijzen.",
		button: "Naar dashboard",
	},
	en: {
		title: "No access",
		description:
			"You do not have sufficient permissions to view this page, please consult your account administrator to assign permissions.",
		button: "To dashboard",
	},
};

export default withTranslation(NoPermissionView);
