import React from "react";
import { ApiLanguage } from "src/api/types";
import { cn } from "src/lib/utils";

const sizes = {
	default: "w-8",
};

interface LanguageIconProps {
	language: ApiLanguage;
	className?: string;
	size: keyof typeof sizes;
}

const LanguageIcon = ({ language, className, size }: LanguageIconProps) => {
	return (
		<img
			className={cn(`${sizes[size]} rounded-sm`, className)}
			src={language.icon}
			alt={language.code}
		/>
	);
};

LanguageIcon.defaultProps = {
	size: "default",
};

export default LanguageIcon;
