import { useEffect, useState } from "react";
import Button from "src/components/Button";
import CustomSelectedFilters from "src/components/custom-form/filters/CustomSelectedFilters";
import MultiSelectList from "src/components/form/Dropdown/MultiSelectList";
import SearchInput from "src/components/form/SearchInput";
import withTranslation, {
	Translation,
} from "src/components/hoc/withTranslation";
import FilterNetworkModal from "src/components/network/FilterNetworkModal";
import useConnectionCustomForm from "src/hooks/api/services/forms/useConnectionCustomForm";
import useConnectionFilter from "src/hooks/api/services/users/useConnectionFilter";
import useConnections from "src/hooks/api/services/users/useConnections";
import useAuth from "src/hooks/selectors/useAuth";
import { useUpdateEffectDebounce } from "src/hooks/useDebounce";
import Dropdown from ".";

interface ConnectionDropdownProps extends Translation {
	label?: string;
	placeholder?: string;
	onChange: (value?: number | string | any[]) => void;
	value?: number | any[];
	exclude?: any[];
	variant?: "dropdown" | "list";
}

const ConnectionDropdown = ({
	t,
	onChange,
	label,
	placeholder,
	value,
	exclude,
	variant = "dropdown",
}: ConnectionDropdownProps) => {
	const [filterNetworkVisisble, setFilterNetworkVisible] = useState(false);
	const [search, setSearch] = useState("");
	const auth = useAuth();
	const { forms, actions: customFormsActions } = useConnectionCustomForm({
		company_id: auth?.company?.id,
	});
	const { filter, setFilter } = useConnectionFilter();
	const { connections, status, actions, pagination } = useConnections({
		filter: {
			...filter,
			with: ["form", "forms", "fields", "values"],
			q: search,
			response_type: "option",
			sort: "popular",
			"exclude[]": exclude,
			limit: 6,
		},
		rememberKey: "NETWORK_CONNECTIONS_OPTIONS",
	});
	useUpdateEffectDebounce(filter, 1000, (update) => {
		if (auth.type === "company") {
			actions.list(update);
		}
	});

	const options = (connections || []).map((item: any) => ({
		id: item.id,
		name: item.name,
		avatar: item.avatar,
		rating: item?.reviews?.rating ? Number(item.reviews?.rating) : null,
	})) as any;

	useUpdateEffectDebounce(search, 500, (q) => {
		actions.list({ ...filter, q, page: 1 });
	});

	useEffect(() => {
		actions.list({ ...filter, page: 1 });
		customFormsActions.list();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	if (variant === "list") {
		return (
			<>
				{!!forms.length && (
					<>
						<FilterNetworkModal
							visible={filterNetworkVisisble}
							onClose={() => setFilterNetworkVisible(false)}
							{...{ filter }}
							onChange={(form_filters) => {
								setFilter({
									...filter,
									form_filters,
								});
							}}
						/>
					</>
				)}

				<div className="flex flex-col gap-4">
					<div className="flex items-center w-full gap-4">
						<div className="flex-1">
							<SearchInput
								className="w-full"
								inputClassName="w-full"
								theme="bordered"
								value={search}
								onChange={(value) => setSearch(value)}
								{...{ placeholder }}
							/>
						</div>
						{!!forms.length && (
							<Button
								type="border"
								onClick={() => setFilterNetworkVisible(true)}
								xsmall
							>
								{t("filter")}
							</Button>
						)}
					</div>
					{Object.keys(filter.form_filters || {}).length > 0 && (
						<CustomSelectedFilters
							filter={filter.form_filters}
							{...{ forms }}
						/>
					)}
					{connections.length === 0 && status === "idle" && (
						<div className="flex flex-col justify-center items-center p-8">
							<strong>{t("empty.title")}</strong>
							<p className="opacity-70">
								{t("empty.description")}
							</p>
						</div>
					)}
					{connections.length > 0 && (
						<MultiSelectList
							value={Array.isArray(value) ? value : []}
							button={{
								label: t("load-more"),
							}}
							settings={{
								showAvatar: true,
								showRating: true,
							}}
							loading={status !== "idle"}
							hasMore={pagination?.last_page > pagination?.page}
							{...{ options, onChange }}
							onNext={() => {
								actions.list(
									{
										...filter,
										page: pagination?.page + 1,
									},
									{ appendResult: true }
								);
							}}
						/>
					)}
				</div>
			</>
		);
	}

	return (
		<Dropdown
			hasAvatar
			onSearch={(q) => setSearch(q)}
			value={!Array.isArray(value) ? value : undefined}
			loading={status !== "idle"}
			{...{ onChange, placeholder, options, label }}
		/>
	);
};

ConnectionDropdown.locale = {
	nl: {
		"load-more": "Laad meer professionals",
		empty: {
			title: "Geen professionals gevonden",
			description:
				"Je hebt nog niet eerder met professionals samen gewerkt.",
		},
		filter: "Filter",
		filters: "Filters",
	},
	en: {
		"load-more": "Load more professionals",
		empty: {
			title: "No professionals found",
			description: "You haven't worked with professionals before.",
		},
		filter: "Filter",
		filters: "Filters",
	},
};

export default withTranslation(ConnectionDropdown);
