import { useEffect, useState } from "react";
import * as Yup from "yup";
import useTaskForm from "src/hooks/api/tasks/useTaskForm";
import moment from "moment";
import useTaskScheme from "src/hooks/schemes/useTaskScheme";
import { useTranslation } from "react-i18next";

function useValidateTask() {
	const _scheme: any = useTaskScheme();
	const { t } = useTranslation("schemes");

	const { task } = useTaskForm();
	if (task?.subtasks?.length > 0) {
		delete _scheme.budget;
		delete _scheme.amount_of_students;
	}
	if (task.budget_per_hour) {
		delete _scheme.budget;
		_scheme.estimated_duration = Yup.string().required(t("required"));
	} else {
		delete _scheme.budget_per_hour;
	}
	const Schema = Yup.object().shape(_scheme);

	const [isValid, setIsValid] = useState(false);
	const [error, setError] = useState<any>();

	useEffect(() => {
		const validate = async () => {
			try {
				if (task.start_date)
					task.start_date = moment(task.start_date).toDate();
				if (task.end_date)
					task.end_date = moment(task.end_date).toDate();

				if (!task.end_date) {
					delete task.end_date;
				}

				if (task.budget) task.budget = parseFloat(task.budget);
				if (task.budget_per_hour)
					task.budget_per_hour = parseFloat(task.budget_per_hour);

				if (task.category?.id) {
					task.category_id = task.category.id;
					delete task.category;
				}

				await Schema.validate(task, {
					strict: true,
				});
				setIsValid(true);
			} catch (error) {
				setError(error);
				// console.log(error);
				setIsValid(false);
			}
		};
		validate();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [task]);

	return {
		error,
		isValid,
	};
}

export default useValidateTask;
