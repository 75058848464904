import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ApiSignature, LayoutVariants } from "src/api/types";
import Modal from "src/components/Modal";
import SignatureCanvas from "src/components/signer/signature/SignatureCanvas";
import SignatureGrid from "src/components/signer/signature/SignatureGrid";
import useSignature from "src/hooks/selectors/useSignature";

interface SignatureModalProps {
	variant: LayoutVariants;
	children: (onClick: () => void) => void;
	onSubmit: (signature: ApiSignature) => void;
}

const SignatureModal = ({
	children,
	variant,
	onSubmit,
}: SignatureModalProps) => {
	const { t } = useTranslation("envelope", {
		keyPrefix: "signature-modal",
	});
	const tabs = [t("tabs.select"), t("tabs.create")];
	const [visible, setVisible] = useState(false);
	const { signature, loading } = useSignature();
	const [selected, setSelected] = useState(signature?.id ? tabs[0] : tabs[1]);

	useEffect(() => {
		setSelected(signature?.id ? tabs[0] : tabs[1]);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [loading]);

	const handleSubmit = () => {
		setSelected(tabs[0]);
	};

	return (
		<>
			{children(() => setVisible(!visible))}
			<Modal
				title={t("title")}
				{...{ variant }}
				onClose={() => {
					setVisible(false);
				}}
				{...{ visible }}
			>
				<Modal.Tabs {...{ variant, tabs, selected, setSelected }}>
					{(tab: string) => {
						if (tab === t("tabs.select")) {
							return (
								<SignatureGrid
									{...{ variant }}
									onDirectSubmit={() => onSubmit(signature)}
									onSubmit={() => setVisible(false)}
								/>
							);
						}

						return (
							<SignatureCanvas
								onSubmit={handleSubmit}
								{...{ variant }}
							/>
						);
					}}
				</Modal.Tabs>
			</Modal>
		</>
	);
};

export default SignatureModal;
