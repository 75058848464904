import { Route } from "react-router-dom";
import EnableTenantAccessView from "src/views/global/EnableTenantAccessView";
import NotActivatedView from "src/views/global/NotActivatedView";

const GlobalRoutes = [
	<Route
		element={<EnableTenantAccessView />}
		path="/enable-access"
		key="/enable-access"
	/>,
	<Route
		element={<NotActivatedView />}
		path="/not-activated"
		key="/not-activated"
	/>,
];

export default GlobalRoutes;
