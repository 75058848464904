const auth = {
	"blocked-account": {
		title: "Je account is geblokkeerd",
		description:
			"Door verdachte activiteiten is je account ({{email}}) geblokkerd, neem contact op met <3>support@{{tenant.slug}}.nl</3>",
		button: "Uitloggen",
	},
	login: {
		"already-signed-in": {
			title: "Je bent al ingelogd",
			button: "Naar dashboard",
		},
		or: "Of",
		title: "Inloggen",
		"no-account": "Nog geen account? Maak deze gemakkelijk aan.",
	},
	"not-found": {
		title: "De pagina lijkt niet meer te bestaan",
		description:
			"Het lijkt erop dat deze pagina niet meer bestaat. Gebruik de onderstaande knoppen om weer op de website te komen",
		buttons: {
			login: "Inloggen",
			home: "Naar home pagina",
			dashboard: "Naar mijn dashboard",
		},
	},
};

export default auth;
