import { ApiForm } from "src/api/types";
import useApi from "src/hooks/api/utils/useApi";

type Options = {
	company_id: string | number;
	filter?: any;
};

function usePaymentRequestCustomForms(options: Options) {
	const { state, actions, api } = useApi(
		{ id: `PAYMENT_REQUEST_FORMS_${options.company_id}` },
		{
			baseUrl: `/client/forms`,
			defaultStatus: "mounted",
			query: {
				...(options?.filter || {}),
				type: "PAYMENT_REQUEST",
				with: ["fields"],
				company_id: options?.company_id,
			},
		}
	);
	const forms: ApiForm[] = state?.list || [];

	return {
		forms,
		filter: state?.filter,
		status: state.status,
		actions,
		api,
	};
}

export default usePaymentRequestCustomForms;
