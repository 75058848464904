import { useTranslation } from "react-i18next";
import { ApiLanguage } from "src/api/types";
import DropdownButton, {
	DropdownButtonPosition,
} from "src/components/DropdownButton";
import useLanguage from "src/hooks/api/useLanguage";
import { cn } from "src/lib/utils";

const themes = {
	light: {
		icon: "text-white",
	},
	dark: {
		icon: "text-background-foreground",
	},
	transparent: {
		icon: "",
	},
};

interface LanguageSelectorProps {
	className?: string;
	theme: keyof typeof themes;
	position: DropdownButtonPosition;
}

const LanguageSelector = ({
	className,
	position,
	theme: _theme,
}: LanguageSelectorProps) => {
	const { t } = useTranslation("common", {
		keyPrefix: "translation",
	});
	const { language, actions, current } = useLanguage();
	// const auth = useAuth();
	// const [selected, setSelected] = useState(auth?.language?.code);
	const languages = language?.languages || [];
	const currentLanguage = languages.find(
		(item: ApiLanguage) =>
			item?.code.toLowerCase() === current.toLowerCase()
	);

	const theme = themes[_theme];

	return (
		<div className={`${className || ""}`}>
			<DropdownButton
				menu={{
					position,
				}}
				button={{
					iconOnly: true,
					children: (
						<img
							alt={currentLanguage?.code}
							src={currentLanguage?.icon}
							className={`${theme?.icon} w-5 rounded-sm`}
						/>
					),
					type: `${_theme}-outline`,
					className: cn(
						"w-10 h-10",
						_theme === "light" && "border-light",
						_theme === "dark" && "border-border"
					),
				}}
			>
				{languages.map((language: any) => (
					<DropdownButton.Item
						onClick={(close) => {
							actions.update(language?.code as any);
							close();
						}}
						className="text-background-foreground"
						activeClassName="font-bold"
						key={`language-selector-${language.code}`}
						locale={language.code.toLowerCase()}
					>
						<div className="flex items-center gap-2">
							<div className="w-5 rounded-sm overflow-hidden">
								<img
									className="w-5 block rounded-sm overflow-hidden"
									src={language.icon}
									alt={language.label}
								/>
							</div>
							{t(language.code)}
						</div>
					</DropdownButton.Item>
				))}
			</DropdownButton>
		</div>
	);
};

LanguageSelector.defaultProps = {
	position: "bottom-right",
};

export default LanguageSelector;
