import { useEffect, useState } from "react";

type Breakpoint = "xs" | "sm" | "md" | "lg" | "xl";

const getBreakpoint = (width: number): Breakpoint => {
	if (width >= 1280) {
		return "xl";
	} else if (width >= 1024) {
		return "lg";
	} else if (width >= 768) {
		return "md";
	} else if (width >= 640) {
		return "sm";
	}
	return "xs";
};

export default function useWindowSize() {
	const [size, setWindowSize] = useState({
		width: window?.innerWidth || 0,
		height: window.innerHeight || 0,
	});

	const [breakpoint, setBreakpoint] = useState<Breakpoint>(
		getBreakpoint(window?.innerWidth || 0)
	);

	// eslint-disable-next-line react-hooks/rules-of-hooks
	useEffect(() => {
		const _setWindowSize = () => {
			setWindowSize({
				width: window?.innerWidth || 0,
				height: window.innerHeight,
			});
			setBreakpoint(getBreakpoint(window?.innerWidth || 0));
		};
		window.addEventListener("resize", _setWindowSize);
		return () => {
			window.removeEventListener("resize", _setWindowSize);
		};
	}, []);

	return {
		...size,
		breakpoint,
		isTablet: size.width <= 1024,
		isPhone: size.width <= 599,
		isDesktop: size.width >= 1324,
	};
}
