import moment from "moment";
import Button from "src/components/Button";
import Card from "src/components/Card";
import withTranslation, {
	Translation,
} from "src/components/hoc/withTranslation";
import Loader from "src/components/Loader";
import useGet from "src/hooks/rest/useGet";
import { formatPrice } from "src/lib/formatters";

interface PauseSubscriptionMiddlewareProps extends Translation {
	children?: any;
}

const PauseSubscriptionMiddleware = ({
	t,
	children,
}: PauseSubscriptionMiddlewareProps) => {
	const [{ data: payments }, loading] = useGet(
		"/auth/subscriptions/plans/pause/validate"
	);

	const payouts = (payments || []).filter((payment: any) => payment.task);
	const revenuePayments = (payments || []).filter(
		(payment: any) => !payment.task
	);

	if (loading) {
		return (
			<div className="flex flex-col justify-center items-center flex-1">
				<Loader />
			</div>
		);
	}

	if (payments.length && !loading) {
		return (
			<Card className="w-full max-w-md">
				{payouts.length > 0 && (
					<div className="flex flex-col gap-4">
						<div className="flex flex-col">
							<strong>{t("payouts.title")}</strong>
							<p className="opacity-70">
								{t("payouts.description")}
							</p>
						</div>

						<ul className="flex flex-col gap-2">
							{payouts.map((payment: any) => (
								<li
									key={`${payment.hid}-${payment.type}`}
									className="p-3 rounded-md border border-border flex flex-col md:flex-row md:justify-between md:items-center gap-3"
								>
									<div className="flex flex-col flex-1">
										<strong>{payment.task.title}</strong>
										<p className="opacity-70">
											{formatPrice(payment.total)}
										</p>
									</div>
									{payment?.task?.hid &&
										payment?.registration?.hid && (
											<Button
												xsmall
												to={`/tasks/${payment.task.hid}/show/${payment.registration.hid}`}
											>
												{t("actions.view")}
											</Button>
										)}
								</li>
							))}
						</ul>
					</div>
				)}
				{revenuePayments.length > 0 && (
					<div className="flex flex-col gap-4">
						<div className="flex flex-col">
							<strong>{t("revenue-payments.title")}</strong>
							<p className="opacity-70">
								{t("revenue-payments.description")}
							</p>
						</div>

						<ul className="flex flex-col gap-2">
							{revenuePayments.map((payment: any) => (
								<li
									key={`${payment.hid}-${payment.type}`}
									className="p-3 rounded-md border border-border flex flex-col md:flex-row md:justify-between md:items-center gap-3"
								>
									<div className="flex flex-col flex-1">
										<strong>
											{payment.invoice_number}
										</strong>
										<div className="flex items-center gap-3">
											<p className="opacity-70">
												{formatPrice(payment.total)}
											</p>
											{payment.invoice_date && (
												<p className="opacity-70">
													{moment(
														payment.invoice_date
													).format("DD-MM-YYYY")}
												</p>
											)}
										</div>
									</div>
									{payment?.hid && (
										<Button
											xsmall
											target="_blank"
											to={`/payments/${payment.hid}`}
										>
											{t("actions.view")}
										</Button>
									)}
								</li>
							))}
						</ul>
					</div>
				)}
			</Card>
		);
	}

	return children;
};

PauseSubscriptionMiddleware.locale = {
	nl: {
		payouts: {
			title: "Je hebt nog openstaande matches",
			description:
				"Voor je je abonnement kan pauzeren moet je alle opdrachten afronden.",
		},
		"revenue-payments": {
			title: "Je hebt nog openstaande facturen",
			description:
				"Helaas zijn er nog openstaande facturen, voordat je je abonnement kan pauzeren dienen deze te worden betaald.",
		},
		"invoice-number": "Factuurnummer: {{invoice_number}}",
		actions: {
			view: "Bekijk opdracht",
		},
	},
	en: {
		payouts: {
			title: "You have pending matches",
			description:
				"You can pause your subscription to finish all your tasks.",
		},
		"revenue-payments": {
			title: "You have pending invoices",
			description:
				"Unfortunately there are still pending invoices, before you can pause your subscription these must be paid.",
		},
		"invoice-number": "Invoice number: {{invoice_number}}",
		actions: {
			view: "View task",
		},
	},
};

export default withTranslation(PauseSubscriptionMiddleware);
