import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Button from "src/components/Button";
import Loader from "src/components/Loader";
import Field from "src/components/field/Field";
import Permissions, {
	PermissionsLoading,
} from "src/components/forms/auth/Permissions";
import withTranslation, {
	Translation,
} from "src/components/hoc/withTranslation";
import Tab from "src/components/layout/Tab";
import Tabs from "src/components/layout/Tabs";
import ShareClipboard from "src/components/promotion/share/ShareClipboard";
import usePermissions from "src/hooks/api/services/auth/usePermissions";
import useUsers from "src/hooks/api/services/users/useUsers";
import useAuth from "src/hooks/selectors/useAuth";
import useModal from "src/hooks/useModal";
import useQueryString from "src/hooks/useQueryString";

const EditUserModal = ({ t }: Translation) => {
	const modal = useModal();
	const { id } = useParams();
	const qs = useQueryString();
	const auth = useAuth();
	const { users, status, actions } = useUsers();
	const { actions: permissionActions, status: permissionStatus } =
		usePermissions();
	const [permissions, setPermissions] = useState<string[]>([]);
	const [activeTab, setActiveTab] = useState<"general" | "permissions">(
		"general"
	);
	const user = users.find((item) => item.hid === id);
	const [form, setForm] = useState({
		teams: user?.teams || [],
	});

	useEffect(() => {
		if (activeTab === "permissions" && id) {
			permissionActions.getByUser(id).then(({ data }) => {
				setPermissions(data);
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [activeTab]);

	useEffect(() => {
		if (user?.id) {
			modal.setTitle(user.full_name);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const onSubmit = async (e?: any) => {
		if (e) e.preventDefault();
		if (activeTab === "general") {
			await actions.update(
				{
					id,
					...form,
				},
				{
					updateList: true,
				}
			);
			modal.onClose();
		}

		if (activeTab === "permissions" && id) {
			await permissionActions.updateUser(id, permissions);
			modal.onClose();
		}
	};

	const onActivate = async () => {
		await actions.update(
			{
				id,
				activate: true,
			},
			{
				updateList: true,
			}
		);
	};

	const onDeactivate = async () => {
		await actions.update(
			{
				id,
				deactivate: true,
			},
			{
				updateList: true,
			}
		);
		modal.onClose();
	};

	const onDelete = async (e?: any) => {
		if (e) e.preventDefault();
		if (id) {
			await actions.delete(id);
			modal.onClose();
		}
	};

	if (status === "loading") {
		return (
			<div className="flex justify-center items-center p-4">
				<Loader />
			</div>
		);
	}

	if (!user?.id) {
		return (
			<div className="flex flex-col gap-2">
				<strong>{t("not-found.title")}</strong>
			</div>
		);
	}

	if (qs.type === "delete") {
		return (
			<div className="">
				<Button
					onClick={onDelete}
					disabled={status !== "idle"}
					loading={status !== "idle"}
					className="w-full"
					type="red"
				>
					{t("buttons.delete")}
				</Button>
			</div>
		);
	}

	if (
		user.status === "INACTIVE" &&
		auth.permissions.includes("permissions.edit")
	) {
		return (
			<div className="w-full flex flex-col gap-2">
				<h2>{t("activate.title")}</h2>
				<p>{t("activate.description")}</p>
				<Button
					onClick={onActivate}
					disabled={status !== "idle"}
					loading={status !== "idle"}
					className="my-4"
				>
					{t("activate.button")}
				</Button>
				<p className="text-sm opacity-50">{t("activate.disclaimer")}</p>
			</div>
		);
	}

	return (
		<form {...{ onSubmit }} className="w-full flex flex-col">
			{auth.permissions.includes("permissions.edit") && (
				<Tabs.Container className="bg-accent w-full mb-8">
					<Tab
						color="white"
						active={activeTab === "general"}
						onClick={() => setActiveTab("general")}
						className="flex-1"
					>
						{t("tabs.general")}
					</Tab>
					<Tab
						color="white"
						active={activeTab === "permissions"}
						onClick={() => setActiveTab("permissions")}
						className="flex-1"
					>
						{t("tabs.permissions")}
					</Tab>
				</Tabs.Container>
			)}

			{user?.invite && (
				<div className="flex flex-col mb-8">
					<strong>{t("invite.label")}</strong>
					<ShareClipboard url={user.invite.url}></ShareClipboard>
					<small className="mt-2 opacity-70">
						{t("invite.disclaimer", {
							name: user.first_name,
						})}
					</small>
				</div>
			)}

			{activeTab === "general" && (
				<>
					<Field.Team
						wrapper={{
							label: t("fields.team.label"),
							layout: "horizontal",
						}}
						value={form.teams}
						onChange={(teams) =>
							setForm((state: any) => ({
								...state,
								teams,
							}))
						}
					/>

					<div className="mt-8 flex flex-col gap-2">
						<Button
							disabled={status !== "idle"}
							loading={status !== "idle"}
							className="w-full"
							submit
							type="dark"
						>
							{t("buttons.save")}
						</Button>
						{auth.permissions.includes("permissions.edit") &&
							auth.id !== user.id && (
								<Button
									onClick={onDeactivate}
									disabled={status !== "idle"}
									loading={status !== "idle"}
									type="ghost"
								>
									{t("buttons.deactivate")}
								</Button>
							)}
					</div>
				</>
			)}

			{activeTab === "permissions" &&
				auth.permissions.includes("permissions.edit") && (
					<>
						{["loading", "mounted"].includes(permissionStatus) ? (
							<PermissionsLoading />
						) : (
							<Permissions
								value={permissions}
								onChange={(value) => setPermissions(value)}
							/>
						)}

						<div className="mt-8 flex flex-col gap-2">
							<Button
								disabled={permissionStatus !== "idle"}
								loading={permissionStatus !== "idle"}
								className="w-full"
								submit
								type="dark"
							>
								{t("buttons.save")}
							</Button>
						</div>
					</>
				)}
		</form>
	);
};

EditUserModal.locale = {
	nl: {
		invite: {
			label: "Aanmeld link",
			disclaimer: "{{name}} kan zich met bovenstaande link aanmelden",
		},
		"not-found": {
			title: "Geen gebruiker gevonden",
		},
		fields: {
			team: {
				label: "Team",
			},
		},
		activate: {
			title: "Activeer licentie",
			description:
				"Gebruik de onderstaande knop om het account weer te activeren.",
			button: "Activeer",
			disclaimer: "Het abonnement wordt aangepast",
		},
		tabs: {
			general: "Algemeen",
			permissions: "Rechten",
		},
		buttons: {
			save: "Opslaan",
			delete: "Verwijderen",
			deactivate: "Deactiveren",
		},
	},
	en: {
		invite: {
			label: "Invite url",
			disclaimer: "{{name}} can signup via the link above",
		},
		"not-found": {
			title: "No user found",
		},
		fields: {
			team: {
				label: "Team",
			},
		},
		activate: {
			title: "Activate license",
			description: "Use the button below to activate the user license",
			button: "Activate",
			disclaimer: "The subscribtion will be changed",
		},
		tabs: {
			general: "General",
			permissions: "Permissions",
		},
		buttons: {
			save: "Save",
			delete: "Delete",
			deactivate: "Deactivate",
		},
	},
};

export default withTranslation(EditUserModal);
