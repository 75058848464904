import ChatRoutes from "src/routes/authenticated/chat";
import DashboardRoutes, {
	DashboardModalRoutes,
} from "src/routes/authenticated/dashboard";
import EnvelopeRoutes from "src/routes/authenticated/envelopes";
import LanguageRoutes from "src/routes/authenticated/language";
import NetworkRoutes, {
	NetworkModalRoutes,
} from "src/routes/authenticated/network";
import OffersRoutes from "src/routes/authenticated/offers";
import PaymentsRoutes from "src/routes/authenticated/payments";
import ProfileRoutes, {
	ProfileModalRoutes,
} from "src/routes/authenticated/profile";
// import ReferralRoutes from "src/routes/admin/referral";
import { Routes as ReactRoutes, Route, useLocation } from "react-router-dom";
import AuthRoutes from "src/routes/auth";
import SignupRoutes from "src/routes/auth/signup";
import AnalyticsRoutes from "src/routes/authenticated/analytics";
import BenefitRoutes, {
	BenefitModalRoutes,
} from "src/routes/authenticated/benefits";
import FeedbackRoutes, {
	FeedbackModalRoutes,
} from "src/routes/authenticated/feedback";
import GlobalModalRoutes from "src/routes/authenticated/global";
import HoursRoutes from "src/routes/authenticated/hours";
import OnboardingRoutes, {
	OnboardingModalRoutes,
} from "src/routes/authenticated/onboarding";
import PaymentRequestsRoutes, {
	PaymentRequestsModalRoutes,
} from "src/routes/authenticated/payment-requests";
import ProductsRoutes from "src/routes/authenticated/products";
import ReferralsRoutes, {
	ReferralsModalRoutes,
} from "src/routes/authenticated/referrals";
import ReviewsRoutes from "src/routes/authenticated/reviews";
import SessionRoutes, {
	SessionModalRoutes,
} from "src/routes/authenticated/session";
import SettingsRoutes, {
	SettingsModalRoutes,
} from "src/routes/authenticated/settings";
import SetupRoutes from "src/routes/authenticated/setup";
import SignerRoutes from "src/routes/authenticated/signer";
import SubscriptionRoutes, {
	SubscriptionModalRoutes,
} from "src/routes/authenticated/subscription";
import TalentsRoutes from "src/routes/authenticated/talents";
import TasksRoutes, { TasksModalRoutes } from "src/routes/authenticated/tasks";
import AuthenticatedToolRoutes from "src/routes/authenticated/tools";
import VacanciesRoutes, {
	VacanciesModalRoutes,
} from "src/routes/authenticated/vacancies";
import GlobalRoutes from "src/routes/global";
import PublicToolRoutes from "src/routes/global/tools";
import ProtectedRoute from "src/routes/middlewares/ProtectedRoute";
import NotFoundView from "src/views/auth/NotFoundView";
import DebugView from "src/views/authenticated/DebugView";
import WaitlistView from "src/views/authenticated/WaitlistView";
import ProjectsRoutes, { ProjectsModalRoutes } from "./authenticated/projects";

function findPath(basePath: string, paths: string[] = []): string | null {
	const basePathSegments = basePath.split("/");

	for (const path of paths) {
		const pathSegments = path.split("/");

		if (basePathSegments.length === pathSegments.length) {
			let isMatch = true;

			for (let i = 0; i < basePathSegments.length; i++) {
				const basePathSegment = basePathSegments[i];
				const pathSegment = pathSegments[i];

				if (
					basePathSegment !== pathSegment &&
					!pathSegment.startsWith(":")
				) {
					isMatch = false;
					break;
				}
			}

			if (isMatch) {
				return path;
			}
		}
	}

	return null;
}

interface RoutesProps {
	children?: any;
}

const Routes = ({ children }: RoutesProps) => {
	const ROUTES = [
		...GlobalRoutes,
		...AuthRoutes,
		...SignupRoutes,
		...ChatRoutes,
		...DashboardRoutes,
		...EnvelopeRoutes,
		...NetworkRoutes,
		...PaymentsRoutes,
		...ProfileRoutes,
		...ReviewsRoutes,
		...SettingsRoutes,
		...SubscriptionRoutes,
		...TasksRoutes,
		...SignerRoutes,
		...OffersRoutes,
		...TalentsRoutes,
		...LanguageRoutes,
		...HoursRoutes(),
		...FeedbackRoutes,
		...ReferralsRoutes,
		...ProjectsRoutes,
		...AnalyticsRoutes,
		...OnboardingRoutes,
		...SetupRoutes,
		...PublicToolRoutes,
		...PaymentRequestsRoutes,
		...SessionRoutes,
		...AuthenticatedToolRoutes,
		...VacanciesRoutes,
		...ProductsRoutes,
		...BenefitRoutes,
	];
	const location = useLocation() as any;
	const background = location.state ? location?.state?.background : false;
	const paths = ROUTES.map((route) => route.props.path);
	const getPrefix = () => {
		const pathname = background.pathname.replace(/\/$/, "");
		return findPath(background.pathname, paths) || pathname;
	};
	return (
		<>
			<ReactRoutes location={background || location}>
				{ROUTES}
				<Route
					element={
						<ProtectedRoute>
							<WaitlistView />
						</ProtectedRoute>
					}
					path="/waitlist"
				/>

				{process.env.NODE_ENV === "development" && (
					<Route
						element={
							<ProtectedRoute>
								<DebugView />
							</ProtectedRoute>
						}
						path="/debug"
					/>
				)}
				<Route path="*" key="not-found" element={<NotFoundView />} />
			</ReactRoutes>
			{children}
			{background && (
				<ReactRoutes>
					{GlobalModalRoutes(getPrefix())}
					{DashboardModalRoutes}
					{TasksModalRoutes}
					{ProjectsModalRoutes}
					{ProfileModalRoutes}
					{FeedbackModalRoutes}
					{SubscriptionModalRoutes}
					{ReferralsModalRoutes}
					{PaymentRequestsModalRoutes}
					{SessionModalRoutes}
					{SettingsModalRoutes}
					{NetworkModalRoutes}
					{OnboardingModalRoutes}
					{VacanciesModalRoutes}
					{BenefitModalRoutes}
				</ReactRoutes>
			)}
		</>
	);
};

export default Routes;
