import { ApiUserMinimal } from "src/api/types";
import useLanguage from "src/hooks/api/useLanguage";
import useApi from "src/hooks/api/utils/useApi";
import useAuth from "src/hooks/selectors/useAuth";

type Options = {
	filter?: any;
};

function useUsers(options?: Options) {
	const query = {
		exclude: ["badges"],
		with: ["teams", "status"],
		...(options?.filter || {}),
	};
	const { state, actions, api } = useApi(
		{ id: "USERS" },
		{
			baseUrl: `/client/users`,
			query,
		}
	);
	const auth = useAuth();
	const { language } = useLanguage();
	const me: ApiUserMinimal = {
		id: "me",
		hid: "me",
		avatar: "me",
		full_name: language?.current === "en" ? "Me" : "Ik",
		first_name: language?.current === "en" ? "Me" : "Ik",
		type: auth.type,
		has_merchant: false,
		teams: [],
		created_at: "",
		status: "ACTIVE",
	};
	const users: ApiUserMinimal[] = state?.list || [];

	const invite = async (data: any) => {
		return api
			.post("/invite", data, {
				params: query,
			})
			.then(({ data }) => {
				const user = data?.data;

				actions.set((state: any) => ({
					...state,
					list: [...(state?.list || []), user],
				}));
			});
	};

	return {
		users: users.map((item) => {
			if (
				auth?.id &&
				(item.id === auth.id || item.id === auth?.id?.toString())
			) {
				return {
					...item,
					avatar: me.avatar,
					full_name: me.full_name,
					first_name: me.first_name,
				};
			}
			return item;
		}),
		usersRaw: users,
		me,
		status: state.status,
		actions: {
			...actions,
			invite,
		},
		api,
	};
}

export default useUsers;
