import React from "react";

interface DotsProps {
	steps: String[];
	current: number;
	className?: string;
}

const Dots = ({ steps, current, className }: DotsProps) => {
	return (
		<div
			className={`flex justify-center items-center gap-4 ${
				className || ""
			}`}
		>
			{steps.map((step, index) => (
				<div
					key={`step-${step}`}
					className={`w-2 h-2 rounded-full ${
						index === current ? "bg-primary" : "bg-accent"
					}`}
				></div>
			))}
		</div>
	);
};

export default Dots;
