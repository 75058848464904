import { useEffect } from "react";
import FlatList from "src/components/FlatList";
import Loader from "src/components/Loader";
import AlertListItem from "src/components/alerts/AlertListItem";
import withTranslation, {
	Translation,
} from "src/components/hoc/withTranslation";
import Tab from "src/components/layout/Tab";
import Tabs from "src/components/layout/Tabs";
import useAlert from "src/hooks/api/services/useAlert";

interface AlertListProps extends Translation {}

const AlertList = ({ t }: AlertListProps) => {
	const { alerts, actions, pagination, status, filter } = useAlert();

	useEffect(() => {
		if (status === "idle") {
			actions.list();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<div className="flex flex-col gap-2">
			<div className="px-6 -mx-1">
				<Tabs.Container>
					<Tab
						onClick={() =>
							filter.state !== "not-viewed" &&
							actions.list({ state: "not-viewed" })
						}
						active={filter.state === "not-viewed"}
					>
						{t("tabs.not-viewed")}
					</Tab>
					<Tab
						onClick={() =>
							filter.state !== "all" &&
							actions.list({ state: "all" })
						}
						active={filter.state === "all"}
					>
						{t("tabs.all")}
					</Tab>
				</Tabs.Container>
			</div>
			{status === "loading" && !alerts.length ? (
				<div className="flex justify-center items-center py-16 p-6">
					<Loader />
				</div>
			) : (
				<FlatList
					variant="flat"
					empty={{
						title: t("empty.title"),
						text: t("empty.text"),
					}}
					paginationType="button"
					data={alerts}
					{...{ pagination }}
					renderItem={(alert) => (
						<AlertListItem
							key={`alert-${alert.id}`}
							{...{ alert }}
						/>
					)}
				/>
			)}
		</div>
	);
};

AlertList.locale = {
	nl: {
		tabs: {
			all: "Alles",
			"not-viewed": "Ongelezen",
		},
		empty: {
			title: "Je bent up to date",
			text: "Geen notificaties gevonden",
		},
	},
	en: {
		tabs: {
			all: "All",
			"not-viewed": "Inbox",
		},
		empty: {
			title: "You are up to date",
			text: "No notifications found",
		},
	},
};

export default withTranslation(AlertList);
