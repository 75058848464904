import { AxiosInstance } from "axios";
import { atom } from "jotai";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import useLogin from "src/hooks/api/services/auth/useLogin";
import useSignupReset from "src/hooks/api/services/auth/useSignupReset";
import useSignupTracking from "src/hooks/api/services/auth/useSignupTracking";
import useLanguage from "src/hooks/api/useLanguage";
import useApi, {
	ApiActions,
	ApiStatus,
	Pagination,
} from "src/hooks/api/utils/useApi";
import useForm from "src/hooks/schemes/useForm";
// import mixpanel from "src/lib/mixpanel";
import { usePostHog } from "posthog-js/react";
import { useParams } from "react-router-dom";
import useTenant from "src/hooks/api/services/tenants/useTenant";

interface SignupActions extends ApiActions {
	getField: (name: string) => any;
	setForm: any;
	signup: (data?: any) => Promise<any>;
	validateField: (name: string) => void;
	getInvitation: () => Promise<any>;
}

type State = {
	user: any;
	invite?: any;
	form: any;
	validate: any;
	status: ApiStatus;
	actions: SignupActions;
	api: AxiosInstance;
	pagination: Pagination;
};

const formAtom: any = atom({});
const validateAtom: any = atom({});

type Options = {
	type:
		| "later"
		| "student"
		| "company"
		| "company-invite"
		| "company-minimal"
		| "company-ai";
	filter?: any;
	defaultValue?: any;
	inviteId?: string;
};

function useSignup(options: Options): State {
	const params = useParams();
	const resetState = useSignupReset();
	const { t } = useTranslation("schemes");
	const trackingData = useSignupTracking();
	const posthog = usePostHog();
	const { language } = useLanguage();
	const { actions: tenantActions } = useTenant();
	const {
		actions: { authenticate },
	} = useLogin({
		redirectUrl: ["company", "company-minimal", "later"].includes(
			options.type
		)
			? "/onboarding"
			: undefined,
	});
	const urls = {
		later: "/auth/signup?is_type_later=true",
		student: "/auth/signup",
		company: "/auth/signup/company",
		"company-ai": "/auth/signup/company/minimal",
		"company-minimal": "/auth/signup/company/minimal",
		"company-invite": `/company-invite/${options?.inviteId}`,
	};

	const { state, actions, api, pagination } = useApi(
		{ id: "SIGNUP" },
		{
			baseUrl: urls[options?.type],
			query: {
				...(options?.filter || {}),
			},
		}
	);
	const {
		form,
		actions: { setForm, getField, setErrors, validateField, resetForm },
		validate,
	} = useForm({
		atoms: {
			form: formAtom,
			validate: validateAtom,
		},
		scheme: {
			first_name: ["string", "required"],
			last_name: ["string", "required"],
			email: ["string", "email", "required"],
			password: ["string", "password", "required"],
			telephone: [
				"company-minimal",
				"later",
				"company-ai",
				"student",
			].includes(options.type)
				? ["string", "telephone"]
				: ["string", "telephone", "required"],
			...(options.type === "company-ai"
				? {
						company_name: ["string", "required"],
				  }
				: {}),
			...(options.type === "company"
				? {
						company_name: ["string", "required"],
						job_title: ["string", "required"],
				  }
				: {}),
			...(options.type === "company-invite"
				? {
						job_title: ["string", "required"],
				  }
				: {}),
		},
	});

	useEffect(() => {
		if (
			options?.defaultValue &&
			Object.keys(options?.defaultValue).length
		) {
			setForm(options.defaultValue, false);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const getInvitation = async () => {
		actions.set((state: any) => ({
			...state,
			status: "loading",
		}));

		try {
			const { data } = await api.get(`/check`).then(({ data }) => data);

			if (data?.email) {
				setForm(
					Object.fromEntries(
						Object.entries({
							first_name: data?.first_name,
							last_name: data?.last_name,
							email: data?.email,
							telephone: data?.telephone,
							job_title: data?.job_title,
						}).filter(([_, v]) => v != null)
					),
					false
				);
			}

			actions.set((state: any) => ({
				...state,
				status: "idle",
				custom: {
					invite: data,
				},
			}));

			return data;
		} catch (error) {
			throw error;
		}
	};

	const signup = async (data?: any) => {
		if (!validate.isValid) return;
		actions.set((state: any) => ({
			...state,
			status: "creating",
		}));

		if (trackingData?.referral_type && data?.company) {
			data.company.referral_type = trackingData.referral_type;
		}
		const formData = {
			...form,
			...(data || {}),
			...trackingData,
			locale: language.current,
		};

		if (params.session) {
			formData.session_id = params.session;
		}

		try {
			const user = await (options.type === "company-invite"
				? api.post(`/redeem`, formData).then(({ data }) => data?.data)
				: actions.create(formData));

			await authenticate(user);
			await tenantActions.current();
			resetState();
			resetForm();

			if (window && window.dataLayer) {
				if (user.type === "company") {
					window.dataLayer.push({
						event: "CompanyCreated",
						name: user?.company?.name,
					});
				}
				if (user.type === "student") {
					window.dataLayer.push({
						event: "StudentCreated",
						id: user?.id,
					});
				}
			}
			if (user?.id) {
				// posthog?.identify(user.id, {
				// 	email: user?.email,
				// });
				posthog?.capture("USER_SIGNUP", {
					type: user.type,
				});
				// mixpanel.identify(user.id);
				// mixpanel.track("USER_SIGNUP", {
				// 	type: user.type,
				// });
			}

			actions.set((state: any) => ({
				...state,
				status: "idle",
			}));
		} catch (error: any) {
			if (error?.errors?.in_use || error?.errors?.email) {
				setErrors({
					email: t("email-in-use"),
				});
			}
			if (error?.errors?.kvk_in_use) {
				if (options.type === "company-minimal") {
					setErrors({
						company_name: t("kvk-in-use"),
					});
				} else {
					setErrors({
						kvk: t("kvk-in-use"),
					});
				}
			}
			actions.set((state: any) => ({
				...state,
				status: "idle",
			}));
		}
	};

	return {
		user: state?.data || [],
		invite: state?.custom?.invite,
		form,
		validate,
		status: state.status,
		pagination,
		actions: {
			...actions,
			getField,
			validateField,
			setForm,
			signup,
			getInvitation,
		},
		api,
	};
}

export default useSignup;
