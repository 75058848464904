import { atom, useAtom } from "jotai";
import { atomWithStorage } from "jotai/utils";

const DEFAULT_SIZE = {
	bottom: 0,
	height: 0,
	left: 0,
	right: 0,
	top: 0,
	width: 0,
};

type NavbarVariant = "default" | "minimal";
type Navbar = {
	variant: NavbarVariant;
	size: typeof DEFAULT_SIZE;
};
type Element = {
	size?: typeof DEFAULT_SIZE;
};

const navbarAtom = atomWithStorage<NavbarVariant>("navbar", "default");
const headAtom = atom<Element>({
	size: DEFAULT_SIZE,
});
const navbarSizeAtom = atom<Element>({
	size: DEFAULT_SIZE,
});

interface Layout {
	layout: {
		navbar: Navbar;
		head: Element;
	};
	actions: {
		setNavbar: (value: NavbarVariant) => void;
		setHead: (value: Element) => void;
		setNavbarSize: (value: Element) => void;
	};
}

export default function useLayout(): Layout {
	const [navbar, setNavbar] = useAtom<NavbarVariant, any, any>(navbarAtom);
	const [head, setHead] = useAtom<Element, any, any>(headAtom);
	const [navbarSize, setNavbarSize] = useAtom<Element, any, any>(
		navbarSizeAtom
	);

	return {
		layout: {
			navbar: {
				variant: navbar,
				size: navbarSize.size || DEFAULT_SIZE,
			},
			head,
		},
		actions: {
			setNavbar,
			setHead,
			setNavbarSize,
		},
	};
}
