const chat = {
	"create-message-box": {
		"denied-company": "Registration declined, chat closed",
		"denied-student": "Registration declined, chat closed",
		"no-subscription":
			"To chat with professionals you need an active subscription <1>Click here</1> to choose a subscription",
		placeholder: "Type your message here...",
		cta: "Send",
	},
	"message-bid-form": {
		title: "Change price",
		"price-adjust-accepted": "Task price adjusted to {{price}}",
		"price-adjust-denied": "Price change to {{price}} was declined",
		"price-adjust-open": "{{name}} wants to change the price to {{price}}",
		pending: "Waiting for response",
		accept: "Adjust price",
	},
	"messages-box": {
		"turned-off": "This chat is turned off",
		"button-older-messages": "Load older messages",
		motivation: "Motivation",
	},
};

export default chat;
