import useUserCardContext from "../UserCardContext";

function UserCardTelephone() {
	const { user } = useUserCardContext();

	if (!user.telephone) {
		return <></>;
	}

	return (
		<div className="flex relative items-center">
			<span className="mb-0">Tel: {user.telephone}</span>
		</div>
	);
}

export default UserCardTelephone;
