import moment from "moment";
import TaskDeadlineText from "src/components/tasks/TaskDeadlineText";
import { useTaskKanban } from "src/components/tasks/kanban/TaskKanbanCard";

const TaskKanbanCardDate = () => {
	const { task } = useTaskKanban();

	if (!task.end_date) {
		return <div />;
	}

	if (task.status === "COMPLETED") {
		return (
			<small className="text-placeholder">
				{moment(task.end_date).format("DD-MM-YYYY")}
			</small>
		);
	}

	return task.end_date ? (
		<TaskDeadlineText date={task.end_date} />
	) : (
		<small className="text-placeholder">
			{moment(task.created_at).format("DD-MM-YYYY")}
		</small>
	);
};

export default TaskKanbanCardDate;
