import { useTranslation } from "react-i18next";
import { ApiRegistrationStatus } from "src/api/types";
import Badge, { BadgeSize } from "src/components/Badge";

const color = {
	completed: {
		bg: "green",
		border: "green-500",
		color: "white",
	},
	matched: {
		bg: "primary",
		border: "primary-500",
		color: "white",
	},
	expired: {
		bg: "red",
		border: "red-500",
		color: "white",
	},
	denied: {
		bg: "transparent",
		border: "transparent",
		color: "red",
	},
	canceled: {
		bg: "red",
		color: "white",
	},
} as any;

interface RegistrationStatusProps {
	status: ApiRegistrationStatus;
	size?: BadgeSize;
}

const RegistrationStatus = ({ status, size }: RegistrationStatusProps) => {
	const { t } = useTranslation("registrations", {
		keyPrefix: "status",
	});
	return (
		<div className=" ">
			<Badge
				bg={color[status]?.bg || "gray"}
				color={color[status]?.color || "dark"}
				size={size || "small"}
			>
				{t(status)}
			</Badge>
		</div>
	);
};

export default RegistrationStatus;
