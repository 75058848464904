import Link from "src/components/Link";
import withTranslation, {
	Translation,
} from "src/components/hoc/withTranslation";
import {
	Tooltip,
	TooltipContent,
	TooltipProvider,
	TooltipTrigger,
} from "src/components/ui/tooltip";
import useTimeTracking from "src/hooks/api/services/useTimeTracking";

interface TimerOriginTooltipProps extends Translation {
	disabled?: boolean;
	children?: any;
}

const TimerOriginTooltip = ({
	t,
	disabled,
	children,
}: TimerOriginTooltipProps) => {
	const { time } = useTimeTracking();
	if (disabled || !time?.task) return children;
	return (
		<TooltipProvider>
			<Tooltip>
				<TooltipTrigger>{children}</TooltipTrigger>
				<TooltipContent>
					<p>{t("origin")}</p>
					<Link
						className="underline"
						to={`/tasks/${time.task.hid}/editor`}
					>
						{time.task?.title}
					</Link>
				</TooltipContent>
			</Tooltip>
		</TooltipProvider>
	);
};

TimerOriginTooltip.locale = {
	nl: {
		origin: "Tijd wordt bijgehouden voor",
	},
	en: {
		origin: "Time is being tracked for",
	},
};

export default withTranslation(TimerOriginTooltip);
