import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import nl from "src/locale/nl";
import en from "src/locale/en";

const tenants = {
	getjobsdone: {
		name: "GetJobsDone",
		slug: "getjobsdone",
		code: "GJD",
	},
	getcrewdone: {
		name: "GetCrewDone",
		slug: "getcrewdone",
		code: "GCD",
	},
	odylyt: {
		name: "Odylyt",
		slug: "odylyt",
		code: "O",
	},
};

let fallbackLng = "nl";

let tenant = tenants.getjobsdone;
if (window.location.host.includes("getcrewdone")) {
	tenant = tenants.getcrewdone;
}

if (window.location.host.includes("odylyt")) {
	tenant = tenants.odylyt;
	fallbackLng = "en";
}

const resources = {
	nl,
	en,
};

const initOptions: any = {
	fallbackLng,
	resources,
	interpolation: {
		escapeValue: false,
		defaultVariables: {
			tenant: tenant,
		},
	},
	react: {
		transKeepBasicHtmlNodesFor: ["br", "strong", "i", "p", "u"],
		useSuspense: false,
	},
	returnNull: false,
};

if (window.location.host.includes("odylyt")) {
	initOptions.detection = {
		convertDetectedLanguage: (lng: string) => {
			if (lng === "nl-NL") return "nl";
			return "en";
		},
		caches: [],
	};
	i18n.use(LanguageDetector).use(initReactI18next).init(initOptions);
} else {
	i18n.use(initReactI18next).init(initOptions);
}

export default i18n;
