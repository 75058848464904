import { atom, useAtom } from "jotai";
import { atomFamily } from "jotai/utils";
import { useMemo } from "react";
import { useDispatch } from "react-redux";
import { ApiIcon } from "src/api/types";
import { SourceValue } from "src/components/Onboarding/source-input";
import useTenant from "src/hooks/api/services/tenants/useTenant";
import useApi from "src/hooks/api/utils/useApi";
import useAuth from "src/hooks/selectors/useAuth";
import { updateProfile } from "src/redux/auth";

export type OnboardingUser = {
	id?: number;
	email: string;
	teams?: number[];
};

export type OnboardingBoard = {
	id?: number;
	icon?: ApiIcon;
	name: string;
	team: number;
	type: "MANUAL" | "STATIC";
};

export type OnboardingTeam = {
	name: string;
	icon?: ApiIcon;
	type: "MANUAL" | "DEFAULT";
};

type Update = {
	teams?: any[];
	users?: OnboardingUser[];
	boards?: OnboardingBoard[];
	company?: any;
	source?: SourceValue;
	information?: {
		website?: string;
		description?: string;
	};
	resumes?: {
		getjobsdone: boolean;
		getcrewdone: boolean;
	};
};

export type OnboardingStep =
	| "create-teams"
	| "invite-users"
	| "team-skills"
	| "create-boards"
	| "website"
	| "completed"
	| "company"
	| "copy-resume";

const stepAtom = atomFamily(
	({ step }) => atom<OnboardingStep>(step),
	(a: any, b: any) => a.type === b.type
);

function useOnboarding() {
	const auth = useAuth();
	const dispatch = useDispatch();

	const {
		tenant: { slug, modules },
	} = useTenant();
	const defaultStep = useMemo(() => {
		if (
			(modules.includes("extern-task") ||
				modules.includes("vacancies")) &&
			!modules.includes("intern-task")
		) {
			if (auth.type === "student") {
				if (
					auth?.onboarding?.resumes?.getjobsdone &&
					slug !== "getjobsdone"
				) {
					return "copy-resume";
				}

				if (!auth.onboarding.missing.length) {
					return auth.onboarding.all[auth.onboarding.all.length - 1];
				}
				return auth.onboarding.missing[0];
			}

			return "company";
		}
		return auth.type === "student" ? "create-boards" : "company";
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [auth.type, modules]);
	const [step, setStep] = useAtom(
		stepAtom({ type: auth.type, step: defaultStep }) as any
	);
	const { state, actions, api } = useApi(
		{ id: "ONBOARDING" },
		{
			baseUrl: `/client/onboarding`,
			query: {},
		}
	);

	const current = async () => {
		actions.set({
			...state,
			status: "loading",
		});
		const { data } = await api.get("/current");
		actions.set({
			...state,
			status: "idle",
			data: data?.data || {},
		});
	};

	const update = async (update: Update) => {
		actions.set({
			status: "updating",
		});
		try {
			const { data } = await api.patch("/", update);
			actions.set({
				...state,
				status: "idle",
				data: data?.data || {},
			});
		} catch (error: any) {
			actions.set({
				...state,
				status: "idle",
			});
			throw error;
		}
	};

	const complete = async (data?: { source?: SourceValue }) => {
		await api.post(`/complete`, data);
		dispatch(
			updateProfile({
				onboarding: { ...auth.onboarding, is_completed: true },
			})
		);
	};

	return {
		onboarding: state.data,
		step,
		status: state.status,
		defaultStep,
		data: auth.onboarding,
		actions: {
			current,
			update,
			setStep,
			complete,
		},
	};
}

export default useOnboarding;
