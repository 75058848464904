import React from "react";
import { useTranslation } from "react-i18next";
import { ApiOfferRegistration } from "src/api/types";
import Alert from "src/components/Alert";
import ApiCall from "src/components/ApiCall";
import Card from "src/components/Card";
import Loader from "src/components/Loader";
import Head from "src/components/offer/cards/OfferRegistrationMatchCard/Head";
import ReviewInfo from "src/components/ReviewInfo";
import Signers from "src/components/signer/Signers";
import useAuth from "src/hooks/selectors/useAuth";
import useBackUrl from "src/hooks/useBackUrl";
import PaymentStatus from "src/components/payment/PaymentStatus";

interface OfferRegistrationMatchCardProps {
	registration: ApiOfferRegistration;
	variant: "card" | "flat";
	reviewUrl?: string;
}

const OfferRegistrationMatchCard = ({
	registration,
	variant,
	reviewUrl,
}: OfferRegistrationMatchCardProps) => {
	const { t } = useTranslation("offer", {
		keyPrefix: "offer-registration-match-card",
	});
	const backUrl = useBackUrl();
	const auth = useAuth();
	const isProcessed =
		registration?.links?.envelope_hid &&
		registration?.links?.payment_hid &&
		registration?.links?.task_hid;

	const getButtonDisclaimer = () => {
		if (!registration.statuses?.has_signed_envelope) {
			return t("envelope-not-signed");
		}
		if (!registration.statuses?.has_payment) {
			return t("envelope-not-signed");
		}
	};

	const renderChildren = () => (
		<>
			<Alert type="success">{t("alert.success")}</Alert>
			<Wrapper>
				<Head {...{ registration }} />
			</Wrapper>

			{!isProcessed && (
				<div className="flex flex-1 flex-col justify-center items-center">
					<Loader />
				</div>
			)}

			{registration?.links?.envelope_hid && (
				<>
					<Line />
					<Wrapper>
						<Signers
							isUpdateViewed={false}
							hid={registration?.links?.envelope_hid}
							variant="light"
							title={t("signers.title")}
							showButton
							buttonUrl={`/signer/${registration.links.envelope_hid}?${backUrl}`}
						/>
					</Wrapper>
				</>
			)}
			{registration?.links?.payment_hid && (
				<>
					<Line />
					<Wrapper>
						<ApiCall
							listener={`payment.${registration?.links?.payment_hid}`}
							query={`/${auth.type}/payments/${registration?.links?.payment_hid}?exclude[]=invoice&exclude[]=task`}
						>
							{(data, loading) =>
								loading ? (
									<PaymentStatus.Loading variant="flat" />
								) : data ? (
									<PaymentStatus
										registration={
											registration.links.match_hid
										}
										payment={data}
										variant="flat"
									/>
								) : (
									false
								)
							}
						</ApiCall>
					</Wrapper>
				</>
			)}
			{registration?.links?.task_hid && (
				<>
					<Line />
					<Wrapper>
						<ReviewInfo
							buttonUrl={reviewUrl}
							id={registration.links.task_hid}
							registrationId={registration.links.match_hid || ""}
							query={`registration_id=${registration?.links?.match_hid}`}
							showButton={registration?.statuses?.can_finish}
							buttonDisclaimer={getButtonDisclaimer()}
						/>
					</Wrapper>
				</>
			)}
		</>
	);

	if (variant === "flat") {
		return <div className="flex-1 flex flex-col">{renderChildren()}</div>;
	}

	return (
		<Card
			className={`flex-1 ${
				auth.type === "company" && "bg-dark-accent text-white"
			}`}
			contentClassName={auth.type === "company" ? "text-white" : ""}
		>
			{renderChildren()}
		</Card>
	);
};

OfferRegistrationMatchCard.defaultProps = {
	variant: "card",
};

const Wrapper = ({ children }: { children: any }) => (
	<div className="py-6">{children}</div>
);

const Line = () => {
	const auth = useAuth();
	return (
		<hr
			className={
				auth.type === "student" ? "border-border" : "border-dark"
			}
		/>
	);
};

export default OfferRegistrationMatchCard;
