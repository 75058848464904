import moment from "moment";
import { useEffect } from "react";
import { ApiProject } from "src/api/types";
import Button from "src/components/Button";
import UserAvatars from "src/components/UserAvatars";
import withTranslation, {
	Translation,
} from "src/components/hoc/withTranslation";
import useProjects from "src/hooks/api/services/projects/useProjects";
import useUsers from "src/hooks/api/services/users/useUsers";
import ProjectStatus from "../ProjectStatus";
import { useUpdateEffectDebounce } from "src/hooks/useDebounce";
import Skeleton from "src/components/Skeleton";
import Icon from "src/components/icon/Icon";
import List from "src/components/ui/list";

function ProjectsList({ t }: Translation) {
	const {
		projects,
		actions,
		pagination,
		filter,
		status: _status,
	} = useProjects();
	const status = _status === "loading" && projects.length ? "idle" : _status;
	const { users } = useUsers();
	useUpdateEffectDebounce(filter, 1000, () => {
		actions.list(filter);
	});

	useEffect(() => {
		actions.list({
			page: 1,
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<>
			<List data={projects} {...{ status }}>
				<List.Head>
					<List.Item>{t("headers.status")}</List.Item>
					<List.Item>{t("headers.project")}</List.Item>
					<List.Item>{t("headers.members")}</List.Item>
					<List.Item>{t("headers.date")}</List.Item>
					<List.Item className="sticky right-0"></List.Item>
				</List.Head>
				<List.Items>
					{(project: ApiProject) => {
						const members = users.filter((u: any) =>
							project.members.includes(u.id)
						);
						return (
							<List.Row key={project.id}>
								<List.Item className="w-36">
									<ProjectStatus
										completedTasks={
											project.progress.completed
										}
										totalTasks={project.progress.total}
									/>
								</List.Item>
								<List.Item>
									<div className="flex flex-row items-center gap-2">
										<Icon
											className="bg-accent w-10"
											icon={project.icon}
										/>
										{project.name}
									</div>
								</List.Item>
								<List.Item>
									<UserAvatars
										users={members.slice(0, 6)}
										overlay={
											members.length > 5 ? (
												<div className="absolute inset-0 bg-primary backdrop-blur-sm rounded-md flex justify-center items-center text-white">
													<strong>
														{`+${
															members.length - 5
														}`}
													</strong>
												</div>
											) : undefined
										}
									/>
								</List.Item>
								<List.Item>
									{moment(project.start_date).isValid() &&
									moment(project.end_date).isValid()
										? `${moment(project.start_date).format(
												"DD-MM-YYYY"
										  )} / ${moment(
												project.end_date
										  ).format("DD-MM-YYYY")}`
										: `${moment(project.start_date).format(
												"DD-MM-YYYY"
										  )} / ${t("empty_end_date")}`}
								</List.Item>
								<List.Item className="sticky right-0 bg-background z-[66]">
									<div className="flex justify-end">
										<Button
											className="py-4"
											iconOnly
											icon="fas fa-eye"
											type="gray"
											to={`/projects/${project.slug}`}
										/>
									</div>
								</List.Item>
							</List.Row>
						);
					}}
				</List.Items>
				<List.Empty>
					<h2>{t("empty.title")}</h2>
					<p>{t("empty.text")}</p>
				</List.Empty>
				<List.Loading>
					<Loading />
				</List.Loading>
				<List.Pagination
					{...{ pagination }}
					onNavigate={pagination.setPage}
				/>
			</List>
		</>
	);
}

ProjectsList.locale = {
	nl: {
		headers: {
			status: "Status",
			project: "Project",
			members: "Leden",
			date: "Datum",
		},
		empty: {
			title: "Geen projecten gevonden",
			text: "Je hebt nog geen project gemaakt",
		},
		empty_end_date: "Niet gespecificeerd",
	},
	en: {
		headers: {
			status: "Status",
			project: "Project",
			members: "Members",
			date: "Date",
		},
		empty: {
			title: "No projects found",
			text: "You haven't created a project yet",
		},
		empty_end_date: "Not specified",
	},
};

const Loading = () => {
	return (
		<>
			<List.Item>
				<Skeleton className="h-6 w-full" />
			</List.Item>
			<List.Item>
				<Skeleton className="h-6 w-full" />
			</List.Item>
			<List.Item>
				<UserAvatars.Loading />
			</List.Item>
			<List.Item>
				<Skeleton className="h-6 w-full" />
			</List.Item>
			<List.Item align="right">
				<Skeleton className="h-10 w-10" />
			</List.Item>
		</>
	);
};

export default withTranslation(ProjectsList);
