import { createContext, useContext } from "react";
import { ApiProfileMinimal, ApiUserMinimal } from "src/api/types";

export const UserCardContext = createContext<{
	user: ApiProfileMinimal | ApiUserMinimal;
	status: string;
	setStatus: (status: "idle" | "deleting") => void;
} | null>(null);

export function useUserCardContext() {
	const context = useContext(UserCardContext);
	if (!context) {
		throw new Error(
			"UserCard.* component must be rendered as child of UserCard component"
		);
	}
	return context;
}

export default useUserCardContext;
