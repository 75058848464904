import { useState } from "react";
import { ApiMedia } from "src/api/types";
import Button from "src/components/Button";
import Tag from "src/components/Tag";
import FileList from "src/components/field/utils/FileList";
import Wrapper, { WrapperProps } from "src/components/field/utils/Wrapper";
import FileUpload from "src/components/form/file-upload/FileUpload";
import FileUploadItem from "src/components/form/file-upload/FileUploadItem";
import withTranslation, {
	Translation,
} from "src/components/hoc/withTranslation";
import {
	Popover,
	PopoverContent,
	PopoverTrigger,
} from "src/components/ui/popover";
import { ALLOWED_FILES } from "src/lib/constants";
import { cn } from "src/lib/utils";

type Value = ApiMedia | ApiMedia[];

interface FileProps extends Translation {
	wrapper: WrapperProps;
	placeholder?: string;
	value?: Value;
	className?: string;
	onChange: (value?: Value) => void;
	theme?: "default" | "input" | "input-with-preview";
}

const File = ({
	t,
	wrapper,
	placeholder,
	value,
	onChange,
	className,
	theme = "default",
}: FileProps) => {
	const [open, setOpen] = useState(false);
	const isMultiple = Array.isArray(value);
	const hasValue = isMultiple ? isMultiple && value?.length > 0 : !!value;
	const files = isMultiple ? value : [...(value ? [value] : [])];
	const [variant, setVariant] = useState<"upload" | "list">(
		hasValue ? "list" : "upload"
	);

	const handleUpload = (media: ApiMedia) => {
		if (isMultiple) {
			onChange([...value, media]);
		} else {
			onChange(media);
		}
		setVariant("list");
		setOpen(false);
	};

	return (
		<Wrapper
			{...{
				...wrapper,
			}}
		>
			<Popover
				{...{ open }}
				onOpenChange={(v) => {
					if (open) {
						setVariant(hasValue ? "list" : "upload");
					}
					setOpen(v);
				}}
			>
				<PopoverTrigger asChild>
					<button
						className={cn(
							"flex relative w-full items-center text-left gap-2 border px-3 focus:border-border rounded-md text-background-foreground h-[44px]",
							open ? "border-border" : "border-transparent",
							className
						)}
					>
						{!hasValue && (
							<span className="text-placeholder line-clamp-1">
								{placeholder || t("placeholder")}
							</span>
						)}
						{hasValue && (
							<div className="flex gap-2 w-full flex-wrap">
								{files.length > 3 ? (
									<Tag theme="gray" size="default">
										{t("file-max", {
											count: files.length,
										})}
									</Tag>
								) : (
									files.map((media) => (
										<FileUploadItem
											className={cn(
												[
													"input",
													"input-with-preview",
												].includes(theme) &&
													"bg-transparent font-regular pl-0 w-full"
											)}
											isFullname={
												theme === "input-with-preview"
											}
											isPreview={
												theme === "input-with-preview"
											}
											key={`file-${media.id}`}
											{...{ media }}
										>
											{media?.url && (
												<Button
													xsmall
													type="border"
													onClick={(event) => {
														event.stopPropagation();
														setVariant("upload");
														onChange(undefined);
													}}
												>
													{t("edit")}
												</Button>
											)}
										</FileUploadItem>
									))
								)}
							</div>
						)}
					</button>
				</PopoverTrigger>
				<PopoverContent align="start" className="w-full">
					{variant === "upload" && (
						<FileUpload
							onChange={handleUpload}
							allow={ALLOWED_FILES}
							value={undefined}
						/>
					)}
					{variant === "list" && (
						<>
							<FileList {...{ files, onChange }} />
							<div
								onClick={() => setVariant("upload")}
								className="py-[11px] px-[10px] rounded-md flex items-center gap-2 cursor-pointer"
							>
								<i className="far fa-plus"></i>
								<span className="font-regular">
									{t("upload")}
								</span>
							</div>
						</>
					)}
				</PopoverContent>
			</Popover>
		</Wrapper>
	);
};

File.locale = {
	nl: {
		"file-max": "({{count}}) bestanden",
		placeholder: "Voeg een afbeelding of bestand toe",
		upload: "Upload",
		edit: "Bewerk",
	},
	en: {
		"file-max": "({{count}}) files",
		placeholder: "Upload an image or file",
		upload: "Upload",
		edit: "Edit",
	},
};

export default withTranslation(File);
