import React from "react";

const states = {
	online: "bg-green",
	offline: "bg-accent",
};
interface OnlineIndicatorProps {
	state: keyof typeof states;
}

const OnlineIndicator = ({ state }: OnlineIndicatorProps) => {
	return <div className={`w-2 h-2 rounded-full ${states[state]}`}></div>;
};

OnlineIndicator.defaultProps = {
	state: "offline",
};

export default OnlineIndicator;
