import { useEditorProps } from "src/components/field/fields/Editor/Editor";
import HeaderItem from "src/components/field/fields/Editor/HeaderItem";

const ImageHeadItem = () => {
	const { loadingImage: loading, onUploadImageClick } = useEditorProps();

	return (
		<HeaderItem
			{...{ loading }}
			onChange={onUploadImageClick}
			icon="fas fa-image"
		/>
	);
};

export default ImageHeadItem;
