import { motion } from "framer-motion";
import { useEffect, useState } from "react";
import Button from "src/components/Button";
import Loader from "src/components/Loader";
import Field from "src/components/field/Field";
import withTranslation, {
	Translation,
} from "src/components/hoc/withTranslation";
import useGenerateTaskDescription from "src/hooks/api/tasks/useGenerateTaskDescription";
import useTaskForm from "src/hooks/api/tasks/useTaskForm";

interface AIGenerateDescriptionProps extends Translation {
	children?: any;
	onBack?: () => void;
}

const AIGenerateDescription = ({
	t,
	children,
	onBack,
}: AIGenerateDescriptionProps) => {
	const { task, actions: formActions } = useTaskForm();
	const { actions, status, response } = useGenerateTaskDescription();
	const [mode, setMode] = useState<"preview" | "edit">("preview");

	useEffect(() => {
		if (status === "idle") {
			actions.start(task.id).then((description) => {
				formActions.set({ description }, "merge");
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<div className="flex flex-col relative p-4 gap-2">
			{status === "loading" && (
				<div className="absolute right-0 bottom-0">
					<Loader />
				</div>
			)}
			{onBack && (
				<Button
					className="w-max mb-4"
					iconOnly
					type="ghost"
					onClick={onBack}
					disabled={status === "loading"}
				>
					<i className="far fa-arrow-left"></i>
				</Button>
			)}
			<h2 className="mb-4">{task.title}</h2>
			{mode === "preview" && (
				<>
					<div className="">
						{!response && (
							<motion.div
								animate={{
									opacity: 0.2,
								}}
								transition={{
									duration: 1.5,
									repeat: Infinity,
									repeatType: "reverse",
								}}
								className="h-5 w-[2px] flex rounded-sm bg-primary"
							/>
						)}
						<div
							className="flex flex-col gap-2 formatted-task-description"
							dangerouslySetInnerHTML={{ __html: response }}
						></div>
					</div>
					{status === "finished" && (
						<Button
							className="w-max mt-2"
							onClick={() => setMode("edit")}
							type="ghost"
						>
							<i className="far fa-pencil mr-2"></i>
							{t("edit")}
						</Button>
					)}
				</>
			)}
			{mode === "edit" && (
				<>
					<Field.Editor
						value={task.description || ""}
						onChange={(value) => {
							formActions.set({ description: value }, "manual");
							actions.setResponse(value);
						}}
					/>
					<Button
						className="w-max mt-2"
						onClick={() => setMode("preview")}
						type="ghost"
					>
						<i className="far fa-eye mr-2"></i>
						{t("preview")}
					</Button>
				</>
			)}

			{status === "finished" && task.description && children}
		</div>
	);
};

AIGenerateDescription.locale = {
	nl: {
		edit: "Aanpassen",
		preview: "Voorbeeld",
	},
	en: {
		edit: "Edit",
		preview: "Preview",
	},
};

export default withTranslation(AIGenerateDescription);
