const unprofessionalReport = {
    title: "Melden",
    placeholder: "Beschrijf het gedrag van de professional",
    required: "Verplicht",
    options: {
        not_there:"Professional is niet op komen dagen",
        incorrect_language:"Professional gebruikt onjuist taalgebruik",
        other:"Anders:"
    }
}

export default unprofessionalReport