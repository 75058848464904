import axios from "axios";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import Button from "src/components/Button";
import Skeleton from "src/components/Skeleton";
import withTranslation, {
	Translation,
} from "src/components/hoc/withTranslation";
import Layout from "src/components/layout/Layout";
import TasksKanban from "src/components/tasks/kanban/TasksKanban";
import useProjects from "src/hooks/api/services/projects/useProjects";
import useTasksFilter from "src/hooks/api/tasks/useTasksFilter";
import useTasksKanban from "src/hooks/api/tasks/useTasksKanban";
import ProjectTasksLayout from "src/components/layout/project-tasks-layout/ProjectTasksLayout";

function ProjectView({ t }: Translation) {
	const { project } = useParams() as any;
	const {
		actions: projectActions,
		project: projectState,
		status,
	} = useProjects();
	const { filter, actions } = useTasksFilter();
	const { actions: kanbanActions } = useTasksKanban();

	useEffect(() => {
		projectActions.get(project);
		return () => {
			projectActions.setItem(
				{
					data: {},
				},
				{
					updateList: false,
				}
			);
			actions.set(
				{
					project: undefined,
				},
				"reset"
			);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [project]);

	useEffect(() => {
		if (project) {
			actions.set(
				{
					project,
				},
				"reset"
			);
		} else if (filter?.project) {
			actions.set(
				{
					project: undefined,
				},
				"reset"
			);
		}
		return () => {
			//Clear all the tasks from the kanban board
			kanbanActions.reset();
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [project]);

	const handleProgress = async () => {
		const { data } = await axios.get(
			`/company/projects/${project}/progress`
		);
		// actions set
		projectActions.setItem({
			data: {
				...projectState,
				id: projectState.id,
				progress: data.data,
			},
		});
	};

	if (status === "idle" && projectState?.slug !== project) {
		return (
			<Layout
				title={
					<div className="flex items-center gap-4">
						<h1 className="text-[24px] hidden sm:flex lg:text-[32px]">
							{t("title")}
						</h1>
					</div>
				}
			>
				<div className="flex flex-1 justify-center items-center p-8 text-center flex-col max-w-md mx-auto">
					<strong className="mb-1 text-[18px]">
						{t("not-found.title")}
					</strong>
					<p className="mb-4">{t("not-found.description")}</p>
					<Button to={`/projects`}>{t("back_to_projects")}</Button>
				</div>
			</Layout>
		);
	}

	return (
		<ProjectTasksLayout>
			<div className="flex flex-col flex-1">
				{status === "loading" ? (
					<ProjectViewSkeleton />
				) : (
					<TasksKanban onStatusChange={handleProgress} />
				)}
			</div>
		</ProjectTasksLayout>
	);
}

ProjectView.locale = {
	nl: {
		title: "Projecten",
		"not-found": {
			title: "Project niet gevonden",
			description:
				"Het lijkt er op dat dit project nooit heeft bestaan of is verwijdert.",
		},
		back_to_projects: "Terug naar projecten",
	},
	en: {
		title: "Projects",
		"not-found": {
			title: "Project not found",
			description:
				"It seems that this project never existed or was removed.",
		},
		back_to_projects: "Back to projects",
	},
};

const ProjectViewSkeleton = () => {
	return (
		<>
			<div>
				<Skeleton className="w-[150px] h-[30px]" />
			</div>
			<div className="flex items-center gap-4 flex-wrap justify-between my-4">
				<div>
					<Skeleton className="w-[300px] h-[50px]"></Skeleton>
				</div>
				<div className="flex flex-row gap-4">
					<Skeleton className="w-[150px] h-[50px]"></Skeleton>
					<Skeleton className="w-[150px] h-[50px]"></Skeleton>
				</div>
			</div>
		</>
	);
};

export default withTranslation(ProjectView);
