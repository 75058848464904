import { ComponentProps, useEffect, useState } from "react";
import {
	Radar,
	RadarChart,
	PolarGrid,
	PolarAngleAxis,
	ResponsiveContainer,
} from "recharts";
import CountUp from "react-countup";
import Card from "src/components/Card";
import Skeleton from "src/components/Skeleton";
import { useUserStatistics } from "src/components/analytics/user/Provider";
import SkillSelector from "src/components/form/skill-selector/SkillSelector";
import withTranslation, {
	Translation,
} from "src/components/hoc/withTranslation";
import Text from "src/components/ui/text";
import useUserStats from "src/hooks/api/services/statistics/user/useUserStats";
import { cn } from "src/lib/utils";
import Chart from "src/components/statistics/Chart";
import moment from "moment";

interface SkillsProps {}

export const Skills = ({
	className,
	children,
	...rest
}: SkillsProps & ComponentProps<"div">) => {
	const { data } = useUserStatistics();

	return (
		<SkillSelector.Root
			value={(data?.skills?.unique as any) || []}
			options={{ limit: 5 }}
			theme={{
				default: {
					className: "text-sm bg-accent border border-border",
					size: "small",
				},
				active: {
					className:
						"text-sm bg-accent border border-border text-background-foreground",
				},
			}}
		>
			<SkillSelector.Results />
		</SkillSelector.Root>
	);
};

export const Totals = withTranslation(
	({ t, className, ...rest }: Translation & ComponentProps<"div">) => {
		const { data, status } = useUserStatistics();
		return (
			<div {...rest} className={cn("flex gap-8", className)}>
				<div className="flex flex-col flex-1 border-r border-border py-2 gap-1">
					<div className="flex gap-2 items-center">
						<i className="fas fa-check text-sm"></i>
						{status !== "idle" ? (
							<Skeleton className="w-12 h-8" />
						) : (
							<strong className="text-2xl leading-full">
								<CountUp end={data?.tasks?.completed || 0} />
							</strong>
						)}
					</div>
					<Text.Eyebrow>{t("tasks")}</Text.Eyebrow>
				</div>
				<div className="flex flex-col flex-1 py-2 gap-1">
					<div className="flex gap-2 items-center">
						<i className="fas fa-brain text-sm"></i>
						{status !== "idle" ? (
							<Skeleton className="w-12 h-8" />
						) : (
							<strong className="text-2xl leading-full">
								<CountUp
									end={data?.skills?.unique?.length || 0}
								/>
							</strong>
						)}
					</div>
					<Text.Eyebrow>{t("skills")}</Text.Eyebrow>
				</div>
			</div>
		);
	},
	{
		nl: {
			tasks: "Taken",
			skills: "Skills",
		},
		en: {
			tasks: "Tasks",
			skills: "Skills",
		},
	}
);

interface SkillDistributionProps {
	title?: string;
	height?: number;
	as?: "card" | "div";
}

export const SkillDistribution = withTranslation(
	({
		t,
		className,
		title,
		height = 400,
		as = "card",
		...rest
	}: Translation & ComponentProps<"div"> & SkillDistributionProps) => {
		const Wrapper = as === "card" ? Card : "div";
		const { userId } = useUserStatistics();
		const [isLoading, setIsLoading] = useState(false);
		const { actions, distribution } = useUserStats({
			userId,
		});

		useEffect(() => {
			setIsLoading(true);
			actions.getSkillDistribution().finally(() => setIsLoading(false));
			// eslint-disable-next-line react-hooks/exhaustive-deps
		}, [userId]);

		return (
			<Wrapper
				{...rest}
				{...{ title }}
				className={cn("flex gap-8", className)}
			>
				{!isLoading && distribution.length === 0 ? (
					<div className="flex flex-1 justify-center items-center flex-col py-12">
						<p className="opacity-70">{t("empty.description")}</p>
					</div>
				) : (
					<ResponsiveContainer width="100%" {...{ height }}>
						<RadarChart
							cx="50%"
							cy="50%"
							outerRadius="80%"
							data={(distribution || []).map((item: any) => ({
								name: item.name,
								value: item.total,
							}))}
						>
							<defs>
								<radialGradient
									id="radial"
									gradientTransform="translate(-0.5 -0.5) scale(2, 2)"
								>
									<stop
										offset="0%"
										stopColor="rgba(34, 143, 212, 0.1)"
									/>
									<stop
										offset="25%"
										stopColor="rgba(34, 143, 212, 0.33)"
									/>
									<stop
										offset="50%"
										stopColor="rgba(34, 143, 212, 0.55)"
									/>
									<stop offset="100%" stopColor="#228fd4" />
								</radialGradient>
							</defs>
							<PolarGrid />
							<PolarAngleAxis dataKey="name" />
							<Radar
								dataKey="value"
								stroke="#228fd4"
								fill="url(#radial)"
								fillOpacity={1}
							/>
						</RadarChart>
					</ResponsiveContainer>
				)}
			</Wrapper>
		);
	},
	{
		nl: {
			empty: {
				description: "Geen skills gevonden",
			},
		},
		en: {
			empty: {
				description: "No skills found",
			},
		},
	}
);

export const SkillProgress = withTranslation(
	({
		t,
		height,
		title,
		className,
		...rest
	}: Translation &
		ComponentProps<"div"> & { height?: number | null; title?: string }) => {
		const { userId } = useUserStatistics();
		const [isLoading, setIsLoading] = useState(false);
		const { actions, progress } = useUserStats({
			userId,
		});
		const isEveryEmpty = progress.every((item: any) => !item.total);

		useEffect(() => {
			setIsLoading(true);
			actions.getSkillProgress().finally(() => setIsLoading(false));
			// eslint-disable-next-line react-hooks/exhaustive-deps
		}, [userId]);

		return (
			<Card
				{...rest}
				{...{ title }}
				className={cn("flex gap-8", className)}
			>
				{!isLoading && isEveryEmpty ? (
					<div className="flex flex-1 justify-center items-center flex-col py-12">
						<p className="opacity-70">{t("empty.description")}</p>
					</div>
				) : (
					<Chart
						data={(progress || []).map((item: any) => ({
							key: moment(item.date).format("DD-MM-YYYY"),
							value: item.total,
						}))}
						chart={{
							height: height !== undefined ? height : 240,
						}}
					/>
				)}
			</Card>
		);
	},
	{
		nl: {
			empty: {
				description: "Geen skills gevonden",
			},
		},
		en: {
			empty: {
				description: "No skills found",
			},
		},
	}
);
