import axios from "axios";
import { atom, useAtom } from "jotai";
import {
	ApiTaskKanbanStatus,
	ApiTaskKanban,
	ApiPagination,
} from "src/api/types";
import useTaskStatuses from "src/hooks/api/tasks/useTaskStatuses";
import useTasksFilter from "src/hooks/api/tasks/useTasksFilter";
import { createApiInstance } from "src/lib/api";
import uid from "src/lib/uid";

export interface ActiveDraggableTask {
	id: string | number;
	from: ApiTaskKanbanStatus;
	to: ApiTaskKanbanStatus;
}

type Columns = {
	[key in ApiTaskKanbanStatus]: {
		meta: ApiPagination;
		tasks: ApiTaskKanban[];
		status: "loading" | "idle";
		params?: {
			page?: number;
			q?: string;
		};
	};
};

export type TasksKanbanState = {
	columns: Columns;
	status: "loading" | "blocked" | "idle";
};

const tasksKanbanAtom: any = atom({
	columns: {},
	status: "loading",
});

export default function useTasksKanban() {
	const taskStatuses = useTaskStatuses();
	const { filter } = useTasksFilter();
	const [state, setState] = useAtom<TasksKanbanState, any, any>(
		tasksKanbanAtom
	);
	const api = createApiInstance({
		baseURL: `${axios.defaults.baseURL}/client/tasks`,
	});

	const reset = () => {
		setState({ columns: {}, status: "loading" });
	};

	const getAllStatuses = async () => {
		setState((s: any) => ({ ...s, status: "loading" }));
		let statuses: string[] = [];
		if (!taskStatuses.statuses?.length) {
			statuses = await taskStatuses.actions.get();
		} else {
			statuses = taskStatuses.statuses;
		}

		const columns = Object.assign(
			{},
			...statuses.map((status: any) => ({
				[status]: {
					tasks: [],
					status: "idle",
				},
			}))
		);

		setState((s: any) => ({ ...s, columns, status: "idle" }));
		return statuses;
	};

	const getColumns = async (columns: ApiTaskKanbanStatus[]) => {
		if (state.status !== "blocked") {
			setState((state: TasksKanbanState) => ({
				...state,
				status: "loading",
			}));
		}
		if (columns.length) {
			await Promise.all(columns.map((status) => getColumn(status)));
		}

		setState((state: TasksKanbanState) => ({
			...state,
			status: "idle",
		}));
	};

	const getColumn = async (
		status: ApiTaskKanbanStatus,
		params?: { page?: number },
		mode?: "merge" | "reset"
	) => {
		if (!Object.keys(state.columns).length) return;
		setState((s: TasksKanbanState) => ({
			...s,
			columns: {
				...s.columns,
				[status]: {
					...s.columns[status],
					status: "loading",
				},
			},
		}));

		let { data: newTasks, meta } = await api
			.get(`/kanban/${status}`, {
				params: {
					...params,
					...filter,
				},
			})
			.then(({ data }) => data);

		const currentColumn = state.columns[status];

		const column = {
			tasks: currentColumn?.tasks || [],
			meta,
			status: "idle",
			params,
		};

		if (!mode || mode === "merge") {
			//Update the current state with new fetched items
			const currentTasks = (currentColumn?.tasks || []).map((current) => {
				const newTask = newTasks.find(
					(task: any) => task.id === current.id
				);
				return newTask?.id ? newTask : current;
			});

			//Filter out the tasks that are already in state
			if (currentTasks.length) {
				newTasks = newTasks.filter((item: any) => {
					return !currentTasks.find(
						(current) => current.id === item.id
					);
				});
			}
			column.tasks = [...currentTasks, ...newTasks];
		} else {
			column.tasks = newTasks;
		}

		setState((s: TasksKanbanState) => ({
			...s,
			columns: {
				...s.columns,
				[status]: column,
			},
		}));

		return column;
	};

	const setStatus = ({ from, to, id }: ActiveDraggableTask) => {
		if (!Object.keys(state.columns).length) return;
		if (from && to) {
			setState((state: TasksKanbanState) => {
				const item = state.columns[from].tasks.find(
					(row: any) => row.id === id
				);
				const columns = state.columns;
				const fromMeta = columns[from]?.meta || {};

				columns[from] = {
					...columns[from],
					tasks: columns[from].tasks.filter((r: any) => r.id !== id),
					meta: {
						...fromMeta,
						total: fromMeta?.total > 0 ? fromMeta.total - 1 : 0,
					},
				};

				if (item) {
					const toMeta = columns[to]?.meta || {};

					columns[to] = {
						...columns[to],
						tasks: [item, ...columns[to].tasks],
						meta: {
							...toMeta,
							total: (toMeta?.total || 0) + 1,
						},
					};
				}

				return {
					...state,
					columns,
				};
			});
		}
	};

	const updateStatus = async (params: ActiveDraggableTask) => {
		if (!params.to) {
			return;
		}

		if (!params.from) params.from = "BACKLOG";

		setStatus(params);
		return api.patch(`/${params.id}/status`, {
			status: params.to,
		});
	};

	const createEditableTask = (status: ApiTaskKanbanStatus) => {
		setState((state: TasksKanbanState) => {
			const meta = state?.columns[status]?.meta || {};
			const tasks = state?.columns[status]?.tasks || [];
			return {
				...state,
				columns: {
					...state?.columns,
					[status]: {
						...state?.columns[status],
						tasks: [
							{
								_id: uid(),
								status,
								title: "",
								// id: -1,
								tags: [],
								type: "INTERNAL",
								mode: "NEW",
								registrations: null,
								matches: null,
								links: {
									assignees: [],
								},
							},
							...tasks,
						],
						meta: {
							...meta,
							total: (meta?.total || 0) + 1,
						},
					},
				},
			};
		});
	};

	const updateNewItem = (_id: string, data: ApiTaskKanban) => {
		const status = data.status || "BACKLOG";
		setState((state: TasksKanbanState) => {
			const tasks = state?.columns[status]?.tasks || [];
			const meta = state?.columns[status]?.meta || {};
			return {
				...state,
				columns: {
					...state?.columns,
					[status]: {
						...state?.columns[status],
						tasks: tasks.map((item) => {
							return item._id === _id ? data : item;
						}),
						meta: {
							...meta,
						},
					},
				},
			};
		});
	};

	const removeNewItem = (_id: string, status: ApiTaskKanbanStatus) => {
		setState((state: TasksKanbanState) => {
			const meta = state?.columns[status]?.meta || {};
			const tasks = state?.columns[status]?.tasks || [];
			return {
				...state,
				columns: {
					...state?.columns,
					[status]: {
						...state?.columns[status],
						tasks: tasks.filter((item) => {
							return item._id !== _id;
						}),
						meta: {
							...meta,
							total: (meta?.total || 1) - 1,
						},
					},
				},
			};
		});
	};

	return {
		atom: tasksKanbanAtom,
		state,
		api,
		actions: {
			getAllStatuses,
			getColumn,
			getColumns,
			updateStatus,
			setStatus,
			setState,
			reset,
			createEditableTask,
			updateNewItem,
			removeNewItem,
		},
	};
}
