import { useTranslation } from "react-i18next";
import FlatList from "src/components/FlatList";
import Link from "src/components/Link";
import ListItem from "src/components/lists/list-items/ListItem";
import Skeleton from "src/components/Skeleton";
import Stars from "src/components/Stars";
import useGet from "src/hooks/rest/useGet";
import useAuth from "src/hooks/selectors/useAuth";
import { cutString } from "src/lib/formatters";

interface RegistrationListProps {
	limit: number;
	query?: string;
}

const RegistrationList = ({ limit, query }: RegistrationListProps) => {
	const { t } = useTranslation("lists", {
		keyPrefix: "registration-list",
	});
	const auth = useAuth();
	const [result, loading, { pagination }] = useGet(
		`/${auth.type}/registrations?limit=${limit}&include[]=task&include[]=user&${query}`,
		{ data: [] },
		true
	);
	const { data } = result;

	return (
		<FlatList
			noInfiniteScroll
			gap={16}
			{...{ loading, pagination }}
			data={data || []}
			empty={{
				title: t("empty.title"),
			}}
			loadingRows={limit - 1}
			renderLoadingItem={(index) => (
				<div
					key={`registration-list-loading-${index}`}
					className={`flex flex-col gap-2 p-4 transition-all rounded-md bg-accent`}
				>
					<div className="flex gap-2">
						<div className="flex justify-center items-center">
							<Skeleton bg="background" className="w-10 h-10" />
						</div>
						<div className="flex flex-1 gap-1 flex-col">
							<Skeleton bg="background" className="w-2/4 h-5" />
							<Skeleton bg="background" className="w-10 h-4" />
						</div>
					</div>
					<div className="flex flex-col">
						<Skeleton bg="background" className="w-full h-4" />
						<Skeleton bg="background" className="w-full h-4 mt-2" />
						<div className="flex justify-end mt-2">
							<Skeleton bg="background" className="w-20 h-4" />
						</div>
					</div>
				</div>
			)}
			renderItem={(row: any) => (
				<ListItem
					to={`/tasks/${row?.task?.hid}/show/${row?.hid}`}
					bordered
					align="start"
					key={row.id + row.hid}
				>
					<ListItem.Avatar size="small" src={row?.user?.avatar} />
					<ListItem.Content>
						<div className="flex flex-wrap gap-1 justify-between">
							<ListItem.Title>
								{row?.user?.full_name}
							</ListItem.Title>
							<div className="mr-8">
								{row?.user?.review > 0 && (
									<Stars rating={row?.user?.review} />
								)}
							</div>
						</div>
						<ListItem.Text variant="small" className="line-clamp-2">
							{row.excerpt}
						</ListItem.Text>
						<div className="flex w-max p-1 bg-accent rounded-md mt-1">
							<Link
								to={`/tasks/${row?.task?.hid}/show/${row?.hid}`}
								className="block text-sm opacity-70 line-clamp-1"
							>
								{cutString(row?.task?.title, 25)}
							</Link>
						</div>
					</ListItem.Content>
				</ListItem>
			)}
		/>
	);
};

RegistrationList.defaultProps = {
	limit: 4,
};

export default RegistrationList;
