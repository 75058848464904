import { useEffect } from "react";
import { useCookies } from "react-cookie";
import useQueryString from "src/hooks/useQueryString";
import { createCookie } from "src/hooks/useTokenListener";

export default function useStoreSearchParams() {
	const qs = useQueryString();
	const [, setCookie] = useCookies();

	useEffect(() => {
		if (qs.referral) {
			setCookie("referral", qs.referral, createCookie());
		}
		if (qs.ref) {
			localStorage.setItem("referral_type", qs.ref.toString());
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
}
