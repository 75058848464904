import moment, { Moment } from "moment";

const FORMAT = "YYYY-MM-DD";

export const getHoursBetweenDates = (start: string, end: string) => {
	return moment.duration(moment(end).diff(moment(start))).asHours();
};

export const getDatesBetweenDates = (start: Moment, end: Moment) => {
	const dates = [];
	const days = end.startOf("day").diff(start.startOf("day"), "days", true);

	for (let index = 0; index <= days; index++) {
		dates.push(moment(start).add(index, "day"));
	}

	return dates;
};

export const isToday = (date: Moment) => {
	return date.format(FORMAT) === moment().format(FORMAT);
};
