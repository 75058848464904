import { Route } from "react-router-dom";
import ProtectedRoute from "src/routes/middlewares/ProtectedRoute";
import ProductsView from "src/views/authenticated/products/ProductsView";

const ProductsRoutes = [
	<Route
		element={
			<ProtectedRoute>
				<ProductsView />
			</ProtectedRoute>
		}
		path="/products"
		key="/products"
	/>,
];

export default ProductsRoutes;
