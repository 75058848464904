import { stagger, useAnimate } from "framer-motion";
import { useEffect } from "react";
import Tag from "src/components/Tag";
import useExampleTask from "src/hooks/data/useExampleTasks";

const staggerItems = stagger(0.2, { startDelay: 0.4 });

interface ExamplesProps {
	onSelect: (value: string) => void;
}

const Examples = ({ onSelect }: ExamplesProps) => {
	const examples = useExampleTask();
	const [scope, animate] = useAnimate();

	useEffect(() => {
		animate(
			".stagger-in",
			{
				opacity: 1,
				scale: 1,
			},
			{
				delay: staggerItems,
			}
		);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<div ref={scope} className="flex flex-wrap gap-3 my-3">
			{examples.randomItems.map((item, index) => (
				<Tag
					key={`random-${index}`}
					className="bg-accent border-border border cursor-pointer hover:bg-primary hover:text-primary-foreground text-background-foreground text-sm font-regular stagger-in opacity-0 scale-90"
					onClick={() => {
						onSelect(item.title);
					}}
				>
					{item.title}
				</Tag>
			))}
		</div>
	);
};

export default Examples;
