import { useEffect } from "react";
import Button from "src/components/Button";
import EmptyState from "src/components/EmptyState";
import Loader from "src/components/Loader";
import DeleteCustomFormButton from "src/components/custom-form/DeleteCustomFormButton";
import Field from "src/components/field/Field";
import FormGrid from "src/components/form/FormGrid";
import withTranslation, {
	Translation,
} from "src/components/hoc/withTranslation";
import Layout from "src/components/layout/Layout";
import useForm from "src/hooks/api/services/forms/useForm";
import useSettings from "src/hooks/api/services/useSettings";

function PaymentRequestsView({ t }: Translation) {
	const { forms, actions, status } = useForm({
		rememberId: "FORM_PAYMENT_REQUESTS",
		filter: {
			type: "PAYMENT_REQUEST",
		},
	});

	const { settings, actions: settingsActions } = useSettings({
		type: "COMPANY",
	});

	useEffect(() => {
		actions.list();
		settingsActions.get();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<Layout container="xxl" title={t("title")}>
			<Layout.SettingsNav />
			<FormGrid>
				<FormGrid.Header title={t("addons.title")}>
					<p className="opacity-70">{t("addons.description")}</p>
				</FormGrid.Header>
				<FormGrid.Card>
					{forms.length > 0 && (
						<div className="flex flex-col gap-4">
							<div className="flex items-center gap-4 justify-between">
								<strong>{t("addons.all")}</strong>
								<Button
									xsmall
									type="border"
									to={{
										modal: "/settings/payment-requests/addons/create",
									}}
								>
									{t("addons.buttons.create")}
								</Button>
							</div>
							<div className="flex flex-col gap-4">
								{forms.map((form) => (
									<div
										key={form.id}
										className="flex gap-6 p-6 border border-border rounded-md items-center"
									>
										<div className="flex flex-col flex-1">
											<strong>{form.name}</strong>
											{form.description && (
												<p className="opacity-70">
													{form.description}
												</p>
											)}
										</div>
										<div className="flex items-center gap-3">
											<DeleteCustomFormButton
												onDelete={async () =>
													await actions.delete(
														form.id
													)
												}
											/>
											<Button
												to={`/settings/payment-requests/addons/${form.id}`}
												xsmall
												type="border"
											>
												{t("addons.buttons.view")}
											</Button>
										</div>
									</div>
								))}
							</div>
						</div>
					)}
					{status === "idle" && forms.length === 0 && (
						<EmptyState.Overlay className="p-6 relative">
							<EmptyState.Title>
								{t("addons.empty.title")}
							</EmptyState.Title>
							<EmptyState.Description>
								{t("addons.empty.description")}
							</EmptyState.Description>
							<EmptyState.Buttons>
								<Button
									to={{
										modal: "/settings/payment-requests/addons/create",
									}}
								>
									{t("addons.buttons.create")}
								</Button>
							</EmptyState.Buttons>
						</EmptyState.Overlay>
					)}
					{status === "loading" && forms.length === 0 && (
						<div className="flex flex-col justify-center items-center p-8">
							<Loader />
						</div>
					)}
				</FormGrid.Card>

				<FormGrid.Header title={t("settings.title")}>
					<p className="opacity-70">{t("settings.description")}</p>
				</FormGrid.Header>
				<FormGrid.Card>
					<div className="flex flex-col gap-4">
						<div className="flex items-center gap-4 justify-between">
							<strong>{t("settings.title")}</strong>
						</div>

						<div className="flex flex-col gap-4">
							<div className="flex items-center w-full gap-4">
								<Field.Switch
									wrapper={{
										layout: "vertical",
										label: t(
											"settings.create_from_task.title"
										),
									}}
									value={
										settings.payment_requests
											?.only_from_task
									}
									onChange={(value) =>
										settingsActions.update(
											"payment_requests.only_from_task",
											value
										)
									}
								/>
								{settingsActions.isLoading(
									"payment_requests.only_from_task"
								) && <Loader />}
							</div>
						</div>
					</div>
				</FormGrid.Card>
			</FormGrid>
		</Layout>
	);
}

PaymentRequestsView.locale = {
	nl: {
		title: "Betaalverzoeken",
		addons: {
			title: "Add-ons",
			all: "Alle add-ons",
			description:
				"Laat opdrachtnemers reiskosten, vergoedingen en andere velden invullen.",
			empty: {
				title: "Je hebt nog geen add-ons",
				description:
					"Voeg een add-on toe om je opdrachtnemers te helpen met hun reiskosten, vergoedingen en andere velden.",
			},
			buttons: {
				view: "Bekijk",
				create: "Aanmaken",
			},
		},
		settings: {
			title: "Instellingen",
			description:
				"Configureer de betaalverzoek instellingen naar je wens",

			create_from_task: {
				title: "Betaalverzoeken enkel vanuit opdracht",
			},
		},
	},
	en: {
		title: "Payment Requests",
		addons: {
			title: "Add-ons",
			all: "All Add-ons",
			description:
				"Allow contractors to fill in travel expenses, allowances, and other fields.",
			empty: {
				title: "You have no add-ons yet",
				description:
					"Add an add-on to help your contractors with their travel expenses, allowances, and other fields.",
			},
			buttons: {
				view: "View",
				create: "Create",
			},
		},
		settings: {
			title: "Settings",
			description:
				"Configure the payment request settings to your liking",
		},
	},
};

export default withTranslation(PaymentRequestsView);
