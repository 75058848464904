import { atom, useAtom } from "jotai";
import { atomFamily } from "jotai/utils";
import { ApiTask } from "src/api/types";
import useLimits from "src/hooks/api/services/auth/useLimits";
import useParseUpdateTaskData from "src/hooks/api/services/tasks/task/useParseUpdateTaskData";
import useTenant from "src/hooks/api/services/tenants/useTenant";
import axios, { createApiInstance } from "src/lib/api";

const defaultSearchParams = {
	with: [
		"answers",
		"description_obj",
		"assignees",
		"teams",
		"skills",
		"creator",
		"company",
		"parent",
	],
};

//create a function that merges 2 objects deep
const merge = (a: any, b: any) => {
	const c = { ...a, ...b };
	Object.keys(c).forEach((key) => {
		if (
			a[key] &&
			b[key] &&
			typeof a[key] === "object" &&
			!Array.isArray(a[key])
		) {
			c[key] = merge(a[key], b[key]);
		}
	});
	return c;
};

type ApiStatus = "idle" | "loading" | "creating" | "updating" | "error";

export type ApiState = {
	task: ApiTask;
	update: {
		[key: string]: any;
	};
	status: ApiStatus;
};

export const apiAtomFamily = atomFamily(
	(data: any) => atom({ ...({} as ApiState), status: "loading", ...data }),
	(a: any, b: any) => a.id === b.id
);

interface Props {
	id?: string | "create";
}

export default function useTask(props: Props) {
	const api = createApiInstance({
		baseURL: `${axios.defaults.baseURL}/client/tasks`,
	});
	const limits = useLimits();
	const {
		tenant: { modules, slug: tenantSlug },
	} = useTenant();
	const mode: "EDIT" | "CREATE" = props.id !== "create" ? "EDIT" : "CREATE";
	const [state, setState] = useAtom(apiAtomFamily(props));
	const parseUpdateData = useParseUpdateTaskData();
	//Task Actions
	const setStatus = (status: ApiStatus) => {
		setState((prev: ApiState) => ({
			...prev,
			status,
		}));
	};

	const change = (data: any) => {
		setState((prev: any) => {
			return {
				...prev,
				update: {
					...(prev.update || {}),
					...(data || {}),
				},
			};
		});
	};

	const get = async () => {
		if (!props.id || mode === "CREATE") {
			setStatus("idle");
			return;
		}

		if (!state.id) {
			setStatus("loading");
		}

		try {
			const { data } = await api.get(`/${props.id}`, {
				params: defaultSearchParams,
			});
			setState((prev: ApiState) => ({
				...prev,
				status: "idle",
				task: data?.data,
			}));
			return data?.data;
		} catch (error) {
			setStatus("error");
		}
	};

	const create = async (value: any) => {
		if (mode === "EDIT") return;
		setStatus("creating");
		try {
			const updateData = await parseUpdateData(value, "CREATE");
			const { data } = await api.post(`/`, updateData, {
				params: defaultSearchParams,
			});
			limits.actions.get();
			setState((prev: ApiState) => ({
				...prev,
				task: {},
				update: {},
				status: "loading",
			}));
			return data?.data;
		} catch (error) {
			setStatus("error");
		}
	};

	const update = async (value: any) => {
		if (mode === "CREATE") return;
		try {
			const updateData = await parseUpdateData(value, "UPDATE");
			const { data } = await api.patch(`/${props.id}`, updateData, {
				params: defaultSearchParams,
			});

			setState((prev: ApiState) => {
				const update = prev.update || {};

				//Remove the value from the update object
				Object.keys(value).forEach((key) => {
					if (value[key]) {
						delete update[key];
					}
				});

				return {
					...prev,
					status: "idle",
					task: data?.data,
					update,
				};
			});
			return data?.data;
		} catch (error) {
			setStatus("error");
		}
	};

	const archive = async () => {
		if (mode === "CREATE") return;
		try {
			await api.delete(`/${props.id}`);
			setState((prev: ApiState) => ({
				...prev,
				status: "idle",
				task: {},
				update: {},
			}));
			return true;
		} catch (error) {
			setStatus("error");
		}
	};

	const duplicate = async () => {
		if (mode === "CREATE") return;
		try {
			const { data } = await api.post(`/${props.id}/duplicate`);
			setState((prev: ApiState) => ({
				...prev,
				status: "idle",
				update: {},
			}));
			return data?.data;
		} catch (error) {
			setStatus("error");
		}
	};

	const indexSkills = async () => {
		if (!modules.includes("intern-task") || tenantSlug === "getjobsdone") {
			return null;
		}
		const { data } = await api
			.post(`/ai/${props.id}/skills`)
			.then(({ data }) => data);

		setState((prev: ApiState) => ({
			...prev,
			status: "idle",
			task: {
				...prev.task,
				skills: data,
			},
		}));
		return data;
	};

	return {
		id: props.id,
		status: state.status,
		mode,
		update: state.update,
		task: merge(state.task || {}, state.update || {}),
		actions: {
			change,
			get,
			create,
			update,
			archive,
			duplicate,
			indexSkills,
		},
	};
}
