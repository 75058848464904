import { useEffect } from "react";
import Select, {
	SelectSettings,
	SelectTheme,
	SelectValue,
} from "src/components/field/fields/Select";
import { WrapperProps } from "src/components/field/utils/Wrapper";
import withTranslation, {
	Translation,
} from "src/components/hoc/withTranslation";
import useTags from "src/hooks/api/services/useTags";
import TagElement from "src/components/Tag";
import { ApiTag } from "src/api/types";

interface TagProps extends Translation {
	canCreate?: boolean;
	wrapper?: WrapperProps;
	value?: SelectValue;
	onChange: (value?: SelectValue) => void;
	disabled?: boolean;
	theme?: SelectTheme;
	settings?: SelectSettings;
}

const Tag = ({
	t,
	canCreate,
	wrapper,
	value,
	onChange,
	disabled,
	theme,
	settings,
}: TagProps) => {
	const { tags, search, status, actions } = useTags();
	const options = [
		...(Array.isArray(value) && !search ? value : []),
		...tags.map((item: ApiTag) => item.tag),
	];

	useEffect(() => {
		if (status === "idle" && !tags.length) {
			actions.list();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<Select
			{...{ wrapper, value, disabled }}
			settings={{
				...(settings || {}),
				stayOpen: true,
			}}
			onChange={(value) => {
				if (value) {
					onChange(value);
				} else {
					onChange(undefined);
				}
				actions.list({
					q: null,
				});
			}}
			onClose={() => {
				actions.list({
					q: null,
				});
			}}
			search={{
				autoFocus: true,
				value: search,
				loading: status !== "idle",
				placeholder: t("search.placeholder"),
				onChange: (search) =>
					actions.list({
						q: search,
					}),
			}}
			create={
				search &&
				canCreate && {
					value: search,
					render: (
						<div className="flex items-center gap-2">
							<i className="fas fa-plus text-sm opacity-40"></i>
							<TagElement size="small" theme="primary">
								{search}
							</TagElement>
						</div>
					),
				}
			}
			options={options
				.filter((tag, index) => options.indexOf(tag) === index)
				.map((tag) => ({
					value: tag.toString(),
					label: (
						<TagElement size="small" theme="dark">
							{tag}
						</TagElement>
					),
				}))}
			empty={{
				description: t("empty.description"),
			}}
			theme={{
				...(theme || {}),
				item: {
					indicator: true,
				},
				value: {
					renderValue: () => (
						<>
							{value &&
								Array.isArray(value) &&
								(value || []).map(
									(tag: string, index: number) => {
										if (!tag) {
											return <></>;
										}
										return (
											<TagElement
												key={`value-${tag}-${index}`}
												size="small"
												theme="dark"
											>
												{tag}
											</TagElement>
										);
									}
								)}
						</>
					),
				},
			}}
		/>
	);
};

Tag.locale = {
	nl: {
		search: {
			placeholder: "Zoeken / aanmaken",
		},
		empty: {
			description: "Er zijn nog geen tags aangemaakt",
		},
	},
	en: {
		search: {
			placeholder: "Search / create",
		},
		empty: {
			description: "No tags have been created yet",
		},
	},
};

export default withTranslation(Tag);
