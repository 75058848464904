import { atom, useAtom } from "jotai";
import _ from "lodash";

const selectedAtom = atom<number[]>([]);

function useSelectRegistrations() {
	const [selected, setSelected] = useAtom(selectedAtom);
	const toggle = (id: number) => {
		setSelected(_.xor(selected, [id]));
	};
	return {
		selected,
		actions: {
			toggle,
			set: setSelected,
		},
	};
}

export default useSelectRegistrations;
