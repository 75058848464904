const resume = {
	resume: {
		empty: {
			title: "We hebben geen profiel kunnen vinden",
		},
		titles: {
			bio: "Over mij",
			education: "Opleidingen & cursussen",
			social: "Social media",
			job: "Werkervaring",
			reviews: "Reviews",
			skills: "Vaardigheden",
			badges: "Badges",
		},
	},
	"edit-bio": {
		label: "Introduceer jezelf aan je toekomstige opdrachtgever",
	},
	"resume-badges": {
		empty: {
			title: "Geen badges gevonden",
		},
	},
	"resume-experiences-list": {
		create: "Aanmaken",
		job: {
			add: "Werkervaring toevoegen",
			empty: {
				title: "Geen werkervaringen gevonden",
			},
		},
		education: {
			add: "Toevoegen",
			empty: {
				title: "Geen opleidingen of cursussen gevonden",
			},
		},
	},
	"resume-reviews": {
		empty: {
			title: "Geen reviews gevonden",
		},
	},
	"resume-skills": {
		empty: {
			title: "Geen vaardigheden gevonden",
		},
		form: {
			label: "Type een skill zoals bijvoorbeeld 'Word' en druk daarna op enter",
			button: "Toevoegen",
		},
	},
	"resume-social-media": {
		title: "Social media",
		"error-delete": {
			title: "Social media verwijderen is mislukt",
		},
		"error-save": {
			title: "Social media opslaan is mislukt",
			text: "Klopt de profiellink?",
		},
		empty: {
			title: "Geen socials gevonden",
		},
		form: {
			placeholder: "Link naar jouw {{name}} pagina",
			button: "Opslaan",
		},
	},
};

export default resume;
