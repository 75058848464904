import axios from "axios";
import { uniqueId } from "lodash";
import { useState } from "react";
import Turnstile, { useTurnstile } from "react-turnstile";
import Button from "src/components/Button";
import withTranslation, {
	Translation,
} from "src/components/hoc/withTranslation";
import Loader from "src/components/Loader";
import DEFAULT_JOB_OFFERS from "src/components/tools/job-offer-converter/data";
import JobOfferDefaultTabs from "src/components/tools/job-offer-converter/JobOfferDefaultTabs";
import JobOfferEditor from "src/components/tools/job-offer-converter/JobOfferEditor";
import JobOfferTaskItem, {
	JobOfferTask,
} from "src/components/tools/job-offer-converter/JobOfferTaskItem";
import useAuthSession from "src/hooks/api/services/session/useAuthSession";
import useAuth from "src/hooks/selectors/useAuth";
import { cn } from "src/lib/utils";
import untruncateJson from "untruncate-json";

const keys = Object.keys(DEFAULT_JOB_OFFERS) as any;

const JobOfferConverterView = ({ t }: Translation) => {
	const isInIframe = window.self !== window.top;
	const [editorKey, setEditorKey] = useState(uniqueId());

	const [value, setValue] = useState<string>(
		DEFAULT_JOB_OFFERS["content-marketeer"].value
	);
	const disabled = !value;
	const auth = useAuth();
	const turnstile = useTurnstile();
	const [turnstileResponse, setTurnstileResponse] = useState<string>();
	const [error, setError] = useState<"NO_TASKS_CREATED">();
	const [jobOfferId, setJobOfferId] = useState<string>();
	const [tasks, setTasks] = useState([]);
	const [loading, setLoading] = useState(false);
	const [submitted, setSubmitted] = useState(false);
	const [isCompleted, setIsCompleted] = useState(false);
	const {
		session,
		actions: sessionActions,
		status: sessionStatus,
	} = useAuthSession();
	const isFinished = (tasks || [])?.length > 0 && !loading;

	const refreshEditorValue = () => {
		setEditorKey(uniqueId());
	};

	const setRandomJobOffer = () => {
		const key = keys[
			Math.floor(Math.random() * keys.length)
		] as keyof typeof DEFAULT_JOB_OFFERS;
		const offer = DEFAULT_JOB_OFFERS[key];
		setValue(offer.value);
		refreshEditorValue();
	};

	const handleSubmit = async () => {
		if (disabled || loading) return;

		//Get the turnstile response
		if (!turnstileResponse && process.env.NODE_ENV === "production") return;
		setError(undefined);
		setLoading(true);
		setSubmitted(true);
		setTasks([]);

		axios
			.post(
				`/tools/job-offer-to-tasks`,
				{
					turnstile:
						process.env.NODE_ENV === "production"
							? turnstileResponse
							: "123",
					description: value,
				},
				{
					responseType: "stream",
					onDownloadProgress: function (progressEvent) {
						const response = progressEvent.currentTarget.response;
						const data = JSON.parse(untruncateJson(response));
						setTasks(data?.tasks);
						setJobOfferId(data?.job_offer_id);
					},
				}
			)
			.then((result) => {
				const { data } = result;
				if (!data) {
					return;
				}
				if (!data?.tasks || !data?.tasks?.length) {
					setError("NO_TASKS_CREATED");
				}
				setJobOfferId(data?.job_offer_id);
				// scrollToId("output-bottom");
				setTasks(data?.tasks);
				return data?.data;
			})
			.finally(() => {
				if (turnstile) {
					turnstile.reset();
				}
				setLoading(false);
			});
	};

	const handleCreateDirect = async () => {
		if (!auth.id || isInIframe) return;

		//Format the tasks
		try {
			await axios.post("/client/tasks/multiple", {
				tasks: tasks.map((task: JobOfferTask) => ({
					...task,
					budget: task.estimated_duration * task.hour_rate,
					id: undefined,
					type: "PUBLISHED",
				})),
			});
			setIsCompleted(true);
		} catch (error) {}
	};

	const onCreateAuthSession = async () => {
		const referral_type = "JOB_OFFER_CONVERTER";
		const value = {
			type: "COMPANY" as const,
			referral_type,
			job_offer_id: jobOfferId,
			task: tasks.map((task: JobOfferTask) => ({
				...task,
				budget: task.estimated_duration * task.hour_rate,
			})),
		};
		if (!session?.id) {
			const newSession = await sessionActions.create(value);
			if (!newSession?.id) {
				return null;
			}
			return sessionActions.redirect(newSession.id);
		}
		await sessionActions.update(session?.id, value);
		return sessionActions.redirect(session.id);
	};

	return (
		<div className="flex flex-col md:flex-row flex-1">
			<div
				id="input"
				className={cn(
					"flex flex-col flex-1 pt-3 px-3 md:px-4 md:pt-4 gap-4 md:shadow md:border-r border-accent z-20 relative",
					!isInIframe && "bg-card rounded-md"
				)}
			>
				<JobOfferDefaultTabs
					{...{ value }}
					onChange={(value) => {
						setValue(value);
						refreshEditorValue();
					}}
				/>
				<hr className="border-border" />
				<div className="flex gap-3">
					<Button
						type="border"
						xsmall
						onClick={() => {
							setValue("");
							refreshEditorValue();
						}}
					>
						<i className="far fa-trash"></i>
						{t("actions.empty")}
					</Button>
					<Button type="border" xsmall onClick={setRandomJobOffer}>
						<i className="far fa-random"></i>
						{t("actions.random")}
					</Button>
				</div>
				<div
					className={cn(
						"flex flex-col flex-1 relative",
						isInIframe && "max-h-[460px] md:max-h-[560px]"
					)}
				>
					<div className="flex flex-col flex-1 overflow-y-auto absolute top-0 bottom-0 -left-3 -right-3 px-3 md:-left-4 md:-right-4 md:px-4">
						<JobOfferEditor
							key={editorKey}
							{...{ value }}
							onChange={setValue}
						/>
					</div>
				</div>
			</div>
			<div className="flex-1 flex flex-col p-3 md:p-4 relative md:overflow-auto">
				{process.env.REACT_APP_CLOUDFLARE_SITE_KEY && (
					<Turnstile
						sitekey={process.env.REACT_APP_CLOUDFLARE_SITE_KEY}
						onVerify={setTurnstileResponse}
					/>
				)}
				{(tasks || [])?.length === 0 && !loading && (
					<div className="flex flex-col md:max-w-md mx-auto gap-6 justify-center items-center p-6">
						<p className="text-center text-[20px] opacity-70">
							{t("generate.title")}
						</p>

						<Button
							disabled={!value}
							onClick={handleSubmit}
							{...{ loading }}
							className="text-xl hover:scale-[1.01] gap-4 py-4 px-6"
						>
							<i className="fas fa-sparkles text-xl"></i>
							<span>{t("generate.button")}</span>
						</Button>
					</div>
				)}
				<div
					className={cn(
						"flex flex-col justify-center items-center flex-1 pointer-events-none absolute inset-0 opacity-0",
						loading && "opacity-30"
					)}
				>
					<div className="max-w-[200px]">
						<Loader variant="ai" />
					</div>
				</div>
				<div className="flex flex-col z-10 relative flex-1 py-4 px-3 md:px-6 md:pt-16">
					{submitted && (
						<div className="flex flex-col gap-6">
							{error && (
								<div className="flex flex-col flex-1 gap-4 p-8">
									<h3>{t(`errors.${error}.title`)}</h3>
									<p>{t(`errors.${error}.description`)}</p>
								</div>
							)}

							{tasks?.length > 0 && (
								<div className="flex flex-col gap-12">
									<div
										className={cn(
											"flex flex-col gap-2 overflow-hidden transition-all md:opacity-0",
											isFinished &&
												"opacity-100 md:opacity-100"
										)}
									>
										<h3>{t("output.title")}</h3>
										<p className="text-lg opacity-70 md:text-wrap-balance">
											{t("output.description")}
										</p>
										<Button
											onClick={() => handleSubmit()}
											disabled={loading}
											xsmall
											type="border"
											className="mt-2"
										>
											<i className="fas fa-sync"></i>
											{t("actions.retry")}
										</Button>
									</div>
									<div className="flex flex-col gap-5">
										{(tasks || []).map((task, index) => (
											<JobOfferTaskItem
												key={`task-${index}`}
												{...{ task }}
												onDelete={() =>
													setTasks(
														tasks.filter(
															(item, i) =>
																i !== index
														)
													)
												}
											/>
										))}
									</div>
								</div>
							)}
						</div>
					)}
					{!loading && tasks?.length > 0 && !auth?.id && (
						<div className="flex flex-col gap-3 justify-center items-center mt-12">
							<Button
								className="w-full"
								loading={sessionStatus !== "idle"}
								onClick={() => {
									onCreateAuthSession();
								}}
							>
								{t("actions.signup")}
							</Button>
							<div className="flex gap-2 pl-12">
								<img
									className=""
									src="/arrow.svg"
									alt={t("actions.disclaimer")}
								/>
								<span className="flex-1">
									{t("actions.disclaimer")}
								</span>
							</div>
						</div>
					)}

					{!isInIframe && auth.id && !loading && tasks.length > 0 && (
						<div className="flex flex-col mt-12">
							<Button onClick={handleCreateDirect}>
								{t("actions.create-direct")}
							</Button>
						</div>
					)}
					{isCompleted && (
						<div className="flex flex-col p-6 gap-4 mt-12 bg-primary text-primary-foreground rounded-md">
							<div className="flex flex-col gap-1">
								<p>{t("completed-direct.title")}</p>
								<p className="opacity-80">
									{t("completed-direct.description")}
								</p>
							</div>
							<Button to={`/tasks`} className="w-max">
								{t("completed-direct.button")}
							</Button>
						</div>
					)}
				</div>
				<div id="output" className="md:hidden flex"></div>
			</div>
		</div>
	);
};

JobOfferConverterView.locale = {
	nl: {
		actions: {
			empty: "Leeg",
			random: "Willekeurig",
			signup: "Opdrachten plaatsen",
			disclaimer: "Geen betaling verplicht",
			retry: "Opnieuw proberen",
			"scroll-to-output": "Scroll naar AI",
			"create-direct": "Opdrachten plaatsen",
		},
		"completed-direct": {
			title: "De opdrachten zijn geplaatst",
			description:
				"De opdrachten zijn geplaatst op het platform. Je kan de opdrachten nog inzien / aanpassen in je dashboard.",
			button: "Opdrachten bekijken",
		},
		generate: {
			title: "Als je je vacature hebt ingevoerd, klik dan op de knop om opdrachten te genereren",
			button: "Genereer opdrachten",
		},
		loggedin: "Ingelogd als {{name}}",
		title: "Vacature tekst",
		description:
			"Laten we een beter oplossing zoeken voor je openstaande vacature",

		output: {
			title: "Opdrachten",
			description:
				"Op basis van eerder uitbesteden opdrachten op ons platform denken we dat je je vacature met deze opdrachten kan opvullen.",
		},
		errors: {
			NO_TASKS_CREATED: {
				title: "Geen opdrachten gevonden",
				description:
					"Er zijn geen opdrachten gevonden in de gegeven vacature tekst. Probeer het opnieuw met een andere vacature tekst.",
			},
		},
	},
	en: {
		actions: {
			empty: "Empty",
			random: "Random",
			signup: "Place Tasks",
			disclaimer: "No payment required",
			retry: "Retry",
			"scroll-to-output": "Scroll to AI",
			"create-direct": "Publish tasks",
		},
		generate: {
			title: "If you have entered your job offer, click the button to generate tasks",
			button: "Generate Tasks",
		},
		loggedin: "Logged in as {{name}}",
		title: "Job offer text",
		description: "Let's find a better solution for your current job offer",

		output: {
			title: "Tasks",
			description:
				"Based on the feedback we received from our platform, we think you should place these tasks instead of your current job offer.",
		},
		errors: {
			NO_TASKS_CREATED: {
				title: "No tasks found",
				description:
					"No tasks were found in the given job offer text. Please try again with a different job offer text.",
			},
		},
	},
};

export default withTranslation(JobOfferConverterView);
