import { useParams } from "react-router-dom";
import withTranslation, {
	Translation,
} from "src/components/hoc/withTranslation";
import EditLayout, {
	EditLayoutDescription,
	EditLayoutHeader,
	EditLayoutTitle,
} from "src/components/resume/edit-layout/EditLayout";
import ExperienceForm from "src/components/resume/elements/experience-form/ExperienceForm";

const DashboardEditExperiencesModal = ({ t }: Translation) => {
	const { type } = useParams() as any;
	if (!["education", "job"].includes(type)) return null;
	return (
		<EditLayout>
			<EditLayoutHeader className="-top-2 md:-top-8">
				<EditLayoutTitle>{t(`${type}.title`)}</EditLayoutTitle>
				<EditLayoutDescription>
					{t(`${type}.description`)}
				</EditLayoutDescription>
			</EditLayoutHeader>
			<div className="flex flex-col mt-8 gap-6">
				<ExperienceForm {...{ type }}>
					<ExperienceForm.List
						getEditTo={({ id, type }) => ({
							modal: `/dashboard/resume/experiences/${type}/${id}/edit`,
						})}
					/>
					<ExperienceForm.EmptyState />
					<div className="flex flex-col gap-3">
						<ExperienceForm.Autocomplete />
						<ExperienceForm.QuickCreate />
					</div>
				</ExperienceForm>
			</div>
		</EditLayout>
	);
};

DashboardEditExperiencesModal.locale = {
	nl: {
		job: {
			title: "Werkervaringen",
			description: "Voeg je werkervaring toe aan je CV",
		},
		education: {
			title: "Opleidingen",
			description: "Voeg je opleiding toe aan je CV",
		},
	},
	en: {
		job: {
			title: "Job experience",
			description: "Add your job experience to your CV",
		},
		education: {
			title: "Education",
			description: "Add your education to your CV",
		},
	},
};

export default withTranslation(DashboardEditExperiencesModal);
