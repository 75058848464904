import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import OfferChat from "src/components/chat/chats/OfferChat";
import Layout from "src/components/layout/Layout";
import useOfferRegistration from "src/hooks/api/useOfferRegistration";
import useSocketListener from "src/hooks/sockets/useSocketListener";
import useWindowSize from "src/hooks/useWindowSize";

export default function TalentDetailView() {
	const { t } = useTranslation("talent-view", {
		keyPrefix: "detail-view",
	});
	const { isTablet } = useWindowSize();
	const { registration, fetchData } = useOfferRegistration();
	useSocketListener(`offer-registration.${registration?.hid}`, () =>
		fetchData(false)
	);

	useEffect(() => {
		fetchData();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<Layout hideCtaButton={isTablet} title={t("title")}>
			{registration && (
				<OfferChat offer={registration.offer} {...{ registration }} />
			)}
		</Layout>
	);
}
