import Badge from "src/components/Badge";
import withTranslation, {
	Translation,
} from "src/components/hoc/withTranslation";
import {
	Tooltip,
	TooltipContent,
	TooltipProvider,
	TooltipTrigger,
} from "src/components/ui/tooltip";

interface AlphaBadgeProps extends Translation {
	children?: any;
}

const AlphaBadge = ({ t }: AlphaBadgeProps) => {
	return (
		<TooltipProvider>
			<Tooltip>
				<TooltipTrigger>
					<Badge variant="border-bg" size="xsmall">
						Alpha
					</Badge>
				</TooltipTrigger>
				<TooltipContent>{t("description")}</TooltipContent>
			</Tooltip>
		</TooltipProvider>
	);
};

AlphaBadge.locale = {
	nl: {
		description:
			"Deze functionaliteit is momenteel in ontwikkeling, resultaten kunnen daardoor afwijken.",
	},
	en: {
		description:
			"This functionality is currently in development; as a result, outcomes may vary.",
	},
};

export default withTranslation(AlphaBadge);
