import axios from "axios";
import { ComponentProps, createContext, useContext, useState } from "react";
import Button, { ButtonProps } from "src/components/Button";
import Input from "src/components/form/Input";
import withTranslation, {
	Translation,
} from "src/components/hoc/withTranslation";
import {
	Dialog,
	DialogContent,
	DialogOverlay,
	DialogPortal,
	DialogTrigger,
} from "src/components/ui/dialog";
import { cn } from "src/lib/utils";

const Context = createContext<any>(null);
const usePlatformFeedbackModal = () => useContext(Context);

type PlatformFeedbackType =
	| "RESUME_SKILLS"
	| "TASK_SKILLS"
	| "REGISTRATION_SCORE";

interface PlatformFeedbackModalProps {
	children?: any;
	trigger?: any;
	type: PlatformFeedbackType;
	data?: any;
}

const PlatformFeedbackModal = ({
	children,
	trigger,
	type,
	data,
}: PlatformFeedbackModalProps) => {
	const [value, setValue] = useState<string>("");
	const [isSubmitting, setIsSubmitting] = useState(false);
	const [isCompleted, setIsComleted] = useState(false);

	const onSubmit = async () => {
		setIsSubmitting(true);
		axios
			.post("/client/feedback", {
				description: value,
				type,
				data,
			})
			.then(() => {
				setValue("");
				setIsComleted(true);
				setTimeout(() => {
					setIsComleted(false);
				}, 2000);
			})
			.finally(() => setIsSubmitting(false));
	};

	return (
		<Context.Provider
			value={{ value, setValue, onSubmit, isSubmitting, isCompleted }}
		>
			<Dialog>
				{trigger}
				<DialogPortal>
					<DialogOverlay className="z-[999]" />
					<DialogContent
						variant="default"
						className="max-w-[460px] gap-1"
					>
						<div className="absolute top-4 right-4 z-[999]">
							<DialogTrigger>
								<Button xsmall type="border">
									<i className="far fa-times"></i>
								</Button>
							</DialogTrigger>
						</div>
						{children}
					</DialogContent>
				</DialogPortal>
			</Dialog>
		</Context.Provider>
	);
};

const Trigger = withTranslation(
	({ t, children, ...rest }: Translation & ButtonProps) => {
		return (
			<DialogTrigger asChild>
				<Button xsmall type="border" {...rest}>
					{t("label") || children}
				</Button>
			</DialogTrigger>
		);
	},
	{
		nl: {
			label: "Feedback",
		},
		en: {
			label: "Feedback",
		},
	}
);

const Title = withTranslation(
	({
		t,
		className,
		children,
		...rest
	}: Translation & ComponentProps<"p">) => {
		return (
			<p {...rest} className={cn("font-bold", className)}>
				{children || t("title")}
			</p>
		);
	},
	{
		nl: {
			title: "Geef ons je feedback",
		},
		en: {
			title: "Give us your feedback",
		},
	}
);

const Description = withTranslation(
	({
		t,
		className,
		children,
		...rest
	}: Translation & ComponentProps<"p">) => {
		return (
			<p {...rest} className={cn("opacity-70", className)}>
				{children || t("description")}
			</p>
		);
	},
	{
		nl: {
			description:
				"Vertel ons wat je hier van vind, zo kunnen we het platform samen met je doorontwikkelen",
		},
		en: {
			description:
				"Tell us what you think about this platform, together we can improve it",
		},
	}
);

const Form = withTranslation(
	({ t, className, ...rest }: Translation & ComponentProps<"form">) => {
		const { value, setValue, isSubmitting, onSubmit, isCompleted } =
			usePlatformFeedbackModal();
		return (
			<form
				{...rest}
				className={cn("flex flex-col gap-4", className)}
				onSubmit={(e) => {
					e.preventDefault();
					onSubmit();
				}}
			>
				<Input
					multiline
					name="description"
					placeholder={t("placeholder")}
					onChange={(_, newValue) => setValue(newValue)}
					{...{ value }}
				/>

				<Button
					loading={isSubmitting}
					disabled={!value}
					submit
					type={isCompleted ? "green" : undefined}
				>
					{isCompleted ? t("completed") : t("button")}
				</Button>
			</form>
		);
	},
	{
		nl: {
			placeholder: "Je bevindingen...",
			button: "Verzenden",
			completed: "Bedankt voor je feedback!",
		},
		en: {
			placeholder: "Your feedback...",
			button: "Send",
			completed: "Thank you for your feedback!",
		},
	}
);

PlatformFeedbackModal.Trigger = Trigger;
PlatformFeedbackModal.Title = Title;
PlatformFeedbackModal.Description = Description;
PlatformFeedbackModal.Form = Form;

export default PlatformFeedbackModal;
