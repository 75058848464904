import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { ApiBenefitRestriction, ApiBenefitUnlock } from "src/api/types";
import Button from "src/components/Button";
import withTranslation, {
	Translation,
} from "src/components/hoc/withTranslation";
import Loader from "src/components/Loader";
import ShareClipboard from "src/components/promotion/share/ShareClipboard";
import useShopBenefits from "src/hooks/api/services/benefits/useShopBenefits";

const ButtonUnlockModal = ({ t }: Translation) => {
	const params = useParams();
	const { actions } = useShopBenefits();
	const [loading, setLoading] = useState(true);
	const [data, setData] = useState<ApiBenefitUnlock | null>(null);
	const [restriction, setRestriction] =
		useState<ApiBenefitRestriction | null>(null);

	useEffect(() => {
		if (!params.id) {
			return;
		}
		setLoading(true);
		setRestriction(null);
		actions
			.unlock(params.id?.toString())
			.then((res) => {
				setData(res);
				setLoading(false);
			})
			.catch((error) => {
				const errorData = error.response?.data;
				const restriction = errorData?.errors?.restriction;
				setRestriction(restriction);
				setLoading(false);
			});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	if (loading) {
		return (
			<div className="flex flex-col justify-center items-center p-8">
				<Loader />
			</div>
		);
	}

	if (!data) {
		if (restriction) {
			return (
				<div className="flex flex-col justify-center items-center px-8 pb-8 pt-12 text-center">
					<p>{t(`restrictions.${restriction}.title`)}</p>
					<p className="opacity-80">
						{t(`restrictions.${restriction}.description`)}
					</p>
					{restriction === "FULL_PROFILE" && (
						<Button to="/dashboard" className="mt-4">
							{t("restrictions.FULL_PROFILE.button")}
						</Button>
					)}
				</div>
			);
		}
		return (
			<div className="flex flex-col justify-center items-center p-8 text-center">
				<p>{t("not-found.title")}</p>
				<p className="opacity-80">{t("not-found.description")}</p>
			</div>
		);
	}

	return (
		<div className="flex flex-col pt-4">
			{data?.variant === "COUPON_CODE" && data?.code && (
				<div className="flex flex-col">
					<div className="flex flex-col mb-4">
						<strong>{data.name}</strong>
						<p className="opacity-80">
							{t("coupon-code.description")}
						</p>
					</div>
					<ShareClipboard url={data.code} />
					<a
						target="_blank"
						href={data.url}
						className="w-full flex items-center gap-2 mt-2 underline"
						rel="noreferrer"
					>
						{t("coupon-code.button")}
						<i className="fas fa-external-link text-sm" />
					</a>
				</div>
			)}

			{data?.variant === "DIRECT_LINK" && data?.url && (
				<div className="flex flex-col">
					<div className="flex flex-col mb-4">
						<strong>{data.name}</strong>
						<p className="opacity-80">
							{t("direct-link.description")}
						</p>
					</div>
					<Button to={data.url} className="w-full">
						{t("direct-link.button")}
						<i className="fas fa-external-link ml-2" />
					</Button>
				</div>
			)}
		</div>
	);
};

ButtonUnlockModal.locale = {
	nl: {
		"not-found": {
			title: "Voordeel niet gevonden",
			description: "Deze korting is niet meer beschikbaar",
		},
		restrictions: {
			FULL_PROFILE: {
				title: "Je kan deze korting niet gebruiken",
				description:
					"Om deze korting te kunnen gebruiken, moet je een volledig profiel hebben.",
				button: "Profiel compleet maken",
			},
		},
		"coupon-code": {
			description: "Kopieer de onderstaande korting",
			button: "Naar de website",
		},
		"direct-link": {
			description:
				"Klik op de onderstaande link om de korting te gebruiken",
			button: "Naar de pagina",
		},
	},
	en: {},
};

export default withTranslation(ButtonUnlockModal);
