import { useAtom } from "jotai";
import { useEffect } from "react";
import useScheme from "src/hooks/schemes/useScheme";

interface FormProps {
	atoms: {
		form: any;
		validate: any;
	};
	scheme: any;
}

type SetMode = "merge" | "override";

function useForm({ atoms, scheme: _scheme }: FormProps) {
	const [form, setForm] = useAtom(atoms.form) as any;
	const [validate, setValidate]: any = useAtom(atoms.validate);
	const setValidState = (
		key: string,
		value: any,
		mode: SetMode = "merge"
	) => {
		setValidate((state: any) => ({
			...state,
			[key]: {
				...(mode === "merge" ? state[key] : {}),
				...value,
			},
		}));
	};
	const setErrors = (errors: any, mode: SetMode = "merge") =>
		setValidState("errors", errors, mode);
	const setValid = (valid: any, mode: SetMode = "merge") =>
		setValidState("valid", valid, mode);
	const setTouched = (touched: any, mode: SetMode = "merge") =>
		setValidState("touched", touched, mode);
	const setIsValid = (isValid: any) =>
		setValidate((state: any) => ({
			...state,
			isValid,
		}));
	const valid = validate.valid || {};
	const errors = validate.errors || {};
	const touched = validate.touched || {};
	const isValid = validate.isValid || false;

	const scheme = useScheme(_scheme);
	// const formScheme = yup.object().shape(scheme);
	const getField = (name: string) => ({
		name,
		value: form[name] || "",
		error: errors[name] && touched[name] ? errors[name] : undefined,
		valid: touched[name] && valid[name] ? valid[name] : undefined,
		touched: true,
		handleBlur: () => {
			setTouched({
				[name]: true,
			});
			validateField(name);
		},
		onFocus: () => {
			setErrors({
				[name]: false,
			});
			setTouched({
				[name]: true,
			});
		},
		handleChange: (e: any) => {
			setTouched({
				[name]: false,
			});
			setForm((state: any) => ({
				...state,
				[name]: e?.target?.value,
			}));
		},
	});

	const validateField = async (name: string) => {
		const result: any = scheme.validate(form);
		if (result === true) {
			setFullValidState(form);
			return;
		}
		setIsValid(false);
		const field = result.find((error: any) => {
			return error.field === name;
		});
		if (field?.message) {
			setErrors({
				[name]: field.message,
			});
			setValid({
				[name]: false,
			});
		} else {
			setErrors({
				[name]: false,
			});
			setValid({
				[name]: true,
			});
		}
	};

	const setFullValidState = (state: any) => {
		const valid = Object.assign(
			{},
			...Object.keys(state).map((key) => ({
				[key]: true,
			}))
		);
		setIsValid(true);
		setErrors({}, "override");
		setValid(valid);
		setTouched(valid);
	};

	const validateForm = async (state?: any) => {
		const result: any = scheme.validate(state);
		//The form is valid
		if (result === true) {
			setFullValidState(state);
			return true;
		}
		setIsValid(false);

		//List the errors
		if (result?.length) {
			const errors = Object.assign(
				{},
				...result.map((error: any) => ({
					[error.field]: error.message,
				}))
			);
			setErrors(errors, "override");
		}
	};

	const checkIfIsValid = async (state?: any) => {
		const result: any = scheme.validate(state);
		if (result === true) {
			setFullValidState(state);
		} else {
			setIsValid(false);
		}
	};

	useEffect(() => {
		checkIfIsValid(form);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [form]);

	useEffect(() => {
		if (scheme && Object.keys(form).length) {
			validateForm(form);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleFormChange = (
		data: any,
		validate = true,
		mode: SetMode = "merge"
	) => {
		if (validate) {
			setTouched(
				Object.assign(
					touched,
					...Object.keys(data).map((k) => ({ [k]: true }))
				)
			);
		}
		setForm({
			...(mode === "merge" ? form : {}),
			...data,
		});
		if (validate) {
			validateForm({
				...form,
				...data,
			});
		}
	};

	const resetForm = (value?: any) => {
		setTouched({}, "override");
		setForm(value || {}, "override");
		setValid({}, "override");
		setErrors({}, "override");
	};

	return {
		form,
		actions: {
			getField,
			setForm: handleFormChange,
			setErrors,
			setTouched,
			validateField,
			resetForm,
		},
		validate: {
			isValid,
			errors,
			valid,
			touched,
		},
	};
}

export default useForm;
