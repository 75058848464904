import Button from "src/components/Button";
import withTranslation, {
	Translation,
} from "src/components/hoc/withTranslation";
import useLayout from "src/hooks/useLayout";

interface SubscribeExternCardProps extends Translation {}

const SubscribeExternCard = ({ t }: SubscribeExternCardProps) => {
	const { layout } = useLayout();
	if (layout.navbar.variant !== "default") {
		return <></>;
	}
	return (
		<div className="rounded-md p-4 m-2 border border-border bg-background dark:bg-border text-background-foreground w-full max-w-[240px] lg:hidden xl:flex flex-col">
			<strong className="flex mb-1">{t("title")}</strong>
			<p
				className="text-sm"
				dangerouslySetInnerHTML={{
					__html: t("description"),
				}}
			/>
			<Button xsmall className="mt-4" to="/subscription" type="primary">
				{t("button")}
			</Button>
		</div>
	);
};

SubscribeExternCard.locale = {
	nl: {
		title: "Upgrade je abonnement",
		description:
			"Besteed taken uit aan meer dan <strong class='text-primary'>{{global.students}} professionals</strong>, en get the job done!",
		button: "Bekijk abonnementen",
	},
	en: {
		title: "Upgrade your payment",
		description:
			"Outsource tasks to more than <strong class='text-primary'>{{global.students}} professionals</strong>, and get the job done!",
		button: "View subscriptions",
	},
};

export default withTranslation(SubscribeExternCard);
