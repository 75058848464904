import { ComponentProps } from "react";
import { useDroppable } from "@dnd-kit/core";
import { AnimatePresence, motion } from "framer-motion";
import { cn } from "src/lib/utils";

interface DraggableDropProps extends ComponentProps<"div"> {
	id: string;
	children?: any;
	theme?: {
		overClassName?: string;
	};
	ghostItemVariant?: "line" | "list-item" | "none";
}

const DraggableDrop = ({
	id,
	theme,
	ghostItemVariant = "line",
	children,
	...rest
}: DraggableDropProps) => {
	const { isOver, setNodeRef: ref } = useDroppable({
		id: id,
	});
	return (
		<div
			{...rest}
			{...{ ref }}
			className={cn(
				"relative flex-1",
				rest?.className,
				isOver && theme?.overClassName
			)}
		>
			{ghostItemVariant === "line" && (
				<div
					style={{ opacity: isOver ? 0.2 : 0 }}
					className="absolute inset-0 h-1 rounded-md bg-primary z-[20]"
				/>
			)}
			{ghostItemVariant === "list-item" && (
				<AnimatePresence>
					{isOver && (
						<motion.div
							initial={{ opacity: 0, height: 0 }}
							animate={{ opacity: 0.1, height: 40 }}
							exit={{ opacity: 0, height: 0 }}
							className="flex w-full rounded-md bg-primary z-[99]"
						/>
					)}
				</AnimatePresence>
			)}
			{typeof children === "function" ? children({ isOver }) : children}
		</div>
	);
};

export default DraggableDrop;
