import { ApiReferralStatus } from "src/api/types";
import withTranslation, {
	Translation,
} from "src/components/hoc/withTranslation";
import { cn } from "src/lib/utils";

const STATUSES = ["SEND", "SIGNED_UP", "COMPLETED"];

interface ReferralStatusProps extends Translation {
	status: ApiReferralStatus;
}

const ReferralStatus = ({ t, status }: ReferralStatusProps) => {
	const index = STATUSES.indexOf(status);
	return (
		<div
			className={cn(
				"flex items-center gap-3",
				status === "PENDING" && "text-border",
				status === "COMPLETED" && "text-green"
			)}
		>
			<div className="flex items-center gap-1">
				{STATUSES.map((status, count) => (
					<Circle key={`status-${status}`} active={index >= count} />
				))}
			</div>
			<div className="md:w-[100px] flex justify-end">{t(status)}</div>
		</div>
	);
};

interface CircleProps {
	active?: boolean;
}

const Circle = ({ active }: CircleProps) => {
	return (
		<div
			className={cn(
				"w-2 h-2 rounded-full bg-accent",
				active && "bg-green"
			)}
		></div>
	);
};

ReferralStatus.locale = {
	nl: {
		PENDING: "In afwachting",
		SEND: "Verzonden",
		SIGNED_UP: "Aangemeld",
		COMPLETED: "Afgerond!",
	},
	en: {
		PENDING: "Pending",
		SEND: "Send",
		SIGNED_UP: "Signed up",
		COMPLETED: "Completed!",
	},
};

export default withTranslation(ReferralStatus);
