import { Route } from "react-router-dom";
import ProtectedRoute from "src/routes/middlewares/ProtectedRoute";
import CreateVacancyView from "src/views/authenticated/vacancies/create/CreateVacancyView";
import EditVacancyView from "src/views/authenticated/vacancies/show/EditVacancyView";
import ShowVacancyView from "src/views/authenticated/vacancies/show/ShowVacancyView";
import VacanciesView from "src/views/authenticated/vacancies/VacanciesView";

const VacanciesRoutes = [
	<Route
		element={
			<ProtectedRoute type="company">
				<VacanciesView />
			</ProtectedRoute>
		}
		path="/vacancies"
		key="/vacancies"
	/>,
	<Route
		element={
			<ProtectedRoute type="company">
				<CreateVacancyView />
			</ProtectedRoute>
		}
		path="/vacancies/create"
		key="/vacancies/create"
	/>,
	<Route
		element={
			<ProtectedRoute type="company">
				<ShowVacancyView />
			</ProtectedRoute>
		}
		path="/vacancies/:id"
		key="/vacancies/:id"
	/>,
	<Route
		element={
			<ProtectedRoute type="company">
				<EditVacancyView />
			</ProtectedRoute>
		}
		path="/vacancies/:id/edit"
		key="/vacancies/:id/edit"
	/>,
];

export const VacanciesModalRoutes = [];

export default VacanciesRoutes;
