import { useState } from "react";
import { useTranslation } from "react-i18next";
import { ApiMandate } from "src/api/types";
import useGet from "src/hooks/rest/useGet";
import usePatch from "src/hooks/rest/usePatch";
import useAlert from "src/hooks/useAlert";
import useBackUrl from "src/hooks/useBackUrl";
import SettingCard, {
	SettingCardLoading,
} from "src/components/cards/SettingCard";
import MandateCard from "src/components/subscription/billing/MandateCard";

const MandateList = () => {
	const back = useBackUrl();
	const { t } = useTranslation("subscription", {
		keyPrefix: "mandate-list",
	});
	const [{ data }, loading, { fetchGet }] = useGet(
		"/auth/subscriptions/mandate"
	);
	const [selectMandate] = usePatch("/auth/subscriptions/mandate");
	const [sendAlert] = useAlert();
	const [selected, setSelecting] = useState({} as ApiMandate);

	const handleError = () => {
		sendAlert({
			type: "error",
			title: t("error.title"),
			text: t("error.text"),
		});
		setSelecting({} as ApiMandate);
	};

	const handleSelectMandate = (mandate: ApiMandate) => {
		setSelecting(mandate);
		selectMandate({
			id: mandate.id,
		})
			.then(async ({ data }: any) => {
				if (data?.id) {
					await fetchGet();
					setSelecting({} as ApiMandate);
				} else {
					handleError();
				}
			})
			.catch(handleError);
	};

	return (
		<div className="flex flex-col w-full flex-wrap md:flex-row gap-8">
			{data?.length === 0 && loading && <SettingCardLoading />}
			{data?.length > 0 &&
				data?.map((item: ApiMandate) => (
					<MandateCard
						onActivate={handleSelectMandate}
						loading={selected?.id === item.id}
						key={item.id}
						mandate={item}
					/>
				))}
			<SettingCard
				to={`/profile/billing/change/mandate?${back}`}
				className="cursor-pointer group"
			>
				<div className="flex flex-col flex-1 gap-2 justify-center items-center  opacity-60 group-hover:opacity-100">
					<div className="w-12 h-12 rounded-full flex justify-center items-center bg-primary text-white">
						<i className="fas fa-plus "></i>
					</div>
				</div>
			</SettingCard>
		</div>
	);
};

export default MandateList;
