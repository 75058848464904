import { Route } from "react-router-dom";
import ProtectedRoute from "src/routes/middlewares/ProtectedRoute";
import DashboardEnvelopes from "src/views/authenticated/envelopes/EnvelopesView";
import DashboardSignerView from "src/views/authenticated/signer/SignerView";

const EnvelopeRoutes = [
	<Route
		element={
			<ProtectedRoute>
				<DashboardEnvelopes />
			</ProtectedRoute>
		}
		path="/envelopes"
		key="/envelopes"
	/>,
	<Route
		element={
			<ProtectedRoute>
				<DashboardSignerView />
			</ProtectedRoute>
		}
		path="/envelopes/:id"
		key="/envelopes/:id"
	/>,
];

export default EnvelopeRoutes;
