import { OfferEditFormType } from "src/components/offer/forms/edit/OfferEditFormType";
import OfferEditForm from "src/components/offer/forms/OfferEditForm";
import useEditOffer from "src/hooks/api/useEditOffer";
import CategoryDropdown from "src/components/form/dropdowns/CategoryDropdown";
import { useTranslation } from "react-i18next";
import useOfferScheme from "src/hooks/schemes/useOfferScheme";

const OfferVisibilityForm = ({ id, isOpen, toggleOpen }: OfferEditFormType) => {
	const scheme = useOfferScheme();
	const { t } = useTranslation("offer", {
		keyPrefix: "form",
	});
	const { offer } = useEditOffer(id);
	return (
		<OfferEditForm
			title={t("visibility.title")}
			value={{ category_id: offer?.category_id || offer?.category?.id }}
			schema={() => ({
				category_id: scheme.category_id,
			})}
			{...{ isOpen, toggleOpen }}
		>
			{(getInputProps) => (
				<>
					<CategoryDropdown
						label={t("category.label")}
						placeholder={t("category.placeholder")}
						{...getInputProps("category_id", "dropdown")}
					/>
				</>
			)}
		</OfferEditForm>
	);
};

export default OfferVisibilityForm;
