import { AnimatePresence, motion } from "framer-motion";
import { ComponentProps, useContext } from "react";
import Checkbox from "src/components/form/Checkbox";
import { RowContext } from "src/components/ui/list/Layout";
import { useList } from "src/components/ui/list/Provider";
import { cn } from "src/lib/utils";

interface SelectProps {
	disabled?: boolean;
}

export const Select = ({ disabled }: SelectProps) => {
	const { id } = useContext(RowContext);
	const { selected, setSelected, selectMaximum } = useList();

	return (
		<Checkbox
			{...{ disabled }}
			value={selected.includes(id)}
			onChange={() =>
				setSelected((selected) => {
					if (selected.includes(id)) {
						return selected.filter((item: any) => item !== id);
					}

					if (selected.length === selectMaximum) {
						return selected;
					}

					return [...selected, id];
				})
			}
		/>
	);
};

export const SelectAll = ({
	dataKey,
	exclude,
}: {
	dataKey: string;
	exclude: string[];
}) => {
	const { selected, setSelected, data, selectMaximum } = useList();
	const currentValues = data
		.filter((item) => item?.receiver.has_merchant)
		.map((item: any) => item[dataKey]);
	const isAllSelected =
		(currentValues.every((item: any) => selected.includes(item)) &&
			currentValues.length > 0) ||
		selected.length === selectMaximum;

	return (
		<Checkbox
			value={isAllSelected}
			onChange={() =>
				setSelected((selected) => {
					if (isAllSelected) {
						return selected.filter(
							(item: any) => !currentValues.includes(item)
						);
					}
					const newSelected = [...selected];

					currentValues.forEach((item: any) => {
						if (
							!exclude.includes(item) &&
							!newSelected.includes(item) &&
							(!selectMaximum ||
								newSelected.length < selectMaximum)
						) {
							newSelected.push(item);
						}
					});

					return newSelected;
				})
			}
		/>
	);
};

interface SelectedProps {
	children: (selected: any[], clear: () => void) => JSX.Element;
	variant?: "card" | "floating-card";
}

export const Selected = ({
	children,
	className,
	variant = "card",
	...rest
}: SelectedProps & Omit<ComponentProps<"div">, "children">) => {
	const { selected, setSelected } = useList();
	return (
		<AnimatePresence>
			{selected.length > 0 && (
				<motion.div
					{...(rest as any)}
					{...(variant === "floating-card"
						? {
								initial: { opacity: 0, y: 30, x: "-50%" },
								animate: { opacity: 1, y: 0, x: "-50%" },
								exit: { opacity: 0, y: 30, x: "-50%" },
						  }
						: {})}
					className={cn(
						"bg-card flex items-center gap-3 border border-border rounded-md p-3",
						variant === "floating-card" &&
							"fixed bottom-4 left-1/2 z-[99]",
						className
					)}
				>
					{children(selected, () => setSelected([]))}
				</motion.div>
			)}
		</AnimatePresence>
	);
};
