import axios from "axios";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { ApiOfferRegistration } from "src/api/types";
import { updateOfferRegistration } from "src/redux/offerRegistration";

export default function useOfferRegistration(id?: any) {
	const params = useParams() as any;
	const registrationId = id || params?.registration;
	const registration = useSelector(
		(state: any) => state.offerRegistration.registration
	);
	const dispatch = useDispatch();
	const [loading, setLoading] = useState(true);
	const fetchData = async (loading = true) => {
		setLoading(loading);
		const query = new URLSearchParams({
			"with[0]": "offer",
			"with[1]": "company",
			"with[2]": "user",
			"with[3]": "task",
		});
		const { data } = await axios
			.get(`/offer-registrations/${registrationId}?${query}`)
			.then(({ data }) => data);
		setOfferRegistration(data);
		setLoading(false);
		return data;
	};

	const setOfferRegistration = (registration: ApiOfferRegistration) => {
		dispatch(updateOfferRegistration({ registration }));
	};

	return {
		registration,
		fetchData,
		setOfferRegistration,
		loading,
	};
}
