import {
	ComponentProps,
	createContext,
	useContext,
	useMemo,
	useState,
} from "react";
import { LabelList, Pie, PieChart, Sector } from "recharts";
import { PieSectorDataItem } from "recharts/types/polar/Pie";
import { ApiLinkedSkillCategory } from "src/api/types";
import { useSkillsData } from "src/components/skills-data/SkillsData";
import {
	ChartContainer,
	ChartTooltip,
	ChartTooltipContent,
} from "src/components/ui/chart";
import { getAreaChartColor } from "src/lib/colors";
import { cn } from "src/lib/utils";

interface AreaChartContextProps {
	type: "categories" | "area";
	activeIndex?: number;
	setActiveIndex: React.Dispatch<React.SetStateAction<number | undefined>>;
}

interface ChartProps {
	type: AreaChartContextProps["type"];
}

function useChartData({ type }: ChartProps) {
	const { data } = useSkillsData();
	const chartData = useMemo(() => {
		if (type === "categories") {
			if (!data?.categories) return [];
			const total = data?.categories.reduce(
				(acc, curr) => acc + (curr?.skill_count || 0),
				0
			);

			return (data?.categories || []).map(
				(item: ApiLinkedSkillCategory, index) => ({
					name: item.name,
					value: ((item?.skill_count || 0) / total) * 100,
					fill: getAreaChartColor(index),
				})
			);
		}

		if (type === "area") {
			if (!data?.area) return [];
			const total = data?.area.reduce(
				(acc, curr) => acc + (curr?.skill_count || 0),
				0
			);

			return (data?.area || []).map(
				(item: ApiLinkedSkillCategory, index) => ({
					name: item.name,
					value: ((item?.skill_count || 0) / total) * 100,
					fill: getAreaChartColor(index),
				})
			);
		}
		return [];
	}, [data, type]);
	return chartData;
}

const AreaChartContext = createContext<AreaChartContextProps>(
	{} as AreaChartContextProps
);

export const useAreaChartContext = () => useContext(AreaChartContext);

export const Area = ({ type, children }: ChartProps & { children?: any }) => {
	const [activeIndex, setActiveIndex] = useState<number>();
	return (
		<AreaChartContext.Provider
			value={{
				type,
				activeIndex,
				setActiveIndex,
			}}
		>
			{children}
		</AreaChartContext.Provider>
	);
};

export const AreaDisplay = ({ className }: { className?: string }) => {
	const { type, activeIndex } = useAreaChartContext();
	const chartData = useChartData({ type });

	return (
		<ChartContainer
			config={{}}
			className={cn(
				"aspect-square max-h-[350px] [&_.recharts-pie-label-text]:fill-foreground",
				className
			)}
		>
			<PieChart>
				<ChartTooltip
					content={
						<ChartTooltipContent
							formatValue={(value) => `${value.toFixed(2)}%`}
						/>
					}
				/>
				<Pie
					data={chartData}
					dataKey="value"
					nameKey="name"
					{...{ activeIndex }}
					activeShape={({
						outerRadius = 0,
						...props
					}: PieSectorDataItem) => (
						<Sector {...props} outerRadius={outerRadius + 6} />
					)}
				>
					<LabelList
						dataKey="value"
						className="fill-background"
						stroke="none"
						fontSize={13}
						formatter={(value: any) =>
							value > 7 ? `${value.toFixed(2)}%` : ""
						}
					/>
				</Pie>
			</PieChart>
		</ChartContainer>
	);
};

export const AreaLabels = ({ className, ...rest }: ComponentProps<"div">) => {
	const { type, setActiveIndex, activeIndex } = useAreaChartContext();
	const chartData = useChartData({ type });

	return (
		<div {...rest} className={cn("flex flex-col gap-3", className)}>
			{chartData.map((item, index) => (
				<div
					key={`chart-label-${index}-${item.name}`}
					className={cn(
						"flex gap-2.5 items-center transition-all",
						activeIndex !== undefined &&
							activeIndex !== index &&
							"opacity-50"
					)}
					onMouseOver={() => setActiveIndex(index)}
					onMouseLeave={() =>
						activeIndex === index && setActiveIndex(undefined)
					}
				>
					<div
						className={cn("w-8 h-3 rounded-sm")}
						style={{ backgroundColor: item.fill }}
					></div>
					<span className="leading-[130%] flex-1 text-sm">
						{item.name}
					</span>
				</div>
			))}
		</div>
	);
};
