import { ApiBenefit } from "src/api/types";
import useApi from "src/hooks/api/utils/useApi";

export default function useDashboardBenefits() {
	const { state, actions, api, pagination } = useApi(
		{ id: "DASHBOARD_BENEFITS" },
		{
			baseUrl: `/client/benefits`,
			query: {
				featured: "DASHBOARD",
			},
		}
	);
	const benefits: ApiBenefit[] = state?.list || [];

	return {
		benefits,
		idea: state?.data,
		search: state?.filter?.q,
		status: state.status,
		pagination,
		actions,
		api,
	};
}
