import Button from "src/components/Button";
import withTranslation, {
	Translation,
} from "src/components/hoc/withTranslation";
import Layout from "src/components/layout/Layout";
import useAuth from "src/hooks/selectors/useAuth";
import useBackUrl from "src/hooks/useBackUrl";

interface MiddlewareLayoutProps extends Translation {
	children?: any;
	title: string;
	heading: string;
	loading?: boolean;
	tabs?: any;
}

const MiddlewareLayout = ({
	t,
	children,
	title,
	heading,
	loading,
	tabs,
}: MiddlewareLayoutProps) => {
	const backUrl = useBackUrl();

	const auth = useAuth();
	return (
		<Layout {...{ title, loading }} container="xxl">
			{tabs}
			<div className="flex flex-1 flex-col gap-4 justify-center items-center text-center">
				<h2>{heading}</h2>
				<p className="text-center max-w-md">{children}</p>
				<Button
					className="mt-4"
					to={
						auth.has_subscription
							? `/profile/billing/change?${backUrl}`
							: `/subscription?${backUrl}`
					}
				>
					{auth.has_subscription
						? t("button.with-subscription")
						: t("button.without-subscription")}
				</Button>
			</div>
		</Layout>
	);
};

MiddlewareLayout.locale = {
	nl: {
		button: {
			"with-subscription": "Abonnement wijzigen",
			"without-subscription": "Upgrade account",
		},
	},
	en: {
		button: {
			"with-subscription": "Change subscription",
			"without-subscription": "Upgrade account",
		},
	},
};

export default withTranslation(MiddlewareLayout);
