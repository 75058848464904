import ComplianceItem from "./ComplianceItem";

import withTranslation, {
	Translation,
} from "src/components/hoc/withTranslation";
import useAuth from "src/hooks/selectors/useAuth";

const PaymentProviderOnboarding = ({ t }: Translation) => {
	const auth = useAuth();
	const { status } = auth;
	const compliance = status?.compliance || {};
	const requirements = (compliance?.requirements || []).filter(
		(item: any) => item.object_redirect_url
	);

	if (
		["unverified", "pending"].includes(compliance?.status) &&
		requirements?.length
	) {
		return (
			<div className="flex flex-col gap-4">
				{requirements.map((item: any) => (
					<ComplianceItem key={item.type} {...{ ...item }} />
				))}

				{compliance?.overview_url && (
					<div>
						<a
							className="underline"
							target="_blank"
							href={compliance.overview_url}
							rel="noreferrer"
						>
							{t("cta")}
						</a>
					</div>
				)}
			</div>
		);
	}

	return <></>;
};

PaymentProviderOnboarding.locale = {
	nl: {
		cta: "Bekijk account status",
	},
	en: {
		cta: "View account status",
	},
};

export default withTranslation(PaymentProviderOnboarding);
