import { useState } from "react";
import Button from "src/components/Button";
import Input from "src/components/form/Input";
import withTranslation, {
	Translation,
} from "src/components/hoc/withTranslation";
import useReferrals from "src/hooks/api/services/useReferrals";

interface ReferralInviteFormProps extends Translation {}

const ReferralInviteForm = ({ t }: ReferralInviteFormProps) => {
	const { actions } = useReferrals();
	const [isLoading, setIsLoading] = useState(false);
	const [emails, setEmails] = useState("");

	const onSubmit = (e?: any) => {
		if (e) e.preventDefault();
		if (!emails) return;
		setIsLoading(true);
		actions.invite(emails).finally(() => {
			actions.list({ page: 1 });
			setIsLoading(false);
			setEmails("");
		});
	};

	return (
		<form {...{ onSubmit }} className="flex flex-col gap-2">
			<div className="flex gap-4 flex-col md:flex-row">
				<Input
					name="invite"
					noMarginBottom
					placeholder={t("input.placeholder")}
					value={emails}
					onChange={(_, value) => setEmails(value)}
				/>
				<Button
					type="dark"
					loading={isLoading}
					submit
					disabled={!emails}
				>
					<i className="far fa-user-plus mr-2"></i>
					{t("send")}
				</Button>
			</div>
			<span className="leading-full text-sm">{t("input.help")}</span>
		</form>
	);
};

ReferralInviteForm.locale = {
	nl: {
		input: {
			placeholder: "Uitnodigen via email",
			help: `Gebruik komma's "," om meerdere emails te versturen`,
		},
		send: "Versturen",
	},
	en: {
		input: {
			placeholder: "Invite via email",
			help: 'Use commas "," to send multiple emails',
		},
		send: "Send",
	},
};

export default withTranslation(ReferralInviteForm);
