import { atom } from "jotai";
import { ComponentProps, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Button from "src/components/Button";
import FormGroup from "src/components/form/FormGroup";
import Input from "src/components/form/Input";
import AddressForm from "src/components/forms/AddressForm/v2/AddressForm";
import Choose from "src/components/forms/auth/signup/Choose";
import withTranslation, {
	Translation,
} from "src/components/hoc/withTranslation";
import {
	SourceInput,
	SourceInputDropdown,
	SourceInputOther,
	SourceValue,
} from "src/components/Onboarding/source-input";
import useCompanyInfoSearch from "src/hooks/api/services/auth/useCompanyInfo";
import useOnboarding from "src/hooks/api/services/auth/useOnboarding";
import useTenant from "src/hooks/api/services/tenants/useTenant";
import useFetchProfile from "src/hooks/api/useFetchProfile";
import useForm from "src/hooks/schemes/useForm";
import { useUpdateEffectDebounce } from "src/hooks/useDebounce";
import { cn } from "src/lib/utils";

const formAtom: any = atom({});
const validateAtom: any = atom({});

interface CompanyProps extends Translation {}

const Company = ({
	t,
	className,
	...rest
}: CompanyProps & ComponentProps<"div">) => {
	const { fetchProfile } = useFetchProfile();
	const schemeTranslation = useTranslation("schemes");
	const companyInfo = useCompanyInfoSearch();
	const [showForm, setShowForm] = useState(false);
	const [fetchingCompanyInfo, setFetchingCompanyInfo] = useState<string>();
	const [error, setError] = useState<string>();
	const [addressMode, setAddressMode] = useState<any>("search");
	const {
		tenant: { modules },
	} = useTenant();
	const [source, setSource] = useState<SourceValue>({
		mode: "SELECT",
		source: undefined,
	});
	const {
		form,
		actions: formActions,
		validate,
	} = useForm({
		atoms: {
			form: formAtom,
			validate: validateAtom,
		},
		scheme: {
			name: ["string", "required"],
			kvk: ["string"],
			kvk_id: ["string"],
			street: ["string", "required"],
			house_number: ["string", "required"],
			city: ["string", "required"],
			zipcode: ["string", "required"],
		},
	});
	const searches =
		form.name && (companyInfo?.searches || []).length > 0
			? companyInfo?.searches
			: [];
	useUpdateEffectDebounce(form.name, 1000, (q) => {
		if (companyInfo.status === "idle") {
			setShowForm(false);
			companyInfo.actions.search(q).then((list) => {
				if (list.length === 0) {
					setShowForm(true);
				}
			});
		}
	});

	const { onboarding, actions, status } = useOnboarding();
	const disabled = !validate.isValid || !source.source;

	useEffect(() => {
		if (onboarding?.company) {
			formActions.setForm(onboarding?.company);
			if (onboarding?.company?.street) {
				setAddressMode("manual");
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleSubmit = () => {
		if (disabled) return;
		setError(undefined);
		actions
			.update({ company: form, source })
			.then(() => {
				fetchProfile().then(() => {
					if (modules.includes("intern-task")) {
						actions.setStep("create-teams");
					} else {
						actions.setStep("completed");
					}
				});
			})
			.catch((error: any) => {
				const { errors } = error.response.data;
				if (errors?.kvk_in_use) {
					setError(schemeTranslation.t("kvk-in-use"));
				}
			});
	};

	const handlePickCompanyInfo = async (item: {
		id: string;
		dossiernummer: string;
		ids: string[];
	}) => {
		setFetchingCompanyInfo(item.id);
		const data = await companyInfo.actions.getDetails(item.id, {
			ids: item?.ids || null,
		});
		setFetchingCompanyInfo(undefined);
		formActions.setForm(data);
		if (data?.street) {
			setAddressMode("manual");
		}
		setShowForm(true);
	};

	return (
		<div {...rest} className={cn("flex flex-col flex-1", className)}>
			<div className="flex flex-col flex-1 gap-4">
				<div className="flex flex-col">
					<Input
						label={t("fields.name.label")}
						placeholder={t("fields.name.placeholder")}
						{...formActions.getField("name")}
					/>

					{(!!searches.length || companyInfo.status === "loading") &&
						!form.kvk &&
						!showForm && (
							<div className="flex flex-col max-h-full overflow-auto hide-default-scrollbar mt-2">
								<Choose>
									{companyInfo.searches
										.filter((item, index) => index < 6)
										.map((item) => (
											<Choose.Item
												loading={
													item.id ===
													fetchingCompanyInfo
												}
												key={item.id}
												active={item.id === form.kvk_id}
												title={
													item.handelsnaam ||
													item?.text
												}
												description={item?.id}
												onClick={() =>
													handlePickCompanyInfo(item)
												}
											/>
										))}
									{searches.length === 0 &&
										companyInfo.status === "loading" &&
										new Array(4)
											.fill("")
											.map((item, index) => (
												<Choose.Skeleton
													key={`company-item-${index}-loading`}
												/>
											))}
									<p
										className="w-max opacity-70 hover:opacity-100 transition-all cursor-pointer select-none text-sm"
										onClick={() => setShowForm(true)}
									>
										{t("manual")}
									</p>
								</Choose>
							</div>
						)}

					{!searches.length &&
						!showForm &&
						companyInfo.status === "idle" && (
							<p
								className="w-max opacity-70 hover:opacity-100 transition-all cursor-pointer select-none text-sm mt-2 flex gap-1 items-center"
								onClick={() => setShowForm(true)}
							>
								<small>
									<i className="far fa-pencil"></i>
								</small>
								{t("manual")}
							</p>
						)}
				</div>
				{(showForm || form.kvk) && (
					<>
						<Input
							label={t("fields.kvk.label")}
							optional
							placeholder={t("fields.kvk.placeholder")}
							{...formActions.getField("kvk")}
						/>
						<FormGroup
							label={t("fields.address.label")}
							className="flex flex-col w-full"
						>
							<AddressForm
								className="w-full"
								defaultMode={addressMode}
								onChange={(address) =>
									formActions.setForm(address, true, "merge")
								}
								address={{
									street: form.street,
									house_number: form.house_number,
									zipcode: form.zipcode,
									city: form.city,
								}}
							/>
						</FormGroup>
					</>
				)}

				<SourceInput value={source} onChange={setSource}>
					<SourceInputDropdown
						label={t("fields.source.label")}
						placeholder={t("fields.source.placeholder")}
					/>
					<SourceInputOther />
				</SourceInput>
			</div>
			{error && (
				<div className="flex flex-col p-4 rounded-md bg-red-200 border border-red text-red">
					{error}
				</div>
			)}
			<div className="flex flex-col mt-8 sticky bottom-4">
				<Button
					onClick={handleSubmit}
					{...{ disabled }}
					loading={status === "updating"}
				>
					{t("continue")}
				</Button>
			</div>
		</div>
	);
};

Company.locale = {
	nl: {
		continue: "Opslaan",
		manual: "Handmatig gegevens invoeren",
		fields: {
			name: {
				label: "Bedrijfsnaam",
				placeholder: "Bedrijfsnaam",
			},
			kvk: {
				label: "KVK",
				placeholder: "KVK",
			},
			address: {
				label: "Adres",
			},
			source: {
				label: "Hoe heb je van ons gehoord",
				placeholder: "Via welk medium...",
			},
		},
	},
	en: {
		continue: "Save",
		manual: "Enter manual",
		fields: {
			name: {
				label: "Company name",
				placeholder: "Company name",
			},
			kvk: {
				label: "KVK",
				placeholder: "KVK",
			},
			address: {
				label: "Address",
			},
			source: {
				label: "How did you hear about us",
				placeholder: "Via which medium...",
			},
		},
	},
};

export default withTranslation(Company);
