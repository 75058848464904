import React from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import BackButton from "src/components/BackButton";
import Layout from "src/components/layout/Layout";
import EnvelopeDocument, {
	Viewer,
} from "src/components/signer/document/EnvelopeDocument";
import useGet from "src/hooks/rest/useGet";

export default function SignerPreview() {
	const { t } = useTranslation("signer-view", {
		keyPrefix: "preview-view",
	});
	const { task, registration } = useParams() as any;
	const [{ data: envelope }, loading] = useGet(
		`/company/registrations/${registration}/preview-envelope`
	);

	return (
		<Layout title={t("title")}>
			<div className="flex justify-between items-center mb-4">
				<BackButton label={t("back")} to={`/tasks/${task}/show`} />
			</div>
			{!loading && envelope && typeof envelope === "string" ? (
				<Viewer content={envelope} />
			) : (
				<EnvelopeDocument.Loader />
			)}
		</Layout>
	);
}
