import { ApiPaymentRequest } from "src/api/types";
import useUnreads from "src/hooks/api/useUnreads";
import useApi from "src/hooks/api/utils/useApi";

export type PaymentRequestFilter = {
	connection_id?: number | string;
	ids?: string[];
	sort?: "newest" | "status_change";
};

export type PaymentRequestFormValue = {
	id?: string | number;
	type?: "HOUR_RATE" | "FIXED_FEE";
	description?: string;
	start_date?: string;
	end_date?: string;
	break?: number;
	price?: number;
	company_id?: string;
	receiver_id?: string;
	from_task_id?: string;
	status?: "OPEN" | "PENDING" | "APPROVED";
	forms?: any;
};

type Options = {
	stateId?: string;
	connectionId?: string;
	filter?: any;
};

function usePaymentRequests(options?: Options) {
	const { fetchUnreads } = useUnreads();
	const { state, actions, api, pagination } = useApi(
		{
			id: options?.stateId
				? options.stateId
				: options?.connectionId
				? `PAYMENT_REQUESTS_${options.connectionId}`
				: "PAYMENT_REQUESTS",
		},
		{
			baseUrl: `/payment-requests`,
			query: {
				connection_id: options?.connectionId,
				with: [
					"company",
					"creator",
					"receiver",
					"hours",
					"forms",
					"connection_fields",
					"payment",
				],
				...(options?.filter || {}),
			},
		}
	);
	const requests: ApiPaymentRequest[] = state?.list || [];

	const approve = async (ids: string[]) => {
		actions.set((state: any) => ({
			...state,
			status: "loading",
		}));
		await api.post(`/status/approve`, {
			ids,
		});
		fetchUnreads();
		actions.set((state: any) => ({
			...state,
			status: "idle",
		}));
	};

	const deny = async (ids: string[], reason: string) => {
		actions.set((state: any) => ({
			...state,
			status: "loading",
		}));
		await api.post(`/status/deny`, {
			ids,
			reason,
		});
		fetchUnreads();
		actions.set((state: any) => ({
			...state,
			status: "idle",
		}));
	};

	return {
		request: state?.data || {},
		requests,
		status: state.status,
		pagination,
		actions: {
			...actions,
			approve,
			deny,
		},
		api,
	};
}

export default usePaymentRequests;
