import { useState } from "react";
import { ApiTeam, ApiUserMinimal } from "src/api/types";
import DropdownButton from "src/components/DropdownButton";
import withTranslation, {
	Translation,
} from "src/components/hoc/withTranslation";
import UserCard from "src/components/user-card/UserCard";
import useUsers from "src/hooks/api/services/users/useUsers";
import useNavigateModal from "src/hooks/useNavigateModal";

interface UserTeamCardItemProps extends Translation {
	user: ApiUserMinimal;
	team?: ApiTeam;
}

const UserTeamCardItem = ({ t, user, team }: UserTeamCardItemProps) => {
	const navigateModal = useNavigateModal();
	const [status, setStatus] = useState<"idle" | "deleting">("idle");
	const { actions } = useUsers();

	const onDeleteFromTeam = () => {
		if (!team) return;
		setStatus("deleting");

		actions
			.update(
				{
					id: user.id,
					teams: user.teams.filter((slug) => slug !== team.slug),
				},
				{ updateList: true }
			)
			.finally(() => setStatus("idle"));
	};

	return (
		<UserCard card={{ contentClassName: "p-3" }} {...{ user }}>
			<div className="flex items-center justify-between gap-4">
				<div className="flex items-center gap-2">
					<UserCard.Avatar className="w-8 h-8 rounded-md" />
					<UserCard.Name />
				</div>
				<div className="flex gap-2 items-center">
					<UserCard.Status />
					<DropdownButton
						menu={{
							position: "bottom-right",
						}}
						button={{
							tabIndex: "-1",
							children: <i className="far fa-ellipsis-v" />,
							type: "transparent",
							iconOnly: true,
							className: "hover:bg-accent p-0 w-8 h-8 ",
						}}
					>
						<DropdownButton.Item
							icon="far fa-pencil"
							onClick={(close) => {
								navigateModal(
									`/profile/users/${user.hid}/edit`
								);
								if (close) close();
							}}
						>
							{t("edit")}
						</DropdownButton.Item>

						{!!user.teams.length && (
							<DropdownButton.Item
								icon="far fa-trash"
								loading={status === "deleting"}
								onClick={onDeleteFromTeam}
							>
								{t("delete")}
							</DropdownButton.Item>
						)}
					</DropdownButton>
				</div>
			</div>
		</UserCard>
	);
};

UserTeamCardItem.locale = {
	nl: {
		edit: "Aanpassen",
		delete: "Verwijderen",
	},
	en: {
		edit: "Edit",
		delete: "Delete",
	},
};

export default withTranslation(UserTeamCardItem);
