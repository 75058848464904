import { ComponentProps } from "react";
import IsSticky, { useIsSticky } from "src/components/IsSticky";
import useWindowSize from "src/hooks/useWindowSize";
import { cn } from "src/lib/utils";

const STICKY_BREAKPOINTS = ["md", "lg", "xl"];

const EditLayout = ({ className, ...rest }: ComponentProps<"div">) => {
	return (
		<div
			{...rest}
			className={cn("flex flex-col max-w-md w-full mx-auto", className)}
		/>
	);
};

export const EditLayoutHeader = ({
	className,
	children,
	...rest
}: ComponentProps<"div">) => {
	const { breakpoint } = useWindowSize();
	return (
		<IsSticky>
			{({ isSticky }) => (
				<header
					{...rest}
					className={cn(
						"flex flex-col gap-1 pt-12 md:sticky top-0 z-10 transition-all relative",
						isSticky &&
							STICKY_BREAKPOINTS.includes(breakpoint) &&
							"pt-8",
						className
					)}
				>
					<div className="flex flex-col gap-1 z-10">
						{children as any}
					</div>
					<div
						className={cn(
							"bg-card -inset-4 absolute border border-border rounded-md opacity-0 transition-all",
							isSticky &&
								STICKY_BREAKPOINTS.includes(breakpoint) &&
								"opacity-100"
						)}
					></div>
				</header>
			)}
		</IsSticky>
	);
};

export const EditLayoutTitle = ({
	className,
	children,
	...rest
}: ComponentProps<"h2">) => {
	return (
		<h2
			{...rest}
			className={cn("text-xl md:text-2xl font-bold", className)}
		>
			{children}
		</h2>
	);
};

export const EditLayoutDescription = ({
	className,
	children,
	...rest
}: ComponentProps<"p">) => {
	const isSticky = useIsSticky();
	const { breakpoint } = useWindowSize();
	return (
		<p
			{...rest}
			className={cn(
				"opacity-70 transition-all",
				isSticky &&
					STICKY_BREAKPOINTS.includes(breakpoint) &&
					"h-0 overflow-hidden",
				className
			)}
		>
			{children}
		</p>
	);
};

export default EditLayout;
