import React from "react";
import moment from "moment";
import { useTranslation } from "react-i18next";
import useSubscription from "src/hooks/selectors/useSubscription";

const SubscriptionChangedBanner = () => {
	const subscription = useSubscription();
	const nextPlanStarts = subscription?.ends_at;
	const { t } = useTranslation("promotion", {
		keyPrefix: "subscription-changed-banner",
	});

	if (subscription.paused_at) {
		return <></>;
	}
	return (
		<div className="col-span-3 rounded-md p-4 bg-primary">
			<p className="text-center">
				{t("description", {
					date: moment(nextPlanStarts).format("DD-MM-YYYY"),
					plan: subscription?.next_plan?.name || "",
				})}
			</p>
		</div>
	);
};

export default SubscriptionChangedBanner;
