import { AnimatePresence, motion } from "framer-motion";
import moment from "moment";
import { ApiRegistration } from "src/api/types";
import Avatar from "src/components/Avatar";
import Button from "src/components/Button";
import Checkbox from "src/components/form/Checkbox";
import withTranslation, {
	Translation,
} from "src/components/hoc/withTranslation";
import Loader from "src/components/Loader";
import ReadMore from "src/components/ReadMore";
import RegistrationStatus from "src/components/RegistrationStatus";
import Table from "src/components/Table";
import useSelectRegistrations from "src/hooks/api/tasks/useSelectRegistrations";

interface RegistrationAcceptListItemProps extends Translation {
	registration: ApiRegistration;
	links?: {
		taskHid?: string;
	};
}

const RegistrationAcceptListItem = ({
	t,
	registration,
	links,
}: RegistrationAcceptListItemProps) => {
	const { selected, actions: selectActions } = useSelectRegistrations();
	const status = registration.status;
	// useSocketListener(`registration.${registration.hid}.status`, (res) => {
	// 	if (res.status) {
	// 		setStatus(res.status);
	// 	}
	// });

	return (
		<Table.Row key={`registration-${registration.id}`}>
			<Table.Cell>
				{status === "pending" && (
					<Checkbox
						value={selected.includes(registration.id)}
						onChange={() => selectActions.toggle(registration.id)}
					/>
				)}
			</Table.Cell>
			<Table.Cell>
				<div className="relative">
					<AnimatePresence>
						{status === "processing" && (
							<motion.div
								initial={{ opacity: 0 }}
								animate={{ opacity: 1 }}
								exit={{ opacity: 0 }}
								className="absolute inset-0 flex justify-center items-center backdrop-blur-sm rounded-md"
							>
								<Loader />
							</motion.div>
						)}
					</AnimatePresence>
					<RegistrationStatus {...{ status }} />
				</div>
			</Table.Cell>
			<Table.Cell>
				<div className="w-[240px]">
					<Avatar.User
						user={registration.user as any}
						className="w-full"
						wrapper={{ className: "max-w-full gap-0" }}
						subtitle={
							registration.is_connected ? (
								<small
									className={
										registration.is_connected
											? "text-green"
											: ""
									}
								>
									<i className="far fa-check"></i>
									{t("connected")}
								</small>
							) : (
								registration.user.tagline
							)
						}
						avatar={{
							className: registration.is_connected
								? "border-green border-2"
								: "",
						}}
					/>
				</div>
			</Table.Cell>
			<Table.Cell>
				<p className="whitespace-nowrap">
					{registration.user.telephone}
				</p>
			</Table.Cell>
			<Table.Cell className="w-full">
				<ReadMore
					variant="line-clamp"
					className="max-w-[360px] md:max-w-[540px]"
					text={registration.motivation}
				></ReadMore>
			</Table.Cell>
			<Table.Cell>
				{moment(registration.created_at).format("DD-MM-YYYY")}
			</Table.Cell>
			<Table.Cell>
				<div className="flex gap-2 empty:hidden justify-end">
					{links?.taskHid && (
						<>
							{registration.status === "matched" && (
								<Button
									xsmall
									type="border"
									to={{
										modal: `/tasks/${links.taskHid}/show/${registration.hid}/cancel`,
									}}
								>
									{t("cancel")}
								</Button>
							)}
							<Button
								xsmall
								iconOnly
								indicator={registration.unread_messages}
								icon="fas fa-comments"
								to={`/inbox/${links.taskHid}-${registration.hid}`}
							/>
							<Button
								xsmall
								iconOnly
								icon="fas fa-eye"
								to={`/tasks/${links?.taskHid}/show/${registration.hid}`}
							/>
						</>
					)}
				</div>
			</Table.Cell>
		</Table.Row>
	);
};

RegistrationAcceptListItem.locale = {
	nl: {
		connected: "Connectie",
		cancel: "Annuleren",
	},
	en: {
		connected: "Connected",
		cancel: "Cancel",
	},
};

export default withTranslation(RegistrationAcceptListItem);
