import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import usePermissions from "src/hooks/api/services/auth/usePermissions";
import useFetchProfile from "src/hooks/api/useFetchProfile";
import useAuth from "src/hooks/selectors/useAuth";
import {
	removeTask,
	TaskFormStatus,
	updateStatusTask,
	updateTask,
} from "src/redux/taskForm";

function useTask() {
	const permissions = usePermissions();
	const { id } = useParams() as any;
	const dispatch = useDispatch();
	const auth = useAuth();
	const task = useSelector((state: any) => state.taskForm.task);
	const status = useSelector((state: any) => state.taskForm.status);
	const { fetchProfile } = useFetchProfile();

	const get = async (loading = true, query?: string) => {
		if (status === "loading") {
			return;
		}
		if (!id) return;
		if (loading) {
			setStatus("loading");
		}

		const withItems = permissions.has("tasks.create")
			? ["creator", "answers", "description_obj", "assignees", "teams"]
			: ["creator", "company"];

		try {
			const {
				data: { data: _task },
			} = await axios.get(`/client/tasks/${id}?${query}`, {
				params: {
					with: withItems,
					exclude: ["subtasks"],
				},
			});
			set(_task, "fill");
			await fetchProfile();
			setStatus("idle");
		} catch (error) {
			setStatus("error");
		}
	};

	const setStatus = (status: TaskFormStatus) => {
		dispatch(updateStatusTask(status));
	};

	const set = (data?: any, type: "fill" | "manual" = "manual") => {
		let update = {
			task: {
				...task,
				...data,
			},
		} as any;
		dispatch(updateTask(update));
	};

	const clear = () => {
		dispatch(removeTask());
	};

	const getHeadProps = () => {
		if (status !== "idle") {
			return {
				title: "",
				subtitle: undefined,
			};
		}

		if (auth.type === "student") {
			return {
				title: task?.title || "",
			};
		}

		return {
			title: task?.title || "",
			subtitle: task?.creator
				? `Aangemaakt door: ${task?.creator?.first_name}`
				: undefined,
		};
	};

	return {
		task,
		status,
		actions: {
			set,
			clear,
			setStatus,
			get,
			getHeadProps,
		},
	};
}

export default useTask;
