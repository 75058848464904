import { ApiUserOptionMinimal } from "src/api/types";
import useApi from "src/hooks/api/utils/useApi";

type Options = {
	filter?: any;
	rememberKey?: string;
};

function useAllConnections(options?: Options) {
	const { state, actions, api } = useApi(
		{ id: options?.rememberKey || "ALL_CONNECTIONS" },
		{
			baseUrl: `/connections`,
			query: {
				sort: "recent",
				response_type: "option-minimal",
				...(options?.filter || {}),
			},
		}
	);
	const connections: ApiUserOptionMinimal[] = state?.list || [];

	const search = (search: string) => {
		actions.list({ q: search });
	};

	return {
		connections,
		connection: state?.data,
		filter: state?.filter || {},
		search: state?.filter?.q,
		status: state.status,
		pagination: state?.pagination,
		actions: {
			...actions,
			search,
		},
		api,
	};
}

export default useAllConnections;
