import { useTranslation } from "react-i18next";
import ButtonComp, { ButtonProps } from "src/components/Button";
import { useSlider } from "src/components/slider/Slider";

interface SliderButtonProps extends ButtonProps {
	direction: "previous" | "next";
	endLabel?: string;
}

const Button = ({
	direction,
	children,
	endLabel,
	...rest
}: SliderButtonProps) => {
	const { t } = useTranslation(["buttons"]);
	const { current, max, setCurrent, onEndReach } = useSlider();
	let label = direction === "previous" ? t("prev") : t("next");
	if (endLabel && current === max - 1) label = endLabel;
	let disabled = false;
	if (direction === "previous" && current === 0) {
		disabled = true;
	}

	if (direction === "next" && current + 1 >= max) {
		disabled = true;
	}

	return (
		<ButtonComp
			{...rest}
			{...{ disabled }}
			onClick={() => {
				if (direction === "previous") {
					if (current <= 0) return;
					setCurrent(current - 1);
					return;
				}

				if (direction === "next") {
					const newCurrent = current + 1;

					if (newCurrent >= max) {
						if (onEndReach) {
							onEndReach();
						}
					} else {
						setCurrent(current + 1);
					}
				}
			}}
		>
			{children || label}
		</ButtonComp>
	);
};

export default Button;
