import { useEffect, useState } from "react";
import useTenant from "src/hooks/api/services/tenants/useTenant";
import usePost from "src/hooks/rest/usePost";

export default function useRedirectToCustomerPortal(
	{ redirect_to } = { redirect_to: "/profile/billing" }
) {
	const { tenant } = useTenant();
	const [getRedirectUrl] = usePost(`/auth/subscriptions/customer/portal`);
	const [isLoading, setIsLoading] = useState(
		tenant.providers.payments === "STRIPE"
	);

	const redirectToCustomerPortal = () => {
		if (tenant.providers.payments === "STRIPE") {
			setIsLoading(true);
			getRedirectUrl({ redirect_to }).then(({ data }) => {
				if (data.redirect_url) {
					window.location = data.redirect_url;
				}
			});
		} else {
			setIsLoading(false);
		}
	};

	useEffect(() => {
		redirectToCustomerPortal();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return {
		isLoading,
		redirectToCustomerPortal,
	};
}
