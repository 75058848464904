import moment from "moment";
import { cn } from "src/lib/utils";
import Avatar from "src/components/Avatar";
import Text from "src/components/ui/text";
import withTranslation, {
	Translation,
} from "src/components/hoc/withTranslation";
import TaskSkills from "src/components/tasks/TaskSkills";
import TaskWeight from "src/components/tasks/TaskWeight";
import useBoards from "src/hooks/api/services/tasks/useBoards";
import useUsers from "src/hooks/api/services/users/useUsers";
import useAuth from "src/hooks/selectors/useAuth";
import useSocketListener from "src/hooks/sockets/useSocketListener";
import Icon from "src/components/icon/Icon";
import AlphaBadge from "src/components/AlphaBadge";
import Field from "src/components/field/Field";
import { useState } from "react";
import useTask from "src/hooks/api/services/tasks/task/useTask";
import { useParams } from "react-router-dom";
import useProjectOptions from "src/hooks/api/services/projects/useProjectOptions";
import Link from "src/components/Link";

interface TaskModalInfoProps extends Translation {}

const TaskModalInfo = ({ t }: TaskModalInfoProps) => {
	const { id } = useParams();
	const { task, actions } = useTask({
		id,
	});
	const { users } = useUsers();
	const auth = useAuth();
	const { boards } = useBoards();
	const { projects } = useProjectOptions();
	const [isEditableBoard, setIsEditableBoard] = useState(false);
	useSocketListener(`TASK.${task.hid}.WEIGHT.INDEXED`, (res) => {
		if (res?.weight) {
			actions.change({
				weight: res?.weight,
			});
		}
	});

	const board = task?.links?.board
		? boards.find((item) => item.hid === task.links.board)
		: null;
	const project = task?.links?.project
		? projects.find((item) => item.hid === task.links.project)
		: null;

	const creator = task?.id
		? users.find((user) => user.id === task?.links?.creator_id)
		: auth;
	const isIndexable: boolean =
		(!!task?.skills?.length || task?.links?.board || task.links?.project) &&
		!!board?.skills?.length;

	return (
		<div className="flex-1 flex flex-col justify-end">
			<div className="px-3 flex pb-3 flex-col gap-4">
				{isIndexable && (
					<Section title={t("skills")} extra={<AlphaBadge />}>
						<TaskSkills {...{ task }} />
					</Section>
				)}
				{board && (
					<Section title={t("board")}>
						{!isEditableBoard ? (
							<div
								onClick={() => setIsEditableBoard(true)}
								className="flex items-center gap-2 group cursor-pointer"
							>
								{board.icon && (
									<Icon
										icon={board.icon}
										className="bg-accent"
									/>
								)}
								<p className="line-clamp-1">{board.name}</p>
								<i className="fas fa-pencil ml-2 text-sm opacity-0 group-hover:opacity-100 transition-all text-border"></i>
							</div>
						) : (
							<Field.Board
								wrapper={{}}
								value={task.links?.board}
								onChange={(value) => {
									actions.change({
										board: value,
										links: {
											...task.links,
											board: value,
										},
									});
									setIsEditableBoard(false);
								}}
							/>
						)}
					</Section>
				)}
				{project?.name && (
					<Section title={t("project")}>
						<Link
							to={`/projects/${project.slug}`}
							className="flex items-center gap-2"
						>
							<div className="">
								<Icon
									className="w-5 h-5 rounded-sm group-hover:bg-accent"
									iconFontSize={12}
									icon={
										project?.icon ||
										Icon.getFontAwesome("fa-folder", "far")
									}
								/>
							</div>
							<span className="underline">{project.name}</span>
						</Link>
					</Section>
				)}

				{task?.weight && (
					<Section title={t("weight")}>
						<div className="flex items-center gap-2">
							<TaskWeight weight={task.weight} />
						</div>
					</Section>
				)}
				{creator && (
					<Section title={t("created-by")}>
						<Avatar.User
							user={creator}
							avatar={{
								size: "xsmall",
							}}
							subtitle={<></>}
							variant="light"
						/>
					</Section>
				)}
			</div>
			{task?.id && task.type !== "TEMPLATE" && (
				<>
					<hr className="border-border" />
					<div className="p-3 flex flex- gap-4">
						<Section className="flex-1" title={t("update")}>
							<span className="opacity-90 text-sm">
								{moment(task.updated_at).format(
									"DD-MM-YYYY HH:mm"
								)}
							</span>
						</Section>
						<Section className="flex-1" title={t("created")}>
							<span className="opacity-90 text-sm">
								{moment(task.created_at).format(
									"DD-MM-YYYY HH:mm"
								)}
							</span>
						</Section>
					</div>
				</>
			)}
		</div>
	);
};

interface SectionProps {
	title: string;
	children: any;
	className?: string;
	extra?: any;
}

export const Section = ({
	title,
	children,
	className,
	extra,
}: SectionProps) => (
	<div className={cn("flex flex-col gap-1.5", className)}>
		<div className="flex items-center gap-2">
			<Text.Eyebrow>{title}</Text.Eyebrow>
			{extra}
		</div>
		<div className="flex flex-col">{children}</div>
	</div>
);

TaskModalInfo.locale = {
	nl: {
		title: "",
		update: "Geupdate",
		created: "Aangemaakt",
		"created-by": "Aangemaakt door",
		"assigned-to": "Uitvoerder",
		team: "Team",
		board: "Bord",
		skills: "Skills",
		weight: "Inspanning",
		project: "Project",
	},
	en: {
		title: "",
		update: "Updated",
		created: "Created",
		"created-by": "Created by",
		"assigned-to": "Assignee",
		team: "Team",
		board: "Board",
		skills: "Skills",
		weight: "Weight",
		project: "Project",
	},
};

export default withTranslation(TaskModalInfo);
