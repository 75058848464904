import { useCallback, useEffect, useRef, useState } from "react";

function useFullScreenDrop(onDrop: any) {
	const [isDragging, setIsDragging] = useState(false);
	const dragCounter = useRef(0);

	const handleDrag = useCallback((event: any) => {
		event.preventDefault();
		event.stopPropagation();
	}, []);
	const handleDragIn = useCallback((event: any) => {
		event.preventDefault();
		event.stopPropagation();
		dragCounter.current++;
		if (event.dataTransfer.items && event.dataTransfer.items.length > 0) {
			setIsDragging(true);
		}
	}, []);
	const handleDragOut = useCallback((event: any) => {
		event.preventDefault();
		event.stopPropagation();
		dragCounter.current--;
		if (dragCounter.current > 0) return;
		setIsDragging(false);
	}, []);
	const handleDrop = useCallback((event: any) => {
		event.preventDefault();
		event.stopPropagation();
		setIsDragging(false);
		if (event.dataTransfer.files && event.dataTransfer.files.length > 0) {
			dragCounter.current = 0;
			onDrop(event.dataTransfer.files);
			event.dataTransfer.clearData();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		window.addEventListener("dragenter", handleDragIn);
		window.addEventListener("dragleave", handleDragOut);
		window.addEventListener("dragover", handleDrag);
		window.addEventListener("drop", handleDrop);
		return function cleanUp() {
			window.removeEventListener("dragenter", handleDragIn);
			window.removeEventListener("dragleave", handleDragOut);
			window.removeEventListener("dragover", handleDrag);
			window.removeEventListener("drop", handleDrop);
		};
	});
	return {
		isDragging,
		dragCounter,
	};
}

export default useFullScreenDrop;
