const Line = ({ label }: { label: string }) => {
	return (
		<div className="flex items-center gap-2 my-4 md:my-8 text-placeholder">
			<div className="h-[1px] flex flex-1 rounded-md bg-placeholder opacity-50"></div>
			{label && <p className="text-placeholder">{label}</p>}
			<div className="h-[1px] flex flex-1 rounded-md bg-placeholder opacity-50"></div>
		</div>
	);
};

export default Line;
