import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import MandateCard from "src/components/subscription/billing/MandateCard";
import useGet from "src/hooks/rest/useGet";

const CurrentMandate = () => {
	const { t } = useTranslation("subscription", {
		keyPrefix: "current-mandate",
	});
	const [{ data }] = useGet("/auth/subscriptions/mandate/current");

	return (
		<>
			{data && <MandateCard maxWidth={"[100%]"} mandate={data} />}
			<NavLink className="-mt-6 text-center" to="/profile/billing/change">
				<small>{t("change-subscription")}</small>
			</NavLink>
		</>
	);
};

export default CurrentMandate;
