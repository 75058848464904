import Button from "src/components/Button";
import ConnectUserButton from "src/components/ConnectUserButton";
import ToggleFavorite from "src/components/favorites/ToggleFavorite";
import { useT } from "src/components/hoc/withTranslation";
import { useRegistration } from "src/components/registrations/registration/registration";
import { cn } from "src/lib/utils";

export const FavoriteRegistration = () => {
	const { registration } = useRegistration();
	const t = useT({
		nl: {
			favorite: "Favoriet",
		},
		en: {
			favorite: "Favorite",
		},
	});
	return (
		<ToggleFavorite
			defaultFavorite={registration.is_favorite || false}
			id={registration.id}
			type="REGISTRATION"
		>
			{({ isFavorite, onClick, loading }) => (
				<Button
					type={isFavorite ? "green" : "border"}
					xsmall
					{...{
						onClick,
						loading,
					}}
				>
					<i
						className={cn(isFavorite ? "fas" : "fas", "fa-star")}
					></i>
					{t("favorite")}
				</Button>
			)}
		</ToggleFavorite>
	);
};

export const ConnectRegistrationUser = () => {
	const { registration } = useRegistration();
	const user = registration.user;
	const t = useT({
		nl: {
			connection: "Connectie",
		},
		en: {
			connection: "Connection",
		},
	});
	return (
		<ConnectUserButton
			isConnected={registration.is_connected}
			{...{ user }}
		>
			{({ isConnected, loading, onClick }) => (
				<Button
					type={isConnected ? "green" : "border"}
					xsmall
					{...{
						onClick,
						loading,
					}}
				>
					<i
						className={cn(
							"fas",
							isConnected ? "fa-user-check" : "fa-user-plus"
						)}
					></i>
					{t("connection", {})}
				</Button>
			)}
		</ConnectUserButton>
	);
};
