import { ComponentProps } from "react";
import Logo from "src/components/Logo";
import useTenant from "src/hooks/api/services/tenants/useTenant";
import useTheme from "src/hooks/useTheme";
import { cn } from "src/lib/utils";

interface OnboardingLayoutProps extends ComponentProps<"div"> {
	actions?: JSX.Element;
}

const OnboardingLayout = ({
	className,
	children,
	actions,
	...rest
}: OnboardingLayoutProps) => {
	const { theme } = useTheme();
	const { tenant } = useTenant();
	return (
		<div
			{...rest}
			className={cn("flex flex-col flex-1 bg-background", className)}
		>
			<div className="flex flex-col flex-1 max-w-md w-full mx-auto">
				<div className="flex justify-between items-end p-4">
					{tenant.slug === "getcrewdone" ? (
						<Logo
							variant="default"
							color={theme === "dark" ? "dark" : "primary"}
							className="max-w-[160px]"
						/>
					) : (
						<Logo
							variant="default"
							color={theme === "dark" ? "white" : "dark"}
							className="max-w-[160px]"
						/>
					)}
					<div className="flex items-center gap-3">{actions}</div>
				</div>
				<div className="flex flex-col flex-1 px-4">{children}</div>
			</div>
		</div>
	);
};

const Header = ({ className, children, ...rest }: ComponentProps<"div">) => {
	return (
		<header {...rest} className={cn("md:mt-8", className)}>
			{children}
		</header>
	);
};

const Container = ({ children, className, ...rest }: ComponentProps<"div">) => {
	return (
		<div
			{...rest}
			className={cn(
				"flex flex-col mt-2 md:mt-8 flex-1 md:flex-none",
				className
			)}
		>
			{children}
		</div>
	);
};

const Footer = ({ children, className, ...rest }: ComponentProps<"div">) => {
	return (
		<div
			{...rest}
			className={cn(
				"flex justify-between items-center pb-4 pt-4 md:pt-8",
				className
			)}
		>
			{children}
		</div>
	);
};

const Title = ({ children, className, ...rest }: ComponentProps<"h1">) => {
	return (
		<p className={cn("text-[20px] font-bold", className)} {...rest}>
			{children}
		</p>
	);
};

const Description = ({ children, className, ...rest }: ComponentProps<"p">) => {
	return (
		<p className={cn("opacity-70", className)} {...rest}>
			{children}
		</p>
	);
};

OnboardingLayout.Header = Header;
OnboardingLayout.Container = Container;
OnboardingLayout.Footer = Footer;
OnboardingLayout.Title = Title;
OnboardingLayout.Description = Description;

export default OnboardingLayout;
