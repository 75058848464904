import { useEffect } from "react";
import Badge from "src/components/Badge";
import Select, { SelectValue } from "src/components/field/fields/Select";
import { WrapperProps } from "src/components/field/utils/Wrapper";
import useTeams from "src/hooks/api/services/users/useTeams";

interface TeamProps {
	wrapper?: WrapperProps;
	value: SelectValue;
	placeholder?: string;
	onChange: (value?: SelectValue) => void;
}

const Team = ({ wrapper, value, placeholder, onChange }: TeamProps) => {
	const { teams, actions } = useTeams();

	useEffect(() => {
		if (!teams?.length) {
			actions.list();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const linked = teams.filter(
		(item) =>
			item.slug === value ||
			(Array.isArray(value) && value.includes(item.slug))
	);

	return (
		<Select
			{...{ wrapper, placeholder }}
			onChange={(value) => {
				onChange(value);
			}}
			value={value as any}
			options={teams.map((team) => ({
				value: team.slug.toString(),
				label: (
					<div className="mr-2">
						<Badge custom={team.color}>{team.name}</Badge>
					</div>
				),
			}))}
			theme={{
				item: {
					indicator: true,
					className: "radix-highlighted:bg-accent",
				},
				value: {
					renderValue: () => (
						<div className="flex items-center gap-2 flex-wrap">
							{linked.map((team) => (
								<Badge
									key={`team-value-${team.hid}`}
									custom={team.color}
								>
									{team.name}
								</Badge>
							))}
						</div>
					),
				},
			}}
		/>
	);
};

export default Team;
