import { atom, useAtom } from "jotai";
import useTenant from "src/hooks/api/services/tenants/useTenant";
import useApi from "src/hooks/api/utils/useApi";

type Interval = "MONTHLY" | "YEARLY";

const planIntervalAtom = atom<Interval>("MONTHLY");

export default function usePlans() {
	const { tenant } = useTenant();
	const { state, actions } = useApi(
		{
			id: "PLANS",
		},
		{
			baseUrl: "/auth/subscriptions/plans",
			query: {
				...(tenant.slug === "getjobsdone" && {
					group_by: "interval_price",
				}),
			},
		}
	);
	const [interval, setInterval] = useAtom<Interval, any, any>(
		planIntervalAtom
	);
	const intervalEnabled = false;

	return {
		plans: state?.list || [],
		status: state.status,
		interval: !intervalEnabled ? "MONTHLY" : interval,
		intervalEnabled,
		actions: {
			...actions,
			setInterval,
		},
	};
}
