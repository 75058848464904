import React, { useEffect } from "react";
import Button from "src/components/Button";
import withTranslation, {
	Translation,
} from "src/components/hoc/withTranslation";
import useModal from "src/hooks/useModal";
import Input from "src/components/form/Input";
import Switch from "src/components/form/Switch";
import { Formik } from "formik";
import * as Yup from "yup";
import useWorkExperiences from "src/hooks/api/services/resume/useWorkExperiences";
import { useParams } from "react-router-dom";
import useAlert from "src/hooks/useAlert";
import moment from "moment";

function WorkExperienceFormModal({ t }: Translation) {
	const { workExperience, actions, status } = useWorkExperiences();
	const { id } = useParams() as any;
	const [sendAlert] = useAlert();
	const modal = useModal({
		title: t("title"),
	});

	useEffect(() => {
		if (id && !workExperience.id) {
			actions.get(id);
		}
		return () => {
			actions.setItem(
				{
					data: {},
				},
				{
					updateList: false,
				}
			);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [id]);

	const scheme = {
		function: Yup.string().required(t("required")),
		company: Yup.string().required(t("required")),
		current: Yup.boolean(),
		startYear: Yup.number()
			.required(t("required"))
			.max(moment().year(), t("input.date.year-future"))
			.test(
				"len",
				t("input.date.type-year"),
				(val?: any) =>
					val === undefined || (val && val.toString().length === 4)
			),
		endYear: Yup.number().when("current", {
			is: false,
			then: Yup.number()
				.required(t("required"))
				.test(
					"len",
					t("input.date.type-year"),
					(val?: any) =>
						val === undefined ||
						(val && val.toString().length === 4)
				)
				.max(moment().year(), t("input.date.year-future"))
				.when(
					"startYear",
					(started: any, schema: any) =>
						started &&
						schema.min(started, t("end-date-before-start-date"))
				),
		}),
	} as {
		[key: string]: any;
	};

	const initialValues = {
		function: workExperience.subtitle ? workExperience.subtitle : "",
		company: workExperience.title ? workExperience.title : "",
		current: !workExperience.ended && workExperience.started ? true : false,
		startYear: workExperience.started ? workExperience.started : "",
		endYear: workExperience.ended ? workExperience.ended : "",
		description: workExperience.description
			? workExperience.description
			: "",
	};

	const handleDelete = () => {
		if (!workExperience.id || status !== "idle") return;
		sendAlert({
			type: "confirm",
			title: t("delete.title"),
			text: t("delete.text"),
			onConfirm: () => {
				actions.delete(workExperience.id).then(() => {
					modal.onClose();
				});
			},
		});
	};

	return (
		<Formik
			{...{ initialValues }}
			validateOnChange
			validateOnBlur
			enableReinitialize
			validateOnMount
			validationSchema={Yup.object().shape(scheme)}
			onSubmit={(values: any, { setSubmitting }) => {
				const form = {
					title: values.company,
					subtitle: values.function,
					started: values.startYear,
					ended: values.endYear,
					description: values.description,
				};

				if (workExperience.id) {
					actions
						.update(
							{
								id: workExperience.id,
								...form,
							},
							{
								updateList: true,
							}
						)
						.then((data: any) => {
							setSubmitting(false);
							modal.onClose();
						});
				} else {
					actions.create(form).then(() => {
						setSubmitting(false);
						actions.list({
							page: 1,
						});
						modal.onClose();
					});
				}
			}}
		>
			{({
				values,
				errors,
				touched,
				handleChange,
				handleSubmit,
				handleBlur,
				isSubmitting,
				isValid,
			}) => (
				<form className="flex flex-col gap-4">
					<Input
						autoFocus
						label={t("input.function.label")}
						className={""}
						placeholder={t("input.function.label")}
						name="function"
						value={values.function}
						valid={values.function && !errors.function}
						error={
							errors.function &&
							touched.function &&
							errors.function
						}
						{...{ handleChange, handleBlur }}
					/>
					<Input
						label={t("input.company-name.label")}
						className={""}
						placeholder={t("input.company-name.label")}
						name="company"
						value={values.company}
						valid={values.company && !errors.company}
						error={
							errors.company && touched.company && errors.company
						}
						{...{ handleChange, handleBlur }}
					/>
					<Switch
						changeColor
						className={"mb-4"}
						value={values.current}
						label={t("switch.label")}
						onChange={(value) => {
							handleChange({
								target: {
									name: "current",
									value,
								},
							});
						}}
					/>
					<Input
						type="number"
						label={t("input.date.start")}
						placeholder={t("input.date.placeholder")}
						name="startYear"
						value={values.startYear}
						valid={values.startYear && !errors.startYear}
						error={
							errors.startYear &&
							touched.startYear &&
							errors.startYear
						}
						{...{ handleChange, handleBlur }}
					/>
					<div className={`${values.current ? "hidden" : ""}`}>
						<Input
							type="number"
							label={t("input.date.end")}
							placeholder={t("input.date.placeholder")}
							name="endYear"
							value={values.endYear}
							valid={values.endYear && !errors.endYear}
							error={
								errors.endYear &&
								touched.endYear &&
								errors.endYear
							}
							{...{ handleChange, handleBlur }}
						/>
					</div>
					<Input
						label={t("multiline.label")}
						className={""}
						multiline={true}
						placeholder={t("multiline.placeholder")}
						name="description"
						value={values.description}
						valid={values.description}
						{...{ handleChange, handleBlur }}
					/>
					<div className="flex flex-col gap-4">
						<Button
							disabled={!isValid}
							loading={
								isSubmitting ||
								status === "deleting" ||
								status === "loading"
							}
							className="w-full"
							type="dark"
							onClick={handleSubmit}
						>
							{id ? t("edit") : t("add")}
						</Button>
						{id && (
							<Button
								disabled={status === "loading"}
								loading={
									status === "loading" ||
									status === "deleting"
								}
								onClick={handleDelete}
								className="w-full"
								type="red"
							>
								{t("delete.button")}
							</Button>
						)}
					</div>
				</form>
			)}
		</Formik>
	);
}

WorkExperienceFormModal.locale = {
	nl: {
		title: "Werkervaring",
		add: "Toevoegen",
		delete: {
			button: "Verwijderen",
			title: "Weet je het zeker?",
			text: "Weet je zeker dat je deze werkervaring wilt verwijderen",
		},
		edit: "Opslaan",
		input: {
			function: {
				label: "Functienaam",
			},
			"company-name": {
				label: "Bedrijfsnaam",
			},
			date: {
				"year-future": "Het jaartal ligt in de toekomst",
				"type-year": "Moet een jaar zijn",
				start: "Start jaar",
				end: "Eind jaar",
				placeholder: "yyyy",
			},
		},
		switch: {
			label: "Dit is mijn huidige baan",
		},
		multiline: {
			label: "Wat heb je in deze functie gedaan",
			placeholder: "Omschrijving",
		},
		required: "Verplicht",
		"end-date-before-start-date": "Eind datum is voor start datum",
	},
	en: {
		title: "Work experience",
		add: "Add",
		delete: {
			button: "Delete",
			title: "Are you sure?",
			text: "Are you sure that you want to delete this work experience",
		},
		edit: "Save",
		input: {
			function: {
				label: "Function name",
			},
			"company-name": {
				label: "Company name",
			},
			date: {
				"year-future": "Year is in the future",
				"type-year": "Must be a year",
				start: "Start year",
				end: "Eind year",
				placeholder: "yyyy",
			},
		},
		switch: {
			label: "This is my current job",
		},
		multiline: {
			label: "What have you done in this function",
			placeholder: "Description",
		},
		required: "Required",
		"end-date-before-start-date": "End date is before start date",
	},
};

export default withTranslation(WorkExperienceFormModal);
