const offer = {
	status: {
		pending: "In waiting",
		denied: "Denied",
		accepted: "Approved",
	},
	"offer-error-card": {
		title: "Something is not quite right",
		description:
			"Refresh the page, if this issue still persists please email <1>support@{{tenant.slug}}.nl</1>",
		"try-again": "Try again",
		"refresh-page": "Reload page",
	},
	"offer-registration-match-card": {
		"envelope-not-signed":
			"The agreement must be fully signed before you can complete.",
		"missing-payment": "There must be a payment before you can complete",
		alert: {
			success: "Task approved",
		},
		signers: {
			title: "Agreement",
		},
	},
	"offer-share-card": {
		"not-allowed": {
			title: "You cannot share your offer",
			description: "Your offer must be verified and published",
		},
		title: "Share your project",
	},
	"offer-summary-card": {
		yes: "Yes",
		no: "No",
		unknown: "Not specified",
		"previous-step": "Previous step",
		publish: "Publish the task",
		concept: "Save as concept",
		description: {
			intro: "Introduction",
			what: "What I will do for your company",
			how: "This is how I will do it",
		},
		visibility: {
			title: "Visibility",
			category: "Category:",
			"category-not-found": "Category not found",
			everybody: "Everyone can see my task:",
			"show-profile": "Show my profile with the task:",
		},
		budget: {
			title: "Budget",
			budget: "You will receive the following on your account:",
			"estimated-duration": "Estimated duration",
			hour: "hour",
		},
	},
	"archive-offer-button": {
		confirm: {
			title: "Archive offer",
			description: "Are you sure you want to archive this offer?",
		},
	},
	"offer-accept-form": {
		title: "Ready to take on the task?",
		description:
			"Are you satisfied with the price and the task? Then click approve task. A task will then be created at the company where you can work further.",
		agree: "Yes I want to take on the task",
		button: "Approve task",
	},
	"offer-available-work-switch": {
		all: "Available to work",
		detail: "Show task",
	},
	"offer-bid-form": {
		title: "Propose another price?",
		description: "This task is currently worth {{price}}",
		placeholder: "Amount",
		button: "Adjust price",
	},
	"offer-deny-form": {
		title: "Reject Task",
		description: "Not quite what you wanted? Decline the task.",
		checkbox: "Yes, I want to decline the task",
		button: "Reject Task",
	},
	"publish-offer-button": {
		confirm: {
			title: "Publish Offer",
			description: "Are you sure you want to publish this offer?",
		},
		button: "Publish Offer",
	},
	form: {
		budget: "Task Value",
		"estimated-duration": "Estimated Duration",
		"title-budget": "Budget & Duration",
		description: {
			intro: "Tell us about yourself",
			"intro-placeholder": "My name is {{name}}",
			what: "What will you do for the company",
			"what-placeholder": "I will update the company's social media...",
			how: "How will you do it for the company",
			"how-placeholder": "I expect growth through 3 different posts...",
		},
		skills: "Skills",
		"skills-placeholder": "Word, SEO, Web Design",
		title: {
			unknown: "No title found",
			label: "Type a title for your project",
			placeholder: "Title",
			example: {
				title: "For example",
				"example-one": "Maintain social media",
				"example-two": "Write SEO blogs",
				"example-three": "Help with events for a day",
				"example-four": "Custom website",
				"example-five": "I deliver 10 new customers",
			},
		},
		category: {
			label: "Choose a category",
			placeholder: "Category",
		},
		"category-manual": {
			label: "Does your category not appear? Then create it yourself",
			placeholder: "Category name",
		},
		visibility: {
			title: "Visibility",
			"is-private": "Everyone can see my task",
			"is-anonymous": "Show my profile with the task",
		},
		"title-description": "Title & Description",
		"project-title": "Project Title",
		steps: {
			title: "Title",
			description: "Describe",
			visibility: "Visibility",
			skills: "Skills",
			budget: "Budget",
		},
	},
	"offer-edit-save-button": {
		confirm: {
			title: "Are you sure you want to adjust your offer?",
			description:
				"The offer must be approved again by a {{tenant.code}} employee",
		},
	},
	"offer-list": {
		"empty-student": {
			title: "You have not created an offer yet",
			description: "Create offer",
		},
	},
	"offer-registration-company-list": {
		empty: {
			title: "No professionals found",
			description: "Find your professionals",
		},
	},
	"offer-registration-list": {
		title: "Requests",
		empty: {
			title: "You have no registrations yet",
			description:
				"Tip: Spread your offer also in your own network, who knows who can use your skills.",
		},
		filters: {
			all: "All",
			accepted: "Accepted",
			denied: "Denied",
		},
	},
};

export default offer;
