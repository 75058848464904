import { ApiRegistration } from "src/api/types";
import useApi from "src/hooks/api/utils/useApi";

function useRegistrationsByTask(taskId: string) {
	const { state, actions, api, pagination } = useApi(
		{ id: "REGISTRATIONS" },
		{
			baseUrl: `/tasks/${taskId}/registrations`,
			query: {
				limit: 2,
				with: ["favorite", "skills", "score"],
			},
		}
	);

	const registrations: ApiRegistration[] = state?.list || [];

	return {
		registrations,
		status: state.status,
		pagination,
		actions,
		api,
	};
}

export default useRegistrationsByTask;
