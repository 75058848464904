import { useTranslation } from "react-i18next";
import useTenant from "src/hooks/api/services/tenants/useTenant";
import FixedFeeForm from "src/components/tasks/TaskForm/steps/budget/FixedFeeForm";
import Tabs from "src/components/layout/Tabs";
import PerHourForm from "src/components/tasks/TaskForm/steps/budget/PerHourForm";
import useTaskForm from "src/hooks/api/tasks/useTaskForm";
import { TaskFormOptions } from "src/components/tasks/TaskForm";

interface BudgetFormProps extends TaskFormOptions {
	amountLocked?: boolean;
}

const BudgetForm = ({ amountLocked, hideButtons }: BudgetFormProps) => {
	const { settings } = useTenant();
	const { t } = useTranslation("forms", {
		keyPrefix: "budget-form",
	});
	const { task } = useTaskForm();
	const defaultTab = task.budget_per_hour
		? t("tabs.pay-per-hour")
		: t("tabs.fixed-fee");
	const tabs = [t("tabs.pay-per-hour"), t("tabs.fixed-fee")];

	if (settings.tasks.budget_per_hour && !task.subtasks?.length) {
		return (
			<Tabs {...{ tabs, defaultTab }}>
				{(tab) => {
					if (tab === t("tabs.pay-per-hour")) {
						return (
							<PerHourForm {...{ amountLocked, hideButtons }} />
						);
					}
					return <FixedFeeForm {...{ amountLocked, hideButtons }} />;
				}}
			</Tabs>
		);
	}

	return <FixedFeeForm {...{ amountLocked, hideButtons }} />;
};

export default BudgetForm;
