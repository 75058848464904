import { Formik } from "formik";
import * as Yup from "yup";
import axios from "axios";
import Button from "src/components/Button";
import Input from "src/components/form/Input";
import useAuth from "src/hooks/selectors/useAuth";
import useAlert from "src/hooks/useAlert";
import Switch from "src/components/form/Switch";
import { useTranslation } from "react-i18next";

const initialValues = {
	use_custom_address: false,
	company_name: "",
	contact: "",
	street: "",
	house_number: "",
	zipcode: "",
	city: "",
};
const baseValidationSchema = Yup.object({
	company_name: Yup.string().required(),
	contact: Yup.string(),
	street: Yup.string().required(),
	house_number: Yup.string().required(),
	zipcode: Yup.string().required(),
	city: Yup.string().required(),
});
const validationSchema = Yup.object({
	use_custom_address: Yup.boolean(),
	contact: Yup.string(),
	company_name: Yup.string(),
	street: Yup.string(),
	house_number: Yup.string(),
	zipcode: Yup.string(),
	city: Yup.string(),
});

const EditInvoiceAddress = () => {
	const { t } = useTranslation(["forms", "buttons"]);
	const [sendAlert] = useAlert();
	const auth = useAuth();
	const value = {
		...initialValues,
		...auth?.company?.invoice_address,
	};

	return (
		<div>
			<Formik
				initialValues={value}
				validationSchema={validationSchema}
				onSubmit={(values, { setSubmitting }) => {
					if (values.use_custom_address) {
						try {
							baseValidationSchema.validateSync(values);
						} catch {
							setSubmitting(false);
							return sendAlert({
								title: t("alert-error.title", {
									ns: "forms",
									keyPrefix: "edit-invoice-address",
								}),
								text: t("alert-error.description-fields", {
									ns: "forms",
									keyPrefix: "edit-invoice-address",
								}),
							});
						}
					}

					axios
						.put("/company/invoice-address", values)
						.then(() => {
							sendAlert({
								title: t("alert-success.title", {
									ns: "forms",
									keyPrefix: "edit-invoice-address",
								}),
							});
						})
						.catch(() =>
							sendAlert({
								title: t("alert-error.title", {
									ns: "forms",
									keyPrefix: "edit-invoice-address",
								}),
								text: t("alert-error.description-unknown", {
									ns: "forms",
									keyPrefix: "edit-invoice-address",
								}),
							})
						)
						.finally(() => setSubmitting(false));
				}}
			>
				{({
					values,
					handleChange,
					handleBlur,
					handleSubmit,
					isSubmitting,
					setFieldValue,
				}) => (
					<form
						onSubmit={handleSubmit}
						className="mt-4 flex flex-col gap-4"
					>
						<Switch
							className="mb-4"
							value={values.use_custom_address}
							onChange={(value) =>
								setFieldValue("use_custom_address", value)
							}
							label={t("switch", {
								ns: "forms",
								keyPrefix: "edit-invoice-address",
							})}
						/>
						{values.use_custom_address ? (
							<>
								<Input
									name="company_name"
									label={t("company-name", {
										ns: "forms",
										keyPrefix: "edit-invoice-address",
									})}
									valid={values.company_name}
									value={values.company_name}
									error={!values.company_name}
									{...{ handleBlur, handleChange }}
								/>
								<Input
									name="contact"
									label={t("contact", {
										ns: "forms",
										keyPrefix: "edit-invoice-address",
									})}
									valid={values.contact}
									value={values.contact}
									error={!values.contact}
									{...{ handleBlur, handleChange }}
								/>
								<Input
									name="street"
									label={t("street", {
										ns: "forms",
										keyPrefix: "edit-invoice-address",
									})}
									valid={values.street}
									value={values.street}
									error={!values.street}
									{...{ handleBlur, handleChange }}
								/>
								<Input
									name="house_number"
									label={t("house-number", {
										ns: "forms",
										keyPrefix: "edit-invoice-address",
									})}
									valid={values.house_number}
									value={values.house_number}
									error={!values.house_number}
									{...{ handleBlur, handleChange }}
								/>
								<Input
									name="zipcode"
									label={t("zipcode", {
										ns: "forms",
										keyPrefix: "edit-invoice-address",
									})}
									valid={values.zipcode}
									value={values.zipcode}
									error={!values.zipcode}
									{...{ handleBlur, handleChange }}
								/>
								<Input
									name="city"
									label={t("city", {
										ns: "forms",
										keyPrefix: "edit-invoice-address",
									})}
									valid={values.city}
									value={values.city}
									error={!values.city}
									{...{ handleBlur, handleChange }}
								/>
							</>
						) : (
							false
						)}
						<div className="flex">
							<Button submit loading={isSubmitting}>
								{t("save", {
									ns: "buttons",
								})}
							</Button>
						</div>
					</form>
				)}
			</Formik>
		</div>
	);
};

export default EditInvoiceAddress;
