import React from "react";
import { useDroppable } from "@dnd-kit/core";
import GhostItem from "src/components/kanban/GhostItem";
import useKanban from "src/hooks/useKanban";
import { cn } from "src/lib/utils";

interface ColumnProps {
	id: string;
	children?: any;
	options?: {
		disabled?: boolean;
	};
	className?: string;
	head?: React.ReactNode;
}

const Column = ({ id, children, options, className, head }: ColumnProps) => {
	const { current } = useKanban();
	const { isOver, setNodeRef: ref } = useDroppable({
		id,
	});

	const getDroppableProps = () => {
		if (options?.disabled) return {};
		return {
			ref,
		};
	};

	return (
		<div
			{...getDroppableProps()}
			className={cn(
				"transition-all flex flex-col",
				"border-l pl-4 border-border",
				className
			)}
		>
			{head && <div className="mb-4">{head}</div>}
			<div
				{...{ ref }}
				className="flex flex-col flex-1 gap-5 w-[60vw] md:w-[285px] xl:w-[360px]"
			>
				{isOver && current?.from !== id && <GhostItem />}
				{children}
			</div>
		</div>
	);
};

export default Column;
