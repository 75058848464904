import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { ApiTask } from "src/api/types";
import useTenant from "src/hooks/api/services/tenants/useTenant";
import useAuth from "src/hooks/selectors/useAuth";
import useModules from "src/hooks/selectors/useModules";

function useTaskTabs(task?: ApiTask) {
	const { t } = useTranslation("task-view", {
		keyPrefix: "tabs",
	});
	const auth = useAuth();
	const { modules } = useModules();
	const { settings, tenant } = useTenant();
	const [, isFullyMatched] = useMemo((): [boolean, boolean] => {
		if (!task) return [false, false];

		if (task.matched_students && task.amount_of_students)
			if (task.matched_students >= task.amount_of_students)
				return [true, true];
			else if (task.matched_students > 0) return [true, false];
			else;
		else if (task.is_matched) return [true, true];

		return [false, false];
	}, [task]);
	const can = task?.can || [];
	const tabs = [];

	if (
		!task?.has_subtasks &&
		task?.type === "PUBLISHED" &&
		can.includes("edit")
	) {
		tabs.push({
			to: `/tasks/${task?.hid}/show`,
			label: isFullyMatched ? t("registration") : t("registrations"),
		});
	}

	if (auth.type === "student" && !can.includes("edit")) {
		tabs.push({
			to: `/tasks/${task?.hid}/show`,
			label: t("task"),
		});
	}

	if (tenant.modules?.includes("intern-task") && can.includes("edit")) {
		tabs.push({
			to: `/tasks/${task?.hid}/editor`,
			label: t("editor"),
		});
	}

	if (task?.media?.length) {
		tabs.push({
			to: `/tasks/${task?.hid}/files`,
			label: t("files"),
		});
	}

	if (task?.has_subtasks && can.includes("edit")) {
		tabs.push({
			to: `/tasks/${task?.hid}/show`,
			label: t("shifts"),
		});
	}

	if (
		modules.includes("student-pool") &&
		!task?.has_subtasks &&
		task?.type === "PUBLISHED" &&
		can.includes("edit")
	) {
		tabs.push({
			to: `/tasks/${task?.hid}/invites`,
			label: t("invites"),
		});
	}
	if (
		task?.type !== "DRAFT" &&
		!task?.has_subtasks &&
		task?.type === "PUBLISHED" &&
		can.includes("edit")
	) {
		tabs.push({
			to: `/tasks/${task?.hid}/statistics`,
			label: t("statistics"),
		});
	}
	if (
		settings.tasks.budget_per_hour &&
		!task?.has_subtasks &&
		can.includes("edit")
	) {
		tabs.push({
			to: `/hours/${task?.hid}/show`,
			label: t("hours"),
		});
	}

	if (task?.type === "PUBLISHED" && can.includes("edit")) {
		tabs.push({
			to: `/tasks/${task?.hid}/edit`,
			label: t("edit"),
		});
	}

	return {
		tabs,
	};
}

export default useTaskTabs;
