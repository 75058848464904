import { useTranslation } from "react-i18next";
import { ApiRegistration } from "src/api/types";
import Button from "src/components/Button";
import PaymentStatus from "src/components/payment/PaymentStatus";
import Signers from "src/components/signer/Signers";
import RegistrationSection from "src/components/tasks/panes/registration/RegistrationSection";

interface RegistrationMatchDetailsProps {
	registration: ApiRegistration;
}

const RegistrationMatchDetails = ({
	registration,
}: RegistrationMatchDetailsProps) => {
	const { t } = useTranslation("registrations", {
		keyPrefix: "registration-match-details",
	});
	return (
		<div className="flex lg:flex-row flex-col flex-1 gap-6">
			{registration?.envelope && (
				<RegistrationSection
					className="flex-1 text-background-foreground"
					title={t("envelope")}
				>
					<Signers
						id={registration?.envelope?.id}
						hid={registration?.envelope?.hid}
						variant="white"
						showButton
						isUpdateViewed={false}
					/>
				</RegistrationSection>
			)}
			{registration?.payment && (
				<RegistrationSection
					className="flex-1 text-background-foreground"
					title={t("payment")}
				>
					<PaymentStatus
						className="flex-1"
						variant="details"
						payment={registration?.payment}
						{...{ registration }}
					/>
				</RegistrationSection>
			)}
			{!registration?.payment && (
				<RegistrationSection
					className="flex-1 text-background-foreground"
					title={t("payment")}
				>
					<p>{t("no-payment")}</p>
					<div className="flex items-center">
						<Button
							to={`/tasks/${registration?.task?.hid}/show/${registration?.hid}/accept`}
							type="dark"
						>
							{t("create-payment")}
						</Button>
					</div>
				</RegistrationSection>
			)}
		</div>
	);
};

export default RegistrationMatchDetails;
