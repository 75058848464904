import "@formatjs/intl-listformat/locale-data/en"; // locale-data for en
import "@formatjs/intl-listformat/locale-data/nl"; // locale-data for en
import "@formatjs/intl-listformat/polyfill";
import queryString from "query-string";

export const formatPrice = (
	n?: number,
	currency = "EUR",
	digits: number | "auto" = "auto"
) => {
	let params = {
		style: "currency",
		currency,
	} as any;

	if (digits === "auto") {
		if ((n || 0) % 1 === 0) {
			params = {
				...params,
				minimumFractionDigits: 0,
				maximumFractionDigits: 0,
				useGrouping: false,
			};
		} else {
			params.minimumFractionDigits = 2;
		}
	} else {
		params.minimumFractionDigits = digits;
	}

	params.useGrouping = true;

	return new Intl.NumberFormat("nl-NL", params).format(n || 0);
};

export const formatNumber = (n: number) => {
	return new Intl.NumberFormat().format(n);
};

export const formatDecimal = (n: number, digits = 2) => {
	const formatter = new Intl.NumberFormat("nl-NL", {
		minimumFractionDigits: digits,
	});
	return formatter.format(n);
};

export const ucFirst = (str: string) => {
	return str.charAt(0).toUpperCase() + str.slice(1);
};

export const formatExcerpt = (str?: string, length: number = 40) => {
	if (!str) return "";
	if (str?.length > length) {
		str = str?.substring(0, length) + "...";
	}
	return str;
};

export function kFormatter(num: number, type = "currency") {
	const fixed = (Math.abs(num) / 1000).toFixed(1) as any;
	const formatter = new Intl.NumberFormat("nl-NL", {
		style: "currency",
		currency: "EUR",
		minimumFractionDigits: 2,
	});

	if (type === "currency") {
		return Math.abs(num) > 999
			? `€ ${Math.sign(num) * fixed}k`
			: formatter.format(Math.sign(num) * Math.abs(num));
	}
	return Math.abs(num) > 999
		? `${Math.sign(num) * fixed}k`
		: Math.sign(num) * Math.abs(num);
}

type FilterType = {
	[key: string]: any;
};

export const formatUrl = (url: string, filters: FilterType) => {
	if (!url) {
		return undefined;
	}
	let _filters = filters || {};
	const urlParts = url.split("?");
	const base = urlParts[0];
	if (urlParts?.length > 1) {
		const search = urlParts[urlParts?.length - 1];
		const parsed = queryString.parse(search);
		_filters = {
			..._filters,
			...(parsed || {}),
		};
	}

	const q = queryString.stringify(_filters || {});

	return `${base}?${q || ""}`;
};

export const qsToObject = (str?: string) => {
	return Object.fromEntries(new URLSearchParams(str || ""));
};

export const cleanHTMLText = (str: string) => {
	return str.replace(/<\/?[^>]+(>|$)/gi, "");
};

export function cutString(text: string, maxLength: number) {
	return text?.length <= maxLength
		? text
		: text.slice(0, maxLength - 3) + "...";
}

export function formatList(list: string[], language = "nl") {
	const formatter = new Intl.ListFormat((language || "nl").toLowerCase(), {
		style: "long",
		type: "conjunction",
	});
	return formatter.format(list);
}

export function roundToDecimalPlace(
	value: number,
	decimalPlaces: number
): number {
	const factor = Math.pow(10, decimalPlaces);
	return Math.round(value * factor) / factor;
}
