import React from "react";
import { cn } from "src/lib/utils";

interface SkeletonProps {
	bg?: string;
}

const Skeleton: React.FC<SkeletonProps & React.HTMLProps<HTMLDivElement>> = ({
	bg,
	className,
	...rest
}) => {
	return (
		<div
			{...rest}
			className={cn(
				"animate-pulse bg-accent rounded-md flex",
				bg && `bg-${bg}`,
				className
			)}
		>
			{rest.children}
		</div>
	);
};

export default Skeleton;
