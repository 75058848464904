import { Helmet } from "react-helmet";
import { getCurrentDomainName } from "src/lib/domain";

const TenantManifest = () => {
	const domain = getCurrentDomainName();

	return (
		<Helmet>
			<link
				rel="manifest"
				href={process.env.PUBLIC_URL + `/manifest/${domain}.json`}
			/>
		</Helmet>
	);
};

export default TenantManifest;
