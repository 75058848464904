import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import CustomForm from "src/components/custom-form/CustomForm";
import withTranslation, {
	Translation,
} from "src/components/hoc/withTranslation";
import Loader from "src/components/Loader";
import useConnectionCustomForm from "src/hooks/api/services/forms/useConnectionCustomForm";
import useConnections from "src/hooks/api/services/users/useConnections";
import useAuth from "src/hooks/selectors/useAuth";
import useModal from "src/hooks/useModal";

const DEFAULT_QUERY = {
	with: ["form", "forms", "values", "fields"],
	response_type: "option",
};

const NetworkCustomFormModal = ({ t }: Translation) => {
	useModal({
		title: t("title"),
	});
	const auth = useAuth();
	const params = useParams();
	const navigate = useNavigate();
	const [isLoading, setIsLoading] = useState(true);
	const filter = {
		...DEFAULT_QUERY,
		company_id:
			auth.type === "company" ? auth.company.id : params.connectionId,
	};
	const {
		connection,
		api,
		actions: connectionsActions,
	} = useConnections({
		filter: DEFAULT_QUERY,
		rememberKey: "NETWORK_CONNECTIONS",
	});
	const { forms } = useConnectionCustomForm({
		company_id: filter.company_id,
	});
	const form = connection?.form || forms[0];
	// const form = forms[0];

	useEffect(() => {
		connectionsActions.get(params.connectionId).finally(() => {
			setIsLoading(false);
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const onSubmit = async (values: any) => {
		const result = await api.patch(
			`/${params.connectionId}/values`,
			{
				form_id: form.id,
				values,
			},
			{
				params: DEFAULT_QUERY,
			}
		);
		connectionsActions.setItem(result?.data, { updateList: true });
		navigate(`/network`);
	};

	if (isLoading) {
		return (
			<div className="flex flex-col justify-center items-center p-6">
				<Loader />
			</div>
		);
	}

	return (
		<div className=" ">
			{form && (
				<CustomForm
					{...{ form, value: connection?.values, onSubmit }}
				/>
			)}
		</div>
	);
};

NetworkCustomFormModal.locale = {
	nl: {
		title: "Informatie",
	},
	en: {
		title: "Information",
	},
};

export default withTranslation(NetworkCustomFormModal);
