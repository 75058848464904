import React from "react";
import { useTranslation } from "react-i18next";
import { ApiOfferRegistrationStatus } from "src/api/types";
import Badge from "src/components/Badge";

interface OfferRegistrationStatusProps {
	status: ApiOfferRegistrationStatus;
}

const OfferRegistrationStatus = ({ status }: OfferRegistrationStatusProps) => {
	const { t } = useTranslation("offer", { keyPrefix: "status" });

	const statuses = {
		PENDING: {
			bg: "gray",
			color: "dark",
			label: t("pending"),
		},
		DENIED: {
			bg: "red-200",
			color: "red",
			label: t("denied"),
		},
		ACCEPTED: {
			bg: "green",
			color: "white",
			label: t("accepted"),
		},
	} as any;
	const props = statuses[status || "PENDING"];

	return <Badge {...props}>{props.label}</Badge>;
};

OfferRegistrationStatus.defaultProps = {
	status: "PENDING",
};

export default OfferRegistrationStatus;
