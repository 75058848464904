import { useLocation } from "react-router-dom";
import { ApiIcon } from "src/api/types";
import Badge from "src/components/Badge";
import Link from "src/components/Link";
import Indicator from "src/components/layout/Indicator";
import NavItemIcon from "src/components/layout/layout-default/sidebar/NavItemIcon";
import {
	Tooltip,
	TooltipContent,
	TooltipProvider,
	TooltipTrigger,
} from "src/components/ui/tooltip";
import useLayout from "src/hooks/useLayout";
import useWindowSize from "src/hooks/useWindowSize";
import { cn } from "src/lib/utils";

interface NavItemProps {
	to?: string;
	onClick?: () => void;
	icon?: ApiIcon | string | JSX.Element;
	iconOverlay?: JSX.Element;
	children?: any;
	className?: string;
	count?: number | null;
	loading?: boolean;
	childItems?: JSX.Element[];
	isChild?: boolean;
	open?: boolean;
	disabled?: boolean;
	help?: string;
	showExternalIcon?: boolean;
	activeStartsWith?: string;
	isNew?: boolean;
}

const NavItem = ({
	to,
	icon,
	iconOverlay,
	children,
	className: _className,
	onClick,
	loading,
	count,
	disabled,
	help,
	showExternalIcon,
	activeStartsWith,
	isNew,
}: NavItemProps) => {
	const { layout } = useLayout();
	const location = useLocation();
	let isActive =
		to === location.pathname ||
		(activeStartsWith && location.pathname.startsWith(activeStartsWith));
	const Element = to && !disabled ? Link : "div";
	const { breakpoint } = useWindowSize();
	const isExternal = to && to.startsWith("http");

	const renderElement = () => (
		<Element
			to={to || "/"}
			className={cn(
				"flex items-center w-full rounded-md transition-all border p-0.5 border-transparent hover:border-border group gap-1 select-none",
				isActive &&
					"bg-accent border-border dark:border-border dark:bg-border",
				layout.navbar.variant !== "default" && "group",
				disabled && "opacity-60 cursor-not-allowed",
				_className
			)}
			{...{ disabled }}
			onClick={() => {
				if (onClick) {
					onClick();
					return;
				}
			}}
		>
			<NavItemIcon
				{...{ icon, loading, isActive, iconOverlay }}
				isActive={isActive ? true : false}
				count={
					layout.navbar.variant === "minimal" || breakpoint === "lg"
						? count
						: undefined
				}
			/>
			<div
				className={cn(
					"flex lg:hidden flex-1 xl:flex justify-between items-center relative",
					"xl:hidden",
					layout.navbar.variant === "default" && "flex xl:flex",
					(layout.navbar.variant === "minimal" ||
						breakpoint === "lg") &&
						"group-hover:flex xl:group-hover:flex group-hover:absolute group-hover:z-[98] group-hover:left-14 group-hover:bg-accent dark:group-hover:bg-accent group-hover:border group-hover:border-border group-hover:w-max group-hover:rounded-md group-hover:p-2 text-accent-foreground"
				)}
			>
				<span className={cn("font-regular text-[16px]")}>
					{children}
				</span>
				{isNew && (
					<Badge size="xsmall" className="rounded-md">
						NEW
					</Badge>
				)}
				{isExternal && showExternalIcon && (
					<i className="far fa-external-link-alt pr-2 text-xs opacity-50"></i>
				)}
				{count && count > 0 && layout.navbar.variant === "default" ? (
					<Indicator
						className={cn("mr-2 flex lg:hidden xl:flex")}
						// style={{
						// 	top: -6,
						// 	right: -10,
						// }}
						// className="absolute z-[99]"
					>
						{count}
					</Indicator>
				) : (
					false
				)}
			</div>
		</Element>
	);

	if (help) {
		return (
			<TooltipProvider>
				<Tooltip>
					<TooltipTrigger>{renderElement()}</TooltipTrigger>
					<TooltipContent>{help}</TooltipContent>
				</Tooltip>
			</TooltipProvider>
		);
	}

	return renderElement();
};

export default NavItem;
