import React from "react";
import { useTranslation } from "react-i18next";
import useAuth from "src/hooks/selectors/useAuth";
import { formatPrice } from "src/lib/formatters";
import Card from "src/components/Card";

const CurrentWallet = () => {
	const { t } = useTranslation("subscription", {
		keyPrefix: "current-wallet",
	});
	const auth = useAuth();
	const company = auth.company;
	if (!company.credit || company.credit <= 0) {
		return <></>;
	}
	return (
		<Card
			className="md:col-span-3"
			contentClassName="flex flex-col gap-4 p-6"
		>
			<strong>{t("title")}</strong>
			<div className="flex gap-2 items-end">
				<h2>{formatPrice(company.credit)}</h2>
				<small>{t("remaining")}</small>
			</div>
			<div>
				<strong>{t("why.title")}</strong>
				<p className="mb-0 mt-1">{t("why.description")}</p>
			</div>
		</Card>
	);
};

export default CurrentWallet;
