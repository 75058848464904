import { useEffect } from "react";
import Tag from "src/components/Tag";
import Select, { SelectTheme } from "src/components/field/fields/Select";
import { WrapperProps } from "src/components/field/utils/Wrapper";
import useCategories from "src/hooks/api/services/categories/useCategories";

interface CategoryProps {
	wrapper: WrapperProps;
	value: number;
	onChange: (value: number) => void;
	theme?: SelectTheme;
}

const Category = ({ wrapper, value, onChange, ...rest }: CategoryProps) => {
	const { categories, status, actions } = useCategories();
	useEffect(() => {
		if (status === "idle" && !categories.length) {
			actions.list();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<Select
			{...{ wrapper, ...rest }}
			value={value ? value.toString() : undefined}
			onChange={(value) => onChange(Number(value))}
			options={categories.map((category) => ({
				value: category.id.toString(),
				label: category.name,
			}))}
			theme={{
				...(rest?.theme || {}),
				value: {
					renderValue: (value: any) => (
						<Tag size="regular" className="font-bold">
							{value}
						</Tag>
					),
				},
			}}
		/>
	);
};

export default Category;
