import { useParams } from "react-router-dom";
import Button from "src/components/Button";
import Field from "src/components/field/Field";
import SearchInput from "src/components/form/SearchInput";
import SkillSelector from "src/components/form/skill-selector/SkillSelector";
import withTranslation, {
	Translation,
} from "src/components/hoc/withTranslation";
import TasksLayoutViews from "src/components/layout/tasks-layout/TasksLayoutViews";
import PublishedTasksCount from "src/components/tasks/PublishedTasksCount";
import RefreshKanbanButton from "src/components/tasks/RefreshKanbanButton";
import CreateTaskButton from "src/components/tasks/TaskForm/elements/CreateTaskButton";
import TaskStatus from "src/components/tasks/TaskStatus";
import useBoards from "src/hooks/api/services/tasks/useBoards";
import useTenant from "src/hooks/api/services/tenants/useTenant";
import useTasksFilter from "src/hooks/api/tasks/useTasksFilter";
import useSubscription from "src/hooks/selectors/useSubscription";
import useNavigateModal from "src/hooks/useNavigateModal";
import useWindowSize from "src/hooks/useWindowSize";

interface TasksLayoutHeadProps extends Translation {}

const TasksLayoutHead = ({ t }: TasksLayoutHeadProps) => {
	const { board } = useParams();
	const { boards } = useBoards();
	const activeBoard = (boards || []).find((item) => item.slug === board);
	const { tenant } = useTenant();
	const { filter, actions, count: filterCount } = useTasksFilter();
	const { isPhone } = useWindowSize();
	const subscription = useSubscription();
	const navigateModal = useNavigateModal();

	return (
		<div className="flex flex-col gap-3">
			{!!activeBoard?.skills?.length && (
				<div className="items-center hidden md:flex">
					<SkillSelector.Root
						value={activeBoard.skills}
						className="max-w-md"
						theme={{
							default: {
								className:
									"text-sm bg-accent border border-border",
								size: "small",
							},
							active: {
								className:
									"text-sm bg-accent border border-border text-background-foreground",
							},
						}}
						options={{
							limit: 4,
						}}
					>
						<SkillSelector.Results className="mb-0 gap-1.5" />
					</SkillSelector.Root>
				</div>
			)}

			<div className="flex sm:items-center justify-between sm:flex-row flex-col gap-3">
				{tenant.modules.includes("intern-task") ? (
					<>
						<TasksLayoutViews />
						<PublishedTasksCount />
					</>
				) : (
					<div className="flex">
						<Field.Select
							placeholder="Status"
							className="border border-border"
							value={filter.status}
							options={[
								{
									value: "open",
									label: <TaskStatus status="open" />,
								},
								{
									value: "IN_PROGRESS",
									label: <TaskStatus status="IN_PROGRESS" />,
								},
								{
									value: "COMPLETED",
									label: <TaskStatus status="COMPLETED" />,
								},
							]}
							onChange={(value) => actions.set({ status: value })}
						/>
					</div>
				)}
				<div className="flex items-center justify-between gap-4">
					<div className="flex items-center gap-4 empty:hidden">
						{tenant.modules.includes("intern-task") && (
							<RefreshKanbanButton />
						)}
						<SearchInput
							value={filter.q}
							onChange={(value) => {
								actions.set({
									q: value,
								});
							}}
						/>
					</div>
					<div className="flex items-center gap-4 empty:hidden">
						{!board && (
							<Button
								onClick={() => {
									navigateModal("./filter");
								}}
								type="transparent"
								iconOnly={isPhone}
								className="gap-3"
								small
							>
								<i className="fas fa-filter"></i>
								{!isPhone && <span>{t("filter")}</span>}
								{!!filterCount && (
									<strong className="w-6 h-6 text-sm rounded-md bg-accent flex justify-center items-center">
										{filterCount}
									</strong>
								)}
							</Button>
						)}
					</div>

					{tenant.modules.includes("intern-task") ? (
						<div className="flex items-center gap-4 empty:hidden">
							{subscription?.id && (
								<CreateTaskButton
									small
									iconOnly={isPhone}
									variant="EXTERN"
								/>
							)}
							<CreateTaskButton small variant="INTERN" />
						</div>
					) : (
						<>
							{tenant.modules.includes("subtasks") && (
								<Button
									type="transparent"
									to={{
										modal: `/tasks/import`,
									}}
								>
									<i className="fas fa-file-import mr-2"></i>
									{t("import")}
								</Button>
							)}
							<CreateTaskButton variant="CREATE" />
						</>
					)}
				</div>
			</div>
		</div>
	);
};

TasksLayoutHead.locale = {
	nl: {
		breadcrumbs: {
			home: "Alle taken",
		},
		"edit-board": "Bord aanpassen",
		"clear-filter": "Filter legen",
		filter: "Filter",
		"import-task": "Importeren",
	},
	en: {
		breadcrumbs: {
			home: "All tasks",
		},
		"edit-board": "Edit board",
		"clear-filter": "Clear filter",
		filter: "Filter",
		import: "Import",
	},
};

export default withTranslation(TasksLayoutHead);
