import moment from "moment";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { ApiReview } from "src/api/types";
import Avatar from "src/components/Avatar";
import Card from "src/components/Card";
import FlatList from "src/components/FlatList";
import withTranslation, {
	Translation,
} from "src/components/hoc/withTranslation";
import ResumeSectionTitle from "src/components/resume/resume-detail/ResumeSectionTitle";
import Stars from "src/components/Stars";
import useReviews from "src/hooks/api/services/resume/useReviews";
import { cn } from "src/lib/utils";
import useResumeDetailContext from "../ResumeDetailContext";

interface ResumeReviewsProps extends Translation {
	variant?: "grid" | "list" | "border-cards";
}

function ResumeReview({ t, variant = "grid" }: ResumeReviewsProps) {
	const { id } = useParams();
	const { resume } = useResumeDetailContext();
	const { reviews, pagination, status, actions } = useReviews({ id: id });
	const loading = status === "loading" && reviews.length === 0;

	useEffect(() => {
		actions.list({
			page: 1,
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	if (reviews?.length === 0 && resume.reviews_count === 0) {
		return <></>;
	}

	return (
		<div className="flex flex-col gap-4">
			<div className="flex flex-row justify-between items-center gap-2">
				<ResumeSectionTitle>{t("title")}</ResumeSectionTitle>
				<div className="flex flex-row gap-4 items-center">
					<Stars size="large" rating={resume.reviews_average} />
					<p>
						{t("reviews", {
							count: resume.reviews_count,
						})}
					</p>
				</div>
			</div>
			<div className="flex gap-8 flex-wrap">
				<FlatList
					empty={{ title: "" }}
					data={reviews}
					columns={["grid", "border-cards"].includes(variant) ? 2 : 1}
					gap={16}
					fetching={loading}
					{...{ loading }}
					pagination={{
						...pagination,
					}}
					renderItem={(item: ApiReview) => (
						<Card
							key={item.id}
							className={cn(
								variant === "list" && "shadow-none",
								variant === "border-cards" &&
									"shadow-none border border-border"
							)}
							contentClassName={cn(
								variant === "list" && "p-0 gap-4",
								variant === "border-cards" && "gap-2 p-5"
							)}
						>
							<div className="flex items-center justify-between gap-2">
								<div className="flex flex-row gap-4 items-center">
									<Avatar
										size="small"
										src={item.creator?.avatar}
									/>
									<div className="flex flex-col gap-1">
										<p className="leading-full text-sm">
											{item.creator?.full_name}
										</p>
										{item.creator?.company && (
											<small className="opacity-60">
												{item.creator?.company.name}
											</small>
										)}
									</div>
								</div>
								<div className="flex flex-col gap-1">
									<Stars size="small" rating={item.rating} />
									<small className="opacity-60 text-right">
										{moment(item.created_at).format(
											"DD/MM/YYYY"
										)}
									</small>
								</div>
							</div>
							<p>{item.description}</p>
							<div className="flex flex-col">
								<p className="text-sm opacity-60">
									{t("assignment")}
								</p>
								<p className="text-sm">{item.task?.title}</p>
							</div>
						</Card>
					)}
				/>
			</div>
		</div>
	);
}

ResumeReview.locale = {
	nl: {
		title: "Reviews",
		assignment: "Opdracht",
		reviews_one: "1 review",
		reviews_other: "{{count}} reviews",
	},
	en: {
		title: "Reviews",
		assignment: "Assignment",
		reviews_one: "1 review",
		reviews_other: "{{count}} reviews",
	},
};

export default withTranslation(ResumeReview);
