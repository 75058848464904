const shareNetwork = {
	"create-referral-code": {
		button: "Maak mijn code",
		alert: {
			title: "Jouw unieke code is aangemaakt",
			text: "Het is gelukt! Gebruik nu jouw code om bedrijven aan te melden",
		},
	},
	"share-referral-code": {
		title: "Deel jouw unieke link",
		description: "Via de volgende link kan jij bedrijven uitnodigen",
		share: {
			title: "Zullen we samenwerken?",
			text: "Zullen we samenwerken? Op {{tenant.name}} doe ik opdrachten voor bedrijven voor een fixed fee prijs en zonder contractuele verplichtingen. Meld je aan via mijn persoonlijke link: {{link}}",
		},
	},
	"share-referral-tutorial": {
		create: {
			title: "Maak jouw unieke link aan",
			description:
				"Jouw link kan je dan in je eigen netwerk delen met bedrijven",
		},
		share: {
			title: "Bedrijven melden zich aan",
			description:
				"Wanneer een bedrijf zich aanmeldt krijg je € 25. Sluit het bedrijf daarna een jaar abonnement af, dan krijg je nog eens €250",
		},
		paid: {
			title: "Krijg uitbetaald",
			description:
				"Aan het eind van elke maand checken we alle aanmeldingen, wanneer deze worden goedgekeurd betalen wij de comissies d.m.v. een opdracht uit",
		},
	},
	"share-social-referral": {
		facebook: "Delen op Facebook",
		linkedin: "Delen op Linkedin",
		twitter: "Delen op Twitter",
	},
	"referral-link-payouts": {
		empty: {
			title: "Er staan nog geen betalingen voor je klaar",
		},
		item: {
			text: {
				"signed-up": "{{name}} heeft zich aangemeld via jouw link",
				"placed-task":
					"{{name}} heeft een opdracht aangemaakt via jouw link`",
				"subscribed-year":
					"{{name}} heeft een jaarlijks abonnement afgesloten via jouw link`",
			},
			approved: "Goedgekeurd",
			pending: "In afwachting",
			"created-at": "aangemaakt op {{date}}",
		},
	},
};

export default shareNetwork;
