import { ApiBenefit } from "src/api/types";
import useApi from "src/hooks/api/utils/useApi";

export default function useShopBenefits() {
	const { state, actions, api, pagination } = useApi(
		{ id: "BENEFITS" },
		{
			baseUrl: `/client/benefits`,
			query: {
				featured: "SHOP",
			},
		}
	);
	const benefits: ApiBenefit[] = state?.list || [];

	const unlock = async (id: string) => {
		const { data } = await api
			.post(`/${id}/unlock`)
			.then(({ data }) => data);
		return data;
	};

	return {
		benefits,
		idea: state?.data,
		search: state?.filter?.q,
		status: state.status,
		pagination,
		actions: {
			...actions,
			unlock,
		},
		api,
	};
}
