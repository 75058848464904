import { useTranslation } from "react-i18next";
import Button from "src/components/Button";
import Card from "src/components/Card";
import FormGrid from "src/components/form/FormGrid";
import usePost from "src/hooks/rest/usePost";

interface EditBankAccountProps {
	as?: "card" | "form-grid";
}

const EditBankAccount = ({ as = "card" }: EditBankAccountProps) => {
	const { t } = useTranslation("forms", {
		keyPrefix: "edit-bank-account",
	});

	if (as === "form-grid") {
		return (
			<>
				<FormGrid.Header title={t("title")} />
				<FormGrid.Card>
					<div className="flex flex-col gap-1 mb-4">
						<p>{t("description")}</p>
					</div>
					<EditBankAccountButton />
				</FormGrid.Card>
			</>
		);
	}

	return (
		<Card title={t("title")}>
			<div className="flex flex-col gap-1 mb-4">
				<p>{t("description")}</p>
			</div>
			<EditBankAccountButton />
		</Card>
	);
};

const EditBankAccountButton = () => {
	const { t } = useTranslation("forms", {
		keyPrefix: "edit-bank-account",
	});
	const [createNewBankAccount, loading] = usePost(`/student/bank-accounts`);

	const onClick = () => {
		createNewBankAccount({}).then((res) => {
			let { verification_url } = res.data;
			window.location = verification_url;
		});
	};

	return (
		<Button small {...{ onClick, loading }} type="dark">
			{t("button")}
		</Button>
	);
};

export default EditBankAccount;
