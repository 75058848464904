import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import Button from "src/components/Button";
import FileUpload from "src/components/form/file-upload/FileUpload";
import Loader from "src/components/Loader";
import useEditProfile from "src/hooks/api/useEditProfile";
import useAuth from "src/hooks/selectors/useAuth";
import { uploadFile } from "src/lib/uploadFile";

interface EditAvatarProps {
	label?: string;
	variant?: "profile-picture" | "profile-picture-in-company";
	onDone?: () => void;
}

const EditAvatar = ({ label, variant, onDone }: EditAvatarProps) => {
	const { t } = useTranslation(["buttons", "common"]);
	const auth = useAuth();
	const { type } = auth;
	const isUserAvatar =
		type === "student" || variant === "profile-picture-in-company";
	const [media, setMedia] = useState(
		isUserAvatar ? auth.media : auth.company.media
	);
	const savedMedia = isUserAvatar ? auth.media : auth?.company?.media;
	const [updateProfile, loading] = useEditProfile(variant);
	const [saving, setSaving] = useState(false);

	const handleSave = () => {
		if (variant === "profile-picture") {
			handleSaveProfilePicture();
			return;
		}

		if (!media) {
			return;
		}

		const data = isUserAvatar
			? {
					media_id: media.id,
			  }
			: {
					company: {
						media_id: media.id,
					},
			  };
		updateProfile(data).then((res: any) => {
			if (onDone) {
				setMedia({});
				onDone();
			}
		});
	};

	const handleSaveProfilePicture = async () => {
		if (media.path) {
			setSaving(true);
			let fd = new FormData();
			fd.append("file", media);
			let res = await uploadFile("/media", fd);
			const media_id = res?.data?.data?.id;
			updateProfile(
				type === "student"
					? { media_id }
					: {
							company: {
								media_id,
							},
					  }
			).then(() => {
				setMedia({});
				if (onDone) {
					onDone();
				}
				setSaving(false);
			});
		}
	};
	if (variant === "profile-picture") {
		return (
			<>
				<FileUpload
					type="avatar"
					skipApi
					onChange={setMedia}
					value={media}
					className="flex-1"
				>
					{({ loading, preview, isDragActive }) => {
						return (
							<div className="p-8">
								<div className="max-w-16 mx-auto block">
									<div
										className={`block cursor-pointer rounded-md aspect-w-1 aspect-h-1 relative border-4 ${
											isDragActive
												? "border-green-300 bg-green-300"
												: "border-border-background bg-accent-background dark:border-dark dark:bg-dark"
										}`}
									>
										<div className="absolute inset-0 flex flex-col gap-4 justify-center items-center">
											{auth?.avatar || preview ? (
												<img
													className="max-w-[160px] max-h-[160px] object-cover rounded-md"
													src={preview || auth.avatar}
													alt={auth.full_name}
												/>
											) : (
												<img
													className="max-w-[100px] object-cover max-h-[100px] block"
													src="/assets/images/emoji/smiley.png"
													alt={auth.full_name}
												/>
											)}
											{loading || saving ? (
												<Loader />
											) : (
												<p className="text-center">
													<strong>Drag & Drop</strong>{" "}
													{t("or", {
														ns: "common",
													})}{" "}
													<strong className="text-primary cursor-pointer underline">
														{t("click-here", {
															ns: "buttons",
														})}
													</strong>
												</p>
											)}
										</div>
									</div>
								</div>
							</div>
						);
					}}
				</FileUpload>
				{media?.path && (
					<Button
						className="mt-4 mb-8 w-full"
						loading={saving}
						type="primary"
						onClick={(e) => {
							handleSaveProfilePicture();
						}}
					>
						{t("save", {
							ns: "buttons",
						})}
					</Button>
				)}
			</>
		);
	}

	return (
		<div className="edit-avatar">
			{label && <strong>{label}</strong>}

			<FileUpload type="avatar" onChange={setMedia} value={media} />
			<div className="buttons mt-4">
				{media?.id && savedMedia?.id !== media?.id && (
					<Button {...{ loading }} onClick={handleSave}>
						{t("save", {
							ns: "buttons",
						})}
					</Button>
				)}
				{savedMedia?.id && savedMedia.id === media?.id && (
					<Button onClick={() => setMedia({})} type="dark">
						{t("edit", {
							ns: "buttons",
						})}
					</Button>
				)}
			</div>
		</div>
	);
};

export default EditAvatar;
