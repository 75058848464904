import axios from "axios";
import moment from "moment";
import { useEffect, useRef, useState } from "react";
import Calendar from "src/components/Calendar";
import Loader from "src/components/Loader";
import Draggable, { DragChangeEvent } from "src/components/draggable";
import withTranslation, {
	Translation,
} from "src/components/hoc/withTranslation";
import Item from "src/components/tasks/calendar/Item";
import Sidebar from "src/components/ui/sidebar";
import useTasks from "src/hooks/api/services/tasks/useTasks";
import useTenant from "src/hooks/api/services/tenants/useTenant";
import useTaskFormAutoSave from "src/hooks/api/tasks/useTaskFormAutoSave";
import useTasksFilter from "src/hooks/api/tasks/useTasksFilter";
import { useTasksUpdater } from "src/hooks/api/tasks/useTasksUpdater";
import { useUpdateEffectDebounce } from "src/hooks/useDebounce";
import useWindowSize from "src/hooks/useWindowSize";

interface TasksCalendarProps extends Translation {}

const TasksCalendar = ({ t }: TasksCalendarProps) => {
	useTaskFormAutoSave();
	const calendar = useRef(null) as any;
	const [date, setDate] = useState(moment());
	const { filter } = useTasksFilter();
	const { isPhone } = useWindowSize();
	const { tenant } = useTenant();
	const { handleEvent } = useTasksUpdater();
	const { tasks, actions, pagination, status } = useTasks();

	const unplannedTasks = tasks.filter((item) => !item.end_date);
	const [sidebarOpen, setSidebarOpen] = useState(!!unplannedTasks.length);
	useUpdateEffectDebounce(filter, 500, () => {
		getCalendarItems();
	});

	const getCalendarItems = (options?: { scrollToNow?: boolean }) => {
		actions
			.list({
				...filter,
				variant: "kanban",
				date: [
					date.startOf("month").format("YYYY-MM-DD"),
					date.endOf("month").format("YYYY-MM-DD"),
				],
			})
			.then(() => {
				setTimeout(() => {
					if (options?.scrollToNow && calendar && calendar.current) {
						calendar.current.scrollToNow();
					}
				}, 500);
			});
	};

	useEffect(() => {
		getCalendarItems({ scrollToNow: true });
		return () => {
			actions.resetState();
			actions.resetFilter();
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const onChange = (event: DragChangeEvent) => {
		let data: any = {};
		if (event.to === "DATE:TODAY") {
			data.end_date = moment().format("YYYY-MM-DD");
		} else if (event.to === "DATE:OVERDUE") {
			data.end_date = null;
		} else {
			const value = event?.to.toString().split(":");
			if (value.length === 2) {
				data[value[0]] = value[1];
			}
		}
		if (Object.keys(data).length) {
			if (event.data) {
				handleEvent({
					type: "UPDATE",
					id: Number(event.id),
					data: {
						...event.data,
						...data,
					},
				});
			}
			axios.patch(`/client/tasks/${event.id}`, data);
		}
		if (unplannedTasks.length === 0) {
			setSidebarOpen(false);
		}
	};

	return (
		<Draggable {...{ onChange }}>
			<div className="flex flex-1 gap-8">
				{!isPhone &&
					tenant.modules.includes("intern-task") &&
					unplannedTasks?.length > 0 && (
						<Sidebar
							direction="right"
							open={sidebarOpen}
							onOpenChange={setSidebarOpen}
							className="flex flex-col"
						>
							<div className="flex flex-col gap-4 w-[300px]">
								{unplannedTasks.map((task) => (
									<Draggable.Item
										data={task}
										currentDropId="UNKNOWN"
										id={`LIST_${task.id}`}
										key={`task-${task.hid}`}
									>
										<Item {...{ task }} />
									</Draggable.Item>
								))}
								{pagination.page < pagination.last_page && (
									<span
										onClick={() =>
											pagination.setPage(
												pagination.page + 1
											)
										}
										className="cursor-pointer flex items-center gap-2 text-sm"
									>
										{status === "loading" && <Loader />}

										{t("load-more")}
									</span>
								)}
							</div>
						</Sidebar>
					)}
				<div className="flex flex-col flex-1">
					<Calendar
						selected={date}
						actions={{
							onSelectChange: (date) => {
								setDate(date);
							},
						}}
						controls={{
							interval: "month",
						}}
						head={{
							actions: (
								<>
									{status === "loading" && <Loader />}
									{pagination.page < pagination.last_page && (
										<span
											onClick={() =>
												pagination.setPage(
													pagination.page + 1
												)
											}
											className="cursor-pointer flex items-center gap-2 text-sm"
										>
											{t("load-more")}
										</span>
									)}
									<Calendar.WeekLayoutSwitcher />
								</>
							),
						}}
						className="flex-1"
						items={(tasks || [])
							.filter((item) => {
								return item.end_date;
							})
							.map((task: any) => ({
								id: `MONTH_${task.id}`,
								date: task.end_date,
								data: task,
								render: <Item {...{ task }} />,
							}))}
					>
						<Calendar.Month
							ref={calendar}
							week={{
								column: {
									footer: (date) => (
										<span
											onClick={() =>
												actions.createEditableTask(
													date.format("YYYY-MM-DD")
												)
											}
											className="flex items-center gap-1 text-sm opacity-0 group-hover:opacity-50 hover:opacity-100 transition-all cursor-pointer"
										>
											<i className="far fa-plus text-[10px]"></i>
											<span className="font-regular">
												{t("add")}
											</span>
										</span>
									),
								},
							}}
						/>
					</Calendar>
				</div>
			</div>
		</Draggable>
	);
};

export default withTranslation(TasksCalendar);
