const talent = {
	"index-view": {
		filters: {
			all: "All Talents",
			accepted: "Accepted Talents",
			denied: "Denied Talents",
		},
		title: "Talents",
		search: "Search Talent",
	},
	"detail-view": {
		title: "Talent",
	},
};

export default talent;
