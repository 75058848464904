import { Navigate } from "react-router-dom";
import Card from "src/components/Card";
import withTranslation, {
	Translation,
} from "src/components/hoc/withTranslation";
import Container from "src/components/layout/Container";
import Logo from "src/components/Logo";
import useAuth from "src/hooks/selectors/useAuth";

function NotActivatedView({ t }: Translation) {
	const auth = useAuth();

	if (auth?.activated_at) {
		return <Navigate to="/dashboard" />;
	}

	return (
		<div className="flex flex-col flex-1 justify-center overflow-hidden">
			<Container variant="xsmall">
				<Card className="text-background-foreground z-20 relative">
					<h2>{t("title")}</h2>
					<p>
						{t("description", {
							name: ` ${auth?.company?.name}`,
						})}
					</p>
				</Card>
			</Container>
			<div className="absolute right-4 bottom-4 z-10">
				<Logo color="primary" className="w-[300px] block" />
			</div>
		</div>
	);
}

NotActivatedView.locale = {
	nl: {
		title: "Je mist een actieve licentie",
		description:
			"Raadplaag de eigenaar van het{{name}} account om de gebruikers licentie te activeren",
	},
	en: {
		title: "You are missing an active license",
		description:
			"Consult the owner of the{{name}} account to activate the user license",
	},
};

export default withTranslation(NotActivatedView);
