import useApi from "src/hooks/api/utils/useApi";
import useAuth from "src/hooks/selectors/useAuth";

type Options = {
	userId: string | number;
};

export default function useUserStats(options: Options) {
	const auth = useAuth();
	const userId =
		options?.userId.toString() === auth.id.toString()
			? "mine"
			: options?.userId;
	const { state, actions, api, pagination } = useApi(
		{ id: `USER_STATISTICS:${userId}` },
		{
			baseUrl: `/client/statistics/users/${userId}`,
			query: {},
		}
	);

	const getSkillProgress = async () => {
		const { data } = await api
			.get("/skills/progress")
			.then(({ data }) => data);
		actions.set((state: any) => ({
			...state,
			custom: {
				...(state?.custom || {}),
				progress: Object.keys(data).map((date) => ({
					date,
					total: data[date],
				})),
			},
		}));
	};
	const getSkillDistribution = async () => {
		const { data } = await api
			.get("/skills/distribution")
			.then(({ data }) => data);

		actions.set((state: any) => ({
			...state,
			custom: {
				...(state?.custom || {}),
				distribution: data,
			},
		}));
	};

	return {
		userId: options?.userId === auth.id ? "mine" : options?.userId,
		progress: state.custom?.progress || [],
		distribution: state.custom?.distribution || [],
		data: state?.data || {},
		status: state.status,
		pagination,
		filter: state?.filter || {},
		actions: {
			...actions,
			getSkillProgress,
			getSkillDistribution,
		},
		api,
	};
}
