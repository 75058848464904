import Bar from "src/components/statistics/bar-chart/Bar";
import { useBarChart } from "src/components/statistics/bar-chart/Provider";
import {
	Popover,
	PopoverContent,
	PopoverTrigger,
} from "src/components/ui/popover";
import { cn } from "src/lib/utils";

const Bars = () => {
	const { ids } = useBarChart();

	return (
		<div className="flex gap-4 w-full relative z-2 overflow-scroll hide-default-scrollbar snap-x">
			{ids.map(({ id, label, description }) => (
				<Item key={`bar-${id}`} {...{ id, label, description }} />
			))}
		</div>
	);
};

interface ItemProps {
	id: string;
	label: string;
	description?: string;
}

const Item = ({ id, label, description }: ItemProps) => {
	const { data, theme, active, popup } = useBarChart();
	const activeRows = data.map((item) => {
		return {
			...item,
			data: item.data.filter((row) => row.id === id),
		};
	});
	return (
		<div
			onMouseEnter={() => {
				active.set(id);
			}}
			onMouseLeave={() => active.set(undefined)}
			className="flex flex-col min-w-[44px] max-w-[66px] flex-1 cursor-pointer"
		>
			<Popover
				onOpenChange={(value) => {
					active.set(value ? id : undefined);
				}}
				open={active.value === id}
			>
				<PopoverTrigger asChild>
					<div
						className={cn(
							"flex flex-1 w-full snap-start flex-col relative transition-all",
							active.value && active.value === id && "",
							active.value && active.value !== id && "opacity-50"
						)}
					>
						{data.map((item, index) => {
							return item.data
								.filter((row) => row.id === id)
								.map((barItem) => (
									<Bar
										key={`bar-${barItem.id}`}
										theme={item?.theme}
										data={barItem}
										{...{ index }}
									/>
								));
						})}
					</div>
				</PopoverTrigger>
				<PopoverContent side="right" align="start">
					{active?.value && (
						<div className="flex flex-col gap-2">
							<strong>{popup?.title || label}</strong>
							<div className="flex flex-col gap-1.5">
								{popup?.render ? (
									popup.render(active.value, activeRows)
								) : (
									<>
										{activeRows.map((item) => (
											<div
												key={item.label}
												className="flex items-center gap-2"
											>
												<div
													className={cn(
														"bg-accent",
														item.theme?.bar
															?.className,
														"w-6 h-3 rounded-sm"
													)}
												></div>
												<div className="flex-1">
													{item.label}
												</div>
												<strong>
													{item.data.reduce(
														(current, item) =>
															current +
															item.count,
														0
													)}
												</strong>
											</div>
										))}
									</>
								)}
							</div>
							{description && (
								<p className="italic opacity-80">
									{description}
								</p>
							)}
						</div>
					)}
				</PopoverContent>
			</Popover>
			<div
				className={cn(
					theme.footer.className,
					"justify-center items-end"
				)}
			>
				<span className="whitespace-nowrap">{label}</span>
			</div>
		</div>
	);
};

export default Bars;
