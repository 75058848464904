import React, { HTMLAttributes } from "react";
import BudgetForm from "src/components/tasks/TaskForm/steps/budget/BudgetForm";
import Descriptionform from "src/components/tasks/TaskForm/steps/DescriptionForm";
import AddressForm from "src/components/tasks/TaskForm/steps/AddressForm";
import TargetForm from "src/components/tasks/TaskForm/steps/TargetForm";
import StudentPoolForm from "src/components/tasks/TaskForm/steps/StudentPoolForm";
import SummaryForm from "src/components/tasks/TaskForm/steps/SummaryForm";
import InterviewForm from "src/components/tasks/TaskForm/steps/InterviewForm";
import DeleteForm from "src/components/tasks/TaskForm/steps/DeleteForm";
import SubtasksForm from "src/components/tasks/TaskForm/steps/SubtasksForm";
import AIQuestionsForm from "src/components/tasks/TaskForm/steps/AIQuestionsForm";
import ExtraForm from "src/components/tasks/TaskForm/steps/ExtraForm";
import VisibilityForm from "src/components/tasks/TaskForm/steps/VisibilityForm";
import SummaryAIForm from "src/components/tasks/TaskForm/steps/SummaryAIForm";

interface TaskFormProps extends HTMLAttributes<HTMLFormElement> {}

export interface TaskFormOptions {
	hideButtons?: boolean;
}

const TaskForm = ({ children, ...rest }: TaskFormProps) => {
	return <form {...rest}>{children}</form>;
};

TaskForm.Budget = BudgetForm;
TaskForm.Description = Descriptionform;
TaskForm.Address = AddressForm;
TaskForm.Target = TargetForm;
TaskForm.StudentPool = StudentPoolForm;
TaskForm.Summary = SummaryForm;
TaskForm.Interview = InterviewForm;
TaskForm.Delete = DeleteForm;
TaskForm.Subtasks = SubtasksForm;
TaskForm.AIQuestions = AIQuestionsForm;
TaskForm.Extra = ExtraForm;
TaskForm.Visibility = VisibilityForm;
TaskForm.SummaryAI = SummaryAIForm;

export default TaskForm;
