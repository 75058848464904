import axios from "axios";
import { useCallback, useEffect, useState } from "react";
import { formatUrl } from "src/lib/formatters";

type ApiGetOptions = { filters?: any };

const useGet = (
	_route?: string,
	defaultValue?: any,
	usePagination?: boolean,
	options?: ApiGetOptions
) => {
	const [loading, setLoading] = useState(true);
	const [fetching, setFetching] = useState(false);
	const [result, setResult] = useState(defaultValue || {});
	const [error, setError] = useState(null as any);
	const [search, setSearch] = useState("");
	const limit = 16;
	let source: any;
	const route = formatUrl(_route || "", {
		...(usePagination ? { limit } : {}),
		...(options?.filters || {}),
	});

	const fetchNext = async () => {
		if (!route || fetching) return;
		setFetching(true);
		const { meta } = result;
		const params = {
			page: meta.current_page + 1,
		} as any;

		if (search) {
			params.q = search;
		}
		const res = await axios.get(route, {
			params,
		});
		setResult((old: any) => ({
			...res.data,
			data: [...old.data, ...res.data.data],
			hasMore: res.data.meta.current_page !== res.data.meta.last_page,
		}));
		setFetching(false);
	};

	const fetchPage = async (page: number) => {
		if (!route || fetching) return;
		setFetching(true);

		const params = {
			page,
		} as any;

		if (search) {
			params.q = search;
		}
		const res = await axios.get(route, {
			params,
		});
		setResult((old: any) => ({
			...res.data,
			hasMore: res.data.meta.current_page !== res.data.meta.last_page,
		}));
		setFetching(false);
	};

	const fetchGet = useCallback(
		async (defaultLoading = true) => {
			if (!route) return;
			// Check if there are any previous pending requests
			// eslint-disable-next-line react-hooks/exhaustive-deps
			source = axios.CancelToken.source();
			if (route) {
				setLoading(defaultLoading);
				try {
					let params = {
						...(usePagination
							? {
									page: 1,
							  }
							: {}),
					} as any;

					if (params.q) {
						params.q = search;
					}

					const { data } = await axios.get(route, {
						cancelToken: source.token,
						params,
					});
					if (data) {
						const { meta } = data;
						if (usePagination && data.links) {
							data.hasMore = meta.current_page !== meta.last_page;
						}
						setResult(data);
					}
					setLoading(false);
				} catch (error: any) {
					setError(error?.response?.data);
					setLoading(false);
				}
			} else {
				setLoading(false);
			}
		},
		[route, search, usePagination]
	);

	useEffect(() => {
		if (route) {
			fetchGet();
		}
		return () => {
			if (typeof source != typeof undefined) {
				source.cancel("CANCEL_NEW_REQUEST");
			}
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [route]);

	const handleSearch = async (q: string) => {
		if (!route || fetching) return;
		setFetching(true);
		setSearch(q);

		let params = {
			q,
		} as any;

		const { data } = await axios.get(route, {
			params,
		});
		const { meta } = data;

		if (usePagination && data.links) {
			data.hasMore = meta.current_page !== meta.last_page;
		}

		setResult(data);
		setFetching(false);
	};

	const pagination = {
		setPage: fetchPage,
		page: (result?.meta || {})?.current_page,
		last_page: (result?.meta || {})?.last_page,
	};

	return [
		result,
		loading,
		{
			error,
			fetchGet,
			setResult,
			fetchNext,
			fetching,
			handleSearch,
			pagination,
		},
	];
};

export default useGet;
