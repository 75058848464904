import React from "react";
import { getCurrentDomainName } from "src/lib/domain";
import { PostHogProvider as PostHogProviderComp } from "posthog-js/react";

interface PosthogProviderProps {
	children?: any;
}

const PosthogProvider = ({ children }: PosthogProviderProps) => {
	const domain = getCurrentDomainName();

	if (
		domain === "getjobsdone" &&
		process.env.REACT_APP_GJD_PUBLIC_POSTHOG_KEY
	) {
		return (
			<PostHogProviderComp
				apiKey={process.env.REACT_APP_GJD_PUBLIC_POSTHOG_KEY}
				options={{
					api_host: process.env.REACT_APP_GJD_PUBLIC_POSTHOG_HOST,
				}}
			>
				{children}
			</PostHogProviderComp>
		);
	}

	return children;
};

export default PosthogProvider;
