import React from "react";
import { useTranslation } from "react-i18next";
import { ApiOffer } from "src/api/types";
import FlatList from "src/components/FlatList";
import Grid from "src/components/Grid";
import OfferCard from "src/components/offer/cards/OfferCard";
import useGet from "src/hooks/rest/useGet";
import useAuth from "src/hooks/selectors/useAuth";

interface OfferListProps {
	query?: string;
}

const OfferList = ({ query }: OfferListProps) => {
	const { t } = useTranslation("offer", {
		keyPrefix: "offer-list",
	});
	const auth = useAuth();
	const [{ data, hasMore }, loading, { fetchNext, handleSearch }] = useGet(
		`/${auth.type}/offers?${query || ""}`,
		{ data: [] },
		true
	);

	if (loading) {
		return (
			<Grid gap={16}>
				<OfferCard.Loading />
				<OfferCard.Loading />
				<OfferCard.Loading />
				<OfferCard.Loading />
				<OfferCard.Loading />
			</Grid>
		);
	}

	return (
		<FlatList
			gap={16}
			empty={{
				title: t("empty-student.title"),
				text: t("empty-student.description"),
				to: "/offers/create",
			}}
			onNext={async () => await fetchNext()}
			{...{ data, hasMore, handleSearch }}
			renderItem={(offer: ApiOffer) => (
				<OfferCard {...{ offer }} key={`offer-${offer.id}`} />
			)}
		/>
	);
};

export default OfferList;
