import * as RadioGroupPrimitive from "@radix-ui/react-radio-group";
import * as React from "react";

import { cn } from "src/lib/utils";

const RadioGroup = React.forwardRef<
	React.ElementRef<typeof RadioGroupPrimitive.Root>,
	React.ComponentPropsWithoutRef<typeof RadioGroupPrimitive.Root>
>(({ className, ...props }, ref) => {
	return (
		<RadioGroupPrimitive.Root
			className={cn("flex flex-col gap-2", className)}
			{...props}
			ref={ref}
		/>
	);
});
RadioGroup.displayName = RadioGroupPrimitive.Root.displayName;

const RadioGroupItem = React.forwardRef<
	React.ElementRef<typeof RadioGroupPrimitive.Item>,
	React.ComponentPropsWithoutRef<typeof RadioGroupPrimitive.Item>
>(({ className, ...props }, ref) => {
	return (
		<RadioGroupPrimitive.Item
			ref={ref}
			className={cn(
				"aspect-square h-4 w-4 rounded-full border border-foreground opacity-40 focus:opacity-100 focus-visible:opacity-100 disabled:cursor-not-allowed disabled:opacity-50 data-[state=checked]:opacity-100 transition-all duration-400",
				className
			)}
			{...props}
		>
			<RadioGroupPrimitive.Indicator className="flex items-center justify-center">
				<div className="w-[10px] h-[10px] rounded-full bg-foreground transition-all duration-400" />
			</RadioGroupPrimitive.Indicator>
		</RadioGroupPrimitive.Item>
	);
});
RadioGroupItem.displayName = RadioGroupPrimitive.Item.displayName;

const RadioGroupWrapper = ({
	children,
	className,
	...rest
}: React.ComponentProps<"div">) => {
	return (
		<div
			className={cn(
				"flex items-center space-x-2 group cursor-pointer",
				className
			)}
			{...rest}
		>
			{children}
		</div>
	);
};

export { RadioGroup, RadioGroupItem, RadioGroupWrapper };
