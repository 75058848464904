const shareNetwork = {
	"index-view": {
		title: "Invite Your Network",
		tutorial: {
			title: "How the Referral Program Works",
		},
		heading: {
			title: "Refer Companies and Earn up to €275 per Company",
			description:
				"When a company signs up, you receive €25. If the company subscribes to a yearly plan afterward, you earn an additional €250.",
		},
	},
};
export default shareNetwork;
