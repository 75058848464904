interface SubscriptionCheckoutHeadProps {
	title: string;
	subtitle?: string;
}

const SubscriptionCheckoutHead = ({ title }: SubscriptionCheckoutHeadProps) => {
	return (
		<div className="flex flex-col justify-center items-center gap-2">
			<h1 className="text-3xl md:text-4xl">{title}</h1>
		</div>
	);
};

export default SubscriptionCheckoutHead;
