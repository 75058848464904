import { Trans, useTranslation } from "react-i18next";
import MiddlewareLayout from "src/components/middlewares/MiddlewareLayout";

interface MultipleCompaniesMiddlewareProps {
	loading?: boolean;
	tabs?: any;
}

const MultipleCompaniesMiddleware = ({
	loading,
	tabs,
}: MultipleCompaniesMiddlewareProps) => {
	const { t } = useTranslation("middlewares", {
		keyPrefix: "multiple-companies",
	});
	return (
		<MiddlewareLayout
			title={t("title")}
			{...{ loading, tabs }}
			heading={t("heading")}
		>
			<Trans {...{ t }} i18nKey="description">
				Om gebruik te maken van meerdere vestigingen dien je een
				<strong>Premium</strong> abonnement te hebben.
			</Trans>
		</MiddlewareLayout>
	);
};

export default MultipleCompaniesMiddleware;
