import axios from "axios";
import { uniqueId } from "lodash";
import { useRef, useState } from "react";
import Turnstile, { useTurnstile } from "react-turnstile";
import Button from "src/components/Button";
import withTranslation, {
	Translation,
} from "src/components/hoc/withTranslation";
import Loader from "src/components/Loader";
import Arrow from "src/components/tools/job-offer-converter-small/Arrow";
import DEFAULT_JOB_OFFERS from "src/components/tools/job-offer-converter-small/data";
import JobOfferDefaultTabs from "src/components/tools/job-offer-converter-small/JobOfferDefaultTabs";
import JobOfferEditor from "src/components/tools/job-offer-converter-small/JobOfferEditor";
import JobOfferTaskItem, {
	JobOfferTask,
} from "src/components/tools/job-offer-converter-small/JobOfferTaskItem";
import useAuthSession from "src/hooks/api/services/session/useAuthSession";
import useAuth from "src/hooks/selectors/useAuth";
import { cn } from "src/lib/utils";
import untruncateJson from "untruncate-json";

const JobOfferConverterSmall = ({ t }: Translation) => {
	const [editorKey, setEditorKey] = useState(uniqueId());

	const [value, setValue] = useState<string>(
		DEFAULT_JOB_OFFERS["content-marketeer"].value
	);
	const disabled = !value;
	const auth = useAuth();
	const turnstile = useTurnstile();
	const scrollRef = useRef<HTMLDivElement>(null);
	const [turnstileResponse, setTurnstileResponse] = useState<string>();
	const [error, setError] = useState<"NO_TASKS_CREATED">();
	const [jobOfferId, setJobOfferId] = useState<string>();
	const [tasks, setTasks] = useState([]);
	const [loading, setLoading] = useState(false);
	const [submitted, setSubmitted] = useState(false);
	const [isCompleted, setIsCompleted] = useState(false);
	const {
		session,
		actions: sessionActions,
		status: sessionStatus,
	} = useAuthSession();

	const refreshEditorValue = () => {
		setEditorKey(uniqueId());
	};

	const scrollToRef = () => {
		if (!scrollRef.current) return;
		scrollRef.current.scrollIntoView({
			behavior: "smooth",
			block: "center",
		});
	};

	const scrollToSubmitButton = () => {
		const element = document.querySelector("#submit-button");
		if (!element) {
			return;
		}
		element.scrollIntoView({
			block: "center",
			behavior: "smooth",
		});
	};

	const handleSubmit = async () => {
		if (disabled || loading) return;

		//Get the turnstile response
		if (!turnstileResponse && process.env.NODE_ENV === "production") return;
		setError(undefined);
		setLoading(true);
		setSubmitted(true);
		setTasks([]);

		axios
			.post(
				`/tools/job-offer-to-tasks`,
				{
					turnstile:
						process.env.NODE_ENV === "production"
							? turnstileResponse
							: "123",
					description: value,
				},
				{
					responseType: "stream",
					onDownloadProgress: function (progressEvent) {
						const response = progressEvent.currentTarget.response;
						const data = JSON.parse(untruncateJson(response));
						// if (
						// 	(data?.tasks || []).length > tasks.length &&
						// 	data?.tasks?.length > 0
						// ) {
						// 	scrollToRef();
						// }
						setTasks(data?.tasks);
						setJobOfferId(data?.job_offer_id);
					},
				}
			)
			.then((result) => {
				const { data } = result;
				if (!data) {
					return;
				}
				if (!data?.tasks || !data?.tasks?.length) {
					setError("NO_TASKS_CREATED");
				}
				setJobOfferId(data?.job_offer_id);
				setTasks(data?.tasks);
				// scrollToRef();

				return data?.data;
			})
			.finally(() => {
				if (turnstile) {
					turnstile.reset();
				}
				setTimeout(() => {
					scrollToRef();
				}, 500);

				setLoading(false);
			});
	};

	const handleCreateDirect = async () => {
		if (!auth.id || auth.type === "student") return;

		//Format the tasks
		try {
			await axios.post("/client/tasks/multiple", {
				tasks: tasks.map((task: JobOfferTask) => ({
					...task,
					budget: task.estimated_duration * task.hour_rate,
					id: undefined,
					type: "PUBLISHED",
				})),
			});
			setIsCompleted(true);
			scrollToRef();
		} catch (error) {}
	};

	const onCreateAuthSession = async () => {
		const referral_type = "JOB_OFFER_CONVERTER";
		const value = {
			type: "COMPANY" as const,
			referral_type,
			job_offer_id: jobOfferId,
			task: tasks.map((task: JobOfferTask) => ({
				...task,
				budget: task.estimated_duration * task.hour_rate,
			})),
		};
		if (!session?.id) {
			const newSession = await sessionActions.create(value);
			if (!newSession?.id) {
				return null;
			}
			return sessionActions.redirect(newSession.id);
		}
		await sessionActions.update(session?.id, value);
		return sessionActions.redirect(session.id);
	};

	return (
		<div
			id="input"
			className={cn("flex flex-col gap-4 z-20 px-2 relative")}
		>
			<div className="flex flex-col md:flex-row gap-4 relative md:justify-between">
				<JobOfferDefaultTabs
					{...{ value }}
					onChange={(value) => {
						setValue(value);
						refreshEditorValue();
					}}
				>
					<Button
						type="red"
						xsmall
						onClick={() => {
							setValue("");
							refreshEditorValue();
						}}
					>
						<i className="far fa-trash"></i>
						{t("actions.empty")}
					</Button>
				</JobOfferDefaultTabs>
				<div className="flex items-center md:items-end gap-4 justify-between">
					<div className="flex gap-3 items-center flex-1">
						<p className="text-right leading-[125%]">
							{t("paste")}
						</p>
						<Arrow />
					</div>
				</div>
			</div>
			<div className="flex flex-col relative">
				<div className="absolute top-4 right-4 z-[99] flex justify-center items-center">
					<Button xsmall type="border" onClick={scrollToSubmitButton}>
						<i className="fas fa-arrow-down"></i>
					</Button>
				</div>
				<JobOfferEditor
					key={editorKey}
					{...{ value }}
					onChange={setValue}
					className="p-4 border border-purple-light"
				/>
			</div>
			{(tasks || [])?.length === 0 && !loading && (
				<div id="submit-button" className="">
					<Button
						className="w-full gap-2"
						type="primary"
						disabled={!value}
						onClick={handleSubmit}
						{...{ loading }}
					>
						<i className="fas fa-sparkles"></i>
						{t("actions.convert")}
					</Button>
				</div>
			)}
			{process.env.REACT_APP_CLOUDFLARE_SITE_KEY && (
				<Turnstile
					sitekey={process.env.REACT_APP_CLOUDFLARE_SITE_KEY}
					onVerify={setTurnstileResponse}
				/>
			)}
			{submitted && (
				<div className="flex flex-col z-10 relative mt-6">
					<div
						className={cn(
							"flex flex-col justify-center items-center pointer-events-none absolute inset-0 opacity-0",
							loading && "opacity-30"
						)}
					>
						<div className="max-w-[200px]">
							<Loader variant="ai" />
						</div>
					</div>

					<div className="flex flex-col">
						{error && (
							<div className="flex flex-col gap-4 p-8">
								<h3>{t(`errors.${error}.title`)}</h3>
								<p>{t(`errors.${error}.description`)}</p>
							</div>
						)}

						<div className="flex flex-col gap-6">
							<div
								className={cn(
									"flex flex-col gap-2 overflow-hidden"
								)}
							>
								<h3>{t("output.title")}</h3>
								<p className="text-lg opacity-70 md:text-wrap-balance">
									{t("output.description")}
								</p>
								<Button
									onClick={() => handleSubmit()}
									disabled={loading}
									xsmall
									type="border"
									className="mt-2"
								>
									<i className="fas fa-sync"></i>
									{t("actions.retry")}
								</Button>
							</div>
							<div className="flex flex-col gap-5">
								{(tasks || []).map((task, index) => (
									<JobOfferTaskItem
										key={`task-${index}`}
										{...{ task }}
										onDelete={() =>
											setTasks(
												tasks.filter(
													(item, i) => i !== index
												)
											)
										}
									/>
								))}
							</div>
						</div>
					</div>

					{isCompleted && (
						<div className="flex flex-col p-6 gap-4 mt-6 bg-primary text-primary-foreground rounded-md">
							<div className="flex flex-col gap-1">
								<p>{t("completed-direct.title")}</p>
								<p className="opacity-80">
									{t("completed-direct.description")}
								</p>
							</div>
							<Button to={`/tasks`} className="w-max">
								{t("completed-direct.button")}
							</Button>
						</div>
					)}

					{!loading && tasks?.length > 0 && !auth?.id && (
						<div className="flex flex-col gap-3 justify-center items-center mt-6">
							<Button
								className="w-full"
								loading={sessionStatus !== "idle"}
								onClick={() => {
									onCreateAuthSession();
								}}
							>
								{t("actions.signup")}
							</Button>
							<div className="flex gap-2 pl-12">
								<img
									className=""
									src="/arrow.svg"
									alt={t("actions.disclaimer")}
								/>
								<span>{t("actions.disclaimer")}</span>
							</div>
						</div>
					)}

					{auth.id &&
						auth.type === "company" &&
						!loading &&
						tasks.length > 0 && (
							<div className="flex flex-col mt-6">
								<Button onClick={handleCreateDirect}>
									{t("actions.create-direct")}
								</Button>
							</div>
						)}
				</div>
			)}
			<div ref={scrollRef}></div>
		</div>
	);
};

JobOfferConverterSmall.locale = {
	nl: {
		paste: "Plak je vacature hier beneden",
		actions: {
			empty: "Leeg vacature",
			random: "Willekeurig",
			signup: "Opdrachten plaatsen",
			disclaimer: "Geen betaling verplicht",
			retry: "Opnieuw proberen",
			"scroll-to-output": "Scroll naar AI",
			"create-direct": "Opdrachten plaatsen",
			convert: "Omzetten naar opdrachten",
		},
		"completed-direct": {
			title: "De opdrachten zijn geplaatst",
			description:
				"De opdrachten zijn geplaatst op het platform. Je kan de opdrachten nog inzien / aanpassen in je dashboard.",
			button: "Opdrachten bekijken",
		},
		generate: {
			title: "Als je je vacature hebt ingevoerd, klik dan op de knop om opdrachten te genereren",
			button: "Genereer opdrachten",
		},
		loggedin: "Ingelogd als {{name}}",
		title: "Vacature tekst",
		description:
			"Laten we een beter oplossing zoeken voor je openstaande vacature",

		output: {
			title: "Opdrachten",
			description:
				"Op basis van eerder uitbesteden opdrachten op ons platform denken we dat je je vacature met deze opdrachten kan opvullen.",
		},
		errors: {
			NO_TASKS_CREATED: {
				title: "Geen opdrachten gevonden",
				description:
					"Er zijn geen opdrachten gevonden in de gegeven vacature tekst. Probeer het opnieuw met een andere vacature tekst.",
			},
		},
	},
	en: {
		paste: "Paste your job listing below",
		actions: {
			empty: "Clear",
			random: "Random",
			signup: "Post Assignments",
			disclaimer: "No payment required",
			retry: "Try Again",
			"scroll-to-output": "Scroll to AI",
			"create-direct": "Post Assignments",
			convert: "Convert to Assignments",
		},
		"completed-direct": {
			title: "The assignments have been posted",
			description:
				"The assignments have been posted on the platform. You can review/edit the assignments in your dashboard.",
			button: "View Assignments",
		},
		generate: {
			title: "Once you’ve entered your job listing, click the button to generate assignments",
			button: "Generate Assignments",
		},
		loggedin: "Logged in as {{name}}",
		title: "Job Listing Text",
		description: "Let's find a better solution for your open job listing",
		output: {
			title: "Assignments",
			description:
				"Based on previous outsourcing on our platform, we suggest posting these assignments to replace the open job listing.",
		},
		errors: {
			NO_TASKS_CREATED: {
				title: "No assignments found",
				description:
					"No assignments were found in the provided job listing text. Try again with a different job listing text.",
			},
		},
	},
};

export default withTranslation(JobOfferConverterSmall);
