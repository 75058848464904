const providers = {
	"tenant-provider": {
		error: {
			title: "Something went wrong",
			description: "This page is currently unavailable",
		},
	},
};

export default providers;
