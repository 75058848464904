import axios from "axios";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { updateProfile } from "src/redux/auth";

export default function useFetchProfile() {
	const dispatch = useDispatch();
	const [loading, setLoading] = useState(false);

	const fetchProfile = () => {
		dispatch(updateProfile({ isLoading: true }));
		setLoading(true);
		return axios
			.get("/auth/profile")
			.then((result) => {
				dispatch(updateProfile(result.data.data));
			})
			.finally(() => setLoading(false))
			.catch(console.log);
	};

	const fetchUnreads = () => {
		dispatch(updateProfile({ isLoading: true }));
		setLoading(true);
		return axios
			.get("/auth/profile/unreads")
			.finally(() => setLoading(false))
			.catch(console.log);
	};

	return {
		fetchProfile,
		fetchUnreads,
		loading,
	};
}
