import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { AvatarsLoadingItems } from "src/components/avatars";
import Button from "src/components/Button";
import EmptyState from "src/components/EmptyState";
import withTranslation, {
	Translation,
} from "src/components/hoc/withTranslation";
import Loader from "src/components/Loader";
import Registration, {
	RegistrationAvatar,
	RegistrationName,
} from "src/components/registrations/registration/registration";
import {
	ConnectRegistrationUser,
	FavoriteRegistration,
} from "src/components/registrations/registration/registration-actions";

import RegistrationsCarousel, {
	RegistrationsCarouselCard,
	RegistrationsCarouselItem,
	RegistrationsCarouselItems,
	RegistrationsCarouselNavigateButton,
} from "src/components/registrations/registrations-carousel/registrations-carousel";
import Resume from "src/components/resume/Resume";
import RegistrationDetails from "src/components/tasks/panes/registration/RegistrationDetails";
import RegistrationSection from "src/components/tasks/panes/registration/RegistrationSection";
import RegistrationSkillsMatch from "src/components/tasks/panes/registration/RegistrationSkillsMatch";
import { VacancyRegistrationAvatarsCarouselNavigator } from "src/components/vacancies/vacancy-avatars";
import VacancyDetailLayout from "src/components/vacancies/vacancy-detail-layout";
import useRegistrationsByTask from "src/hooks/api/services/registrations/useRegistrationsByTask";

const ShowVacancyView = ({ t }: Translation) => {
	const params = useParams();
	const { registrations, actions, pagination, status } =
		useRegistrationsByTask(params.id?.toString() || "");
	const sectionClassName = "p-3 md:p-6 border border-border";

	useEffect(() => {
		actions.list();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<VacancyDetailLayout id={params?.id?.toString() || ""}>
			{(vacancy) => (
				<>
					<RegistrationsCarousel
						className="flex-1 mt-4"
						loading={status === "loading"}
						{...{ registrations, pagination }}
					>
						<div className="flex justify-between items-center">
							<RegistrationsCarouselNavigateButton direction="previous" />
							<div className="flex items-center gap-1">
								{status === "loading" &&
								registrations.length === 0 ? (
									<AvatarsLoadingItems />
								) : (
									<VacancyRegistrationAvatarsCarouselNavigator
										vacancy={vacancy}
									/>
								)}
							</div>
							<RegistrationsCarouselNavigateButton direction="next" />
						</div>
						<div className="flex flex-col -m-2 mt-4 flex-1">
							{registrations.length === 0 &&
								status === "idle" && (
									<div className="flex flex-col flex-1">
										<div className="flex flex-col flex-1 gap-1 justify-center items-center">
											<EmptyState.Title>
												{t("empty.title")}
											</EmptyState.Title>
											<EmptyState.Description>
												{t("empty.description")}
											</EmptyState.Description>
										</div>
									</div>
								)}
							<RegistrationsCarouselItems>
								{(registration, index) => (
									<RegistrationsCarouselItem
										key={`registration-${index}`}
									>
										<RegistrationsCarouselCard>
											{registration ? (
												<Registration
													{...{ registration }}
													className="gap-6"
												>
													<div className="flex flex-col md:flex-row gap-3 md:gap-6 md:items-end">
														<RegistrationAvatar />
														<div className="flex flex-col gap-4 flex-1">
															<RegistrationName />
															<div className="flex flex-wrap gap-2 md:gap-3">
																<FavoriteRegistration />
																<ConnectRegistrationUser />
																<Button
																	type="border"
																	xsmall
																	to={`/inbox/${vacancy.id}-${registration.hid}-task`}
																>
																	<i className="fas fa-comments"></i>
																	{t(
																		"actions.chat"
																	)}
																</Button>
															</div>
														</div>
													</div>
													<RegistrationSection
														title={t(
															"sections.skills-match"
														)}
														className={
															sectionClassName
														}
													>
														<RegistrationSkillsMatch
															hideBasicDetails
															{...{
																registration,
															}}
														/>
													</RegistrationSection>
													<RegistrationDetails
														variant="vertical"
														{...{
															registration,
														}}
														{...{
															sectionClassName,
														}}
													/>
													{registration.user
														?.has_resume && (
														<RegistrationSection
															title={t(
																"sections.profile"
															)}
															className={
																sectionClassName
															}
														>
															<Resume
																id={registration.user.id.toString()}
																key={`resume-${registration.user.id}`}
																exclude={[
																	"header",
																]}
																variant="registration"
															/>
														</RegistrationSection>
													)}
												</Registration>
											) : (
												<div className="flex flex-col flex-1 justify-center items-center">
													<Loader />
												</div>
											)}
										</RegistrationsCarouselCard>
									</RegistrationsCarouselItem>
								)}
							</RegistrationsCarouselItems>
						</div>
					</RegistrationsCarousel>
				</>
			)}
		</VacancyDetailLayout>
	);
};

ShowVacancyView.locale = {
	nl: {
		actions: {
			favorite: "Favoriet",
			chat: "Chatten",
		},
		sections: {
			"skills-match": "Skills match",
			profile: "Profiel",
		},
		empty: {
			title: "Geen aanmeldingen gevonden",
			description: "Er zijn nog geen aanmeldingen voor deze vacature.",
		},
	},
	en: {
		actions: {
			favorite: "Favorite",
			chat: "Chat",
		},
		sections: {
			"skills-match": "Skills match",
			profile: "Profile",
		},
		empty: {
			title: "No registrations found",
			description: "There are no registrations for this vacancy.",
		},
	},
};

export default withTranslation(ShowVacancyView);
