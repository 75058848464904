import { createAction, handleActions } from "redux-actions";

export const setFilter = createAction("SET_FILTER");
export const clearFilter = createAction("CLEAR_FILTER");

export const emptyFilter = {
	companies: [],
	filter: {
		company_id: null,
	},
	loading: true,
};

const filterReducer = handleActions(
	{
		SET_FILTER: (state: any, action: any) => {
			return { ...state, ...action.payload };
		},
		CLEAR_FILTER: () => {
			return {
				...emptyFilter,
			};
		},
	},
	{
		...emptyFilter,
	}
);
export default filterReducer;
