import Tab from "src/components/layout/Tab";
import Tabs from "src/components/layout/Tabs";

interface FilterTabsProps {
	active: string;
	tabs: {
		[key: string]: any;
	};
	onChange: (key: string) => void;
	variant: "dark" | "light";
	className?: string;
}

const FilterTabs = ({ active, tabs, onChange, className }: FilterTabsProps) => {
	return (
		<Tabs.Container {...{ className }}>
			{Object.keys(tabs).map((key) => (
				<Tab
					key={`tab-item-${key}`}
					active={active === key}
					onClick={() => onChange(key)}
				>
					{tabs[key]}
				</Tab>
			))}
		</Tabs.Container>
	);
};

export default FilterTabs;
