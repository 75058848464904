import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Button from "src/components/Button";
import SkillSelector from "src/components/form/skill-selector/SkillSelector";
import withTranslation, {
	Translation,
} from "src/components/hoc/withTranslation";
import useResume from "src/hooks/api/services/resume/useResume";
import useSkills from "src/hooks/api/services/resume/useSkills";
import useAuth from "src/hooks/selectors/useAuth";

interface SkillFormProps extends Translation {
	type?: string;
}

function SkillForm({ t }: SkillFormProps) {
	const auth = useAuth();
	const resume = useResume({
		rememberKey: auth.id,
	});
	const { skills, actions, status } = useSkills();
	const navigate = useNavigate();

	useEffect(() => {
		actions.list({
			page: 1,
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleSubmit = () => {
		resume.actions
			.update({
				skills,
			})
			.then(() => {
				navigate("/profile/resume/create/interests");
			});
	};

	return (
		<>
			<div className="flex flex-col flex-1">
				<SkillSelector
					onChange={(skills) =>
						actions.set((state: any) => ({
							...state,
							list: skills,
						}))
					}
					value={skills}
					theme={{
						default: {
							className: "bg-background px-2 py-1",
						},
						inactive: {
							className: "bg-background",
						},
						active: {
							className: "bg-primary",
						},
					}}
					className="flex flex-col flex-1"
				>
					<div className="flex flex-col flex-1 justify-end">
						<SkillSelector.Results />
					</div>
					<SkillSelector.Input />
				</SkillSelector>
			</div>
			<div className="flex justify-between">
				<Button
					type="gray"
					small
					disabled={status !== "idle"}
					to={"/profile/resume/create/work"}
				>
					{t("back")}
				</Button>
				<Button
					type="dark"
					small
					disabled={!skills.length}
					onClick={handleSubmit}
					loading={resume.status !== "idle"}
				>
					{t("continue")}
				</Button>
			</div>
		</>
	);
}

SkillForm.locale = {
	nl: {
		title: "Voeg je vaardigheden toe",
		description:
			"Dit kan varieren van programmeren, grafisch ontwerp tot kritisch nadenken en probleemoplossend vermogen.",
		add: "Toevoegen",
		input: {
			label: "Vaardigheden",
		},
		back: "Vorige",
		continue: "Doorgaan",
	},
	en: {
		title: "Add your work experience",
		description:
			"This can range from programming, graphic design to critical thinking and problem solving skills.",
		add: "Add",
		input: {
			label: "Vaardigheden",
		},
		back: "Back",
		continue: "Continue",
	},
};

export default withTranslation(SkillForm);
