import { useEffect } from "react";
import Button from "src/components/Button";
import Input from "src/components/form/Input";
import withTranslation, {
	Translation,
} from "src/components/hoc/withTranslation";
import Form from "src/components/tasks/TaskForm/Form";
import useTaskForm from "src/hooks/api/tasks/useTaskForm";
import useTaskScheme from "src/hooks/schemes/useTaskScheme";

interface TaskFormTitleProps extends Translation {
	variant?: "default";
}

const TaskFormTitle = ({ t }: TaskFormTitleProps) => {
	const { task, actions, status } = useTaskForm();
	const scheme = useTaskScheme();

	useEffect(() => {
		actions.clear();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<Form
			onSubmit={async () => {
				if (!task.title) {
					return;
				}
				actions.createByTitle(task.title);
			}}
			value={{
				title: task?.title,
			}}
			schema={() => ({
				title: scheme.title,
			})}
			nextButtonLabel="Doorgaan"
			customButtons={
				<>
					<Button
						loading={status !== "idle"}
						disabled={!task.title}
						submit
						className="flex-1 mt-6"
					>
						{t("buttons.continue")}
					</Button>
				</>
			}
		>
			{(getInputProps) => {
				return (
					<>
						<Input
							autoFocus
							label={t("fields.title.label")}
							placeholder={t("fields.title.placeholder")}
							name="title"
							{...getInputProps("title")}
						/>
					</>
				);
			}}
		</Form>
	);
};

TaskFormTitle.locale = {
	nl: {
		title: "Opdracht aanmaken",
		fields: {
			title: {
				label: "Opdracht",
				placeholder: "Bijvoorbeeld: Social media beheren",
			},
		},
		buttons: {
			continue: "Doorgaan",
		},
	},
	en: {
		title: "Create task",
		fields: {
			title: {
				label: "Task",
				placeholder: "Example: Social media management",
			},
		},
		buttons: {
			continue: "Continue",
		},
	},
};

export default withTranslation(TaskFormTitle);
