import { Route } from "react-router-dom";
import SearchView from "src/components/layout/SearchView";
import TaskModal from "src/components/tasks/TaskForm/modals/task-modal/TaskModal";
import ProtectedRoute from "src/routes/middlewares/ProtectedRoute";
import RepeatableTaskSettingsModal from "src/views/authenticated/tasks/create/RepeatableTaskSettingsModal";
import EditTimeTrackingModal from "src/views/authenticated/time-tracking/EditTimeTrackingModal";

const GlobalModalRoutes = (prefix: string) => {
	return [
		<Route
			element={
				<ProtectedRoute
					variant="modal"
					modal={{
						backUrl: prefix,
						expanded: false,
						size: "default",
					}}
				>
					<EditTimeTrackingModal />
				</ProtectedRoute>
			}
			path={`${prefix}/time-tracking/:timeTrackingId/edit`}
			key="/time-tracking/:timeTrackingId/edit"
		/>,
		<Route
			element={
				<ProtectedRoute
					variant="modal"
					modal={{
						backUrl: prefix,
						expanded: false,
						size: "default",
					}}
				>
					<RepeatableTaskSettingsModal />
				</ProtectedRoute>
			}
			path={`${prefix}/repeat-task/:taskId`}
			key="/repeat-task/:id"
		/>,
		<Route
			element={
				<ProtectedRoute>
					<SearchView defaultVisible backUrl={prefix} />
				</ProtectedRoute>
			}
			path={`${prefix}/search`}
			key="/search"
		/>,
		// <Route
		// 	element={
		// 		<ProtectedRoute
		// 			guard={["intern-task"]}
		// 			permission={["tasks.create"]}
		// 		>
		// 			<TaskModal defaultVisible backUrl={prefix} />
		// 		</ProtectedRoute>
		// 	}
		// 	path={`${prefix}/create-task`}
		// 	key="/create-task"
		// />,
		<Route
			element={
				<ProtectedRoute
					guard={["intern-task"]}
					// permission={["tasks.create"]}
				>
					<TaskModal defaultVisible backUrl={prefix} />
				</ProtectedRoute>
			}
			path={`${prefix}/modals/tasks/:id`}
			key="/modal-task/:id"
		/>,
	];
};

export default GlobalModalRoutes;
