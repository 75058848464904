import moment from "moment";
import { Trans, useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import useSubscription from "src/hooks/selectors/useSubscription";

const PausedBanner = () => {
	const { t } = useTranslation("promotion", {
		keyPrefix: "paused-banner",
	});
	const subscription = useSubscription();
	return (
		<div className="p-4 rounded-md bg-red z-20">
			<NavLink
				to="/profile/billing/change/resume"
				className="text-white mx-auto block text-center max-w-md"
			>
				<Trans {...{ t }} i18nKey="description">
					Je account is gepauzeerd, jouw account wordt op
					<strong>
						{{
							date: moment(subscription?.ends_at).format(
								"DD-MM-YYYY"
							),
						}}
					</strong>
					volledig gesloten. Tot die tijd kan je nog wel aanmeldingen
					bekijken en opdrachten afronden. Klik hier om je abonnement
					te hervatten.
				</Trans>
			</NavLink>
		</div>
	);
};

export default PausedBanner;
