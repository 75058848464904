import useApi from "src/hooks/api/utils/useApi";

function useSubscriptionCheckoutSession() {
	const { state, actions } = useApi(
		{ id: "SUBSCRIPTION_CHECKOUT_SESSION" },

		{
			baseUrl: `/auth/subscriptions/sessions`,
		}
	);

	// Find the session by ID
	const find = async (id: string) => {
		const session = await actions.get(id);
		return session;
	};

	return {
		session: state?.data,
		status: state.status,
		actions: {
			find,
		},
	};
}

export default useSubscriptionCheckoutSession;
