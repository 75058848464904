import axios, { AxiosRequestConfig } from "axios";
import { getCurrentDomainName } from "src/lib/domain";
import i18n from "src/lib/i18n";
import getTokenName from "./getTokenName";

const API_URL =
	(process.env.REACT_APP_API_URl as string) ||
	(process.env.REACT_APP_API_URL as string);
axios.defaults.baseURL = API_URL || "";

export const getCookie = (cname: string) => {
	let name = cname + "=";
	let decodedCookie = decodeURIComponent(document.cookie);
	let ca = decodedCookie.split(";");
	for (let i = 0; i < ca.length; i++) {
		let c = ca[i];
		while (c.charAt(0) === " ") {
			c = c.substring(1);
		}
		if (c.indexOf(name) === 0) {
			return c.substring(name.length, c.length);
		}
	}
	return "";
};

axios.interceptors.request.use(async (config) => {
	localStorage.removeItem(getTokenName());
	let token = getCookie(getTokenName()) || null;
	if (token) {
		config.headers.Authorization = `Bearer ${token}`;
	}
	config.headers["Device"] = "WEB";
	config.headers["Version"] = "V2";
	config.headers["Tenant"] = getCurrentDomainName();
	config.headers["Locale"] = getCookie("Locale") || i18n.language;

	// removing trailing slash from end of url (trailingshash gives cors error)
	if (config.url) {
		config.url = config.url.replace(/\/$/, "");
	}

	return config;
});

axios.interceptors.response.use(
	function (response) {
		return response;
	},
	function (error) {
		// if (401 === error?.response?.status) {
		// const host = window.location.host;
		// if (
		// 	host.includes("demo.") ||
		// 	process.env.NODE_ENV === "development"
		// ) {
		// 	return Promise.reject(error);
		// } else if (!window?.location?.pathname?.includes("/login")) {
		// window.location.href = "/login";
		// }
		// } else {
		return Promise.reject(error);
		// }
	}
);

export const createApiInstance = (data: AxiosRequestConfig) => {
	const api = axios.create(data);
	api.interceptors.request.use(async (config: any) => {
		localStorage.removeItem(getTokenName());
		let token = getCookie(getTokenName()) || null;
		if (token) {
			config.headers.Authorization = `Bearer ${token}`;
		}
		config.headers["Locale"] = "NL";
		config.headers["Device"] = "WEB";
		config.headers["Tenant"] = getCurrentDomainName();
		// removing trailing slash from end of url (trailingshash gives cors error)
		if (config.url) {
			config.url = config.url.replace(/\/$/, "");
		}
		return config;
	});
	api.interceptors.response.use(
		function (response) {
			return response;
		},
		function (error) {
			return Promise.reject(error);
		}
	);
	return api;
};

export default axios;
