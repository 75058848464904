import { motion } from "framer-motion";
import { useParams } from "react-router-dom";
import Card from "src/components/Card";
import withTranslation, {
	Translation,
} from "src/components/hoc/withTranslation";
import Layout from "src/components/layout/Layout";
import SubscriptionCheckoutHead from "src/components/subscription/SubscriptionCheckoutHead";
import SubscriptionStatus from "src/components/subscription/SubscriptionsStatus";
import useGet from "src/hooks/rest/useGet";
import NotFound from "src/views/auth/NotFoundView";

const SubscriptionThanksView = ({ t }: Translation) => {
	const params = useParams() as any;
	let [{ data: plan }, loading] = useGet(
		`/auth/subscriptions/plans?slug=${params.slug}`
	);

	if (loading) {
		return (
			<Layout hideNav>
				<div className="flex flex-col flex-1 justify-center items-center">
					<span className="base-loader"></span>
				</div>
			</Layout>
		);
	}

	if (!plan) {
		return <NotFound />;
	}

	return (
		<Layout hideNav>
			<SubscriptionCheckoutHead
				title={t("title")}
				subtitle={t("subtitle")}
			/>
			<div className="flex flex-col flex-1 justify-center items-center max-w-md mx-auto">
				<motion.div
					animate={{
						opacity: 1,
						x: 0,
						transition: {
							delay: 0.2,
							type: "timing",
						},
					}}
					initial={{
						opacity: 0,
						x: -200,
					}}
				>
					<Card>
						<SubscriptionStatus {...{ plan }} />
					</Card>
				</motion.div>
			</div>
		</Layout>
	);
};

SubscriptionThanksView.locale = {
	nl: {
		title: "Je abonnement is gekozen",
		subtitle: "Een ogenblik geduld, we maken je account klaar...",
	},
	en: {
		title: "Your subscription is chosen",
		subtitle: "One moment please, we're preparing your account...",
	},
};

export default withTranslation(SubscriptionThanksView);
