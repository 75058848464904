const referral = {
	"invite-link-modal": {
		title: "Your own link",
		description:
			"Invite your friends to sign up using your unique link, copy the link below.",
	},
	"invite-modal": {
		fields: {
			required: "Required",
			"incorrect-email": "Invalid email address",
			"inuse-email": "Email already exists in the list",
		},
		title: "Invite friends",
		description: "Enter your friends' email addresses.",
		form: {
			email: "Email",
		},
		send_one: "Send 1 invitation",
		send_other: "Send {{count}} invitations",
	},
	"index-view": {
		title: "Tell a friend",
		heading: "Hey money maker! Invite your friends",
		description:
			"With our tell-a-friend campaign, you can earn money from your friends. How? Invite as many friends as you can below. If your friend creates an account through you, we'll give you an extra €5 on your first completed task. So if you make sure 5 of your friends sign up, you'll get €25.",
		terms: {
			title: "General terms and conditions",
			"term-one":
				"You will receive your accumulated credit after completing your first task.",
			"term-two":
				"The duration of the campaign is indefinite. {{tenant.name}} is entitled to stop the campaign at any time without giving any reason.",
			"term-three":
				"{{tenant.name}} is entitled to change the terms and conditions of the campaign at any time.",
			"term-four":
				"Personal data of participants will not be provided to third parties.",
		},
		buttons: {
			link: "Invite via your link",
			email: "Invite via email",
		},
		list: {
			title: "Your invited friends:",
			empty: "You have not invited any friends yet",
			paid: "Paid",
			"payment-pending": "Not yet paid",
			signed: "Signed up",
			"signed-pending": "Not yet signed up",
		},
	},
};

export default referral;
