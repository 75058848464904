import { useTranslation } from "react-i18next";
import Button from "src/components/Button";
import OfferCreateProgress from "src/components/offer/layout/OfferCreateProgress";
import useCreateOffer from "src/hooks/api/useCreateOffer";
import useWindowSize from "src/hooks/useWindowSize";

interface OfferCreateLayoutProps {
	children?: any;
	header: {
		title: string;
		description: string;
	};
}

const OfferCreateLayout = ({ header, children }: OfferCreateLayoutProps) => {
	return (
		<div className="rounded-md overflow-hidden grid bg-background grid-cols-1 lg:grid-cols-2  md:h-auto md:min-h-[45%] shadow">
			<div className="bg-background-foreground text-background rounded-t-md md:rounded-tr-none md:rounded-l-md flex flex-col flex-1 p-6">
				<div className="flex flex-col flex-1 md:mt-20 gap-2">
					<h2 className="md:leading-10 text-md md:text-[32px]">
						{header.title}
					</h2>
					<p className="mb-0 opacity-70">{header.description}</p>
				</div>
				<OfferCreateProgress />
			</div>
			<div
				id="offer-create-layout"
				className="overflow-y-auto flex flex-col flex-1"
			>
				{children}
			</div>
		</div>
	);
};

interface OfferCreateLayoutButtonsProps {
	disabled?: boolean;
	loading?: boolean;
}

export const OfferCreateLayoutButtons = ({
	disabled,
	loading,
}: OfferCreateLayoutButtonsProps) => {
	const { t } = useTranslation("buttons");
	const { current, setCurrent } = useCreateOffer();
	const { isPhone } = useWindowSize();

	const getPreviousLabel = () => {
		if (isPhone) {
			return <i className="fas fa-arrow-left"></i>;
		}
		if (current === 0) return "Annuleren";

		return (
			<>
				<i className="fas fa-arrow-left mr-2"></i>
				{t("previous-step")}
			</>
		);
	};

	return (
		<div className="flex items-center bg-gradient-to-t p-6 from-background pt-12 relative bottom-0 justify-between">
			<Button
				disabled={current === 0}
				onClick={() => setCurrent(current - 1)}
				type="gray"
				iconOnly={isPhone}
			>
				{getPreviousLabel()}
			</Button>
			<OfferCreateProgress.Dots />
			<Button
				iconOnly={isPhone}
				submit
				{...{ loading, disabled }}
				type="primary"
			>
				{!isPhone && t("next-step")}
				<i className={`fas fa-arrow-right ${!isPhone && "ml-2"}`}></i>
			</Button>
		</div>
	);
};

export default OfferCreateLayout;
