import { ComponentProps, createContext, useContext, useState } from "react";
import { cn } from "src/lib/utils";
import Items from "src/components/slider/elements/Items";
import Steps from "src/components/slider/elements/Steps";
import Button from "src/components/slider/elements/Button";
import useWindowSize from "src/hooks/useWindowSize";

export const SliderContext = createContext<any>(null);

export function useSlider() {
	const { isPhone } = useWindowSize();
	const slider = useContext(SliderContext);
	if (!slider) {
		return new Error(
			"useSlider can only be used in SliderContext.Provider"
		);
	}

	if (!slider.options.slides) {
		slider.options.slides = 1;
	}

	if (isPhone) {
		slider.options.slides = 1;
	}
	let max = (slider?.items || []).length;

	if (slider.options.slides > 1) {
		max = max - slider.options.slides + 1;
	}

	return {
		...slider,
		max,
	};
}

interface SliderProps extends ComponentProps<"div"> {
	children: any;
	onEndReach?: () => void;
	options?: {
		slides?: number;
		gap?: number;
	};
}

const Slider = ({
	children,
	onEndReach,
	options = { slides: 1, gap: 0 },
	...rest
}: SliderProps) => {
	const [current, setCurrent] = useState(0);
	const [items, setItems] = useState([]);
	return (
		<SliderContext.Provider
			value={{
				current,
				setCurrent,
				options,
				onEndReach,
				items,
				setItems,
			}}
		>
			<div className={cn("flex flex-col", rest?.className)}>
				{children}
			</div>
		</SliderContext.Provider>
	);
};

Slider.Items = Items;
Slider.Steps = Steps;
Slider.Button = Button;

export default Slider;
