import { useEffect } from "react";
import CountUp from "react-countup";
import Button from "src/components/Button";
import Card from "src/components/Card";
import withTranslation, {
	Translation,
} from "src/components/hoc/withTranslation";
import Icon from "src/components/icon/Icon";
import Layout from "src/components/layout/Layout";
import ReferralInviteForm from "src/components/referrals/ReferralInviteForm";
import ReferralShare from "src/components/referrals/ReferralShare";
import ReferralTerms from "src/components/referrals/ReferralTerms";
import ReferralsList from "src/components/referrals/ReferralsList";
import useTenant from "src/hooks/api/services/tenants/useTenant";
import useReferrals from "src/hooks/api/services/useReferrals";
import { cn } from "src/lib/utils";

function ReferralsView({ t }: Translation) {
	const {
		tenant: { modules },
	} = useTenant();
	const { referrals, statistics, pagination, status, actions } =
		useReferrals();
	const isExtern =
		modules.includes("extern-task") && !modules.includes("intern-task");

	useEffect(() => {
		actions.list();
		actions.statistics.get();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<Layout title={t("title")} container="lg">
			{/* <Container className="gap-8" variant="lg"> */}
			{isExtern && (
				<div className="flex flex-col justify-center items-center text-center gap-4 max-w-[700px] mx-auto">
					<h1>{t("heading")}</h1>
					<p className="text-lg">{t("description.text")}</p>
					<div className="flex flex-col md:flex-row items-center gap-4 mb-4">
						<Card contentClassName="p-2 flex-row items-center gap-2">
							<Icon
								className="bg-dark text-white hover:bg-dark hover:text-primary"
								icon={Icon.getFontAwesome(
									"fas fa-check-circle"
								)}
							/>
							{t("description.signup")}
						</Card>
						<Card contentClassName="p-2 flex-row items-center gap-2">
							<Icon
								className="bg-dark text-white hover:bg-dark hover:text-primary"
								icon={Icon.getFontAwesome(
									"fas fa-check-circle"
								)}
							/>
							{t("description.subscribed")}
						</Card>
					</div>

					<Button
						to={{
							modal: "/referrals/tutorial",
						}}
						type="dark"
					>
						{t("how-it-works")}
					</Button>
				</div>
			)}
			{isExtern && (
				<div className="flex flex-col sm:flex-row gap-8 mt-4">
					<Card className="flex-1" contentClassName="gap-2">
						<Card.Head title={t("saved.title")} />
						<div className="flex flex-col gap-1">
							<CountUp
								prefix="€ "
								end={statistics?.total || 0}
								decimals={0}
								separator="."
								decimal=","
								className="text-3xl font-bold leading-full"
							/>
							<small className="opacity-80">
								{t("saved.description")}
							</small>
						</div>
					</Card>
					<Card className="flex-1" contentClassName="gap-2">
						<Card.Head title={t("upcoming.title")} />
						<div className="flex flex-col gap-1">
							<CountUp
								prefix="€ "
								end={statistics?.upcoming || 0}
								decimals={0}
								separator="."
								decimal=","
								className="text-3xl font-bold leading-full"
							/>
							<small className="opacity-80">
								{t("upcoming.description")}
							</small>
						</div>
					</Card>
				</div>
			)}
			<Card contentClassName="gap-2 p-6 md:px-10 md:py-8">
				{isExtern && <ReferralInviteForm />}
				<div
					className={cn("mx-auto w-full", isExtern && "mt-4 md:mt-8")}
				>
					<ReferralShare />
				</div>
			</Card>

			<div className="flex flex-col gap-12">
				<ReferralsList {...{ referrals, pagination, status }} />
			</div>
			{isExtern && <ReferralTerms />}
			{/* </Container> */}
		</Layout>
	);
}

ReferralsView.locale = {
	nl: {
		title: "Referrals",
		heading: "Nodig je connecties uit",
		// description:
		// "Nodig bedrijven uit en ontvang €20,- bij het aanmelden & 50% van de abonnement waarde als korting op je factuur.",

		description: {
			text: "Nodig bedrijven uit en bespaar op je volgende factuur",
			signup: "€20,- per aangemeld bedrijf",
			subscribed: "50% van het abonnement",
		},

		"how-it-works": "Hoe werkt het?",
		saved: {
			title: "Bespaard",
			description:
				"Dit bedrag heb je in totaal bespaard d.m.v. referrals",
		},
		upcoming: {
			title: "Aankomend",
			description: "Korting op de eerst volgende factuur",
		},
	},
	en: {
		title: "Referrals",
		heading: "Invite your connections",
		description:
			"Invite companies and receive 50% of the subscription value as a discount on your invoice.",
		"how-it-works": "How does it work?",
		saved: {
			title: "Saved",
			description:
				"This is the total amount you have saved through referrals.",
		},
		upcoming: {
			title: "Upcoming",
			description: "Discount on the next invoice.",
		},
	},
};
export default withTranslation(ReferralsView);
