import axios from "axios";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import usePatch from "src/hooks/rest/usePatch";
import { updateOffer } from "src/redux/offer";

function useEditOffer(id?: number | string) {
	const dispatch = useDispatch();
	const offer = useSelector((state: any) => state.offer.offer);
	const [fetching, setFetching] = useState(true);
	const [update, updating] = usePatch(`/student/offers/${id}`);
	const navigate = useNavigate();

	const fetchOffer = async () => {
		setFetching(true);
		const { data } = await axios.get(`/student/offers/${id}`);
		if (data?.data?.id) {
			dispatch(
				updateOffer({
					offer: data?.data,
				})
			);
		}
		setFetching(false);
	};

	const onChange = (key: string, value?: any) => {
		dispatch(
			updateOffer({
				offer: {
					...offer,
					[key]: value,
				},
			})
		);
	};

	const getParsedData = () => {
		const data = {
			...offer,
		};
		if (
			data?.description_intro ||
			data?.description_what ||
			data?.description_how
		) {
			data.description = {
				intro: data.description_intro || data?.description?.intro,
				what: data.description_what || data?.description?.what,
				how: data.description_how || data?.description?.how,
			};
		}
		delete data.description_intro;
		delete data.description_what;
		delete data.description_how;
		data.visibility = {
			is_anonymous: data.is_anonymous ? true : false,
			is_private: data.is_private ? true : false,
		};
		delete data.is_anonymous;
		delete data.is_private;
		return data;
	};

	const handleUpdate = async () => {
		const data = getParsedData();
		await update(data);
		navigate(`/offers/${offer.slug}/show?update=1`);
	};

	return {
		offer,
		onChange,
		fetchOffer,
		fetching,
		handleUpdate,
		updating,
	};
}
export default useEditOffer;
