import { useRef, useState } from "react";
import withTranslation, {
	Translation,
} from "src/components/hoc/withTranslation";
import useEmoji from "src/hooks/data/useEmoji";
import Icon from "src/components/icon/Icon";
import { ApiEmoji } from "src/api/types";
import IconList from "src/components/icon/IconList";
import {
	Popover,
	PopoverContent,
	PopoverTrigger,
} from "src/components/ui/popover";
import useElementSize from "src/hooks/useElementSize";

interface IconPickerProps extends Translation {
	children?: any;
	onChange: (value: ApiEmoji) => void;
	value: ApiEmoji;
	trigger: JSX.Element;
}

const IconPicker = ({ t, onChange, value, trigger }: IconPickerProps) => {
	const input = useRef<any>();
	const [open, setOpen] = useState(false);
	const { data, categories, filter, actions } = useEmoji();
	const head = useElementSize();
	return (
		<Popover {...{ open }} onOpenChange={(value) => setOpen(value)}>
			<PopoverTrigger asChild>
				<div className="cursor-pointer">{trigger}</div>
			</PopoverTrigger>
			<PopoverContent
				align="start"
				className="w-full p-2 flex flex-col gap-3"
				onClick={(e) => {
					e.stopPropagation();
				}}
			>
				<div ref={head.ref} className="flex gap-1 flex-wrap w-max">
					{categories.map((category) => (
						<Icon
							iconFontSize={12}
							onClick={() =>
								actions.selectCategory(
									filter.category === category.name
										? undefined
										: category.name
								)
							}
							variant={
								filter.category === category.name
									? "active"
									: undefined
							}
							key={category.name}
							icon={{
								emoji: {
									icon: category.icon.emoji,
									description:
										category.icon.emoji.description,
								},
							}}
						/>
					))}
				</div>
				<div
					onClick={() => {
						if (input?.current) {
							input?.current?.focus();
						}
					}}
					className="bg-accent border border-border rounded-md px-4 py-2 pl-2 flex items-center"
				>
					<i className="fas fa-search text-placeholder mr-4"></i>
					<input
						ref={input}
						className="bg-transparent placeholder-placeholder"
						value={filter.search}
						onChange={(e) => actions.search(e?.target?.value)}
						placeholder={t("search.placeholder")}
					/>
				</div>
				<div
					style={{
						maxHeight: 300,
						maxWidth: head.size.width || "320px",
					}}
					className="overflow-auto flex flex-wrap gap-1"
				>
					<IconList
						selected={value}
						onSelect={(item) => {
							onChange(item);
							setOpen(false);
						}}
						data={data.map((item) => ({
							icon: item.emoji,
							description: item.description,
						}))}
					/>
				</div>
			</PopoverContent>
		</Popover>
	);
};

IconPicker.locale = {
	nl: {
		search: {
			placeholder: "Zoek een icoon...",
		},
	},
	en: {
		search: {
			placeholder: "Search icon...",
		},
	},
};

export default withTranslation(IconPicker);
