import React, { ComponentProps } from "react";
import { useT } from "src/components/hoc/withTranslation";
import {
	useVacancyForm,
	VacancyFormStepName,
} from "src/components/vacancies/vacancy-form/provider";
import { cn } from "src/lib/utils";

interface VacancyFormStepsProps extends ComponentProps<"div"> {}

const VacancyFormSteps = ({
	children,
	className,
	...rest
}: VacancyFormStepsProps) => {
	const childrenWithProps: any = React.Children.map(
		children,
		(child, index) => {
			// Checking isValidElement is the safe way and avoids a typescript error too.
			if (React.isValidElement(child)) {
				return React.cloneElement(child, { index } as any);
			}
			return child;
		}
	);
	return (
		<div {...rest} className={cn("flex gap-6 items-center", className)}>
			{childrenWithProps}
		</div>
	);
};

export default VacancyFormSteps;

interface VacancyFormStepProps
	extends Omit<ComponentProps<"div">, "children" | "name"> {
	name: VacancyFormStepName;
	index?: number;
}

export const VacancyFormStep = ({
	className,
	name,
	index,
	...rest
}: VacancyFormStepProps) => {
	const t = useT({
		nl: {
			submit: "Plak je vacature",
			check: "Controleer de data",
			publish: "Publiceer je vacature",
		},
		en: {
			submit: "Submit your vacancy",
			check: "Check the data",
			publish: "Publish your vacancy",
		},
	});
	const count = index !== undefined ? index + 1 : undefined;
	const { step } = useVacancyForm();

	return (
		<div {...rest} className={cn("flex items-center gap-3", className)}>
			{count !== undefined && (
				<div
					className={cn(
						"flex justify-center items-center w-8 h-8 rounded-full bg-primary text-primary-foreground"
					)}
				>
					<span className="select-none">{count}</span>
				</div>
			)}
			<span
				className={cn(
					"font-regular opacity-40 transition-opacity duration-300 select-none",
					step.current === name && "opacity-100"
				)}
			>
				{t(name)}
			</span>
		</div>
	);
};

interface ActiveVacancyFormStepProps extends ComponentProps<"div"> {
	name: VacancyFormStepName;
}

export const ActiveVacancyFormStep = ({
	name,
	className,
	...rest
}: ActiveVacancyFormStepProps) => {
	const { step } = useVacancyForm();

	if (step.current !== name) return null;

	return <div className={cn("flex flex-col", className)} {...rest} />;
};
