import { createAction, handleActions } from "redux-actions";
import { ApiLanguage } from "src/api/types";
import i18n from "src/lib/i18n";

export const updateLanguage = createAction("UPDATE_LANGUAGE");
export const removeLanguage = createAction("REMOVE_LANGUAGE");

export const emptyLanguageState = {
	languages: [],
	current: i18n.language,
	loading: true,
};

type LanguageState = {
	languages: ApiLanguage[];
	current?: string;
	loading: boolean;
};

const languageReducer = handleActions(
	{
		UPDATE_LANGUAGE: (state: LanguageState, action: any) => {
			return { ...state, ...action.payload };
		},
		REMOVE_LANGUAGE: () => {
			return {
				...emptyLanguageState,
			};
		},
	},
	{
		...emptyLanguageState,
	}
);
export default languageReducer;
