import moment from "moment";
import {
	XAxis,
	YAxis,
	ResponsiveContainer,
	Area,
	AreaChart,
	Tooltip,
} from "recharts";
import { ucFirst } from "src/lib/formatters";

type Item = {
	key: string;
	value: any;
};

interface ChartProps {
	background: "dark" | "light";
	chart?: {
		height?: number | null;
	};
	tooltip?: {
		label?: string;
	};
	data?: Item[];
}

const Chart = ({ chart, data, tooltip, background }: ChartProps) => {
	return (
		<div className="w-full ">
			<ResponsiveContainer width="100%" height={chart?.height || 400}>
				<AreaChart
					data={data || []}
					margin={{ top: 10, right: 30, left: 0, bottom: 0 }}
				>
					<defs>
						<linearGradient
							id="colorUv"
							x1="0"
							y1="0"
							x2="0"
							y2="1"
						>
							<stop
								offset="1%"
								stopColor="#1aaa9d"
								stopOpacity={0.4}
							/>
							<stop
								offset="99%"
								stopColor="#1aaa9d"
								stopOpacity={0}
							/>
						</linearGradient>
					</defs>
					<Tooltip
						content={({ payload }) => (
							<div
								className={`bg-accent border border-border p-4 rounded-md`}
							>
								{payload?.map((item) => (
									<div key={item.payload.key}>
										<strong>
											{ucFirst(
												moment(
													item.payload.key,
													"DD-MM-YYYY"
												).format("DD-MM-YYYY")
											)}
										</strong>
										<div className="flex gap-1 items-center">
											{tooltip?.label && (
												<span>{tooltip.label}: </span>
											)}
											<span>{item.value}</span>
										</div>
									</div>
								))}
							</div>
						)}
					/>
					<XAxis
						axisLine={false}
						dataKey="key"
						tick={{
							fill:
								background === "dark"
									? "#ffffff20"
									: "#18383B40",
						}}
						tickLine={false}
						tickFormatter={(tick) =>
							ucFirst(moment(tick, "DD-MM-YYYY").format("MMM"))
						}
					/>
					<YAxis axisLine={false} tickMargin={20} tickLine={false} />

					<Area
						type="monotone"
						dataKey="value"
						stroke="#1aaa9d"
						fillOpacity={1}
						strokeWidth={3}
						fill="url(#colorUv)"
					/>
				</AreaChart>
			</ResponsiveContainer>
		</div>
	);
};

Chart.defaultProps = {
	background: "dark",
};

export default Chart;
