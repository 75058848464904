import TaskStatus from "src/components/tasks/TaskStatus";
import { useTaskKanban } from "src/components/tasks/kanban/TaskKanbanCard";
import { cn } from "src/lib/utils";

const TaskKanbanCardStatus = () => {
	const { task } = useTaskKanban();
	if (task.type === "GHOST") return null;
	return (
		<TaskStatus
			size="xsmall"
			className={cn(
				"leading-[8px] border border-transparent",
				task.status === "BACKLOG" && "border-border"
			)}
			status={task.status}
		/>
	);
};

export default TaskKanbanCardStatus;
