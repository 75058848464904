import { useTranslation } from "react-i18next";

interface OfferSummaryGroupProps {
	title: string;
	onEdit?: () => void;
	children?: any;
}

const OfferSummaryGroup = ({
	title,
	onEdit,
	children,
}: OfferSummaryGroupProps) => {
	const { t } = useTranslation("buttons");
	return (
		<div className=" ">
			<div className="flex justify-between gap-2">
				<strong>{title}</strong>
				{onEdit && (
					<span
						onClick={onEdit}
						className="opacity-30 cursor-pointer"
					>
						{t("edit")}
					</span>
				)}
			</div>
			<div className="flex flex-col gap-1">{children}</div>

			<div className="h-[1px] opacity-60 mt-6 bg-accent rounded-md"></div>
		</div>
	);
};

interface OfferSummaryGroupItemProps {
	label?: string;
	children?: any;
}

const OfferSummaryGroupItem = ({
	label,
	children,
}: OfferSummaryGroupItemProps) => {
	return (
		<div className="flex justify-between gap-2">
			<span>{label}</span>
			<span>{children}</span>
		</div>
	);
};

OfferSummaryGroup.Item = OfferSummaryGroupItem;

export default OfferSummaryGroup;
