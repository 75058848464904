interface RegistrationSkillsMatchItemProps {
	title: string;
	description: string;
	score: number;
	children?: any;
}

const RegistrationSkillsMatchItem = ({
	title,
	description,
	score,
	children,
}: RegistrationSkillsMatchItemProps) => {
	return (
		<>
			<hr className="border-border" />
			<div className="flex flex-col p-4">
				<div className="flex md:flex-row flex-col gap-2 md:gap-6 md:items-center">
					<div className="md:py-4 md:px-6 rounded-md md:w-36 md:text-center">
						<h2 className="text-primary">+{score}</h2>
					</div>
					<div className="flex flex-col flex-1">
						<p>{title}</p>
						<p className="opacity-80">{description}</p>
						<div className="mt-3 empty:hidden">{children}</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default RegistrationSkillsMatchItem;
