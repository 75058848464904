import { AxiosInstance } from "axios";
import { ApiTaskKanban } from "src/api/types";
import useApi, {
	ApiActions,
	ApiFilter,
	ApiStatus,
	Pagination,
} from "src/hooks/api/utils/useApi";
import moment from "moment";
import useAuth from "src/hooks/selectors/useAuth";

type State = {
	tasks: ApiTaskKanban[];
	status: ApiStatus;
	actions: ApiActions;
	api: AxiosInstance;
	pagination: Pagination;
	filter: ApiFilter;
};

type Options = {
	resetFilter?: boolean;
	filter?: any;
};

function useMyOverdueTasks(options?: Options): State {
	const auth = useAuth();
	const { state, actions, api, pagination } = useApi(
		{ id: "MY_OVERDUE_TASKS", group: "TASKS" },
		{
			baseUrl: `/client/tasks`,
			isAllowed: (item: ApiTaskKanban) =>
				!!(
					item.links.assignees?.includes(auth.id) &&
					(!item.end_date || moment(item.end_date).isBefore(moment()))
				),
			query: {
				date: "overdue",
				board: "my-tasks",
				status: ["BACKLOG", "TODO", "IN_PROGRESS"],
				variant: "kanban",
				limit: 6,
				include: ["private", "project-tasks"],
				...(options?.filter || {}),
			},
		}
	);

	const tasks = (state?.list || []).filter(
		(item) =>
			item.status !== "COMPLETED" &&
			(item.end_date
				? moment(item.end_date).isBefore(moment().subtract(1, "day"))
				: true)
	);

	return {
		tasks,
		status: state.status,
		filter: state?.filter || null,
		pagination,
		actions,
		api,
	};
}

export default useMyOverdueTasks;
