const payments = {
	loading: "Een ogenblik geduld, we controleren je betaling",
	"invoice-send": "De factuur is verzonden",
	completed: "Je betaling is gelukt!",
	track: "Volg je betaling",
	"service-fee": "Success fee ({{rate}}%)",
	status: {
		pending: "In afwachting",
		completed: "Geslaagd",
		expired: "Mislukt",
		reserved: "Gereserveerd",
		Betaald: "Betaald",
		cancelled: "Geannuleerd",
		chargeback: "Geannuleerd",
		refunded: "Geannuleerd",
		created: "In afwachting",
		credit: "Credit",
	},
	types: {
		subscription: "Abonnementsgelden",
		payout: "Uitbetaling professional",
	},
	"information-card": {
		title: "Betaalinformatie",
		"addressed-to": "Tenaamstelling",
		reference: "Referentie",
	},
	"payment-detail-card": {
		title: "Informatie",
		"invoice-number": "Factuurnummer",
		method: "Betaalmethode",
		description: "Omschrijving",
		status: "Status",
		price: {
			"including-vat": "Bedrag (incl. BTW)",
			"percentage-vat": "BTW percentage",
		},
		"created-at": "Aangemaakt op",
		"payout-at": "Wordt uitbetaald op",
	},
	methods: {
		invoice: "Factuur",
		ideal: "Ideal",
		mandate: "Automatisch incasso",
		afterpay: "Factuur achteraf",
		checkout: "Betaling achteraf",
		undefined: "Onbekend",
	},
	"checkout-payment-methods": {
		title: "Kies een betaalmethode",
		"mandate-disclaimer":
			"De opdracht kan pas na {{date}} uitbetaald worden",
		checkout: "Afrekenen",
	},
	"checkout-summary": {
		title: "Factuur{{invoice_number}}",
		products: "Producten",
		subtotal: "Subtotaal",
		vat: "BTW ({{vat}}%)",
		"vat-checkout": "BTW wordt berekend bij afrekenen",
		"partner-fee": "Partner fee",
		"partner-fee-disclaimer":
			"Deze fee moeten wij rekenen van de betalingsprovider voor de verwerking van de betaling",
		total: "Totaal",
	},
	"mandate-methods": {
		title: "Betaalmethode:",
		placeholder: "Kies een betaalmethode",
		ideal: {
			title: "Kies een bank",
			"method-not-existing":
				"Staat jouw gewenste betaalmethode er niet bij? <1>Klik dan hier</1> om een handmatige bankkoppeling aan te vragen.",
			"method-custom":
				"De koppeling met <1>{{name}}</1> is een handmatige koppeling. Je hoeft enkel je iban en tenaamstelling in te voeren, onze betalingsprovider regelt de rest.",
			continue: "Doorgaan naar {{name}}",
			label: "Betaal d.m.v. ideal",
		},
	},
	"payment-status": {
		title: "Betaling",
		price: "Bedrag",
		status: "Status",
		invoice: "Factuur",
		"not-created": "Nog niet gemaakt",
		method: "Methode",
		track: "Betaling volgen",
		new: "Nieuwe betaling",
		view: "Betaling bekijken",
		"payout-description":
			"Deze betaling wordt uitbetaald op {{date}}, daarna duurt het 2 werkdagen voor het op je rekening staat.",
	},
	"registration-payment-url": {
		title: "Betaling status",
		ideal: "Betalen via ideal",
		invoice: "Nieuwe factuur maken",
		"not-paid": "Nog niet betaald",
	},
};

export default payments;
