import { useEffect, useState } from "react";
import Alert from "src/components/Alert";
import Button from "src/components/Button";
import Input from "src/components/form/Input";
import Form from "src/components/tasks/TaskForm/Form";
import withTranslation, {
	Translation,
} from "src/components/hoc/withTranslation";
import useTaskForm from "src/hooks/api/tasks/useTaskForm";
import AIGenerateDescription from "src/components/tasks/TaskForm/steps/AIGenerateDescription";
import useQueryString from "src/hooks/useQueryString";
import Loader from "src/components/Loader";

const DURATION = 4000;

interface AIQuestionsFormProps extends Translation {
	hideButtons?: boolean;
}

const AIQuestionsForm = ({ t, hideButtons }: AIQuestionsFormProps) => {
	const { task, actions, questions, current } = useTaskForm();
	const [loading, setLoading] = useState(true);
	const [tries, setTries] = useState(0);
	const [isGenerating, setIsGenerating] = useState(false);
	const isAnswered = (task?.answers || []).every((item: any) => item.answer);
	const qs = useQueryString();

	useEffect(() => {
		if (isAnswered && qs.generate && !isGenerating) {
			setIsGenerating(true);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isAnswered]);

	useEffect(() => {
		setLoading(true);
		let id: NodeJS.Timeout | null = null;
		const getQuestions = () => {
			if (tries > 3) {
				setLoading(false);
				return;
			}
			actions
				.getQuestions()
				.then(() => {
					setLoading(false);
				})
				.catch(() => {
					setTries((count) => count + 1);

					id = setTimeout(() => {
						getQuestions();
					}, DURATION);
				});
		};

		if (task.answers?.length) {
			actions.setQuestions(task.answers);
			setLoading(false);
		} else {
			getQuestions();
		}

		return () => {
			if (id) {
				clearTimeout(id);
			}
			actions.clearQuestions();
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleChange = (value: string, question: string) => {
		if (task?.answers?.length) {
			actions.set({
				answers: task.answers.map((item: any) => {
					if (item.question === question) {
						return {
							...item,
							answer: value,
						};
					}
					return item;
				}),
			});
			return;
		}

		actions.set({
			answers: questions.map((item: any) => {
				if (item.question === question) {
					return {
						...item,
						answer: value,
					};
				}
				return item;
			}),
		});
	};

	const onSubmit = async () => {
		// actions.updateCurrent(current + 1);
		setIsGenerating(true);
	};

	const getNextDisabled = () => {
		return (
			!task.answers?.length ||
			!(task?.answers || []).every((item: any) => item.answer)
		);
	};

	// if (loading) {
	// 	return <AILoading description={t("loading")} />;
	// }

	if (isGenerating && task?.answers?.length) {
		return (
			<div className="flex flex-col">
				<AIGenerateDescription onBack={() => setIsGenerating(false)}>
					<Button
						onClick={() => actions.updateCurrent(current + 1)}
						type="primary"
						className="mt-10"
					>
						{t("continue")}
						<i className="fas fa-arrow-right ml-2"></i>
					</Button>
				</AIGenerateDescription>
			</div>
		);
	}

	if (loading) {
		return (
			<div className="flex flex-col justify-center items-center p-8">
				<Loader />
			</div>
		);
	}

	return (
		<>
			<Form
				{...{ hideButtons, onSubmit }}
				value={{
					answers: task.answers,
				}}
				nextDisabled={getNextDisabled()}
				nextButtonLabel={t("generate")}
			>
				{() => {
					return (
						<>
							<Alert type="info">
								<p>{t("disclaimer")}</p>
							</Alert>

							{(questions || []).map(
								(question: any, index: number) => {
									return (
										<Input
											key={question.question}
											// multiline
											autoFocus={index === 0}
											label={question.question}
											placeholder=" "
											name="answer"
											value={
												task?.answers?.find(
													(item: any) =>
														item.question ===
														question.question
												)?.answer
											}
											handleChange={(e) => {
												handleChange(
													e.target.value,
													question.question
												);
											}}
										/>
									);
								}
							)}
						</>
					);
				}}
			</Form>
			<div className="flex flex-col justify-center items-center">
				<Button onClick={actions.skipAIStep} type="gray">
					{t("skip-ai")}
					<i className="fas fa-arrow-right ml-2"></i>
				</Button>
			</div>
		</>
	);
};

AIQuestionsForm.locale = {
	nl: {
		loading: "De vragen worden gegenereerd",
		disclaimer:
			"Op basis van deze vragen maakt onze AI een opdrachtomschrijving, deze kan je nog aanpassen",
		"skip-ai": "AI overslaan",
		generate: "Genereren",
		continue: "Doorgaan",
	},
	en: {
		loading: "The questions are being generated",
		disclaimer:
			"Our AI will create a task description based on your answers, the description can be edited before publishing",
		"skip-ai": "Skip AI",
		generate: "Generate",
		continue: "Continue",
	},
};

export default withTranslation(AIQuestionsForm);
