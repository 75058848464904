import * as TogglePrimitive from "@radix-ui/react-toggle";
import { cva, type VariantProps } from "class-variance-authority";
import * as React from "react";
import { cn } from "src/lib/utils";

const toggleVariants = cva(
	cn(
		"rounded-md text-sm font-medium transition-colors text-left",
		"focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring border border-transparent",
		"hover:border-border",
		"data-[state=on]:bg-dark data-[state=on]:text-dark-foreground hover:data-[state=on]:bg-dark hover:data-[state=on]:border-dark"
	),
	{
		variants: {
			variant: {
				default: "bg-accent",
			},
			size: {
				default: "py-2 px-3",
			},
		},
		defaultVariants: {
			variant: "default",
			size: "default",
		},
	}
);

const Toggle = React.forwardRef<
	React.ElementRef<typeof TogglePrimitive.Root>,
	React.ComponentPropsWithoutRef<typeof TogglePrimitive.Root> &
		VariantProps<typeof toggleVariants>
>(({ className, variant, size, ...props }, ref) => (
	<TogglePrimitive.Root
		ref={ref}
		className={cn(toggleVariants({ variant, size, className }))}
		{...props}
	/>
));

Toggle.displayName = TogglePrimitive.Root.displayName;

export { Toggle, toggleVariants };
