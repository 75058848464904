import moment from "moment";
import { useTranslation } from "react-i18next";
import { ApiMandate } from "src/api/types";
import { BANK_ICONS } from "src/lib/constants";
import Button from "src/components/Button";
import SettingCard from "src/components/cards/SettingCard";
import Logo from "src/components/Logo";

interface MandateCardProps {
	mandate: ApiMandate;
	onActivate?: (mandate: ApiMandate) => void;
	loading?: boolean;
	maxWidth?: any;
}

const MandateCard = ({
	mandate,
	loading,
	onActivate,
	maxWidth,
}: MandateCardProps) => {
	const { t } = useTranslation("subscription", {
		keyPrefix: "mandate-card",
	});
	return (
		<SettingCard {...{ maxWidth }} active={mandate.is_selected}>
			{mandate.is_selected && (
				<Logo
					variant="small"
					color="white"
					className="absolute -right-2 -bottom-16 w-32 opacity-10"
				/>
			)}
			<div className="flex items-center justify-between">
				<div className="flex">
					{mandate.status === "completed" && (
						<>
							<strong className="text-primary">
								<i className="fas fa-check mr-2"></i>
								{t("connected")}
							</strong>
						</>
					)}
					{mandate.status === "pending" && (
						<>
							<strong>
								<i className="fas fa-clock mr-2"></i>
								{t("pending")}
							</strong>
						</>
					)}
				</div>
				{mandate.icon && (
					<img
						src={BANK_ICONS[mandate.icon] || BANK_ICONS["unknown"]}
						alt={mandate.icon}
						className="w-12"
					/>
				)}
			</div>
			{mandate?.payment_iban && (
				<div className="flex flex-row flex-wrap justify-between items-center mt-4 z-20">
					<strong className="mb-0">{mandate?.payment_iban}</strong>
					<small className="mb-0">{mandate?.payment_name}</small>
				</div>
			)}
			{mandate.created_at && (
				<div className="flex flex-row flex-wrap mt-4 items-center">
					<span>
						{t("created-at", {
							date: moment(mandate.created_at).format(
								"DD-MM-YYYY"
							),
						})}
					</span>
				</div>
			)}
			{onActivate && (
				<div className="flex mt-8 z-20">
					<Button
						type={mandate.is_selected ? "gray" : "primary"}
						small
						disabled={
							mandate.is_selected || mandate.status === "pending"
						}
						{...{ loading }}
						onClick={() => {
							if (!mandate.is_selected) {
								onActivate(mandate);
							}
						}}
					>
						{mandate.is_selected
							? t("button.selected")
							: t("button.select")}
					</Button>
				</div>
			)}
		</SettingCard>
	);
};

export default MandateCard;
