import Button from "src/components/Button";
import withTranslation, {
	Translation,
} from "src/components/hoc/withTranslation";
import { ModalProps } from "src/components/Modal";
import { Dialog, DialogContent, DialogOverlay } from "src/components/ui/dialog";
import useModal from "src/hooks/useModal";
import { cn } from "src/lib/utils";

function FillModal({ t, children }: ModalProps & Translation) {
	const modal = useModal();
	return (
		<Dialog
			open
			onOpenChange={() => {
				modal.onClose();
			}}
		>
			<DialogOverlay />
			<DialogContent
				variant="minimal"
				className={cn(
					"inset-2 md:inset-8 p-3 md:p-6 flex flex-col flex-1",
					modal.theme === "dark" &&
						"bg-dark text-dark-foreground border-[#ffffff10]"
				)}
			>
				<div className="absolute top-4 right-4 z-[999]">
					<Button
						type={modal.theme === "dark" ? "dark" : "border"}
						xsmall
						onClick={modal.onClose}
					>
						<i className="far fa-times"></i>
						{t("close")}
					</Button>
				</div>
				{children}
			</DialogContent>
		</Dialog>
	);
}

FillModal.locale = {
	nl: {
		close: "Sluiten",
	},
	en: {
		close: "Close",
	},
};

export default withTranslation(FillModal);
