import React from "react";
import { ApiIcon } from "src/api/types";
import Icon from "src/components/icon/Icon";
import Indicator from "src/components/layout/Indicator";
import { cn } from "src/lib/utils";

interface NavItemIconProps {
	count?: number | null;
	icon?: ApiIcon | string | JSX.Element;
	iconOverlay?: JSX.Element;
	loading?: boolean;
	isActive?: boolean;
}

const NavItemIcon = ({
	count,
	icon: _icon,
	loading,
	isActive,
	iconOverlay,
}: NavItemIconProps) => {
	const icon: any = _icon;
	const iconStyles = ["fas", "far", "fal", "fab"];
	// const iconStyle = isActive ? "fas" : "fas";
	const iconStyle = "fas";
	const hasStyle =
		typeof icon === "string"
			? iconStyles.some((style) => (icon || "").includes(style))
			: false;
	const iconFontSize = 13;
	const iconClassName = cn(
		"bg-transparant text-background-foreground hover:bg-transparent group-hover:bg-accent",
		isActive && "bg-accent"
	);

	const isApiIcon =
		typeof icon === "object" &&
		((icon as ApiIcon)?.font_awesome ||
			(icon as ApiIcon)?.emoji ||
			(icon as ApiIcon)?.media);

	return (
		<div className="relative">
			{iconOverlay}
			{count ? (
				<Indicator
					style={{
						top: -6,
						right: -10,
					}}
					className="absolute z-[99]"
				>
					{count}
				</Indicator>
			) : (
				false
			)}
			{loading ? (
				<span className="base-loader"></span>
			) : typeof icon === "string" ? (
				<Icon
					variant="nav-item"
					className={iconClassName}
					{...{ iconFontSize }}
					icon={{
						font_awesome: {
							name: `${icon} ${!hasStyle ? iconStyle : ""}`,
						},
					}}
				/>
			) : isApiIcon ? (
				<Icon
					variant="nav-item"
					className={cn(iconClassName, icon?.className)}
					{...{ iconFontSize, icon }}
				/>
			) : React.isValidElement(icon) ? (
				<Icon
					variant="nav-item"
					className={iconClassName}
					{...{ iconFontSize, icon }}
				/>
			) : (
				false
			)}
		</div>
	);
};

export default NavItemIcon;
