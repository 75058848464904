import moment from "moment";
import qs from "query-string";
import Form from "src/components/tasks/TaskForm/Form";
import useTaskForm from "src/hooks/api/tasks/useTaskForm";
import useAuth from "src/hooks/selectors/useAuth";
import { useTranslation } from "react-i18next";
import useModules from "src/hooks/selectors/useModules";
import useTenant from "src/hooks/api/services/tenants/useTenant";
import { TaskFormOptions } from "src/components/tasks/TaskForm";
import GeneratedDescriptionForm from "src/components/tasks/TaskForm/steps/GeneratedDescriptionForm";
import FinancialSummary from "src/components/tasks/TaskForm/elements/FinancialSummary";
import SummaryWrapper from "src/components/tasks/TaskForm/elements/SummaryWrapper";
import AdvertiseTask from "src/components/tasks/TaskForm/elements/AdvertiseTask";
import Switch from "src/components/form/Switch";
import KeyValue from "src/components/KeyValue";
import ApiCall from "src/components/ApiCall";
import UserAvatars from "src/components/UserAvatars";

const SummaryAIForm = ({ hideButtons }: TaskFormOptions) => {
	const { t } = useTranslation("forms", {
		keyPrefix: "summary-form",
	});
	const auth = useAuth();
	const { task, actions } = useTaskForm();
	const { modules } = useModules();
	const isSameDate =
		moment(task.start_date).format() === moment(task.end_date).format();

	const avatarQuery = qs.stringify({
		"include[]": task.students,
	});
	const { tenant } = useTenant();
	const advertiseTask =
		modules?.includes("pay-advertise-task") && !task.is_anonymous;

	return (
		<Form {...{ hideButtons }} title={t("title")}>
			{() => (
				<>
					<div className="flex flex-col gap-4">
						<GeneratedDescriptionForm />
					</div>

					<SummaryWrapper title={t("details.title")}>
						{!isSameDate && (
							<>
								<KeyValue.Small
									label={t("details.start-date")}
									text={moment(task.start_date).format(
										"D MMMM"
									)}
								/>
								{task.end_date && (
									<KeyValue.Small
										label={t("details.end-date")}
										text={moment(task.end_date).format(
											"D MMMM"
										)}
									/>
								)}
							</>
						)}
						{isSameDate && (
							<KeyValue.Small
								label={t("details.date")}
								text={moment(task.end_date).format("D MMMM")}
							/>
						)}
						{task.estimated_duration && (
							<KeyValue.Small
								label={t("details.estimated-duration")}
								text={`${Math.floor(
									task.estimated_duration
								)} ${t("details.estimated-duration-end")}`}
							/>
						)}
						{(task.invite_all || task.students?.length > 0) && (
							<KeyValue.Small
								label={t("details.invited")}
								text={
									task.invite_all ? (
										t("details.invited-all")
									) : (
										<>
											<ApiCall
												query={`/connections?${avatarQuery}`}
											>
												{(data, loading) => (
													<>
														{loading && (
															<span className="base-loader" />
														)}
														{!loading && (
															<UserAvatars
																users={data}
															/>
														)}
													</>
												)}
											</ApiCall>
										</>
									)
								}
							/>
						)}
					</SummaryWrapper>

					{advertiseTask && (
						<SummaryWrapper>
							<AdvertiseTask
								{...{ task }}
								onChange={(value) => {
									actions.set({
										advertise_task: value,
									});
								}}
							/>
						</SummaryWrapper>
					)}
					{auth.company.promos?.includes("christmas_gift") && (
						<SummaryWrapper title={t("promo.christmas.title")}>
							<Switch
								value={task.add_christmas_gift}
								label={t("promo.christmas.label")}
								onChange={(value) =>
									actions.set({
										add_christmas_gift: value,
									})
								}
							/>
							<a
								target="_blank"
								className="underline mt-2"
								href={`${tenant?.urls?.public}/bedrijven/kerst-actie`}
								rel="noreferrer"
							>
								{t("promo.christmas.more")}
							</a>
						</SummaryWrapper>
					)}
					<FinancialSummary />
				</>
			)}
		</Form>
	);
};

export default SummaryAIForm;
