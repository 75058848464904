import useApi from "src/hooks/api/utils/useApi";

type Options = {
	filter?: any;
};

function useConnection(id: string, options?: Options) {
	const { state, actions, api } = useApi(
		{ id: "CONNECTIONS" },
		{
			baseUrl: `/connections`,
			query: {
				...(options?.filter || {}),
			},
		}
	);
	const connection: any = state?.data || {};

	return {
		connection,
		search: state.filter.q,
		status: state.status,
		actions: {
			...actions,
		},
		api,
	};
}

export default useConnection;
