import { AnimatePresence, motion } from "framer-motion";
import moment, { Moment } from "moment";
import { ComponentProps, useEffect, useMemo, useState } from "react";
import { ApiTaskKanban } from "src/api/types";
import Button from "src/components/Button";
import Card from "src/components/Card";
import EmptyState from "src/components/EmptyState";
import Link from "src/components/Link";
import Draggable from "src/components/draggable";
import withTranslation, {
	Translation,
} from "src/components/hoc/withTranslation";
import Icon from "src/components/icon/Icon";
import TaskKanbanCard from "src/components/tasks/kanban/TaskKanbanCard";
import TaskList from "src/components/tasks/list/TaskList";
import Collapsible from "src/components/ui/collapsible";
import Text from "src/components/ui/text";
import useMyTasksByDay from "src/hooks/api/services/tasks/useMyTasksByDay";
import useNavigateModal from "src/hooks/useNavigateModal";
import { cn } from "src/lib/utils";

interface MyTasksDayCheckCardProps extends Translation, ComponentProps<"div"> {
	date: Moment;
	title?: string;
	defaultOpen?: boolean;
}

const MyTasksDayCheckCard = ({
	t,
	className,
	date,
	title,
	defaultOpen,
	...rest
}: MyTasksDayCheckCardProps) => {
	const dropId = `DATE:${date.format("YYYY-MM-DD")}`;
	const [isOpen, setIsOpen] = useState(
		defaultOpen ? defaultOpen.toString() : "false"
	);
	const navigateModal = useNavigateModal();
	const newUrl = {
		modal: `./modals/tasks/create?end_date=${date.format("YYYY-MM-DD")}`,
	};
	const { tasks, pagination, status, actions, showCompletedTasks } =
		useMyTasksByDay({
			date: date.format("YYYY-MM-DD"),
		});
	const formattedTitle = useMemo(() => {
		if (title) return title;
		//If date is today, return today
		if (date.isSame(moment(), "day")) {
			return t("today");
		}

		//if date is within current week show day name
		if (date.isSame(moment(), "week")) {
			return date.format("dddd");
		}

		return date.format("DD MMMM YYYY");
	}, [date, t, title]);

	useEffect(() => {
		actions.list(
			!showCompletedTasks
				? {
						status: ["BACKLOG", "TODO", "IN_PROGRESS"],
				  }
				: {
						status: ["BACKLOG", "TODO", "IN_PROGRESS", "COMPLETED"],
				  }
		);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [showCompletedTasks]);

	const onToggleStatusCompleted = (task: ApiTaskKanban) => {
		return actions.update(
			{
				id: task.id,
				status: task.status === "COMPLETED" ? "BACKLOG" : "COMPLETED",
			},
			{
				updateList: true,
			}
		);
	};

	return (
		<Draggable.Drop id={dropId} ghostItemVariant="none" className="">
			{({ isOver }: any) => (
				<Collapsible
					open={isOpen === "true" || isOver}
					onOpenChange={(value) =>
						setIsOpen(value ? "true" : "false")
					}
				>
					<div
						{...rest}
						className={cn(
							"flex flex-col gap-4 relative mb-4",
							className
						)}
					>
						<AnimatePresence>
							{isOver && (
								<motion.div
									initial={{ opacity: 0 }}
									animate={{ opacity: 0.1 }}
									exit={{ opacity: 0 }}
									className="flex w-full rounded-md bg-primary z-[99] absolute -inset-2"
								></motion.div>
							)}
						</AnimatePresence>
						<div className="flex justify-between items-center">
							<Collapsible.Trigger
								className="opacity-100"
								showArrow
							>
								<Text.Eyebrow className="select-none">
									{formattedTitle}
								</Text.Eyebrow>
							</Collapsible.Trigger>
							<div className="flex items-center gap-2">
								<p
									className={cn(
										"text-[15px] leading-full text-placeholder",
										pagination?.total === 0 && "opacity-50"
									)}
								>
									{t("total", { count: tasks?.length })}
								</p>
								<Link to={newUrl}>
									<Icon
										className="text-placeholder"
										iconFontSize={17}
										icon={{
											font_awesome: {
												name: "far fa-plus",
											},
										}}
									/>
								</Link>
							</div>
						</div>

						<Collapsible.Content>
							<div className="flex flex-col gap-4">
								<TaskList
									{...{ tasks }}
									onUpdate={(id, value) =>
										actions.update(
											{
												id,
												...value,
											},
											{
												updateList: true,
											}
										)
									}
									item={{
										elements: ["status", "description"],
									}}
									loading={status === "loading"}
									empty={
										tasks.length === 0 &&
										status === "idle" && (
											<EmptyState
												to={newUrl}
												icon="fas fa-check-circle"
												title={t("empty.title")}
												description={t(
													"empty.description"
												)}
												theme="dashed"
												variant="center"
											/>
										)
									}
									renderItem={(task) => (
										<Draggable.Item
											key={`task-${task.id}-check`}
											id={`LIST_${task.id}`}
											data={task}
											currentDropId={dropId}
										>
											<TaskKanbanCard
												{...{ task }}
												variant="fragment"
											>
												<div
													onClick={(e: any) => {
														e.stopPropagation();
														// if (
														// 	task.type ===
														// 		"GHOST" ||
														// 	!task.hid
														// ) {
														// 	return;
														// }
														// if (
														// 	task.type !==
														// 	"INTERNAL"
														// ) {
														// 	navigate(
														// 		`/tasks/${task.hid}/show?${back}`
														// 	);
														// 	return;
														// }
														// navigateModal(
														// 	`/dashboard/tasks/${task.hid}/show`
														// );
														navigateModal(
															`./modals/tasks/${task.hid}`
														);
													}}
													className="flex gap-4 rounded-md items-center"
												>
													<div className="flex">
														<TaskKanbanCard.Check
															value={
																task.status ===
																"COMPLETED"
															}
															onChange={async () =>
																await onToggleStatusCompleted(
																	task
																)
															}
														/>
													</div>
													<div className="flex flex-1 flex-row gap-4">
														<TaskKanbanCard.Title
															className="flex-1"
															variant="animated"
															isLineThrough={
																task.status ===
																"COMPLETED"
															}
														/>
														<div className="flex items-center gap-2">
															<TaskKanbanCard.Board
																cutText={6}
															/>
															<TaskKanbanCard.Type />
														</div>
													</div>
												</div>
											</TaskKanbanCard>
										</Draggable.Item>
									)}
								/>
								{pagination.page < pagination.last_page &&
									tasks.length !== 0 && (
										<Card.LoadMore
											variant="more"
											value={false}
											onClick={() => {
												actions.list(
													{
														page:
															tasks.length ===
																0 &&
															pagination.last_page >
																pagination.page
																? 1
																: pagination.page +
																  1,
													},
													{
														appendResult: true,
													}
												);
											}}
										/>
									)}
								<Button
									xsmall
									className="w-max"
									type="ghost"
									to={newUrl}
								>
									<i className="far fa-plus"></i>
									{t("add")}
								</Button>
							</div>
						</Collapsible.Content>
					</div>
				</Collapsible>
			)}
		</Draggable.Drop>
	);
};

MyTasksDayCheckCard.locale = {
	nl: {
		today: "Jouw taken voor vandaag",
		total_one: "1 taak",
		total_other: "{{count}} taken",
		empty: {
			title: "Geen taken voor vandaag gevonden",
			description: "Klik hier om taken toe te voegen",
		},
		add: "Taak toevoegen",
	},
	en: {
		today: "Your tasks for today",
		total_one: "1 task",
		total_other: "{{count}} tasks",
		empty: {
			title: "No tasks found",
			description: "Click here to create a new task",
		},
		add: "Add task",
	},
};

export default withTranslation(MyTasksDayCheckCard);
