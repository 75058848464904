const profile = {
	"company-create-modal": {
		"email-incorrect": "Invalid email address",
		required: "Required",
		"already-in-use": "{{email}} is already in use",
		status: {
			added: "The branch has been added! The specified email address will receive an invitation soon.",
			failed: "Something went wrong, try again later. If this problem persists, please contact <1>support@{{tenant.slug}}.nl</1>",
			button: "Close",
		},
		title: "Add branch",
		description:
			"Enter an email address and we will invite them for the branch on <1>{{address}}</1>. Via email, the person can create their own account",
		"fill-description": "Fill in the address of the new branch",
		email: "Email",
		buttons: {
			back: "Step back",
			create: "Create branch",
			cancel: "Cancel",
		},
	},
	"companies-view": {
		title: "Branches",
		add: "Add branch",
		empty: "There are currently no branches",
		card: {
			primary: "Main branch",
			current: "Your branch",
			branche: "Branch",
			kvk: "KVK",
		},
	},
	"company-view": {
		title: "Company profile",
		avatar: {
			title: "Avatar",
			description:
				"Add your logo so that professionals can easily recognize your assignments.",
		},
		info: {
			title: "Company information",
		},
		"invoice-address": {
			title: "Invoice address",
			description: "These details will be displayed on the invoices.",
		},
		contact: {
			title: "Contact information",
			description:
				"Matched professionals can leave questions here about, for example, outstanding payments or such questions.",
		},
	},
	"invite-user-modal": {
		fields: {
			required: "Required",
			"incorrect-email": "Invalid email address",
			"inuse-email": "{{email}} is already in use",
		},
		status: {
			added: "The user has been added! The specified email address will receive an invitation soon.",
			failed: "Something went wrong, try again later. If this problem persists, please contact <1>support@{{tenant.slug}}.nl</1>",
			button: "Close",
		},
		title: "Invite user",
		description: "Invite a user by email",
		form: {
			email: "Email",
			button: "Send invitation",
		},
	},
	"profile-tabs": {
		"my-profile": "My profile",
		"my-resume": "My resume",
		"my-subscription": "My subscription",
		"company-profile": "Company profile",
		companies: "Branches",
		users: "Users",
		teams: "Teams",
		"teams-and-users": "Teams & Users",
	},
};

export default profile;
