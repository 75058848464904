import { useState } from "react";
import Button from "src/components/Button";
import Layout from "src/components/layout/Layout";
import FilterTabs from "src/components/layout/FilterTabs";
import OfferRegistrationCompanyList from "src/components/offer/lists/OfferRegistrationCompanyList";
import useAuth from "src/hooks/selectors/useAuth";
import { useTranslation } from "react-i18next";
import useTenant from "src/hooks/api/services/tenants/useTenant";
import SubscriptionFeatureMiddleware from "src/components/middlewares/SubscriptionFeatureMiddleware";

export default function TalentsView() {
	const { t } = useTranslation("talent-view", {
		keyPrefix: "index-view",
	});
	const auth = useAuth();
	const { tenant } = useTenant();
	const [filter, setFilter] = useState("all");
	const filters = {
		all: t("filters.all"),
		accepted: t("filters.accepted"),
		denied: t("filters.denied"),
	} as any;

	if (auth.type === "company" && !auth.has_subscription) {
		return (
			<Layout
				title={t("title")}
				breadcrumb={[
					{
						label: t("title"),
						to: "/talents",
					},
				]}
			>
				<SubscriptionFeatureMiddleware plan="all" />
			</Layout>
		);
	}

	return (
		<Layout
			container="xxl"
			title={t("title")}
			breadcrumb={[
				{
					label: t("title"),
					to: "/talents",
				},
			]}
		>
			<div className="flex justify-between items-center gap-6 mb-6">
				<FilterTabs
					tabs={filters}
					variant={auth.type === "company" ? "light" : "dark"}
					active={filter}
					onChange={setFilter}
				/>

				<Button to={tenant?.urls?.public + "/talentenbord"}>
					<i className="fas fa-search mr-2"></i>
					{t("search")}
				</Button>
			</div>
			<OfferRegistrationCompanyList query={`type=${filter}`} />
		</Layout>
	);
}
