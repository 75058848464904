import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ApiPayment } from "src/api/types";
import Button from "src/components/Button";
import Card from "src/components/Card";
import withTranslation, {
	Translation,
} from "src/components/hoc/withTranslation";
import Layout from "src/components/layout/Layout";
import PaymentListener from "src/components/payment/PaymentListener";
import Skeleton from "src/components/Skeleton";
import AnalyseVacancyForm, {
	AnalyseVacancyFormField,
	AnalyseVacancyFormSubmitButton,
} from "src/components/vacancies/vacancy-form/steps/analyse-vacancy-form";
import VacancyForm from "src/components/vacancies/vacancy-form/vacancy-form";
import useStatistics from "src/hooks/api/services/useStatistics";
import useTaskCredits from "src/hooks/api/services/useTaskCredits";
import usePolling from "src/hooks/usePolling";
import useQueryString from "src/hooks/useQueryString";
import axios from "src/lib/api";

const RecruitmentDashboard = ({ t }: Translation) => {
	const { actions: taskCreditsActions } = useTaskCredits();
	const {
		actions: statisticsActions,
		statistics,
		status: statisticsStatus,
	} = useStatistics();
	const qs = useQueryString();
	const navigate = useNavigate();
	const [payment, setPayment] = useState<ApiPayment>();
	usePolling({
		fn: async () => {
			await getPayment();
			await taskCreditsActions.list();
			await statisticsActions.get();
		},
		interval: 2000,
		enabled:
			!!qs.payment &&
			!["reserved", "completed"].includes(payment?.status || ""),
	});

	const getPayment = async () => {
		if (!qs.payment) return;
		const {
			data: { data: payment },
		} = await axios.get(`/client/payments/${qs.payment}`);
		setPayment(payment);
	};

	useEffect(() => {
		if (qs.payment) {
			getPayment();
		}
		taskCreditsActions.list();
		statisticsActions.get();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<Layout
			breadcrumb={[
				{
					label: t("title"),
					to: "/dashboard",
				},
			]}
		>
			<PaymentListener />
			<div className="flex flex-col flex-1 gap-6 md:gap-16 max-w-[1200px] w-full mx-auto">
				<h1 className="md:text-center">{t("big-title")}</h1>
				<div className="flex md:flex-row flex-col">
					<TotalCount
						title={t("statistics.live.title")}
						loading={statisticsStatus !== "idle"}
						count={statistics?.vacancies?.live}
					/>
					<TotalCount
						title={t("statistics.placeable.title")}
						loading={statisticsStatus !== "idle"}
						count={statistics?.vacancies?.placeable}
					/>

					<div className="flex flex-col flex-1 md:border-y border-border p-3 md:p-8 md:justify-center md:items-center">
						<Button to="/subscription" className="w-max">
							{t("statistics.package.button")}
						</Button>
					</div>
				</div>
				<VacancyForm className="gap-8">
					<AnalyseVacancyForm
						className="md:flex-row gap-4"
						onSuccess={(vacancy) =>
							navigate(
								`/vacancies/create?vacancy_id=${vacancy.id}`
							)
						}
					>
						<Card className="flex-1">
							<p>{t("create.title")}</p>
							<AnalyseVacancyFormField />
							<AnalyseVacancyFormSubmitButton />
						</Card>
					</AnalyseVacancyForm>
				</VacancyForm>
			</div>
		</Layout>
	);
};

interface TotalCountProps {
	count?: number;
	title: string;
	loading: boolean;
}

const TotalCount = ({ count, title, loading }: TotalCountProps) => {
	return (
		<div className="flex flex-col gap-2 flex-1 md:border-y md:text-center md:border-r border-border p-3 md:p-8 md:justify-center md:items-center">
			{loading && count === undefined ? (
				<Skeleton className="h-9 w-24" />
			) : (
				<h2 className="leading-[100%]">{count || 0}</h2>
			)}
			<p>{title}</p>
		</div>
	);
};

RecruitmentDashboard.locale = {
	nl: {
		title: "Mijn Dashboard",
		"big-title": "Welkom bij {{tenant.name}}",
		statistics: {
			live: {
				title: "Open vacatures",
			},
			placeable: {
				title: "Vacatures beschikbaar",
			},
			package: {
				button: "Pakket kopen",
			},
		},
		create: {
			title: "Publiceer je vacature",
		},
	},
	en: {
		title: "My Dashboard",
		"big-title": "Welcome to {{tenant.name}}",
		statistics: {
			live: {
				title: "Live vacancies",
			},
			placeable: {
				title: "Vacancies available",
			},
		},
		create: {
			title: "Publish your vacancy",
		},
	},
};

export default withTranslation(RecruitmentDashboard);
