import React from "react";
import { PopoverContent } from "src/components/ui/popover";

interface ContentProps {
	children?: any;
}

const Content = ({ children }: ContentProps) => {
	return (
		<PopoverContent className="w-full flex gap-3" align="end">
			{children}
		</PopoverContent>
	);
};

export default Content;
