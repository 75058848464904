import moment from "moment";
import { forwardRef, useImperativeHandle, useMemo } from "react";
import Calendar, { useCalendar } from "src/components/Calendar";
import { WeekProps } from "src/components/Calendar/week/Week";
import useElementSize from "src/hooks/useElementSize";
import { ucFirst } from "src/lib/formatters";

interface MonthProps {
	value?: {
		month: number;
		year: number;
	};
	children?: any;
	week?: WeekProps;
}

const Month = forwardRef(({ week: weekProps }: MonthProps, ref: any) => {
	const container = useElementSize();
	const sizer = useElementSize();
	const { selected, layout } = useCalendar();
	const startWeek = selected.value.startOf("month").week();
	const endWeek = selected.value.endOf("month").week() + 1;
	const weeks = endWeek - startWeek;
	const days = useMemo(() => {
		return [
			moment(selected.value).clone().day(1),
			moment(selected.value).clone().day(2),
			moment(selected.value).clone().day(3),
			moment(selected.value).clone().day(4),
			moment(selected.value).clone().day(5),
			...(layout === "full-week"
				? [
						moment(selected.value).clone().day(6),
						moment(selected.value).clone().day(7),
				  ]
				: []),
		].map((date) => ucFirst(date.format("dddd")));
	}, [layout, selected.value]);

	const scrollToNow = () => {
		if (container?.ref?.current) {
			const active = container.ref.current.querySelector(
				`#week-${moment().week()}-${moment().year()}`
			);
			active.scrollIntoView({
				behavior: "smooth",
				block: "start",
				inline: "nearest",
			});
		}
	};

	useImperativeHandle(ref, () => ({
		scrollToNow,
	}));

	return (
		<div className="flex flex-col relative flex-1">
			<div ref={sizer.ref} className="flex-1"></div>
			<div
				className="flex flex-col overflow-scroll hide-default-scrollbar absolute inset-0"
				ref={container.ref}
				style={{
					width: sizer.size.width,
					height: sizer.size.height,
				}}
			>
				<div className="hidden  sm:flex sticky w-full top-0 z-[44]">
					{Array.isArray(days) &&
						(days || []).map((day) => (
							<div
								key={`calendar-${day}`}
								className="flex border-t border-accent flex-1 min-w-[300px] w-[360px] xl:w-full bg-accent-background"
							>
								<p className="p-3">{day}</p>
							</div>
						))}
				</div>
				{new Array(typeof weeks === "number" && weeks > 0 ? weeks : 1)
					.fill("")
					.map((week, index) => (
						<Calendar.Week
							columnVariant="month-child"
							variant="flat"
							className="w-max lg:w-full"
							id={`week-${
								startWeek + index
							}-${selected.value.year()}`}
							key={`week-${startWeek + index}`}
							value={{
								week: startWeek + index,
								year: selected.value.year(),
							}}
							{...weekProps}
						/>
					))}
			</div>
		</div>
	);
});

export default Month;
