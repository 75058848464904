import React from "react";
import { useTranslation } from "react-i18next";
import Input from "src/components/form/Input";
import { OfferEditFormType } from "src/components/offer/forms/edit/OfferEditFormType";
import OfferEditForm from "src/components/offer/forms/OfferEditForm";
import useEditOffer from "src/hooks/api/useEditOffer";
import useOfferScheme from "src/hooks/schemes/useOfferScheme";
import useAuth from "src/hooks/selectors/useAuth";

const OfferTitleDescriptionForm = ({
	id,
	isOpen,
	toggleOpen,
}: OfferEditFormType) => {
	const scheme = useOfferScheme();
	const { t } = useTranslation("offer", {
		keyPrefix: "form",
	});
	const { offer } = useEditOffer(id);
	const auth = useAuth();
	return (
		<OfferEditForm
			title={t("title-description")}
			value={{
				title: offer?.title,
				description_intro:
					offer?.description_intro || offer?.description?.intro,
				description_what:
					offer?.description_what || offer?.description?.what,
				description_how:
					offer?.description_how || offer?.description?.how,
			}}
			schema={() => ({
				title: scheme.title,
				description_intro: scheme.description.intro,
				description_what: scheme.description.what,
				description_how: scheme.description.how,
			})}
			{...{ isOpen, toggleOpen }}
		>
			{(getInputProps) => (
				<>
					<Input
						label={t("project-title")}
						placeholder={t("title.placeholder")}
						{...getInputProps("title")}
					/>
					<Input
						label={t("description.intro")}
						placeholder={t("description.intro-placeholder", {
							name: auth.first_name,
						})}
						multiline
						{...getInputProps("description_intro")}
					/>
					<Input
						label={t("description.what")}
						placeholder={t("description.what-placeholder")}
						multiline
						{...getInputProps("description_what")}
					/>
					<Input
						label={t("description.how")}
						placeholder={t("description.how-placeholder")}
						multiline
						{...getInputProps("description_how")}
					/>
				</>
			)}
		</OfferEditForm>
	);
};

export default OfferTitleDescriptionForm;
