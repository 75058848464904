import React from "react";

interface TimelineDotProps {
	color?: string;
	type: {
		first?: boolean;
		last?: boolean;
	};
}

const TimelineDot = ({ color, type }: TimelineDotProps) => {
	const opacity = 0.5;
	return (
		<div className="flex flex-col justify-center items-center">
			<div
				style={{ opacity: type?.first ? 0 : opacity }}
				className="flex flex-1 dark:bg-dark bg-accent-dark w-1"
			></div>
			<div
				style={{ backgroundColor: color }}
				className={`w-4 h-4 rounded-full block ${
					!color && "border-2 border-border-dark"
				}`}
			></div>
			<div
				style={{ opacity: type?.last ? 0 : opacity }}
				className="flex flex-1 dark:bg-dark bg-accent-dark w-1"
			></div>
		</div>
	);
};

TimelineDot.defaultProps = {
	type: {
		start: true,
		end: true,
	},
};

export default TimelineDot;
