import { useEffect, useState } from "react";
import { ApiUserMinimal } from "src/api/types";
import Avatar from "src/components/Avatar";
import UserAvatars from "src/components/UserAvatars";
import Select, { SelectValue } from "src/components/field/fields/Select";
import { WrapperProps } from "src/components/field/utils/Wrapper";
import withTranslation, {
	Translation,
} from "src/components/hoc/withTranslation";
import useUsers from "src/hooks/api/services/users/useUsers";
import useAuth from "src/hooks/selectors/useAuth";
import { cn } from "src/lib/utils";

interface UsersProps extends Translation {
	wrapper?: WrapperProps;
	value?: SelectValue;
	placeholder?: string;
	onChange: (value?: SelectValue) => void;
	filter?: (option: ApiUserMinimal) => boolean;
	options?: {
		excludeMe?: boolean;
		stayOpen?: boolean;
		isClearable?: boolean;
	};
	trigger?: {
		className?: string;
		arrow?: {
			className?: string;
		};
	};
}

const Users = ({
	wrapper,
	value,
	placeholder,
	onChange,
	filter,
	options: _options,
	trigger,
}: UsersProps) => {
	const auth = useAuth();
	const [search, setSearch] = useState<string>("");
	const { me, users, status, actions } = useUsers();
	let options: ApiUserMinimal[] = [
		me,
		...users.filter(
			(user) =>
				user.id !== auth.id &&
				["ACTIVE", "INVITED"].includes(user.status)
		),
	];

	if (_options?.excludeMe) {
		options = options.filter((user) => user.id !== "me");
	}

	if (filter) {
		options = options.filter(filter);
	}

	useEffect(() => {
		if (status === "idle" && !users.length) {
			actions.list();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	if (users.length === 0) {
		return null;
	}

	return (
		<Select
			{...{ wrapper, placeholder }}
			settings={{
				isClearable: _options?.isClearable,
				stayOpen: _options?.stayOpen,
			}}
			search={{
				value: search,
				onChange: (value) => setSearch(value),
			}}
			value={
				value
					? Array.isArray(value)
						? value
								.map((item) =>
									item === auth.id.toString() ? "me" : item
								)
								.map((item) => item.toString())
						: value.toString() === auth.id.toString()
						? "me"
						: value.toString()
					: undefined
			}
			onChange={(value) => {
				onChange(value ? value : undefined);
			}}
			options={options
				.filter((u) =>
					u.full_name
						.toLocaleLowerCase()
						.includes(search.toLocaleLowerCase())
				)
				.map((user) => ({
					value: user.id.toString(),
					label: <SelectItem variant="list" {...{ user }} />,
				}))}
			theme={{
				item: {
					indicator: true,
				},
				trigger,
				value: {
					renderValue: () => (
						<div className="flex items-center flex-wrap">
							{value && Array.isArray(value) ? (
								<>
									{value.length > 1 ? (
										<UserAvatars
											avatar={{
												size: "xsmall",
											}}
											users={[me, ...users].filter(
												(u) =>
													value?.includes(
														u.id.toString()
													) || value.includes(u.id)
											)}
										/>
									) : (
										<SelectItem
											variant="row"
											user={[me, ...users].find(
												(u) =>
													value?.includes(
														u.id.toString()
													) || value?.includes(u.id)
											)}
										/>
									)}
								</>
							) : (
								<SelectItem
									variant="row"
									user={[me, ...users].find(
										(u: any) =>
											value === u.id.toString() ||
											value === u.id
									)}
								/>
							)}
						</div>
					),
				},
			}}
		/>
	);
};

Users.locale = {
	nl: {
		me: "Ik",
	},
	en: {
		me: "Ik",
	},
};

interface SelectItemProps {
	user?: ApiUserMinimal;
	data?: {
		avatar?: string;
		name?: string;
	};
	variant: "list" | "row";
	className?: string;
}

export const SelectItem = ({
	user,
	data,
	variant,
	className,
}: SelectItemProps) => {
	if (!user && !data) return <></>;

	if (data) {
		return (
			<div className={cn("flex items-center gap-2", className)}>
				<Avatar src={data.avatar} alt={data.name} size="xsmall" />
				<div className="flex-1">
					<span className="font-regular text-sm text-left line-clamp-1">
						{data?.name}
					</span>
				</div>
			</div>
		);
	}

	return (
		<div className={cn("flex items-center gap-2", className)}>
			<Avatar src={user?.avatar} alt={user?.first_name} size="xsmall" />
			<div className="flex-1">
				<span className="font-regular text-sm flex-1 text-left line-clamp-1">
					{user?.first_name || user?.full_name}
				</span>
			</div>
		</div>
	);
};

export default withTranslation(Users);
