import useApi from "src/hooks/api/utils/useApi";

type Options = {
	taskId: string;
};

function useSubtasks(options: Options) {
	const { state, actions } = useApi(
		{ id: `SETTINGS_${options.taskId}` },
		{
			baseUrl: `/client/tasks/${options.taskId}/subtasks`,
			query: {},
		}
	);

	return {
		tasks: state?.list || [],
		status: state.status,
		actions: {
			list: actions.list,
		},
	};
}

export default useSubtasks;
