import Radio from "src/components/form/Radio";
import { cn } from "src/lib/utils";

interface CheckoutPaymentMethodItemProps {
	active: boolean;
	label: string;
	subLabel?: any;
	icon?: string;
	onClick: () => void;
	bg?: string;
	rightIcon?: any;
}

const CheckoutPaymentMethodItem = ({
	active,
	label,
	icon,
	onClick,
	bg,
	rightIcon,
	subLabel,
}: CheckoutPaymentMethodItemProps) => {
	return (
		<div
			{...{ onClick }}
			className={cn(
				`rounded-md pointer p-2 group hover:border-primary flex transition-all cursor-pointer items-center gap-4 border-2 border-border`,
				active && "border-primary"
			)}
		>
			<Radio value={active} variant="light" />
			<div className="mb-0 flex gap-4 items-center flex-1">
				<i className={`${icon}`}></i>
				<div className="flex flex-col">
					<span className="mb-0">{label}</span>
					{subLabel && (
						<small className="opacity-70 leading-[120%]">
							{subLabel}
						</small>
					)}
				</div>
			</div>
			{rightIcon}
		</div>
	);
};

export default CheckoutPaymentMethodItem;
