import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { ApiForm } from "src/api/types";
import Card from "src/components/Card";
import Link from "src/components/Link";
import CustomFormEditForm from "src/components/custom-form/CustomFormEditForm";
import withTranslation, {
	Translation,
} from "src/components/hoc/withTranslation";
import Layout from "src/components/layout/Layout";
import useForm from "src/hooks/api/services/forms/useForm";
import NotFoundView from "src/views/auth/NotFoundView";

const DEFAULT_QUERY = {
	with: ["fields"],
};

const NetworkCustomFormDetailView = ({ t }: Translation) => {
	const params = useParams();
	const { form, actions, status } = useForm({
		rememberId: "CONNECTION",
	});

	useEffect(() => {
		actions.get(params.id, DEFAULT_QUERY);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [params.id]);

	if (!form.id && status === "idle") {
		return <NotFoundView />;
	}

	return (
		<Layout
			loading={(!form.id || form.id !== params.id) && status !== "idle"}
			container="xxl"
			title={form?.name || t("title")}
		>
			<Link
				className="flex items-center gap-2 mb-4"
				to="/settings/network"
			>
				<i className="far fa-arrow-left"></i>
				<span>{t("back")}</span>
			</Link>
			<div className="flex flex-col gap-8">
				<Card contentClassName="gap-6">
					<div className="flex flex-col">
						<strong>{t("fields.title")}</strong>
						<span className="opacity-70">
							{t("fields.description")}
						</span>
					</div>
					<CustomFormEditForm
						{...{ form }}
						type="CONNECTION"
						variant="form-with-preview"
						onUpdate={async (fields) => {
							const result: any = await actions.update(
								{
									id: form.id,
									fields,
								},
								{
									query: DEFAULT_QUERY,
								}
							);
							return result as ApiForm;
						}}
					/>
				</Card>
			</div>
		</Layout>
	);
};

NetworkCustomFormDetailView.locale = {
	nl: {
		title: "Formulier",
		back: "Terug naar instellingen",
		general: { title: "Algemeen" },
		fields: { title: "Velden", description: "Configureer de velden" },
		buttons: {
			save: "Opslaan",
		},
	},
	en: {
		title: "Form",
		back: "Back to settings",
		general: { title: "General" },
		fields: { title: "Fields", description: "Configure the fields" },
		buttons: {
			save: "Save",
		},
	},
};

export default withTranslation(NetworkCustomFormDetailView);
