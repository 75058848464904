import Grid from "src/components/Grid";
import Layout from "src/components/layout/Layout";
import OfferDescriptionCard from "src/components/offer/cards/OfferDescriptionCard";
import OfferShareCard from "src/components/offer/cards/OfferShareCard";
import OfferStatisticsCard from "src/components/offer/cards/OfferStatisticsCard";
import OfferRegistrationList from "src/components/offer/lists/OfferRegistrationList";
import Skeleton from "src/components/Skeleton";

interface OfferDetailGridProps {
	children?: any;
	className?: string;
	gap?: number;
}

const OfferDetailGrid = ({
	children,
	className,
	gap,
}: OfferDetailGridProps) => {
	return (
		<Grid gap={gap !== undefined ? gap : 16} columns={3} {...{ className }}>
			{children}
		</Grid>
	);
};

const Loading = () => (
	<Layout title={<Skeleton className="w-[80%] h-8" />} container="xxl">
		<Layout.Header
			backUrl="/offers"
			title={<Skeleton className="w-40 h-8" />}
		>
			<Skeleton className="w-40 h-8" />
		</Layout.Header>
		<OfferDetailGrid>
			<OfferDescriptionCard.Loading className="col-span-2 row-span-3" />
			<OfferStatisticsCard.Loading />
			<OfferShareCard.Loading className="row-span-2" />
			<div className="col-span-2 mt-4">
				<OfferRegistrationList.Loading />
			</div>
		</OfferDetailGrid>
	</Layout>
);

OfferDetailGrid.Loading = Loading;

export default OfferDetailGrid;
