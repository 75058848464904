import React from "react";
import withTranslation, {
	Translation,
} from "src/components/hoc/withTranslation";
import KeyValue from "src/components/KeyValue";
// import useCalculateServiceFee from "src/hooks/api/tasks/useCalculateServiceFee";
import useTaskForm from "src/hooks/api/tasks/useTaskForm";
import useTenant from "src/hooks/api/services/tenants/useTenant";
import useAuth from "src/hooks/selectors/useAuth";
import { ADVERTISEMENTS_PRICE } from "src/lib/constants";
import { formatPrice } from "src/lib/formatters";
import { SubtaskForm } from "src/redux/taskForm";
import SummaryWrapper from "src/components/tasks/TaskForm/elements/SummaryWrapper";

const CHRISTMAS_GIFT_PRICE = 10;

interface FinancialSummaryProps extends Translation {
	children?: any;
}

const FinancialSummary = ({ t }: FinancialSummaryProps) => {
	const { settings } = useTenant();
	const { task } = useTaskForm();
	const auth = useAuth();
	const hasServiceRate = auth.service_rate > 0;

	const student = task.student;
	const students = task.amount_of_students || 1;

	// const { data: serviceFee } = useCalculateServiceFee(task.budget, students);

	// let total = 0;
	// if (task.budget_per_hour) {
	// 	total = Number(
	// 		Number(task.budget_per_hour) +
	// 			task.budget_per_hour * auth.service_rate
	// 	);
	// } else {
	// 	total =
	// 		(task.budget + task.budget * auth.service_rate) *
	// 		task.amount_of_students;
	// }

	// if (task.advertise_task) {
	// 	total = total + ADVERTISEMENTS_PRICE.tasks;
	// }
	// if (task.add_christmas_gift) {
	// 	total = total + CHRISTMAS_GIFT_PRICE;
	// }

	const renderPrice = (value: number, extra?: any) => (
		<span className="flex min-w-[140px]">
			<span className="flex-1">&euro;</span>
			<span className="">
				{formatPrice(value).replace("€", "")}
				<small>{extra}</small>
			</span>
		</span>
	);

	if (task.subtasks?.length) {
		return (
			<SummaryWrapper title={t("title")}>
				<KeyValue.Small
					label={t("keys.amount-of-students")}
					text={task.subtasks.reduce(
						(amount: number, row: any) =>
							amount + Number(row.amount_of_students),
						0
					)}
				/>
				<KeyValue.Small label={t("keys.shifts")} />
				<div className="pl-4">
					{task.subtasks.map((task: SubtaskForm) => (
						<div key={`sub-${task.title}`} className="">
							<KeyValue.Small
								label={task.title}
								text={
									settings.tasks.budget_per_hour
										? `${formatPrice(
												task.budget_per_hour
										  )} ${t("per-hour")}`
										: formatPrice(task.budget)
								}
							/>
							{/* {hasServiceRate && (
								<KeyValue.Small
									className=" opacity-40"
									label={t("keys.service-fee")}
									text={
										settings.tasks.budget_per_hour
											? `${formatPrice(
													(task.budget_per_hour ||
														0) * auth.service_rate
											  )} ${t("per-hour")}`
											: formatPrice(
													task.budget *
														auth.service_rate
											  )
									}
								/>
							)} */}
						</div>
					))}
				</div>
			</SummaryWrapper>
		);
	}

	return (
		<SummaryWrapper title={t("title")}>
			{!student?.hid && (
				<KeyValue.Small
					label={t("keys.amount-of-students")}
					text={students}
				/>
			)}
			{task.budget && (
				<KeyValue.Small
					label={t("keys.budget")}
					text={renderPrice(task.budget, students > 1 ? " p.p." : "")}
				/>
			)}

			{task.budget_per_hour ? (
				<>
					<KeyValue.Small
						label={t("keys.budget-per-hour")}
						text={renderPrice(task.budget_per_hour)}
					/>
					{hasServiceRate && (
						<KeyValue.Small
							label={t("keys.fee-per-hour")}
							text={renderPrice(
								task.budget_per_hour * auth.service_rate
							)}
						/>
					)}
				</>
			) : (
				<>
					{task?.estimated_duration && (
						<KeyValue.Small
							label={t("keys.estimated-hourly-rate")}
							text={renderPrice(
								task.budget / task.estimated_duration,
								t("keys.per-hour")
							)}
						/>
					)}
					{/* {hasServiceRate && (
						<KeyValue.Small
							label={t("keys.total-service-fee")}
							text={renderPrice(serviceFee?.fee)}
						/>
					)} */}
				</>
			)}
			{task.advertise_task && (
				<KeyValue.Small
					label={t("keys.ad-cost")}
					text={renderPrice(ADVERTISEMENTS_PRICE.tasks)}
				/>
			)}

			{task.add_christmas_gift && (
				<KeyValue.Small
					label={`Kerst donatie🎄`}
					text={renderPrice(CHRISTMAS_GIFT_PRICE)}
				/>
			)}

			{/* <KeyValue.Small
				label={
					task.budget_per_hour
						? t("keys.total-hour-cost")
						: t("keys.total-cost")
				}
				text={renderPrice(total)}
			/> */}
		</SummaryWrapper>
	);
};

FinancialSummary.locale = {
	nl: {
		title: "Financieel",
		"per-hour": "/ uur",
		keys: {
			"amount-of-students": "Aantal professionals",
			budget: "Opdrachtwaarde",
			"service-fee": "Fee",
			"estimated-hourly-rate": "Geschatte uurloon",
			"per-hour": " per uur",
			"fee-per-hour": "Fee per gewerkt uur",
			"budget-per-hour": "Uurloon",
			"total-service-fee": "Totale succesfee",
			"ad-cost": "Adverteerkosten",
			"total-cost": "Totale kosten",
			"total-hour-cost": "Totale kosten per uur",
			shifts: "Shifts",
		},
	},
	en: {
		title: "Financial",
		"per-hour": "/ hour",
		keys: {
			"amount-of-students": "Number of students",
			budget: "Task value",
			"service-fee": "Fee",
			"estimated-hourly-rate": "Estimated hourly rate",
			"per-hour": " per hour",
			"total-service-fee": "Total placement fee",
			"ad-cost": "Advertising cost",
			"total-cost": "Total cost",
			shifts: "Shifts",
		},
	},
};

export default withTranslation(FinancialSummary);
