import moment from "moment";
import { ComponentProps } from "react";
import { useParams } from "react-router-dom";
import withTranslation, {
	Translation,
} from "src/components/hoc/withTranslation";
import Loader from "src/components/Loader";
import PaymentRequestFullForm from "src/components/payment-request/payment-request-form/PaymentRequestFullForm";
import usePaymentRequests, {
	PaymentRequestFormValue,
} from "src/hooks/api/services/payment-requests/usePaymentRequests";
import useTask from "src/hooks/api/services/tasks/task/useTask";
import useGet from "src/hooks/rest/useGet";
import useAuth from "src/hooks/selectors/useAuth";
import useModal from "src/hooks/useModal";
import useQueryString from "src/hooks/useQueryString";

const CreatePaymentRequestModal = ({ t }: Translation) => {
	const qs = useQueryString();
	const auth = useAuth();
	const params = useParams();
	const connectionId = params?.connectionId || qs?.connection_id?.toString();
	const modal = useModal({
		title: t("title"),
	});
	const { task } = useTask({ id: qs?.task_id?.toString() || "" });
	const paymentRequests = usePaymentRequests({ connectionId });

	const onSubmit = async (values: PaymentRequestFormValue) => {
		if (connectionId) {
			if (auth.type === "student") values.company_id = connectionId;
			if (auth.type === "company") values.receiver_id = connectionId;
		}

		//Create the payment request
		await paymentRequests.actions.create(values);
		// //Refresh the list of payment requests
		// paymentRequests.actions.list();
		//Close the modal
		modal.onClose();
	};

	const formatDate = (date?: string) => {
		if (!date) return undefined;
		return moment(date).format("YYYY-MM-DD HH:mm");
	};

	if (qs.task_id) {
		return (
			<TaskMiddleware
				id={qs.task_id.toString()}
				exists={{
					title: t("exists.title"),
					description: t("exists.description"),
				}}
			>
				<PaymentRequestFullForm
					className="min-h-[45vh]"
					defaultValue={
						task
							? {
									start_date: formatDate(task.start_date),
									description: qs?.description
										? qs.description.toString()
										: "",
									type: qs.task_id
										? "HOUR_RATE"
										: "FIXED_FEE",
									from_task_id: task.hid || undefined,
							  }
							: undefined
					}
					{...{ onSubmit, connectionId }}
				/>
			</TaskMiddleware>
		);
	}

	return (
		<PaymentRequestFullForm
			className="min-h-[45vh]"
			defaultValue={
				task
					? {
							start_date: formatDate(task.start_date),
							description: qs?.description
								? qs.description.toString()
								: "",
							type: qs.task_id ? "HOUR_RATE" : "FIXED_FEE",
							from_task_id: task.hid || undefined,
					  }
					: undefined
			}
			{...{ onSubmit, connectionId }}
		/>
	);
};

const TaskMiddleware = (
	props: {
		id: string;
		exists: {
			title: string;
			description: string;
		};
	} & ComponentProps<"div">
) => {
	const [{ data }, loading] = useGet(
		`/payment-requests/check-if-task-exists?from_task_id=${props.id}`
	);

	if (loading) {
		return (
			<div className="flex flex-col justify-center items-center p-8">
				<Loader />
			</div>
		);
	}

	if (!data || data?.exists) {
		return (
			<div className="flex flex-col justify-center items-center p-8 mx-auto max-w-md text-center">
				<strong>{props.exists.title}</strong>
				<p className="opacity-70">{props.exists.description}</p>
			</div>
		);
	}

	return <>{props.children}</>;
};

CreatePaymentRequestModal.locale = {
	nl: {
		title: "Betaalverzoek aanmaken",
		exists: {
			title: "Deze opdracht heeft al een betaalverzoek",
			description:
				"Je hebt al een betaalverzoek voor deze opdracht aangevraagd, het is niet mogelijk om een nieuwe betaalverzoek aan te maken.",
		},
	},
	en: {
		title: "Create payment request",
		exists: {
			title: "This task already has a payment request",
			description:
				"You already have a payment request for this task, it is not possible to create a new payment request.",
		},
	},
};

export default withTranslation(CreatePaymentRequestModal);
