import { ApiTaskKanban } from "src/api/types";
import useApi from "src/hooks/api/utils/useApi";
import useAuth from "src/hooks/selectors/useAuth";

type Options = {
	filter?: any;
};

function useMyTasksInbox(options: Options) {
	const auth = useAuth();
	const { state, actions, api, pagination } = useApi(
		{ id: "MY_TASKS_INBOX", group: "TASKS" },
		{
			baseUrl: `/client/tasks`,
			isAllowed: (item: ApiTaskKanban) =>
				!!(item.links.assignees?.includes(auth.id) && !item.end_date),
			query: {
				recent: 1,
				limit: 6,
				variant: "kanban",
				board: "my-tasks",
				include: ["project-tasks"],
				// date: [options.date],
				date: "overdue",
				status: ["BACKLOG", "TODO"],
				...(options?.filter || {}),
			},
		}
	);

	return {
		tasks: state?.list || [],
		status: state.status,
		filter: state?.filter || null,
		pagination,
		actions: {
			...actions,
		},
		api,
	};
}

export default useMyTasksInbox;
