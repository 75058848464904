import moment from "moment";
import { useTranslation } from "react-i18next";
import { ApiTask } from "src/api/types";
import Badge from "src/components/Badge";
import Card from "src/components/Card";
import ReadMore from "src/components/ReadMore";
import { formatPrice } from "src/lib/formatters";
import { cn } from "src/lib/utils";

interface TaskInfoCardProps {
	task: ApiTask;
	className?: string;
}

const TaskInfoCard = ({ task, className }: TaskInfoCardProps) => {
	const { t } = useTranslation("tasks", {
		keyPrefix: "task-info-card",
	});
	const estimated_duration: any = task?.estimated_duration || 0;
	return (
		<Card className={cn(className)}>
			<div className="flex flex-1 flex-col gap-2">
				<h2>{task?.title}</h2>
				<div className="flex gap-2 my-2 relative items-center">
					<strong className="mb-0 text-primary text-[24px]">
						{formatPrice(task?.budget_per_hour || task.budget)}
					</strong>
					{task.budget_per_hour ? (
						<span>{t("per-hour")}</span>
					) : (
						false
					)}
				</div>
				<ReadMore text={task.description} />

				<p className="mb-0 mt-2">
					<i
						className={`far min-w-[24px] fa-${
							task.is_home ? "map-marker-alt" : "map-marker-alt"
						}`}
					></i>
					{task.is_home ? t("home") : task?.address?.city}
				</p>

				<div className="flex flex-wrap mt-2 gap-2 items-center">
					<Badge variant="gray">{task?.category?.name}</Badge>
					{task?.end_date && (
						<Badge variant="gray">
							{t("deadline")}{" "}
							{moment(task?.end_date).format("D MMMM")}
						</Badge>
					)}
					{estimated_duration > 0 && (
						<Badge variant="gray">
							{Math.round(Number(estimated_duration))} {t("hour")}
						</Badge>
					)}
				</div>
			</div>
		</Card>
	);
};

export default TaskInfoCard;
