import React from "react";
import { useTranslation } from "react-i18next";
import Alert from "src/components/Alert";
import DeleteTaskButton from "src/components/tasks/TaskForm/elements/DeleteTaskButton";
import useTaskForm from "src/hooks/api/tasks/useTaskForm";

const DeleteForm = () => {
	const { t } = useTranslation("forms", {
		keyPrefix: "delete-task-form",
	});
	const { task } = useTaskForm();
	const hasRegistrations = task?.registration_count > 0 ? true : false;
	return (
		<div className="flex flex-col gap-4">
			{hasRegistrations && (
				<Alert
					options={{
						noAnimation: true,
						noIcon: true,
					}}
					type="danger"
				>
					{t("alert")}
				</Alert>
			)}
			<div className="flex flex-col">
				<strong>{t("title")}</strong>
				<p className="mb-4">{t("description")}</p>
				<div className="flex">
					{task?.id && !hasRegistrations && (
						<DeleteTaskButton task={task} />
					)}
				</div>
			</div>
		</div>
	);
};

export default DeleteForm;
