const form = {
	placeholder: {
		search: {
			keyword: "Search by keywords",
		},
	},
	dropdown: {
		empty: {
			description: "No items found",
		},
	},
	"date-of-birth-picker": {
		placeholder: {
			day: "Day",
			month: "Month",
			year: "Year",
		},
	},
	"date-range-picker": {
		placeholder: {
			start: "Start date",
			end: "End date",
		},
	},
	"multi-select-list": {
		placeholder: "Search...",
	},
	"file-upload": {
		loading: "Please wait, this may take a few minutes",
		placeholder: "Click here to upload or drag the file over",
		"placeholder-active": "Place the file here",
		"max-file-size": "Max 10MB",
		failed: {
			description: "The selected file could not be uploaded",
		},
	},
	"form-group": {
		optional: "Optional",
	},
	"category-interest-text": {
		empty: "Select a category to see how many professionals are interested in your job",
		text_one:
			"There is <1>1 professional</1> interested in <3>{{category}}</3>",
		text_other:
			"There are <1>{{count}} professionals</1> interested in <3>{{category}}</3>",
	},
	input: {
		clear: "Clear",
		"date-placeholder": "dd-mm-yyyy",
		telephone: {
			search: {
				placeholder: "Search country...",
			},
		},
	},
};

export default form;
