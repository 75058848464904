import { ApiSearch } from "src/api/types";
import useApi from "src/hooks/api/utils/useApi";

type Options = {
	filter?: any;
};

function useSearch(options?: Options) {
	const { state, actions, api } = useApi(
		{ id: "SEARCH" },
		{
			baseUrl: `/client/search`,
			query: {
				state: "all",
				...(options?.filter || {}),
			},
		}
	);

	const searchList: ApiSearch[] = state?.list || [];

	return {
		searchList,
		status: state.status,
		actions: {
			...actions,
		},
		api,
	};
}

export default useSearch;
