import React from "react";
import Card from "src/components/Card";
import Skeleton from "src/components/Skeleton";

const OfferEditLoading = () => {
	return (
		<>
			<div className="flex items-center gap-4">
				<Skeleton className="w-[38px] h-[38px]" />
				<Skeleton className="h-8 w-full max-w-[220px]" />
				<div className="flex-1"></div>
				<Skeleton className="w-[130px] h-[38px]" />
			</div>
			<div className="container-small flex flex-col gap-4">
				<Card>
					<Skeleton className="h-8 w-[60%] mb-6" />
					<Skeleton className="h-4 w-[30%] mb-2" />
					<Skeleton className="h-8 w-full mb-8" />
					<Skeleton className="h-4 w-[30%] mb-2" />
					<Skeleton className="h-24 w-full mb-8" />
					<Skeleton className="h-4 w-[30%] mb-2" />
					<Skeleton className="h-24 w-full mb-8" />
					<Skeleton className="h-4 w-[30%] mb-2" />
					<Skeleton className="h-24 w-full mb-8" />
				</Card>
				<Card>
					<Skeleton className="h-8 w-[60%]" />
				</Card>
				<Card>
					<Skeleton className="h-8 w-[60%]" />
				</Card>
			</div>
		</>
	);
};

export default OfferEditLoading;
