import React from "react";
import { ApiRegistrationTimesheet } from "src/api/types";
import Avatar from "src/components/Avatar";
import withTranslation, {
	Translation,
} from "src/components/hoc/withTranslation";
import KeyValue from "src/components/KeyValue";
import { formatPrice } from "src/lib/formatters";

interface TimesheetInfoProps extends Translation {
	timesheet: ApiRegistrationTimesheet;
}

const TimesheetInfo = ({ t, timesheet }: TimesheetInfoProps) => {
	const current = timesheet.current;
	return (
		<div className="bg-background p-4 rounded-md">
			<KeyValue.Small
				label={<strong>{t("keys.start") || ""}</strong>}
				text={current.start}
			/>
			<KeyValue.Small
				label={<strong>{t("keys.end") || ""}</strong>}
				text={current.end}
			/>
			<KeyValue.Small
				label={<strong>{t("keys.break.label") || ""}</strong>}
				text={
					t("keys.break.value", {
						count: current.break,
					}) || ""
				}
			/>
			<hr className="border-border-dark my-4" />
			<KeyValue.Small
				label={<strong>{t("keys.total.label") || ""}</strong>}
				text={
					t("keys.total.value", {
						count: current.total,
					}) || ""
				}
			/>
			{timesheet.task?.budget_per_hour && (
				<KeyValue.Small
					label={
						<strong>{t("keys.total-payment.label") || ""}</strong>
					}
					text={formatPrice(
						current.total * timesheet.task?.budget_per_hour
					)}
				/>
			)}

			{(current.creator || current.approver) && (
				<div className="flex gap-4 mt-8 items-center flex-wrapp">
					{current.creator && (
						<div className="flex-1">
							<Avatar.User
								user={current.creator}
								subtitle={t("created-by")}
							/>
						</div>
					)}
					{current.approver && (
						<div className="flex-1">
							<Avatar.User
								user={current.approver}
								subtitle={t("approved-by")}
							/>
						</div>
					)}
				</div>
			)}
		</div>
	);
};

TimesheetInfo.locale = {
	nl: {
		"created-by": "Aangemaakt door",
		"approved-by": "Goedgekeurd door",
		keys: {
			start: "Starttijd",
			end: "Eindtijd",
			break: {
				label: "Pauze",
				value_one: "{{count}} uur",
				value_other: "{{count}} uren",
			},
			total: {
				label: "Totaal",
				value_one: "{{count}} uur",
				value_other: "{{count}} uren",
			},
			"total-payment": {
				label: "Totale bedrag",
			},
		},
	},
	en: {
		"created-by": "Created by",
		"approved-by": "Approved by",
		keys: {
			start: "Start time",
			end: "End time",
			break: {
				label: "Break",
				value_one: "{{count}} hour",
				value_other: "{{count}} hours",
			},
			total: {
				label: "Total",
				value_one: "{{count}} hour",
				value_other: "{{count}} hours",
			},
			"total-payment": {
				label: "Total amount",
			},
		},
	},
};

export default withTranslation(TimesheetInfo);
