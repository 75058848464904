import moment, { Moment } from "moment";
import Confetti from "react-dom-confetti";
import { useNavigate } from "react-router-dom";
import { ApiTaskKanban } from "src/api/types";
import Calendar from "src/components/Calendar";
import Link from "src/components/Link";
import Loader from "src/components/Loader";
import ToggleCompletedIcon from "src/components/dashboard/company/elements/ToggleCompletedIcon";
import withTranslation, {
	Translation,
} from "src/components/hoc/withTranslation";
import Icon from "src/components/icon/Icon";
import TaskKanbanCard from "src/components/tasks/kanban/TaskKanbanCard";
import TaskNewKanbanCard from "src/components/tasks/kanban/TaskNewKanbanCard";
import useMyWeekTasks from "src/hooks/api/services/tasks/useMyWeekTasks";
import useBackUrl from "src/hooks/useBackUrl";
import useNavigateModal from "src/hooks/useNavigateModal";

interface MyWeekTasksCardProps extends Translation {
	children?: any;
	className?: string;
}

const MyWeekTasksCard = ({ t, className }: MyWeekTasksCardProps) => {
	const back = useBackUrl();
	const navigateModal = useNavigateModal();
	const navigate = useNavigate();
	const now = moment();

	const {
		tasks: _tasks,
		pagination,
		showCompletedTasks,
		status,
		actions,
	} = useMyWeekTasks({
		filter: {
			date: [
				moment().startOf("week").format("YYYY-MM-DD"),
				moment().endOf("week").format("YYYY-MM-DD"),
			],
		},
	});

	const tasks = (_tasks || []).map((item) => {
		let end_date = item?.end_date;

		if (end_date && end_date.length === 10) {
			end_date = moment(end_date)
				.set({
					hour: now.get("hour"),
					minute: now.get("minute"),
					second: now.get("second"),
				})
				.format("YYYY-MM-DD HH:mm");
		}

		return {
			...item,
			end_date,
		};
	});

	const onSelectChange = (selected: Moment) => {
		const start = selected.clone().startOf("week");
		const end = selected.clone().endOf("week");
		actions.list(
			{
				date: [start.format("YYYY-MM-DD"), end.format("YYYY-MM-DD")],
			},
			{ appendResult: true }
		);
	};

	const onItemChange = (id: string, value: any) => {
		actions.set((prev: any) => ({
			...(prev || {}),
			status: "idle",
			list: prev.list.map((item: any) => {
				if (item.id === id) {
					return {
						...item,
						end_date: value.date,
					};
				}
				return item;
			}),
		}));

		if (value?.date) {
			actions.update(
				{
					id,
					end_date: value.date,
				},
				{
					updateList: true,
				}
			);
		}
	};

	return (
		<div {...{ className }}>
			<Calendar
				options={{
					scrollToDate: moment(),
				}}
				loading={status !== "idle"}
				className="flex-1 flex flex-col"
				controls={{
					interval: "week",
				}}
				actions={{
					onItemChange,
					onSelectChange,
				}}
				items={(tasks || [])
					.filter((item) => {
						if (!showCompletedTasks) {
							return item.end_date && item.status !== "COMPLETED";
						}
						return item.end_date;
					})
					.sort((a: any, b: any) => {
						return moment(a.end_date).diff(b.end_date);
					})
					.map((task: any) => ({
						id: `MONTH_${task.id}`,
						date: task.end_date,
						data: task,
						render:
							task.mode === "NEW" ? (
								<TaskNewKanbanCard
									onComplete={(task) =>
										task._id &&
										actions.updateNewItem(task._id, task)
									}
									onCancel={(task) =>
										task._id &&
										actions.removeNewItem(task._id)
									}
									{...{ task }}
								/>
							) : (
								<TaskKanbanCard
									head={<TaskKanbanCard.Type />}
									actions={<TaskKanbanCard.Actions />}
									onClick={(e) => {
										if (task.type !== "INTERNAL") {
											navigate(
												`/tasks/${task.hid}/show?${back}`
											);
											return;
										}
										navigateModal(
											`./modals/tasks/${task.hid}`
										);
									}}
									{...{ task }}
								>
									<div className="flex flex-col gap-4">
										<TaskKanbanCard.Title />
										<div className="flex gap-2 justify-between">
											<div className="flex flex-wrap gap-2">
												<TaskKanbanCard.Status />
												<TaskKanbanCard.Tags />
											</div>
											<TaskKanbanCard.Board className="text-sm" />
										</div>
										<TaskKanbanCard.Timer className="right-4 left-auto top-auto bottom-4" />
										<TaskKanbanCard.ProjectLink />
										<TaskKanbanCard.Registrations />
									</div>
								</TaskKanbanCard>
							),
					}))}
				head={{
					actions: (
						<>
							{pagination.page < pagination.last_page && (
								<span
									onClick={() =>
										pagination.setPage(pagination.page + 1)
									}
									className="cursor-pointer flex items-center gap-2 text-sm"
								>
									{status === "loading" && <Loader />}

									{t("load-more")}
								</span>
							)}
							<ToggleCompletedIcon
								name="completed-week"
								onChange={() =>
									actions.setShowCompletedTasks(
										!showCompletedTasks
									)
								}
								value={showCompletedTasks}
							/>
							<Calendar.WeekLayoutSwitcher />
						</>
					),
				}}
			>
				<Calendar.Week
					column={{
						className: "min-w-[360px]",
						head: (date) => (
							<>
								<Progress {...{ tasks, date }} />
								<Link
									to={{
										modal: `./modals/tasks/create?end_date=${date.format(
											"YYYY-MM-DD"
										)}`,
									}}
									className="flex items-center gap-1 text-sm opacity-50 hover:opacity-100 transition-all"
								>
									<Icon
										iconFontSize={12}
										className="w-5 h-5 rounded-sm"
										icon={{
											font_awesome: {
												name: "far fa-plus",
											},
										}}
									/>
								</Link>
							</>
						),
						footer: (date) => (
							<span
								onClick={() =>
									actions.createEditableTask(
										date.format("YYYY-MM-DD")
									)
								}
								className="flex items-center gap-1 text-sm opacity-50 hover:opacity-100 transition-all cursor-pointer"
							>
								<i className="far fa-plus text-[10px]"></i>
								<span className="font-regular">{t("add")}</span>
							</span>
						),
					}}
				/>
			</Calendar>
		</div>
	);
};

interface ProgressProps {
	date: Moment;
	tasks: ApiTaskKanban[];
}

const Progress = ({ date, tasks: _tasks }: ProgressProps) => {
	const tasks = _tasks.filter(
		(item) =>
			moment(item.end_date || item.created_at).format("YYYY-MM-DD") ===
			date.format("YYYY-MM-DD")
	);
	const completed = tasks.filter((item) => item.status === "COMPLETED");

	if (completed.length === 0 && tasks.length === 0) {
		return <></>;
	}

	const percent = (completed.length / tasks.length) * 100;
	return (
		<div className="flex items-center gap-1 relative">
			<Confetti
				config={{
					colors: [
						"#1aaa9d",
						"#ffffff",
						"#4BB543",
						"#f88c5e",
						"#f9b739",
					],
				}}
				active={percent >= 100}
			/>
			<small>{Math.round(percent)}%</small>
			<div className="w-10 h-2 bg-accent flex rounded-full overflow-hidden">
				<div
					style={{
						width: `${percent}%`,
					}}
					className="h-2 bg-primary transition-all"
				></div>
			</div>
		</div>
	);
};

MyWeekTasksCard.locale = {
	nl: {
		add: "Toevoegen",
		"load-more": "Laad meer",
		"upcoming-task": "Deze herhaaltaak wordt om {{time}} aangemaakt",
	},
	en: {
		add: "New",
		"load-more": "Load more",
		"upcoming-task": "This repeated task will be created at {{time}}",
	},
};

export default withTranslation(MyWeekTasksCard);
