import moment from "moment";
import { useTranslation } from "react-i18next";
import { ApiPayment, ApiReview } from "src/api/types";
import Button from "src/components/Button";
import Card from "src/components/Card";
import Link from "src/components/Link";
import Skeleton from "src/components/Skeleton";
import TrackPaymentModal from "src/components/TrackPayment/TrackPaymentModal";
import PaymentBadge from "src/components/payment/PaymentBadge";
import usePaymentStatus from "src/hooks/data/usePaymentStatus";
import useAuth from "src/hooks/selectors/useAuth";
import useBackUrl from "src/hooks/useBackUrl";
import { formatPrice } from "src/lib/formatters";
import RegistrationPaymentUrl from "./RegistrationPaymentUrl";

interface PaymentStatusProps {
	payment: ApiPayment;
	registration: any;
	review?: ApiReview;
	variant: "card" | "flat" | "details";
	className?: string;
}

const PaymentStatus = ({
	payment,
	registration,
	review,
	variant,
	className,
}: PaymentStatusProps) => {
	const label = usePaymentStatus(payment?.status || "pending");
	const { t } = useTranslation("payments", {
		keyPrefix: "payment-status",
	});
	const auth = useAuth();
	const { status } = payment || "";
	const backUrl = useBackUrl();
	const showRefreshButton =
		!payment || ["expired", "cancelled"].includes(status || "");

	if (variant === "details") {
		return (
			<div className={`flex flex-col gap-4 ${className}`}>
				<div className="flex flex-col flex-1 gap-2">
					<div className="flex items-center gap-2 justify-between">
						<strong>{t("price")}</strong>
						<span>{formatPrice(payment.total)}</span>
					</div>
					<div className="flex items-center gap-2 justify-between">
						<strong>{t("status")}</strong>
						<span>
							<PaymentBadge
								status={payment?.status || "created"}
							/>
						</span>
					</div>
					{auth?.type === "company" && (
						<>
							<div className="flex items-center gap-2 justify-between">
								<strong>{t("invoice")}</strong>
								<span>
									{payment?.invoice ? (
										<Link
											className="underline"
											target="_blank"
											to={`/payments/${
												payment?.parent?.hid ||
												payment?.hid
											}?${backUrl}`}
										>
											{payment?.invoice_number}
										</Link>
									) : (
										t("not-created")
									)}
								</span>
							</div>
							<div className="flex items-center gap-2 justify-between">
								<strong>{t("method")}</strong>
								<span>{payment?.type}</span>
							</div>
						</>
					)}
				</div>
				<div className="flex justify-center">
					{!showRefreshButton && (
						<TrackPaymentModal
							button={{
								label: t("track"),
							}}
							{...{ payment }}
						/>
					)}
					{auth.type === "company" &&
						showRefreshButton &&
						payment && (
							<Button
								to={`/payments/${payment?.hid}/refresh?${backUrl}`}
							>
								{t("new")}
							</Button>
						)}
					{auth.type === "company" &&
						showRefreshButton &&
						!payment && (
							<RegistrationPaymentUrl {...{ registration }} />
						)}
				</div>
			</div>
		);
	}

	if (showRefreshButton && variant !== "flat") {
		return (
			<RegistrationPaymentUrl
				{...{ registration }}
				paymentId={payment?.hid}
			/>
		);
	}

	if (variant === "flat") {
		return (
			<div className="flex flex-col gap-3">
				<strong>{t("title")}</strong>
				<div className="flex items-center gap-2 justify-between">
					<strong>{t("status")}</strong>
					<span
						className={`${status === "completed" && "text-green"}`}
					>
						{label}
					</span>
				</div>
				<div className="flex items-center gap-2 justify-between">
					<strong>{t("price")}</strong>
					<span>{formatPrice(payment.total - 1)}</span>
				</div>

				{status !== "completed" && auth.type === "student" && (
					<div className="flex flex-col justify-center items-center mt-3">
						<TrackPaymentModal
							button={{
								label: t("track"),
							}}
							{...{ payment }}
						/>
					</div>
				)}
				{!["reserved", "pending", "completed"].includes(
					payment?.status || ""
				) &&
					auth.type === "company" && (
						<div className="flex flex-col justify-center items-center mt-3">
							<Button
								small
								to={`/payments/${payment.hid}/refresh?${backUrl}`}
							>
								{t("new")}
							</Button>
						</div>
					)}
			</div>
		);
	}

	return (
		<Card contentClassName="flex flex-col justify-center items-center">
			<div className="flex flex-col mb-4 gap-2">
				<p className="mb-0 font-bold text-center">{t("title")}</p>
				<p className="mb-0 text-center">{label}</p>
			</div>
			<div className="flex flex-wrap gap-4">
				{auth.type === "company" &&
					payment?.invoice &&
					status !== "expired" && (
						<Button
							small
							type="dark"
							to={`/payments/${payment.id}`}
							className={`gtm-${auth.type}-dashboard-opdracht-detail-bekijk-betaling`}
						>
							{t("view")}
						</Button>
					)}
				<TrackPaymentModal
					button={{
						label: t("track"),
					}}
					{...{ payment }}
				/>
			</div>
			{review && review?.rating > 0 && payment?.payout_at ? (
				<>
					<small className="mt-4 text-center">
						{t("payout-description", {
							date: moment(payment?.payout_at).format(
								"DD-MM-YYYY"
							),
						})}
					</small>
				</>
			) : (
				false
			)}
		</Card>
	);
};

interface LoadingProps {
	variant: "flat" | "card";
}

const Loading = ({ variant }: LoadingProps) => {
	const { t } = useTranslation("payments", {
		keyPrefix: "payment-status",
	});
	if (variant === "flat") {
		return (
			<div className="flex flex-col gap-3">
				<strong>{t("title")}</strong>
				<div className="flex items-center gap-2 justify-between">
					<Skeleton className="h-5 w-16" />
					<Skeleton className="h-5 w-28" />
				</div>
				<div className="flex items-center gap-2 justify-between">
					<Skeleton className="h-5 w-16" />
					<Skeleton className="h-5 w-28" />
				</div>
				<div className="flex flex-col mt-3">
					<Skeleton className="w-[150px] h-[50px] mx-auto" />
				</div>
			</div>
		);
	}
	return (
		<div className="flex flex-col gap-4 justify-center items-center">
			<Skeleton className="w-2/4 h-4" />
			<Skeleton className="w-1/4 h-4" />
			<Skeleton className="w-3/4 h-[50px]" />
		</div>
	);
};

Loading.defaultProps = {
	variant: "card",
};

PaymentStatus.Loading = Loading;

PaymentStatus.defaultProps = {
	variant: "card",
};

export default PaymentStatus;
