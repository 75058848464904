const offer = {
	"offer-registration-view": {
		title: "Registration",
		breadcrumb: {
			home: "My Offer",
		},
	},
	"offer-create-view": {
		title: "Create Offer",
		verify: {
			title: "Complete your profile",
			description:
				"To share your talents with companies, you need to fully fill out your profile",
			button: "Fill out profile",
		},
		breadcrumb: {
			home: "My Offer",
			create: "Create Offer",
		},
		headers: {
			title: {
				title: "Come up with a catchy title for your project",
				description: "Briefly describe the project",
			},
			description: {
				title: "Describe the project in 3 catchy texts",
				description:
					"The description is built up through an introduction, what you will do for the company and finally how you will do it.",
			},
			visibility: {
				title: "Who may see your task",
				description: "Choose a category and who may see the task.",
			},
			skills: {
				title: "Add some skills",
				description:
					"For example, describe the techniques you will use, this way companies can better find your offer based on tags.",
			},
			budget: {
				title: "Determine the amount & estimated duration.",
				description: "This amount will be credited to your account.",
			},
		},
	},
	"offer-create-finished-view": {
		title: "Offer created",
		breadcrumb: {
			home: "My Offer",
			create: "Create Offer",
			created: "Offer created",
		},
		published: {
			title: "Your offer has been created!",
			description:
				"We have received your offer! A {{tenant.code}} employee will now check your offer, if everything is complete the offer will be approved and put online. When the offer is online, you will find it in the talent board, companies can then respond to your offer.",
		},
		concept: {
			title: "Your offer has been saved!",
			description:
				"You have saved your offer as a concept, so it is not yet available to companies. You can publish this offer later.",
		},
		buttons: {
			view: "View Offer",
			create: "Make another one",
		},
	},
	"offer-detail-view": {
		title: "Offer",
		breadcrumb: {
			home: "My offer",
		},
		alert: {
			published:
				"Your offer is now on its way for approval, once the offer has been approved it will be set live on the website.",
			success:
				"Your offer has been adjusted, a {{tenant.code}} employee has been informed to re-evaluate.",
		},
	},
	"offer-edit-view": {
		title: "Offer",
		breadcrumb: {
			home: "My offer",
		},
	},
};

export default offer;
