import Button from "src/components/Button";
import SearchInput from "src/components/form/SearchInput";
import withTranslation, {
	Translation,
} from "src/components/hoc/withTranslation";
import Icon from "src/components/icon/Icon";
import Layout from "src/components/layout/Layout";
import RegistrationsList from "src/components/tasks/registrations/list/RegistrationsList";
import useRegistrations from "src/hooks/api/services/tasks/registrations/useRegistrations";
import useTenant from "src/hooks/api/services/tenants/useTenant";

const RegistrationsView = ({ t }: Translation) => {
	const { actions, filter } = useRegistrations();
	const { tenant } = useTenant();
	return (
		<Layout
			title={t("title")}
			breadcrumb={[
				{
					label: t("tasks"),
					icon: Icon.getFontAwesome("fa-home"),
					to: "/tasks",
				},
				{
					label: t("title"),
					icon: Icon.getFontAwesome("fa-comment-alt"),
					to: "/tasks/registrations",
				},
			]}
			actions={
				<>
					<SearchInput
						value={filter?.q}
						onChange={(value) =>
							actions.list({
								q: value,
							})
						}
					/>
					<Button
						to={
							tenant.slug === "getcrewdone"
								? tenant.urls.public + "/evenementen"
								: tenant.urls.community
								? tenant.urls.community + "/opdrachten"
								: tenant.urls.public +
								  "/opdrachten/alles/overal"
						}
					>
						<i className="fas fa-search md:mr-2"></i>
						<span className="hidden md:flex">
							{t("buttons.search-task")}
						</span>
					</Button>
				</>
			}
		>
			<RegistrationsList />
		</Layout>
	);
};

RegistrationsView.locale = {
	nl: {
		tasks: "Taken",
		title: "Aanmeldingen",
		buttons: {
			"search-task": "Zoek opdracht",
		},
	},
	en: {
		tasks: "Tasks",
		title: "Registrations",
		buttons: {
			"search-task": "Search task",
		},
	},
};

export default withTranslation(RegistrationsView);
