import { useState } from "react";
import Checkbox from "src/components/form/Checkbox";
import { useTaskKanban } from "src/components/tasks/kanban/TaskKanbanCard";

interface TaskKanbanCardCheckProps {
	onChange: (value: boolean) => Promise<void>;
	value?: boolean;
	className?: string;
}

const TaskKanbanCardCheck = ({ onChange, value }: TaskKanbanCardCheckProps) => {
	const { task } = useTaskKanban();
	const [isLoading, setIsLoading] = useState(false);
	return (
		<Checkbox
			{...{ value }}
			loading={isLoading}
			defaultIcon={
				task.type === "GHOST" ? (
					<i className="fas fa-clock text-[10px] leading-[10px] -mb-[2px] text-primary" />
				) : undefined
			}
			disabled={task.type === "GHOST"}
			button={{
				className: "ring-0 focus:ring-0 w-5 h-5",
			}}
			onChange={(val) => {
				setIsLoading(true);
				onChange(val).finally(() => {
					setIsLoading(false);
				});
			}}
		/>
	);
};

export default TaskKanbanCardCheck;
