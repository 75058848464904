const schemes = {
	required: "Required",
	email: "Invalid email address",
	"min-age": "You must be at least {{age}} years old to use {{tenant.name}}.",
	telephone: "Phone number must have {{length}} characters",
	"min-worth-price": "The minimum value is {{value}}",
	"type-number": "Must be a number",
	"category-unknown": "Category unknown",
	"duration-in-hour": "Duration must be in hours",
	"year-future": "Year is in the future",
	"type-year": "Must be a year",
	"end-date-before-start-date": "End date can't be before start date",
	password:
		"Your password must have at least 8 characters, 1 uppercase & 1 number",
	"email-in-use": "Email is already being used",
	"kvk-in-use": "There is already an account created with this kvk",
	length: "This field must be {expected} characters long",
	number: "This field must be a number",
};

export default schemes;
