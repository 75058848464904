interface TutorialDescriptionProps {
	children?: any;
}

const TutorialDescription = ({ children }: TutorialDescriptionProps) => {
	return (
		<p className="mb-0 opacity-80 text-background-foreground leading-[20px]">
			{children}
		</p>
	);
};

export default TutorialDescription;
