import InboxList from "src/components/lists/InboxList";

interface MyInboxListProps {
	limit: number;
}

const MyInboxList = ({ limit }: MyInboxListProps) => {
	return <InboxList theme="dashboard" {...{ limit }} variant={"light"} />;
};

MyInboxList.defaultProps = {
	limit: 6,
};

export default MyInboxList;
