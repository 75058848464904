import { useTranslation } from "react-i18next";
import { ApiPayment } from "src/api/types";
import FlatList from "src/components/FlatList";
import ListProps from "src/components/lists/ListProps";
import PaymentBadge from "src/components/payment/PaymentBadge";
import Table from "src/components/Table";
import useGet from "src/hooks/rest/useGet";
import useAuth from "src/hooks/selectors/useAuth";
import useWindowSize from "src/hooks/useWindowSize";
import { formatPrice } from "src/lib/formatters";

interface Props extends ListProps {
	payment: ApiPayment;
}

const PaymentUsersList = ({ query, payment }: Props) => {
	const { t } = useTranslation("lists");
	const auth = useAuth();
	const { isPhone } = useWindowSize();
	const [result, loading, { fetchNext, handleSearch }] = useGet(
		`/${auth.type}/payments/${payment.hid}/users?${query || ""}`,
		{ data: [] },
		true
	);

	const { data, hasMore, meta } = result;

	const translations = {
		status: t("headers.status"),
		name: t("headers.name"),
		price: t("headers.price"),
	};

	const headers = [
		translations.status,
		translations.name,
		translations.price,
	];

	if (isPhone) {
		headers.splice(headers.indexOf(translations.status), 1);
	}

	const formatTotalTitle = (total: number) => {
		return t("total", {
			keyPrefix: "payment-users-list",
			count: total || 0,
		});
	};

	return (
		<FlatList
			{...{ loading, hasMore, handleSearch, meta, formatTotalTitle }}
			data={data || []}
			onNext={async () => {
				if (hasMore) await fetchNext();
			}}
			table={{
				headers,
			}}
			empty={{
				title: t("empty.title", {
					keyPrefix: "payment-users-list",
				}),
			}}
			renderItem={(row: any) => (
				<Table.Row
					className={row.needs_attention ? "bg-red-200" : ""}
					key={row.id + row.hid}
				>
					{headers.includes(translations.status) && (
						<Table.Cell className="w-[200px]">
							<PaymentBadge status={row.status} />
						</Table.Cell>
					)}
					<Table.Cell>{row.user?.full_name}</Table.Cell>
					<Table.Cell>{formatPrice(row.total - 1)}</Table.Cell>
				</Table.Row>
			)}
		/>
	);
};

export default PaymentUsersList;
