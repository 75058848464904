const elements = {
	student: {
		"referral-card": {
			title: "Your network is worth money",
			description:
				"Earn €5,- per referred friend or up to €50,- per company",
			buttons: {
				"invite-friends": "Invite friends",
				"invite-companies": "Invite companies",
			},
		},
	},
	"tip-card": {
		title: "💡Tip from the house",
	},
	"payment-balance": {
		empty: {
			title: "Your account is not complete yet",
			description:
				"When you have fully completed your profile, your earnings will be displayed here",
		},
		status: {
			reserved: "Reserved",
			coming: "On the way",
			paid: "Paid out",
		},
	},
};

export default elements;
