import { useNavigate } from "react-router-dom";
import withTranslation, {
	Translation,
} from "src/components/hoc/withTranslation";
import StepsLineResume from "./StepsLineResume";

interface ResumeFormWrapperProps extends Translation {
	children?: any;
}

const ResumeFormWrapper = ({ t, children }: ResumeFormWrapperProps) => {
	const steps = [
		{
			name: "general",
			label: t("steps.general_info"),
			key: ["bio", "basic"],
		},
		{
			name: "education",
			label: t("steps.education"),
			key: "experiences.education",
		},
		{
			name: "work",
			label: t("steps.work_experience"),
			key: "experiences.job",
		},
		{
			name: "skill",
			label: t("steps.skill"),
			key: "skills",
		},
		{
			name: "interests",
			label: t("steps.interests"),
			key: "interests",
		},
	];
	const stepsLabel = t("step");
	const navigate = useNavigate();

	return (
		<div className="flex-1 flex flex-col min-h-[50vh]">
			<div>
				<StepsLineResume
					onClick={(step: any) => {
						navigate(`/profile/resume/create/${step}`);
					}}
					stepsLabel={stepsLabel}
					{...{ steps }}
				/>
			</div>
			<hr className="border-border mt-4" />
			<div className="mt-4 flex flex-col flex-1 gap-4">{children}</div>
		</div>
	);
};

ResumeFormWrapper.locale = {
	nl: {
		steps: {
			general_info: "Algemene informatie",
			education: "Opleidingen",
			work_experience: "Werkervaringen",
			skill: "Vaardigheden",
			interests: "Interesses",
		},
		step: "Stap",
	},
	en: {
		steps: {
			general_info: "General info",
			education: "Educations",
			work_experience: "Work experience",
			skill: "Skills",
			interests: "Interests",
		},
		step: "Step",
	},
};

export default withTranslation(ResumeFormWrapper);
