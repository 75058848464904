import { ApiExperience } from "src/api/types";
import useApi from "src/hooks/api/utils/useApi";
import useAuth from "src/hooks/selectors/useAuth";

type Options = {
	filter?: any;
};

function useEducations(options?: Options) {
	const auth = useAuth();
	const { state, actions, api } = useApi(
		{ id: "EDUCATIONS" },
		{
			baseUrl: `/resume/${auth.id}/experiences`,
			query: {
				state: "all",
				type: "education",
				...(options?.filter || {}),
			},
		}
	);

	const educations: ApiExperience[] = state?.list || [];

	return {
		educations,
		education: state?.data,
		status: state.status,
		actions: {
			...actions,
		},
		api,
	};
}

export default useEducations;
