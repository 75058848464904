import { useEffect } from "react";
import { useParams } from "react-router-dom";
import Loader from "src/components/Loader";
import withTranslation, {
	Translation,
} from "src/components/hoc/withTranslation";
import usePaymentSession from "src/hooks/api/services/session/usePaymentSession";
import useModal from "src/hooks/useModal";

const SessionPaymentSendInvoiceModal = ({ t }: Translation) => {
	useModal({
		title: t("title"),
	});
	const { sessionId } = useParams();
	const { session, actions, status } = usePaymentSession();

	useEffect(() => {
		if (session?.id !== sessionId && sessionId) {
			actions.find(sessionId);
		}
	});

	return (
		<div className="h-[60vh] flex flex-col">
			{status !== "idle" && (
				<div className="flex justify-center items-center flex-1">
					<Loader />
				</div>
			)}
		</div>
	);
};

SessionPaymentSendInvoiceModal.locale = {
	nl: {
		title: "Factuur versturen",
	},
	en: {
		title: "Send invoice",
	},
};

export default withTranslation(SessionPaymentSendInvoiceModal);
