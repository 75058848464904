import { useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";
import Card from "src/components/Card";
import withTranslation, {
	Translation,
} from "src/components/hoc/withTranslation";
import Layout from "src/components/layout/Layout";
import TimesheetProcessResult from "src/components/timesheet/result/TimesheetProcessResult";
import useTimesheets from "src/hooks/api/services/timesheets/useTimesheets";
import useTask from "src/hooks/api/tasks/useTask";

function HoursProcessResultView({ t }: Translation) {
	const location = useLocation();
	const { id } = useParams() as any;
	const { task, actions, status } = useTask();
	const { result, actions: timesheetsActions } = useTimesheets({
		task_id: id,
	});

	useEffect(() => {
		if (!task?.id || task?.hid !== id) {
			actions.get(true, "variant=table&is_hourlist=1&include[]=parent");
		}

		if (!result?.id) {
			timesheetsActions.getProcessResult();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [location]);

	return (
		<Layout
			loading={status !== "idle"}
			title={t("title")}
			backUrl={`/hours/${id}/show/process`}
		>
			<div className="flex items-center flex-col flex-1">
				<Card className="w-full max-w-md" title={t("card.title")}>
					{result?.id && <TimesheetProcessResult {...{ result }} />}
				</Card>
			</div>
		</Layout>
	);
}

HoursProcessResultView.locale = {
	nl: {
		title: "Uren verwerken",
		card: {
			title: "Resultaat",
		},
	},
	en: {
		title: "Process hours",
		card: {
			title: "Result",
		},
	},
};

export default withTranslation(HoursProcessResultView);
