import { useEffect } from "react";
import { ApiExperience } from "src/api/types";
import Button from "src/components/Button";
import FlatList from "src/components/FlatList";
import withTranslation, {
	Translation,
} from "src/components/hoc/withTranslation";
import useWorkExperiences from "src/hooks/api/services/resume/useWorkExperiences";
import useNavigateModal from "src/hooks/useNavigateModal";
import useWindowSize from "src/hooks/useWindowSize";
import { cutString } from "src/lib/formatters";

function WorkExperienceForm({ t }: Translation) {
	const { workExperiences, actions, status } = useWorkExperiences();
	const loading = status === "loading";
	const navigateModal = useNavigateModal();
	const { isPhone } = useWindowSize();

	useEffect(() => {
		actions.list({});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<>
			<FlatList
				empty={{
					title: t(`title`),
					text: t(`description`),
					children: (
						<Button
							className="mt-2"
							type="dark"
							onClick={() =>
								navigateModal(`/profile/resume/create/work`)
							}
						>
							<i className="fas fa-plus mr-2"></i>
							{t("add-first")}
						</Button>
					),
				}}
				data={workExperiences}
				{...{
					loading,
				}}
				fetching={loading}
				renderItem={(item: ApiExperience) => (
					<div
						className="flex justify-between hover:bg-dark-50 group cursor-pointer"
						onClick={() =>
							navigateModal(
								`/profile/resume/create/work/${item.id}`
							)
						}
					>
						<div className="flex-1 flex flex-col gap-1 py-2">
							<div className="flex flex-row items-center">
								{item.title && (
									<strong
										className="max-w-[275px] truncate"
										style={{ lineHeight: "20px" }}
									>
										{cutString(
											item.title,
											isPhone ? 25 : 40
										)}
									</strong>
								)}
								<i className="opacity-0 group-hover:opacity-100 transition-all fa fa-pencil text-[14px] ml-2"></i>
							</div>
							{item.subtitle && (
								<span
									className="max-w-[275px] truncate"
									style={{ lineHeight: "20px" }}
								>
									{cutString(
										item.subtitle,
										isPhone ? 25 : 40
									)}
								</span>
							)}
						</div>
						<div className="flex justify-end items-center">
							{item.title && (
								<span style={{ lineHeight: "20px" }}>
									{item.started} - {item.ended || "Heden"}
								</span>
							)}
						</div>
					</div>
				)}
			/>
			{workExperiences.length > 0 && (
				<div className="flex justify-center">
					<Button
						loading={loading}
						type="ghost"
						small
						className="w-52"
						onClick={() =>
							navigateModal(`/profile/resume/create/work`)
						}
					>
						<i className="fas fa-plus mr-2"></i>
						{t("add")}
					</Button>
				</div>
			)}
			<div className="flex justify-between mt-4">
				<Button
					type="gray"
					small
					// className="w-28"
					to={"/profile/resume/create/education"}
				>
					{t("back")}
				</Button>
				<Button
					type="dark"
					small
					disabled={!workExperiences.length}
					// className="w-40"
					to={"/profile/resume/create/skill"}
				>
					{t("continue")}
				</Button>
			</div>
		</>
	);
}

WorkExperienceForm.locale = {
	nl: {
		title: "Voeg je werkervaring toe",
		description:
			"Voeg hier je professionele werkervaring toe om je carrièregroei en vaardigheden te illustreren.",
		"add-first": "Eerste baan toevoegen",
		add: "Toevoegen",
		back: "Vorige",
		continue: "Doorgaan",
	},
	en: {
		title: "Add your work experience",
		description:
			"Add your professional work experience here to illustrate your career growth and skills.",
		"add-first": "Add first job",
		add: "Add",
		back: "Back",
		continue: "Continue",
	},
};

export default withTranslation(WorkExperienceForm);
