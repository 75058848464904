import { useCookies } from "react-cookie";
import { useNavigate } from "react-router-dom";
import useApi from "src/hooks/api/utils/useApi";
import { createCookie } from "src/hooks/useTokenListener";

const COOKIE_NAME = "auth_session";

type SessionValue = {
	type: "STUDENT" | "COMPANY";
	referral_type: string;
	[key: string]: any;
};

function useAuthSession() {
	const [cookies, _setCookie] = useCookies();
	const navigate = useNavigate();
	const { state, actions } = useApi(
		{ id: "AUTH_SESSION" },
		{
			baseUrl: `/auth/session`,
		}
	);

	//Get the current cookie
	const getCookie = (): string => {
		return cookies[COOKIE_NAME];
	};

	//Set the current session ID
	const setCookie = (id: string) => {
		_setCookie(COOKIE_NAME, id, createCookie());
	};

	const deleteCookie = () => {
		_setCookie(COOKIE_NAME, "", {
			...createCookie(),
			expires: new Date(0),
		});
	};

	// Create / start a new session
	const create = async (value: SessionValue) => {
		const session = await actions.create({
			value,
		});

		actions.set((state: any) => ({
			...state,
			data: session,
		}));

		if (!session?.id) {
			return null;
		}

		setCookie(session.id);

		return session;
	};

	// Find the session by ID
	const find = async (id: string) => {
		const session = await actions.get(id);
		return session;
	};

	// Update the session
	const update = async (id: string, value: SessionValue) => {
		const session = await actions.update({
			id,
			value,
		});
		return session;
	};

	//Redirect the user to the signup page with session id
	const redirect = async (id: string) => {
		//Check if the page is loaded inside of an iframe
		if (window.self !== window.top && window.top) {
			//Navigate the parent window to the signup page
			window.top.location.href = `/signup/${id}`;
			return;
		}
		navigate(`/signup/${id}`);
	};

	return {
		session: state?.data,
		status: state.status,
		actions: {
			create,
			find,
			update,
			setCookie,
			getCookie,
			deleteCookie,
			redirect,
		},
	};
}

export default useAuthSession;
