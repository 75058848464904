import { useState } from "react";
import { useLocation } from "react-router-dom";
import { ApiMessage } from "src/api/types";
import Card from "src/components/Card";
import withTranslation, {
	Translation,
} from "src/components/hoc/withTranslation";
import Indicator from "src/components/layout/Indicator";
import ListItem from "src/components/lists/list-items/ListItem";
import Skeleton from "src/components/Skeleton";
import useUpdateEffect from "src/hooks/useUpdateEffect";
import { formatPrice } from "src/lib/formatters";

interface InboxListItemProps extends Translation {
	url: string;
	user: {
		name: string;
		company_name?: string;
		avatar?: string;
	};
	subject?: string;
	message?: ApiMessage;
	variant: "dark" | "light";
	unread_count: number;
	theme?: "card" | "list-item";
}

const InboxListItem = ({
	t,
	url,
	user,
	subject,
	message,
	unread_count,
	theme = "card",
}: InboxListItemProps) => {
	const location = useLocation();
	const [count, setCount] = useState(unread_count);
	let excerpt = message?.excerpt || message?.content;
	let content = excerpt;

	useUpdateEffect(() => {
		//Set count to 0 if the chat is opened
		if (location.pathname === url) {
			setCount(0);
		}
	}, [location]);

	if (message) {
		if (!content && (message.media || []).length) {
			let count = message?.media?.length || 0;
			excerpt = t("files", { count });
		}

		if (message?.data?.bid) {
			const bid = message?.data?.bid;
			excerpt = t("bid.unknown");
			if (bid?.status === "ACCEPTED") {
				excerpt = t("bid.accepted", {
					price: formatPrice(bid.budget),
				});
			}
			if (bid?.status === "DENIED") {
				excerpt = t("bid.denied", {
					price: formatPrice(bid.budget),
				});
			}

			if (!["DENIED", "ACCEPTED"].includes(bid?.status)) {
				excerpt = t("bid.open", {
					price: formatPrice(bid.budget),
					name: message?.creator?.first_name || "",
				});
			}
		}

		if (excerpt) {
			if (message.is_me) {
				content = `${t("i")}: ${excerpt}`;
			} else {
				if (message.creator) {
					content = `${message?.creator?.first_name}: ${excerpt}`;
				} else {
					content = excerpt;
				}
			}
		}
	}

	const wrapChildren = (children: any) => {
		if (theme === "list-item") return children;

		return (
			<Card
				activeClassName="bg-card"
				className="border-2 border-white"
				to={url}
			>
				{children}
			</Card>
		);
	};

	return wrapChildren(
		<ListItem
			to={theme === "list-item" ? url : undefined}
			bordered
			align="start"
		>
			<ListItem.Avatar size="small" src={user?.avatar} />
			<ListItem.Content className="flex-1 gap-1">
				<div className="flex flex-col">
					<ListItem.Title className="relative flex items-center gap-2">
						<span className="line-clamp-1 leading-full">
							{user?.name}
						</span>
						{user?.company_name && (
							<span className="line-clamp-1">
								@{user.company_name}
							</span>
						)}
						{count > 0 && (
							<Indicator className="relative">{count}</Indicator>
						)}
					</ListItem.Title>
				</div>
				<ListItem.Text className="line-clamp-2" variant="small">
					{content}
				</ListItem.Text>
				{subject && (
					<div className="flex w-max bg-accent rounded-md px-1">
						<ListItem.Text variant="small">
							<p className="line-clamp-1">{subject}</p>
						</ListItem.Text>
					</div>
				)}
			</ListItem.Content>
		</ListItem>
	);
};

InboxListItem.locale = {
	nl: {
		files_one: "1 bestand",
		files_other: "{{count}} bestanden",
		"task-deleted": "Opdracht verwijdert",
		i: "Ik",
		deleted: {
			task: "Verwijderde opdracht",
			offer: "Verwijderde aanmelding",
		},
		bid: {
			unknown: "Prijs wijziging",
			accepted: "De prijs van de opdracht is aangepast naar {{price}}",
			denied: "De prijs van de opdracht is aangepast naar {{price}} is afgewezen",
			open: "{{name}} wilt de prijs wijzigen naar {{price}}",
		},
	},
	en: {
		files_one: "1 file",
		files_other: "{{count}} files",
		"task-deleted": "Task deleted",
		i: "I",
		deleted: {
			task: "Deleted task",
			offer: "Deleted application",
		},
		bid: {
			unknown: "Price change",
			accepted: "The price of the task has been adjusted to {{price}}",
			denied: "The price of the task has been adjusted to {{price}} and rejected",
			open: "{{name}} wants to change the price to {{price}}",
		},
	},
};

export default withTranslation(InboxListItem);

interface InboxListItemSkeletonProps {
	variant: "dark" | "light";
}

export const InboxListItemSkeleton = ({
	variant,
}: InboxListItemSkeletonProps) => {
	return (
		<div
			className={`flex flex-col gap-2 p-4 transition-all rounded-md bg-accent`}
		>
			<div className="flex gap-2">
				<div className="flex justify-center items-center">
					<Skeleton bg="background" className="w-10 h-10" />
				</div>
				<div className="flex flex-1 gap-1 flex-col">
					<Skeleton bg="background" className="w-2/4 h-5" />
					<Skeleton bg="background" className="w-10 h-4" />
				</div>
			</div>
			<div className="flex flex-col">
				<Skeleton bg="background" className="w-full h-4" />
				<Skeleton bg="background" className="w-full h-4 mt-2" />
				<div className="flex justify-end mt-2">
					<Skeleton bg="background" className="w-20 h-4" />
				</div>
			</div>
		</div>
	);
};

InboxListItem.Skeleton = InboxListItemSkeleton;
