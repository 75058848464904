import { useContext, useEffect } from "react";
import Button from "src/components/Button";
import withTranslation, {
	Translation,
} from "src/components/hoc/withTranslation";
import Layout from "src/components/layout/Layout";
import { ModalViewContext } from "src/components/layout/ModalView";
import ProjectFilterForm from "src/components/projects/filter/ProjectFilterForm";
import useProjects from "src/hooks/api/services/projects/useProjects";

function ProjectsFilterView({ t }: Translation) {
	const { actions, filterState } = useProjects();
	const modal = useContext(ModalViewContext);

	useEffect(() => {
		modal.setTitle(t("title"));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const onSubmit = () => {
		actions.set({ filter: filterState })
		modal.onClose()
	}

	return (
		<Layout title={t("title")}>
			<ProjectFilterForm />
			<div className="mt-12 flex flex-col gap-4">
				<Button
					type="dark"
					className="w-full"
					onClick={onSubmit}
				>
					{t("apply")}
				</Button>
			</div>
		</Layout>
	);
}

ProjectsFilterView.locale = {
	nl: {
		title: "Projecten filteren",
		apply: "Toepassen",
	},
	en: {
		title: "Filter projects",
		apply: "Apply",
	},
};

export default withTranslation(ProjectsFilterView);
