import React, { ComponentProps } from "react";
import { ApiUserAvatar, ApiUserMinimal } from "src/api/types";
import Avatar, { AvatarSize } from "src/components/Avatar";
import { cn } from "src/lib/utils";

type User = ApiUserAvatar | ApiUserMinimal;

interface UserAvatarsProps {
	users: User[];
	overlay?: React.ReactElement;
	avatar?: {
		size: AvatarSize;
	};
}

const UserAvatars = ({
	users,
	overlay,
	avatar,
	className,
	...rest
}: UserAvatarsProps & ComponentProps<"div">) => {
	return (
		<div className={cn("flex", className)} {...rest}>
			{users.map((user, index) => {
				if (user.id === "me") {
					return (
						<div
							style={{ zIndex: users.length - index }}
							className={
								index !== 0 ? "-ml-2 relative" : "relative"
							}
							key={`user-${user.hid}`}
						>
							{index === 0 && overlay}
							<Avatar
								size="small"
								defaultIcon={
									user.id === "me" ? "me" : undefined
								}
								src={user.avatar}
								alt={user.full_name}
								{...avatar}
							/>
						</div>
					);
				}

				return (
					<div
						style={{ zIndex: users.length - index }}
						className={index !== 0 ? "-ml-2 relative" : "relative"}
						key={`user-${user.hid}`}
					>
						{index === 0 && overlay}
						<Avatar
							size="small"
							src={user.avatar}
							alt={user.full_name}
							{...avatar}
						/>
					</div>
				);
			})}
		</div>
	);
};

const Loading = () => {
	return (
		<div className="flex">
			{new Array(3).fill("").map((_, index) => (
				<div
					style={{ zIndex: 4 - index }}
					className={cn(
						"rounded-md relative",
						index !== 0 && "-ml-2"
					)}
					key={`user-${index}-loading`}
				>
					<Avatar.Loader size="small" />
				</div>
			))}
		</div>
	);
};

UserAvatars.Loading = Loading;

export default UserAvatars;
