import { Trans, useTranslation } from "react-i18next";
import Switch from "src/components/form/Switch";
import { ADVERTISEMENTS_PRICE } from "src/lib/constants";
import { formatPrice } from "src/lib/formatters";

interface AdvertiseTaskProps {
	task: any;
	onChange: (value: boolean) => void;
}

const AdvertiseTask = ({ task, onChange }: AdvertiseTaskProps) => {
	const { t } = useTranslation("forms", {
		keyPrefix: "advertise-task",
	});
	return (
		<div className="flex justify-between items-center">
			<div className="flex flex-col">
				<p>
					{t("title")}
					<sup>+</sup>
				</p>
				<small className="mt-1">
					<Trans {...{ t }} i18nKey="description">
						Meer kans op een match? Voor
						<strong>
							{{ price: formatPrice(ADVERTISEMENTS_PRICE.tasks) }}
						</strong>
						adverteren wij de opdracht
					</Trans>
				</small>
			</div>
			<Switch value={task.advertise_task} {...{ onChange }} />
		</div>
	);
};

export default AdvertiseTask;
