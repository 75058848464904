import { useEffect } from "react";
import Select from "src/components/field/fields/Select";
import { SelectItem } from "src/components/field/fields/Users";
import { WrapperProps } from "src/components/field/utils/Wrapper";
import useUsers from "src/hooks/api/services/users/useUsers";

interface UserProps {
	isClearable?: boolean;
	wrapper?: WrapperProps;
	value?: number;
	onChange: (value?: number) => void;
}

const User = ({ isClearable, wrapper, value, onChange }: UserProps) => {
	const { me, users, status, actions } = useUsers();
	useEffect(() => {
		if (status === "idle" && !users.length) {
			actions.list();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	if (users.length === 0) {
		return null;
	}

	return (
		<Select
			{...{ wrapper }}
			settings={{
				isClearable,
			}}
			value={value ? value.toString() : undefined}
			onChange={(value) => onChange(value ? Number(value) : undefined)}
			options={users.map((user) => ({
				value: user.id.toString(),
				label: <SelectItem {...{ user }} variant="list" />,
			}))}
			theme={{
				value: {
					renderValue: (value) => (
						<SelectItem
							variant="row"
							user={[me, ...users].find(
								(u) =>
									value?.includes(u.id.toString()) ||
									value?.includes(u.id)
							)}
						/>
					),
				},
			}}
		/>
	);
};

export default User;
