import moment from "moment";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import Avatar from "src/components/Avatar";
import Button from "src/components/Button";
import EmptyState from "src/components/EmptyState";
import withTranslation, {
	Translation,
} from "src/components/hoc/withTranslation";
import PaymentRequestStatus from "src/components/payment-request/elements/PaymentRequestStatus";
import List from "src/components/ui/list";
import usePaymentRequestFinalize from "src/hooks/api/services/payment-requests/usePaymentRequestFinalize";
import { PaymentRequestFilter } from "src/hooks/api/services/payment-requests/usePaymentRequests";
import useNavigateModal from "src/hooks/useNavigateModal";
import { formatPrice } from "src/lib/formatters";

interface PaymentRequestFinalizeTableProps extends Translation {
	children?: any;
	filter?: PaymentRequestFilter;
}

const PaymentRequestFinalizeTable = ({
	t,
	filter,
}: PaymentRequestFinalizeTableProps) => {
	const navigateModal = useNavigateModal();
	const { requests, status, actions, pagination } = usePaymentRequestFinalize(
		{
			filter,
		}
	);
	const location = useLocation();

	useEffect(() => {
		actions.list(filter);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [location, filter]);

	return (
		<List
			data={requests}
			{...{ status }}
			showEmptyList
			keepListWhileLoading
			className="relative flex-1"
		>
			{requests.length === 0 && status === "idle" && (
				<EmptyState.Overlay className="p-4">
					<EmptyState.Title>{t("empty.title")}</EmptyState.Title>
					<EmptyState.Description>
						{t("empty.description")}
					</EmptyState.Description>
				</EmptyState.Overlay>
			)}
			<List.Selected variant="floating-card">
				{(selected, clear) => {
					let params = new URLSearchParams();
					selected.forEach((item: any) => {
						params.append("ids", item);
					});
					return (
						<>
							<small className="hidden md:block">
								{t("selected", {
									count: selected.length,
								})}
							</small>
							<Button
								xsmall
								onClick={() => {
									clear();
									navigateModal(
										`/payment-requests/finalize/review?${params}`
									);
								}}
							>
								<i className="far fa-check"></i>
								{t("buttons.finalize")}
							</Button>
						</>
					);
				}}
			</List.Selected>
			<List.Head>
				<List.Item className="md:pr-0 px-0">
					<div className="pl-2">
						<List.SelectAll dataKey="id" exclude={[]} />
					</div>
				</List.Item>
				<List.Item className="">{t("headers.status")}</List.Item>
				<List.Item>{t(`headers.student`)}</List.Item>
				<List.Item>{t("headers.description")}</List.Item>
				<List.Item>{t(`headers.requests`)}</List.Item>
				<List.Item>{t("headers.price")}</List.Item>
				<List.Item>{t("headers.date")}</List.Item>
			</List.Head>
			<List.Loading count={12} />
			<List.Items>
				{(row) => (
					<List.Row id={row.id}>
						<List.Item className="md:pr-0 px-0">
							<div className="pl-2">
								<List.Select />
							</div>
						</List.Item>
						<List.Item>
							<PaymentRequestStatus status={"FINALIZING"} />
						</List.Item>
						<List.Item>
							<div className="flex gap-3 items-center">
								<Avatar src={row?.receiver?.avatar} />
								<div className="flex flex-col flex-1">
									{row?.receiver?.full_name}
								</div>
							</div>
						</List.Item>
						<List.Item>
							<p className="max-w-[360px]">{row.title}</p>
						</List.Item>
						<List.Item>{row.requests.length}</List.Item>
						<List.Item>
							{row.price ? formatPrice(row.price) : "-"}
						</List.Item>
						<List.Item>
							{moment(row.created_at).format("DD-MM-YYYY")}
						</List.Item>
					</List.Row>
				)}
			</List.Items>

			<List.Pagination
				{...{ pagination }}
				onNavigate={pagination.setPage}
			/>
		</List>
	);
};

PaymentRequestFinalizeTable.locale = {
	nl: {
		selected: "{{count}} geselecteerd",
		empty: {
			title: "Geen verzoeken",
			description: "Alle verzoeken zijn afgerond",
		},
		headers: {
			status: "Status",
			company: "Opdrachtgever",
			student: "Opdrachtnemer",
			requests: "Verzoeken",
			description: "Omschrijving",
			date: "Aangemaakt op",
			price: "Waarde",
		},
		buttons: {
			finalize: "Afronden",
		},
		"missing-merchant":
			"Opdrachtnemer moet zich eerst onboarden bij de betaalprovider.",
	},
	en: {
		selected: "{{count}} selected",
		empty: {
			title: "No requests",
			description: "All requests have been completed",
		},
		headers: {
			status: "Status",
			student: "Contractor",
			company: "Client",
			requests: "Requests",
			description: "Description",
			date: "Created on",
			price: "Value",
		},
		buttons: {
			finalize: "Finalize",
		},
		"missing-merchant":
			"Contractor must first onboard with the payment provider.",
	},
};

export default withTranslation(PaymentRequestFinalizeTable);
