import { ApiTeam } from "src/api/types";
import useApi from "src/hooks/api/utils/useApi";

type Options = {
	filter?: any;
};

function useTeams(options?: Options) {
	const { state, actions, api } = useApi(
		{ id: "TEAMS" },
		{
			baseUrl: `/client/teams`,
			query: {
				...(options?.filter || {}),
			},
		}
	);
	const teams: ApiTeam[] = state?.list || [];

	const deleteTeam = (id: number | string) => {
		return actions.delete(id).then(() => {
			actions.set((state: any) => ({
				...state,
				list: (state?.list || []).filter(
					(item: ApiTeam) => item.id !== id && item.hid !== id
				),
			}));
		});
	};

	return {
		teams,
		status: state.status,
		actions: {
			...actions,
			delete: deleteTeam,
		},
		api,
	};
}

export default useTeams;
