const resume = {
	"preview-view": {
		title: "Mijn cv",
	},
	"edit-avatar-modal": {
		title: "Profielfoto",
	},
	"edit-basic-modal": {
		steps: {
			basic: "Telefoonnummer & geboortedatum",
			address: "Adres",
		},
		buttons: {
			cancel: "Annuleren",
			continue: "Doorgaan",
		},
		title: "Basisgegevens",
		description:
			"Deze gegevens hebben we nodig om later de overeenkomsten voor de opdrachten op te maken. We hebben je woonadres, geboortedatum & telefoonnummer nodig.",
	},
	"edit-experience-modal": {
		job: {
			title: "Werkervaring toevoegen",
			"title-edit": "Werkervaring aanpassen",
			success: "Werk ervaring succesvol toegevoegd",
			form: {
				title: "Bedrijfsnaam",
				subtitle: "Functie",
			},
		},
		education: {
			title: "Opleiding toevoegen",
			"title-edit": "Opleiding aanpassen",
			success: "Opleiding succesvol toegevoegd",
			form: {
				title: "Opleiding",
				subtitle: "School",
			},
		},
		form: {
			started: "Jaar begonnen",
			ended: "Jaar beëindigd (leeg laten indien actief)",
		},
		buttons: {
			edit: "Aanpassen",
			add: "Toevoegen",
			cancel: "Annuleren",
			delete: "Verwijderen",
		},
	},
	"edit-experiences-view": {
		tip: "Beschrijf zo uitgebreid mogelijk waar je hebt gewerkt of welke opleidingen je hebt afgerond. Bedrijven zijn hier dol op!",
		title: "Mijn ervaringen",
		breadcrumb: {
			"my-profile": "Mijn profiel",
			experiences: "Ervaringen",
		},
		education: "Opleidingen / cursussen",
		job: "Werkervaring",
	},
	"edit-extra-view": {
		breadcrumb: {
			"my-profile": "Mijn profiel",
			extra: "Bio, vaardigheden & socials",
		},
		title: "Bio, vaardigheden & socials",
		cards: {
			bio: "Bio",
			skills: "vaardigheden",
			socials: "Social media",
		},
	},
	"edit-interests-modal": {
		title: "Interesses",
		description:
			"Kies een aantal categorieën waar jij interesse in hebt. Niks kunnen vinden? Vul dan zelf in welk gebied jij graag zou willen werken.",
		buttons: {
			cancel: "Annuleren",
			save: "Opslaan",
		},
	},
	"theme-modal": {
		title: "CV thema aanpassen",
		button: "Opslaan",
	},
};

export default resume;
