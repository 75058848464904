import React, { useEffect, useState } from "react";
import useAuth from "src/hooks/selectors/useAuth";

interface ChatFileItemProps {
	file: {
		name: string;
		path: string;
		type: string;
		size: number;
	};
	onDelete: () => void;
}

const ChatFileItem = ({ file, onDelete }: ChatFileItemProps) => {
	const auth = useAuth();
	const bg = "bg-dark-accent text-white";
	const [preview, setPreview] = useState(undefined);
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		if (file.type.startsWith("image")) {
			setLoading(true);
			const reader = new FileReader() as any;
			const url = reader.readAsDataURL(file);
			reader.onloadend = () => {
				setPreview(reader.result);
				setLoading(false);
			};
			return url;
		}
	}, [file]);

	const renderIcon = () => {
		if (!loading && preview) {
			return <img src={preview} alt="" />;
		}

		if (!loading) {
			return <i className="fas fa-file"></i>;
		}

		return <span className="base-loader"></span>;
	};

	return (
		<div
			onClick={onDelete}
			className={`p-2 rounded-md flex w-max cursor-pointer items-center gap-2 ${bg}`}
		>
			<div className="bg-dark text-white flex items-center justify-center rounded-md overflow-hidden w-8 h-8 ">
				{renderIcon()}
			</div>
			<div
				className={`w-full max-w-[200px] overflow-hidden flex relative`}
			>
				<div
					className={`absolute top-0 right-0 bottom-0 w-16 bg-gradient-to-l ${
						auth.type === "company"
							? "from-dark-accent"
							: "from-white"
					}`}
				></div>
				<span className="whitespace-nowrap flex-1">{file.name}</span>
			</div>
			<i className="fas fa-times"></i>
		</div>
	);
};

export default ChatFileItem;
