import { useTranslation } from "react-i18next";
import { ApiContact } from "src/api/types";
import RegistrationSection from "src/components/tasks/panes/registration/RegistrationSection";

interface RegistrationContactDetailsProps {
	contact: ApiContact;
	subject?: string;
}

const RegistrationContactDetails = ({
	contact,
	subject,
}: RegistrationContactDetailsProps) => {
	const { t } = useTranslation("registrations", {
		keyPrefix: "registration-contact-details",
	});
	const telephone = contact.telephone;
	return (
		<div className="flex lg:flex-row flex-col flex-1 gap-6">
			<RegistrationSection className="flex-1" title={t("title")}>
				{contact?.telephone && (
					<div className="flex flex-col gap-1">
						<strong>{t("telephone")}</strong>
						<a className="underline" href={`tel: ${telephone}`}>
							{telephone}
						</a>
					</div>
				)}

				{contact?.email && (
					<div className="flex flex-col gap-1">
						<strong>{t("email")}</strong>
						<a
							className="underline"
							href={`mailto: ${contact?.email}${
								subject ? `?subject=${subject}` : ""
							}`}
						>
							{contact?.email}
						</a>
					</div>
				)}

				{contact?.website && (
					<div className="flex flex-col gap-1">
						<strong>{t("website")}</strong>
						<a
							target="_blank"
							className="underline"
							href={contact.website}
							rel="noreferrer"
						>
							{contact.website}
						</a>
					</div>
				)}
			</RegistrationSection>
		</div>
	);
};

export default RegistrationContactDetails;
