import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import queryString from "query-string";
import "./payment-listener.scss";
import { AnimatePresence, motion } from "framer-motion";
import axios from "axios";
import { useTranslation } from "react-i18next";

const variants = {
	notification: {
		enter: {
			y: 40,
			opacity: 1,
			scale: 1,
		},
		exit: {
			y: -80,
			scale: 0.9,
			opacity: 0,
		},
	},
};

const PaymentListener = () => {
	const location = useLocation();
	const [status, setStatus] = useState("idle");
	const { t } = useTranslation(["payments"]);
	const [message, setMessage] = useState(t("loading") as string);

	useEffect(() => {
		let url = location.search;
		let params = queryString.parse(url);

		const checkPaymentStatus = async () => {
			axios
				.get(`/company/payments/${params.payment}`)
				.then(({ data }: any) => {
					let payment = data?.data || {};
					if (
						payment.status === "pending" &&
						payment.type === "invoice"
					) {
						setStatus("success");
						setMessage(t("invoice-send"));
						setTimeout(() => {
							setStatus("idle");
						}, 2000);
					} else {
						if (
							["completed", "reserved"].includes(
								payment?.status || ""
							)
						) {
							setStatus("success");
							setMessage(t("completed"));
							setTimeout(() => {
								setStatus("idle");
							}, 2000);
						} else {
							if (payment.status === "pending") {
								setTimeout(() => {
									checkPaymentStatus();
								}, 3000);
							} else {
								setStatus("idle");
							}
						}
					}
				});
		};

		if (params.payment) {
			setStatus("loading");
			checkPaymentStatus();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [location.search]);

	return (
		<AnimatePresence>
			{status !== "idle" && (
				<div className="payment-listener">
					<motion.div
						initial="exit"
						animate="enter"
						exit="exit"
						variants={variants.notification}
						className={`payment-notification payment-notification-${status}`}
					>
						{status === "success" ? (
							<div className="icon">
								<i className="fal fa-check"></i>
							</div>
						) : (
							<div className="icon">
								<span className="base-loader"></span>
							</div>
						)}
						<p>{message}</p>
					</motion.div>
				</div>
			)}
		</AnimatePresence>
	);
};

export default PaymentListener;
