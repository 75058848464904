import Button from "src/components/Button";
import withTranslation, {
	Translation,
} from "src/components/hoc/withTranslation";
import Layout from "src/components/layout/Layout";
import SubscriptionFeatureMiddleware from "src/components/middlewares/SubscriptionFeatureMiddleware";
import ProjectsList from "src/components/projects/list/ProjectsList";
import useProjects from "src/hooks/api/services/projects/useProjects";
import useSubscription from "src/hooks/selectors/useSubscription";
import useNavigateModal from "src/hooks/useNavigateModal";
import useWindowSize from "src/hooks/useWindowSize";

function ProjectsView({ t }: Translation) {
	const { isPhone } = useWindowSize();
	const navigateModal = useNavigateModal();
	const { filter, actions, isFiltered, clearFilterState } = useProjects();
	const subscription = useSubscription();

	if (!subscription?.id) {
		return (
			<Layout
				breadcrumb={[
					{
						label: t("title"),
						to: "/projects",
					},
				]}
				title={t("title")}
			>
				<SubscriptionFeatureMiddleware plan="all" />
			</Layout>
		);
	}

	return (
		<Layout
			title={t("title")}
			breadcrumb={[
				{
					label: t("title"),
					to: "/projects",
				},
			]}
			actions={
				<>
					{isFiltered(filter) && (
						<div
							className="cursor-pointer flex gap-2 items-center px-2 transition-all hover:opacity-80"
							onClick={() => {
								actions.resetFilter();
								clearFilterState();
							}}
						>
							<i className="fas fa-times text-sm"></i>
							<span className="font-bold">
								{t("clear-filter")}
							</span>
						</div>
					)}
					<Button
						onClick={() => {
							navigateModal("./filter");
						}}
						type="gray"
						iconOnly={isPhone}
					>
						<i className="fas fa-filter md:mr-2"></i>
						{!isPhone && <span>{t("filter")}</span>}
					</Button>
					<Button
						onClick={() => {
							navigateModal("./create");
						}}
						type="dark"
						iconOnly={isPhone}
					>
						<i className="fas fa-plus md:mr-2"></i>
						{!isPhone && <span>{t("create")}</span>}
					</Button>
				</>
			}
		>
			<ProjectsList />
		</Layout>
	);
}

ProjectsView.locale = {
	nl: {
		title: "Projecten",
		create: "Project",
		filter: "Filteren",
		"clear-filter": "Filter legen",
	},
	en: {
		title: "Projects",
		create: "Project",
		filter: "Filter",
		"clear-filter": "Clear filter",
	},
};

export default withTranslation(ProjectsView);
