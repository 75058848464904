import React from "react";
import { ApiTeam } from "src/api/types";
import Card from "src/components/Card";
import { TeamCardContext } from "src/components/profile/team/team-card/TeamCardContext";
import TeamCardTitle from "src/components/profile/team/team-card/TeamCardTitle";

interface TeamCardProps {
	team?: ApiTeam;
	children?: any;
}

const TeamCard = ({ team, children }: TeamCardProps) => {
	return (
		<TeamCardContext.Provider
			value={{
				team,
			}}
		>
			<Card className=" ">{children}</Card>
		</TeamCardContext.Provider>
	);
};

TeamCard.Title = TeamCardTitle;

export default TeamCard;
