import { Formik } from "formik";
import moment from "moment";
import { ApiExperience } from "src/api/types";
import Button from "src/components/Button";
import Input from "src/components/form/Input";
import withTranslation, {
	Translation,
} from "src/components/hoc/withTranslation";
import useExperiences from "src/hooks/api/services/resume/useExperiences";
import useAlert from "src/hooks/useAlert";
import * as Yup from "yup";

interface EditExperienceFormProps extends Translation {
	experience: ApiExperience;
	type: "job" | "education";
	onSubmit: () => void;
	onDelete: () => void;
}

function EditExperienceForm({
	t,
	experience,
	type,
	onSubmit,
	onDelete,
}: EditExperienceFormProps) {
	const [sendAlert] = useAlert();
	const { actions, status } = useExperiences({ type });

	const scheme = {
		title: Yup.string().required(t("required")),
		subtitle: Yup.string().required(t("required")),
		startYear: Yup.number()
			.required(t("required"))
			.max(moment().year(), t("input.date.year-future"))
			.test(
				"len",
				t("input.date.type-year"),
				(val?: any) =>
					val === undefined || (val && val.toString().length === 4)
			),
		endYear: Yup.number()
			.test(
				"len",
				t("input.date.type-year"),
				(val?: any) =>
					val === undefined || (val && val.toString().length === 4)
			)
			.max(moment().year(), t("input.date.year-future"))
			.when(
				"startYear",
				(started: any, schema: any) =>
					started &&
					schema.min(started, t("end-date-before-start-date"))
			),
	} as const;

	const handleDelete = () => {
		if (!experience.id || status !== "idle") return;
		sendAlert({
			type: "confirm",
			title: t("delete.title"),
			text: t("delete.text"),
			onConfirm: () => {
				actions.delete(experience.id).then(() => {
					onDelete();
				});
			},
		});
	};

	return (
		<Formik
			initialValues={{
				title: experience.title ? experience.title : "",
				subtitle: experience.subtitle ? experience.subtitle : "",
				current: !experience.ended && experience.started ? true : false,
				startYear: experience.started ? experience.started : "",
				endYear: experience.ended ? experience.ended : "",
			}}
			validateOnChange
			validateOnBlur
			enableReinitialize
			validateOnMount
			validationSchema={Yup.object().shape(scheme)}
			onSubmit={(values: any, { setSubmitting }) => {
				actions
					.update(
						{
							id: experience.id,
							title: values.title,
							subtitle: values.subtitle,
							started: values.startYear,
							ended: values.endYear,
						},
						{
							updateList: true,
						}
					)
					.then((data: any) => {
						setSubmitting(false);
						onSubmit();
					});
			}}
		>
			{({
				values,
				errors,
				touched,
				handleChange,
				handleSubmit,
				handleBlur,
				isSubmitting,
				isValid,
			}) => {
				const getField = (
					name: string,
					options?: {
						translationKey?: string;
					}
				) => ({
					name,
					value: values[name],
					valid: values[name] && !errors[name],
					error: errors[name] && touched[name] && errors[name],
					handleChange,
					handleBlur,
					label: t(
						options?.translationKey
							? `${options.translationKey}.label`
							: `input.${name}.label`
					),
					placeholder: t(
						options?.translationKey
							? `${options.translationKey}.placeholder`
							: `input.${name}.placeholder`
					),
				});
				return (
					<form className="flex w-full flex-col gap-4">
						<Input
							autoFocus
							{...getField("title", {
								translationKey: `input.title.${type}`,
							})}
						/>
						<Input
							{...getField("subtitle", {
								translationKey: `input.subtitle.${type}`,
							})}
						/>

						<Input
							noMarginBottom
							type="number"
							{...getField("startYear")}
						/>
						<div className={`${values.current ? "hidden" : ""}`}>
							<Input
								noMarginBottom
								type="number"
								{...getField("endYear")}
							/>
						</div>
						<div className="flex flex-col gap-4 mt-4">
							<Button
								disabled={!isValid}
								loading={
									isSubmitting ||
									status === "deleting" ||
									status === "loading"
								}
								onClick={handleSubmit}
								className="w-full"
								type="dark"
							>
								{experience.id ? t("edit") : t("add")}
							</Button>
							{experience.id && (
								<Button
									disabled={status === "loading"}
									loading={
										status === "loading" ||
										status === "deleting"
									}
									onClick={handleDelete}
									className="w-full"
									type="ghost"
								>
									{t("delete.button")}
								</Button>
							)}
						</div>
					</form>
				);
			}}
		</Formik>
	);
}

EditExperienceForm.locale = {
	nl: {
		add: "Toevoegen",
		edit: "Opslaan",
		delete: {
			button: "Verwijderen",
			title: "Weet je het zeker?",
			text: "Weet je zeker dat je deze ervaring wilt verwijderen",
		},
		input: {
			title: {
				education: {
					label: "Studierichting",
					placeholder: "Studierichting",
				},
				job: {
					label: "Bedrijfsnaam",
					placeholder: "Bedrijfsnaam",
				},
			},
			subtitle: {
				education: {
					label: "School / instituut",
					placeholder: "School / instituut",
				},
				job: {
					label: "Functie",
					placeholder: "Functie",
				},
			},
			startYear: {
				label: "Start jaar",
				placeholder: "Start jaar",
			},
			endYear: {
				label: "Eind jaar",
				placeholder: "Eind jaar",
			},
			date: {
				"year-future": "Het jaartal ligt in de toekomst",
				"type-year": "Moet een jaar zijn",
				start: "Start jaar",
				end: "Eind jaar",
				placeholder: "yyyy",
			},
		},
		switch: {
			label: "Deze opleiding volg ik momenteel",
		},
		required: "Verplicht",
		"end-date-before-start-date": "Eind datum is voor start datum",
	},
	en: {
		add: "Add",
		edit: "Save",
		delete: {
			button: "Delete",
			title: "Are you sure?",
			text: "Are you sure you want to delete this experience?",
		},
		input: {
			title: {
				education: {
					label: "Field of study",
					placeholder: "Field of study",
				},
				job: {
					label: "Company name",
					placeholder: "Company name",
				},
			},
			subtitle: {
				education: {
					label: "School / institution",
					placeholder: "School / institution",
				},
				job: {
					label: "Position",
					placeholder: "Position",
				},
			},
			startYear: {
				label: "Start year",
				placeholder: "Start year",
			},
			endYear: {
				label: "End year",
				placeholder: "End year",
			},
			date: {
				"year-future": "The year is in the future",
				"type-year": "Must be a year",
				start: "Start year",
				end: "End year",
				placeholder: "yyyy",
			},
		},
		switch: {
			label: "I am currently enrolled in this program",
		},
		required: "Required",
		"end-date-before-start-date": "End date is before start date",
	},
};

export default withTranslation(EditExperienceForm);
