import { useState } from "react";
import withTranslation, {
	Translation,
} from "src/components/hoc/withTranslation";
import Select, {
	SelectRecent,
	SelectSettings,
} from "src/components/field/fields/Select";
import { WrapperProps } from "src/components/field/utils/Wrapper";
import Icon from "src/components/icon/Icon";
import useBoards from "src/hooks/api/services/tasks/useBoards";
import useTeams from "src/hooks/api/services/users/useTeams";
import { cutString } from "src/lib/formatters";

interface BoardProps extends Translation {
	wrapper: WrapperProps;
	placeholder?: string;
	value: any;
	onChange: (value?: string) => void;
	settings?: SelectSettings;
	trigger?: {
		className?: string;
		arrow?: {
			className?: string;
		};
	};
	recent?: SelectRecent;
}

const Board = ({
	t,
	wrapper,
	value,
	placeholder,
	onChange,
	settings,
	trigger,
	recent,
}: BoardProps) => {
	const [search, setSearch] = useState<string>("");
	const { teams } = useTeams();
	const { boards } = useBoards();

	return (
		<Select
			{...{ wrapper, placeholder, recent, settings }}
			onChange={(value) => {
				onChange(value);
			}}
			search={{
				value: search,
				onChange: (value) => setSearch(value),
			}}
			value={value as any}
			options={boards
				.filter((board) => board.slug !== "my-tasks")
				.filter((board) =>
					board.name
						.toLocaleLowerCase()
						.includes(search.toLocaleLowerCase())
				)
				.map((board) => ({
					value: board.hid,
					group:
						(board?.team
							? teams.find((team) => team.slug === board.team)
									?.name
							: undefined) || t("general"),
					label: (
						<div className="flex items-center gap-1">
							{board.icon && (
								<Icon
									className="hover:bg-transparent w-6 h-6"
									iconFontSize={12}
									icon={board.icon}
								/>
							)}
							<span className="line-clamp-1">
								{cutString(board?.name, 16)}
							</span>
						</div>
					),
				}))}
			theme={{
				item: {
					indicator: true,
					className: "radix-highlighted:bg-accent",
				},
				trigger,
				value: {
					renderValue: (value) => {
						const item = boards.find((item) => item.hid === value);
						return (
							<div className="flex items-center gap-1">
								{item?.icon && (
									<Icon
										className="hover:bg-transparent w-6 h-6"
										iconFontSize={12}
										icon={item.icon}
									/>
								)}
								<span className="line-clamp-1">
									{cutString(item?.name, 16)}
								</span>
							</div>
						);
					},
				},
			}}
		/>
	);
};

Board.locale = {
	nl: {
		general: "Algemeen",
	},
	en: {
		general: "General",
	},
};

export default withTranslation(Board);
