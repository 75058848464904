import { useEffect } from "react";
import Avatar from "src/components/Avatar";
import Button from "src/components/Button";
import ConnectUserButton from "src/components/ConnectUserButton";
import withTranslation, {
	Translation,
} from "src/components/hoc/withTranslation";
import Loader from "src/components/Loader";
import Modal from "src/components/Modal";
import ResumeDetail from "src/components/resume/resume-detail/ResumeDetail";
import RegistrationDetails from "src/components/tasks/panes/registration/RegistrationDetails";
import useResume from "src/hooks/api/services/resume/useResume";
import useGet from "src/hooks/rest/useGet";
import useModules from "src/hooks/selectors/useModules";
import { cn } from "src/lib/utils";

interface RegistrationDetailModalProps extends Translation {
	registrationId: number | string;
	modal: {
		visible: boolean;
		onClose: () => void;
	};
}

const RegistrationDetailModal = ({
	t,
	registrationId,
	modal,
}: RegistrationDetailModalProps) => {
	const [{ data: registration }] = useGet(
		`/company/registrations/${registrationId}`
	);
	const { modules: tenantModules } = useModules();
	const color = registration?.user?.primary_color;
	const { resume, actions } = useResume({
		rememberKey: registration?.user?.hid,
	});
	const modules = resume?.resume_modules || [];
	const isLoaded = resume.id && registration?.user?.hid === resume?.hid;

	useEffect(() => {
		actions.get(registration?.user?.hid);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [registration]);

	return (
		<Modal
			visible={modal.visible}
			onClose={modal.onClose}
			head="floating"
			size="large"
		>
			{isLoaded &&
			resume.id &&
			registration?.user?.hid === resume?.hid ? (
				<ResumeDetail {...{ resume }}>
					<div className="-mx-8 -mt-8 mb-8">
						<div
							style={{
								backgroundColor: color,
							}}
							className="p-8 py-16"
						></div>
						<div className="px-8 -mt-12">
							<div className="flex flex-col md:flex-row gap-4 md:items-end">
								<Avatar size="large" src={resume.avatar} />
								<div className="flex md:flex-row flex-col justify-between flex-1 md:items-end gap-4 flex-wrap">
									<h3 className="text-left text-xxl flex-col">
										{resume.full_name}
									</h3>

									{(tenantModules || []).includes(
										"student-pool"
									) && (
										<ConnectUserButton
											isConnected={
												registration.is_connected
											}
											user={registration.user}
										>
											{({
												isConnected,
												loading,
												onClick,
											}) => (
												<Button
													type={
														isConnected
															? "green"
															: "border"
													}
													xsmall
													{...{
														onClick,
														loading,
													}}
												>
													<i
														className={cn(
															"fas",
															isConnected
																? "fa-user-check"
																: "fa-user-plus"
														)}
													></i>
													{t("connection", {
														ns: "buttons",
													})}
												</Button>
											)}
										</ConnectUserButton>
									)}
								</div>
							</div>
						</div>
					</div>
					<RegistrationDetails
						variant="vertical"
						{...{ registration }}
					/>

					<div className="my-4"></div>
					{modules.includes("experiences.education") && (
						<Section title={t("sections.education")}>
							<ResumeDetail.Education />
						</Section>
					)}

					{modules.includes("experiences.job") && (
						<Section title={t("sections.job")}>
							<ResumeDetail.Work />
						</Section>
					)}
					{modules.includes("skills") && (
						<Section title={t("sections.skills")}>
							<ResumeDetail.Skills />
						</Section>
					)}

					{modules.includes("social-media") && (
						<Section title={t("sections.social-media")}>
							<ResumeDetail.SocialMedia />
						</Section>
					)}
					{resume.badges.length > 0 && (
						<Section title={t("sections.badges")}>
							<ResumeDetail.Badges />
						</Section>
					)}

					{modules.includes("reviews") && (
						<Section title={t("sections.reviews")}>
							<ResumeDetail.Reviews />
						</Section>
					)}
				</ResumeDetail>
			) : (
				<div className="flex flex-col flex-1 items-center justify-center">
					<Loader />
				</div>
			)}
		</Modal>
	);
};

RegistrationDetailModal.locale = {
	nl: {
		title: "Aanmelding",
		connection: "Connectie",
		sections: {
			education: "Opleidingen & cursussen",
			job: "Werkervaring",
			skills: "Vaardigheden",
			"social-media": "Social media",
			badges: "Badges",
			reviews: "Reviews",
		},
	},
	en: {
		title: "Application",
		connection: "Connection",
		sections: {
			education: "Education & courses",
			job: "Job",
			skills: "Skills",
			"social-media": "Social media",
			badges: "Badges",
			reviews: "Reviews",
		},
	},
};

interface SectionProps {
	title: string;
	children?: any;
}

const Section = ({ title, children }: SectionProps) => {
	return (
		<div className="flex flex-col gap-4">
			<h3>{title}</h3>
			<div className="">{children}</div>
		</div>
	);
};

export default withTranslation(RegistrationDetailModal);
