import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { ApiOffer } from "src/api/types";
import Switch from "src/components/form/Switch";
import usePatch from "src/hooks/rest/usePatch";
import { updateProfile } from "src/redux/auth";

interface OfferAvailableWorkSwitchProps {
	id?: number;
	defaultValue?: boolean;
	type: "all" | "detail";
	onChange?: (offer: ApiOffer) => void;
}

const OfferAvailableWorkSwitch = ({
	id,
	type,
	defaultValue,
	onChange,
}: OfferAvailableWorkSwitchProps) => {
	const { t } = useTranslation("offer", {
		keyPrefix: "offer-available-work-switch",
	});
	const dispatch = useDispatch();
	const [_value, setValue] = useState(defaultValue || false);
	const [update, loading] = usePatch(
		id ? `/student/offers/${id}` : `/auth/profile`
	);
	const labels = {
		all: t("all"),
		detail: t("detail"),
	};

	const handleChange = async (v: boolean) => {
		setValue(v);

		const res = await update({
			is_available: v,
		});

		if (id) {
			dispatch(updateProfile(res.data));
		}
		if (onChange) {
			onChange(res.data);
		}
	};

	return (
		<Switch
			className={`mb-0 ${_value ? "text-green" : ""}`}
			label={labels[type]}
			value={_value}
			onChange={handleChange}
			{...{ loading }}
		/>
	);
};

export default OfferAvailableWorkSwitch;
