import { createAction, handleActions } from "redux-actions";
import { Option } from "src/components/form/Dropdown";

export const updateStudentPool = createAction("UPDATE_STUDENT_POOL");
export const removeStudentPool = createAction("REMOVE_STUDENT_POOL");

export const emptyStudentPoolState = {
	students: [],
	loading: true,
};

type StudentPoolState = {
	students: Option[];
	loading: boolean;
};

const studentPoolReducer = handleActions(
	{
		UPDATE_STUDENT_POOL: (state: StudentPoolState, action: any) => {
			return { ...state, ...action.payload };
		},
		REMOVE_STUDENT_POOL: () => {
			return {
				...emptyStudentPoolState,
			};
		},
	},
	{
		...emptyStudentPoolState,
	}
);
export default studentPoolReducer;
