const lists = {
	headers: {
		status: "Status",
		task: "Taak",
		"created-at": "Aangemaakt op",
		actions: "Acties",
		"invoice-number": "Factuurnummer",
		method: "Methode",
		type: "Type",
		price: "Bedrag",
		description: "Omschrijving",
		company: "Bedrijf",
		name: "Naam",
		"estimated-date": "Uitbetaaldatum",
	},
	"envelope-list": {
		"not-found": "Niet gevonden",
		"empty-open": {
			title: "Er staan geen overeenkomsten open",
			text: "Alle overeenkomsten zijn ondertekend",
		},
		"empty-signed": {
			title: "Geen overeenkomsten gevonden",
			text: "Je hebt nog geen overeenkomsten ondertekend",
		},
		empty: {
			title: "Je hebt nog geen overeenkomsten",
			text: "Overeenkomsten worden aangemaakt wanneer er een overeenkomst is bij een opdracht",
		},
	},
	"payment-list": {
		total_one: "1 betaling gevonden",
		total_other: "{{count}} betalingen gevonden",
		empty: {
			title: "Geen betalingen gevonden",
		},
		task: "Opdracht: ",
	},
	"payment-users-list": {
		total_one: "1 professional gevonden",
		total_other: "{{count}} professionals gevonden",
		empty: {
			title: "Geen professionals gevonden",
		},
	},
	"registration-list": {
		empty: {
			title: "Geen aanmeldingen gevonden",
		},
	},
	"registration-select-list": {
		search: "Zoek op naam of achternaam",
		empty: {
			title: "Geen aanmeldingen gevonden",
		},
	},
	"todo-list": {
		agreements: {
			title: "Overeenkomsten",
			text_one: "1 openstaande overeenkomst",
			text_zero: "Geen openstaande overeenkomsten",
			text_other: "{{count}} openstaande overeenkomsten",
		},
		payments: {
			title: "Betalingen",
			text_one: "1 openstaande betaling",
			text_zero: "Geen openstaande betalingen",
			text_other: "{{count}} openstaande betalingen",
		},
		tasks: {
			title: "Opdrachten",
			text_one: "1 opdracht zonder match",
			text_zero: "Geen opdrachten zonder match",
			text_other: "{{count}} opdrachten zonder match",
		},
		messages: {
			title: "Berichten",
			text_one: "1 ongelezen bericht",
			text_zero: "Alle berichten zijn gelezen",
			text_other: "{{count}} ongelezen berichten",
		},
	},
};

export default lists;
