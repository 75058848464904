import { AnimatePresence, motion } from "framer-motion";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Tabs from "src/components/layout/Tabs";
import useWindowSize from "src/hooks/useWindowSize";
import Link from "src/components/Link";
import TabNavItem from "src/components/layout/tab-nav/TabNavItem";

type TabProps = {
	to: string;
	label: string;
	active?: boolean;
};

type TabNavProps = {
	tabs: TabProps[];
	type?: string;
	variant: "light" | "dark";
};

const TabNav = ({ tabs, type, variant }: TabNavProps) => {
	const gtmTag = (item: any) =>
		` gtm-tab-${type}-nav--${item.label
			.replace(/\s+/g, "-")
			.toLowerCase()}`;
	const size = useWindowSize();
	const location = useLocation();
	const [visible, setVisible] = useState(false);

	useEffect(() => {
		setVisible(false);
	}, [location]);

	if (size.isPhone) {
		const activeTab = tabs.find(
			(t) => t?.to === location.pathname || t.active
		) as any;

		return (
			<div className="flex flex-col flex-1 w-full relative shadow">
				<div
					className={`flex flex-col transition-all ${
						visible ? "rounded-t-md" : "rounded-md"
					} ${
						variant === "dark"
							? "bg-dark text-white"
							: "bg-background text-background-foreground"
					}`}
				>
					<div
						onClick={() => setVisible(!visible)}
						className="px-4 py-2 flex items-center justify-between relative z-20 cursor-pointer"
					>
						<strong>{activeTab?.label}</strong>
						<i
							className={`fas fa-angle-${
								visible ? "up" : "down"
							}`}
						></i>
					</div>
					<AnimatePresence>
						{visible && (
							<motion.div
								className={`flex flex-col z-10 absolute top-[43px] rounded-b-md left-0 right-0 shadow ${
									variant === "dark"
										? "bg-dark text-white"
										: "bg-background text-background-foreground"
								}`}
								initial={{ opacity: 0, y: -20 }}
								exit={{ opacity: 0, y: -20 }}
								animate={{ opacity: 1, y: 0 }}
							>
								{tabs.map((t, index) => {
									if (t?.to === activeTab?.to) {
										return <></>;
									}
									return (
										<Link
											key={`${t?.to}-${index}`}
											to={t?.to}
											className={`border-b-[1px] px-4 py-2 border-border ${gtmTag(
												t
											)}`}
										>
											{t?.label}
										</Link>
									);
								})}
							</motion.div>
						)}
					</AnimatePresence>
				</div>
			</div>
		);
	}

	return (
		<div data-tab-container className="flex flex-col w-full relative">
			<Tabs.Container className="p-0 rounded-none gap-6 bg-transparent dark:bg-transparent">
				{tabs.map((t, index) => (
					<div
						key={`${t.to}-${index}`}
						data-tab-index={index}
						className="flex flex-col relative"
					>
						<TabNavItem
							to={t.to}
							active={t.to === location.pathname || t.active}
							className={gtmTag(t)}
						>
							{t.label}
						</TabNavItem>

						{t.to === location.pathname || t.active ? (
							<motion.div
								className="absolute h-[1.5px] -bottom-0 bg-background-foreground left-0 right-0 rounded-full z-20"
								layoutId="underline"
							></motion.div>
						) : null}
					</div>
				))}
				<div className="absolute bg-border h-[1.5px] left-0 bottom-0 right-0"></div>
			</Tabs.Container>
		</div>
	);
};

TabNav.defaultProps = {
	variant: "light",
};

export default TabNav;
