import { useTranslation } from "react-i18next";
import TagsInput from "src/components/form/TagsInput/TagsInput";
import { OfferEditFormType } from "src/components/offer/forms/edit/OfferEditFormType";
import OfferEditForm from "src/components/offer/forms/OfferEditForm";
import useEditOffer from "src/hooks/api/useEditOffer";
import useOfferScheme from "src/hooks/schemes/useOfferScheme";

const OfferTagsForm = ({ id, isOpen, toggleOpen }: OfferEditFormType) => {
	const { t } = useTranslation("offer", {
		keyPrefix: "form",
	});
	const scheme = useOfferScheme();
	const { offer } = useEditOffer(id);

	return (
		<OfferEditForm
			title={t("skills")}
			value={{
				tags: offer?.tags,
			}}
			schema={() => ({
				tags: scheme.tags,
			})}
			{...{ isOpen, toggleOpen }}
		>
			{(getInputProps) => {
				const props = getInputProps("tags");
				return (
					<TagsInput
						label={t("skills")}
						placeholder={t("skills-placeholder")}
						value={props.value || []}
						onChange={(value) => {
							props.handleChange({
								target: {
									name: "tags",
									value,
								},
							});
						}}
					/>
				);
			}}
		</OfferEditForm>
	);
};

export default OfferTagsForm;
