import { useLocation } from "react-router-dom";
import queryString from "query-string";

export const generateQueryStringFromObject = (query: any) => {
	const params = new URLSearchParams();
	Object.keys(query).forEach((key) => {
		if (query[key]) {
			params.append(key, query[key]);
		}
	});
	return params.toString();
};

const useQueryString = () => {
	const location = useLocation();
	let url = location.search;
	let params = queryString.parse(url);
	return params;
};
export default useQueryString;
