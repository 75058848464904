import { useState } from "react";
import Button from "src/components/Button";
import Input from "src/components/form/Input";
import { ucFirst } from "src/lib/formatters";

interface TagsInputProps {
	value: string[];
	onChange: (value: string[]) => void;
	label?: string;
	placeholder?: string;
}

const TagsInput = ({ value, onChange, label, placeholder }: TagsInputProps) => {
	const [tag, setTag] = useState("");
	const onKeyDown = (e: any) => {
		if (e.key === "Enter") {
			e.preventDefault();
			handleSubmit();
		}
	};

	const unique = (value: string, index: number, self: any) => {
		return self.indexOf(value) === index;
	};
	const checkIfLowercase = (s: string) => s.toLowerCase() === s;
	const handleSubmit = () => {
		if (!tag) return;

		const splitted = tag
			.split(",")
			.map((item) => item.trim())
			.map((item) => (checkIfLowercase(item) ? ucFirst(item) : item));
		if (splitted.length) {
			onChange(
				[...value, ...splitted].filter(unique).filter((item) => item)
			);
		}
		setTag("");
	};

	return (
		<div className="flex-1 flex flex-col gap-4">
			<div className="flex flex-wrap gap-2">
				{(value || []).map((tag: string) => (
					<div
						onClick={() =>
							onChange(value.filter((item) => item !== tag))
						}
						key={tag}
						className="p-2 px-3 cursor-pointer hover:opacity-80 transition-all bg-primary text-white rounded-md flex items-center gap-2"
					>
						<span>{tag}</span>
						<i className="far fa-times"></i>
					</div>
				))}
			</div>

			<div className="flex items-end gap-2">
				<Input
					className="mb-0"
					name="tag"
					value={tag}
					onChange={(_, tag) => setTag(tag)}
					{...{ label, placeholder, onKeyDown }}
					autoFill="tags"
				/>
				<Button
					onClick={handleSubmit}
					className="w-[42px] h-[42px]"
					iconOnly
					type="dark"
				>
					<i className="fas fa-check"></i>
				</Button>
			</div>
		</div>
	);
};

export default TagsInput;
