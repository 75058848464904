import { useLocation, useNavigate } from "react-router-dom";
import Button from "src/components/Button";
import Modal from "src/components/Modal";
import withTranslation, {
	Translation,
} from "src/components/hoc/withTranslation";
import useQueryString from "src/hooks/useQueryString";

const SubscriptionProtectModal = ({ t }: Translation) => {
	const location = useLocation();
	const navigate = useNavigate();
	const qs = useQueryString();
	const visible = qs?.modal === "need-subscription";

	const onClose = () => {
		const root = location.pathname;
		const sp = new URLSearchParams(location.search);
		sp.delete("modal");
		navigate(`${root}${sp ? `?${sp.toString()}` : ""}`);
	};

	return (
		<Modal {...{ onClose, visible }} className=" ">
			<div className="flex flex-col gap-2 max-w-[400px] mb-4">
				<h2 className="text-[22px]">{t("title")}</h2>
				<p dangerouslySetInnerHTML={{ __html: t("description") }}></p>
			</div>
			<div className="flex flex-col gap-2">
				<Button type="dark" to="/subscription">
					{t("buttons.view-subscriptions")}
				</Button>
				<Button type="gray" onClick={onClose}>
					{t("buttons.cancel")}
				</Button>
			</div>
		</Modal>
	);
};

SubscriptionProtectModal.locale = {
	nl: {
		title: "Deze functie is niet beschikbaar",
		description: `Hey! Om deze functie te gebruiken dien je een actief abonnement te hebben. Bekijk via de onderstaande knop de abonnementen of neem contact op met <a class="underline" href="mailto: support@{{tenant.slug}}.nl?subject=Abonnement afsluiten">support@{{tenant.slug}}.nl</a>`,
		buttons: {
			"view-subscriptions": "Abonnementen bekijken",
			cancel: "Annuleren",
		},
	},
	en: {
		title: "This feature is not available",
		description: `Hey! To use this feature you need an active subscription. Use the button to view the subscription or contact <a class="underline" href="mailto: support@{{tenant.slug}}.nl?subject=Subscribe">support@{{tenant.slug}}.nl</a>`,
		buttons: {
			"view-subscriptions": "View subscriptions",
			cancel: "Cancel",
		},
	},
};

export default withTranslation(SubscriptionProtectModal);
