import Button from "src/components/Button";
import Input from "src/components/form/Input";
import withTranslation, {
	Translation,
} from "src/components/hoc/withTranslation";
import useSignup from "src/hooks/api/services/auth/useSignup";

interface CompanyUserFormProps extends Translation {
	value?: any;
	onSubmit: (user: any) => any;
	hide?: string[];
	type?: "company" | "company-invite";
}

const CompanyUserForm = ({
	t,
	value,
	onSubmit: _onSubmit,
	hide,
	type = "company",
}: CompanyUserFormProps) => {
	const { actions, form, validate, status } = useSignup({
		type,
		defaultValue: value,
	});

	const getFormField = (name: string) => {
		return {
			...actions.getField(name),
			noMarginBottom: true,
			placeholder: t(`fields.${name}.placeholder`),
		};
	};

	const onSubmit = (e?: any) => {
		if (e) e.preventDefault();
		if (!validate.isValid) return;

		if (_onSubmit) {
			_onSubmit(form);
		}
	};

	return (
		<form className="flex flex-col gap-4" {...{ onSubmit }}>
			<div className="flex gap-2">
				<Input {...getFormField("first_name")} />
				<Input {...getFormField("last_name")} />
			</div>
			{!(hide || []).includes("email") && (
				<Input {...getFormField("email")} type="email" />
			)}
			<Input {...getFormField("telephone")} type="telephone" />
			<Input {...getFormField("job_title")} />
			<Input {...getFormField("password")} type="password" />

			<Button
				loading={status !== "idle"}
				disabled={!validate.isValid}
				submit
			>
				{t("buttons.signup")}
			</Button>
		</form>
	);
};

CompanyUserForm.locale = {
	nl: {
		fields: {
			first_name: {
				placeholder: "Voornaam",
			},
			last_name: {
				placeholder: "Achternaam",
			},
			email: {
				placeholder: "Email",
			},
			job_title: {
				placeholder: "Functie",
			},
			telephone: {
				placeholder: "Telefoonnummer",
			},
			password: {
				placeholder: "Wachtwoord",
			},
		},

		buttons: {
			signup: "Aanmelden",
		},
	},
	en: {
		fields: {
			first_name: {
				placeholder: "Firstname",
			},
			last_name: {
				placeholder: "Lastname",
			},
			email: {
				placeholder: "Email",
			},
			job_title: {
				placeholder: "Job title",
			},
			telephone: {
				placeholder: "Telephone",
			},
			password: {
				placeholder: "Password",
			},
		},

		buttons: {
			signup: "Signup",
		},
	},
};

export default withTranslation(CompanyUserForm);
