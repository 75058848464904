import { useState, useEffect } from "react";
import useUpdateEffect from "./useUpdateEffect";

function useDebounce(
	defaultValue: any,
	delay = 500,
	callback?: ((q: any) => void) | undefined
) {
	const [value, setValue] = useState(defaultValue);
	const [debouncedValue, setDebounced] = useState(defaultValue);

	useEffect(() => {
		const handler = setTimeout(() => {
			setDebounced(value);
			if (callback) {
				callback(value);
			}
		}, delay);

		return () => {
			clearTimeout(handler);
		};
	}, [callback, delay, value]);

	return [value, setValue, debouncedValue] as [any, (v: any) => void, any];
}

export function useValueDebounce(value: any, delay = 500) {
	const [debouncedValue, setDebounced] = useState("");
	useEffect(() => {
		const handler = setTimeout(() => {
			setDebounced(value);
		}, delay);
		return () => {
			clearTimeout(handler);
		};
	}, [delay, value]);
	return debouncedValue as any;
}

export function useUpdateEffectDebounce(
	value: any,
	delay = 500,
	callback?: (value: any) => void
) {
	const listen = Array.isArray(value) ? [delay, ...value] : [delay, value];
	const [debouncedValue, setDebounced] = useState(value);

	useUpdateEffect(() => {
		const handler = setTimeout(() => {
			setDebounced(value);
			if (callback) {
				callback(value);
			}
		}, delay);
		return () => {
			clearTimeout(handler);
		};
	}, listen);

	return debouncedValue as any;
}

export default useDebounce;
