import moment from "moment";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import Button from "src/components/Button";
import Card from "src/components/Card";
import SubscriptionFeedbackForm, {
	FeedbackValue,
} from "src/components/forms/SubscriptionFeedbackForm";
import withTranslation, {
	Translation,
} from "src/components/hoc/withTranslation";
import Layout from "src/components/layout/Layout";
import PauseSubscriptionMiddleware from "src/components/middlewares/PauseSubscriptionMiddleware";
import useFetchProfile from "src/hooks/api/useFetchProfile";
import usePost from "src/hooks/rest/usePost";
import useSubscription from "src/hooks/selectors/useSubscription";
import useAlert from "src/hooks/useAlert";
import useRedirectToCustomerPortal from "src/hooks/useRedirectToCustomerPortal";

function PausePlanView({ t }: Translation) {
	const { isLoading } = useRedirectToCustomerPortal();
	const [sendAlert] = useAlert();
	const [pauseSubscription, loading] = usePost(
		"/auth/subscriptions/plans/pause"
	);
	const navigate = useNavigate();
	const [feedback, setFeedback] = useState<FeedbackValue>({
		platform: {
			rating: 0,
			feedback: "",
		},
		contact: {
			rating: 0,
			feedback: "",
		},
		return_period: "",
		reason: "",
	});
	const subscription = useSubscription();

	const { loading: fetchingProfile, fetchProfile } = useFetchProfile();

	const isFormValid = () => {
		return (
			feedback.platform.feedback &&
			feedback.contact.feedback &&
			feedback.return_period &&
			feedback.reason
		);
	};

	const handlePause = () => {
		sendAlert({
			type: "confirm",
			title: t("confirm.title"),
			text: t("confirm.description"),
			onConfirm: () => {
				pauseSubscription({
					feedback,
				})
					.then(() => {
						fetchProfile().then(() => {
							navigate(`/dashboard?paused=1`);
						});
					})
					.catch(() => {
						sendAlert({
							title: t("error.title"),
							text: t("error.description"),
						});
					});
			},
		});
	};

	if (!subscription.can_cancel || subscription.cancelable_at) {
		return (
			<Layout
				className="dashboard-profile"
				container="xxl"
				loading={isLoading}
				title={t("title")}
			>
				<Layout.BackButton
					to="/profile/billing/change"
					label={t("back")}
				/>
				<div className="flex flex-col justify-center items-center flex-1">
					<Card contentClassName="text-background-foreground max-w-[620px]">
						<h2 className="mb-4">{t("not-cancelable.title")}</h2>
						<p className="mb-0">
							{t("not-cancelable.description")}
						</p>
					</Card>
				</div>
			</Layout>
		);
	}

	return (
		<Layout
			className="dashboard-profile"
			container="xxl"
			loading={isLoading}
			title={t("title")}
		>
			<Layout.BackButton to="/profile/billing/change" label={t("back")} />

			<div className="flex flex-col items-center flex-1">
				<PauseSubscriptionMiddleware>
					<Card contentClassName="text-background-foreground max-w-[620px] flex flex-col gap-4">
						{!subscription?.is_paused ? (
							<>
								<div className="flex flex-col gap-2">
									<h2 className="mb-4">{t("form.title")}</h2>
									<p>{t("form.description")}</p>
								</div>

								<SubscriptionFeedbackForm
									{...{ feedback }}
									onChange={setFeedback}
								/>

								<div>
									<span>
										{t("form.disclaimer", {
											date: moment(
												subscription.ends_at
											).format("DD-MM-YYYY"),
										})}
									</span>

									{subscription?.plan?.slug ===
										"basic-small" && (
										<small className="mt-2 block text-red">
											<strong>
												{t("form.old-prices")}
											</strong>
										</small>
									)}
								</div>
								<div className="flex mt-8 gap-4 justify-between items-center">
									<Button
										disabled={loading}
										to="/profile/billing/change"
										type="ghost"
									>
										{t("form.cancel")}
									</Button>
									<Button
										type="green"
										disabled={!isFormValid()}
										loading={loading || fetchingProfile}
										onClick={handlePause}
									>
										{t("form.pause")}
									</Button>
								</div>
							</>
						) : (
							<div className="flex flex-col gap-2">
								<h2 className="mb-4">
									{t("already-paused.title")}
								</h2>
								<p>{t("already-paused.description")}</p>
							</div>
						)}
					</Card>
				</PauseSubscriptionMiddleware>
			</div>
		</Layout>
	);
}

PausePlanView.locale = {
	nl: {
		title: "Abonnement pauzeren",
		back: "Terug naar abonnement aanpassen",
		confirm: {
			title: "Weet je het zeker",
			description:
				"Weet je zeker dat je je abonnement bij {{tenant.name}} wilt pauzeren? De opdrachten die al geplaatst zijn worden direct verrekend",
		},
		error: {
			title: "Er is iets misgegaan",
			description: "Probeer het later opnieuw",
		},
		"not-cancelable": {
			title: "Oeps!",
			description:
				"Dit abonnement kan je niet zelf pauzeren. Neem contact op via support@{{tenant.slug}}.nl",
		},
		"already-paused": {
			title: "Je abonnement is gepauzeerd",
			description: "We hebben je feedback ontvangen!",
		},
		form: {
			title: "Wat jammer zeg...",
			description:
				"Wanneer je op de knop pauzeren klikt stoppen wij het abonnement. Wij leren natuurlijk graag, wil je hieronder aangeven waarom onze samenwerking niet meer werkt?",
			placeholder: "Ik wil mijn abonnement opzeggen omdat...",
			disclaimer:
				"Wanneer je pauzeert kan je nog tot: {{date}} gebruik maken van {{tenant.name}}. De openstaande succesfees worden daarna afgeschreven",

			"old-prices":
				"Let op! Je maakt nog gebruik van de oude prijzen, wanneer je opzegt kan je hier later niet meer gebruik van maken.",
			cancel: "Annuleren",
			pause: "Pauzeren",
		},
	},
	en: {
		title: "Pause Subscription",
		back: "Back to Change Subscription",
		confirm: {
			title: "Are you sure",
			description:
				"Are you sure you want to pause your subscription at {{tenant.name}}? The tasks that have already been placed will be immediately charged",
		},
		error: {
			title: "Something went wrong",
			description: "Please try again later",
		},
		"not-cancelable": {
			title: "Oops!",
			description:
				"You can't pause this subscription yourself. Please contact <a>support@{{tenant.slug}}.nl</a>",
		},
		form: {
			title: "What a shame...",
			description:
				"When you click the pause button we will stop the subscription. We always like to learn, would you like to indicate below why our cooperation no longer works?",
			placeholder: "I want to cancel my subscription because...",
			disclaimer:
				"When you pause, you can still use {{tenant.name}} until: {{date}}. The outstanding placement fees will then be charged",

			"old-prices":
				"Note! You are still using the old prices, if you cancel you can no longer use this later.",
			cancel: "Cancel",
			pause: "Pause",
		},
		"already-paused": {
			title: "Your subscription has been paused",
			description: "Thanks for giving us feedback",
		},
	},
};

export default withTranslation(PausePlanView);
