import AnalalyticsLayout from "src/components/analytics/analytics-layout/AnalalyticsLayout";
import withTranslation, {
	Translation,
} from "src/components/hoc/withTranslation";
import Layout from "src/components/layout/Layout";

const SkillsView = ({ t }: Translation) => {
	return (
		<Layout
			breadcrumb={[
				{
					label: t("dashboard"),
					to: "/analytics/dashboard",
				},
				{
					label: t("title"),
					to: "/analytics/skills",
				},
			]}
			title={t("title")}
		>
			<AnalalyticsLayout>{({ date }) => <></>}</AnalalyticsLayout>
		</Layout>
	);
};

SkillsView.locale = {
	nl: {
		dashboard: "Analytics",
		title: "Skills",
	},
	en: {
		dashboard: "Analytics",

		title: "Skills",
	},
};

export default withTranslation(SkillsView);
