const payment = {
	"detail-view": {
		title: "Payment {{invoice_number}}",
		information: "Information",
		invoice: "Invoice",
		students: "Professionals",
		"payment-failed": {
			title: "Payment failed",
			description:
				"Unfortunately, the payment has failed. You can still complete the payment using the button below.",
			button: "Try payment again",
		},
	},

	"refresh-view": {
		title: "",
		error: {
			title: "Something went wrong",
			description:
				"If this problem persists, please contact support@{{tenant.slug}}.nl",
		},
		"invoice-created": {
			title: "The invoice has been created",
			"iframe-title": "Invoice",
		},
		failed: {
			title: "Unfortunately",
			"description-pending": "This payment is currently being processed",
			"description-disabled":
				"No new payment link can be created for this payment. The payment is already complete.",
			button: "Back to payments",
		},
		"account-disabled":
			"You can use your account again after the subscription has been paid",
	},
	"billing-upcoming-view": {
		title: "My subscription",
		back: "Back to subscriptions",
		"invoice-current": {
			title: "Invoice this month",
		},
	},
	"billing-view": {
		title: "My subscription",
		"payment-history": {
			title: "Payments",
		},
	},
	"change-mandate-view": {
		title: "Choose bank link",
		back: "Back to edit",
		header: {
			title: "Bank link",
			description:
				"This link is used for collecting the subscription fees, and if necessary, also for paying out the professional.",
		},
		form: {
			title: "Bank link:",
		},
		error: {
			title: "Something went wrong",
			description:
				"If this problem persists, please contact support@{{tenant.slug}}.nl",
		},
	},
	"change-plan-view": {
		title: "Change Subscription",
		back: "Back to Subscription",
		pause: "Pause Subscription",
		"subscription-head": {
			title: "Subscription",
			subtitle: "Choose a subscription that fits your organization.",
		},
		"mandate-head": {
			title: "Payment Method",
			subtitle:
				"This will be used for paying the subscription but also for the professional's payment.",
		},
	},
	"resume-plan-view": {
		title: "Resume Subscription",
		back: "Back to Change Subscription",
		error: {
			title: "Something went wrong",
			description: "Please try again later",
		},
		card: {
			title: "Resume Subscription",
			description:
				"When you click the resume button, the subscription will be resumed. The next charge will then be on {{date}}",
			cancel: "Cancel",
			resume: "Resume",
		},
	},
};

export default payment;
