import { ComponentProps } from "react";
import TutorialDescription from "src/components/tutorial/elements/TutorialDescription";
import TutorialImage from "src/components/tutorial/elements/TutorialImage";
import TutorialTitle from "src/components/tutorial/elements/TutorialTitle";
import TutorialItem from "src/components/tutorial/elements/TutorialItem";
import Slider from "src/components/slider/Slider";

interface TutorialProps extends ComponentProps<"div"> {
	children: any;
	finishLabel?: string;
	onEndReach: () => void;
}

const Tutorial = ({
	children,
	finishLabel,
	onEndReach,
	...rest
}: TutorialProps) => {
	return (
		<Slider {...{ onEndReach, ...rest }}>
			<Slider.Items>{children}</Slider.Items>
			<div className="flex items-center gap-8 justify-between mt-4">
				<Slider.Button direction="previous" small type="dark" />
				<Slider.Steps className="flex-1 w-full max-w-[300px]" />
				<Slider.Button
					direction="next"
					small
					type="dark"
					endLabel={finishLabel}
				/>
			</div>
		</Slider>
	);
};

Tutorial.Item = TutorialItem;
Tutorial.Image = TutorialImage;
Tutorial.Title = TutorialTitle;
Tutorial.Description = TutorialDescription;

export default Tutorial;
