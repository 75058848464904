import { ApiTag } from "src/api/types";
import useApi from "src/hooks/api/utils/useApi";

type Options = {
	filter?: any;
};

function useTags(options?: Options) {
	const { state, actions, api } = useApi(
		{ id: "TAGS" },
		{
			baseUrl: `/client/tags`,
			query: {
				...(options?.filter || {}),
			},
		}
	);
	const tags: ApiTag[] = state?.list || [];

	const clearSearch = () => {
		actions.set((state: any) => ({
			...state,
			list: [],
			filter: {
				...state.filter,
				q: "",
			},
		}));
	};

	return {
		tags,
		search: state?.filter?.q,
		status: state.status,
		actions: {
			...actions,
			clearSearch,
		},
		api,
	};
}

export default useTags;
