import moment from "moment";
import { useEffect, useState } from "react";
import { ApiTaskKanban } from "src/api/types";
import Button from "src/components/Button";
import Loader from "src/components/Loader";
import Field from "src/components/field/Field";
import withTranslation, {
	Translation,
} from "src/components/hoc/withTranslation";
import DayPicker from "src/components/tasks/repeatable-task/DayPicker";
import IntervalDropdown from "src/components/tasks/repeatable-task/IntervalDropdown";
import useRepeatTasks from "src/hooks/api/services/tasks/useRepeatTasks";

interface RepeatableTaskFormProps extends Translation {
	taskId: string;
	actions?: {
		onComplete?: () => void;
		onDelete?: () => void;
	};
}

const RepeatableTaskForm = ({
	t,
	taskId,
	actions: actionsProps,
}: RepeatableTaskFormProps) => {
	const { status, actions } = useRepeatTasks();
	const [isFetching, setIsFetching] = useState(true);
	const [form, setForm] = useState<any>({
		id: undefined,
		interval: undefined,
		days: [],
		start_at: moment().format("YYYY-MM-DD"),
		time: moment().add("hours", 1).format("HH:mm"),
	});

	useEffect(() => {
		actions
			.get(taskId)
			.then((data: ApiTaskKanban | any) => {
				if (data?.repeat) {
					setForm({
						id: data.repeat.id,
						interval: data.repeat.interval,
						days: data.repeat.days,
						start_at: moment(data.repeat.start_at).format(
							"YYYY-MM-DD"
						),
						time: moment(data.repeat.start_at).format("HH:mm"),
					});
				}
			})
			.finally(() => setIsFetching(false));
		return () => {
			setForm({
				id: undefined,
				interval: undefined,
				days: [],
				start_at: moment().format("YYYY-MM-DD"),
				time: moment().add("hours", 1).format("HH:mm"),
			});
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [taskId]);

	const isValid = () => {
		if (form.interval === "") {
			return (
				form.interval && form.days.length && form.start_at && form.time
			);
		}
		return form.interval && form.start_at && form.time;
	};

	const onSubmit = (event?: any) => {
		event.preventDefault();
		if (!isValid()) return;
		actions
			.upsert({
				task_id: taskId,
				days: form.days,
				interval: form.interval,
				start_at: moment(form.start_at)
					.set({
						hour: moment(form.time, "HH:mm").hour(),
						minutes: moment(form.time, "HH:mm").minutes(),
					})
					.format("YYYY-MM-DD HH:mm"),
			})
			.then((item) => {
				actions.setItem({ data: item }, { updateList: true });
				setForm({
					id: undefined,
					interval: undefined,
					days: [],
					start_at: moment().format("YYYY-MM-DD"),
					time: moment().add("hours", 1).format("HH:mm"),
				});
				if (actionsProps?.onComplete) {
					actionsProps.onComplete();
				}
			});
	};

	if (isFetching) {
		return (
			<div className="flex flex-col justify-center items-center flex-1 p-8">
				<Loader />
			</div>
		);
	}

	return (
		<form {...{ onSubmit }} className="flex flex-col gap-4">
			<IntervalDropdown
				label={t("fields.interval.label")}
				onChange={(value) => setForm({ ...form, interval: value })}
				value={form.interval}
			/>
			{form.interval === "WEEKLY" && (
				<DayPicker
					onChange={(value) => setForm({ ...form, days: value })}
					value={form.days}
				/>
			)}

			<hr className="border-border" />
			<Field.Date
				wrapper={{
					label: t("fields.start.label"),
					layout: "horizontal",
				}}
				value={form.start_at}
				onChange={(value) => setForm({ ...form, start_at: value })}
			/>
			<Field.Input
				wrapper={{
					label: t("fields.time.label"),
					layout: "horizontal",
				}}
				type="time"
				placeholder={t("fields.time.placeholder")}
				value={form.time}
				onChange={(value) => setForm({ ...form, time: value })}
			/>
			<div className="flex flex-col gap-4 mt-4">
				<Button
					disabled={!isValid() || status !== "idle"}
					loading={status === "creating"}
					submit
					type="dark"
				>
					{t("save")}
				</Button>

				{form.id && (
					<Button
						disabled={status !== "idle"}
						onClick={() => {
							actions.delete(form.id).then(() => {
								actions.set((state: any) => ({
									...state,
									list: state.list.filter(
										(item: any) => item.hid !== taskId
									),
								}));
								if (actionsProps?.onDelete) {
									actionsProps?.onDelete();
								}
							});
						}}
						type="gray"
						loading={status === "deleting"}
					>
						{t("delete")}
					</Button>
				)}
			</div>
		</form>
	);
};

RepeatableTaskForm.locale = {
	nl: {
		fields: {
			start: {
				label: "Startdatum",
			},
			interval: { label: "Wanneer" },
			time: {
				label: "Aanmaaktijd",
				placeholder: "uu:mm",
			},
		},
		save: "Opslaan",
		delete: "Verwijder",
	},
	en: {
		fields: {
			start: {
				label: "Startdate",
			},
			interval: { label: "When" },
			time: {
				label: "Create time",
				placeholder: "hh:mm",
			},
		},
		save: "Save",
		delete: "Delete",
	},
};

export default withTranslation(RepeatableTaskForm);
