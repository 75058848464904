import React from "react";
import { useTranslation } from "react-i18next";
import { ApiEnvelopeStatus } from "src/api/types";
import Badge from "src/components/Badge";
import useAuth from "src/hooks/selectors/useAuth";
import { ENVELOPE_STATUSES } from "src/lib/constants";

interface EnvelopeStatusProps {
	status: ApiEnvelopeStatus;
}

const EnvelopeStatus = ({ status }: EnvelopeStatusProps) => {
	const auth = useAuth();
	const { icon, color } = ENVELOPE_STATUSES[status];
	const { t } = useTranslation("envelope", {
		keyPrefix: "status",
	});
	const label = t(status);

	return (
		<Badge
			bg={
				auth.type === "student" && color.bg === "gray"
					? "white"
					: color.bg
			}
			color={color.bg === "green" ? "white" : color.color}
		>
			{icon && <i className={`${icon}`}></i>}
			<span>{label}</span>
		</Badge>
	);
};

export default EnvelopeStatus;
