import { useTranslation } from "react-i18next";
import Field from "src/components/field/Field";
import Input from "src/components/form/Input";
import CategoryDropdown from "src/components/form/dropdowns/CategoryDropdown";
import { TaskFormOptions } from "src/components/tasks/TaskForm";
import Form from "src/components/tasks/TaskForm/Form";
import GalleryForm from "src/components/tasks/TaskForm/steps/GalleryForm";
import useTenant from "src/hooks/api/services/tenants/useTenant";
import useTaskForm from "src/hooks/api/tasks/useTaskForm";
import { cleanHTMLText } from "src/lib/formatters";

const Descriptionform = ({ hideButtons }: TaskFormOptions) => {
	const { tenant } = useTenant();
	const { t } = useTranslation("forms", {
		keyPrefix: "description-task-form",
	});
	const { task, actions, current } = useTaskForm();
	let inputs = ["title", "description", "gallery"];

	if (task.isManualDescription) {
		inputs = ["description", "category"];
	}

	const isNextDisabled = () => {
		if (task.isManualDescription) {
			return !(
				task.title &&
				cleanHTMLText(task?.description || "") &&
				task.category_id
			);
		}

		return !(task.title && cleanHTMLText(task?.description || ""));
	};

	return (
		<Form
			{...{ hideButtons }}
			title={t("title")}
			onSubmit={async () => {
				actions.updateCurrent(current + 1);
			}}
			value={{
				title: task?.title,
				description: task.description,
				category_id: task.category_id,
			}}
			// schema={() => ({
			// 	title: scheme.title,
			// 	description: scheme.description,
			// 	// ...(inputs.includes("category")
			// 	// 	? { category_id: scheme.category_id }
			// 	// 	: {}),
			// })}
			nextDisabled={isNextDisabled()}
		>
			{(getInputProps) => {
				return (
					<>
						{inputs.includes("title") && (
							<Input
								label={t("task-title")}
								name="title"
								{...getInputProps("title")}
							/>
						)}

						{inputs.includes("description") && (
							<Field.Editor
								variant="bordered"
								value={task.description}
								onChange={(value) => {
									actions.set({
										description: value,
									});
								}}
							/>
						)}

						{inputs.includes("category") && (
							<CategoryDropdown
								variant="interests"
								className="dropdown-spacing"
								label={t("category.label")}
								placeholder={t("category.label")}
								value={task.category_id}
								valid={task.category_id}
								onChange={(value) => {
									actions.set({
										category_id: value,
									});
								}}
							/>
						)}

						{tenant.slug !== "getcrewdone" &&
							inputs.includes("gallery") && <GalleryForm />}
					</>
				);
			}}
		</Form>
	);
};

export default Descriptionform;
