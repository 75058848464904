import { useState } from "react";
import { useTranslation } from "react-i18next";
import FilterTabs from "src/components/layout/FilterTabs";
import EnvelopeList from "src/components/lists/EnvelopeList";
import { useFetchUnreadsOnMount } from "src/hooks/api/useUnreads";
import useAuth from "src/hooks/selectors/useAuth";
import Layout from "src/components/layout/Layout";
import SubscriptionFeatureMiddleware from "src/components/middlewares/SubscriptionFeatureMiddleware";

const DashboardEnvelopesView = () => {
	const { t } = useTranslation("envelope-view", {
		keyPrefix: "envelopes",
	});
	const auth = useAuth();
	const [filter, setFilter] = useState("open");
	const filters = {
		open: t("status.open"),
		SIGNED: t("status.signed"),
	} as any;
	useFetchUnreadsOnMount();

	if (auth.type === "company" && !auth.has_subscription) {
		return (
			<Layout
				title={t("title")}
				breadcrumb={[
					{
						label: t("title"),
						to: "/envelopes",
					},
				]}
			>
				<SubscriptionFeatureMiddleware plan="all" />
			</Layout>
		);
	}

	return (
		<Layout
			container="xxl"
			title={t("title")}
			breadcrumb={[
				{
					label: t("title"),
					to: "/envelopes",
				},
			]}
		>
			<div className="flex flex-col gap-4">
				<FilterTabs
					tabs={filters}
					variant={auth.type === "company" ? "light" : "dark"}
					active={filter}
					onChange={setFilter}
				/>
				<div className="flex-1">
					<EnvelopeList query={`state=${filter}`} />
				</div>
			</div>
		</Layout>
	);
};

export default DashboardEnvelopesView;
