import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import Chat from "src/components/chat/Chat";
import OfferChat from "src/components/chat/chats/OfferChat";
import Layout from "src/components/layout/Layout";
import OfferDetailGrid from "src/components/offer/layout/OfferDetailGrid";
import Skeleton from "src/components/Skeleton";
import useOfferRegistration from "src/hooks/api/useOfferRegistration";
import useSocketListener from "src/hooks/sockets/useSocketListener";
import useWindowSize from "src/hooks/useWindowSize";

export default function OfferRegistrationView() {
	const { t } = useTranslation("offer-view", {
		keyPrefix: "offer-registration-view",
	});
	const params = useParams() as any;
	const { registration, fetchData, loading } = useOfferRegistration();
	const { isPhone } = useWindowSize();
	const { offer } = registration || {};

	useSocketListener(`offer-registration.${registration?.hid}`, () =>
		fetchData(false)
	);

	useEffect(() => {
		fetchData();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	if (loading) {
		return (
			<Layout title={<Skeleton className="w-[80%] h-8" />}>
				<OfferDetailGrid className="flex-1">
					<Chat.Loading className="md:col-span-2" />
				</OfferDetailGrid>
			</Layout>
		);
	}

	return (
		<>
			<Layout
				variant={isPhone ? "no-container" : undefined}
				title={t("title")}
				breadcrumb={[
					{
						to: "/offers",
						label: t("breadcrumb.home"),
						isHome: true,
					},
					{
						to: `/offers/${offer?.slug}/show`,
						label: offer?.title,
					},
					{
						to: `/offers/${offer?.slug}/registration/${params?.registration}`,
						label: registration?.company?.name,
					},
				]}
			>
				{registration?.id && <OfferChat {...{ offer, registration }} />}
			</Layout>
		</>
	);
}
