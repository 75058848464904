import React from "react";
import { useSlider } from "src/components/slider/Slider";
import StepsComp from "src/components/Steps";

interface StepsProps {
	className?: string;
}

const Steps = ({ className }: StepsProps) => {
	const { current, max, setCurrent } = useSlider();
	return <StepsComp onChange={setCurrent} {...{ max, current, className }} />;
};

export default Steps;
