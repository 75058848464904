import { ApiFormFilter } from "src/api/types";
import useApi from "src/hooks/api/utils/useApi";

type Options = {
	type: "CONNECTION" | "PAYMENT_REQUEST";
	filter?: any;
};

function useCustomFormFilters(options: Options) {
	const { state, actions, api } = useApi(
		{ id: `CONNECTION_FORMS_FILTERS_${options.type}` },
		{
			baseUrl: `/client/forms/filters`,
			defaultStatus: "mounted",
			query: {
				...(options?.filter || {}),
				type: options.type,
			},
		}
	);
	const filters: ApiFormFilter[] = state?.list || [];

	return {
		filters,
		status: state.status,
		actions,
		api,
	};
}

export default useCustomFormFilters;
