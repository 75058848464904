const inbox = {
	"inbox-view": {
		title: "Berichten",
		select: "Selecteer eerst een bericht om te chatten",
		"not-found": "We hebben geen chat kunnen vinden",
		back: "Terug",
	},
};

export default inbox;
