import { ComponentProps } from "react";
import { ApiRegistration } from "src/api/types";
import withTranslation, {
	Translation,
} from "src/components/hoc/withTranslation";
import ChatButton from "src/components/tasks/TaskForm/elements/ChatButton";
import { cn } from "src/lib/utils";

interface RegistrationNoMerchantProps {
	registration: ApiRegistration;
}

const RegistrationNoMerchant = ({
	t,
	registration,
	className,
	...rest
}: RegistrationNoMerchantProps & Translation & ComponentProps<"div">) => {
	return (
		<div {...rest} className={cn("flex flex-col gap-2", className)}>
			<strong>{t("title")}</strong>
			<p>{t("description", { name: registration?.user?.first_name })}</p>
			{registration?.task && (
				<ChatButton
					className="mt-4"
					to={`/tasks/${registration?.task?.hid}/show/${registration?.hid}/chat`}
					unreadCount={registration?.unread_messages}
				/>
			)}
		</div>
	);
};

RegistrationNoMerchant.locale = {
	nl: {
		title: "Je kan de aanmelding nog niet verwerken",
		description:
			"{{name}} heeft nog geen koppeling met een betaalprovider. Neem contact op via de chat om {{name}} er op te wijzen dat hij/zij nog geen koppeling heeft met een betaalprovider.",
	},
	en: {
		title: "You can't process the registration yet",
		description:
			"{{name}} does not yet have a link with a payment provider. Contact them via chat to point out that {{name}} does not yet have a link with a payment provider.",
	},
};

export default withTranslation(RegistrationNoMerchant);
