import { ApiPayment } from "src/api/types";
import withTranslation, {
	Translation,
} from "src/components/hoc/withTranslation";
import TrackPaymentItem, {
	TrackPaymentItemDate,
	TrackPaymentItemDescription,
	TrackPaymentItemLabel,
} from "src/components/TrackPayment/TrackPaymentItem";

interface TrackPaymentProps extends Translation {
	payment: ApiPayment;
}

const TrackPayment = withTranslation(
	({ t, payment }: TrackPaymentProps) => {
		const isCanceled = !!payment.statuses.canceled;
		return (
			<ul className="flex flex-col">
				<TrackPaymentItem
					status="created"
					date={payment.statuses.created}
				>
					<TrackPaymentItemDate />
					<TrackPaymentItemLabel />
					<TrackPaymentItemDescription />
				</TrackPaymentItem>
				{!payment.statuses.reserved && isCanceled && (
					<TrackPaymentItem
						date={payment.statuses.canceled}
						status="canceled"
					>
						<TrackPaymentItemDate />
						<TrackPaymentItemLabel />
						<TrackPaymentItemDescription />
					</TrackPaymentItem>
				)}
				<TrackPaymentItem
					date={payment.statuses.reserved}
					status="reserved"
					className={
						isCanceled && !payment.statuses.reserved
							? "opacity-40"
							: ""
					}
				>
					<TrackPaymentItemDate />
					<TrackPaymentItemLabel />
					<TrackPaymentItemDescription />
				</TrackPaymentItem>

				{payment.statuses.reserved && isCanceled && (
					<TrackPaymentItem
						date={payment.statuses.canceled}
						status="canceled"
					>
						<TrackPaymentItemDate />
						<TrackPaymentItemLabel />
						<TrackPaymentItemDescription />
					</TrackPaymentItem>
				)}

				<TrackPaymentItem
					date={payment.statuses.completed}
					status="completed"
					layout="last"
					className={isCanceled ? "opacity-40" : ""}
				>
					<TrackPaymentItemDate />
					<TrackPaymentItemLabel />
					<TrackPaymentItemDescription />
				</TrackPaymentItem>
			</ul>
		);
	},
	{
		nl: {},
	}
);

export default TrackPayment;
