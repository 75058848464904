import moment from "moment";
import { ApiTask } from "src/api/types";
import ApiCall from "src/components/ApiCall";
import Card from "src/components/Card";
import PlatformFeedbackModal from "src/components/feedback/PlatformFeedbackModal";
import withTranslation, {
	Translation,
} from "src/components/hoc/withTranslation";
import SkillsData from "src/components/skills-data/SkillsData";
import Chart from "src/components/statistics/Chart";
import TotalCard from "src/components/statistics/TotalCard";
import useTenant from "src/hooks/api/services/tenants/useTenant";
import useAuth from "src/hooks/selectors/useAuth";
import NotFound from "src/views/auth/NotFoundView";

interface StatisticsPaneProps extends Translation {
	task: ApiTask;
}

const StatisticsPane = ({ t, task }: StatisticsPaneProps) => {
	const { tenant } = useTenant();
	const auth = useAuth();
	const isBeforeRelease = moment(task.created_at).isBefore("2022-04-10");

	if (auth.type === "student") {
		return <NotFound />;
	}

	return (
		<>
			<div className="grid grid-cols-1 md:grid-cols-3 gap-8">
				{isBeforeRelease && (
					<div className="col-span-3 flex flex-col justify-center items-center">
						<strong>{t("before-release")}</strong>
					</div>
				)}

				<ApiCall query={`/company/tasks/${task.id}/statistics/views`}>
					{(data: any, loading) => (
						<TotalCard
							title={t("views")}
							icon="fas fa-eye"
							background="light"
							value={data?.views || 0}
							{...{ loading }}
						/>
					)}
				</ApiCall>

				{(task?.registration_count || 0) > 0 ? (
					<ApiCall
						query={`/company/tasks/${task.id}/statistics/first-response`}
					>
						{(data: any, loading) => (
							<TotalCard
								title={t("first-registration")}
								icon="fas fa-calendar"
								background="light"
								value={data?.time}
								{...{ loading }}
							/>
						)}
					</ApiCall>
				) : (
					false
				)}
				<TotalCard
					title={t("registrations")}
					icon="fas fa-users"
					background="light"
					value={task.registrations?.count || 0}
				/>

				<ApiCall
					query={`/company/tasks/${task.id}/statistics/charts/views`}
				>
					{(data: any) => (
						<>
							{data && data?.length > 2 && (
								<Card className="md:col-span-3">
									<Chart
										background="light"
										tooltip={{ label: t("views") }}
										{...{ data }}
									/>
								</Card>
							)}
						</>
					)}
				</ApiCall>
			</div>
			{tenant.modules.includes("skills-profile") && task.skills && (
				<Card className="mt-8">
					<SkillsData
						className="flex flex-col flex-1 gap-12"
						data={task.skills}
					>
						<div className="flex justify-between items-end gap-4 flex-wrap">
							<h2>{t("skills.title")}</h2>
							<PlatformFeedbackModal
								type="TASK_SKILLS"
								data={{
									url: window.location.href,
								}}
								trigger={<PlatformFeedbackModal.Trigger />}
							>
								<PlatformFeedbackModal.Title />
								<PlatformFeedbackModal.Description>
									{t("skills-feedback.description")}
								</PlatformFeedbackModal.Description>
								<PlatformFeedbackModal.Form className="mt-4" />
							</PlatformFeedbackModal>
						</div>

						<div className="flex justify-center">
							<div className="flex gap-12">
								<div className="flex md:flex-row flex-col gap-4 justify-center items-center flex-1">
									<SkillsData.AreaChart type="area">
										<SkillsData.AreaChartDisplay className="col-span-2 max-h-[400px] w-full max-w-[400px]" />
										<SkillsData.AreaChartLabels className="max-w-[360px]" />
									</SkillsData.AreaChart>
								</div>
							</div>
						</div>

						<div className="flex gap-12 md:flex-row flex-col">
							<SkillsData.CheckEmptySkills type="COMPETENCE">
								<div className="flex flex-col flex-1 gap-3">
									<div className="flex flex-col">
										<SkillsData.Title type="COMPETENCE" />
										<SkillsData.Description type="COMPETENCE" />
									</div>
									<SkillsData.Skills type="COMPETENCE" />
								</div>
							</SkillsData.CheckEmptySkills>
							<SkillsData.CheckEmptySkills type="KNOWLEDGE">
								<div className="flex flex-col flex-1 gap-3">
									<div className="flex flex-col">
										<SkillsData.Title type="KNOWLEDGE" />
										<SkillsData.Description type="KNOWLEDGE" />
									</div>

									<SkillsData.Skills type="KNOWLEDGE" />
								</div>
							</SkillsData.CheckEmptySkills>
						</div>
					</SkillsData>
				</Card>
			)}
		</>
	);
};

StatisticsPane.locale = {
	nl: {
		"before-release":
			"Aangezien deze opdracht voor 10 april 2022 is gemaakt kan de weergave niet realistisch zijn",
		views: "Weergave",
		"first-registration": "Eerste aanmelding",
		registrations: "Aanmeldingen",
		skills: {
			title: "Deze skills zijn nodig voor de opdracht",
		},
		"skills-feedback": {
			description:
				"Vertel ons wat je van de skill resultaten vind, zo kunnen wij de AI in de toekomst verbeteren",
		},
	},
	en: {
		"before-release":
			"Since this task was made before April 10, 2022, the display may not be accurate",
		views: "Views",
		"first-registration": "First registration",
		registrations: "Registrations",
		skills: {
			title: "Deze skills zijn nodig voor de opdracht",
		},
		"skills-feedback": {
			description:
				"Tell us what you think about the skill results, so we can improve the AI in the future",
		},
	},
};

export default withTranslation(StatisticsPane);
