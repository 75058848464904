import { useEffect } from "react";
import { listen, off } from "src/lib/socket";

function useSocketListener(key: string | null, callback: (res: any) => void) {
	const init = () => {
		if (key) {
			listen(key, (event) => {
				if (process.env.NODE_ENV === "development") {
					console.log("SOCKET_LISTENER", {
						key,
						event,
					});
				}
				callback(event);
			});
		}
	};

	useEffect(() => {
		if (key) {
			init();
		}
		return () => {
			if (key) {
				off(key);
			}
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [key]);
}

export default useSocketListener;
