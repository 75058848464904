import { usePostHog } from "posthog-js/react";
import { useSelector, useDispatch } from "react-redux";
import usePatch from "src/hooks/rest/usePatch";
import { updateProfile } from "src/redux/auth";

export default function useEditProfile(variant?: string) {
	const posthog = usePostHog();
	const user = useSelector((state: any) => state.auth);
	const dispatch = useDispatch();
	const [patchProfile, loading] = usePatch(
		user.type === "student" ? "/auth/profile" : "/auth/profile/company"
	);

	const editProfile = async (data: any) => {
		let isOnboarded = false;
		if (user.type === "company") {
			isOnboarded =
				(user?.company?.onboarding?.missing || []).length === 0;
		}

		const res = await patchProfile(data);

		if (
			user.type === "company" &&
			!isOnboarded &&
			(res?.data?.company?.onboarding?.missing || []).length === 0
		) {
			posthog?.capture("ONBOARDING_COMPLETED");
			// mixpanel.track("ONBOARDING_COMPLETED", {});
		}

		dispatch(updateProfile(res.data));
	};

	return [editProfile, loading] as [(data: any) => Promise<any>, boolean];
}
