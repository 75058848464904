import { ApiNetworkInvite } from "src/api/types";
import useApi from "src/hooks/api/utils/useApi";

function useNetworkInvites() {
	const { state, actions, api, pagination } = useApi(
		{ id: "FEEDBACK" },
		{
			baseUrl: `/client/connections/invites`,
			query: {},
		}
	);
	const invites: ApiNetworkInvite[] = state?.list || [];

	return {
		invites,
		filter: state?.filter,
		search: state?.filter?.q,
		status: state.status,
		pagination,
		actions,
		api,
	};
}

export default useNetworkInvites;
