import moment from "moment";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import { ApiEnvelope, LayoutVariants } from "src/api/types";
import Skeleton from "src/components/Skeleton";
import { formatPrice } from "src/lib/formatters";

interface EnvelopeInformationProps {
	envelope: ApiEnvelope;
	variant: "dark" | "light";
	loading?: boolean;
}

const EnvelopeInformation = ({
	envelope,
	variant,
	loading,
}: EnvelopeInformationProps) => {
	const { t } = useTranslation("envelope", {
		keyPrefix: "envelope-information",
	});
	return (
		<>
			<strong>{t("title")}</strong>
			{loading ? (
				<EnvelopeInformation.Loader {...{ variant }} />
			) : (
				<div className="flex flex-col mt-3 gap-4">
					<EnvelopeInformationRow label={t("task")}>
						<NavLink
							className="underline"
							to={`/tasks/${envelope?.task?.hid}/show`}
						>
							{envelope.task?.title}
						</NavLink>
					</EnvelopeInformationRow>
					<EnvelopeInformationRow label={t("task-budget")}>
						{formatPrice(envelope.task?.budget)}
					</EnvelopeInformationRow>
					{envelope?.task?.variant === "HOUR_RATE" &&
					envelope.task.budget_per_hour ? (
						<EnvelopeInformationRow
							label={t("task-budget-per-hour")}
						>
							{formatPrice(envelope.task?.budget_per_hour)}
						</EnvelopeInformationRow>
					) : (
						false
					)}
					{envelope?.task?.end_date && (
						<EnvelopeInformationRow label={t("deadline")}>
							{moment(envelope.task?.end_date).format(
								"DD-MM-YYYY"
							)}
						</EnvelopeInformationRow>
					)}
				</div>
			)}
		</>
	);
};

EnvelopeInformation.Loader = ({ variant }: { variant: LayoutVariants }) => {
	const bg = variant === "dark" ? "dark" : "gray-900";

	return (
		<div className="flex flex-col mt-3 gap-4">
			<EnvelopeInformationRow
				label={<Skeleton className="h-6 w-2/4" {...{ bg }} />}
			>
				<Skeleton className="h-6 w-full" {...{ bg }} />
			</EnvelopeInformationRow>
			<EnvelopeInformationRow
				label={<Skeleton className="h-6 w-full" {...{ bg }} />}
			>
				<Skeleton className="h-6 w-32" {...{ bg }} />
			</EnvelopeInformationRow>{" "}
			<EnvelopeInformationRow
				label={<Skeleton className="h-6 w-2/4" {...{ bg }} />}
			>
				<Skeleton className="h-6 w-40" {...{ bg }} />
			</EnvelopeInformationRow>
		</div>
	);
};

interface EnvelopeInformationRowProps {
	label?: any;
	children?: any;
}

const EnvelopeInformationRow = ({
	label,
	children,
}: EnvelopeInformationRowProps) => {
	return (
		<div className="flex items-center gap-2">
			<span className="min-w-[160px]">{label}</span>
			<strong className="w-full block">{children}</strong>
		</div>
	);
};

EnvelopeInformation.defaultProps = {
	variant: "dark",
};

export default EnvelopeInformation;
