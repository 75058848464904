const reviews = {
	"index-view": {
		title: "Reviews",
		tabs: {
			received: "Received",
			created: "Placed",
		},
		empty: {
			created: "You have not placed any reviews",
			received: "You did not receive any reviews",
		},
	},
};

export default reviews;
