import { atom, useAtom } from "jotai";
import moment from "moment";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Button from "src/components/Button";
import { AnalyticsLayoutContext } from "src/components/analytics/analytics-layout/Provider";
import * as DateRangeFilter from "src/components/date-range-filter/DateRangeFilter";
import { DateRangeFilterValue } from "src/components/date-range-filter/Provider";
import withTranslation, {
	Translation,
} from "src/components/hoc/withTranslation";
import TabNav from "src/components/layout/tab-nav/TabNav";
import useExport from "src/hooks/api/services/statistics/export/useExport";
import { useUpdateEffectDebounce } from "src/hooks/useDebounce";

const dateAtom = atom<any>({});

interface AnalyticsLayoutProps extends Translation {
	children: (props: { date: DateRangeFilterValue }) => JSX.Element;
}

const AnalyticsLayout = ({ t, children }: AnalyticsLayoutProps) => {
	const location = useLocation();
	const [date, setDate] = useState<DateRangeFilterValue>({
		start: moment().startOf("week"),
		end: moment().endOf("week"),
	});
	const { actions, status } = useExport({
		date,
	});
	const [debouncedDate, setDebouncedDate] = useAtom(dateAtom);
	useUpdateEffectDebounce(date, 1000, (date) => {
		setDebouncedDate(date);
	});

	const handleExport = () => {
		actions.download(
			"/",
			{
				date: [
					date.start.format("YYYY-MM-DD"),
					date?.end?.format("YYYY-MM-DD"),
				],
			},
			{
				fileName: "export.xlsx",
			}
		);
	};

	useEffect(() => {
		setDebouncedDate({
			start: moment().startOf("week"),
			end: moment().endOf("week"),
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<AnalyticsLayoutContext.Provider
			value={{
				date,
				setDate,
			}}
		>
			<div className="flex items-center gap-3">
				<TabNav
					tabs={[
						{
							to: "/analytics/dashboard",
							label: t("tabs.dashboard"),
							active: location.pathname.startsWith(
								"/analytics/dashboard"
							),
						},
						{
							to: "/analytics/skills",
							label: t("tabs.skills"),
							active: location.pathname.startsWith(
								"/analytics/skills"
							),
						},
						{
							to: "/analytics/employees",
							label: t("tabs.employees"),
							active: location.pathname.startsWith(
								"/analytics/employees"
							),
						},
						{
							to: "/analytics/planning",
							label: t("tabs.planning"),
							active: location.pathname.startsWith(
								"/analytics/planning"
							),
						},
					]}
				/>
				<DateRangeFilter.Root value={date} onChange={setDate}>
					<DateRangeFilter.Trigger />
					<DateRangeFilter.Content>
						<DateRangeFilter.Presets />
						<DateRangeFilter.Calendar />
					</DateRangeFilter.Content>
				</DateRangeFilter.Root>
				<Button
					type="dark"
					loading={status === "loading"}
					className="hidden md:flex"
					onClick={handleExport}
				>
					<i className="fas fa-cloud-download mr-2"></i>
					{t("export")}
				</Button>
			</div>
			{children({
				date: debouncedDate,
			})}
		</AnalyticsLayoutContext.Provider>
	);
};

AnalyticsLayout.locale = {
	nl: {
		export: "Export",
		tabs: {
			dashboard: "Dashboard",
			skills: "Skills",
			employees: "Medewerkers",
			planning: "Planning",
		},
	},
	en: {
		export: "Export",
		tabs: {
			dashboard: "Dashboard",
			skills: "Skills",
			employees: "Employees",
			planning: "Planning",
		},
	},
};

export default withTranslation(AnalyticsLayout);
