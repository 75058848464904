import {
	DateRangeFilterContext,
	DateRangeFilterValue,
} from "src/components/date-range-filter/Provider";
import Calendar from "src/components/date-range-filter/elements/Calendar";
import Content from "src/components/date-range-filter/elements/Content";
import Presets from "src/components/date-range-filter/elements/Presets";
import Trigger from "src/components/date-range-filter/elements/Trigger";
import { Popover } from "src/components/ui/popover";

interface DateRangeFilterProps {
	children?: any;
	value: DateRangeFilterValue;
	onChange: (value: DateRangeFilterValue) => void;
}

const DateRangeFilter = ({
	children,
	value,
	onChange,
}: DateRangeFilterProps) => {
	return (
		<DateRangeFilterContext.Provider value={{ value, onChange }}>
			<Popover>{children}</Popover>
		</DateRangeFilterContext.Provider>
	);
};

export { DateRangeFilter as Root, Trigger, Content, Presets, Calendar };
