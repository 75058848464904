import { ApiIdea } from "src/api/types";
import useApi from "src/hooks/api/utils/useApi";

type Item = any;

type Options = {
	filter?: any;
};

function useFeedback(options?: Options) {
	const { state, actions, api, pagination } = useApi(
		{ id: "FEEDBACK" },
		{
			baseUrl: `/ideas`,
			query: {
				state: "all",
				...(options?.filter || {}),
			},
		}
	);
	const ideas: Item[] = state?.list || [];

	const like = async (id: number) => {
		const { data } = await api
			.patch(`/${id}/like`)
			.then(({ data }) => data);

		if (data?.id) {
			actions.set((state: any) => ({
				...state,
				list: (state?.list || []).map((item: ApiIdea) => {
					if (item.id === data.id) {
						return data;
					}
					return item;
				}),
			}));
		}
	};

	return {
		ideas,
		idea: state?.data,
		filter: state?.filter,
		search: state?.filter?.q,
		status: state.status,
		pagination,
		actions: {
			...actions,
			like,
		},
		api,
	};
}

export default useFeedback;
