import axios from "axios";
import { FormEvent, useState } from "react";
import Button from "src/components/Button";
import withTranslation, {
	Translation,
} from "src/components/hoc/withTranslation";
import useTasks from "src/hooks/api/services/tasks/useTasks";
import useTasksFilter from "src/hooks/api/tasks/useTasksFilter";
import useModal from "src/hooks/useModal";

const ImportTaskModal = ({ t }: Translation) => {
	const modal = useModal();
	const [selectedFile, setSelectedFile] = useState<any>(null);
	const [isSubmitting, setIsSubmitting] = useState(false);
	const { actions } = useTasks();
	const { filter } = useTasksFilter();

	const handleSubmit = async (event: FormEvent) => {
		event.preventDefault();
		if (!selectedFile) return;
		setIsSubmitting(true);
		const fd = new FormData();
		fd.append("file", selectedFile);
		try {
			await axios.post("/client/tasks/import", fd, {
				headers: {
					"Content-Type": "multipart/form-data",
				},
			});
			await actions.list(filter);
			setIsSubmitting(false);
			modal.onClose();
		} catch (error) {
			setIsSubmitting(false);
		}
	};

	return (
		<div className="flex flex-col gap-6">
			<div className="flex flex-col pt-6">
				<strong className="text-md mb-1">{t("title")}</strong>
				<p className="opacity-70 mb-3">{t("description")}</p>
				<a
					className="underline opacity-70 hover:opacity-100"
					download
					href="/downloads/task_import_sample_shifts.xlsx"
				>
					{t("download")}
				</a>
			</div>
			<hr className="border-border" />
			<form onSubmit={handleSubmit} className="flex flex-col gap-6">
				<input
					type="file"
					onChange={(e: any) => setSelectedFile(e?.target?.files[0])}
				/>
				<Button type="dark" submit loading={isSubmitting}>
					{t("buttons.import")}
				</Button>
			</form>
		</div>
	);
};

ImportTaskModal.locale = {
	nl: {
		title: "Opdrachten importeren",

		description:
			"Gebruik het onderstaande sjabloon om opdrachten te importeren, na het importeren kan je de opdrachten controleren en publiceren.",
		download: "Download sjabloon",
		buttons: {
			import: "Bestand importeren",
		},
	},
	en: {
		title: "Import Tasks",
		description:
			"Use the template below to import tasks, once imported you can check the tasks and publish them.",
		download: "Download template",
		buttons: {
			import: "Import file",
		},
	},
};

export default withTranslation(ImportTaskModal);
