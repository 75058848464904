import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Button from "src/components/Button";
import EmptyState from "src/components/EmptyState";
import Loader from "src/components/Loader";
import DeleteCustomFormButton from "src/components/custom-form/DeleteCustomFormButton";
import FormGrid from "src/components/form/FormGrid";
import withTranslation, {
	Translation,
} from "src/components/hoc/withTranslation";
import Layout from "src/components/layout/Layout";
import useForm from "src/hooks/api/services/forms/useForm";

function NetworkView({ t }: Translation) {
	const navigate = useNavigate();
	const { forms, actions, status } = useForm({
		rememberId: "FORM_CONNECTIONS",
		filter: {
			type: "CONNECTION",
		},
	});

	useEffect(() => {
		actions.list({
			type: "CONNECTION",
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const onCreateConnectionForm = async () => {
		const result = await actions.create({
			type: "CONNECTION",
			name: t("custom-form-values.name"),
		});
		navigate(`/settings/network/forms/${result.id}`);
	};

	return (
		<Layout container="xxl" title={t("title")}>
			<Layout.SettingsNav />
			<FormGrid>
				<FormGrid.Header title={t("addons.title")}>
					<p className="opacity-70">{t("addons.description")}</p>
				</FormGrid.Header>
				<FormGrid.Card>
					{forms.length > 0 && (
						<div className="flex flex-col gap-6">
							<div className="flex items-center gap-4 justify-between">
								<strong>{t("addons.all")}</strong>
							</div>
							<div className="flex flex-col gap-4">
								{forms.map((form) => (
									<div
										key={form.id}
										className="flex gap-6 p-6 border border-border rounded-md items-center"
									>
										<div className="flex flex-col flex-1">
											<strong>{form.name}</strong>
											{form.description && (
												<p className="opacity-70">
													{form.description}
												</p>
											)}
										</div>
										<div className="flex items-center gap-3">
											<DeleteCustomFormButton
												onDelete={async () =>
													await actions.delete(
														form.id
													)
												}
											/>
											<Button
												to={`/settings/network/forms/${form.id}`}
												xsmall
												type="border"
											>
												{t("addons.buttons.view")}
											</Button>
										</div>
									</div>
								))}
							</div>
						</div>
					)}
					{status === "idle" && forms.length === 0 && (
						<EmptyState.Overlay className="p-6 relative">
							<EmptyState.Title>
								{t("addons.empty.title")}
							</EmptyState.Title>
							<EmptyState.Description>
								{t("addons.empty.description")}
							</EmptyState.Description>
							<EmptyState.Buttons>
								<Button onClick={onCreateConnectionForm}>
									{t("addons.buttons.create")}
								</Button>
							</EmptyState.Buttons>
						</EmptyState.Overlay>
					)}
					{status === "loading" && forms.length === 0 && (
						<div className="flex flex-col justify-center items-center p-8">
							<Loader />
						</div>
					)}
				</FormGrid.Card>
			</FormGrid>
		</Layout>
	);
}

NetworkView.locale = {
	nl: {
		title: "Netwerk",
		addons: {
			title: "Connectie gegevens",
			all: "Connectie formulier",
			description: "Laat connecties extra informatie invullen.",
			empty: {
				title: "Nog geen formulier ingesteld",
				description:
					"Voeg een formulier toe zodat je connecties extra informatie invullen.",
			},
			buttons: {
				view: "Bekijk",
				create: "Instellen",
			},
		},
		"custom-form-values": {
			name: "Extra informatie",
		},
	},
	en: {
		title: "Network",
		addons: {
			title: "Connection Details",
			all: "Connection Form",
			description: "Allow connections to fill in additional information.",
			empty: {
				title: "No form set up yet",
				description:
					"Add a form so your connections can fill in additional information.",
			},
			buttons: {
				view: "View",
				create: "Set up",
			},
		},
		"custom-form-values": {
			name: "Additional Information",
		},
	},
};

export default withTranslation(NetworkView);
