import {
	DndContext,
	KeyboardSensor,
	useSensor,
	useSensors,
	TouchSensor,
	MouseSensor,
	rectIntersection,
} from "@dnd-kit/core";
import { restrictToFirstScrollableAncestor } from "@dnd-kit/modifiers";
import AddButton from "src/components/kanban/AddButton";
import Column from "src/components/kanban/Column";
import Container from "src/components/kanban/Container";
import Item from "src/components/kanban/Item";
import LoadMore from "src/components/kanban/LoadMore";
import useKanban, { ActiveDraggable } from "src/hooks/useKanban";
import useWindowSize from "src/hooks/useWindowSize";

interface KanbanProps {
	children: any;
	onDrop: (params: ActiveDraggable) => void;
}

const Kanban = ({ children, onDrop }: KanbanProps) => {
	const { isPhone } = useWindowSize();
	const sensors = useSensors(
		useSensor(MouseSensor, {
			// Require the mouse to move by 10 pixels before activating
			activationConstraint: {
				distance: 10,
			},
		}),
		useSensor(TouchSensor, {
			// Press delay of 250ms, with tolerance of 5px of movement
			activationConstraint: {
				delay: isPhone ? 300 : 0,
				tolerance: 5,
			},
		}),
		useSensor(KeyboardSensor)
	);
	const { current, actions } = useKanban();

	const handleDragStart = (event: any) => {
		actions.setActiveId(event?.active?.id);
	};

	const handleDragOver = (event: any) => {
		actions.setToColumn(event?.over?.id);
	};

	const handleDragEnd = () => {
		if (
			current?.id &&
			current?.from &&
			current.to &&
			current.from !== current.to
		) {
			onDrop(current);
		}
		actions.setActiveId(undefined);
		actions.setGhost({
			width: undefined,
			height: undefined,
		});
	};

	return (
		<DndContext
			sensors={sensors}
			onDragEnd={handleDragEnd}
			onDragStart={handleDragStart}
			onDragOver={handleDragOver}
			modifiers={[restrictToFirstScrollableAncestor]}
			collisionDetection={rectIntersection}
		>
			<div className="flex flex-1 py-2 gap-5">{children}</div>
		</DndContext>
	);
};

Kanban.Item = Item;
Kanban.Column = Column;
Kanban.LoadMore = LoadMore;
Kanban.AddButton = AddButton;
Kanban.Container = Container;

export default Kanban;
