import Field from "src/components/field/Field";
import withTranslation, {
	Translation,
} from "src/components/hoc/withTranslation";

interface JobOfferEditorProps extends Translation {
	onChange: (text: string) => void;
	value: string;
}

const JobOfferEditor = ({ t, value, onChange }: JobOfferEditorProps) => {
	return (
		<Field.Editor
			variant="floating"
			{...{ value, onChange }}
			editorClassName="md:min-h-[300px] min-h-[200px]"
			placeholder={t("placeholder")}
		/>
	);
};

JobOfferEditor.locale = {
	nl: {
		placeholder: "Plak hier je vacature tekst...",
	},
	en: {
		placeholder: "Paste your job offer text here...",
	},
};

export default withTranslation(JobOfferEditor);
