import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { ApiUserMinimal } from "src/api/types";
import Button from "src/components/Button";
import Card from "src/components/Card";
import useOfferRegistration from "src/hooks/api/useOfferRegistration";
import usePatch from "src/hooks/rest/usePatch";
import useAuth from "src/hooks/selectors/useAuth";
import useSocketListener from "src/hooks/sockets/useSocketListener";
import { formatPrice } from "src/lib/formatters";

interface MessageBidFormProps {
	bid: {
		id: number;
		budget: number;
		status: "PENDING" | "DENIED" | "ACCEPTED";
		created_at: string;
		offer_id?: number;
		offer_registration_id?: number;
	};
	creator?: ApiUserMinimal;
}

const MessageBidForm = ({ bid, creator }: MessageBidFormProps) => {
	const { t } = useTranslation(["chat", "buttons"]);
	const { fetchData, registration } = useOfferRegistration();
	const [loading, setLoading] = useState(undefined) as any;
	const [status, setStatus] = useState(bid.status) as any;
	const auth = useAuth();
	const [update] = usePatch(
		`/offer-registrations/${registration.id}/bid/${bid.id}`
	);

	useSocketListener(`bid.${bid.id}`, (result) => {
		if (result?.status) {
			setStatus(result?.status);
		}
		fetchData();
	});

	const handleUpdateBid = async (status: string) => {
		setLoading(status);
		update({ status })
			.then(() => {
				setStatus(status);
				fetchData();
			})
			.finally(() => {
				setLoading(undefined);
			});
	};

	return (
		<Card className="shadow-sm">
			<div className="flex flex-col gap-2">
				<strong className="leading-4">
					{t("title", {
						keyPrefix: "message-bid-form",
						ns: "chat",
					})}
				</strong>
				{status === "ACCEPTED" && (
					<p className="mb-0 text-green max-w-[200px] md:max-w-[300px]">
						{t("price-adjust-accepted", {
							price: formatPrice(bid.budget),
							keyPrefix: "message-bid-form",
							ns: "chat",
						})}
					</p>
				)}

				{status === "DENIED" && (
					<p className="mb-0 text-red max-w-[200px] md:max-w-[300px]">
						{t("price-adjust-denied", {
							price: formatPrice(bid.budget),
							keyPrefix: "message-bid-form",
							ns: "chat",
						})}
					</p>
				)}
				{!["DENIED", "ACCEPTED"].includes(status) && (
					<p className="mb-0 max-w-[200px] md:max-w-[300px]">
						{t("price-adjust-open", {
							name: creator?.first_name,
							price: formatPrice(bid.budget),
							keyPrefix: "message-bid-form",
							ns: "chat",
						})}
					</p>
				)}

				{creator?.id === auth.id && status === "PENDING" && (
					<small className="opacity-40">
						{t("pending", {
							keyPrefix: "message-bid-form",
							ns: "chat",
						})}
					</small>
				)}
			</div>

			{status === "PENDING" && creator?.id !== auth.id && (
				<div className="flex items-center gap-2 mt-4">
					<Button
						loading={loading === "DENIED"}
						onClick={() => handleUpdateBid("DENIED")}
						small
						type="gray"
						disabled={loading}
					>
						{t("denie", {
							ns: "buttons",
						})}
					</Button>
					<Button
						loading={loading === "ACCEPTED"}
						onClick={() => handleUpdateBid("ACCEPTED")}
						small
						disabled={loading}
					>
						{t("accept", {
							keyPrefix: "message-bid-form",
							ns: "chat",
						})}
					</Button>
				</div>
			)}
		</Card>
	);
};

export default MessageBidForm;
