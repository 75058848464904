import { useState } from "react";
import Button from "src/components/Button";
import Stars from "src/components/Stars";
import FormGroup from "src/components/form/FormGroup";
import Input from "src/components/form/Input";
import withTranslation, {
	Translation,
} from "src/components/hoc/withTranslation";
import usePaymentRequestFinalize from "src/hooks/api/services/payment-requests/usePaymentRequestFinalize";
import useModal from "src/hooks/useModal";
import useQueryString from "src/hooks/useQueryString";

const PaymentRequestsReviewModal = ({ t }: Translation) => {
	const modal = useModal({
		title: t("title"),
	});
	const qs = useQueryString();
	const ids = typeof qs.ids === "string" ? [qs.ids] : (qs.ids as string[]);
	const [form, setForm] = useState({
		review: "",
		rating: undefined,
	});
	const [isSubmitting, setIsSubmitting] = useState(false);
	const isDisabled = !(form.rating && form.review) || isSubmitting;
	const { actions } = usePaymentRequestFinalize();

	const onSubmit = async () => {
		if (form.rating === undefined || form.review === undefined) {
			return;
		}
		setIsSubmitting(true);
		try {
			await actions.finalize(ids, form as any);
			setIsSubmitting(false);
			modal.onClose();
		} catch (error) {
			setIsSubmitting(false);
		}
	};

	return (
		<div className="flex flex-col">
			<p className="opacity-70">{t("description")}</p>
			<div className="flex flex-col mt-6 gap-4">
				<Input
					autoFocus
					name="review"
					label={t("fields.review.label")}
					placeholder={t("fields.review.placeholder")}
					multiline
					onChange={(key, value) =>
						setForm((prev) => ({
							...prev,
							review: value,
						}))
					}
					value={form?.review}
				/>
				<FormGroup label={t("fields.rating.label")}>
					<div className="flex flex-col gap-2">
						<Stars
							size="24px"
							inActiveClassName="text-placeholder"
							rating={form?.rating}
							onClick={(value: any) =>
								setForm((prev) => ({ ...prev, rating: value }))
							}
						/>
						<small className="opacity-50">
							{t("fields.rating.description")}
						</small>
					</div>
				</FormGroup>
			</div>
			<div className="flex flex-col mt-6">
				<Button
					onClick={onSubmit}
					loading={isSubmitting}
					disabled={isDisabled}
				>
					{t("buttons.complete")}
				</Button>
			</div>
		</div>
	);
};

PaymentRequestsReviewModal.locale = {
	nl: {
		title: "Verzoeken afronden",
		description:
			"Om een betaalverzoek ook daadwerkelijk te kunnen uitbetalen hebben wij een review nodig hoe de werkzaamheden zijn bevallen.",
		fields: {
			review: {
				label: "Review",
				placeholder: "Hoe bevielen de werkzaamheden?",
			},
			rating: {
				label: "Rating",
				description: "0 = heel slecht, 5 = heel goed",
			},
		},
		buttons: {
			complete: "Afronden",
		},
	},
	en: {
		title: "Complete Requests",
		description:
			"To be able to pay out a payment request, we need a review of how the work was.",
		fields: {
			review: {
				label: "Review",
				placeholder: "How was the work?",
			},
			rating: {
				label: "Rating",
				description: "0 = very bad, 5 = very good",
			},
		},
		buttons: {
			complete: "Complete",
		},
	},
};

export default withTranslation(PaymentRequestsReviewModal);
