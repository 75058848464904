import moment from "moment";
import { useTranslation } from "react-i18next";
import { formatPrice } from "src/lib/formatters";
import * as Yup from "yup";

function useTaskScheme() {
	const { t } = useTranslation("schemes");
	return {
		title: Yup.string().required(t("required")),
		description: Yup.string().required(t("required")),
		budget: Yup.number()
			.typeError(t("type-number"))
			.min(
				10,
				t("min-worth-price", {
					value: formatPrice(10),
				})
			)
			.required(t("required")),
		budget_per_hour: Yup.number()
			.typeError(t("type-number"))
			.min(
				10,
				t("min-worth-price", {
					value: formatPrice(10),
				})
			)
			.required(t("required")),

		start_date: Yup.date().required(t("required")),
		end_date: Yup.date().when(
			"start_date",
			(start_date: any, schema: any) =>
				start_date &&
				schema.min(
					moment(start_date).subtract(1, "day").toDate(),
					t("end-date-before-start-date")
				)
		),
		estimated_duration: Yup.string().nullable(),
		category_id: Yup.number().nullable(),
		category: Yup.string().nullable(),
		promotion_code: Yup.string(),
		amount_of_students: Yup.number().typeError(t("type-number")),
	};
}

export default useTaskScheme;
