import React from "react";
import { useTranslation } from "react-i18next";
import Input from "src/components/form/Input";
import OfferForm from "src/components/offer/forms/OfferForm";
import useCreateOffer from "src/hooks/api/useCreateOffer";
import useOfferScheme from "src/hooks/schemes/useOfferScheme";

const OfferTitleForm = () => {
	const { setCurrent, offer } = useCreateOffer();
	const { t } = useTranslation("offer", {
		keyPrefix: "form",
	});
	const scheme = useOfferScheme();

	return (
		<OfferForm
			onSubmit={async () => {
				if (offer.title) {
					setCurrent(1);
				} else {
					throw new Error(t("title.unknown"));
				}
				return;
			}}
			value={{ title: offer?.title }}
			schema={() => ({
				title: scheme.title,
			})}
		>
			{(getInputProps) => (
				<>
					<Input
						label={t("title.label")}
						placeholder={t("title.placeholder")}
						{...getInputProps("title")}
					/>
					<div className="ml-4">
						<strong>{t("title.example.title")}</strong>
						<ul className="pl-8 list-disc">
							<li>{t("title.example.example-one")}</li>
							<li>{t("title.example.example-two")}</li>
							<li>{t("title.example.example-three")}</li>
							<li>{t("title.example.example-four")}</li>
							<li>{t("title.example.example-five")}</li>
						</ul>
					</div>
				</>
			)}
		</OfferForm>
	);
};

export default OfferTitleForm;
