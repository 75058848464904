import { cn } from "src/lib/utils";

interface MessageSmallProps {
	children?: any;
	className?: string;
	align?: "left" | "right";
	icon?: {
		name?: string;
		className?: string;
	};
}

const MessageSmall = ({
	children,
	className,
	align = "left",
	icon,
}: MessageSmallProps) => {
	return (
		<div
			className={cn(
				"flex items-center gap-1.5 p-1.5 rounded-md bg-background shadow",
				align === "left" && "rounded-bl-none",
				align === "right" && "rounded-br-none",
				className
			)}
		>
			{icon?.name && <i className={cn(icon.name, icon.className)}></i>}
			<span className="text-sm flex-1 pr-1 font-regular leading-full">
				{children}
			</span>
		</div>
	);
};

export default MessageSmall;
