import moment from "moment";
import useLatestBuild from "src/hooks/useCurrentVersion";
import useModules from "src/hooks/selectors/useModules";
import useAuth from "src/hooks/selectors/useAuth";
import Container from "src/components/layout/Container";

const LayoutFooter = () => {
	const currentVersion = useLatestBuild();
	const { modules } = useModules();
	const auth = useAuth();

	if (auth.type === "company" || auth.type === "student") {
		return (
			<>
				{/* {process.env.NODE_ENV === "development" && (
					<div className="flex justify-center flex-col gap-1 fixed right-4 bottom-4 bg-background opacity-70 p-4 rounded-md hover:opacity-100 text-sm max-w-[500px]">
						<div className="flex items-center">
							<strong className="flex w-[100px]">User ID:</strong>
							<p>{auth.id || "Niet gevonden"}</p>
						</div>
						<div className="flex items-center">
							<strong className="flex w-[100px]">
								Merchant ID:
							</strong>

							<p>{auth.merchant_id || "Niet gevonden"}</p>
						</div>
						<div className="flex items-center">
							<strong className="flex w-[100px]">
								Service Fee:
							</strong>
							<p>{auth.service_rate || "Niet gevonden"}</p>
						</div>
						<div className="flex items-center">
							<strong className="flex w-[100px]">
								Client ID:
							</strong>
							<p>{auth?.links?.client || "Niet gevonden"}</p>
						</div>
						<div className="flex items-center">
							<strong className="flex w-[100px]">
								Permissions:
							</strong>
							<p>
								{permissions
									.map((permission: string) => permission)
									.join(", ")}
							</p>
						</div>
						<div className="flex items-center">
							<strong className="flex w-[100px]">Modules:</strong>
							<p>
								{modules.map((item: string) => item).join(", ")}
							</p>
						</div>
					</div>
				)} */}
			</>
		);
	}

	return (
		<Container className="hidden md:flex py-8 justify-between items-center gap-4 w-full text-background-foreground dark:text-white">
			<small className="updated_at flex-1 font-[8px] opacity-0">
				{moment(currentVersion).format("DD-MM-YYYY")}
			</small>
			<small className="flex flex-col flex-1 justify-center font-[8px] items-center">
				{process.env.NODE_ENV === "development" && (
					<span>{modules.join(",")}</span>
				)}
			</small>
			<div className="flex-1" />
		</Container>
	);
};

export default LayoutFooter;
