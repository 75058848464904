import { Route } from "react-router-dom";
import CostCalculatorView from "src/views/global/tools/CostCalculatorView";
import JobOfferConverterView from "src/views/global/tools/JobOfferConverterView";

const ToolRoutes = [
	<Route
		element={<JobOfferConverterView />}
		path="/public/tools/job-offer-converter"
		key="/public/tools/job-offer-converter"
	/>,
	<Route
		element={<CostCalculatorView />}
		path="/public/tools/cost-calculator"
		key="/public/tools/cost-calculator"
	/>,
];

export default ToolRoutes;
