import { ComponentProps } from "react";
import Loader from "src/components/Loader";
import withTranslation, {
	Translation,
} from "src/components/hoc/withTranslation";
import useAuthSession from "src/hooks/api/services/session/useAuthSession";
import { cn } from "src/lib/utils";

interface AuthSessionProcessingLoaderProps
	extends ComponentProps<"div">,
		Translation {}

const AuthSessionProcessingLoader = ({
	t,
	children,
	className,
	...rest
}: AuthSessionProcessingLoaderProps) => {
	const { session } = useAuthSession();

	// useEffect(() => {
	// 	const interval = setInterval(() => {
	// 		if (!session?.id || session.status === "COMPLETED") {
	// 			actions.deleteCookie();

	// 			clearInterval(interval);
	// 			return;
	// 		}
	// 		actions.find(session.id);
	// 	}, 1000);
	// 	return () => {
	// 		clearInterval(interval);
	// 	};
	// 	// eslint-disable-next-line react-hooks/exhaustive-deps
	// }, []);

	if (!session?.id) return null;

	return (
		<div
			{...rest}
			className={cn(
				"flex flex-col max-w-md justify-center items-center text-center gap-2",
				className
			)}
		>
			{session?.status !== "COMPLETED" ? (
				<Loader />
			) : (
				<i className="far fa-check"></i>
			)}
			<strong>{t("title")}</strong>
			<p className="opacity-70">{t("description")}</p>
		</div>
	);
};

AuthSessionProcessingLoader.locale = {
	nl: {
		title: "Je account is nog aan het verwerken",
		description:
			"Momenteel wordt je account nog verwerkt. Dit kan enkele minuten duren, daarna kan je je opdrachten inzien.",
	},
	en: {
		title: "Your account is still processing",
		description:
			"Your account is currently being processed. This may take a few minutes, after which you will be able to view your assignments.",
	},
};

export default withTranslation(AuthSessionProcessingLoader);
