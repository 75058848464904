import { useSelector } from "react-redux";
import useTheme from "src/hooks/useTheme";

const useAuth = () => {
	const auth = useSelector((state: any) => state.auth);
	const { theme } = useTheme();
	return {
		...auth,
		variant: auth.type === "company" ? "light" : "light",
		theme,
	};
};

export default useAuth;
