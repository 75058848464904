import { useEffect } from "react";
import { useParams } from "react-router-dom";
import Loader from "src/components/Loader";
import withTranslation, {
	Translation,
} from "src/components/hoc/withTranslation";
import PaymentRequestFullForm from "src/components/payment-request/payment-request-form/PaymentRequestFullForm";
import usePaymentRequests, {
	PaymentRequestFormValue,
} from "src/hooks/api/services/payment-requests/usePaymentRequests";
import useAuth from "src/hooks/selectors/useAuth";
import useModal from "src/hooks/useModal";

const EditPaymentRequestModal = ({ t }: Translation) => {
	const auth = useAuth();
	const { paymentRequestId } = useParams();
	const modal = useModal({
		title: t("title"),
	});

	const { request, actions, status } = usePaymentRequests();

	useEffect(() => {
		actions.get(paymentRequestId);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const onSubmit = async (values: PaymentRequestFormValue) => {
		//Create the payment request
		await actions.update(
			{
				id: request.id,
				...values,
			},
			{ updateList: true }
		);
		//Refresh the list of payment requests
		await actions.list();
		//Close the modal
		modal.onClose();
	};

	if (
		!request?.id ||
		request.id?.toString() !== paymentRequestId?.toString()
	) {
		return (
			<div className="p-8 flex flex-col items-center justify-center min-h-[45vh]">
				<Loader />
			</div>
		);
	}

	if (auth.type === "student" && request.status !== "OPEN") {
		return (
			<div className="p-8 flex flex-col items-center justify-center min-h-[45vh]">
				<p className="text-center">{t("submitted.text")}</p>
			</div>
		);
	}

	if (request.status === "DENIED") {
		return (
			<div className="p-8 flex flex-col items-center justify-center min-h-[45vh]">
				<p className="text-center">{t("denied.text")}</p>
			</div>
		);
	}

	return (
		<>
			{status !== "loading" && request?.id ? (
				<PaymentRequestFullForm
					className="min-h-[45vh]"
					{...{ onSubmit }}
					connectionId={
						auth.type === "student"
							? request?.company?.id
							: request?.receiver?.hid
					}
					actions={
						auth.hid === request.creator?.hid ? ["delete"] : []
					}
					defaultValue={{
						id: request.id,
						status: request.status,
						type: request.type,
						description: request?.description,
						start_date: request.start_date,
						end_date: request.end_date,
						break: request.break,
						forms: request.forms,
						price: request.price
							? Number(request.price)
							: undefined,
					}}
				/>
			) : (
				<div className="flex flex-col justify-center items-center p-8 min-h-[45vh]">
					<Loader />
				</div>
			)}
		</>
	);
};

EditPaymentRequestModal.locale = {
	nl: {
		title: "Betaalverzoek aanpassen",
		submitted: {
			text: "Betaalverzoek verzonden naar de opdrachtgever",
		},
		denied: {
			text: "Betaalverzoek afgewezen door de opdrachtgever",
		},
		buttons: {
			cancel: "Annuleren",
		},
	},
	en: {
		title: "Modify Payment Request",
		submitted: {
			text: "Payment request sent to the client",
		},
		denied: {
			text: "Payment request rejected by the client",
		},
		buttons: {
			cancel: "Cancel",
		},
	},
};

export default withTranslation(EditPaymentRequestModal);
