import Button from "src/components/Button";
import withTranslation, {
	Translation,
} from "src/components/hoc/withTranslation";
import Modal from "src/components/Modal";
import usePost from "src/hooks/rest/usePost";
import useAuth from "src/hooks/selectors/useAuth";

interface TimesheetApproveModalProps extends Translation {
	visible: boolean;
	onClose: (status?: "completed" | "cancel") => void;
	links: {
		selected: number[];
		task: string | number;
		registration?: string;
	};
}

const TimesheetApproveModal = ({
	t,
	visible,
	onClose,
	links,
}: TimesheetApproveModalProps) => {
	const auth = useAuth();
	const [approve, loading] = usePost(
		`/${auth.type}/tasks/${links.task}/timesheets/multi-approve`
	);

	const onSubmit = () => {
		approve({
			ids: links.selected,
		})
			.then(() => {
				onClose("completed");
			})
			.catch(() => {
				console.log("errror");
			});
	};

	return (
		<Modal title={t(`title`)} {...{ visible, onClose }}>
			<div className="flex flex-col gap-4">
				<p>
					{t("description", {
						count: links.selected.length,
					})}
				</p>

				<div className="flex flex-col gap-2">
					<Button onClick={onSubmit} loading={loading} type="dark">
						{t(`buttons.submit`, {
							count: links.selected.length,
						})}
					</Button>
					<Button
						onClick={(e) => {
							e.preventDefault();
							onClose();
						}}
						disabled={loading}
						type="gray"
					>
						{t(`buttons.cancel`)}
					</Button>
				</div>
			</div>
		</Modal>
	);
};

TimesheetApproveModal.locale = {
	nl: {
		title: "Uren accepteren",
		description_one:
			"Weet je zeker dat je deze urenregistratie wilt goedkeuren? Het is niet mogelijk om hierna de urenregistratie aan te passen.",
		description_other:
			"Weet je zeker dat je deze urenregistraties wilt goedkeuren? Het is niet mogelijk om hierna de urenregistratie aan te passen.",
		buttons: {
			submit: "({{count}}) Goedkeuren",
			cancel: "Annulren",
		},
	},
	en: {
		title: "Approve hours",
		description_one:
			"Are you sure you want to approve this hour registration? It will not be possible to modify the hour registration afterwards.",
		description_other:
			"Are you sure you want to approve these hour registrations? It will not be possible to modify the hour registrations afterwards.",
		buttons: {
			submit: "Approve ({{count}})",
			cancel: "Cancel",
		},
	},
};

export default withTranslation(TimesheetApproveModal);
