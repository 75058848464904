const schemes = {
	required: "Verplicht",
	email: "Dit is een ongeldig e-mailadres",
	"min-age":
		"Om gebruik te maken van {{tenant.name}} moet je minimaal {{age}} jaar zijn.",
	telephone: "Telefoonnummer moet {{length}} tekens hebben",
	"min-worth-price": "De minimale waarde is {{value}}",
	"type-number": "Moet een nummer zijn",
	"category-unknown": "Categorie is niet bekend",
	"duration-in-hour": "Tijdsduur moet in uren",
	"year-future": "Het jaartal ligt in de toekomst",
	"type-year": "Moet een jaar zijn",
	"end-date-before-start-date":
		"De einddatum kan niet voor de starttijd zijn",
	password:
		"Je wachtwoord moet minimaal 8 karakters hebben, 1 hoofdletter & 1 cijfer",
	"email-in-use": "Email is al in gebruik",
	"kvk-in-use": "Er bestaat al een account met de opgegeven kvk.",
	length: "Dit veld moet exact {expected} karakters hebben",
	number: "Dit veld moet een nummer zijn",
};

export default schemes;
