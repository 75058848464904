import React from "react";
import Card from "src/components/Card";
import { formatNumber } from "src/lib/formatters";

interface TotalCardProps {
	title: string;
	icon?: string;
	value: number | string;
	background: "dark" | "light";
	loading?: boolean;
}

const TotalCard = ({
	title,
	icon,
	value,
	background,
	loading,
}: TotalCardProps) => {
	let _value = value;
	if (typeof _value === "number") {
		_value = formatNumber(_value);
	}
	return (
		<Card
			className={`${background === "dark" && "bg-dark-accent"}`}
			contentClassName={`${background === "dark" && "text-white"}`}
		>
			<div className="mb-0 flex items-center opacity-40">
				{icon && <i className={`mr-2 ${icon}`}></i>}
				<strong>{title}</strong>
			</div>
			{loading && (
				<h2 className="mt-2 text-primary">
					<span className="base-loader"></span>
				</h2>
			)}
			{!loading && <h2 className="mt-2 text-primary">{_value}</h2>}
		</Card>
	);
};

TotalCard.defaultProps = {
	background: "dark",
};

export default TotalCard;
