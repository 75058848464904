import React from "react";
import useWindowSize from "src/hooks/useWindowSize";

interface SignerContainerProps {
	children?: any;
}

const SignerContainer = ({ children }: SignerContainerProps) => {
	const size = useWindowSize();
	return (
		<div
			className={`grid ${
				size.isTablet ? "grid-cols-1" : "grid-cols-[60%,40%]"
			} flex-1 gap-4`}
		>
			{children}
		</div>
	);
};

const SignerContainerSideBar = ({ children }: SignerContainerProps) => {
	return <div className="grid grid-cols-1 gap-3">{children}</div>;
};

SignerContainer.SideBar = SignerContainerSideBar;

export default SignerContainer;
