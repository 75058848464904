import { ClassValue } from "clsx";
import { Moment } from "moment";
import { useLayoutEffect, useRef, useState } from "react";
import { useCalendar } from "src/components/Calendar";
import Column from "src/components/Calendar/week/Column";
import { DateRangeFilterValue } from "src/components/date-range-filter/Provider";
import Container from "src/components/layout/Container";
import { getDatesBetweenDates } from "src/lib/dates";
import { cn } from "src/lib/utils";

export interface DateRangeProps {
	value: DateRangeFilterValue;
	children?: any;
	column?: {
		head?: (date: Moment) => JSX.Element;
		footer?: (date: Moment) => JSX.Element;
	};
	variant?: "scrollable" | "flat" | "month-child";
	columnVariant?: "scrollable" | "flat" | "month-child";
	className?: ClassValue;
	id?: string;
}

const DateRange = ({
	value,
	column,
	columnVariant,
	variant = "scrollable",
	className,
	id,
}: DateRangeProps) => {
	const ref = useRef() as any;
	const [scrolledToDate, setScrolledToDate] = useState(false);
	const { options } = useCalendar();
	const dates = getDatesBetweenDates(
		value.start,
		value?.end || value.start.add(1, "day")
	);

	useLayoutEffect(() => {
		if (
			options &&
			options?.scrollToDate &&
			ref?.current &&
			!scrolledToDate
		) {
			const active = ref.current.querySelector(
				`[data-date="${options.scrollToDate.format("YYYY-MM-DD")}"]`
			);
			active.scrollIntoView({
				behavior: "smooth",
				block: "start",
				inline: "nearest",
			});
			setScrolledToDate(true);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	if (variant === "flat") {
		return (
			<div
				{...{ id }}
				className={cn("flex flex-1 scroll-m-8", className)}
			>
				{dates.map((date) => (
					<Column
						key={`week-${date}`}
						variant={columnVariant}
						{...{ date }}
						head={column?.head ? column.head(date) : undefined}
						footer={
							column?.footer ? column.footer(date) : undefined
						}
					/>
				))}
			</div>
		);
	}

	return (
		<Container
			{...{ id, ref }}
			variant="full-scrollable"
			className={cn("flex flex-row flex-1 min-h-[50vh]", className)}
		>
			<div className="flex flex-1">
				{dates.map((date) => (
					<Column
						className="min-w-[220px]"
						key={`week-${date}`}
						variant={columnVariant}
						{...{ date }}
						head={column?.head ? column.head(date) : undefined}
						footer={
							column?.footer ? column.footer(date) : undefined
						}
					/>
				))}
			</div>
		</Container>
	);
};

export default DateRange;
