import { ComponentProps } from "react";
import { cn } from "src/lib/utils";

const Text = ({ ...rest }: ComponentProps<"p">) => {
	return <p {...rest} />;
};

const Eyebrow = ({ className, ...rest }: ComponentProps<"p">) => {
	return (
		<p
			{...rest}
			className={cn(
				"text-[15px] dark:text-sm uppercase dark:opacity-80 font-bold dark:font-regular tracking-wide",
				"opacity-100 dark:opacity-100 text-background-foreground",
				className
			)}
		></p>
	);
};

const Title = ({ className, children, ...rest }: ComponentProps<"h1">) => {
	return (
		<h1
			{...rest}
			className={cn(
				"text-[24px] md:text-[46px] leading-[120%] tracking-tight",
				// "text-wrap-balance",
				className
			)}
		>
			{children}
		</h1>
	);
};

Text.Eyebrow = Eyebrow;
Text.Title = Title;

export default Text;
