import { useState } from "react";
import { ApiExperience } from "src/api/types";
import useApi from "src/hooks/api/utils/useApi";
import useAuth from "src/hooks/selectors/useAuth";

type Options = {
	type: "job" | "education";
	filter?: any;
};

function useExperiences(options: Options) {
	const auth = useAuth();
	const { state, actions, api } = useApi(
		{ id: `EXPERIENCES:${auth.hid}:${options.type}` },
		{
			baseUrl: `/resume/${auth.hid}/experiences`,
			query: {
				state: "all",
				type: options.type,
				...(options?.filter || {}),
			},
		}
	);
	const [autocomplete, setAutocomplete] = useState([]);
	const experiences: ApiExperience[] = state?.list || [];
	const experience: ApiExperience = state?.data || {};

	const getAutocomplete = async (query: string) => {
		const {
			data: { data },
		} = await api.get(`/autocomplete`, {
			params: {
				q: query,
				type: options.type,
			},
		});
		setAutocomplete(data || []);
	};

	const createByTitle = async (
		title: string,
		data: {
			linked?: string;
		} = {}
	) => {
		const result = await actions.create({
			...(options.type === "education" && { title }),
			...(options.type === "job" && { subtitle: title }),
			...data,
			type: options.type,
		});

		actions.list();
		setAutocomplete([]);
		return result;
	};

	const updateLink = async (id: string, linked: any) => {
		await api.patch(`/${id}/update-link`, {
			linked,
		});
	};

	return {
		experience,
		experiences,
		autocomplete,
		status: state.status,
		actions: {
			...actions,
			createByTitle,
			getAutocomplete,
			setAutocomplete,
			updateLink,
		},
		api,
	};
}

export default useExperiences;
