import { useState } from "react";
import { useNavigate } from "react-router-dom";
import DropdownButton from "src/components/DropdownButton";
import withTranslation, {
	Translation,
} from "src/components/hoc/withTranslation";
import PublishTaskButton from "src/components/tasks/PublishTaskButton";
import { useTaskKanban } from "src/components/tasks/kanban/TaskKanbanCard";
import useLimits from "src/hooks/api/services/auth/useLimits";
import useTaskForm from "src/hooks/api/tasks/useTaskForm";
import useModules from "src/hooks/selectors/useModules";
import useBackUrl from "src/hooks/useBackUrl";
import useNavigateModal from "src/hooks/useNavigateModal";

interface TaskKanbanCardActionsProps extends Translation {
	children?: any;
}

const TaskKanbanCardActions = ({ t, children }: TaskKanbanCardActionsProps) => {
	const { task } = useTaskKanban();
	const { actions } = useTaskForm();
	const navigateModal = useNavigateModal();
	const { modules } = useModules();
	const { limits } = useLimits();
	const [status, setStatus] = useState<"idle" | "deleting" | "duplicating">(
		"idle"
	);
	const navigate = useNavigate();
	const back = useBackUrl();

	return (
		<DropdownButton
			menu={{
				position: "bottom-right",
			}}
			button={{
				tabIndex: "-1",
				children: <i className="far fa-ellipsis-v " />,
				type: "transparent",
				iconOnly: true,
				className: "hover:bg-accent p-0 w-6 h-6 rounded-sm",
				as: "div",
			}}
			context={children}
		>
			{task.type === "PUBLISHED" && (
				<>
					<DropdownButton.Item
						icon="far fa-retweet"
						onClick={() =>
							navigate(`/tasks/create?repeat=${task.hid}`)
						}
					>
						{t("repeat-external")}
					</DropdownButton.Item>
					<DropdownButton.Item
						loading={status === "deleting"}
						icon="far fa-archive"
						onClick={() => {
							setStatus("deleting");
							actions.archive(task.id).then(() => {
								setStatus("idle");
							});
						}}
					>
						{t("archive")}
					</DropdownButton.Item>
				</>
			)}
			{task.type !== "PUBLISHED" && (
				<>
					<DropdownButton.Item
						icon="far fa-eye"
						onClick={() =>
							navigate(`/tasks/${task.hid}/editor?${back}`)
						}
					>
						{t("preview")}
					</DropdownButton.Item>
					<DropdownButton.Item
						icon="far fa-retweet"
						onClick={(close) => {
							close();
							navigateModal(`./repeat-task/${task.hid}`);
						}}
					>
						{t("repeat-internal")}
					</DropdownButton.Item>
					{task.type !== "TEMPLATE" && (
						<>
							<DropdownButton.Item
								loading={status === "duplicating"}
								icon="far fa-copy"
								onClick={(close) => {
									setStatus("duplicating");
									actions.duplicate(task.id).then(() => {
										setStatus("idle");
										close();
									});
								}}
							>
								{t("duplicate")}
							</DropdownButton.Item>
							{limits?.published_tasks?.is_available &&
								modules.includes("extern-task") && (
									<PublishTaskButton
										id={task.hid}
										variant="dropdown-button"
									/>
								)}
						</>
					)}

					<DropdownButton.Item
						loading={status === "deleting"}
						icon="far fa-archive"
						onClick={() => {
							setStatus("deleting");
							actions.archive(task.id).then(() => {
								setStatus("idle");
							});
						}}
					>
						{t("archive")}
					</DropdownButton.Item>
				</>
			)}
		</DropdownButton>
	);
};

TaskKanbanCardActions.locale = {
	nl: {
		preview: "Preview",
		publish: "Uitbesteden",
		archive: "Archiveren",
		duplicate: "Dupliceren",
		"repeat-internal": "Herhalen",
		"repeat-external": "Opnieuw",
	},
	en: {
		preview: "Preview",
		publish: "Outsource",
		archive: "Archive",
		duplicate: "Duplicate",
		"repeat-internal": "Repeat",
		"repeat-external": "Repeat",
	},
};

export default withTranslation(TaskKanbanCardActions);
