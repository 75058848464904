import { createAction, handleActions } from "redux-actions";
import { ApiModule } from "src/api/types";

export const updateModules = createAction("UPDATE_MODULES");
export const removeModules = createAction("REMOVE_MODULES");

export const emptyModulesState = {
	modules: [],
	expires: {},
	loading: true,
};

type ModulesState = {
	modules: ApiModule[];
	expires: {
		[key: string]: string;
	};
	loading: boolean;
};

const modulesReducer = handleActions(
	{
		UPDATE_MODULES: (state: ModulesState, action: any) => {
			return { ...state, ...action.payload };
		},
		REMOVE_MODULES: () => {
			return {
				...emptyModulesState,
			};
		},
	},
	{
		...emptyModulesState,
	}
);
export default modulesReducer;
