import { useCallback, useEffect, useRef, useState } from "react";

interface UsePollingOptions {
	fn: () => Promise<void>;
	interval: number;
	enabled: boolean;
}

const usePolling = ({ fn, interval, enabled }: UsePollingOptions) => {
	const [isPolling, setIsPolling] = useState(false);
	const savedCallback = useRef(fn);

	// Remember the latest fn
	useEffect(() => {
		savedCallback.current = fn;
	}, [fn]);

	const poll = useCallback(async () => {
		if (enabled) {
			setIsPolling(true);
			try {
				await savedCallback.current();
			} catch (error) {
				console.error("Polling error:", error);
			} finally {
				setIsPolling(false);
			}
		}
	}, [enabled]);

	useEffect(() => {
		if (!enabled) {
			return;
		}

		const id = setInterval(() => {
			poll();
		}, interval);

		return () => clearInterval(id);
	}, [interval, enabled, poll]);

	return isPolling;
};

export default usePolling;
