import { usePostHog } from "posthog-js/react";
import { useState } from "react";
import { useCookies } from "react-cookie";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { createCookie } from "src/hooks/useTokenListener";
import getTokenName from "src/lib/getTokenName";
import { logout } from "src/redux/auth";

function useLogout() {
	const [loading, setLoading] = useState(false);
	const dispatch = useDispatch();
	const [, setCookie] = useCookies();
	const navigate = useNavigate();
	const posthog = usePostHog();

	const handleLogout = () => {
		setLoading(true);
		setTimeout(() => {
			setCookie(getTokenName(), "", createCookie());
			dispatch(logout());
			// mixpanel.reset();
			posthog?.reset();
			navigate("/login");
			setLoading(false);
		}, 1000);
	};

	return {
		loading,
		handleLogout,
	};
}
export default useLogout;
