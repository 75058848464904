import { useEffect, useMemo, useState } from "react";
import Avatar from "src/components/Avatar";
import Button from "src/components/Button";
import Skeleton from "src/components/Skeleton";
import AnalalyticsLayout from "src/components/analytics/analytics-layout/AnalalyticsLayout";
import withTranslation, {
	Translation,
} from "src/components/hoc/withTranslation";
import Layout from "src/components/layout/Layout";
import Tab from "src/components/layout/Tab";
import Tabs from "src/components/layout/Tabs";
import { colors } from "src/components/tasks/TaskStatus";
import List from "src/components/ui/list";
import useUsers from "src/hooks/api/services/users/useUsers";
import { cn } from "src/lib/utils";

const EmployeesView = ({ t }: Translation) => {
	const { users: _users, status: _status, actions } = useUsers();
	const [search, setSearch] = useState("");
	const [state, setState] = useState<"ACTIVE" | "INVITED" | "DELETED">(
		"ACTIVE"
	);
	const counts = useMemo(() => {
		return {
			active: _users.filter((u) => u.status === "ACTIVE").length,
			invited: _users.filter((u) => u.status === "INVITED").length,
			deleted: _users.filter((u) => u.status === "DELETED").length,
		};
	}, [_users]);
	const status = _users.length && _status === "loading" ? "idle" : _status;

	const users = _users
		.filter((u) => {
			return u.status === state;
		})
		.filter((u) =>
			u.full_name.toLocaleLowerCase().includes(search.toLocaleLowerCase())
		);

	useEffect(() => {
		actions.list({
			with: ["teams", "status", "task-statistics"],
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<Layout
			breadcrumb={[
				{
					label: t("dashboard"),
					to: "/analytics/dashboard",
				},
				{
					label: t("title"),
					to: "/analytics/employees",
				},
			]}
			title={t("title")}
		>
			<AnalalyticsLayout>
				{() => (
					<List data={users} {...{ status }}>
						<List.Filter className="justify-between mb-4">
							<div>
								{status === "idle" && (
									<Tabs.Container>
										<Tab
											color="white"
											active={state === "ACTIVE"}
											onClick={() => setState("ACTIVE")}
										>
											{t("tabs.active")}
											<div className="w-6 h-6 bg-green-400 text-green rounded-md flex justify-center items-center">
												{counts.active}
											</div>
										</Tab>
										<Tab
											color="white"
											active={state === "INVITED"}
											onClick={() => setState("INVITED")}
										>
											{t("tabs.invited")}
											<div className="w-6 h-6 bg-accent text-background-foreground rounded-md flex justify-center items-center">
												{counts.invited}
											</div>
										</Tab>
										<Tab
											color="white"
											active={state === "DELETED"}
											onClick={() => setState("DELETED")}
										>
											{t("tabs.deleted")}
											<div className="w-6 h-6 bg-red-400 text-red rounded-md flex justify-center items-center">
												{counts.deleted}
											</div>
										</Tab>
									</Tabs.Container>
								)}
							</div>
							<List.Search
								onChange={setSearch}
								value={search}
								defaultOpen
							/>
						</List.Filter>
						<List.Head>
							<List.Item className="w-full">
								{t("headers.name")}
							</List.Item>
							<List.Item>{t("headers.progress")}</List.Item>
							<List.Item>{t("headers.actions")}</List.Item>
						</List.Head>
						<List.Items>
							{(item) => (
								<List.Row key={`employee-${item.hid}-list`}>
									<List.Item>
										<Avatar.User user={item} />
									</List.Item>
									<List.Item className="whitespace-nowrap">
										{item.task_statistics && (
											<div className="flex gap-1 text-sm justify-between">
												<span
													className={cn(
														"min-w-2 flex w-full justify-center px-1.5 rounded-sm bg-accent"
													)}
												>
													{
														item.task_statistics
															.backlog
													}
												</span>
												<span>/</span>
												<span
													className={cn(
														`min-w-2 flex w-full justify-center px-1.5 rounded-sm bg-${colors.TODO.bg} text-${colors.TODO.color}`
													)}
												>
													{item.task_statistics.todo}
												</span>
												<span>/</span>
												<span
													className={cn(
														`min-w-2 flex w-full justify-center px-1.5 rounded-sm bg-${colors.IN_PROGRESS.bg} text-${colors.IN_PROGRESS.color}`
													)}
												>
													{
														item.task_statistics
															.in_progress
													}
												</span>
												<span>/</span>
												<span
													className={cn(
														`min-w-2 flex w-full justify-center px-1.5 rounded-sm bg-${colors.COMPLETED.bg} text-${colors.COMPLETED.color}`
													)}
												>
													{
														item.task_statistics
															.completed
													}
												</span>
											</div>
										)}
									</List.Item>

									<List.Item>
										<Button
											iconOnly
											icon="fas fa-eye"
											type="gray"
											to={`/analytics/employees/${item.hid}`}
										/>
									</List.Item>
								</List.Row>
							)}
						</List.Items>
						<List.Loading>
							<List.Item>
								<Avatar.Loader variant="user" />
							</List.Item>
							<List.Item>
								<Skeleton className="w-24 h-4" />
							</List.Item>
							<List.Item>
								<Skeleton className="w-10 h-10" />
							</List.Item>
						</List.Loading>
						<List.Empty>
							<h2>{t("empty.title")}</h2>
							<p>{t("empty.description")}</p>
							<Button type="dark" to="/profile/users">
								{t("empty.button")}
								<i className="far fa-arrow-right text-sm ml-2"></i>
							</Button>
						</List.Empty>
					</List>
				)}
			</AnalalyticsLayout>
		</Layout>
	);
};

EmployeesView.locale = {
	nl: {
		dashboard: "Analytics",
		title: "Medewerkers",
		headers: {
			name: "Medewerker",
			team: "Team",
			progress: "B/T/P/C",
			actions: "Acties",
		},
		tabs: {
			active: "Actief",
			invited: "Uitgenodigd",
			deleted: "Verwijdert",
		},
		empty: {
			title: "Geen gebruikers gevonden",
			description:
				"Er zijn geen gebruikers gevonden, voeg gebruikers toe om mee samen te werken",
			button: "Naar gebruikers",
		},
	},
	en: {
		dashboard: "Analytics",
		title: "Employees",
		headers: {
			name: "Employee",
			team: "Team",
			progress: "B/T/P/C",
			actions: "Actions",
		},
		tabs: {
			active: "Active",
			invited: "Invited",
			deleted: "Deleted",
		},
		empty: {
			title: "No users found",
			description:
				"No users have been found, invite users to collaborate",
			button: "To users",
		},
	},
};

export default withTranslation(EmployeesView);
