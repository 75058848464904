import React from "react";
import { ApiPaymentLine } from "src/api/types";
import { formatPrice } from "src/lib/formatters";

interface CheckoutSummaryItemProps {
	line: ApiPaymentLine;
}

const CheckoutSummaryItem = ({ line }: CheckoutSummaryItemProps) => {
	return (
		<div className="bg-dark-1100 flex justify-between items-center gap-2">
			<p className="mb-0 flex-1">{line.description}</p>
			<p className="mb-0">{formatPrice(line.price)}</p>
		</div>
	);
};

export default CheckoutSummaryItem;
