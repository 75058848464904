import moment, { Moment } from "moment";
import { useCalendar } from "src/components/Calendar";
import Draggable, { useDraggable } from "src/components/draggable";
import { isToday } from "src/lib/dates";
import { cn } from "src/lib/utils";

import { LayoutGroup, motion } from "framer-motion";
import withTranslation, {
	Translation,
} from "src/components/hoc/withTranslation";
import { ucFirst } from "src/lib/formatters";

interface ColumnProps extends Translation {
	children?: any;
}

interface ColumnProps extends Translation {
	date: Moment;
	children?: any;
	head?: JSX.Element;
	footer?: JSX.Element;
	variant?: "scrollable" | "flat" | "month-child";
	className?: string;
}

const Column = ({ t, date, footer, head, variant, className }: ColumnProps) => {
	const { isDraggable } = useDraggable();
	const { items, selected } = useCalendar();
	const columnId = `end_date:${date.format("YYYY-MM-DD")}`;

	const isInactiveMonth =
		variant === "month-child" &&
		date.month() !== moment(selected.value).month();

	return (
		<Draggable.Drop
			id={columnId}
			theme={{
				overClassName: "bg-accent",
			}}
			data-date={date.format("YYYY-MM-DD")}
			className={cn(
				"flex flex-col flex-1 transition-all h-full",
				// isToday(date) && "bg-primary-100",
				"w-[360px] xl:w-full",
				variant === "month-child" && "min-w-[300px]",
				isInactiveMonth && "bg-dark-100",
				className
			)}
		>
			{variant !== "month-child" && (
				<div className={cn("flex group")}>
					<div
						className={cn(
							"flex-1 flex justify-between items-center p-3",
							isToday(date) && "font-bold",
							"border-r [&>*:last-child]:border-r-0 border-border dark:border-accent"
						)}
					>
						<p className="text-[15px] opacity-70">
							{isToday(date)
								? t("today")
								: ucFirst(
										date.format("dddd D").replace(".", "")
								  )}
						</p>
						<div className="flex items-center gap-2">{head}</div>
					</div>
				</div>
			)}
			{variant === "month-child" && (
				<div className="flex group border-t border-accent">
					<div
						className={cn(
							"flex-1 flex justify-between items-center p-3 px-6 pb-0 border-r [&>*:last-child]:border-r-0 border-border dark:border-accent",
							isToday(date) && "font-bold"
						)}
					>
						<div className="flex items-center gap-2">{head}</div>
						<p className="opacity-80">
							{date.format("D").replace(".", "")}
						</p>
					</div>
				</div>
			)}
			<div className="flex flex-1">
				<div
					key={`date-${date}-week`}
					className={cn(
						"flex-1 p-3 [&>*:last-child]:border-r-0 flex flex-col gap-3 group",
						variant === "month-child" && "min-h-24",
						"border-r border-border dark:border-accent"
					)}
				>
					<LayoutGroup>
						{items
							.filter(
								(item) =>
									moment(item.date).format("YYYY-MM-DD") ===
									date.format("YYYY-MM-DD")
							)
							.map((item) =>
								isDraggable ? (
									<Draggable.Item
										key={`item-week-${item.id}`}
										id={item.id}
										data={item?.data}
										currentDropId={columnId}
									>
										<motion.div layout>
											{item.render}
										</motion.div>
									</Draggable.Item>
								) : (
									item.render
								)
							)}
						{footer}
					</LayoutGroup>
				</div>
			</div>
		</Draggable.Drop>
	);
};

Column.locale = {
	nl: {
		today: "Vandaag",
	},
	en: {
		today: "Today",
	},
};

export default withTranslation(Column);
