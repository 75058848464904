import { ComponentProps, createContext, useContext } from "react";
import { ApiBenefit } from "src/api/types";
import Button, { ButtonProps } from "src/components/Button";
import { useT } from "src/components/hoc/withTranslation";
import { cn } from "src/lib/utils";

type BenefitCardContextType = {
	benefit: ApiBenefit;
};

const BenefitCardContext = createContext<BenefitCardContextType>(
	{} as BenefitCardContextType
);

interface BenefitCardProps extends ComponentProps<"div"> {
	benefit: BenefitCardContextType["benefit"];
}

export default function BenefitCard({
	benefit,
	className,
	children,
	...rest
}: BenefitCardProps) {
	return (
		<BenefitCardContext.Provider value={{ benefit }}>
			<div
				className={cn(
					"flex flex-col bg-card border border-border rounded-md group transition-all duration-400 hover:shadow-md",
					benefit.size === "MEDIUM" && "md:col-span-2",
					benefit.size === "LARGE" && "md:col-span-3",

					["MEDIUM", "LARGE"].includes(benefit.size) && "flex-row",

					className
				)}
				{...rest}
			>
				{children}
			</div>
		</BenefitCardContext.Provider>
	);
}

export function BenefitCardHeader({
	children,
	className,
	...rest
}: ComponentProps<"div">) {
	const { benefit } = useContext(BenefitCardContext);
	return (
		<div
			{...rest}
			className={cn(
				"flex flex-col gap-1 p-4",
				benefit.size === "LARGE" && "p-6",
				benefit.size === "MEDIUM" && "p-4",
				className
			)}
		>
			{children}
		</div>
	);
}

export function BenefitCardBanner({
	children,
	...rest
}: ComponentProps<"div">) {
	const { benefit } = useContext(BenefitCardContext);
	return (
		<div
			{...rest}
			className={cn(
				"flex flex-col gap-4 p-4 m-3 justify-center items-center bg-accent border border-border rounded-md",
				benefit.size === "SMALL" && "min-h-[180px]"
			)}
		>
			{children}
		</div>
	);
}

export function BenefitCardFooter({
	children,
	className,
	...rest
}: ComponentProps<"div">) {
	const { benefit } = useContext(BenefitCardContext);
	return (
		<div
			{...rest}
			className={cn(
				"flex flex-col gap-2 p-4 border-t border-border",
				benefit.size === "LARGE" && "p-6",
				benefit.size === "MEDIUM" && "p-4",
				className
			)}
		>
			{children}
		</div>
	);
}

export function BenefitCardImage({
	className,
	...rest
}: ComponentProps<"img">) {
	const { benefit } = useContext(BenefitCardContext);
	if (!benefit.media) {
		return null;
	}
	return (
		<img
			{...rest}
			alt={benefit.name}
			className={cn(
				"w-full object-contain h-full max-w-[200px] bg-card rounded-md",
				className
			)}
			src={benefit.media?.url}
		/>
	);
}

export function BenefitCardName({
	className,
	...rest
}: Omit<ComponentProps<"h2">, "children">) {
	const { benefit } = useContext(BenefitCardContext);
	return (
		<h2 {...rest} className={cn("font-bold text-lg", className)}>
			{benefit.name}
		</h2>
	);
}

export function BenefitCardDescription({
	className,
	...rest
}: Omit<ComponentProps<"p">, "children">) {
	const { benefit } = useContext(BenefitCardContext);
	return (
		<p {...rest} className={cn("", className)}>
			{benefit.description}
		</p>
	);
}

export function BenefitCardButton({
	children,
	className,
	startPathname = "/benefits",
	...rest
}: ButtonProps & { startPathname?: string }) {
	const { benefit } = useContext(BenefitCardContext);
	const t = useT({
		nl: {
			COUPON_CODE: "Code ontgrendelen",
			DIRECT_LINK: "Bekijk de pagina",
			default: "Bekijk korting",
		},
		en: {
			COUPON_CODE: "Unlock code",
			DIRECT_LINK: "View page",
			default: "View discount",
		},
	});
	return (
		<Button
			{...rest}
			className={cn("w-full", className)}
			to={{
				modal: `${startPathname}/${benefit.id}/unlock`,
			}}
		>
			{children || t(benefit.variant || "default")}
		</Button>
	);
}

export function BenefitCardRestriction({
	className,
	...rest
}: Omit<ComponentProps<"p">, "children">) {
	const { benefit } = useContext(BenefitCardContext);
	const t = useT({
		nl: {
			FULL_PROFILE:
				"Om deze korting te kunnen gebruiken, moet je een volledig profiel hebben.",
		},
		en: {
			FULL_PROFILE: "To use this discount, you must have a full profile.",
		},
	});

	if (!benefit.restriction) {
		return null;
	}

	return (
		<div
			{...rest}
			className={cn(
				"opacity-50 transition-all duration-400 group-hover:opacity-100 text-sm",
				className
			)}
		>
			{t(benefit.restriction)}
		</div>
	);
}
