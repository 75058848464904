import withTranslation, {
	Translation,
} from "src/components/hoc/withTranslation";
import ShareButton from "src/components/promotion/share/ShareButton";
import ShareClipboard from "src/components/promotion/share/ShareClipboard";
import useTenant from "src/hooks/api/services/tenants/useTenant";
import useAuth from "src/hooks/selectors/useAuth";

interface ReferralShareProps extends Translation {}

const ReferralShare = ({ t }: ReferralShareProps) => {
	const { tenant } = useTenant();
	const auth = useAuth();
	const clientId = auth?.links?.client
		? `C_${auth?.links?.client}`
		: undefined;
	const url = `${tenant.urls.account}/signup?referral=${
		auth?.company?.slug || clientId
	}`;
	const share = {
		title: t("share.title"),
		description: t("share.description"),
		url,
	};

	return (
		<div className="flex flex-col gap-4">
			<ShareClipboard label={t("copy")} {...{ url }} />
			<div className="flex gap-3 justify-center items-center">
				<ShareButton {...{ share }} type="facebook" />
				<ShareButton {...{ share }} type="linkedin" />
				<ShareButton {...{ share }} type="twitter" />
				<ShareButton {...{ share }} type="whatsapp" />
				<ShareButton {...{ share }} type="mail" />
			</div>
		</div>
	);
};

ReferralShare.locale = {
	nl: {
		share: {
			title: "Meld je ook aan op {{tenant.name}}",
			description:
				"Via de onderstaande link kan je gemakkelijk een account aanmaken voor {{tenant.name}}.",
		},
		copy: "Kopieer link",
		send: "Versturen",
	},
	en: {
		share: {
			title: "Join {{tenant.name}} too",
			description:
				"You can easily create an account for {{tenant.name}} using the link below.",
		},
		copy: "Copy link",
		send: "Send",
	},
};

export default withTranslation(ReferralShare);
