import { useEffect } from "react";
import { useDraggable as useDraggableDnD } from "@dnd-kit/core";
import { cn } from "src/lib/utils";
import { useDraggable } from "src/components/draggable";
import useElementSize from "src/hooks/useElementSize";

interface DraggableItemProps {
	id: number | string;
	currentDropId: string;
	children?: any;
	className?: string;
	options?: {
		disabled?: boolean;
	};
	theme?: {
		activeClassName?: string;
	};
	data?: any;
}

const DraggableItem = ({
	id,
	currentDropId,
	children,
	className,
	options,
	theme,
	data,
}: DraggableItemProps) => {
	const element = useElementSize();
	const {
		attributes,
		listeners,
		setNodeRef: ref,
		active,
	} = useDraggableDnD({ id, data });
	const { onStartDragging, setDraggingChildren } = useDraggable();
	const isActive = active?.id?.toString() === id?.toString();

	const getDragProps = () => {
		if (options?.disabled) return {};
		return {
			ref,
			...listeners,
			...attributes,
		};
	};

	useEffect(() => {
		if (isActive && onStartDragging) {
			onStartDragging(currentDropId);
			setDraggingChildren(children);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isActive]);

	return (
		<>
			<div
				className={cn(
					"relative transition-all",
					!options?.disabled && "cursor-pointer touch-none",
					className,
					isActive && "opacity-40",
					isActive && theme?.activeClassName
				)}
				{...getDragProps()}
			>
				<div className={`relative`} ref={element.ref}>
					<div className="relative w-full flex flex-col">
						{children}
					</div>
				</div>
			</div>
		</>
	);
};

export default DraggableItem;
