import { forwardRef } from "react";
import { useTranslation } from "react-i18next";
import ReactTooltip from "react-tooltip";
import Skeleton from "src/components/Skeleton";
import { cn } from "src/lib/utils";

const LAYOUTS = {
	stacked: {
		wrapper: "",
		label: "",
	},
	horizontal: {
		wrapper: "flex flex-row gap-2",
		label: "flex items-center w-full max-w-[100px]",
	},
};
export type FormGroupLayout = keyof typeof LAYOUTS;

interface FormGroupProps {
	type?: string;
	label?: string;
	error?: any | boolean;
	valid?: string | boolean | number;
	loading?: boolean;
	help?: string;
	children?: any;
	className?: string;
	optional?: boolean;
	ref?: any;
	name?: string;
	noMarginBottom?: boolean;
	disabled?: boolean;
	layout?: FormGroupLayout;
	subLabel?: string | JSX.Element;
}

const FormGroup = forwardRef(
	(
		{
			type,
			label,
			error,
			valid,
			loading,
			help,
			className,
			children,
			name,
			optional,
			layout = "stacked",
			subLabel,
		}: FormGroupProps,
		ref: any
	) => {
		const { t } = useTranslation("form", {
			keyPrefix: "form-group",
		});
		const classes = LAYOUTS[layout];
		// const Label = layout === "horizontal" ? "span" : "span";

		return (
			<div className={cn(className)}>
				{label && (
					<div className="flex justify-between items-center gap-2 mb-1.5">
						<label
							className={cn(
								"flex text-left items-center",
								classes.label
							)}
						>
							{label && (
								<span className="text-[16px] opacity-70">
									{label}
								</span>
							)}
							{valid && !error && (
								<i className="ml-2 fal fa-check"></i>
							)}
							{help && !valid && (
								<span className="ml-2">
									<i
										data-tip
										data-for={`tooltip-${name}`}
										className="fal fa-info-circle"
									></i>
									<ReactTooltip
										id={`tooltip-${name}`}
										effect="solid"
									>
										{help}
									</ReactTooltip>
								</span>
							)}
							{optional && (
								<small className="ml-2">
									({t("optional")})
								</small>
							)}
						</label>
						{subLabel}
					</div>
				)}
				<div className="flex w-full relative rounded-md input-wrapper">
					{children}
					{loading && (
						<div className="absolute top-0 flex items-center right-4 bottom-0">
							<div className="base-loader"></div>
						</div>
					)}
				</div>
				{error && <p className="mt-2 text-sm opacity-50">{error}</p>}
			</div>
		);
	}
) as any;

interface LoadingProps {
	children?: any;
}

const Loading = ({ children }: LoadingProps) => {
	return (
		<div className="flex flex-col gap-2">
			<Skeleton className="w-16 h-4" />
			<div className="flex flex-col flex-1 w-full">{children}</div>
		</div>
	);
};

FormGroup.Loading = Loading;

export default FormGroup;
