import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { EditorPreview } from "src/components/field/fields/Editor/Editor";
import FileList from "src/components/field/utils/FileList";
import ShareTask from "src/components/promotion/share/ShareTask";
import TaskDetailEditor from "src/components/tasks/detail/TaskDetailEditor";
import TaskDetailWrapper from "src/components/tasks/detail/TaskDetailWrapper";
import SelectRegistrationPane from "src/components/tasks/panes/SelectRegistrationPane";
import StatisticsPane from "src/components/tasks/panes/StatisticsPane";
import SubtasksTable from "src/components/tasks/sub-tasks/SubtasksTable";
import PublishInviteTask from "src/components/tasks/TaskForm/elements/PublishInviteTask";
import TaskInvites from "src/components/tasks/TaskInvites";
import useTask from "src/hooks/api/services/tasks/task/useTask";
import useTaskAutoSave from "src/hooks/api/services/tasks/task/useTaskAutoSave";
import useAuth from "src/hooks/selectors/useAuth";
import useWindowSize from "src/hooks/useWindowSize";

const TasksDetailNormalView = () => {
	const { t } = useTranslation("task-view", {
		keyPrefix: "detail-view",
	});
	const auth = useAuth();
	const { id, pane } = useParams() as any;
	const { task: data, status, actions } = useTask({ id });
	useTaskAutoSave({ id });
	const { isTablet } = useWindowSize();
	const [openSidebar, setOpenSidebar] = useState(isTablet ? false : true);
	// const canEdit = auth.
	useEffect(() => {
		if (["show"].includes(pane)) {
			setOpenSidebar(false);
		} else if (["editor"].includes(pane)) {
			setOpenSidebar(isTablet ? false : true);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [pane]);

	return (
		<TaskDetailWrapper
			sidebar={{
				open: openSidebar,
				onOpenChange: setOpenSidebar,
			}}
			options={{
				hideSidebar: !(data?.id && data?.can.includes("edit")),
			}}
			task={data}
			loading={status === "loading"}
			container={pane === "editor" ? "max-w-xl mx-auto" : undefined}
		>
			{data?.id ? (
				<>
					{pane === "invites" && auth.type === "company" && (
						<>
							<TaskInvites task={data} />
							{!data.is_visible && !data.is_matched && (
								<PublishInviteTask
									task={data}
									onSubmit={(task) => {
										actions.get();
									}}
								/>
							)}
							{data?.url && data.is_visible && (
								<div className="flex justify-center items-center text-center py-8">
									<ShareTask
										variant="buttons"
										budget={data?.budget}
										task_id={data?.slug || ""}
										description={data?.description}
										title={data?.title || ""}
										url={data?.url}
									/>
								</div>
							)}
						</>
					)}

					{pane === "statistics" && data?.id && (
						<StatisticsPane task={data} />
					)}

					{pane === "editor" && data?.id && (
						<div className="flex flex-1 flex-col gap-4">
							<TaskDetailEditor className="flex-1 flex flex-col" />
						</div>
					)}

					{pane === "show" &&
						data.type === "PUBLISHED" &&
						!data?.can.includes("edit") && (
							<EditorPreview value={data.description} />
						)}

					{pane === "show" &&
						!data?.has_subtasks &&
						data.type === "PUBLISHED" &&
						data?.can.includes("edit") && (
							<SelectRegistrationPane />
						)}

					{pane === "show" && data.has_subtasks && (
						<SubtasksTable task={data} />
					)}

					{pane === "files" && (
						<FileList
							files={data?.media || []}
							onChange={(media) =>
								actions.change({
									media,
								})
							}
						/>
					)}
				</>
			) : (
				<div className="flex flex-col justify-center items-center flex-1">
					{!["idle", "error"].includes(status) ? (
						<span className="base-loader"></span>
					) : (
						<strong>{t("empty.title")}</strong>
					)}
				</div>
			)}
		</TaskDetailWrapper>
	);
};

export default TasksDetailNormalView;
