import { store } from "src/lib/jotai";
import { ApiTaskKanban } from "src/api/types";
import { TaskSocketEvent } from "src/hooks/api/tasks/useTasksListeners";
import axios from "src/lib/api";
import useAtomIds from "src/hooks/api/utils/useAtomIds";

export function useTasksUpdater() {
	const atomIds = useAtomIds();

	const handleStatus = (event: any) => {
		const atoms = atomIds.getAtomsByGroup("TASKS");

		for (let atom of atoms) {
			store.set(atom, (prev: any) => ({
				...prev,
				data:
					(prev?.data || {})?.id === event?.id
						? {
								...(prev?.data || {}),
								status: event.to,
						  }
						: prev?.data,
				list: (prev.list || []).map((item: any) =>
					item.id === event.id
						? {
								...item,
								status: event.to,
						  }
						: item
				),
			}));
		}
	};

	const handleEvent = async (event: TaskSocketEvent) => {
		const task: ApiTaskKanban = event?.data || {};
		const _id = task?._id;
		// console.log("SOCKET", event.type, {
		// 	_id,
		// 	task,
		// });

		//Cancel if no data is set
		if (!task?.id && event.type !== "DELETE") return;

		const atoms = atomIds.getAtomsByGroup("TASKS");

		for (let atom of atoms) {
			//Check if the task is allowed to be processed
			const state: any = store.get(atom);
			let data: any = event?.data;
			let isAllowed = false;

			if (state.isAllowed && data?.id) {
				isAllowed = state.isAllowed(data);
			} else {
				if (["CREATE"].includes(event.type)) {
					if (Object.keys(state.filter)) {
						const result = await axios
							.get(`/client/tasks/kanban/tasks/${event.id}`, {
								params: {
									filter: state.filter,
									validate_filter: true,
								},
							})
							.then(({ data }) => data);
						data = result?.data?.id ? result?.data : null;
						isAllowed = data?.id ? true : false;
					} else {
						isAllowed = false;
					}
				} else {
					isAllowed = true;
				}
			}

			store.set(atom, (state: any) => {
				let data = state?.data || {};
				let list = state?.list || [];

				//Check if item is allowed in list, else set the type to delete
				if (event.type === "UPDATE") {
					list = list.map((item: any) => {
						if (item.id === event.id) {
							return {
								...item,
								...(event?.data || {}),
							};
						}
						return item;
					});

					if (
						isAllowed &&
						!list.find(
							(item: ApiTaskKanban) => item.id === event.id
						)
					) {
						list = [task, ...list];
					}

					if (!isAllowed) {
						list = list.filter((item: any) => item.id !== event.id);
					}
					data = data?.id === event.id ? { ...data, ...task } : data;
				}

				if (event.type === "DELETE") {
					list = list.filter((item: any) => item.id !== event.id);
					data = data?.id === event.id ? {} : data;
				}
				if (event.type === "CREATE" && isAllowed) {
					if (!_id || !list.find((item: any) => item._id === _id)) {
						list = [task, ...list];
					}
				}

				return {
					...state,
					data,
					list,
				};
			});
		}
	};

	return { handleEvent, handleStatus };
}
