import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import LanguageSelectList from "src/components/language/LanguageSelectList";
import Container from "src/components/layout/Container";
import { useParseRedirectUrl } from "src/hooks/useRedirectUrl";

export default function LanguageView() {
	const to = useParseRedirectUrl();
	const navigate = useNavigate();
	const { t } = useTranslation();

	const onSubmit = () => {
		navigate(to || "/dashboard");
	};

	return (
		<div className="flex flex-col flex-1 justify-center overflow-hidden">
			<Container variant="xsmall">
				<h1>{t("translation.choose", { ns: "common" })}</h1>
				<LanguageSelectList {...{ onSubmit }} className="mt-8" />
			</Container>
		</div>
	);
}
