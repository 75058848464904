import Loader from "src/components/Loader";

interface AddressItemProps {
	description: string;
	search?: string;
	onClick?: () => void;
	fetching?: boolean;
}

const AddressItem = ({
	description,
	search,
	onClick,
	fetching,
}: AddressItemProps) => {
	const parts = search
		? description
				.split(new RegExp(`(${search})`, "gi"))
				.filter((item) => item)
		: [description];

	return (
		<div
			{...{ onClick }}
			className="py-2 px-4 cursor-pointer rounded-md flex items-center gap-2 justify-between transition-all hover:bg-accent"
		>
			<p>
				{parts.map((item) =>
					item.toLowerCase() === search?.toLowerCase() ? (
						<span className="font-bold" key={item}>
							{item}
						</span>
					) : (
						item
					)
				)}
			</p>
			{fetching ? (
				<Loader />
			) : (
				<i className="fal fa-arrow-right text-sm"></i>
			)}
		</div>
	);
};

export default AddressItem;
