import React from "react";
import { getRandomGif } from "src/lib/gifs";

interface RandomGifProps {
	type: "success" | "error";
	className?: string;
}

const RandomGif = ({ type, className }: RandomGifProps) => {
	const src = getRandomGif(type);
	let _className = `${className} rounded-md`;

	if (!_className?.includes("w-")) {
		_className += " w-full";
	}

	return <img {...{ src }} className={_className} alt={type} />;
};

export default RandomGif;
