import { Moment } from "moment";
import { ComponentProps } from "react";
import { useWeekTimeContext } from "src/components/Calendar/week-time/WeekTime";
import useWeekTimePosition from "src/components/Calendar/week-time/useWeekTimePosition";
import Link, { LinkTo } from "src/components/Link";
import { cn } from "src/lib/utils";

interface WeekTimeItemProps {
	data: {
		start: Moment;
		end: Moment;
	};
	onDateChange: (start: Moment, end: Moment) => void;
	to?: LinkTo;
}

const WeekTimeItem = ({
	data,
	onDateChange,
	children,
	className,
	to,
	...rest
}: ComponentProps<"div"> & WeekTimeItemProps) => {
	const { dates } = useWeekTimeContext();
	const { position } = useWeekTimePosition({
		...data,
		dates,
		onDateChange,
	});
	const Wrapper = to ? Link : "div";

	return (
		<Wrapper
			{...rest}
			{...({ to } as any)}
			style={{
				...(rest?.style || {}),
				gridColumn: `${position.column.start}/${position.column.end}`,
				gridRowStart: position.rows.start + 1,
				gridRowEnd: position.rows.end + 1,
			}}
			className="flex flex-col flex-1 relative z-20"
		>
			<div
				className={cn(
					"bg-card shadow border border-border rounded-md relative overflow-hidden flex flex-col flex-1 m-1",
					className
				)}
			>
				{/* <ResizeHandle
					onChange={(value) => {
						actions.moveY("start", value);
					}}
					variant="top"
				/> */}

				<div className="px-1">
					<small className="opacity-70 leading-full">
						{data.start.format("HH:mm")} -{" "}
						{data.end.format("HH:mm")}
					</small>
				</div>
				{children as any}

				{/* <ResizeHandle
					onChange={(value) => {
						actions.moveY("end", value);
					}}
					variant="bottom"
				/> */}
			</div>
		</Wrapper>
	);
};

export default WeekTimeItem;
