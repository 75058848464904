import React from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import Button from "src/components/Button";
import Card from "src/components/Card";
import CheckoutPaymentMethods from "src/components/checkout/CheckoutPaymentMethods";
import CheckoutSummary, {
	CheckoutSummaryLoading,
} from "src/components/checkout/CheckoutSummary";
import Layout from "src/components/layout/Layout";
import useGet from "src/hooks/rest/useGet";
import usePost from "src/hooks/rest/usePost";
import useAlert from "src/hooks/useAlert";
import useSubscription from "src/hooks/selectors/useSubscription";
import useAuth from "src/hooks/selectors/useAuth";
import { useParseBackUrl } from "src/hooks/useBackUrl";
import { CheckoutType } from "src/api/types";
import useQueryString from "src/hooks/useQueryString";
import BackButton from "src/components/BackButton";
import Container from "src/components/layout/Container";
import { useTranslation } from "react-i18next";
import useTenant from "src/hooks/api/services/tenants/useTenant";

export default function RefreshPaymentsView() {
	const { t } = useTranslation("payment-view", { keyPrefix: "refresh-view" });
	const { id } = useParams() as any;
	const [refreshPayment, refreshing] = usePost(
		`/company/payments/${id}/refresh`
	);
	const [{ data }, loading] = useGet(`/company/payments/${id}?with[]=lines`);
	const [sendAlert] = useAlert();
	const navigate = useNavigate();
	const subscription = useSubscription();
	const isChargeback = subscription?.payment?.status === "chargeback";
	const methods = ["ideal"] as CheckoutType[];
	const auth = useAuth();
	const redirectUrl = useParseBackUrl();
	const query = useQueryString();
	const location = useLocation();
	const { tenant } = useTenant();

	if (!isChargeback) methods.push("mandate");

	if (
		auth?.company?.permissions?.includes("pay_with_invoice") &&
		data?.type === "checkout"
	) {
		methods.push("invoice");
	}

	const handleError = () => {
		sendAlert({
			type: "error",
			title: t("error.title"),
			text: t("error.description"),
		});
	};

	const handleSubmit = (method: string) => {
		let data = {
			payment_method: method,
		} as any;
		if (redirectUrl) {
			data.redirect_url = `${
				tenant?.urls?.account + redirectUrl
			}?payment_returned=1`;
		}

		if (method === "invoice") {
			data.redirect_url = `${
				tenant?.urls?.account + location.pathname
			}?payment_returned=1`;

			if (query?.back) {
				data.redirect_url += `&back=${query.back}`;
			}
		}

		refreshPayment(data)
			.then(({ data }) => {
				const url = data?.redirect_url;
				if (url) {
					if (url.startsWith("/")) {
						navigate(url);
					} else {
						window.location = url;
					}
				} else {
					handleError();
				}
			})
			.catch(handleError);
	};

	if (query?.payment_returned && data?.type === "invoice") {
		return (
			<Layout hideNav>
				<Container className="pt-8 flex flex-col flex-1">
					<BackButton />
					<h2 className="text-center my-4">
						{t("invoice-created.title")}
					</h2>
					<iframe
						src={data?.invoice.url}
						title={t("invoice-created.iframe-title")}
						className="flex flex-col flex-1"
					></iframe>
				</Container>
			</Layout>
		);
	}

	if (
		data?.id &&
		["completed", "reserved", "pending"].includes(data.status)
	) {
		return (
			<Layout hideNav>
				<div className="container pt-16">
					<Card
						className="max-w-md mx-auto"
						contentClassName="flex items-center justify-center flex-col"
					>
						<h2 className="text-center mb-4">
							{t("failed.title")}
						</h2>
						{data?.status && (
							<p className="max-w-[400px] text-center mb-8">
								{data.status === "pending"
									? t("failed.description-pending")
									: t("failed.description-disabled")}
							</p>
						)}
						<Button to="/payments">
							<i className="fas fa-arrow-left mr-2"></i>
							{t("failed.button")}
						</Button>
					</Card>
				</div>
			</Layout>
		);
	}

	return (
		<Layout hideNav>
			<div className="md:container pt-16">
				{isChargeback && subscription?.payment?.hid === data?.hid && (
					<div className="block rounded-md text-white text-center max-w-[640px] mx-auto right-0 p-4 bg-red z-20">
						<strong>{t("account-disabled")}</strong>
					</div>
				)}

				<div className="grid grid-cols-2 max-w-[1080px] items-center w-full mx-auto mt-16">
					{loading ? (
						<CheckoutSummaryLoading />
					) : (
						<CheckoutSummary payment={data} />
					)}
					<div className="my-12 md:relative sticky bottom-0 left-0 right-0 z-20">
						<Card contentClassName="pt-12 px-8 md:p-2">
							<CheckoutPaymentMethods
								{...{ methods }}
								variant={auth.variant}
								onSubmit={handleSubmit}
								pending={refreshing ? true : undefined}
							/>
						</Card>
					</div>
				</div>
			</div>
		</Layout>
	);
}
