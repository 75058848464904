import { Calendar as CalendarComp } from "src/components/ui/calendar";

import moment from "moment";
import { useDateRangeFilter } from "src/components/date-range-filter/Provider";
import withTranslation, {
	Translation,
} from "src/components/hoc/withTranslation";
import useWindowSize from "src/hooks/useWindowSize";

interface CalendarProps extends Translation {
	months?: number;
}

const Calendar = ({ t, months = 2 }: CalendarProps) => {
	const { isPhone } = useWindowSize();
	const { value, onChange } = useDateRangeFilter();
	return (
		<div className="flex flex-col gap-2">
			<div className="flex gap-2 relative flex-1">
				<CalendarComp
					size="small"
					mode="range"
					numberOfMonths={isPhone ? 1 : months}
					selected={{
						from: value.start.toDate(),
						to: value?.end?.toDate(),
					}}
					className="p-0"
					classNames={{
						day_outside: "is-invisible-day",
					}}
					onSelect={(value) => {
						if (value) {
							onChange({
								start: moment(
									moment(value.from).format("YYYY-MM-DD"),
									"YYYY-MM-DD"
								),
								end: value?.to
									? moment(
											moment(value.to).format(
												"YYYY-MM-DD"
											),
											"YYYY-MM-DD"
									  )
									: null,
							});
						}
					}}
				/>
			</div>
			<div className="">
				<small className="text-[#767777]">
					{value?.end
						? t("description", {
								start: value.start.format("DD-MM-YYYY"),
								end: value.end.format("DD-MM-YYYY"),
						  })
						: value.start.format("DD-MM-YYYY")}
				</small>
			</div>
		</div>
	);
};

Calendar.locale = {
	nl: {
		description: "{{start}} tot {{end}}",
	},
	en: {
		description: "{{start}} till {{end}}",
	},
};

export default withTranslation(Calendar);
