import React from "react";
import { useEffect } from "react";
import useAuth from "src/hooks/selectors/useAuth";
import "./header.scss";

type HeaderProps = {
	type: string;
	children?: any;
	hideLogo?: boolean;
	card?: any;
	full?: boolean;
	className?: string;
	container?: string;
	small?: boolean;
	xsmall?: boolean;
	floatingBlock?: any;
};

const Header = ({
	type,
	children,
	hideLogo,
	card,
	full,
	className,
	container,
	small,
	xsmall,
	floatingBlock,
}: HeaderProps) => {
	const auth = useAuth();
	const classes = ["header", `header_${type}`];

	if (card) classes.push("header_has-card");

	if (full) classes.push("header_full");
	if (small) classes.push("header_small");
	if (xsmall) classes.push("header_xsmall");

	if (className) classes.push(className);

	let containerClassName = full ? "container" : "container-small";
	if (container) containerClassName = container;

	if (auth.type === "company") {
		containerClassName = "container-lg";
	}

	useEffect(() => {
		const dark = ["student", "white"];
		const main = document.querySelector("body");

		if (dark.includes(type)) {
			main?.classList.add("nav_dark");
		} else {
			main?.classList.remove("nav_dark");
		}
	}, [type]);

	if (small) {
		return (
			<div className={classes.join(" ")}>
				<div className={containerClassName}>
					<div className="header-content">{children || false}</div>
				</div>
			</div>
		);
	}

	return (
		<div className={classes.join(" ")}>
			<div className={containerClassName}>
				<div className="header-content">
					{floatingBlock ? (
						<div className="floating-block">{floatingBlock}</div>
					) : (
						false
					)}

					{children || false}
				</div>
				{card && <div className="header-card">{card}</div>}
			</div>
		</div>
	);
};

Header.defaultProps = {
	children: undefined,
	type: "primary",
};

export default Header;
