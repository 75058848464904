import { ComponentProps, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Link from "src/components/Link";
import Loader from "src/components/Loader";
import withTranslation, {
	Translation,
} from "src/components/hoc/withTranslation";
import PublishTaskButton from "src/components/tasks/PublishTaskButton";
import useLimits from "src/hooks/api/services/auth/useLimits";
import useTask from "src/hooks/api/tasks/useTask";
import useTaskForm from "src/hooks/api/tasks/useTaskForm";
import useBackUrl from "src/hooks/useBackUrl";
import { cn } from "src/lib/utils";

const TaskQuickActions = ({
	t,
	className,
	...rest
}: Translation & ComponentProps<"div">) => {
	const { pane } = useParams();
	const navigate = useNavigate();
	const { task } = useTask();
	const { actions } = useTaskForm();
	const back = useBackUrl();
	const { limits } = useLimits();
	const [status, setStatus] = useState<"idle" | "deleting" | "duplicating">(
		"idle"
	);

	if (!task?.can?.includes("edit")) {
		return null;
	}

	return (
		<div {...rest} className={cn("flex flex-col gap-4", className)}>
			{task.type === "INTERNAL" && (
				<>
					{limits?.published_tasks?.is_available && (
						<PublishTaskButton
							id={task.hid}
							variant="list-button"
						/>
					)}
					<div
						className="flex gap-2 items-center cursor-pointer"
						onClick={() => {
							setStatus("duplicating");
							actions.duplicate(task.id).then((res) => {
								navigate(`/tasks/${res.hid}/editor?${back}`);
								setStatus("idle");
								return;
							});
						}}
					>
						<div className="w-5 flex justify-center items-center">
							{status === "duplicating" ? (
								<Loader />
							) : (
								<i className="far fa-copy"></i>
							)}
						</div>
						<span>{t("duplicate")}</span>
					</div>
				</>
			)}
			{task.type === "PUBLISHED" && (
				<>
					<Link
						to={`/tasks/create?repeat=${task.hid}&${back}`}
						className="flex gap-2 items-center cursor-pointer"
					>
						<div className="w-5 flex justify-center items-center">
							<i className="far fa-retweet"></i>
						</div>
						<span>{t("repeat")}</span>
					</Link>
					<Link
						to={{
							modal: `/tasks/${task.hid}/${pane}/mass-message`,
						}}
						className="flex gap-2 items-center cursor-pointer"
					>
						<div className="w-5 flex justify-center items-center">
							<i className="far fa-comments"></i>
						</div>
						<span>{t("mass-message")}</span>
					</Link>
				</>
			)}
			<div
				className="flex gap-2 items-center cursor-pointer"
				onClick={() => {
					setStatus("deleting");
					actions.archive(task.id).then(() => {
						setStatus("idle");
						navigate("/tasks");
					});
				}}
			>
				<div className="w-5 flex justify-center items-center">
					{status === "deleting" ? (
						<Loader />
					) : (
						<i className="far fa-archive"></i>
					)}
				</div>
				<span>{t("archive")}</span>
			</div>
		</div>
	);
};

TaskQuickActions.locale = {
	nl: {
		repeat: "Opnieuw uitbesteden",
		duplicate: "Dupliceren",
		archive: "Archiveren",
		"mass-message": "Massabericht",
	},
	en: {
		repeat: "Outsource again",
		duplicate: "Duplicate",
		archive: "Archive",
		"mass-message": "Mass message",
	},
};

export default withTranslation(TaskQuickActions);
