import { useEffect } from "react";
import { stagger, useAnimate } from "framer-motion";

const staggerMenuItems = stagger(0.03, { startDelay: 0.2 });

export default function useDescriptionAnimate(
	isLineThrough: boolean,
	disabled?: boolean
) {
	const [scope, animate] = useAnimate();
	useEffect(() => {
		if (!disabled) {
			animate(scope.current, {
				opacity: isLineThrough ? 0.6 : 1,
			});

			animate(
				".anim-line .anim-line_line",
				isLineThrough ? { width: "100%" } : { width: 0 },
				{
					delay: isLineThrough ? staggerMenuItems : 0,
				}
			);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isLineThrough]);

	return scope;
}
