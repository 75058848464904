import { ComponentProps, createContext, useContext, useMemo } from "react";
import {
	ApiSubscriptionPlan,
	ApiSubscriptionPlanInterval,
} from "src/api/types";
import Badge, { BadgeProps } from "src/components/Badge";
import Button, { ButtonProps } from "src/components/Button";
import { useT } from "src/components/hoc/withTranslation";
import Indicator from "src/components/layout/Indicator";
import Skeleton from "src/components/Skeleton";
import useTenant from "src/hooks/api/services/tenants/useTenant";
import { formatPrice } from "src/lib/formatters";
import { cn } from "src/lib/utils";

interface PlanContextProps {
	plan: ApiSubscriptionPlan;
	isMostCommon?: boolean;
	getPlanCheckoutUrl: (
		plan: ApiSubscriptionPlan,
		interval: ApiSubscriptionPlanInterval
	) => string;
}

export interface PlanProps {
	plan: PlanContextProps["plan"];
	isMostCommon?: boolean;
	getPlanCheckoutUrl: PlanContextProps["getPlanCheckoutUrl"];
}

const PlanContext = createContext<PlanContextProps>({
	plan: {} as PlanProps["plan"],
	isMostCommon: false,
	getPlanCheckoutUrl: (plan, interval) =>
		`/subscription/${plan.slug}?interval=${interval}`,
});
const usePlanContext = () => useContext(PlanContext);

export default function Plan({
	plan,
	isMostCommon,
	children,
	className,
	getPlanCheckoutUrl,
	...rest
}: PlanProps & Omit<ComponentProps<"div">, "onSelect">) {
	return (
		<PlanContext.Provider
			value={{
				plan: {
					...plan,
				},
				getPlanCheckoutUrl,
				isMostCommon,
			}}
		>
			<div
				className={cn(
					"relative group",
					isMostCommon && "z-10 scale-[1.01] md:scale-[1.02]",
					className
				)}
				{...(rest as any)}
			>
				{plan ? (children as any) : null}
			</div>
		</PlanContext.Provider>
	);
}

export const PlanSpecialBackground = ({
	className,
	...rest
}: ComponentProps<"div">) => {
	const { isMostCommon } = usePlanContext();
	if (!isMostCommon) return null;
	return (
		<div
			{...rest}
			className={cn(
				"absolute -inset-2 bg-primary opacity-20 z-10 rounded-lg group-hover:opacity-80 transition-all hidden md:flex",
				className
			)}
		></div>
	);
};

export const PlanCard = ({
	className,
	children,
	...rest
}: ComponentProps<"div">) => {
	const { isMostCommon } = usePlanContext();

	return (
		<div
			className={cn(
				"flex flex-col gap-8 bg-card px-8 pb-8 pt-12 relative rounded-md shadow",
				isMostCommon &&
					"z-10 border border-primary md:border-transparent",
				className
			)}
			{...rest}
		>
			{isMostCommon && (
				<PlanMostCommonBadge className="absolute top-3 right-3" />
			)}

			{children as any}
		</div>
	);
};

export const PlanMostCommonBadge = ({ className, ...rest }: BadgeProps) => {
	const t = useT({
		nl: {
			label: "Meest gekozen",
		},
		en: {
			label: "Most common",
		},
	});
	return (
		<Badge
			variant="primary"
			className={cn("w-max uppercase border-0 text-sm py-2", className)}
			size="default"
			{...rest}
		>
			<p>{t("label")}</p>
		</Badge>
	);
};

export const PlanInterval = ({ className, ...rest }: ComponentProps<"p">) => {
	const { plan } = usePlanContext();
	const t = useT({
		nl: {
			MONTHLY: "Maandelijks",
			YEARLY: "Per 12 maanden",
			HALF_YEARLY: "Per 6 maanden",
			QUARTERLY: "Per 3 maanden",
		},
		en: {
			MONTHLY: "Monthly",
			YEARLY: "Yearly",
			HALF_YEARLY: "Half yearly",
			QUARTERLY: "Quarterly",
		},
	});
	return (
		<p {...rest} className={cn("", className)}>
			{t(plan.interval)}
		</p>
	);
};

export const PlanTitle = ({ className, ...rest }: ComponentProps<"p">) => {
	const { plan } = usePlanContext();
	return (
		<p className={cn("font-bold", className)} {...rest}>
			{plan.name}
		</p>
	);
};

export const PlanServices = ({ className, ...rest }: ComponentProps<"div">) => {
	const { plan } = usePlanContext();
	return (
		<div {...rest} className={cn("flex flex-col gap-2", className)}>
			{plan.services.map((service) => {
				const isComingSoon = service.trim().endsWith("(coming soon)");
				service = service.replace("(coming soon)", "");
				return (
					<div
						key={`${plan.slug}-${service}`}
						className="flex flex-row gap-2"
					>
						<div>
							<i className="far fa-check"></i>
						</div>
						<p className="flex items-center gap-4 flex-1">
							{service}
							{isComingSoon && (
								<Indicator className="w-max rounded-sm bg-background text-background-foreground py-3">
									Coming soon
								</Indicator>
							)}
						</p>
					</div>
				);
			})}
		</div>
	);
};

export const PlanPrice = ({ className, ...rest }: ComponentProps<"p">) => {
	const { plan } = usePlanContext();
	return (
		<p {...rest} className={cn("flex flex-col", className)}>
			{formatPrice(plan.price)}
		</p>
	);
};

export const PlanPricePerMonth = ({
	className,
	...rest
}: ComponentProps<"p">) => {
	const { plan } = usePlanContext();
	const price = useMemo(() => {
		if (!plan.interval) return plan.price;

		const divide = {
			YEARLY: 12,
			HALF_YEARLY: 6,
			QUARTERLY: 3,
			MONTHLY: 1,
			ONE_TIME: 1,
		} as {
			[key in ApiSubscriptionPlan["interval"]]: number;
		};

		return plan.price / divide[plan.interval];
	}, [plan]);

	return (
		<p
			{...rest}
			className={cn("text-3xl leading-[100%] font-bold", className)}
		>
			{formatPrice(price)}
		</p>
	);
};

export const PlanNormalMonthlyPrice = ({
	className,
	...rest
}: ComponentProps<"p">) => {
	const { tenant } = useTenant();

	if (tenant.slug !== "getjobsdone") {
		return null;
	}

	return (
		<p
			{...rest}
			className={cn(
				"text-3xl line-through opacity-40 leading-[100%] font-bold",
				className
			)}
		>
			{formatPrice(79.95)}
		</p>
	);
};

export const PlanPaymentInterval = ({
	className,
	...rest
}: ComponentProps<"p">) => {
	const { plan } = usePlanContext();
	return (
		<p {...rest} className={cn("flex flex-col", className)}>
			{formatPrice(plan.price)}
		</p>
	);
};

export const PlanPerMonthLabel = ({
	className,
	...rest
}: ComponentProps<"p">) => {
	const { plan } = usePlanContext();
	const t = useT({
		nl: {
			label: "/ maand",
			excl_vat: "excl. BTW",
		},
		en: {
			label: "/ month",
			excl_vat: "excl. BTW",
		},
	});

	if (plan.interval === "ONE_TIME") {
		return (
			<p
				{...rest}
				className={cn("opacity-50 leading-[100%] relative", className)}
			>
				{t("excl_vat")}
			</p>
		);
	}

	return (
		<p
			{...rest}
			className={cn("opacity-50 leading-[100%] relative", className)}
		>
			{t("label")}

			<sup>*</sup>
		</p>
	);
};

interface PlanSelectButtonProps extends ButtonProps {
	label?: "select" | "direct" | "claim-deal";
}

export const PlanSelectButton = ({
	className,
	label = "select",
	...rest
}: PlanSelectButtonProps) => {
	const { plan, isMostCommon, getPlanCheckoutUrl } = usePlanContext();
	const t = useT({
		nl: {
			select: "Selecteer",
			direct: "Begin direct",
			"claim-deal": "Claim de deal",
		},
		en: {
			select: "Select",
			direct: "Direct",
			"claim-deal": "Claim deal",
		},
	});

	return (
		<Button
			{...rest}
			disabled={!plan?.statuses?.can_upgrade}
			className={cn(className)}
			type={isMostCommon ? "primary" : undefined}
			to={getPlanCheckoutUrl(plan, plan.interval)}
		>
			{t(label)}
		</Button>
	);
};

interface PlanServiceFeeProps extends ComponentProps<"p"> {
	variant?: "default" | "instead-of";
}

export const PlanServiceFee = ({
	className,
	variant = "default",
	...rest
}: PlanServiceFeeProps) => {
	const { plan } = usePlanContext();
	const t = useT({
		nl: {
			label: "Success fee:",
			"label-full": "Succes fee van {{fee}}%",
			"instead-of": "Succes fee van {{fee}}% i.p.v {{normal}}%",
		},
		en: {
			label: "Success fee",
		},
	});

	const serviceFee = plan.service_fee * 100;
	const normalServiceFee = (plan.discount?.original_service_fee || 0) * 100;

	if (serviceFee === normalServiceFee || !normalServiceFee) {
		if (variant === "instead-of") {
			return (
				<p className={cn("flex gap-1.5", className)} {...rest}>
					<span>
						{t("label-full", {
							fee: serviceFee,
						})}
					</span>
				</p>
			);
		}
		return (
			<p className={cn("flex gap-1.5", className)} {...rest}>
				<span>{t("label")}</span>
				<span className="font-bold">{serviceFee}%</span>
			</p>
		);
	}

	if (variant === "instead-of") {
		return (
			<p className={cn("flex gap-1.5", className)} {...rest}>
				<span>
					{t("instead-of", {
						fee: serviceFee,
						normal: normalServiceFee,
					})}
				</span>
			</p>
		);
	}

	return (
		<p className={cn("flex gap-1.5", className)} {...rest}>
			<span>{t("label")}</span>
			{plan.discount && (
				<span className="font-bold line-through opacity-50">
					{normalServiceFee}%
				</span>
			)}
			<span className="font-bold">{serviceFee}%</span>
		</p>
	);
};

export const PlanServiceFeeNormal = ({
	className,
	...rest
}: ComponentProps<"p">) => {
	const { plan } = usePlanContext();
	const t = useT({
		nl: {
			label: "normaal",
		},
		en: {
			label: "normally",
		},
	});
	if (
		plan.discount?.original_service_fee === undefined ||
		plan.service_fee === plan.discount?.original_service_fee
	) {
		return null;
	}
	const serviceFee = plan.discount?.original_service_fee * 100;
	return (
		<p className={cn("opacity-70", className)} {...rest}>
			{t("label")} {serviceFee}%
		</p>
	);
};

export const PlanSkeleton = ({ isMostCommon }: { isMostCommon?: boolean }) => {
	return (
		<div
			className={cn(
				"relative w-full flex-1",
				isMostCommon && "z-10 scale-[1.02]"
			)}
		>
			<div className="flex flex-col gap-8 bg-card px-8 pb-8 pt-12 relative rounded-md shadow">
				<div className="flex flex-col gap-3">
					<Skeleton className="w-32 h-6 bg-primary" />
					<div className="flex flex-col gap-3">
						<Skeleton className="w-32 h-4 opacity-50" />
						<div className="flex gap-2 items-end">
							<Skeleton className="w-24 h-10" />
							<Skeleton className="w-16 h-6 opacity-50" />
						</div>
					</div>
					<Skeleton className="w-40 h-4 opacity-50" />
				</div>
				<hr className="border-border" />
				<div className="flex flex-col gap-2">
					<div className="flex flex-row gap-2">
						<Skeleton className="w-6 h-6 rounded-full" />
						<Skeleton className="w-full h-5" />
					</div>
					<div className="flex flex-row gap-2">
						<Skeleton className="w-6 h-6 rounded-full" />
						<Skeleton className="w-full h-5" />
					</div>
					<div className="flex flex-row gap-2">
						<Skeleton className="w-6 h-6 rounded-full" />
						<Skeleton className="w-full h-5" />
					</div>
					<div className="flex flex-row gap-2">
						<Skeleton className="w-6 h-6 rounded-full" />
						<Skeleton className="w-full h-5" />
					</div>
					<div className="flex flex-row gap-2">
						<Skeleton className="w-6 h-6 rounded-full" />
						<Skeleton className="w-full h-5" />
					</div>
					<div className="flex flex-row gap-2">
						<Skeleton className="w-6 h-6 rounded-full" />
						<Skeleton className="w-full h-5" />
					</div>
					<div className="flex flex-row gap-2">
						<Skeleton className="w-6 h-6 rounded-full" />
						<Skeleton className="w-full h-5" />
					</div>
					<div className="flex flex-row gap-2">
						<Skeleton className="w-6 h-6 rounded-full" />
						<Skeleton className="w-full h-5" />
					</div>
				</div>
				<div className="flex flex-col gap-4">
					<Skeleton className="w-full h-10" />
					<div className="flex justify-center items-center">
						<Skeleton className="w-32 h-4 bg-primary" />
					</div>
				</div>
			</div>
		</div>
	);
};

export const PlanDisclaimer = ({ className, ...rest }: ComponentProps<"p">) => {
	const t = useT({
		nl: {
			disclaimer:
				"* De prijzen van de {{tenant.name}}-abonnementen zijn exclusief btw. Na afloop van de gekozen periode wordt het abonnement met dezelfde periode verlengd. Abonnementen zijn tijdens de periode opzegbaar; het abonnement blijft dan geldig totdat de periode is verstreken.",
		},
		en: {
			disclaimer:
				"The prices of the {{tenant.name}} subscriptions are excluding VAT. After the chosen period, the subscription with the same period will be extended. Subscriptions are available during the period; the subscription will remain valid until the period is over.",
		},
	});
	const { tenant } = useTenant();

	if (tenant.slug === "getrecruitmentdone") {
		return null;
	}

	return (
		<p {...rest} className={cn("opacity-80", className)}>
			{t("disclaimer")}
		</p>
	);
};
