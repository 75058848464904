import { useCallback, useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import { useTranslation } from "react-i18next";
import Avatar from "src/components/Avatar";
import { uploadFile } from "src/lib/uploadFile";
import { cn } from "src/lib/utils";

type ChildrenProps = {
	loading: boolean;
	preview?: string;
	isDragActive?: boolean;
};
export type FileUploadChildren = (props: ChildrenProps) => any;
type FileUploadProps = {
	onChange?: (value: any) => void;
	value: any;
	multiple?: boolean;
	type?: string;
	skipApi?: boolean;
	pending?: boolean;
	noPreview?: boolean;
	allow?: any;
	className?: string;
	placeholder?: string;
	children?: FileUploadChildren;
	onDrop?: (files?: any[]) => void;
};

export default function FileUpload({
	onChange,
	value,
	multiple,
	type,
	skipApi,
	pending,
	noPreview,
	className,
	allow,
	placeholder,
	children,
	onDrop: onDropManual,
}: FileUploadProps) {
	const { t } = useTranslation("form", {
		keyPrefix: "file-upload",
	});
	const [preview, setPreview] = useState("");
	const [loading, setLoading] = useState(false);
	const [isFailed, setIsFailed] = useState(false);

	const onDrop = useCallback(
		async (acceptedFiles: any[]) => {
			if (!acceptedFiles.length) {
				setIsFailed(true);
				return;
			}
			if (onDropManual) {
				onDropManual(acceptedFiles);
				return;
			}
			if (!onChange) return;
			setIsFailed(false);
			setLoading(true);

			if (skipApi) {
				let file = acceptedFiles[0];
				onChange(file);
				const reader = new FileReader() as any;
				const url = reader.readAsDataURL(file);
				reader.onloadend = () => {
					if (!noPreview) {
						setPreview(reader.result);
					}
					setLoading(false);
				};
				return url;
			}

			let files = [];
			for (let file of acceptedFiles) {
				let fd = new FormData();
				fd.append("file", file);

				files.push(await uploadFile("/media", fd));
			}

			let media = files.map((file: any) => {
				return file.data.data;
			});

			onChange(multiple ? media : media[0]);
			setLoading(false);
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[multiple, noPreview, onChange, skipApi]
	);
	const { getRootProps, getInputProps, isDragActive } = useDropzone({
		onDrop,
		accept: allow || "image/jpeg, image/png",
		// maxSize: 999999999999,
		maxSize: 15 * 1000000,
	});

	useEffect(() => {
		if (skipApi && !value?.path) {
			setPreview("");
		}
	}, [skipApi, value]);

	if (children) {
		return (
			<div className={`${className}`} {...getRootProps()}>
				<input {...getInputProps()} />
				{children({ loading, preview, isDragActive })}
			</div>
		);
	}

	if (loading || pending) {
		return (
			<Wrapper>
				<span className="base-loader"></span>
				{pending && <p>{t("loading")}</p>}
			</Wrapper>
		);
	}

	if ((value && value.id) || preview) {
		return (
			<Wrapper>
				{type === "avatar" ? (
					<Avatar size="large" src={value?.url || preview} />
				) : (
					<img
						className="rounded-md"
						src={value?.url || preview}
						alt=""
					/>
				)}
			</Wrapper>
		);
	}

	let label = placeholder || t("placeholder");
	if (isDragActive) {
		label = t("placeholder-active");
	}
	return (
		<Wrapper className={` ${className}`} {...getRootProps()}>
			<input {...getInputProps()} />
			<div className="flex flex-col justify-center text-background-foreground items-center gap-2">
				{isFailed && (
					<div className="text-red">
						<small>{t("failed.description")}</small>
					</div>
				)}

				<i className="fas text-2xl fa-file-image"></i>
				<small className="text-center max-w-[300px]">{label}</small>
				<small className="opacity-40">{t("max-file-size")}</small>
			</div>
		</Wrapper>
	);
}

const Wrapper = (props: any) => {
	return (
		<div
			style={{ borderWidth: 1.5 }}
			{...props}
			className={cn(
				"flex cursor-pointer flex-col items-center justify-center px-4 py-4 rounded-md border-dashed border-border",
				props.className
			)}
		>
			{props?.children}
		</div>
	);
};
