import { ApiEnvelopeStatus } from "src/api/types";
import EnvelopeStatus from "src/components/signer/EnvelopeStatus";
import useWindowSize from "src/hooks/useWindowSize";

interface SignerLayoutTitleProps {
	title: string;
	status: ApiEnvelopeStatus;
}

const SignerLayoutTitle = ({ title, status }: SignerLayoutTitleProps) => {
	const size = useWindowSize();

	if (size.isPhone) {
		return <></>;
	}

	return (
		<div className="flex items-center flex-1 relative w-full gap-[12px]">
			<div className="max-w-[420px] hidden md:flex w-full relative">
				<h2 className="line-clamp-1">{title}</h2>
			</div>
			<EnvelopeStatus status={status} />
		</div>
	);
};

export default SignerLayoutTitle;
