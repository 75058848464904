import React from "react";

interface BillingHeadProps {
	title: string;
	subtitle?: string;
	className?: string;
}

const BillingHead = ({ title, subtitle, className }: BillingHeadProps) => {
	return (
		<div {...{ className }}>
			<h2 className="mb-2 text-2xl">{title}</h2>
			{subtitle && <p className="opacity-80">{subtitle}</p>}
		</div>
	);
};

export default BillingHead;
