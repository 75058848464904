import { ApiIssuer } from "src/api/types";
import Radio from "src/components/form/Radio";
import { BANK_ICONS } from "src/lib/constants";
import { cn } from "src/lib/utils";

interface IdealPaymentMethodProps extends ApiIssuer {
	onClick: () => void;
	active: boolean;
	type: "circle" | "direct";
	variant: "dark" | "light";
}

const IdealPaymentMethod = ({
	onClick,
	name,
	icon,
	active,
	type,
	variant,
}: IdealPaymentMethodProps) => {
	return (
		<div
			{...{ onClick }}
			className={cn(
				`rounded-md pointer p-2 group hover:border-primary flex transition-all cursor-pointer items-center gap-4 border border-border`,
				active && "border-primary"
			)}
		>
			{type === "circle" && <Radio {...{ variant }} value={active} />}
			<div className="flex gap-4 items-center flex-1">
				{icon && (
					<img
						src={BANK_ICONS[icon] || BANK_ICONS["unknown"]}
						alt={icon}
						className="w-12"
					/>
				)}
				<span>{name}</span>
			</div>
			{type === "direct" && (
				<div className="w-12 flex items-center justify-center">
					<i className="fas fa-arrow-right"></i>
				</div>
			)}
		</div>
	);
};

IdealPaymentMethod.defaultProps = {
	type: "direct",
};

export default IdealPaymentMethod;
