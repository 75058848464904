import React, { ComponentProps } from "react";
import Button from "src/components/Button";
import Link from "src/components/Link";
import useBackUrl from "src/hooks/useBackUrl";
import { cn } from "src/lib/utils";

interface ResumeEditButtonProps extends ComponentProps<"a"> {
	variant: "general" | "education" | "work" | "skill";
	type?: "link" | "button";
}

const ResumeEditButton = ({
	variant,
	className,
	type,
	children,
	...rest
}: ResumeEditButtonProps) => {
	const backUrl = useBackUrl();

	if (type === "button") {
		return (
			<Button
				className={cn(className)}
				xsmall
				to={`/profile/resume/create/${variant}?${backUrl}`}
			>
				{children}
			</Button>
		);
	}

	return (
		<Link
			{...rest}
			to={`/profile/resume/create/${variant}?${backUrl}`}
			className={cn(className)}
		>
			<i className="fas fa-pencil"></i>
		</Link>
	);
};

export default ResumeEditButton;
