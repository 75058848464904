import { useTranslation } from "react-i18next";
import Button from "src/components/Button";
import SubscriptionProtectButton from "src/components/subscription/subscription-protect/SubscriptionProtectButton";
import useAuth from "src/hooks/selectors/useAuth";
import { cn } from "src/lib/utils";

interface ChatButtonProps {
	to: string;
	className?: string;
	xsmall?: boolean;
	unreadCount?: number;
}

const ChatButton = ({
	to,
	className,
	xsmall,
	unreadCount,
}: ChatButtonProps) => {
	const { t } = useTranslation("common");
	const auth = useAuth();

	if (auth.type === "company") {
		return (
			<SubscriptionProtectButton
				{...{ className, to, xsmall }}
				type="dark"
				indicator={unreadCount}
			>
				<i className={cn("fas fa-comments", !xsmall && "mr-1")}></i>
				{t("chat")}
			</SubscriptionProtectButton>
		);
	}

	return (
		<Button
			{...{ to, xsmall, className }}
			type="dark"
			indicator={unreadCount}
		>
			<i className={cn("fas fa-comments", !xsmall && "mr-1")}></i>

			{t("chat")}
		</Button>
	);
};

export default ChatButton;
