import { NavLink } from "react-router-dom";
import { ApiSubscription } from "src/api/types";
import Badge from "src/components/Badge";
import Card from "src/components/Card";
import withTranslation, {
	Translation,
} from "src/components/hoc/withTranslation";
import useTenant from "src/hooks/api/services/tenants/useTenant";
import useGet from "src/hooks/rest/useGet";
import { formatPrice } from "src/lib/formatters";

interface CurrentSubscriptionProps extends Translation {
	subscription: ApiSubscription;
}

const CurrentSubscription = ({ t, subscription }: CurrentSubscriptionProps) => {
	const {
		tenant: { modules },
	} = useTenant();

	const [{ data }] = useGet("/auth/subscriptions/plans/current");
	return (
		<Card
			contentClassName="p-6 flex flex-col gap-4"
			className="md:col-span-2"
		>
			<div className="flex flex-row items-center">
				<Badge>{subscription?.name}</Badge>
				<p className="mb-0 font-bold ml-2">{t("current")}</p>
			</div>
			<div className="flex gap-2 items-end">
				<h2 className="text-4xl">{formatPrice(subscription.price)}</h2>
				<small>{t(`intervals.${subscription?.interval}`)}</small>
			</div>
			{data?.upcoming?.fees > 0 &&
				!modules.includes("fee-in-payment") && (
					<>
						<h2 className="text-2xl mb-0">
							{t("service-fee.title", {
								price: formatPrice(data?.upcoming?.fees),
							})}
						</h2>
						<NavLink
							className="underline"
							to="/profile/billing/upcoming"
						>
							{t("service-fee.button")}
						</NavLink>
					</>
				)}
		</Card>
	);
};

CurrentSubscription.locale = {
	nl: {
		current: "Huidig abonnement",
		intervals: {
			MONTHLY: "/ per maand excl. BTW",
			QUARTERLY: "/ per 3 maanden excl. BTW",
			HALF_YEARLY: "/ per 6 maanden excl. BTW",
			YEARLY: "/ per jaar excl. BTW",
		},
		"service-fee": {
			title: "+ {{price}} succesfee",
			button: "Bekijk opdrachten",
		},
	},
	en: {
		current: "Current subscription",
		intervals: {
			MONTHLY: "/ per month excl. VAT",
			QUARTERLY: "/ per 3 months excl. VAT",
			HALF_YEARLY: "/ per 6 months excl. VAT",
			YEARLY: "/ per year excl. VAT",
		},
		"service-fee": {
			title: "+ {{price}} service fee",
			button: "View orders",
		},
	},
};

export default withTranslation(CurrentSubscription);
