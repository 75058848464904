import Loader from "src/components/Loader";
import withTranslation, {
	Translation,
} from "src/components/hoc/withTranslation";
import useLimits from "src/hooks/api/services/auth/useLimits";
import useModules from "src/hooks/selectors/useModules";

interface PublishedTasksCountProps extends Translation {}

const PublishedTasksCount = ({ t }: PublishedTasksCountProps) => {
	const { modules } = useModules();
	const { limits, status } = useLimits();

	if (status !== "idle") {
		return <Loader />;
	}

	if (
		!modules?.includes("extern-task") ||
		!limits?.published_tasks.is_restricted
	) {
		return <></>;
	}

	return (
		<div className="text-placeholder">
			{t("usage", {
				used: limits?.published_tasks?.used,
				total: limits?.published_tasks?.total,
			})}
		</div>
	);
};

PublishedTasksCount.locale = {
	nl: {
		usage: "{{used}} / {{total}} opdrachten uitbesteed deze maand",
	},
	en: {
		usage: "{{used}} / {{total}} tasks outsourced this month",
	},
};

export default withTranslation(PublishedTasksCount);
