import { motion } from "framer-motion";
import { ComponentProps, createContext, useContext } from "react";
import { cn } from "src/lib/utils";

export const Provider = createContext({} as any);

function useCollapsible() {
	const props = useContext(Provider);
	return props;
}

interface CollapsibleProps extends ComponentProps<"div"> {
	open: boolean;
	onOpenChange: (open: boolean) => void;
}

const Collapsible = ({ open, onOpenChange, children }: CollapsibleProps) => {
	return (
		<Provider.Provider
			value={{
				open,
				onOpenChange,
			}}
		>
			{children as any}
		</Provider.Provider>
	);
};

interface TriggerProps {
	showArrow?: boolean;
}

const Trigger = ({
	className,
	showArrow,
	children,
	...rest
}: TriggerProps & ComponentProps<"div">) => {
	const { open, onOpenChange } = useCollapsible();
	if (showArrow) {
		return (
			<div
				{...rest}
				onClick={() => onOpenChange(!open)}
				className={cn(
					"flex items-center gap-2 opacity-40 transition-all hover:opacity-100 cursor-pointer",
					open && "opacity-100",
					className
				)}
			>
				<div className="flex items-center">
					<motion.i
						animate={{
							rotate: open ? 0 : -90,
						}}
						className="far fa-angle-down text-[11px]"
					></motion.i>
				</div>
				<div className="flex">{children}</div>
			</div>
		);
	}
	return (
		<div
			{...rest}
			onClick={() => onOpenChange(!open)}
			className={cn(
				"cursor-pointer opacity-40",
				open && "opacity-100",
				className
			)}
		>
			{children}
		</div>
	);
};

const Content = ({ className, children, ...rest }: ComponentProps<"div">) => {
	const { open } = useCollapsible();

	return (
		<motion.div
			{...(rest as any)}
			animate={{
				height: open ? "auto" : 0,
				opacity: open ? 1 : 0,
				// scale: open ? 1 : 0.9,
			}}
			className={cn(!open && "overflow-hidden", className)}
		>
			{children}
		</motion.div>
	);
};

Collapsible.Trigger = Trigger;
Collapsible.Content = Content;

export default Collapsible;
