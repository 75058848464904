import { ApiPayment } from "src/api/types";
import PaymentBadge from "src/components/payment/PaymentBadge";
import { formatPrice } from "src/lib/formatters";

interface PaymentCardProps {
	payment: ApiPayment;
	className?: string;
	theme: {
		bordered?: boolean;
	};
}

const PaymentCard = ({
	payment,
	className: _className,
	theme,
}: PaymentCardProps) => {
	const Wrapper = payment?.invoice?.url ? "a" : "div";
	let className = `p-3 gap-4 rounded-md flex flex-col md:flex-row md:items-center ${_className} ${
		theme.bordered && "border-2 border-border"
	} ${payment?.invoice && "text-underline"}`;

	return (
		<Wrapper
			{...{ className }}
			{...(payment?.invoice
				? {
						href: payment.invoice.url,
						target: "_blank",
				  }
				: {})}
		>
			<div className="flex justify-center items-center md:aspect-square w-16 h-16 md:h-full bg-accent rounded-md">
				<i className="fas fa-file"></i>
			</div>
			<div className="flex flex-col flex-1 gap-1">
				<div className="flex items-center justify-between">
					<strong>{payment.description}</strong>
					{payment.invoice && (
						<small>
							<i className="fal hidden md:block fa-external-link"></i>
						</small>
					)}
				</div>
				<div className="flex gap-2 flex-wrap items-center justify-between">
					<div className="flex items-center gap-2">
						<p>{formatPrice(payment.total)}</p>
						{payment.invoice_number && (
							<>
								<p>-</p>
								<p>{payment.invoice_number}</p>
							</>
						)}
					</div>
					<PaymentBadge status={payment.status} />
				</div>
			</div>
		</Wrapper>
	);
};

PaymentCard.defaultProps = {
	theme: {
		bordered: true,
	},
};

export default PaymentCard;
