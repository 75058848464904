import moment from "moment";
import { useEffect } from "react";
import Card from "src/components/Card";
import EmptyState from "src/components/EmptyState";
import Link from "src/components/Link";
import ToggleCompletedIcon from "src/components/dashboard/company/elements/ToggleCompletedIcon";
import withTranslation, {
	Translation,
} from "src/components/hoc/withTranslation";
import Icon from "src/components/icon/Icon";
import TaskList from "src/components/tasks/list/TaskList";
import useMyTasksToday from "src/hooks/api/services/tasks/useMyTasksToday";

interface MyTasksTodayCardProps extends Translation {
	className?: string;
}

const MyTasksTodayCard = ({ t, className }: MyTasksTodayCardProps) => {
	const { tasks, pagination, status, showCompletedTasks, actions } =
		useMyTasksToday();

	useEffect(() => {
		actions.list(
			!showCompletedTasks
				? {
						status: ["BACKLOG", "TODO", "IN_PROGRESS"],
				  }
				: {
						status: ["BACKLOG", "TODO", "IN_PROGRESS", "COMPLETED"],
				  }
		);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [showCompletedTasks]);

	return (
		<Card {...{ className }}>
			<Card.Head
				className="flex-row justify-between"
				title={t("title")}
				icon={{
					font_awesome: {
						name: "fas fa-user-crown",
					},
				}}
			>
				<div className="flex items-center relative">
					<ToggleCompletedIcon
						name="tooltip-tasks-today"
						value={showCompletedTasks}
						onChange={() =>
							actions.setShowCompletedTasks(
								showCompletedTasks ? false : true
							)
						}
					/>

					<Link
						to={{
							modal: `./modals/tasks/create?end_date=${moment().format(
								"YYYY-MM-DD"
							)}`,
						}}
					>
						<Icon
							className="text-placeholder"
							iconFontSize={14}
							icon={{
								font_awesome: {
									name: "far fa-plus",
								},
							}}
						/>
					</Link>
				</div>
			</Card.Head>
			<div className="flex flex-col gap-4">
				<TaskList
					draggable={{
						id: "DATE:TODAY",
					}}
					{...{ tasks }}
					onUpdate={(id, value) =>
						actions.update(
							{
								id,
								...value,
							},
							{
								updateList: true,
							}
						)
					}
					item={{
						elements: ["status", "description"],
					}}
					loading={status === "loading"}
					empty={
						tasks.length === 0 &&
						status === "idle" && (
							<EmptyState
								to={{
									modal: "./modals/tasks/create",
								}}
								icon="fas fa-check-circle"
								title={t("empty.title")}
								description={t("empty.description")}
							/>
						)
					}
				/>
				{pagination.page < pagination.last_page &&
					tasks.length !== 0 && (
						<Card.LoadMore
							variant="more"
							value={false}
							onClick={() => {
								actions.list(
									{
										page:
											tasks.length === 0 &&
											pagination.last_page >
												pagination.page
												? 1
												: pagination.page + 1,
									},
									{
										appendResult: true,
									}
								);
							}}
						/>
					)}
			</div>
		</Card>
	);
};

MyTasksTodayCard.locale = {
	nl: {
		title: "Taken van vandaag",
		empty: {
			title: "Geen taken voor vandaag gevonden",
			description: "Klik hier om taken toe te voegen",
		},
	},
	en: {
		title: "Tasks for today",
		empty: {
			title: "No tasks found for today",
			description: "Click here to create a new task",
		},
	},
};

export default withTranslation(MyTasksTodayCard);
