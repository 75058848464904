import useApi from "src/hooks/api/utils/useApi";

function useRegistrations() {
	const { state, actions, api, pagination } = useApi(
		{ id: "TASKS_OUTSOURCED" },
		{
			baseUrl: "/client/tasks/outsourced",
		}
	);

	return {
		tasks: state?.list || [],
		filter: state.filter,
		status: state.status,
		pagination,
		actions,
		api,
	};
}

export default useRegistrations;
