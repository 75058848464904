import CustomFormFilters from "src/components/custom-form/filters/CustomFormFilters";
import withTranslation, {
	Translation,
} from "src/components/hoc/withTranslation";
import Modal from "src/components/Modal";

interface FilterNetworkModalProps {
	visible: boolean;
	onClose: () => void;
	filter: any;
	onChange: (filter: any) => any;
}

const FilterNetworkModal = ({
	t,
	visible,
	onClose,
	filter,
	onChange,
}: FilterNetworkModalProps & Translation) => {
	return (
		<Modal title={t("title")} {...{ visible, onClose }}>
			<div className="flex flex-col gap-4">
				<CustomFormFilters
					value={filter?.form_filters || {}}
					{...{ onChange }}
					type="CONNECTION"
				/>
			</div>
		</Modal>
	);
};

FilterNetworkModal.locale = {
	nl: {
		title: "Filter connecties",
	},
	en: {
		title: "Filter connections",
	},
};

export default withTranslation(FilterNetworkModal);
