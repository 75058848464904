import Field from "src/components/field/Field";
import withTranslation, {
	Translation,
} from "src/components/hoc/withTranslation";
import Icon from "src/components/icon/Icon";

import { useTaskKanban } from "src/components/tasks/kanban/TaskKanbanCard";
import useBoards from "src/hooks/api/services/tasks/useBoards";
import { cutString } from "src/lib/formatters";
import { cn } from "src/lib/utils";

interface TaskKanbanCardBoardProps extends Translation {
	onChange?: (value?: string) => void;
	value?: string;
	className?: string;
	isMissing?: boolean;
	cutText?: number;
}

const TaskKanbanCardBoard = ({
	t,
	onChange,
	value,
	className,
	isMissing,
	cutText,
}: TaskKanbanCardBoardProps) => {
	const { task } = useTaskKanban();
	const { boards } = useBoards();
	const board = boards.find((item) => task?.links?.board === item.hid);

	if (onChange) {
		return (
			<Field.Board
				wrapper={{
					className,
				}}
				placeholder={t("placeholder")}
				onChange={(value) => onChange(value)}
				{...{ value }}
				trigger={{
					className: cn(
						"flex-row transition-all",
						isMissing && "border-red"
					),
					arrow: {
						className: "bg-border",
					},
				}}
			/>
		);
	}

	if (!board) return null;

	return (
		<div className={cn("flex items-center", className)}>
			{board?.icon && (
				<Icon
					className="hover:bg-transparent w-6 h-6"
					iconFontSize={12}
					icon={board.icon}
				/>
			)}
			{cutText ? cutString(board?.name || "", cutText) : board?.name}
		</div>
	);
};

TaskKanbanCardBoard.locale = {
	nl: {
		placeholder: "Bord...",
	},
	en: {
		placeholder: "Board...",
	},
};

export default withTranslation(TaskKanbanCardBoard);
