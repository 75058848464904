import { useMemo } from "react";
import { ApiIssuer, ApiPaymentRequest, CheckoutType } from "src/api/types";
import useApi from "src/hooks/api/utils/useApi";
import useAuth from "src/hooks/selectors/useAuth";

type CheckoutValues = {
	ids: string[];
	method: CheckoutType;
	issuer?: ApiIssuer;
	redirect_url?: string;
	is_sign_direct?: boolean;
	project_code?: string;
};

export type PaymentRequestFormValue = {
	id?: string | number;
	type?: "HOUR_RATE" | "FIXED_FEE";
	description?: string;
	start_date?: string;
	end_date?: string;
	break?: number;
	price?: number;
	company_id?: string;
	receiver_id?: string;
	status?: "OPEN" | "PENDING" | "APPROVED";
};

type Options = {
	ids: string[];
};

function usePaymentRequestsCheckout(options?: Options) {
	const auth = useAuth();
	const { state, actions, api, pagination } = useApi(
		{
			id: "PAYMENT_REQUESTS_CHECKOUT_TABLE",
		},
		{
			baseUrl: `/payment-requests`,
			query: {
				status: "APPROVED",
				with: [
					"company",
					"creator",
					"receiver",
					"hours",
					"forms",
					"connection_fields",
				],
				ids: options?.ids,
			},
		}
	);
	const requests: ApiPaymentRequest[] = useMemo(() => {
		return state?.list || [];
	}, [state]);
	const totals = useMemo(() => {
		const price = requests.reduce((acc, curr) => acc + curr.total, 0);
		const fee = price * auth.service_rate;
		const vat = fee * 0.21;
		return {
			payments: requests.length,
			hours: requests.reduce((acc, curr) => acc + (curr?.hours || 0), 0),
			price,
			fee,
			vat,
			total: price + fee + vat,
		};
	}, [auth.service_rate, requests]);

	const checkout = async (data: CheckoutValues) => {
		const {
			data: { data: response },
		} = await api.post("/process", data);
		return response;
	};

	return {
		requests,
		status: state.status,
		pagination,
		totals,
		actions: {
			...actions,
			checkout,
		},
		api,
	};
}

export default usePaymentRequestsCheckout;
