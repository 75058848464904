const network = {
	"network-company-view": {
		title_one: "1 Connection Found",
		title_other: "{{count}} Connections Found",
		empty: "You have no connections yet",
	},
	"network-student-view": {
		title_one: "1 Invitation Found",
		title_other: "{{count}} Invitations Found",
		empty: "You have no invitations yet",
	},
	"network-invite-view": {
		title: "Invitation",
		back: "Back to Invitations",
	},
};

export default network;
