import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { updateIssuer } from "src/redux/issuer";
import { updateStudentPool } from "src/redux/studentPool";

function useStudentPool() {
	const studentPool = useSelector((state: any) => state.studentPool);
	const dispatch = useDispatch();

	const fetchStudentPool = async () => {
		axios
			.get("/connections?response_type=option")
			.then(({ data }) => {
				const students = (data?.data || []).filter(
					(user: any) => user.has_matches
				);
				dispatch(
					updateStudentPool({
						loading: false,
						students,
					})
				);
			})
			.catch(() => {
				dispatch(updateIssuer({ loading: false }));
			});
	};

	return {
		...studentPool,
		fetchStudentPool,
	};
}

export default useStudentPool;
