import React from "react";
import { useTranslation } from "react-i18next";
import { ApiSubscription } from "src/api/types";
import FlatList from "src/components/FlatList";
import SubscriptionPaymentItem from "src/components/subscription/billing/SubscriptionPaymentItem";
import useGet from "src/hooks/rest/useGet";

const SubscriptionPaymentsTable = () => {
	const { t } = useTranslation("subscription", {
		keyPrefix: "subscription-payments-table",
	});
	const [result, loading, { fetchNext }] = useGet(
		`/auth/subscriptions/payments?with[]=payment`,
		{ data: [] },
		true
	);
	const { data, hasMore } = result;

	const headers = [
		t("headers.price"),
		t("headers.status"),
		t("headers.description"),
		t("headers.date"),
		"",
	];

	return (
		<FlatList
			noInfiniteScroll
			table={{
				headers,
			}}
			{...{ loading, hasMore }}
			data={data || []}
			onNext={async () => {
				if (hasMore) await fetchNext();
			}}
			empty={{
				title: t("empty.title"),
			}}
			gap={16}
			renderItem={(row: ApiSubscription) => (
				<SubscriptionPaymentItem
					key={`subscription-${row.id}`}
					subscription={row}
				/>
			)}
		/>
	);
};

export default SubscriptionPaymentsTable;
