import Category from "src/components/field/fields/Category";
import Connection from "src/components/field/fields/Connection";
import Date from "src/components/field/fields/Date";
import Editor from "src/components/field/fields/Editor/Editor";
import Options from "src/components/field/fields/Options";
import Status from "src/components/field/fields/Status";
import Select from "src/components/field/fields/Select";
import User from "src/components/field/fields/User";
import Tag from "src/components/field/fields/Tag";
import File from "src/components/field/fields/File";
import Users from "src/components/field/fields/Users";
import Team from "src/components/field/fields/Team";
import Project from "src/components/field/fields/Project";
import Input from "src/components/field/fields/Input";
import Time from "./fields/Time";
import Board from "./fields/Board";
import Switch from "./fields/Switch";

const Field = () => {
	return <></>;
};

//Set the elements
Field.Date = Date;
Field.Input = Input;
Field.Select = Select;
Field.User = User;
Field.Users = Users;
Field.Status = Status;
Field.Editor = Editor;
Field.Category = Category;
Field.Options = Options;
Field.Connection = Connection;
Field.Tag = Tag;
Field.File = File;
Field.Team = Team;
Field.Project = Project;
Field.Time = Time;
Field.Board = Board;
Field.Switch = Switch;

export default Field;
