import { useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Button from "src/components/Button";
import Modal from "src/components/Modal";
import Dropdown from "src/components/form/Dropdown";
import Input from "src/components/form/Input";
import withTranslation, {
	Translation,
} from "src/components/hoc/withTranslation";
import useTask from "src/hooks/api/tasks/useTask";
import usePost from "src/hooks/rest/usePost";

interface MassMessageTaskModalProps extends Translation {}

const MassMessageTaskModal = ({ t }: MassMessageTaskModalProps) => {
	const params = useParams();
	const navigate = useNavigate();
	const { task } = useTask();
	const location = useLocation();
	const [values, setValues] = useState({
		status: undefined,
		message: "",
		// send_confirm: false,
	});
	const [createMassMessage, loading] = usePost(
		`/client/tasks/${task.id}/mass-message`
	);
	const [response, setResponse] = useState<null | {
		status: "success" | "error";
		count?: number;
	}>();
	const isDisabled = !(values.message && values.status);

	const onSubmit = async () => {
		if (isDisabled) return;

		try {
			const { data } = await createMassMessage(values);

			setResponse({
				status: "success",
				count: data.count,
			});
		} catch (error) {
			setResponse({
				status: "error",
			});
		}
	};

	return (
		<Modal
			title={t("title")}
			visible={location.pathname.includes("/mass-message")}
			onClose={() => {
				navigate(`/tasks/${params.id}/${params.pane}`);
			}}
			className=" "
		>
			{response?.status === "error" && (
				<div className="p-4 bg-red-500 rounded-md text-red border border-red mb-4">
					{t("error")}
				</div>
			)}
			{response?.status === "success" ? (
				<>
					<div className="p-4 bg-green-500 text-green border rounded-md border-green mb-4">
						{t("success", {
							count: response?.count || 0,
						})}
					</div>
					<Button
						onClick={() => {
							setValues({
								message: "",
								status: undefined,
							});
							setResponse(null);
						}}
					>
						{t("again")}
					</Button>
				</>
			) : (
				<div className="flex flex-col gap-6">
					<Input
						name="message"
						label={t("inputs.message")}
						value={values.message}
						onChange={(key, value) =>
							setValues({ ...values, message: value })
						}
						multiline
						placeholder={t("inputs.message")}
					/>
					<Dropdown
						label={t("inputs.status")}
						placeholder={t("inputs.status-placeholder")}
						value={values.status}
						options={[
							{ id: "open", name: t("status.open") },
							{ id: "matched", name: t("status.matched") },
							{ id: "declined", name: t("status.declined") },
							{ id: "all", name: t("status.all") },
						]}
						onChange={(value: any) => {
							setValues({ ...values, status: value });
						}}
					/>

					{/* <Switch
					label={t("inputs.send-confirm")}
					value={values.send_confirm}
					onChange={() =>
						setValues({
							...values,
							send_confirm: !values.send_confirm,
						})
					}
				/> */}

					<Button
						loading={loading}
						disabled={loading || isDisabled}
						className="mt-4"
						onClick={onSubmit}
					>
						{t("send")}
					</Button>
					<div className="opacity-70">{t("disclaimer")}</div>
				</div>
			)}
		</Modal>
	);
};

MassMessageTaskModal.locale = {
	nl: {
		title: "Massabericht delen",
		inputs: {
			message: "Bericht",
			status: "Aanmeldingen",
			"status-placeholder": "Wie moet het bericht ontvangen?",
			"send-confirm": "Ontvang een mail na het versturen",
		},
		status: {
			open: "Open",
			matched: "Gematcht",
			declined: "Niet geselecteerd",
			all: "Alle aanmeldingen",
		},
		error: "Er is iets misgegaan, mocht dit probleem zich blijven voor doen neem dan contact op met support.",
		send: "Versturen",
		success:
			"Het is gelukt! Er zijn naar {{count}} gebruiker(s) een bericht gestuurd.",
		again: "Opnieuw",
		disclaimer:
			"Berichten worden altijd vanuit de aanmaker van de opdracht verzonden.",
	},
	en: {
		title: "Share mass message",
		inputs: {
			message: "Message",
			status: "Invitations",
			"status-placeholder": "Who should the message be sent to?",
			"send-confirm": "Send a confirmation email",
		},
		status: {
			open: "Open",
			matched: "Matched",
			declined: "Declined",
			all: "All invitations",
		},
		error: "Something went wrong, if this problem persists contact support.",
		send: "Send",
		success: "Success! {{count}} user(s) have received a message.",
		again: "Again",
		disclaimer: "Messages are always sent from the creator of the task.",
	},
};

export default withTranslation(MassMessageTaskModal);
