import { createAction, handleActions } from "redux-actions";
export const updateNotifications = createAction("UPDATE_NOTIFICATIONS");
export const removeNotifications = createAction("REMOVE_NOTIFICATIONS");

export const emptyNotificationsState = {
	data: [],
};

type NotificationsState = {
	data: any[];
};

const notificationsReducer = handleActions(
	{
		UPDATE_NOTIFICATIONS: (state: NotificationsState, action: any) => {
			return { ...state, ...action.payload };
		},
		REMOVE_NOTIFICATIONS: () => {
			return {
				...emptyNotificationsState,
			};
		},
	},
	{
		...emptyNotificationsState,
	}
);
export default notificationsReducer;
