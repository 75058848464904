import { useState } from "react";
import Button from "src/components/Button";
import withTranslation, {
	Translation,
} from "src/components/hoc/withTranslation";
import Tab from "src/components/layout/Tab";
import Tabs from "src/components/layout/Tabs";
import EditLayout, {
	EditLayoutDescription,
	EditLayoutHeader,
	EditLayoutTitle,
} from "src/components/resume/edit-layout/EditLayout";
import ResumeForm from "src/components/resume/ResumeForm";

const DashboardEditBioModal = ({ t }: Translation) => {
	const [activeTab, setActiveTab] = useState("bio");
	return (
		<EditLayout>
			<EditLayoutHeader className="-top-2 md:-top-8">
				<EditLayoutTitle>{t("title")}</EditLayoutTitle>
				<EditLayoutDescription>
					{t("description")}
				</EditLayoutDescription>
			</EditLayoutHeader>
			<div className="flex flex-col mt-8 gap-6">
				<Tabs.Container>
					<Tab
						active={activeTab === "bio"}
						onClick={() => setActiveTab("bio")}
						small
					>
						{t("tabs.bio")}
					</Tab>
					<Tab
						active={activeTab === "video"}
						onClick={() => setActiveTab("video")}
						small
					>
						{t("tabs.video")}
					</Tab>
				</Tabs.Container>

				{activeTab === "bio" && (
					<ResumeForm.GeneralInfo>
						{({ isValid, isLoading, onSubmit }) => (
							<div className="flex justify-end">
								<Button
									onClick={onSubmit}
									disabled={!isValid}
									loading={isLoading}
								>
									{t("save")}
								</Button>
							</div>
						)}
					</ResumeForm.GeneralInfo>
				)}
				{activeTab === "video" && <ResumeForm.VideoPitch />}
			</div>
		</EditLayout>
	);
};

DashboardEditBioModal.locale = {
	nl: {
		title: "Bio & Video pitch",
		description:
			"Voeg een persoonlijke touch toe aan je profiel. Door je goed te omschrijven en / of een video pitch toe te voegen krijgen nieuwe opdrachtgevers een beter beeld van jou als professional.",
		save: "Opslaan",
		tabs: {
			bio: "Omschrijving",
			video: "Video pitch",
		},
	},
	en: {
		title: "Bio & Video pitch",
		description:
			"Add a personal touch to your profile. By describing yourself well and / or adding a video pitch, new job providers will get a better picture of you as a professional.",
		save: "Save",
		tabs: {
			bio: "Description",
			video: "Video pitch",
		},
	},
};

export default withTranslation(DashboardEditBioModal);
