import { useEffect, useState } from "react";

function useIframeEvents() {
	useEffect(() => {
		window.addEventListener("load", sendHeightToIframe);
		window.addEventListener("resize", sendHeightToIframe);
		const observer = new ResizeObserver(sendHeightToIframe);
		sendHeightToIframe();
		return () => {
			window.removeEventListener("load", sendHeightToIframe);
			window.removeEventListener("resize", sendHeightToIframe);
			observer.disconnect();
		};
	}, []);
}

export function usePollIframeHeight() {
	const [contentHeight, setContentHeight] = useState<number>();

	useEffect(() => {
		const isInIframe = window.self !== window.top;
		if (!isInIframe) return;
		const intervalId = setInterval(() => {
			const newHeight = getHeight();
			if (newHeight !== contentHeight) {
				setContentHeight(newHeight);
				sendHeightToIframe();
			}
		}, 500);

		// Clean up the interval when the component unmounts
		return () => {
			clearInterval(intervalId);
		};
	}, [contentHeight]);
}

function getHeight() {
	let body = document.body,
		html = document.documentElement;

	let height = Math.max(
		body.scrollHeight,
		body.offsetHeight,
		html.clientHeight,
		html.scrollHeight,
		html.offsetHeight
	);
	return height;
}

export function sendHeightToIframe() {
	const width = document.body.scrollWidth;
	const height = getHeight();
	window.parent.postMessage({ height, width }, "*");
}

export default useIframeEvents;
