import ForgotPasswordRequestForm from "src/components/forms/auth/forgot-password/ForgotPasswordRequestForm";
import withTranslation, {
	Translation,
} from "src/components/hoc/withTranslation";
import AuthLayout from "src/components/layout/auth-layout/AuthLayout";

function ForgotPasswordView({ t }: Translation) {
	return (
		<AuthLayout
			head={{
				title: t("title"),
				back: {
					to: "/login",
					label: t("back.label"),
				},
			}}
		>
			<ForgotPasswordRequestForm />
		</AuthLayout>
	);
}

ForgotPasswordView.locale = {
	nl: {
		title: "Wachtwoord vergeten",
		back: {
			label: "Terug naar inloggen",
		},
	},
	en: {
		title: "Forgot password",
		back: {
			label: "Back to login",
		},
	},
};

export default withTranslation(ForgotPasswordView);
