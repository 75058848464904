const payments = {
	loading: "Please wait, checking your payment",
	"invoice-send": "Invoice sent",
	completed: "Payment successful!",
	track: "Track your payment",
	"service-fee": "Success fee ({{rate}}%)",
	status: {
		pending: "Pending",
		completed: "Successful",
		expired: "Failed",
		reserved: "Reserved",
		Betaald: "Paid",
		cancelled: "Cancelled",
		chargeback: "Cancelled",
		refunded: "Cancelled",
		created: "Pending",
		credit: "Credit",
	},
	types: {
		subscription: "Subscription fees",
		payout: "Professional payout",
	},
	"information-card": {
		title: "Payment information",
		"addressed-to": "Address",
		reference: "Reference",
	},
	"payment-detail-card": {
		title: "Information",
		"invoice-number": "Invoice number",
		method: "Payment method",
		description: "Description",
		status: "Status",
		price: {
			"including-vat": "Amount (incl. VAT)",
			"percentage-vat": "VAT percentage",
		},
		"created-at": "Created at",
		"payout-at": "Payout on",
	},
	methods: {
		invoice: "Invoice",
		ideal: "Ideal",
		mandate: "Automatic debit",
		afterpay: "Pay later invoice",
		checkout: "Pay later",
		undefined: "Unknown",
	},
	"checkout-payment-methods": {
		title: "Choose a payment method",
		"mandate-disclaimer": "The mandate can only be paid out after {{date}}",
		checkout: "Checkout",
	},
	"checkout-summary": {
		title: "Invoice{{invoice_number}}",
		products: "Products",
		subtotal: "Subtotal",
		vat: "VAT ({{vat}}%)",
		"vat-checkout": "VAT is calulated at checkout",
		"partner-fee": "Partner fee",
		"partner-fee-disclaimer":
			"We have to charge this fee from the payment provider for processing the payment",
		total: "Total",
	},
	"mandate-methods": {
		title: "Payment method:",
		placeholder: "Choose a payment method",
		ideal: {
			title: "Choose a bank",
			"method-not-existing":
				"Is your desired payment method not listed? <1>Click here</1> to request a manual bank link.",
			"method-custom":
				"The link with <1>{{name}}</1> is a manual link. You only need to enter your IBAN and address, our payment provider will handle the rest.",
			continue: "Continue to {{name}}",
			label: "Pay with Ideal",
		},
	},
	"payment-status": {
		title: "Payment",
		price: "Amount",
		status: "Status",
		invoice: "Invoice",
		"not-created": "Not created",
		method: "Method",
		track: "Track payment",
		new: "New payment",
		view: "View payment",
		"payout-description":
			"This payment will be paid out on {{date}}, it takes 2 working days for it to be on your account.",
	},
	"registration-payment-url": {
		title: "Payment status",
		ideal: "Pay via iDeal",
		invoice: "Create new invoice",
		"not-paid": "Not paid yet",
	},
};

export default payments;
