import moment from "moment";
import { useTranslation } from "react-i18next";
import { ApiOfferRegistration } from "src/api/types";
import Avatar from "src/components/Avatar";
import Badge from "src/components/Badge";
import Card from "src/components/Card";
import OfferRegistrationStatus from "src/components/offer/utils/OfferRegistrationStatus";
import Skeleton from "src/components/Skeleton";
import Stars from "src/components/Stars";
import { formatPrice } from "src/lib/formatters";

interface OfferCardProps {
	registration: ApiOfferRegistration;
}

const OfferRegistrationCompanyCard = ({ registration }: OfferCardProps) => {
	const { t } = useTranslation("common");
	const { offer } = registration;

	return (
		<Card to={`/talents/${registration.hid}/show`} className=" ">
			<div className="flex gap-2">
				<Avatar className="w-20 h-20" src={offer?.user?.avatar} />
				<div className="flex flex-1 gap-2 flex-col">
					<div className="flex items-start">
						<div className="flex-1">
							<strong className="text-[20px] leading-[20px]">
								{offer?.title}
							</strong>
							<p className="mb-0">{offer?.user?.full_name}</p>
						</div>
						{registration?.unread_messages ? (
							<>
								<strong className="text-red">
									{t("unread-messages", {
										count:
											registration?.unread_messages || 0,
									})}
								</strong>
							</>
						) : (
							<Stars
								rating={offer?.user?.review || 0}
								size={"20px"}
							/>
						)}
					</div>
					{offer?.budget && (
						<strong>{formatPrice(offer?.budget || 0)}</strong>
					)}
				</div>
			</div>
			<div className="mt-4 flex-wrap flex gap-2 items-end">
				<Badge
					style={{
						backgroundColor: "#2870D120",
					}}
					bg=" "
					color="blue"
				>
					{offer?.category?.name}
				</Badge>
				<OfferRegistrationStatus
					status={registration?.status || "PENDING"}
				/>
				{/* <Button
					type="dark"
					to={`/offers/${offer.slug}/registration/${registration.hid}`}
				>
					Chatten
				</Button> */}
				<div className="flex-1"></div>
				<small>{moment(registration.created_at).fromNow()}</small>
			</div>
		</Card>
	);
};

const Loading = () => (
	<Card>
		<div className="flex gap-2">
			<Skeleton className="w-20 h-20" />
			<div className="flex flex-col flex-1 gap-2">
				<Skeleton className="h-8 w-32" />
				<Skeleton className="h-4 w-full" />
				<Skeleton className="h-4 w-[80%]" />
			</div>
		</div>
		<div className="flex gap-2 mt-4">
			<Skeleton className="h-10 w-32" />
			<Skeleton className="h-10 w-32" />
		</div>
	</Card>
);

OfferRegistrationCompanyCard.Loading = Loading;

export default OfferRegistrationCompanyCard;
