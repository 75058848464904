import { useTranslation } from "react-i18next";
import Badge from "src/components/Badge";
import FormGroup from "src/components/form/FormGroup";
import Input from "src/components/form/Input";
import Stars from "src/components/Stars";

export type FeedbackValue = {
	platform: {
		rating?: number;
		feedback: string;
	};
	contact: {
		rating?: number;
		feedback: string;
	};
	return_period?: string;
	reason: string;
};

interface SubscriptionFeedbackFormProps {
	feedback: FeedbackValue;
	onChange: (feedback: FeedbackValue) => void;
}

const SubscriptionFeedbackForm = ({
	feedback,
	onChange,
}: SubscriptionFeedbackFormProps) => {
	const { t } = useTranslation("forms", {
		keyPrefix: "subscription-feedback-form",
	});
	const PERIODS = {
		one_month: t("periods.one-month"),
		three_month: t("periods.three-month"),
		six_month: t("periods.six-month"),
		never: t("periods.never"),
	} as any;
	return (
		<div className="flex flex-col gap-4">
			<hr className="border-border" />
			<Section>
				<FormGroup noMarginBottom label={t("platform.title")}>
					<div className="flex flex-col gap-2">
						<Stars
							size="26px"
							inActiveClassName="text-background-foreground-200"
							rating={feedback.platform.rating || 0}
							onClick={(rating) => {
								onChange({
									...feedback,
									platform: {
										...feedback.platform,
										rating,
									},
								});
							}}
						/>
						<small>{t("rating-help")}</small>
					</div>
				</FormGroup>
				<Input
					label={t("platform.improve")}
					placeholder=" "
					multiline
					className="textarea-medium"
					value={feedback.platform.feedback}
					name="platform_feedback"
					noMarginBottom
					onChange={(_, value) => {
						onChange({
							...feedback,
							platform: {
								...feedback.platform,
								feedback: value,
							},
						});
					}}
				/>
			</Section>
			<hr className="border-border" />
			<Section>
				<FormGroup noMarginBottom label={t("contact.title")}>
					<div className="flex flex-col gap-2">
						<Stars
							size="26px"
							inActiveClassName="text-background-foreground-200"
							rating={feedback.contact.rating || 0}
							onClick={(rating) => {
								onChange({
									...feedback,
									contact: {
										...feedback.contact,
										rating,
									},
								});
							}}
						/>
						<small>{t("rating-help")}</small>
					</div>
				</FormGroup>
				<Input
					label={t("contact.improve")}
					placeholder=" "
					multiline
					noMarginBottom
					className="textarea-medium"
					value={feedback.contact.feedback}
					name="platform_feedback"
					onChange={(_, value) => {
						onChange({
							...feedback,
							contact: {
								...feedback.contact,
								feedback: value,
							},
						});
					}}
				/>
			</Section>
			<hr className="border-border" />
			<Section>
				<FormGroup noMarginBottom label={t("usage")}>
					<div className="flex gap-4 flex-wrap">
						{Object.keys(PERIODS).map((key) => (
							<Badge
								variant={
									feedback.return_period === key
										? "dark"
										: "gray"
								}
								className="cursor-pointer"
								onClick={() => {
									onChange({
										...feedback,
										return_period: key,
									});
								}}
							>
								{PERIODS[key]}
							</Badge>
						))}
					</div>
				</FormGroup>
				<Input
					label={t("reason")}
					placeholder=" "
					multiline
					noMarginBottom
					className="textarea-medium"
					value={feedback.reason}
					name="platform_feedback"
					onChange={(_, value) => {
						onChange({
							...feedback,
							reason: value,
						});
					}}
				/>
			</Section>
		</div>
	);
};

const Section = ({ children }: { children: any }) => (
	<div className="flex flex-col gap-4">{children}</div>
);

export default SubscriptionFeedbackForm;
