const network = {
	"deny-task-invite": {
		confirm: {
			title: "Weet je het zeker?",
			description: "Weet je zeker dat je deze uitnodiging wilt afwijzen?",
		},
	},
	"network-student-card": {
		"create-task": "Opdracht",
		"view-profile": "Profiel bekijken",
		"delete-connection": "Verwijderen",
		confirm: {
			title: "Weet je het zeker?",
			description: "Je staat op het punt om een connectie te verwijderen",
		},
	},
	"signup-task": {
		button: {
			label: "Aanmelden",
			"label-success": "Je bent aangemeld 🎉",
		},
		"alert-success": "Gefeliciteerd! Je aanmelding is binnengekomen. 🎉",
		description:
			"Schrijf hieronder waarom jij deze opdracht moet doen. Vertel bijvoorbeeld wat over je ervaringen en/of opleidingen.",
		"input-label": "Motivatie",
	},
	"task-invite-card": {
		"per-hour": "per uur",
		button: {
			"view-invite": "Uitnodiging bekijken",
			registered: "Je bent aangemeld 🎉",
		},
	},
};

export default network;
