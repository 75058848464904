import axios from "axios";
import moment from "moment";
import useBoards from "src/hooks/api/services/tasks/useBoards";
import useUsers from "src/hooks/api/services/users/useUsers";
import useTaskForm from "src/hooks/api/tasks/useTaskForm";
import useTasksFilter from "src/hooks/api/tasks/useTasksFilter";
import useGet from "src/hooks/rest/useGet";
import useAuth from "src/hooks/selectors/useAuth";
import useQueryString from "src/hooks/useQueryString";
import { getHoursBetweenDates } from "src/lib/dates";
import uid from "src/lib/uid";

function useFillTask() {
	const auth = useAuth();
	const { actions } = useTaskForm();
	const { users } = useUsers();
	const {
		repeat,
		c,
		title,
		description,
		estimated_duration,
		budget,
		status,
		assign_to,
		end_date,
	} = useQueryString();
	const { profile } = useQueryString();
	const [{ data: student }] = useGet(
		profile ? `/connections/${profile}` : undefined
	);
	const { boards } = useBoards();
	const { filter, isFiltered } = useTasksFilter();

	const board = filter?.board
		? boards.find((item: any) => item.slug === filter.board)
		: null;
	const hasBoard = !!board?.filter || board?.slug === "my-tasks";

	const listeners = [
		student,
		repeat,
		title,
		description,
		estimated_duration,
		budget,
		c,
		profile,
		status,
		hasBoard,
		isFiltered,
		assign_to,
		end_date,
	];

	const getFillableData = () => {
		if (
			title ||
			description ||
			estimated_duration ||
			budget ||
			status ||
			board ||
			filter ||
			end_date
		) {
			let assignees = JSON.parse(
				JSON.stringify(
					board?.filter?.assignees || filter?.assignees || []
				)
			);
			if (assign_to) {
				const user = users.find((item) => item.hid === assign_to);
				if (user?.id) assignees = [user.id];
			} else {
				assignees.push(auth.id.toString());
			}

			if (board?.slug === "my-tasks" && !assignees.includes(auth.id)) {
				assignees.push(auth.id.toString());
			}

			const data: any = {
				amount_of_students: 1,
				title,
				description,
				estimated_duration,
				budget,
				status,
				tags: board?.filter?.tags || filter?.tags || [],
				assignees,
				end_date,
			};

			if (board?.slug && board?.hid) {
				data.board = board.hid;
			}

			if (filter.project) {
				data.project_slug = filter?.project;
			}

			return data;
		}
		return {};
	};

	const fill = () => {
		actions.clear();
		actions.setStatus("filling");
		//Check if empty params
		if (listeners.filter((i) => i).length === 0) {
			setTimeout(() => {
				actions.setStatus("idle");
			}, 500);
			return;
		}
		if (student) {
			actions.set(
				{
					student,
					student_hid: student.hid,
				},
				"fill"
			);
			actions.setStatus("idle");
			return;
		}
		if (repeat) {
			axios
				.get(`/client/tasks/${repeat}`)
				.then(({ data }: any) => {
					let questions = null;
					let subtasks = undefined;

					const task = data?.data || {};
					if (task.questions) {
						questions = task.questions.map((question: any) => {
							let item = {
								title: question.title,
								answers: (question?.choices || []).map(
									(item: any) => ({
										answer: item.answer,
										is_right: item.is_right,
									})
								),
							};
							return item;
						});
					}

					if (task.subtasks.length) {
						task.budget = null;
						task.amount_of_students = null;
						subtasks = task.subtasks.map((subtask: any) => ({
							_id: uid(),
							title: subtask.title,
							budget: subtask.budget,
							amount_of_students: subtask.amount_of_students,
							start_date: subtask.start_date,
							end_date: subtask.end_date,
							estimated_duration: getHoursBetweenDates(
								subtask.start_date,
								subtask.end_date
							),
							budget_per_hour: subtask.budget_per_hour,
						}));
					}

					const duplicatedTask = {
						title: task.title,
						description: task.description,
						category_id: task.category_id,
						amount_of_students: task.amount_of_students
							? Number(task.amount_of_students)
							: 1,
						budget: !task?.budget_per_hour ? task.budget : null,
						budget_per_hour: task.budget_per_hour,
						address: task.address,
						is_home: task.is_home,
						is_office: task.is_office,
						media: task.media,
						estimated_duration: task.estimated_duration,
						is_private: task.is_private,
						is_anonymous: task.is_anonymous,
						questions,
						subtasks,
						start_date: moment().format("YYYY-MM-DD"),
						min_age: task?.age?.min || null,
						students: [],
					};
					console.log(duplicatedTask);
					actions.set(duplicatedTask, "fill");
				})
				.finally(() => {
					actions.setStatus("idle");
				});
			return;
		}

		if (title || budget || status || board || filter || end_date) {
			const data = getFillableData();
			actions.set(data, "merge");
			setTimeout(() => {
				actions.setStatus("idle");
			}, 100);
			return;
		}
	};
	return { fill, listeners, getFillableData };
}

export default useFillTask;
