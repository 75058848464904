const setting = {
	"notifications-view": {
		title: "Settings",
		emails: {
			title: "Emails",
			"new-chat": {
				label: "New chat message",
				"sub-label": "You have a new chat message",
			},
			"new-review": {
				label: "New review",
				"sub-label": "Someone has posted a review",
			},
			"new-envelope": {
				label: "New agreement",
				"sub-label": "An agreement needs to be signed.",
			},
			"summary-registrations": {
				label: "Summary of registrations",
				"sub-label":
					"This many professionals have responded to your task in the past week",
			},
			"new-registration": {
				label: "New registration",
				"sub-label": "You have a new registration for your task",
			},
			"new-payment": {
				label: "New payment",
				"sub-label": "Emails regarding payments and invoices",
			},
			"task-expires": {
				label: "Task expires",
				"sub-label": "Task x expires in 3 days",
			},
			"no-registrations": {
				label: "Your task has no registrations yet",
			},
			"offer-recommendations": {
				label: "New professionals this week",
			},
			"new-tasks": {
				label: "New tasks",
				"sub-label": "These tasks have been posted in the last 4 hours",
			},
			"registration-received": {
				label: "Registration received",
				"sub-label": "Your registration for task x has been received",
			},
			"registration-accepted": {
				label: "Registration accepted",
				"sub-label": "Your registration for task x has been accepted",
			},
			"registration-denied": {
				label: "Registration not selected",
				"sub-label":
					"Your registration for task x has not been selected",
			},
			payout: {
				label: "Payouts",
				sublabel: "You have been paid!",
			},
			"task-invite": {
				label: "Task invitations",
				"sub-label": "Company x has invited you for a task",
			},
			"offer-created": {
				label: "We have received your offer!",
			},
			"offer-verified": {
				label: "Good news! Your offer has been approved.",
			},
			"offer-new-registration": {
				label: "New registrations on your offer",
				"sub-label": "You have a new registration!",
			},
		},
		notifications: {
			title: "Notifications",
			"new-chat": {
				label: "New chat message",
				"sub-label": "You have a new chat message",
			},
			"recommend-task": {
				label: "New tasks",
				"sub-label":
					"Is making 4 blogs for Digital marketing B.V. something for you?",
			},
			"task-invite": {
				label: "Task invitations",
				"sub-label": "Company x has invited you for a task",
			},
		},
	},
};

export default setting;
