import moment from "moment";
import { ApiPaymentRequest } from "src/api/types";

interface PaymentRequestDateCellProps {
	request: ApiPaymentRequest;
}

const PaymentRequestDateCell = ({ request }: PaymentRequestDateCellProps) => {
	if (request.start_date && request.end_date) {
		return (
			<div className="flex flex-col">
				<span className="whitespace-nowrap text-[16px]">
					{moment(request.start_date).format("DD-MM-YYYY")}
				</span>
				<span className="whitespace-nowrap opacity-70 text-sm">
					{moment(request.start_date).format("HH:mm")} -{" "}
					{moment(request.end_date).format("HH:mm")}
				</span>
			</div>
		);
	}
	return (
		<span className="whitespace-nowrap text-[16px]">
			{moment(request.start_date).format("DD-MM-YYYY")}
		</span>
	);
};

export default PaymentRequestDateCell;
