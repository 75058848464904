import axios from "axios";
import { atom, useAtom } from "jotai";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import useTenant from "src/hooks/api/services/tenants/useTenant";
import usePrefetch from "src/hooks/api/usePrefetch";
import { updateProfile } from "src/redux/auth";

const loadingAtom = atom(false);

export default function useSetType() {
	const { tenant } = useTenant();
	// const { modules } = tenant;
	const [loading, setLoading] = useAtom(loadingAtom);
	const dispatch = useDispatch();
	const prefetch = usePrefetch();
	const navigate = useNavigate();

	const setType = async (type: "company" | "student") => {
		try {
			setLoading(true);
			const user = await axios
				.patch("/auth/type", {
					type,
				})
				.then(({ data }) => data.data);
			if (user?.id) {
				await authenticate(user);
			}

			setLoading(false);
		} catch (error) {
			setLoading(false);
			throw error;
		}
	};

	const authenticate = async (user: any) => {
		//Get the current tenant state
		prefetch.run(tenant);

		//Set the auth state
		dispatch(updateProfile(user));

		//Redirect to the url
		navigate(
			"/onboarding"
			// modules.includes("intern-task") ? "/onboarding" : "/dashboard"
		);
	};

	return {
		status: loading ? "loading" : "idle",
		actions: {
			setType,
		},
	};
}
