import React from "react";

interface SummaryWrapperProps {
	title?: string;
	icon?: any;
	children?: any;
}

export const SummaryWrapper = ({
	title,
	icon,
	children,
}: SummaryWrapperProps) => {
	return (
		<div className="border-b-2 border-border pb-6 ">
			{(title || icon) && (
				<div className="items-center flex flex-wrap gap-4 justify-between mb-2">
					<strong>{title}</strong>
					{icon}
				</div>
			)}
			<div className="flex flex-col gap-1">{children}</div>
		</div>
	);
};

export default SummaryWrapper;
