const reviews = {
	title: "Reviews",
	share: {
		created_one:
			"I just posted a 1-star review on {{tenant.name}}. Want to know more about {{tenant.name}}?",
		created_other:
			"I just posted a {{count}}-star review on {{tenant.name}}. Want to know more about {{tenant.name}}?",
		received_one:
			"I just received a 1-star review on {{tenant.name}}. Want to know more about {{tenant.name}}?",
		received_other:
			"I just received a {{count}}-star review on {{tenant.name}}. Want to know more about {{tenant.name}}?",
	},
};

export default reviews;
