import { useState } from "react";
import { ApiRegistration } from "src/api/types";
import Button from "src/components/Button";
import Input from "src/components/form/Input";
import withTranslation, {
	Translation,
} from "src/components/hoc/withTranslation";
import Modal from "src/components/Modal";
import usePost from "src/hooks/rest/usePost";

interface RegistrationDenieModalProps extends Translation {
	visible?: boolean;
	onClose: (data?: ApiRegistration) => Promise<unknown>;
	registrationId: number | string;
}

const RegistrationDenieModal = ({
	t,
	visible,
	onClose,
	registrationId,
}: RegistrationDenieModalProps) => {
	const [motivation, setMotivation] = useState("");
	const [denieRegistration, loading] = usePost(
		`/company/registrations/${registrationId}/denie`
	);
	const [isClosing, setIsClosing] = useState(false);

	const handleClose = async (registration?: ApiRegistration) => {
		setIsClosing(true);
		await onClose(registration);
		setIsClosing(false);
	};
	const handleDenie = () => {
		denieRegistration({
			deny_motivation: motivation,
		}).then(({ data }) => {
			setMotivation("");
			handleClose(data);
		});
	};
	return (
		<Modal
			title={t("title")}
			visible={visible || false}
			loading={isClosing}
			{...{ onClose }}
		>
			<p className="mb-4">{t("description")}</p>

			<div className="flex flex-col mb-4 gap-1">
				<span className="text-[16px] opacity-70">
					{t("defaults.title")}
				</span>

				<div className="flex flex-wrap gap-1">
					{["not-suitable", "already-selected"].map((key) => (
						<Button
							key={`default-${key}`}
							xsmall
							onClick={() =>
								setMotivation(
									t(`defaults.options.${key}.description`)
								)
							}
							type="border-bg"
						>
							{t(`defaults.options.${key}.label`)}
						</Button>
					))}
				</div>
			</div>

			<Input
				name="motivation"
				label={t("label")}
				placeholder={t("placeholder")}
				multiline
				value={motivation}
				onChange={(key, value) => setMotivation(value)}
			/>
			<div className="flex items-center justify-between gap-4 mt-8">
				<Button onClick={onClose} type="gray">
					{t("buttons.cancel")}
				</Button>
				<Button
					onClick={handleDenie}
					{...{ loading }}
					disabled={!motivation}
				>
					{t("buttons.deny")}
				</Button>
			</div>
		</Modal>
	);
};

RegistrationDenieModal.locale = {
	nl: {
		title: "Professional afwijzen",
		description:
			"Licht kort toe waarom deze professional niet geschikt is, hierdoor kunnen wij in de toekomst betere kandidaten selecteren voor jouw opdrachten. De kandidaat ziet deze toelichting niet.",
		confirm: {
			title: "Weet je het zeker?",
			description: "Weet je zeker dat je de aanmelding wilt afwijzen?",
			button: "Bevestigen",
		},
		label: "Waarom is deze professional niet geschikt?",
		placeholder: "Licht je keuze toe...",
		buttons: {
			cancel: "Annuleren",
			deny: "Afwijzen",
		},
		defaults: {
			title: "Standaard opties",
			options: {
				"not-suitable": {
					label: "Niet geschikt",
					description:
						"Deze professional is niet geschikt voor deze opdracht",
				},
				"already-selected": {
					label: "Kandidaat al gevonden",
					description:
						"We hebben al een kandidaat gekozen voor deze opdracht",
				},
			},
		},
	},
	en: {
		title: "Reject Professional",
		description:
			"Briefly explain why this professional is not suitable. This will help us select better candidates for your assignments in the future. The candidate will not see this explanation.",
		confirm: {
			title: "Are you sure?",
			description: "Are you sure you want to reject this application?",
			button: "Confirm",
		},
		label: "Why is this professional not suitable?",
		placeholder: "Explain your choice...",
		buttons: {
			cancel: "Cancel",
			deny: "Reject",
		},
		defaults: {
			title: "Default options",
			options: {
				"not-suitable": {
					label: "Not suitable",
					description:
						"This professional is not suitable for this assignment",
				},
				"already-selected": {
					label: "Candidate already selected",
					description:
						"We have already chosen a candidate for this assignment",
				},
			},
		},
	},
};

export default withTranslation(RegistrationDenieModal);
