import { Formik } from "formik";
import * as Yup from "yup";
import Input from "src/components/form/Input";
import useGet from "src/hooks/rest/useGet";
import Button from "src/components/Button";
import usePatch from "src/hooks/rest/usePatch";
import useAlert from "src/hooks/useAlert";
import Skeleton from "src/components/Skeleton";
import { useTranslation } from "react-i18next";
import useCompanyContactScheme from "src/hooks/schemes/useCompanyContactScheme";

const CompanyContactForm = () => {
	const { t } = useTranslation(["forms", "buttons"]);
	const [{ data }, loading] = useGet(`/company/contact`);
	const [upsert] = usePatch(`/company/contact`);
	const [sendAlert] = useAlert();
	const scheme = useCompanyContactScheme();

	const handleSubmit = async (values: any) => {
		const result = await upsert(values);
		sendAlert({
			title: t("alert.title", {
				ns: "forms",
				keyPrefix: "company-contact-form",
			}),
			text: t("alert.description", {
				ns: "forms",
				keyPrefix: "company-contact-form",
			}),
		});
		return result;
	};

	return (
		<>
			{loading && (
				<div className="flex flex-col gap-4">
					<Input.Loading />
					<Input.Loading />
					<Input.Loading />
					<Skeleton className="w-32 h-[50px]" />
				</div>
			)}
			{!loading && (
				<Formik
					initialValues={{
						website: data?.website || "",
						telephone: data?.telephone || "",
						email: data?.email || "",
					}}
					validationSchema={Yup.object(scheme)}
					onSubmit={(values, { setSubmitting }) => {
						handleSubmit(values).finally(() =>
							setSubmitting(false)
						);
					}}
				>
					{({
						values,
						errors,
						touched,
						handleChange,
						handleBlur,
						handleSubmit,
						isSubmitting,
					}) => (
						<form
							onSubmit={handleSubmit}
							className="flex flex-col gap-4"
						>
							<Input
								name="telephone"
								label={t("telephone", {
									ns: "forms",
									keyPrefix: "company-contact-form",
								})}
								type="telephone"
								value={values.telephone}
								valid={values.telephone && !errors.telephone}
								error={
									errors.telephone &&
									touched.telephone &&
									errors.telephone
								}
								{...{ handleBlur, handleChange }}
							/>
							<Input
								name="email"
								label={t("email", {
									ns: "forms",
									keyPrefix: "company-contact-form",
								})}
								type="email"
								value={values.email}
								valid={values.email && !errors.email}
								error={
									errors.email &&
									touched.email &&
									errors.email
								}
								{...{ handleBlur, handleChange }}
							/>
							<Input
								name="website"
								label={t("website", {
									ns: "forms",
									keyPrefix: "company-contact-form",
								})}
								value={values.website}
								valid={values.website && !errors.website}
								error={
									errors.website &&
									touched.website &&
									errors.website
								}
								{...{ handleBlur, handleChange }}
							/>
							<div className="flex">
								<Button submit loading={isSubmitting}>
									{t("save", { ns: "buttons" })}
								</Button>
							</div>
						</form>
					)}
				</Formik>
			)}
		</>
	);
};

export default CompanyContactForm;
