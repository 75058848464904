import withTranslation, {
	Translation,
} from "src/components/hoc/withTranslation";
import TabNav from "src/components/layout/tab-nav/TabNav";
import useAuth from "src/hooks/selectors/useAuth";

const SettingsNav = ({ t }: Translation) => {
	const auth = useAuth();

	if (auth.type === "student") {
		return (
			<div className="flex flex-col mb-4">
				<TabNav
					tabs={[
						{
							to: "/settings/notifications",
							label: t("notifications"),
						},
					]}
				/>
			</div>
		);
	}

	return (
		<div className="flex flex-col mb-4">
			<TabNav
				tabs={[
					{
						to: "/settings/notifications",
						label: t("notifications"),
					},
					{
						to: "/settings/payment-requests",
						label: t("payment-requests"),
					},
					{
						to: "/settings/network",
						label: t("network"),
					},
				]}
			/>
		</div>
	);
};

SettingsNav.locale = {
	nl: {
		notifications: "Notificaties",
		"payment-requests": "Betaalverzoeken",
		network: "Netwerk",
	},
	en: {
		notifications: "Notifications",
		"payment-requests": "Payment requests",
		network: "Network",
	},
};

export default withTranslation(SettingsNav);
