import { ComponentProps } from "react";
import { useDateRangeFilter } from "src/components/date-range-filter/Provider";
import withTranslation, {
	Translation,
} from "src/components/hoc/withTranslation";
import { PopoverTrigger } from "src/components/ui/popover";
import { cn } from "src/lib/utils";

type Extended = Translation & ComponentProps<"button">;

interface TriggerProps extends Extended {
	placeholder?: string;
}

const Trigger = ({
	t,
	placeholder: _placeholder,
	className,
	...rest
}: TriggerProps) => {
	const { value } = useDateRangeFilter();
	const placeholder = _placeholder || t("placeholder");
	const format = "D MMM";
	// const format = "DD-MM-YYYY";

	return (
		<PopoverTrigger asChild>
			<button
				{...rest}
				className={cn(
					"flex gap-2 items-center cursor-pointer px-5 py-2.5 rounded-md hover:bg-accent transition-colors",
					className
				)}
			>
				<i className="far fa-calendar"></i>
				<span className="whitespace-nowrap">
					{value?.start && value?.end
						? `${value.start.format(format)} - ${value.end.format(
								format
						  )}`
						: placeholder}
				</span>
			</button>
		</PopoverTrigger>
	);
};

Trigger.locale = {
	nl: {
		placeholder: "Datum",
	},
	en: {
		placeholder: "Date",
	},
};

export default withTranslation(Trigger);
