import { ReactNode, useState } from "react";
import { ApiProfileMinimal, ApiUserMinimal } from "src/api/types";
import Card from "src/components/Card";
import UserCardCustomForm from "src/components/user-card/elements/UserCardCustomForm";
import UserCardStatus from "src/components/user-card/elements/UserCardStatus";
import { cn } from "src/lib/utils";
import { UserCardContext } from "./UserCardContext";
import UserCardActions from "./elements/UserCardActions";
import UserCardAvatar from "./elements/UserCardAvatar";
import UserCardBadges from "./elements/UserCardBadges";
import UserCardButtonCreateTask from "./elements/UserCardButtonCreateTask";
import UserCardName from "./elements/UserCardName";
import UserCardStars from "./elements/UserCardStars";
import UserCardTelephone from "./elements/UserCardTelephone";

interface UserCardProps {
	user: ApiProfileMinimal | ApiUserMinimal;
	children?: ReactNode;
	card?: {
		className?: string;
		contentClassName?: string;
	};
}

const UserCard = ({ user, children, card }: UserCardProps) => {
	const [status, setStatus] = useState<"idle" | "deleting">("idle");

	return (
		<UserCardContext.Provider value={{ user, status, setStatus }}>
			<Card
				className={card?.className}
				contentClassName={cn(
					"p-6 flex flex-col gap-4",
					card?.contentClassName
				)}
			>
				{children}
			</Card>
		</UserCardContext.Provider>
	);
};

UserCard.Avatar = UserCardAvatar;
UserCard.Name = UserCardName;
UserCard.Stars = UserCardStars;
UserCard.Badges = UserCardBadges;
UserCard.Telephone = UserCardTelephone;
UserCard.CustomForm = UserCardCustomForm;
UserCard.ButtonCreateTask = UserCardButtonCreateTask;
UserCard.Actions = UserCardActions;
UserCard.Status = UserCardStatus;

export default UserCard;
