import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import moment from "moment";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { ApiTask } from "src/api/types";
import Button from "src/components/Button";
import Modal from "src/components/Modal";
import useFetchProfile from "src/hooks/api/useFetchProfile";
import ReadMore from "src/components/ReadMore";
import KeyValue from "src/components/KeyValue";
import { formatPrice } from "src/lib/formatters";
import { useTranslation } from "react-i18next";

interface PublishDraftModalProps {
	setModalTask: Dispatch<SetStateAction<null | string>>;
	taskId: null | string;
}

const PublishDraftModal = ({
	setModalTask,
	taskId,
}: PublishDraftModalProps) => {
	const { t } = useTranslation(["tasks", "buttons"]);
	const [loadingPublish, setLoadingPublish] = useState(false);
	const [loading, setLoading] = useState(false);
	const [task, setTask] = useState<ApiTask | null>(null);
	const navigate = useNavigate();
	const { fetchProfile } = useFetchProfile();
	const isSameDate =
		moment(task?.start_date).format() === moment(task?.end_date).format();

	useEffect(() => {
		if (!taskId) return setTask(null);

		setLoading(true);

		axios
			.get(`/company/tasks/${taskId}`)
			.then(({ data }) => setTask(data.data))
			.catch(console.error)
			.finally(() => setLoading(false));
	}, [taskId]);

	const publishTask = () => {
		if (!taskId || !task) return;

		setLoadingPublish(true);

		axios
			.post(`/company/tasks/publish-draft/${taskId}`)
			.then(() => {
				fetchProfile().then(() => {
					navigate(`/admin/tasks/${taskId}/show`);
				});
			})
			.catch(console.error)
			.finally(() => setLoadingPublish(false));
	};

	return (
		<Modal
			title={t("title", {
				keyPrefix: "publish-draft-modal",
				ns: "tasks",
			})}
			onClose={() => setModalTask(null)}
			visible={taskId ? true : false}
		>
			{loading && (
				<div className="flex flex-col flex-1 p-8 justify-center items-center">
					<span className="base-loader"></span>
				</div>
			)}
			{!loading && !task && (
				<p>
					{t("no-results", {
						keyPrefix: "publish-draft-modal",
						ns: "tasks",
					})}
				</p>
			)}
			{!loading && task && (
				<div className="flex flex-col gap-3">
					<div className="flex flex-col">
						<strong>{task.title}</strong>
						<ReadMore text={task.description} />
					</div>
					<div className="flex flex-col">
						<KeyValue.Small
							label={t("keys.amount-of-students", {
								keyPrefix: "publish-draft-modal",
								ns: "tasks",
							})}
							text={task.amount_of_students}
						/>
						<KeyValue.Small
							label={t("keys.budget", {
								keyPrefix: "publish-draft-modal",
								ns: "tasks",
							})}
							text={
								<>
									{formatPrice(task.budget)}
									{task.amount_of_students > 1 ? " p.p." : ""}
								</>
							}
						/>
						{task?.estimated_duration && (
							<KeyValue.Small
								label={t("keys.estimated-hourly-rate", {
									keyPrefix: "publish-draft-modal",
									ns: "tasks",
								})}
								text={
									<>
										{formatPrice(
											task.budget /
												task.amount_of_students
										)}
										<small className="ml-2">
											{t("keys.per-hour", {
												keyPrefix:
													"publish-draft-modal",
												ns: "tasks",
											})}
										</small>
									</>
								}
							/>
						)}
						{!isSameDate && (
							<>
								<KeyValue.Small
									label={t("keys.start-date", {
										keyPrefix: "publish-draft-modal",
										ns: "tasks",
									})}
									text={moment(task.start_date).format(
										"DD MMMM"
									)}
								/>
								<KeyValue.Small
									label={t("keys.end-date", {
										keyPrefix: "publish-draft-modal",
										ns: "tasks",
									})}
									text={moment(task.end_date).format(
										"DD MMMM"
									)}
								/>
							</>
						)}
						{isSameDate && (
							<KeyValue.Small
								label={t("keys.date", {
									keyPrefix: "publish-draft-modal",
									ns: "tasks",
								})}
								text={moment(task.end_date).format("DD MMMM")}
							/>
						)}
					</div>

					<div className="flex justify-between items-center gap-4 flex-wrap mt-8">
						<Button
							key="button-edit"
							type="gray"
							disabled={loadingPublish}
							onClick={() =>
								navigate(`/admin/tasks/${task.hid}/edit`)
							}
						>
							{t("edit", {
								ns: "buttons",
							})}
						</Button>
						<Button
							key="button-post"
							loading={loadingPublish}
							onClick={() => publishTask()}
						>
							{t("task.create", {
								ns: "buttons",
							})}
						</Button>
					</div>
				</div>
			)}
		</Modal>
	);
};

export default PublishDraftModal;
