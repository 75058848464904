import { ComponentProps, useState } from "react";
import usePercentageOfTimeInDay from "src/hooks/usePercentageOfTimeInDay";
import { cn } from "src/lib/utils";

const ROW_COUNT = 24 * 4; // 24 hours in quarters
const ITEM_MIN_HEIGHT = "1.75rem";

interface BackgroundGridProps {
	days: number;
}

export const BackgroundGrid = ({ days }: BackgroundGridProps) => {
	const itemCount = days * 24;
	return (
		<div className="absolute inset-0 pointer-events-none flex">
			<div
				className="grid h-full w-16"
				style={{
					gridTemplateRows: `repeat(${ROW_COUNT}, ${ITEM_MIN_HEIGHT})`,
				}}
			>
				{new Array(24).fill(0).map((_, index) => (
					<div
						key={`grid-item-${index}`}
						className="flex flex-col row-span-4"
					>
						<span className="text-sm opacity-70">
							{index < 10 ? `0${index}` : index}:00
						</span>
					</div>
				))}
			</div>
			<Grid days={days}>
				{new Array(itemCount).fill(0).map((_, index) => (
					<div
						key={`grid-item-${index}`}
						className="flex flex-col border-r border-b border-border row-span-4"
						style={{}}
					/>
				))}
			</Grid>
		</div>
	);
};

interface GridProps {
	days: number;
}

export const Grid = ({
	days,
	children,
	className,
	style,
	...rest
}: ComponentProps<"div"> & GridProps) => {
	return (
		<div
			{...rest}
			className={cn("flex-1 relative grid", className)}
			style={{
				...(style || {}),
				gridTemplateColumns: `repeat(${days}, 1fr)`,
				gridTemplateRows: `repeat(${ROW_COUNT}, ${ITEM_MIN_HEIGHT})`,
			}}
		>
			{children}
		</div>
	);
};

interface CurrentTimeLineProps {
	onMounted: (height: number) => void;
}

export const CurrentTimeLine = ({
	className,
	onMounted,
	...rest
}: ComponentProps<"div"> & CurrentTimeLineProps) => {
	const percentage = usePercentageOfTimeInDay(new Date());
	const [height, setHeight] = useState(0);

	return (
		<div
			{...rest}
			ref={(node) => {
				if (!node) return;
				const { height } = node.getBoundingClientRect();
				if (height) {
					onMounted(height * percentage);
					setHeight(height);
				}
			}}
			className={cn(
				"absolute inset-0 z-10 pointer-events-none",
				className
			)}
		>
			<hr
				style={{ marginTop: height * percentage }}
				className="border border-aqua rounded-md"
				id="current-time-line"
			/>
		</div>
	);
};
