import { atom } from "jotai";
import { AxiosInstance } from "axios";
import useApi, {
	ApiActions,
	ApiStatus,
	Pagination,
} from "src/hooks/api/utils/useApi";
import useForm from "src/hooks/schemes/useForm";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { ApiProspect } from "src/api/types";

interface SignupStudentActions extends ApiActions {
	getField: (name: string) => any;
	setForm: any;
	resetForm: any;
	create: () => Promise<any>;
}

type State = {
	prospect: ApiProspect;
	form: any;
	validate: any;
	status: ApiStatus;
	actions: SignupStudentActions;
	api: AxiosInstance;
	pagination: Pagination;
};

const formAtom: any = atom({});
const validateAtom: any = atom({});

type Options = {
	filter?: any;
};

function useProspect(options?: Options): State {
	const navigate = useNavigate();
	const { t } = useTranslation("schemes");
	const { state, actions, api, pagination } = useApi(
		{ id: "PROSPECT" },
		{
			baseUrl: `/auth/prospects`,
			query: {
				...(options?.filter || {}),
			},
		}
	);
	const {
		form,
		actions: { setForm, getField, setErrors, resetForm },
		validate,
	} = useForm({
		atoms: {
			form: formAtom,
			validate: validateAtom,
		},
		scheme: {
			company_name: ["string", "required"],
			email: ["string", "email", "required"],
			website: ["string"],
		},
	});

	const create = async () => {
		if (!validate.isValid) return;
		try {
			const data = await actions.create(form);
			if (data?.slug) {
				navigate(`/signup/company/${data.slug}`);
			}
			actions.set((state: any) => ({
				...state,
				data,
				status: "idle",
			}));
		} catch (error: any) {
			if (error?.errors?.in_use) {
				setErrors({
					...state,
					email: t("email-in-use"),
				});
			}
		}
	};

	return {
		prospect: state?.data || [],
		form,
		validate,
		status: state.status,
		pagination,
		actions: {
			...actions,
			create,
			getField,
			setForm,
			resetForm,
		},
		api,
	};
}

export default useProspect;
