import { useState } from "react";
import Card from "src/components/Card";
import Input from "src/components/form/Input";
import withTranslation, {
	Translation,
} from "src/components/hoc/withTranslation";
import { useUpdateEffectDebounce } from "src/hooks/useDebounce";
import useElementSize from "src/hooks/useElementSize";

interface FlatListHeadProps extends Translation {
	title: string;
	subtitle?: any;
	children?: any;
	search?: {
		onSearch: (q: string) => any;
		placeholder?: string;
	};
}

const FlatListHead = ({
	t,
	title,
	subtitle,
	children,
	search,
}: FlatListHeadProps) => {
	const [query, setQuery] = useState<string>("");
	useUpdateEffectDebounce(query, 1000, (value) => {
		if (search?.onSearch) {
			search.onSearch(value);
		}
	});
	const actionBar = useElementSize();

	return (
		<Card className=" ">
			<div className="flex items-center justify-between gap-4 flex-wrap">
				<div className="flex flex-col gap-2">
					<h3 className="">{title}</h3>
					{typeof subtitle === "string" ? (
						<p>{subtitle}</p>
					) : (
						subtitle
					)}
				</div>
				<div
					ref={actionBar.ref}
					className="flex md:flex-row flex-col md:items-center items-stretch md:gap-4 gap-2 flex-1 justify-end"
				>
					{children}

					{search?.onSearch && (
						<>
							{children ? (
								<div
									style={{
										height: actionBar.size.height,
									}}
									className={`w-[2px] rounded-md hidden md:flex bg-accent-dark`}
								></div>
							) : (
								false
							)}

							<Input
								name="search"
								onChange={(key, value) => setQuery(value)}
								noMarginBottom
								className={`md:max-w-[240px]`}
								placeholder={
									search.placeholder || t("placeholder")
								}
								value={query}
							/>
						</>
					)}
				</div>
			</div>
		</Card>
	);
};

FlatListHead.locale = {
	nl: {
		placeholder: "Zoeken...",
	},
	en: {
		placeholder: "Search...",
	},
};

export default withTranslation(FlatListHead);
