import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { useParams } from "react-router-dom";
import Button from "src/components/Button";
import Modal from "src/components/Modal";
import Field from "src/components/field/Field";
import withTranslation, {
	Translation,
} from "src/components/hoc/withTranslation";
import ShareClipboard from "src/components/promotion/share/ShareClipboard";

import useProjects from "src/hooks/api/services/projects/useProjects";
import useAuth from "src/hooks/selectors/useAuth";
import { getAdminUrl } from "src/lib/urls";

interface CreateProjectModalProps extends Translation {
	defaultVisible: boolean;
	backUrl: string;
}

function ShareProjectModal({ t, defaultVisible }: CreateProjectModalProps) {
	const [errorCheck, setErrorCheck] = useState(false);
	const visible = defaultVisible;
	const navigate = useNavigate();
	const auth = useAuth();
	const { project } = useParams() as any;
	const {
		actions: projectActions,
		project: projectState,
		status,
		formState,
		setFormState,
		clearFormState,
	} = useProjects();

	useEffect(() => {
		setFormState({
			icon: projectState.icon,
			name: projectState.name,
			start_date: projectState.start_date,
			end_date: projectState.end_date,
			members: projectState.members,
			skills: projectState.skills,
		});

		return () => {
			clearFormState();
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [projectState.id]);

	const onClose = () => {
		navigate(`/projects/${project}`);
	};

	const onSubmit = () => {
		if (status !== "idle") return;
		// const missingValues = projectState.members.filter(
		// 	(value: string) => !formState.members.includes(value)
		// );
		// if (missingValues.length > 0) {
		// 	setErrorCheck(true);
		// }
		projectActions
			.update(
				{
					id: projectState.id,
					...formState,
				},
				{
					updateList: true,
				}
			)
			.then((data: any) => {
				// Get the current pathname
				// const currentPath = window.location.pathname;
				// // Remove "/share" from the path
				// const newPath = currentPath.replace("/share", "");
				// // Create a new URL by combining the origin and the new path
				// const newUrl = window.location.origin + newPath;
				// setShareLink(newUrl);
			});
	};

	return (
		<Modal
			size="default"
			className=""
			// bodyClassName="p-6"
			{...{ onClose, visible }}
			title={t("title")}
			loading={status === "loading"}
		>
			<div className="flex flex-col gap-4">
				<p>{t("description")}</p>
				<Field.Users
					wrapper={{
						label: t("fields.members.label"),
						layout: "horizontal",
					}}
					value={
						formState.members
							? formState.members.map((user: any) =>
									typeof user === "object"
										? user.id.toString()
										: user
							  )
							: []
					}
					onChange={(value) => {
						setErrorCheck(false);
						if (
							value?.includes("me") &&
							formState.members.find((u: any) => u.id === auth.id)
						) {
							value = value.filter(
								(item: any) =>
									item !== "me" &&
									item !== auth?.id &&
									item !== auth.id.toString()
							);
						}
						setFormState((prev) => ({
							...prev,
							members: value,
						}));
					}}
				/>
				{errorCheck && (
					<div className="input_has-error">
						{t("remove-existing-users")}
					</div>
				)}
				<Button
					loading={status === "updating"}
					disabled={errorCheck}
					type="dark"
					className="w-full mb-2"
					onClick={onSubmit}
				>
					{t("create")}
				</Button>
			</div>

			<hr className="border-border mt-4" />

			<div className="flex flex-col mt-4">
				<ShareClipboard url={getAdminUrl(`/projects/${project}`)} />
			</div>
		</Modal>
	);
}

ShareProjectModal.locale = {
	nl: {
		title: "Delen",
		create: "Toevoegen",
		close: "Afsluiten",
		description: "Voeg teamleden toe aan dit project",
		error_date: "Start datum kan niet voor eind datum of andersom",
		fields: {
			members: {
				label: "Leden",
			},
		},
		"remove-existing-users": "Je kan niet bestaande leden verwijderen",
	},
	en: {
		title: "Share",
		create: "Add",
		close: "Close",
		description: "Add members to this project",

		error_date: "Start date cannot be before end date or vice versa",
		fields: {
			members: {
				label: "Members",
			},
		},
		"remove-existing-users": "You cannot remove existing members",
	},
};

export default withTranslation(ShareProjectModal);
