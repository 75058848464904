const middlewares = {
	"multiple-companies": {
		title: "Locations",
		heading: "You need a premium subscription for this feature",
		description:
			"To use multiple locations you need a <1>Premium</1> subscription.",
	},
	network: {
		title: "Connections",
		heading: "You need a premium subscription for this feature",
		description:
			"To use the professional pool you need a <1>Premium</1> subscription.",
	},
};

export default middlewares;
