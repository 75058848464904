import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Address } from "src/api/types";
import Button from "src/components/Button";
import AddressForm from "src/components/forms/AddressForm";
import Modal from "src/components/Modal";
import useEditProfile from "src/hooks/api/useEditProfile";

const EditAddress = () => {
	const { t } = useTranslation(["buttons", "forms"]);
	const [visible, setVisible] = useState(false);
	const auth = useSelector((state: any) => state.auth);
	const [editProfile, fetching] = useEditProfile();

	const onSubmit = (address: Address) => {
		editProfile({ address }).then(() => {
			setVisible(false);
		});
	};

	const ModalContent = () => {
		if (auth.address) {
			return (
				<AddressForm
					address={auth?.address}
					button_title={t("edit", { ns: "buttons" })}
					editForm
					{...{ onSubmit, fetching }}
				/>
			);
		}

		if (auth.onboarding_status === "not-filled" || 1) {
			return (
				<div className="flex flex-col justify-center items-center gap-4">
					<p>
						{t("complete-account.description", {
							ns: "forms",
							keyPrefix: "edit-address",
						})}
					</p>
					<Button type="dark" to="/dashboard/complete-account">
						{t("complete-account.button", {
							ns: "forms",
							keyPrefix: "edit-address",
						})}
					</Button>
				</div>
			);
		}

		return (
			<p>{t("unknown", { ns: "forms", keyPrefix: "edit-address" })}</p>
		);
	};

	return (
		<>
			<Modal {...{ visible }} onClose={() => setVisible(false)}>
				<ModalContent />
			</Modal>
			<Button
				small
				onClick={() => setVisible(true)}
				type="dark"
				className="w-max"
			>
				{t("button", { ns: "forms", keyPrefix: "edit-address" })}
			</Button>
		</>
	);
};

export default EditAddress;
