import { useTranslation } from "react-i18next";
import { formatPrice } from "src/lib/formatters";
import * as Yup from "yup";

function useOfferScheme() {
	const { t } = useTranslation("schemes");
	return {
		title: Yup.string().required(t("required")),
		budget: Yup.number()
			.typeError(t("type-number"))
			.min(
				20,
				t("min-worth-price", {
					value: formatPrice(20),
				})
			)
			.required(t("required")),
		description: {
			intro: Yup.string().required(t("required")),
			what: Yup.string().required(t("required")),
			how: Yup.string().required(t("required")),
		},
		category_id: Yup.number()
			.typeError(t("category-unknown"))
			.when("category_name", {
				is: (categoryName) => !categoryName,
				then: Yup.number().required(t("required")),
				otherwise: Yup.number(),
			}),
		category_name: Yup.string(),
		estimated_duration: Yup.number().typeError(t("duration-in-hour")),
		tags: Yup.array(),
	};
}

export default useOfferScheme;
