import { useTranslation } from "react-i18next";
import {
	FacebookShareButton,
	LinkedinShareButton,
	TwitterShareButton,
} from "react-share";
import { ApiReview } from "src/api/types";
import ButtonMenu from "src/components/ButtonMenu";
import useTenant from "src/hooks/api/services/tenants/useTenant";
import useAuth from "src/hooks/selectors/useAuth";

interface ShareReviewProps {
	review: ApiReview;
}

const ShareReview = ({ review }: ShareReviewProps) => {
	const auth = useAuth();
	const { t } = useTranslation(["reviews"]);
	const { tenant } = useTenant();
	const url = tenant?.urls?.public;
	const text = t(
		auth.id === review?.creator?.id ? "share.created" : "share.received",
		{
			count: review.rating,
		}
	);

	return (
		<ButtonMenu head={<i className="fal fa-share-alt"></i>}>
			<div className="flex flex-col gap-2">
				<FacebookShareButton
					hashtag="test"
					className="share-button share-button_facebook gtm-werkgever-dashboard-review--button-delen-op-facebook"
					quote={text}
					{...{ url }}
				>
					<Icon type="facebook" />
				</FacebookShareButton>
				<LinkedinShareButton
					source={url}
					className="share-button share-button_linkedin gtm-werkgever-dashboard-review--button-delen-op-linkedin"
					summary={text}
					{...{ url }}
				>
					<Icon type="linkedin" />
				</LinkedinShareButton>
				<TwitterShareButton
					className="share-button share-button_twitter gtm-werkgever-dashboard-review--button-delen-op-twitter"
					via={text}
					{...{ url }}
				>
					<Icon type="twitter" />
				</TwitterShareButton>
			</div>
		</ButtonMenu>
	);
};

interface IconProps {
	type: "facebook" | "twitter" | "linkedin";
}

const Icon = ({ type }: IconProps) => {
	const className = `w-8 h-8 flex justify-center items-center bg-dark text-white rounded-sm cursor-pointer gtm-werkgever-dashboard-review--button-delen-op-${type}`;
	return (
		<span {...{ className }}>
			<i
				className={`fab fa-${type}${
					type !== "linkedin" ? "-square" : ""
				}`}
			></i>
		</span>
	);
};

export default ShareReview;
