import React from "react";
import Card from "src/components/Card";
import Skeleton from "src/components/Skeleton";

interface IconTotalCardProps {
	icon: string;
	label: string;
	children?: any;
}

const IconTotalCard = ({ children, icon, label }: IconTotalCardProps) => {
	return (
		<Card
			className=" p-0 md:w-auto w-full"
			contentClassName=" p-0"
			bg="transparent"
		>
			<div
				className={`p-6 rounded-md flex items-center gap-4 bg-background`}
			>
				<div className="w-12 h-12 flex justify-center items-center">
					<i className={`text-3xl ${icon}`}></i>
				</div>
				<div className="flex flex-col">
					<strong className="text-2xl">{children}</strong>
					<p className="mb-0 opacity-50">{label}</p>
				</div>
			</div>
		</Card>
	);
};

const Loading = () => {
	const bg = "gray";
	return (
		<div className={`p-6 rounded-md flex items-center gap-4 bg-background`}>
			<div className="w-12 h-12 flex justify-center items-center">
				<Skeleton className={`w-10 h-10 bg-${bg}`} />
			</div>
			<div className="flex flex-col gap-1">
				<Skeleton className={`w-20 h-4 bg-${bg}`} />
				<Skeleton className={`w-[190px] h-6 bg-${bg}`} />
			</div>
		</div>
	);
};

IconTotalCard.Loading = Loading;

export default IconTotalCard;
