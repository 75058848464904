import moment from "moment";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import Button from "src/components/Button";
import Link from "src/components/Link";
import Skeleton from "src/components/Skeleton";
import ListProps from "src/components/lists/ListProps";
import PaymentBadge from "src/components/payment/PaymentBadge";
import List from "src/components/ui/list";
import usePayments from "src/hooks/api/services/payments/usePayments";
import useAuth from "src/hooks/selectors/useAuth";
import useWindowSize from "src/hooks/useWindowSize";
import { formatPrice } from "src/lib/formatters";
import { cn } from "src/lib/utils";

const PaymentList = ({ query }: ListProps) => {
	const { t } = useTranslation(["lists", "payments"]);
	const auth = useAuth();
	const { isPhone } = useWindowSize();
	const { payments, status, actions, pagination } = usePayments({ query });

	useEffect(() => {
		actions.list({ query });
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [query]);

	const translations = {
		status: t("status", { ns: "lists", keyPrefix: "headers" }),
		invoice_number: t("invoice-number", {
			ns: "lists",
			keyPrefix: "headers",
		}),
		method: t("method", { ns: "lists", keyPrefix: "headers" }),
		type: t("type", { ns: "lists", keyPrefix: "headers" }),
		price: t("price", { ns: "lists", keyPrefix: "headers" }),
		description: t("description", { ns: "lists", keyPrefix: "headers" }),
		company: t("company", { ns: "lists", keyPrefix: "headers" }),
		task: t("task", { ns: "lists", keyPrefix: "headers" }),
		date: t("estimated-date", { ns: "lists", keyPrefix: "headers" }),
	};

	let headers = [] as string[];
	if (auth.type === "company") {
		headers = [
			translations.status,
			translations.invoice_number,
			translations.method,
			translations.type,
			translations.price,
			translations.description,
			"",
		];
	} else {
		headers = [
			translations.status,
			translations.task,
			translations.company,
			translations.price,
			translations.date,
		];
	}

	if (isPhone) {
		headers.splice(headers.indexOf(translations.method), 1);
		headers.splice(headers.indexOf(translations.status), 1);
		headers.splice(headers.indexOf(translations.description), 1);
		headers.splice(headers.indexOf(translations.company), 1);
	}

	return (
		<List data={payments} {...{ status }}>
			<List.Head>
				{headers.map((header, index) => (
					<List.Item
						key={`${header}-${index}`}
						className={cn(
							header === translations.date && "whitespace-nowrap"
						)}
					>
						{header}
					</List.Item>
				))}
			</List.Head>
			<List.Items>
				{(row) => (
					<>
						{isPhone && auth.type === "student" && (
							<List.Row
								className={
									row.needs_attention
										? "bg-red-200 border-0"
										: "border-0"
								}
								key={row.id + row.hid}
							>
								<List.Item className="min-w-[180px]">
									<div className="flex">
										<PaymentBadge status={row.status} />
									</div>
								</List.Item>
								<List.Item>{formatPrice(row.total)}</List.Item>
							</List.Row>
						)}

						<List.Row
							className={row.needs_attention ? "bg-red-200" : ""}
							key={row.id + row.hid}
						>
							{!isPhone && (
								<List.Item>
									<div className="flex">
										<PaymentBadge status={row.status} />
									</div>
								</List.Item>
							)}
							{headers.includes(translations.task) &&
								row.task && (
									<List.Item
										colSpan={isPhone ? 2 : undefined}
										className="w-full"
									>
										{row?.task?.hid &&
										row?.registration?.hid ? (
											<Link
												to={`/tasks/${row?.task?.hid}/show/${row?.registration?.hid}`}
												target="_blank"
											>
												{row?.task?.type !==
													"PAYMENT_REQUEST" &&
													t("task", {
														keyPrefix:
															"payment-list",
														ns: "lists",
													})}
												{row?.task?.title}
											</Link>
										) : (
											<span>
												{t("task", {
													keyPrefix: "payment-list",
													ns: "lists",
												})}
												{row?.task?.title}
											</span>
										)}
									</List.Item>
								)}
							{headers.includes(translations.invoice_number) && (
								<List.Item>{row.invoice_number}</List.Item>
							)}
							{headers.includes(translations.method) && (
								<List.Item>
									{t(`methods.${row.type}`, {
										ns: "payments",
									})}
								</List.Item>
							)}
							{headers.includes(translations.type) && (
								<List.Item>
									{[
										"INCREASE_WALLET",
										"SUBSCRIPTION",
									].includes(row.reference)
										? t(`types.subscription`, {
												ns: "payments",
										  })
										: t(`types.payout`, {
												ns: "payments",
										  })}
								</List.Item>
							)}
							{auth.type === "company" && isPhone && (
								<List.Item>{formatPrice(row.total)}</List.Item>
							)}
							{!isPhone && (
								<>
									{headers.includes(translations.company) && (
										<List.Item className="w-full">
											<p className="whitespace-nowrap">
												{row?.company?.name}
											</p>
										</List.Item>
									)}
									<List.Item>
										{formatPrice(row.total)}
									</List.Item>
									{headers.includes(
										translations.description
									) && (
										<List.Item>
											<span className="line-clamp-2">
												{row.description}
											</span>
										</List.Item>
									)}
								</>
							)}
							{auth.type === "student" && (
								<List.Item>
									{row.status === "completed" &&
									row.statuses?.completed
										? moment(
												row.statuses?.completed
										  ).format("DD-MM-YYYY")
										: "-"}
								</List.Item>
							)}
							{auth.type === "company" && (
								<List.Item className="sticky right-0">
									<Button
										iconOnly
										type="gray"
										to={`/payments/${row.hid}`}
									>
										<i className="fas fa-eye"></i>
									</Button>
								</List.Item>
							)}
						</List.Row>
					</>
				)}
			</List.Items>
			<List.Loading>
				<List.Item>
					<Skeleton className="h-6 w-full" />
				</List.Item>
				<List.Item className="w-full">
					<Skeleton className="h-6 w-full" />
				</List.Item>
				<List.Item>
					<Skeleton className="h-6 w-full" />
				</List.Item>
				<List.Item>
					<Skeleton className="h-6 w-full" />
				</List.Item>{" "}
				<List.Item>
					<Skeleton className="h-6 w-full" />
				</List.Item>
			</List.Loading>
			<List.Empty>
				<h2>
					{t("empty.title", {
						keyPrefix: "payment-list",
						ns: "lists",
					})}
				</h2>
			</List.Empty>
			<List.Pagination
				{...{ pagination }}
				onNavigate={pagination.setPage}
			/>
		</List>
	);
};

export default PaymentList;
