import moment from "moment";
import { ComponentProps } from "react";
import { ApiRegistration } from "src/api/types";
import withTranslation, {
	Translation,
} from "src/components/hoc/withTranslation";
import ResumeMiddleware from "src/components/middlewares/ResumeMiddleware";
import ReadMore from "src/components/ReadMore";
import RegistrationSection from "src/components/tasks/panes/registration/RegistrationSection";
import useTenant from "src/hooks/api/services/tenants/useTenant";
import useAuth from "src/hooks/selectors/useAuth";
import { cn } from "src/lib/utils";

interface RegistrationDetailsProps extends ComponentProps<"div"> {
	registration: ApiRegistration;
	variant?: "vertical" | "horizontal";
	sectionClassName?: string;
}

const RegistrationDetails = ({
	t,
	registration,
	variant = "horizontal",
	className,
	sectionClassName,
	...rest
}: RegistrationDetailsProps & Translation) => {
	const auth = useAuth();
	const { tenant } = useTenant();
	let answers = registration?.answers || [];
	const user: any = registration?.user || {};
	const currentEducation = user?.current?.education;
	const modules = user?.resume_modules || [];
	const hasAboutMe =
		modules?.includes("skills") ||
		currentEducation?.title ||
		user?.bio ||
		user?.telephone;

	if (!(answers?.length > 0) && registration?.motivation) {
		answers = [
			{
				hid: "",
				question: "Motivatie",
				answer: registration.motivation,
			},
		];
	}

	const hasRegistration = answers.some((answer: any) => !!answer.answer);

	if (
		registration?.user?.variant === "FULL_ANONYMOUS" &&
		auth.type !== "student"
	) {
		return (
			<ResumeMiddleware className="flex flex-col gap-4 p-8 md:p-12">
				<div className="flex flex-col">
					<ResumeMiddleware.Title />
					<ResumeMiddleware.Description />
				</div>
				<div className="flex">
					<ResumeMiddleware.UpgradeButton />
				</div>
			</ResumeMiddleware>
		);
	}

	return (
		<div
			className={cn(
				"flex flex-col gap-6",
				variant === "horizontal" && "md:flex-row",
				className
			)}
			{...rest}
		>
			{hasRegistration && (
				<RegistrationSection
					className={cn(
						"flex-1 text-background-foreground",
						sectionClassName
					)}
					title={t("registration")}
				>
					{answers.map((answer: any) => (
						<div
							className="flex flex-col gap-1"
							key={`${answer.question}`}
						>
							<strong>{answer.question}</strong>
							<span
								className={
									answer?.is_right !== undefined
										? answer.is_right
											? "text-green"
											: "text-red"
										: ""
								}
							>
								{answer.answer}
							</span>
						</div>
					))}

					{auth.type === "company" &&
						registration.deny_motivation && (
							<div className="flex flex-col gap-1 text-red">
								<strong>{t("reason-of-deny")}</strong>
								<ReadMore
									length={120}
									text={registration.deny_motivation}
								/>
							</div>
						)}

					<div className="flex">
						<small className="opacity-40">
							{moment(registration?.created_at).fromNow()}
						</small>
					</div>
				</RegistrationSection>
			)}

			{hasAboutMe && (
				<RegistrationSection
					className={cn(
						"flex-1 text-background-foreground",
						sectionClassName
					)}
					title={t("about-me")}
				>
					{tenant.modules.includes("vacancies") && (
						<>
							<div className="flex flex-col gap-1">
								<strong>{t("full_name")}</strong>
								<span>{user.full_name}</span>
							</div>
							<div className="flex flex-col gap-1">
								<strong>{t("address")}</strong>
								<span>
									{user?.address?.formatted_address ||
										t("not-provided")}
								</span>
							</div>
							<div className="flex flex-col gap-1">
								<strong>{t("city")}</strong>
								<span>
									{user?.address?.city || t("not-provided")}
								</span>
							</div>
						</>
					)}
					{user?.telephone ? (
						<div className="flex flex-col gap-1">
							<strong>{t("telephone")}</strong>
							<span>{user.telephone}</span>
						</div>
					) : (
						false
					)}

					{currentEducation && (
						<div className="flex flex-col gap-1">
							<strong>{t("current-education")}</strong>
							<span>
								{t("current-education-text", currentEducation)}
							</span>
						</div>
					)}
					{user?.bio && (
						<div className="flex flex-col gap-2">
							<strong>{t("bio")}</strong>
							<ReadMore text={user?.bio?.bio} length={200} />
						</div>
					)}
				</RegistrationSection>
			)}
		</div>
	);
};

RegistrationDetails.locale = {
	nl: {
		registration: "Aanmelding",
		"reason-of-deny": "Reden van afwijzing",
		telephone: "Telefoonnummer",
		"about-me": "Over mij",
		"current-education": "Huidige opleiding",
		"current-education-text": "{{title}} aan {{subtitle}}",
		bio: "Bio",
		skills: "Vaardigheden",
		full_name: "Naam",
		address: "Adres",
		city: "Woonplaats",
		"not-provided": "Niet opgegeven",
	},
	en: {
		registration: "Registration",
		"reason-of-deny": "Reason of deny",
		telephone: "Telephone",
		"about-me": "About me",
		"current-education": "Current education",
		"current-education-text": "{{title}} at {{subtitle}}",
		bio: "Bio",
		skills: "Skills",
		full_name: "Name",
		address: "Address",
		city: "City",
		"not-provided": "Not provided",
	},
};

export default withTranslation(RegistrationDetails);
