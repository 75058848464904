import { useEffect } from "react";
import { Navigate, useParams } from "react-router-dom";
import { ApiSubscriptionPlanInterval } from "src/api/types";
import withTranslation, {
	Translation,
} from "src/components/hoc/withTranslation";
import AuthLayout, {
	AuthLayoutContainer,
} from "src/components/layout/auth-layout/AuthLayout";
import Link from "src/components/Link";
import Loader from "src/components/Loader";
import SubscriptionPlanSelected from "src/components/subscription/SubscriptionPlanSelected";
import useSubscriptionCheckoutSession from "src/hooks/api/services/session/useSubscriptionCheckoutSession";
import useTenant from "src/hooks/api/services/tenants/useTenant";
import usePlans from "src/hooks/api/usePlans";
import useAuth from "src/hooks/selectors/useAuth";
import useQueryString from "src/hooks/useQueryString";

const SignupCompanySubscriptionCheckoutView = ({ t }: Translation) => {
	const params = useParams();
	const qs = useQueryString();
	const { tenant } = useTenant();
	const { auth } = useAuth();
	const interval = (
		qs.interval?.toString() || "monthly"
	).toUpperCase() as ApiSubscriptionPlanInterval;
	const {
		session,
		actions: sessionActions,
		status: sessionStatus,
	} = useSubscriptionCheckoutSession();
	const sessionFailed = qs.error === "mandate-failed";
	const { plans, status } = usePlans();
	const plan = plans.find(
		(item) => item.slug === params.slug && item.interval === interval
	);

	useEffect(() => {
		if (qs.session) {
			sessionActions.find(qs.session.toString());
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	if (!plan && status === "idle" && plans.length > 0) {
		return <Navigate to="/signup/company" />;
	}

	if (qs.session && sessionStatus !== "idle") {
		return (
			<AuthLayout variant="flat" actions={["contact-sales"]}>
				<AuthLayoutContainer className="flex flex-col flex-1 justify-center items-center">
					<Loader />
				</AuthLayoutContainer>
			</AuthLayout>
		);
	}

	return (
		<AuthLayout variant="flat" actions={["contact-sales"]}>
			<AuthLayoutContainer>
				{plan && (
					<SubscriptionPlanSelected
						{...{ plan, interval }}
						header={
							sessionFailed &&
							session?.id && (
								<div className="flex flex-col gap-2">
									<p>{t("session-failed")}</p>
								</div>
							)
						}
						sessionId={session?.id}
						className="md:rounded-md mx-auto w-full md:mt-8 mt-4 max-w-xl"
						showIntervalSwitch={false}
						showAnimations={false}
						layout="horizontal"
					/>
				)}
				<div className="flex flex-col gap-8 justify-center items-center mt-8">
					{tenant.slug === "getrecruitmentdone" && !auth?.id && (
						<div className="w-full max-w-md flex flex-col gap-6">
							<p
								dangerouslySetInnerHTML={{
									__html: t("how-it-works.title"),
								}}
							></p>
							<ol className="list-decimal flex flex-col gap-4 pl-6">
								{[
									"confirm",
									"access",
									"publish",
									"registrations",
									"support",
								].map((item) => (
									<li key={item}>
										<span
											dangerouslySetInnerHTML={{
												__html: t(
													`how-it-works.${item}`
												),
											}}
										></span>
									</li>
								))}
							</ol>
						</div>
					)}

					<Link className="opacity-50" to="/signup/company">
						{t("back")}
					</Link>
				</div>
			</AuthLayoutContainer>
		</AuthLayout>
	);
};

SignupCompanySubscriptionCheckoutView.locale = {
	nl: {
		back: "Terug naar abonnementen",
		"session-failed":
			"De koppeling met je bank is mislukt. Probeer het opnieuw.",
		"how-it-works": {
			title: "Na afronding van de betaling bij <strong>GetRecruitmentDone</strong> kun je het volgende verwachten:",
			confirm:
				"<strong>Bevestiging van betaling:</strong> Direct na de transactie ontvang je een bevestigingsmail met de details van je aankoop.",
			access: "<strong>Toegang tot je account:</strong> Je kunt onmiddellijk inloggen op je persoonlijke dashboard om je vacature(s) te beheren.",
			publish:
				"<strong>Publicatie van je vacature(s):</strong> Plaats je vacature(s) eenvoudig via het dashboard; deze worden zichtbaar voor ons netwerk van {{global.students}} young professionals.",
			registrations:
				"<strong>Ontvangst van kandidaten:</strong> Binnen de afgesproken termijn ontvang je reacties van gekwalificeerde kandidaten, inclusief hun unieke skills-profielen en matchscores.",
			support:
				"<strong>Ondersteuning:</strong> Ons team staat klaar om je te begeleiden en eventuele vragen te beantwoorden gedurende het hele proces.",
		},
	},
	en: {
		back: "Back to subscriptions",
		"session-failed":
			"The connection with your bank failed. Please try again.",
		"how-it-works": {
			title: "After completing the payment at <strong>GetRecruitmentDone</strong>, you can expect the following:",
			confirm:
				"<strong>Payment confirmation:</strong> Immediately after the transaction, you will receive an email with the details of your purchase.",
			access: "<strong>Access to your account:</strong> You can immediately log in to your personal dashboard to manage your vacancies.",
			publish:
				"<strong>Publication of your vacancies:</strong> Easily publish your vacancies via the dashboard; these will be visible to our network of {{global.students}} young professionals.",
			registrations:
				"<strong>Receipt of candidates:</strong> Within the agreed period, you will receive responses from qualified candidates, including their unique skills profiles and match scores.",
			support:
				"<strong>Support:</strong> Our team is ready to guide you and answer any questions throughout the process.",
		},
	},
};

export default withTranslation(SignupCompanySubscriptionCheckoutView);
