import { useState } from "react";
import AddressForm from "src/components/forms/AddressForm/v2/AddressForm";
import useEditProfile from "src/hooks/api/useEditProfile";

interface EditAddressSmallProps {
	onDone?: () => void;
	renderButtons: (options?: {
		isSubmitting?: boolean;
		isValid?: boolean;
		onSubmit?: () => void;
	}) => any;
}

const EditAddressSmall = ({ onDone, renderButtons }: EditAddressSmallProps) => {
	const [updateProfile, fetching] = useEditProfile();
	const [value, setValue] = useState<any>({
		zipcode: "",
		house_number: "",
		street: "",
		city: "",
	});
	const hasAddress =
		value.zipcode && value.house_number && value.street && value.city;

	const onSubmit = () => {
		updateProfile({
			address: value,
		}).then(() => {
			if (onDone) {
				onDone();
			}
		});
	};

	return (
		<div className="flex flex-col gap-4">
			<AddressForm address={value} onChange={setValue} />
			{renderButtons({
				isSubmitting: fetching,
				isValid: hasAddress,
				onSubmit,
			})}
		</div>
	);
};

export default EditAddressSmall;
