import { cva } from "class-variance-authority";
import { cn } from "src/lib/utils";

export type VariantTypes =
	| "primary"
	| "gray"
	| "white"
	| "transparent"
	| "dark"
	| "primary-outline"
	| "dark-outline"
	| "facebook"
	| "facebook-outline"
	| "green"
	| "red"
	| "ghost"
	| "orange"
	| "border"
	| "background"
	| "border-bg"
	| "accent"
	| "blue";

export default function useVariant(variant: VariantTypes = "primary") {
	const variants = cva("border-2 box-border", {
		variants: {
			variant: {
				primary: "bg-primary text-primary-foreground hover:opacity-80",
				border: "bg-transparent text-foreground border-border hover:bg-border hover:text-border-foreground",
				"border-bg":
					"bg-border text-border-foreground border border-border hover:bg-border hover:text-border-foreground",
				dark: "bg-dark text-dark-foreground dark:bg-background-foreground dark:text-background hover:opacity-80",
				gray: "bg-accent text-accent-foreground dark:bg-accent dark:text-accent-foreground hover:opacity-80",
				white: "bg-white text-dark hover:opacity-80",
				facebook: "bg-socials-facebook text-white hover:opacity-80",
				green: "bg-green text-white border-green hover:opacity-80",
				red: "bg-red text-white hover:opacity-80",
				ghost: "bg-transparent text-background-foreground hover:bg-accent opacity-40 hover:opacity-100",
				accent: "bg-accent text-accent-foreground hover:opacity-80",
				transparent:
					"bg-transparent text-background-foreground hover:bg-accent",
				outlined:
					"bg-transparent text-background-foreground border-background-foreground hover:bg-background-foreground hover:text-background",
				"dark-outline":
					"bg-transparent border-dark text-dark dark:border-background-foreground dark:text-background-foreground hover:bg-dark hover:text-white dark:hover:bg-background-foreground dark:hover:text-background",
				"primary-outline":
					"bg-transparent border-primary text-primary hover:bg-primary hover:text-primary-foreground",
				"facebook-outline":
					"bg-transparent border-socials-facebook text-socials-facebook hover:bg-socials-facebook hover:text-white",
				orange: "bg-gradient-to-r from-orange-dark to-orange-light text-white",
				background:
					"bg-background text-background-foreground hover:opacity-80",
				blue: "bg-blue text-white border-blue hover:opacity-80",
			},
		},
		defaultVariants: {
			variant: "dark",
		},
	});
	return cn("border-transparent transition-all", variants({ variant }));
}
