import { ApiAlert } from "src/api/types";
import Link from "src/components/Link";

interface AlertListDescriptionProps {
	alert: ApiAlert;
}

const AlertListDescription = ({ alert }: AlertListDescriptionProps) => {
	if (alert?.object?.url?.domain === "webapp") {
		return (
			<Link className="alert-link" to={alert.object.url.path}>
				<p dangerouslySetInnerHTML={{ __html: alert.description }}></p>
			</Link>
		);
	}

	return <p dangerouslySetInnerHTML={{ __html: alert.description }}></p>;
};

export default AlertListDescription;
