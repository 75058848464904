import {
	createContext,
	Fragment,
	useContext,
	useEffect,
	useId,
	useRef,
	useState,
} from "react";

const IsStickyContext = createContext<boolean>(false);

export function useIsSticky() {
	return useContext(IsStickyContext);
}

interface IsStickyProps {
	children: ({ isSticky }: { isSticky: boolean }) => JSX.Element;
}

const IsSticky = ({ children }: IsStickyProps) => {
	const ref = useRef<HTMLDivElement>(null);
	const [isSticky, setIsSticky] = useState(false);
	const uid = useId();

	useEffect(() => {
		const spy = ref.current;
		const observer = new IntersectionObserver(([entry]) => {
			setIsSticky(!entry.isIntersecting);
		});

		if (spy) {
			observer.observe(spy);
		}

		return () => (spy ? observer.unobserve(spy) : undefined);
	}, [children, ref]);

	return (
		<IsStickyContext.Provider value={isSticky}>
			<Fragment key={uid}>
				<div ref={ref} style={{ height: 0, width: 1 }} />
				{children({ isSticky })}
			</Fragment>
		</IsStickyContext.Provider>
	);
};

export default IsSticky;
