import { useState } from "react";
import { ApiTaskTableRow } from "src/api/types";
import Button from "src/components/Button";
import withTranslation, {
	Translation,
} from "src/components/hoc/withTranslation";
import usePermissions from "src/hooks/api/services/auth/usePermissions";
import useModules from "src/hooks/selectors/useModules";
import useAlert from "src/hooks/useAlert";

interface TaskRowButtonsProps extends Translation {
	task: ApiTaskTableRow;
	variant?: "sub-task";
	onDelete?: () => Promise<any>;
}

const TaskRowButtons = ({
	t,
	task,
	variant,
	onDelete,
}: TaskRowButtonsProps) => {
	const permissions = usePermissions();
	const isParentImport = task.links?.import;
	const [sendAlert] = useAlert();
	const { modules } = useModules();
	const [isDeleting, setIsDeleting] = useState(false);
	const handleDelete = async () => {
		if (!onDelete) return;
		sendAlert({
			type: "confirm",
			title: t("delete.title"),
			text: t("delete.description"),
			onConfirm: async () => {
				setIsDeleting(true);
				try {
					await onDelete();
					setIsDeleting(false);
				} catch (error) {
					setIsDeleting(false);
				}
			},
		});
	};

	if (["DRAFT", "INTERNAL"].includes(task.type)) {
		return (
			<>
				<Button
					iconOnly
					help={t("help.delete")}
					icon="fas fa-trash"
					type="gray"
					onClick={handleDelete}
					loading={isDeleting}
				/>
				{modules.includes("extern-task") && (
					<Button
						iconOnly
						help={t("help.concept")}
						icon="fas fa-pencil"
						type="gray"
						to={`/tasks/${task?.hid}/publish`}
					/>
				)}
			</>
		);
	}

	if (isParentImport) {
		return null;
	}

	return (
		<>
			{permissions.has("tasks.create") && variant !== "sub-task" && (
				<Button
					iconOnly
					help={t("help.repeat")}
					icon="fas fa-retweet"
					type="gray"
					to={`/tasks/create?repeat=${task?.hid}`}
				/>
			)}

			<Button
				iconOnly
				help={t("help.show")}
				icon="fas fa-eye"
				to={`/tasks/${task.hid}/show${
					task?.links?.registration && task.variant !== "HOUR_RATE"
						? `/${task?.links?.registration}`
						: ""
				}`}
			/>
		</>
	);
};

TaskRowButtons.locale = {
	nl: {
		help: {
			repeat: "Opdracht opnieuw uitbesteden",
			show: "Opdracht bekijken",
			concept: "Opdracht bewerken",
			delete: "Opdracht verwijderen",
		},
		delete: {
			title: "Opdracht verwijderen",
			description: "Weet je zeker dat je deze opdracht wilt verwijderen?",
		},
	},
	en: {
		help: {
			repeat: "Repeat task",
			show: "Show task",
			concept: "Edit task",
			delete: "Delete task",
		},
		delete: {
			title: "Delete task",
			description: "Are you sure you want to delete this task?",
		},
	},
};

export default withTranslation(TaskRowButtons);
