const auth = {
	"login-form": {
		error: {
			title: "Login Failed",
			text: "The email and password combination doesn't match, please check your details and try again.",
		},
		form: {
			email: "Email",
			password: "Password",
		},
		buttons: {
			forgot: "Forgot Password",
			login: "Login",
		},
	},
};

export default auth;
