import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import Avatar from "src/components/Avatar";
import Button from "src/components/Button";
import Link from "src/components/Link";
import withTranslation, {
	Translation,
} from "src/components/hoc/withTranslation";
import PaymentRequestStatus from "src/components/payment-request/elements/PaymentRequestStatus";
import PaymentRequestDateCell from "src/components/payment-request/visualizations/PaymentRequestDateCell";
import List from "src/components/ui/list";
import usePaymentRequestsCheckout from "src/hooks/api/services/payment-requests/usePaymentRequestsCheckout";
import useAuth from "src/hooks/selectors/useAuth";
import { formatPrice, roundToDecimalPlace } from "src/lib/formatters";
import { cn } from "src/lib/utils";

interface PaymentRequestCheckoutTableProps extends Translation {
	ids: string[];
	className?: string;
}

const PaymentRequestCheckoutTable = ({
	t,
	ids,
	className,
}: PaymentRequestCheckoutTableProps) => {
	const auth = useAuth();
	const { requests, status, actions, totals } = usePaymentRequestsCheckout({
		ids,
	});
	const location = useLocation();

	useEffect(() => {
		actions.list();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [location]);

	return (
		<List
			data={requests}
			{...{ status }}
			className={cn("relative flex-1", className)}
		>
			<List.Empty>
				<div className="flex flex-col text-center">
					<strong>{t("empty.title")}</strong>
					<p className="opacity-70 max-w-[460px] text-center">
						{t("empty.description")}
					</p>
					<div className="flex items-center justify-center gap-3 mt-4">
						<Button to="/payment-requests/process">
							{t("buttons.select-payments")}
						</Button>
					</div>
				</div>
			</List.Empty>
			<List.Head>
				<List.Item className="">{t("headers.status")}</List.Item>
				<List.Item className={cn()}>
					{t(
						`headers.${
							auth.type === "student" ? "company" : "student"
						}`
					)}
				</List.Item>
				<List.Item>{t("headers.description")}</List.Item>
				<List.Item>{t("headers.date")}</List.Item>
				<List.Item>{t("headers.break")}</List.Item>
				<List.Item>{t("headers.hours")}</List.Item>
				<List.Item>{t("headers.price")}</List.Item>
			</List.Head>
			<List.Loading count={12} />
			<List.Items>
				{(row) => (
					<List.Row id={row.id}>
						<List.Item>
							<PaymentRequestStatus status={row.status} />
						</List.Item>
						<List.Item>
							<div className="flex gap-3 items-center">
								<Avatar src={row?.receiver?.avatar} />
								<div className="flex flex-col flex-1">
									{row?.receiver?.full_name}
								</div>
							</div>
						</List.Item>
						<List.Item>
							<Link
								to={{
									modal: `/payment-requests/${row.id}/edit`,
								}}
								className="max-w-[360px]"
							>
								{row.description}
							</Link>
						</List.Item>
						<List.Item>
							<PaymentRequestDateCell request={row} />
						</List.Item>
						<List.Item>
							<p className="whitespace-nowrap">
								{row.break
									? t("hour", {
											hours: row.break,
									  })
									: "-"}
							</p>
						</List.Item>
						<List.Item>{row.hours || "-"}</List.Item>
						<List.Item>
							{row.price ? formatPrice(row.price) : "-"}
						</List.Item>
					</List.Row>
				)}
			</List.Items>
			{totals?.hours > 0 && totals?.price > 0 && (
				<List.Footer>
					<List.Item />
					<List.Item />
					<List.Item />
					<List.Item />
					<List.Item />
					<List.Item>
						{roundToDecimalPlace(totals.hours, 2)}
					</List.Item>
					<List.Item>{formatPrice(totals.price)}</List.Item>
				</List.Footer>
			)}
		</List>
	);
};

PaymentRequestCheckoutTable.locale = {
	nl: {
		selected: "{{count}} geselecteerd",
		headers: {
			status: "Status",
			company: "Opdrachtgever",
			student: "Opdrachtnemer",
			description: "Omschrijving",
			hours: "Uren",
			break: "Pauze",
			date: "Datum",
			price: "Waarde",
		},
		hour: "{{hours}} uur",
		buttons: {
			"create-payment": "Betaling maken",
			"select-payments": "Selecteer verzoeken",
		},
		empty: {
			title: "Geen verzoeken geselecteerd",
			description:
				"Selecteer eerst een aantal verzoeken om een betaling aan te maken.",
		},
	},
	en: {
		selected: "{{count}} selected",
		headers: {
			status: "Status",
			company: "Client",
			student: "Contractor",
			description: "Description",
			hours: "Hours",
			break: "Break",
			date: "Date",
			price: "Value",
		},
		hour: "{{hours}} hour",
		buttons: {
			"create-payment": "Make Payment",
			"select-payments": "Select Requests",
		},
		empty: {
			title: "No requests selected",
			description: "Please select some requests to create a payment.",
		},
	},
};

export default withTranslation(PaymentRequestCheckoutTable);
