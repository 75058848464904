const lists = {
	headers: {
		status: "Status",
		task: "Task",
		"created-at": "Created At",
		actions: "Actions",
		"invoice-number": "Invoice Number",
		method: "Method",
		type: "Type",
		price: "Amount",
		description: "Description",
		company: "Company",
		name: "Name",
		"estimated-date": "Estimated date",
	},
	"envelope-list": {
		"not-found": "Not found",
		"empty-open": {
			title: "No agreements are open",
			text: "All agreements are signed",
		},
		"empty-signed": {
			title: "No agreements found",
			text: "You have not yet signed any agreements",
		},
		empty: {
			title: "You have no agreements",
			text: "Agreements are created when there is an agreement for a task",
		},
	},
	"payment-list": {
		total_one: "1 payment found",
		total_other: "{{count}} payments found",
		empty: {
			title: "No payments found",
		},
		task: "Task: ",
	},
	"payment-users-list": {
		total_one: "1 professional found",
		total_other: "{{count}} professionals found",
		empty: {
			title: "No professionals found",
		},
	},
	"registration-list": {
		empty: {
			title: "No applications found",
		},
	},
	"registration-select-list": {
		search: "Search by first name or last name",
		empty: {
			title: "No applications found",
		},
	},
	"todo-list": {
		agreements: {
			title: "Agreements",
			text_one: "1 pending agreement",
			text_zero: "No pending agreements",
			text_other: "{{count}} pending agreements",
		},
		payments: {
			title: "Payments",
			text_one: "1 pending payment",
			text_zero: "No pending payments",
			text_other: "{{count}} pending payments",
		},
		tasks: {
			title: "Tasks",
			text_one: "1 task without match",
			text_zero: "No tasks without match",
			text_other: "{{count}} tasks without match",
		},
		messages: {
			title: "Messages",
			text_one: "1 unread message",
			text_zero: "All messages read",
			text_other: "{{count}} unread messages",
		},
	},
};

export default lists;
