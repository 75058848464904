import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Alert from "src/components/Alert";
import Avatar from "src/components/Avatar";
import CompanyUserForm from "src/components/forms/auth/signup/company/CompanyUserForm";
import withTranslation, {
	Translation,
} from "src/components/hoc/withTranslation";
import AuthLayout from "src/components/layout/auth-layout/AuthLayout";
import useCompanyForm from "src/hooks/api/services/auth/useCompanyForm";
import useProspect from "src/hooks/api/services/auth/useProspect";
import useSignup from "src/hooks/api/services/auth/useSignup";

function SignupCompanyUserView({ t }: Translation) {
	const { slug } = useParams() as any;
	const navigate = useNavigate();
	const { prospect, actions, status } = useProspect();
	const { form: company, validate: companyValidated } = useCompanyForm();
	const {
		actions: signupActions,
		status: signupStatus,
		validate,
	} = useSignup({
		type: "company",
	});
	useEffect(() => {
		if (!companyValidated.isValid) {
			navigate(`/signup/company/${slug}`);
		}
		if (slug && !prospect?.id) {
			actions.get(slug).catch(() => {
				navigate("/signup/company");
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const onSubmit = (user: any) => {
		if (!prospect.id) {
			navigate(`/signup/company`);
			return;
		}
		if (!companyValidated.isValid) {
			navigate(`/signup/company/${slug}/information`);
			return;
		}
		if (signupStatus === "loading") {
			return;
		}
		signupActions
			.signup({
				prospect_id: prospect?.id,
				company,
			})
			.catch((error) => {
				console.log(error);
			});
	};

	return (
		<AuthLayout
			head={{
				title: t("title"),
				back: {
					to: `/signup/company/${slug}/information`,
					label: t("back"),
				},
			}}
			loading={status === "loading"}
		>
			<div className="flex flex-col gap-4">
				<div className="flex gap-4 items-end">
					{prospect.icon && (
						<Avatar
							size="medium"
							variant="white"
							src={prospect?.icon}
						/>
					)}
					<div className="flex flex-col">
						<strong>{prospect?.company_name}</strong>
						{(prospect?.website || prospect?.kvk) && (
							<span>
								{prospect?.kvk
									? t("kvk", {
											kvk: prospect.kvk,
									  })
									: prospect.website}
							</span>
						)}
					</div>
				</div>
				<div className="my-4">
					<CompanyUserForm
						value={
							prospect?.email
								? { email: prospect.email }
								: undefined
						}
						{...{ onSubmit }}
					/>
					{validate?.errors?.kvk && (
						<div className="mt-4">
							<Alert type="danger">{validate.errors.kvk}</Alert>
						</div>
					)}
				</div>
			</div>
		</AuthLayout>
	);
}

SignupCompanyUserView.locale = {
	nl: {
		title: "Aanmelden als bedrijf",
		back: "Terug naar aanmelden",
		kvk: "kvk: {{kvk}}",
		"manual-disclaimer":
			"Staat je bedrijf niet tussen de zoekresultaten? Gebruik de ondertaande knop om je bedrijfsgegevens handmatig in te vullen",
		buttons: {
			manual: "Handmatig invoeren",
			continue: "Doorgaan",
		},
	},
	en: {
		title: "I want to outsource tasks",
		back: "Back to signup",
		kvk: "chamber of commerce: {{kvk}}",
		"manual-disclaimer":
			"Are you not able to find your company, no worries. Use the button below to add your details manual",
		buttons: {
			manual: "Manual",
			continue: "Continue",
		},
	},
};
export default withTranslation(SignupCompanyUserView);
