import { useLocation, useNavigate } from "react-router-dom";

function useNavigateModal() {
	const location = useLocation();
	const navigateAction = useNavigate();

	const navigate = (path: string) => {
		let pathname = location.pathname.replace(/\/$/, "");
		if (path.startsWith("./")) {
			path = path.replace("./", "/");
			if (pathname?.includes("/modals/")) {
				pathname = pathname.split("/modals/")[0];
			}

			path = `${pathname}${path}`;
		}
		navigateAction(path, {
			state: {
				background: location?.state?.background || location,
			},
		});
	};

	return navigate;
}

export default useNavigateModal;
