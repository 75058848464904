import { motion } from "framer-motion";
import React, {
	ComponentProps,
	createContext,
	useContext,
	useEffect,
	useState,
} from "react";
import useElementSize from "src/hooks/useElementSize";
import useWindowSize from "src/hooks/useWindowSize";
import { cn } from "src/lib/utils";

type SidebarContextProps = {
	open: boolean;
	onOpenChange: (value: boolean) => void;
	direction?: "left" | "right";
};

export const SidebarContext = createContext<SidebarContextProps>({
	open: true,
	direction: "left",
} as SidebarContextProps);

export function useSidebar() {
	const sidebar = useContext(SidebarContext);
	return sidebar;
}

interface SidebarProps extends ComponentProps<"div"> {
	children?: any;
	open: boolean;
	direction?: "left" | "right";
	onOpenChange?: (value: boolean) => void;
}

const Sidebar = ({
	children,
	className,
	open: _open,
	onOpenChange,
	direction = "left",
	...rest
}: SidebarProps) => {
	const element = useElementSize();
	const { isPhone } = useWindowSize();
	const [open, setOpen] = useState(_open);

	useEffect(() => {
		setOpen(_open);
	}, [_open]);

	return (
		<SidebarContext.Provider
			value={{
				direction,
				open,
				onOpenChange: (value) => {
					setOpen(value);
					if (onOpenChange) {
						onOpenChange(value);
					}
				},
			}}
		>
			<motion.div
				{...(rest as any)}
				className={cn(
					"fixed right-0 top-20 bottom-4 sm:top-0 pointer-events-none sm:pointer-events-auto rounded-l-md sm:relative sm:bg-card dark:sm:bg-background p-4 sm:rounded-md transition-all",
					open &&
						"bg-card dark:bg-accent shadow pointer-events-auto overflow-auto hide-default-scrollbar sm:overflow-hidden",
					className
				)}
				animate={open ? "open" : "hidden"}
			>
				{!open && <div className="w-8"></div>}
				<motion.div className="mb-4 mt-0 sticky top-0 z-[44] sm:relative">
					<SidebarTrigger />
				</motion.div>
				<motion.div
					variants={{
						open: {
							width: isPhone
								? "calc(100vw - 32px - 32px)"
								: "max-content",
							opacity: 1,
						},
						hidden: {
							width: 0,
							opacity: 0,
						},
					}}
					className="overflow-hidden"
				>
					<div
						ref={element.ref}
						style={{
							minWidth: element.size.width,
						}}
						className="flex flex-col sm:max-w-[400px]"
					>
						{children}
					</div>
				</motion.div>
			</motion.div>
		</SidebarContext.Provider>
	);
};

export const SidebarTrigger = () => {
	const { onOpenChange, open, direction } = useSidebar();
	return (
		<div
			className={cn(
				"bg-background border border-border w-8 h-8 rounded-md flex justify-center items-center cursor-pointer pointer-events-auto"
			)}
			onClick={() => onOpenChange(!open)}
		>
			<motion.div
				variants={{
					open: {
						rotate: 0,
					},
					hidden: {
						rotate: 180,
					},
				}}
			>
				<i
					className={`far ${
						direction === "left"
							? "fa-angle-right"
							: "fa-angle-left"
					}`}
				></i>
			</motion.div>
		</div>
	);
};

export default Sidebar;
