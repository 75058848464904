import useApi from "src/hooks/api/utils/useApi";

function useWorstPerformingUSers() {
	const { state, actions, api, pagination } = useApi(
		{ id: "USER_WORST_PERFORMING" },
		{
			baseUrl: `/client/statistics/users/performing`,
			query: {
				sort: "worst",
			},
		}
	);
	const users = state?.list || [];

	return {
		users,
		status:
			users.length && state.status === "loading" ? "idle" : state.status,
		pagination,
		filter: state?.filter || {},
		actions: {
			...actions,
		},
		api,
	};
}

export default useWorstPerformingUSers;
