import Dropdown, { DropdownProps, Option } from "src/components/form/Dropdown";

interface EducationLevelDropdownProps extends Omit<DropdownProps, "options"> {
	options: Option[];
}

const EducationLevelDropdown = ({
	options,
	...rest
}: EducationLevelDropdownProps) => {
	return (
		<Dropdown
			// isSearch
			noMarginBottom
			options={options}
			className={`${rest?.className || ""} mb-0`}
			{...{ ...rest }}
		/>
	);
};

EducationLevelDropdown.defaultProps = {
	layout: "stacked",
};

export default EducationLevelDropdown;
