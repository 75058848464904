import CustomFormPreviewField from "src/components/custom-form/CustomFormPreviewField";
import useUserCardContext from "../UserCardContext";

function UserCardCustomForm() {
	const { user } = useUserCardContext();
	if (!user.values) return null;
	return (
		<div className="flex flex-col gap-1 my-2">
			{Object.values(user.values).map((field: any, index: number) => (
				<div
					key={`user-card-custom-form-${index}`}
					className="grid grid-cols-[170px_1fr] gap-2"
				>
					<span className="opacity-70">{field.label}:</span>
					<CustomFormPreviewField {...{ field }} />
				</div>
			))}
		</div>
	);
}

export default UserCardCustomForm;
