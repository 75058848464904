import { useNavigate, useLocation } from "react-router-dom";
import Modal from "src/components/Modal";
import withTranslation, {
	Translation,
} from "src/components/hoc/withTranslation";
import useTaskForm from "src/hooks/api/tasks/useTaskForm";
import useQueryString from "src/hooks/useQueryString";
import TaskFormTitle from "src/components/tasks/TaskForm/modals/TaskFormTitle";

interface TaskFormTitleModalProps extends Translation {}

const TaskFormTitleModal = ({ t }: TaskFormTitleModalProps) => {
	const qs = useQueryString();
	const navigate = useNavigate();
	const location = useLocation();
	const visible = qs?.modal === "create-extern-task";
	const { actions } = useTaskForm();

	const onClose = () => {
		actions.clear();
		const root = location.pathname;
		const sp = new URLSearchParams(location.search);
		sp.delete("modal");
		navigate(`${root}${sp ? `?${sp.toString()}` : ""}`);
	};

	return (
		<Modal size={"default"} {...{ onClose, visible }} title={t("title")}>
			{visible && <TaskFormTitle />}
		</Modal>
	);
};

TaskFormTitleModal.locale = {
	nl: {
		title: "Opdracht aanmaken",
		fields: {
			title: {
				label: "Opdracht",
				placeholder: "Bijvoorbeeld: Social media beheren",
			},
		},
		buttons: {
			continue: "Doorgaan",
		},
	},
	en: {
		title: "Create task",
		fields: {
			title: {
				label: "Task",
				placeholder: "Example: Social media management",
			},
		},
		buttons: {
			continue: "Continue",
		},
	},
};

export default withTranslation(TaskFormTitleModal);
