import React from "react";
import { useTranslation } from "react-i18next";
import { ApiProfileMinimal } from "src/api/types";
import Avatar from "src/components/Avatar";
import Card from "src/components/Card";
import Stars from "src/components/Stars";

interface StudentBannerProps {
	profile: ApiProfileMinimal;
}

const StudentBanner = ({ profile }: StudentBannerProps) => {
	const { t } = useTranslation("tasks", {
		keyPrefix: "student-banner",
	});
	return (
		<Card className=" " contentClassName="flex flex-col p-6">
			<strong>{t("title")}</strong>
			<div className="flex mt-4 gap-2 items-end">
				<Avatar
					src={profile.avatar}
					alt={profile.full_name}
					size="regular"
				/>
				<div className="flex flex-col">
					<strong>{profile?.full_name}</strong>
					<Stars rating={profile?.reviews?.rating} />
				</div>
			</div>
		</Card>
	);
};

export default StudentBanner;
