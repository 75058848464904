import { ApiReview } from "src/api/types";
import useApi from "src/hooks/api/utils/useApi";
import useAuth from "src/hooks/selectors/useAuth";

type Options = {
	filter?: any;
	id?: string;
};

function useReviews(options?: Options) {
	const auth = useAuth();
	const id = options?.id ? options?.id : auth.id;
	const { state, actions, api, pagination } = useApi(
		{ id: `REVIEWS:${id}` },
		{
			baseUrl: `/resume/${id}/reviews`,
			query: {
				state: "all",
				...(options?.filter || {}),
			},
		}
	);

	const reviews: ApiReview[] = state?.list || [];

	return {
		reviews,
		review: state?.data,
		status: state.status,
		actions: {
			...actions,
		},
		pagination,
		api,
	};
}

export default useReviews;
