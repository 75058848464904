import { atom } from "jotai";
import { useReducerAtom } from "jotai/utils";
import { UniqueKey, apiAtomFamily } from "src/hooks/api/utils/useApi";
import { store } from "src/lib/jotai";

const atomIds = atom<{
	[key: string]: UniqueKey;
}>({});

const idsReducer = (state: any, action: any) => {
	if (action.type === "add") {
		return {
			...state,
			[action.payload.id]: action.payload,
		};
	}
	throw state;
};

export default function useAtomIds() {
	const [ids, dispatch] = useReducerAtom(atomIds, idsReducer);
	// const [ids, set] = useAtom(atomIds);

	const add = (key: UniqueKey) => {
		if (!ids[key.id]) {
			dispatch({
				type: "add",
				payload: key,
			});
		}
	};

	const read = (id: string) => {
		return store.get(apiAtomFamily({ id }));
	};

	const getAtom = (id: string) => {
		return apiAtomFamily({ id });
	};

	const getAtomsByGroup = (group: string) => {
		const ids = Object.values(store.get(atomIds))
			.filter((item) => item.group === group)
			.map((item) => item.id);

		return ids.map((id) => getAtom(id));
	};

	return {
		ids: Object.values(ids),
		add,
		read,
		atom: getAtom,
		getAtomsByGroup,
	};
}
