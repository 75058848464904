import { useEffect } from "react";
import useQueryString from "src/hooks/useQueryString";
import axios from "src/lib/api";

function useNotificationAnalytics() {
	const qs = useQueryString();

	useEffect(() => {
		if (qs.t) {
			axios
				.post(`/analytics/${qs.t}/track`, {
					status: "OPENED",
				})
				.then(({ data }) => {});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
}

export default useNotificationAnalytics;
