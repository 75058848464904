import Loader from "src/components/Loader";
import withTranslation, {
	Translation,
} from "src/components/hoc/withTranslation";

interface AddButtonProps extends Translation {
	onClick: () => void;
	loading?: boolean;
}

const AddButton = ({ t, onClick, loading }: AddButtonProps) => {
	return (
		<button
			onClick={(e) => {
				e.preventDefault();
				if (!loading) {
					onClick();
				}
			}}
			className="w-max flex items-center gap-1.5 px-2 py-2 rounded-md hover:bg-dark-100 transition-all"
		>
			<div className="flex justify-center items-center">
				{loading ? (
					<Loader />
				) : (
					<i className="far text-[13px] fa-plus"></i>
				)}
			</div>
			<span className="font-regular text-[15px] leading-full">
				{t("label")}
			</span>
		</button>
	);
};

AddButton.locale = {
	nl: {
		label: "Nieuw",
	},
	en: {
		label: "Add new",
	},
};

export default withTranslation(AddButton);
