import moment from "moment";
import useTaskForm from "src/hooks/api/tasks/useTaskForm";
import { getHoursBetweenDates } from "src/lib/dates";
import uid from "src/lib/uid";
import { SubtaskForm } from "src/redux/taskForm";

function useSubtaskForm(id?: string | number) {
	const { task, actions: taskActions } = useTaskForm();
	const subtasks: SubtaskForm[] = task?.subtasks || [];
	const createEmpty = () => {
		const _id = uid();
		taskActions.set({
			subtasks: [...subtasks, { _id } as SubtaskForm],
		});
	};

	const isValid = subtasks.every(
		(item) =>
			item.title &&
			(item.budget || item?.budget_per_hour) &&
			item.start_date &&
			item.end_date &&
			item?.estimated_duration &&
			item?.estimated_duration > 0
	);

	const remove = (id: string | number) => {
		taskActions.set({
			subtasks: subtasks.filter((task) => task._id !== id),
		});
	};

	const getEstimatedDuration = (data: any) => {
		if (!data?.start_date && !data.end_date) {
			return null;
		}
		return getHoursBetweenDates(data.start_date, data.end_date);
	};

	const set = (_task: any) => {
		taskActions.set({
			subtasks: subtasks.map((item) => {
				if (item._id === id || item.id === id) {
					const newTask = {
						...item,
						..._task,
					};

					if (newTask.start_date) {
						newTask.start_date = moment(newTask.start_date).format(
							"YYYY-MM-DD HH:mm:ss"
						);
					}

					if (newTask.end_date) {
						newTask.end_date = moment(newTask.end_date).format(
							"YYYY-MM-DD HH:mm:ss"
						);
					}

					const estimated_duration = getEstimatedDuration(newTask);
					newTask.estimated_duration = estimated_duration;
					newTask.budget = newTask.budget_per_hour
						? newTask.budget_per_hour *
						  (newTask.estimated_duration || 0)
						: newTask.budget;
					return newTask;
				}
				return item;
			}),
		});
	};

	const duplicate = (_task: any) => {
		const _id = uid();
		let copy = {
			..._task,
			_id,
		};

		if (copy.start_date) {
			copy.start_date = moment(copy.start_date).add(1, "day").toDate();
		}
		if (copy.end_date) {
			copy.end_date = moment(copy.end_date).add(1, "day").toDate();
		}

		taskActions.set({
			subtasks: [...subtasks, copy as SubtaskForm],
		});
	};

	return {
		subtasks,
		isValid,
		actions: {
			createEmpty,
			remove,
			set,
			duplicate,
		},
	};
}

export default useSubtaskForm;
