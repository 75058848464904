import { ChevronsUpDown } from "lucide-react";
import { ComponentProps, ReactNode } from "react";
import { ApiListTenant } from "src/api/types";
import { useT } from "src/components/hoc/withTranslation";
import {
	DropdownMenu,
	DropdownMenuContent,
	DropdownMenuItem,
	DropdownMenuTrigger,
} from "src/components/ui/dropdown-menu";
import useTenant from "src/hooks/api/services/tenants/useTenant";
import useTenants from "src/hooks/api/services/tenants/useTenants";
import useWindowSize from "src/hooks/useWindowSize";
import { cn } from "src/lib/utils";

export default function TenantSwitcher(
	props: ComponentProps<typeof DropdownMenu>
) {
	return <DropdownMenu {...props} />;
}

interface TenantSwitcherTriggerProps {
	children: (tenant: ApiListTenant) => ReactNode;
}

export function TenantSwitcherTrigger({
	children,
}: TenantSwitcherTriggerProps) {
	const { tenant } = useTenant();
	return (
		<DropdownMenuTrigger asChild>
			<button className="w-full text-left">{children(tenant)}</button>
		</DropdownMenuTrigger>
	);
}

export function TenantSwitcherTriggerIcon({
	className,
	...rest
}: ComponentProps<typeof ChevronsUpDown>) {
	return (
		<ChevronsUpDown
			className={cn("text-border hover:text-foreground", className)}
			{...rest}
		/>
	);
}

export function TenantSwitcherTriggerDescription({
	className,
	...rest
}: ComponentProps<"span">) {
	const t = useT({
		nl: {
			all: "Bekijk alle platformen",
		},
		en: {
			all: "View all platforms",
		},
	});
	return (
		<span
			className={cn("opacity-60 truncate select-none", className)}
			{...rest}
		>
			{t("all")}
		</span>
	);
}

interface TenantSwitcherOptionsProps
	extends Omit<ComponentProps<typeof DropdownMenuContent>, "children"> {
	children: (tenant: ApiListTenant) => ReactNode;
}

export function TenantSwitcherOptions({
	children,
	className,
	...rest
}: TenantSwitcherOptionsProps) {
	const { tenants } = useTenants();
	const { tenant } = useTenant();
	const { isPhone } = useWindowSize();
	const t = useT({
		nl: {
			title: "Onze platformen",
			description: "Klik op een platform om in te loggen",
		},
		en: {
			title: "Our platforms",
			description: "Click on a platform to log in",
		},
	});
	return (
		<DropdownMenuContent
			align="start"
			sideOffset={4}
			{...rest}
			className={cn(
				"w-[--radix-dropdown-menu-trigger-width] min-w-56 rounded-lg",
				className
			)}
			side={isPhone ? "bottom" : "right"}
		>
			<div className="flex flex-col text-[15px] p-3">
				<span className="strong">{t("title")}</span>
				<span className="opacity-70">{t("description")}</span>
			</div>
			{tenants.filter((item) => item.slug !== tenant.slug).map(children)}
		</DropdownMenuContent>
	);
}

export function TenantSwitcherOption({
	className,
	...rest
}: ComponentProps<typeof DropdownMenuItem>) {
	return (
		<DropdownMenuItem
			{...rest}
			className={cn("gap-2 px-3 py-2", className)}
		/>
	);
}
