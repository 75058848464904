import { Route } from "react-router-dom";
import TaskModal from "src/components/tasks/TaskForm/modals/task-modal/TaskModal";
import EditSubTasksView from "src/components/tasks/edit/EditSubTasksView";
import ProtectedRoute from "src/routes/middlewares/ProtectedRoute";
import DashboardTasksCompleted from "src/views/authenticated/tasks/TasksCompletedView";
import TasksFilterView from "src/views/authenticated/tasks/TasksFilterView";
import TasksRepeatView from "src/views/authenticated/tasks/TasksRepeatView";
import DashboardTasks from "src/views/authenticated/tasks/TasksView";
import CreateBoardView from "src/views/authenticated/tasks/boards/CreateBoardView";
import EditBoardView from "src/views/authenticated/tasks/boards/EditBoardView";
import CreateTaskModal from "src/views/authenticated/tasks/create/CreateTaskModal";
import DashboardCreateTaskView from "src/views/authenticated/tasks/create/CreateTaskView";
import EditTaskView from "src/views/authenticated/tasks/create/EditTaskView";
import TasksDetailView from "src/views/authenticated/tasks/detail";
import OverviewCompletedTasks from "src/views/authenticated/tasks/detail/OverviewCompletedTasks";
import TaskAddRegistrationModal from "src/views/authenticated/tasks/detail/add-registration/TaskAddRegistrationModal";
import TaskCancelRegistrationModal from "src/views/authenticated/tasks/detail/cancel-registration/TaskCancelRegistrationModal";
import MassMessageTaskModal from "src/views/authenticated/tasks/detail/mass-message/MassMessageTaskModal";
import TaskPublishView from "src/views/authenticated/tasks/detail/publish/TaskPublishView";
import ShareTaskModal from "src/views/authenticated/tasks/detail/share/ShareTaskModal";
import ImportTaskModal from "src/views/authenticated/tasks/import/ImportTaskModal";
import RegistrationsView from "src/views/authenticated/tasks/registrations/RegistationsView";

const TasksRoutes = [
	<Route
		element={
			<ProtectedRoute>
				<DashboardTasks />
			</ProtectedRoute>
		}
		path="/tasks"
		key="/tasks"
	/>,
	<Route
		element={
			<ProtectedRoute guard={["intern-task"]}>
				<TasksRepeatView />
			</ProtectedRoute>
		}
		path="/tasks/repeat"
		key="/tasks/repeat"
	/>,

	<Route
		element={
			<ProtectedRoute type="company">
				<DashboardTasks />
			</ProtectedRoute>
		}
		path="/tasks/teams/:team"
		key="/tasks/teams/:team"
	/>,

	<Route
		element={
			<ProtectedRoute guard={["intern-task"]}>
				<CreateBoardView />
			</ProtectedRoute>
		}
		path="/tasks/boards/create"
		key="/tasks/boards/create"
	/>,
	<Route
		element={
			<ProtectedRoute guard={["intern-task"]}>
				<EditBoardView />
			</ProtectedRoute>
		}
		path="/tasks/boards/:slug/edit"
		key="/tasks/boards/:slug/edit"
	/>,
	<Route
		element={
			<ProtectedRoute guard={["intern-task"]}>
				<DashboardTasks />
			</ProtectedRoute>
		}
		path="/tasks/boards/:board"
		key="/tasks/boards/:board"
	/>,
	<Route
		element={
			<ProtectedRoute permission={"tasks.create"}>
				<DashboardCreateTaskView />
			</ProtectedRoute>
		}
		path="/tasks/create"
		key="/tasks/create"
	/>,
	<Route
		element={
			<ProtectedRoute permission={"tasks.create"}>
				<DashboardTasksCompleted />
			</ProtectedRoute>
		}
		path="/tasks/create/completed/thanks"
		key="/tasks/create/completed/thanks"
	/>,
	<Route
		element={
			<ProtectedRoute type="company">
				<EditSubTasksView />
			</ProtectedRoute>
		}
		path="/tasks/:id/subtasks/edit"
		key="/tasks/:id/subtasks/edit"
	/>,
	<Route
		element={
			<ProtectedRoute type="company">
				<TaskPublishView />
			</ProtectedRoute>
		}
		path="/tasks/:id/publish"
		key="/tasks/:id/publish"
	/>,
	<Route
		element={
			<ProtectedRoute type="company">
				<EditTaskView />
			</ProtectedRoute>
		}
		path="/tasks/:id/edit"
		key="/tasks/:id/edit"
	/>,
	<Route
		element={
			<ProtectedRoute>
				<TasksDetailView />
			</ProtectedRoute>
		}
		path="/tasks/:id/:pane/:registration?/:extra?"
		key="/tasks/:id/:pane/:registration?/:extra?"
	/>,
	<Route
		element={
			<ProtectedRoute>
				<TasksDetailView />
			</ProtectedRoute>
		}
		path="/tasks/boards/:board/:id/:pane/:registration?/:extra?"
		key="/tasks/boards/:board/:id/:pane/:registration?/:extra?"
	/>,
	<Route
		element={
			<ProtectedRoute guard={["intern-task"]}>
				<OverviewCompletedTasks />
			</ProtectedRoute>
		}
		path="/tasks/boards/:board/overview"
		key="/tasks/boards/:board/overview"
	/>,
	<Route
		element={
			<ProtectedRoute guard={["extern-task"]}>
				<RegistrationsView />
			</ProtectedRoute>
		}
		path="/tasks/registrations"
		key="/tasks/registrations"
	/>,
];

export const TasksModalRoutes = [
	<Route
		element={
			<ProtectedRoute
				variant="modal"
				modal={{
					backUrl: "/tasks",
					expanded: false,
					size: "default",
					className: "max-w-[520px]",
				}}
			>
				<TasksFilterView />
			</ProtectedRoute>
		}
		path="/tasks/filter"
		key="/tasks/filter"
	/>,

	<Route
		element={
			<ProtectedRoute
				variant="modal"
				modal={{
					backUrl: "/tasks",
					expanded: false,
					size: "default",
				}}
			>
				<TasksFilterView />
			</ProtectedRoute>
		}
		path="/tasks/teams/:team/filter"
		key="/tasks/teams/:team/filter"
	/>,
	<Route
		element={
			<ProtectedRoute
				variant="modal"
				modal={{
					backUrl: "/tasks/boards/:board",
					expanded: false,
					size: "default",
					className: "max-w-[520px]",
				}}
			>
				<TasksFilterView />
			</ProtectedRoute>
		}
		path="/tasks/boards/:board/filter"
		key="/tasks/boards/:board/filter"
	/>,
	<Route
		element={
			<ProtectedRoute
				variant="modal"
				modal={{
					backUrl: "/tasks",
					expanded: false,
					size: "large",
				}}
			>
				<CreateTaskModal />
			</ProtectedRoute>
		}
		path="/tasks/:id/create"
		key="/tasks/:id/create"
	/>,
	<Route
		element={
			<ProtectedRoute variant="on-top">
				<TaskModal defaultVisible backUrl="/tasks/repeat" />
			</ProtectedRoute>
		}
		path="/tasks/repeat/:id/show"
		key="/tasks/repeat/:id/show"
	/>,
	<Route
		element={
			<ProtectedRoute variant="on-top">
				<TaskModal defaultVisible backUrl="/tasks" />
			</ProtectedRoute>
		}
		path="/tasks/:id/show"
		key="/tasks/:id/show"
	/>,
	<Route
		element={
			<ProtectedRoute guard={["intern-task"]} variant="on-top">
				<TaskModal defaultVisible backUrl="/tasks/boards/:board" />
			</ProtectedRoute>
		}
		path="/tasks/boards/:board/:id/show"
		key="/tasks/boards/:board/:id/show"
	/>,
	<Route
		element={
			<ProtectedRoute variant="on-top" guard={["extern-task"]}>
				<ShareTaskModal />
			</ProtectedRoute>
		}
		path="/tasks/:id/:pane/share"
		key="/tasks/:id/:pane/share"
	/>,
	<Route
		element={
			<ProtectedRoute
				variant="on-top"
				type="company"
				guard={["extern-task"]}
			>
				<MassMessageTaskModal />
			</ProtectedRoute>
		}
		path="/tasks/:id/:pane/mass-message"
		key="/tasks/:id/:pane/mass-message"
	/>,
	<Route
		element={
			<ProtectedRoute
				variant="modal"
				type="company"
				guard={["extern-task"]}
				modal={{
					backUrl: "/tasks/:id/:pane",
					size: "large",
				}}
			>
				<TaskAddRegistrationModal />
			</ProtectedRoute>
		}
		path="/tasks/:id/:pane/add-registration"
		key="/tasks/:id/:pane/add-registration"
	/>,
	<Route
		element={
			<ProtectedRoute
				variant="modal"
				type="company"
				guard={["extern-task"]}
				modal={{
					backUrl: "/tasks/:id/show",
					expanded: false,
					size: "default",
					head: "floating",
					className: "max-w-[720px]",
				}}
			>
				<TaskCancelRegistrationModal />
			</ProtectedRoute>
		}
		path="/tasks/:id/show/:registration/cancel"
		key="/tasks/:id/show/:registration/cancel"
	/>,
	<Route
		element={
			<ProtectedRoute
				variant="modal"
				modal={{
					backUrl: "/tasks",
					expanded: false,
					size: "default",
					head: "floating",
					className: "max-w-[520px]",
				}}
			>
				<ImportTaskModal />
			</ProtectedRoute>
		}
		path="/tasks/import"
		key="/tasks/import"
	/>,
];

export default TasksRoutes;
