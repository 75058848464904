const ChristmasGiftBanner = () => {
	return (
		<div className="top-0 left-0 right-0 p-4 rounded-md bg-primary z-20 px-8">
			<div className="flex items-center gap-[2px]">
				<strong>🎄</strong>
				<p className="text-white mx-auto block text-center font-bold">
					Voor elke studiegerichte opdracht boven de &euro; 250
					doneren wij een kersstol aan de voedselbank.
				</p>
				<strong>🎄</strong>
			</div>
		</div>
	);
};

export default ChristmasGiftBanner;
