import { ComponentProps, createContext, useContext } from "react";
import Text from "src/components/ui/text";
import { cn } from "src/lib/utils";

export const Provider = createContext({} as any);

interface SectionProps extends ComponentProps<"section"> {
	label: string;
	variant?: "large";
	labelClassName?: string;
}

const Section = ({
	label,
	className,
	children,
	variant,
	labelClassName,
	...rest
}: SectionProps) => {
	return (
		<Provider.Provider value={{ label, variant }}>
			<section {...rest} className={cn("flex flex-col gap-3", className)}>
				<Label className={labelClassName}>{label}</Label>
				{children as any}
			</section>
		</Provider.Provider>
	);
};

const Label = (props: ComponentProps<"h2">) => {
	const { label, variant } = useContext(Provider);
	if (variant === "large") {
		return (
			<h2 {...props} className={cn("text-xl", props.className)}>
				{label}
			</h2>
		);
	}
	return (
		<Text.Eyebrow {...props} className={cn("", props.className)}>
			{label}
		</Text.Eyebrow>
	);
};

Section.Label = Label;

export default Section;
