import withTranslation, {
	Translation,
} from "src/components/hoc/withTranslation";
import ResumeSectionTitle from "src/components/resume/resume-detail/ResumeSectionTitle";
import { VALID_SOCIALS } from "src/lib/constants";
import useResumeDetailContext from "../ResumeDetailContext";

interface ResumeSocialMediaProps extends Translation {}

function ResumeSocialMedia({ t }: ResumeSocialMediaProps) {
	const { resume } = useResumeDetailContext();

	return (
		<div className="flex flex-col gap-2">
			<ResumeSectionTitle>{t("title")}</ResumeSectionTitle>

			<div className="flex flex-row flex-wrap gap-3">
				{resume.social_media.map((social) => {
					const validSocial = VALID_SOCIALS.find(
						(item) => item.name === social.platform
					);
					if (!validSocial) return null;
					return (
						<a
							key={`social-media-${social.id}`}
							href={social.direct_link}
							target="_blank"
							rel="noreferrer"
							className="flex items-center gap-2 rounded-md border border-border px-5 py-3 hover:border-primary hover:bg-accent transition-all"
						>
							<div className="block min-w-[18px]">
								<i className={validSocial.icon}></i>
							</div>
							<span className="flex-1">{validSocial.label}</span>
						</a>
					);
				})}
			</div>
		</div>
	);
}

ResumeSocialMedia.locale = {
	nl: {
		title: "Social media",
		empty: "Voeg je social media profielen toe aan je CV",
	},
	en: {
		title: "Social media",
		empty: "Add your social media profiles to your resume",
	},
};

export default withTranslation(ResumeSocialMedia);
