import { ApiTag } from "src/api/types";
import Tag from "src/components/Tag";
import { useTaskKanban } from "src/components/tasks/kanban/TaskKanbanCard";

// interface TaskKanbanCardTagsProps {
// 	children?: any;
// }

const TaskKanbanCardTags = () => {
	const { task } = useTaskKanban();
	if (!task?.tags?.length || task.type !== "INTERNAL") {
		return <></>;
	}
	return (
		<>
			{(task?.tags || [])
				.filter((tag: ApiTag) => tag.tag)
				.map((tag: ApiTag) => (
					<Tag
						theme="dark"
						size="xsmall"
						className="whitespace-nowrap px-2 py-1"
						key={`${tag.tag}-${task.hid}`}
					>
						{tag.tag}
					</Tag>
				))}
		</>
	);
};

export default TaskKanbanCardTags;
