import React from "react";
import { useTranslation } from "react-i18next";
import useCreateOffer from "src/hooks/api/useCreateOffer";

const OfferCreateProgress = () => {
	const { current } = useCreateOffer();
	const { t } = useTranslation("offer", {
		keyPrefix: "form",
	});
	const steps = [
		t("steps.title"),
		t("steps.description"),
		t("steps.visibility"),
		t("steps.skills"),
		t("steps.budget"),
	];

	const progress = (current / steps.length) * 100;
	return (
		<div className="mt-8 md:mt-20">
			<div className="bg-dark-accent h-2 w-full relative rounded-full">
				<div
					style={{
						width: `${progress}%`,
					}}
					className="rounded-full block h-2 transition-all bg-green"
				></div>
			</div>
			<div className="md:flex hidden items-center justify-between text-white mt-4">
				{steps.map((step, index) => (
					<small
						className={`font-bold transition-all ${
							current > index && "text-green"
						}`}
						key={`${step}-step`}
					>
						{step}
					</small>
				))}
			</div>
		</div>
	);
};

const Dots = () => {
	const { current } = useCreateOffer();
	const { t } = useTranslation("offer", {
		keyPrefix: "form",
	});
	const steps = [
		t("steps.title"),
		t("steps.description"),
		t("steps.visibility"),
		t("steps.skills"),
		t("steps.budget"),
	];

	return (
		<div className="flex gap-2 items-center">
			{steps.map((step, index) => (
				<div
					key={`${step}-dot`}
					className={`w-2 h-2 rounded-full transition-all ${
						current === index ? "bg-primary" : "bg-accent"
					}`}
				></div>
			))}
		</div>
	);
};

OfferCreateProgress.Dots = Dots;

export default OfferCreateProgress;
