import {
	Tooltip,
	TooltipContent,
	TooltipProvider,
	TooltipTrigger,
} from "src/components/ui/tooltip";
import useVariant, { VariantTypes } from "src/hooks/useVariant";
import { hexToRGB } from "src/lib/colors";
import { cn } from "src/lib/utils";

const sizes = {
	default: "py-2 px-3 rounded-md",
	small: "py-0.5 px-1.5 text-sm rounded-md font-regular",
	xsmall: "py-0.5 px-1.5 text-[10px] rounded-sm font-regular",
};

export type BadgeSize = keyof typeof sizes;

export interface BadgeProps {
	children?: any;
	bg?: string;
	color?: string;
	border?: string;
	variant?: VariantTypes;
	className?: string;
	style?: any;
	size?: BadgeSize;
	onClick?: () => void;
	custom?: string;
	tooltip?: { text: string };
}

const Badge = ({
	className: _className,
	children,
	color,
	bg,
	border,
	variant: _variant,
	style,
	size = "default",
	onClick,
	custom,
	tooltip,
}: BadgeProps) => {
	const variant = useVariant(_variant);
	let variantClassName: any =
		!custom && _variant && ["green", "red", "blue"].includes(_variant)
			? `text-${_variant} bg-${_variant}-100`
			: variant;
	if (color && bg && !custom) {
		variantClassName = null;
	}
	const className = cn(
		"w-max text-[14px] flex gap-1 items-center whitespace-nowrap font-regular leading-full select-none",
		!custom && color && bg && `text-${color} bg-${bg}`,
		variantClassName,
		border && !custom && `border-${border} border-2`,
		sizes[size],
		_className
	);

	if (tooltip) {
		return (
			<TooltipProvider>
				<Tooltip>
					<TooltipTrigger asChild>
						<span
							{...{ onClick, className }}
							style={
								custom && {
									...(style || {}),
									backgroundColor: hexToRGB(custom, 0.1),
									color: custom,
								}
							}
						>
							{children}
						</span>
					</TooltipTrigger>
					<TooltipContent>
						<p className="max-w-[220px] text-center">
							{tooltip.text}
						</p>
					</TooltipContent>
				</Tooltip>
			</TooltipProvider>
		);
	}

	return (
		<span
			{...{ onClick, className }}
			style={
				custom && {
					...(style || {}),
					backgroundColor: hexToRGB(custom, 0.1),
					color: custom,
				}
			}
		>
			{children}
		</span>
	);
};

Badge.defaultProps = {
	color: "primary",
	size: "default",
};

export default Badge;
