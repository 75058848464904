import { ClassValue } from "clsx";
import moment, { Moment } from "moment";
import { useLayoutEffect, useRef, useState } from "react";
import { useCalendar } from "src/components/Calendar";
import Column from "src/components/Calendar/week/Column";
import Container from "src/components/layout/Container";
import { getDatesBetweenDates } from "src/lib/dates";
import { cn } from "src/lib/utils";

export interface WeekProps {
	value?: {
		week: number;
		year: number;
	};
	children?: any;
	column?: {
		head?: (date: Moment) => JSX.Element;
		footer?: (date: Moment) => JSX.Element;
		className?: string;
	};
	variant?: "scrollable" | "flat" | "month-child";
	columnVariant?: "scrollable" | "flat" | "month-child";
	className?: ClassValue;
	id?: string;
}

const Week = ({
	value,
	column,
	columnVariant,
	variant = "scrollable",
	className,
	id,
}: WeekProps) => {
	const ref = useRef() as any;
	const [scrolledToDate, setScrolledToDate] = useState(false);
	const { selected, layout, options } = useCalendar();
	const date = value
		? moment().week(value.week).year(value.year)
		: selected.value;

	const start = moment(date).startOf("week");
	const end = moment(date).endOf("week");
	const dates = getDatesBetweenDates(
		start,
		layout === "full-week" ? end : moment(start).day(5)
	);

	const scrollTo = () => {
		if (
			options &&
			options?.scrollToDate &&
			ref?.current &&
			!scrolledToDate
		) {
			const active = ref.current.querySelector(
				`[data-date="${options.scrollToDate.format("YYYY-MM-DD")}"]`
			);
			ref.current.scrollTo({
				left: active?.offsetLeft || 0,
				behavior: "smooth",
			});
			setScrolledToDate(true);
		}
	};

	useLayoutEffect(() => {
		scrollTo();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	if (variant === "flat") {
		return (
			<div
				{...{ id, ref }}
				className={cn("flex flex-1 scroll-m-8", className)}
			>
				{dates.map((date) => (
					<Column
						key={`week-${date}`}
						variant={columnVariant}
						{...{ date }}
						className={column?.className}
						head={column?.head ? column.head(date) : undefined}
						footer={
							column?.footer ? column.footer(date) : undefined
						}
					/>
				))}
			</div>
		);
	}

	return (
		<Container
			{...{ id, ref }}
			variant="full-scrollable"
			className={cn("flex flex-row flex-1 min-h-[50vh]", className)}
		>
			<div className="flex flex-1 relative">
				{dates.map((date) => (
					<Column
						key={`week-${date}`}
						variant={columnVariant}
						{...{ date }}
						className={column?.className}
						head={column?.head ? column.head(date) : undefined}
						footer={
							column?.footer ? column.footer(date) : undefined
						}
					/>
				))}
			</div>
		</Container>
	);
};

export default Week;
