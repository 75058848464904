import { Form, Formik } from "formik";
import * as Yup from "yup";
import { useState } from "react";
import usePost from "src/hooks/rest/usePost";
import Button from "src/components/Button";
import Input from "src/components/form/Input";
import withTranslation, {
	Translation,
} from "src/components/hoc/withTranslation";

const ForgotPasswordRequestForm = ({ t }: Translation) => {
	const ForgotPasswordRequestScheme = Yup.object().shape({
		email: Yup.string()
			.email(t("validate.email") || "")
			.required(t("validate.required") || ""),
	});

	const [requestPassword] = usePost(`/auth/password/create`);
	const [status, setStatus] = useState<"idle" | "success" | "error">("idle");

	return (
		<Formik
			initialValues={{ email: "" }}
			validationSchema={ForgotPasswordRequestScheme}
			onSubmit={(values, { setSubmitting, resetForm }) => {
				requestPassword(values)
					.then(() => {
						setStatus("success");
						resetForm();
					})
					.catch(() => {
						setStatus("error");
					})
					.finally(() => setSubmitting(false));
			}}
		>
			{({
				values,
				errors,
				touched,
				handleChange,
				handleBlur,
				handleSubmit,
				isSubmitting,
			}) => (
				<Form className="flex flex-col gap-6" onSubmit={handleSubmit}>
					{status === "success" && (
						<div className="bg-green-200 mb-4 p-4 flex flex-col text-green px-6 rounded-md">
							<strong>{t("success.title")}</strong>
							<span>{t("success.text")}</span>
						</div>
					)}
					{status === "error" && (
						<div className="bg-red-200 mb-4 p-4 flex flex-col text-red px-6 rounded-md">
							<strong>{t("failed.title")}</strong>
							<span>{t("failed.text")}</span>
						</div>
					)}
					<div className="flex flex-col">
						<Input
							label={t("fields.email.label") || ""}
							name="email"
							type="email"
							value={values.email}
							valid={values.email && !errors.email}
							error={
								errors.email && touched.email && errors.email
							}
							{...{ handleChange, handleBlur }}
						/>
						<p className="mt-2">{t("fields.email.placeholder")}</p>
					</div>
					<Button type="dark" submit loading={isSubmitting}>
						{t("buttons.submit")}
					</Button>
				</Form>
			)}
		</Formik>
	);
};

ForgotPasswordRequestForm.locale = {
	nl: {
		validate: {
			email: "Ongeldig e-mailadres",
			required: "Verplicht",
		},
		failed: {
			title: "Er is iets misgegaan",
			text: "Probeer een ander emailadres",
		},
		success: {
			title: "Gelukt!",
			text: "Je ontvangt een e-mail met een wijzigingslink",
		},
		fields: {
			email: {
				label: "E-mail",
				placeholder:
					"Het emailadres waar je je account mee hebt aangemaakt",
			},
		},
		buttons: {
			submit: "Wachtwoord aanvragen",
		},
	},
	en: {
		validate: {
			email: "Invalid email address",
			required: "Required",
		},
		failed: {
			title: "Something went wrong",
			text: "Try another email address",
		},
		success: {
			title: "Success!",
			text: "You will receive an email with a change link",
		},
		fields: {
			email: {
				label: "Email",
				placeholder:
					"The email address you used to create your account",
			},
		},
		buttons: {
			submit: "Request Password",
		},
	},
};

export default withTranslation(ForgotPasswordRequestForm);
