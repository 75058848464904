const chat = {
	"create-message-box": {
		"denied-company":
			"De aanmelding is afgewezen, hierdoor is de chat gesloten",
		"denied-student":
			"De aanmelding is afgewezen, hierdoor is de chat gesloten",
		"no-subscription":
			"Om te kunnen chatten met de professionals heb je een actief abonnement nodig <1>Klik hier</1> om een abonnement te kiezen",
		placeholder: "Type hier jouw bericht...",
		cta: "Versturen",
	},
	"message-bid-form": {
		title: "Prijs wijzigen",
		"price-adjust-accepted":
			"De prijs van de opdracht is aangepast naar {{price}}",
		"price-adjust-denied":
			"De wijziging van de prijs naar {{price}} is afgekeurd",
		"price-adjust-open": "{{name}} wilt de prijs wijzigen naar {{price}}",
		pending: "In afwachting van reactie",
		accept: "Prijs aanpassen",
	},
	"messages-box": {
		"turned-off": "Deze chat is uitgeschakeld",
		"button-older-messages": "Laad oudere berichten",
		motivation: "Motivatie",
	},
};

export default chat;
