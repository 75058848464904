import { createAction, handleActions } from "redux-actions";
import { ApiSalesTeamMember } from "src/api/types";

export const updateSupport = createAction("UPDATE_SUPPORT");
export const removeSupport = createAction("REMOVE_SUPPORT");

export const emptySupportState = {
	member: undefined,
	loading: true,
};

type SupportState = {
	member?: ApiSalesTeamMember;
	loading: boolean;
};

const supportReducer = handleActions(
	{
		UPDATE_SUPPORT: (state: SupportState, action: any) => {
			return { ...state, ...action.payload };
		},
		REMOVE_SUPPORT: () => {
			return {
				...emptySupportState,
			};
		},
	},
	{
		...emptySupportState,
	}
);
export default supportReducer;
