import React, { ComponentProps, useState } from "react";
import SearchInput from "src/components/form/SearchInput";
import Items, {
	Empty,
	Filter,
	Footer,
	Head,
	Item,
	Loading,
	Row,
} from "src/components/ui/list/Layout";
import Pagination from "src/components/ui/list/Pagination";
import { ListContext, ListContextProps } from "src/components/ui/list/Provider";
import { Select, SelectAll, Selected } from "src/components/ui/list/Select";
import { cn } from "src/lib/utils";

interface ListProps
	extends Omit<ListContextProps, "selected" | "setSelected"> {}

const List = ({
	data,
	status,
	children,
	columnCount: _columnCount,
	className,
	showEmptyList,
	groupBy,
	keepListWhileLoading,
	selectMaximum,
	...rest
}: ListProps & ComponentProps<"div">) => {
	const [selected, setSelected] = useState<any[]>([]);
	const columnCount =
		_columnCount ||
		(
			React.Children.toArray(children as any).find(
				(child: any) => child.type.name === "Head"
			) as any
		)?.props?.children?.length ||
		undefined;

	return (
		<ListContext.Provider
			value={{
				selected,
				setSelected,
				data,
				status,
				columnCount,
				showEmptyList,
				groupBy,
				keepListWhileLoading,
				selectMaximum,
			}}
		>
			<div
				{...rest}
				className={cn(
					"flex flex-col p-0 gap-0 relative overflow-x-auto",
					status !== "idle" && "overflow-hidden",
					className
				)}
			>
				<table className="w-full">{children}</table>
			</div>
		</ListContext.Provider>
	);
};

List.Items = Items;
List.Row = Row;
List.Footer = Footer;
List.Item = Item;
List.Head = Head;
List.Loading = Loading;
List.Filter = Filter;
List.Search = SearchInput;
List.Empty = Empty;
List.Pagination = Pagination;
List.Select = Select;
List.SelectAll = SelectAll;
List.Selected = Selected;

export default List;
