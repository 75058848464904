import { useEffect } from "react";

interface Options {
	loading: boolean;
	prefix?: string;
}

function useScrollToHid(options: Options) {
	useEffect(() => {
		if (!options.loading) {
			const href = window.location.href;
			if (href.includes("#")) {
				const parts = href.split("#");
				const hid = parts[parts.length - 1];
				if (hid) {
					const selector = options?.prefix
						? `${options?.prefix}-${hid}`
						: hid;
					const element = document.getElementById(selector);
					element?.scrollIntoView({
						behavior: "smooth",
						block: "start",
					});
				}
			}
		}
	}, [options]);
}

export default useScrollToHid;
