const envelope = {
	"envelope-information": {
		title: "Informatie",
		task: "Opdracht:",
		"task-budget": "Opdrachtwaarde:",
		"task-budget-per-hour": "Uurloon:",
		deadline: "Deadline:",
	},
	status: {
		SIGNED: "Ondertekend",
		VIEWED: "Bekeken",
		CREATED: "Aangemaakt",
		SEND: "Verstuurd",
		"NOT-SEEN": "Niet bekeken",
	},
	"signer-signature-form": {
		"not-allowed": {
			title: "Oeps, deze overeenkomst kan jij niet ondertekenen",
			description: "Enkel {{name}} kan deze overeenkomst ondertekenen.",
		},
		button: {
			"already-signed": "Al ondertekend",
			sign: "Ondertekenen",
		},
		title: "Ondertekenen",
		"edit-signature": "Handtekening wijzigen",
		"click-to-sign": "Klik hier om te ondertekenen",
		"signed-succesfull": "De overeenkomst is succesvol ondertekend",
	},
	"envelope-document": {
		error: {
			title: "Het document kan niet worden geladen",
			text: "Controleer of je profiel compleet is ingevuld. Blijft het probleem zich voor doen neem dan contact op met <1>support@{{tenant.slug}}.nl</1>. Excuses voor het ongemak!",
			subject: "Overeenkomst: {{id}} lijkt niet te werken",
		},
		"view-document": "Document bekijken",
	},
	"signature-canvas": {
		title: "Teken in het gebied",
		error: {
			title: "Oeps! Er is iets misgegaan",
			text: "Het is niet gelukt om je handtekening op te slaan",
		},
	},
	"signature-grid": {
		empty: {
			title: "Je hebt nog geen handtekeningen",
		},
		button: {
			sign: "Document ondertekenen",
			select: "Handtekening selecteren",
		},
	},
	"signature-modal": {
		title: "Ondertekenen",
		tabs: {
			select: "Selecteren",
			create: "Aanmaken",
		},
	},
};

export default envelope;
