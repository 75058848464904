import { useState } from "react";
import { ApiMedia } from "src/api/types";
import * as DropdownMenuPrimitive from "@radix-ui/react-dropdown-menu";
import Card from "src/components/Card";
import withTranslation, {
	Translation,
} from "src/components/hoc/withTranslation";

interface FileActionsProps extends Translation {
	media: ApiMedia;
	onDelete?: () => void;
}

const FileActions = ({ t, media, onDelete }: FileActionsProps) => {
	const [open, setOpen] = useState(false);

	const handleDownload = () => {
		const a = document.createElement("a");
		a.href = media.url;
		a.target = "_blank";

		const clickEvnt = new MouseEvent("click", {
			view: window,
			bubbles: true,
			cancelable: true,
		});

		a.dispatchEvent(clickEvnt);
		a.remove();
	};

	return (
		<DropdownMenuPrimitive.Root {...{ open }} onOpenChange={setOpen}>
			<DropdownMenuPrimitive.Trigger asChild>
				<button className="w-6 h-6 bg-transparent hover:bg-dark-50 transition-all cursor-pointer flex justify-center items-center rounded-sm">
					<i className="fas fa-ellipsis-v text-[12px]"></i>
				</button>
			</DropdownMenuPrimitive.Trigger>
			<DropdownMenuPrimitive.Content className="w-full z-[88]">
				<Card contentClassName=" p-1">
					<DropdownMenuPrimitive.Item
						onClick={handleDownload}
						className={`flex items-center gap-2 cursor-pointer rounded-md radix-disabled:opacity-30 radix-highlighted:bg-accent py-[11px] px-[10px]`}
					>
						<div className="w-5">
							<i className="far fa-download"></i>
						</div>
						<span>{t("download")}</span>
					</DropdownMenuPrimitive.Item>
					{onDelete && (
						<DropdownMenuPrimitive.Item
							onClick={onDelete}
							className={`flex items-center gap-2 cursor-pointer rounded-md radix-disabled:opacity-30 radix-highlighted:bg-accent py-[11px] px-[10px]`}
						>
							<div className="w-5">
								<i className="far fa-trash"></i>
							</div>
							<span>{t("delete")}</span>
						</DropdownMenuPrimitive.Item>
					)}
				</Card>
			</DropdownMenuPrimitive.Content>
		</DropdownMenuPrimitive.Root>
	);
};

FileActions.locale = {
	nl: {
		download: "Download",
		delete: "Verwijder",
	},
	en: {
		download: "Download",
		delete: "Delete",
	},
};

export default withTranslation(FileActions);
