import { atom, useAtom } from "jotai";
import useLanguage from "src/hooks/api/useLanguage";
import useEmoji, { getRandomEmoji } from "src/hooks/data/useEmoji";
import { formatList } from "src/lib/formatters";

const emptyBoard = {
	name: "",
	visibility: "PUBLIC",
	users: [],
	emoji: getRandomEmoji(),
};

const boardFormAtom = atom(emptyBoard);

export default function useBoardForm() {
	const { language } = useLanguage();
	const { actions: emojiActions } = useEmoji();
	// boardFormAtom.onMount = ()
	const [board, setBoard] = useAtom<any, any, any>(boardFormAtom);

	const generateDefaultName = (filter: any) => {
		let name = "";
		const isMe = filter.assignees?.includes("me");

		let tags = formatList(
			filter?.tags || [],
			(language?.current || "NL").toLowerCase()
		);

		if (tags) {
			const prefix = language?.current === "nl" ? "Mijn" : "My";
			const affix = language?.current === "nl" ? "taken" : "tasks";
			name = isMe ? `${prefix} ${tags} ${affix}` : tags;
		}

		return name;
	};

	const getRandomIcon = () => {
		return {
			emoji: emojiActions.getRandomEmoji(),
		};
	};

	const isDisabled = !board.name;

	return {
		board,
		isDisabled,
		actions: {
			generateDefaultName,
			getRandomIcon,
			set: (data: any, mode: "fill" | "reset" = "fill") =>
				setBoard((state: any) =>
					mode === "fill"
						? {
								...state,
								...(data || {}),
						  }
						: {
								...emptyBoard,
								...(data || {}),
						  }
				),
		},
	};
}
