import { Route } from "react-router-dom";
import TaskModal from "src/components/tasks/TaskForm/modals/task-modal/TaskModal";
import CreateProjectModal from "src/components/projects/create/CreateProjectModal";
import ShareProjectModal from "src/components/projects/share/ShareProjectModal";
import ProtectedRoute from "src/routes/middlewares/ProtectedRoute";
import EditProjectView from "src/views/authenticated/projects/EditProjectView";
import ProjectView from "src/views/authenticated/projects/ProjectView";
import ProjectsFilterView from "src/views/authenticated/projects/ProjectsFilterView";
import DashboardProjects from "src/views/authenticated/projects/ProjectsView";

const ProjectsRoutes = [
	<Route
		element={
			<ProtectedRoute>
				<DashboardProjects />
			</ProtectedRoute>
		}
		path="/projects"
		key="/projects"
	/>,
	<Route
		element={
			<ProtectedRoute>
				<ProjectView />
			</ProtectedRoute>
		}
		path="/projects/:project"
		key="/projects/:project"
	/>,
	<Route
		element={
			<ProtectedRoute guard={["intern-task"]}>
				<EditProjectView />
			</ProtectedRoute>
		}
		path="/projects/:project/edit"
		key="/projects/:project/edit"
	/>,
];

export const ProjectsModalRoutes = [
	<Route
		element={
			<ProtectedRoute variant="on-top">
				<CreateProjectModal defaultVisible backUrl="/projects" />
			</ProtectedRoute>
		}
		path="/projects/create"
		key="/projects/create"
	/>,
	<Route
		element={
			<ProtectedRoute
				variant="modal"
				modal={{
					backUrl: "/projects",
					expanded: false,
					size: "default",
				}}
			>
				<ProjectsFilterView />
			</ProtectedRoute>
		}
		path="/projects/filter"
		key="/projects/filter"
	/>,
	<Route
		element={
			<ProtectedRoute variant="on-top">
				<TaskModal defaultVisible backUrl="/projects/:project" />
			</ProtectedRoute>
		}
		path="/projects/:project/tasks/:id/show"
		key="/projects/:project/tasks/:id/show"
	/>,
	<Route
		element={
			<ProtectedRoute variant="on-top">
				<ShareProjectModal
					defaultVisible
					backUrl="/projects/:project"
				/>
			</ProtectedRoute>
		}
		path="/projects/:project/share"
		key="/projects/:project/share"
	/>,
];

export default ProjectsRoutes;
