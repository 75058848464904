import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Avatar from "src/components/Avatar";
import CompanyInformationForm from "src/components/forms/auth/signup/company/CompanyInformationForm";
import withTranslation, {
	Translation,
} from "src/components/hoc/withTranslation";
import AuthLayout from "src/components/layout/auth-layout/AuthLayout";
import useProspect from "src/hooks/api/services/auth/useProspect";
import useQueryString from "src/hooks/useQueryString";

function SignupCompanyInformationView({ t }: Translation) {
	const qs = useQueryString();
	const { slug } = useParams() as any;
	const navigate = useNavigate();
	const { prospect, actions, status } = useProspect();

	useEffect(() => {
		if (slug && !prospect?.id) {
			actions.get(slug).catch(() => {
				navigate("/signup/company");
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<AuthLayout
			head={{
				title: t("title"),
				back: {
					to: `/signup/company/${slug}`,
					label: t("back"),
				},
			}}
			loading={status === "loading"}
		>
			<div className="flex flex-col gap-4">
				<div className="flex gap-4 items-end">
					{prospect.icon && (
						<Avatar
							size="medium"
							variant="white"
							src={prospect?.icon}
						/>
					)}
					<div className="flex flex-col">
						<strong>{prospect.company_name}</strong>
						{(prospect?.website || prospect?.kvk) && (
							<span>
								{prospect?.kvk
									? t("kvk", {
											kvk: prospect.kvk,
									  })
									: prospect.website}
							</span>
						)}
					</div>
				</div>
				<div className="my-4">
					<CompanyInformationForm
						onBack={() => navigate(-1)}
						onSubmit={() =>
							navigate(
								`/signup/company/${slug}/information/contact`
							)
						}
						infoId={qs?.info?.toString()}
					/>
				</div>
			</div>
		</AuthLayout>
	);
}

SignupCompanyInformationView.locale = {
	nl: {
		title: "Aanmelden als bedrijf",
		back: "Terug naar aanmelden",
		kvk: "kvk: {{kvk}}",
		"manual-disclaimer":
			"Staat je bedrijf niet tussen de zoekresultaten? Gebruik de ondertaande knop om je bedrijfsgegevens handmatig in te vullen",
		buttons: {
			manual: "Handmatig invoeren",
			continue: "Doorgaan",
		},
	},
	en: {
		title: "I want to outsource tasks",
		back: "Back to signup",
		kvk: "chamber of commerce: {{kvk}}",
		"manual-disclaimer":
			"Are you not able to find your company, no worries. Use the button below to add your details manual",
		buttons: {
			manual: "Manual",
			continue: "Continue",
		},
	},
};
export default withTranslation(SignupCompanyInformationView);
