import Avatar from "src/components/Avatar";
import useWindowSize from "src/hooks/useWindowSize";
import useResumeDetailContext from "../ResumeDetailContext";

interface ResumePublicHeadProps {
	variant?: "default" | "compact";
}

function ResumePublicHead({ variant = "default" }: ResumePublicHeadProps) {
	const { isPhone } = useWindowSize();
	const { resume } = useResumeDetailContext();

	if (variant === "compact") {
		return (
			<div className="flex flex-col md:flex-row gap-4 items-end">
				<div className="relative">
					<Avatar
						size={isPhone ? "large" : "resume"}
						src={resume.avatar}
					/>
				</div>
				<div className="md:ml-4 flex flex-col gap-2">
					<h1>
						{resume.full_name}
						<span className="absolute ml-2 md:ml-2">👋</span>
					</h1>
					<p>{resume.tagline}</p>
				</div>
			</div>
		);
	}

	return (
		<div className="flex flex-col md:flex-row gap-4">
			<div className="-mt-[60px] md:-mt-[100px] relative">
				<Avatar
					size={isPhone ? "large" : "resume"}
					src={resume.avatar}
				/>
			</div>
			<div className="md:ml-4 flex flex-col gap-2">
				<h1>
					{resume.full_name}
					<span className="absolute ml-2 md:ml-2">👋</span>
				</h1>
				<p>{resume.tagline}</p>
			</div>
		</div>
	);
}

export default ResumePublicHead;
