import useLayout from "src/hooks/useLayout";
import useWindowSize from "src/hooks/useWindowSize";
import { cn } from "src/lib/utils";

interface NavbarToggleProps {
	className?: string;
	containerClassName?: string;
	forcedVariant?: "default" | "minimal";
}

const NavbarToggle = ({
	className,
	forcedVariant,
	containerClassName,
}: NavbarToggleProps) => {
	const { layout, actions } = useLayout();
	const { isPhone, isTablet } = useWindowSize();
	const isDesktop = !(isPhone || isTablet);
	const variant: any = layout.navbar.variant || "default";
	if (!isDesktop) {
		return <></>;
	}
	return (
		<div
			className={cn(
				"absolute top-0 right-0 bottom-0 lg:hidden xl:flex items-center",
				className
			)}
		>
			<div
				onClick={() =>
					actions.setNavbar(
						variant === "default" ? "minimal" : "default"
					)
				}
				className={cn(
					"flex justify-center items-center rounded-md cursor-pointer transition-all w-6 h-6",
					(variant === "minimal" || forcedVariant === "minimal") &&
						"bg-background opacity-0 group-hover/sidebar:opacity-100",
					containerClassName
				)}
			>
				<svg
					className="w-full"
					viewBox="0 0 19 18"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
				>
					<path
						d="M14.75 2.25H4.25C3.42157 2.25 2.75 2.92157 2.75 3.75V14.25C2.75 15.0784 3.42157 15.75 4.25 15.75H14.75C15.5784 15.75 16.25 15.0784 16.25 14.25V3.75C16.25 2.92157 15.5784 2.25 14.75 2.25Z"
						strokeWidth="1.5"
						className="stroke-border"
						strokeLinecap="round"
						strokeLinejoin="round"
					/>
					<path
						d="M7.25 10.5V11.25"
						strokeWidth="1.5"
						className="stroke-border"
						strokeLinecap="round"
						strokeLinejoin="round"
					/>
					<path
						d="M7.25 14.25V15.75"
						strokeWidth="1.5"
						className="stroke-border"
						strokeLinecap="round"
						strokeLinejoin="round"
					/>
					<path
						d="M7.25 2.25V3.75"
						strokeWidth="1.5"
						className="stroke-border"
						strokeLinecap="round"
						strokeLinejoin="round"
					/>
					<path
						d="M7.25 6.75V7.5"
						strokeWidth="1.5"
						className="stroke-border"
						strokeLinecap="round"
						strokeLinejoin="round"
					/>
				</svg>
			</div>
		</div>
	);
};

export default NavbarToggle;
