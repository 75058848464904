import { createAction, handleActions } from "redux-actions";
import { ApiOfferRegistration } from "src/api/types";

export const updateOfferRegistration = createAction(
	"UPDATE_OFFER_REGISTRATION"
);
export const removeOfferRegistration = createAction(
	"REMOVE_OFFER_REGISTRATION"
);

type OfferRegistrationState = {
	registration: ApiOfferRegistration;
};

const offerRegistrationReducer = handleActions(
	{
		UPDATE_OFFER_REGISTRATION: (
			state: OfferRegistrationState,
			action: any
		) => {
			return { ...state, ...action.payload };
		},
		REMOVE_OFFER_REGISTRATION: () => {
			return {} as OfferRegistrationState;
		},
	},
	{} as OfferRegistrationState
);
export default offerRegistrationReducer;
