import { HTMLProps } from "react";
import FormGroup from "src/components/form/FormGroup";
import { cn } from "src/lib/utils";

export interface WrapperProps extends HTMLProps<HTMLDivElement> {
	label?: string;
	description?: string;
	layout?: "vertical" | "horizontal" | "horizontal-always";
	onFocus?: () => void;
	variant?: "form-group";
	footer?: JSX.Element;
	theme?: {
		layout?: { className?: string; variant?: "fit" };
	};
}

const Wrapper = ({
	label,
	description,
	layout = "vertical",
	children,
	onFocus,
	variant,
	className,
	theme,
	footer,
	...rest
}: WrapperProps) => {
	if (variant === "form-group") {
		return (
			<FormGroup noMarginBottom {...{ label }}>
				<div className="flex flex-col flex-1">{children}</div>
			</FormGroup>
		);
	}

	return (
		<div className={cn("flex flex-col", className)}>
			<div
				className={cn(
					"flex flex-col",
					layout === "vertical" && "flex-1 gap-1",
					layout === "horizontal" &&
						`md:items-center md:grid md:grid-cols-[126px,1fr] md:gap-4 gap-1`,
					layout === "horizontal-always" &&
						`items-center ignore-template grid grid-cols-[126px,1fr] gap-4`,
					layout === "horizontal" &&
						theme?.layout?.variant === "fit" &&
						`md:grid-cols-[max-content,1fr]`,
					theme?.layout?.className
				)}
				{...rest}
			>
				{label && (
					<label
						className="font-regular text-[16px] opacity-70"
						onClick={onFocus}
					>
						{label}
					</label>
				)}
				<div className="flex flex-col flex-1">{children}</div>
			</div>
			{description && (
				<small
					className={cn(
						"col-span-2 italic opacity-60 block mt-1",
						footer && "mb-1"
					)}
				>
					{description}
				</small>
			)}
			{footer}
		</div>
	);
};

export default Wrapper;
