import React from "react";
import SubscriptionChangedBanner from "src/components/promotion/banners/SubscriptionChangedBanner";
import CurrentMandate from "src/components/subscription/billing/CurrentMandate";
import CurrentSubscription from "src/components/subscription/billing/CurrentSubscription";
import CurrentWallet from "src/components/subscription/billing/CurrentWallet";
import SubscriptionNextPayment from "src/components/subscription/billing/SubscriptionNextPayment";
import SubscriptionPaymentsTable from "src/components/subscription/billing/SubscriptionPaymentsTable";
import NoSubscriptionMiddleware from "src/components/middlewares/NoSubscriptionMiddleware";
import { useTranslation } from "react-i18next";
import ProfileTabs from "src/components/layout/profile-settings-layout/ProfileTabs";
import ProfileSettingsLayout from "src/components/layout/profile-settings-layout/ProfileSettingsLayout";
import useSubscription from "src/hooks/selectors/useSubscription";
import useTenant from "src/hooks/api/services/tenants/useTenant";

export default function BillingView() {
	const { t } = useTranslation("payment-view", { keyPrefix: "billing-view" });
	const { tenant } = useTenant();
	const subscription = useSubscription();

	if (!subscription?.name) {
		return (
			<NoSubscriptionMiddleware
				title={t("title")}
				tabs={<ProfileTabs />}
			/>
		);
	}

	return (
		<ProfileSettingsLayout title={t("title")}>
			<div className="md:grid flex flex-col md:grid-cols-3 gap-8">
				{subscription?.next_plan && <SubscriptionChangedBanner />}
				<CurrentSubscription {...{ subscription }} />
				<div className="flex flex-col gap-8">
					<SubscriptionNextPayment {...{ subscription }} />
					<CurrentMandate />
				</div>
				<CurrentWallet />
				{tenant.providers.payments === "OPP" && (
					<div className="col-span-3 mt-8">
						<h2 className="mb-8">{t("payment-history.title")}</h2>
						<SubscriptionPaymentsTable />
					</div>
				)}
			</div>
		</ProfileSettingsLayout>
	);
}
