import axios from "axios";
import { atom, useAtom } from "jotai";
import { ApiTaskKanbanStatus } from "src/api/types";

const taskStatusesAtom = atom<ApiTaskKanbanStatus[]>([]);
const taskStatusesLoadingAtom = atom<boolean>(false);

type State = {
	statuses: ApiTaskKanbanStatus[];
	loading: boolean;
	actions: {
		get: () => Promise<ApiTaskKanbanStatus[]>;
	};
};

export default function useTaskStatuses(): State {
	const [statuses, setStatuses] = useAtom(taskStatusesAtom);
	const [loading, setLoading] = useAtom(taskStatusesLoadingAtom);

	const get = async () => {
		setLoading(true);
		const { data } = await axios
			.get(`/client/tasks/kanban/statuses`)
			.then((res) => res.data);
		setStatuses(data);
		return data;
	};

	return {
		statuses,
		loading,
		actions: {
			get,
		},
	};
}
