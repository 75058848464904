import { useCallback, useContext } from "react";
import { createPortal } from "react-dom";

import { AnimatePresence, motion } from "framer-motion";
import { LayoutVariants } from "src/api/types";
import Loader from "src/components/Loader";
import FillModal from "src/components/Modal/FillModal";
import ModalTabs from "src/components/Modal/ModalTabs";
import ShadowBackground from "src/components/Modal/ShadowBackground";
import SideModal from "src/components/Modal/SideModal";
import { ModalViewContext } from "src/components/layout/ModalView";
import { useKeyPress } from "src/hooks/useKeyPress";
import useWindowSize from "src/hooks/useWindowSize";
import { cn } from "src/lib/utils";

export const MODAL_SIZES = {
	default: "w-full sm:max-w-[440px] md:min-w-[400px] max-h-[80vh]",
	small: "max-w-[512px] md:min-w-[400px] h-[80vh] max-h-[800px]",
	medium: "max-w-lg h-[85vh] sm:h-[80vh] sm:max-h-[800px] w-full md:w-[90%]",
	large: "max-w-[1080px] h-[85vh] sm:h-[80vh] sm:max-h-[800px] w-full md:w-[90%]",
	sidebar: "h-screen max-w-[1/2] w-full",
	fill: "",
	side: "",
	custom: "",
};

export type ModalSize = keyof typeof MODAL_SIZES;

export type ModalHeadVariant = "default" | "floating" | "disabled";

export interface ModalProps {
	onClose: () => void;
	visible: boolean;
	children?: any;
	buttons?: any;
	type?: string;
	className?: string;
	bodyClassName?: string;
	title?: string;
	size: ModalSize;
	loading?: boolean;
	variant?: LayoutVariants;
	head: ModalHeadVariant;
	expanded?: boolean;
	actions?: React.ReactNode;
	portalId?: string;
	theme?: "default" | "dark" | "light";
}

const Modal = (props: ModalProps) => {
	const modal = useContext(ModalViewContext);
	const {
		onClose,
		visible,
		children,
		buttons,
		className,
		bodyClassName,
		title,
		size,
		loading,
		variant: _variant,
		head,
		actions,
		portalId,
	} = props;
	const { isPhone } = useWindowSize();
	const hasTitle = title || modal?.title;
	const handleCloseByKeyPress = useCallback(() => {
		if (visible) {
			onClose();
		}
	}, [visible, onClose]);
	useKeyPress(["Escape"], handleCloseByKeyPress);

	const variants: any = {
		dark: {
			wrapper: "bg-dark-accent text-white",
			head: "bg-dark",
		},
		light: {
			wrapper: "bg-background text-background-foreground",
			head: "bg-background z-[99]",
		},
	};
	const variant =
		variants[_variant === "white" ? "light" : _variant || "light"];

	if (size === "side") {
		return <SideModal {...props} />;
	}

	if (size === "fill") {
		return <FillModal {...props} />;
	}

	if (size === "sidebar") {
		return createPortal(
			<AnimatePresence>
				{visible && (
					<>
						<ShadowBackground onClick={onClose} />
						<motion.div
							transition={{
								ease: "linear",
							}}
							initial={{ x: 1000 }}
							animate={{ x: 0 }}
							exit={{ x: 1000 }}
							className={`z-[99] overflow-y-auto fixed w-full max-w-[600px] top-0 right-0 bottom-0 ${
								variant.wrapper
							} ${className || ""}`}
						>
							<div
								className={`flex flex-1 gap-4 flex-row ${
									head !== "floating"
										? variant.head
										: "absolute top-0 left-0 right-0 z-20"
								} rounded-t-md p-4 px-8 justify-between`}
							>
								{title || modal?.title ? (
									<strong className="text-[18px]">
										{title || modal?.title}
									</strong>
								) : (
									<span></span>
								)}
								<i
									onClick={onClose}
									className="fal fa-times text-2xl cursor-pointer"
								></i>
							</div>
							<div className="p-8 md:text-left overflow-auto text-center">
								{loading ? (
									<div className="flex flex-1 flex-col justify-center items-center">
										<Loader />
									</div>
								) : (
									children
								)}
							</div>
							{buttons && (
								<div className="modal-footer">
									<div className={``}>{buttons}</div>
								</div>
							)}
						</motion.div>
					</>
				)}
			</AnimatePresence>,
			document.getElementById(portalId || "modal-root") as any
		);
	}

	return createPortal(
		<AnimatePresence>
			{visible && (
				<>
					<ShadowBackground onClick={onClose} />
					<motion.div
						style={
							!isPhone || size === "custom"
								? {
										top: "50%",
										left: "50%",
										transform: "translate(-50%, -50%)",
								  }
								: {}
						}
						className={cn(
							"z-[99] flex flex-col flex-1 fixed",
							isPhone &&
								size !== "custom" &&
								"bottom-0 left-0 right-0",
							MODAL_SIZES[size],
							isPhone && size === "custom" && "w-[94%]",
							className
						)}
					>
						<motion.div
							initial={{
								opacity: 0.9,
								scale: 0.95,
								...(isPhone ? { y: "100vh" } : {}),
							}}
							animate={{
								opacity: 1,
								scale: 1,
								...(isPhone ? { y: 0 } : {}),
							}}
							exit={{
								opacity: 0.9,
								scale: 0.95,
								...(isPhone ? { y: "100vh" } : {}),
							}}
							transition={
								isPhone
									? {
											ease: "linear",
									  }
									: {}
							}
							className={cn(
								"bg-background dark:border dark:border-accent text-background-foreground rounded-md flex-1 flex relative flex-col max-h-[80vh] overflow-auto",
								isPhone && "h-full rounded-t-md rounded-b-none"
							)}
						>
							{head !== "disabled" && (
								<div
									className={cn(
										"flex gap-6 flex-row rounded-t-md p-4 md:px-6 justify-between bg-background sticky top-0",
										head === "floating" &&
											"bg-transparent absolute top-0 right-0 z-20",
										head === "floating" &&
											hasTitle &&
											"left-0"
									)}
								>
									<div className="flex items-center gap-4">
										{hasTitle && (
											<strong className="md:text-[24px]">
												{title || modal?.title}
											</strong>
										)}
									</div>
									<div className="flex items-center gap-4">
										{actions || modal?.actions}
										<i
											onClick={onClose}
											className="fal fa-times text-3xl cursor-pointer transition-all text-placeholder hover:text-foreground"
										></i>
									</div>
								</div>
							)}
							<div
								className={cn(
									"flex flex-1 md:text-left overflow-y-scroll flex-col pt-2 pb-6 px-4 md:px-6",
									bodyClassName
								)}
							>
								{loading ? (
									<div className="flex flex-1 flex-col justify-center items-center">
										<Loader />
									</div>
								) : (
									children
								)}
							</div>
							{buttons && (
								<div className="modal-footer">
									<div className={``}>{buttons}</div>
								</div>
							)}
						</motion.div>
					</motion.div>
				</>
			)}
		</AnimatePresence>,
		document.getElementById(portalId || "modal-root") as any
	);
};

Modal.defaultProps = {
	head: "default",
	size: "default",
};

Modal.Tabs = ModalTabs;

interface ModalButtonsProps {
	children?: any;
	className?: string;
}

Modal.Buttons = ({ children, className }: ModalButtonsProps) => (
	<div
		className={cn(
			"flex items-center justify-between py-4 md:py-8 sticky bottom-0",
			className
		)}
	>
		{children}
	</div>
);

export default Modal;
