import moment from "moment";
import { ApiTimesheet } from "src/api/types";
import withTranslation, {
	Translation,
} from "src/components/hoc/withTranslation";
import TimelineItem from "src/components/Timeline/TimelineItem";
import useAuth from "src/hooks/selectors/useAuth";

interface TimesheetChangeLogItemProps extends Translation {
	timesheet: ApiTimesheet;
	type: {
		first: boolean;
		last: boolean;
	};
}

const TimesheetChangeLogItem = ({
	t,
	timesheet,
	type,
}: TimesheetChangeLogItemProps) => {
	const auth = useAuth();
	const isDisapproved =
		timesheet.status === "disapproved" && timesheet.approver;
	const isProcessed = timesheet.status === "processed" && timesheet.approver;
	const isApproved =
		["approved", "processed"].includes(timesheet.status) &&
		timesheet.approver;

	const params = {
		start: timesheet.start,
		end: timesheet.end,
		break: timesheet.break,
		count: timesheet.total,
	};

	return (
		<>
			{isProcessed && (
				<TimelineItem type={{ first: type.first }}>
					<strong>{t("processed.label")}</strong>
					<small className="opacity-50">
						{moment(timesheet.processed_at).fromNow()}
					</small>
				</TimelineItem>
			)}

			{isDisapproved && (
				<TimelineItem
					className="text-red"
					type={{ first: type.first && !isProcessed }}
				>
					<strong>
						{auth.id === timesheet?.approver?.id
							? t("denied.me")
							: t("denied.name", {
									name: timesheet.approver?.first_name,
							  })}
					</strong>
					<small className="opacity-50">
						{moment(timesheet.approved_at).fromNow()}
					</small>
				</TimelineItem>
			)}
			{isApproved && (
				<TimelineItem
					className="text-green"
					type={{ first: type.first && !isProcessed }}
				>
					<strong>
						{auth.id === timesheet?.approver?.id
							? t("approved.me")
							: t("approved.name", {
									name: timesheet.approver?.first_name,
							  })}
					</strong>
					<small className="opacity-50">
						{moment(timesheet.approved_at).fromNow()}
					</small>
				</TimelineItem>
			)}
			<TimelineItem
				type={isDisapproved || isApproved ? { last: type.last } : type}
			>
				<strong>
					{auth.id === timesheet.links.creator
						? t("me")
						: timesheet.creator?.first_name}
				</strong>
				<p className="text-[16px]">
					{timesheet.break
						? t("description.with-break", params)
						: t("description.without-break", params)}
				</p>
				<small className="opacity-50">
					{moment(timesheet.created_at).fromNow()}
				</small>
			</TimelineItem>
		</>
	);
};

TimesheetChangeLogItem.locale = {
	nl: {
		me: "ik",
		denied: {
			me: "Afgekeurd door: mij",
			name: "Afgekeurd door: {{name}}",
		},
		approved: {
			me: "Goedgekeurd door: mij",
			name: "Goedgekeurd door: {{name}}",
		},
		processed: {
			label: "Betaling is aangemaakt en verstuurd naar de opdrachtgever",
		},
		description: {
			"without-break":
				"Van {{start}} tot {{end}} zonder pauze, is totaal {{count}} uur",
			"with-break":
				"Van {{start}} tot {{end}} met een pauze van {{break}} uur, is totaal {{count}} uur",
		},
	},
	en: {
		me: "me",
		denied: {
			me: "Denied by: me",
			name: "Denied by: {{name}}",
		},
		approved: {
			me: "Approved by: mij",
			name: "Approved by: {{name}}",
		},
		processed: {
			label: "Payment is created & send to the company",
		},
		description: {
			"without-break":
				"From {{start}} till {{end}} with no breaks, is a total of {{count}} hour(s)",
			"with-break":
				"From {{start}} till {{end}} with a break of {{break}} hour, is a total of {{count}} hour(s)",
		},
	},
};

export default withTranslation(TimesheetChangeLogItem);
