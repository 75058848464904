import { createContext, useContext, useMemo } from "react";
import { ApiVacancy } from "src/api/types";

export type VacancyFormStepName = "submit" | "check" | "publish";

export type VacancyFormStatus =
	| "analyzing"
	| "updating"
	| "idle"
	| "publishing";

export type AnalyseData = { description: string };

export type VacancyFormSectionName = "description" | "salary" | "address";

export interface VacancyFormContextType {
	step: {
		current: VacancyFormStepName;
		set: React.Dispatch<React.SetStateAction<VacancyFormStepName>>;
	};
	vacancy: ApiVacancy | null;
	status: VacancyFormStatus;
	creditId: string | null;
	actions: {
		setVacancy: React.Dispatch<React.SetStateAction<ApiVacancy | null>>;
		setStatus: React.Dispatch<React.SetStateAction<VacancyFormStatus>>;
		setCreditId: React.Dispatch<React.SetStateAction<string | null>>;
		analyze: (data: AnalyseData) => Promise<ApiVacancy | null>;
		update: (data: ApiVacancy) => Promise<void>;
		publish: () => Promise<void>;
	};
}

export const VacancyFormContext = createContext<VacancyFormContextType>({
	step: {
		current: "submit",
		set: () => {},
	},
	vacancy: null,
	status: "idle",
	creditId: null,
	actions: {
		setVacancy: () => {},
		setStatus: () => {},
		setCreditId: () => {},
		analyze: async () => null,
		update: async () => {},
		publish: async () => {},
	},
});

export const useVacancyForm = (): VacancyFormContextType & {
	valid: {
		[name in VacancyFormSectionName]: boolean;
	};
} => {
	const { vacancy, ...context } = useContext(VacancyFormContext);
	const valid = useMemo(() => {
		return {
			address: !!vacancy?.address?.city,
			description:
				!!vacancy?.descriptions?.company &&
				!!vacancy?.descriptions?.offer &&
				!!vacancy?.descriptions?.role &&
				!!vacancy?.descriptions?.profile,
			salary:
				(!!vacancy?.salary?.min && !!vacancy?.salary?.max) ||
				(vacancy?.salary?.min === null &&
					vacancy?.salary?.max === null),
		};
	}, [vacancy]);

	return {
		...context,
		vacancy,
		valid,
	};
};

export const useCurrentVacancyFormStep = () => {
	const { step } = useVacancyForm();
	return step.current;
};
