import React from "react";
import { useTranslation } from "react-i18next";
import { formatPrice } from "src/lib/formatters";

interface PaymentBalanceItemProps {
	type: "reserved" | "coming" | "paid";
	value: number;
	isLast?: boolean;
	isFirst?: boolean;
}

const icons = {
	reserved: "fa-lock",
	coming: "fa-paper-plane",
	paid: "fa-credit-card",
};

const PaymentBalanceItem = ({
	type,
	value,
	isLast,
	isFirst,
}: PaymentBalanceItemProps) => {
	const { t } = useTranslation("elements", {
		keyPrefix: "payment-balance",
	});
	const labels = {
		reserved: t("status.reserved"),
		coming: t("status.coming"),
		paid: t("status.paid"),
	};

	const label = labels[type];
	const renderLine = (opacity = 100) => {
		return (
			<div className={`h-3 bg-accent w-[1px] opacity-${opacity}`}></div>
		);
	};
	return (
		<div className="flex items-center gap-4">
			<div className="flex flex-col items-center">
				{renderLine(isFirst ? 0 : 100)}
				<div
					className={`w-10 h-10 flex border-2 border-white dark:border-dark-accent justify-center items-center rounded-full ${
						type === "paid"
							? "bg-primary text-white"
							: "bg-accent text-background-foreground"
					}`}
				>
					<i
						className={`fas ${icons[type]} text-[14px] leading-2`}
					></i>
				</div>
				{renderLine(isLast ? 0 : 100)}
			</div>
			<div className={`flex-1 ${type === "paid" && "text-primary"}`}>
				<strong className="leading-4 block">
					{formatPrice(value)}
				</strong>
				<small>{label}</small>
			</div>
		</div>
	);
};

export default PaymentBalanceItem;
