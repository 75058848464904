import React from "react";
import { useTranslation } from "react-i18next";
import { ApiOfferRegistration } from "src/api/types";
import FlatList from "src/components/FlatList";
import OfferRegistrationCompanyCard from "src/components/offer/cards/OfferRegistrationCompanyCard";
import useTenant from "src/hooks/api/services/tenants/useTenant";
import useGet from "src/hooks/rest/useGet";

interface OfferRegistrationCompanyListProps {
	query?: string;
}

const OfferRegistrationCompanyList = ({
	query,
}: OfferRegistrationCompanyListProps) => {
	const { t } = useTranslation("offer", {
		keyPrefix: "offer-registration-company-list",
	});
	const { tenant } = useTenant();

	const [{ data, hasMore }, loading, { fetchNext }] = useGet(
		`/company/offer-registrations?with[]=offer&with[]=user&${query || ""}`,
		{ data: [] },
		true
	);

	if (loading) {
		return <Loading />;
	}

	return (
		<div className="flex flex-col">
			<FlatList
				gap={16}
				empty={{
					title: t("empty.title"),
					text: t("empty.description"),
					to: tenant?.urls?.public + "/talentenbord",
				}}
				onNext={async () => await fetchNext()}
				{...{ data, hasMore }}
				renderItem={(registration: ApiOfferRegistration) => (
					<OfferRegistrationCompanyCard
						{...{ registration }}
						key={`offer-registration-${registration.id}`}
					/>
				)}
			/>
		</div>
	);
};

const Loading = () => (
	<div className="flex flex-col gap-4">
		<OfferRegistrationCompanyCard.Loading />
		<OfferRegistrationCompanyCard.Loading />
		<OfferRegistrationCompanyCard.Loading />
		<OfferRegistrationCompanyCard.Loading />
		<OfferRegistrationCompanyCard.Loading />
	</div>
);

OfferRegistrationCompanyList.Loading = Loading;

export default OfferRegistrationCompanyList;
