import { useState } from "react";
import useApi from "src/hooks/api/utils/useApi";

const TYPE_MAP = {
	COMPANY: "COMPANIES",
	USER: "USERS",
};

type Options = {
	type: keyof typeof TYPE_MAP;
};

function useSettings(options: Options) {
	const { state, actions, api } = useApi(
		{ id: `SETTINGS_${options.type}` },
		{
			baseUrl: `/settings`,
			query: {
				type: TYPE_MAP[options.type],
			},
		}
	);
	const [loading, setLoading] = useState<string[]>([]);

	const update = async (name: string, value: any) => {
		setLoading((prev) => [...prev, name]);
		try {
			const {
				data: { data },
			} = await api.patch("/", {
				type: TYPE_MAP[options.type],
				name,
				value,
			});
			actions.set({
				data,
			});
			setLoading((prev) => prev.filter((item) => item !== name));
		} catch (error) {
			setLoading((prev) => prev.filter((item) => item !== name));
			throw error;
		}
	};

	return {
		settings: state?.data || {},
		status: state.status,
		actions: {
			isLoading: (name: string) => loading.includes(name),
			get: actions.get,
			update,
		},
	};
}

export default useSettings;
