import Loader from "src/components/Loader";
import TimerLabel from "src/components/time-tracker/TimerLabel";
import TimerOriginTooltip from "src/components/time-tracker/TimerOriginTooltip";
import useTimeTracking, {
	TimerFormat,
} from "src/hooks/api/services/useTimeTracking";
import { cn } from "src/lib/utils";

interface TimerProps {
	size?: keyof typeof sizes;
	format?: TimerFormat;
	data?: {
		task_id: number;
	};
	options?: {
		showOriginTooltip?: boolean;
	};
}

const Timer = ({
	size: _size = "default",
	format = "hh:mm:ss",
	data,
	options,
}: TimerProps) => {
	const { time, status, actions } = useTimeTracking();
	const loading = status === "toggling";
	const placeholder = format
		.replace("hh", "00")
		.replace("mm", "00")
		.replace("ss", "00");
	const isDifferent =
		data && time?.id && data.task_id !== time?.links?.task_id;
	// const isPlaying = time && time.links?.task === data?.task_id;
	const isPlaying = !isDifferent && time.id && !time.ended_at;
	const size = sizes[_size];

	const handleTogglePlay = (event: any) => {
		event.preventDefault();
		event.stopPropagation();
		if ((!time?.id && !data?.task_id) || isDifferent) return;
		const taskId = time?.links?.task_id || data?.task_id;
		if (!taskId) return;

		actions.toggle(taskId).then(() => {
			actions.list({
				with: ["creator"],
				task_id: taskId,
			});
		});
	};

	return (
		<TimerOriginTooltip
			disabled={!options?.showOriginTooltip && !isDifferent}
		>
			<div
				onClick={handleTogglePlay}
				className={cn(
					"border flex w-max items-center cursor-pointer transition-all group/timer relative",
					isDifferent && "opacity-40 cursor-not-allowed",
					size.wrapper,
					!isPlaying
						? "border-border hover:bg-accent"
						: "border-dark hover:bg-dark-accent"
				)}
			>
				<div
					className={cn(
						"flex justify-center items-center",
						size.button,
						!isPlaying
							? "bg-accent text-background-foreground group-hover/timer:bg-background"
							: "bg-dark text-white"
					)}
				>
					{loading ? (
						<Loader />
					) : (
						<i
							className={cn(
								"fas",
								isPlaying ? "fa-stop" : "fa-play",
								size.icon
							)}
						></i>
					)}
				</div>
				<span
					className={cn(
						size.label,
						isPlaying && "group-hover/timer:text-white"
					)}
				>
					{isDifferent ? placeholder : <TimerLabel {...{ format }} />}
				</span>
			</div>
		</TimerOriginTooltip>
	);
};

const sizes = {
	large: {
		wrapper: "p-1 rounded-md gap-2",
		button: "w-8 h-8 rounded-md",
		icon: "text-[12px]",
		label: "text-[18px] leading-full pr-1",
	},
	default: {
		wrapper: "py-1 px-[5px] rounded-md gap-2 h-10",
		button: "w-[28px] h-[28px] rounded-md",
		icon: "text-[10px]",
		label: "text-[14px] leading-full pr-[2px]",
	},
	small: {
		wrapper: "p-[2px] rounded-sm gap-1",
		button: "w-5 h-5 rounded-sm",
		icon: "text-[8px]",
		label: "text-[12px] leading-[10px] pr-[2px]",
	},
};

export default Timer;
