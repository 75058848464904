import { ComponentProps, useMemo } from "react";
import { useTranslation } from "react-i18next";
import Skeleton from "src/components/Skeleton";
import {
	ValueVariant,
	useTotalCard,
} from "src/components/statistics/total-card/Provider";
import Text from "src/components/ui/text";
import { formatPrice, kFormatter } from "src/lib/formatters";
import { cn } from "src/lib/utils";

export const Label = ({ className, ...rest }: ComponentProps<"p">) => {
	const { label, loading } = useTotalCard();
	if (!label) return null;

	if (loading) {
		return <Skeleton className="h-3 w-1/2" />;
	}

	return (
		<Text.Eyebrow {...rest} className={cn("leading-full", className)}>
			{label}
		</Text.Eyebrow>
	);
};

interface ValueProps {
	variant?: ValueVariant;
}

export const Value = ({
	className,
	variant,
	...rest
}: ValueProps & ComponentProps<"p">) => {
	let { value: _value, loading } = useTotalCard();
	_value = typeof _value !== "number" ? _value?.current : (_value as number);
	const { t } = useTranslation("common");
	const value: any = useMemo(() => {
		switch (variant) {
			case "hours":
				return t("format.trailing-hour", {
					value: _value,
				});
			case "k":
				return kFormatter(_value as number, "default");
			case "price":
				return formatPrice(_value as number);
			default:
				return _value;
		}
	}, [variant, t, _value]);

	if (loading) {
		return <Skeleton className="h-9 w-1/4" />;
	}

	return (
		<h2
			{...rest}
			className={cn("text-[40px] font-bold leading-full", className)}
		>
			{value}
		</h2>
	);
};

export const Indicator = ({ className, ...rest }: ComponentProps<"div">) => {
	const { value, loading } = useTotalCard();
	if (typeof value === "number") return null;

	const { current, previous } = value;
	let rate = Math.round((current / previous || 1) * 100 - 100);

	if (previous === 0 && current) {
		rate = current * 100;
	}

	if (current === 0 && previous) {
		rate = -(previous * 100);
	}

	if (loading) {
		return <Skeleton className="h-6 w-16" />;
	}

	if (rate === 0) return null;

	return (
		<div
			{...rest}
			className={cn(
				"leading-full flex items-center gap-2 rounded-md py-1.5 px-2",
				rate > 0 && "text-green bg-green-200",
				rate < 0 && "text-red bg-red-200",
				rate === 0 && "bg-accent text-background-foreground",
				className
			)}
		>
			{rate === 0 ? (
				<div className="h-[2px] rounded-md w-2.5 bg-dark"></div>
			) : (
				<i
					className={cn(
						"far w-2.5 text-[12px]",
						rate > 0 && "fa-chart-line",
						rate < 0 && "fa-chart-line-down"
					)}
				/>
			)}
			<span className="">{Math.abs(rate)}%</span>
		</div>
	);
};
