import React from "react";
import useGet from "src/hooks/rest/useGet";
import useSocketListener from "src/hooks/sockets/useSocketListener";

type ApiMeta = {
	total: number;
	current_page: number;
	from: number;
	last_page: number;
	per_page: number;
	to: number;
};

interface ApiCallProps {
	query: string;
	children?: (
		data: any,
		loading: boolean,
		meta?: ApiMeta,
		refetch?: () => void
	) => any;
	listener?: string;
}

const ApiCall = ({ children, query, listener }: ApiCallProps) => {
	const [{ data, meta }, loading, { fetchGet: refetch }] = useGet(query);
	useSocketListener(listener || null, () => {
		refetch(false);
	});
	return children ? children(data, loading, meta, refetch) : <></>;
};

export default ApiCall;
