import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { updateGlobal } from "src/redux/global";

export default function useGlobal() {
	const global = useSelector((state: any) => state.global.data);
	const isFetched = useSelector((state: any) => state.global.isFetched);
	const dispatch = useDispatch();

	const fetchGlobal = () => {
		axios.get(`/site/data`).then(({ data }) => {
			dispatch(updateGlobal(data));
		});
	};

	return { global, isFetched, fetchGlobal };
}
