import React from "react";
import Indicator from "src/components/layout/Indicator";
import useVariant, { VariantTypes } from "src/hooks/useVariant";
import { lightenDarkenColor } from "src/lib/colors";

interface IconButtonProps {
	onClick?: () => void;
	variant: VariantTypes;
	icon: string;
	loading: boolean;
	customColor?: string;
	indicator?: string | number;
}

const IconButton = ({
	onClick,
	variant,
	icon,
	loading,
	customColor,
	indicator,
}: IconButtonProps) => {
	const classes = ["text-[16px] w-8 h-8 rounded-md relative"];
	const variantClassName = useVariant(variant);
	const style = {} as any;

	if (!customColor) {
		classes.push(variantClassName);
	}

	if (customColor) {
		style.color = customColor;
		style.backgroundColor = lightenDarkenColor(customColor, 99);
	}

	return (
		<button className={classes.join(" ")} {...{ onClick, style }}>
			{indicator !== undefined && (
				<Indicator className="absolute -top-2 -right-2">
					{indicator}
				</Indicator>
			)}
			{loading ? (
				<span className="base-loader"></span>
			) : (
				<i className={icon}></i>
			)}
		</button>
	);
};

IconButton.defaultProps = {
	variant: "gray",
	loading: false,
};

export default IconButton;
