const credit = {
	title: "Recharge",
	"price-form": {
		required: "Required",
		title: "Recharge your account",
		description:
			"You're about to recharge your account, but why? To offer your task, we ask for a placement fee of {{fee}}% of the task budget. You can recharge an amount once and then easily place tasks.",
		subtotal: "Subtotal",
		"service-fee": "Partner fee",
		vat: "VAT",
		total: "Total",
		label: "How much do you want to recharge",
		cancel: "Cancel",
		checkout: "Checkout",
	},
	checkout: {
		failed: {
			title: "Your payment could not be created",
			button: "Try again",
		},
		title: "how do you want to pay",
		ideal: "Pay with iDeal",
		invoice: "Pay by invoice",
	},
};

export default credit;
