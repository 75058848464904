import { ClassValue } from "clsx";
import moment, { Moment } from "moment";
import { createContext, useContext, useRef, useState } from "react";
import { useCalendar } from "src/components/Calendar";
import {
	BackgroundGrid,
	CurrentTimeLine,
	Grid,
} from "src/components/Calendar/week-time/Grid";
import WeekTimeItem from "src/components/Calendar/week-time/WeekTimeItem";
import Container from "src/components/layout/Container";
import { getDatesBetweenDates, isToday } from "src/lib/dates";
import { ucFirst } from "src/lib/formatters";
import { cn } from "src/lib/utils";

const WeekTimeContext = createContext<{ dates: Moment[] }>({ dates: [] });
export function useWeekTimeContext() {
	const context = useContext(WeekTimeContext);
	if (!context) {
		throw new Error(
			"useWeekTimeContext can only be used as child of WeekTime component"
		);
	}
	return context;
}

export interface WeekTimeProps {
	value?: {
		week: number;
		year: number;
	};
	children?: any;
	column?: {
		head?: (date: Moment) => JSX.Element;
		footer?: (date: Moment) => JSX.Element;
		className?: string;
	};
	className?: ClassValue;
	id?: string;
}

const WeekTime = ({ children, value, className, id }: WeekTimeProps) => {
	const [isAutoScrolled, setIsAutoScrolled] = useState(false);
	const ref = useRef() as any;
	const { selected, layout } = useCalendar();
	const date = value
		? moment().week(value.week).year(value.year)
		: selected.value;

	const start = moment(date).startOf("week");
	const end = moment(date).endOf("week");
	const dates = getDatesBetweenDates(
		start,
		layout === "full-week" ? end : moment(start).day(5)
	);

	const onCurrentTimeLineMounted = (height: number) => {
		const threshold = 100;
		const top = height > threshold ? height - threshold : 0;
		if (ref?.current && !isAutoScrolled) {
			ref.current.scrollTo({
				top,
				behavior: "smooth",
			});
			setIsAutoScrolled(true);
		}
	};

	return (
		<WeekTimeContext.Provider value={{ dates }}>
			<div className="flex ml-16">
				{dates.map((date) => (
					<div
						className={cn(
							"flex-1 py-1 px-2 flex gap-2 opacity-70",
							isToday(date) && "opacity-100"
						)}
						key={`week-time-${date}`}
					>
						<span>{ucFirst(date.format("ddd"))}</span>
						<span>{date.format("DD")}</span>
					</div>
				))}
			</div>
			<Container
				{...{ id, ref }}
				variant="full-scrollable"
				className={cn("flex flex-row flex-1 relative", className)}
			>
				<div className="flex flex-col flex-1">
					<BackgroundGrid days={dates.length} />
					<div className="absolute inset-0 left-16 z-20">
						<Grid days={dates.length}>
							<CurrentTimeLine
								onMounted={onCurrentTimeLineMounted}
							/>
							{children}
						</Grid>
					</div>
				</div>
			</Container>
		</WeekTimeContext.Provider>
	);
};

WeekTime.Item = WeekTimeItem;

export default WeekTime;
