import { ApiTaskKanbanStatus } from "src/api/types";
import Select, {
	SelectSettings,
	SelectTheme,
} from "src/components/field/fields/Select";
import { WrapperProps } from "src/components/field/utils/Wrapper";
import TaskStatus from "src/components/tasks/TaskStatus";
import useTaskStatuses from "src/hooks/api/tasks/useTaskStatuses";

interface StatusProps {
	wrapper?: WrapperProps;
	value?: ApiTaskKanbanStatus;
	onChange: (value?: string) => void;
	theme?: SelectTheme;
	disabled?: boolean;
	settings?: SelectSettings;
}

const Status = ({
	wrapper,
	value,
	onChange,
	theme,
	disabled,
	settings,
}: StatusProps) => {
	const { statuses } = useTaskStatuses();
	return (
		<Select
			{...{ wrapper, disabled, settings }}
			onChange={(value) => {
				onChange(value);
			}}
			value={value as any}
			options={statuses.map((status) => ({
				value: status,
				label: <TaskStatus size="regular" {...{ status }} />,
			}))}
			theme={{
				...(theme || {}),
				item: {
					className: "radix-highlighted:bg-accent",
					...(theme?.item || {}),
				},
				value: {
					renderValue: (value) => {
						return <TaskStatus size="regular" status={value} />;
					},
				},
			}}
		/>
	);
};

export default Status;
