import { useEffect } from "react";
import Select from "src/components/field/fields/Select";
import { SelectItem } from "src/components/field/fields/Users";
import { WrapperProps } from "src/components/field/utils/Wrapper";
import useConnections from "src/hooks/api/services/users/useConnections";

interface ConnectionProps {
	isClearable?: boolean;
	wrapper?: WrapperProps;
	value?: number | string;
	onChange: (value?: number | string) => void;
	placeholder?: string;
	className?: string;
	disabled?: boolean;
}

const Connection = ({
	isClearable,
	wrapper,
	value,
	onChange,
	placeholder,
	className,
	disabled,
}: ConnectionProps) => {
	const { connections, status, search, actions } = useConnections();

	useEffect(() => {
		if (!connections?.length) {
			actions.list({
				with: ["values"],
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	return (
		<Select
			{...{ wrapper, className, disabled, placeholder }}
			settings={{
				isClearable,
			}}
			value={value ? value.toString() : undefined}
			onChange={(value) => onChange(value ? value : undefined)}
			search={{
				onChange: actions.search,
				value: search,
				loading: status !== "idle",
				autoFocus: true,
			}}
			options={connections.map((item: any) => ({
				value: item.id.toString(),
				label: (
					<SelectItem
						data={{ name: item?.name, avatar: item.avatar }}
						variant="list"
					/>
				),
			}))}
			theme={{
				value: {
					renderValue: (value) => {
						const item: any = connections.find(
							(u) =>
								value?.includes(u.id.toString()) ||
								value?.includes(u.id)
						);
						return (
							<SelectItem
								variant="row"
								data={{
									name: item?.name,
									avatar: item?.avatar,
								}}
							/>
						);
					},
				},
			}}
		/>
	);
};

export default Connection;
