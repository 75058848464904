import moment from "moment";
import { cn } from "src/lib/utils";

export const getDeadlineColor = (date: string) => {
	const m = moment(date);
	const isPast = m.isBefore(moment().subtract(1, "day"));
	const inWeek = !isPast && m.isBefore(moment().add(1, "week"));
	if (isPast) {
		return "text-red";
	}
	if (inWeek) {
		return "text-background-foreground-orange";
	}
	return "text-background-foreground";
};

interface TaskDeadlineTextProps {
	date: string;
}

const TaskDeadlineText = ({ date }: TaskDeadlineTextProps) => {
	const color = getDeadlineColor(date);
	return (
		<small className={cn(color)}>{moment(date).format("DD-MM-YYYY")}</small>
	);
};

export default TaskDeadlineText;
