import React, { useState } from "react";
import { ApiRegistrationTimesheet } from "src/api/types";
import Alert from "src/components/Alert";
import Button from "src/components/Button";
import Input from "src/components/form/Input";
import TimesheetDescription from "src/components/timesheet/TimesheetDescription";
import withTranslation, {
	Translation,
} from "src/components/hoc/withTranslation";
import Loader from "src/components/Loader";
import useTimesheet from "src/hooks/api/services/timesheets/useTimesheet";
import useAuth from "src/hooks/selectors/useAuth";
import useTenant from "src/hooks/api/services/tenants/useTenant";

interface TimesheetFormProps extends Translation {
	timesheet: ApiRegistrationTimesheet;
	variant: "horizontal" | "vertical";
	options: {
		autosave: boolean;
	};
	onSubmit?: (status: "error" | "success") => void;
}

const TimesheetForm = ({
	t,
	timesheet: _timesheet,
	variant,
	options,
	onSubmit: _onSubmit,
}: TimesheetFormProps) => {
	const auth = useAuth();
	const { tenant } = useTenant();
	const { sheet, actions, status } = useTimesheet({
		timesheet: _timesheet,
		autosave: options.autosave,
	});
	const [saveStatus, setSaveStatus] = useState(
		sheet?.id ? "success" : "idle"
	);
	const isValid = sheet?.start && sheet?.end;

	const styles = {
		form: {
			horizontal: "flex items-center gap-4",
			vertical: "flex flex-col gap-4",
		},
	};

	const onSubmit = (e?: any) => {
		if (e) e.preventDefault();
		setSaveStatus("idle");
		if (options.autosave || !isValid) {
			return;
		}
		actions
			.save(sheet as any)
			.then(() => {
				setSaveStatus("success");
				if (_onSubmit) {
					_onSubmit("success");
				}
			})
			.catch(() => {
				setSaveStatus("error");
				if (_onSubmit) {
					_onSubmit("error");
				}
			});
	};

	return (
		<div className="flex flex-col gap-2">
			{saveStatus !== "idle" &&
				variant === "vertical" &&
				auth.type === "student" && (
					<Alert type={saveStatus as any}>
						{t(`status.${saveStatus}`, {
							email: `support@${tenant?.urls?.domain}`,
						})}
					</Alert>
				)}
			<form {...{ onSubmit }} className={styles.form[variant]}>
				<Input
					value={sheet?.start}
					onChange={actions.change}
					type="time"
					noMarginBottom
					name="start"
					label={variant === "vertical" ? t("start.label") : ""}
				/>

				<Input
					value={sheet?.end}
					onChange={actions.change}
					type="time"
					noMarginBottom
					name="end"
					label={variant === "vertical" ? t("end.label") : ""}
				/>
				<Input
					value={sheet?.break.toString()}
					onChange={(key, value) =>
						actions.change(key, Number(value))
					}
					type="number"
					noMarginBottom
					name="break"
					step={0.25}
					label={variant === "vertical" ? t("break.label") : ""}
				/>

				{variant === "vertical" && (
					<>
						{sheet && <TimesheetDescription timesheet={sheet} />}

						<Button
							disabled={
								!isValid ||
								!sheet ||
								sheet?.status === "approved" ||
								sheet?.total < 0
							}
							loading={status !== "idle"}
							submit
						>
							{sheet?.id ? t("buttons.edit") : t("buttons.save")}
						</Button>
					</>
				)}
			</form>
			{status === "saving" && variant === "horizontal" && <Loader />}
		</div>
	);
};

TimesheetForm.locale = {
	nl: {
		start: {
			label: "Starttijd",
		},
		end: {
			label: "Eindtijd",
		},
		break: {
			label: "Pauze",
		},
		buttons: {
			save: "Opslaan",
			edit: "Aanpassen",
		},
		status: {
			success:
				"De opdrachtgever heeft de uren succesvol ontvangen. Zodra de opdrachtgever de uren goedkeurt en de betaling compleet maakt ontvang je het geld op je rekening",
			error: "Oeps er lijkt iets verkeerd te zijn gegaan, probeer het later nog een keer of neem contact op met {{email}}",
		},
	},
	en: {
		start: {
			label: "Start time",
		},
		end: {
			label: "Finish time",
		},
		break: {
			label: "Break",
		},
		buttons: {
			save: "Submit",
			edit: "Edit",
		},
		status: {
			success:
				"The client has successfully received the hours. As soon as the client approves the hours and completes the payment, you will receive the money on your account",
			error: "Oops something seems to have gone wrong, please try again later or contact {{email}}",
		},
	},
};

export default withTranslation(TimesheetForm);
