import useGet from "src/hooks/rest/useGet";

function useEnvelope(id: string | number) {
	const [{ data: envelope }, loading, { fetchGet }] = useGet(
		`/envelopes/${id}`
	);

	return {
		envelope,
		loading,
		fetchEnvelope: fetchGet,
	};
}
export default useEnvelope;
