import { useTranslation } from "react-i18next";
import TagsInput from "src/components/form/TagsInput/TagsInput";
import OfferForm from "src/components/offer/forms/OfferForm";
import useCreateOffer from "src/hooks/api/useCreateOffer";
import useOfferScheme from "src/hooks/schemes/useOfferScheme";

const OfferTagsForm = () => {
	const { t } = useTranslation("offer", {
		keyPrefix: "form",
	});
	const { setCurrent, offer } = useCreateOffer();
	const scheme = useOfferScheme();

	return (
		<OfferForm
			onSubmit={async (e) => {
				setCurrent(4);
				return;
			}}
			value={{
				tags: offer?.tags,
			}}
			schema={() => ({
				tags: scheme.tags,
			})}
			defaultValid
		>
			{(getInputProps) => {
				const props = getInputProps("tags");
				return (
					<div>
						<TagsInput
							label={t("skills")}
							placeholder={t("skills-placeholder")}
							value={props.value || []}
							onChange={(value) => {
								props.handleChange({
									target: {
										name: "tags",
										value,
									},
								});
							}}
						/>
					</div>
				);
			}}
		</OfferForm>
	);
};

export default OfferTagsForm;
