import { useParams } from "react-router-dom";
import withTranslation, {
	Translation,
} from "src/components/hoc/withTranslation";
import EditExperienceForm from "src/components/resume/elements/experience-form/EditExperienceForm";
import useExperiences from "src/hooks/api/services/resume/useExperiences";
import useModal from "src/hooks/useModal";

const ExperienceFormModal = ({ t }: Translation) => {
	const { type, id } = useParams() as any;
	const modal = useModal({
		title: t(`${type}.title`),
	});
	const { experiences } = useExperiences({ type });
	const experience = (experiences || []).find((item) => item.id === id);

	if (!experience) {
		return (
			<div className="flex flex-col">
				<strong>{t(`${type}.not-found`)}</strong>
				<p>{t(`${type}.not-found-description`)}</p>
			</div>
		);
	}

	return (
		<EditExperienceForm
			{...{ type, experience }}
			onSubmit={() => modal.onClose()}
			onDelete={() => modal.onClose()}
		/>
	);
};

ExperienceFormModal.locale = {
	nl: {
		job: {
			title: "Werk",
			"not-found": {
				title: "Werkervaring niet gevonden",
				description:
					"De werkervaring die je wilt bewerken bestaat niet meer",
			},
		},
		education: {
			title: "Opleiding",
			"not-found": {
				title: "Opleiding niet gevonden",
				description:
					"De opleiding die je wilt bewerken bestaat niet meer",
			},
		},
	},
	en: {
		job: {
			title: "Work",
			"not-found": {
				title: "Work experience not found",
				description:
					"The work experience you want to edit no longer exists",
			},
		},
		education: {
			title: "Education",
			"not-found": {
				title: "Education not found",
				description: "The education you want to edit no longer exists",
			},
		},
	},
};

export default withTranslation(ExperienceFormModal);
