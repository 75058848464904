import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ApiTaskKanban, ApiTaskKanbanStatus } from "src/api/types";
import Link from "src/components/Link";
import Loader from "src/components/Loader";
import Kanban from "src/components/kanban/Kanban";
import TaskStatus from "src/components/tasks/TaskStatus";
import TaskKanbanCard from "src/components/tasks/kanban/TaskKanbanCard";
import TaskNewKanbanCard from "src/components/tasks/kanban/TaskNewKanbanCard";
import useBoards from "src/hooks/api/services/tasks/useBoards";
import useTenant from "src/hooks/api/services/tenants/useTenant";
import useFillTask from "src/hooks/api/tasks/useFillTask";
import useTaskForm from "src/hooks/api/tasks/useTaskForm";
import useTasksFilter from "src/hooks/api/tasks/useTasksFilter";
import useTasksKanban from "src/hooks/api/tasks/useTasksKanban";
import useBackUrl from "src/hooks/useBackUrl";
import { useUpdateEffectDebounce } from "src/hooks/useDebounce";
import useNavigateModal from "src/hooks/useNavigateModal";
import { cn } from "src/lib/utils";

interface TasksKanbanColumnProps {
	status: ApiTaskKanbanStatus;
}

const TasksKanbanColumn = ({ status }: TasksKanbanColumnProps) => {
	const { state, actions } = useTasksKanban();
	const back = useBackUrl();
	const { board, project } = useParams();
	const navigateModal = useNavigateModal();
	const navigate = useNavigate();
	const column = state?.columns[status];
	const {
		tenant: { modules },
	} = useTenant();
	const hasMore =
		column?.meta && column.meta.current_page < column.meta.last_page;
	const { getFillableData } = useFillTask();
	const { filter } = useTasksFilter();
	const form = useTaskForm();
	const total = column?.meta?.total || 0;
	const { boards } = useBoards();
	const activeBoard = (boards || []).find((item) => item.slug === board);
	useUpdateEffectDebounce(filter, 200, () => {
		actions.getColumn(
			status,
			{
				page: 1,
			},
			"reset"
		);
	});
	const hideCompleted = filter.hide_completed && status === "COMPLETED";

	const handleDescriptionBlur = (data: ApiTaskKanban) => {
		if (!data.title) return;
		const fillableData = getFillableData();
		Object.keys(fillableData).forEach(
			(key) => fillableData[key] === undefined && delete fillableData[key]
		);
		const task = {
			...data,
			// disable_socket: true,
			variant: "kanban",
			...fillableData,
		};

		form.actions
			.create("INTERNAL", {
				data: task,
				disableRedirect: true,
			})
			.then((task) => {
				form.actions.indexSkills(task.id);
				if (data._id) {
					actions.updateNewItem(data._id, task);
				}
			});
	};

	useEffect(() => {
		actions.getColumn(status);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<Kanban.Column
			id={status}
			key={status}
			className={cn(
				"w-max max-w-full min-w-max",
				status === "BACKLOG" ? "border-l-0" : undefined
			)}
			head={
				<div
					className={cn(
						"flex items-center justify-between",
						state.status === "blocked" &&
							"opacity-50 cursor-not-allowed"
					)}
				>
					<div className="flex items-center gap-2">
						<TaskStatus size="regular" {...{ status }} />
						{column.status === "loading" &&
							state.status !== "blocked" && <Loader />}
						{column.status !== "loading" &&
							total > 0 &&
							!hideCompleted && (
								<span className="opacity-30">{total}</span>
							)}
						{hideCompleted && (
							<i className="far fa-eye-slash opacity-30"></i>
						)}
						{activeBoard && status === "COMPLETED" && (
							<Link
								to={`/tasks/boards/${activeBoard.slug}/overview?${back}`}
							>
								<i className="far fa-external-link text-[14px] ml-2"></i>
							</Link>
						)}
					</div>
					<Link
						className="w-8 h-8 flex justify-center items-center hover:bg-dark-100 rounded-md"
						to={{
							modal: `./modals/tasks/create?status=${status}`,
						}}
					>
						<i className="far fa-plus text-[16px]"></i>
					</Link>
				</div>
			}
		>
			{state.status !== "blocked" &&
				(column?.tasks || [])?.map((task, index) => (
					<Kanban.Item
						options={{
							disabled:
								task.type !== "INTERNAL" || task.mode === "NEW",
						}}
						column={status}
						id={task._id || task.id || ""}
						key={`${status}-${task.id}-${index}`}
					>
						{task.mode === "NEW" ? (
							<TaskNewKanbanCard
								onComplete={(task) =>
									task._id &&
									actions.updateNewItem(task._id, task)
								}
								onCancel={(task) =>
									task._id &&
									actions.removeNewItem(task._id, status)
								}
								{...{ task }}
							/>
						) : (
							<TaskKanbanCard
								{...{ task }}
								head={
									modules.includes("extern-task") &&
									modules.includes("intern-task") && (
										<TaskKanbanCard.Type />
									)
								}
								actions={
									task.mode !== "NEW" && (
										<TaskKanbanCard.Actions />
									)
								}
								onClick={(e) => {
									if (task.mode === "NEW") return;
									e.stopPropagation();
									if (
										task.type === "INTERNAL" ||
										task.type === "TEMPLATE"
									) {
										navigateModal(
											board
												? `/tasks/boards/${board}/${task.hid}/show`
												: project
												? `/projects/${project}/tasks/${task.hid}/show`
												: `/tasks/${task.hid}/show`
										);
									} else {
										navigate(
											`/tasks/${task.hid}/show?${back}`
										);
									}
								}}
							>
								<TaskKanbanCard.Title
									showIcon
									onBlur={
										task.mode === "NEW" && task?._id
											? (value) =>
													handleDescriptionBlur({
														...task,
														title: value,
													})
											: undefined
									}
								/>
								<div className="flex flex-wrap gap-2 empty:hidden">
									<TaskKanbanCard.Tags />
								</div>
								<div className="flex flex-col">
									<div className="flex items-center gap-2 justify-between relative">
										{task.type === "INTERNAL" && (
											<TaskKanbanCard.Date />
										)}

										<TaskKanbanCard.Timer />
										<TaskKanbanCard.Registrations />
										<TaskKanbanCard.Assignee />
									</div>
									<TaskKanbanCard.ProjectLink />
								</div>
							</TaskKanbanCard>
						)}
					</Kanban.Item>
				))}
			<div className="flex items-center gap-2">
				<Kanban.AddButton
					onClick={() => actions.createEditableTask(status)}
				/>
				{hasMore && (
					<Kanban.LoadMore
						onClick={() =>
							actions.getColumn(status, {
								page: column.meta.current_page + 1,
							})
						}
						loading={column.status === "loading"}
					/>
				)}
			</div>
		</Kanban.Column>
	);
};

export default TasksKanbanColumn;
