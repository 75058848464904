import moment from "moment";
import { useNavigate } from "react-router-dom";
import Button from "src/components/Button";
import Card from "src/components/Card";
import Layout from "src/components/layout/Layout";
import useFetchProfile from "src/hooks/api/useFetchProfile";
import usePost from "src/hooks/rest/usePost";
import useAlert from "src/hooks/useAlert";
import { useTranslation } from "react-i18next";
import useRedirectToCustomerPortal from "src/hooks/useRedirectToCustomerPortal";
import useSubscription from "src/hooks/selectors/useSubscription";

export default function ResumePlanView() {
	const { t } = useTranslation("payment-view", {
		keyPrefix: "resume-plan-view",
	});
	const [sendAlert] = useAlert();
	const [resumeSubscription, loading] = usePost(
		"/auth/subscriptions/plans/resume"
	);
	const subscription = useSubscription();
	const navigate = useNavigate();
	const { isLoading } = useRedirectToCustomerPortal();
	const { loading: fetchingProfile, fetchProfile } = useFetchProfile();

	const handleResume = () => {
		resumeSubscription({})
			.then(() => {
				fetchProfile().then(() => {
					navigate(`/dashboard?resume=1`);
				});
			})
			.catch(() => {
				sendAlert({
					title: t("error.title"),
					text: t("error.description"),
				});
			});
	};

	return (
		<Layout
			loading={isLoading}
			className="dashboard-profile"
			title="Abonnement hervatten"
		>
			<Layout.BackButton to="/profile/billing/change" label={t("back")} />
			<div className="flex flex-col justify-center items-center flex-1">
				<Card className="bg-dark-1100">
					<div className="max-w-md">
						<h2 className="mb-6 text-center">{t("card.title")}</h2>
						<p className="mb-4 text-center">
							{t("card.description", {
								date: moment(subscription.ends_at).format(
									"DD-MM-YYYY"
								),
							})}{" "}
						</p>
						<div className="flex gap-4 justify-between items-center">
							<Button
								disabled={loading}
								to="/profile/billing/change"
								type="ghost"
							>
								{t("card.cancel")}
							</Button>
							<Button
								type="green"
								loading={loading || fetchingProfile}
								onClick={handleResume}
							>
								<i className="fas fa-check mr-4"></i>
								{t("card.resume")}
							</Button>
						</div>
					</div>
				</Card>
			</div>
		</Layout>
	);
}
