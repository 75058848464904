import { AnimatePresence, motion } from "framer-motion";
import React, { ComponentProps } from "react";
import useScrollPosition from "src/hooks/useScrollPosition";
import { cn } from "src/lib/utils";

interface ScrollPopupProps extends ComponentProps<"div"> {
	afterY: number;
	popup?: Omit<ComponentProps<"div">, "children">;
}

const ScrollPopup = ({ afterY, popup, ...rest }: ScrollPopupProps) => {
	const y = useScrollPosition();
	const animations = {
		initial: {
			bottom: -100,
		},
		animate: {
			bottom: 16,
		},
	};
	return (
		<>
			<div {...rest} />
			<AnimatePresence>
				{y >= afterY && (
					<motion.div
						initial={animations.initial}
						exit={animations.initial}
						animate={animations.animate}
						className="fixed bottom-4 z-[99] left-4 right-4 md:right-auto md:left-1/2 md:-translate-x-1/2"
					>
						<motion.div
							initial={{
								scale: 0.9,
							}}
							animate={{
								scale: 1,
							}}
							exit={{ scale: 0 }}
							{...(popup as any)}
							className={cn(
								"bg-background shadow p-4 rounded-md gap-4",
								popup?.className
							)}
						>
							{rest?.children as any}
						</motion.div>
					</motion.div>
				)}
			</AnimatePresence>
		</>
	);
};

export default ScrollPopup;
