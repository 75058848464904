import withTranslation, {
	Translation,
} from "src/components/hoc/withTranslation";
import InputComp from "src/components/form/Input";
import { useSkillSelector } from "src/components/form/skill-selector/Provider";

interface InputProps extends Translation {
	placeholder?: string;
	className?: string;
}

const Input = ({ t, placeholder, className }: InputProps) => {
	const { search, onSearch, disabled } = useSkillSelector();
	return (
		<InputComp
			name="search"
			value={search}
			{...{ className, disabled }}
			placeholder={placeholder || t("placeholder")}
			onChange={(_, value) => (!disabled ? onSearch(value) : null)}
		/>
	);
};

Input.locale = {
	nl: {
		placeholder: "Zoek op vaardigheden",
	},
	en: {
		placeholder: "Search skills",
	},
};

export default withTranslation(Input);
