import React from "react";
import Button from "src/components/Button";
import withTranslation, {
	Translation,
} from "src/components/hoc/withTranslation";
import useLimits from "src/hooks/api/services/auth/useLimits";
import useModules from "src/hooks/selectors/useModules";

const ExternTaskMiddleware = ({ t }: Translation) => {
	const { modules } = useModules();
	const { limits } = useLimits();

	if (!modules?.includes("extern-task")) {
		return (
			<div className="flex flex-col pt-16 justify-center py-8 max-w-md">
				<h2 className="mb-4">{t("extern-task.title")}</h2>
				<p className="mb-4">{t("extern-task.description")}</p>
				<div className="flex gap-4">
					<Button to="/profile/billing/change">
						{t("extern-task.change")}
					</Button>
					<Button type="gray" to="/tasks">
						{t("extern-task.to-tasks")}
					</Button>
				</div>
			</div>
		);
	}

	return (
		<div className="flex flex-col pt-16 justify-center py-8 max-w-md">
			<h2 className="mb-4">{t("exceeded.title")}</h2>
			<p className="mb-4">
				{t("exceeded.description", {
					limit: limits?.published_tasks?.total,
				})}
			</p>
			<div className="flex flex-wrap gap-4">
				<Button
					className="w-full md:w-auto"
					to="/profile/billing/change"
				>
					{t("exceeded.change")}
				</Button>
				<Button className="w-full md:w-auto" type="gray" to="/tasks">
					{t("exceeded.to-tasks")}
				</Button>
			</div>
		</div>
	);
};

ExternTaskMiddleware.locale = {
	nl: {
		"extern-task": {
			title: "Deze functie is helaas niet mogelijk",
			description:
				"Om taken aan {{global.students}} professionals uit te besteden heb je een Basic of Premium abonnement nodig.",
			change: "Abonnement wijzigen",
			"to-tasks": "Terug naar taken",
		},
		exceeded: {
			title: "Je kan geen opdrachten meer uitbesteden",
			description:
				"Om meer dan {{limit}} taken te kunnen uitbesteden dien je je abonnement te upgraden naar een Premium abonnement.",
			change: "Abonnement wijzigen",
			"to-tasks": "Terug naar taken",
		},
	},
	en: {
		"extern-task": {
			title: "This feature is not available",
			description:
				"To outsource tasks to more than {{global.students}} professionals you need a Premium subscription",
			change: "Change subscription",
			"to-tasks": "Back to tasks",
		},
		exceeded: {
			title: "You have exceeded the outsource limit",
			description:
				"To outsource more than {{limit}} tasks, a Premium subscription is needed",
			change: "Change subscription",
			"to-tasks": "Back to tasks",
		},
	},
};

export default withTranslation(ExternTaskMiddleware);
