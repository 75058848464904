import Button from "src/components/Button";
import withTranslation, {
	Translation,
} from "src/components/hoc/withTranslation";
import useAuth from "src/hooks/selectors/useAuth";

interface SubscriptionFeatureMiddlewareProps extends Translation {
	plan: "all" | "basic" | "premium";
}

const SubscriptionFeatureMiddleware = ({
	t,
	plan,
}: SubscriptionFeatureMiddlewareProps) => {
	const auth = useAuth();
	return (
		<div className="flex flex-1 flex-col gap-4 justify-center items-center text-center">
			<h2>{t("title")}</h2>
			<p
				className="text-center max-w-md"
				dangerouslySetInnerHTML={{
					__html: t(`description.${plan}`),
				}}
			></p>
			<Button
				className="mt-4"
				to={
					auth.has_subscription
						? "/profile/billing/change"
						: "/subscription"
				}
			>
				{auth.has_subscription
					? t("button.with-subscription")
					: t("button.without-subscription")}
			</Button>
		</div>
	);
};

SubscriptionFeatureMiddleware.locale = {
	nl: {
		title: "Upgrade je abonnement",
		description: {
			all: "Om deze functie te gebruiken heb je een <strong>Basic</strong> of <strong>Premium</strong> abonnement nodig",
			basic: "Om deze functie te gebruiken heb je een <strong>Basic</strong> abonnement nodig",
			premium:
				"Om deze functie te gebruiken heb je een <strong>Premium</strong> abonnement nodig",
		},
		button: {
			"with-subscription": "Upgrade abonnement",
			"without-subscription": "Abonnementen bekijken",
		},
	},
	en: {
		title: "Upgrade your subscription",
		description: {
			all: "To enable this feature you need a <strong>Basic</strong> or <strong>Premium</strong> subscription",
			basic: "To enable this feature you need a <strong>Basic</strong> subscription",
			premium:
				"To enable this feature you need a <strong>Premium</strong> subscription",
		},
		button: {
			"with-subscription": "Upgrade subscription",
			"without-subscription": "View subscriptions",
		},
	},
};

export default withTranslation(SubscriptionFeatureMiddleware);
