import DropdownButton from "src/components/DropdownButton";
import Draggable from "src/components/draggable";
import withTranslation, {
	Translation,
} from "src/components/hoc/withTranslation";
import Icon from "src/components/icon/Icon";
import UserTeamCardItem from "src/components/profile/team/UserTeamCardItem";
import TeamCard from "src/components/profile/team/team-card/TeamCard";
import useUsers from "src/hooks/api/services/users/useUsers";
import useSubscription from "src/hooks/selectors/useSubscription";
import useNavigateModal from "src/hooks/useNavigateModal";
import { cn } from "src/lib/utils";

interface UnknownTeamCardProps extends Translation {
	children?: any;
	droppable?: boolean;
	content?: {
		className?: string;
	};
}

const UnknownTeamCard = ({
	t,
	content,
	droppable,
	children,
}: UnknownTeamCardProps) => {
	const { users: _users } = useUsers();
	const subscription = useSubscription();
	const navigateModal = useNavigateModal();
	const Wrapper = droppable ? Draggable.Drop : "div";
	const dropId = `REMOVE_TEAM`;
	const users = _users.filter((user) => !(user?.teams || []).length);
	return (
		<TeamCard>
			<Wrapper id={dropId} className="flex flex-col gap-4">
				<div className="flex justify-between items-center gap-3">
					<TeamCard.Title
						icon={Icon.getFontAwesome("fa-empty-set", "far")}
					>
						{t("title")}
					</TeamCard.Title>
					{subscription?.id && (
						<DropdownButton
							menu={{
								position: "bottom-right",
							}}
							button={{
								tabIndex: "-1",
								children: <i className="far fa-ellipsis-h" />,
								type: "transparent",
								iconOnly: true,
								className: "hover:bg-accent p-0 w-8 h-8 ",
							}}
						>
							<DropdownButton.Item
								icon="far fa-user-plus"
								onClick={(close) => {
									navigateModal(`/profile/users/invite`);
									if (close) close();
								}}
							>
								{t("actions.invite-user")}
							</DropdownButton.Item>
						</DropdownButton>
					)}
				</div>
				{!!users.length && (
					<div
						className={cn(
							"bg-accent-background rounded-md flex gap-3 flex-wrap",
							content?.className
						)}
					>
						{users
							.sort(function (a, b) {
								if (a.full_name < b.full_name) {
									return -1;
								}
								if (a.full_name > b.full_name) {
									return 1;
								}
								return 0;
							})
							.map((user) => (
								<Draggable.Item
									id={user.id}
									currentDropId={dropId}
									key={`user-${user.hid}-unknown`}
								>
									<UserTeamCardItem {...{ user }} />
								</Draggable.Item>
							))}
					</div>
				)}
				{children}
			</Wrapper>
		</TeamCard>
	);
};

UnknownTeamCard.locale = {
	nl: {
		title: "Leden zonder team",
		actions: {
			"invite-user": "Gebruiker",
		},
	},
	en: {
		title: "Members without a team",
		actions: {
			"invite-user": "Invite user",
		},
	},
};

export default withTranslation(UnknownTeamCard);
