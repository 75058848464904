import { createContext, useContext } from "react";
import { ApiStatus } from "src/hooks/api/utils/useApi";
import { filterByUnique } from "src/lib/filters";

export interface ListContextProps {
	data: any[];
	selected: any[];
	setSelected: React.Dispatch<React.SetStateAction<any[]>>;
	status?: ApiStatus;
	columnCount?: number;
	showEmptyList?: boolean;
	groupBy?: (item: any) => string;
	keepListWhileLoading?: boolean;
	selectMaximum?: number;
}

export const ListContext = createContext<ListContextProps | undefined>(
	undefined
);

export function useList() {
	const props = useContext(ListContext);
	if (props === undefined) {
		throw new Error("useList must be used within a ListProvider");
	}

	return {
		...props,
		groups: props.groupBy
			? props.data.map(props.groupBy).filter(filterByUnique)
			: [],
		// isEmpty: props.status === "idle" && props.data.length === 0,
		isEmpty: props.data.length === 0,
	};
}
