import HoursDetailStudentView from "src/views/authenticated/hours/HoursDetailStudentView";
import HoursDetailCompanyView from "src/views/authenticated/hours/HoursDetailCompanyView";
import HoursView from "src/views/authenticated/hours/HoursView";
import useAuth from "src/hooks/selectors/useAuth";
import HoursProcessView from "src/views/authenticated/hours/process/HoursProcessView";
import HoursProcessResultView from "src/views/authenticated/hours/process/HoursProcessResultView";
import ProtectedRoute from "src/routes/middlewares/ProtectedRoute";
import { Route } from "react-router-dom";

const HoursRoutes = () => {
	const auth = useAuth();
	return [
		<Route
			element={
				<ProtectedRoute guard={["hour-validation"]}>
					<HoursView />
				</ProtectedRoute>
			}
			path="/hours"
			key="/hours"
		/>,
		<Route
			element={
				<ProtectedRoute
				// guard={["hour-validation"]}
				>
					{auth.type === "company" ? (
						<HoursDetailCompanyView />
					) : (
						<HoursDetailStudentView />
					)}
				</ProtectedRoute>
			}
			path="/hours/:id/show"
			key="/hours/:id/show"
		/>,
		<Route
			element={
				<ProtectedRoute guard={["hour-validation"]} type="company">
					<HoursProcessView />
				</ProtectedRoute>
			}
			path="/hours/:id/show/process"
			key="/hours/:id/show/process"
		/>,
		<Route
			element={
				<ProtectedRoute guard={["hour-validation"]} type="company">
					<HoursProcessResultView />
				</ProtectedRoute>
			}
			path="/hours/:id/show/process/result"
			key="/hours/:id/show/process/result"
		/>,
	];
};

export default HoursRoutes;
