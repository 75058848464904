import React, { useEffect, useRef } from "react";
import "./review.scss";
import { ApiReview } from "src/api/types";
import Card from "src/components/Card";
import Avatar from "src/components/Avatar";
import { NavLink } from "react-router-dom";
import Stars from "src/components/Stars";
import axios from "axios";
import useOnScreen from "src/hooks/useOnScreen";
import TitleCut from "src/components/TitleCut";
import ShareReview from "src/components/Review/ShareReview";
import useBackUrl from "src/hooks/useBackUrl";

type ReviewProps = {
	review: ApiReview;
	type?: string;
};

const Review = ({ review, type }: ReviewProps) => {
	const ref = useRef() as any;
	const backUrl = useBackUrl();
	const onScreen = useOnScreen(ref);
	let avatar = review.creator?.avatar;
	if (review.creator?.company) {
		avatar = review.creator.company.avatar;
	}

	useEffect(() => {
		if (
			review.can_read &&
			!review.is_read &&
			onScreen &&
			type !== "resume"
		) {
			axios.post(`/reviews/${review.id}/read`);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [review, onScreen]);

	if (type === "resume") {
		return (
			<div {...{ ref }} className="flex flex-col gap-4 py-4">
				<div className="flex flex-wrap gap-4 justify-between items-center">
					<div className="flex items-center gap-2">
						<Avatar
							size="regular"
							src={avatar}
							alt={review.creator?.full_name}
						/>
						<div className="flex flex-col">
							<strong>{review?.creator?.first_name}</strong>
							{review.creator?.company && (
								<p className="">
									{review.creator.company.name}
								</p>
							)}
						</div>
					</div>
					<Stars size="18px" rating={review.rating} />
				</div>
				<div className="flex flex-col gap-1">
					<p className="text-left">{review.description}</p>
					{review?.task?.title && (
						<TitleCut className="w-60">
							<small className="font-bold">
								{review.task.title}
							</small>
						</TitleCut>
					)}
				</div>
			</div>
		);
	}

	return (
		<Card
			className={`review relative review-default ${
				type && "review-" + type
			}`}
			{...{ ref }}
		>
			<div className="flex gap-4 flex-wrap items-end">
				<Avatar size="regular" src={review.creator?.avatar} />
				<div className="flex flex-col">
					<strong>{review.creator?.full_name}</strong>
					{review.creator?.company ? (
						<p className="company name">
							{review.creator.company.name}
						</p>
					) : (
						<NavLink
							className="name"
							to={`/view-profile/${review.creator?.hid}?${backUrl}`}
						>
							{review.creator?.full_name}
						</NavLink>
					)}
				</div>
				<div className="flex-1"></div>
				<Stars size="32" rating={review.rating} />
			</div>
			<div className="absolute right-4 top-4">
				<ShareReview {...{ review }} />
			</div>
			<p className="description mt-4 mb-0">{review.description}</p>
		</Card>
	);
};

export default Review;
