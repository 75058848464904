import { motion } from "framer-motion";
import { useLocation } from "react-router-dom";
import Card from "src/components/Card";
import AlertActions from "src/components/alerts/AlertActions";
import AlertList from "src/components/alerts/AlertList";
import withTranslation, {
	Translation,
} from "src/components/hoc/withTranslation";
import useAlert from "src/hooks/api/services/useAlert";
import useUpdateEffect from "src/hooks/useUpdateEffect";
import useWindowSize from "src/hooks/useWindowSize";

interface AlertPopupProps extends Translation {
	children?: any;
}

const AlertPopup = ({ t }: AlertPopupProps) => {
	const location = useLocation();
	const { isPhone } = useWindowSize();
	const { actions, filter } = useAlert();
	const initial = {
		opacity: 0,
		y: -20,
	};

	useUpdateEffect(() => {
		actions.setOpen(false);
	}, [location]);

	return (
		<motion.div
			{...{ initial }}
			exit={initial}
			animate={{
				opacity: 1,
				y: 0,
			}}
			className={`z-[999] ${
				isPhone ? "fixed left-0 right-0" : "absolute right-0"
			}`}
		>
			<div className={isPhone ? "container" : "w-full"}>
				<Card contentClassName=" p-0" className="mt-4">
					<div className="px-6 py-4 flex justify-between items-center gap-4">
						<strong>{t("title")}</strong>
						<i
							onClick={() => actions.setOpen(false)}
							className="far fa-times text-xl cursor-pointer text-placeholder"
						></i>
					</div>
					<div className="flex flex-col min-w-[370px] w-full md:w-[470px] max-w-full max-h-[70vh] overflow-auto hide-default-scrollbar">
						<AlertList />
					</div>
					{filter.state === "not-viewed" && <AlertActions />}
				</Card>
			</div>
		</motion.div>
	);
};

AlertPopup.locale = {
	nl: {
		title: "Notificaties",
	},
	en: {
		title: "Alerts",
	},
};

export default withTranslation(AlertPopup);
