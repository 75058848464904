const network = {
	"deny-task-invite": {
		confirm: {
			title: "Are you sure?",
			description: "Are you sure you want to reject this invitation?",
		},
	},
	"network-student-card": {
		"create-task": "Create",
		"view-profile": "View profile",
		"delete-connection": "Delete",
		confirm: {
			title: "Are you sure?",
			description: "You are about to remove your connection",
		},
	},
	"signup-task": {
		button: {
			label: "Sign up",
			"label-success": "You have signed up 🎉",
		},
		"alert-success":
			"Congratulations! Your registration has been received. 🎉",
		description:
			"Write below why you should do this task. For example, tell about your experiences and/or education.",
		"input-label": "Motivation",
	},
	"task-invite-card": {
		"per-hour": "/ hour",

		button: {
			"view-invite": "View invitation",
			registered: "You have signed up 🎉",
		},
	},
};

export default network;
