import useApi, { ApiStatus } from "src/hooks/api/utils/useApi";

type State = {
	searches: any[];
	detail: any;
	status: ApiStatus;
	actions: {
		set: (state: any) => any;
		search: (search: string) => Promise<any>;
		getDetails: (id: string, query?: any) => Promise<any>;
	};
};

function useCompanyInfoSearch(): State {
	const { state, actions } = useApi(
		{ id: "COMPANY_INFO" },
		{
			baseUrl: `/helpers/company-info`,
			query: {},
		}
	);

	const getSearch = async (search: string) => {
		return await actions.list({
			search,
		});
	};

	const getDetails = async (id: string, query?: any) => {
		return actions.get(id, query);
	};

	return {
		searches: state?.list,
		detail: state?.data,
		status: state.status,
		actions: {
			set: actions.set,
			search: getSearch,
			getDetails,
		},
	};
}

export default useCompanyInfoSearch;
