import { Route } from "react-router-dom";
import ProtectedRoute from "src/routes/middlewares/ProtectedRoute";
import BenefitsView from "src/views/authenticated/benefits/BenefitsView";
import BenefitUnlockModal from "src/views/authenticated/benefits/unlock/BenefitUnlockModal";

const BenefitRoutes = [
	<Route
		element={
			<ProtectedRoute>
				<BenefitsView />
			</ProtectedRoute>
		}
		path="/benefits"
		key="/benefits"
	/>,
];

export const BenefitModalRoutes = [
	<Route
		element={
			<ProtectedRoute
				variant="modal"
				modal={{
					backUrl: "/benefits",
					expanded: false,
					head: "floating",
					size: "default",
					className: "max-w-[520px]",
				}}
			>
				<BenefitUnlockModal />
			</ProtectedRoute>
		}
		path="/benefits/:id/unlock"
		key="/benefits/:id/unlock"
	/>,
];

export default BenefitRoutes;
