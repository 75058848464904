import moment from "moment";
import { ApiPaymentRequest } from "src/api/types";
import Avatar from "src/components/Avatar";
import Button from "src/components/Button";
import Link from "src/components/Link";
import CustomFormPreviewField from "src/components/custom-form/CustomFormPreviewField";
import withTranslation, {
	Translation,
} from "src/components/hoc/withTranslation";
import PaymentRequestStatus from "src/components/payment-request/elements/PaymentRequestStatus";
import PaymentRequestPriceField, {
	PaymentRequestPriceFieldTrigger,
} from "src/components/payment-request/payment-request-form/PaymentRequestPriceField";
import PaymentRequestDateCell from "src/components/payment-request/visualizations/PaymentRequestDateCell";
import {
	HoverCard,
	HoverCardContent,
	HoverCardPortal,
	HoverCardTrigger,
} from "src/components/ui/hover-card";
import List from "src/components/ui/list";
import useAuth from "src/hooks/selectors/useAuth";
import { formatPrice } from "src/lib/formatters";
import { cn } from "src/lib/utils";

interface PaymentRequestTableItemProps extends Translation {
	request: ApiPaymentRequest;
	onUpdate?: (value: any) => Promise<void>;
}

const PaymentRequestTableItem = ({
	t,
	request,
	onUpdate,
}: PaymentRequestTableItemProps) => {
	const auth = useAuth();
	const isPriceEditabled =
		auth.type === "company" &&
		["PENDING", "OPEN"].includes(request.status) &&
		request.type === "HOUR_RATE" &&
		onUpdate;
	const attachmentCount = Object.keys(request.forms || {}).filter(
		(key) => Object.values(request.forms[key])?.length > 0
	).length;

	const hasConnectionFields =
		Object.values(request?.connection_fields || {}).length > 0;

	return (
		<List.Row id={request.id}>
			{auth.type === "company" && (
				<List.Item className="md:pr-0 px-0 w-8">
					<div className="pl-2">
						{["PENDING", "OPEN"].includes(request.status) &&
							request.price !== null && <List.Select />}
					</div>
				</List.Item>
			)}
			<List.Item>
				<PaymentRequestStatus status={request.status} />
			</List.Item>
			<List.Item>
				{auth.type === "student" ? (
					<div className="flex gap-3 items-center">
						<Avatar src={request?.company?.avatar} />
						<div className="flex flex-col flex-1">
							{request?.company?.name}
						</div>
					</div>
				) : (
					<div className="flex">
						<HoverCard>
							<HoverCardTrigger className="w-max pr-2">
								<div className="flex gap-3 items-center w-max">
									<Avatar src={request?.receiver?.avatar} />
									<div className="flex flex-col flex-1">
										<span>
											{request?.receiver?.full_name}
										</span>
										{hasConnectionFields && (
											<span className="opacity-50">
												{t(
													"extra-information-on-hover"
												)}
											</span>
										)}
									</div>
								</div>
							</HoverCardTrigger>
							{hasConnectionFields && (
								<HoverCardPortal>
									<HoverCardContent
										side="right"
										align="center"
										sideOffset={8}
									>
										<div className="flex flex-col gap-3">
											{Object.values(
												request.connection_fields
											).map((field: any) => (
												<div
													key={`${request.id}-${field.label}`}
													className="flex gap-2 items-center justify-between"
												>
													<span className="opacity-70">
														{field.label}:
													</span>
													<CustomFormPreviewField
														{...{ field }}
													/>
												</div>
											))}
										</div>
									</HoverCardContent>
								</HoverCardPortal>
							)}
						</HoverCard>
					</div>
				)}
			</List.Item>
			<List.Item>
				<div className="flex flex-col">
					<Link
						to={{
							modal: `/payment-requests/${request.id}/edit`,
						}}
						className="max-w-[360px]"
					>
						{request.description}
					</Link>
					{attachmentCount > 0 && (
						<span className="opacity-50">
							{t("attachement", {
								count: attachmentCount,
							})}
						</span>
					)}
				</div>
			</List.Item>
			<List.Item>
				<PaymentRequestDateCell {...{ request }} />
			</List.Item>
			<List.Item>
				{auth.type === "student" || !isPriceEditabled ? (
					<>{request.price ? formatPrice(request.price) : "-"}</>
				) : (
					<PaymentRequestPriceField
						value={{
							start_date: request.start_date,
							end_date: request.end_date,
							price: request.price,
							break: request.break,
						}}
						onSubmit={(price: any) =>
							onUpdate({
								type: request.type,
								price: price,
							})
						}
					>
						<PaymentRequestPriceFieldTrigger className="w-full md:w-[120px] text-left justify-between relative">
							<p
								className={cn(
									"line-clamp-1",
									!request?.price && "text-placeholder"
								)}
							>
								{request?.price
									? formatPrice(request.price)
									: t("fields.price.placeholder")}
							</p>
							<div
								className={cn(
									"absolute top-0 bottom-0 flex items-center right-2 pointer-events-none opacity-100 transition-all",
									request.price && "opacity-0"
								)}
							>
								<i className="fas fa-pencil text-sm text-border"></i>
							</div>
						</PaymentRequestPriceFieldTrigger>
					</PaymentRequestPriceField>
				)}
			</List.Item>
			<List.Item>
				<Link
					to={{
						modal: `/payment-requests/${request.id}/edit`,
					}}
					className="max-w-[360px]"
				>
					{request.total ? formatPrice(request.total) : "-"}
				</Link>
			</List.Item>
			<List.Item>
				{request?.payment?.statuses?.completed ? (
					<div className="flex flex-col">
						<span className="text-[16px]">{t("paid")}</span>
						<span className="text-sm opacity-70">
							{moment(request.payment.statuses.completed).format(
								"DD-MM-YYYY HH:mm"
							)}
						</span>
					</div>
				) : (
					<span>-</span>
				)}
			</List.Item>
			<List.Item>
				<div className="flex items-center gap-3 justify-end pr-2">
					{["APPROVED", "COMPLETED"].includes(request.status) && (
						<Button
							xsmall
							to={{
								modal: `/payment-requests/${request.id}/edit`,
							}}
						>
							{t("buttons.view")}
						</Button>
					)}
					{["PENDING", "OPEN"].includes(request.status) && (
						<>
							{auth.hid === request?.creator?.hid && (
								<Button
									xsmall
									type="ghost"
									to={{
										modal: `/payment-requests/${request.id}/delete`,
									}}
								>
									{t("buttons.delete")}
								</Button>
							)}
							<Button
								xsmall
								to={{
									modal: `/payment-requests/${request.id}/edit`,
								}}
							>
								{t("buttons.edit")}
							</Button>
						</>
					)}
				</div>
			</List.Item>
		</List.Row>
	);
};

PaymentRequestTableItem.locale = {
	nl: {
		buttons: {
			edit: "Bewerk",
			delete: "Annuleren",
			create: "Betaalverzoek maken",
			accept: "Accepteren",
			deny: "Afwijzen",
			view: "Bekijk",
			"load-more": "Meer laden",
		},
		paid: "Uitbetaald op",
		attachement: "+ {{count}} bijlage",
		"extra-information-on-hover": "Extra informatie",
		fields: {
			price: {
				placeholder: "€ -",
			},
		},
	},
	en: {
		buttons: {
			edit: "Edit",
			delete: "Cancel",
			create: "Create Payment Request",
			accept: "Accept",
			deny: "Reject",
			"load-more": "Load More",
			view: "View",
		},
		paid: "Paid at",
		attachement: "+{{count}} attachment",
		"extra-information-on-hover": "Extra information",
		fields: {
			price: {
				placeholder: "€ -",
			},
		},
	},
};

export default withTranslation(PaymentRequestTableItem);
