const auth = {
	"blocked-account": {
		title: "Your account is blocked",
		description:
			"Your account ({{email}}) has been blocked due to suspicious activity, please contact <3>support@{{tenant.slug}}.nl</3>",
		button: "Logout",
	},
	login: {
		"already-signed-in": {
			title: "You are already signed in",
			button: "Go to dashboard",
		},
		or: "Or",
		title: "Login",
		"no-account": "No account? Create it easily",
	},
	"not-found": {
		title: "The page doesn't seem to exist anymore",
		description:
			"It seems like this page no longer exists. Use the buttons below to get back to the website",
		buttons: {
			login: "Login",
			home: "Go to home page",
			dashboard: "Go to dashboard",
		},
	},
};

export default auth;
