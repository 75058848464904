import { useState } from "react";
import { ApiTaskMinimal } from "src/api/types";
import Avatar from "src/components/Avatar";
import Button from "src/components/Button";
import FlatList from "src/components/FlatList";
import Modal from "src/components/Modal";
import Table from "src/components/Table";
import withTranslation, {
	Translation,
} from "src/components/hoc/withTranslation";
import TimeSheetForm from "src/components/timesheet/TimesheetForm";
import useTimesheets from "src/hooks/api/services/timesheets/useTimesheets";
import useGet from "src/hooks/rest/useGet";
import { cn } from "src/lib/utils";

interface TimesheetMissingListProps extends Translation {
	task: ApiTaskMinimal;
}

const TimesheetMissingList = ({ t, task }: TimesheetMissingListProps) => {
	const [{ data }, loading, { handleSearch, pagination, setResult }] = useGet(
		`/company/registrations?task_id=${task.id}&missing_timesheet=1&with[]=current&with[]=timesheets&with=task&variant=registration-timesheet`,
		{ data: [] }
	);
	const [selected, setSelected] = useState<any>();
	const { actions, status } = useTimesheets({
		task_id: task.id,
	});

	// useEffect(() => {
	// 	actions.list();
	// 	// eslint-disable-next-line react-hooks/exhaustive-deps
	// }, []);

	const headers = [t("headers.user"), ""];

	return (
		<>
			<Modal
				visible={selected?.id ? true : false}
				onClose={() => setSelected(undefined)}
				title={selected?.user.full_name}
			>
				<TimeSheetForm
					variant={"vertical"}
					options={{
						autosave: false,
					}}
					onSubmit={(status) => {
						setResult((result: any) => ({
							...result,
							data: result?.data.filter(
								(item: any) => item.id !== selected.id
							),
						}));
						setSelected(undefined);
						actions.list();
					}}
					timesheet={selected}
				/>
			</Modal>
			<div className="flex flex-col">
				<strong className="mb-4">{t("title")}</strong>
				<FlatList
					searchPlaceholder={t("search-placeholder")}
					{...{ pagination, loading, handleSearch, data }}
					table={{
						headers,
					}}
					loading={status !== "idle"}
					renderItem={(registration: any, index) => (
						<Table.Row
							className={cn(
								registration.status === "canceled" &&
									"opacity-50"
							)}
							key={`registration-${registration.id}`}
						>
							<Table.Cell className="w-full">
								<Avatar.User
									user={registration.user}
									subtitle={
										!registration.user.has_merchant ? (
											<small className="text-red">
												{t("item.no-merchant")}
											</small>
										) : (
											registration.user?.tagline
										)
									}
									wrapper={{
										className: "max-w-full",
									}}
								/>
							</Table.Cell>
							<Table.Cell className="justify-end">
								{registration.status !== "canceled" ? (
									<Button
										onClick={() => {
											actions.set((state: any) => ({
												...state,
												list: [
													...(state?.list || []),
													registration,
												],
											}));
											setSelected(registration);
										}}
										small
										type="dark"
										disabled={
											!registration.user.has_merchant
										}
									>
										{t("item.button")}
									</Button>
								) : (
									<span className="text-sm">
										{t("item.canceled")}
									</span>
								)}
							</Table.Cell>
						</Table.Row>
					)}
					empty={{
						title: t("empty.title"),
						text: t("empty.text"),
					}}
				/>
			</div>
		</>
	);
};

TimesheetMissingList.locale = {
	nl: {
		title: "Missende uren",
		"search-placeholder": "Zoek gebruiker",
		empty: {
			title: "Alle uren zijn opgegeven",
			text: "Geen missende aanmeldingen gevonden",
		},
		headers: {
			user: "Uitvoerder",
		},
		item: {
			button: "Invullen",
			canceled: "Geannuleerd",
			"no-merchant": "Mist account gegevens",
		},
	},
	en: {
		title: "Missende uren",
		"search-placeholder": "Search user",
		empty: {
			title: "All hours filled",
			text: "No missing registrations found",
		},
		headers: {
			user: "User",
		},
		item: {
			button: "Create",
			canceled: "Canceled",
			"no-merchant": "Missing account details",
		},
	},
};

export default withTranslation(TimesheetMissingList);
