import useEmblaCarousel, {
	type UseEmblaCarouselType,
} from "embla-carousel-react";
import * as React from "react";
import Button, { ButtonProps } from "src/components/Button";
import { cn } from "src/lib/utils";

type CarouselApi = UseEmblaCarouselType[1];
type UseCarouselParameters = Parameters<typeof useEmblaCarousel>;
type CarouselOptions = UseCarouselParameters[0];
type CarouselPlugin = UseCarouselParameters[1];

type CarouselProps = {
	opts?: CarouselOptions;
	plugins?: CarouselPlugin;
	orientation?: "horizontal" | "vertical";
	setApi?: (api: CarouselApi) => void;
	onSlideChange?: (index: number) => void;
};

type CarouselContextProps = {
	carouselRef: ReturnType<typeof useEmblaCarousel>[0];
	api: ReturnType<typeof useEmblaCarousel>[1];
	scrollPrev: () => void;
	scrollNext: () => void;
	scrollTo: (index: number) => void;
	canScrollPrev: boolean;
	canScrollNext: boolean;
	currentSlide: number;
} & CarouselProps;

const CarouselContext = React.createContext<CarouselContextProps | null>(null);

export function useCarousel() {
	const context = React.useContext(CarouselContext);

	if (!context) {
		throw new Error("useCarousel must be used within a <Carousel />");
	}

	return context;
}

const Carousel = React.forwardRef<
	HTMLDivElement,
	React.HTMLAttributes<HTMLDivElement> & CarouselProps
>(
	(
		{
			orientation = "horizontal",
			opts,
			setApi,
			plugins,
			className,
			children,
			onSlideChange,
			...props
		},
		ref
	) => {
		const [carouselRef, api] = useEmblaCarousel(
			{
				...opts,
				axis: orientation === "horizontal" ? "x" : "y",
			},
			plugins
		);
		const [canScrollPrev, setCanScrollPrev] = React.useState(false);
		const [canScrollNext, setCanScrollNext] = React.useState(false);
		const [currentSlide, setCurrentSlide] = React.useState(0);

		const onSelect = React.useCallback(
			(api: CarouselApi) => {
				if (!api) {
					return;
				}
				setCanScrollPrev(api.canScrollPrev());
				setCanScrollNext(api.canScrollNext());
				setCurrentSlide(api.selectedScrollSnap() + 1);

				if (onSlideChange) {
					onSlideChange(api.selectedScrollSnap());
				}
			},
			[onSlideChange]
		);

		const scrollPrev = React.useCallback(() => {
			api?.scrollPrev();
		}, [api]);

		const scrollNext = React.useCallback(() => {
			api?.scrollNext();
		}, [api]);

		const scrollTo = React.useCallback(
			(index: number) => {
				api?.scrollTo(index);
			},
			[api]
		);

		const handleKeyDown = React.useCallback(
			(event: React.KeyboardEvent<HTMLDivElement>) => {
				if (event.key === "ArrowLeft") {
					event.preventDefault();
					scrollPrev();
				} else if (event.key === "ArrowRight") {
					event.preventDefault();
					scrollNext();
				}
			},
			[scrollPrev, scrollNext]
		);

		React.useEffect(() => {
			if (!api || !setApi) {
				return;
			}

			setApi(api);
			setCurrentSlide(api.selectedScrollSnap() + 1);
		}, [api, setApi]);

		React.useEffect(() => {
			if (!api) {
				return;
			}

			onSelect(api);
			api.on("reInit", onSelect);
			api.on("select", onSelect);

			return () => {
				api?.off("select", onSelect);
			};
		}, [api, onSelect]);

		return (
			<CarouselContext.Provider
				value={{
					carouselRef,
					api: api,
					opts,
					orientation:
						orientation ||
						(opts?.axis === "y" ? "vertical" : "horizontal"),
					scrollPrev,
					scrollNext,
					canScrollPrev,
					canScrollNext,
					scrollTo,
					currentSlide,
				}}
			>
				<div
					ref={ref}
					onKeyDownCapture={handleKeyDown}
					className={cn("relative", className)}
					role="region"
					aria-roledescription="carousel"
					{...props}
				>
					{children}
				</div>
			</CarouselContext.Provider>
		);
	}
);
Carousel.displayName = "Carousel";

const CarouselContent = React.forwardRef<
	HTMLDivElement,
	React.HTMLAttributes<HTMLDivElement>
>(({ className, ...props }, ref) => {
	const { carouselRef, orientation } = useCarousel();

	return (
		<div ref={carouselRef} className="overflow-hidden">
			<div
				ref={ref}
				className={cn(
					"flex",
					orientation === "horizontal" ? "-ml-4" : "-mt-4 flex-col",
					className
				)}
				{...props}
			/>
		</div>
	);
});
CarouselContent.displayName = "CarouselContent";

const CarouselItem = React.forwardRef<
	HTMLDivElement,
	React.HTMLAttributes<HTMLDivElement>
>(({ className, ...props }, ref) => {
	const { orientation } = useCarousel();

	return (
		<div
			ref={ref}
			role="group"
			aria-roledescription="slide"
			className={cn(
				"min-w-0 shrink-0 grow-0 basis-full",
				orientation === "horizontal" ? "pl-4" : "pt-4",
				className
			)}
			{...props}
		/>
	);
});
CarouselItem.displayName = "CarouselItem";

const CarouselPrevious = React.forwardRef<HTMLButtonElement, ButtonProps>(
	({ className, ...props }) => {
		const { scrollPrev, canScrollPrev } = useCarousel();

		return (
			<Button
				className={cn("text-sm w-10 h-10", className)}
				disabled={!canScrollPrev}
				onClick={scrollPrev}
				type="border"
				iconOnly
				{...props}
			>
				<i className="far fa-arrow-left"></i>
				<span className="sr-only">Previous slide</span>
			</Button>
		);
	}
);
CarouselPrevious.displayName = "CarouselPrevious";

const CarouselNext = React.forwardRef<HTMLButtonElement, ButtonProps>(
	({ className, ...props }) => {
		const { scrollNext, canScrollNext } = useCarousel();

		return (
			<Button
				className={cn("text-sm w-10 h-10", className)}
				disabled={!canScrollNext}
				onClick={scrollNext}
				type="border"
				iconOnly
				{...props}
			>
				<i className="far fa-arrow-right"></i>
				<span className="sr-only">Next slide</span>
			</Button>
		);
	}
);
CarouselNext.displayName = "CarouselNext";

const CarouselNavigateButton = ({
	direction,
	className,
	...props
}: ButtonProps & { direction: "next" | "previous" }) => {
	const { scrollNext, canScrollNext, scrollPrev, canScrollPrev } =
		useCarousel();

	const onClick = () => {
		if (direction === "next" && canScrollNext) {
			scrollNext();
		}
		if (direction === "previous" && canScrollPrev) {
			scrollPrev();
		}
	};

	return (
		<Button
			{...props}
			{...(direction === "next" ? { disabled: !canScrollNext } : {})}
			{...(direction === "previous" ? { disabled: !canScrollPrev } : {})}
			onClick={onClick}
		/>
	);
};

CarouselNavigateButton.displayName = "CarouselNavigateButton";

export {
	Carousel,
	CarouselContent,
	CarouselItem,
	CarouselNavigateButton,
	CarouselNext,
	CarouselPrevious,
	type CarouselApi,
};
