import axios from "axios";
import { atom, useAtom } from "jotai";
import { atomFamily } from "jotai/utils";
import { useEffect, useState } from "react";

export const apiAtomFamily = atomFamily(
	(data: any) => atom(data?.defaultData || {}),
	(a: any, b: any) => a.id === b.id
);

type ApiGetOptions = {
	params?: any;
	defaultData?: any;
	usePagination?: boolean;
};

const useRememberGet = (
	rememberKey: string,
	route?: string,
	options?: ApiGetOptions
) => {
	const [loading, setLoading] = useState(true);
	const [fetching, setFetching] = useState(false);
	const [result, setResult] = useAtom(
		apiAtomFamily({
			id: rememberKey,
			defaultData: options?.defaultData || {},
		})
	);
	const [error, setError] = useState(null as any);
	const [search, setSearch] = useState("");
	let source: any;

	const getDefaultParams = () => {
		let params = options?.params || {};

		if (options?.usePagination) {
			params.page = result?.meta?.current_page || params?.page || 1;
		}

		if (search) {
			params.q = search;
		}

		return params;
	};

	const fetchNext = async () => {
		if (!route || fetching) return;
		setFetching(true);
		const { meta } = result;
		const res = await axios.get(route, {
			params: {
				...getDefaultParams(),
				page: meta.current_page + 1,
			},
		});
		setResult((old: any) => ({
			...res.data,
			data: [...old.data, ...res.data.data],
			hasMore: res.data.meta.current_page !== res.data.meta.last_page,
		}));
		setFetching(false);
	};

	const fetchPage = async (page: number) => {
		if (!route || fetching) return;
		setFetching(true);
		const res = await axios.get(route, {
			params: {
				...getDefaultParams(),
				page,
			},
		});
		setResult((old: any) => ({
			...res.data,
			hasMore: res.data.meta.current_page !== res.data.meta.last_page,
		}));
		setFetching(false);
	};

	const fetchGet = async (defaultLoading = true) => {
		if (!route) return;
		// Check if there are any previous pending requests
		// eslint-disable-next-line react-hooks/exhaustive-deps
		source = axios.CancelToken.source();

		if (route) {
			setLoading(defaultLoading);
			try {
				const { data } = await axios.get(route, {
					cancelToken: source.token,
					params: getDefaultParams(),
				});
				if (data) {
					const { meta } = data;
					if (meta?.last_page) {
						data.hasMore = meta.current_page !== meta.last_page;
					}
					setResult(data);
				}
				setLoading(false);
			} catch (error: any) {
				setError(error?.response?.data);
				setLoading(false);
			}
		} else {
			setLoading(false);
		}
	};

	useEffect(() => {
		if (route) {
			fetchGet();
		}
		return () => {
			if (typeof source != typeof undefined) {
				source.cancel("CANCEL_NEW_REQUEST");
			}
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [rememberKey]);

	const handleSearch = async (q: string) => {
		if (!route || fetching) return;
		setFetching(true);
		setSearch(q);

		const { data } = await axios.get(route, {
			params: {
				...getDefaultParams(),
				q,
			},
		});
		const { meta } = data;

		if (meta?.last_page) {
			data.hasMore = meta.current_page !== meta.last_page;
		}

		setResult(data);
		setFetching(false);
	};

	return [
		result,
		loading,
		{
			error,
			fetchGet,
			setResult,
			fetchNext,
			fetching,
			handleSearch,
			pagination: {
				setPage: fetchPage,
				page: (result?.meta || {})?.current_page,
				last_page: (result?.meta || {})?.last_page,
			},
		},
	];
};

export default useRememberGet;
