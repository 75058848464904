import { useNavigate } from "react-router-dom";
import GeneralCustomForm, {
	FormValues,
} from "src/components/custom-form/GeneralCustomForm";
import withTranslation, {
	Translation,
} from "src/components/hoc/withTranslation";
import useForm from "src/hooks/api/services/forms/useForm";
import useModal from "src/hooks/useModal";

const CreatePaymentRequestAddonModal = ({ t }: Translation) => {
	useModal({
		title: t("title"),
	});
	const navigate = useNavigate();
	const { actions } = useForm({
		rememberId: "FORM_PAYMENT_REQUESTS",
	});

	const onSubmit = async (values: FormValues) => {
		const result = await actions.create({
			// type: "PAYMENT_REQUEST",
			...values,
		});
		await actions.list();
		if (result.id) {
			navigate(`/settings/payment-requests/addons/${result.id}`);
		}
	};

	return <GeneralCustomForm {...{ onSubmit }} />;
};

CreatePaymentRequestAddonModal.locale = {
	nl: {
		title: "Addon aanmaken",
	},
	en: {
		title: "Create Addon",
	},
};

export default withTranslation(CreatePaymentRequestAddonModal);
