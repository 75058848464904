import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import useTenant from "src/hooks/api/services/tenants/useTenant";
import analytics from "src/lib/analytics";

export default function useAnalytics() {
	const location = useLocation();
	const { tenant } = useTenant();
	useEffect(() => {
		// console.log(process.env);
		if (
			process?.env?.NODE_ENV !== "development" &&
			tenant.slug === "getjobsdone"
		) {
			analytics.init();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		// if (process.env.NODE_ENV !== "development") {
		// 	const currentPath = location.pathname + location.search;
		// 	analytics.sendPageview(currentPath);
		// }
	}, [location]);
}
