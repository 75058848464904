import { useContext, useEffect } from "react";
import Button from "src/components/Button";
import withTranslation, {
	Translation,
} from "src/components/hoc/withTranslation";
import Layout from "src/components/layout/Layout";
import { ModalViewContext } from "src/components/layout/ModalView";
import TaskFilterForm from "src/components/tasks/TaskFilterForm";
import useTasksFilter from "src/hooks/api/tasks/useTasksFilter";

function TasksFilterView({ t }: Translation) {
	const modal = useContext(ModalViewContext);
	const { isFilteredState, actions, filterState } = useTasksFilter();

	useEffect(() => {
		modal.setTitle(t("title"));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const onSubmit = () => {
		actions.set(filterState);
		modal.onClose();
	};

	return (
		<Layout title={t("title")}>
			<TaskFilterForm />
			<div className="mt-12 flex flex-col gap-4">
				<Button type="dark" className="w-full" onClick={onSubmit}>
					{t("apply")}
				</Button>

				{isFilteredState && (
					<Button
						type="ghost"
						className="w-full"
						to={`/tasks/boards/create?filter=${btoa(
							JSON.stringify(filterState)
						)}`}
					>
						<i className="fas fa-save mr-2"></i>
						{t("create-board")}
					</Button>
				)}

				{isFilteredState && (
					<Button
						type="ghost"
						className="w-full"
						onClick={() => {
							actions.clear();
							modal.onClose();
						}}
					>
						{t("clear")}
					</Button>
				)}
			</div>
		</Layout>
	);
}

TasksFilterView.locale = {
	nl: {
		title: "Taken filteren",
		apply: "Toepassen",
		"create-board": "Opslaan als bord",
		clear: "Filters legen",
	},
	en: {
		title: "Filter tasks",
		apply: "Apply",
		"create-board": "Save as board",
		clear: "Clear filters",
	},
};

export default withTranslation(TasksFilterView);
