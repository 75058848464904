import { atom, useAtom } from "jotai";
import { ApiEmoji, ApiProject } from "src/api/types";
import { Skill } from "src/components/form/skill-selector/Provider";
import useApi from "src/hooks/api/utils/useApi";
import useEmoji from "src/hooks/data/useEmoji";

type Options = {
	filter?: any;
};

export const formAtom = atom({
	icon: { emoji: {} as ApiEmoji },
	name: "",
	start_date: "",
	end_date: "",
	members: [] as string[],
	skills: [] as Skill[],
});

export const filterAtom = atom({
	search: "",
	start_date: "",
	end_date: "",
	members: [] as string[],
});

export const isFiltered = (filter: any) => {
	if (
		filter?.search ||
		filter?.members ||
		filter?.start_date ||
		filter?.end_date
	) {
		return true;
	}
	return false;
};

function useProjects(options?: Options) {
	const [formState, setFormState] = useAtom(formAtom);
	const [filterState, setFilterState] = useAtom(filterAtom);
	const { actions: emojiActions } = useEmoji();
	const { state, actions, api, pagination } = useApi(
		{ id: "PROJECTS" },
		{
			baseUrl: `/client/projects`,
			query: {
				state: "all",
				...(options?.filter || {}),
			},
		}
	);

	const projects: ApiProject[] = state?.list || [];

	const getRandomIcon = () => {
		return {
			emoji: emojiActions.getRandomEmoji(),
		};
	};

	const clearFormState = () => {
		setFormState({
			icon: { emoji: { icon: "", description: "" } },
			name: "",
			start_date: "",
			end_date: "",
			members: [],
			skills: [],
		});
	};
	const clearFilterState = () => {
		setFilterState({
			search: "",
			start_date: "",
			end_date: "",
			members: [],
		});
	};

	return {
		formState,
		setFormState,
		clearFormState,
		filterState,
		setFilterState,
		clearFilterState,
		getRandomIcon,
		filter: state?.filter,
		isFiltered,
		projects,
		project: state?.data,
		status: state.status,
		pagination,
		actions: {
			...actions,
		},
		api,
	};
}

export default useProjects;
