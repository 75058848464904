import React from "react";
import { useTranslation } from "react-i18next";
import { ApiLanguage } from "src/api/types";
import Radio from "src/components/form/Radio";
import LanguageIcon from "src/components/language/LanguageIcon";
import Skeleton from "src/components/Skeleton";
import { cn } from "src/lib/utils";

interface LanguageSelectItemProps {
	language: ApiLanguage;
	onClick?: () => void;
	selected?: boolean;
}

const LanguageSelectItem = ({
	language,
	onClick,
	selected,
}: LanguageSelectItemProps) => {
	const { t } = useTranslation("common", {
		keyPrefix: "translation",
	});
	return (
		<li
			{...{ onClick }}
			className={cn(
				`rounded-md pointer p-2 group hover:border-primary flex transition-all cursor-pointer items-center gap-4 border-2 border-border`,
				selected && "border-primary text-primary"
			)}
		>
			<Radio value={selected} />
			<div className="mb-0 flex gap-3 items-center flex-1">
				<LanguageIcon {...{ language }} className="w-6" />
				<div className="flex flex-col">
					<p>{t(language.code)}</p>
				</div>
			</div>
		</li>
	);
};

const Loading = () => {
	return (
		<li
			className={`rounded-md pointer p-2 group hover:border-primary flex transition-all cursor-pointer items-center gap-4 border-2 bg-background border-border`}
		>
			<Radio value={false} variant="light" />
			<div className="mb-0 flex gap-4 items-center flex-1">
				<Skeleton className="w-8 h-6" />
				<div className="flex flex-col">
					<Skeleton className="h-4 w-24" />
				</div>
			</div>
		</li>
	);
};

LanguageSelectItem.Loading = Loading;

export default LanguageSelectItem;
