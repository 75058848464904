import axios from "axios";
import { ComponentProps, useEffect, useState } from "react";
import { ApiVacancy } from "src/api/types";
import Loader from "src/components/Loader";
import {
	AnalyseData,
	VacancyFormContext,
	VacancyFormStatus,
	VacancyFormStepName,
} from "src/components/vacancies/vacancy-form/provider";
import useTaskCredits from "src/hooks/api/services/useTaskCredits";
import { cn } from "src/lib/utils";

interface VacancyFormProps extends ComponentProps<"div"> {
	vacancyId?: string;
	defaultVacancy?: ApiVacancy;
	onSave?: (vacancy: ApiVacancy) => void;
}

const VacancyForm = ({
	className,
	children,
	vacancyId,
	defaultVacancy,
	onSave,
	...props
}: VacancyFormProps) => {
	const { actions: taskCreditsActions } = useTaskCredits();
	const [step, setStep] = useState<VacancyFormStepName>("submit");
	const [isFetchingVacancy, setIsFetchingVacancy] = useState(true);
	const [vacancy, setVacancy] = useState<ApiVacancy | null>(
		defaultVacancy || null
	);
	const [creditId, setCreditId] = useState<string | null>(null);
	const [status, setStatus] = useState<VacancyFormStatus>("idle");

	useEffect(() => {
		if (vacancyId) {
			axios
				.get(`/client/vacancies/${vacancyId}`)
				.then(({ data: { data } }) => {
					setVacancy(data);
					if (data.id) {
						setStep("check");
					}
					setIsFetchingVacancy(false);
				});
		} else {
			setIsFetchingVacancy(false);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleAnalyze = async (data: AnalyseData) => {
		setStatus("analyzing");
		try {
			const {
				data: { data: vacancy },
			} = await axios.post("/client/vacancies", data);
			setVacancy(vacancy);
			//Scroll to top
			window.scrollTo({ top: 0, behavior: "smooth" });
			return vacancy;
		} finally {
			setStatus("idle");
		}
	};

	const handleUpdate = async (data: ApiVacancy) => {
		setStatus("updating");
		try {
			const {
				data: { data: vacancy },
			} = await axios.patch(`/client/vacancies/${data?.id}`, data);
			//Scroll to top
			window.scrollTo({ top: 0, behavior: "smooth" });
			setVacancy(vacancy);
			onSave?.(vacancy);
		} finally {
			setStatus("idle");
		}
	};

	const handlePublish = async () => {
		setStatus("publishing");
		try {
			await axios.post(`/client/vacancies/${vacancy?.id}/publish`, {
				credit_id: creditId,
			});
			await taskCreditsActions.list();
		} finally {
			setStatus("idle");
		}
	};

	if (isFetchingVacancy) {
		return (
			<div className="flex flex-col flex-1 justify-center items-center">
				<Loader />
			</div>
		);
	}

	return (
		<VacancyFormContext.Provider
			value={{
				step: {
					current: step,
					set: setStep,
				},
				status,
				vacancy,
				creditId,
				actions: {
					setVacancy,
					setStatus,
					setCreditId,
					analyze: handleAnalyze,
					update: handleUpdate,
					publish: handlePublish,
				},
			}}
		>
			<div className={cn("flex flex-col", className)} {...props}>
				{children}
			</div>
		</VacancyFormContext.Provider>
	);
};

export default VacancyForm;
