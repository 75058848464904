import * as ToggleGroup from "@radix-ui/react-toggle-group";
import Wrapper, { WrapperProps } from "src/components/field/utils/Wrapper";
import withTranslation, {
	Translation,
} from "src/components/hoc/withTranslation";
import Tab from "src/components/layout/Tab";
import Tabs from "src/components/layout/Tabs";

type Option = {
	value: string;
	label: string | JSX.Element;
};

interface OptionsProps extends Translation {
	wrapper: WrapperProps;
	options: Option[];
	value?: string;
	onChange: (value: string) => void;
}

const Options = ({ t, wrapper, options, value, onChange }: OptionsProps) => {
	return (
		<Wrapper
			{...{
				...wrapper,
			}}
		>
			<Tabs.Container className="relative">
				<ToggleGroup.Root
					onValueChange={(v) => (v ? onChange(v) : null)}
					type="single"
					defaultValue="center"
					aria-label="Text alignment"
				>
					{options.map((option) => (
						<ToggleGroup.Item
							className={
								"focus:bg-background transition-all focus:shadow rounded-md"
							}
							key={option.value}
							value={option.value}
							aria-label={option.label.toString()}
						>
							<Tab element="div" active={option.value === value}>
								{option.label}
							</Tab>
						</ToggleGroup.Item>
					))}
				</ToggleGroup.Root>
			</Tabs.Container>
		</Wrapper>
	);
};

Options.locale = {};

export default withTranslation(Options);
