import { Route } from "react-router-dom";
import CostCalculatorView from "src/views/authenticated/tools/CostCalculatorView";
import JobOfferConverterView from "src/views/authenticated/tools/JobOfferConverterView";

const ToolRoutes = [
	<Route
		element={<JobOfferConverterView />}
		path="/tools/job-offer-converter"
		key="/tools/job-offer-converter"
	/>,
	<Route
		element={<CostCalculatorView />}
		path="/tools/cost-calculator"
		key="/tools/cost-calculator"
	/>,
];

export default ToolRoutes;
