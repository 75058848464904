import axios from "axios";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import Avatar from "src/components/Avatar";
import BackButton from "src/components/BackButton";
import Card from "src/components/Card";
import CtaBar from "src/components/CtaBar";
import Layout from "src/components/layout/Layout";
import NetworkMiddleware from "src/components/middlewares/NetworkMiddleware";
import DenyTaskInvite from "src/components/network/DenyTaskInvite";
import SignupTask from "src/components/network/SignupTask";
import TaskInfoCard from "src/components/tasks/TaskInfoCard";
import useGet from "src/hooks/rest/useGet";
import useRefetchProfile from "src/hooks/rest/useRefetchProfile";
import useAuth from "src/hooks/selectors/useAuth";
import useSubscription from "src/hooks/selectors/useSubscription";
import useWindowSize from "src/hooks/useWindowSize";
import NotFound from "src/views/auth/NotFoundView";

const DashboardNetworkInviteView = () => {
	const { t } = useTranslation("network-view", {
		keyPrefix: "network-invite-view",
	});
	const auth = useAuth();
	const subscription = useSubscription();
	const { id } = useParams() as any;
	const [{ data }, loading, { fetchGet }] = useGet(
		`/student/task-invites/${id}?with[]=creator`
	);
	const { refetchProfile } = useRefetchProfile();
	const task = data?.task || {};
	const size = useWindowSize();

	const [isRead, setIsRead] = useState(data?.is_read || false);
	useEffect(() => {
		if (!isRead) {
			axios.post(`/student/task-invites/${id}/read`).then(() => {
				refetchProfile();
			});
			setIsRead(true);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [data, isRead]);

	const onSuccess = () => {
		fetchGet(false);
	};

	if (
		auth.type === "company" &&
		(!auth?.has_subscription || subscription?.plan?.slug !== "pro")
	) {
		return <NetworkMiddleware />;
	}

	if (!data?.id && !loading) {
		return <NotFound />;
	}

	return (
		<Layout {...{ loading }} title={t("title")} container="xxl">
			<BackButton to="/network/invites" label={t("back")} />
			{task && (
				<>
					<div className="flex flex-col pb-16 md:pb-0 md:grid md:grid-cols-3 gap-8">
						<TaskInfoCard className="md:col-span-2" {...{ task }} />
						<div className="flex gap-8 flex-col">
							{task?.company && (
								<Card>
									<div className="flex items-center gap-2">
										<Avatar
											src={task?.company?.avatar}
											alt={task?.company?.name}
										/>
										<div className="flex flex-col">
											<strong>
												{task?.company.name}
											</strong>
											{task?.creator && (
												<span className="opacity-70">
													{task?.creator?.first_name}
												</span>
											)}
										</div>
									</div>
								</Card>
							)}
							{!size.isPhone && (
								<Card>
									<div className="flex flex-col gap-2">
										<SignupTask
											{...{ onSuccess }}
											invite={data}
										/>

										{!data?.is_registered && (
											<DenyTaskInvite invite={data} />
										)}
									</div>
								</Card>
							)}
							{size.isPhone && !data?.is_registered && (
								<Card>
									<DenyTaskInvite invite={data} />
								</Card>
							)}
						</div>
					</div>
					{size.isPhone && (
						<CtaBar>
							<div className="flex items-center justify-center p-4">
								<SignupTask {...{ onSuccess }} invite={data} />
							</div>
						</CtaBar>
					)}
				</>
			)}
		</Layout>
	);
};

export default DashboardNetworkInviteView;
