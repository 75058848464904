import FlatList from "src/components/FlatList";
import withTranslation, {
	Translation,
} from "src/components/hoc/withTranslation";
import Layout from "src/components/layout/Layout";
import TaskInviteCard from "src/components/network/TaskInviteCard";
import useGet from "src/hooks/rest/useGet";

const NetworkInvitesView = ({ t }: Translation) => {
	const [result, loading, { fetchNext }] = useGet(
		"/student/task-invites?with[]=creator",
		{ data: [] },
		true
	);
	const { data, hasMore } = result;

	return (
		<Layout title={t("title")} container="xxl" className=" ">
			<FlatList
				{...{ loading, hasMore }}
				data={data || []}
				onNext={async () => {
					if (hasMore) await fetchNext();
				}}
				columns={2}
				empty={{
					title: t("empty.title"),
					text: t("empty.description"),
				}}
				renderItem={(row: any) => (
					<TaskInviteCard key={row.hid} invite={row} />
				)}
			/>
		</Layout>
	);
};

NetworkInvitesView.locale = {
	nl: {
		title: "Uitnodigingen",
		empty: {
			title: "Geen uitnodigingen",
			description: "Er zijn nog geen uitnodigingen voor jou",
		},
	},
	en: {
		title: "Invites",
		empty: {
			title: "No invites",
			description: "There are no invites for you yet",
		},
	},
};

export default withTranslation(NetworkInvitesView);
