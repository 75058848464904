import { useState } from "react";
import { useTranslation } from "react-i18next";
import { ApiPaymentMethods } from "src/api/types";
import MandateMethods from "src/components/checkout/MandateMethods";
import FormGrid from "src/components/form/FormGrid";
import Layout from "src/components/layout/Layout";
import usePost from "src/hooks/rest/usePost";
import useAlert from "src/hooks/useAlert";
import useRedirectToCustomerPortal from "src/hooks/useRedirectToCustomerPortal";

export default function ChangeMandateView() {
	const { t } = useTranslation("payment-view", {
		keyPrefix: "change-mandate-view",
	});
	const [pending, setPending] = useState("");
	const [createMandate] = usePost("/auth/subscriptions/mandate");
	const { isLoading } = useRedirectToCustomerPortal();
	const [sendAlert] = useAlert();
	const handleError = () => {
		sendAlert({
			type: "error",
			title: t("error.title"),
			text: t("error.description"),
		});
		setPending("");
	};

	const handleSubmit = (method: ApiPaymentMethods, issuer?: string) => {
		setPending(method);
		createMandate({
			payment_method: method,
			redirect_url: `/profile/billing/change`,
			issuer,
		})
			.then(({ data }: any) => {
				const url = data?.redirect_url;
				if (url) {
					window.location = url;
				} else {
					handleError();
				}
			})
			.catch(handleError);
	};

	return (
		<Layout
			loading={isLoading}
			className="dashboard-profile"
			title={t("title")}
		>
			<Layout.BackButton to="/profile/billing/change" label={t("back")} />
			<FormGrid>
				<FormGrid.Header title={t("header.title")}>
					{t("header.description")}
				</FormGrid.Header>
				<FormGrid.Card>
					<MandateMethods
						title={t("form.title")}
						loading={pending as ApiPaymentMethods}
						onClick={handleSubmit}
						variant="button"
					/>
				</FormGrid.Card>
			</FormGrid>
		</Layout>
	);
}
