import moment from "moment";
import Input from "src/components/form/Input";
import Switch from "src/components/form/Switch";
import Form from "src/components/tasks/TaskForm/Form";
import useTaskForm from "src/hooks/api/tasks/useTaskForm";
import CategoryDropdown from "src/components/form/dropdowns/CategoryDropdown";
import { useTranslation } from "react-i18next";
import useTenant from "src/hooks/api/services/tenants/useTenant";
import { TaskFormOptions } from "src/components/tasks/TaskForm";

interface TargetFormProps extends TaskFormOptions {
	hasStudent?: boolean;
	hideVisibility?: boolean;
}

const TargetForm = ({
	hasStudent,
	hideVisibility,
	hideButtons,
}: TargetFormProps) => {
	const { settings } = useTenant();
	const { t } = useTranslation("forms", {
		keyPrefix: "target-form",
	});
	const { task, actions, current } = useTaskForm();

	const getNextDisabled = () => {
		return !(task.start_date && (task.category_id || task.category));
	};

	return (
		<Form
			{...{ hideButtons }}
			title={t("title")}
			value={{
				start_date: task.start_date,
				end_date: task.end_date,
				estimated_duration: task.estimated_duration,
				category_id: task.category_id,
				category: task.category,
				AnyCategory: false,
				promotion_code: task.promotion_code,
				is_private: task.is_private,
			}}
			onSubmit={async () => {
				actions.updateCurrent(current + 1);
			}}
			nextDisabled={getNextDisabled()}
		>
			{(getInputProps) => {
				return (
					<>
						{!hasStudent && !hideVisibility && (
							<>
								<div className="flex flex-col gap-2">
									<Switch
										label={t("anonymous")}
										value={task?.is_anonymous || false}
										onChange={(value) => {
											actions.set({
												is_anonymous: value,
											});
										}}
									/>
									<Switch
										label={t("private.label")}
										subLabel={t("private.sub-label")}
										value={task?.is_private || false}
										onChange={(value) => {
											actions.set({
												is_private: value,
											});
										}}
									/>
								</div>
								<hr className="border-border my-4" />
							</>
						)}
						<Input
							noMarginBottom
							type="date"
							withTime={task?.variant === "HOUR_RATE"}
							label={t("start-date.label")}
							value={task.start_date}
							valid={task.start_date && task.end_date}
							{...getInputProps("start_date")}
						/>
						<Input
							noMarginBottom
							type="date"
							withTime={task?.variant === "HOUR_RATE"}
							label={t("end-date.label")}
							value={task.end_date}
							valid={task.end_date}
							{...getInputProps("end_date")}
							optional
						/>

						{task.end_date &&
							task.start_date &&
							task.end_date &&
							moment(task.end_date).isBefore(
								moment(task.start_date)
							) && (
								<p className="text-red">
									{t("start-date.error-date-before")}
								</p>
							)}

						{!(task?.subtasks?.length > 0) && (
							<Input
								optional
								label={t("estimated-duration.label")}
								help={t("estimated-duration.help")}
								type="number"
								{...getInputProps("estimated_duration")}
							/>
						)}
						<CategoryDropdown
							variant="interests"
							className="dropdown-spacing"
							label={t("category.label")}
							placeholder={t("category.label")}
							value={task.category_id}
							valid={task.category_id}
							onChange={(value) => {
								actions.set({
									category_id: value,
								});
							}}
						/>
						{!task.category_id &&
							settings.tasks.manual_category && (
								<Input
									type="text"
									label={t("category.manual")}
									name="category"
									value={task.category}
									valid={task.category}
									onChange={(_, value) => {
										actions.set({
											category: value,
										});
									}}
								/>
							)}
					</>
				);
			}}
		</Form>
	);
};

export default TargetForm;
