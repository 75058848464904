import { useFormik } from "formik";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import Buttons from "src/components/tasks/TaskForm/Buttons";
import useTaskForm from "src/hooks/api/tasks/useTaskForm";
import * as Yup from "yup";

interface FormProps {
	header?: any;
	title?: string;
	titleBadge?: string;
	value?: any;
	schema?: (Yup: any) => any | any;
	onSubmit?: (values: any) => Promise<unknown>;
	children?: (getInputProps: (name: string, type?: string) => any) => any;
	defaultValid?: boolean;
	variant: "create" | "edit";
	nextDisabled?: boolean;
	nextButtonLabel?: string;
	customButtons?: any;
	hideButtons?: boolean;
}

const Form = ({
	header,
	title,
	titleBadge,
	children,
	value,
	schema,
	onSubmit,
	defaultValid,
	nextDisabled,
	nextButtonLabel,
	customButtons,
	hideButtons,
}: FormProps) => {
	const { actions, task } = useTaskForm();
	const { id } = useParams() as any;
	const form = useFormik({
		initialValues: typeof value === "function" ? value() : value,
		validationSchema: schema && Yup.object().shape(schema(Yup)),
		onSubmit: async (values: any, { setSubmitting }) => {
			if (!onSubmit) return;
			if (task.id) {
				await actions.save({
					disableAlert: true,
				});
			}

			onSubmit(values)
				.then(() => {
					setSubmitting(false);
				})
				.catch(() => {
					setSubmitting(false);
				});
		},
	});
	const { values, errors, handleChange, handleBlur } = form;
	const getIsNextDisabled = () => {
		if (nextDisabled !== undefined) {
			return nextDisabled;
		}
		return !defaultValid ? form.isSubmitting || !form.isValid : false;
	};
	useEffect(() => {
		form.validateForm();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const getInputProps = (name: string, type = "input") => {
		if (type === "dropdown") {
			return {
				name,
				value: values[name],
				valid: values[name] ? true : false,
				onChange: (value: number) => {
					handleChange({
						target: {
							name,
							value,
						},
					});
					actions.set({
						[name]: value,
					});
				},
				onBlur: () => {
					handleBlur({
						target: {
							name,
							value: values[name],
						},
					});
				},
				className: "mb-0",
			};
		}

		return {
			name,
			value: values[name],
			error: errors[name] && values[name] ? errors[name] : false,
			valid: !errors[name] && values[name] ? true : false,
			handleBlur: (e: any) => {
				actions.set({
					[name]: e?.target?.value,
				});
				return handleBlur(e);
			},
			handleChange: (e: any) => {
				handleChange({
					target: {
						name,
						value: e?.target?.value,
					},
				});
				return actions.set({
					[name]: e?.target?.value,
				});
			},
			className: "mb-0",
		};
	};

	return (
		<form
			className={`flex flex-col flex-1 h-full ${!id && ""}`}
			onSubmit={form.handleSubmit}
		>
			{header && <div className="">{header}</div>}
			{title && !id && (
				<div className="flex mb-4">
					<p className="text-[24px] font-bold relative">
						{title}

						{titleBadge && (
							<strong
								className={`text-[12px] bg-red px-2 h-[20px] flex items-center justify-center text-white rounded-md absolute -top-4 -right-6 `}
							>
								{titleBadge}
							</strong>
						)}
					</p>
				</div>
			)}
			<div className="flex flex-col flex-1 gap-4">
				{children && children(getInputProps)}
			</div>
			{customButtons || (
				<Buttons
					className="mt-8"
					disabled={getIsNextDisabled()}
					loading={form.isSubmitting}
					{...{ nextButtonLabel, hideButtons }}
				/>
			)}
		</form>
	);
};

Form.defaultProps = {
	variant: "create",
};

export default Form;
