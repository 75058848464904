import { useEffect, useState } from "react";
import { ApiRegistrationTimesheet, ApiTaskMinimal } from "src/api/types";
import Button from "src/components/Button";
import Card from "src/components/Card";
import FlatList from "src/components/FlatList";
import Checkbox from "src/components/form/Checkbox";
import withTranslation, {
	Translation,
} from "src/components/hoc/withTranslation";
import TimesheetProcessListItem from "src/components/lists/list-items/TimesheetProcessListItem";
import TimesheetProcessDetails from "src/components/timesheet/TimesheetProcessDetails";
import useTimesheets from "src/hooks/api/services/timesheets/useTimesheets";
import { formatPrice } from "src/lib/formatters";

interface TimesheetProcessListProps extends Translation {
	task: ApiTaskMinimal;
	onSubmit?: (status: "success" | "error") => void;
}

const TimesheetProcessList = ({
	t,
	task,
	onSubmit: _onSubmit,
}: TimesheetProcessListProps) => {
	const [agreed, setAgreed] = useState(false);
	const { timesheets, actions, status, pagination } = useTimesheets({
		task_id: task.id,
		filter: {
			approved: 1,
		},
	});

	useEffect(() => {
		actions.list();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const headers = [t("headers.user"), t("headers.total"), t("headers.price")];

	const onSubmit = () => {
		if (!agreed) return null;

		actions.process().then(() => {
			if (_onSubmit) {
				_onSubmit("success");
			}
		});
	};

	return (
		<>
			<FlatList.Head
				title={task.title}
				subtitle={t(`subtitle`, {
					title: task?.parent?.title,
					hour_rate: formatPrice(task.budget_per_hour),
				})}
				search={{
					onSearch: (q: string) => {
						actions.list({ page: 1, q });
					},
				}}
			></FlatList.Head>
			<div className="flex flex-col md:grid grid-cols-3 gap-4">
				<div className="flex flex-col flex-1 md:col-span-2">
					<FlatList
						{...{ pagination }}
						data={timesheets}
						table={{
							headers,
						}}
						loading={status !== "idle"}
						renderItem={(
							timesheet: ApiRegistrationTimesheet,
							index
						) => (
							<TimesheetProcessListItem
								key={`timesheet-${timesheet.id}-${index}`}
								{...{ timesheet }}
							/>
						)}
						empty={{
							title: t("empty.title"),
							text: t("empty.text"),
						}}
					/>
				</div>
				<Card contentClassName="flex flex-col gap-2">
					<strong>{t("details.title")}</strong>
					<TimesheetProcessDetails
						options={{
							task_id: task.id,
							approved: true,
						}}
					>
						{_onSubmit && (
							<>
								<div className="flex gap-2">
									<Checkbox
										value={agreed}
										onChange={setAgreed}
									/>
									<p className="flex-1">{t("agreement")}</p>
								</div>

								<Button
									onClick={onSubmit}
									className="w-full"
									disabled={!agreed}
									loading={status === "processing"}
								>
									{t("buttons.submit")}
								</Button>
							</>
						)}
					</TimesheetProcessDetails>
				</Card>
			</div>
		</>
	);
};

TimesheetProcessList.locale = {
	nl: {
		subtitle: "{{title}} | {{hour_rate}} per uur",
		empty: {
			title: "Geen uren gevonden",
			text: "Er zijn geen gebruikers goedgekeurd voor de opdracht",
		},
		headers: {
			status: "Status",
			user: "Uitvoerder",
			total: "Totaal",
			price: "Bedrag",
		},
		details: {
			title: "Overzicht",
		},
		buttons: {
			submit: "Betaling aanmaken",
		},
		agreement:
			"Ik heb de bedragen gecontroleerd en de facturen mogen aangemaakt worden",
	},
	en: {
		subtitle: "{{title}} | {{hour_rate}} / hour",
		empty: {
			title: "No hours found",
			text: "Users has not yet submitted there hours",
		},
		headers: {
			status: "Status",
			user: "User",
			total: "Total",
			price: "Amount",
		},
		details: {
			title: "Overview",
		},
		buttons: {
			submit: "Create payment",
		},
		agreement:
			"I have checked the amounts and the invoices can be generated",
	},
};

export default withTranslation(TimesheetProcessList);
