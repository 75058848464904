import { useTranslation } from "react-i18next";
import Button from "src/components/Button";
import Card from "src/components/Card";
import Loader from "src/components/Loader";
import Logo from "src/components/Logo";
import useGet from "src/hooks/rest/useGet";

const BankCard = () => {
	const { t } = useTranslation("dashboard-view", {
		keyPrefix: "bank-card",
	});
	const [{ data }, loading] = useGet(`/student/bank-accounts/current`, {
		data: {},
	});
	const BANK_STATUSES = {
		new: t("status.new"),
		pending: t("status.pending"),
		approved: t("status.approved"),
		disapproved: t("status.disapproved"),
	} as any;
	if (loading) {
		return (
			<Card className="p-8 rounded-md shadow min-h-44 flex items-center justify-center">
				<Loader />
			</Card>
		);
	}

	if (!data?.uid) {
		return (
			<Card
				className={`p-0 rounded-md flex items-center justify-center shadow`}
			>
				<p className="mb-0">
					<strong className="block mb-2">{t("empty.title")}</strong>
					<small>{t("empty.description")}</small>
				</p>
			</Card>
		);
	}

	if (data.status === "new") {
		return (
			<Card
				className={`p-0 rounded-md flex items-center justify-center shadow`}
			>
				<p className="mb-0">
					<strong className="block mb-2">{t("new.title")}</strong>
					<small>{t("new.description")}</small>
				</p>
				<Button className="mt-2" href={data?.verification_url}>
					{t("new.button")}
				</Button>
			</Card>
		);
	}

	return (
		<div className="bg-primary relative overflow-hidden text-white p-6 rounded-md flex flex-1 flex-col shadow min-h-44">
			<div className="flex items-center justify-between">
				<Logo color="white" variant="small" className="max-w-[32px]" />
				<small className="opacity-40">
					{BANK_STATUSES[data?.status || "new"]}
				</small>
			</div>
			<div className="flex items-end flex-1 justify-between">
				<div className="flex flex-col">
					<strong>{data?.account?.account_iban}</strong>
					<span>{data?.account?.account_name}</span>
				</div>
			</div>
		</div>
	);
};

export default BankCard;
