import React from "react";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import NoSubscriptionMiddleware from "src/components/middlewares/NoSubscriptionMiddleware";
import Layout from "src/components/layout/Layout";
import Skeleton from "src/components/Skeleton";
import useGet from "src/hooks/rest/useGet";
import { formatPrice } from "src/lib/formatters";
import ProfileTabs from "src/components/layout/profile-settings-layout/ProfileTabs";
import useRedirectToCustomerPortal from "src/hooks/useRedirectToCustomerPortal";
import useSubscription from "src/hooks/selectors/useSubscription";

export default function BillingUpcomingView() {
	const { t } = useTranslation("payment-view", {
		keyPrefix: "billing-upcoming-view",
	});
	const { isLoading } = useRedirectToCustomerPortal();
	const subscription = useSubscription();

	const [{ data }] = useGet("/auth/subscriptions/plans/upcoming");

	const loading = false;

	if (!subscription?.name) {
		return <NoSubscriptionMiddleware tabs={<ProfileTabs />} />;
	}

	return (
		<Layout
			loading={isLoading}
			className="dashboard-profile"
			title={t("title")}
		>
			<Layout.BackButton to="/profile/billing" label={t("back")} />

			{data && data.length && !loading && (
				<div className="flex flex-col flex-1 gap-8 max-w-md">
					<h2>{t("invoice-current.title")}</h2>
					<div className="mt-8 flex-col flex gap-4">
						{data.map((item: any, index: number) => (
							<div key={`row-${index}`} className="">
								<div className="flex items-center justify-between">
									<strong>{item.description}</strong>
									<strong>{formatPrice(item.price)}</strong>
								</div>
								{item?.items?.length > 0 ? (
									<div className="flex mt-2 ml-4 flex-col gap-2">
										{item.items.map(
											(line: any, i: number) => (
												<NavLink
													to={`/tasks/${item.task_id}/show`}
													key={`line-${index}-${i}`}
													className="flex gap-8 justify-between underline"
												>
													<p>{line.description}</p>
													<p>
														{formatPrice(
															line.price
														)}
													</p>
												</NavLink>
											)
										)}
									</div>
								) : (
									false
								)}
							</div>
						))}
					</div>
				</div>
			)}
			{loading && (
				<div className="flex flex-col flex-1 gap-8">
					<Skeleton className="h-8 w-80 m bg-dark-1100" />
				</div>
			)}
		</Layout>
	);
}
