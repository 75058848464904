import ApiCall from "src/components/ApiCall";
import DownloadButton from "src/components/buttons/DownloadButton";
import withTranslation, {
	Translation,
} from "src/components/hoc/withTranslation";
import Layout from "src/components/layout/Layout";
import Tabs from "src/components/layout/Tabs";
import PaymentList from "src/components/lists/PaymentList";
import IconTotalCard from "src/components/statistics/IconTotalCard";
import { formatPrice } from "src/lib/formatters";

const CompanyPaymentsView = ({ t }: Translation) => {
	return (
		<Layout
			container="xxl"
			breadcrumb={[
				{
					label: t("title"),
					to: "/payments",
				},
			]}
			title={t("title")}
		>
			<ApiCall query={`/company/payments/statistics`}>
				{(data, loading) => {
					if (loading) {
						return (
							<div className="flex flex-wrap items-center gap-4">
								<IconTotalCard.Loading />
								<IconTotalCard.Loading />
							</div>
						);
					}
					return (
						<div className="flex flex-wrap items-center gap-4">
							<IconTotalCard
								label={t("cards.open")}
								icon="far fa-business-time"
							>
								{data?.open?.total || 0}
							</IconTotalCard>
							<IconTotalCard
								label={t("cards.open-worth")}
								icon="far fa-euro-sign"
							>
								{formatPrice(data?.open?.value || 0)}
							</IconTotalCard>
							<div className="flex-1"></div>
							{data?.open?.total > 0 && (
								<DownloadButton
									label={t("cards.open-invoices")}
									api={{
										url: "/company/payments/download-invoices",
										data: {
											type: "open",
										},
									}}
									file={{
										name: t("cards.open-invoices"),
										extension: "zip",
									}}
								/>
							)}
						</div>
					);
				}}
			</ApiCall>
			<div className="mt-8">
				<Tabs tabs={[t("tabs.all"), t("tabs.open"), t("tabs.credit")]}>
					{(tab) => {
						if (tab === t("tabs.open")) {
							return (
								<div className="flex flex-col">
									<PaymentList
										query={`response_type=list&state=open`}
									/>
								</div>
							);
						}
						if (tab === t("tabs.credit")) {
							return (
								<div className="flex flex-col">
									<PaymentList
										query={`response_type=list&state=credit`}
									/>
								</div>
							);
						}

						return (
							<div className="flex flex-col">
								<PaymentList query={`response_type=list`} />
							</div>
						);
					}}
				</Tabs>
			</div>
		</Layout>
	);
};

CompanyPaymentsView.locale = {
	nl: {
		title: "Mijn betalingen",
		"title-refresh": "Verdiensten van {{year}}",
		status: {
			reserved: "Gereserveerd",
			coming: "Onderweg",
			paid: "Uitbetaald",
		},
		cards: {
			open: "Openstaande betalingen",
			"open-worth": "Openstaande waarde",
			"open-invoices": "Open facturen",
		},
		tabs: {
			all: "Alles",
			open: "Open betalingen",
			credit: "Credit",
			reserved: "Gereserveerd",
		},
	},
	en: {
		title: "My payments",
		"title-refresh": "Earnings of {{year}}",
		status: {
			reserved: "Reserved",
			coming: "On the way",
			paid: "Paid out",
		},
		cards: {
			open: "Outstanding payments",
			"open-worth": "Outstanding value",
			"open-invoices": "Open invoices",
		},
		tabs: {
			all: "All",
			open: "Open payments",
			credit: "Credit",
			reserved: "Reserved",
		},
	},
};

export default withTranslation(CompanyPaymentsView);
