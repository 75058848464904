import { atom, useAtom, useAtomValue } from "jotai";
import { useMemo } from "react";

const emptyState: any = {
	q: "",
	type: "all",
	creator_id: undefined,
	match_student_id: undefined,
	tags: [],
	assignees: [],
	teams: [],
	board: undefined,
	hide_completed: false,
};

const filterAtom = atom(emptyState);

export const tasksFilterAtom = atom(emptyState);

export const isFilteredAtom = atom((get) => {
	const filter = get(tasksFilterAtom);
	if (
		filter.type !== "all" ||
		filter?.creator_id ||
		filter?.match_student_id ||
		filter?.tags?.length ||
		filter?.assignees?.length ||
		filter?.teams?.length ||
		filter?.project ||
		filter.hide_completed
	) {
		return true;
	}
	return false;
});

export const isFilteredStateAtom = atom((get) => {
	const filter = get(filterAtom);
	if (filter.type !== "all" || filter?.tags?.length) {
		return filter?.board ? false : true;
	}
	return false;
});

export default function useTasksFilter() {
	const [filter, setFilter] = useAtom(tasksFilterAtom);
	const [filterState, setFilterState] = useAtom(filterAtom);
	const isFiltered = useAtomValue(isFilteredAtom);
	const count = useMemo(() => {
		const items = Object.keys(filter)
			.filter((key) => {
				if (!filter[key] || (key === "type" && filter[key] === "all")) {
					return false;
				}
				return typeof filter[key] === "object"
					? filter[key].length
					: filter[key];
			})
			.map((key) => filter[key]);
		return items.reduce(
			(prev, curr) => prev + (typeof curr === "object" ? curr.length : 1),
			0
		);
	}, [filter]);
	const isFilteredState = useAtomValue(isFilteredStateAtom);
	const set = (value: any, mode: "merge" | "reset" = "merge") => {
		setFilter((state: any) => ({
			...(mode === "merge" ? state : emptyState),
			...value,
		}));
	};

	const clear = (data?: any) => {
		setFilter({
			...emptyState,
			...(data || {}),
		});
	};

	return {
		filter,
		isFiltered,
		count,
		filterState,
		setFilterState,
		isFilteredState,
		actions: {
			set,
			clear,
		},
	};
}
