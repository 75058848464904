const envelope = {
	"envelope-information": {
		title: "Information",
		task: "Task:",
		"task-budget": "Task value:",
		"task-budget-per-hour": "Task value per hour:",
		deadline: "Deadline:",
	},
	status: {
		SIGNED: "Signed",
		VIEWED: "Viewed",
		CREATED: "Created",
		SEND: "Sent",
		"NOT-SEEN": "Not seen",
	},
	"signer-signature-form": {
		"not-allowed": {
			title: "Oops, you cannot sign this agreement",
			description: "Only {{name}} can sign this agreement.",
		},
		button: {
			"already-signed": "Already signed",
			sign: "Sign",
		},
		title: "Sign",
		"edit-signature": "Edit signature",
		"click-to-sign": "Click here to sign",
		"signed-succesfull": "The agreement has been signed successfully",
	},
	"envelope-document": {
		error: {
			title: "The document cannot be loaded",
			text: "Check if your profile is fully filled out. If the problem persists, please contact <1>support@{{tenant.slug}}.nl</1>. Sorry for the inconvenience!",
			subject: "Agreement: {{id}} doesn't seem to work",
		},
		"view-document": "View document",
	},
	"signature-canvas": {
		title: "Sign in the area",
		error: {
			title: "Oops! Something went wrong",
			text: "Failed to save your signature",
		},
	},
	"signature-grid": {
		empty: {
			title: "You don't have any signatures yet",
		},
		button: {
			sign: "Sign document",
			select: "Select signature",
		},
	},
	"signature-modal": {
		title: "Sign",
		tabs: {
			select: "Select",
			create: "Create",
		},
	},
};

export default envelope;
