export default function useChatParams(id: string) {
	const parts = id.split("-");
	if (parts.length < 2) {
		return {};
	}
	if (parts.includes("offer")) {
		return {
			type: "offer",
			offerId: parts[0],
			registrationId: parts[1],
		};
	}

	return {
		type: "task",
		taskId: parts[0],
		registrationId: parts[1],
	};
}
