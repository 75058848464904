import { ALLOWED_EXTENSIONS } from "src/lib/constants";

const alerts = {
	"login-failed": "Inloggen mislukt",
	"something-wrong": "Oeps! Er lijkt iets mis te gaan.",
	file: {
		"to-large": {
			title: "Dit bestand is te groot",
			description: "Upload een bestand kleiner dan 2mb",
		},
		unknown: {
			title: "Dit bestand kan niet worden geupload",
			description: `Alleen deze bestanden zijn toegestaan: ${ALLOWED_EXTENSIONS.join(
				", "
			)}`,
		},
	},
};

export default alerts;
