import { ComponentProps, useState } from "react";
import { cn } from "src/lib/utils";
import withTranslation, {
	Translation,
} from "src/components/hoc/withTranslation";
import Button from "src/components/Button";
import useOnboarding, {
	OnboardingUser,
} from "src/hooks/api/services/auth/useOnboarding";
import List from "src/components/ui/list";
import EmptyState from "src/components/EmptyState";
import { atom } from "jotai";
import useForm from "src/hooks/schemes/useForm";
import Input from "src/components/form/Input";
import Field from "src/components/field/Field";

const formAtom = atom({ email: "" });
const validateAtom = atom({});

const InviteUsers = ({
	t,
	children,
	className,
	onSubmit,
	...rest
}: Translation & ComponentProps<"div">) => {
	const { actions, onboarding, status } = useOnboarding();
	const [users, setUsers] = useState<OnboardingUser[]>(
		onboarding?.users || []
	);
	const {
		form,
		validate,
		actions: formActions,
	} = useForm({
		atoms: {
			form: formAtom,
			validate: validateAtom,
		},
		scheme: {
			email: ["string", "email", "required"],
		},
	});

	const handleSubmit = () => {
		actions.update({ users }).then(() => {
			actions.setStep("create-boards");
		});
	};

	const updateUser = (user: any) => {
		setUsers((users) =>
			users.map((item) => (item.email === user.email ? user : item))
		);
	};

	const onAddUser = (event: any) => {
		event.preventDefault();
		const email = form?.email;
		if (
			!validate.isValid ||
			!email ||
			users.find((user) => user.email === email)
		) {
			return;
		}

		setUsers([...(users as any[]), { email, teams: [] }]);

		formActions.resetForm();
	};

	return (
		<div
			{...rest}
			className={cn("flex flex-col flex-1 gap-4 w-full", className)}
		>
			<div className="w-full flex flex-col gap-4 py-4 max-w-md mx-auto flex-1 rounded-md">
				<div className="flex flex-col flex-1">
					<List columnCount={2} data={users}>
						<List.Head>
							<List.Item className="">
								{t("headers.email")}
							</List.Item>
							<List.Item align="right">
								{t("headers.teams")}
							</List.Item>
							<List.Item></List.Item>
						</List.Head>
						<List.Items>
							{(item) => (
								<List.Row>
									<List.Item>{item.email}</List.Item>
									<List.Item align="right">
										<Field.Team
											value={item?.teams || []}
											placeholder={t("teams.placeholder")}
											onChange={(teams) => {
												updateUser({
													...item,
													teams,
												});
											}}
										/>
									</List.Item>
									<List.Item>
										<i
											onClick={() => {
												if (!item.id) {
													setUsers(
														users.filter(
															(user) =>
																user.email !==
																item.email
														)
													);
												}
											}}
											className={cn(
												"far fa-times",
												item.id
													? "cursor-not-allowed opacity-20"
													: "cursor-pointer"
											)}
										></i>
									</List.Item>
								</List.Row>
							)}
						</List.Items>
						<List.Empty>
							<EmptyState
								className="border-0"
								icon="fas fa-users"
								title={t("empty.title")}
								description={t("empty.description")}
							/>
						</List.Empty>
					</List>
				</div>

				<form onSubmit={onAddUser} className="flex gap-2">
					<Input
						{...formActions.getField("email")}
						placeholder={t("email.placeholder")}
					/>
					<Button className="h-[42px]" small submit type="primary">
						{t("add")}
					</Button>
				</form>
				{/* {(onboarding?.teams || []).map((team: any, index: number) => (
					<InviteUserCard
						key={team.hid}
						{...{ team, users }}
						defaultOpen={index === 0}
						name={team.hid}
						onChange={(users) => setUsers(users)}
					/>
				))}
				{onboarding?.teams?.length === 0 && (
					<InviteUserCard
						{...{ users }}
						name={"GENERAL"}
						onChange={(users) => setUsers(users)}
						defaultOpen
					/>
				)} */}
			</div>
			<div className="flex flex-col mt-8 sticky bottom-4">
				<Button
					onClick={handleSubmit}
					disabled={status !== "idle" || !users.length}
					loading={status === "updating"}
					type="primary"
				>
					{t("continue")}
				</Button>
			</div>
		</div>
	);
};

InviteUsers.locale = {
	nl: {
		email: {
			placeholder: "Email",
		},
		teams: {
			placeholder: "Teams",
		},
		add: "Toevoegen",
		continue: "Doorgaan",
		empty: {
			title: "Geen medewerkers",
			description: "Voeg het email adres via het veld hieronder toe",
		},
		headers: {
			email: "Email",
			teams: "Teams",
		},
	},
	en: {
		email: {
			placeholder: "Email",
		},
		teams: {
			placeholder: "Teams",
		},
		add: "Add",
		continue: "Continue",
		empty: {
			title: "No employees",
			description: "Add the email in the field below",
		},
		headers: {
			email: "Email",
			teams: "Teams",
		},
	},
};

export default withTranslation(InviteUsers);
