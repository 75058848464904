import withTranslation, {
	Translation,
} from "src/components/hoc/withTranslation";
import Indicator from "src/components/layout/Indicator";
import { Switch } from "src/components/ui/switch";
import usePlans from "src/hooks/api/usePlans";
import { cn } from "src/lib/utils";

interface SubscriptionIntervalSwitchProps extends Translation {
	hideDiscountRate?: boolean;
	variant?: "tabs" | "switch";
}

const SubscriptionIntervalSwitch = ({
	t,
	hideDiscountRate,
	variant = "tabs",
}: SubscriptionIntervalSwitchProps) => {
	const { interval, actions } = usePlans();

	if (variant === "switch") {
		return (
			<div
				onClick={() =>
					actions.setInterval(
						interval === "MONTHLY" ? "YEARLY" : "MONTHLY"
					)
				}
				className="flex gap-2 items-center"
			>
				<Switch
					checked={interval === "YEARLY"}
					onClick={() =>
						actions.setInterval(
							interval === "MONTHLY" ? "YEARLY" : "MONTHLY"
						)
					}
				/>
				<div className="flex flex-col">
					<p>{t("switch.title")}</p>
					<p className="opacity-60">{t("switch.description")}</p>
				</div>
			</div>
		);
	}

	return (
		<div className="flex justify-center items-center">
			<div className="rounded-md p-2 gap-2 bg-background dark:bg-accent border border-border flex items-center shadow">
				<IntervalItem
					onClick={() => actions.setInterval("MONTHLY")}
					active={interval === "MONTHLY"}
				>
					{t("monthly")}
				</IntervalItem>
				<IntervalItem
					onClick={() => actions.setInterval("YEARLY")}
					active={interval === "YEARLY"}
				>
					{!hideDiscountRate && (
						<Indicator className="rounded-md absolute w-max -top-4 -right-16 py-3">
							{t("save")}
						</Indicator>
					)}
					{t("yearly")}
				</IntervalItem>
			</div>
		</div>
	);
};

interface IntervalItemProps {
	children?: any;
	active: boolean;
	onClick: () => void;
}

const IntervalItem = ({ children, active, onClick }: IntervalItemProps) => {
	return (
		<div
			className={cn(
				"px-4 py-2 transition-all rounded-md cursor-pointer relative dark:bg-accent text-accent-foreground",
				active &&
					"bg-primary text-primary-foreground dark:bg-border dark:text-border-foreground"
			)}
			{...{ onClick }}
		>
			{children}
		</div>
	);
};

SubscriptionIntervalSwitch.locale = {
	nl: {
		monthly: "Maandelijks",
		yearly: "Jaarlijks",
		save: "Bespaar 30%",
		switch: {
			title: "Jaarlijks betalen",
			description: "Bespaar 30% wanneer je jaarlijks betaalt",
		},
	},
	en: {
		monthly: "Monthly",
		yearly: "Yearly",
		save: "Save 30%",
	},
};

export default withTranslation(SubscriptionIntervalSwitch);
