import { useDispatch } from "react-redux";
import { Navigate, useNavigate } from "react-router-dom";
import Button from "src/components/Button";
import withTranslation, {
	Translation,
} from "src/components/hoc/withTranslation";
import Layout from "src/components/layout/Layout";
import usePost from "src/hooks/rest/usePost";
import useAuth from "src/hooks/selectors/useAuth";
import useAlert from "src/hooks/useAlert";
import { updateProfile } from "src/redux/auth";

const SetupClientView = ({ t }: Translation) => {
	const auth = useAuth();
	const [sendAlert] = useAlert();
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const [setup, isSettingUp] = usePost("/client/clients/setup");

	const handleSetup = async () => {
		try {
			const { data } = await setup({});
			dispatch(updateProfile(data));
			if (data?.links?.client) {
				navigate("/dashboard");
			}
		} catch (error) {
			sendAlert({
				title: t("error.title"),
				text: t("error.text"),
			});
		}
	};

	if (auth?.links?.client) {
		return <Navigate to="/dashboard" />;
	}

	return (
		<Layout hideNav className=" ">
			<div className="flex flex-col max-w-[700px] w-full mx-auto px-4 pt-[25vh]">
				<h1 className="mb-4">{t("title")}</h1>
				<p className="mb-8">{t("description")}</p>
				<Button
					onClick={handleSetup}
					className="w-max"
					loading={isSettingUp}
				>
					{t("button")}
				</Button>
			</div>
		</Layout>
	);
};

SetupClientView.locale = {
	nl: {
		title: "Er is wat mis met je account",
		description:
			"Het lijkt erop alsof je account niet volledig is opgezet. Klik op de onderstaande knop om je account te checken.",
		button: "Check account",
		error: {
			title: "Er is iets misgegaan",
			text: "Raadpleeg support@{{tenant.slug}}.nl",
		},
	},
	en: {
		title: "There's something wrong with your account",
		description:
			"It appears that your account is not fully set up. Click the button below to check your account.",
		button: "Check account",
		error: {
			title: "Something went wrong",
			text: "Contact support@{{tenant.slug}}.nl",
		},
	},
};

export default withTranslation(SetupClientView);
