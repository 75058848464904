import { useTranslation } from "react-i18next";
import { ApiUserAvatar } from "src/api/types";
import Avatar, { AvatarSize } from "src/components/Avatar";

interface TaskMatchProfileProps {
	user: ApiUserAvatar;
	avatar?: {
		size: AvatarSize;
	};
}

const TaskMatchProfile = ({ user, avatar }: TaskMatchProfileProps) => {
	const { t } = useTranslation("tasks", {
		keyPrefix: "task-match-profile",
	});

	const Subtitle = avatar?.size === "xsmall" ? "small" : "strong";

	return (
		<div className="flex gap-3 items-center">
			<div className="flex relative">
				<Avatar {...avatar} src={user.avatar} />
				<div className="absolute -right-2 -bottom-2">
					<i className="fas fa-check text-green"></i>
				</div>
			</div>
			<div className="flex flex-col gap-[3px]">
				<small className="leading-full opacity-60">
					{t("matched-with")}
				</small>
				<Subtitle className="leading-full whitespace-nowrap font-bold">
					{user.full_name}
				</Subtitle>
			</div>
		</div>
	);
};

export default TaskMatchProfile;
