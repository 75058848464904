import { ApiForm } from "src/api/types";
import useApi from "src/hooks/api/utils/useApi";

type Options = {
	company_id: string | number;
	filter?: any;
};

function useConnectionCustomForm(options: Options) {
	const { state, actions, api } = useApi(
		{ id: `CONNECTION_FORMS_${options.company_id}` },
		{
			baseUrl: `/client/forms`,
			defaultStatus: "mounted",
			query: {
				...(options?.filter || {}),
				type: "CONNECTION",
				with: ["fields"],
				company_id: options?.company_id,
			},
		}
	);
	const forms: ApiForm[] = state?.list || [];

	return {
		forms,
		filter: state?.filter,
		status: state.status,
		actions,
		api,
	};
}

export default useConnectionCustomForm;
