import Loader from "src/components/Loader";
import Icon from "src/components/field/utils/Icon";
import { cn } from "src/lib/utils";

interface HeaderItemProps {
	icon?: string;
	label?: string;
	active?: boolean;
	onChange: (event: any) => void;
	disabled?: boolean;
	loading?: boolean;
	variant?: "bordered" | "floating" | "bubble";
}

const HeaderItem = ({
	icon,
	label,
	active,
	onChange,
	disabled,
	loading,
	variant,
}: HeaderItemProps) => {
	const className = cn(
		"bg-transparent text-background-foreground cursor-pointer transition-all",
		variant === "floating" &&
			"text-border hover:text-background-foreground",
		disabled && "opacity-30",
		active && "bg-dark text-white"
	);

	if (loading) {
		return <Loader />;
	}

	if (icon) {
		return <Icon onClick={onChange} {...{ icon, className }} />;
	}
	return <Icon.Text>{label}</Icon.Text>;
};

export default HeaderItem;
