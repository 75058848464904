import { Trans, useTranslation } from "react-i18next";
import Switch from "src/components/form/Switch";
import AddressFormComp from "src/components/forms/AddressForm/v2/AddressForm";
import { TaskFormOptions } from "src/components/tasks/TaskForm";
import Form from "src/components/tasks/TaskForm/Form";
import useTaskForm from "src/hooks/api/tasks/useTaskForm";
import useAuth from "src/hooks/selectors/useAuth";

const AddressForm = ({ hideButtons }: TaskFormOptions) => {
	const { t } = useTranslation("forms", {
		keyPrefix: "address-form",
	});
	const { task, actions, current } = useTaskForm();
	const auth = useAuth();
	const address = auth?.company?.address;
	const onChange = (data: any) => {
		actions.set({
			...data,
		});
	};

	const getNextDisabled = () => {
		if (task?.address?.street) {
			const a = task?.address;
			return !(a?.street && a?.house_number && a?.zipcode && a?.city);
		}

		return !task.is_home && !task.is_office;
	};

	return (
		<Form
			{...{ hideButtons }}
			title={t("title")}
			onSubmit={async () => {
				actions.updateCurrent(current + 1);
			}}
			value={{
				street: task?.address?.street,
				house_number: task?.address?.house_number,
				zipcode: task?.address?.zipcode,
				city: task?.address?.city,
			}}
			nextDisabled={getNextDisabled()}
		>
			{(getInputProps) => {
				return (
					<>
						<Switch
							label={
								<Trans i18nKey="home" {...{ t }}>
									Deze opdracht kan <strong>Thuis</strong>{" "}
									worden uitgevoerd
								</Trans>
							}
							value={task?.is_home || false}
							onChange={(value) => {
								onChange({
									is_home: value,
									is_office: false,
									address: undefined,
								});
							}}
						/>
						<Switch
							label={
								<>
									<Trans
										location={`${address.street} ${address.house_number}`}
										{...{ t }}
										i18nKey="location"
									>
										Deze opdracht wordt op
										<strong>
											{{
												location: `${address.street} ${address.house_number}`,
											}}
										</strong>
										uitgevoerd
									</Trans>
								</>
							}
							value={task?.is_office || false}
							onChange={(value) => {
								onChange({
									is_office: value,
									is_home: false,
									address: undefined,
								});
							}}
						/>

						{!task?.is_home && !task.is_office && (
							<>
								<hr className="border-border my-4" />
								<AddressFormComp
									address={task.address}
									defaultMode={
										task.address ? "manual" : "search"
									}
									onChange={(address) =>
										actions.set({ address })
									}
								/>
								{/* <div className="address-row">
									<Input
										label={t("street")}
										name="street"
										valid={task?.address?.street}
										value={task?.address?.street}
										onChange={(key, value) => {
											actions.set({
												address: {
													...task?.address,
													[key]: value,
												},
											});
										}}
									/>
									<Input
										label={t("house-number")}
										name="house_number"
										valid={task?.address?.house_number}
										value={task?.address?.house_number}
										onChange={(key, value) => {
											actions.set({
												address: {
													...task?.address,
													[key]: value,
												},
											});
										}}
									/>
								</div>
								<Input
									label={t("zipcode")}
									name="zipcode"
									valid={task?.address?.zipcode}
									value={task?.address?.zipcode}
									onChange={(key, value) => {
										actions.set({
											address: {
												...task?.address,
												[key]: value,
											},
										});
									}}
								/>

								<Input
									label={t("city")}
									name="city"
									valid={task?.address?.city}
									value={task?.address?.city}
									onChange={(key, value) => {
										actions.set({
											address: {
												...task?.address,
												[key]: value,
											},
										});
									}}
								/> */}
							</>
						)}
					</>
				);
			}}
		</Form>
	);
};

export default AddressForm;
