import { Route } from "react-router-dom";
import ProtectedRoute from "src/routes/middlewares/ProtectedRoute";
import ReferralsTutorialModal from "src/views/authenticated/referrals/ReferralsTutorialModal";
import ReferralsView from "src/views/authenticated/referrals/ReferralsView";

const ReferralsRoutes = [
	<Route
		element={
			<ProtectedRoute>
				<ReferralsView />
			</ProtectedRoute>
		}
		path="/referrals"
		key="/referrals"
	/>,
];

export const ReferralsModalRoutes = [
	<Route
		element={
			<ProtectedRoute
				variant="modal"
				modal={{
					head: "disabled",
					size: "default",
					expanded: false,
					backUrl: "/referrals",
				}}
			>
				<ReferralsTutorialModal />
			</ProtectedRoute>
		}
		path="/referrals/tutorial"
		key="/referrals/tutorial"
	/>,
];

export default ReferralsRoutes;
