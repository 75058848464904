import moment from "moment";
import withTranslation, {
	Translation,
} from "src/components/hoc/withTranslation";
import {
	HoverCard,
	HoverCardContent,
	HoverCardTrigger,
} from "src/components/ui/hover-card";
import { formatPrice } from "src/lib/formatters";

interface CustomFormPreviewFieldProps extends Translation {
	field?: any;
}

const CustomFormPreviewField = ({ t, field }: CustomFormPreviewFieldProps) => {
	const value = field?.value;
	// const value = values[form.id]?.[field.id]?.value;
	if (!value && field.type !== "BOOLEAN") return null;
	const isImage = value?.mime_type?.startsWith("image");
	return (
		<div className=" ">
			{field.type === "DATE_START_END_TIME" && (
				<span>
					{t("DATE_START_END_TIME", {
						date: moment(value.date).format("DD-MM-YYYY"),
						start: moment(value.start_date).format("HH:mm"),
						end: moment(value.end_date).format("HH:mm"),
					})}
				</span>
			)}
			{field.type === "FILE" && (
				<span>
					{value?.url && (
						<HoverCard>
							<HoverCardTrigger>
								<a
									className="underline"
									target="_blank"
									href={value.url}
									rel="noreferrer"
								>
									{value.filename}
								</a>
							</HoverCardTrigger>
							{isImage && (
								<HoverCardContent
									side="right"
									align="center"
									sideOffset={8}
								>
									<a
										href={value.url}
										target="_blank"
										rel="noreferrer"
									>
										<img
											className="max-w-[260px] w-full"
											src={value.url}
											alt={value.filename}
										/>
									</a>
								</HoverCardContent>
							)}
						</HoverCard>
					)}
				</span>
			)}
			{field.type === "NUMBER" && (
				<span>
					{field.identifier === "price" ? formatPrice(value) : value}
				</span>
			)}

			{field.type === "PRICE" && (
				<span>{value ? formatPrice(value) : "-"}</span>
			)}

			{field.type === "BOOLEAN" && (
				<>
					<span>
						{value ? t("BOOLEAN.true") : t("BOOLEAN.false")}
					</span>
				</>
			)}
			{field.type === "DATE" && (
				<span>{moment(value).format("DD-MM-YYYY")}</span>
			)}

			{![
				"DATE_START_END_TIME",
				"FILE",
				"NUMBER",
				"BOOLEAN",
				"PRICE",
				"DATE",
			].includes(field.type) && (
				<div className="flex flex-col">
					<span>{value}</span>
				</div>
			)}
		</div>
	);
};

CustomFormPreviewField.locale = {
	nl: {
		DATE_START_END_TIME: "Op {{date}} van {{start}} tot {{end}}",
		BOOLEAN: {
			true: "Ja",
			false: "Nee",
		},
	},
	en: {
		DATE_START_END_TIME: "{{date}} from {{start}} to {{end}}",
		BOOLEAN: {
			true: "Yes",
			false: "No",
		},
	},
};

export default withTranslation(CustomFormPreviewField);
