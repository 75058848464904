import { atom } from "jotai";
import { AxiosInstance } from "axios";
import { ApiTimeTracking } from "src/api/types";
import useApi, {
	ApiActions,
	ApiStatus,
	emptyApiState,
	Pagination,
} from "src/hooks/api/utils/useApi";

export type TimerFormat = "hh:mm:ss" | "hh:mm" | "mm" | "mm:ss";

interface ApiTImeTrackingActions extends ApiActions {
	toggle: (taskId: number) => Promise<void>;
	getCurrent: () => Promise<ApiTimeTracking>;
	resetList: () => void;
}

type State = {
	time: ApiTimeTracking;
	list: ApiTimeTracking[];
	status: ApiStatus | "toggling";
	actions: ApiTImeTrackingActions;
	api: AxiosInstance;
	pagination: Pagination;
};

export const timeTrackingAtom: any = atom({
	...emptyApiState,
});

type Options = {
	resetFilter?: boolean;
	filter?: any;
	appendListResult?: boolean;
};

function useTimeTracking(options?: Options): State {
	const { state, actions, api, pagination } = useApi(
		{ id: "TIME_TRACKING" },
		{
			baseUrl: `/client/time-tracking`,
			query: {
				...(options?.filter || {}),
			},
		}
	);
	const time = state?.data || {};

	const resetList = () => {
		actions.set({
			...state,
			list: [],
			pagination: {},
		});
	};

	const getCurrent = async () => {
		actions.set({
			...state,
			status: "loading",
		});
		const time: ApiTimeTracking = await api
			.get("/current?with[]=task")
			.then(({ data }) => data?.data);
		actions.set({
			...state,
			data: time || {},
			status: "idle",
		});
		return time || {};
	};

	const toggle = async (taskId: number) => {
		actions.set({
			...state,
			status: "toggling",
		});
		const time = await api
			.post("/toggle?with[]=task", {
				task_id: taskId,
			})
			.then(({ data }) => data?.data);
		actions.set({
			...state,
			data: !time?.ended_at ? time || {} : {},
			status: "idle",
		});
		return time;
	};

	return {
		time,
		list: state.list || [],
		status: state.status,
		pagination,
		actions: {
			...actions,
			toggle,
			getCurrent,
			resetList,
		},
		api,
	};
}

export default useTimeTracking;
