import { NavLink, useLocation } from "react-router-dom";
import { ApiIcon } from "src/api/types";
import Icon from "src/components/icon/Icon";
import useTheme from "src/hooks/useTheme";
import { cn } from "src/lib/utils";

type Route = Crumb | JSX.Element;

type BreadcrumbProps = {
	routes: Route[];
};

export type Crumb = {
	label: string;
	to: string;
	icon?: ApiIcon;
	isActive?: boolean;
	isLast?: boolean;
	isFirst?: boolean;
};

const Breadcrumb = ({ routes }: BreadcrumbProps) => {
	const { theme: variant } = useTheme();
	return (
		<div className="flex items-center gap-3">
			{routes.map((item: any, index: number) => (
				<div
					key={`${item.to}-${item.label}-${index}-${Math.random()}`}
					className="flex items-center gap-3"
				>
					{item?.to ? (
						<BreadcrumbItem
							isFirst={index === 0}
							{...{ variant, ...item }}
							isLast={routes?.length - 1 === index}
						/>
					) : (
						item
					)}
					{index !== routes.length - 1 && (
						<div className="opacity-60">
							<i className="far fa-angle-right"></i>
						</div>
					)}
				</div>
			))}
		</div>
	);
};

Breadcrumb.defaultProps = {
	variant: "light",
};

interface BreadcrumbItemProps extends Crumb {
	variant: "light" | "dark";
	theme?: "bold";
}

const BreadcrumbItem = ({ label, to, icon, isFirst }: BreadcrumbItemProps) => {
	const location = useLocation();
	const isActive = location.pathname === to;

	return (
		<NavLink
			{...{ to }}
			className={cn(
				"flex items-center hover:text-background-foreground transition-all group tracking-wide",
				!isActive && "opacity-60 hover:opacity-100"
			)}
		>
			{isFirst && (
				<Icon
					iconFontSize={12}
					className={cn(
						"bg-transparent w-7 h-7 hover:bg-transparent"
					)}
					icon={icon ? icon : Icon.getFontAwesome("fa-home")}
				/>
			)}
			<span className="">{label}</span>
		</NavLink>
	);
};

export default Breadcrumb;
