import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import NotFound from "src/views/auth/NotFoundView";
import Layout from "src/components/layout/Layout";
import useGet from "src/hooks/rest/useGet";

const DashboardMandateView = () => {
	const { t } = useTranslation("dashboard-view", {
		keyPrefix: "mandate-view",
	});
	const params = useParams() as any;
	const [{ data }, loading] = useGet(
		`/auth/subscriptions/mandate/${params?.mandate}?with[]=opp_url`
	);

	useEffect(() => {
		if (data && data?.id && data?.opp_url) {
			setTimeout(() => {
				window.location = data?.opp_url;
			}, 2000);
		}
	}, [data]);

	if (!data?.id && !loading) {
		return <NotFound />;
	}

	return (
		<Layout hideNav {...{ loading }}>
			<div className="flex flex-1 justify-center max-w-[340px] gap-4 text-center mx-auto items-center flex-col">
				<h2>{t("title")}</h2>
				<p className="text-center">{t("description")}</p>
				<span className="base-loader"></span>
			</div>
		</Layout>
	);
};

export default DashboardMandateView;
