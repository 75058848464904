import alerts from "src/locale/nl/alerts";
import buttons from "src/locale/nl/buttons";
import common from "src/locale/nl/common";
import form from "src/locale/nl/form";
import forms from "src/locale/nl/forms";
import exampleTasks from "src/locale/nl/hooks/exampleTasks";
import layout from "src/locale/nl/layout";
import lists from "src/locale/nl/lists";
import auth from "src/locale/nl/modules/auth";
import chat from "src/locale/nl/modules/chat";
import elements from "src/locale/nl/modules/elements";
import envelope from "src/locale/nl/modules/envelope";
import middlewares from "src/locale/nl/modules/middlewares";
import network from "src/locale/nl/modules/network";
import offer from "src/locale/nl/modules/offer";
import onboarding from "src/locale/nl/modules/onboarding";
import payments from "src/locale/nl/modules/payments";
import promotion from "src/locale/nl/modules/promotion";
import providers from "src/locale/nl/modules/providers";
import registrations from "src/locale/nl/modules/registrations";
import resume from "src/locale/nl/modules/resume";
import reviews from "src/locale/nl/modules/reviews";
import shareNetwork from "src/locale/nl/modules/shareNetwork";
import subscription from "src/locale/nl/modules/subscription";
import support from "src/locale/nl/modules/support";
import tasks from "src/locale/nl/modules/tasks";
import schemes from "src/locale/nl/schemes";
import authView from "src/locale/nl/views/auth";
import credit from "src/locale/nl/views/credit";
import dashboard from "src/locale/nl/views/dashboard";
import envelopeView from "src/locale/nl/views/envelope";
import hoursView from "src/locale/nl/views/hours";
import inbox from "src/locale/nl/views/inbox";
import networkView from "src/locale/nl/views/network";
import offerView from "src/locale/nl/views/offer";
import paymentView from "src/locale/nl/views/payment";
import profileView from "src/locale/nl/views/profile";
import referralView from "src/locale/nl/views/referral";
import report from "src/locale/nl/views/report";
import resumeView from "src/locale/nl/views/resume";
import reviewView from "src/locale/nl/views/review";
import settingView from "src/locale/nl/views/setting";
import shareNetworkView from "src/locale/nl/views/shareNetwork";
import signerView from "src/locale/nl/views/signer";
import talentView from "src/locale/nl/views/talent";
import taskView from "src/locale/nl/views/task";

const nl = {
	common,
	form,
	schemes,
	layout,
	buttons,
	alerts,
	forms,
	payments,
	onboarding,
	registrations,
	reviews,
	tasks,
	elements,
	chat,
	lists,
	middlewares,
	network,
	offer,
	promotion,
	resume,
	envelope,
	subscription,
	support,
	auth,
	providers,
	report,
	"share-network": shareNetwork,
	"auth-view": authView,
	"credit-view": credit,
	"dashboard-view": dashboard,
	"envelope-view": envelopeView,
	"inbox-view": inbox,
	"network-view": networkView,
	"offer-view": offerView,
	"payment-view": paymentView,
	"profile-view": profileView,
	"referral-view": referralView,
	"resume-view": resumeView,
	"review-view": reviewView,
	"setting-view": settingView,
	"signer-view": signerView,
	"talent-view": talentView,
	"task-view": taskView,
	"share-network-view": shareNetworkView,
	"hours-view": hoursView,
	"example-tasks-hook": exampleTasks,
};

export default nl;
