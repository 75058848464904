const exampleTasks = {
	marketing: {
		title: "Marketing",
		items: [
			{
				title: "Manage social media",
				budget: 250,
			},
			{
				title: "Add websites",
				budget: 200,
			},
			{
				title: "Translate website",
				budget: 500,
			},
			{
				title: "SEO keyword research",
				budget: 200,
			},
			{
				title: "Set and create Facebook/Instagram ad strategy",
				budget: 500,
			},
			{
				title: "Set up Google Ads for improved visibility",
				budget: 250,
			},
		],
	},
	"marketing-development": {
		title: "Marketing / Development",
		items: [
			{
				title: "Scrape data for lead list",
				budget: 300,
			},

			{
				title: "Update webshop with new products",
				budget: 300,
			},

			{
				title: "Set up Google Analytics and social media pixels",
				budget: 500,
			},

			{
				title: "Design and layout email funnel",
				budget: 250,
			},
		],
	},
	"marketing-content": {
		title: "Marketing / Content creation",
		items: [
			{
				title: "Create 15 gifs",
				budget: 300,
			},
			{
				title: "Write 5 SEO optimized blogs",
				budget: 250,
			},
			{
				title: "Transcribe 10 three quarter hour interviews",
				budget: 200,
			},
			{
				title: "Create and manage TikTok content",
				budget: 200,
			},
		],
	},
	sales: {
		title: "Sales",
		items: [
			{
				title: "Make 10 appointments with prospects",
				budget: 500,
			},
			{
				title: "Recruit 10 candidates for interview",
				budget: 250,
			},
			{
				title: "Customer care support",
				budget: 400,
			},
		],
	},
	"administration-legal": {
		title: "Administration / Legal",
		items: [
			{
				title: "Help with processing administration",
				budget: 350,
			},
			{
				title: "Prepare VAT returns",
				budget: 500,
			},
			{
				title: "Check and improve contracts legally",
				budget: 250,
			},
			{
				title: "Create privacy statement and terms and conditions",
				budget: 300,
			},
			{
				title: "Create cookie policy and set up pop up on website",
				budget: 250,
			},
		],
	},
	other: {
		title: "Other",
		items: [
			{
				title: "Mystery shop/visit",
				budget: 25,
			},
		],
	},
};

export default exampleTasks;
