import { DateRangeFilterValue } from "src/components/date-range-filter/Provider";
import useApi from "src/hooks/api/utils/useApi";
import useAuth from "src/hooks/selectors/useAuth";

function useExport(filter: { date: DateRangeFilterValue }) {
	const auth = useAuth();
	const { state, actions, api, pagination } = useApi(
		{ id: "EXPORT" },
		{
			baseUrl: `/${auth.type}/statistics/export`,
			query: {
				...(filter || {}),
				date: [
					filter.date.start.format("YYYY-MM-DD"),
					filter.date?.end?.format("YYYY-MM-DD"),
				],
			},
		}
	);

	return {
		status: state.status,
		pagination,
		actions: {
			...actions,
		},
		api,
	};
}

export default useExport;
