import withTranslation, {
	Translation,
} from "src/components/hoc/withTranslation";
import useUsers from "src/hooks/api/services/users/useUsers";
import useSubscription from "src/hooks/selectors/useSubscription";

const TotalUsersCount = ({ t }: Translation) => {
	const subscription = useSubscription();
	const { users } = useUsers();
	const allowedUsers = subscription?.plan?.users || undefined;

	if (typeof allowedUsers !== "number") {
		return (
			<p className="mb-0">
				{t("users", {
					count: users.filter((item) => item.status === "ACTIVE")
						.length,
				})}
			</p>
		);
	}

	return (
		<p className="mb-0">
			{t("total", {
				count: users?.length,
				total: allowedUsers || 1,
			})}
		</p>
	);
};

TotalUsersCount.locale = {
	nl: {
		total: "{{count}} / {{total}} licensies gebruikt",
		users: "{{count}} actieve licensies",
	},
	en: {
		total: "{{count}} / {{total}} licenses used",
		users: "{{count}} active licenses",
	},
};

export default withTranslation(TotalUsersCount);
