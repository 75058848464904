import moment from "moment";
import { useTranslation } from "react-i18next";
import { ApiOffer, ApiOfferRegistration } from "src/api/types";
import Avatar from "src/components/Avatar";
import Button from "src/components/Button";
import Card from "src/components/Card";
import OfferRegistrationStatus from "src/components/offer/utils/OfferRegistrationStatus";
import ReadMore from "src/components/ReadMore";
import Skeleton from "src/components/Skeleton";

interface OfferCardProps {
	offer: ApiOffer;
	registration: ApiOfferRegistration;
}

const OfferRegistrationCard = ({ registration, offer }: OfferCardProps) => {
	const { t } = useTranslation("buttons");
	return (
		<Card id={`offer-registration-${registration.hid}`} className=" ">
			<div className="flex gap-4 items-start">
				<Avatar
					className="w-20 h-20"
					src={registration.company?.avatar}
				/>
				<div className="flex justify-between flex-1 gap-4 md:flex-row flex-col">
					<div className="flex flex-1 flex-col">
						<strong>
							{registration.company?.name} -{" "}
							{registration.user?.first_name}
						</strong>
						<ReadMore
							className="mb-0"
							text={registration.motivation || ""}
							length={200}
						/>
					</div>
					<div className="flex items-center">
						<OfferRegistrationStatus status={registration.status} />
					</div>
				</div>
			</div>
			<div className="mt-4 flex-wrap flex gap-2 items-end">
				<Button
					indicator={registration?.unread_messages}
					type="dark"
					to={`/offers/${offer.slug}/registration/${registration.hid}`}
				>
					{t("chat")}
				</Button>
				<div className="flex-1"></div>
				<small>{moment(registration.created_at).fromNow()}</small>
			</div>
		</Card>
	);
};

const Loading = () => (
	<Card>
		<div className="flex gap-2">
			<Skeleton className="w-20 h-20" />
			<div className="flex flex-col flex-1 gap-2">
				<Skeleton className="h-8 w-32" />
				<Skeleton className="h-4 w-full" />
				<Skeleton className="h-4 w-[80%]" />
			</div>
		</div>
		<div className="flex gap-2 mt-4">
			<Skeleton className="h-10 w-32" />
			<Skeleton className="h-10 w-32" />
			<Skeleton className="h-10 w-32" />
		</div>
	</Card>
);

OfferRegistrationCard.Loading = Loading;

export default OfferRegistrationCard;
