const buttons = {
	continue: "Doorgaan",
	cancel: "Annuleren",
	confirm: "Bevestigen",
	back: "Terug",
	"back-to-overview": "Terug naar overzicht",
	open: "Openen",
	close: "Sluiten",
	signin: "Inloggen",
	edit: "Aanpassen",
	save: "Opslaan",
	"click-here": "Klik hier",
	denie: "Afwijzen",
	report: "Melden",
	signup: {
		facebook: "Aanmelden met Facebook",
	},
	"read-more": "Lees meer",
	"read-less": "Toon minder",
	task: {
		finish: "Opdracht afronden",
		create: "Opdracht plaatsen",
		"create-concept": "Opslaan als concept",
		publish: "Publiceren",
	},
	"load-more": "Laad meer",
	skip: "Overslaan",
	"previous-step": "Vorige",
	"next-step": "Volgende",
	next: "Volgende",
	prev: "Vorige",
	chat: "Chatten",
	sign: "Ondertekenen",
	view: "Bekijken",
	retry: "Opnieuw",
	signout: "Uitloggen",
	connection: "Connectie",
	favorite: "Favoriet",
};

export default buttons;
