import { useParams } from "react-router-dom";
import ReviewForm from "src/components/forms/ReviewForm";
import withTranslation, {
	Translation,
} from "src/components/hoc/withTranslation";
import Loader from "src/components/Loader";
import useTask from "src/hooks/api/services/tasks/task/useTask";
import useGet from "src/hooks/rest/useGet";
import useModal from "src/hooks/useModal";

const TaskCancelRegistrationModal = ({ t }: Translation) => {
	const { id: taskId, registration: registrationId } = useParams() as any;
	const modal = useModal();
	const { task, status: taskStatus } = useTask({ id: taskId.toString() });
	const [{ data: registration }, loading] = useGet(
		`/client/registrations/${registrationId}`
	);
	return (
		<div className="flex flex-col gap-4 pt-6">
			<div className="flex flex-col gap-2">
				<strong>{t("title")}</strong>
				<p className="opacity-70">{t("description")}</p>
			</div>
			{!loading && taskStatus === "idle" ? (
				<ReviewForm
					taskId={task.id}
					userId={registration?.user?.id}
					companyId={task?.company?.id}
					registrationId={registration?.id}
					onDone={() => {
						modal.onClose();
					}}
					hideDescription
					variant="cancel"
					theme="flat"
					onCancel={() => modal.onClose()}
				/>
			) : (
				<div className="flex flex-col p-6 justify-center items-center">
					<Loader />
				</div>
			)}
		</div>
	);
};

TaskCancelRegistrationModal.locale = {
	nl: {
		title: "Vervelend dat de klus niet is geklaard",
		description:
			"Is de professional niet komen opdagen? Of is de opdracht niet juist uitgevoerd? Geef hieronder aan wat er is misgegaan.",
	},
	en: {
		title: "Unfortunate that the job wasn't completed",
		description:
			"Did the professional not show up? Or was the task not completed correctly? Please indicate below what went wrong.",
	},
};

export default withTranslation(TaskCancelRegistrationModal);
