import { Link } from "react-router-dom";
import withTranslation, {
	Translation,
} from "src/components/hoc/withTranslation";
import useAuth from "src/hooks/selectors/useAuth";
import useWindowSize from "src/hooks/useWindowSize";
import { cn } from "src/lib/utils";

interface SubscribeExternButtonProps extends Translation {
	className?: string;
}

const SubscribeExternButton = ({
	t,
	className,
}: SubscribeExternButtonProps) => {
	const { breakpoint } = useWindowSize();
	const auth = useAuth();
	if (auth.type !== "company") return null;
	return (
		<Link
			to="/subscription?from=head"
			className={cn("flex underline", className)}
			type="link"
		>
			{["sm", "xs"].includes(breakpoint) ? t("upgrade") : t("button")}
		</Link>
	);
};

SubscribeExternButton.locale = {
	nl: {
		button: "Upgrade abonnement",
		upgrade: "Upgrade",
	},
	en: {
		button: "Upgrade subscription",
		upgrade: "Upgrade",
	},
};

export default withTranslation(SubscribeExternButton);
