import { ApiLimits } from "src/api/types";
import useApi from "src/hooks/api/utils/useApi";

function useLimits() {
	const { state, actions, api } = useApi(
		{ id: "LIMITS" },
		{
			baseUrl: `/client/limits`,
			query: {},
		}
	);

	const limits: ApiLimits = state?.data || {};

	return {
		limits,
		status: state.status,
		actions,
		api,
	};
}

export default useLimits;
