import { useTranslation } from "react-i18next";
import { ApiOffer } from "src/api/types";
import Button from "src/components/Button";
import usePost from "src/hooks/rest/usePost";
import useAlert from "src/hooks/useAlert";

interface PublishOfferButtonProps {
	offer: ApiOffer;
	onChange: (offer: ApiOffer) => void;
}

const PublishOfferButton = ({ offer, onChange }: PublishOfferButtonProps) => {
	const { t } = useTranslation("offer", {
		keyPrefix: "publish-offer-button",
	});
	const [publish, loading] = usePost(`/student/offers/${offer.id}/publish`);
	const [sendAlert] = useAlert();
	const onClick = () => {
		sendAlert({
			type: "confirm",
			title: t("confirm.title"),
			text: t("confirm.description"),
			onConfirm: () =>
				publish({}).then((res) => {
					onChange(res.data);
				}),
		});
	};

	return (
		<Button {...{ onClick, loading }} type="green">
			{t("button")}
			{!loading && <i className="fas fa-share ml-2"></i>}
		</Button>
	);
};

export default PublishOfferButton;
