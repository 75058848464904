import { useState } from "react";
import { ApiListTenant } from "src/api/types";
import Loader from "src/components/Loader";
import Tenant, {
	TenantDescription,
	TenantLogo,
	TenantName,
} from "src/components/tenant/tenant";
import TenantSwitcher, {
	TenantSwitcherOption,
	TenantSwitcherOptions,
	TenantSwitcherTrigger,
	TenantSwitcherTriggerDescription,
	TenantSwitcherTriggerIcon,
} from "src/components/tenant/tenant-switcher";
import useTenants from "src/hooks/api/services/tenants/useTenants";
import useLayout from "src/hooks/useLayout";
import { cn } from "src/lib/utils";

const NavTenantSwitcher = () => {
	const { layout } = useLayout();
	const { actions } = useTenants();
	const [isSwitcherOpen, setIsSwitcherOpen] = useState(false);
	const [authenticating, setAuthenticating] = useState<string | null>(null);

	const handleAuthenticateTenant = async (tenant: ApiListTenant) => {
		setAuthenticating(tenant.slug);
		//Sleep for 5 seconds
		// await new Promise((resolve) => setTimeout(resolve, 5000));
		const data = await actions.join(tenant, "FROM_NAV_SWITCHER");
		window.location = data.redirect_url;
		console.log(data);
		setAuthenticating(null);
	};

	return (
		<TenantSwitcher
			open={isSwitcherOpen}
			onOpenChange={(open) => {
				if (authenticating) {
					return;
				}
				setIsSwitcherOpen(open);
			}}
		>
			<TenantSwitcherTrigger>
				{(tenant) => (
					<Tenant id={tenant.slug}>
						<div
							className={cn(
								"transition-all duration-300 p-2 rounded-md border border-border hover:bg-accent flex gap-3 items-center",
								"data-[state=open]:bg-accent",
								layout.navbar.variant !== "default" &&
									"w-[34px] h-[34px]"
							)}
						>
							<TenantLogo
								variant="small"
								color="primary"
								className={cn("w-7")}
							/>
							{layout.navbar.variant === "default" && (
								<>
									<div className="flex flex-col text-sm flex-1">
										<TenantName className="truncate select-none" />
										<TenantSwitcherTriggerDescription />
									</div>
									<TenantSwitcherTriggerIcon />
								</>
							)}
						</div>
					</Tenant>
				)}
			</TenantSwitcherTrigger>
			<TenantSwitcherOptions className="min-w-[324px]">
				{(tenant) => (
					<TenantSwitcherOption
						key={tenant.slug}
						className="cursor-pointer"
						onClick={() => handleAuthenticateTenant(tenant)}
					>
						<Tenant id={tenant.slug}>
							<div className="flex items-center justify-center relative">
								{authenticating === tenant.slug && (
									<div className="absolute inset-0 flex items-center justify-center">
										<Loader />
									</div>
								)}
								<TenantLogo
									variant="small"
									color="primary"
									className={cn(
										"w-7",
										authenticating === tenant.slug &&
											"opacity-50"
									)}
								/>
							</div>
							<div className="flex flex-col">
								<TenantName className="" />
								<TenantDescription className="text-sm opacity-70" />
							</div>
						</Tenant>
					</TenantSwitcherOption>
				)}
			</TenantSwitcherOptions>
		</TenantSwitcher>
	);
};

export default NavTenantSwitcher;
