import { Trans, useTranslation } from "react-i18next";
import { ApiEnvelope } from "src/api/types";
import Button from "src/components/Button";
import useTenant from "src/hooks/api/services/tenants/useTenant";
import useGet from "src/hooks/rest/useGet";
import useAuth from "src/hooks/selectors/useAuth";
import useWindowSize from "src/hooks/useWindowSize";

const base64toBlob = (data: string) => {
	// Cut the prefix `data:application/pdf;base64` from the raw base 64
	const base64WithoutPrefix = data.substr(
		"data:application/pdf;base64,".length
	);

	const bytes = atob(base64WithoutPrefix);
	let length = bytes.length;
	let out = new Uint8Array(length);

	while (length--) {
		out[length] = bytes.charCodeAt(length);
	}

	return new Blob([out], { type: "application/pdf" });
};

interface EnvelopeDocumentProps {
	envelope: ApiEnvelope;
}

const EnvelopeDocument = ({ envelope }: EnvelopeDocumentProps) => {
	const auth = useAuth();
	const { t } = useTranslation("envelope", {
		keyPrefix: "envelope-document",
	});
	const [result, loading] = useGet(
		`/envelopes/${envelope.id}/embed-document`
	);
	const url = result?.data?.url;
	const { tenant } = useTenant();

	if (loading) {
		return <EnvelopeDocument.Loader />;
	}
	return (
		<div className={`flex flex-1 flex-col bg-accent rounded-md`}>
			{!loading && result && typeof result === "string" && (
				<Viewer content={result} />
			)}
			{!loading && result && url && <Viewer content={url} />}
			{!loading && result && typeof result !== "string" && !url && (
				<div className="flex flex-1 flex-col justify-center items-center">
					<p className="mb-2">
						<strong>{t("error.title")}</strong>
					</p>
					<p className="max-w-md mx-auto text-center">
						<Trans {...{ t }} i18nKey="error.text">
							Stuur een mail naar
							<a
								className="underline"
								href={`mailto: support@${
									tenant?.urls?.domain
								}?subject=${t("error.subject", {
									id: envelope?.hid || "",
								})}&reply-to=${auth.email}`}
							>
								support@{tenant?.urls?.domain}
							</a>
						</Trans>
					</p>
				</div>
			)}
		</div>
	);
};

interface ViewerProps {
	content: string;
}

export const Viewer = ({ content }: ViewerProps) => {
	const { t } = useTranslation("envelope", {
		keyPrefix: "envelope-document",
	});
	const blob = !content.startsWith("http") ? base64toBlob(content) : null;
	const url = !blob ? content : URL.createObjectURL(blob);
	const size = useWindowSize();

	return (
		<div className="flex w-full h-full flex-1 rounded-md overflow-auto">
			{size.isPhone ? (
				<div className="flex flex-1 items-center justify-center">
					<Button href={url} target="_blank">
						<i className="fas fa-eye mr-2"></i>
						{t("view-document")}
					</Button>
				</div>
			) : (
				<embed
					type="application/pdf"
					width="100%"
					height="100%"
					className="flex w-full h-full flex-1 rounded-md overflow-auto"
					title="Document"
					id="iframe-document"
					src={url}
				></embed>
			)}
		</div>
	);
};

const Loader = () => {
	const { t } = useTranslation("common");
	return (
		<div
			className={`flex flex-1 flex-col items-center justify-center gap-2`}
		>
			<span className="base-loader"></span>
			<p>{t("loading.text")}</p>
		</div>
	);
};

EnvelopeDocument.Loader = Loader;

export default EnvelopeDocument;
