import { useAtom } from "jotai";
import { atomWithStorage } from "jotai/utils";
import { useEffect } from "react";
import useRememberState from "src/hooks/useRememberState";

const themeAtom = atomWithStorage("theme", "light");

function useTheme() {
	const [theme, onChange] = useAtom(themeAtom);

	const toggle = () => {
		const newTheme = theme === "light" ? "dark" : "light";
		onChange(newTheme);
		setCurrentTheme(newTheme);
	};

	return {
		theme,
		toggle,
	};
}

export function useThemeListener() {
	const [theme] = useRememberState("theme", "light");
	useEffect(() => {
		const isInIframe = window.self !== window.top;
		//Check if there is a theme in the url search params
		const searchParams = new URLSearchParams(window.location.search);
		const themeFromUrl = searchParams.get("theme");

		setCurrentTheme(isInIframe ? themeFromUrl || "light" : theme);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [theme]);
}

function setCurrentTheme(theme: "dark" | "light") {
	const root = window.document.documentElement;
	if (theme?.includes('"')) {
		theme = theme.replace(/"/g, "") as any;
	}

	const remove = theme === "dark" ? "light" : "dark";
	root.classList.remove(remove);
	// if (process.env.NODE_ENV === "development") {
	// 	console.group("Theme");
	// 	console.log(`Remove -> ${remove}`);
	// 	console.log("Add -> ", theme);
	// }
	root.classList.add(theme);
	console.groupEnd();
}

export default useTheme;
