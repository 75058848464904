import React from "react";
import useKanban from "src/hooks/useKanban";

interface GhostItemProps {
	children?: any;
}

const GhostItem = ({ children }: GhostItemProps) => {
	const { ghost } = useKanban();
	// const ghost = useElementSize();

	return (
		<div
			className="flex w-full rounded-md p-8 bg-primary opacity-10 z-[99]"
			// ref={ghost.ref}
			style={{
				height: ghost.height,
				width: ghost.width,
			}}
		></div>
	);
};

export default GhostItem;
