import {
	ComponentProps,
	createContext,
	useContext,
	useEffect,
	useState,
} from "react";
import { ApiCategory } from "src/api/types";
import Button, { ButtonProps } from "src/components/Button";
import Checkbox from "src/components/form/Checkbox";
import useCategories from "src/hooks/api/services/categories/useCategories";
import useResume from "src/hooks/api/services/resume/useResume";
import useTenant from "src/hooks/api/services/tenants/useTenant";
import useAuth from "src/hooks/selectors/useAuth";
import { cn } from "src/lib/utils";

const InterestsContext = createContext({});
const useInterestsContext = () => useContext(InterestsContext) as any;

interface InterestsFormProps {
	children: React.ReactNode;
}

function InterestsForm({ children }: InterestsFormProps) {
	const auth = useAuth();
	const { resume, actions } = useResume({
		rememberKey: auth.id,
	});
	const [interests, setInterests] = useState<ApiCategory[]>(
		resume?.interests || []
	);

	useEffect(() => {
		actions.get(auth.hid).then((resume) => {
			setInterests(resume?.interests || []);
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleSubmit = async () => {
		return actions
			.update({
				interests,
			})
			.then((update: any) => {
				setInterests(update?.interests || []);
			});
	};

	return (
		<InterestsContext.Provider
			value={{ interests, setInterests, handleSubmit }}
		>
			{children}
		</InterestsContext.Provider>
	);
}

const InterestsFormList = ({ className, ...rest }: ComponentProps<"ul">) => {
	const { tenant } = useTenant();
	const { interests, setInterests } = useInterestsContext();
	const { categories, actions } = useCategories({
		filter: {
			variant: "interests",
			...(tenant.slug === "getjobsdone" ? { type: "professional" } : {}),
		},
	});

	useEffect(() => {
		actions.list();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleToggle = (category: ApiCategory) => {
		setInterests((state: ApiCategory[]) => {
			if (
				state.map((item: ApiCategory) => item.id).includes(category.id)
			) {
				return state.filter(
					(item: ApiCategory) => item.id !== category.id
				);
			}
			return [...state, category];
		});
	};

	const isActive = (category: ApiCategory) => {
		return interests
			.map((item: ApiCategory) => item.id)
			.includes(category.id);
	};

	return (
		<ul {...rest} className={cn("flex flex-col gap-3", className)}>
			{categories.map((category) => (
				<li
					key={`category-${category.id}`}
					className={cn(
						"flex p-3 border border-border rounded-md cursor-pointer bg-accent items-center gap-2 hover:border-primary transition-all",
						isActive(category) && "border-primary"
					)}
					onClick={() => handleToggle(category)}
				>
					<Checkbox
						value={isActive(category)}
						onChange={() => handleToggle(category)}
					/>
					<span className="select-none">{category.name}</span>
				</li>
			))}
		</ul>
	);
};

interface SubmitButtonProps extends ButtonProps {
	onSubmitted?: () => void;
}

const InterestsFormSubmitButton = ({
	children,
	onSubmitted,
	...rest
}: SubmitButtonProps) => {
	const { interests, handleSubmit } = useInterestsContext();
	return (
		<Button
			submit
			disabled={(interests || []).length === 0}
			{...rest}
			onClick={async () =>
				handleSubmit().then(() => {
					if (onSubmitted) onSubmitted();
				})
			}
		>
			{children}
		</Button>
	);
};

InterestsForm.List = InterestsFormList;
InterestsForm.SubmitButton = InterestsFormSubmitButton;

export default InterestsForm;
