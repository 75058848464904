import React from "react";
import { ReactNode, createContext, useContext } from "react";
import { ApiTaskKanban } from "src/api/types";
import Card from "src/components/Card";
import TaskKanbanCardActions from "src/components/tasks/kanban/card-elements/TaskKanbanCardActions";
import TaskKanbanCardAssignee from "src/components/tasks/kanban/card-elements/TaskKanbanCardAssignee";
import TaskKanbanCardBoard from "src/components/tasks/kanban/card-elements/TaskKanbanCardBoard";
import TaskKanbanCardCheck from "src/components/tasks/kanban/card-elements/TaskKanbanCardCheck";
import TaskKanbanCardDate from "src/components/tasks/kanban/card-elements/TaskKanbanCardDate";
import TaskKanbanCardTitle from "src/components/tasks/kanban/card-elements/TaskKanbanCardTitle";
import TaskKanbanCardProject from "src/components/tasks/kanban/card-elements/TaskKanbanCardProject";
import TaskKanbanCardRegistrations from "src/components/tasks/kanban/card-elements/TaskKanbanCardRegistrations";
import TaskKanbanCardStatus from "src/components/tasks/kanban/card-elements/TaskKanbanCardStatus";
import TaskKanbanCardTags from "src/components/tasks/kanban/card-elements/TaskKanbanCardTags";
import TaskKanbanCardTimer from "src/components/tasks/kanban/card-elements/TaskKanbanCardTimer";
import TaskKanbanCardType from "src/components/tasks/kanban/card-elements/TaskKanbanCardType";
import useTenant from "src/hooks/api/services/tenants/useTenant";
import { cn } from "src/lib/utils";

type TaskKanbanCardContextProps = {
	task: ApiTaskKanban;
};

export const TaskKanbanCardContext = createContext<TaskKanbanCardContextProps>(
	{} as TaskKanbanCardContextProps
);

export function useTaskKanban() {
	const task = useContext(TaskKanbanCardContext);
	return task;
}

interface TaskKanbanCardProps {
	task: ApiTaskKanban;
	onClick?: (e: any) => void;
	elements?: string[];
	children?: ReactNode;
	head?: ReactNode;
	actions?: ReactNode;
	className?: string;
	contentClassName?: string;
	disableActions?: boolean;
	variant?: "card" | "fragment";
}

const TaskKanbanCard = ({
	task,
	onClick,
	children,
	head,
	actions,
	className,
	contentClassName,
	disableActions: _disableActions,
	variant = "card",
}: TaskKanbanCardProps) => {
	const disableActions = task.type === "GHOST" || _disableActions;
	const {
		tenant: { modules },
	} = useTenant();
	const Wrapper = variant === "card" ? Card : React.Fragment;
	return (
		<TaskKanbanCardContext.Provider value={{ task }}>
			{disableActions ? (
				<Wrapper
					{...(variant === "card"
						? {
								className: cn(
									"cursor-default shadow group/card",
									className
								),
								contentClassName: cn(
									"p-4 gap-2",
									contentClassName
								),
						  }
						: {})}
				>
					{children}
				</Wrapper>
			) : (
				<TaskKanbanCardActions>
					<Wrapper
						{...(variant === "card"
							? {
									className: cn(
										"cursor-pointer shadow group/card",
										className
									),
									contentClassName: cn(
										"p-4 gap-3",
										contentClassName
									),
									onClick,
							  }
							: {})}
					>
						{(head || actions) && (
							<div className="flex items-center justify-between">
								{head ||
									(modules.includes("extern-task") &&
									modules.includes("intern-task") ? (
										<TaskKanbanCardType />
									) : (
										<div />
									))}
								<div className="flex items-center gap-2">
									{!!task.unread_messages && (
										<div className="text-red flex items-center gap-1">
											<i className="fas fa-comments"></i>
											<strong>
												{task.unread_messages}
											</strong>
										</div>
									)}
									{actions}
								</div>
							</div>
						)}

						{children}
					</Wrapper>
				</TaskKanbanCardActions>
			)}
		</TaskKanbanCardContext.Provider>
	);
};

TaskKanbanCard.Type = TaskKanbanCardType;
TaskKanbanCard.Actions = TaskKanbanCardActions;
TaskKanbanCard.Assignee = TaskKanbanCardAssignee;
TaskKanbanCard.Date = TaskKanbanCardDate;
TaskKanbanCard.Title = TaskKanbanCardTitle;
TaskKanbanCard.Registrations = TaskKanbanCardRegistrations;
TaskKanbanCard.Tags = TaskKanbanCardTags;
TaskKanbanCard.Status = TaskKanbanCardStatus;
TaskKanbanCard.ProjectLink = TaskKanbanCardProject;
TaskKanbanCard.Timer = TaskKanbanCardTimer;
TaskKanbanCard.Board = TaskKanbanCardBoard;
TaskKanbanCard.Check = TaskKanbanCardCheck;

export default TaskKanbanCard;
