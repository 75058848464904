import { motion } from "framer-motion";
import React, {
	ComponentProps,
	createContext,
	useContext,
	useState,
} from "react";
import { cn } from "src/lib/utils";

type AccordionContextType = {
	open: boolean;
	onOpenChange: React.Dispatch<React.SetStateAction<boolean>>;
};

const AccordionContext = createContext<AccordionContextType>(
	{} as AccordionContextType
);

function useAccordion() {
	const props = useContext(AccordionContext);
	return props;
}

interface AccordionProps extends ComponentProps<"div"> {
	defaultOpen?: boolean;
}

const Accordion = ({ defaultOpen, className, ...rest }: AccordionProps) => {
	const [open, setOpen] = useState(defaultOpen || false);
	return (
		<AccordionContext.Provider
			value={{
				open,
				onOpenChange: setOpen,
			}}
		>
			<div
				{...rest}
				className={cn(
					"bg-card border border-border rounded-md overflow-hidden",
					className
				)}
			/>
		</AccordionContext.Provider>
	);
};

const Head = ({ children, className, ...rest }: ComponentProps<"div">) => {
	const { onOpenChange } = useAccordion();
	return (
		<div
			{...rest}
			className={cn(
				"p-4 cursor-pointer transition-all flex items-center justify-between",
				className
			)}
			onClick={() => onOpenChange((state) => !state)}
		>
			{children}
		</div>
	);
};

const Icon = ({ className, ...rest }: ComponentProps<"i">) => {
	const { open } = useAccordion();
	return (
		<motion.div animate={{ rotate: open ? 180 : 0 }}>
			<i
				{...rest}
				className={cn("far leading-full fa-angle-down", className)}
			></i>
		</motion.div>
	);
};

const Content = ({ className, ...rest }: ComponentProps<"div">) => {
	const { open } = useAccordion();
	return (
		<motion.div
			initial="closed"
			animate={open ? "open" : "closed"}
			variants={{
				closed: {
					height: 0,
				},
				open: {
					height: "auto",
				},
			}}
		>
			<div
				{...rest}
				className={cn("flex flex-col p-4 pt-0", className)}
			/>
		</motion.div>
	);
};

const ValidOrPendingIcon = ({
	className,
	valid,
	...rest
}: ComponentProps<"div"> & { valid: boolean }) => {
	return (
		<div
			{...rest}
			className={cn(
				"w-8 h-8 rounded-md bg-accent flex items-center justify-center text-sm",
				className,
				valid && "bg-green-300 text-green"
			)}
		>
			{valid ? (
				<i className="fas fa-check"></i>
			) : (
				<i className="fas fa-clock"></i>
			)}
		</div>
	);
};

const Title = ({ children, className, ...rest }: ComponentProps<"p">) => {
	return (
		<p {...rest} className={cn("select-none font-regular", className)}>
			{children}
		</p>
	);
};

Accordion.Head = Head;
Accordion.Icon = Icon;
Accordion.ValidOrPendingIcon = ValidOrPendingIcon;
Accordion.Content = Content;
Accordion.Title = Title;

export default Accordion;
