import { useTranslation } from "react-i18next";
import { ApiOffer } from "src/api/types";
import Button from "src/components/Button";
import useDelete from "src/hooks/rest/useDelete";
import useAlert from "src/hooks/useAlert";

interface ArchiveOfferButtonProps {
	offer: ApiOffer;
	onChange: () => void;
}

const ArchiveOfferButton = ({ offer, onChange }: ArchiveOfferButtonProps) => {
	const { t } = useTranslation("offer", {
		keyPrefix: "archive-offer-button",
	});
	const [deleteOffer, loading] = useDelete(`/student/offers/${offer?.id}`);
	const [sendAlert] = useAlert();
	const onClick = () => {
		sendAlert({
			type: "confirm",
			title: t("confirm.title"),
			text: t("confirm.description"),
			onConfirm: () => deleteOffer().then(onChange),
		});
	};

	return (
		<Button {...{ onClick, loading }} type="gray" iconOnly>
			{!loading && <i className="fas fa-archive"></i>}
		</Button>
	);
};

export default ArchiveOfferButton;
