import Stars from "src/components/Stars";
import useUserCardContext from "../UserCardContext";

function UserCardName() {
	const { user } = useUserCardContext();
	if (!user?.reviews?.rating) return <></>;
	return <Stars rating={Number(user?.reviews?.rating) || 0} />;
}

export default UserCardName;
