import { motion } from "framer-motion";
import { ComponentProps } from "react";
import Text from "src/components/ui/text";
import useLayout from "src/hooks/useLayout";
import useRememberState from "src/hooks/useRememberState";
import useWindowSize from "src/hooks/useWindowSize";
import { cn } from "src/lib/utils";

type FuncLabel = (isOpen: boolean) => JSX.Element;

interface NavItemGroupProps extends ComponentProps<"div"> {
	label?: FuncLabel | JSX.Element | string;
	name: string;
	variant?: "stacked" | "closeable";
	color?: string;
	defaultOpen?: boolean;
	alwaysOpen?: boolean;
}

const NavItemGroup = ({
	label,
	name,
	className,
	children,
	color,
	defaultOpen,
	alwaysOpen,
	variant = "closeable",
	...rest
}: NavItemGroupProps) => {
	const { layout } = useLayout();
	const { breakpoint } = useWindowSize();
	const [open, setOpen] = useRememberState(
		name,
		defaultOpen ? "true" : "false"
	);
	const isOpen =
		alwaysOpen ||
		open === "true" ||
		layout.navbar.variant === "minimal" ||
		breakpoint === "lg";

	if (variant === "stacked") {
		return (
			<div {...rest} className={cn("flex flex-col gap-3", className)}>
				{layout.navbar.variant === "default" && (
					<div className="flex flex-col lg:hidden xl:flex">
						<small className="opacity-70">
							{typeof label === "function"
								? label(open === "true")
								: label}
						</small>
					</div>
				)}
				<div className="flex flex-col gap-1.5">{children}</div>
			</div>
		);
	}

	return (
		<div
			{...rest}
			className={cn("rounded-md transition-colors", className)}
		>
			{layout.navbar.variant === "default" && (
				<div
					onClick={() => setOpen(open === "true" ? "false" : "true")}
					className={cn(
						"flex items-center lg:hidden xl:flex gap-1 opacity-70 hover:opacity-100 rounded-md p-0.5 border border-transparent group",
						!alwaysOpen && "cursor-pointer",
						open === "true" && "opacity-100"
					)}
				>
					{!alwaysOpen && (
						<div className="mr-1 flex items-center">
							<motion.i
								animate={{
									rotate: isOpen ? 0 : -90,
								}}
								className="far fa-angle-down text-[11px]"
							></motion.i>
						</div>
					)}
					<div className="flex h-7">
						{typeof label === "function"
							? label(open === "true")
							: label}
					</div>
				</div>
			)}
			<motion.div
				animate={{
					height: isOpen ? "auto" : 0,
					opacity: isOpen ? 1 : 0,
					scale: isOpen ? 1 : 0.9,
				}}
				className={cn(
					"flex flex-col",
					layout.navbar.variant !== "minimal" &&
						!isOpen &&
						"overflow-hidden"
				)}
			>
				<div className={cn("flex flex-col gap-2 pb-4 mt-1")}>
					{children}
				</div>
			</motion.div>
		</div>
	);
};

interface LabelProps extends ComponentProps<"div"> {
	color?: string;
}

const Label = ({ className, children, color, ...rest }: LabelProps) => {
	return (
		<div
			{...rest}
			className={cn(
				"leading-full rounded-md flex gap-2 items-center",
				className
			)}
		>
			<Text.Eyebrow className="leading-full opacity-100 text-[13px]">
				{children}
			</Text.Eyebrow>
		</div>
	);
};

NavItemGroup.Label = Label;

export default NavItemGroup;
