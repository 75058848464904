import { Route } from "react-router-dom";
import ProtectedRoute from "src/routes/middlewares/ProtectedRoute";
import DashboardSignerPreview from "src/views/authenticated/signer/SignerPreview";
import DashboardSignerView from "src/views/authenticated/signer/SignerView";

const SignerRoutes = [
	<Route
		element={
			<ProtectedRoute>
				<DashboardSignerView />
			</ProtectedRoute>
		}
		path="/signer/:id"
		key="/signer/:id"
	/>,
	<Route
		element={
			<ProtectedRoute type="company">
				<DashboardSignerPreview />
			</ProtectedRoute>
		}
		path="/signer/:task/registration/:registration/preview"
		key="/signer/:task/registration/:registration/preview"
	/>,
];

export default SignerRoutes;
