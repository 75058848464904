import { useState } from "react";
import Button from "src/components/Button";
import SearchInput from "src/components/form/SearchInput";
import withTranslation, {
	Translation,
} from "src/components/hoc/withTranslation";
import Layout from "src/components/layout/Layout";
import VacanciesTable from "src/components/vacancies/vacancies-table";

const VacanciesView = ({ t }: Translation) => {
	const [search, setSearch] = useState<string>();
	return (
		<Layout
			className=" "
			title={t("title")}
			breadcrumb={[
				{
					label: t("title"),
					to: "/vacancies",
				},
			]}
			actions={
				<>
					<SearchInput
						value={search}
						onChange={(value) => setSearch(value)}
					/>
					<Button to="/vacancies/create">
						<i className="fas fa-plus mr-2"></i>
						{t("actions.create")}
					</Button>
				</>
			}
			container="xxl"
		>
			<VacanciesTable />
		</Layout>
	);
};

VacanciesView.locale = {
	nl: {
		title: "Vacatures",
		actions: {
			create: "Vacature",
		},
	},
	en: {
		title: "Vacancies",
		actions: {
			create: "Vacancy",
		},
	},
};

export default withTranslation(VacanciesView);
