import React from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import Button from "src/components/Button";
import Card from "src/components/Card";
import useTenant from "src/hooks/api/services/tenants/useTenant";
import usePost from "src/hooks/rest/usePost";
import useAuth from "src/hooks/selectors/useAuth";

interface RegistrationPaymentUrlProps {
	registration: string;
	paymentId?: string | number;
	variant?: "buttons";
}

const RegistrationPaymentUrl = ({
	registration,
	paymentId,
	variant,
}: RegistrationPaymentUrlProps) => {
	const { t } = useTranslation("payments", {
		keyPrefix: "registration-payment-url",
	});
	const auth = useAuth();
	const [requestPayment, loading] = usePost(
		`/company/registrations/${registration}/request-payment`
	);
	const permissions = auth?.company?.permissions || [];
	const location = useLocation();
	const { tenant } = useTenant();
	const redirect_url = tenant?.urls?.account + location.pathname;

	if (variant === "buttons") {
		return (
			<>
				{paymentId && (
					<Button
						small
						type="dark"
						to={`/payments/${paymentId}/refresh`}
						{...{ loading }}
					>
						{t("ideal")}
					</Button>
				)}

				{permissions.includes("pay_with_invoice") && (
					<Button
						type="dark"
						onClick={() =>
							requestPayment({
								type: "invoice",
								redirect_url,
							}).then(({ data }: any) => {
								window.location = data.redirect_url;
							})
						}
						small
						{...{ loading }}
					>
						{t("invoice")}
					</Button>
				)}
			</>
		);
	}

	return (
		<Card className="signing-status">
			<p
				style={{
					marginBottom: 10,
				}}
				className="highlight-green"
			>
				{t("title")}
			</p>
			<p>{t("not-paid")}</p>
			{auth.type === "company" && (
				<div style={{ marginTop: 15 }} className="buttons-stacked">
					{paymentId && (
						<Button
							small
							type="dark"
							to={`/payments/${paymentId}/refresh`}
							{...{ loading }}
						>
							{t("ideal")}
						</Button>
					)}

					{permissions.includes("pay_with_invoice") && (
						<Button
							type="dark"
							onClick={() =>
								requestPayment({
									type: "invoice",
									redirect_url,
								}).then(({ data }: any) => {
									window.location = data.redirect_url;
								})
							}
							small
							{...{ loading }}
						>
							{t("invoice")}
						</Button>
					)}
				</div>
			)}
		</Card>
	);
};

export default RegistrationPaymentUrl;
