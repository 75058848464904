import { ApiExperience } from "src/api/types";
import useApi from "src/hooks/api/utils/useApi";
import useAuth from "src/hooks/selectors/useAuth";

type Options = {
	filter?: any;
};

function useWorkExperiences(options?: Options) {
	const auth = useAuth();
	const { state, actions, api } = useApi(
		{ id: `WORK_EXPERIENCES:${auth.id}` },
		{
			baseUrl: `/resume/${auth.id}/experiences`,
			query: {
				state: "all",
				type: "job",
				...(options?.filter || {}),
			},
		}
	);

	const workExperiences: ApiExperience[] = state?.list || [];

	return {
		workExperiences,
		workExperience: state?.data,
		status: state.status,
		actions: {
			...actions,
		},
		api,
	};
}

export default useWorkExperiences;
