import { Moment } from "moment";
import { useEffect, useState } from "react";
function roundToNearest(num: number, nearest: number) {
	return Math.round(num / nearest) * nearest;
}
interface Props {
	start: Moment;
	end: Moment;
	dates: Moment[];
	onDateChange: (start: Moment, end: Moment) => void;
}

export default function useWeekTimePosition({
	start,
	end,
	dates,
	onDateChange,
}: Props) {
	const getColumn = (date: Moment) => {
		return dates.findIndex((item) => item.isSame(date, "day")) + 1;
	};
	const getValueByStartAndEndDate = (start: Moment, end: Moment) => {
		//Get the start in minutes
		const startMinutes = start.get("minutes");
		const startMinutesRounded = roundToNearest(startMinutes, 15);
		const rowStart = start.get("hours") * 4 + startMinutesRounded / 15;
		//Get the end in minutes
		const endMinutes = end.get("minutes");
		const endMinutesRounded = roundToNearest(endMinutes, 15);
		const rowEnd = end.get("hours") * 4 + endMinutesRounded / 15;
		//Get the total amount of minutes in week view
		// const minutesOccupied = (rowEnd - rowStart) * 15;

		return {
			column: {
				start: getColumn(start),
				end: getColumn(end),
			},
			rows: {
				start: rowStart,
				end: rowEnd,
			},
		};
	};

	const [position, setPosition] = useState(() =>
		getValueByStartAndEndDate(start, end)
	);

	const moveY = (type: "start" | "end", value: number) => {
		const newStart = start.clone();
		const newEnd = end.clone();

		const AMOUNT = 15;

		if (type === "start" && value !== 0) {
			const minutes = newStart.get("minutes");
			const rounded = roundToNearest(minutes, 15);
			let amount = rounded !== minutes ? rounded - minutes : AMOUNT;
			newStart.set("minutes", minutes + (value >= 1 ? amount : -amount));
		}

		if (type === "end" && value !== 0) {
			const minutes = newEnd.get("minutes");
			const rounded = roundToNearest(minutes, 15);
			let amount = rounded !== minutes ? rounded - minutes : AMOUNT;
			newEnd.set("minutes", minutes + (value >= 1 ? amount : -amount));
		}

		onDateChange(newStart, newEnd);
	};

	useEffect(() => {
		const newPosition = getValueByStartAndEndDate(start, end);
		setPosition(newPosition);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [start, end]);

	return {
		position,
		actions: {
			moveY,
		},
	};
}
