import React from "react";
import { ApiOfferRegistration } from "src/api/types";
import Avatar from "src/components/Avatar";
import useAuth from "src/hooks/selectors/useAuth";

interface HeadProps {
	registration: ApiOfferRegistration;
}

const Head = ({ registration }: HeadProps) => {
	const { type } = useAuth();
	const avatar =
		type === "student"
			? registration?.company?.avatar
			: registration?.offer?.user?.avatar;
	const name =
		type === "student"
			? registration?.company?.name
			: registration?.offer?.user?.full_name;

	return (
		<div className="flex flex-col justify-center items-center gap-4">
			<Avatar className="w-24 h-24" src={avatar} alt={name} />
			<div className="flex flex-col justify-center items-center">
				<h2 className="mb-0 text-[24px]">{name}</h2>
				{type === "student" && (
					<p className="mb-0">{registration?.user?.full_name}</p>
				)}
			</div>
		</div>
	);
};

export default Head;
