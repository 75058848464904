import React from "react";
import { ApiMedia } from "src/api/types";

interface FileUploadItemIconProps {
	media: ApiMedia;
}
const FileUploadItemIcon = ({ media }: FileUploadItemIconProps) => {
	let icon = "fa-file";

	if (media.mime_type.startsWith("image")) {
		icon = "fa-image";
	}

	if (["pdf"].includes(media.type)) {
		icon = "fa-file-pdf";
	}

	if (["csv"].includes(media.type)) {
		icon = "fa-file-csv";
	}

	if (["xlsx", "xls"].includes(media.type)) {
		icon = "fa-file-excel";
	}

	if (["ppt", "pptx"].includes(media.type)) {
		icon = "fa-file-powerpoint";
	}

	return (
		<div className="">
			<i className={`far ${icon}`} />
		</div>
	);
};

export default FileUploadItemIcon;
