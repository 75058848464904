import withTranslation, {
	Translation,
} from "src/components/hoc/withTranslation";
import Switch from "src/components/form/Switch";
import Form from "src/components/tasks/TaskForm/Form";
import useTaskForm from "src/hooks/api/tasks/useTaskForm";
import { TaskFormOptions } from "src/components/tasks/TaskForm";

type Field = "anonymous" | "private" | "pause-registration";

interface VisibilityFormProps extends TaskFormOptions, Translation {
	fields?: Field[];
}

const VisibilityForm = ({
	t,
	hideButtons,
	fields = ["anonymous", "private"],
}: VisibilityFormProps) => {
	const { task, actions, current } = useTaskForm();

	const getNextDisabled = () => {
		return !(task.start_date && (task.category_id || task.category));
	};

	return (
		<Form
			{...{ hideButtons }}
			title={t("title")}
			value={{
				is_private: task.is_private,
			}}
			onSubmit={async () => {
				actions.updateCurrent(current + 1);
			}}
			nextDisabled={getNextDisabled()}
		>
			{(getInputProps) => {
				return (
					<>
						{fields.includes("anonymous") && (
							<Switch
								label={t("anonymous.label")}
								subLabel={t("anonymous.sub-label")}
								value={task?.is_anonymous || false}
								onChange={(value) => {
									actions.set({
										is_anonymous: value,
									});
								}}
							/>
						)}
						{fields.includes("private") && (
							<Switch
								label={t("private.label")}
								subLabel={t("private.sub-label")}
								value={task?.is_private || false}
								onChange={(value) => {
									actions.set({
										is_private: value,
									});
								}}
							/>
						)}
						{fields.includes("pause-registration") && (
							<Switch
								label={t("pause-registration.label")}
								subLabel={t("pause-registration.sub-label")}
								value={task?.is_paused || false}
								onChange={(value) => {
									actions.set({
										is_paused: value,
									});
								}}
							/>
						)}
					</>
				);
			}}
		</Form>
	);
};

VisibilityForm.locale = {
	nl: {
		anonymous: {
			label: "Verberg mijn bedrijfsnaam",
			"sub-label":
				"Je bedrijfsnaam wordt niet getoond op de aanmeldpagina",
		},
		private: {
			label: "Opdracht niet promoten",
			"sub-label": "Zo kunnen niet al onze professionals jouw job zien",
		},
		"pause-registration": {
			label: "Aanmeldingen pauzeren",
			"sub-label":
				"Het is voor professionals niet mogelijk om te reageren op de job",
		},
	},
	en: {
		anonymous: {
			label: "Hide my company name",
			"sub-label":
				"Your company name will not be visible on the registration page",
		},
		private: {
			label: "Do not promote task",
			"sub-label": "So not all our professionals can see your job",
		},
		"pause-registration": {
			label: "Pause registrations",
			"sub-label": "Professionals won't be able to signup to the task",
		},
	},
};

export default withTranslation(VisibilityForm);
