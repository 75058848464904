import { ComponentProps, useMemo } from "react";
import { ApiUserAvatar, ApiVacancy } from "src/api/types";
import Avatars, {
	Avatar,
	AvatarsItems,
	AvatarsRotateWrapper,
	AvatarsTooltipWrapper,
} from "src/components/avatars";
import { useCarousel } from "src/components/ui/carousel";
import useWindowSize from "src/hooks/useWindowSize";
import { cn } from "src/lib/utils";

interface VacancyRegistrationAvatarsProps {
	vacancy: ApiVacancy;
	currentIndex?: number;
	className?: string;
	onClick?: (avatar: ApiUserAvatar, index: number) => void;
	avatarSize?: "small" | "regular";
}

const VacancyRegistrationAvatars = ({
	className,
	vacancy,
	currentIndex,
	onClick,
	avatarSize = "regular",
	...rest
}: VacancyRegistrationAvatarsProps &
	Omit<ComponentProps<"div">, "onClick">) => {
	const { isPhone } = useWindowSize();
	const restCount = useMemo(() => {
		if (
			!vacancy.registrations?.count ||
			!vacancy.registrations?.users.length
		)
			return 0;
		return (
			vacancy.registrations?.count - vacancy.registrations?.users.length
		);
	}, [vacancy.registrations]);
	if (vacancy.registrations?.users.length === 0) {
		return null;
	}

	return (
		<Avatars
			{...rest}
			{...{ className }}
			avatars={vacancy.registrations?.users || []}
		>
			<AvatarsItems>
				{(avatar, index) => (
					<AvatarsRotateWrapper
						index={index}
						onClick={() => onClick?.(avatar, index)}
						key={`avatar-${avatar.id}`}
					>
						<AvatarsTooltipWrapper>
							<Avatar
								size={isPhone ? "small" : avatarSize}
								className={cn(
									"transition-all duration-300",
									currentIndex === index && "border-primary"
								)}
							/>
						</AvatarsTooltipWrapper>
					</AvatarsRotateWrapper>
				)}
			</AvatarsItems>
			{restCount > 0 && (
				<strong className="hidden md:block">+{restCount}</strong>
			)}
		</Avatars>
	);
};

export const VacancyRegistrationAvatarsCarouselNavigator = ({
	vacancy,
}: {
	vacancy: ApiVacancy;
}) => {
	const { currentSlide, scrollTo } = useCarousel();

	return (
		<VacancyRegistrationAvatars
			{...{ vacancy }}
			className="gap-2"
			onClick={(avatar, index) => {
				console.log(index);
				scrollTo(index);
			}}
			currentIndex={currentSlide - 1}
		/>
	);
};

export default VacancyRegistrationAvatars;
