import { useRef, useState } from "react";
import withTranslation, {
	Translation,
} from "src/components/hoc/withTranslation";
import Icon from "src/components/icon/Icon";
import { cn } from "src/lib/utils";

interface CardSearchProps extends Translation {
	value: string;
	onChange: (value: string) => void;
	placeholder?: string;
	className?: string;
}

export const CardSearch = ({
	t,
	value,
	onChange,
	placeholder,
	...rest
}: CardSearchProps) => {
	const input = useRef<any>();
	const [visible, setVisible] = useState(false);

	const toggle = () => {
		setVisible(!visible);
	};

	return (
		<div
			className={cn(
				"flex items-center gap-2 rounded-md",
				visible && "bg-accent",
				rest?.className
			)}
		>
			<Icon
				onClick={toggle}
				iconFontSize={14}
				className={cn("cursor-pointer text-placeholder")}
				icon={{
					font_awesome: {
						name: "far fa-search",
					},
				}}
			/>
			{(visible || value) && (
				<input
					ref={input}
					type="text"
					name="search"
					value={value || ""}
					autoFocus
					onBlur={() => {
						if (!value) {
							setVisible(false);
						}
					}}
					onChange={(e) => onChange(e?.target?.value)}
					className="placeholder-placeholder bg-transparent text-sm"
					placeholder={placeholder || t("placeholder")}
				/>
			)}
		</div>
	);
};

CardSearch.locale = {
	nl: {
		placeholder: "Zoeken...",
	},
	en: {
		placeholder: "Search...",
	},
};

export default withTranslation(CardSearch);
