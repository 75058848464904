import axios from "axios";
import { useMemo, useState } from "react";
import Button from "src/components/Button";
import withTranslation, {
	Translation,
} from "src/components/hoc/withTranslation";
import ResumeSectionTitle from "src/components/resume/resume-detail/ResumeSectionTitle";
import CreateVideoPitch from "src/components/video-pitch/CreateVideoPitch";
import VideoPitchPlayer from "src/components/video-pitch/VideoPitchPlayer";
import useResume from "src/hooks/api/services/resume/useResume";
import useAuth from "src/hooks/selectors/useAuth";
import useAlert from "src/hooks/useAlert";
import useBackUrl from "src/hooks/useBackUrl";
import { cn } from "src/lib/utils";
import useResumeDetailContext from "../ResumeDetailContext";

const INTERVAL_DURATION = 4000;

interface ResumeBioProps extends Translation {
	editable?: boolean;
}

function ResumeBio({ t, editable }: ResumeBioProps) {
	const auth = useAuth();
	const { resume } = useResumeDetailContext();
	const backUrl = useBackUrl();
	const videoPitch = resume.video_pitch;
	const [isDeletingVideoPitch, setIsDeletingVideoPitch] = useState(false);
	const [sendAlert] = useAlert();
	const { actions } = useResume({
		rememberKey: auth.id,
	});
	const IS_PROCESSING = ["PROCESSING", "UPLOADING"].includes(
		videoPitch?.status || ""
	);

	const aspectRatio = useMemo(() => {
		if (!videoPitch && editable) return "PORTRAIT";
		if (IS_PROCESSING) {
			return "PORTRAIT";
		}

		return videoPitch?.id ? videoPitch?.thumbnail?.aspect_ratio : null;
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [videoPitch]);

	const getStatus = async () => {
		const newResume = await actions.get(auth.id);
		if (newResume.video_pitch?.status !== "READY") {
			setTimeout(() => {
				getStatus();
			}, INTERVAL_DURATION);
		}
	};

	const deleteVideoPitch = async () => {
		sendAlert({
			type: "confirm",
			title: t("confirm.title"),
			text: t("confirm.description"),
			onConfirm: () => {
				setIsDeletingVideoPitch(true);
				axios
					.delete(`/video-pitches/${videoPitch?.id}`)
					.then(async () => {
						// setVideoPitch(undefined);
						await actions.get(auth.id);
					})
					.finally(() => setIsDeletingVideoPitch(false));
			},
		});
	};

	// if (!resume?.bio && !videoPitch && resume.id === auth.id && editable) {
	// 	return (
	// 		<>
	// 			<div
	// 				className={`flex flex-col text-center ${
	// 					!editable && "gap-4"
	// 				}`}
	// 			>
	// 				<h3 className="mb-2">{t("empty.title")}</h3>
	// 				<p>{t("empty.description")}</p>
	// 				<div className="flex flex-1 items-center gap-3 justify-center mt-6">
	// 					<Button
	// 						xsmall
	// 						to={`/profile/resume/create/general?${backUrl}`}
	// 					>
	// 						<i className="fas fa-pencil mr-1"></i>
	// 						{t("empty.buttons.description")}
	// 					</Button>
	// 					<CreateVideoPitch
	// 						type="RESUME"
	// 						variant="button"
	// 						onCompleted={getStatus}
	// 					>
	// 						{({ loading }) => (
	// 							<Button xsmall {...{ loading }}>
	// 								<i className="fas fa-video mr-1"></i>
	// 								{t("empty.buttons.video")}
	// 							</Button>
	// 						)}
	// 					</CreateVideoPitch>
	// 				</div>
	// 			</div>
	// 		</>
	// 	);
	// }

	if (!resume?.bio && !videoPitch) {
		return <></>;
	}

	return (
		<div className={`flex flex-col gap-3`}>
			<ResumeSectionTitle>{t("title")}</ResumeSectionTitle>
			<div
				className={cn(
					"flex flex-col gap-4",
					aspectRatio === "PORTRAIT" && "flex-col gap-4 md:flex-row",
					videoPitch && !resume?.bio && "justify-center"
				)}
			>
				{!videoPitch && editable && (
					<CreateVideoPitch
						onCompleted={getStatus}
						type="RESUME"
						className="md:max-w-[320px]"
					/>
				)}
				{videoPitch && (
					<div className="flex flex-col relative">
						<VideoPitchPlayer
							{...{ videoPitch }}
							className={cn(
								aspectRatio === "PORTRAIT" &&
									"md:max-w-[320px]",
								videoPitch && !resume?.bio && "md:max-w-[240px]"
							)}
						/>
						{editable &&
							videoPitch?.id &&
							videoPitch.status === "READY" && (
								<div className="flex mt-2">
									<Button
										xsmall
										type="red"
										onClick={deleteVideoPitch}
										loading={isDeletingVideoPitch}
									>
										{t("delete")}
									</Button>
								</div>
							)}
					</div>
				)}
				{resume?.bio ? (
					<p className="flex-1">{resume.bio.bio}</p>
				) : editable ? (
					<div
						className={`flex flex-col text-center py-4 justify-center items-center flex-1 ${
							!editable && "gap-4"
						}`}
					>
						<strong>{t("empty-description.title")}</strong>
						<p className="description">
							{t("empty-description.description")}
						</p>
						<div className="flex items-center gap-3 justify-center mt-6">
							<Button
								xsmall
								to={`/profile/resume/create/general?${backUrl}`}
							>
								<i className="fas fa-pencil mr-1"></i>
								{t("empty.buttons.description")}
							</Button>
						</div>
					</div>
				) : (
					false
				)}
			</div>
		</div>
	);
}

ResumeBio.locale = {
	nl: {
		title: "Over mij",
		"title-empty": "Je hebt nog geen bio",
		add: "Toevoegen",
		confirm: {
			title: "Weet je het zeker",
			description: "Je kan dit niet ongedaan maken.",
		},
		delete: "Verwijder",
		empty: {
			title: "Vertel wat meer over jezelf",
			description:
				"Voeg een bio toe om jezelf te presenteren aan toekomstige bedrijven.",
			buttons: {
				description: "Standaard omschrijving",
				video: "Video omschrijving",
			},
		},
		"video-pitch-modal": {
			title: "Video omschrijving",
		},
		"empty-description": {
			title: "Standaard omschrijving",
			description: "Type een korte omschrijving over jezelf.",
		},
	},
	en: {
		title: "About me",
		"title-empty": "You don't have a bio yet",
		add: "Add",
		confirm: {
			title: "Are you sure",
			description: "You can't undo this action.",
		},
		delete: "Delete",
		empty: {
			title: "Tell us more about yourself",
			description: "Add a bio to present yourself to future companies.",
			buttons: {
				description: "Standard description",
				video: "Video description",
			},
		},
		"video-pitch-modal": {
			title: "Video description",
		},
	},
};

export default withTranslation(ResumeBio);
