import { useTranslation } from "react-i18next";
import Button from "src/components/Button";
import Header from "src/components/Header";
import Layout from "src/components/layout/Layout";
import SEO from "src/components/layout/SEO";
import useTenant from "src/hooks/api/services/tenants/useTenant";
import useAuth from "src/hooks/selectors/useAuth";

const NotFoundView = () => {
	const auth = useAuth();
	const { tenant } = useTenant();
	const { t } = useTranslation("auth-view", {
		keyPrefix: "not-found",
	});

	if (auth?.id && auth?.type) {
		return (
			<Layout>
				<SEO title={t("title")} />
				<div className="flex flex-col flex-1 justify-center items-center">
					<div className="flex flex-1 flex-col justify-center max-w-md">
						<div className="flex flex-col gap-4 mb-8">
							<h2>{t("title")}</h2>
							<p className="opacity-70">{t("description")}</p>
						</div>
						<div className="flex items-center gap-4">
							{auth.id ? (
								<Button noBlank type="dark" to="/dashboard">
									{t("buttons.dashboard")}
								</Button>
							) : (
								<>
									<Button noBlank type="dark" to="/login">
										{t("buttons.login")}
									</Button>
									<Button
										noBlank
										type="dark"
										href={tenant?.urls?.public}
									>
										{t("buttons.home")}
									</Button>
								</>
							)}
						</div>
					</div>
				</div>
			</Layout>
		);
	}

	return (
		<>
			<SEO title={t("title")} />
			<Header type="student" hideLogo full>
				<div className="flex flex-1 flex-col justify-center">
					<div className="flex flex-col gap-4 mb-8">
						<h1>{t("title")}</h1>
						<p className="opacity-70">{t("description")}</p>
					</div>
					<div className="flex items-center gap-4">
						{auth.id ? (
							<Button noBlank type="dark" to="/dashboard">
								{t("buttons.dashboard")}
							</Button>
						) : (
							<>
								<Button noBlank type="dark" to="/login">
									{t("buttons.login")}
								</Button>
								<Button
									noBlank
									type="dark"
									href={tenant?.urls?.public}
								>
									{t("buttons.home")}
								</Button>
							</>
						)}
					</div>
				</div>
			</Header>
		</>
	);
};

export default NotFoundView;
