import { useNavigate, useParams } from "react-router-dom";
import AnalalyticsLayout from "src/components/analytics/analytics-layout/AnalalyticsLayout";
import TasksStatistics from "src/components/analytics/tasks/TasksStatistics";
import UserStatistics from "src/components/analytics/user/UserStatistics";
import Field from "src/components/field/Field";
import withTranslation, {
	Translation,
} from "src/components/hoc/withTranslation";
import Layout from "src/components/layout/Layout";
import useUsers from "src/hooks/api/services/users/useUsers";

const EmployeeView = ({ t }: Translation) => {
	const { id } = useParams();
	const { users } = useUsers();
	const { users: rawUsers } = useUsers();
	const user = users.find((user) => user.hid === id);
	const navigate = useNavigate();
	return (
		<Layout
			breadcrumb={[
				{
					label: t("dashboard"),
					to: "/analytics/dashboard",
				},
				{
					label: t("employees"),
					to: "/analytics/employees",
				},
				{
					label: user?.full_name || t("title"),
					to: `/analytics/employees/${id}`,
				},
			]}
			title={t("title")}
		>
			<AnalalyticsLayout>
				{({ date }) =>
					user?.id ? (
						<>
							<div className="flex items-center">
								<Field.User
									value={Number(user?.id)}
									onChange={(value) => {
										const item = rawUsers.find(
											(u) => u.id === value
										);
										if (item) {
											navigate(
												`/analytics/employees/${item.hid}`
											);
										}
									}}
								/>
							</div>
							<div className="flex flex-col md:grid md:grid-cols-3 gap-4">
								<TasksStatistics
									userId={user?.id}
									{...{ date }}
								>
									<TasksStatistics.CompletedBarChart className="col-span-2" />
									<div className="grid grid-cols-1 gap-4">
										<TasksStatistics.TotalCompleted />
										<TasksStatistics.TotalOpen />
										<TasksStatistics.TotalHoursWorked />
									</div>
								</TasksStatistics>
								<UserStatistics
									as="fragment"
									userId={Number(user.id)}
								>
									<UserStatistics.SkillProgress
										title={t("skill-progress")}
										height={null}
									/>
									<UserStatistics.SkillDistribution
										title={t("skill-distribution")}
									/>
								</UserStatistics>
							</div>
						</>
					) : (
						<></>
					)
				}
			</AnalalyticsLayout>
		</Layout>
	);
};

EmployeeView.locale = {
	nl: {
		dashboard: "Analytics",
		employees: "Medewerkers",
		title: "Medewerker",
		breadcrumbs: {
			dashboard: "Dashboard",
			employees: "Medewerkers",
			employee: "Medewerker",
		},
		"skill-progress": "Skill voortgang",
		"skill-distribution": "Skill verdeling",
	},
	en: {
		dashboard: "Analytics",
		employees: "Employees",
		title: "Employee",
		breadcrumbs: {
			dashboard: "Dashboard",
			employees: "Employees",
			employee: "Employee",
		},
		"skill-progress": "Skill progress",
		"skill-distribution": "Skill distribution",
	},
};

export default withTranslation(EmployeeView);
