import moment from "moment";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import Button from "src/components/Button";
import Input from "src/components/form/Input";
import withTranslation, {
	Translation,
} from "src/components/hoc/withTranslation";
import Layout from "src/components/layout/Layout";
import List from "src/components/ui/list";
import useEditSubTasks from "src/hooks/api/services/tasks/task/useEditSubTasks";
import useTask from "src/hooks/api/services/tasks/task/useTask";
import { cn } from "src/lib/utils";

const EditSubTasksView = ({ t }: Translation) => {
	const { id } = useParams() as any;
	const { task, status, actions: taskActions } = useTask({ id });
	const { subtasks, isChanged, actions } = useEditSubTasks({ id });
	const inputClassName =
		"p-3 py-1.5 relative z-10 bg-transparent border border-transparent hover:border-border focus:border-border rounded-md outline-none min-w-0 w-full";

	useEffect(() => {
		taskActions.get().then((data) => {
			actions.set(data?.subtasks || []);
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	// if (!task.has_subtasks && status === "idle") {
	// 	return <Navigate to={`/tasks/${task.hid}/show`} />;
	// }

	return (
		<Layout
			{...{ task }}
			loading={status === "loading"}
			title={t("title")}
			breadcrumb={[
				{
					label: t("tasks"),
					to: "/tasks",
				},
				{
					label: task?.title,
					to: `/tasks/${task.hid}/show`,
				},
				{
					label: t("title"),
					to: `/tasks/${task.hid}/subtasks/edit`,
				},
			]}
		>
			<div className="flex gap-4">
				<Button
					onClick={() => actions.save(subtasks)}
					disabled={!isChanged}
					loading={status !== "idle"}
				>
					{t("actions.save")}
				</Button>
			</div>
			<List data={subtasks || []} {...{ status }}>
				<List.Head>
					<List.Item>{t("headers.id")}</List.Item>
					<List.Item>{t("headers.title")}</List.Item>
					<List.Item>{t("headers.hour-rate")}</List.Item>
					<List.Item>{t("headers.slots")}</List.Item>
					<List.Item>{t("headers.start-date")}</List.Item>
					<List.Item>{t("headers.end-date")}</List.Item>
					<List.Item align="right">{t("headers.actions")}</List.Item>
				</List.Head>
				<List.Empty>
					<strong>{t("empty.title")}</strong>
					<p>{t("empty.description")}</p>
					<Button xsmall type="border" onClick={() => actions.add()}>
						{t("empty.button")}
					</Button>
				</List.Empty>
				<List.Items>
					{(item, index) => (
						<List.Row key={`task-list-${item.hid}-${index}`}>
							<List.Item className="max-w-[60px] font-bold">
								<small>{item.hid || "-"}</small>
							</List.Item>
							<List.Item className="pr-0 md:pr-0">
								<input
									className={inputClassName}
									placeholder={t("placeholders.title")}
									type="text"
									value={item.title}
									onChange={(e) =>
										actions.change(index, {
											title: e.target.value,
										})
									}
								/>
								{item.start_date && (
									<small className="opacity-70 text-xs">
										{moment(item.start_date).format(
											"dddd DD MMM"
										)}
									</small>
								)}
							</List.Item>
							<List.Item className="max-w-[140px]">
								<input
									className={cn(
										inputClassName,
										item?.registrations?.count > 0 &&
											"opacity-40 bg-border"
									)}
									placeholder={t("placeholders.hour-rate")}
									disabled={item?.registrations?.count > 0}
									type="number"
									value={item.budget_per_hour}
									onChange={(e) =>
										actions.change(index, {
											budget_per_hour: e.target.value,
										})
									}
								/>
							</List.Item>
							<List.Item className="max-w-[140px]">
								<input
									className={inputClassName}
									placeholder={t("placeholders.slots")}
									type="number"
									value={item.amount_of_students}
									onChange={(e) =>
										actions.change(index, {
											amount_of_students: e.target.value,
										})
									}
								/>
							</List.Item>
							<List.Item>
								<Input
									type="date"
									withTime
									className="max-w-[320px]"
									noMarginBottom
									name="start_date"
									placeholder={t("placeholders.start-date")}
									value={item.start_date}
									onChange={(_, value) => {
										actions.change(index, {
											start_date: value,
										});
									}}
								/>
							</List.Item>
							<List.Item>
								<Input
									type="date"
									withTime
									noMarginBottom
									className="max-w-[320px]"
									name="end_date"
									placeholder={t("placeholders.end-date")}
									value={item.end_date}
									onChange={(_, value) => {
										actions.change(index, {
											end_date: value,
										});
									}}
								/>
							</List.Item>
							<List.Item align="right">
								<div className="flex items-center justify-end gap-4">
									<Button
										type="transparent"
										xsmall
										onClick={async () => {
											actions.add({
												title: item.title,
												budget_per_hour:
													item.budget_per_hour,
												amount_of_students:
													item.amount_of_students,
												start_date: moment(
													item.start_date
												)
													.add(1, "day")
													.format("YYYY-MM-DD HH:mm"),
												end_date: moment(item.end_date)
													.add(1, "day")
													.format("YYYY-MM-DD HH:mm"),
											});
										}}
									>
										<div className="relative">
											<i className="fas fa-plus text-xs text-primary absolute -left-1 -bottom-1"></i>
											<i className="fas fa-copy"></i>
										</div>
									</Button>
									<Button
										type="border"
										xsmall
										onClick={async () => {
											actions.duplicate(index);
										}}
									>
										<i className="fas fa-copy"></i>
									</Button>

									<Button
										type="border"
										xsmall
										disabled={
											item?.registrations?.count > 0
										}
										onClick={async () => {
											if (item.hid) {
												return await actions.remove(
													item.hid
												);
											}
											return actions.removeGhostItem(
												index
											);
										}}
									>
										{t("actions.delete")}
									</Button>
								</div>
							</List.Item>
						</List.Row>
					)}
				</List.Items>
			</List>
			{subtasks.length > 0 && (
				<div className="flex">
					<Button xsmall type="border" onClick={() => actions.add()}>
						{t("actions.add")}
					</Button>
				</div>
			)}
		</Layout>
	);
};

EditSubTasksView.locale = {
	nl: {
		tasks: "Opdrachten",
		title: "Shifts bewerken",
		headers: {
			id: "ID",
			title: "Titel",
			"hour-rate": "Prijs per uur",
			slots: "Plekken",
			"start-date": "Startdatum",
			"end-date": "Einddatum",
			actions: "Acties",
		},
		empty: {
			title: "Geen shifts gevonden",
			description: "Je hebt nog geen shifts geplaatst",
			button: "Shift toevoegen",
		},
		placeholders: {
			title: "Titel",
			"hour-rate": "€",
			slots: "Plekken",
			"start-date": "Startdatum",
			"end-date": "Einddatum",
		},
		actions: {
			delete: "Verwijder",
			save: "Opslaan",
			add: "Toevoegen",
		},
	},
	en: {
		tasks: "Tasks",
		title: "Edit Shifts",
		headers: {
			id: "ID",
			title: "Title",
			"hour-rate": "Hourly Rate",
			slots: "Slots",
			"start-date": "Start Date",
			"end-date": "End Date",
			actions: "Actions",
		},
		empty: {
			title: "No Shifts Found",
			description: "You haven't posted any shifts yet",
			button: "Add Shift",
		},
		placeholders: {
			title: "Title",
			"hour-rate": "€",
			slots: "Slots",
			"start-date": "Start Date",
			"end-date": "End Date",
		},
		actions: {
			delete: "Delete",
			save: "Save",
			add: "Add",
		},
	},
};

export default withTranslation(EditSubTasksView);
