import React from "react";
import { ApiTimesheetProcessResult } from "src/api/types";
import withTranslation, {
	Translation,
} from "src/components/hoc/withTranslation";
import KeyValue from "src/components/KeyValue";
import Link from "src/components/Link";
import PaymentCard from "src/components/payment/PaymentCard";

interface TimesheetProcessResultProps extends Translation {
	result: ApiTimesheetProcessResult;
}

const TimesheetProcessResult = ({ t, result }: TimesheetProcessResultProps) => {
	return (
		<div className="flex flex-col gap-4">
			<KeyValue.Small
				label={t("keys.project")}
				text={
					<Link
						className="underline"
						target="_blank"
						to={`/tasks/${result.links.task}/show`}
					>
						{result.title}
					</Link>
				}
			></KeyValue.Small>

			{(result?.payments || []).length ? (
				<>
					<p>{t("keys.payments")}:</p>

					<div className="flex flex-col gap-2">
						{result.payments?.map((payment) => (
							<PaymentCard
								key={`payment-${payment.id}`}
								{...{ payment }}
							/>
						))}
					</div>
				</>
			) : null}

			<p>{t("payment-description")}</p>
		</div>
	);
};

TimesheetProcessResult.locale = {
	nl: {
		keys: {
			project: "Project",
			payments: "Betalingen",
		},
		"payment-description":
			"Zodra de betalingen zijn voldaan zullen de uitvoerders het bedrag automatisch binnen 2 werkdagen op hun rekening ontvangen. Het enige wat jij nog hoeft te doen, is het betalen van de openstaande facturen.",
	},
	en: {
		keys: {
			project: "Project",
			payments: "Payments",
		},
		"payment-description":
			"Once the payments are completed, the performers will receive the amount automatically within 2 working days. All you have to do is pay the outstanding invoices.",
	},
};

export default withTranslation(TimesheetProcessResult);
