import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import Button from "src/components/Button";
import Dots from "src/components/form/Dots";
import EditAddressSmall from "src/components/forms/profile/EditAddressSmall";
import EditUserSmall from "src/components/forms/profile/EditUserSmall";
import Modal from "src/components/Modal";

const DashboardEditBasicModal = () => {
	const { t } = useTranslation("resume-view", {
		keyPrefix: "edit-basic-modal",
	});
	const navigate = useNavigate();
	const steps = [t("steps.basic"), t("steps.address")] as string[];
	const [current, setCurrent] = useState(0);
	const onClose = () => {
		navigate("/dashboard");
	};

	const renderButtons = ({ isSubmitting, isValid, onSubmit }: any) => {
		return (
			<>
				<Dots className="mt-4" {...{ steps, current }} />
				<Modal.Buttons className="py-0 md:py-0 mt-4">
					<Button type="gray" {...{ onClose }}>
						{t("buttons.cancel")}
					</Button>
					<Button
						type="primary"
						disabled={!isValid}
						submit
						onClick={() => {
							if (isValid && onSubmit) {
								onSubmit();
							}
						}}
						loading={isSubmitting}
					>
						{t("buttons.continue")}
					</Button>
				</Modal.Buttons>
			</>
		);
	};

	return (
		<Modal
			title={t("title")}
			className="max-w-[460px]"
			visible
			{...{ onClose }}
		>
			{/* <p className="mb-4 mt-4">{t("description")}</p> */}
			<div className="flex flex-col mt-4">
				{current === 0 && (
					<EditUserSmall
						renderButtons={renderButtons}
						onDone={() => {
							setCurrent(1);
						}}
					/>
				)}

				{current === 1 && (
					<EditAddressSmall
						renderButtons={renderButtons}
						onDone={() => {
							onClose();
						}}
					/>
				)}
			</div>
		</Modal>
	);
};

export default DashboardEditBasicModal;
