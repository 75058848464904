import moment from "moment";
import { useState } from "react";
import { ApiTimeTracking } from "src/api/types";
import Avatar from "src/components/Avatar";
import DropdownButton from "src/components/DropdownButton";
import Skeleton from "src/components/Skeleton";
import withTranslation, {
	Translation,
} from "src/components/hoc/withTranslation";
import useTimeTracking from "src/hooks/api/services/useTimeTracking";
import { ApiStatus } from "src/hooks/api/utils/useApi";
import useAuth from "src/hooks/selectors/useAuth";
import useNavigateModal from "src/hooks/useNavigateModal";
import { cn } from "src/lib/utils";

interface TimeTrackerListItemProps extends Translation {
	time: ApiTimeTracking;
}

const TimeTrackerListItem = ({ t, time }: TimeTrackerListItemProps) => {
	const auth = useAuth();
	const isCreator = time?.creator?.id === auth.id;
	const navigateModal = useNavigateModal();
	const [status, setStatus] = useState<ApiStatus>("idle");
	const { actions } = useTimeTracking();
	const label = `${moment(time.started_at).format("HH:mm")} - ${
		!time.ended_at ? t("current") : moment(time.ended_at).format("HH:mm")
	}`;
	return (
		<div className="flex justify-between gap-2 -m-1 p-1 hover:bg-accent-background rounded-[4px] transition-all">
			{time.creator ? (
				<div className="flex items-center gap-1.5 p-1 rounded-[4px] bg-accent w-max">
					<Avatar
						className="w-7 h-7 rounded-[4px]"
						src={time.creator.avatar}
					/>
					<p className="text-[14px] pr-1.5 font-regular">
						{time.creator.first_name}
					</p>
				</div>
			) : (
				<div />
			)}

			<div className="flex gap-2 items-center">
				<p className="text-[14px]">{label}</p>
				<div
					className={cn(
						!isCreator && "opacity-50 cursor-not-allowed"
					)}
				>
					<DropdownButton
						menu={{
							position: "bottom-right",
						}}
						button={{
							tabIndex: "-1",
							children: <i className="far fa-ellipsis-v " />,
							type: "transparent",
							iconOnly: true,
							className: "hover:bg-accent p-0 w-6 h-6 rounded-sm",
						}}
					>
						<DropdownButton.Item
							icon="far fa-pencil"
							disabled={status !== "idle" || !isCreator}
							onClick={(close) => {
								close();
								navigateModal(
									`./time-tracking/${time.id}/edit`
								);
							}}
						>
							{t("actions.edit")}
						</DropdownButton.Item>
						<DropdownButton.Item
							loading={status === "deleting"}
							disabled={!isCreator}
							icon="far fa-trash"
							onClick={(close) => {
								setStatus("deleting");
								actions.delete(time.id).then(() => {
									setStatus("idle");
									close();
								});
							}}
						>
							{t("actions.delete")}
						</DropdownButton.Item>
					</DropdownButton>
				</div>
			</div>
		</div>
	);
};

TimeTrackerListItem.locale = {
	nl: {
		current: "Bezig",
		actions: {
			delete: "Verwijder",
			edit: "Wijzig",
		},
	},
	en: {
		current: "Current",
		actions: {
			delete: "Delete",
			edit: "Edit",
		},
	},
};

export const Loader = () => (
	<div className="flex justify-between rounded-[4px] transition-all">
		<Skeleton className="w-24 h-6" />
		<div className="flex items-center gap-2">
			<Skeleton className="w-16 h-6" />
			<Skeleton className="w-6 h-6" />
		</div>
	</div>
);

export default withTranslation(TimeTrackerListItem);
