import { atom, useAtom } from "jotai";
import { ComponentProps } from "react";
import withTranslation, {
	Translation,
} from "src/components/hoc/withTranslation";
import { Switch } from "src/components/ui/switch";
import { cn } from "src/lib/utils";

const focusModeAtom = atom(false);

export function useFocusMode() {
	const [isFocusMode, setFocusMode] = useAtom(focusModeAtom);

	return {
		isFocusMode,
		setFocusMode,
	};
}

interface FocusModeSwitchProps extends Translation, ComponentProps<"div"> {}

const FocusModeSwitch = ({ t, className, ...rest }: FocusModeSwitchProps) => {
	const { isFocusMode, setFocusMode } = useFocusMode();
	return (
		<div {...rest} className={cn("flex items-center gap-2", className)}>
			<p
				className={cn(
					"transition-all text-border line-clamp-1",
					isFocusMode && "text-primary"
				)}
			>
				{t("label")}
			</p>
			<Switch
				checked={isFocusMode}
				onClick={() => setFocusMode(!isFocusMode)}
			/>
		</div>
	);
};

FocusModeSwitch.locale = {
	nl: {
		label: "Focus mode",
	},
	en: {
		label: "Focus mode",
	},
};

export default withTranslation(FocusModeSwitch);
