import useCompanyForm from "src/hooks/api/services/auth/useCompanyForm";
import useProspect from "src/hooks/api/services/auth/useProspect";

export default function useSignupReset() {
	const prospect = useProspect();
	const company = useCompanyForm();

	const reset = () => {
		prospect.actions.resetForm();
		company.actions.resetForm();
	};
	return reset;
}
