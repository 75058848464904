import { Route } from "react-router-dom";
import ProtectedRoute from "src/routes/middlewares/ProtectedRoute";
import OnboardingAddressModal from "src/views/authenticated/dashboard/company/onboarding/OnboardingAddressModal";
import SelectedSubscriptionView from "src/views/authenticated/subscription/SelectedSubscriptionView";
import DashboardSubscriptionThanksView from "src/views/authenticated/subscription/SubscriptionThanksView";
import DashboardSubscriptionView from "src/views/authenticated/subscription/SubscriptionView";

const SubscriptionRoutes = [
	<Route
		element={
			<ProtectedRoute>
				<DashboardSubscriptionView />
			</ProtectedRoute>
		}
		path="/subscription"
		key="/subscription"
	/>,
	<Route
		element={
			<ProtectedRoute>
				<SelectedSubscriptionView />
			</ProtectedRoute>
		}
		path="/subscription/:slug"
		key="/subscription/:slug"
	/>,
	<Route
		element={
			<ProtectedRoute>
				<DashboardSubscriptionThanksView />
			</ProtectedRoute>
		}
		path="/subscription/:slug/thanks"
		key="/subscription/:slug/thanks"
	/>,
];

export const SubscriptionModalRoutes = [
	<Route
		element={
			<ProtectedRoute
				variant="modal"
				modal={{
					backUrl: "/subscription",
					expanded: false,
					size: "default",
				}}
			>
				<OnboardingAddressModal />
			</ProtectedRoute>
		}
		path="/subscription/address"
		key="/subscription/address"
	/>,
];

export default SubscriptionRoutes;
