import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { ApiTaskInvite } from "src/api/types";
import Button from "src/components/Button";
import useDelete from "src/hooks/rest/useDelete";
import useAlert from "src/hooks/useAlert";

interface DenyTaskInviteProps {
	invite: ApiTaskInvite;
}

const DenyTaskInvite = ({ invite }: DenyTaskInviteProps) => {
	const { t } = useTranslation(["network", "buttons"]);
	const [confirm] = useAlert();
	const [deny, loading] = useDelete(`/student/task-invites/${invite.id}`);
	const navigate = useNavigate();

	const onClick = () => {
		confirm({
			type: "confirm",
			title: t("confirm.title", {
				ns: "network",
				keyPrefix: "deny-task-invite",
			}),
			text: t("confirm.description", {
				ns: "network",
				keyPrefix: "deny-task-invite",
			}),
			onConfirm: () => {
				deny().then(() => {
					navigate("/network");
				});
			},
		});
	};

	return (
		<Button {...{ onClick, loading }} type="ghost">
			{t("denie", { ns: "buttons" })}
		</Button>
	);
};

export default DenyTaskInvite;
