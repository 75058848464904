import { ApiRegistrationTimesheet } from "src/api/types";
import Avatar from "src/components/Avatar";
import withTranslation, {
	Translation,
} from "src/components/hoc/withTranslation";
import Table from "src/components/Table";
import { formatPrice } from "src/lib/formatters";

interface TimesheetProcessListItemProps extends Translation {
	timesheet: ApiRegistrationTimesheet;
}

const TimesheetProcessListItem = ({
	t,
	timesheet,
}: TimesheetProcessListItemProps) => {
	return (
		<>
			<Table.Row>
				<Table.Cell className="w-full">
					<Avatar.User
						className="w-full flex-1"
						wrapper={{
							className: "flex-1 max-w-full",
						}}
						user={timesheet.user}
						subtitle={timesheet.user?.tagline}
					/>
				</Table.Cell>
				<Table.Cell>{timesheet.current.total}</Table.Cell>
				<Table.Cell>
					{timesheet.current?.total
						? formatPrice(
								timesheet.hour_rate * timesheet.current?.total
						  )
						: t("price-unknown")}
				</Table.Cell>
			</Table.Row>
		</>
	);
};

TimesheetProcessListItem.locale = {
	nl: {
		"price-unknown": "-",
	},
	en: {
		"price-unknown": "-",
	},
};

export default withTranslation(TimesheetProcessListItem);
