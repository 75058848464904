import { AxiosInstance } from "axios";
import { atom } from "jotai";
import { ApiTaskKanban } from "src/api/types";
import useFillTask from "src/hooks/api/tasks/useFillTask";
import useApi, {
	ApiActions,
	ApiFilter,
	ApiStatus,
	emptyApiState,
	Pagination,
} from "src/hooks/api/utils/useApi";
import uid from "src/lib/uid";

interface ApiTaskActions extends ApiActions {
	createEditableTask: (date: string) => void;
	updateNewItem: (_id: string, data: ApiTaskKanban) => void;
}

type State = {
	tasks: ApiTaskKanban[];
	status: ApiStatus;
	actions: ApiTaskActions;
	api: AxiosInstance;
	pagination: Pagination;
	filter: ApiFilter;
};

export const tasksAtom: any = atom({ name: "useTasks", ...emptyApiState });

type Options = {
	resetFilter?: boolean;
	filter?: any;
	appendListResult?: boolean;
};

function useTasks(options?: Options): State {
	const { state, actions, api, pagination } = useApi(
		{ id: "TASKS_LIST", group: "TASKS" },
		{
			baseUrl: `/client/tasks`,
			appendListResult: options?.appendListResult,
			query: {
				recent: 1,
				limit: 20,
				...(options?.filter || {}),
			},
		}
	);
	const { getFillableData } = useFillTask();

	const createEditableTask = (date: string) => {
		const fill = getFillableData();
		actions.set((state: any) => {
			const tasks = state.list || [];
			const item = {
				_id: uid(),
				status: "BACKLOG",
				end_date: date,
				title: "",
				tags: fill.tags || [],
				type: "INTERNAL",
				mode: "NEW",
				registrations: null,
				matches: null,
				links: {
					assignees: [],
				},
			};
			return {
				...state,
				list: [item, ...tasks],
			};
		});
	};

	const updateNewItem = (_id: string, data: ApiTaskKanban) => {
		actions.set((state: any) => {
			const tasks = state.list || [];
			return {
				...state,
				list: tasks.map((item: any) => {
					return item._id === _id ? data : item;
				}),
			};
		});
	};

	return {
		tasks: state?.list || [],
		status: state.status,
		filter: state?.filter || null,
		pagination,
		actions: {
			...actions,
			createEditableTask,
			updateNewItem,
		},
		api,
	};
}

export default useTasks;
