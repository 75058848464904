import { OnboardingTeam } from "src/hooks/api/services/auth/useOnboarding";

const DEFAULTS: { [key in "nl" | "en"]: OnboardingTeam[] } = {
	nl: [
		{
			name: "Management",
			type: "DEFAULT",
			icon: {
				emoji: {
					icon: "💼",
					description: "briefcase",
				},
			},
		},
		{
			name: "HR",
			type: "DEFAULT",
			icon: {
				emoji: {
					icon: "👥",
					description: "busts in silhouette",
				},
			},
		},
		{
			name: "Sales",
			type: "DEFAULT",
			icon: {
				emoji: {
					icon: "💰",
					description: "money bag",
				},
			},
		},
		{
			name: "Administratie",
			type: "DEFAULT",
			icon: {
				emoji: {
					icon: "🗂️",
					description: "open file folder",
				},
			},
		},
		{
			name: "Marketing",
			type: "DEFAULT",
			icon: {
				emoji: {
					icon: "📈",
					description: "bar chart increasing",
				},
			},
		},
		{
			name: "Development",
			type: "DEFAULT",
			icon: {
				emoji: {
					icon: "👨‍💻",
					description: "man technologist",
				},
			},
		},
	],
	en: [
		{
			name: "Management",
			type: "DEFAULT",
			icon: {
				emoji: {
					icon: "💼",
					description: "briefcase",
				},
			},
		},
		{
			name: "HR",
			type: "DEFAULT",
			icon: {
				emoji: {
					icon: "👥",
					description: "busts in silhouette",
				},
			},
		},
		{
			name: "Sales",
			type: "DEFAULT",
			icon: {
				emoji: {
					icon: "💰",
					description: "money bag",
				},
			},
		},
		{
			name: "Administration",
			type: "DEFAULT",
			icon: {
				emoji: {
					icon: "🗂️",
					description: "open file folder",
				},
			},
		},
		{
			name: "Marketing",
			type: "DEFAULT",
			icon: {
				emoji: {
					icon: "📈",
					description: "bar chart increasing",
				},
			},
		},
		{
			name: "Development",
			type: "DEFAULT",
			icon: {
				emoji: {
					icon: "👨‍💻",
					description: "man technologist",
				},
			},
		},
	],
};
export default DEFAULTS;
