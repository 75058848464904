import { ApiRegistrationTimesheet } from "src/api/types";
import Button from "src/components/Button";
import TimeSheetForm from "src/components/timesheet/TimesheetForm";
import withTranslation, {
	Translation,
} from "src/components/hoc/withTranslation";
import Modal from "src/components/Modal";

interface TimesheetEditModalProps extends Translation {
	visible: boolean;
	onClose: (status?: "completed" | "cancel") => void;
	timesheet: ApiRegistrationTimesheet;
	links: {
		task: string | number;
		registration?: string;
	};
}

const TimesheetEditModal = ({
	t,
	visible,
	onClose,
	timesheet,
}: TimesheetEditModalProps) => {
	return (
		<Modal title={t(`title`)} {...{ visible, onClose }}>
			<div className="flex flex-col gap-4">
				<TimeSheetForm
					variant={"vertical"}
					options={{
						autosave: false,
					}}
					onSubmit={(status) => {
						onClose(status === "success" ? "completed" : "cancel");
					}}
					{...{ timesheet }}
				/>
				<div className="flex flex-col gap-2">
					<Button
						onClick={(e) => {
							e.preventDefault();
							onClose();
						}}
						type="gray"
					>
						{t(`buttons.cancel`)}
					</Button>
				</div>
			</div>
		</Modal>
	);
};

TimesheetEditModal.locale = {
	nl: {
		title: "Uren wijzigen",
		description_one:
			"Weet je zeker dat je deze urenregistratie wilt goedkeuren? Het is niet mogelijk om hierna de urenregistratie aan te passen.",
		description_other:
			"Weet je zeker dat je deze urenregistraties wilt goedkeuren? Het is niet mogelijk om hierna de urenregistratie aan te passen.",
		buttons: {
			cancel: "Annuleren",
		},
	},
	en: {
		title: "Edit hours",
		description_one:
			"Are you sure you want to approve this hour registration? It will not be possible to modify the hour registration afterwards.",
		description_other:
			"Are you sure you want to approve these hour registrations? It will not be possible to modify the hour registrations afterwards.",
		buttons: {
			cancel: "Cancel",
		},
	},
};

export default withTranslation(TimesheetEditModal);
