import React from "react";
import "./grid.scss";

const Grid = ({
	children,
	columns,
	className,
	gap,
	style,
}: {
	children?: any;
	columns: number | string;
	className?: string;
	gap?: number;
	style?: any;
}) => {
	let defaultStyle: any = {
		gridTemplateColumns:
			typeof columns === "string" ? columns : `repeat(${columns}, 1fr)`,
	};

	if (gap !== undefined) defaultStyle.gridGap = gap;

	return (
		<div
			style={{
				...defaultStyle,
				...style,
			}}
			className={`flex flex-col md:grid ${className || ""}`}
		>
			{children || false}
		</div>
	);
};

Grid.defaultProps = {
	columns: 1,
	gap: 32,
};

export default Grid;
