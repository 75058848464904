import { useParams } from "react-router-dom";
import Card from "src/components/Card";
import withTranslation, {
	Translation,
} from "src/components/hoc/withTranslation";
import Layout from "src/components/layout/layout-default/LayoutDefault";
import Link from "src/components/Link";
import Logo from "src/components/Logo";
import ResumeFormWrapper from "src/components/resume/steps-resume/ResumeFormWrapper";
import { useParseBackUrl } from "src/hooks/useBackUrl";
import useTheme from "src/hooks/useTheme";
import ResumeForm from "../../../../../components/resume/ResumeForm";

function CreateResumeView({ t }: Translation) {
	const { theme } = useTheme();
	const { step } = useParams() as any;
	const backUrl = useParseBackUrl();

	return (
		<Layout
			breadcrumb={[
				{ label: t("breadcrumb.my-profile"), to: "/profile/resume" },
				{
					label: t("breadcrumb.create-resume"),
					to: "/profile/resume/create",
				},
			]}
		>
			<div className="flex flex-1 lg:justify-center lg:items-center">
				<div className="container flex-1 px-4">
					<div className="flex lg:flex-row flex-1 flex-col md:items-center justify-between gap-6">
						<div className="flex flex-col lg:max-w-[460px]">
							<div className="flex flex-row pt-6 md:flex-col gap-4 items-center md:items-start">
								<Logo
									color={
										theme === "light" ? "dark" : "primary"
									}
									variant="small"
									className="max-w-[32px]"
								/>
								<h3>{t("info.title")}</h3>
							</div>
							<p className="mt-4 md:block hidden">
								{t("info.description")}
							</p>
							<div className="lg:mt-16 mt-4">
								<Link
									to={backUrl ? backUrl : "/profile/resume"}
									className="cursor-pointer inline-block"
								>
									<i className="far fa-arrow-left mr-1"></i>
									{t("info.back")}
								</Link>
							</div>
						</div>
						<Card className="bg-background w-full lg:max-w-[520px] flex-1">
							<ResumeFormWrapper>
								{(step === "general" || !step) && (
									<ResumeForm.GeneralInfo />
								)}
								{step === "education" && (
									<ResumeForm.Education />
								)}
								{step === "work" && (
									<ResumeForm.WorkExperience />
								)}
								{step === "skill" && <ResumeForm.Skill />}
								{/* {step === "interests" && (
									<ResumeForm.Interests />
								)} */}
							</ResumeFormWrapper>
						</Card>
					</div>
				</div>
			</div>
		</Layout>
	);
}

CreateResumeView.locale = {
	nl: {
		breadcrumb: {
			"my-profile": "Mijn profiel",
			"create-resume": "Maak een CV",
		},
		info: {
			title: "Maak een traditioneel CV aan",
			description:
				"Voordat we op vaardigheden gebasseerd profiel voor je kunnen creeren, hebben we enige informatie nodig over je loopbaan.",
			back: "Terug naar dashboard",
		},
		title: "Mijn profiel",
		"not-found": {
			title: "CV niet gevonden",
			description: "Het lijkt er op dat je nog geen CV hebt.",
			create_resume: "Creëer een CV",
		},
	},
	en: {
		info: {
			title: "Create a traditional resume",
			description:
				"Before we can create a skills-based profile for you, we need some information about your career path.",
			back: "Back to dashboard",
		},
		title: "My profile",
		"not-found": {
			title: "Rsume not found",
			description: "It seems like you don't have a resume yet.",
			create_resume: "Create a resume",
		},
	},
};

export default withTranslation(CreateResumeView);
