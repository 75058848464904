import Card from "src/components/Card";
import OfferForm from "src/components/offer/forms/OfferForm";

interface OfferEditFormrops {
	title: string;
	defaultOpen?: boolean;
	value?: any;
	schema?: (Yup: any) => any | any;
	isOpen?: boolean;
	toggleOpen?: () => void;
	children?: (getInputProps: (name: string, type?: string) => any) => any;
}

const OfferEditForm = ({
	children,
	value,
	schema,
	title,
	isOpen,
	toggleOpen,
}: OfferEditFormrops) => {
	return (
		<Card>
			<div className="flex flex-col gap-4">
				<div
					onClick={toggleOpen}
					className="flex items-center gap-8 justify-between cursor-pointer"
				>
					<h3>{title}</h3>
					<i className={`fas fa-angle-${isOpen ? "up" : "down"}`}></i>
				</div>

				{isOpen && (
					<OfferForm
						variant="edit"
						onSubmit={async () => {}}
						{...{ value, schema }}
					>
						{children}
					</OfferForm>
				)}
			</div>
		</Card>
	);
};

export default OfferEditForm;
