import React from "react";
import Loader from "src/components/Loader";

interface AILoadingProps {
	description?: string;
}

const AILoading = ({ description }: AILoadingProps) => {
	return (
		<div className="flex flex-col justify-center items-center py-16 gap-4 rounded-md bg-accent">
			<Loader />
			{description && <p>{description}</p>}
		</div>
	);
};

export default AILoading;
