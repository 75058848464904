import { ApiPayment } from "src/api/types";
import useApi from "src/hooks/api/utils/useApi";
import useAuth from "src/hooks/selectors/useAuth";

interface Options {
	query?: string;
}

function usePayments(options?: Options) {
	const auth = useAuth();
	const { state, actions, api, pagination } = useApi(
		{ id: "PAYMENTS" },
		{
			baseUrl: `/${auth.type}/payments?${options?.query || ""}`,
		}
	);
	const payments: ApiPayment[] = state?.list || [];

	return {
		payments,
		search: state.filter.q,
		status: state.status,
		actions,
		pagination,
		api,
	};
}

export default usePayments;
