import { useEffect } from "react";
import { useCookies } from "react-cookie";
import { useNavigate } from "react-router-dom";
import useTenant from "src/hooks/api/services/tenants/useTenant";
import usePrefetch from "src/hooks/api/usePrefetch";
import useQueryString from "src/hooks/useQueryString";
import { getCurrentDomainName } from "src/lib/domain";
import getTokenName from "src/lib/getTokenName";

export function createCookie() {
	let host = window.location.host;

	let domain = "";
	const currentDomainName = getCurrentDomainName();
	const extension = host.split(".").pop();
	if (host.includes("demo.")) {
		domain = `demo.${currentDomainName}.${extension}`;
	} else if (process.env.NODE_ENV !== "development") {
		domain = `${currentDomainName}.${extension}`;
	} else if (process.env.NODE_ENV === "development") {
		domain = `${currentDomainName}.test`;
	}

	return {
		path: "/",
		maxAge: 2592000,
		domain,
	};
}

function useTokenListener() {
	const { tenant } = useTenant();
	const { token } = useQueryString();
	const [, setCookie] = useCookies();
	const navigate = useNavigate();
	const prefetch = usePrefetch();

	useEffect(() => {
		if (token) {
			setCookie(getTokenName(), token.toString(), createCookie());
			localStorage.setItem(getTokenName(), token.toString());
			prefetch.run(tenant).then((user) => {
				if (user.onboarding && !user?.onboarding?.is_completed) {
					navigate("/onboarding");
					return;
				}
				navigate("/dashboard");
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [token]);
}

export default useTokenListener;
