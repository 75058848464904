import { useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { OfferCreateLayoutButtons } from "src/components/offer/layout/OfferCreateLayout";
import useCreateOffer from "src/hooks/api/useCreateOffer";

interface OfferFormProps {
	value?: any;
	schema?: (Yup: any) => any | any;
	onSubmit: (values: any) => Promise<unknown>;
	children?: (getInputProps: (name: string, type?: string) => any) => any;
	defaultValid?: boolean;
	variant: "create" | "edit";
}

const OfferForm = ({
	children,
	value,
	schema,
	onSubmit,
	defaultValid,
	variant,
}: OfferFormProps) => {
	const { onChange } = useCreateOffer();
	const form = useFormik({
		initialValues: value,
		validationSchema: schema && Yup.object().shape(schema(Yup)),
		onSubmit: (values: any, { setSubmitting }) => {
			onSubmit(values)
				.then(() => {
					setSubmitting(false);
				})
				.catch(() => {
					setSubmitting(false);
				});
		},
	});
	const { values, errors, handleChange, handleBlur } = form;

	useEffect(() => {
		form.validateForm();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const getInputProps = (name: string, type = "input") => {
		if (type === "dropdown") {
			return {
				name,
				value: values[name],
				valid: values[name] ? true : false,
				onChange: (value: number) => {
					// validateField(name);
					handleChange({
						target: {
							name,
							value,
						},
					});
					onChange(name, value);
				},
				onBlur: () => {
					// validateField(name);
					handleBlur({
						target: {
							name,
							value: values[name],
						},
					});
				},
				className: "mb-0",
			};
		}

		return {
			name,
			value: values[name],
			error: errors[name] && values[name] ? errors[name] : false,
			valid: !errors[name] && values[name] ? true : false,
			handleBlur: (e: any) => {
				onChange(name, e?.target?.value);
				return handleBlur(e);
			},
			handleChange: (e: any) => {
				handleChange(e);
				return onChange(name, e?.target?.value);
			},
			className: "mb-0",
		};
	};

	return (
		<form
			className={
				variant === "create"
					? "flex flex-col gap-8 flex-1 md:mt-20 "
					: ""
			}
			onSubmit={form.handleSubmit}
		>
			<div
				className={
					variant === "create"
						? "flex-1 p-6 flex flex-col gap-4"
						: "flex flex-col gap-4"
				}
			>
				{children && children(getInputProps)}
			</div>

			{variant === "create" && (
				<OfferCreateLayoutButtons
					disabled={
						!defaultValid
							? form.isSubmitting || !form.isValid
							: false
					}
					loading={form.isSubmitting}
				/>
			)}
		</form>
	);
};

OfferForm.defaultProps = {
	variant: "create",
};

export default OfferForm;
