import moment from "moment";
import React from "react";
import { useTranslation } from "react-i18next";
import { ApiReferralLinkPayout } from "src/api/types";
import FlatList from "src/components/FlatList";
import useGet from "src/hooks/rest/useGet";
import { formatPrice } from "src/lib/formatters";

const ReferralLinkPayouts = () => {
	const { t } = useTranslation("share-network", {
		keyPrefix: "referral-link-payouts",
	});
	const [{ data, hasMore }, loading, { fetchNext }] = useGet(
		"/student/referral-link/payouts",
		{ data: [] },
		true
	);

	return (
		<FlatList
			{...{ loading, hasMore }}
			data={data || []}
			onNext={async () => {
				if (hasMore) await fetchNext();
			}}
			empty={{
				title: t("empty.title"),
			}}
			gap={16}
			renderItem={(payout: ApiReferralLinkPayout) => (
				<ReferralLinkPayoutItem
					key={`payout-${payout.id}`}
					{...{ payout }}
				/>
			)}
		/>
	);
};

interface ReferralLinkPayoutItemProps {
	payout: ApiReferralLinkPayout;
}

const ReferralLinkPayoutItem = ({ payout }: ReferralLinkPayoutItemProps) => {
	const { t } = useTranslation("share-network", {
		keyPrefix: "referral-link-payouts.item",
	});
	let text = "";
	if (payout.type === "COMPANY_CREATED") {
		text = t("text.signed-up", {
			name: payout?.company?.name,
		});
	} else if (payout.type === "TASK_CREATED") {
		text = t("text.placed-task", {
			name: payout?.company?.name,
		});
	} else if (payout.type === "SUBSCRIBED_YEAR") {
		text = t("text.subscribed-year", {
			name: payout?.company?.name,
		});
	}

	return (
		<div
			className="flex md:items-center gap-1 md:gap-8 flex-wrap md:flex-row flex-col"
			key={`payout-${payout.id}`}
		>
			<small className={payout.approved_at ? "text-primary" : ""}>
				{payout.approved_at ? t("approved") : t("pending")}
			</small>
			<div className="flex-1 flex flex-col gap-2">
				{/* <strong>{payout?.company?.name}</strong> */}
				{text && <p className="mb-0 flex">{text}</p>}
				<div className="flex gap-2 items-center opacity-50">
					<small>{payout?.company?.name}</small>
					<small>-</small>
					<small>
						{t("created-at", {
							date: moment(payout.created_at).format(
								"DD-MM-YYYY"
							),
						})}
					</small>
				</div>
			</div>
			<strong className={`${payout.has_charge ? "text-primary" : ""} `}>
				{payout.has_charge && <i className="fas mr-4 fa-check"></i>}
				{formatPrice(payout.amount)}
			</strong>
		</div>
	);
};

export default ReferralLinkPayouts;
