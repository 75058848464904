import { ComponentProps, useState } from "react";
import Bars from "src/components/statistics/bar-chart/Bars";
import Info from "src/components/statistics/bar-chart/Info";
import { BarChartContext } from "src/components/statistics/bar-chart/Provider";
import { cn } from "src/lib/utils";

export type DatasetItem = {
	id: string;
	label: string;
	description?: string;
	count: number;
};

export type DatasetTheme = {
	bar?: {
		className?: string;
	};
};

export type Dataset = {
	label: string;
	theme?: DatasetTheme;
	data: DatasetItem[];
};

export type BarChartPopup = {
	title: string;
	render?: (id: string, data: Dataset[]) => JSX.Element;
};

interface BarChartProps extends ComponentProps<"div"> {
	data: Dataset[];
	popup?: BarChartPopup;
}

const BarChart = ({ data, popup, ...rest }: BarChartProps) => {
	const [active, setActive] = useState<any>();

	return (
		<BarChartContext.Provider value={{ data, active, setActive, popup }}>
			<div
				{...rest}
				className={cn(
					"flex gap-4 min-h-[300px] relative mt-[15px]",
					rest?.className
				)}
			>
				<Info />
				<Bars />
			</div>
		</BarChartContext.Provider>
	);
};

export default BarChart;
