import React, { ComponentProps } from "react";
import { ApiSubscriptionPlan } from "src/api/types";
import { cn } from "src/lib/utils";

interface SubscriptionGridProps extends ComponentProps<"div"> {
	selected?: ApiSubscriptionPlan;
	columns: number;
}

const SubscriptionGrid = ({
	children,
	selected,
	columns,
	className,
	...rest
}: SubscriptionGridProps) => {
	return (
		<div
			{...rest}
			className={cn(
				"grid  w-full mx-auto transition-all md:grid-cols-1 lg:grid-cols-3 flex-1",
				selected?.slug && "grid-cols-2 max-w-[1080px]",
				selected?.slug &&
					columns === 1 &&
					"grid-cols-2 lg:grid-cols-2 max-w-[1080px]",
				columns === 2 && "md:grid-cols-1 lg:grid-cols-2 flex-1",
				!selected?.slug &&
					columns === 1 &&
					"md:grid-cols-1 lg:grid-cols-1 flex-1 max-w-[660px]",
				className
			)}
		>
			{children}
		</div>
	);
};

export default SubscriptionGrid;
