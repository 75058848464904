import { useMemo, useState } from "react";
import Card from "src/components/Card";
import Input from "src/components/form/Input";
import withTranslation, {
	Translation,
} from "src/components/hoc/withTranslation";
import { Switch } from "src/components/ui/switch";
import { usePollIframeHeight } from "src/hooks/useIframeEvents";
import { formatPrice } from "src/lib/formatters";

const defaultForm = {
	people: 5,
	hours: 16,
	hour_rate: 15,
	administration_outsourced: true,
	insurance: true,
	retirement: true,
	people_needed: 2,
};

interface Form {
	people: number;
	hours: number;
	hour_rate: number;
	administration_outsourced: boolean;
	insurance: boolean;
	retirement: boolean;
	people_needed: number;
}

type FormKey = keyof Form;

const CostCalculatorView = ({ t }: Translation) => {
	usePollIframeHeight();
	const [form, setForm] = useState<Form>(defaultForm);
	const COST_ADMINISTRATION = 15;

	const traditionalCosts = useMemo(() => {
		const salary = form.people * (form.hours * (52 / 12)) * form.hour_rate;
		const vacationDaysCost =
			(form.hour_rate * 8 * 25 * (form.hours / 40)) / 12;
		return {
			salary,
			vacationSalary: salary * 0.08,
			vacationDaysCost,
			retirement: form.retirement ? salary * 0.14 : 0,
			insurance: form.insurance ? salary * 0.03 : 0,
			costs: {
				zvw: salary * 0.0657,
				aof: salary * 0.0725,
				whk: salary * 0.0122,
				wko: salary * 0.005,
				awf: salary * 0.0764,
			},
		};
	}, [form]);
	const monthlyTraditionalSalaryCosts = useMemo(() => {
		return (
			traditionalCosts.salary +
			traditionalCosts.vacationSalary +
			traditionalCosts.vacationDaysCost +
			traditionalCosts.retirement +
			traditionalCosts.insurance +
			traditionalCosts.costs.zvw +
			traditionalCosts.costs.aof +
			traditionalCosts.costs.whk +
			traditionalCosts.costs.wko +
			traditionalCosts.costs.awf
		);
	}, [traditionalCosts]);
	const monthlyTraditionalCost = useMemo(() => {
		return (
			monthlyTraditionalSalaryCosts +
			(form.administration_outsourced
				? COST_ADMINISTRATION * form.people
				: 0)
		);
	}, [
		form.administration_outsourced,
		form.people,
		monthlyTraditionalSalaryCosts,
	]);
	const ourMonthlyCost = useMemo(() => {
		return (
			form.hour_rate * form.hours * form.people * 1.25 * (52 / 12) + 79.95
		);
	}, [form.hour_rate, form.hours, form.people]);

	const ourYearlyCost = useMemo(() => {
		return form.hour_rate * form.hours * form.people * 1.2 * 52 + 649.95;
	}, [form.hour_rate, form.hours, form.people]);

	const booleanKeys = [
		"administration_outsourced",
		"insurance",
		"retirement",
	] as FormKey[];

	const getFormField = (name: FormKey) => ({
		name,
		value: form[name],
		onChange: (key: string, value: any) =>
			setForm({ ...form, [name]: value }),
	});

	return (
		<div className="flex flex-col flex-1 p-3 md:p-8 bg-aqua-light">
			<div className="flex flex-col md:flex-row max-w-xl gap-8 mx-auto w-full">
				<Card className="flex-1">
					<Input
						label={t("inputs.people")}
						type="number"
						increments
						incrementsPosition="right"
						{...getFormField("people")}
					/>
					<Input
						label={t("inputs.hours")}
						type="number"
						increments
						incrementsPosition="right"
						{...getFormField("hours")}
					/>
					<Input
						label={t("inputs.hour_rate")}
						type="number"
						increments
						incrementsPosition="right"
						{...getFormField("hour_rate")}
					/>
					{booleanKeys.map((key) => (
						<div
							key={key}
							className="flex items-center gap-3"
							onClick={() => {
								setForm({ ...form, [key]: !form[key] });
							}}
						>
							<Switch
								checked={form[key] ? true : false}
								onChange={() =>
									setForm({
										...form,
										[key]: !form[key],
									})
								}
							/>
							<span>{t(`inputs.${key}`)}</span>
						</div>
					))}
				</Card>
				<Card className="flex-1 text-aqua">
					<div className="flex flex-col justify-center gap-2 flex-1">
						<strong>{t("result.monthly")}</strong>
						<div className="flex items-center">
							<span className="min-w-[180px] md:min-w-[220px]">
								{t("result.current")}:
							</span>
							<span>{formatPrice(monthlyTraditionalCost)}</span>
						</div>
						<div className="flex items-center">
							<span className="min-w-[180px] md:min-w-[220px]">
								{t("result.ourCosts")}:
							</span>
							<span>{formatPrice(ourMonthlyCost)}</span>
						</div>
						<div className="flex items-center">
							<strong className="min-w-[180px] md:min-w-[220px]">
								{t("result.saving")}:
							</strong>
							<strong>
								{formatPrice(
									Math.max(
										0,
										monthlyTraditionalCost - ourMonthlyCost
									)
								)}
							</strong>
						</div>
					</div>
					<hr className="border-aqua-light" />
					<div className="flex flex-col justify-center gap-2 flex-1">
						<strong>{t("result.yearly")}</strong>
						<div className="flex items-center">
							<span className="min-w-[180px] md:min-w-[220px]">
								{t("result.current")}:
							</span>
							<span>
								{formatPrice(monthlyTraditionalCost * 12)}
							</span>
						</div>
						<div className="flex items-center">
							<span className="min-w-[180px] md:min-w-[220px]">
								{t("result.ourCosts")}:
							</span>
							<span>{formatPrice(ourYearlyCost)}</span>
						</div>
						<div className="flex items-center">
							<strong className="min-w-[180px] md:min-w-[220px]">
								{t("result.saving")}:
							</strong>
							<strong>
								{formatPrice(
									Math.max(
										0,
										monthlyTraditionalCost * 12 -
											ourYearlyCost
									)
								)}
							</strong>
						</div>
					</div>
				</Card>
			</div>
		</div>
	);
};

CostCalculatorView.locale = {
	nl: {
		inputs: {
			people: "Aantal personen",
			hours: "Aantal uren per week",
			hour_rate: "Uurtarief",
			administration_outsourced: "Heb je je administratie uitbesteed?",
			insurance: "Heb je een verzuimverzekering?",
			retirement: "Heb je een pensioenregeling?",
			people_needed: "Aantal personen nog te werven",
		},
		result: {
			monthly: "Maandelijkse kosten",
			yearly: "Jaarlijkse kosten",
			current: "Huidige situatie",
			ourCosts: "{{tenant.name}}",
			saving: "Besparing",
		},
	},
	en: {
		inputs: {
			people: "Number of people",
			hours: "Number of hours per week",
			hour_rate: "Hour rate",
			administration_outsourced:
				"Have you outsourced your administration?",
			insurance: "Have you got insurance?",
			retirement: "Have you got retirement?",
			people_needed: "Number of people still to work",
		},
		result: {
			monthly: "Monthly costs",
			yearly: "Yearly costs",
			current: "Current situation",
			ourCosts: "{{tenant.name}}",
			saving: "Saving",
		},
	},
};

export default withTranslation(CostCalculatorView);
