import moment from "moment";
import { useEffect, useState } from "react";
import useTask from "src/hooks/api/services/tasks/task/useTask";
import axios from "src/lib/api";

interface EditSubTasksProps {
	id: string;
}

function useEditSubTasks({ id }: EditSubTasksProps) {
	const { task, actions } = useTask({ id });
	const [subtasks, setSubtasks] = useState(task?.subtasks || []);
	const [isChanged, setIsChanged] = useState(false);

	useEffect(() => {
		setSubtasks(task?.subtasks || []);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const change = (index: number, data: any) => {
		setIsChanged(true);
		setSubtasks((prev: any) => {
			const newSubtasks = [...prev];
			newSubtasks[index] = {
				...newSubtasks[index],
				...data,
			};
			return newSubtasks;
		});
	};

	const save = async (update: any[]) => {
		try {
			const result = await actions.update({
				id: task.id,
				subtasks: update,
			});
			setIsChanged(false);
			setSubtasks(result?.subtasks || []);
			return result;
		} catch (error) {}
	};

	const add = async (data?: any) => {
		setIsChanged(true);
		setSubtasks((prev: any) => [
			...prev,
			{
				start_date: moment().format("YYYY-MM-DD HH"),
				end_date: moment().add(1, "hour").format("YYYY-MM-DD HH"),
				budget_per_hour: null,
				amount_of_students: null,
				title: "",
				...(data || {}),
			},
		]);
	};

	const remove = async (id: string) => {
		try {
			await axios.delete(`/client/tasks/${id}`);
			setSubtasks((prev: any) =>
				prev.filter((item: any) => item.hid !== id)
			);
		} catch (error) {
			setIsChanged(false);
		}
	};

	const removeGhostItem = (removeIndex: number) => {
		setSubtasks((prev: any) =>
			prev.filter((item: any, index: number) => index !== removeIndex)
		);
	};

	const duplicate = (index: number) => {
		const item = subtasks[index];
		setSubtasks((prev: any) => [
			...prev,
			{
				hid: null,
				start_date: item.start_date,
				end_date: item.end_date,
				budget_per_hour: item.budget_per_hour,
				amount_of_students: item.amount_of_students,
				title: item.title,
			},
		]);
	};

	return {
		subtasks,
		isChanged,
		actions: {
			change,
			set: setSubtasks,
			save,
			add,
			remove,
			removeGhostItem,
			duplicate,
		},
	};
}

export default useEditSubTasks;
