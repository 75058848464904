import { ComponentProps, useEffect } from "react";
import Button from "src/components/Button";
import withTranslation, {
	Translation,
} from "src/components/hoc/withTranslation";
import Icon from "src/components/icon/Icon";
import useProjectOptions from "src/hooks/api/services/projects/useProjectOptions";
import useSubscription from "src/hooks/selectors/useSubscription";
import { cn } from "src/lib/utils";

const ProjectsButtons = ({
	t,
	className,
	...rest
}: ComponentProps<"div"> & Translation) => {
	const subscription = useSubscription();
	const { projects, actions } = useProjectOptions();

	useEffect(() => {
		if (subscription?.id && projects.length === 0) {
			actions.list();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	if (!subscription?.id) return <></>;

	if (projects.length === 0) {
		return (
			<div
				{...rest}
				className={cn(
					"flex justify-center items-center p-4",
					className
				)}
			>
				<p className="opacity-70">{t("empty")}</p>
			</div>
		);
	}

	return (
		<div {...rest} className={cn("flex gap-3 flex-wrap", className)}>
			{projects
				.filter((item, index) => index < 5)
				.map((project: any) => (
					<Button
						to={`/projects/${project.slug}`}
						className="text-[14px] py-1.5 px-1.5"
						xsmall
						type="border"
						key={project.hid}
					>
						{project?.icon && (
							<Icon
								className="hover:bg-transparent w-6 h-6"
								iconFontSize={12}
								icon={project.icon}
							/>
						)}
						{project.name}
					</Button>
				))}
		</div>
	);
};

ProjectsButtons.locale = {
	nl: {
		empty: "Geen projecten gevonden",
	},
	en: {
		empty: "No projects found",
	},
};

export default withTranslation(ProjectsButtons);
