import { useTranslation } from "react-i18next";
import * as Yup from "yup";

function useCompanyContactScheme() {
	const { t } = useTranslation("schemes");
	return {
		website: Yup.string().required(t("required")),
		email: Yup.string().email(t("email")).required(t("required")),
		telephone: Yup.string().nullable(),
	};
}

export default useCompanyContactScheme;
