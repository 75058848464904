import { Route } from "react-router-dom";
import DashboardPaymentsView from "src/views/authenticated/payments/PaymentsView";
import DashboardCompanyPaymentDetailView from "src/views/authenticated/payments/company/CompanyPaymentDetailView";
import DashboardRefreshPaymentsView from "src/views/authenticated/payments/RefreshPaymentsView";
import ProtectedRoute from "src/routes/middlewares/ProtectedRoute";

const PaymentsRoutes = [
	<Route
		element={
			<ProtectedRoute>
				<DashboardPaymentsView />
			</ProtectedRoute>
		}
		path="/payments"
		key="/payments"
	/>,
	<Route
		element={
			<ProtectedRoute type="company">
				<DashboardCompanyPaymentDetailView />
			</ProtectedRoute>
		}
		path="/payments/:id"
		key="/payments/:id"
	/>,
	<Route
		element={
			<ProtectedRoute type="company">
				<DashboardRefreshPaymentsView />
			</ProtectedRoute>
		}
		path="/payments/:id/refresh"
		key="/payments/:id/refresh"
	/>,
];

export default PaymentsRoutes;
