import { ApiVacancy } from "src/api/types";
import useApi from "src/hooks/api/utils/useApi";

function useVacancies() {
	const { state, actions, pagination } = useApi(
		{ id: "VACANCIES" },
		{
			baseUrl: `/client/vacancies`,
			query: {
				with: ["registrations"],
			},
		}
	);

	const vacancies: ApiVacancy[] = state?.list || [];

	return {
		vacancies,
		search: state.filter.q,
		status: state.status,
		pagination,
		actions,
	};
}

export default useVacancies;
