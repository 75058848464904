import React from "react";
import { Trans, useTranslation } from "react-i18next";
import Button from "src/components/Button";
import useTenant from "src/hooks/api/services/tenants/useTenant";
import useCreateOffer from "src/hooks/api/useCreateOffer";

const OfferErrorCard = () => {
	const { t } = useTranslation("offer", {
		keyPrefix: "offer-error-card",
	});
	const { setCurrent } = useCreateOffer();
	const { tenant } = useTenant();

	return (
		<div className="flex flex-col flex-1 p-6">
			<strong className="">{t("title")}</strong>
			<p>
				<Trans {...{ t }} i18nKey="description">
					Ververs de pagina, als dit probleem nog steeds blijft
					voordoen mail dan naar
					<a
						href={`mailto:support@${tenant?.urls?.domain}`}
						className="underline"
					>
						support@{tenant?.urls?.domain}
					</a>
				</Trans>
			</p>
			<div className="flex mt-4 gap-4">
				<Button type="dark" onClick={() => setCurrent(0)}>
					<i className="fas fa-sync mr-2"></i>
					{t("try-again")}
				</Button>
				<Button type="gray" onClick={() => window.location.reload()}>
					{t("refresh-page")}
				</Button>
			</div>
		</div>
	);
};

export default OfferErrorCard;
