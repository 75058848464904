import { ComponentProps, createContext, ReactNode, useContext } from "react";
import { ButtonProps } from "react-day-picker";
import { ApiRegistration } from "src/api/types";
import { useT } from "src/components/hoc/withTranslation";
import {
	Carousel,
	CarouselContent,
	CarouselItem,
	CarouselNavigateButton,
	useCarousel,
} from "src/components/ui/carousel";
import { Pagination } from "src/hooks/api/utils/useApi";
import useUpdateEffect from "src/hooks/useUpdateEffect";
import { cn } from "src/lib/utils";

interface RegistrationsCarouselContextType {
	registrations: ApiRegistration[];
	pagination: Pagination;
	loading?: boolean;
}

const RegistrationsCarouselContext =
	createContext<RegistrationsCarouselContextType>({
		registrations: [],
		pagination: {} as Pagination,
	});

const useRegistrationsCarousel = () => {
	const context = useContext(RegistrationsCarouselContext);
	return context;
};

interface RegistrationsCarouselProps extends ComponentProps<typeof Carousel> {
	registrations: RegistrationsCarouselContextType["registrations"];
	pagination: RegistrationsCarouselContextType["pagination"];
	loading?: boolean;
}

export default function RegistrationsCarousel({
	children,
	className,
	registrations,
	pagination,
	loading,
	...rest
}: RegistrationsCarouselProps) {
	return (
		<RegistrationsCarouselContext.Provider
			value={{ registrations, pagination, loading }}
		>
			<Carousel
				draggable={false}
				opts={{
					watchDrag: false,
				}}
				className={cn("flex flex-col", className)}
				{...rest}
			>
				{children}
			</Carousel>
		</RegistrationsCarouselContext.Provider>
	);
}

interface ItemsProps extends Omit<ComponentProps<"div">, "children"> {
	children: (
		registration: ApiRegistration | null,
		index: number
	) => ReactNode;
}

const Items = ({ children, className, ...rest }: ItemsProps) => {
	const { currentSlide } = useCarousel();
	const { registrations, pagination } = useRegistrationsCarousel();
	//Create a new array with the total number of items, filling in the missing items with empty objects
	const slides = Array.from(
		{ length: pagination.total || 0 },
		(_, index) => registrations[index] || null
	);

	useUpdateEffect(() => {
		const perPage = pagination.per_page || 0;

		if (currentSlide > 0) {
			//Round to highest number
			const currentPageShowing = Math.ceil(currentSlide / perPage);
			if (pagination.page < currentPageShowing) {
				console.log("Fetching new page", {
					currentPageShowing,
					pagination,
				});
				pagination.loadMore(currentPageShowing);
			}
		}

		// console.log(currentSlide);
	}, [currentSlide]);

	return (
		<CarouselContent className={cn(className)}>
			{slides.map((registration, index) => children(registration, index))}
		</CarouselContent>
	);
};

const Item = ({
	children,
	className,
	...rest
}: ComponentProps<typeof CarouselItem>) => {
	return (
		<CarouselItem {...rest} className={cn(className)}>
			{children}
		</CarouselItem>
	);
};

const Card = ({ children, className, ...rest }: ComponentProps<"div">) => {
	return (
		<div
			className={cn(
				"flex flex-col flex-1 shadow bg-card border border-border m-2 rounded-md p-4 md:p-8",
				className
			)}
			{...rest}
		>
			{children}
		</div>
	);
};

interface NavigateButtonProps extends ButtonProps {
	direction: "next" | "previous";
}

const NavigateButton = ({ direction, ...rest }: NavigateButtonProps) => {
	const { loading } = useRegistrationsCarousel();
	const t = useT({
		nl: {
			next: "Volgende",
			previous: "Vorige",
		},
		en: {
			next: "Next",
			previous: "Previous",
		},
	});
	return (
		<CarouselNavigateButton
			direction={direction}
			xsmall
			{...rest}
			disabled={loading}
		>
			<span className="hidden md:flex">{t(direction)}</span>
			<i
				className={cn(
					"far flex md:hidden",
					direction === "previous" && "fa-arrow-left",
					direction === "next" && "fa-arrow-right"
				)}
			></i>
		</CarouselNavigateButton>
	);
};

const Count = ({
	className,
	variant,
	...rest
}: ComponentProps<"p"> & { variant: "current" | "total" }) => {
	const { pagination } = useRegistrationsCarousel();
	const { currentSlide } = useCarousel();

	return (
		<p className={cn("", className)} {...rest}>
			{variant === "current" ? currentSlide : pagination.total}
		</p>
	);
};

export {
	Card as RegistrationsCarouselCard,
	Count as RegistrationsCarouselCount,
	Item as RegistrationsCarouselItem,
	Items as RegistrationsCarouselItems,
	NavigateButton as RegistrationsCarouselNavigateButton,
};
