import { ApiUserMinimal } from "src/api/types";
import Avatar from "src/components/Avatar";
import UserAvatars from "src/components/UserAvatars";
import { useTaskKanban } from "src/components/tasks/kanban/TaskKanbanCard";
import useTeams from "src/hooks/api/services/users/useTeams";
import useUsers from "src/hooks/api/services/users/useUsers";

const TaskKanbanCardAssignee = () => {
	const { users } = useUsers();
	const { teams } = useTeams();
	const { task } = useTaskKanban();
	const creator = task?.id
		? users.find((user) => user.id === task?.links?.creator_id)
		: null;
	const assignees = users.filter((u: any) =>
		(task?.links?.assignees || []).includes(u.id)
	);
	let teamSlugs = assignees.map((user: ApiUserMinimal) => user.teams).flat();
	const linkedTeams = teams.filter((team) =>
		(teamSlugs || []).includes(team.slug)
	);

	if (!!assignees.length) {
		return (
			<div className="flex items-center gap-2">
				<div className="flex items-center gap-1 empty:hidden">
					{linkedTeams.map((team) => (
						<div
							key={`${task.hid}-${team.id}`}
							className={`w-2 h-2 rounded-full bg-accent`}
							style={{
								backgroundColor: team.color,
							}}
						></div>
					))}
				</div>

				<UserAvatars users={assignees} avatar={{ size: "xsmall" }} />
			</div>
		);
	}

	if (creator) {
		return (
			<Avatar.User
				user={creator}
				avatar={{
					size: "xsmall",
				}}
				wrapper={{
					className: "gap-1",
				}}
				options={{ iconOnly: true }}
			/>
		);
	}

	return null;
};

export default TaskKanbanCardAssignee;
