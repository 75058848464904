import { useEffect } from "react";
import { Navigate } from "react-router-dom";
import { ApiVacancy } from "src/api/types";
import withTranslation, {
	Translation,
} from "src/components/hoc/withTranslation";
import Layout from "src/components/layout/Layout";
import VacancyTabs from "src/components/vacancies/vacancy-tabs";
import useVacancy from "src/hooks/api/services/vacancies/useVacancy";

interface ChildrenActions {
	setVacancy: (vacancy: ApiVacancy) => void;
}

interface VacancyDetailLayoutProps extends Translation {
	id: string;
	children: (
		vacancy: ApiVacancy,
		actions: ChildrenActions
	) => React.ReactNode;
}

const VacancyDetailLayout = ({ t, id, children }: VacancyDetailLayoutProps) => {
	const { vacancy, status, actions } = useVacancy(id);

	useEffect(() => {
		actions.get();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [id]);

	//Check if the ID exists
	if (!id) {
		return <Navigate to="/vacancies" />;
	}

	return (
		<Layout
			loading={status !== "idle" && vacancy?.id !== id}
			title={vacancy?.title}
			breadcrumb={[
				{ label: t("vacancies"), to: "/vacancies" },
				{
					label: vacancy?.title,
					to: `/vacancies/${id}`,
				},
			]}
			container="xxl"
		>
			{vacancy && (
				<>
					<VacancyTabs id={vacancy?.id} />
					{children(vacancy, {
						setVacancy: (vacancy) => {
							actions.setItem({ data: vacancy });
						},
					})}
				</>
			)}
		</Layout>
	);
};

VacancyDetailLayout.locale = {
	nl: {
		vacancies: "Vacatures",
		title: "Vacature",
	},
	en: {
		vacancies: "Vacancies",
		title: "Vacancy",
	},
};

export default withTranslation(VacancyDetailLayout);
