import moment from "moment";
import { useEffect } from "react";
import { ApiTaskRegistration } from "src/api/types";
import Button from "src/components/Button";
import UserAvatars from "src/components/UserAvatars";
import withTranslation, {
	Translation,
} from "src/components/hoc/withTranslation";
import TaskStatus from "src/components/tasks/TaskStatus";
import List from "src/components/ui/list";
import useRegistrations from "src/hooks/api/services/tasks/registrations/useRegistrations";
import useAuth from "src/hooks/selectors/useAuth";
import useBackUrl from "src/hooks/useBackUrl";

interface RegistrationsListProps extends Translation {}

const RegistrationsList = ({ t }: RegistrationsListProps) => {
	const back = useBackUrl();
	const auth = useAuth();
	const { tasks, status, actions } = useRegistrations();
	useEffect(() => {
		actions.list();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<List className="flex-1" data={tasks} {...{ status }}>
			<List.Head>
				<List.Item>{t("headers.status")}</List.Item>
				<List.Item className="w-full">{t("headers.task")}</List.Item>
				{auth.type === "company" && (
					<List.Item>{t("headers.registrations")}</List.Item>
				)}

				<List.Item>{t("headers.published")}</List.Item>
				<List.Item className="sticky right-0" />
			</List.Head>
			<List.Items>
				{(item: ApiTaskRegistration) => (
					<List.Row key={`registration-${item.hid}`}>
						<List.Item>
							<TaskStatus status={item.status} />
						</List.Item>
						<List.Item>{item.title}</List.Item>
						{auth.type === "company" && (
							<List.Item>
								{!item?.registrations?.count ? (
									<p className="text-sm opacity-50 whitespace-nowrap">
										{t("item.registrations.none")}
									</p>
								) : item?.registrations?.users ? (
									<>
										{item.registrations.count > 20 ? (
											<p>
												{t("item.registrations.count", {
													count: item.registrations
														?.count,
												})}
											</p>
										) : (
											<UserAvatars
												users={item.registrations.users}
												overlay={
													<div className="absolute inset-0 bg-primary backdrop-blur-sm rounded-md flex justify-center items-center text-white">
														<strong>
															{
																item
																	.registrations
																	?.count
															}
														</strong>
													</div>
												}
											/>
										)}
									</>
								) : (
									false
								)}
							</List.Item>
						)}
						<List.Item>
							<p className="whitespace-nowrap">
								{moment(item.published_at).format("DD-MM-YYYY")}
							</p>
						</List.Item>
						<List.Item sticky={{ position: "right" }}>
							<Button
								to={`/tasks/${item.hid}/show?${back}`}
								// disabled={!item.links.registration}
								type="gray"
								iconOnly
							>
								<i className="fas fa-eye"></i>
							</Button>
						</List.Item>
					</List.Row>
				)}
			</List.Items>
			<List.Empty>
				<h2>{t("empty.title")}</h2>
				<p>{t("empty.description")}</p>
			</List.Empty>
		</List>
	);
};

RegistrationsList.locale = {
	nl: {
		headers: {
			status: "Status",
			task: "Opdracht",
			registrations: "Aanmeldingen",
			published: "Uitbesteed",
		},
		item: {
			registrations: {
				none: "Geen aanmeldingen",
				count_one: "{{count}} aanmelding",
				count_other: "{{count}} aanmeldingen",
			},
		},
		empty: {
			title: "Geen aanmeldingen gevonden",
			description:
				"Je hebt nog geen opdrachten uitbesteed of je hebt nog geen aanmeldingen ontvangen",
		},
	},
	en: {
		headers: {
			status: "Status",
			task: "Task",
			registrations: "Registrations",
			published: "Published",
		},
		item: {
			registrations: {
				none: "No registrations",
				count_one: "{{count}} registration",
				count_other: "{{count}} registrations",
			},
		},
		empty: {
			title: "No registrations found",
			description:
				"You have not outsourced tasks or have not yet received registrations",
		},
	},
};

export default withTranslation(RegistrationsList);
