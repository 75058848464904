import { useTranslation } from "react-i18next";
import ConnectionDropdown from "src/components/form/Dropdown/ConnectionDropdown";
import Switch from "src/components/form/Switch";
import { TaskFormOptions } from "src/components/tasks/TaskForm";
import Form from "src/components/tasks/TaskForm/Form";
import useTaskForm from "src/hooks/api/tasks/useTaskForm";

const StudentPoolForm = ({ hideButtons }: TaskFormOptions) => {
	const { t } = useTranslation(["forms", "buttons"]);
	const { task, actions, current } = useTaskForm();
	return (
		<Form
			{...{ hideButtons }}
			title={t("title", {
				ns: "forms",
				keyPrefix: "student-pool-form",
			})}
			onSubmit={async () => {
				actions.updateCurrent(current + 1);
			}}
			value={{}}
			schema={() => ({})}
			nextButtonLabel={
				task?.students?.length || task.invite_all
					? t("next-step", { ns: "buttons" })
					: t("skip", { ns: "buttons" })
			}
		>
			{() => {
				return (
					<>
						<Switch
							label={t("only-switch.label", {
								ns: "forms",
								keyPrefix: "student-pool-form",
							})}
							subLabel={t("only-switch.sub-label", {
								ns: "forms",
								keyPrefix: "student-pool-form",
							})}
							onChange={(v) => {
								actions.set({
									invite_all: v,
									students: [],
								});
							}}
							value={task.invite_all}
						/>

						{!task.invite_all && (
							<div className="mt-4">
								<ConnectionDropdown
									onChange={(v: any) => {
										actions.set({
											students: v,
										});
									}}
									value={task.students}
									label={t("connection-dropdown.label", {
										ns: "forms",
										keyPrefix: "student-pool-form",
									})}
									placeholder={t(
										"connection-dropdown.placeholder",
										{
											ns: "forms",
											keyPrefix: "student-pool-form",
										}
									)}
									variant="list"
								/>
							</div>
						)}
					</>
				);
			}}
		</Form>
	);
};

export default StudentPoolForm;
