import axios from "axios";
import { useState } from "react";

export default function useGenerateTaskDescription() {
	const [status, setStatus] = useState<"idle" | "loading" | "finished">(
		"idle"
	);
	const [response, setResponse] = useState<any>();

	const start = async (taskId: number | string) => {
		setStatus("loading");
		setResponse(undefined);
		return axios
			.get(`/client/tasks/ai/${taskId}/description/generate`, {
				responseType: "stream",
				onDownloadProgress: function (progressEvent) {
					setResponse(progressEvent.currentTarget.response);
				},
			})
			.then((response) => {
				return response?.data;
			})
			.finally(() => {
				setStatus("finished");
			});
	};

	return {
		status,
		response,
		actions: {
			start,
			setResponse,
		},
	};
}
