import { useTranslation } from "react-i18next";
import Avatar from "src/components/Avatar";
import Link from "src/components/Link";
import Skeleton from "src/components/Skeleton";
import useAuth from "src/hooks/selectors/useAuth";
import useBackUrl from "src/hooks/useBackUrl";

interface ChatHeadProps {
	avatar?: string;
	name?: string;
	subtitle?: string;
	profileId?: string;
	backUrl?: string;
	taskUrl?: string;
	taskLabel?: string;
	headActions?: any;
}

const ChatHead = ({
	avatar,
	name,
	profileId,
	backUrl,
	subtitle,
	headActions,
	taskUrl,
	taskLabel,
}: ChatHeadProps) => {
	const { t } = useTranslation("common");
	const auth = useAuth();
	const back = useBackUrl();
	const { type } = auth;
	return (
		<div
			className={`flex items-center sticky rounded-t-md top-0 z-[44] bg-background justify-between border-b-[1px] border-border p-4`}
		>
			<div className="flex items-center gap-4">
				{backUrl && (
					<Link to={backUrl} className="px-3">
						<i className="far fa-angle-left"></i>
					</Link>
				)}
				<div className="flex items-center gap-2">
					<Avatar src={avatar} alt={name} />
					<div className="flex flex-col">
						<strong>{name}</strong>
						{subtitle && <small>{subtitle}</small>}
					</div>
				</div>
			</div>
			<div className="flex items-center gap-4">
				{headActions}

				{taskUrl && (
					<Link className="underline flex items-center" to={taskUrl}>
						<i className="fas fa-file mr-2"></i>
						<div className="hidden md:flex flex-1">
							<span className="line-clamp-1">
								{taskLabel || t("task")}
							</span>
						</div>
					</Link>
				)}

				{type === "company" && profileId && (
					<Link
						className="underline flex items-center"
						to={`/view-profile/${profileId}?${back}`}
					>
						<i className="fas fa-user mr-2"></i>
						<div className="hidden md:flex flex-1">
							<span className="line-clamp-1">{t("profile")}</span>
						</div>
					</Link>
				)}
			</div>
		</div>
	);
};

const Loading = () => {
	const { type } = useAuth();
	return (
		<div className={`flex items-center justify-between p-4 md:p-6`}>
			<div className="flex items-center gap-2">
				<Skeleton className="w-[38px] h-[38px]" />
				<Skeleton className="w-[50px] h-[50px]" />
				<Skeleton className="w-[120px] h-[27px]" />
			</div>

			{type === "company" && <Skeleton className="w-[120px] h-[27px]" />}
		</div>
	);
};

ChatHead.Loading = Loading;

export default ChatHead;
