import { useState } from "react";
import { useTranslation } from "react-i18next";
import Button from "src/components/Button";
import Card from "src/components/Card";
import Checkbox from "src/components/form/Checkbox";
import useOfferRegistration from "src/hooks/api/useOfferRegistration";
import usePost from "src/hooks/rest/usePost";
import useAuth from "src/hooks/selectors/useAuth";

interface OfferAcceptFormProps {
	onDone?: () => void;
	variant?: "form" | "card";
}

const OfferAcceptForm = ({ onDone, variant }: OfferAcceptFormProps) => {
	const { t } = useTranslation("offer", {
		keyPrefix: "offer-accept-form",
	});
	const { registration, setOfferRegistration } = useOfferRegistration();
	const auth = useAuth();
	const [isAgreed, setIsAgreed] = useState(false);
	const query = new URLSearchParams({
		"with[0]": "offer",
		"with[1]": "company",
		"with[2]": "user",
	});
	const [handleAccept, posting] = usePost(
		`/student/offers/${registration?.offer?.id}/registrations/${registration.id}/accept?${query}`
	);

	const onSubmit = (e?: any) => {
		if (e) e.preventDefault();
		if (!isAgreed) return;
		handleAccept({}).then(({ data }) => {
			setOfferRegistration(data);
			if (onDone) {
				onDone();
			}
		});
	};

	const renderChildren = () => (
		<>
			<strong>{t("title")}</strong>
			<p className="mb-0">{t("description")}</p>

			<div className="my-4 flex gap-2">
				<Checkbox
					value={isAgreed}
					onChange={() => setIsAgreed(!isAgreed)}
					label={t("agree")}
				/>
			</div>

			<form {...{ onSubmit }} className="flex flex-col mt-4">
				<Button
					loading={posting}
					disabled={!isAgreed}
					submit
					type="green"
					className="flex-1"
				>
					{t("button")}
				</Button>
			</form>
		</>
	);

	if (variant === "form") {
		return renderChildren();
	}

	return (
		<Card
			className={
				auth.type === "company"
					? "bg-dark-accent text-white"
					: "bg-background"
			}
			contentClassName={
				auth.type === "company" ? "text-white" : undefined
			}
		>
			{renderChildren()}
		</Card>
	);
};

OfferAcceptForm.defaultProps = {
	variant: "card",
};

export default OfferAcceptForm;
