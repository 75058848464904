import withTranslation, {
	Translation,
} from "src/components/hoc/withTranslation";
import TabNav from "src/components/layout/tab-nav/TabNav";
import useTenant from "src/hooks/api/services/tenants/useTenant";
import useAuth from "src/hooks/selectors/useAuth";

const ProfileTabs = ({ t }: Translation) => {
	const {
		tenant: { modules },
	} = useTenant();
	const auth = useAuth();
	const tabs = [];

	if (modules.includes("intern-task")) {
		tabs.push({
			to: "/profile/progress",
			label: t("my-progress"),
		});
	}

	tabs.push({
		to: "/profile/show",
		label: t("my-profile"),
	});

	// if (modules.includes("extern-task") && auth?.type === "student") {
	// 	tabs.push({
	// 		to: "/profile/resume",
	// 		label: t("my-resume"),
	// 	});
	// }

	if (modules.includes("intern-task") || auth.type === "company") {
		tabs.push({
			to: "/profile/billing",
			label: t("my-subscription"),
		});
	}

	if (auth.type === "company") {
		tabs.push(
			{
				to: "/profile/company",
				label: t("company-profile"),
			},
			{
				to: "/profile/users",
				label: t("teams-and-users"),
			}
		);
		if (modules.includes("extern-task")) {
			tabs.push({
				to: "/profile/companies",
				label: t("companies"),
			});
		}
	}

	return (
		<div className="flex flex-col mb-4">
			<TabNav {...{ tabs }} />
		</div>
	);
};

ProfileTabs.locale = {
	nl: {
		"my-progress": "Mijn voortgang",
		"my-profile": "Mijn profiel",
		"my-resume": "Mijn CV",
		"my-subscription": "Mijn abonnement",
		"company-profile": "Bedrijfsprofiel",
		companies: "Vestigingen",
		users: "Gebruikers",
		teams: "Teams",
		"teams-and-users": "Teams & Gebruikers",
	},
	en: {
		"my-progress": "My Progress",
		"my-profile": "My Profile",
		"my-resume": "My Resume",
		"my-subscription": "My Subscription",
		"company-profile": "Company Profile",
		companies: "Branches",
		users: "Users",
		teams: "Teams",
		"teams-and-users": "Teams & Users",
	},
};

export default withTranslation(ProfileTabs);
