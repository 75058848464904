import React, { useMemo } from "react";
import Badge from "src/components/Badge";

interface RegistrationScoreBadgeProps {
	score: number;
}

const RegistrationScoreBadge = ({ score }: RegistrationScoreBadgeProps) => {
	const props = useMemo(() => {
		if (score >= 70) {
			return {
				className: "bg-green text-white border-0",
			};
		}

		if (score >= 10) {
			return {
				className: "bg-orange text-white border-0",
			};
		}

		return {
			bg: "gray",
			color: "dark",
		};
	}, [score]);
	return (
		<Badge {...props} size="small">
			{score}% match
		</Badge>
	);
};

export default RegistrationScoreBadge;
