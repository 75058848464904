import { useNavigate, useParams } from "react-router-dom";
import Button from "src/components/Button";
import DropdownButton from "src/components/DropdownButton";
import Loader from "src/components/Loader";
import withTranslation, {
	Translation,
} from "src/components/hoc/withTranslation";
import PublishTaskButton from "src/components/tasks/PublishTaskButton";
import useTask from "src/hooks/api/services/tasks/task/useTask";
import useNavigateModal from "src/hooks/useNavigateModal";
import useWindowSize from "src/hooks/useWindowSize";

interface TaskModalActionsProps extends Translation {
	onClose?: () => void;
}

const TaskModalActions = ({ t, onClose }: TaskModalActionsProps) => {
	const { id } = useParams();
	// const { status, task, actions } = useTaskForm();
	const { status, task, actions, update } = useTask({
		id,
	});
	const { isPhone, isTablet } = useWindowSize();
	const navigate = useNavigate();
	const navigateModal = useNavigateModal();
	const iconButtonClassName = "w-[40px] h-[40px] text-[20px]";

	return (
		<div className="flex items-center gap-3">
			{task?.hid && (
				<>
					{!isPhone && (
						<>
							{task.type === "INTERNAL" && (
								<PublishTaskButton
									id={task.hid}
									variant="button"
									button={{ small: true }}
								/>
							)}

							<Button
								onClick={() => {
									if (status === "idle") {
										actions.update(update);
									}
								}}
								disabled={
									status !== "idle" ||
									!Object.keys(update || {}).length
								}
								className="w-[40px] h-[40px]"
								tabIndex="-1"
								type="primary"
								iconOnly
								small
							>
								{["updating", "creating"].includes(status) ? (
									<Loader />
								) : (
									<i className="far fa-save"></i>
								)}
							</Button>

							<Button
								onClick={() => {
									navigate(`/tasks/${task.hid}/editor`);
								}}
								className="w-[40px] h-[40px]"
								tabIndex="-1"
								type="gray"
								iconOnly
								small
							>
								<i className="far fa-eye"></i>
							</Button>
						</>
					)}
					{task.type !== "TEMPLATE" && (
						<DropdownButton
							menu={{
								position: "bottom-right",
							}}
							button={{
								tabIndex: "-1",
								children: <i className="far fa-ellipsis-v " />,
								iconOnly: true,
								className: iconButtonClassName,
								type: "gray",
								small: true,
								as: "div",
							}}
						>
							{isPhone && (
								<>
									<PublishTaskButton
										id={task.hid}
										variant="dropdown-button"
									/>
								</>
							)}
							{task.type === "INTERNAL" && (
								<DropdownButton.Item
									icon="far fa-retweet"
									onClick={(close) => {
										close();
										navigateModal(
											`./repeat-task/${task.hid}`
										);
									}}
								>
									{t("repeat-internal")}
								</DropdownButton.Item>
							)}
							<DropdownButton.Item
								icon="far fa-eye"
								onClick={() => {
									navigate(`/tasks/${task.hid}/editor`);
								}}
							>
								{t("preview")}
							</DropdownButton.Item>
							<DropdownButton.Item
								loading={status === "duplicating"}
								icon="far fa-copy"
								onClick={() => {
									actions.duplicate().then(() => {
										if (onClose) {
											onClose();
										}
									});
								}}
							>
								{t("duplicate")}
							</DropdownButton.Item>
							<DropdownButton.Item
								loading={status === "deleting"}
								icon="far fa-archive"
								onClick={() =>
									actions.archive().then(() => {
										if (onClose) {
											onClose();
										}
									})
								}
							>
								{t("archive")}
							</DropdownButton.Item>
						</DropdownButton>
					)}
				</>
			)}

			{onClose && !isTablet && (
				<Button
					tabIndex="-1"
					type="transparent"
					iconOnly
					className={iconButtonClassName}
					onClick={onClose}
					small
				>
					<i className="fal fa-times text-placeholder text-2xl"></i>
				</Button>
			)}
		</div>
	);
};

TaskModalActions.locale = {
	nl: {
		preview: "Preview",
		publish: "Uitbesteden",
		archive: "Archiveren",
		duplicate: "Dupliceren",
		"repeat-internal": "Herhalen",
	},
	en: {
		preview: "Preview",

		publish: "Outsource",
		archive: "Archive",
		duplicate: "Duplicate",
		"repeat-internal": "Repeat",
	},
};

export default withTranslation(TaskModalActions);
