import {
	ComponentProps,
	useCallback,
	useEffect,
	useMemo,
	useState,
} from "react";
import Button from "src/components/Button";
import withTranslation, {
	Translation,
} from "src/components/hoc/withTranslation";
import Loader from "src/components/Loader";
import { cn } from "src/lib/utils";

const VALIDATE_PARTS = ["educations", "jobs", "validate"] as const;
const STATUSES = ["pending", "started", "valid"] as const;
const INTERVAL_TIME = 1000;

type ValidatePart = (typeof VALIDATE_PARTS)[number];
type Status = (typeof STATUSES)[number];

interface SkillValidationStatusProps extends Translation {
	onSubmit: () => void;
	stayLoading?: boolean;
}

const SkillValidationStatus = ({
	t,
	onSubmit,
	stayLoading,
}: SkillValidationStatusProps) => {
	const [statuses, setStatuses] = useState<Record<ValidatePart, Status>>({
		// educations: "started",
		// jobs: "pending",
		// validate: "pending",
		educations: "valid",
		jobs: "valid",
		validate: "valid",
	});
	const updateStatus = useCallback(() => {
		setStatuses((prev) => {
			const newStatuses = { ...prev };
			for (const part of VALIDATE_PARTS) {
				const currentStatusIndex = STATUSES.indexOf(prev[part]);
				if (currentStatusIndex < STATUSES.length - 1) {
					// If next status would be "pending", skip to "started"
					const nextStatus = STATUSES[currentStatusIndex + 1];
					newStatuses[part] =
						nextStatus === "pending" ? "started" : nextStatus;
					return newStatuses;
				}
			}
			return prev;
		});
	}, []);
	const allValid = useMemo(
		() =>
			Object.values(statuses).every((status) => status === "valid") &&
			!stayLoading,
		[statuses, stayLoading]
	);

	useEffect(() => {
		const interval = setInterval(updateStatus, INTERVAL_TIME);
		return () => clearInterval(interval);
	}, [updateStatus]);
	return (
		<>
			<div className="flex flex-col gap-4">
				{VALIDATE_PARTS.map((name) => (
					<PendingSkillCheckItem
						key={`check-item-${name}`}
						label={`${t(`checks.${name}`)}`}
						status={
							name === "validate" &&
							stayLoading &&
							statuses[name] === "valid"
								? "started"
								: (statuses[name] as any)
						}
					/>
				))}
			</div>
			<Button
				type="primary"
				disabled={!allValid}
				onClick={() => onSubmit()}
			>
				{t("button")}
			</Button>
		</>
	);
};

SkillValidationStatus.locale = {
	nl: {
		checks: {
			educations: "Vaardigheden zoeken op basis van jouw opleidingen",
			jobs: "Vaardigheden zoeken op basis van jouw werkervaring",
			validate: "Resultaten controleren en samenvoegen",
		},
		button: "Nulpunt maken",
	},
	en: {
		checks: {
			educations: "Search skills based on your educations",
			jobs: "Search skills based on your job experience",
			validate: "Check results and combine",
		},
		button: "Create starting point",
	},
};

interface PendingSkillCheckItemProps {
	label: string;
	status: "pending" | "started" | "valid";
}

const PendingSkillCheckItem = ({
	label,
	status,
	className,
	...rest
}: PendingSkillCheckItemProps & ComponentProps<"div">) => {
	return (
		<div
			{...rest}
			className={cn(
				"flex items-center gap-2 transition-all",
				status === "valid" && "text-primary",
				status === "pending" && "opacity-50",
				className
			)}
		>
			<div className="flex w-6 justify-center items-center">
				{status === "pending" && <i className="far fa-clock"></i>}
				{status === "started" && <Loader />}
				{status === "valid" && <i className="far fa-check"></i>}
			</div>
			<span>{label}</span>
		</div>
	);
};

export default withTranslation(SkillValidationStatus);
