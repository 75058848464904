const inbox = {
	"inbox-view": {
		title: "Messages",
		select: "Select a message first to chat",
		"not-found": "We couldn't find a chat",
		back: "Back",
	},
};

export default inbox;
