import { useTranslation } from "react-i18next";
import { ApiPayment } from "src/api/types";
import Card from "src/components/Card";
import KeyValue from "src/components/KeyValue";
import useGet from "src/hooks/rest/useGet";

interface OppPaymentDetailCardProps {
	payment: ApiPayment;
}

const OppPaymentDetailCard = ({ payment }: OppPaymentDetailCardProps) => {
	const { t } = useTranslation("payments");
	const [{ data }, loading] = useGet(
		`/company/payments/${payment.hid}/opp-details`
	);
	return (
		<Card
			{...{ loading }}
			title={t("information-card.title")}
			className=" "
		>
			<div className="flex flex-col gap-1">
				{data?.payment_details && (
					<>
						<KeyValue.Small
							bold
							label="IBAN"
							text={
								data.payment_details.provider_bank_account_iban
							}
						/>
						<KeyValue.Small
							bold
							label="BIC"
							text={
								data.payment_details.provider_bank_account_bic
							}
						/>
						<KeyValue.Small
							bold
							label={t("information-card.addressed-to")}
							text={
								data.payment_details.provider_bank_account_name
							}
						/>
						<KeyValue.Small
							bold
							label={t("information-card.reference")}
							text={`${data.payment_details.reference} / ${payment.invoice_number}`}
						/>
					</>
				)}
			</div>
		</Card>
	);
};

export default OppPaymentDetailCard;
