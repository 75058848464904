import withTranslation, {
	Translation,
} from "src/components/hoc/withTranslation";
import Tab from "src/components/layout/Tab";
import Tabs from "src/components/layout/Tabs";
import useTasksView from "src/hooks/useTasksView";

interface TasksLayoutViewsProps extends Translation {
	children?: any;
}

const TasksLayoutViews = ({ t, children }: TasksLayoutViewsProps) => {
	const { view, setView } = useTasksView();

	return (
		<Tabs.Container>
			<Tab onClick={() => setView("kanban")} active={view === "kanban"}>
				<i className="far fa-game-board"></i>
				<span>{t("kanban")}</span>
			</Tab>
			<Tab
				onClick={() => setView("calendar")}
				active={view === "calendar"}
			>
				<i className="fas fa-calendar"></i>
				<span>{t("calendar")}</span>
			</Tab>
			<Tab onClick={() => setView("list")} active={view === "list"}>
				<i className="fas fa-list-alt"></i>
				<span>{t("list")}</span>
			</Tab>
		</Tabs.Container>
	);
};

TasksLayoutViews.locale = {
	nl: {
		kanban: "Bord",
		calendar: "Kalender",
		list: "Lijst",
	},
	en: {
		kanban: "Board",
		calendar: "Calendar",
		list: "List",
	},
};

export default withTranslation(TasksLayoutViews);
