import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import Button from "src/components/Button";
import Link from "src/components/Link";
import Logo from "src/components/Logo";
import ReadMore from "src/components/ReadMore";
import useNavigateModal from "src/hooks/useNavigateModal";
import { ApiBadge } from "src/api/types";
import Avatar from "src/components/Avatar";
import Card from "src/components/Card";
import Stars from "src/components/Stars";
import "./resume-header.scss";
import useAuth from "src/hooks/selectors/useAuth";
import useBackUrl from "src/hooks/useBackUrl";

interface ResumeHeaderProps {
	backgroundColor: string;
	avatar?: string;
	editable?: boolean;
	name?: string;
	description?: string;
	rating?: number;
	badges?: ApiBadge[];
}

const ResumeHeader = ({
	backgroundColor,
	avatar,
	editable,
	name,
	description,
	rating,
}: ResumeHeaderProps) => {
	const { t } = useTranslation(["buttons"]);
	const auth = useAuth();
	const navigateModal = useNavigateModal();
	const navigate = useNavigate();
	const backUrl = useBackUrl();

	return (
		<Card>
			<div
				style={{ backgroundColor }}
				className={`p-6 mb-6 flex rounded-md`}
			>
				<div className="flex -mb-6 flex-1 justify-between relative">
					<div className="flex">
						<div
							onClick={() => {
								if (editable) {
									navigateModal("/dashboard/resume/avatar");
								}
							}}
							className={`w-[63px] h-[63px] -mb-4 relative ${
								editable && `cursor-pointer`
							}`}
						>
							<Avatar
								defaultIcon="smiley"
								className="bg-accent-background border-0 text-background-foreground"
								src={avatar}
								size="medium"
							/>
							{editable && (
								<div className="absolute -right-2 -bottom-2">
									<Button.Icon
										customColor={backgroundColor}
										icon="fas fa-pencil"
									/>
								</div>
							)}
						</div>
						<Logo
							className="w-[50%] md:w-[40%] ml-3"
							color="white"
						/>
					</div>
					{editable && (
						<div className="flex gap-2 -mb-4 justify-end items-end">
							<Button.Icon
								onClick={() => {
									navigateModal("/dashboard/theme/edit");
								}}
								customColor={backgroundColor}
								icon="fas fa-palette"
							/>
							<Button.Icon
								onClick={() =>
									navigate(
										`/dashboard/resume/preview/${auth.id}?${backUrl}`
									)
								}
								customColor={backgroundColor}
								icon="fas fa-eye"
							/>
						</div>
					)}
				</div>
			</div>

			<div className="md:px-6 mt-8">
				{rating ? (
					<div className="mb-2">
						<Stars className="remove-m" rating={rating} />
					</div>
				) : (
					false
				)}
				<strong>{name}</strong>

				{description && <ReadMore text={description} length={200} />}

				{editable && (
					<Link
						to={`/profile/resume/create/general?${backUrl}`}
						className="mt-2 inline-block"
					>
						<small>
							<i className="fas fa-pencil mr-2"></i>
							{t("edit", {
								ns: "buttons",
							})}
						</small>
					</Link>
				)}
			</div>
		</Card>
	);
};

export default ResumeHeader;
