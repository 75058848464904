import {
	useCallback,
	useEffect,
	useLayoutEffect,
	useRef,
	useState,
} from "react";

type Options = {
	ref?: any;
	debug?: boolean;
};

const useIsomorphicLayoutEffect =
	typeof window !== "undefined" ? useLayoutEffect : useEffect;

export const useElementSizeByRef = (ref: any, options?: Options) => {
	const [size, setSize] = useState(getRect());
	const handleResize = useCallback(() => {
		if (!ref.current) {
			return;
		}
		const size = getRect(ref.current);
		if (options?.debug) {
			console.log("GOT SIZE -> ", size);
		}
		setSize(size);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [ref]);

	const getSize = (ref: any) => {
		const _size = getRect(ref.current);
		setSize(_size);
		return _size;
	};

	useIsomorphicLayoutEffect(() => {
		const element = ref.current;
		if (!element) {
			return;
		}

		handleResize();

		if (typeof ResizeObserver === "function") {
			let resizeObserver = new ResizeObserver(() => handleResize());
			resizeObserver.observe(element);

			return () => {
				if (!resizeObserver) {
					return;
				}

				resizeObserver.disconnect();
				resizeObserver = null as any;
			};
		} else {
			// Browser support, remove freely
			window.addEventListener("resize", handleResize);

			return () => {
				window.removeEventListener("resize", handleResize);
			};
		}
	}, [ref, handleResize]);

	return {
		ref,
		size,
		getSize,
	};
};

const useElementSize = (options?: Options) => {
	const ref = useRef(null) as any;
	return useElementSizeByRef(options?.ref || ref, options);
};

function getRect(element?: any) {
	if (!element) {
		return {
			bottom: 0,
			height: 0,
			left: 0,
			right: 0,
			top: 0,
			width: 0,
		};
	}
	const size = element.getBoundingClientRect();
	size.width = element?.clientWidth;
	size.height = element?.clientHeight;
	return size;
}

export default useElementSize;
