import { useEffect, useState } from "react";

function useScrollPosition() {
	const [y, setY] = useState(0);

	useEffect(() => {
		const onScroll = (e: any) => {
			setY(e.target.documentElement.scrollTop);
		};

		window.addEventListener("scroll", onScroll);

		return () => window.removeEventListener("scroll", onScroll);
	}, []);

	return y;
}

export default useScrollPosition;
