import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import useTenant from "src/hooks/api/services/tenants/useTenant";
import useTaskForm from "src/hooks/api/tasks/useTaskForm";
import useModules from "src/hooks/selectors/useModules";
import useQueryString from "src/hooks/useQueryString";

function useTaskFormSteps() {
	const { tenant } = useTenant();
	const { t } = useTranslation("tasks", {
		keyPrefix: "steps",
	});
	const current = useSelector((state: any) => state.taskForm.current);
	const { profile } = useQueryString();
	const { modules } = useModules();
	const { task } = useTaskForm();
	const hasStudentPool = modules.includes("student-pool") && !profile;
	let steps = [t("description"), t("budget"), t("location"), t("date")];

	if (
		modules?.includes("subtasks") ||
		tenant?.modules?.includes("subtasks")
	) {
		steps.splice(1, 0, t("shifts"));
	}

	if (task.subtasks?.length) {
		steps.splice(steps.indexOf(t("budget")), 1);
	}

	if (!task.student) {
		steps.push(t("form"));
	}

	if (hasStudentPool) {
		steps.push(t("invite"));
	}

	if (tenant.modules?.includes("ai-description-creation") && task?.id) {
		steps = [
			t("planning.questions"),
			t("planning.budget"),
			t("form"),
			t("planning.extra"),
			// t("planning.publish"),
		];
	}

	if (task.isManualDescription) {
		steps = [
			t("planning.questions"),
			t("description"),
			t("planning.budget"),
			t("form"),
			t("planning.extra"),
		];
	}

	return {
		current,
		currentStep: steps[current],
		steps,
		total: steps.length,
		isFinal: !steps[current],
	};
}

export default useTaskFormSteps;
