import React from "react";
import { useTranslation } from "react-i18next";
import { ApiOffer } from "src/api/types";
import Card from "src/components/Card";
import ShareButton from "src/components/promotion/share/ShareButton";
import ShareClipboard from "src/components/promotion/share/ShareClipboard";
import Skeleton from "src/components/Skeleton";
import useTenant from "src/hooks/api/services/tenants/useTenant";

interface OfferShareCardProps {
	offer: ApiOffer;
	className?: string;
}

const OfferShareCard = ({ offer, className }: OfferShareCardProps) => {
	const { t } = useTranslation("offer", {
		keyPrefix: "offer-share-card",
	});
	const isPublished =
		offer.visibility.status === "PUBLISHED" && offer.visibility.is_verified;
	const { tenant } = useTenant();
	const share = {
		title: offer.title,
		description: offer?.description?.what || "",
		url: tenant?.urls?.public + `/talentenbord/${offer.slug}`,
	};

	return (
		<Card className={`${className} relative`}>
			{!isPublished && (
				<div className="absolute inset-0 p-4 z-20 text-center flex justify-center items-center flex-col backdrop-blur-sm dark:bg-dark-accent/30 bg-background/30 ">
					<strong>{t("not-allowed.title")}</strong>
					<small className="text-center">
						{t("not-allowed.description")}
					</small>
				</div>
			)}

			<strong>{t("title")}</strong>
			<div className="flex flex-wrap items-center my-2 gap-2">
				<ShareButton {...{ share }} type="facebook" />
				<ShareButton {...{ share }} type="linkedin" />
				<ShareButton {...{ share }} type="twitter" />
				<ShareButton {...{ share }} type="whatsapp" />
				<ShareButton {...{ share }} type="mail" />
			</div>
			<ShareClipboard url={share.url} />
		</Card>
	);
};

const Loading = ({ className }: { className?: string }) => (
	<Card
		className={`flex gap-4 flex-col justify-center flex-1 ${className}`}
		contentClassName="flex flex-col gap-4 p-6"
	>
		<Skeleton className="h-4 w-[50%]" />

		<div className="flex items-center gap-2">
			<Skeleton className="w-12 h-12" />
			<Skeleton className="w-12 h-12" />
			<Skeleton className="w-12 h-12" />
			<Skeleton className="w-12 h-12" />
		</div>
		<div className="flex gap-2">
			<Skeleton className="h-12 flex-1" />
			<Skeleton className="w-12 h-12" />
		</div>
	</Card>
);

OfferShareCard.Loading = Loading;

export default OfferShareCard;
