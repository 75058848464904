import axios from "axios";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Navigate } from "react-router-dom";
import Button from "src/components/Button";
import Link from "src/components/Link";
import Logo from "src/components/Logo";
import withTranslation, {
	Translation,
} from "src/components/hoc/withTranslation";
import LanguageSelector from "src/components/language/LanguageSelector";
import CreateTaskChat from "src/components/tasks/create-task-chat/CreateTaskChat";
import { FormattedTaskChat } from "src/components/tasks/create-task-chat/Final";
import Message from "src/components/tasks/create-task-chat/Message";
import useAuthSession from "src/hooks/api/services/session/useAuthSession";
import useTenant from "src/hooks/api/services/tenants/useTenant";
import useTheme from "src/hooks/useTheme";
import { cn } from "src/lib/utils";

const REFERRAL_TYPE = "TASK_AI_SIGNUP";

const IS_DISABLED = true;

function SignupCompanyAIView({ t }: Translation) {
	const { tenant } = useTenant();
	const { theme, toggle } = useTheme();
	const [formatted, setFormatted] = useState<FormattedTaskChat>();
	const [generateStatus, setGenerateStatus] = useState<
		"idle" | "loading" | "finished"
	>("idle");
	const [description, setDescription] = useState<any>();
	const {
		session,
		actions: sessionActions,
		status: sessionStatus,
	} = useAuthSession();

	useEffect(() => {
		const sessionId = sessionActions.getCookie();
		if (sessionId) {
			sessionActions.find(sessionId).catch(() => {
				sessionActions.create({
					type: "COMPANY",
					referral_type: REFERRAL_TYPE,
				});
			});
		} else {
			sessionActions.create({
				type: "COMPANY",
				referral_type: REFERRAL_TYPE,
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const getGeneratedDescription = (formatted: FormattedTaskChat) => {
		setGenerateStatus("loading");
		setDescription(undefined);
		setFormatted(formatted);
		axios
			.post(`/ai/generate-description`, formatted, {
				responseType: "stream",
				onDownloadProgress: function (progressEvent) {
					setDescription(progressEvent.currentTarget.response);
				},
			})
			.then((response) => {
				setDescription(response?.data);
				return response?.data;
			})
			.finally(() => {
				setGenerateStatus("finished");
			});
	};

	const handleRedirecToSignup = async () => {
		const value = {
			type: "COMPANY" as const,
			referral_type: REFERRAL_TYPE,
			task: { ...formatted, description },
		};
		if (!session?.id) {
			const newSession = await sessionActions.create(value);
			if (!newSession?.id) {
				return null;
			}
			return sessionActions.redirect(newSession.id);
		}
		await sessionActions.update(session?.id, value);
		return sessionActions.redirect(session.id);
	};

	if (IS_DISABLED) {
		return <Navigate to="/signup/company" />;
	}

	return (
		<>
			<div className="flex flex-col flex-1 dark:bg-background bg-accent">
				{tenant.slug === "getjobsdone" && (
					<Helmet>
						<script>{`(function(h,o,t,j,a,r){
                                h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
                                h._hjSettings={hjid:3867718,hjsv:6};
                                a=o.getElementsByTagName('head')[0];
                                r=o.createElement('script');r.async=1;
                                r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
                                a.appendChild(r);
                            })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');`}</script>
					</Helmet>
				)}

				<nav className="flex max-w-xl mx-auto w-full p-4 items-center justify-between">
					<Logo
						color={theme === "dark" ? "white" : "dark"}
						variant="small-outline"
						className="w-10"
					/>
					<div className="flex items-center gap-4">
						<div className="flex gap-2 items-center">
							<LanguageSelector theme="transparent" />
							<Button
								xsmall
								type="border-bg"
								onClick={() => toggle()}
							>
								<i
									className={cn(
										"far",
										theme !== "dark" ? "fa-sun" : "fa-moon"
									)}
								></i>
							</Button>
						</div>
						<Link to="/login">{t("nav.login")}</Link>
					</div>
				</nav>

				<div className="flex flex-col flex-1 md:py-6">
					<div className="flex flex-col flex-1 max-w-xl w-full mx-auto">
						<CreateTaskChat
							className="w-full rounded-t-xl md:rounded-xl"
							contentClassName="p-2"
							autoSubmit
							isLoading={generateStatus === "loading"}
							onSubmit={(formatted) =>
								getGeneratedDescription(formatted)
							}
							extra={["BUDGET"]}
						>
							{description && (
								<>
									<Message
										variant="SYSTEM"
										theme={{
											body: "max-w-[100%]",
											card: "max-w-full",
										}}
										value={
											<>
												<strong>
													{formatted?.title}
												</strong>{" "}
												<div
													className="flex flex-col gap-2 description"
													dangerouslySetInnerHTML={{
														__html: description,
													}}
												></div>
											</>
										}
										loading={false}
									/>
									{generateStatus === "finished" && (
										<>
											<p className="mt-2 opacity-50">
												{t("edit")}
											</p>

											<div className="flex flex-col gap-2 mt-2">
												<Button
													loading={
														sessionStatus !== "idle"
													}
													onClick={
														handleRedirecToSignup
													}
													type="primary"
												>
													{t("create")}
												</Button>
												<div className="flex gap-1 items-center">
													<div className="flex px-2">
														<img
															src="/assets/images/memoji/1.webp"
															alt=""
															className="w-8 h-8 z-[4]"
														/>
														<img
															src="/assets/images/memoji/2.webp"
															alt=""
															className="w-8 h-8 -ml-2 z-[3]"
														/>
														<img
															src="/assets/images/memoji/3.webp"
															alt=""
															className="w-8 h-8 -ml-2 z-[2]"
														/>
														<img
															src="/assets/images/memoji/4.webp"
															alt=""
															className="w-8 h-8 -ml-2"
														/>
													</div>
													<p className="italic flex-1">
														{t("create-for-free")}
													</p>
												</div>
											</div>
										</>
									)}
								</>
							)}
						</CreateTaskChat>
					</div>
				</div>
			</div>
		</>
	);
}

SignupCompanyAIView.locale = {
	nl: {
		nav: {
			login: "Login",
		},
		theme: {
			dark: "Donker",
			light: "Licht",
		},
		signup: "Aanmelden",
		"modal-title": "Bijna klaar",
		fields: {
			first_name: {
				placeholder: "Voornaam",
			},
			last_name: {
				placeholder: "Achternaam",
			},
			email: {
				placeholder: "Email",
			},
			telephone: {
				placeholder: "Telefoonnummer",
			},
			password: {
				placeholder: "Wachtwoord",
			},
			company_name: {
				placeholder: "Bedrijfsnaam",
			},
		},
		edit: "Je kan de opdracht omschrijving later nog aanpassen",
		create: "Plaats opdracht",
		"create-for-free":
			"Stuur je opdracht naar {{global.students}} (young) professionals",
	},
	en: {
		nav: {
			login: "Login",
		},
		theme: {
			dark: "Dark",
			light: "Light",
		},
		signup: "Signup",

		"modal-title": "Almost ready",
		fields: {
			first_name: {
				placeholder: "Firstname",
			},
			last_name: {
				placeholder: "Lastname",
			},
			email: {
				placeholder: "Email",
			},
			telephone: {
				placeholder: "Telephone",
			},
			password: {
				placeholder: "Password",
			},
			company_name: {
				placeholder: "Company name",
			},
		},
		edit: "You can edit the task description later",
		create: "Create task",
		"create-for-free":
			"Send your task to {{global.students}} (young) professionals",
	},
};

export default withTranslation(SignupCompanyAIView);
