import React, { ComponentProps } from "react";
import { ApiIcon } from "src/api/types";
import Icon from "src/components/icon/Icon";
import Text from "src/components/ui/text";
import { cn } from "src/lib/utils";

interface CardHeadProps extends ComponentProps<"div"> {
	title?: string;
	description?: string;
	icon?: ApiIcon;
	children?: any;
}

export const CardHead = ({
	title,
	description,
	icon,
	children,
	...rest
}: CardHeadProps) => (
	<div {...rest} className={cn("flex flex-col mb-2", rest?.className)}>
		{(title || description) && (
			<div className="flex flex-col">
				{title && (
					<div className="flex items-center gap-2">
						{icon && (
							<Icon
								className="bg-background text-background-foreground hover:bg-background hover:text-background-foreground"
								iconFontSize={14}
								{...{ icon }}
							></Icon>
						)}
						<Text.Eyebrow className="leading-full">
							{title}
						</Text.Eyebrow>
						{/* <strong className="text-[18px] leading-full">
							{title}
						</strong> */}
					</div>
				)}
				{description && <p>{description}</p>}
			</div>
		)}
		{children}
	</div>
);

export default CardHead;
