import Breadcrumb from "src/components/Breadcrumb";
import Button from "src/components/Button";
import withTranslation, {
	Translation,
} from "src/components/hoc/withTranslation";
import Icon from "src/components/icon/Icon";
import Layout from "src/components/layout/Layout";
import ProjectStatus from "src/components/projects/ProjectStatus";
import RefreshKanbanButton from "src/components/tasks/RefreshKanbanButton";
import CreateTaskButton from "src/components/tasks/TaskForm/elements/CreateTaskButton";
import useProjects from "src/hooks/api/services/projects/useProjects";
import useNavigateModal from "src/hooks/useNavigateModal";

interface ProjectTasksLayoutProps extends Translation {
	children?: any;
}

const ProjectTasksLayout = ({ t, children }: ProjectTasksLayoutProps) => {
	const { project } = useProjects();
	const navigateModal = useNavigateModal();
	return (
		<Layout
			// title={t("title")}
			breadcrumb={
				<Breadcrumb
					variant="dark"
					routes={[
						{
							label: t("breadcrumbs.home"),
							to: "/projects",
							icon: Icon.getFontAwesome("fas fa-folder"),
						},
						{
							label: project?.name,
							to: `/projects/${project?.slug}`,
						},
					]}
				/>
			}
			head={{
				children: (
					<div className="flex flex-col gap-3">
						<div className="flex justify-between items-center gap-3">
							<div className="flex flex-row gap-4 items-end">
								<Icon
									className="bg-dark w-14 hover:bg-dark"
									icon={project?.icon}
								/>
								<div>
									<div className="flex flex-row gap-2 items-center mb-1">
										<h2>{project?.name}</h2>
										<Button
											type="none"
											iconOnly={true}
											icon="fa fa-pencil"
											to={`/projects/${project.slug}/edit`}
										/>
									</div>
									<ProjectStatus
										completedTasks={
											project.progress?.completed
										}
										totalTasks={project?.progress?.total}
									/>
								</div>
							</div>
							<div className="flex flex-row gap-4 items-center">
								<RefreshKanbanButton />
								<Button
									type="gray"
									onClick={() => {
										navigateModal(`./share`);
									}}
								>
									<i className="fas fa-users mr-2"></i>
									{t("share")}
								</Button>
								<CreateTaskButton variant="INTERN" />
							</div>
						</div>
					</div>
				),
			}}
		>
			{children}
		</Layout>
	);
};

ProjectTasksLayout.locale = {
	nl: {
		title: "Projecten",
		breadcrumbs: {
			home: "Projecten",
		},
		create: "Taak",
		share: "Delen",

		back_to_projects: "Terug naar projecten",
	},
	en: {
		title: "Projects",
		breadcrumbs: {
			home: "Projects",
		},
		create: "Task",
		share: "Share",

		back_to_projects: "Back to projects",
	},
};

export default withTranslation(ProjectTasksLayout);
