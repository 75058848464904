import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import useQueryString from "src/hooks/useQueryString";
import useTheme from "src/hooks/useTheme";

type BackButtonProps = {
	to?: string;
	label?: string;
	type?: string;
	noText?: boolean;
	onClick?: () => void;
	className?: string;
};

const BackButton = ({
	to,
	label,
	type,
	noText,
	onClick,
	className,
}: BackButtonProps) => {
	const query = useQueryString();
	const { theme } = useTheme();
	const { t } = useTranslation(["buttons"]);
	const classes = [
		"back-button gap-1.5 flex items-center font-bold cursor-pointer",
		type === "light" || theme === "dark"
			? "gtm-werkgever-dashboard--button-terug-naar-overzicht text-white"
			: "gtm-student-dashboard--button-terug-naar-overzicht dark:text-white text-background-foreground",
	];

	if (className) classes.push(className);
	let defaultLabel = t("back-to-overview");

	if (noText) defaultLabel = "";

	const getBackUrl = () => {
		if (query?.back) {
			return atob(query.back as string);
		}
		return to || "";
	};

	if (onClick) {
		return (
			<div {...{ onClick }} className={classes.join(" ")}>
				<i className="fas fa-arrow-left text-[14px]"></i>
				{label || ""}
			</div>
		);
	}
	return (
		<NavLink to={getBackUrl()} className={classes.join(" ")}>
			<i className="fas fa-arrow-left text-[14px]"></i>

			{label || defaultLabel}
		</NavLink>
	);
};

export default BackButton;
