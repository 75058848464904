import { createAction, handleActions } from "redux-actions";
import { ApiTask } from "src/api/types";

export type TaskFormStatus =
	| "filling"
	| "loading"
	| "idle"
	| "created"
	| "creating"
	| "error"
	| "updating"
	| "deleting"
	| "duplicating";

type ApiTaskAnswer = {
	question: string;
	answer: string;
};

export interface TaskForm extends Omit<ApiTask, "id"> {
	id?: number | string;
	is_home?: boolean;
	is_office?: boolean;
	is_anonymous?: boolean;
	advertise_task?: boolean;
	media?: any;
	students?: any[];
	answers: ApiTaskAnswer[];
}

export type SubtaskForm = {
	_id: string;
	id: string;
	title: string;
	start_date: string;
	end_date: string;
	budget: number;
	budget_per_hour?: number;
	amount_of_students: number;
	estimated_duration?: number;
};

export const updateTask = createAction("UPDATE_TASK");
export const updateStatusTask = createAction("UPDATE_STATUS_TASK");
export const removeTask = createAction("REMOVE_TASK");

export const emptyTaskState = {
	task: {
		title: "",
		description: "",
		is_home: false,
		is_office: false,
		is_anonymous: false,
		advertise_task: false,
		media: undefined,
		amount_of_students: 1,
		students: [],
		answers: [],
		status: "BACKLOG",
	},
	changes: {},
	status: "idle",
	current: 0,
	loading: true,
};

type TaskState = {
	task: TaskForm;
	status: TaskFormStatus;
	changes: any;
	current: number;
	loading: boolean;
};

const taskFormReducer = handleActions(
	{
		UPDATE_TASK: (state: TaskState, action: any) => {
			return { ...state, ...action.payload };
		},
		UPDATE_STATUS_TASK: (state: TaskState, action: any) => {
			return {
				...state,
				status: action.payload,
			};
		},
		REMOVE_TASK: () => {
			return emptyTaskState as any;
		},
	},
	emptyTaskState as any
);
export default taskFormReducer;
