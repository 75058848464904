import React, { useState } from "react";
import { ApiTaskKanbanStatus } from "src/api/types";
import Icon from "src/components/icon/Icon";
import useTasksKanban from "src/hooks/api/tasks/useTasksKanban";
import { cn } from "src/lib/utils";

const RefreshKanbanButton = () => {
	const { actions: kanbanActions, state: kanbanState } = useTasksKanban();
	const [loading, setLoading] = useState<boolean>(false);
	const handleRefresh = () => {
		setLoading(true);
		kanbanActions
			.getColumns(
				Object.keys(kanbanState.columns) as ApiTaskKanbanStatus[]
			)
			.then(() => {
				setLoading(false);
			});
	};
	return (
		<Icon
			onClick={handleRefresh}
			iconFontSize={14}
			className={cn("cursor-pointer text-placeholder")}
			icon={{
				font_awesome: {
					name: `far fa-sync-alt ${loading ? "fa-spin" : ""}`,
				},
			}}
		/>
	);
};

export default RefreshKanbanButton;
