const unprofessionalReport = {
    title: "Report",
    placeholder: "Describe the behavior of the professional",
    required: "Required",
    options: {
        not_there:"Professional doesn't show up",
        incorrect_language:"Professional uses incorrect language",
        other:"Other:"
    }
}

export default unprofessionalReport