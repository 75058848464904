import { useRef } from "react";
import { AutoFillTypes } from "src/api/types";
import Wrapper, { WrapperProps } from "src/components/field/utils/Wrapper";
import AutoFillOptions from "src/components/form/Input/AutoFillOptions";
import useTheme from "src/hooks/useTheme";
import { cn } from "src/lib/utils";

interface InputProps {
	wrapper: WrapperProps;
	as?: "input" | "textarea";
	value?: string | number;
	placeholder?: string;
	onChange: (value?: string | null) => void;
	className?: string;
	type?: string;
	disabled?: boolean;
	theme?: {
		placeholder?: {
			className?: string;
		};
	};
	step?: number;
	min?: number;
	onFocus?: () => void;
	onBlur?: () => void;
	autoFill?: AutoFillTypes;
}

const Input = ({
	wrapper,
	as: As = "input",
	value,
	onChange,
	className,
	placeholder,
	type = "text",
	step,
	min = 0,
	disabled,
	onFocus,
	onBlur,
	autoFill,
}: InputProps) => {
	const { theme } = useTheme();
	const input: any = useRef();
	const wrapWithAutofill = (children: JSX.Element) => {
		if (autoFill) {
			return (
				<AutoFillOptions
					{...{ value }}
					onSelect={(s) => {
						onChange(s);
					}}
					type={autoFill}
				>
					{children}
				</AutoFillOptions>
			);
		}
		return children;
	};

	return (
		<Wrapper
			{...{
				...wrapper,
				onFocus: () => !disabled && input?.current?.focus(),
			}}
		>
			{wrapWithAutofill(
				<As
					className={cn(
						"w-max rounded-md px-3 placeholder:text-placeholder bg-transparent text-accent-foreground border-transparent border focus:border-border",
						As === "textarea" && "pt-2 min-h-[120px]",
						As === "input" && "h-[44px]",
						disabled && "bg-accent cursor-not-allowed",
						className
					)}
					style={{ colorScheme: theme }}
					onChange={(event) =>
						!disabled && onChange(event.target.value)
					}
					{...{
						value,
						placeholder,
						type,
						step,
						disabled,
						min,
						onFocus,
						onBlur,
					}}
				/>
			)}
		</Wrapper>
	);
};

export default Input;
