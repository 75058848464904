import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { ApiPaymentRequest } from "src/api/types";
import Avatar from "src/components/Avatar";
import Button from "src/components/Button";
import CustomFormPreviewField from "src/components/custom-form/CustomFormPreviewField";
import EmptyState from "src/components/EmptyState";
import withTranslation, {
	Translation,
} from "src/components/hoc/withTranslation";
import PaymentRequestStatus from "src/components/payment-request/elements/PaymentRequestStatus";
import PaymentRequestDateCell from "src/components/payment-request/visualizations/PaymentRequestDateCell";
import {
	HoverCard,
	HoverCardContent,
	HoverCardPortal,
	HoverCardTrigger,
} from "src/components/ui/hover-card";
import List from "src/components/ui/list";
import {
	Tooltip,
	TooltipContent,
	TooltipProvider,
	TooltipTrigger,
} from "src/components/ui/tooltip";
import usePaymentRequests, {
	PaymentRequestFilter,
} from "src/hooks/api/services/payment-requests/usePaymentRequests";
import useAuth from "src/hooks/selectors/useAuth";
import useNavigateModal from "src/hooks/useNavigateModal";
import { formatPrice } from "src/lib/formatters";
import { cn } from "src/lib/utils";

const MAX_SELECTED = 25;

interface PaymentRequestProcessTableProps extends Translation {
	children?: any;
	filter?: PaymentRequestFilter;
}

const PaymentRequestProcessTable = ({
	t,
	filter,
}: PaymentRequestProcessTableProps) => {
	const navigateModal = useNavigateModal();
	const auth = useAuth();
	const { requests, status, actions, pagination } = usePaymentRequests({
		stateId: "PAYMENT_REQUESTS_PROCESS_TABLE",
		filter,
	});
	const location = useLocation();

	useEffect(() => {
		actions.list(filter);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [location, filter]);

	return (
		<List
			data={requests}
			{...{ status }}
			showEmptyList
			keepListWhileLoading
			className="relative flex-1"
			selectMaximum={MAX_SELECTED}
		>
			{requests.length === 0 && status === "idle" && (
				<EmptyState.Overlay className="p-4">
					<EmptyState.Title>{t("empty.title")}</EmptyState.Title>
					<EmptyState.Description>
						{t("empty.description")}
					</EmptyState.Description>
				</EmptyState.Overlay>
			)}
			{auth.type === "company" && (
				<List.Selected variant="floating-card">
					{(selected, clear) => {
						let params = new URLSearchParams();
						selected.forEach((item: any) => {
							params.append("ids", item);
						});
						return (
							<>
								<small className="hidden md:block">
									{t("selected", {
										count: selected.length,
										max: MAX_SELECTED,
									})}
								</small>
								<Button
									xsmall
									onClick={() => {
										clear();
										navigateModal(
											`/payment-requests/process/checkout?${params}`
										);
									}}
								>
									<i className="far fa-file-invoice-dollar"></i>
									{t("buttons.create-payment")}
								</Button>
							</>
						);
					}}
				</List.Selected>
			)}
			<List.Head>
				<List.Item className="md:pr-0 px-0">
					<div className="pl-2">
						<List.SelectAll
							dataKey="id"
							exclude={requests
								.filter(
									(r: ApiPaymentRequest) =>
										!r?.receiver?.has_merchant
								)
								.map((r) => r.id)}
						/>
					</div>
				</List.Item>
				<List.Item className="">{t("headers.status")}</List.Item>
				<List.Item className={cn()}>
					{t(
						`headers.${
							auth.type === "student" ? "company" : "student"
						}`
					)}
				</List.Item>
				<List.Item>{t("headers.description")}</List.Item>
				<List.Item>{t("headers.date")}</List.Item>
				<List.Item>{t("headers.price")}</List.Item>
				<List.Item>{t("headers.total")}</List.Item>
			</List.Head>
			<List.Loading count={12} />
			<List.Items>
				{(row) => {
					const hasConnectionFields =
						Object.values(row?.connection_fields || {}).length > 0;

					return (
						<List.Row id={row.id}>
							<List.Item className="md:pr-0 px-0">
								<div className="pl-2">
									{["APPROVED"].includes(row.status) &&
										row.receiver.has_merchant &&
										row.price !== null && <List.Select />}

									{row?.receiver &&
										!row?.receiver.has_merchant && (
											<TooltipProvider>
												<Tooltip>
													<TooltipTrigger asChild>
														<div className="flex justify-center">
															<i className="fas fa-exclamation opacity-70"></i>
														</div>
													</TooltipTrigger>
													<TooltipContent className="max-w-[300px] text-center">
														{t("missing-merchant")}
													</TooltipContent>
												</Tooltip>
											</TooltipProvider>
										)}
								</div>
							</List.Item>
							<List.Item>
								<PaymentRequestStatus status={row.status} />
							</List.Item>
							<List.Item>
								<div className="flex">
									<HoverCard>
										<HoverCardTrigger className="w-max pr-2">
											<div className="flex gap-3 items-center w-max">
												<Avatar
													src={row?.receiver?.avatar}
												/>
												<div className="flex flex-col flex-1">
													<span>
														{
															row?.receiver
																?.full_name
														}
													</span>
													{hasConnectionFields && (
														<span className="opacity-50">
															{t(
																"extra-information-on-hover"
															)}
														</span>
													)}
												</div>
											</div>
										</HoverCardTrigger>
										{hasConnectionFields && (
											<HoverCardPortal>
												<HoverCardContent
													side="right"
													align="center"
													sideOffset={8}
												>
													<div className="flex flex-col gap-3">
														{Object.values(
															row.connection_fields
														).map((field: any) => (
															<div
																key={`${row.id}-${field.label}`}
																className="flex gap-2 items-center justify-between"
															>
																<span className="opacity-70">
																	{
																		field.label
																	}
																	:
																</span>
																<CustomFormPreviewField
																	{...{
																		field,
																	}}
																/>
															</div>
														))}
													</div>
												</HoverCardContent>
											</HoverCardPortal>
										)}
									</HoverCard>
								</div>
							</List.Item>
							<List.Item>
								<p className="max-w-[360px]">
									{row.description}
								</p>
							</List.Item>
							<List.Item>
								<PaymentRequestDateCell request={row} />
							</List.Item>
							<List.Item>
								{row.price ? formatPrice(row.price) : "-"}
							</List.Item>
							<List.Item>
								{row.total ? formatPrice(row.total) : "-"}
							</List.Item>
							<List.Item align="right">
								<Button
									xsmall
									type="ghost"
									to={{
										modal: `/payment-requests/process/${row.id}/delete`,
									}}
								>
									{t("buttons.delete")}
								</Button>
							</List.Item>
						</List.Row>
					);
				}}
			</List.Items>

			<List.Pagination
				{...{ pagination }}
				onNavigate={pagination.setPage}
			/>
		</List>
	);
};

PaymentRequestProcessTable.locale = {
	nl: {
		selected: "{{count}} / {{max}} geselecteerd",
		headers: {
			status: "Status",
			company: "Opdrachtgever",
			student: "Opdrachtnemer",
			description: "Omschrijving",
			date: "Datum",
			price: "Waarde",
			total: "Totaal",
		},
		buttons: {
			"create-payment": "Betaling maken",
			delete: "Annuleren",
		},
		empty: {
			title: "Geen verzoeken gevonden",
			description: "Er zijn geen verzoeken die verwerkt moeten worden.",
		},
		"extra-information-on-hover": "Extra informatie",
		"missing-merchant":
			"Opdrachtnemer moet zich eerst onboarden bij de betaalprovider.",
	},
	en: {
		selected: "{{count}} / {{max}} selected",
		headers: {
			status: "Status",
			company: "Client",
			student: "Contractor",
			description: "Description",
			date: "Date",
			price: "Value",
			total: "Total",
		},
		"extra-information-on-hover": "Extra information",
		buttons: {
			"create-payment": "Make Payment",
			delete: "Cancel",
		},
		empty: {
			title: "No requests found",
			description: "There are no requests to be processed.",
		},
		"missing-merchant":
			"Contractor must first onboard with the payment provider.",
	},
};

export default withTranslation(PaymentRequestProcessTable);
