import { useState } from "react";
import axios from "axios";
import { useDispatch } from "react-redux";
import { updateProfile } from "src/redux/auth";

const useRefetchProfile = () => {
	const [loading, setLoading] = useState(false);
	const dispatch = useDispatch();

	const refetchProfile = async () => {
		setLoading(true);
		dispatch(updateProfile({ isLoading: true }));
		const { data } = await axios.get(`/auth/profile`);
		dispatch(updateProfile(data?.data));
		setLoading(false);
	};

	return {
		fetching: loading,
		refetchProfile,
	};
};

export default useRefetchProfile;
