import { useNavigate } from "react-router-dom";
import withTranslation, {
	Translation,
} from "src/components/hoc/withTranslation";
import PaymentRequestSelectForm from "src/components/payment-request/PaymentRequestSelectForm";
import useAuth from "src/hooks/selectors/useAuth";
import useModal from "src/hooks/useModal";

const SelectPaymentRequestModal = ({ t }: Translation) => {
	const auth = useAuth();
	const navigate = useNavigate();
	const modal = useModal({
		title: t(`${auth.type}.title`),
	});

	const onSubmit = (value: string) => {
		if (!value) {
			modal.onClose();
			return;
		}
		navigate(`/payment-requests/${value}/show`);
	};

	return <PaymentRequestSelectForm {...{ onSubmit }} />;
};

SelectPaymentRequestModal.locale = {
	nl: {
		student: {
			title: "Selecteer opdrachtgever",
		},
		company: {
			title: "Selecteer opdrachtnemer",
		},
	},
	en: {
		student: {
			title: "Select client",
		},
		company: {
			title: "Select contractor",
		},
	},
};

export default withTranslation(SelectPaymentRequestModal);
