import { useEffect, useRef } from "react";
import ScrollView from "react-inverted-scrollview";
import Button from "src/components/Button";
import useChat from "src/hooks/api/useChat";
import {
	ApiMessage,
	ApiOffer,
	ApiOfferRegistration,
	ApiRegistration,
	ApiTask,
} from "src/api/types";
import Message from "src/components/chat/Message";
import useWindowSize from "src/hooks/useWindowSize";
import useElementSize from "src/hooks/useElementSize";
import { useTranslation } from "react-i18next";

type MessagesBoxProps = {
	id: number | string;
	data: {
		registration: ApiRegistration | ApiOfferRegistration;
		task?: ApiTask;
		offer?: ApiOffer;
	};
	type: "offer" | "task";
};

const MessagesBox = ({ id, data, type }: MessagesBoxProps) => {
	const { t } = useTranslation("chat", {
		keyPrefix: "messages-box",
	});
	const scrollview: any = useRef(null);
	const size = useWindowSize();
	const container = useElementSize();
	const { registration }: any = data;
	const { response, loading, error, pending, handleLoadMore } = useChat(
		id,
		scrollview,
		type
	);
	const hasMore = response?.meta?.current_page < response?.meta?.last_page;
	const messages = (response?.data || []).sort((a: any, b: any) =>
		a.id > b.id ? 1 : -1
	);

	useEffect(() => {
		container.getSize(container.ref);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [loading]);

	if (loading) {
		return (
			<div className="flex flex-1 flex-col justify-center items-center">
				<span className="base-loader"></span>
			</div>
		);
	}

	return (
		<div ref={container.ref} className="flex flex-col flex-1 relative">
			<ScrollView
				width={container.size.width}
				height={container.size.height}
				ref={scrollview}
			>
				<div className="flex flex-1 flex-col p-4 md:p-6 pt-24 gap-4 overflow-y-auto">
					{!size.isPhone && (
						<div className="block h-32 w-full">
							<div className="h-32  w-full block"></div>
						</div>
					)}

					{error?.errors?.disabled && (
						<div className="flex-1 flex items-center flex-col justify-center">
							<strong>{t("turned-off")}</strong>
						</div>
					)}

					{!error?.errors && (
						<>
							{registration.motivation && (
								<div className="flex justify-center items-center">
									<div
										className={`max-w-[400px] w-full rounded-md p-8 bg-accent dark:bg-background`}
									>
										<b>{t("motivation")}:</b>
										<p className="mb-0">
											{registration.motivation}
										</p>
									</div>
								</div>
							)}

							{hasMore && (
								<div className="flex items-center justify-center">
									<Button
										loading={pending}
										type="white"
										small
										onClick={handleLoadMore}
									>
										{t("button-older-messages")}
									</Button>
								</div>
							)}

							{messages.map((item: ApiMessage) => (
								<Message
									key={item.id + "Message"}
									{...{ ...item }}
								/>
							))}
						</>
					)}
				</div>
			</ScrollView>
		</div>
	);
};

interface WrapperProps {
	children?: any;
}

const Wrapper = ({ children }: WrapperProps) => {
	const { size, ref } = useElementSize();

	return (
		<div className="flex flex-col flex-1 relative" {...{ ref }}>
			<div
				style={{ maxHeight: size.height }}
				className="flex flex-col flex-1 relative"
			>
				{children}
			</div>
		</div>
	);
};

const Loading = () => {
	return (
		<div className="flex flex-1 flex-col p-4 md:p-6 pt-24 gap-4 overflow-y-auto">
			<Message.Loading is_me />
			<Message.Loading />
			<Message.Loading lines={2} />
		</div>
	);
};

MessagesBox.Loading = Loading;
MessagesBox.Wrapper = Wrapper;

export default MessagesBox;
