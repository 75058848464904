import { useSetAtom } from "jotai";
import { useCallback } from "react";
import { ApiTaskKanban, ApiTaskKanbanStatus } from "src/api/types";
import { tasksFilterAtom } from "src/hooks/api/tasks/useTasksFilter";
import useTasksKanban, {
	TasksKanbanState,
} from "src/hooks/api/tasks/useTasksKanban";
import { TaskSocketEvent } from "src/hooks/api/tasks/useTasksListeners";
import { store } from "src/lib/jotai";

export default function useTasksKanbanUpdater() {
	const {
		state,
		api,
		actions: { getColumn, setStatus, setState },
		atom,
	} = useTasksKanban();

	const set = useSetAtom(atom);

	const handleStatus = useCallback(
		(res: any) => {
			const ids = Object.values(state?.columns || {})
				.map((column) => column?.tasks || [])
				.reduce((acc: any, curr: any) => [...acc, ...curr], [])
				.map((item) => item.id);

			if (res?.from && res?.to && res?.id && res.to !== res.from) {
				if (ids.includes(res.id)) {
					setStatus(res);
				} else {
					getColumn(res.to);
				}
			}
		},
		[getColumn, setStatus, state]
	);

	const handleEvent = useCallback(
		async (event: TaskSocketEvent) => {
			if (!event.id) {
				return;
			}
			const filter = store.get(tasksFilterAtom);
			let data: any = null;
			const _id = event?.data?._id;
			//Check if is allowed to be applied to the board
			if (["CREATE", "UPDATE"].includes(event.type)) {
				const result = await api
					.get(`/kanban/tasks/${event.id}`, {
						params: {
							filter,
							validate_filter: true,
						},
					})
					.then(({ data }) => data);

				data = result?.data?.id ? result?.data : null;
			}

			set((state: TasksKanbanState) => {
				const keys: ApiTaskKanbanStatus[] = Object.keys(
					state.columns
				) as any[];

				if (!keys.length) {
					return state;
				}

				const ids = Object.values(state?.columns || {})
					.map((column) => column?.tasks || [])
					.reduce((acc: any, curr: any) => [...acc, ...curr], [])
					.map((item) => item.id);

				if (event.type === "DELETE" && ids?.includes(event.id)) {
					const keys: ApiTaskKanbanStatus[] = Object.keys(
						state.columns
					) as any[];
					const status = keys.find((key: ApiTaskKanbanStatus) => {
						const exists = state.columns[key]?.tasks?.find(
							(item: any) => item.id === event.id
						);
						return exists ? true : false;
					});

					if (status) {
						const column = state.columns[status];

						column.tasks = column?.tasks?.filter(
							(item: ApiTaskKanban) => item.id !== event.id
						);
						column.meta.total =
							column?.meta?.total > 0 ? column.meta.total - 1 : 0;
						return {
							...state,
							columns: {
								...state.columns,
								[status]: column,
							},
						};
					}
				}

				if (event.type === "CREATE" && data?.id && data?.status) {
					const status = data.status;
					if (data?.id && status) {
						const column =
							state.columns[status as ApiTaskKanbanStatus];

						if (
							!_id ||
							!column.tasks.find((item: any) => item._id === _id)
						) {
							column.tasks = [data, ...column?.tasks];
							column.meta.total = (column?.meta?.total || 0) + 1;
						}
						return {
							...state,
							columns: {
								...state.columns,
								[status]: column,
							},
						};
					}
				}

				if (event.type === "UPDATE") {
					// Find the status by the id
					let oldStatus = keys.find((key: ApiTaskKanbanStatus) => {
						const exists = state.columns[key].tasks.find(
							(item: any) => item.id === event.id
						);
						return exists ? true : false;
					}) as ApiTaskKanbanStatus;

					if (!data?.id && oldStatus) {
						setState((state: any) => {
							const column = state.columns[oldStatus];
							column.tasks = column?.tasks?.filter(
								(item: ApiTaskKanban) => item.id !== event.id
							);
							return {
								...state,
								columns: {
									...state.columns,
									[oldStatus]: column,
								},
							};
						});
					}

					if (data?.id) {
						if (oldStatus && data.status !== oldStatus) {
							const old = state.columns[oldStatus];
							if (old.status !== "loading") {
								old.tasks = old.tasks.filter(
									(item: ApiTaskKanban) =>
										item.id !== event.id
								);
							}

							const newStatus =
								data.status as ApiTaskKanbanStatus;
							const updated = state.columns[newStatus];
							if (updated.status !== "loading") {
								updated.tasks = [data, ...updated.tasks];
							}
							return {
								...state,
								columns: {
									...state.columns,
									[oldStatus]: old,
									[newStatus]: updated,
								},
							};
						} else {
							const column =
								state.columns[
									data.status as ApiTaskKanbanStatus
								];

							if (column?.tasks) {
								column.tasks = column?.tasks?.map(
									(item: ApiTaskKanban) =>
										item.id === event.id ? data : item
								);

								if (
									!column.tasks.find(
										(item: any) => item.id === event.id
									)
								) {
									column.tasks = [...column.tasks, data];
								}
							}

							return {
								...state,
								columns: {
									...state.columns,
									[data.status]: column,
								},
							};
						}
					}
				}

				//Do nothing with the state
				return state;
			});
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[api, setState, state.columns]
	);

	return { handleEvent, handleStatus };
}
