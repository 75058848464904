import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ApiLanguage } from "src/api/types";
import Button from "src/components/Button";
import LanguageSelectItem from "src/components/language/LanguageSelectItem";
import useLanguage from "src/hooks/api/useLanguage";
import useAuth from "src/hooks/selectors/useAuth";
import i18n from "src/lib/i18n";

interface LanguageSelectListProps {
	className?: string;
	onSubmit?: () => void;
	button?: {
		label?: string;
	};
}

const LanguageSelectList = ({
	onSubmit,
	className,
	button,
}: LanguageSelectListProps) => {
	const { language, updating, actions } = useLanguage();
	const { t } = useTranslation();
	const auth = useAuth();
	const [selected, setSelected] = useState(auth?.language);
	const languages = language?.languages || [];

	useEffect(() => {
		actions.get();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleSubmit = async () => {
		if (!selected?.code) return;
		await actions.update(selected.code);

		if (onSubmit) {
			onSubmit();
		}
	};

	return (
		<div className="">
			<ul className={`flex flex-col gap-4 ${className || ""}`}>
				{languages?.length === 0 ? (
					<>
						<LanguageSelectItem.Loading />
						<LanguageSelectItem.Loading />
					</>
				) : (
					<>
						{languages.map((language: ApiLanguage) => (
							<LanguageSelectItem
								key={language.code}
								{...{ language }}
								onClick={() => {
									setSelected(language);
									i18n.changeLanguage(
										language.code.toLowerCase()
									);
								}}
								selected={language.code === selected?.code}
							/>
						))}
					</>
				)}
			</ul>
			<div className="mt-8">
				<Button
					loading={updating}
					onClick={handleSubmit}
					disabled={!selected?.code}
				>
					{button?.label || t("continue", { ns: "buttons" })}
				</Button>
			</div>
		</div>
	);
};

export default LanguageSelectList;
