import { useEffect, useState } from "react";
import { ApiUserMinimal } from "src/api/types";
import Skeleton from "src/components/Skeleton";
import { TitleCutBackground } from "src/components/TitleCut";
import useTeams from "src/hooks/api/services/users/useTeams";
import useUsers from "src/hooks/api/services/users/useUsers";
import useAuth from "src/hooks/selectors/useAuth";
import useVariant, { VariantTypes } from "src/hooks/useVariant";
import { cutString, formatList } from "src/lib/formatters";
import { cn } from "src/lib/utils";

const sizes = {
	xsmall: "rounded-md w-8 h-8",
	small: "rounded-lg w-10 h-10",
	regular: "rounded-lg w-12 h-12",
	medium: "rounded-lg w-16 h-16",
	large: "rounded-lg w-24 h-24",
	button: "rounded-md w-[40px] h-[40px]",
	xlarge: "rounded-lg w-32 h-32 text-[32px]",
	xxlarge: "rounded-lg w-48 h-48 text-[32px]",
	resume: "rounded-xl w-56 h-56",
};

export type AvatarSize = keyof typeof sizes;

export interface AvatarProps {
	src?: string;
	variant?: VariantTypes;
	className?: string;
	alt?: string;
	size?: AvatarSize;
	defaultIcon?: "smiley" | "me";
}

const Avatar = ({
	src,
	alt,
	className: _className,
	size = "small",
	defaultIcon,
	variant,
}: AvatarProps) => {
	const { me } = useUsers();
	const _variant = useVariant(variant);
	const [isError, setIsError] = useState(src ? false : true);
	const className = cn(
		"overflow-hidden flex justify-center items-center bg-accent dark:bg-border",
		sizes[size],
		(isError || src === "me") && variant && _variant,
		_className
	);

	useEffect(() => {
		if (isError) {
			setIsError(false);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [src]);

	const getIcon = () => {
		if (src === "me") {
			return <span className="text-[12px]">{me?.full_name || alt}</span>;
		}
		if (src && !isError) {
			return (
				<img
					onError={() => setIsError(true)}
					className="w-full h-full object-cover"
					src={src}
					alt={alt}
				/>
			);
		}
		if (defaultIcon === "smiley") {
			return (
				<img
					className="w-full h-full block p-2"
					src="/assets/images/emoji/smiley.png"
					alt=""
				/>
			);
		}

		if (defaultIcon === "me") {
			return <span className="text-sm">{alt}</span>;
		}
		return <i className="fas fa-user"></i>;
	};

	return <div {...{ className }}>{getIcon()}</div>;
};

interface LoaderProps {
	size?: AvatarSize;
	variant?: "user";
	bg?: any;
}

const Loader = ({ size = "small", variant, bg }: LoaderProps) => {
	if (variant === "user") {
		return (
			<div className="flex items-center gap-2">
				<Skeleton className={sizes[size]} {...{ bg }} />
				<div className="flex flex-col flex-1 whitespace-nowrap max-w-[110px] gap-1">
					<Skeleton className="w-full h-3" {...{ bg }} />
					<Skeleton className="w-1/2 h-3" {...{ bg }} />
				</div>
			</div>
		);
	}

	return <Skeleton className={sizes[size]} {...{ bg }} />;
};

Avatar.Loader = Loader;

const USER_VARIANTS = {
	bold: {
		label: "font-bold",
	},
	light: {
		label: "",
	},
};

interface UserProps {
	user: ApiUserMinimal;
	subtitle?: any;
	className?: string;
	avatar?: {
		size?: AvatarSize;
		className?: string;
	};
	variant: keyof typeof USER_VARIANTS;
	options?: {
		iconOnly?: boolean;
		titleCutBackground?: TitleCutBackground;
	};
	wrapper?: {
		className?: string;
	};
}

const User = ({
	user,
	subtitle,
	className,
	avatar,
	variant: _variant,
	options,
	wrapper,
}: UserProps) => {
	const auth = useAuth();
	const variant = USER_VARIANTS[_variant];
	const { teams } = useTeams();

	if (!subtitle && user?.company) {
		subtitle = `${user?.company?.name}`;
		if (user.teams) {
			const names = teams
				.filter((team) => (user.teams || []).includes(team.slug))
				.map((team) => team.name);
			subtitle = formatList(names, "nl");
		}
	}

	return (
		<div className={cn("flex items-center gap-2", className)}>
			<Avatar
				size={avatar?.size || "small"}
				className={avatar?.className}
				src={user?.avatar}
			/>
			{!options?.iconOnly && (
				<div
					className={cn(
						"flex flex-col max-w-[110px] gap-1 flex-1",
						wrapper?.className,
						avatar?.size === "xsmall" && "text-sm"
					)}
				>
					<p
						className={cn(
							"line-clamp-1",
							avatar?.size === "medium" && "text-xl",
							variant.label
						)}
					>
						{user?.company && auth.type === "student"
							? `${user.first_name}`
							: user.full_name}
					</p>

					{subtitle && typeof subtitle === "string" ? (
						<small
							className={cn(
								"leading-full line-clamp-1",
								avatar?.size === "medium" && "text-md"
							)}
						>
							{avatar?.size === "medium"
								? subtitle
								: cutString(subtitle, 14)}
						</small>
					) : (
						subtitle
					)}
				</div>
			)}
		</div>
	);
};

User.defaultProps = {
	variant: "bold",
};

Avatar.User = User;

export default Avatar;
