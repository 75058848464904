const signer = {
	"preview-view": {
		title: "Agreement",
		back: "Back to Project",
	},
	"signer-view": {
		confirm: {
			title: "Are You Sure",
			text: "Are you sure you want to sign this agreement",
		},
		success: {
			title: "Document Signed",
		},
		error: {
			title: "Something Went Wrong",
			text: "Please try again later",
		},
		"not-found": {
			title: "No Agreement Found",
			button: "Back to Projects",
		},
		loading: {
			title: "The Agreement is Being Signed",
			description: "Please wait a moment",
		},
		breadcrumb: {
			envelopes: "Agreements",
			envelope: "Agreement",
		},
		"information-modal": {
			title: "Information",
		},
		signers: {
			title: "Parties",
		},
	},
};

export default signer;
