import { Route } from "react-router-dom";
import ProtectedRoute from "src/routes/middlewares/ProtectedRoute";
import DashboardReviews from "src/views/authenticated/reviews/ReviewsView";

const ReviewsRoutes = [
	<Route
		element={
			<ProtectedRoute>
				<DashboardReviews />
			</ProtectedRoute>
		}
		path="/reviews/:pane?"
		key="/reviews/:pane?"
	/>,
];

export default ReviewsRoutes;
