import { ApiTimeTracking } from "src/api/types";
import FlatList from "src/components/FlatList";
import withTranslation, {
	Translation,
} from "src/components/hoc/withTranslation";
import TimeTrackerListItem, {
	Loader,
} from "src/components/time-tracker/TimeTrackerListItem";
import useTimeTracking from "src/hooks/api/services/useTimeTracking";

interface TimeTrackerListProps extends Translation {
	data: {
		task_id: number;
	};
}

const TimeTrackerList = ({ t, data }: TimeTrackerListProps) => {
	const { list, actions, pagination, status } = useTimeTracking();

	return (
		<FlatList
			loading={status === "loading"}
			fetching={status === "loading"}
			data={list}
			pagination={{
				setPage: (page) => actions.list({ ...data, page }),
				page: pagination.page,
				last_page: pagination.last_page,
			}}
			loadingRows={4}
			renderLoadingItem={(index) => <Loader key={`time-${index}`} />}
			renderItem={(time: ApiTimeTracking) => (
				<TimeTrackerListItem key={`item-${time.id}`} {...{ time }} />
			)}
			gap={8}
			empty={{
				title: t(`empty.title`),
				text: t(`empty.text`),
			}}
		/>
	);
};

TimeTrackerList.locale = {
	nl: {
		empty: {
			title: "Geen tijden gevonden",
			text: "Er zijn nog geen tijden bijgehouden",
		},
	},
	en: {
		empty: {
			title: "No times found",
			text: "There are no times tracked",
		},
	},
};

export default withTranslation(TimeTrackerList);
