import React, { useEffect, useState } from "react";
import { HexColorPicker } from "react-colorful";
import { useNavigate } from "react-router-dom";
import Button from "src/components/Button";
import Modal from "src/components/Modal";
import useAuth from "src/hooks/selectors/useAuth";
import { useTranslation } from "react-i18next";
import useResume from "src/hooks/api/services/resume/useResume";

const ResumeEditThemeModal = () => {
	const { t } = useTranslation("resume-view", {
		keyPrefix: "theme-modal",
	});
	const navigate = useNavigate();
	const auth = useAuth();
	const { resume, actions, status } = useResume({
		rememberKey: auth.id,
	});
	const [color, setColor] = useState(resume.primary_color);
	const handleSave = () => {
		if (color !== auth.primary_color) {
			actions
				.update(
					{
						id: auth.id,
						color,
					},
					{
						updateList: true,
					}
				)
				.then(() => {
					navigate("/profile/resume");
				});
		}
	};

	useEffect(() => {
		actions.get(auth.id);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<Modal
			title={t("title")}
			visible
			onClose={() => navigate("/profile/resume")}
			className="md:max-w-[300px]"
		>
			<HexColorPicker color={color} onChange={setColor} />
			<Modal.Buttons>
				<Button
					loading={status === "updating" || status === "loading"}
					type="dark"
					className="gtm-student-dashboard-mijn-cv--button-kleur-aanpassen flex-1"
					onClick={handleSave}
				>
					{t("button")}
				</Button>
			</Modal.Buttons>
		</Modal>
	);
};

export default ResumeEditThemeModal;
