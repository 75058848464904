import FormGrid from "src/components/form/FormGrid";
import Input from "src/components/form/Input";
import NotificationSettingsItem from "src/components/settings/notifications/NotificationSettingsItem";
import NotificationSettingsSection from "src/components/settings/notifications/NotificationSettingsSection";
import useNotificationSettings from "src/hooks/api/useNotificationSettings";
import useDebounce from "src/hooks/useDebounce";

const NotificationSettings = () => {
	const [value, onChange, debouncedValue] = useDebounce("", 500);
	const { settings } = useNotificationSettings({
		query: debouncedValue ? `q=${debouncedValue}` : undefined,
	});

	let items = {
		...settings,
	};

	if (items.signer) {
		items = {
			...items,
			envelope: [...(items?.envelope || []), ...(items?.signer || [])],
		};
		delete items.signer;
	}

	const titles: any = {
		task: "Opdrachten",
		envelope: "Overeenkomsten",
		payment: "Betalingen",
		registration: "Aanmeldingen",
		mandate: "Bankkoppelingen",
		message: "Berichten",
		offer: "Aanbod",
		offerregistration: "Aanbod aanmeldingen",
		review: "Reviews",
		user: "Algemeen",
		taskinvite: "Uitnodigingen",
	};
	return (
		<>
			<Input
				name="search-settings"
				onChange={(_, value) => onChange(value)}
				placeholder="Notificaties zoeken"
				icon={<i className="fas fa-search" />}
				{...{ value }}
			/>
			<FormGrid>
				{Object.keys(items).map((name) => (
					<NotificationSettingsSection
						title={titles[name]}
						key={name}
					>
						{items[name].map((notification, index) => (
							<NotificationSettingsItem
								key={`${index}-${notification}`}
								{...{ notification }}
							/>
						))}
					</NotificationSettingsSection>
				))}
			</FormGrid>
		</>
	);
};

export default NotificationSettings;
