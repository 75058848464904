import axios from "axios";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import {
	ApiEnvelopeSigner,
	ApiEnvelopeStatus,
	LayoutVariants,
} from "src/api/types";
import Avatar from "src/components/Avatar";
import Skeleton from "src/components/Skeleton";
import { ENVELOPE_STATUSES } from "src/lib/constants";

interface SignersUserProps {
	signer: ApiEnvelopeSigner;
	variant: LayoutVariants;
	isUpdateViewed: boolean;
	layout: "default" | "small";
}

const SignersUser = ({ signer, isUpdateViewed, layout }: SignersUserProps) => {
	useEffect(() => {
		if (
			signer?.id &&
			signer?.is_me &&
			signer.status === "CREATED" &&
			isUpdateViewed
		) {
			axios.post(`/envelopes/${signer.envelope_id}/viewed`);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [signer]);

	if (layout === "small") {
		return (
			<div className="flex items-center gap-2">
				<div>
					<Avatar size="small" src={signer.user?.avatar} />
				</div>
				<small className="flex flex-col flex-1 gap-1">
					<strong>{signer.user?.full_name}</strong>
					<SignersUserStatus status={signer.status} />
				</small>
			</div>
		);
	}

	return (
		<div className="flex items-center gap-2">
			<div>
				<Avatar className="w-10 h-10" src={signer.user?.avatar} />
			</div>
			<div className="flex flex-col flex-1">
				<strong>
					{signer.user?.full_name}{" "}
					{signer.user?.company
						? ` - ${signer.user.company.name}`
						: ""}
				</strong>
				<SignersUserStatus status={signer.status} />
			</div>
		</div>
	);
};

SignersUser.defaultProps = {
	layout: "default",
	isUpdateViewed: true,
};

interface SignersUserLoadingProps {
	variant?: LayoutVariants;
	layout: "default" | "small";
}

const SignersUserLoading = ({ variant, layout }: SignersUserLoadingProps) => {
	let bg = variant === "dark" ? "dark" : undefined;

	if (variant === "white") {
		bg = "white";
	}

	if (layout === "small") {
		return (
			<div className="flex gap-2">
				<div>
					<Skeleton {...{ bg }} className="w-10 h-10" />
				</div>
				<div className="flex flex-col gap-2 flex-1">
					<Skeleton {...{ bg }} className="w-3/4 h-4" />
					<Skeleton {...{ bg }} className="w-1/4 h-4" />
				</div>
			</div>
		);
	}
	return (
		<div className="flex gap-2">
			<div>
				<Skeleton {...{ bg }} className="w-14 h-14" />
			</div>
			<div className="flex flex-col gap-2 flex-1">
				<Skeleton {...{ bg }} className="w-3/4 h-6" />
				<Skeleton {...{ bg }} className="w-1/4 h-6" />
			</div>
		</div>
	);
};

SignersUserLoading.defaultProps = {
	layout: "default",
};

SignersUser.Loader = SignersUserLoading;

SignersUser.defaultProps = {
	variant: "dark",
};

interface SignersUserStatusProps {
	status: ApiEnvelopeStatus;
}

const SignersUserStatus = ({ status }: SignersUserStatusProps) => {
	const { t } = useTranslation("envelope", {
		keyPrefix: "status",
	});
	const _status = ENVELOPE_STATUSES[status];
	const text = t(status === "CREATED" ? "NOT-SEEN" : status);

	if (status === "CREATED") {
		return (
			<div className="flex items-center gap-2 opacity-40">
				<i className="fas fa-eye-slash"></i>
				{text}
			</div>
		);
	}

	return (
		<div
			className={`flex items-center gap-2 text-${
				status === "SIGNED" ? "green" : "dark"
			}`}
		>
			{_status.icon && <i className={`${_status.icon}`}></i>}
			{text}
		</div>
	);
};

export default SignersUser;
