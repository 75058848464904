import React from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import "./address-form.scss";

import Input from "src/components/form/Input";
import Button from "src/components/Button";
import { Address } from "src/api/types";
import { useTranslation } from "react-i18next";

const AddressForm = ({
	address,
	onSubmit,
	prevButton,
	disabled,
	button_title,
	fetching,
	editForm,
	type,
}: {
	address: Address;
	onSubmit: (a: Address) => void;
	prevButton?: any;
	disabled?: boolean;
	button_title?: string;
	fetching?: boolean;
	editForm?: boolean;
	type?: string;
}) => {
	const { t } = useTranslation("forms", {
		keyPrefix: "address-form",
	});

	const AddressScheme = Yup.object().shape({
		house_number: Yup.string().required(t("required")),
		zipcode: Yup.string().required(t("required")),
		street: Yup.string().required(t("required")),
		city: Yup.string().required(t("required")),
	});

	let gtmTag: string =
		"gtm-student-dashboard-complete-account-2--button-volgende-stap";
	switch (type) {
		case "company":
			gtmTag =
				"gtm-werkgever-dashboard-plaats-opdracht-3--button-volgende-stap";
			break;
		case "company-vestiging":
			gtmTag =
				"gtm-werkgever-dashboard-vestigingen--button-vesting-toevoegen";
			break;
		default:
			gtmTag =
				"gtm-student-dashboard-complete-account-2--button-volgende-stap";
			break;
	}

	return (
		<Formik
			initialValues={address}
			validationSchema={AddressScheme}
			onSubmit={(values: Address, { setSubmitting, resetForm }) => {
				onSubmit(values as Address);
			}}
		>
			{({
				values,
				errors,
				touched,
				handleChange,
				handleBlur,
				handleSubmit,
				isSubmitting,
			}) => (
				<form onSubmit={handleSubmit} className="flex flex-col gap-4">
					<div className="address-row">
						<Input
							label={t("street")}
							name="street"
							value={values.street}
							valid={values.street && !errors.street}
							error={
								errors.street && touched.street && errors.street
							}
							{...{ handleChange, handleBlur }}
						/>
						<Input
							label={t("house-number")}
							name="house_number"
							value={values.house_number}
							valid={values.house_number && !errors.house_number}
							error={
								errors.house_number &&
								touched.house_number &&
								errors.house_number
							}
							{...{ handleChange, handleBlur }}
						/>
					</div>
					<Input
						label={t("zipcode")}
						name="zipcode"
						value={values.zipcode}
						valid={values.zipcode && !errors.zipcode}
						error={
							errors.zipcode && touched.zipcode && errors.zipcode
						}
						{...{ handleChange, handleBlur }}
					/>

					<Input
						label={t("city")}
						name="city"
						value={values.city}
						valid={values.city && !errors.city}
						error={errors.city && touched.city && errors.city}
						{...{ handleChange, handleBlur }}
					/>

					<div className="flex justify-between gap-4 items-center mt-4">
						{prevButton}
						<Button
							submit
							onClick={() => {
								if (!editForm) {
									if (disabled) {
										onSubmit({} as Address);
									} else {
										handleSubmit();
									}
								}
							}}
							className={gtmTag}
							loading={isSubmitting || fetching}
						>
							{button_title || t("next-step")}
						</Button>
					</div>
				</form>
			)}
		</Formik>
	);
};

export default AddressForm;
