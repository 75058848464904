import { DateRangeFilterValue } from "src/components/date-range-filter/Provider";
import useApi from "src/hooks/api/utils/useApi";

function useTasks(
	name: string,
	filter: {
		date: DateRangeFilterValue;
		assignees?: string[];
		teams?: string[];
	}
) {
	const { state, actions, api, pagination } = useApi(
		{ id: `TASKS:${name}`, group: "TASKS" },
		{
			baseUrl: `/client/tasks`,
			query: {
				variant: "kanban",
				...(filter || {}),
				date: [
					filter.date.start.format("YYYY-MM-DD"),
					filter.date?.end?.format("YYYY-MM-DD"),
				],
				limit: 9999,
			},
		}
	);

	return {
		tasks: state?.list || [],
		status: state.status,
		pagination,
		actions: {
			...actions,
		},
		api,
	};
}

export default useTasks;
