import { ApiReferral } from "src/api/types";
import Card from "src/components/Card";
import EmptyState from "src/components/EmptyState";
import Pagination from "src/components/FlatList/Pagination";
import withTranslation, {
	Translation,
} from "src/components/hoc/withTranslation";
import ReferralPrice from "src/components/referrals/ReferralPrice";
import ReferralStatus from "src/components/referrals/ReferralStatus";
import useTenant from "src/hooks/api/services/tenants/useTenant";
import {
	ApiStatus,
	Pagination as PaginationType,
} from "src/hooks/api/utils/useApi";

interface ReferralsListProps extends Translation {
	status: ApiStatus;
	referrals: ApiReferral[];
	pagination: PaginationType;
}

const ReferralsList = ({
	t,
	status,
	referrals,
	pagination,
}: ReferralsListProps) => {
	const {
		tenant: { modules },
	} = useTenant();
	const isExtern =
		modules.includes("extern-task") && !modules.includes("intern-task");
	return (
		<Card className=" ">
			<Card.Head title={t("title")} />
			{referrals.length > 0 && (
				<>
					<ul className="flex flex-col gap-3  md:gap-2">
						{referrals.map((item) => (
							<li
								className="flex flex-col md:flex-row md:items-center md:justify-between gap-1 md:gap-6"
								key={`referral-${item.id}`}
							>
								<div className="flex justify-between items-center flex-1">
									<div className="flex flex-col">
										<p>{item?.data?.name}</p>
										{/* {item?.data?.name && item?.email && (
											<small className="text-placeholder">
												{item.email}
											</small>
										)} */}
									</div>
									{isExtern && (
										<ReferralPrice rewards={item.rewards} />
									)}
								</div>
								{isExtern ? (
									<ReferralStatus status={item.status} />
								) : (
									<i className="fas fa-check text-primary"></i>
								)}
							</li>
						))}
					</ul>
					<Pagination
						className="mt-4"
						page={{
							current: pagination.page,
							max: pagination.last_page,
						}}
						fetching={false}
						onNavigate={async (page) => {
							pagination.setPage(page);
						}}
					/>
				</>
			)}
			{status !== "loading" && referrals.length === 0 && (
				<EmptyState
					icon="far fa-users"
					variant="center"
					title={t("empty.title")}
					description={t("empty.description")}
				/>
			)}
		</Card>
	);
};

ReferralsList.locale = {
	nl: {
		title: "Referrals",
		empty: {
			title: "Geen referrals gevonden",
			description: "Je hebt nog geen referrals",
		},
	},
	en: {
		title: "Referrals",
		empty: {
			title: "No referrals found",
			description: "You have not send any invites",
		},
	},
};

export default withTranslation(ReferralsList);
