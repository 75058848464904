import React from "react";
import { useTranslation } from "react-i18next";
import {
	FacebookShareButton,
	LinkedinShareButton,
	TwitterShareButton,
} from "react-share";
import Grid from "src/components/Grid";

interface ShareSocialReferralCodeProps {
	text: string;
	url: string;
	title: string;
}

const ShareSocialReferralCode = ({
	text,
	url,
	title,
}: ShareSocialReferralCodeProps) => {
	const { t } = useTranslation("share-network", {
		keyPrefix: "share-social-referral",
	});
	return (
		<Grid className="mt-8" columns={3}>
			<FacebookShareButton hashtag="test" quote={text} {...{ url }}>
				<SocialItem
					icon="fab fa-facebook-square"
					label={t("facebook")}
				/>
			</FacebookShareButton>
			<LinkedinShareButton
				title={title}
				source={url}
				summary={text}
				{...{ url }}
			>
				<SocialItem icon="fab fa-linkedin" label={t("linkedin")} />
			</LinkedinShareButton>
			<TwitterShareButton title={title} via={text} {...{ url }}>
				<SocialItem icon="fab fa-twitter" label={t("twitter")} />
			</TwitterShareButton>
		</Grid>
	);
};

interface SocialItemProps {
	icon: string;
	label: string;
}

const SocialItem = ({ icon, label }: SocialItemProps) => {
	return (
		<div className="text-center flex justify-center items-center p-8 bg-accent rounded-md">
			<i className={`${icon} md:text-5xl mr-4 text-primary`}></i>
			<strong className="mb-0 text-background-foreground text-center">
				{label}
			</strong>
		</div>
	);
};

export default ShareSocialReferralCode;
