import { Route } from "react-router-dom";
import ForgotPasswordResetView from "src/views/auth/forgot-password/ForgotPasswordResetView";
import ForgotPasswordView from "src/views/auth/forgot-password/ForgotPasswordView";
import LoginView from "src/views/auth/LoginView";

const AuthRoutes = [
	<Route element={<LoginView />} path="/login" key="/login" />,
	<Route
		element={<ForgotPasswordView />}
		path="/forgot-password"
		key="/forgot-password"
	/>,
	<Route
		element={<ForgotPasswordResetView />}
		path="/forgot-password/:token"
		key="/forgot-password/:token"
	/>,
];

export default AuthRoutes;
