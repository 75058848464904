import { useEffect } from "react";
import useFetchProfile from "src/hooks/api/useFetchProfile";
import useUnreads from "src/hooks/api/useUnreads";
import useAuth from "src/hooks/selectors/useAuth";
import socket, { listen } from "src/lib/socket";

export default function useProfileListeners() {
	const auth = useAuth();
	const { fetchProfile } = useFetchProfile();
	const { fetchUnreads } = useUnreads();
	useEffect(() => {
		listen(`profile-changed.${auth.hid}`, () => {
			fetchProfile();
			fetchUnreads();
		});
		return () => {
			socket.off(`profile-changed.${auth.hid}`);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
}
