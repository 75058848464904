import ReactTooltip from "react-tooltip";
import withTranslation, {
	Translation,
} from "src/components/hoc/withTranslation";
import Icon from "src/components/icon/Icon";

interface ToggleCompletedIconProps extends Translation {
	value: boolean;
	onChange: () => void;
	name: string;
}

const ToggleCompletedIcon = ({
	t,
	value,
	onChange,
	name,
}: ToggleCompletedIconProps) => {
	return (
		<div className="relative" data-tip data-for={name}>
			{!value && (
				<ReactTooltip id={name} effect="solid">
					{t("show-completed")}
				</ReactTooltip>
			)}
			{value && (
				<ReactTooltip id={name} effect="solid">
					{t("hide-completed")}
				</ReactTooltip>
			)}
			<Icon
				onClick={onChange}
				className={!value ? "text-placeholder" : "text-primary"}
				iconFontSize={14}
				icon={{
					font_awesome: {
						name: value
							? "fas fa-check-circle"
							: "far fa-check-circle",
					},
				}}
			/>
		</div>
	);
};

ToggleCompletedIcon.locale = {
	nl: {
		"show-completed": "Afgeronde taken tonen",
		"hide-completed": "Afgeronde taken verbergen",
	},
	en: {
		"show-completed": "Show completed tasks",
		"hide-completed": "Hide completed tasks",
	},
};

export default withTranslation(ToggleCompletedIcon);
