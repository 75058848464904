import FlatList from "src/components/FlatList";
import withTranslation, {
	Translation,
} from "src/components/hoc/withTranslation";
import TeamListItem from "src/components/team/TeamListItem";
import useTeams from "src/hooks/api/services/users/useTeams";

const TeamList = ({ t }: Translation) => {
	const { teams, status } = useTeams();
	const headers = [t("headers.team"), t("headers.members"), ""];

	return (
		<FlatList
			loading={status === "loading" && !teams?.length}
			data={teams}
			table={{
				headers,
			}}
			empty={{
				title: t("empty.title"),
				text: t("empty.text"),
			}}
			renderItem={(team) => (
				<TeamListItem {...{ team }} key={`team-${team.id}`} />
			)}
		/>
	);
};

TeamList.locale = {
	nl: {
		headers: {
			team: "Team",
			members: "Leden",
		},
		empty: {
			title: "Maak je eerste team aan",
			text: 'Maak een team "Marketing" en nodig al jouw marketing teamleden uit voor een {{tenant.name}} account. Hierdoor krijg je beter inzicht in de capaciteit van jouw "Marketing" team',
		},
	},
	en: {
		headers: {
			team: "Team",
			members: "Members",
		},
		empty: {
			title: "Create your first team",
			text: 'Create a team "Marketing" and invite all your marketing team members for a {{tenant.name}} account. Manage your marketing team capacity based on their tasks',
		},
	},
};

export default withTranslation(TeamList);
