import { useEffect } from "react";
import { Trans, useTranslation } from "react-i18next";
import { ApiCategory } from "src/api/types";
import Dropdown, { DropdownProps } from "src/components/form/Dropdown";
import useCategories from "src/hooks/api/services/categories/useCategories";
import useGet from "src/hooks/rest/useGet";

interface CategoryDropdownProps extends Omit<DropdownProps, "options"> {
	variant?: "interests";
}

const CategoryDropdown = ({ variant, ...rest }: CategoryDropdownProps) => {
	// const [{ data }, loading] = useGet("/categories?hide_golden=1");
	const { categories: data, status, actions } = useCategories();
	useEffect(() => {
		if (!data.length && status === "idle") {
			actions.list();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	let selectedCategrory: any = null;
	if (rest.value) {
		selectedCategrory = (data || []).find(
			(item: any) => item.id === rest.value
		);
	}

	return (
		<div className="mb-[15px]">
			<Dropdown
				noMarginBottom
				isSearch
				className={`${rest?.className || ""} mb-0`}
				options={
					data && data.length
						? data.map((item: any) => ({
								id: item.id,
								name: item.name,
						  }))
						: []
				}
				loading={status === "loading"}
				{...{ ...rest }}
			/>
			{variant === "interests" && (
				<div className="mt-2 text-sm">
					<CategoryInterestText category={selectedCategrory} />
				</div>
			)}
		</div>
	);
};

interface CategoryInterestTextProps {
	category?: ApiCategory;
}

const CategoryInterestText = ({ category }: CategoryInterestTextProps) => {
	const { t } = useTranslation("form", {
		keyPrefix: "category-interest-text",
	});
	const [{ data }, loading] = useGet(
		category?.id ? `/categories/${category.id}/interests` : undefined
	);
	const interests = data?.interests || 0;

	if (typeof category?.id !== "number") {
		return <span>{t("empty")}</span>;
	}

	if (loading) {
		return (
			<span>
				<i className="fas fa-spinner-third fa-spin" />
			</span>
		);
	}

	return (
		<Trans
			{...{ t }}
			count={interests}
			category={category?.name}
			i18nKey="text"
		>
			Er zijn <strong>{{ interests }}</strong> professionals met
			insteresse in
			<strong>{{ category: category?.name }}</strong>
		</Trans>
	);
};

CategoryDropdown.Interests = CategoryInterestText;

CategoryDropdown.defaultProps = {
	layout: "stacked",
};

export default CategoryDropdown;
