import React from "react";
import { useTranslation } from "react-i18next";
import CategoryDropdown from "src/components/form/dropdowns/CategoryDropdown";
import FormGroup from "src/components/form/FormGroup";
import Input from "src/components/form/Input";
import Switch from "src/components/form/Switch";
import OfferForm from "src/components/offer/forms/OfferForm";
import useCreateOffer from "src/hooks/api/useCreateOffer";
import useOfferScheme from "src/hooks/schemes/useOfferScheme";
// import CategoryDropdown from "src/components/form/dropdowns/CategoryDropdown";

const OfferVisibilityForm = () => {
	const { t } = useTranslation("offer", {
		keyPrefix: "form",
	});
	const { setCurrent, offer, onChange } = useCreateOffer();
	const scheme = useOfferScheme();

	return (
		<OfferForm
			onSubmit={async (values) => {
				setCurrent(3);
				onChange("category_id", values?.category_id);
				return;
			}}
			value={{
				category_id: offer?.category_id,
				category_name: offer?.category_name,
			}}
			schema={() => ({
				category_id: scheme.category_id,
				category_name: scheme.category_name,
			})}
		>
			{(getInputProps) => {
				const categoryDropdown = getInputProps(
					"category_id",
					"dropdown"
				);
				return (
					<>
						<CategoryDropdown
							label={t("category.label")}
							placeholder={t("category.placeholder")}
							{...categoryDropdown}
						/>
						{!categoryDropdown.value && (
							<Input
								label={t("category-manual.label")}
								placeholder={t("category-manual.placeholder")}
								{...getInputProps("category_name")}
							/>
						)}

						<FormGroup label={t("visibility.title")}>
							<div className="flex flex-col gap-2">
								<Switch
									label={t("visibility.is-private")}
									onChange={(value) =>
										onChange("is_private", !value)
									}
									value={!offer?.is_private}
								/>
								<Switch
									label={t("visibility.is-anonymous")}
									onChange={(value) =>
										onChange("is_anonymous", !value)
									}
									value={!offer?.is_anonymous}
								/>
							</div>
						</FormGroup>
					</>
				);
			}}
		</OfferForm>
	);
};

export default OfferVisibilityForm;
