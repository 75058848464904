const DEFAULT_JOB_OFFERS = {
	"content-marketeer": {
		name: "Content Marketeer",
		value: `
            <h2>Content Marketeer</h2>
            <p><strong>Wat ga je doen?</strong></p>
            <p>Als Parttime Content Marketeer bij Amsterdam Creatives speel je een sleutelrol in het cre&euml;ren en verspreiden van inspirerende content voor onze diverse klanten. Je verantwoordelijkheden omvatten:</p>
            <ul>
                <li><strong>Contentontwikkeling:</strong> Schrijven van overtuigende blogartikelen, social media posts, nieuwsbrieven en website-inhoud die perfect aansluit bij de doelstellingen van onze klanten.</li>
                <li><strong>Contentstrategie:</strong> Meedenken over en uitvoeren van contentstrategie&euml;n die de merkwaarde van onze klanten versterken en hun doelgroep aanspreken.</li>
                <li><strong>SEO &amp; Analytics:</strong> Optimaliseren van content voor zoekmachines en het analyseren van de prestaties om continue verbeteringen door te voeren.</li>
                <li><strong>Social Media Management:</strong> Beheren van social media kanalen voor verschillende klanten, en het cre&euml;ren van content die aanzet tot interactie.</li>
                <li><strong>Samenwerking:</strong> Nauwe samenwerking met ons creatieve team van designers, marketeers en strategen om samen krachtige campagnes te realiseren.</li>
            </ul>
            <p><strong>Wie ben jij?</strong></p>
            <ul>
                <li>Je hebt minimaal 2 jaar ervaring in contentcreatie, bij voorkeur binnen een bureauomgeving.</li>
                <li>Je bent een creatieve schrijver met een feilloos gevoel voor de Nederlandse taal.</li>
                <li>Je hebt kennis van SEO en ervaring met het inzetten van content voor leadgeneratie.</li>
                <li>Je bent proactief, kunt zelfstandig werken, en bent altijd op zoek naar manieren om jezelf en je werk te verbeteren.</li>
                <li>Je hebt ervaring met social media platforms en weet hoe je content kunt afstemmen op verschillende doelgroepen.</li>
                <li>Ervaring met copywriting voor diverse sectoren is een pr&eacute;.</li>
            </ul>
        `,
	},
	webdeveloper: {
		name: "Webdeveloper",
		value: `
            <h2>Webdeveloper</h2>
            <p><strong>Wat ga je doen?</strong></p>
            <p>Als Parttime Webdeveloper bij WebWorks Arnhem ben je verantwoordelijk voor het bouwen en optimaliseren van websites en webapplicaties. Je taken omvatten:</p>
            <ul>
                <li><strong>Ontwikkeling en onderhoud:</strong> Ontwerpen, bouwen en testen van responsive websites en webapplicaties die voldoen aan de wensen van onze klanten.</li>
                <li><strong>Front-end en back-end ontwikkeling:</strong> Werken met moderne technologie&euml;n en frameworks om zowel de voorkant als de achterkant van onze projecten te realiseren.</li>
                <li><strong>Samenwerking:</strong> Nauwe samenwerking met ons designteam om functionele en visueel aantrekkelijke oplossingen te cre&euml;ren.</li>
                <li><strong>Optimalisatie:</strong> Verbeteren van bestaande websites op het gebied van snelheid, SEO, en gebruiksvriendelijkheid.</li>
                <li><strong>Probleemoplossing:</strong> Technische ondersteuning bieden en problemen snel en effici&euml;nt oplossen.</li>
            </ul>
            <p><strong>Wie ben jij?</strong></p>
            <ul>
                <li>Je hebt minimaal 3 jaar ervaring als webdeveloper, bij voorkeur binnen een digitaal bureau.</li>
                <li>Je hebt uitgebreide kennis van HTML, CSS, JavaScript, en ervaring met frameworks zoals React, Angular, of Vue.js.</li>
                <li>Je hebt ervaring met back-end technologie&euml;n zoals PHP, Node.js, of Python, en kunt werken met databases (bijv. MySQL).</li>
                <li>Je werkt nauwkeurig, hebt oog voor detail en houdt van schone, effici&euml;nte code.</li>
                <li>Je kunt zelfstandig werken, maar voelt je ook thuis in een teamomgeving.</li>
                <li>Ervaring met WordPress of andere CMS-systemen is een pr&eacute;.</li>
                <li>Affiniteit met UX/UI design is een pluspunt.</li>
            </ul>
        `,
	},
	"appointment-setter": {
		name: "Appointment Setter",
		value: `
            <h2>Appointment Setter</h2>
            <p><strong>Wat ga je doen?</strong></p>
            <p>Als Appointment Setter bij NorthConnect Groningen ben jij het eerste contactpunt voor potenti&euml;le klanten. Je bent verantwoordelijk voor het maken van afspraken voor onze accountmanagers. Je taken omvatten:</p>
            <ul>
                <li><strong>Cold calling:</strong> Actief benaderen van potenti&euml;le klanten via de telefoon om onze diensten voor te stellen en afspraken in te plannen.</li>
                <li><strong>Leadkwalificatie:</strong> Identificeren van de behoeften van prospects en bepalen of zij in aanmerking komen voor een vervolgafspraak.</li>
                <li><strong>Afspraakplanning:</strong> Effici&euml;nt inplannen van afspraken voor onze accountmanagers en ervoor zorgen dat alle details correct worden vastgelegd in ons CRM-systeem.</li>
                <li><strong>Relatiebeheer:</strong> Onderhouden van contact met leads en prospects om langdurige relaties op te bouwen.</li>
                <li><strong>Feedback geven:</strong> Delen van inzichten en feedback met het sales- en marketingteam om onze strategie&euml;n continu te verbeteren.</li>
            </ul>
            <p><strong>Wie ben jij?</strong></p>
            <ul>
                <li>Je hebt minimaal 1-2 jaar ervaring in een vergelijkbare functie, bij voorkeur in een commerci&euml;le omgeving.</li>
                <li>Je hebt uitstekende communicatieve vaardigheden en weet mensen te overtuigen en enthousiast te maken.</li>
                <li>Je bent resultaatgericht en gedreven om je doelen te bereiken.</li>
                <li>Je bent niet bang om de telefoon op te pakken en hebt ervaring met cold calling.</li>
                <li>Ervaring met CRM-systemen is een pr&eacute;.</li>
                <li>Je kunt zelfstandig werken, maar bent ook een echte teamspeler.</li>
                <li>Een positieve instelling en doorzettingsvermogen zijn essentieel in deze rol.</li>
            </ul>
        `,
	},
	"administratief-medewerker": {
		name: "Administratief medewerker",
		value: `
            <h2>Administratief medewerker</h2>
            <p><strong>Wat ga je doen?</strong></p>
            <p>Als Parttime Administratief Medewerker bij Brabant Support Services speel je een cruciale rol in het ondersteunen van onze dagelijkse bedrijfsvoering. Je taken omvatten:</p>
            <ul>
                <li><strong>Documentbeheer:</strong> Verwerken, archiveren en beheren van belangrijke documenten zoals facturen, contracten en correspondentie.</li>
                <li><strong>Data-invoer:</strong> Nauwkeurig invoeren van gegevens in onze administratiesystemen en ervoor zorgen dat alle informatie up-to-date is.</li>
                <li><strong>Ondersteuning bij boekhouding:</strong> Assisteren bij het verwerken van facturen, betalingen en het opstellen van financi&euml;le rapporten.</li>
                <li><strong>Klantencontact:</strong> Beantwoorden van telefoontjes en e-mails van klanten, en hen voorzien van de nodige informatie en ondersteuning.</li>
                <li><strong>Agendabeheer:</strong> Ondersteunen bij het plannen en co&ouml;rdineren van afspraken en vergaderingen.</li>
                <li><strong>Algemene administratieve taken:</strong> Ondersteunen bij andere voorkomende administratieve werkzaamheden om de operationele processen soepel te laten verlopen.</li>
            </ul>
            <p><strong>Wie ben jij?</strong></p>
            <ul>
                <li>Je hebt minimaal 2 jaar ervaring in een administratieve functie.</li>
                <li>Je werkt nauwkeurig en hebt oog voor detail.</li>
                <li>Je hebt goede communicatieve vaardigheden en kunt professioneel omgaan met klanten en collega&rsquo;s.</li>
                <li>Je hebt ervaring met MS Office (Word, Excel, Outlook) en bij voorkeur met boekhoudsoftware.</li>
                <li>Je bent proactief, zelfstandig, en weet prioriteiten te stellen in je werk.</li>
                <li>Affiniteit met cijfers en een goede basiskennis van boekhouding is een pr&eacute;.</li>
            </ul>
        `,
	},
} as const;

export default DEFAULT_JOB_OFFERS;
