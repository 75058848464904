import React, { ComponentProps } from "react";
import { useTranslation } from "react-i18next";
import ReactTooltip from "react-tooltip";
import { formatPrice } from "src/lib/formatters";
import { cn } from "src/lib/utils";

export type Product = {
	description: "PARTNER_FEE" | string;
	price: number;
	amount?: number | null;
	vat: number;
};

export function useCheckoutSummary(products: Product[]) {
	const subtotal = products.reduce(
		(value, item) => value + item.price * (item?.amount || 1),
		0
	);
	const vat = products.reduce(
		(value, item) => value + item.price * (item?.amount || 1) * item.vat,
		0
	);
	const total = subtotal + vat;
	return {
		subtotal,
		total,
		vat,
	};
}

interface ManualCheckoutSummaryProps {
	products: Product[];
	summary?: {
		// partner_fee: number;
		// vat: number;
		vat_mode?: "checkout";
	};
	className?: string;
	variant: "light" | "dark";
}

const ManualCheckoutSummary = ({
	products,
	summary,
	className,
	variant,
}: ManualCheckoutSummaryProps) => {
	const { subtotal, total, vat } = useCheckoutSummary(products);
	const { t } = useTranslation("payments", {
		keyPrefix: "checkout-summary",
	});

	//Get the highest vat rate from products
	const rates = products.map((item) => item.vat);
	const maxRate = Math.max(...rates);

	return (
		<div className={`flex flex-col gap-1.5 ${className || ""}`}>
			{products.map((item, index) => (
				<RowItem
					key={`description-${index}`}
					{...item}
					description={
						item.description === "PARTNER_FEE"
							? t("partner-fee")
							: item.description
					}
				/>
			))}

			<hr className={`border-border my-2`} />
			{summary?.vat_mode === "checkout" ? (
				<RowItem
					description={t("vat-checkout")}
					className="opacity-40"
				/>
			) : (
				<>
					<RowItem description={t("subtotal")} price={subtotal} />
					<RowItem
						description={t("vat", {
							// vat: summary.vat * 100,
							vat: maxRate * 100,
						})}
						price={vat}
					/>
				</>
			)}
			<hr className={`border-border my-2`} />
			<RowItem variant="strong" description={t("total")} price={total} />
		</div>
	);
};

ManualCheckoutSummary.defaultProps = {
	variant: "dark",
};

interface RowItemProps extends ComponentProps<"div"> {
	description: string;
	price?: number;
	help?: string;
	variant?: "strong";
	amount?: number | null;
}

const RowItem = ({
	variant,
	description,
	price,
	help,
	className,
	amount,
	...rest
}: RowItemProps) => {
	return (
		<div {...rest} className={cn("flex items-center gap-2", className)}>
			<span className="flex flex-1">
				{variant === "strong" ? (
					<strong>{description}</strong>
				) : (
					description
				)}
				{help && (
					<span className="ml-2">
						<i
							data-tip
							data-for={`tooltip-${description}`}
							className="far fa-info-square opacity-20"
						></i>
						<ReactTooltip
							id={`tooltip-${description}`}
							effect="solid"
						>
							{help}
						</ReactTooltip>
					</span>
				)}
			</span>
			{price !== undefined && (
				<span>
					{amount && amount > 0 ? (
						<span className="mr-1.5 opacity-50">{amount}x</span>
					) : (
						false
					)}

					{formatPrice(price)}
				</span>
			)}
		</div>
	);
};

ManualCheckoutSummary.Item = RowItem;

export default ManualCheckoutSummary;
