import moment from "moment";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Calendar from "src/components/Calendar";
import { DateRangeFilterValue } from "src/components/date-range-filter/Provider";
import TaskKanbanCard from "src/components/tasks/kanban/TaskKanbanCard";
import useTasks from "src/hooks/api/services/statistics/tasks/useTasks";
import useBackUrl from "src/hooks/useBackUrl";

interface TasksPlanningProps {
	name: string;
	date: DateRangeFilterValue;
	filter?: {
		teams?: string[];
		assignees?: string[];
	};
}

const TasksPlanning = ({ name, date, filter }: TasksPlanningProps) => {
	const navigate = useNavigate();
	const back = useBackUrl();
	const { tasks, actions } = useTasks(name, {
		date,
		...(filter || {}),
	});

	useEffect(() => {
		actions.list();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<>
			<Calendar
				head={null}
				options={{
					scrollToDate: moment(),
				}}
				items={(tasks || []).map((task: any) => ({
					id: `DATE_${task.id}`,
					date: task.end_date,
					data: task,
					render: (
						<TaskKanbanCard
							actions={<TaskKanbanCard.Actions />}
							onClick={(e) => {
								if (task.mode === "NEW") {
									return;
								}
								if (task.type !== "INTERNAL") {
									navigate(`/tasks/${task.hid}/show?${back}`);
								} else {
									navigate(
										`/tasks/${task.hid}/editor?${back}`
									);
								}
							}}
							{...{ task }}
						>
							<div className="flex flex-col gap-2">
								<TaskKanbanCard.Title />
								<div className="flex flex-wrap gap-2">
									<TaskKanbanCard.Status />
								</div>
								{!filter?.assignees && (
									<TaskKanbanCard.Assignee />
								)}
								<TaskKanbanCard.ProjectLink />
							</div>
						</TaskKanbanCard>
					),
				}))}
			>
				<Calendar.DateRange variant="scrollable" value={date} />
			</Calendar>
		</>
	);
};

export default TasksPlanning;
