const offer = {
	"offer-registration-view": {
		title: "Aanmelding",
		breadcrumb: {
			home: "Mijn aanbod",
		},
	},
	"offer-create-view": {
		title: "Aanbod aanmaken",
		verify: {
			title: "Maak je profiel compleet",
			description:
				"Voor je jouw talenten kan delen met bedrijven dien je je profiel volledig in te vullen",
			button: "Profiel invullen",
		},
		breadcrumb: {
			home: "Mijn aanbod",
			create: "Aanbod aanmaken",
		},
		headers: {
			title: {
				title: "Bedenk een pakkende titel voor jouw project",
				description: "Beschrijf zo kort mogelijk het project",
			},
			description: {
				title: "Omschrijf het project in 3 pakkende teksten",
				description:
					"Omschrijving bouw je op d.m.v. een introductie, wat je voor het bedrijf gaat doen en afsluitend met hoe je dit gaat doen.",
			},
			visibility: {
				title: "Wie mag jouw opdracht zien",
				description: "Kies een categorie en wie de opdracht mag zien.",
			},
			skills: {
				title: "Voeg wat vaardigheden toe",
				description:
					"Beschrijf bijvoorbeeld welke technieken je gaat gebruiken, hierdoor kunnen bedrijven op basis van tags je aanbod beter vinden.",
			},
			budget: {
				title: "Bepaal het bedrag & de geschatte tijdsduur.",
				description:
					"Dit bedrag krijg je netto op je rekening gestort.",
			},
		},
	},
	"offer-create-finished-view": {
		title: "Aanbod aangemaakt",
		breadcrumb: {
			home: "Mijn aanbod",
			create: "Aanbod aanmaken",
			created: "Aanbod aangemakt",
		},
		published: {
			title: "Je aanbod is aangemaakt!",
			description:
				"We hebben je aanbod ontvangen! Een medewerker van {{tenant.code}} gaat nu je aanbod controleren, indien alles compleet is wordt he t aanbod goedgekeurd en online gezet. Wanneer het aanbod online staat vind je hem terug in het talentenbord, bedrijven kunnen dan op je aanbod reageren.",
		},
		concept: {
			title: "Je aanbod is opgeslagen!",
			description:
				"Je hebt je aanbod opgeslagen als concept, het is dus nog niet beschikbaar voor bedrijven. Je kan dit aanbod later publiceren.",
		},
		buttons: {
			view: "Aanbod bekijken",
			create: "Nog één maken",
		},
	},
	"offer-detail-view": {
		title: "Aanbod",
		breadcrumb: {
			home: "Mijn aanbod",
		},
		alert: {
			published:
				"Jouw aanbod is nu onderweg naar de goedkeuring, zodra het aanbod is goedgekeurd wordt hij live gezet op de website.",
			success:
				"Jouw aanbod is aangepast, een medewerker van {{tenant.code}} is ingelicht om opnieuw te beoordelen.",
		},
	},
	"offer-edit-view": {
		title: "Aanbod",
		breadcrumb: {
			home: "Mijn aanbod",
		},
	},
};

export default offer;
