import { useParams } from "react-router-dom";
import Button from "src/components/Button";
import ForgotPasswordResetForm from "src/components/forms/auth/forgot-password/ForgotPasswordResetForm";
import withTranslation, {
	Translation,
} from "src/components/hoc/withTranslation";
import AuthLayout from "src/components/layout/auth-layout/AuthLayout";
import useGet from "src/hooks/rest/useGet";

function ForgotPasswordResetView({ t }: Translation) {
	const { token } = useParams() as any;
	const [{ data }, loading] = useGet(`/auth/password/find/${token}`);
	return (
		<AuthLayout
			head={{
				title: t("title"),
				back: {
					to: "/login",
					label: t("back.label"),
				},
			}}
			{...{ loading }}
		>
			{data?.id ? (
				<ForgotPasswordResetForm {...{ data }} />
			) : (
				<div className=" p-4 rounded-md">
					<p>{t("invalid.description")}</p>
					<div className="flex mt-4">
						<Button type="dark" to="/forgot-password">
							{t("invalid.button")}
						</Button>
					</div>
				</div>
			)}
		</AuthLayout>
	);
}

ForgotPasswordResetView.locale = {
	nl: {
		title: "Wachtwoord vergeten",
		invalid: {
			description:
				"De link lijkt niet meer geldig te zijn, vraag via het wachtwoord vergeten formulier een nieuwe link aan.",
			button: "Nieuwe link aanvragen",
		},
		back: {
			label: "Terug naar inloggen",
		},
	},
	en: {
		title: "Forgot password",
		invalid: {
			description:
				"It looks like the current url is not valid anymore, use the password forgot form to request a new url.",
			button: "Request new url",
		},
		back: {
			label: "Back to login",
		},
	},
};

export default withTranslation(ForgotPasswordResetView);
