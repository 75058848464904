import { ComponentProps, useState } from "react";
import { ApiTeam } from "src/api/types";
import Button from "src/components/Button";
import TeamSkillsCard from "src/components/forms/onboarding/elements/TeamSkillsCard";
import withTranslation, {
	Translation,
} from "src/components/hoc/withTranslation";
import useOnboarding from "src/hooks/api/services/auth/useOnboarding";
import { cn } from "src/lib/utils";

interface CreateTeamsProps extends Translation {
	children?: any;
}

const CreateTeams = ({
	t,
	children,
	className,
	onSubmit,
	...rest
}: CreateTeamsProps & ComponentProps<"div">) => {
	const { actions, onboarding, status } = useOnboarding();
	const [teams, setTeams] = useState<ApiTeam[]>(onboarding?.teams || []);
	const isSkillsEmpty = teams.every((team) => !team.skills.length);
	const handleSubmit = () => {
		if (isSkillsEmpty) return;
		actions.update({ teams }).then(() => {
			actions.setStep("invite-users");
		});
	};

	return (
		<div
			{...rest}
			className={cn("flex flex-col flex-1 gap-4 w-full", className)}
		>
			<div className="w-full flex flex-col gap-4 py-4 max-w-md mx-auto justify-center flex-1 rounded-md">
				{teams.map((team, index) => (
					<TeamSkillsCard
						key={`team-${team.hid}`}
						defaultOpen={index === 0}
						team={team}
						onChange={(value) => {
							setTeams((state) =>
								state.map((item, i) =>
									i === index ? value : item
								)
							);
						}}
					/>
				))}
			</div>
			<div className="flex flex-col mt-8 sticky bottom-4">
				<Button
					onClick={handleSubmit}
					disabled={status !== "idle" || isSkillsEmpty}
					loading={status === "updating"}
					type="primary"
				>
					{t("continue")}
				</Button>
			</div>
		</div>
	);
};

CreateTeams.locale = {
	nl: {
		continue: "Doorgaan",
	},
	en: {
		continue: "Continue",
	},
};

export default withTranslation(CreateTeams);
