const promotion = {
	"connect-discount-banner": {
		"last-day":
			"⚠️ Vandaag is de laatste dag dat je jouw korting kan gebruiken!",
		"days-remaining_other":
			"⚠️ Over {{days}} dag verloopt jouw speciale korting",
		"days-remaining_one": "⚠️ Over 1 dag verloopt jouw speciale korting",
		title: "Alle pakketten nu met 15% succesfee",
		description:
			"Kies voor {{date}} je abonnement en maak gebruik van een succesfee van 15% op alle pakketten",
	},
	"no-mandate-banner": {
		description:
			"Je hebt geen actieve bankkoppeling,<1>klik hier</1> om een nieuwe koppeling te maken",
	},
	"no-subscription-banner": {
		"payment-failed":
			"De betaling voor het abonnement is niet gelukt. <1>Klik hier</1> om de betaling alsnog te voldoen",
		"no-active":
			"Je hebt nog geen actief abonnement. <1>Klik hier</1> om een abonnement te kiezen.",
	},
	"paused-banner": {
		description:
			"Je account is gepauzeerd, jouw account wordt op <1>{{date}}</1> volledig gesloten. Tot die tijd kan je nog wel aanmeldingen bekijken en opdrachten afronden. Klik hier om je abonnement te hervatten. ",
	},
	"student-can-register": {
		description:
			"Je account is goedgekeurd! Klik hier om op opdrachten te reageren",
	},
	"subscription-changed-banner": {
		description: "Je account wijzigt per {{date}} naar {{plan}}",
	},
	"euro-banner": {
		description:
			"Kies nu een abonnement naar keuze voor maar <2>€ 1 voor de aankomende maand.</2> <4>Klik hier</4> om de korting te claimen.",
	},
	"download-app-box": {
		appstore: "Download in de appstore",
		playstore: "Download in de playstore",
	},
};

export default promotion;
