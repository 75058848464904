const auth = {
	"login-form": {
		error: {
			title: "Inloggen mislukt",
			text: "De combinatie van email en wachtwoord komen niet overeen, check je gegevens en probeer het opnieuw",
		},
		form: {
			email: "E-mail",
			password: "Wachtwoord",
		},
		buttons: {
			forgot: "Wachtwoord vergeten",
			login: "Inloggen",
		},
	},
};

export default auth;
