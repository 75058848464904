import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import FormGrid from "src/components/form/FormGrid";
import TaskForm from "src/components/tasks/TaskForm";
import SaveTaskButton from "src/components/tasks/TaskForm/elements/SaveTaskButton";
import TaskDetailWrapper from "src/components/tasks/detail/TaskDetailWrapper";
import useTaskForm from "src/hooks/api/tasks/useTaskForm";

export default function EditTaskView() {
	const { id } = useParams();
	const { t } = useTranslation("task-view", {
		keyPrefix: "edit-view",
	});
	const { task, status, actions } = useTaskForm();
	const loading = status !== "idle";

	useEffect(() => {
		actions.get();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	if (status === "error") {
		return (
			<TaskDetailWrapper loading={false} {...{ task }}>
				<div className="flex flex-1 justify-center items-center flex-col">
					<h2>{t("error.title")}</h2>
				</div>
			</TaskDetailWrapper>
		);
	}

	return (
		<TaskDetailWrapper
			{...{ task }}
			loading={status === "loading" && task?.hid !== id}
			options={{
				childrenOffset: true,
			}}
		>
			{task?.status === "completed" && status === "idle" && (
				<div className="flex flex-col flex-1 justify-center items-center">
					<div className="flex flex-col max-w-md text-center">
						<strong>{t("disabled.title")}</strong>
						<p>{t("disabled.description")}</p>
					</div>
				</div>
			)}

			{task?.id && task.status !== "completed" && (
				<>
					<FormGrid variant="grid-sm">
						<FormGrid.Header
							title={t("cards.description.title")}
						></FormGrid.Header>
						<FormGrid.Card {...{ loading }}>
							<TaskForm.Description hideButtons />
						</FormGrid.Card>
						<FormGrid.Header
							title={t("cards.visibility.title")}
						></FormGrid.Header>
						<FormGrid.Card {...{ loading }}>
							<TaskForm.Visibility
								hideButtons
								fields={["anonymous", "pause-registration"]}
							/>
						</FormGrid.Card>
						<FormGrid.Header title={t("cards.budget.title")}>
							{t("cards.budget.description")}
						</FormGrid.Header>
						<FormGrid.Card {...{ loading }}>
							<TaskForm.Budget hideButtons />
						</FormGrid.Card>
						<FormGrid.Header
							title={t("cards.location.title")}
						></FormGrid.Header>
						<FormGrid.Card {...{ loading }}>
							<TaskForm.Address hideButtons />
						</FormGrid.Card>
						<FormGrid.Header
							title={t("cards.date.title")}
						></FormGrid.Header>
						<FormGrid.Card {...{ loading }}>
							<TaskForm.Target hideButtons hideVisibility />
						</FormGrid.Card>
						<FormGrid.Header
							title={t("cards.form.title")}
						></FormGrid.Header>
						<FormGrid.Card {...{ loading }}>
							<TaskForm.Interview
								defaultMode="MANUAL"
								hideButtons
							/>
						</FormGrid.Card>
						<FormGrid.Header
							title={t("cards.delete.title")}
						></FormGrid.Header>
						<FormGrid.Card {...{ loading }}>
							<TaskForm.Delete />
						</FormGrid.Card>
						<FormGrid.Header></FormGrid.Header>

						<FormGrid.Card variant="flat" {...{ loading }}>
							<SaveTaskButton />
						</FormGrid.Card>
					</FormGrid>
				</>
			)}
		</TaskDetailWrapper>
	);
}
