import { useEffect } from "react";
import Button from "src/components/Button";
import FlatList from "src/components/FlatList";
import withTranslation, {
	Translation,
} from "src/components/hoc/withTranslation";
import Layout from "src/components/layout/Layout";
import Table from "src/components/Table";
import TimesheetStatus from "src/components/timesheet/TimesheetStatus";
import useTasks from "src/hooks/api/services/tasks/useTasks";
import useUnreads from "src/hooks/api/useUnreads";
import useAuth from "src/hooks/selectors/useAuth";

function HoursView({ t }: Translation) {
	const auth = useAuth();
	const { fetchUnreads } = useUnreads();
	const include = ["user", "parent"];
	if (auth.type === "company") {
		include.push("timesheet_count");
	}
	if (auth.type === "student") {
		include.push("timesheet_status");
	}
	const { tasks, actions, filter, status, pagination } = useTasks();
	const needsVerification =
		!auth.merchant_id && tasks.length > 0 && auth.type === "student";

	const headers = [];

	if (auth.type === "student") {
		headers.push(t("headers.status"));
	}

	headers.push(t("headers.project"));

	if (auth.type === "company") {
		headers.push(t("headers.timesheets"));
		headers.push(t("headers.approved"));
		headers.push(t("headers.matches"));
	}

	useEffect(() => {
		actions.list(
			{
				variant: "table",
				include,
				is_hourlist: true,
				recent: 1,
				limit: 12,
				with: ["timesheet"],
			},
			{
				disableLoading: !!tasks.length && filter?.is_hourlist,
				disableMergeFilter: true,
			}
		);
		fetchUnreads();

		return () => {
			actions.resetState({
				filter: {},
			});
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<Layout className="" title={t("title")}>
			<FlatList.Head
				title={t(`head.${auth.type}.title`)}
				subtitle={t(`head.${auth.type}.subtitle`)}
				search={{
					onSearch: (q: string) => {
						actions.list({
							page: 1,
							limit: 12,
							q,
							variant: "table",
							include,
							is_hourlist: true,
							recent: 1,
						});
					},
				}}
			/>

			{needsVerification ? (
				<div className="flex flex-col justify-center items-center">
					<div className="flex flex-col gap-4 max-w-[560px] justify-center items-center flex-1 text-center py-8">
						<h2>{t("needs-verification.title")}</h2>
						<p>
							{t("needs-verification.description", {
								count: tasks.length,
							})}
						</p>
						<div className="mt-4">
							<Button to="/dashboard">
								{t("needs-verification.button")}
							</Button>
						</div>
					</div>
				</div>
			) : (
				<FlatList
					{...{ pagination }}
					data={tasks}
					table={{
						headers: [...headers, ""],
					}}
					loading={status !== "idle"}
					renderItem={(task: any) => (
						<Table.Row key={`task-${task.hid}`}>
							{auth.type === "student" && (
								<Table.Cell>
									<TimesheetStatus
										status={
											task.timesheet_status || "unknown"
										}
									/>
								</Table.Cell>
							)}
							<Table.Cell className="w-full">
								{task?.parent && (
									<span className="block">
										{task?.parent?.title}
									</span>
								)}
								<span
									className={`block ${
										task?.parent && "opacity-60"
									}`}
								>
									{task.title}
								</span>
							</Table.Cell>
							{auth.type === "company" && (
								<>
									<Table.Cell>
										<span>{task.timesheet_count}</span>
									</Table.Cell>
									<Table.Cell>
										<span>
											{task.timesheet_approved_count}
										</span>
									</Table.Cell>
									<Table.Cell>
										<span>{task?.matches?.count || 0}</span>
									</Table.Cell>
								</>
							)}

							<Table.Cell>
								<Button
									to={`/hours/${task.hid}/show`}
									iconOnly
									icon="fas fa-eye"
								></Button>
							</Table.Cell>
						</Table.Row>
					)}
					empty={{
						title: t(`empty.${auth.type}.title`),
						text: t(`empty.${auth.type}.text`),
					}}
				/>
			)}
		</Layout>
	);
}

HoursView.locale = {
	nl: {
		title: "Uren controle",
		head: {
			company: {
				title: "Alle opdrachten",
				subtitle: "Hier controleer je de uren van de professionals",
			},
			student: {
				title: "Mijn opdrachten",
				subtitle:
					"Hier geef je je gewerkte uren door aan de opdrachtgever",
			},
		},
		headers: {
			project: "Project",
			status: "Status",
			timesheets: "In afwachting",
			approved: "Goedgekeurd",
			matches: "Matches",
		},
		empty: {
			company: {
				title: "Geen opdrachten gevonden",
				text: "Je hebt nog geen opdrachten waar uren controle voor nodig is",
			},
			student: {
				title: "Geen opdrachten gevonden",
				text: "Er zijn nog geen opdrachten waar jij je uren voor door hoeft te geven",
			},
		},
		"needs-verification": {
			title: "Je account is nog niet geverifieerd",
			description_one:
				"Er is 1 opdracht waar je je uren voor kan invullen. Voordat je dit kan doen moet je je account verifieren.",
			description_other:
				"Er zijn {{count}} opdrachten waar je je uren voor kan invullen. Voordat je dit kan doen moet je je account verifieren.",
			button: "Account verifieren",
		},
	},
	en: {
		title: "Hour registration",
		head: {
			company: {
				title: "All tasks",
				subtitle: "Here you can approve the hours",
			},
			student: {
				title: "My tasks",
				subtitle: "This is the place where you submit your hours",
			},
		},
		headers: {
			project: "Project",
			status: "Status",
			timesheets: "Pending",
			approved: "Approved",
			matches: "Matches",
		},
		empty: {
			company: {
				title: "No tasks found",
				text: "You don't have any tasks where hour rates are being used",
			},
			student: {
				title: "No tasks found",
				text: "There are no tasks available where you need to submit your hours",
			},
		},
		"needs-verification": {
			title: "Your account is not yet verified",
			description_one:
				"There is 1 assignment for which you can enter your hours. Before you can do this, you need to verify your account.",
			description_other:
				"There are {{count}} assignments for which you can enter your hours. Before you can do this, you need to verify your account.",
			button: "Verify Account",
		},
	},
};

export default withTranslation(HoursView);
