import { useState } from "react";
import axios from "axios";
import Button from "src/components/Button";
import { VariantTypes } from "src/hooks/useVariant";

interface DownloadButtonProps {
	variant: VariantTypes;
	label: string;
	api: {
		url: string;
		data?: any;
	};
	file: {
		name: string;
		extension: "csv" | "pdf" | "zip";
	};
}

const DownloadButton = ({ variant, label, api, file }: DownloadButtonProps) => {
	const [loading, setLoading] = useState(false);

	const onClick = () => {
		setLoading(true);
		axios({
			method: "POST",
			url: api.url,
			responseType: "blob",
			data: api?.data || {},
		})
			.then((response) => {
				const dataUrl = window.URL.createObjectURL(response.data);
				const tempLink = document.createElement("a");
				tempLink.href = dataUrl;
				tempLink.setAttribute(
					"download",
					`${file.name}.${file.extension}`
				);
				tempLink.click();
			})
			.finally(() => {
				setLoading(false);
			});
	};

	return (
		<Button {...{ loading, onClick }} type={variant}>
			<i className="fas fa-download mr-4"></i>
			{label}
		</Button>
	);
};

DownloadButton.defaultProps = {
	variant: "dark",
};

export default DownloadButton;
