import { Route } from "react-router-dom";
import ProtectedRoute from "src/routes/middlewares/ProtectedRoute";
import SessionPaymentInvoiceModal from "src/views/authenticated/session/payment/SessionPaymentInvoiceModal";
import SessionPaymentSendInvoiceModal from "src/views/authenticated/session/payment/SessionPaymentSendInvoiceModal";
import SessionPaymentView from "src/views/authenticated/session/payment/SessionPaymentView";

const SessionRoutes = [
	<Route
		element={
			<ProtectedRoute>
				<SessionPaymentView />
			</ProtectedRoute>
		}
		path="/session/payment/:sessionId"
		key="/session/payment/:sessionId"
	/>,
];

export const SessionModalRoutes = [
	<Route
		element={
			<ProtectedRoute
				variant="modal"
				modal={{
					backUrl: "/session/payment/:sessionId",
					expanded: false,
					size: "large",
					className: "h-auto sm:h-auto max-w-[920px]",
				}}
			>
				<SessionPaymentInvoiceModal />
			</ProtectedRoute>
		}
		path="/session/payment/:sessionId/view-invoice/:paymentId"
		key="/session/payment/:sessionId/view-invoice/:paymentId"
	/>,
	<Route
		element={
			<ProtectedRoute
				variant="modal"
				modal={{
					backUrl: "/session/payment/:sessionId",
					expanded: false,
					size: "large",
					className: "h-auto sm:h-auto max-w-[920px]",
				}}
			>
				<SessionPaymentSendInvoiceModal />
			</ProtectedRoute>
		}
		path="/session/payment/:sessionId/send-invoice"
		key="/session/payment/:sessionId/send-invoice"
	/>,
];

export default SessionRoutes;
