const onboarding = {
	student: {
		title: "Complete your profile ({{current}} / {{total}})",
		information: {
			title: "Enter your basic information",
			description:
				"We need this information to later create contracts for assignments. We need your home address, date of birth & telephone number.",
		},
		interests: {
			title: "Indicate your interests",
			description:
				"Through these interests, we can easily offer you jobs.",
		},
		avatar: {
			title: "Set a profile picture",
			description:
				"92% of companies choose a professional with a profile picture first.",
		},
		experiences: {
			title: "Education & work experience",
			description:
				"Through this information, the client can see your experience.",
		},
		skills: {
			title: "Bio, skills & connect your socials",
			description:
				"Tell us about yourself, what you like to do, what your goals are, and what you really excel at.",
		},
	},
};

export default onboarding;
