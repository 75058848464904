import { useState } from "react";
import { HexColorPicker } from "react-colorful";
import FormGroup from "src/components/form/FormGroup";
import Swatches from "src/components/form/color-picker/Swatches";
import withTranslation, {
	Translation,
} from "src/components/hoc/withTranslation";

interface ColorPickerProps extends Translation {
	label?: string;
	value: string;
	onChange: (value: string) => void;
}

const ColorPicker = ({ t, label, value, onChange }: ColorPickerProps) => {
	const [mode, setMode] = useState<"swatches" | "manual">("swatches");
	return (
		<FormGroup {...{ label }} className=" ">
			<div className="flex w-full flex-col gap-2">
				{mode === "manual" ? (
					<HexColorPicker color={value || ""} {...{ onChange }} />
				) : (
					<Swatches {...{ value, onChange }} />
				)}
				<small
					onClick={() =>
						setMode(mode === "manual" ? "swatches" : "manual")
					}
					className="hover:text-placeholder cursor-pointer transition-all w-max"
				>
					<i
						className={`fal mr-1 ${
							mode === "manual" ? "fa-swatchbook" : "fa-palette"
						}`}
					></i>

					{t(`modes.${mode === "manual" ? "swatches" : "manual"}`)}
				</small>
			</div>
		</FormGroup>
	);
};

ColorPicker.locale = {
	nl: {
		modes: {
			manual: "Handmatig",
			swatches: "Presets",
		},
	},
	en: {
		modes: {
			manual: "Custom",
			swatches: "Presets",
		},
	},
};

export default withTranslation(ColorPicker);
