import { atom, useAtom } from "jotai";
import { AxiosInstance } from "axios";
import { ApiTaskKanban } from "src/api/types";
import useApi, {
	ApiActions,
	ApiFilter,
	ApiStatus,
	Pagination,
} from "src/hooks/api/utils/useApi";
import moment from "moment";
import useAuth from "src/hooks/selectors/useAuth";

const showCompletedTasksAtom = atom(false);

interface ApiMyTasksTodayActions extends ApiActions {
	setShowCompletedTasks: (value: boolean) => void;
}

type State = {
	tasks: ApiTaskKanban[];
	status: ApiStatus;
	showCompletedTasks: boolean;
	actions: ApiMyTasksTodayActions;
	api: AxiosInstance;
	pagination: Pagination;
	filter: ApiFilter;
};

type Options = {
	resetFilter?: boolean;
	filter?: any;
	alwaysIncludeCompleted?: boolean;
};

function useMyTasksToday(options?: Options): State {
	const auth = useAuth();
	const [_showCompletedTasks, setShowCompletedTasks] = useAtom(
		showCompletedTasksAtom
	);
	const showCompletedTasks =
		options?.alwaysIncludeCompleted || _showCompletedTasks;
	const { state, actions, api, pagination } = useApi(
		{ id: "MY_TASKS_TODAY", group: "TASKS" },
		{
			baseUrl: `/client/tasks`,
			isAllowed: (item: ApiTaskKanban) => {
				const allowed = Boolean(
					item.links.assignees?.includes(auth.id) &&
						item.end_date &&
						moment(item.end_date).format("YYYY-MM-DD") ===
							moment().format("YYYY-MM-DD")
				);

				if (!showCompletedTasks) {
					return allowed && item.status !== "COMPLETED";
				}

				return allowed;
			},
			query: {
				recent: 1,
				variant: "kanban",
				board: "my-tasks",
				date: "today",
				...(!showCompletedTasks
					? {
							status: ["BACKLOG", "TODO", "IN_PROGRESS"],
					  }
					: {}),
				include: ["project-tasks"],
				...(options?.filter || {}),
			},
		}
	);

	return {
		tasks: state?.list || [],
		status: state.status,
		showCompletedTasks,
		filter: state?.filter || null,
		pagination,
		actions: {
			...actions,
			setShowCompletedTasks,
		},
		api,
	};
}

export default useMyTasksToday;
