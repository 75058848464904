import { ApiVacancy } from "src/api/types";
import useApi from "src/hooks/api/utils/useApi";

function useVacancy(id: string) {
	const { state, actions } = useApi(
		{ id: `VACANCY_${id}` },
		{
			baseUrl: `/client/vacancies/${id}`,
			query: {
				with: ["registrations"],
			},
		}
	);
	const vacancy: ApiVacancy = state.data || {};
	return {
		vacancy,
		status: state.status,
		actions,
	};
}

export default useVacancy;
