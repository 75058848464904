import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { ApiSignature } from "src/api/types";
import { updateSignature } from "src/redux/signature";

function useSignature() {
	const signature = useSelector((state: any) => state.signature);
	const dispatch = useDispatch();

	const fetchSelectedSignature = async () => {
		axios
			.get("/signatures/selected")
			.then(({ data }) => {
				setSelectedSignature(data.data);
			})
			.catch(() => {
				dispatch(updateSignature({ loading: false }));
			});
	};

	const setSelectedSignature = (data: ApiSignature) => {
		dispatch(
			updateSignature({
				signature: data,
				loading: false,
			})
		);
	};

	const updateSelectedSignature = (data: ApiSignature) => {
		setSelectedSignature(data);
		axios.patch(`/signatures/${data.id}`, {
			selected_at: true,
		});
	};

	return {
		...signature,
		setSelectedSignature,
		fetchSelectedSignature,
		updateSelectedSignature,
	};
}

export default useSignature;
