import { forwardRef, useCallback, useImperativeHandle, useState } from "react";
import { useDropzone } from "react-dropzone";
import { useTranslation } from "react-i18next";
import useAlert from "src/hooks/useAlert";
import useFullScreenDrop from "src/hooks/useFullScreenDrop";
import { ALLOWED_EXTENSIONS } from "src/lib/constants";

interface ChatFileDropzoneProps {
	onSubmit: (file: any) => void;
}

const ChatFileDropzone = ({ onSubmit }: ChatFileDropzoneProps, ref: any) => {
	const { t } = useTranslation(["alerts"]);
	const [value, setValue] = useState(undefined);
	const [sendAlert] = useAlert();
	const [show, setShow] = useState(true);
	const onDrop = useCallback(
		(acceptedFiles: any) => {
			setShow(false);
			const accept = ALLOWED_EXTENSIONS;
			const file = acceptedFiles[0];
			const isAllowed = accept.some((v) =>
				file.name.toLowerCase().includes(v)
			);
			setValue(undefined);
			setShow(true);
			if (file.size > 2e6) {
				sendAlert({
					title: t("file.to-large.title", { ns: "alerts" }),
					text: t("file.to-large.description", { ns: "alerts" }),
				});
				return;
			}

			if (isAllowed) {
				onSubmit(file);
				return;
			} else {
				sendAlert({
					title: t("file.unknown.title", { ns: "alerts" }),
					text: t("file.unknown.description", { ns: "alerts" }),
				});
				return;
			}
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[onSubmit, sendAlert]
	);
	useFullScreenDrop(onDrop);
	const { getRootProps, getInputProps, inputRef } = useDropzone({
		onDrop,
		noClick: true,
	});

	useImperativeHandle(
		ref,
		() => ({
			toggle: () => {
				if (inputRef && inputRef.current) {
					inputRef.current.click();
				}
			},
		}),
		[inputRef]
	);

	return (
		<div {...getRootProps()} {...{ ref }}>
			{show && <input {...{ value }} {...getInputProps()} />}
		</div>
	);
};

export default forwardRef(ChatFileDropzone);
