import { useState } from "react";
import Button from "src/components/Button";
import Input from "src/components/form/Input";
import withTranslation, {
	Translation,
} from "src/components/hoc/withTranslation";
import usePaymentRequests from "src/hooks/api/services/payment-requests/usePaymentRequests";
import useAuth from "src/hooks/selectors/useAuth";
import useModal from "src/hooks/useModal";
import useQueryString from "src/hooks/useQueryString";

const DenyPaymentRequestModal = ({ t }: Translation) => {
	const auth = useAuth();
	const qs = useQueryString();
	const modal = useModal({
		title: t(`title`),
	});
	const [reason, setReason] = useState("");
	const { actions, status } = usePaymentRequests();

	const onSubmit = async (e?: any) => {
		if (e) e.preventDefault();
		if (!qs?.ids) {
			modal.onClose();
			return;
		}

		const ids = Array.isArray(qs?.ids) ? qs?.ids : [qs?.ids];
		await actions.deny(ids, reason);
		modal.onClose();
	};

	if (auth.type === "student") {
		return (
			<div className="flex flex-col">
				<strong>{t("not-allowed.title")}</strong>
				<p className="opacity-70">{t("not-allowed.description")}</p>
			</div>
		);
	}

	if (!qs?.ids) {
		return (
			<div className="flex flex-col">
				<strong>{t("no-selected.title")}</strong>
				<p className="opacity-70">{t("no-selected.description")}</p>
			</div>
		);
	}

	return (
		<form {...{ onSubmit }} className="flex flex-col gap-4">
			<Input
				value={reason}
				onChange={(key, value) => setReason(value)}
				name="reason"
				label={t("fields.reason.label")}
				multiline
			/>
			<Button loading={status !== "idle"} disabled={!reason} submit>
				{t("buttons.submit")}
			</Button>
		</form>
	);
};

DenyPaymentRequestModal.locale = {
	nl: {
		title: "Verzoeken afwijzen",
		"not-allowed": {
			title: "Niet toegestaan",
			description: "Het is niet mogelijk om betalingen te annuleren",
		},
		"no-selected": {
			title: "Geen betalingen geselecteerd",
			description: "Geen betalingen zijn geselecteerd",
		},
		fields: {
			reason: {
				label: "Waarom zijn deze betalingen afgewezen?",
			},
		},
		buttons: {
			submit: "Afwijzen",
		},
	},
	en: {
		title: "Reject Requests",
		"not-allowed": {
			title: "Not Allowed",
			description: "It is not possible to cancel payments",
		},
		"no-selected": {
			title: "No Payments Selected",
			description: "No payments have been selected",
		},
		fields: {
			reason: {
				label: "Why are these payments being rejected?",
			},
		},
		buttons: {
			submit: "Reject",
		},
	},
};

export default withTranslation(DenyPaymentRequestModal);
