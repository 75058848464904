import { useAtom } from "jotai";
import { atomWithStorage } from "jotai/utils";
import moment from "moment";
import { ApiTaskKanban } from "src/api/types";
import useApi from "src/hooks/api/utils/useApi";
import useAuth from "src/hooks/selectors/useAuth";

const showCompletedTasksAtom = atomWithStorage(
	"show-completed-tasks-day",
	true
);

type Options = {
	date: string;
	filter?: any;
};

function useMyTasksByDay(options: Options) {
	const auth = useAuth();
	const { showCompletedTasks, setShowCompletedTasks } =
		useMyTasksByDayCompletedToggle();
	const isAllowed = (item: ApiTaskKanban) => {
		const allowed = !!(
			item.links.assignees?.includes(auth.id) &&
			item.end_date &&
			moment(item.end_date).isSame(moment(options.date))
		);
		if (!showCompletedTasks) {
			return allowed && item.status !== "COMPLETED";
		}

		return allowed;
	};
	const { state, actions, api, pagination } = useApi(
		{ id: `MY_TASK_BY_DAY:${options.date}`, group: "TASKS" },
		{
			baseUrl: `/client/tasks`,
			isAllowed,
			query: {
				recent: 1,
				limit: 20,
				variant: "kanban",
				board: "my-tasks",
				include: ["project-tasks"],
				date: [options.date],
				...(!showCompletedTasks
					? {
							status: ["BACKLOG", "TODO", "IN_PROGRESS"],
					  }
					: {}),
				...(options?.filter || {}),
			},
		}
	);

	return {
		tasks: (state?.list || []).sort((a, b) => {
			if (a.status === "COMPLETED" && b.status !== "COMPLETED") {
				return 1; // "COMPLETED" should come last
			}
			if (b.status === "COMPLETED" && a.status !== "COMPLETED") {
				return -1; // "COMPLETED" should come last
			}
			return 0; // maintain original order for other statuses
		}),
		status: state.status,
		filter: state?.filter || null,
		pagination,
		showCompletedTasks,
		actions: {
			...actions,
			setShowCompletedTasks,
		},
		api,
	};
}

export function useMyTasksByDayCompletedToggle() {
	const [showCompletedTasks, setShowCompletedTasks] = useAtom(
		showCompletedTasksAtom
	);
	return {
		showCompletedTasks,
		setShowCompletedTasks,
	};
}

export default useMyTasksByDay;
