import moment from "moment";
import React from "react";
import { ApiOffer } from "src/api/types";
import KeyValue from "src/components/KeyValue";
import OfferDescription from "src/components/offer/utils/OfferDescription";
import { formatPrice } from "src/lib/formatters";

interface OfferInfoCardProps {
	offer: ApiOffer;
}

const OfferInfoCard = ({ offer }: OfferInfoCardProps) => {
	const duration = parseFloat(offer.estimated_duration + "").toString();

	return (
		<div className="flex flex-col gap-4">
			<OfferDescription description={offer.description} />

			{offer.estimated_duration && (
				<KeyValue.Small
					labelAs="strong"
					label="Geschatte tijdsduur"
					text={`${duration} uur`}
				/>
			)}
			<KeyValue.Small
				labelAs="strong"
				label="Categorie"
				text={offer.category.name}
			/>
			<KeyValue.Small
				labelAs="strong"
				label="Opdrachtwaarde"
				text={formatPrice(offer.budget)}
			/>
			<KeyValue.Small
				labelAs="strong"
				label="Aangemaakt op"
				text={moment(offer.created_at).format("DD-MM-YYYY")}
			/>
		</div>
	);
};

export default OfferInfoCard;
