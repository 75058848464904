import React from "react";
import { useTranslation } from "react-i18next";
import useTenant from "src/hooks/api/services/tenants/useTenant";
import "./download-app-box.scss";
const IOS_URL = "https://apps.apple.com/nl/app/getjobsdone/id1559569492";
const ANDROID_URL =
	"https://play.google.com/store/apps/details?id=nl.getjobsdone.getjobsdone";

interface DownloadAppBoxProps {
	children?: any;
	size: "small" | "medium" | "large";
}

const DownloadAppBox = ({ size }: DownloadAppBoxProps) => {
	const { tenant } = useTenant();
	const { t } = useTranslation("promotion", {
		keyPrefix: "download-app-box",
	});
	const classes = ["download-app-box", `download-app-box_${size}`];
	if (tenant.slug !== "getjobsdone") {
		return <></>;
	}
	return (
		<div className={classes.join(" ")}>
			<a target="_blank" rel="noopener noreferrer" href={IOS_URL}>
				<img
					src="/assets/images/app-store-badge.webp"
					alt={t("appstore")}
				/>
			</a>
			<a target="_blank" rel="noopener noreferrer" href={ANDROID_URL}>
				<img
					src="/assets/images/google-play-badge.webp"
					alt={t("playstore")}
				/>
			</a>
		</div>
	);
};

DownloadAppBox.defaultProps = {
	size: "small",
};

export default DownloadAppBox;
