import Button from "src/components/Button";
import FacebookButton from "src/components/FacebookButton";
import AgreeTermsCheckbox from "src/components/form/AgreeTermsCheckbox";
import Input from "src/components/form/Input";
import OAuthProviders from "src/components/forms/auth/OAuthProviders";
import withTranslation, {
	Translation,
} from "src/components/hoc/withTranslation";
import Line from "src/components/Line";
import useSignup from "src/hooks/api/services/auth/useSignup";
import useTenant from "src/hooks/api/services/tenants/useTenant";

interface StudentSignupFormProps extends Translation {}

const StudentSignupForm = ({ t }: StudentSignupFormProps) => {
	const { actions, form, validate, status } = useSignup({
		type: "student",
	});
	const { tenant } = useTenant();
	const hasMultiProviders = [
		"odylyt",
		"getjobsdone",
		"getrecruitmentdone",
	].includes(tenant.slug);

	const getFormField = (name: string) => {
		return {
			...actions.getField(name),
			noMarginBottom: true,
			placeholder: t(`fields.${name}.placeholder`),
		};
	};

	const onSubmit = (e?: any) => {
		if (e) e.preventDefault();
		if (!validate.isValid) return;

		actions.signup();
	};

	return (
		<div className="flex flex-col gap-4">
			{hasMultiProviders ? (
				<OAuthProviders userType="student" />
			) : (
				<FacebookButton
					hideDisclaimer
					type="signup"
					params={{
						is_type_later: true,
					}}
				/>
			)}
			<Line label={t("or") || ""} />
			<form className="flex flex-col gap-4" {...{ onSubmit }}>
				<div className="flex gap-2">
					<Input {...getFormField("first_name")} />
					<Input {...getFormField("last_name")} />
				</div>
				<Input {...getFormField("email")} type="email" />
				{/* <Input {...getFormField("telephone")} type="telephone" /> */}
				<Input {...getFormField("password")} type="password" />
				<AgreeTermsCheckbox
					className="my-2"
					value={form.agreed}
					onChange={() =>
						actions.setForm({
							agreed: !form.agreed,
						})
					}
				/>

				<Button
					loading={status !== "idle"}
					disabled={!form.agreed || !validate.isValid}
					submit
				>
					{t("buttons.signup")}
				</Button>
			</form>
		</div>
	);
};

StudentSignupForm.locale = {
	nl: {
		or: "of via email",
		fields: {
			first_name: {
				placeholder: "Voornaam",
			},
			last_name: {
				placeholder: "Achternaam",
			},
			email: {
				placeholder: "Email",
			},
			telephone: {
				placeholder: "Telefoonnummer",
			},
			password: {
				placeholder: "Wachtwoord",
			},
		},

		buttons: {
			signup: "Aanmelden",
		},
	},
	en: {
		or: "via email",
		fields: {
			first_name: {
				placeholder: "Firstname",
			},
			last_name: {
				placeholder: "Lastname",
			},
			email: {
				placeholder: "Email",
			},
			telephone: {
				placeholder: "Telephone",
			},
			password: {
				placeholder: "Password",
			},
		},

		buttons: {
			signup: "Signup",
		},
	},
};

export default withTranslation(StudentSignupForm);
