import { useEffect } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { ApiSubscriptionPlan } from "src/api/types";
import Button from "src/components/Button";
import useTenant from "src/hooks/api/services/tenants/useTenant";
import useSubscriptionStatus from "src/hooks/api/useSubscriptionStatus";
import useAuth from "src/hooks/selectors/useAuth";
import useSubscription from "src/hooks/selectors/useSubscription";
import useQueryString from "src/hooks/useQueryString";

interface SubscriptionStatusProps {
	plan: ApiSubscriptionPlan;
}

const SubscriptionStatus = ({ plan }: SubscriptionStatusProps) => {
	const { t } = useTranslation("subscription", {
		keyPrefix: "subscription-status",
	});
	const navigate = useNavigate();
	const userSubscription = useSubscription();
	const qs = useQueryString();
	const { mandate, subscription } = useSubscriptionStatus({
		session_id: qs.session_id,
	});
	const { tenant } = useTenant();
	const auth = useAuth();
	const mail_to = `mailto:support@${tenant?.urls?.domain}?subject=${t(
		"mandate.subject",
		{
			name: auth?.company?.name,
		}
	)}`;

	useEffect(() => {
		if (mandate === "completed" && subscription) {
			navigate("/dashboard?subscription=active");
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [mandate, subscription]);

	const renderMandateStatus = () => {
		if (mandate === "completed") {
			return (
				<>
					<i className="fas fa-check text-primary"></i>
					<p className="text-primary">{t("mandate.completed")}</p>
				</>
			);
		}

		if (mandate === "cancelled" || mandate === "expired") {
			return (
				<div className="flex flex-col gap-2">
					<i className="fas fa-times text-red text-xl"></i>
					<p>
						<Trans i18nKey="cancelled" {...{ t }}>
							Het lijkt erop dat de machtiging niet is gelukt.
							Mocht je op een andere manier willen koppelen dan
							via Rabonbank, ABN of ING? Neem dan contact op met{" "}
							<a
								href={mail_to}
								className="underline text-primary"
							>
								support@{tenant?.urls?.domain}
							</a>
						</Trans>
					</p>
					<Button className="mt-4" to="/subscription">
						{t("button.retry")}
					</Button>
				</div>
			);
		}

		return (
			<>
				<i className="fas fa-spinner-third fa-spin"></i>
				<p>{t("mandate.pending")}</p>
			</>
		);
	};

	return (
		<div className="flex flex-col gap-4">
			<h2 className="mb-4">{t("title")}</h2>
			<div className="flex items-center gap-2">
				{renderMandateStatus()}
			</div>
			{!["cancelled", "expired"].includes(mandate || "") && (
				<div className="flex items-center gap-2">
					{!subscription ? (
						<>
							<i className="fas fa-spinner-third fa-spin"></i>
							<p>{t("subscription.loading")}</p>
						</>
					) : (
						<>
							<i className="fas fa-check text-primary"></i>
							<p className="text-primary">
								{t("subscription.completed", {
									name: plan.name?.toLowerCase(),
								})}
							</p>
						</>
					)}
				</div>
			)}
			{mandate === "completed" &&
				subscription &&
				userSubscription?.id && (
					<div className="flex mt-8">
						<Button type="primary" to="/dashboard">
							{t("button.continue")}
							<i className="fas fa-arrow-right ml-2"></i>
						</Button>
					</div>
				)}
		</div>
	);
};

export default SubscriptionStatus;
