import { ComponentProps } from "react";
import { ApiDay } from "src/api/types";
import withTranslation, {
	Translation,
} from "src/components/hoc/withTranslation";
import { cn } from "src/lib/utils";

type Extend = Translation & ComponentProps<"button">;

interface DayIconProps extends Extend {
	variant?: "active";
	size?: "default" | "small";
	day: ApiDay;
}

const DayIcon = ({
	t,
	variant,
	size = "default",
	day,
	className,
	...rest
}: DayIconProps) => {
	const Element = rest?.onClick ? "button" : "div";
	return (
		<Element
			{...(rest as any)}
			className={cn(
				"bg-accent border border-border flex justify-center items-center rounded-full transition-all",
				className,
				variant === "active" && "bg-dark border-dark text-white",
				size === "default" && "w-10 h-10 text-sm",
				size === "small" && "w-8 h-8 text-[11px]"
			)}
		>
			{t(day)}
		</Element>
	);
};

DayIcon.locale = {
	nl: {
		MONDAY: "Ma",
		TUESDAY: "Di",
		WEDNESDAY: "Wo",
		THURSDAY: "Do",
		FRIDAY: "Vr",
		SATURDAY: "Za",
		SUNDAY: "Zo",
	},
	en: {
		MONDAY: "Mo",
		TUESDAY: "Tu",
		WEDNESDAY: "We",
		THURSDAY: "Th",
		FRIDAY: "Fr",
		SATURDAY: "Sa",
		SUNDAY: "Su",
	},
};

export default withTranslation(DayIcon);
