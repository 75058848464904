import { useEffect } from "react";
import { useParams } from "react-router-dom";
import Button from "src/components/Button";
import Loader from "src/components/Loader";
import withTranslation, {
	Translation,
} from "src/components/hoc/withTranslation";
import usePaymentRequests from "src/hooks/api/services/payment-requests/usePaymentRequests";
import useUnreads from "src/hooks/api/useUnreads";
import useAuth from "src/hooks/selectors/useAuth";
import useModal from "src/hooks/useModal";

const DeletePaymentRequestModal = ({ t }: Translation) => {
	const auth = useAuth();
	const { paymentRequestId } = useParams();
	const modal = useModal({
		title: t("title"),
	});
	const { fetchUnreads } = useUnreads();
	const { request, actions, status } = usePaymentRequests();

	useEffect(() => {
		actions.get(paymentRequestId);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleDelete = async () => {
		//Create the payment request
		await actions.delete(request.id);
		// //Refresh the list of payment requests
		// await actions.list();
		//Fetch the unreads
		fetchUnreads();
		//Close the modal
		modal.onClose();
	};

	if (
		!request?.id ||
		request.id?.toString() !== paymentRequestId?.toString()
	) {
		return (
			<div className="p-8 flex flex-col items-center justify-center">
				<Loader />
			</div>
		);
	}

	if (request?.creator?.hid !== auth.hid && auth.type === "student") {
		return (
			<div className="flex flex-col">
				<strong>{t("not-allowed.title")}</strong>
				<p className="opacity-70">{t("not-allowed.description")}</p>
			</div>
		);
	}

	if (request.status === "DENIED") {
		return (
			<div className="p-8 flex flex-col items-center justify-center min-h-[45vh]">
				<p className="text-center">{t("denied.text")}</p>
			</div>
		);
	}

	return (
		<>
			{request?.id ? (
				<div className="flex flex-col">
					<strong>{t("confirm.title")}</strong>
					<p className="opacity-70">{t("confirm.description")}</p>
					<div className="flex flex-col gap-3 mt-4">
						<Button
							loading={status !== "idle"}
							type="red"
							onClick={handleDelete}
						>
							{t("buttons.confirm")}
						</Button>
						<Button
							disabled={status !== "idle"}
							type="ghost"
							onClick={modal.onClose}
						>
							{t("buttons.cancel")}
						</Button>
					</div>
				</div>
			) : (
				<div className="flex flex-col justify-center items-center p-8">
					<Loader />
				</div>
			)}
		</>
	);
};

DeletePaymentRequestModal.locale = {
	nl: {
		title: "Betaalverzoek annuleren",
		confirm: {
			title: "Weet je het zeker?",
			description:
				"Je staat op het punt om een betaalverzoek te annuleren, het is niet mogelijk om dit ongedaan te maken.",
		},
		buttons: {
			confirm: "Bevestigen",
			cancel: "Annuleren",
		},
		"not-allowed": {
			title: "Annuleren niet toegestaan",
			description:
				"Je kan dit alleen doen als je het betaalverzoek zelf hebt aangemaakt.",
		},
		denied: {
			text: "Betaalverzoek afgewezen door de opdrachtgever",
		},
	},
	en: {
		title: "Cancel Payment Request",
		confirm: {
			title: "Are you sure?",
			description:
				"You are about to cancel a payment request, this action cannot be undone.",
		},
		buttons: {
			confirm: "Confirm",
			cancel: "Cancel",
		},
		"not-allowed": {
			title: "Cancellation not allowed",
			description:
				"You can only cancel if you created the payment request yourself.",
		},
		denied: {
			text: "Payment request rejected by the client",
		},
	},
};

export default withTranslation(DeletePaymentRequestModal);
