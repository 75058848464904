import { useMemo } from "react";
import { useLocation } from "react-router-dom";
import Button from "src/components/Button";
import withTranslation, {
	Translation,
} from "src/components/hoc/withTranslation";
import usePermissions from "src/hooks/api/services/auth/usePermissions";
import useTenant from "src/hooks/api/services/tenants/useTenant";
import useAuth from "src/hooks/selectors/useAuth";
import useModules from "src/hooks/selectors/useModules";

interface CreateTaskButtonProps extends Translation {
	variant?: "INTERN" | "EXTERN" | "CREATE" | "CREATE_NEW";
	small?: boolean;
	iconOnly?: boolean;
}

const CreateTaskButton = ({
	t,
	variant,
	small,
	iconOnly,
}: CreateTaskButtonProps) => {
	const auth = useAuth();
	const { modules } = useModules();
	const location = useLocation();
	const permissions = usePermissions();
	const { tenant } = useTenant();
	const to = useMemo(() => {
		const sp = new URLSearchParams(location.search);
		let url = auth.has_subscription ? "/tasks/create" : "/subscription";
		// if (variant === "CREATE") {
		// 	return url;
		// }

		const forceModal = tenant.slug !== "getcrewdone";

		if (forceModal) {
			if (variant === "INTERN") {
				return {
					modal: "./modals/tasks/create",
				};
			}
			if (variant === "EXTERN") {
				url = location.pathname;
				sp.set("modal", "create-extern-task");
			}
			if (variant === "CREATE" || variant === "CREATE_NEW") {
				url = location.pathname;
				sp.set("modal", "create-extern-task");
			}
		}

		return `${url}${sp ? `?${sp.toString()}` : ""}`;
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		auth.has_subscription,
		location.search,
		location.pathname,
		modules,
		variant,
	]);

	const type = useMemo(() => {
		if (variant === "EXTERN") return "gray";
		if (variant === "CREATE_NEW") return "dark";
		return "dark";
	}, [variant]);

	if (
		(variant === "EXTERN" && !modules.includes("extern-task")) ||
		!permissions.has("tasks.create")
	) {
		return <></>;
	}

	return (
		<Button {...{ to, small, iconOnly, type }}>
			{variant &&
				["INTERN", "CREATE", "CREATE_NEW"].includes(variant) && (
					<i className={`fas fa-plus ${!iconOnly && "mr-2"}`}></i>
				)}
			{variant === "EXTERN" && (
				<i
					className={`fas fa-hands-helping ${!iconOnly && "mr-2"}`}
				></i>
			)}
			{!iconOnly &&
				t(
					variant === "EXTERN"
						? "cta-extern"
						: variant === "CREATE"
						? "cta-create"
						: variant === "CREATE_NEW"
						? "cta-create-new"
						: "cta-intern"
				)}
		</Button>
	);
};

CreateTaskButton.locale = {
	nl: {
		"cta-intern": "Taak",
		"cta-extern": "Uitbesteden",
		"cta-create": "Opdracht",
		"cta-create-new": "Plaats opdracht",
	},
	en: {
		"cta-intern": "Create",
		"cta-create": "Create",
		"cta-extern": "Outsource",
		"cta-create-new": "Create task",
	},
};

export default withTranslation(CreateTaskButton);
