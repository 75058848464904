import { ApiTask } from "src/api/types";
import useTask from "src/hooks/api/services/tasks/task/useTask";
import { useUpdateEffectDebounce } from "src/hooks/useDebounce";

interface SaveProps {
	id?: string | "create";
	disabled?: boolean;
	timeout?: number;
	validate?: (value: any) => boolean;
	manipulateData?: (value: any) => Promise<any>;
	onCreate?: (task: ApiTask) => void;
	onUpdate?: (task: ApiTask) => void;
}

export default function useTaskAutoSave(props: SaveProps) {
	const { update, actions } = useTask(props);

	useUpdateEffectDebounce(update, props?.timeout || 500, async (value) => {
		if (!Object.keys(value || {}).length) return;

		let task = value;
		if (props.manipulateData) task = await props.manipulateData(value);

		//Validate the save value
		if (props.validate && !props.validate(task)) return;

		//Update the current task
		if (props.id && props.id !== "create") {
			const result = await actions.update(task);
			if (props.onUpdate) props.onUpdate(result);
			return;
		}

		//Create the new task
		const result = await actions.create(task);
		if (props.onCreate) props.onCreate(result);
		// actions.update(value);
	});
}
