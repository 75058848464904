import { useState } from "react";
import Avatar from "src/components/Avatar";
import Badge from "src/components/Badge";
import AnalalyticsLayout from "src/components/analytics/analytics-layout/AnalalyticsLayout";
import TasksPlanning from "src/components/analytics/tasks/TasksPlanning";
import Field from "src/components/field/Field";
import withTranslation, {
	Translation,
} from "src/components/hoc/withTranslation";
import Layout from "src/components/layout/Layout";
import ScrollPopup from "src/components/layout/ScrollPopup";
import useTeams from "src/hooks/api/services/users/useTeams";
import useUsers from "src/hooks/api/services/users/useUsers";
import useAuth from "src/hooks/selectors/useAuth";

const PlanningView = ({ t }: Translation) => {
	const [users, setUsers] = useState([]);
	const [teams, setTeams] = useState([]);
	return (
		<Layout
			breadcrumb={[
				{
					label: t("dashboard"),
					to: "/analytics/dashboard",
				},
				{
					label: t("title"),
					to: "/analytics/planning",
				},
			]}
			title={t("title")}
		>
			<AnalalyticsLayout>
				{({ date }) => (
					<>
						<ScrollPopup
							afterY={80}
							className="flex items-center gap-6"
							popup={{
								className: "flex items-center",
							}}
						>
							<Field.Users
								placeholder={t("fields.users.placeholder")}
								value={users}
								onChange={(value) => {
									setUsers(value);
									setTeams([]);
								}}
							/>
							<Field.Team
								placeholder={t("fields.teams.placeholder")}
								value={teams}
								onChange={(value) => {
									setTeams(value || []);
									setUsers([]);
								}}
							/>
						</ScrollPopup>
						{date?.start && date?.end && (
							<div className="flex flex-col gap-6 px-2">
								{!teams.length && !users.length && (
									<TasksPlanning
										name="DEFAULT"
										{...{ date }}
									/>
								)}

								{users.map((userId) => (
									<div
										key={`user-${userId}`}
										className="flex flex-col"
									>
										<div className="mb-4">
											<Head variant="user" id={userId} />
										</div>
										<TasksPlanning
											name={`USER_${userId}`}
											{...{ date }}
											filter={{ assignees: [userId] }}
										/>
									</div>
								))}
								{teams.map((teamId) => (
									<div
										key={`team-${teamId}`}
										className="flex flex-col"
									>
										<div className="mb-4">
											<Head variant="team" id={teamId} />
										</div>
										<TasksPlanning
											name={`TEAM_${teamId}`}
											{...{ date }}
											filter={{ teams: [teamId] }}
										/>
									</div>
								))}
							</div>
						)}
					</>
				)}
			</AnalalyticsLayout>
		</Layout>
	);
};

interface HeadProps {
	variant: "team" | "user";
	id: number | string;
}

const Head = ({ variant, id: _id }: HeadProps) => {
	const auth = useAuth();
	const { users } = useUsers();
	const { teams } = useTeams();
	const id = _id === "me" ? auth.id : _id;
	const user =
		variant === "user"
			? users.find((u) => u.id.toString() === id.toString())
			: null;
	const team =
		variant === "team" ? teams.find((item: any) => item.slug === id) : null;
	return (
		<div className=" ">
			{user && (
				<Avatar.User
					{...{ user }}
					avatar={{
						size: "xsmall",
					}}
				/>
			)}
			{team && <Badge custom={team.color}>{team.name}</Badge>}
		</div>
	);
};

PlanningView.locale = {
	nl: {
		dashboard: "Analytics",
		title: "Planning",
		fields: {
			users: {
				placeholder: "Uitvoerder(s)",
			},
			teams: {
				placeholder: "Team(s)",
			},
		},
	},
	en: {
		dashboard: "Analytics",

		title: "Planning",
		fields: {
			users: {
				placeholder: "Employee(s)",
			},
			teams: {
				placeholder: "Team(s)",
			},
		},
	},
};

export default withTranslation(PlanningView);
