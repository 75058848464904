import React from "react";
import useVariant from "src/hooks/useVariant";
import Badge from "src/components/Badge";

const DemoAccountBanner = () => {
	const variant = useVariant("orange");
	return (
		<div className="fixed top-0 left-0 right-0 ">
			<div className={`h-2 w-full ${variant}`}>
				<div className="container-lg z-20 relative pt-2">
					<Badge className="absolute -top-2 right-0" variant="orange">
						Demo
					</Badge>
				</div>
			</div>
		</div>
	);
};

export default DemoAccountBanner;
