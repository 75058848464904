import { motion } from "framer-motion";
import { DatasetItem, DatasetTheme } from "src/components/statistics/bar-chart";
import { useBarChart } from "src/components/statistics/bar-chart/Provider";
import { cn } from "src/lib/utils";

interface BarProps {
	data: DatasetItem;
	theme?: DatasetTheme;
	index?: number;
}

const Bar = ({ data, theme, index }: BarProps) => {
	const { totals } = useBarChart();
	const percent = (data.count / totals.max) * 100;
	return (
		<div
			className={cn("absolute inset-0 flex flex-col justify-end")}
			style={{
				zIndex: 44 - (index || 0),
			}}
		>
			<motion.div
				className={cn("bg-accent rounded-md", theme?.bar?.className)}
				initial={{ height: 0 }}
				transition={{
					duration: 0.75,
				}}
				animate={{
					height: `${percent}%`,
				}}
			></motion.div>
		</div>
	);
};

export default Bar;
