import { useEffect } from "react";
import withTranslation, {
	Translation,
} from "src/components/hoc/withTranslation";
import KeyValue from "src/components/KeyValue";
import useTimesheetsTotals from "src/hooks/api/services/timesheets/useTimesheetsTotals";
import useModules from "src/hooks/selectors/useModules";
import useServiceRate from "src/hooks/useServiceRate";
import { formatPrice } from "src/lib/formatters";

interface TimesheetProcessDetailsProps extends Translation {
	options: {
		task_id: number;
		approved?: boolean;
	};
	children?: any;
}

const TimesheetProcessDetails = ({
	t,
	options,
	children,
}: TimesheetProcessDetailsProps) => {
	const { service_rate } = useServiceRate();
	const { totals, actions } = useTimesheetsTotals({
		task_id: options.task_id,
		filter: {
			approved: options?.approved,
		},
	});
	const price = totals.price;
	const partnerFee = totals?.approved;
	const serviceFee = price * service_rate + 1;
	const total = price + partnerFee + serviceFee;
	const { modules } = useModules();
	useEffect(() => {
		actions.get();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<>
			<div className="flex flex-col flex-1 gap-2">
				<KeyValue.Small
					label={t("keys.hours.label")}
					text={t("keys.hours.value", {
						count: totals.total,
					})}
				/>
				<KeyValue.Small
					label={t("keys.price.label")}
					text={t("keys.price.value", {
						price: formatPrice(price, "EUR", 2),
					})}
				/>
				<KeyValue.Small
					label={t("keys.partner-fee.label")}
					text={t("keys.partner-fee.value", {
						price: formatPrice(partnerFee, "EUR", 2),
					})}
				/>
				<KeyValue.Small
					label={t("keys.service-fee.label")}
					text={t("keys.service-fee.value", {
						price: formatPrice(serviceFee, "EUR", 2),
					})}
				/>
				<hr className="my-4 border-border-dark" />
				<KeyValue.Small
					label={t("keys.total.label")}
					text={t("keys.total.value", {
						price: formatPrice(total, "EUR", 2),
					})}
				/>
			</div>
			{children && (
				<div className="bg-background border-2 border-border-dark rounded-md p-6 mt-8 gap-4 flex flex-col">
					<div className="flex flex-col">
						<strong className="mb-1">{t("cta.title")}</strong>
						<p>
							{modules.includes("no-cure-no-pay")
								? t("cta.description-no-cure-no-pay")
								: t("cta.description-subscription")}
						</p>
					</div>

					{children}
				</div>
			)}
		</>
	);
};

TimesheetProcessDetails.locale = {
	nl: {
		keys: {
			hours: {
				label: "Totaal uren",
				value_one: "{{count}} uur",
				value_other: "{{count}} uren",
			},
			price: {
				label: "Uitbetaalbedrag",
				value: "{{price}}",
			},
			"service-fee": {
				label: "Totale fee (excl. btw)",
				value: "{{price}}",
			},
			"partner-fee": {
				label: "Totale partner fee (excl. btw)",
				value: "{{price}}",
			},
			total: {
				label: "Factuurbedrag",
				value: "{{price}}",
			},
		},
		cta: {
			title: "Betaling aanmaken",
			"description-subscription":
				"Wanneer je de betaling verwerkt worden de uitvoerders verdeeld over facturen (max. 25 personen per factuur).",
			"description-no-cure-no-pay":
				"Wanneer je de betaling verwerkt worden de uitvoerders verdeeld over facturen (max. 25 personen per factuur) en er wordt 1 losse factuur gemaakt voor de fee.",
		},
	},
	en: {
		keys: {
			hours: {
				label: "Total hours",
				value_one: "{{count}} hour",
				value_other: "{{count}} hours",
			},
			price: {
				label: "Payout amount",
				value: "{{price}}",
			},
			"service-fee": {
				label: "Total fee (excluding VAT)",
				value: "{{price}}",
			},
			"partner-fee": {
				label: "Total partner fee (excl. btw)",
				value: "{{price}}",
			},
			total: {
				label: "Invoice amount",
				value: "{{price}}",
			},
		},
		cta: {
			title: "Create payment",
			"description-subscription":
				"When you process the payment, the performers will be divided into invoices (maximum 25 people per invoice) and a separate invoice will be made for the fee.",
			"description-no-cure-no-pay":
				"When you process the payment, the performers will be divided into invoices (maximum 25 people per invoice)",
		},
	},
};

export default withTranslation(TimesheetProcessDetails);
