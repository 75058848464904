import { createAction, handleActions } from "redux-actions";
import { ApiIssuer } from "src/api/types";

export const updateIssuer = createAction("UPDATE_ISSUER");
export const removeIssuer = createAction("REMOVE_ISSUER");

export const emptyIssuerState = {
	issuers: {
		mandate: [],
		ideal: [],
	},
	loading: true,
};

type IssuerState = {
	issuers: {
		mandate: ApiIssuer[];
		ideal: ApiIssuer[];
	};
	loading: boolean;
};

const issuerReducer = handleActions(
	{
		UPDATE_ISSUER: (state: IssuerState, action: any) => {
			return { ...state, ...action.payload };
		},
		REMOVE_ISSUER: () => {
			return {
				...emptyIssuerState,
			};
		},
	},
	{
		...emptyIssuerState,
	}
);
export default issuerReducer;
