import { useEffect } from "react";
import withTranslation, {
	Translation,
} from "src/components/hoc/withTranslation";
import Layout from "src/components/layout/Layout";
import TimesheetList from "src/components/lists/TimesheetList";
import useTask from "src/hooks/api/tasks/useTask";

function HoursDetailCompanyView({ t }: Translation) {
	const { task, actions, status } = useTask();

	useEffect(() => {
		actions.get(true, "variant=table&is_hourlist=1&include[]=parent");
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<Layout title={t("title")} loading={status !== "idle"} backUrl="/hours">
			{task?.id && status === "idle" && <TimesheetList {...{ task }} />}
		</Layout>
	);
}

HoursDetailCompanyView.locale = {
	nl: {
		title: "Uren controle",
	},
	en: {
		title: "Hour registration",
	},
};

export default withTranslation(HoursDetailCompanyView);
