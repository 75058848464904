import { ApiMedia } from "src/api/types";
import { cn } from "src/lib/utils";

interface MessageFileProps {
	media: ApiMedia;
	isMe?: boolean;
}

const MessageFile = ({ media, isMe }: MessageFileProps) => {
	return (
		<a
			href={media.download_url}
			target="_blank"
			download
			className={cn(
				"rounded-md p-4 flex flex-col gap-2 justify-center items-center bg-accent",
				isMe && "bg-primary text-primary-foreground"
			)}
			rel="noreferrer"
		>
			<div
				className={`w-40 h-40 flex justify-center items-center rounded-md`}
			>
				{media.mime_type.startsWith("image") ? (
					<img
						src={media.url}
						className="w-full h-full object-contain"
						alt=""
					/>
				) : (
					<i className="fas fa-file text-[40px]"></i>
				)}
			</div>

			<div className="flex justify-between gap-2 items-center">
				<div className="">
					<div className="max-w-[120px] md:max-w-[160px] relative overflow-hidden">
						<strong className="whitespace-nowrap line-clam-1">
							{media.filename}
						</strong>
					</div>
					<span className="opacity-60">{media.size}</span>
				</div>

				<i className="fas fa-download"></i>
			</div>
		</a>
	);
};

export default MessageFile;
