import React, { HTMLProps } from "react";

interface ContainerProps extends HTMLProps<HTMLDivElement> {
	children?: any;
}

const Container = ({ children, ...rest }: ContainerProps) => {
	return (
		<div
			{...rest}
			className={`flex flex-col flex-1 overflow-auto hide-default-scrollbar md:mx-0 md:px-0 -mx-4 px-4 ${rest?.className}`}
		>
			{children}
		</div>
	);
};

export default Container;
